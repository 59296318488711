import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 16,
  y: 16,
}

export const CloudUpload: React.FCC<IIcon> = ({
  size = defaultSize,
  className,
  color = '#5C6574',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66664 12C2.93447 12 1.53027 10.6568 1.53027 8.99998C1.53027 7.34313 2.93447 5.99999 4.66664 5.99999C4.93128 4.82101 5.87468 3.85866 7.14147 3.47543C8.40826 3.09221 9.80599 3.34635 10.8081 4.1421C11.8103 4.93786 12.2646 6.15434 12 7.33332H12.6666C13.9553 7.33332 15 8.37799 15 9.66665C15 10.9553 13.9553 12 12.6666 12H12"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 10L8 8L10 10"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99984 8V14"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
