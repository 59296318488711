// @ts-nocheck
import { paper } from '@aninix-inc/renderer'
import { Viewport } from '@aninix/core'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import * as PIXI from 'pixi.js'
import { NodeSnapshot } from '../../common/renderers/types'
import { TessGraphics, drawVectorPath } from './draw-vector-path'

export async function applyInnerShadow(
  node: NodeSnapshot,
  graphics: PIXI.Graphics,
  container: PIXI.Container,
  viewport: Viewport,
  app: PIXI.Application,
  postrenderCallbacks: (() => void)[],
  blurQuality: number
) {
  node.innerShadows.forEach((shadow) => {
    if (!shadow.visible) return

    const { color: colorRGBA, offset: offsetProp, radius, blendMode } = shadow
    const offset = {
      x: offsetProp.x,
      y: offsetProp.y,
    }
    const blur = (radius * viewport.zoom) / (3 * 1.66)

    const shadowGraphics = new TessGraphics(node.data[0].windingRule).beginFill(
      colorRGBA
    )
    shadowGraphics.alpha = colorRGBA.a

    const includePath = new paper.Path.Rectangle(
      new paper.Rectangle(
        { x: -radius * 3, y: -radius * 3 },
        {
          x: node.size.x + 2 * radius * 3,
          y: node.size.y + 2 * radius * 3,
        }
      )
    )

    const shape = new paper.CompoundPath(node.data.map((e) => e.data).join(''))
    shape.fillRule = node.data[0].windingRule

    const excludePath = shape.clone()

    includePath.transform(node.transformMatrix.clone())
    excludePath.transform(node.transformMatrix.clone())

    excludePath.translate(offset)
    const shadowPath = includePath.subtract(excludePath)

    shadowPath.transform(node.transformMatrix.clone().invert())

    drawVectorPath(node, shadowGraphics, shadowPath.pathData, true)

    shadowGraphics.endFill()

    // postrenderCallbacks.push(() => {
    // const blurFilter = new PIXI.BlurFilter()
    const blurFilter = new KawaseBlurFilter()
    blurFilter.quality = blurQuality
    blurFilter.resolution = 1
    blurFilter.padding = 10
    blurFilter.blur = blur

    shadowGraphics.filters = [blurFilter]

    const mask = graphics.clone()

    const shadowContainer = new PIXI.Container()
    shadowContainer.addChild(mask, shadowGraphics)
    shadowContainer.mask = mask

    shadowGraphics.name = `${node.name}_innerShadow`
    container.addChild(shadowContainer)
  })
  // })
}
