import * as React from 'react'

import { IIcon } from '.'

const defaultSize = { x: 20, y: 20 }

export const GetLink: React.FCC<IIcon> = ({
  size = defaultSize,
  className = 'stroke-gray-400',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 20 20`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.33325 11.6667C8.88184 12.2266 9.63272 12.5421 10.4166 12.5421C11.2005 12.5421 11.9513 12.2266 12.4999 11.6667L15.8333 8.33332C16.9838 7.18273 16.9838 5.31725 15.8333 4.16666C14.6827 3.01606 12.8172 3.01606 11.6666 4.16666L11.2499 4.58332"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6667 8.33343C11.1181 7.77353 10.3672 7.45801 9.58332 7.45801C8.79946 7.45801 8.04858 7.77353 7.49999 8.33343L4.16666 11.6668C3.01606 12.8174 3.01606 14.6828 4.16666 15.8334C5.31725 16.984 7.18273 16.984 8.33332 15.8334L8.74999 15.4168"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
