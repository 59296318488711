import { DurationComponent, Root } from '@aninix-inc/model'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useProject } from '../../../../../../stores'

const secondsToMS = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return seconds < 1
    ? format(date, 'SSS') + ' ms'
    : format(date, 'sSSS') + ' ms'
}

const secondsToSS = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return format(date, 's.S') + ' s'
}

export interface IProps {}
export const TotalTime: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const duration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value
  const totalTime = (duration <= 1 ? secondsToMS : secondsToSS)(duration)
  return (
    <p className="m-0 min-w-[54px] shrink-[1] select-none pl-1 text-center font-mono text-[11px] text-black text-opacity-30">
      {totalTime}
    </p>
  )
})

TotalTime.displayName = 'TotalTime'
