import {
  ClipboardKeyframeJson,
  Entity,
  EntryComponent,
  Project,
  SelectionSystem,
  UndoRedoSystem,
  cleanupKeyframes,
  commitUndo,
  getNode,
  pasteKeyframes,
} from '@aninix-inc/model'
import * as R from 'ramda'
import * as React from 'react'

type Payload = {
  project: Project
}

export interface IKeyframesPasteUseCase {
  execute: (payload: {
    keyframes: ClipboardKeyframeJson[]
    time: number
  }) => void
}

/**
 * Paste segments from aninix's clipboard.
 * For position and rotation we use "delta" mode so new animation added to initialValue.
 * For all other props we replace state.
 * ---
 * Possible cases:
 * 1. keyframes copied from multiple nodes;
 *    1. pasted into multiple nodes !!! NOT implemented yet;
 *        1. number of selected nodes equal to copied;
 *        2. number of selected nodes are differ from copied;
 *    2. pasted into single node -> paste all keyframes into 1 node;
 * 2. keyframes copied from single node;
 *    1. pasted into multiple nodes -> paste keyframes on each node as clones;
 *    2. pasted into single node -> paste keyframes;
 * ---
 * @todo refactor and simplify
 */
export function useKeyframesPasteUseCase({
  project,
}: Payload): IKeyframesPasteUseCase {
  const selection = project.getSystemOrThrow(SelectionSystem)
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)

  const execute: IKeyframesPasteUseCase['execute'] = React.useCallback(
    ({ keyframes, time }) => {
      // @NOTE: required to prevent pasting multiple times to the same node. Related to ANI-1533
      const nodesToPaste = R.uniqBy(
        (entity) => entity.id,
        selection
          .getEntities()
          .map((entity) => getNode(entity))
          // @NOTE: required to prevent pasting from entry frame. Related to ANI-1268
          .filter((node) => !node?.hasComponent(EntryComponent)) as Entity[]
      )

      const createdKeyframes = pasteKeyframes(keyframes, nodesToPaste, time)

      // @NOTE: select created keyframes
      selection.replace(createdKeyframes.map((keyframe) => keyframe.id))
      cleanupKeyframes(project)
      commitUndo(project)
    },
    [selection, undoRedo, project]
  )

  return {
    execute,
  }
}
