import { useQuery } from '@tanstack/react-query'

import { ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetActiveSystemStatus() {
  return useQuery({
    queryKey: ['get-active-system-status', `1`],
    async queryFn() {
      return httpClient.get<ApiGetResponse<'/system-statuses/editor'>>(
        '/system-statuses/editor'
      )
    },
  })
}
