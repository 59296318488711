import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'

import { PresetAnimationAppearanceAlignmentOutBoxType } from '../../../models/preset'
import * as styles from './index.scss'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  type: PresetAnimationAppearanceAlignmentOutBoxType
  size?: Point2D
}
export const InOutbox: React.FCC<IProps> = ({ type, size = defaultSize }) => {
  if (type === PresetAnimationAppearanceAlignmentOutBoxType.Top) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00004 10.7071L7.64648 10.3536L4.64648 7.35355L5.35359 6.64645L7.50004 8.79289V1L8.50004 1V8.79289L10.6465 6.64645L11.3536 7.35355L8.35359 10.3536L8.00004 10.7071Z"
          className={styles.icon__fill}
        />
        <path
          d="M11 1.5H14.5V14.5H1.5L1.5 1.5H5"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentOutBoxType.Right) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.29289 8.00004L5.64645 7.64648L8.64645 4.64648L9.35355 5.35359L7.20711 7.50004L15 7.50004L15 8.50004L7.20711 8.50004L9.35355 10.6465L8.64645 11.3536L5.64645 8.35359L5.29289 8.00004Z"
          className={styles.icon__fill}
        />
        <path
          d="M14.5 11L14.5 14.5L1.5 14.5L1.5 1.5L14.5 1.5L14.5 5"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentOutBoxType.Bottom) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99996 5.29289L8.35352 5.64645L11.3535 8.64645L10.6464 9.35355L8.49996 7.20711L8.49996 15L7.49996 15L7.49996 7.20711L5.35352 9.35355L4.64641 8.64645L7.64641 5.64645L7.99996 5.29289Z"
          className={styles.icon__fill}
        />
        <path
          d="M5 14.5L1.5 14.5L1.5 1.5L14.5 1.5L14.5 14.5L11 14.5"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentOutBoxType.Left) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7071 7.99996L10.3536 8.35352L7.35355 11.3535L6.64645 10.6464L8.79289 8.49996L1 8.49996L1 7.49996L8.79289 7.49996L6.64645 5.35352L7.35355 4.64641L10.3536 7.64641L10.7071 7.99996Z"
          className={styles.icon__fill}
        />
        <path
          d="M1.5 5L1.5 1.5L14.5 1.5L14.5 14.5L1.5 14.5L1.5 11"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentOutBoxType.None) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C9.33875 2.5 10.5658 2.97831 11.5195 3.77337L3.77337 11.5195C2.97831 10.5658 2.5 9.33875 2.5 8ZM4.48047 12.2266C5.43422 13.0217 6.66125 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 6.66125 13.0217 5.43422 12.2266 4.48047L4.48047 12.2266ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  return null
}
