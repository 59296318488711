import * as React from 'react'
import type { OnScrollParams } from 'react-virtualized'
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized'

import * as styles from './styles.scss'

export interface IVirtualizedListPops<T> {
  data: T[]
  renderRow: (payload: {
    value: T
    measure: () => void
    registerChild: ((element: Element) => void) | undefined
    isVisible: boolean
  }) => React.ReactNode
  offsetY?: number
  onScroll?: (params: OnScrollParams) => void
  height?: number
}

function VirtualizedList<T>(
  { data, renderRow, offsetY, onScroll, height = 200 }: IVirtualizedListPops<T>,
  ref: React.ForwardedRef<List>
) {
  const cache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 16,
    })
  )

  return (
    <div className={styles.list} style={{ height }}>
      <AutoSizer>
        {(options) => (
          <List
            ref={ref}
            className={styles.grid}
            onScroll={onScroll}
            scrollTop={offsetY}
            width={options.width}
            height={options.height}
            rowHeight={cache.current.rowHeight}
            deferredMeasurementCache={cache.current}
            rowCount={data.length}
            rowRenderer={({ key, index, style, parent, isVisible }) => {
              const item = data[index]

              return (
                <CellMeasurer
                  key={key}
                  cache={cache.current}
                  parent={parent}
                  columnIndex={0}
                  rowIndex={index}
                >
                  {({ measure, registerChild }) => (
                    <div style={style}>
                      {renderRow({
                        value: item,
                        measure,
                        registerChild,
                        isVisible,
                      })}
                    </div>
                  )}
                </CellMeasurer>
              )
            }}
          />
        )}
      </AutoSizer>
    </div>
  )
}

export default React.forwardRef(VirtualizedList) as <T>(
  props: IVirtualizedListPops<T> & { ref?: React.ForwardedRef<List> }
) => React.ReactElement
