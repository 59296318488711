import { commitUndo, Component, mixed } from '@aninix-inc/model'
import { InputWithIcon } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useValuePoint2d } from '../../../../../hooks/value-controllers/point-2d'

export interface IProps {
  components: Component[]
  time?: number
  iconX?: React.ReactNode
  iconY?: React.ReactNode
  /**
   * Function would be applied to value before providing the value to input
   */
  beforeX?: (x: number) => number
  /**
   * Function would be applied to value before applying changes the value to model
   */
  afterX?: (x: number) => number
  /**
   * Function would be applied to value before providing the value to input
   */
  beforeY?: (y: number) => number
  /**
   * Function would be applied to value before applying changes the value to model
   */
  afterY?: (y: number) => number
  formatValueX?: (x: number) => string
  formatValueY?: (y: number) => string
  onUpdate?: (type: 'x' | 'y', value: number) => void
  onDeltaUpdate?: (type: 'x' | 'y', value: number) => void
}
export const Point2dValue: React.FCC<IProps> = observer(
  ({
    components,
    time,
    iconX,
    iconY,
    beforeX,
    beforeY,
    afterX,
    afterY,
    formatValueX,
    formatValueY,
    onUpdate,
    onDeltaUpdate,
  }) => {
    const { x, y, updateX, updateXByDelta, updateY, updateYByDelta } =
      useValuePoint2d({ components, time })
    const id = components.map((component) => component.id).join('-')
    const endChange = () => commitUndo(components[0].entity.getProjectOrThrow())

    const handleChangeX = React.useCallback(
      (value: number) => {
        const preparedValue = afterX == null ? value : afterX(value)

        if (onUpdate == null) {
          updateX(preparedValue)
          return
        }

        onUpdate('x', preparedValue)
      },
      [onUpdate, updateX, afterX]
    )
    const handleChangeY = React.useCallback(
      (value: number) => {
        const preparedValue = afterY == null ? value : afterY(value)

        if (onUpdate == null) {
          updateY(preparedValue)
          return
        }

        onUpdate('y', preparedValue)
      },
      [onUpdate, updateY, afterY]
    )

    const handelChangeXByDelta = React.useCallback(
      (delta: number) => {
        if (onDeltaUpdate == null) {
          updateXByDelta(delta)
          return
        }

        onDeltaUpdate('x', delta)
      },
      [onDeltaUpdate, updateXByDelta]
    )
    const handelChangeYByDelta = React.useCallback(
      (delta: number) => {
        if (onDeltaUpdate == null) {
          updateYByDelta(delta)
          return
        }

        onDeltaUpdate('y', delta)
      },
      [onDeltaUpdate, updateYByDelta]
    )

    return (
      <div>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <InputWithIcon
            value={x !== mixed && beforeX ? beforeX(x) : x}
            id={id}
            icon={iconX}
            onChange={handleChangeX}
            onDeltaChange={handelChangeXByDelta}
            onEndChange={endChange}
            format={formatValueX}
          />
          <InputWithIcon
            value={y !== mixed && beforeY ? beforeY(y) : y}
            id={id}
            icon={iconY}
            onChange={handleChangeY}
            onDeltaChange={handelChangeYByDelta}
            onEndChange={endChange}
            format={formatValueY}
          />
        </div>
      </div>
    )
  }
)

Point2dValue.displayName = 'Point2dValue'
