import classNames from 'classnames'
import * as React from 'react'

import { AddAvatar } from '../../components/add-avatar'
import { Typography } from '../../components/design-system/typography'
import { useCurrentStepContext } from './use-current-step'

export interface IProps {
  handleNavigation: (teamId: string) => void
}
export const AddAvatarPage: React.FCC<IProps> = ({ handleNavigation }) => {
  const [initialTransition, setInitialTransitions] =
    React.useState<boolean>(false)

  React.useEffect(() => {
    setInitialTransitions(true)
  }, [])

  const { teamId } = useCurrentStepContext()

  const handleNextStep = React.useCallback(() => {
    //TODO: replace with navigation to next page
    handleNavigation(teamId!)
  }, [teamId])

  return (
    <div
      className={classNames(
        'flex flex-col items-center justify-center gap-4 opacity-0 transition-all duration-200',
        {
          ['!opacity-100']: initialTransition,
        }
      )}
    >
      <img
        crossOrigin="anonymous"
        className="h-20"
        src="/images/aninix-logo.svg"
      />
      <Typography
        style="Heading3"
        alignment="center"
        text="Upload your team logo"
      />
      <AddAvatar handleNextStep={handleNextStep} />
    </div>
  )
}

AddAvatarPage.displayName = 'AddAvatarPage'
