import * as React from 'react'

import { useCurrentSpaceStore, useCurrentTeamStore } from '../../../use-cases'
import { TeamItem } from './team-item'

interface IProps {}

export const CurrentTeam: React.FCC<IProps> = ({}) => {
  const { currentUser } = useCurrentSpaceStore()

  const { currentTeam } = useCurrentTeamStore()

  const { id, name, avatarUrl, aninixRole, plan } = currentTeam!

  return (
    <TeamItem
      key={id}
      name={name}
      avatarUrl={avatarUrl}
      aninixRole={aninixRole}
      isPro={plan === 'pro'}
    />
  )
}

CurrentTeam.displayName = 'CurrentTeam'
