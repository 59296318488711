import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 24,
  y: 24,
}

export const ProjectsSelected: React.FCC<IIcon> = ({
  size = defaultSize,
  className,
  color = '#0B1118',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 ${size.x} ${size.y}`}
    fill="none"
    strokeWidth={2}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 4.5H6.5C5.39543 4.5 4.5 5.39543 4.5 6.5V8.5C4.5 9.60457 5.39543 10.5 6.5 10.5H8.5C9.60457 10.5 10.5 9.60457 10.5 8.5V6.5C10.5 5.39543 9.60457 4.5 8.5 4.5Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 4.5H15.5C14.3954 4.5 13.5 5.39543 13.5 6.5V8.5C13.5 9.60457 14.3954 10.5 15.5 10.5H17.5C18.6046 10.5 19.5 9.60457 19.5 8.5V6.5C19.5 5.39543 18.6046 4.5 17.5 4.5Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.5 13.5H6.5C5.39543 13.5 4.5 14.3954 4.5 15.5V17.5C4.5 18.6046 5.39543 19.5 6.5 19.5H8.5C9.60457 19.5 10.5 18.6046 10.5 17.5V15.5C10.5 14.3954 9.60457 13.5 8.5 13.5Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 13.5H15.5C14.3954 13.5 13.5 14.3954 13.5 15.5V17.5C13.5 18.6046 14.3954 19.5 15.5 19.5H17.5C18.6046 19.5 19.5 18.6046 19.5 17.5V15.5C19.5 14.3954 18.6046 13.5 17.5 13.5Z"
      fill={color}
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
