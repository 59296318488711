import {
  useGetMe as useGetMeDefault,
  useSendFeedback as useSendFeedbackDefault,
} from '@aninix/api'
import { useNotifications as useNotificationsDefault } from '@aninix/notifications'
import classNames from 'classnames'
import * as React from 'react'
import { Loader } from '../common'
import { Attachment } from './attachment'

// source: https://emailregex.com/, JS section
// should cover 99.9% of cases, but never 100% unless you try to send an email :)
const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  export type IProps = {
    defaultTitle?: string
    defaultProjectLink: string
    onSubmit?: () => void
    onCancel?: () => void
    useNotifications?: typeof useNotificationsDefault
    useGetMe?: typeof useGetMeDefault
    useSendFeedback?: typeof useSendFeedbackDefault
    highlightColor?: string
    header?: React.ReactNode
  }

  export const FeedbackFormWithFiles: React.FC<IProps> = ({
    defaultTitle = '',
    defaultProjectLink = '',
    onSubmit,
    useNotifications = useNotificationsDefault,
    useGetMe = useGetMeDefault,
    useSendFeedback = useSendFeedbackDefault,
    highlightColor = '#374FD5',
    header = <p className="txt-[32_32_590]">Send us a message</p>,
  }) => {
    const [isEmailDirty, setIsEmailDirty] = React.useState(false)
    const [email, setEmail] = React.useState('')
    const [title, setTitle] = React.useState(defaultTitle)
    const [projectLink, setProjectLink] =
      React.useState<string>(defaultProjectLink)
    const [message, setMessage] = React.useState('')

    const bodyContent =
      (projectLink.length > 0 ? 'Project link: ' + projectLink + '\n\n' : '') +
      message

    const [isSubmissionError, setIsSubmissionError] = React.useState(false)

    const [attachments, setAttachments] = React.useState<File[]>([])

    const isAttachementValid =
      attachments.length <= 4 &&
      attachments
        .map((file) => file.size / 1024 / 1024 < 10)
        .every((v) => v === true)

    const notifications = useNotifications()
    const { data: me, isLoading: isMeLoading } = useGetMe()
    const { mutateAsync: sendFeedback, status } = useSendFeedback()

    const isSendFeedbackLoading = status === 'pending'

    const isEmailValid = React.useMemo(() => {
      if (!isEmailDirty) {
        return true
      }

      if (email === '') {
        return true
      }

      return emailRegex.test(email)
    }, [isEmailDirty, email])

    const handleSubmit = React.useCallback(async () => {
      const formData = new FormData()

      formData.append('title', title)
      formData.append('message', message)
      if (projectLink.length > 0)
        formData.append('projectLink.length > 0', projectLink)
      attachments?.forEach((file) => formData.append('attachment', file))

      sendFeedback({
        content: bodyContent,
        title,
        email,
        attachments: attachments as unknown as Record<string, never>[],
      })
        .then((v) => {
          onSubmit?.()
        })
        .catch((e) => {
          setIsSubmissionError(true)
          return
        })
    }, [
      email,
      message,
      title,
      projectLink,
      sendFeedback,
      onSubmit,
      notifications,
    ])

    const handleAttachmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e?.currentTarget?.files == null) return

      const newFiles = Array.from(e.currentTarget.files)

      setAttachments((value) => [...value, ...newFiles])
    }

    React.useEffect(() => {
      console.log('WHATS IN STATE', attachments)
    }, [attachments])

    React.useEffect(() => {
      if (me?.data.email != null) {
        setEmail(me.data.email)
      }
    }, [me])

    const footer = (
      <div className="py-4 px-5 bg-[#F5F6F8] border-t-[1px] border-t-solid border-t-[#E6EAED] text-[#89909A]">
        {isSubmissionError && (
          <p className="text-red text-center w-full txt-[16_24_400]">
            We couldn't recieve your feedback. Contact us via{' '}
            <a
              className="text-blue-500"
              href={`mailto:info@aninix.com?subject=${title}&body=${bodyContent}`}
            >
              info@aninix.com
            </a>
          </p>
        )}
        <p className="txt-[14_20_400] text-center">
          You can also contact us{' '}
          <a
            href="https://aninix.com/discord"
            className="text-[var(--highlight-color)]"
            target="_blank"
          >
            via Discord
          </a>
          . We will reply sooner there.
        </p>
      </div>
    )

    return (
      <div
        className="bg-white rounded-2xl overflow-hidden shadow-lg border-t border-t-gray-200 shadow-[#00034E]/20"
        style={{
          // @ts-ignore
          '--highlight-color': highlightColor,
        }}
      >
        <div className="p-8 flex flex-col gap-6">
          {header}

          <label
            className={classNames(
              'txt-[14_20_510] w-full flex flex-col gap-1',
              {
                '!text-red': !isEmailValid,
              }
            )}
          >
            <p>Your email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={
                isMeLoading
                  ? 'Searching for your email...'
                  : 'So we know who to reply to'
              }
              disabled={isMeLoading || me?.data.email != null}
              className={classNames(
                'font-normal border-[1px] border-[#E6EAED] border-solid rounded-lg px-2 py-[6px] focus:border-[var(--highlight-color)] disabled:opacity-60',
                {
                  '!border-red': !isEmailValid,
                }
              )}
              onBlur={() => setIsEmailDirty(true)}
            />

            {!isEmailValid && (
              <p className="text-red font-normal">Email is not valid</p>
            )}
          </label>

          <label className="txt-[14_20_510] w-full flex flex-col gap-1">
            <p>Title</p>
            <input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Describe what your feedback is about"
              className="font-normal border-[1px] border-[#E6EAED] border-solid rounded-lg px-2 py-[6px] focus:border-[var(--highlight-color)]"
            />
          </label>

          <label className="group txt-[14_20_510] w-full flex flex-col gap-1 has-[:invalid]:text-red">
            <p>
              Project file <span className="font-normal">(optional)</span>
            </p>
            <input
              type="url"
              value={projectLink}
              pattern="https://.*"
              onChange={(e) => setProjectLink(e.target.value)}
              placeholder="Link to Aninix project or Figma file"
              className="font-normal border-[1px] border-[#E6EAED] invalid:!border-red border-solid rounded-lg px-2 py-[6px] focus:border-[var(--highlight-color)]"
            />
            <p className="text-red font-normal hidden group-has-[:invalid]:block">
              Enter valid URL that starts with https://
            </p>
          </label>

          <label className="txt-[14_20_510] w-full flex flex-col gap-1">
            <p>Description</p>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              placeholder="Let us know what happened or how we can improve your experience"
              className="font-normal border-[1px] border-[#E6EAED] border-solid rounded-lg px-2 py-[6px] focus:border-[var(--highlight-color)]"
              rows={4}
            />
          </label>

          {attachments && attachments.length > 0 && (
            <div className="flex flex-col gap-1">
              <div className="flex flex-row gap-2 flex-wrap">
                {attachments.map((file, idx) => (
                  <Attachment
                    key={file.webkitRelativePath + file.name}
                    file={file}
                    onRemove={() => {
                      setAttachments((value) =>
                        value?.filter((e, i) => {
                          return i != idx
                        })
                      )
                    }}
                  />
                ))}
              </div>
            </div>
          )}

          <div className="flex flex-col gap-1">
            <div className="flex flex-row justify-between">
              <label className="has-[:disabled]:opacity-50">
                <input
                  hidden
                  type="file"
                  disabled={attachments.length > 3}
                  multiple
                  onChange={(e) => handleAttachmentChange(e)}
                />
                <div className="flex cursor-default flex-row items-center justify-center gap-2 rounded-md border border-solid border-gray-200 bg-white px-[10px] py-[8px] text-secondary txt-[12_16_600] hover:opacity-90 active:opacity-80 disabled:pointer-events-none disabled:opacity-60">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2 5.8C2 4.11984 2 3.27976 2.32698 2.63803C2.6146 2.07354 3.07354 1.6146 3.63803 1.32698C4.27976 1 5.11984 1 6.8 1H8.83726C9.08185 1 9.20414 1 9.31923 1.02763C9.42127 1.05213 9.51881 1.09253 9.60828 1.14736C9.7092 1.2092 9.79568 1.29568 9.96863 1.46863L13.5314 5.03137C13.7043 5.20432 13.7908 5.2908 13.8526 5.39172C13.9075 5.48119 13.9479 5.57873 13.9724 5.68077C14 5.79586 14 5.91815 14 6.16274V10.2C14 11.8802 14 12.7202 13.673 13.362C13.3854 13.9265 12.9265 14.3854 12.362 14.673C11.7202 15 10.8802 15 9.2 15H6.8C5.11984 15 4.27976 15 3.63803 14.673C3.07354 14.3854 2.6146 13.9265 2.32698 13.362C2 12.7202 2 11.8802 2 10.2V5.8Z"
                      stroke="#0B1118"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8 8L8 12.5M8 8L6 10M8 8L10 10"
                      stroke="#0B1118"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9.5 1.5V3.9C9.5 4.46005 9.5 4.74008 9.60899 4.95399C9.70487 5.14215 9.85785 5.29513 10.046 5.39101C10.2599 5.5 10.5399 5.5 11.1 5.5H13.5"
                      stroke="#0B1118"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Add attachments
                </div>
              </label>

              <button
                onClick={handleSubmit}
                disabled={
                  !email ||
                  !message ||
                  !title ||
                  isSendFeedbackLoading ||
                  !isAttachementValid
                }
                className="flex cursor-default flex-row items-center justify-center gap-2 rounded-md border-2 border-solid border-[var(--highlight-color)] bg-[var(--highlight-color)] px-[10px] py-[6px] text-white txt-[12_16_600] hover:opacity-90 active:opacity-80 disabled:pointer-events-none disabled:opacity-60"
              >
                {isSendFeedbackLoading ? (
                  <Loader size={16} color="white" />
                ) : (
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.5 14.5L14 2L1.5 6.5L7 9L9.5 14.5Z"
                      stroke="white"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M14 2L7 9"
                      stroke="white"
                      strokeWidth="1.6"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
                Send
              </button>
            </div>
            {attachments.length > 4 ? (
              <p className="text-red font-normal text-[12px]/[14px]">
                You can select up to 4 files. Remove {attachments.length - 4}{' '}
                file
                {attachments.length - 4 === 1 ? '' : 's'}
              </p>
            ) : (
              <p className="text-gray-400 font-normal px-1 text-[12px]/[14px]">
                Add up to 4 files with max size 10MB each
              </p>
            )}
          </div>
        </div>

        {footer}
      </div>
    )
  }

  export const PrimaryButton: React.FCC<{
    className?: string
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    disabled?: boolean
  }> = ({ className, onClick, disabled, children }) => {
    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        onClick(e)
      },
      [onClick]
    )

    return (
      <button
        onClick={handleClick}
        disabled={disabled}
        className={classNames(
          'flex cursor-default flex-row items-center justify-center gap-2 rounded-md border-2 border-solid border-[var(--highlight-color)] bg-[var(--highlight-color)] px-[10px] py-[6px] text-white txt-[12_16_600] hover:border-[#0079CA] active:border-[#0079CA] active:bg-[#0079CA] disabled:pointer-events-none disabled:opacity-60',
          className
        )}
      >
        {children}
      </button>
    )
  }

  export const SecondaryButton: React.FCC<{
    className?: string
    onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    disabled?: boolean
  }> = ({ className, onClick, disabled, children }) => {
    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        onClick(e)
      },
      [onClick]
    )

    return (
      <button
        onClick={handleClick}
        disabled={disabled}
        className={classNames(
          'flex cursor-default flex-row items-center justify-center gap-2 rounded-md border-2 border-solid border-[#E5E5E5] bg-[#E5E5E5] px-[10px] py-[6px] text-[#0B1118] txt-[12_16_500] hover:border-[#C7C7C7] active:border-[#C7C7C7] active:bg-[#C7C7C7] disabled:pointer-events-none disabled:opacity-60',
          className
        )}
      >
        {children}
      </button>
    )
  }


