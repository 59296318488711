import * as React from 'react'

import { Normal } from './normal'
import { Reversed } from './reversed'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  type: 'normal' | 'reversed'
  style?: React.CSSProperties
}
export const ReversePath: React.FCC<IProps> = ({
  size = defaultSize,
  type,
  style,
}) => {
  if (type === 'normal') {
    return <Normal size={size} style={style} />
  }

  if (type === 'reversed') {
    return <Reversed size={size} style={style} />
  }

  return null
}
