import {
  AnchorPointComponent,
  Entity,
  Point2D,
  UndoRedoSystem,
  UpdatesSystem,
  getAnchorPoint,
  getSize,
  mixed,
  round,
  setAnchorPoint,
} from '@aninix-inc/model'
import {
  AnchorPointPosition,
  AnchorPoint as AnchorPointUiComponent,
  CompactPropertyRow,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { useComponents } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { Point2dValue } from '../../values/point-2d'

const buttonSize = {
  width: 32,
  height: 32,
}
const iconSize = {
  x: 16,
  y: 16,
}

/**
 * @description compare 2 points to meet anchor point requirements
 */
const comparePoints = (left: number, right: number): boolean =>
  Math.abs(Math.floor(left - right)) <= 1

// @TODO: move to separated file, maybe to model
export const mapPoint2DToAnchorPoint = ({
  point,
  size,
}: {
  point: Point2D
  size: Point2D
}): AnchorPointPosition => {
  const newPoint = {
    x: Math.floor(point.x),
    y: Math.floor(point.y),
  }

  const newSize = {
    x: Math.floor(size.x),
    y: Math.floor(size.y),
  }

  if (newPoint.y === 0) {
    if (newPoint.x === 0) {
      return AnchorPointPosition.TopLeft
    }

    if (comparePoints(newPoint.x, newSize.x / 2)) {
      return AnchorPointPosition.Top
    }

    if (newPoint.x === newSize.x) {
      return AnchorPointPosition.TopRight
    }
  }

  if (comparePoints(newPoint.y, newSize.y / 2)) {
    if (newPoint.x === 0) {
      return AnchorPointPosition.Left
    }

    if (comparePoints(newPoint.x, newSize.x / 2)) {
      return AnchorPointPosition.Center
    }

    if (newPoint.x === newSize.x) {
      return AnchorPointPosition.Right
    }
  }

  if (newPoint.y === newSize.y) {
    if (newPoint.x === 0) {
      return AnchorPointPosition.BottomLeft
    }

    if (comparePoints(newPoint.x, newSize.x / 2)) {
      return AnchorPointPosition.Bottom
    }

    if (newPoint.x === newSize.x) {
      return AnchorPointPosition.BottomRight
    }
  }

  return AnchorPointPosition.Custom
}

// @TODO: move to separated file, maybe to model
export const mapAnchorPointToPoint2D = (
  anchorPoint: AnchorPointPosition,
  size: Point2D
): Point2D => {
  switch (anchorPoint) {
    case AnchorPointPosition.TopLeft:
      return { x: 0, y: 0 }
    case AnchorPointPosition.Top:
      return { x: size.x / 2, y: 0 }
    case AnchorPointPosition.TopRight:
      return { x: size.x, y: 0 }
    case AnchorPointPosition.Left:
      return { x: 0, y: size.y / 2 }
    case AnchorPointPosition.Center:
      return { x: size.x / 2, y: size.y / 2 }
    case AnchorPointPosition.Right:
      return { x: size.x, y: size.y / 2 }
    case AnchorPointPosition.BottomLeft:
      return { x: 0, y: size.y }
    case AnchorPointPosition.Bottom:
      return { x: size.x / 2, y: size.y }
    case AnchorPointPosition.BottomRight:
      return { x: size.x, y: size.y }
    default:
      // Handle AnchorPointPosition.Custom or any other values
      return { x: 0, y: 0 } // You may want to choose a default point based on your needs
  }
}

export const AnchorPoint: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()

  const components = nodes.map((node) =>
    node.getComponentOrThrow(AnchorPointComponent)
  )
  useComponents(components)

  const value = (() => {
    const isXEquals =
      R.keys(
        nodes.reduce((acc, node) => {
          const anchorPoint = getAnchorPoint(node, time)
          return {
            ...acc,
            [anchorPoint.x]: anchorPoint.x,
          }
        }, {})
      ).length === 1
    const isYEquals =
      R.keys(
        nodes.reduce((acc, node) => {
          const anchorPoint = getAnchorPoint(node, time)
          return {
            ...acc,
            [anchorPoint.y]: anchorPoint.y,
          }
        }, {})
      ).length === 1

    if (isXEquals && isYEquals) {
      const anchorPoint = getAnchorPoint(nodes[0], time)
      const size = getSize(nodes[0])
      return mapPoint2DToAnchorPoint({
        point: anchorPoint,
        size,
      })
    }

    return mixed
  })()

  const [isFreeAnchorPointEnabled, setIsFreeAnchorPointEnabled] =
    React.useState(() => value === AnchorPointPosition.Custom)

  React.useEffect(() => {
    if (value === AnchorPointPosition.Custom) setIsFreeAnchorPointEnabled(true)
  }, [value])

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerEnter={() => setIsEditable(true)}>
      {isEditable ? (
        <AnchorPointEditable
          time={time}
          nodes={nodes}
          value={value}
          components={components}
          isFreeAnchorPointEnabled={isFreeAnchorPointEnabled}
          onFreeAnchorPointToggle={() => setIsFreeAnchorPointEnabled((v) => !v)}
        />
      ) : (
        <AnchorPointDisplay
          nodes={nodes}
          value={value}
          components={components}
          isFreeAnchorPointEnabled={isFreeAnchorPointEnabled}
        />
      )}
    </div>
  )
}

AnchorPoint.displayName = 'AnchorPoint'

const AnchorPointEditable: React.FC<{
  time: number
  nodes: Entity[]
  value: AnchorPointPosition | typeof mixed
  components: AnchorPointComponent[]
  isFreeAnchorPointEnabled: boolean
  onFreeAnchorPointToggle: () => void
}> = ({
  time,
  nodes,
  value,
  components,
  isFreeAnchorPointEnabled,
  onFreeAnchorPointToggle,
}) => {
  const onValueChange = React.useCallback(
    (newValue: AnchorPointPosition): void => {
      if (nodes.length === 0) {
        return
      }

      const project = nodes[0].getProjectOrThrow()
      const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
      const updates = project.getSystemOrThrow(UpdatesSystem)
      updates.batch(() => {
        nodes.forEach((node) => {
          const size = getSize(node)
          setAnchorPoint(node, mapAnchorPointToPoint2D(newValue, size), time)
        })
      })
      undoRedo.commitUndo()
    },
    [nodes, time]
  )
  const handleUpdate = React.useCallback(
    (type: 'x' | 'y', value: number): void => {
      if (nodes.length === 0) {
        return
      }

      const project = nodes[0].getProjectOrThrow()
      const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
      const updates = project.getSystemOrThrow(UpdatesSystem)
      updates.batch(() => {
        nodes.forEach((node) => {
          const anchorPoint = getAnchorPoint(node, time)
          setAnchorPoint(
            node,
            {
              x: type === 'x' ? value : anchorPoint.x,
              y: type === 'y' ? value : anchorPoint.y,
            },
            time
          )
        })
      })
      undoRedo.commitUndo()
    },
    [nodes, time]
  )
  const handleDeltaUpdate = React.useCallback(
    (type: 'x' | 'y', value: number): void => {
      if (nodes.length === 0) {
        return
      }

      const project = nodes[0].getProjectOrThrow()
      const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
      const updates = project.getSystemOrThrow(UpdatesSystem)
      updates.batch(() => {
        nodes.forEach((node) => {
          const anchorPoint = getAnchorPoint(node, time)
          setAnchorPoint(
            node,
            {
              x: type === 'x' ? anchorPoint.x + value : anchorPoint.x,
              y: type === 'y' ? anchorPoint.y + value : anchorPoint.y,
            },
            time
          )
        })
      })
      undoRedo.commitUndo()
    },
    [nodes, time]
  )

  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex flex-row items-center justify-start pt-2">
          <buttons.Icon
            onClick={onFreeAnchorPointToggle}
            btnSize={buttonSize}
            className="flex flex-col items-center justify-center w-8 h-8 mr-2"
            active={isFreeAnchorPointEnabled}
            tooltip="Free Anchor Point"
          >
            <icons.FreeAnchorPoint size={iconSize} />
          </buttons.Icon>

          {isFreeAnchorPointEnabled ? (
            <Point2dValue
              components={components}
              iconX={<span>X</span>}
              iconY={<span>Y</span>}
              beforeX={(x) =>
                round((x / getSize(nodes[0]).x) * 100, { fixed: 0 })
              }
              afterX={(x) => (x / 100) * getSize(nodes[0]).x}
              formatValueX={value === mixed ? undefined : (x) => `${x}%`}
              beforeY={(y) =>
                round((y / getSize(nodes[0]).y) * 100, { fixed: 0 })
              }
              afterY={(y) => (y / 100) * getSize(nodes[0]).y}
              formatValueY={value === mixed ? undefined : (y) => `${y}%`}
              onUpdate={handleUpdate}
              onDeltaUpdate={handleDeltaUpdate}
            />
          ) : (
            <AnchorPointUiComponent
              value={value === mixed ? AnchorPointPosition.Custom : value}
              onValueChange={onValueChange}
              className="pb-2"
            />
          )}
        </div>
      }
      rightColumn={null}
    />
  )
}

AnchorPointEditable.displayName = 'AnchorPointEditable'

export const AnchorPointDisplay: React.FC<{
  nodes: Entity[]
  value: AnchorPointPosition | typeof mixed
  components: AnchorPointComponent[]
  isFreeAnchorPointEnabled: boolean
}> = React.memo(
  ({ nodes, value, components, isFreeAnchorPointEnabled }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex flex-row items-center justify-start pt-2">
            <buttons.Icon
              onClick={() => {}}
              btnSize={buttonSize}
              className="flex flex-col items-center justify-center w-8 h-8 mr-2"
              active={isFreeAnchorPointEnabled}
              tooltip="Free Anchor Point"
            >
              <icons.FreeAnchorPoint size={iconSize} />
            </buttons.Icon>

            {isFreeAnchorPointEnabled ? (
              <Point2dValue
                components={components}
                iconX={<span>X</span>}
                iconY={<span>Y</span>}
                beforeX={(x) =>
                  round((x / getSize(nodes[0]).x) * 100, { fixed: 0 })
                }
                afterX={(x) => (x / 100) * getSize(nodes[0]).x}
                formatValueX={value === mixed ? undefined : (x) => `${x}%`}
                beforeY={(y) =>
                  round((y / getSize(nodes[0]).y) * 100, { fixed: 0 })
                }
                afterY={(y) => (y / 100) * getSize(nodes[0]).y}
                formatValueY={value === mixed ? undefined : (y) => `${y}%`}
              />
            ) : (
              <AnchorPointUiComponent
                value={value === mixed ? AnchorPointPosition.Custom : value}
                onValueChange={() => {}}
                className="pb-2"
              />
            )}
          </div>
        }
        rightColumn={null}
      />
    )
  },
  (prev, next) => {
    return (
      prev.value === next.value ||
      prev.isFreeAnchorPointEnabled === next.isFreeAnchorPointEnabled
    )
  }
)

AnchorPointDisplay.displayName = 'AnchorPointDisplay'
