import * as React from 'react'

import { Icons } from '../icons'

export interface IProps {
  inputAvatarUrl: string | null
  avatarUrl: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}
export const AvatarInput: React.FCC<IProps> = ({
  inputAvatarUrl,
  avatarUrl,
  onChange,
}) => (
  <div className="flex flex-row items-center gap-3">
    <div className="flex h-20 w-20 items-center justify-center  overflow-clip rounded-full">
      <img
        crossOrigin="anonymous"
        className="object-fit"
        src={inputAvatarUrl || avatarUrl}
      />
    </div>
    <label className="flex h-10 flex-row items-center gap-2 rounded-lg border-[1px] border-gray-200 px-3 py-2">
      <input
        className="hidden"
        type={'file'}
        accept={'.png,.jpg,.webp'}
        onChange={onChange}
      />
      <Icons.CloudUpload />
      <p className="font-body text-sm font-medium text-gray-500">
        Replace image
      </p>
    </label>
  </div>
)

AvatarInput.displayName = 'AvatarInput'
