import {
  DurationComponent,
  EntityType,
  EntityTypeComponent,
  FpsComponent,
  HashComponent,
  NameComponent,
  Project,
  Root,
} from '@aninix-inc/model'
import { ImagesStore } from '@aninix/core'
import { svgToString } from '@aninix/core/use-cases'
import { generateDefaultSvg } from '@aninix/core/use-cases/generate-svg'
import JsZip from 'jszip'
import { downloadUriAsBlob } from '../helpers'

export async function generateAndDownloadSvgSequence(stores: {
  project: Project
  images: ImagesStore
}): Promise<void> {
  const { project, images } = stores
  const root = project.getEntityByTypeOrThrow(Root)

  await images
    .clean()
    .loadImagesByHashes(
      project
        .getEntitiesByPredicate(
          (e) =>
            e.getComponentOrThrow(EntityTypeComponent).value ===
              EntityType.Paint && e.hasComponent(HashComponent)
        )
        .map((paint) => paint.getComponentOrThrow(HashComponent).value)
    )

  // @NOTE: compress all files into one *.zip
  const zip = new JsZip()
  let idx = 0
  for (
    let i = 0;
    i <= root.getComponentOrThrow(DurationComponent).value;
    i += 1 / root.getComponentOrThrow(FpsComponent).value
  ) {
    const svg = generateDefaultSvg({
      project,
      images: stores.images,
      time: i,
    })

    zip.file(`${idx.toString().padStart(6, '0')}.svg`, svgToString(svg))
    idx += 1
  }

  const archive = await zip.generateAsync({ type: 'blob' })
  downloadUriAsBlob(
    archive,
    `${root.getComponentOrThrow(NameComponent).value}.zip`
  )
  console.log('DONE')
}
