import {
  Ellipse,
  Entity,
  Frame,
  Group,
  Line,
  NameComponent,
  Polygon,
  Rectangle,
  Vector,
} from '@aninix-inc/model'

export const applyName = (entity: Entity) => {
  let name = 'Unknown'
  const project = entity.getProjectOrThrow()

  if (entity instanceof Vector) {
    const count = project.getEntitiesByType(Vector).length
    name = `Vector ${count + 1}`
  }

  if (entity instanceof Rectangle) {
    const count = project.getEntitiesByType(Rectangle).length
    name = `Rectangle ${count + 1}`
  }

  if (entity instanceof Ellipse) {
    const count = project.getEntitiesByType(Ellipse).length
    name = `Ellipse ${count + 1}`
  }

  if (entity instanceof Line) {
    const count = project.getEntitiesByType(Line).length
    name = `Line ${count + 1}`
  }

  if (entity instanceof Polygon) {
    const count = project.getEntitiesByType(Polygon).length
    name = `Polygon ${count + 1}`
  }

  if (entity instanceof Frame) {
    const count = project.getEntitiesByType(Frame).length
    name = `Frame ${count + 1}`
  }

  if (entity instanceof Group) {
    const count = project.getEntitiesByType(Group).length
    name = `Group ${count + 1}`
  }

  entity.updateComponent(NameComponent, name)
}
