import * as React from 'react'

const DEBUG = false

export const PointHandle: React.FCC<{
  point: paper.Point | null
  angle: number | null
  zoom: number
  onMouseDown?: (e: React.MouseEvent<SVGElement>) => void
  onMouseMove?: (e: React.MouseEvent<SVGElement>) => void
  onMouseLeave?: (e: React.MouseEvent<SVGElement>) => void
}> = (props) => {
  const { point, angle, zoom, ...handlers } = props
  if (!point || angle === null) return null

  const hitBoxSize = 16 / zoom

  return (
    <g transform={`rotate(${angle} ${point.x} ${point.y})`}>
      <circle
        cx={point.x}
        cy={point.y}
        r={4 / zoom}
        fill="white"
        filter={`drop-shadow(0 0 ${1 / zoom}px rgba(0, 0, 0, 0.5))`}
      />
      <rect
        x={point.x - hitBoxSize / 2}
        y={point.y - hitBoxSize / 2}
        width={hitBoxSize}
        height={hitBoxSize}
        fill={DEBUG ? '#555aff90' : 'transparent'}
        {...handlers}
      />
    </g>
  )
}
