import { useCurrentFolder } from '@aninix/api'
import classNames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { Icons } from '../icons'

export interface IProps {
  label: string
  folderId?: string
  icon?: React.ReactNode
}
export const Crumb: React.FCC<IProps> = ({ label, folderId, icon = <></> }) => {
  const [isDraggedOver, setIsDraggedOver] = React.useState(false)
  const { setDraggedFolderOverId } = useCurrentFolder()
  return (
    <>
      {folderId && <Icons.ArrowRight />}
      <Link
        to={folderId ? `../projects/${folderId}` : '../projects'}
        className="group"
        onDragOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsDraggedOver(true)
          setDraggedFolderOverId?.(folderId ?? null)
        }}
        onDragLeave={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsDraggedOver(false)
          setDraggedFolderOverId?.(undefined)
        }}
        onDrop={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsDraggedOver(false)
        }}
      >
        <div
          className={classNames(
            'flex flex-row items-center gap-1 border-[1px] border-transparent px-2 py-1 transition-all duration-300',
            {
              [' rounded-lg border-gray-400 bg-gray-100 shadow-inner']:
                isDraggedOver,
            }
          )}
        >
          {icon}
          <p
            className={classNames(
              'font-body text-base text-gray-400 transition-all duration-300 group-hover:text-secondary',
              {}
            )}
          >
            {label}
          </p>
        </div>
      </Link>
    </>
  )
}

Crumb.displayName = 'Crumb'
