import * as React from 'react'

export const MathOpsPointerIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9497 4.94975L6 0L0.343146 5.65685L6 11.3137L10.9497 6.36396C11.3403 5.97344 11.3403 5.34027 10.9497 4.94975Z"
      fill="#0B1118"
    />
  </svg>
)

export const MathOpsIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 3L13 13M13 3L3 13"
      stroke="#1891FB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 8H14.5"
      stroke="#1891FB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.00017 12H8.00684M8 14.5H8.00667"
      stroke="#1891FB"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.5 6.75L4 9.25M4 6.75L1.5 9.25"
      stroke="#1891FB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.99984 1L8 4M6.5 2.5H9.5"
      stroke="#1891FB"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export const MathOpsCloseIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00002 7.29287L12.6465 2.64641L13.3536 3.35351L8.70713 7.99998L13.3536 12.6464L12.6465 13.3535L8.00002 8.70709L3.35359 13.3535L2.64648 12.6464L7.29291 7.99998L2.64649 3.35359L3.35359 2.64648L8.00002 7.29287Z"
      fill="white"
      fillOpacity="0.4"
    />
  </svg>
)
