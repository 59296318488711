import { ApiGetParams, ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function exchangeFigmaAccountId(
  payload: ApiGetParams<'/figma-users/{figmaAccountId}/exchange-id'>['path']
) {
  return httpClient.get<
    ApiGetParams<'/figma-users/{figmaAccountId}/exchange-id'>['path'],
    ApiGetResponse<'/figma-users/{figmaAccountId}/exchange-id'>
  >(`/figma-users/${payload.figmaAccountId}/exchange-id`)
}
