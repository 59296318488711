import { DurationComponent, Root } from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'
import { usePlayback, useProject } from '../../../../../../../stores'

export interface IProps {}
export const PlaybackProgress: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const playback = usePlayback()
  const duration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value

  const { startListen, isListening, offsetX, endAtX } = useMouseMove()

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      // @ts-ignore
      startListen(e)
    },
    []
  )

  const progress = playback.time / duration

  const progressRef = React.useRef<HTMLDivElement>(null)

  const [beenPlaying, setBeenPlaying] = React.useState<boolean | null>(null)

  const managePlaybackOnTimelineInteraction = () => {
    if (isListening && beenPlaying === null) {
      setBeenPlaying(playback.isPlaying)
      if (playback.isPlaying) playback.pause()
    }

    if (!isListening) {
      setBeenPlaying(null)
      if (beenPlaying) playback.play()
    }
  }

  React.useEffect(() => {
    managePlaybackOnTimelineInteraction()

    if (isListening === false) {
      return
    }

    const { left, width } =
      progressRef.current!.parentElement!.getBoundingClientRect()

    playback.updateTime(((endAtX - left) / width) * duration)
  }, [isListening, offsetX, endAtX])

  return (
    <div
      className="absolute inset-0 z-30 hover:cursor-pointer"
      // @ts-ignore
      onMouseDown={handleMouseDown}
    >
      <div
        ref={progressRef}
        //@ts-ignore
        onMouseDown={handleMouseDown}
        className={classNames(
          'z-100 absolute h-[10px] w-[11px] -translate-x-[5px] fill-accent hover:cursor-pointer',
          //so last position sits in timeline
          { ['-translate-x-[6px]']: progress === 1 }
        )}
        style={{
          left: progress * 100 + '%',
          bottom: '0',
        }}
      >
        <svg viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 8.5C15 1 12.5 0 7.5 0S0 1 0 8.5c0 2.407 1.39 3.752 3 5C4.734 14.845 6 16 7.5 16s2.766-1.155 4.5-2.5c1.61-1.248 3-2.593 3-5z" />
        </svg>
      </div>
    </div>
  )
})

PlaybackProgress.displayName = 'PlaybackProgress'
