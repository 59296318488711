import {
  ICommentsGetResponse,
  useGetComments,
} from '@aninix/core/use-cases/comments/use-get-comments'
import { Typography } from 'apps/web-app/src/components/design-system/typography'
import { LoadingSpinner } from 'apps/web-app/src/components/loading-spinner'
import * as React from 'react'

import {
  filter,
  useCommentContext,
} from '@aninix/core/modules/comments-wrapper/use-cases/use-comment-context'
import { CommentListItem } from './comment-list-item'

export interface IProps {
  commentsGetResponse?: ICommentsGetResponse
  searchText?: string
}

export const CommentsList: React.FCC<IProps> = ({ commentsGetResponse }) => {
  const response = commentsGetResponse ?? useGetComments({})
  const { data, status } = response

  const { filterMode, searchText } = useCommentContext()

  //slice for not modifying old array
  const comments = filter(data?.slice().reverse() ?? [], filterMode, searchText)
  const noSearchComments = filter(
    data?.slice().reverse() ?? [],
    filterMode,
    undefined
  )

  if (status === 'error')
    return (
      <Typography className="p-4 text-gray-400" style="Subscript">
        Couldn't load comments. Try again later
      </Typography>
    )

  if (status === 'pending') return <LoadingSpinner />

  //This shouldn't happend at all. Might be better to show "contact support" if user sees that
  if (data === undefined)
    return (
      <Typography
        className="p-4 text-[--figma-color-text-secondary]"
        style="Subscript"
      >
        Something went wrong
      </Typography>
    )

  if (noSearchComments.length === 0)
    return (
      <Typography
        className="p-8 text-center text-[--figma-color-text-secondary]"
        style="Subscript"
      >
        Create your first comment by clicking anywhere on the animation
      </Typography>
    )

  if (noSearchComments.length !== 0 && comments.length === 0)
    return (
      <Typography
        className="p-8 text-center text-[--figma-color-text-secondary]"
        style="Subscript"
      >
        {`"${searchText}" is not found in the comments`}
      </Typography>
    )

  return (
    <div className="flex flex-col gap-0">
      {comments.map((comment, index) => (
        <CommentListItem
          key={comment.id}
          data={comment}
          index={data.findIndex((c) => c.id == comment.id)}
          isLast={index === data.length - 1}
        />
      ))}
    </div>
  )
}

CommentsList.displayName = 'CommentsList'
