import * as React from 'react'

import { IIcon } from '.'

export const LockOpen: React.FCC<IIcon> = ({
  size = { x: 24, y: 24 },
  className = 'stroke-gray-300',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 ${size.x} ${size.y}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 11C5 9.89543 5.89543 9 7 9H17C18.1046 9 19 9.89543 19 11V19C19 20.1046 18.1046 21 17 21H7C5.89543 21 5 20.1046 5 19V11Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="15"
      r="1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9V6C8 3.79086 9.79086 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
