import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const SelectTool: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M13.6827 23L11 10L22.2263 16.7865L16.8252 18.2523L13.6827 23Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeLinecap="square"
    />
  </svg>
)
