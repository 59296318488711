import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { httpClient, setHttpClientToken } from '@aninix/api'
import { useLogger } from '@aninix/logger'
import * as React from 'react'
import * as Rx from 'rxjs'

import {
  ApiGetParams,
  ApiGetResponse,
  ApiPostCreatedResponse,
  ApiPostRequestBody,
} from '@aninix/api/helpers'
import { SessionStore } from '../stores/session'

export interface IUpgradeToProUseCase {
  state: 'not-started' | 'pending' | 'success' | 'error'
  errorMessage?: string
  start: () => Promise<{ url: string } | null>
  startWithPayload: ({
    _teamId,
    userId,
  }: {
    _teamId: string | undefined
    userId: string
    redirectUrlOnSuccess?: string
    redirectUrlOnError?: string
  }) => Promise<{ url: string } | null>
  cancel: () => void
  toggleAnnual: () => void
  isAnnual: boolean
}

function startUserPaymentSession(
  payload: ApiPostRequestBody<'/stripe-payment-sessions/user'>
) {
  return httpClient.post<
    ApiPostCreatedResponse<'/stripe-payment-sessions/user'>
  >('/stripe-payment-sessions/user', payload)
}

function startTeamPaymentSession(
  payload: ApiPostRequestBody<'/stripe-payment-sessions/team'>
) {
  return httpClient.post<
    ApiPostCreatedResponse<'/stripe-payment-sessions/team'>
  >('/stripe-payment-sessions/team', payload)
}

function getPaymentSession(
  payload: ApiGetParams<'/stripe-payment-sessions/{stripePaymentSessionId}'>['path']
) {
  return httpClient.get<
    ApiGetResponse<'/stripe-payment-sessions/{stripePaymentSessionId}'>
  >(`/stripe-payment-sessions/${payload.stripePaymentSessionId}`)
}

type Payload = {
  sessionStore: SessionStore
  teamId?: string
}

export const useUpgradeToPro = ({
  sessionStore,
  teamId,
}: Payload): IUpgradeToProUseCase => {
  const analytics = useAnalytics()
  const logger = useLogger()
  const subscriptionRef = React.useRef<Rx.Subscription | undefined>()
  const [state, setState] =
    React.useState<IUpgradeToProUseCase['state']>('not-started')
  const [errorMessage, setErrorMessage] =
    React.useState<IUpgradeToProUseCase['errorMessage']>(undefined)
  const [isAnnual, setIsAnnual] = React.useState(false)
  // @NOTE: for some reason state doesn't work in useCallback so we have to make turnaround here
  const isAnnualRef = React.useRef(false)

  const toggleAnnual = React.useCallback(() => {
    setIsAnnual((v) => !v)
    isAnnualRef.current = !isAnnualRef.current
  }, [])

  const listenForChanges = React.useCallback((sessionId: string) => {
    subscriptionRef.current = Rx.interval(2000)
      .pipe(
        Rx.tap((ind) => logger.log('[upgradeUserToPro] Interval fire', ind)),
        Rx.switchMap(() =>
          Rx.from(getPaymentSession({ stripePaymentSessionId: sessionId }))
        ),
        Rx.map((result) => result.data),
        Rx.tap((session) =>
          logger.log('[upgradeUserToPro] responded', session)
        ),
        Rx.takeWhile((session) => session.status === 'pending', true)
      )
      .subscribe(async (result) => {
        if (result.status === 'failure') {
          logger.log('[upgradeUserToPro] failure')
          setState('error')
          setErrorMessage('Payment failed')
          return
        }

        /**
         * @description refresh user token on success to receive new token with claims
         * @todo @TODO: EDITOR refactor this block with new payment api
         */
        if (result.status === 'success') {
          logger.log('[upgradeUserToPro] success')
          await sessionStore.refresh()
          setState('success')
          return
        }
      })
  }, [])

  const unsubscribe = React.useCallback(() => {
    if (subscriptionRef.current == null) {
      return
    }

    subscriptionRef.current.unsubscribe()
  }, [])

  const start: IUpgradeToProUseCase['start'] = React.useCallback(async () => {
    setState('pending')

    const result = await (async () => {
      if (teamId != null) {
        const startActivateTeamStripePaymentSession = (
          await startTeamPaymentSession({
            subscriptionPlan: isAnnualRef.current ? 'annual' : 'monthly',
            teamId,
          })
        ).data

        return startActivateTeamStripePaymentSession
      }

      const startUpgradeUserToProStripePaymentSession = (
        await startUserPaymentSession({
          subscriptionPlan: isAnnualRef.current ? 'annual' : 'monthly',
        })
      ).data

      return startUpgradeUserToProStripePaymentSession
    })()

    listenForChanges(result.id)

    analytics.track({
      eventName: AnalyticsEvent.CheckoutSessionRequested,
    })

    return { url: result.paymentUrl }
  }, [analytics, teamId])

  const startWithPayload: IUpgradeToProUseCase['startWithPayload'] =
    React.useCallback(
      async ({ _teamId, userId, redirectUrlOnSuccess, redirectUrlOnError }) => {
        setHttpClientToken(sessionStore.tokens[userId])

        setState('pending')

        const result = await (async () => {
          if (_teamId != null) {
            const startActivateTeamStripePaymentSession = (
              await startTeamPaymentSession({
                subscriptionPlan: isAnnualRef.current ? 'annual' : 'monthly',
                teamId: _teamId,
                redirectUrlOnSuccess,
                redirectUrlOnError,
              })
            ).data

            return startActivateTeamStripePaymentSession
          }

          const startUpgradeUserToProStripePaymentSession = (
            await startUserPaymentSession({
              subscriptionPlan: isAnnualRef.current ? 'annual' : 'monthly',
              redirectUrlOnSuccess,
              redirectUrlOnError,
            })
          ).data

          return startUpgradeUserToProStripePaymentSession
        })()

        listenForChanges(result.id)

        analytics.track({
          eventName: AnalyticsEvent.CheckoutSessionRequested,
        })

        return { url: result.paymentUrl }
      },
      [analytics]
    )

  const cancel: IUpgradeToProUseCase['cancel'] = React.useCallback(() => {
    if (subscriptionRef.current == null) {
      return
    }

    unsubscribe()
    setState('not-started')
  }, [unsubscribe])

  return {
    state,
    errorMessage,
    start,
    startWithPayload,
    cancel,
    toggleAnnual,
    isAnnual,
  }
}
