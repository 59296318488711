import * as React from 'react'

import { IExportInteractor } from './interactor'
import { Render } from './render'
import { ExportView } from './view'

export interface IProps {
  interactor: IExportInteractor
}
export const ExportRouter: React.FCC<IProps> = ({ interactor }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  return (
    <div ref={containerRef} style={{ width: 'max-content' }}>
      <ExportView onClick={interactor.openModal} title="Export" />

      {/* @NOTE: inserted here to avoid props drilling with containerRef */}
      {/* @TODO: refactor */}
      {interactor.children.render && (
        <Render
          anchorEl={containerRef.current}
          onClose={interactor.closeModal}
          project={interactor.project}
        />
      )}
    </div>
  )
}
