import { Logger } from 'packages/logger/src/logger'

import { Clipboard } from './clipboard'

export class LocalClipboard implements Clipboard {
  constructor(
    private readonly dependencies: {
      logger: Logger
    }
  ) {}

  copy: Clipboard['copy'] = async (content) => {
    await this.copyTextToClipboard(content)
  }

  copyCurrentSelection: Clipboard['copyCurrentSelection'] = () => {
    return document.execCommand('copy')
  }

  private fallbackCopyTextToClipboard(text: string) {
    return new Promise<void>((resolve, reject) => {
      const textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'
      textArea.style.zIndex = '10001'
      textArea.id = 'clipboard-service'

      document.body.appendChild(textArea)

      textArea.focus()
      textArea.select()

      try {
        const successful = this.copyCurrentSelection()
        const msg = successful ? 'successful' : 'unsuccessful'
        this.dependencies.logger.log(
          '[clipboard] fallback: Copying text command was ' + msg,
          text
        )
        resolve()
      } catch (err: any) {
        this.dependencies.logger.error(
          '[clipboard] fallback: Oops, unable to copy',
          err
        )
        reject()
      }

      document.body.removeChild(textArea)
    })
  }

  private async copyTextToClipboard(text: string) {
    if (!navigator.clipboard) {
      this.fallbackCopyTextToClipboard(text)
      return
    }

    try {
      await navigator.clipboard.writeText(text)
      this.dependencies.logger.log(
        '[clipboard] Copying text command was successful',
        text
      )
    } catch (err: any) {
      this.dependencies.logger.error('[clipboard] Oops, unable to copy', err)
      this.fallbackCopyTextToClipboard(text)
    }
  }
}
