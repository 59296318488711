import {
  Entity,
  FillsRelationsAspect,
  SolidPaint,
  UpdatesSystem,
  commitUndo,
} from '@aninix-inc/model'
import { PropertyRowV2, buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { Paints, isPaintsMixed } from '../paints'

const btnSize = {
  width: 32,
  height: 32,
}

export const Fills: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { entitiesWithPaints: nodes, time } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) => n.hasAspect(FillsRelationsAspect))

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const paints = filteredNodes.flatMap((n) =>
    n.getAspectOrThrow(FillsRelationsAspect).getChildrenList()
  )

  const isMixed = isPaintsMixed(filteredNodes, FillsRelationsAspect)

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <FillsEditable
          nodes={filteredNodes}
          paints={paints}
          isMixed={isMixed}
        />
      ) : (
        <FillsDisplay paintsLength={paints.length} isMixed={isMixed} />
      )}
    </div>
  )
}

Fills.displayName = 'Fills'

export const FillsEditable: React.FC<{
  nodes: Entity[]
  paints: Entity[]
  isMixed: boolean
}> = ({ nodes, paints, isMixed }) => {
  const project = nodes[0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)

  return (
    <PropertyRowV2
      name="Fill"
      headerButtons={
        <>
          {!isMixed && paints.length > 0 && (
            <buttons.Icon
              onClick={() => {
                updates.batch(() => {
                  nodes.forEach((layer) => {
                    const internalPaints = layer
                      .getAspectOrThrow(FillsRelationsAspect)
                      .getChildrenList()

                    if (internalPaints.length === 0) {
                      return
                    }

                    layer
                      .getAspectOrThrow(FillsRelationsAspect)
                      .removeChild(R.last(internalPaints)!.id)
                  })
                })
                commitUndo(project)
              }}
              btnSize={btnSize}
            >
              <icons.Remove />
            </buttons.Icon>
          )}

          <buttons.Icon
            onClick={() => {
              updates.batch(() => {
                if (isMixed) {
                  nodes.forEach((layer) => {
                    // @TODO: implement FillsComponent.clear() in the model
                    const fills = layer.getAspectOrThrow(FillsRelationsAspect)
                    fills.getChildrenList().forEach((child) => {
                      fills.removeChild(child.id)
                    })

                    // @TODO: implement FillsComponent.create in the model
                    fills.addChild(project.createEntity(SolidPaint))
                  })
                  commitUndo(project)
                  return
                }

                nodes.forEach((layer) => {
                  layer
                    .getAspectOrThrow(FillsRelationsAspect)
                    .addChild(project.createEntity(SolidPaint))
                })
              })
              commitUndo(project)
            }}
            btnSize={btnSize}
          >
            <icons.Add />
          </buttons.Icon>
        </>
      }
      inputs={<Paints aspect={FillsRelationsAspect} />}
      empty={paints.length === 0}
    />
  )
}

FillsEditable.displayName = 'FillsEditable'

interface FillsDisplayProps {
  isMixed: boolean
  paintsLength: number
}

const propsAreEqual = (prev: FillsDisplayProps, next: FillsDisplayProps) => {
  if (prev.isMixed !== next.isMixed) return false
  if (prev.paintsLength !== next.paintsLength) return false
  return true
}

export const FillsDisplay: React.FC<FillsDisplayProps> = React.memo(
  ({ isMixed, paintsLength }) => {
    return (
      <PropertyRowV2
        name="Fill"
        headerButtons={
          <>
            {!isMixed && paintsLength > 0 && (
              <buttons.Icon onClick={() => {}} btnSize={btnSize}>
                <icons.Remove />
              </buttons.Icon>
            )}

            <buttons.Icon onClick={() => {}} btnSize={btnSize}>
              <icons.Add />
            </buttons.Icon>
          </>
        }
        inputs={<Paints aspect={FillsRelationsAspect} />}
        empty={paintsLength === 0}
      />
    )
  },
  propsAreEqual
)

FillsDisplay.displayName = 'FillsDisplay'
