import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { UpgradeToProBanner } from '../upgrade-to-pro-banner'
import { useUserSideMenuInteractor } from './interactor'
import { UserSideMenuRouter } from './router'

export interface IProps {}
export const UserSideMenu: React.FC<IProps> = observer(() => {
  const interactor = useUserSideMenuInteractor()

  return (
    <UserSideMenuRouter
      interactor={interactor}
      upgradeToPro={<UpgradeToProBanner />}
    />
  )
})

UserSideMenu.displayName = 'UserSideMenu'
