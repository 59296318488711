import * as React from 'react'

import kittyCover from './assets/kitty.webp'
import { INotFoundViewInteractor } from './interactor'
import * as styles from './router.scss'

export interface IProps {
  interactor: INotFoundViewInteractor
}
export const NotFoundViewRouter: React.FCC<IProps> = ({ interactor }) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <img
        crossOrigin="anonymous"
        className={styles.cover}
        src={kittyCover}
        alt="Kitty cover"
      />
      <h1 className={styles.title}>Content not found</h1>
      <p className={styles.description}>
        Probably you've got broken url. Ask owner for correct one.
      </p>
    </div>
  </div>
)
