import { icons } from '@aninix/app-design-system'
import { LayerType } from '@aninix/app-design-system/components/common/icons'
import * as React from 'react'

const size = {
  x: 16,
  y: 16,
}

export interface IProps {
  name: string
  type: LayerType
}
export const Title: React.FCC<IProps> = ({ name, type }) => (
  <div className="flex flex-row items-center gap-1 pb-1.5">
    <icons.Layer type={type} size={size} />

    <div
      className="line-clamp-1 font-body text-xs font-normal text-gray-500"
      style={{ width: `calc(100% - 20px)` }}
    >
      {name}
    </div>
  </div>
)

Title.displayName = 'Title'
