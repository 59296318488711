import {
  Entity,
  SolidPaint,
  StrokesRelationsAspect,
  UpdatesSystem,
  commitUndo,
} from '@aninix-inc/model'
import { PropertyRowV2, buttons, icons } from '@aninix/app-design-system'
import { useEntities, useProject } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { Dashes } from '../dashes'
import { Paints, isPaintsMixed } from '../paints'
import { SpecificStrokeWidth, StrokeSide } from '../specific-stroke-width'
import { StrokeCaps } from '../stroke-caps'
import { StrokeWeight } from '../stroke-weight'
import { TrimPath } from '../trim-path'

const btnSize = {
  width: 32,
  height: 32,
}

export const Strokes: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time, nodes } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) => n.hasAspect(StrokesRelationsAspect))

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const paints = filteredNodes.flatMap((l) =>
    l.getAspectOrThrow(StrokesRelationsAspect).getChildrenList()
  )
  const isMixed = isPaintsMixed(filteredNodes, StrokesRelationsAspect)

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <StrokesEditable
          time={time}
          nodes={filteredNodes}
          paints={paints}
          isMixed={isMixed}
        />
      ) : (
        <StrokesDisplay
          time={time}
          nodes={filteredNodes}
          paints={paints}
          isMixed={isMixed}
        />
      )}
    </div>
  )
}

Strokes.displayName = 'Strokes'

export const StrokesEditable: React.FC<{
  time: number
  nodes: Entity[]
  paints: Entity[]

  isMixed: boolean
}> = ({ time, nodes, paints, isMixed }) => {
  const project = useProject()
  const updates = project.getSystemOrThrow(UpdatesSystem)

  return (
    <PropertyRowV2
      name="Stroke"
      headerButtons={
        <>
          {!isMixed && paints.length > 0 && (
            <buttons.Icon
              onClick={() => {
                updates.batch(() => {
                  nodes.forEach((layer) => {
                    const internalPaints = layer
                      .getAspectOrThrow(StrokesRelationsAspect)
                      .getChildrenList()

                    if (internalPaints.length === 0) {
                      return
                    }

                    layer
                      .getAspectOrThrow(StrokesRelationsAspect)
                      .removeChild(R.last(internalPaints)!.id)
                  })
                })
                commitUndo(project)
              }}
              btnSize={btnSize}
            >
              <icons.Remove />
            </buttons.Icon>
          )}

          <buttons.Icon
            onClick={() => {
              updates.batch(() => {
                if (isMixed) {
                  nodes.forEach((layer) => {
                    const strokes = layer.getAspectOrThrow(
                      StrokesRelationsAspect
                    )
                    strokes.clear()
                    strokes.addChild(project.createEntity(SolidPaint))
                  })
                  commitUndo(project)
                  return
                }

                nodes.forEach((layer) => {
                  layer
                    .getAspectOrThrow(StrokesRelationsAspect)
                    .addChild(project.createEntity(SolidPaint))
                })
              })
              commitUndo(project)
            }}
            btnSize={btnSize}
          >
            <icons.Add />
          </buttons.Icon>
        </>
      }
      inputs={
        Boolean(paints.length) && (
          <div className="flex w-full flex-col gap-[8px]">
            <Paints aspect={StrokesRelationsAspect} />
            <StrokeWeight />
            <SpecificStrokeWidth side={StrokeSide.Top} />
            <SpecificStrokeWidth side={StrokeSide.Right} />
            <SpecificStrokeWidth side={StrokeSide.Bottom} />
            <SpecificStrokeWidth side={StrokeSide.Left} />
            <StrokeCaps nodes={nodes} />
          </div>
        )
      }
      wideInputs={
        paints.length === 0 ? undefined : (
          <div className="flex w-full flex-col gap-[8px]">
            <Dashes nodes={nodes} />
            <TrimPath nodes={nodes} />
          </div>
        )
      }
      empty={paints.length === 0}
    />
  )
}

StrokesEditable.displayName = 'StrokesEditable'

interface StrokesDisplayProps {
  time: number
  nodes: Entity[]
  paints: Entity[]
  isMixed: boolean
}

const propsAreEqual = (
  prev: StrokesDisplayProps,
  next: StrokesDisplayProps
) => {
  if (prev.isMixed !== next.isMixed) return false
  if (prev.paints.length !== next.paints.length) return false

  return true
}

export const StrokesDisplay: React.FC<StrokesDisplayProps> = React.memo(
  ({ time, nodes, paints, isMixed }) => {
    return (
      <PropertyRowV2
        name="Stroke"
        headerButtons={
          <>
            {!isMixed && paints.length > 0 && (
              <buttons.Icon onClick={() => {}} btnSize={btnSize}>
                <icons.Remove />
              </buttons.Icon>
            )}

            <buttons.Icon onClick={() => {}} btnSize={btnSize}>
              <icons.Add />
            </buttons.Icon>
          </>
        }
        inputs={
          Boolean(paints.length) && (
            <div className="flex w-full flex-col gap-[8px]">
              <Paints aspect={StrokesRelationsAspect} />
              <StrokeWeight />
              <SpecificStrokeWidth side={StrokeSide.Top} />
              <SpecificStrokeWidth side={StrokeSide.Right} />
              <SpecificStrokeWidth side={StrokeSide.Bottom} />
              <SpecificStrokeWidth side={StrokeSide.Left} />
              <StrokeCaps nodes={nodes} />
            </div>
          )
        }
        wideInputs={
          Boolean(paints.length) && (
            <div className="flex w-full flex-col gap-[8px]">
              <Dashes nodes={nodes} />
              <TrimPath nodes={nodes} />
            </div>
          )
        }
        empty={paints.length === 0}
      />
    )
  },
  propsAreEqual
)

StrokesDisplay.displayName = 'StrokesDisplay'
