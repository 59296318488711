import {
  Entity,
  NodeColorComponent,
  SelectionSystem,
  getDuration,
  getEntryOrThrow,
  getNode,
  getStartTime,
} from '@aninix-inc/model'
import { TimelineTrack } from '@aninix/app-design-system'
import { useSystem } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { defaults } from '../../../../defaults'
import * as timeConverters from '../../../../helpers/timeConverters'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import * as styles from './index.scss'

const handlerWidth = 6

export interface IProps {
  entity: Entity
  // NOTE: required by virtualized list to correctly calculate height
  measure: () => void
  parentTrackWidth: number
}
export const PropertyGroup: React.FCC<IProps> = observer(
  ({ entity, measure, parentTrackWidth }) => {
    const layer = getNode(entity)

    if (layer === undefined) {
      throw new Error('Invalid state. Node not found')
    }

    const isAnyParentSelectedValue = isAnyParentSelected(layer)
    const isLayerSelectedValue = isLayerSelected(layer)
    const isComponentSelectedValue = R.any(
      (component) => isComponentSelected(component),
      entity.components
    )
    const id = entity.id
    const startTime = getStartTime(entity)
    const duration = getDuration(entity)
    const project = entity.getProjectOrThrow()
    const selection = project.getSystemOrThrow(SelectionSystem)
    const entry = getEntryOrThrow(project)
    const projectDuration = getDuration(entry)
    useSystem(selection)

    const convertTimeToPixels = React.useCallback(
      (time: number) =>
        timeConverters.convertTimeToPixels({
          projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
          time,
        }),
      [projectDuration]
    )

    const left = React.useMemo(
      () => convertTimeToPixels(startTime) + handlerWidth,
      [convertTimeToPixels]
    )

    const width = React.useMemo(
      () => convertTimeToPixels(duration),
      [convertTimeToPixels]
    )

    const color = React.useMemo(() => {
      if (
        isAnyParentSelectedValue ||
        isLayerSelectedValue ||
        isComponentSelectedValue
      ) {
        return `${
          defaults.nodeColors[
            layer.getComponentOrThrow(NodeColorComponent).value
          ]
        }10`
      }

      return 'transparent'
    }, [
      layer,
      isAnyParentSelectedValue,
      isLayerSelectedValue,
      isComponentSelectedValue,
    ])

    return (
      <TimelineTrack className={styles.container}>
        <div
          data-id={id}
          className={styles.background}
          style={{
            left,
            width,
            // @ts-ignore
            '--bg-color': color,
          }}
        />
      </TimelineTrack>
    )
  }
)

PropertyGroup.displayName = 'PropertyGroup'
