type PlayEvent = {
  type: 'play'

  /**
   * Actual fps provided by user
   */
  fps: number

  /**
   * Time to start from
   */
  time: number

  /**
   * Speed multiplicator
   * @example 0.5, 2
   */
  speed: number

  /**
   * Min time bound
   */
  min: number

  /**
   * Max time bound
   */
  max: number
}
type TickEvent = {
  type: 'tick'
  time: number
}
type PauseEvent = {
  type: 'pause'
}
type UpdateTimeEvent = {
  type: 'update-time'
  time: number
}

const workerFn = function setupWorker() {
  let intervalId: number
  let time = 0

  self.addEventListener('message', (e) => {
    const data = e.data as PlayEvent | PauseEvent | UpdateTimeEvent

    if (data.type === 'play') {
      time = data.time
      // @ts-ignore
      intervalId = setInterval(() => {
        time += (1 / data.fps) * data.speed

        if (time > data.max) {
          time = data.min
        }

        self.postMessage({
          type: 'tick',
          time,
        })
      }, 1000 / data.fps)
    }

    if (data.type === 'pause') {
      clearInterval(intervalId)
    }

    if (data.type === 'update-time') {
      time = data.time
    }
  })
}

const workerCode = `(${workerFn.toString()})()`

type Thread = {
  onMessage: (callback: (e: MessageEvent<any>) => void) => () => void
  postMessage: (data: any) => Thread
  terminate: () => Thread
}

class CodeThread implements Thread {
  private worker: Worker

  constructor(code: string) {
    const blob = new Blob([code], { type: 'application/javascript' })
    const blobUrl = URL.createObjectURL(blob)
    this.worker = new Worker(blobUrl)
  }

  onMessage: Thread['onMessage'] = (callback) => {
    this.worker.addEventListener('message', callback)
    return () => {
      this.worker.removeEventListener('message', callback)
    }
  }

  postMessage: Thread['postMessage'] = (message) => {
    this.worker.postMessage(message)
    return this
  }

  terminate: Thread['terminate'] = () => {
    this.worker.terminate()
    return this
  }
}

/**
 * Ticker allowed to properl update time. It load worker and return provided world time on the constant fps ticks.
 * Similar approach is using in game dev when we have update loop and world time.
 */
export class Ticker {
  private _onTick?: (time: number) => void

  private thread: Thread

  private queued: boolean = false

  constructor() {
    this.thread = new CodeThread(workerCode)
    this.thread.onMessage((e) => {
      const data = e.data as TickEvent

      if (this.queued) {
        return
      }

      this.queued = true
      requestAnimationFrame(() => {
        this._onTick?.(data.time)
        this.queued = false
      })
    })
  }

  public dispose = () => {
    this.thread.terminate()
  }

  public play = (payload: Omit<PlayEvent, 'type'>) => {
    this.pause()
    const message: PlayEvent = {
      type: 'play',
      time: payload.time,
      fps: payload.fps,
      speed: payload.speed,
      min: payload.min,
      max: payload.max,
    }
    this.thread.postMessage(message)
  }

  public pause = () => {
    const message: PauseEvent = {
      type: 'pause',
    }
    this.thread.postMessage(message)
  }

  public onTick = (callback: (time: number) => void) => {
    this._onTick = callback
  }

  public updateTime = (time: number): this => {
    const message: UpdateTimeEvent = {
      type: 'update-time',
      time,
    }
    this.thread.postMessage(message)
    return this
  }
}
