import * as React from 'react'

import { Locked } from './Locked'
import { Normal } from './Normal'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  locked: boolean
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Link: React.FC<IProps> = ({
  locked,
  size = defaultSize,
  style,
}) =>
  locked ? (
    <Locked size={size} style={style} />
  ) : (
    <Normal size={size} style={style} />
  )
