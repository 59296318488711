import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Mask: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.86 3.646a4.5 4.5 0 110 8.709A5.49 5.49 0 009 8a5.49 5.49 0 00-2.14-4.354zM8 2.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
