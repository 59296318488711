import { commitUndo, Component } from '@aninix-inc/model'
import { useProject } from '@aninix/core'
import * as React from 'react'
import { useInfo } from '../..'
import { useValueSpatialPoint2d } from '../../../../../hooks/value-controllers/spatial-point-2d'
import { NumericValue } from '../numeric-value'

export interface IProps {
  component: Component
  color?: string
  formatValue?: (value: number) => string
}
export const SpatialPoint2dValue: React.FC<IProps> = ({
  component,
  color = 'black',
  formatValue,
}) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time } = useInfo()
  const { x, y, updateX, updateY } = useValueSpatialPoint2d({
    components: [component],
    time,
  })

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div className="flex flex-row" onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <SpatialPoint2dValueEditable
          component={component}
          x={x as number}
          y={y as number}
          updateX={updateX}
          updateY={updateY}
          formatValue={formatValue}
          color={color}
        />
      ) : (
        <SpatialPoint2dValueDisplay
          x={x as number}
          y={y as number}
          color={color}
          formatValue={formatValue}
        />
      )}
    </div>
  )
}

SpatialPoint2dValue.displayName = 'SpatialPoint2dValue'

const SpatialPoint2dValueEditable: React.FC<{
  component: Component
  x: number
  y: number
  updateX: (value: number) => void
  updateY: (value: number) => void
  color?: string
  formatValue?: (value: number) => string
}> = ({ component, x, y, updateX, updateY, color, formatValue }) => {
  const project = useProject()

  const onEndChange = React.useCallback(() => {
    commitUndo(project)
  }, [component])

  return (
    <>
      <NumericValue
        onEndChange={onEndChange}
        suffix=", "
        value={x as number}
        onChange={updateX}
        textColor={color}
        format={formatValue}
      />
      <NumericValue
        onEndChange={onEndChange}
        value={y as number}
        onChange={updateY}
        textColor={color}
        format={formatValue}
      />
    </>
  )
}
SpatialPoint2dValueEditable.displayName = 'SpatialPoint2dValueEditable'

const SpatialPoint2dValueDisplay: React.FC<{
  x: number
  y: number
  color?: string
  formatValue?: (value: number) => string
}> = React.memo(
  ({ x, y, color, formatValue }) => {
    return (
      <>
        <NumericValue
          onEndChange={() => {}}
          suffix=", "
          value={x as number}
          onChange={() => {}}
          textColor={color}
          format={formatValue}
        />
        <NumericValue
          onEndChange={() => {}}
          value={y as number}
          onChange={() => {}}
          textColor={color}
          format={formatValue}
        />
      </>
    )
  },
  (prev, next) => {
    if (prev.x !== next.x) return false
    if (prev.y !== next.y) return false
    if (prev.color !== next.color) return false
    return true
  }
)
SpatialPoint2dValueDisplay.displayName = 'SpatialPoint2dValueDisplay'
