import {
  EntityType,
  SelectionSystem,
  TimeComponent,
  segmentsFromKeyframes,
} from '@aninix-inc/model'
import classNames from 'classnames'
import hljs from 'highlight.js/lib/core'
import cssLanguage from 'highlight.js/lib/languages/css'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import * as React from 'react'
import { CssCodeBlock } from '../../../../../../../../modules/inspector/css-code-block'
import { useProject } from '../../../../../../../../stores'
import { useSystem } from '../../../../../../../../updates'
import { NoSegmentsToInspect } from '../no-segments-to-inspect'
import { Check } from './check'
import { Copy } from './copy'
import './highlight.css'
import { InspectIcon } from './inspect-icon'

hljs.registerLanguage('css', cssLanguage)

const iconSize = {
  x: 20,
  y: 20,
}

export interface IIcon {
  size?: { x: number; y: number }
  color?: string
  className?: string
}

export const Icons = {
  Check,
  Copy,
  InspectIcon,
}

export interface IProps {
  language?: 'css'
}

export const InspectCode: React.FCC<IProps> = observer(
  ({ language = 'css' }: IProps): JSX.Element => {
    const project = useProject()
    const selection = project.getSystemOrThrow(SelectionSystem)
    useSystem(selection)

    const [showCheckmark, setShowCheckmark] = React.useState<boolean>(false)

    React.useEffect(() => {
      if (showCheckmark)
        setTimeout(() => {
          setShowCheckmark(false)
        }, 1300)
    }, [showCheckmark])

    const segments = segmentsFromKeyframes(
      R.sortBy(
        (e) => e.getComponentOrThrow(TimeComponent).value,
        selection.getEntitiesByEntityType(EntityType.Keyframe)
      )
    )
    const code = segments.length > 0 ? new CssCodeBlock(segments).asCode() : ''

    const handleClick = React.useCallback(() => {
      navigator.clipboard.writeText(code)
      setShowCheckmark(true)
    }, [code])

    const highlighted = language
      ? hljs.highlight(language, code)
      : hljs.highlightAuto(code)

    if (segments.length === 0) {
      return <NoSegmentsToInspect selectedTab="code" />
    }

    return (
      <div className="flex h-full cursor-text select-text flex-col gap-3 overflow-hidden px-4 pt-3 selection:bg-[#374FD5] selection:text-white">
        <div className="flex w-full flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <Icons.InspectIcon />
            <p className="font-body text-xs font-normal text-secondary">CSS</p>
          </div>
          <div
            className="relative w-4 hover:cursor-pointer"
            onClick={handleClick}
          >
            <Icons.Copy
              size={iconSize}
              className={classNames(
                'absolute h-5 w-5 !stroke-gray-500  transition-all duration-200',
                {
                  ['invisible opacity-0']: showCheckmark,
                }
              )}
            />
            <div
              className={classNames(
                'absolute right-0 flex -translate-y-[8px] translate-x-[1px]  flex-row items-center gap-1  transition-all duration-200',
                { ['invisible opacity-0']: !showCheckmark }
              )}
            >
              <p
                className={classNames(
                  'origin-right rounded bg-[#0B1118] bg-opacity-90 px-3 py-2 text-[11px] text-white transition-all duration-200',
                  { ['invisible scale-0']: !showCheckmark }
                )}
              >
                Copied!
              </p>
              <Icons.Check
                size={iconSize}
                className="h-5 w-5 rounded-full bg-[#0B1118]  bg-opacity-90 stroke-gray-100 p-1"
              />
            </div>
          </div>
        </div>

        <pre className="hljs w-full overflow-scroll pb-4">
          <code
            className="hljs select-text"
            dangerouslySetInnerHTML={{ __html: highlighted.value }}
          />
        </pre>
      </div>
    )
  }
)

InspectCode.displayName = 'InspectCode'
