import {
  BackgroundBlur,
  BlurRadiusComponent,
  DropShadow,
  EffectsRelationsAspect,
  Entity,
  InnerShadow,
  LayerBlur,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
  ShadowSpreadComponent,
} from '@aninix-inc/model'
import { INode } from 'svgson'
import { Filter } from '../../types'

export const applyFilters = (
  entity: Entity,
  node: INode,
  filters: Record<string, Filter[]>
) => {
  const { filter } = node.attributes
  if (!filter || !filter.includes('url')) return

  const filterId = filter.substring(5, filter.length - 1)
  if (!filters[filterId]?.length) return

  const project = entity.getProjectOrThrow()

  for (const filter of filters[filterId]) {
    if (filter.type === 'shadow') {
      const { radius, x, y, color, direction, spread } = filter
      const effectsAspect = entity.createAspectIfNotExists(
        EffectsRelationsAspect
      )

      let shadow = project.createEntity(
        direction === 'in' ? InnerShadow : DropShadow
      )

      shadow.updateComponent(ShadowRadiusComponent, radius)
      shadow.updateComponent(ShadowOffsetComponent, { x, y })
      shadow.updateComponent(ShadowColorComponent, color)
      if (spread) shadow.updateComponent(ShadowSpreadComponent, spread)

      effectsAspect.addChild(shadow)
    }

    if (filter.type === 'blur') {
      const { radius, blurType } = filter
      const effectsAspect = entity.createAspectIfNotExists(
        EffectsRelationsAspect
      )

      const blur = project.createEntity(
        blurType === 'layer' ? LayerBlur : BackgroundBlur
      )
      blur.updateComponent(BlurRadiusComponent, radius)
      effectsAspect.addChild(blur)
    }
  }
}
