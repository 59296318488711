import React from 'react'

import { IIcon } from '.'

export const DeviceDesktop: React.FCC<IIcon> = ({
  size = { x: 24, y: 24 },
  className = '',
}) => (
  <svg
    fill="none"
    className={className}
    width="24"
    height="24"
    viewBox={`0 0 24 24`}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.16146 3H17.8385C18.3657 2.99998 18.8205 2.99997 19.195 3.03057C19.5904 3.06287 19.9836 3.13419 20.362 3.32698C20.9265 3.6146 21.3854 4.07354 21.673 4.63803C21.8658 5.01641 21.9371 5.40963 21.9694 5.80497C22 6.17954 22 6.6343 22 7.16144V12.8386C22 13.3657 22 13.8205 21.9694 14.195C21.9371 14.5904 21.8658 14.9836 21.673 15.362C21.3854 15.9265 20.9265 16.3854 20.362 16.673C19.9836 16.8658 19.5904 16.9371 19.195 16.9694C18.8205 17 18.3657 17 17.8385 17H6.16148C5.63432 17 5.17955 17 4.80497 16.9694C4.40963 16.9371 4.01641 16.8658 3.63803 16.673C3.07354 16.3854 2.6146 15.9265 2.32698 15.362C2.13419 14.9836 2.06287 14.5904 2.03057 14.195C1.99997 13.8205 1.99998 13.3657 2 12.8385V7.16146C1.99998 6.63431 1.99997 6.17955 2.03057 5.80497C2.06287 5.40963 2.13419 5.01641 2.32698 4.63803C2.6146 4.07354 3.07354 3.6146 3.63803 3.32698C4.01641 3.13419 4.40963 3.06287 4.80497 3.03057C5.17955 2.99997 5.63431 2.99998 6.16146 3ZM4.96784 5.02393C4.69617 5.04612 4.59546 5.0838 4.54601 5.109C4.35785 5.20487 4.20487 5.35785 4.109 5.54601C4.0838 5.59546 4.04612 5.69617 4.02393 5.96784C4.00078 6.25117 4 6.62345 4 7.2V12.8C4 13.3766 4.00078 13.7488 4.02393 14.0322C4.04612 14.3038 4.0838 14.4045 4.109 14.454C4.20487 14.6422 4.35785 14.7951 4.54601 14.891C4.59546 14.9162 4.69617 14.9539 4.96784 14.9761C5.25117 14.9992 5.62345 15 6.2 15H17.8C18.3766 15 18.7488 14.9992 19.0322 14.9761C19.3038 14.9539 19.4045 14.9162 19.454 14.891C19.6422 14.7951 19.7951 14.6422 19.891 14.454C19.9162 14.4045 19.9539 14.3038 19.9761 14.0322C19.9992 13.7488 20 13.3766 20 12.8V7.2C20 6.62345 19.9992 6.25118 19.9761 5.96784C19.9539 5.69617 19.9162 5.59546 19.891 5.54601C19.7951 5.35785 19.6422 5.20487 19.454 5.109C19.4045 5.0838 19.3038 5.04612 19.0322 5.02393C18.7488 5.00078 18.3766 5 17.8 5H6.2C5.62345 5 5.25117 5.00078 4.96784 5.02393Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 20C8 19.4477 8.44772 19 9 19H15C15.5523 19 16 19.4477 16 20C16 20.5523 15.5523 21 15 21H9C8.44772 21 8 20.5523 8 20Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15C12.5523 15 13 15.4477 13 16V20C13 20.5523 12.5523 21 12 21C11.4477 21 11 20.5523 11 20V16C11 15.4477 11.4477 15 12 15Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 7C19 7.55228 18.5523 8 18 8L14 8C13.4477 8 13 7.55228 13 7C13 6.44771 13.4477 6 14 6L18 6C18.5523 6 19 6.44772 19 7Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 13C17 13.5523 16.5523 14 16 14L14 14C13.4477 14 13 13.5523 13 13C13 12.4477 13.4477 12 14 12L16 12C16.5523 12 17 12.4477 17 13Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 10C16 10.5523 15.5523 11 15 11L14 11C13.4477 11 13 10.5523 13 10C13 9.44772 13.4477 9 14 9L15 9C15.5523 9 16 9.44772 16 10Z"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 10C12 10.3513 11.8157 10.6768 11.5145 10.8575L6.5145 13.8575C6.20556 14.0429 5.82081 14.0477 5.5073 13.8702C5.19379 13.6927 5 13.3603 5 13L5 7C5 6.63973 5.19379 6.30731 5.5073 6.1298C5.82081 5.95229 6.20556 5.95715 6.51449 6.14251L11.5145 9.14251C11.8157 9.32323 12 9.64874 12 10ZM9.05635 10L7 8.76619L7 11.2338L9.05635 10Z"
      fill="black"
    />
  </svg>
)
