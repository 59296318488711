import classNames from 'classnames'
import * as React from 'react'

export interface IProps {
  value: string
  onChange: (value: string) => void
  isMultiline: boolean

  isHtmlFormattedText?: boolean
}
export const TextInput: React.FCC<IProps> = ({
  value,
  onChange,
  isMultiline,
  isHtmlFormattedText = false,
}) => {
  const inputRef = React.useRef<HTMLDivElement>(null)

  const handleChange = (e?: React.FormEvent<HTMLDivElement>) => {
    if (!isHtmlFormattedText) {
      onChange(e?.currentTarget.innerText ?? '')
      isMultiline && handleAutoResize(e)
      return
    }

    let html = e?.currentTarget.innerHTML ?? ''

    html = html.replace(/<b>/g, '<span style="font-weight: 600">')
    html = html.replace(/<\/b>/g, '</span>')

    const nestedSpansRegex =
      /(<span([^>]*)>)([^<]*)<span([^>]*)>(.*?)<\/span>([^<]*)<\/span>/g

    if (nestedSpansRegex.test(html)) {
      nestedSpansRegex.lastIndex = 0
      const flattenedHtml = html.replaceAll(
        nestedSpansRegex,
        (
          _,
          outerSpanFull,
          outerSpanAttrs,
          beforeText,
          innerSpanAttrs,
          innerContent,
          afterText
        ) => {
          return `<span${outerSpanAttrs}>${beforeText}</span><span${innerSpanAttrs}>${innerContent}</span><span${outerSpanAttrs}>${afterText}</span>`
        }
      )
      onChange(flattenedHtml)
    } else {
      onChange(html)
    }
    handleAutoResize(e)
  }

  const handleAutoResize = (e?: React.FormEvent<HTMLDivElement>) => {
    if (!inputRef || !inputRef.current) return

    if (!isMultiline) {
      inputRef.current.innerHTML = clearNewLines(inputRef.current.innerHTML)
      return
    }

    const element = inputRef.current
    element.style.height = '1px'
    element.style.height = `${element.scrollHeight + 4}px`
  }

  React.useEffect(() => {
    if (isMultiline) handleAutoResize()
  }, [])

  const defaultValue = isMultiline ? value : clearNewLines(value)

  return (
    <div
      contentEditable
      ref={inputRef}
      onInput={handleChange}
      className={classNames(
        'resize-none w-full  font-body text-sm font-normal py-1.5 px-2 rounded-md bg-gray-500/10',
        { ['!h-[32px]']: !isMultiline, ['h-max']: isMultiline }
      )}
      dangerouslySetInnerHTML={{ __html: defaultValue }}
    />
  )
}

const clearNewLines = (value: string) => value.replaceAll('\n', ' ')

TextInput.displayName = 'TextInput'
