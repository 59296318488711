import * as React from 'react'

import * as styles from './index.scss'

export interface IProps {
  leftColumn: React.ReactNode
  rightColumn: React.ReactNode
}
export const CompactPropertyRow: React.FCC<IProps> = ({
  leftColumn,
  rightColumn,
}) => (
  <div className={styles.container}>
    <div className={styles.left}>{leftColumn}</div>

    <div className={styles.right}>{rightColumn}</div>
  </div>
)
