import * as React from 'react'

import { Effect } from './effect'
import { In } from './in'
import { Out } from './out'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  type: 'effect' | 'in' | 'out'
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const PresetType: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  if (type === 'effect') {
    return <Effect size={size} style={style} />
  }

  if (type === 'in') {
    return <In size={size} style={style} />
  }

  return <Out size={size} style={style} />
}
