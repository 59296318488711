import * as React from 'react'

export interface IProps {}
export const Medias: React.FCC<IProps> = () => {
  return null

  // const {
  //   openedPaneles,
  //   updateOpenedPanels,
  //   data: { snapshot },
  // } = useOverride()

  // const data = snapshot.filter(
  //   (entity) => entity.type === 'media'
  // ) as MediaType[]

  // if (data === undefined || data.length === 0) return null

  // return (
  //   <div>
  //     <ComponentGroupPanel
  //       isOpen={openedPaneles.medias}
  //       toggleOpen={() => updateOpenedPanels({ medias: !openedPaneles.medias })}
  //       label="Media"
  //     >
  //       <div className="flex flex-col gap-1">
  //         {data.map((item) => (
  //           <Media key={item.id} data={item} />
  //         ))}
  //       </div>
  //     </ComponentGroupPanel>
  //   </div>
  // )
}

Medias.displayName = 'Medias'
