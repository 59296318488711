import { paper } from '@aninix-inc/renderer'

function getPart(payload: {
  path: paper.Path
  start: number
  end: number
}): paper.Path {
  const { path, start, end } = payload

  let internalPath = path.clone()

  const length = internalPath.length

  if (internalPath.closed) {
    internalPath.splitAt(0)
  }

  if (end <= length) {
    // @NOTE: after second split we get the left part
    internalPath.splitAt(end)
  }

  // @NOTE: after first split we get the right part
  const trimmedPart = internalPath.splitAt(start)

  return trimmedPart
}

export function makeDashedPath(
  path: string,
  dashLength: number,
  gapLength: number,
  dashOffset: number = 0
) {
  let originalPath = paper.Path.create(path)

  var dashedPaths: string[] = []

  let pathsToIterate =
    originalPath instanceof paper.Path
      ? [originalPath]
      : (originalPath.children.filter(
          (e) => e instanceof paper.Path
        ) as paper.Path[])

  pathsToIterate.forEach((path) => {
    var length = path.length
    var currentPos =
      dashOffset >= 0 ? dashOffset : -dashOffset % (gapLength + dashLength)
    while (currentPos < length) {
      var from = currentPos
      currentPos += dashLength === 0 ? length : dashLength
      if (currentPos > length) {
        currentPos = length
      }
      var to = currentPos

      const part = getPart({
        path,
        start: from,
        end: to,
      })

      if (part) dashedPaths.push(part.pathData)

      currentPos += gapLength
    }
  })

  return dashedPaths
}
