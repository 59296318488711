import { Entity } from '@aninix-inc/model'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ImagesStore } from '../../stores'
import { AnimatedProperties } from './animated-properties'
import { StaticSvg } from './static'

export interface IProps {
  entity: Entity
  images: ImagesStore
  timeForPreview: number
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
export const AnimatedSvg: React.FCC<IProps> = observer(
  ({ entity, timeForPreview, images, timeRange, startEvent, endEvent }) => {
    return (
      <StaticSvg time={timeForPreview} entity={entity} images={images}>
        <AnimatedProperties
          entity={entity}
          images={images}
          timeRange={timeRange}
          startEvent={startEvent}
          endEvent={endEvent}
        />
      </StaticSvg>
    )
  }
)

AnimatedSvg.displayName = 'AnimatedSvg'
