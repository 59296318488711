// @TODO: FIXME implement
import { Project } from '@aninix-inc/model'
import { httpClient } from '@aninix/api'
import { config } from '@aninix/api/config'
import * as React from 'react'

export interface PostProjectHistoryVersionRequest {
  (payload: { projectId: string }): {
    result: GetProjectHistoryVersionResult
    createNewVersion: (
      projectData: Project,
      options?: {
        emitEvent: boolean
      }
    ) => Promise<void>
  }
}

export interface GetProjectHistoryVersionResult {
  data: any | null
  isError: boolean
  isLoading: boolean
}

export const usePostProjectHistoryVersion: PostProjectHistoryVersionRequest = ({
  projectId,
}) => {
  const [result, setResult] = React.useState<GetProjectHistoryVersionResult>({
    data: null,
    isLoading: true,
    isError: false,
  })

  const createNewVersion: ReturnType<PostProjectHistoryVersionRequest>['createNewVersion'] =
    React.useCallback(async (projectData, options) => {
      setResult({
        data: result.data,
        isLoading: true,
        isError: false,
      })

      httpClient
        .post<GetProjectHistoryVersionResult['data']>(
          `${config.apiUrl}/projects/${projectId}/versions`,
          {
            data: projectData.toJSON(),
            emitEvent: options?.emitEvent,
          }
        )
        .then((value) =>
          setResult({
            data: value.data,
            isLoading: false,
            isError: false,
          })
        )
        .catch((e) =>
          setResult({ data: null, isLoading: false, isError: true })
        )
    }, [])

  return { result, createNewVersion }
}
