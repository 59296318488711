import { GenericKeyframe, Node } from '@aninix-inc/model/legacy'
import { makeAutoObservable } from 'mobx'
import * as React from 'react'

export const SelectionType = {
  Keyframe: 'KEYFRAME',
  Node: 'NODE',
} as const

export enum KeyModificator {
  Shift = 'shift',
  Alt = 'alt',
  Ctrl = 'ctrl',
}

type KeyframeSelection = {
  type: typeof SelectionType.Keyframe
  keyframe: GenericKeyframe
  id: string
}

type NodeSelection = {
  type: typeof SelectionType.Node
  node: Node
  id: string
}

// type PaintSelection = {
//   type: SelectionType.Paint,
//   id: string
// }

/**
 * @description selected nodes/segments/keyframes
 * and pressed key modificators
 */
export class Session {
  /**
   * @description node id used to highlight different nodes
   */
  public buffer: string = ''

  public keyModificators: KeyModificator[] = []

  constructor() {
    makeAutoObservable(this)
  }

  setBuffer = (payload: string) => {
    this.buffer = payload
    return this
  }

  cleanBuffer = () => {
    this.buffer = ''
    return this
  }

  keyDown = (key: KeyModificator) => {
    if (this.keyModificators.includes(key)) {
      return
    }

    this.keyModificators.push(key)
  }

  keyUp = (key: KeyModificator) => {
    const idx = this.keyModificators.indexOf(key)

    if (idx === -1) {
      return
    }

    this.keyModificators.splice(idx, 1)
  }
}

const Context = React.createContext<Session>(null as any)

export const useSession = (): Session => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'Session context not found. Use SessionProvider at the root component.'
    )
  }

  return context
}

export const SessionProvider: React.FCC<{ store: Session }> = ({
  store,
  children,
}) => <Context.Provider value={store}>{children}</Context.Provider>
