import { CurveStyle, NameComponent } from '@aninix-inc/model'
import trim from 'lodash/trim'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

import { useEntities } from '@aninix/core'
import { IAddPathProps, TNormalizedUserPreset } from '.'
import * as styles from './index.scss'
import UserGroupedPresets from './user-grouped-presets'

function formatName(name: string): string {
  return trim(R.last(name.split('/')))
}

// @TODO refactor
function addPath({ splittedArray, acc, value }: IAddPathProps) {
  const file = trim(splittedArray.shift())
  let existed = acc.find(
    (item: any) => item.name.toLowerCase() === file?.toLowerCase()
  )

  if (!existed && file) {
    existed = { name: file }
    acc.push(existed)
  }

  if (existed && !splittedArray.length) {
    existed.value = value
  }

  if (splittedArray.length && existed) {
    addPath({
      splittedArray,
      acc: existed.children || (existed.children = []),
      value,
    })
  }

  return R.sortBy(R.prop('name'))(acc)
}

export interface IProps {
  curveStyles: CurveStyle[]
  onEditPreset: (presetId: string) => void
  onApply: (presetId: string) => void
  selectedCurveStyleId?: string
  openAllGroupsByDefault?: boolean
}

/**
 * @description curve styles used in project's plugin
 * @todo remove model from app-design-system. It should receive pure objects.
 */
export const CurveStyles: React.FCC<IProps> = observer(
  ({
    curveStyles,
    onEditPreset,
    onApply,
    selectedCurveStyleId,
    openAllGroupsByDefault = false,
  }) => {
    useEntities(curveStyles)
    const [openGroups, setOpenGroups] = React.useState<string[]>([])

    const toggleOpenTracks = (groupName: string) => {
      if (openGroups.includes(groupName)) {
        const newOpenTracks = R.remove(
          R.indexOf(groupName, openGroups),
          1,
          openGroups
        )
        setOpenGroups(newOpenTracks)
      }
      if (!openGroups.includes(groupName)) {
        setOpenGroups([...openGroups, groupName])
      }
    }

    const normalizedCurveStyles = curveStyles.reduce<TNormalizedUserPreset[]>(
      (acc, file) =>
        addPath({
          splittedArray: file
            .getComponentOrThrow(NameComponent)
            .value.split('/'),
          acc,
          value: file,
        }),
      []
    )

    // @NOTE: required to open all folders by default
    React.useEffect(() => {
      if (openAllGroupsByDefault === false) {
        return
      }

      setOpenGroups(
        normalizedCurveStyles
          .filter((style) => style.children != null)
          .map((style) => style.name)
      )
    }, [openAllGroupsByDefault])

    if (normalizedCurveStyles.length === 0) {
      return <div className={styles.empty}>No Curve Styles.</div>
    }

    return (
      <div>
        {normalizedCurveStyles.map((preset) => (
          <UserGroupedPresets
            key={preset.value?.id || preset.name}
            preset={preset}
            openGroups={openGroups}
            onOpen={toggleOpenTracks}
            onEditPreset={onEditPreset}
            onApply={onApply}
            selectedName={formatName(
              curveStyles
                .find((curveStyle) => curveStyle.id === selectedCurveStyleId)
                ?.getComponentOrThrow(NameComponent).value || ''
            )}
          />
        ))}
      </div>
    )
  }
)

CurveStyles.displayName = 'CurveStyles'
