import * as React from 'react'

const defaultSize = { x: 14, y: 13 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const HorizontalLeftOutside: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 13V12.5L10 0.5V0H10.5H14V1L11 1L11 12L14 12V13L10.5 13H10ZM0 5H8V3H0V5ZM8 10H3V8H8V10Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
