import * as React from 'react'

export interface IProps {}
export const AninixIcon: React.FCC<IProps> = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="48" height="48" rx="24" fill="#374FD5" />
    <path
      d="M31.9688 23.5781L20.8594 33.4688V15.1875L16.9219 17.2504V37.0781L19.4062 39.0938L37.6875 22.6875L19.125 7.96875L15.1875 10.0312L31.9688 23.5781Z"
      fill="white"
    />
  </svg>
)

AninixIcon.displayName = 'AninixIcon'
