import { useMutation } from '@tanstack/react-query'

import { ApiPostCreatedResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetAnonymousToken() {
  return useMutation({
    async mutationFn() {
      return httpClient.post<ApiPostCreatedResponse<'/tokens'>>(
        '/tokens',
        undefined
      )
    },
  })
}

export function getAnonymousToken() {
  return httpClient.post<ApiPostCreatedResponse<'/tokens'>>(
    '/tokens',
    undefined
  )
}
