import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Lottie: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 3H13V13H3L3 3ZM2 3C2 2.44772 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V13C14 13.5523 13.5523 14 13 14H3C2.44772 14 2 13.5523 2 13V3ZM11.5 4.5C10.4573 4.5 9.67894 4.95582 9.06338 5.58896C8.47368 6.19551 8.01036 6.98985 7.5852 7.71877L7.56811 7.74806C7.12295 8.5112 6.71577 9.20364 6.21963 9.71396C5.74144 10.2058 5.20734 10.5 4.5 10.5V11.5C5.54266 11.5 6.32106 11.0442 6.93662 10.411C7.52632 9.80449 7.98964 9.01015 8.4148 8.28123L8.43189 8.25194C8.87705 7.4888 9.28423 6.79636 9.78037 6.28604C10.2586 5.79418 10.7927 5.5 11.5 5.5V4.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
