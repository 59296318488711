import { RGBA } from '@aninix-inc/model/legacy'
import * as React from 'react'
import tinycolor from 'tinycolor2'

import { IconButton } from '../buttons/icon-button'
import { ColorDropper } from '../icons'

interface IEyeDropperResult {
  sRGBHex: string
}

export interface IProps {
  onChange: (color: RGBA) => void
  onError?: (e: Error) => void
}
export const EyeDropper: React.FCC<IProps> = ({ onChange, onError }) => {
  const handleClick = React.useCallback(async () => {
    // @ts-ignore
    if (!window.EyeDropper) {
      if (onError) {
        onError(new Error('EyeDropper is not supported'))
      }
      return
    }

    // @ts-ignore
    const eyeDropper = new window.EyeDropper()

    try {
      const result: IEyeDropperResult = await eyeDropper.open()
      const newColor = result.sRGBHex as string
      onChange(tinycolor(newColor).toRgb())
    } catch (e: any) {
      if (onError) {
        onError(e)
      }
    }
  }, [onChange, onError])

  return (
    // @TODO: enable
    <IconButton onClick={handleClick} disabled>
      <ColorDropper />
    </IconButton>
  )
}
