import * as React from 'react'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Opacity: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle
      cx="6"
      cy="10"
      r="4"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M12 9.46488C13.1956 8.77326 14 7.48057 14 6C14 3.79086 12.2092 2 10 2C8.51946 2 7.22678 2.8044 6.53516 4"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeOpacity="0.3"
      strokeDasharray="2 1"
    />
  </svg>
)
