import {
  ColorStopsRelationsAspect,
  Entity,
  PaintType,
  PaintTypeComponent,
  ProgressComponent,
  RgbaValueComponent,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { getKeyframesType } from './getKeyframeType'

export const getPaintsKeyframesType = (paints: Entity[], time: number) => {
  const types: PropertyKeyframesType[] = []
  for (const paint of paints) {
    const type = paint.getComponentOrThrow(PaintTypeComponent).value
    switch (type) {
      case PaintType.Solid: {
        types.push(
          getKeyframesType(
            [paint.getComponentOrThrow(RgbaValueComponent)],
            time
          )
        )
        continue
      }
      case PaintType.GradientLinear: {
        const colorStops = paint
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()
        for (const colorStop of colorStops) {
          types.push(
            getKeyframesType(
              [
                colorStop.getComponentOrThrow(ProgressComponent),
                colorStop.getComponentOrThrow(RgbaValueComponent),
              ],
              time
            )
          )
        }
        continue
      }
      case PaintType.GradientRadial: {
        const colorStops = paint
          .getAspectOrThrow(ColorStopsRelationsAspect)
          .getChildrenList()
        for (const colorStop of colorStops) {
          types.push(
            getKeyframesType(
              [
                colorStop.getComponentOrThrow(ProgressComponent),
                colorStop.getComponentOrThrow(RgbaValueComponent),
              ],
              time
            )
          )
        }
        continue
      }
      case PaintType.Image: {
        continue
      }
      default: {
        throw new Error(`Unknown paint type: ${type}`)
      }
    }
  }

  return types
}
