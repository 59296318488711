import { observer } from 'mobx-react'
import * as React from 'react'
import { useRatio } from '../use-ratio'
import { Properties } from './properties'
import { Timeline } from './timeline'

export interface IProps {}
export const Details: React.FCC<IProps> = observer(() => {
  const ratioRef = React.useRef<HTMLDivElement>(null)

  const { startListen, topRatio, bottomRatio, reset } = useRatio({
    element: ratioRef.current as HTMLElement,
    minimumTopRatio: 0.2,
  })

  return (
    <div
      ref={ratioRef}
      className="flex h-full w-full flex-col rounded-xl border-[1px] border-[#5C65741A] border-opacity-10 bg-white"
    >
      <div className="flex w-full" style={{ height: topRatio * 100 + '%' }}>
        <Timeline />
      </div>
      <div
        className="hover: absolute h-2 w-full cursor-ns-resize opacity-0"
        style={{ top: `calc(${topRatio * 100 + '%'} - 0.5rem)` }}
        //@ts-ignore
        onMouseDown={startListen}
        onDoubleClick={reset}
      />
      <div
        className="flex w-full flex-col gap-0"
        style={{ height: bottomRatio * 100 + '%' }}
      >
        <Properties />
      </div>
    </div>
  )
})

Details.displayName = 'Details'
