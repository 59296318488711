import classNames from 'classnames'
import * as React from 'react'

export interface IProps {
  file: File
  onRemove: () => void
}
export const Attachment: React.FCC<IProps> = ({ file, onRemove }) => {
  const [fileSrc, setFileSrc] = React.useState<string | undefined | null>(
    defaultPreview
  )

  const isFileSmall = file.size / 1024 / 1024 < 10

  React.useEffect(() => {
    console.log(file.type)
    if (!file.type.startsWith('image/')) return

    const reader = new FileReader()
    reader.onload = (e) => {
      setFileSrc(e.target?.result as string)
    }
    reader.readAsDataURL(file)
  }, [])

  return (
    <div
      title={file.name}
      style={{
        backgroundImage: `url('${fileSrc}')`,
        backgroundSize: 'cover',
      }}
      className="w-[calc(25%-6px)] aspect-square  border border-solid overflow-hidden border-gray-200 bg-white rounded-md flex flex-col items-center justify-between"
    >
      <div className="w-full flex flex-row items-center justify-end p-2">
        <button className="p-1 bg-white/80 rounded-full" onClick={onRemove}>
          <CloseIcon />
        </button>
      </div>
      <div className="w-full flex flex-row items-center justify-center p-2 bg-white">
        <p
          className={classNames(
            'text-[12px]/[16px] font-semibold w-fit line-clamp-1 break-words break-all',
            { ['text-red']: !isFileSmall }
          )}
        >
          {isFileSmall ? file.name : 'File is too large'}
        </p>
      </div>
    </div>
  )
}

const defaultPreview =
  'data:image/svg+xml,<svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 10.5333C10 9.41321 10 8.85316 10.218 8.42533C10.4097 8.04901 10.7157 7.74305 11.092 7.5513C11.5198 7.33331 12.0799 7.33331 13.2 7.33331H14.5582C14.7212 7.33331 14.8028 7.33331 14.8795 7.35173C14.9475 7.36806 15.0125 7.395 15.0722 7.43155C15.1395 7.47278 15.1971 7.53043 15.3124 7.64573L17.6876 10.0209C17.8029 10.1362 17.8605 10.1938 17.9018 10.2611C17.9383 10.3208 17.9652 10.3858 17.9816 10.4538C18 10.5306 18 10.6121 18 10.7751V13.4666C18 14.5868 18 15.1468 17.782 15.5746C17.5903 15.951 17.2843 16.2569 16.908 16.4487C16.4802 16.6666 15.9201 16.6666 14.8 16.6666H13.2C12.0799 16.6666 11.5198 16.6666 11.092 16.4487C10.7157 16.2569 10.4097 15.951 10.218 15.5746C10 15.1468 10 14.5868 10 13.4666V10.5333Z" stroke="%23E0E0E0" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 7.66669V9.26669C15 9.64006 15 9.82674 15.0727 9.96935C15.1366 10.0948 15.2386 10.1968 15.364 10.2607C15.5066 10.3334 15.6933 10.3334 16.0667 10.3334H17.6667" stroke="%23E0E0E0" stroke-linecap="round" stroke-linejoin="round"/></svg>'

Attachment.displayName = 'Attachment'

const CloseIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_27_34)">
      <path
        d="M7 1L1 7M1 1L7 7"
        stroke="#0B1118"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_27_34">
        <rect width="8" height="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
