import type { NestedMenuItemType as ISection } from '@aninix/app-design-system'
import { NestedMenu } from '@aninix/app-design-system'
import * as React from 'react'

export interface IProps {
  sections: ISection[][]
  isOpen: boolean
  onClose: () => void
  anchorEl: HTMLDivElement | null
}
export const MenuViewModal: React.FCC<IProps> = ({
  isOpen,
  onClose,
  anchorEl,
  sections,
}) => (
  // <Popover
  //   open={isOpen}
  //   onClose={onClose}
  //   anchorEl={anchorEl}
  //   anchorOrigin={{
  //     vertical: 'bottom',
  //     horizontal: 'center',
  //   }}
  //   transformOrigin={{
  //     vertical: 'top',
  //     horizontal: 'center',
  //   }}
  //   disableRestoreFocus
  //   classes={{
  //     paper: styles['container-paper'],
  //   }}
  // >
  <NestedMenu menuItems={sections} width="200px" />
  // </Popover>
)
