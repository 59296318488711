import { InfoItem } from '@aninix/core/modules/inspector/mappers/types'
import * as React from 'react'

import { Property } from '../segment/property'

export interface IProps {
  properties: InfoItem[]
  propertiesName: string
}
export const TimeInfoItems: React.FCC<IProps> = ({
  properties,
  propertiesName,
}) => {
  return (
    <>
      <p className="font-body text-xs font-normal text-secondary">
        {propertiesName}
      </p>

      <div className="flex flex-row gap-4">
        {properties.map(({ title, value }: any, index: number) => (
          <Property key={index.toString()} title={title} value={value} />
        ))}
      </div>
    </>
  )
}

TimeInfoItems.displayName = 'TimeInfoItems'
