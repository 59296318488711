import * as React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import { UseGetSpaceBadge } from '@aninix/api'
import { useCurrentSpaceStore } from './use-current-space'

interface IProps {
  currentTeam?: UseGetSpaceBadge['infoFromTokens']['users'][number]['teams'][number]
}

const CurrentTeamContext = React.createContext<IProps>({})

export const useCurrentTeamStore = (): IProps => {
  return React.useContext(CurrentTeamContext)
}

export const CurrentTeamStore: React.FCC<IProps> = ({ children }) => {
  const { currentUser } = useCurrentSpaceStore()

  const { teamId } = useParams()

  const currentTeam = currentUser!.teams?.find((t) => t.id === teamId)

  if (currentTeam === undefined) return <Navigate to="../my" />

  return (
    <CurrentTeamContext.Provider value={{ currentTeam }}>
      {children}
    </CurrentTeamContext.Provider>
  )
}
