// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UpH7WHDdMB2O3gIaWSXw{overflow:hidden;border:1px solid rgba(0,0,0,0);border-radius:2px}.UpH7WHDdMB2O3gIaWSXw:hover{border:1px solid var(--aninix-color-foreground-black-1)}.hD5yckZjmzB3z7BAcXvy{background:var(--aninix-color-foreground-black-1);border:none;cursor:default}.kdu4N95Q0aaplmksOn8D{border:none;cursor:default}.fr6eCuTAUQqHtZEat5vC{min-width:24px;height:24px;padding:0}.AXfJUtysOi9vkaoX3wwp{border:none !important}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/buttons/GroupButton/index.scss","webpack://./packages/app-design-system/src/theme/aninix-colors.scss"],"names":[],"mappings":"AAEA,sBACE,eAAA,CACA,8BAAA,CACA,iBAAA,CAEA,4BACE,uDAAA,CAIJ,sBACE,iDCToB,CDUpB,WAAA,CACA,cAAA,CAGF,sBACE,WAAA,CACA,cAAA,CAGF,sBACE,cAAA,CACA,WAAA,CACA,SAAA,CAGF,sBACE,sBAAA","sourcesContent":["@use '../../../../theme/index.scss' as *;\n\n.root {\n  overflow: hidden;\n  border: 1px solid transparent;\n  border-radius: 2px;\n\n  &:hover {\n    border: 1px solid $special-black-1;\n  }\n}\n\n.selected {\n  background: $special-black-1;\n  border: none;\n  cursor: default;\n}\n\n.default {\n  border: none;\n  cursor: default;\n}\n\n.grouped {\n  min-width: 24px;\n  height: 24px;\n  padding: 0;\n}\n\n.disabled {\n  border: none !important;\n}\n","$aninixColorFgWhite1: var(--aninix-color-foreground-white-1);\n$aninixColorFgWhite2: var(--aninix-color-foreground-white-2);\n$aninixColorFgWhite4: var(--aninix-color-foreground-white-4);\n$aninixColorFgBlack05: var(--aninix-color-foreground-black-05);\n$aninixColorFgBlack1: var(--aninix-color-foreground-black-1);\n$aninixColorFgBlack2: var(--aninix-color-foreground-black-2);\n$aninixColorBgWhite2: var(--aninix-color-background-white-2);\n$aninixColorBgBlack3: var(--aninix-color-background-black-3);\n$aninixColorBgSilver: var(--aninix-color-background-silver);\n"],"sourceRoot":""}]);
// Exports
export var root = `UpH7WHDdMB2O3gIaWSXw`;
export var selected = `hD5yckZjmzB3z7BAcXvy`;
export var _default = `kdu4N95Q0aaplmksOn8D`;
export var grouped = `fr6eCuTAUQqHtZEat5vC`;
export var disabled = `AXfJUtysOi9vkaoX3wwp`;
export default ___CSS_LOADER_EXPORT___;
