// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hljs {
  color: #000000;
  font-size: 11px;
  background: transparent;
}

/* Comment */

.hljs-comment,
.hljs-quote {
  color: rgba(0, 0, 0, 0.3);
}

/* Red */

.hljs-variable,
.hljs-template-variable,
.hljs-tag,
.hljs-name,
.hljs-selector-id,
.hljs-selector-class,
.hljs-regexp,
.hljs-deletion {
  color: #f17424;
}

/* Orange */

.hljs-number,
.hljs-built_in,
.hljs-literal,
.hljs-type,
.hljs-params,
.hljs-meta,
.hljs-link {
  color: #f17424;
}

/* Yellow */

.hljs-attribute {
  color: #000000;
}

/* Green */

.hljs-string,
.hljs-symbol,
.hljs-bullet,
.hljs-addition {
  color: #7dab21;
}

/* Blue */

.hljs-title,
.hljs-section {
  color: #00e0e0;
}

/* Purple */

.hljs-keyword,
.hljs-selector-tag {
  color: #7b61ff;
}

.hljs-emphasis {
  font-style: italic;
}

.hljs-strong {
  font-weight: bold;
}

@media screen and (-ms-high-contrast: active) {
  .hljs-addition,
  .hljs-attribute,
  .hljs-built_in,
  .hljs-bullet,
  .hljs-comment,
  .hljs-link,
  .hljs-literal,
  .hljs-meta,
  .hljs-number,
  .hljs-params,
  .hljs-string,
  .hljs-symbol,
  .hljs-type,
  .hljs-quote {
    color: highlight;
  }

  .hljs-keyword,
  .hljs-selector-tag {
    font-weight: bold;
  }
}
`, "",{"version":3,"sources":["webpack://./packages/core/src/modules/inspector/components/details/properties/tabs/content/code/highlight.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,eAAe;EACf,uBAAuB;AACzB;;AAEA,YAAY;;AACZ;;EAEE,yBAAyB;AAC3B;;AAEA,QAAQ;;AACR;;;;;;;;EAQE,cAAc;AAChB;;AAEA,WAAW;;AACX;;;;;;;EAOE,cAAc;AAChB;;AAEA,WAAW;;AACX;EACE,cAAc;AAChB;;AAEA,UAAU;;AACV;;;;EAIE,cAAc;AAChB;;AAEA,SAAS;;AACT;;EAEE,cAAc;AAChB;;AAEA,WAAW;;AACX;;EAEE,cAAc;AAChB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE;;;;;;;;;;;;;;IAcE,gBAAgB;EAClB;;EAEA;;IAEE,iBAAiB;EACnB;AACF","sourcesContent":[".hljs {\n  color: #000000;\n  font-size: 11px;\n  background: transparent;\n}\n\n/* Comment */\n.hljs-comment,\n.hljs-quote {\n  color: rgba(0, 0, 0, 0.3);\n}\n\n/* Red */\n.hljs-variable,\n.hljs-template-variable,\n.hljs-tag,\n.hljs-name,\n.hljs-selector-id,\n.hljs-selector-class,\n.hljs-regexp,\n.hljs-deletion {\n  color: #f17424;\n}\n\n/* Orange */\n.hljs-number,\n.hljs-built_in,\n.hljs-literal,\n.hljs-type,\n.hljs-params,\n.hljs-meta,\n.hljs-link {\n  color: #f17424;\n}\n\n/* Yellow */\n.hljs-attribute {\n  color: #000000;\n}\n\n/* Green */\n.hljs-string,\n.hljs-symbol,\n.hljs-bullet,\n.hljs-addition {\n  color: #7dab21;\n}\n\n/* Blue */\n.hljs-title,\n.hljs-section {\n  color: #00e0e0;\n}\n\n/* Purple */\n.hljs-keyword,\n.hljs-selector-tag {\n  color: #7b61ff;\n}\n\n.hljs-emphasis {\n  font-style: italic;\n}\n\n.hljs-strong {\n  font-weight: bold;\n}\n\n@media screen and (-ms-high-contrast: active) {\n  .hljs-addition,\n  .hljs-attribute,\n  .hljs-built_in,\n  .hljs-bullet,\n  .hljs-comment,\n  .hljs-link,\n  .hljs-literal,\n  .hljs-meta,\n  .hljs-number,\n  .hljs-params,\n  .hljs-string,\n  .hljs-symbol,\n  .hljs-type,\n  .hljs-quote {\n    color: highlight;\n  }\n\n  .hljs-keyword,\n  .hljs-selector-tag {\n    font-weight: bold;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
