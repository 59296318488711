import { Typography } from 'apps/web-app/src/components/design-system/typography'
import * as React from 'react'
import { Overrides } from './overrides'
import { useOverrides } from './use-overrides'

export interface IProps {}
export const OverridesPanel: React.FCC<IProps> = () => {
  const { createOverride } = useOverrides()

  return (
    <div className="flex flex-col rounded-xl bg-white/50 backdrop-blur-md gap-2 py-2 border-gray-500/10 border">
      <div className="flex flex-row justify-between items-center px-3 py-1">
        <Typography style="Body4Medium" text="Override variations" />
        <button onClick={() => createOverride()}>
          <PlusIcon />
        </button>
      </div>
      <Overrides />
    </div>
  )
}

OverridesPanel.displayName = 'OverridesPanel'

const PlusIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1438_1167)">
      <path
        d="M8 1V15M1 8H15"
        stroke="#0B1118"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1438_1167">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
