import paper from 'paper'
import { map3x2ToPaperMatrix, mapPaperMatrixTo3x2, Matrix3x2 } from '../utils'
import { getAngle } from './utils'

const initCenter = new paper.Point(0.5, 0.5)
const initRadiusY = new paper.Point(0.5, 1)
const initRadiusX = new paper.Point(1, 0.5)

export const getRadialGradientFromMatrix = (
  transformMatrix: Matrix3x2,
  paintMatrix: paper.Matrix,
  size: paper.Size
) => {
  const matrix = map3x2ToPaperMatrix(transformMatrix)

  const gMatrix = matrix.isInvertible() ? matrix.inverted() : matrix

  const gCenter = gMatrix.transform(initCenter)
  const gRadiusX = gMatrix.transform(initRadiusX)
  const gRadiusY = gMatrix.transform(initRadiusY)

  const scaledSize = size.divide(paintMatrix.scaling)
  const center = paintMatrix.transform(gCenter.multiply(scaledSize))
  const radiusX = paintMatrix.transform(gRadiusX.multiply(scaledSize))
  const radiusY = paintMatrix.transform(gRadiusY.multiply(scaledSize))

  const angle = getAngle(center, radiusX)

  return {
    center,
    radiusX,
    radiusY,
    angle,
  }
}

export const getMatrixFromRadialGradient = (
  center: paper.Point,
  radiusX: paper.Point,
  radiusY: paper.Point,
  paintMatrix: paper.Matrix,
  size: paper.Size
) => {
  const invPaintMatrix = paintMatrix.inverted()

  const scaledSize = size.divide(paintMatrix.scaling)
  const gCenter = invPaintMatrix.transform(center).divide(scaledSize)
  const gRadiusX = invPaintMatrix.transform(radiusX).divide(scaledSize)
  const gRadiusY = invPaintMatrix.transform(radiusY).divide(scaledSize)

  const scaleX = Math.abs(gCenter.subtract(gRadiusX).length * 2)
  const scaleY = Math.abs(gCenter.subtract(gRadiusY).length * 2)

  const angle = getAngle(gCenter, gRadiusX)

  const gMatrix = new paper.Matrix()
  gMatrix.rotate(angle, gCenter)
  gMatrix.scale(scaleX, scaleY, gCenter)
  gMatrix.translate(gCenter.subtract(initCenter))

  const resultMatrix = gMatrix.isInvertible() ? gMatrix.inverted() : gMatrix

  const matrix3x2 = mapPaperMatrixTo3x2(resultMatrix)

  return matrix3x2
}

export const getRadiusPointByVector = (
  initPoints: { start: paper.Point; end: paper.Point },
  newPoints: { start: paper.Point; end: paper.Point },
  initRadiusX: paper.Point
) => {
  const initVector = initPoints.end.subtract(initPoints.start)
  const newVector = newPoints.end.subtract(newPoints.start)

  const scaleFactor = newVector.length / initVector.length || 0
  const angleDifference = newVector.angle - initVector.angle

  const initRadiusVector = initRadiusX.subtract(initPoints.start)
  const rotatedRadiusVector = initRadiusVector.rotate(
    angleDifference,
    new paper.Point(0, 0)
  )
  const scaledRadiusVector = rotatedRadiusVector.multiply(scaleFactor)
  const newRadiusXPoint = newPoints.start.add(scaledRadiusVector)

  return newRadiusXPoint
}

export const getRadiusPointByVectorAndOffset = (
  offset: paper.Point,
  startPoint: paper.Point,
  endPoint: paper.Point,
  radiusPoint: paper.Point
) => {
  const mainVector = endPoint.subtract(startPoint)
  const perpVector = new paper.Point(-mainVector.y, mainVector.x).normalize()

  const projectedOffset = perpVector.multiply(offset.dot(perpVector))

  return radiusPoint.add(projectedOffset)
}
