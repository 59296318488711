// @ts-nocheck
import { Point2D } from '@aninix-inc/model/legacy'
import { ImagesStore } from '@aninix/core/stores'
import * as PIXI from 'pixi.js'
import { NodeSnapshot } from '../../common/renderers/types'
import { getSize } from '../helpers/get-size'
import { gradientTexture } from '../helpers/gradient-texture'
import { drawVectorPath } from './draw-vector-path'

export function drawFills(
  node: NodeSnapshot,
  graphics: PIXI.Graphics,
  images: ImagesStore,
  app: PIXI.Application,
  prerenderCallbacks: (() => void)[]
) {
  node.fills.forEach((fill, index) => {
    if (!fill.visible) return

    if (fill.type === 'solid') {
      graphics.beginFill(fill.color)
    }

    if (fill.type === 'linear-gradient' || fill.type === 'radial-gradient') {
      graphics.beginTextureFill({
        texture: gradientTexture(fill, node.size),
        alpha: fill.opacity,
      })
    }

    if (fill.type === 'image') {
      const matrix = new PIXI.Matrix()

      const canvas = document.createElement('canvas')
      canvas.width = node.size.x * 3
      canvas.height = node.size.y * 3
      const ctx = canvas.getContext('2d')!

      const image: HTMLImageElement = images.images[fill.hash]?.source
      if (image === undefined) return

      let imagePosition: Point2D = { x: 0, y: 0 }
      let imageSize: Point2D = { x: 0, y: 0 }

      const [a, b, tx, c, d, ty] = fill.imageTransform.flat()

      const originalSize = images.images[fill.hash].size

      switch (fill.scaleMode) {
        case 'FILL': {
          const newSize = getSize({
            target: node.size,
            actual: originalSize,
            mode: 'fill',
          })

          imageSize = { x: newSize.x * 3 - 2, y: newSize.y * 3 - 2 }
          imagePosition = {
            x: (node.size.x * 3 - imageSize.x) / 2,
            y: (node.size.y * 3 - imageSize.y) / 2,
          }

          break
        }
        case 'FIT': {
          const newSize = getSize({
            target: node.size,
            actual: originalSize,
            mode: 'fit',
          })

          imageSize = { x: newSize.x * 3 - 2, y: newSize.y * 3 - 2 }
          imagePosition = {
            x: (node.size.x * 3 - imageSize.x) / 2,
            y: (node.size.y * 3 - imageSize.y) / 2,
          }

          break
        }
        case 'CROP': {
          const newSize = getSize({
            target: node.size,
            actual: originalSize,
            mode: 'fit',
          })

          imageSize = { x: newSize.x * 3 - 2, y: newSize.y * 3 - 2 }
          imagePosition = {
            x: (node.size.x * 3 - imageSize.x) / 2,
            y: (node.size.y * 3 - imageSize.y) / 2,
          }

          ctx.setTransform(a, b, c, d, tx, ty)

          break
        }
        case 'TILE': {
          const newSize = getSize({
            target: { x: node.size.x * 2.37, y: node.size.y * 2.37 },
            actual: originalSize,
            mode: 'fit',
          })

          imageSize = { x: newSize.x * 3 - 2, y: newSize.y * 3 - 2 }
          imagePosition = { x: 0, y: 0 }

          canvas.width = imageSize.x
          canvas.height = imageSize.y

          matrix.scale(fill.scalingFactor * 3, fill.scalingFactor * 3)
          break
        }
      }

      ctx.drawImage(
        image,
        imagePosition.x,
        imagePosition.y,
        imageSize.x,
        imageSize.y
      )

      const texture = PIXI.Texture.from(canvas)

      prerenderCallbacks.push(() => {
        texture.baseTexture.destroy()
      })

      texture.baseTexture.width = node.size.x
      texture.baseTexture.height = node.size.y

      graphics.beginTextureFill({
        texture,
        alpha: fill.opacity,
        matrix: matrix.scale(1 / 3, 1 / 3),
      })
    }

    drawVectorPath(node, graphics, node.data.map((e) => e.data).join(''))

    graphics.endFill()
  })
}
