import {
  Component,
  ScaleLockedComponent,
  SizeLockedComponent,
  getAnimatableValue,
  mixed,
  setValuePoint2d,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/legacy'
import { useComponents } from '@aninix/core'
import * as R from 'ramda'

/**
 * Encapsulate logic to retrieve and update value
 */
export function useValueLockablePoint2d({
  components,
  lockableComponents,
  time,
}: {
  components: Component[]
  lockableComponents: (SizeLockedComponent | ScaleLockedComponent)[]
  time?: number
}): {
  x: number | typeof mixed
  y: number | typeof mixed
  locked: boolean
  updateX: (newValue: number) => void
  updateXByDelta: (delta: number) => void
  updateY: (newValue: number) => void
  updateYByDelta: (delta: number) => void
  toggleLock: () => void
} {
  useComponents(components)
  useComponents(lockableComponents)

  const xEquals = R.all(
    (component) =>
      (getAnimatableValue(component, time) as Point2D).x ===
      (getAnimatableValue(components[0], time) as Point2D).x,
    components
  )
  const yEquals = R.all(
    (component) =>
      (getAnimatableValue(component, time) as Point2D).y ===
      (getAnimatableValue(components[0], time) as Point2D).y,
    components
  )

  const locked = lockableComponents[0].value

  const updateX = (newValue: number) => {
    for (const component of components) {
      if (locked) {
        const currentValue = getAnimatableValue(component, time) as Point2D
        if (currentValue.x === 0) {
          setValuePoint2d(
            component,
            {
              x: newValue,
              y: newValue,
            },
            time
          )
          return
        }

        setValuePoint2d(
          component,
          (value: Point2D) => {
            const multiplier = value.y / value.x
            const offset = (newValue - value.x) * multiplier
            return {
              x: newValue,
              y: value.y + offset,
            }
          },
          time
        )
        return
      }

      setValuePoint2d(
        component,
        (value) => ({
          x: newValue,
          y: value.y,
        }),
        time
      )
    }
  }

  const updateY = (newValue: number) => {
    for (const component of components) {
      const currentValue = getAnimatableValue(component, time) as Point2D
      if (locked) {
        if (currentValue.y === 0) {
          setValuePoint2d(
            component,
            {
              x: newValue,
              y: newValue,
            },
            time
          )
          return
        }

        setValuePoint2d(
          component,
          (value: Point2D) => {
            const multiplier = value.x / value.y
            const offset = (newValue - value.y) * multiplier
            return {
              x: value.x + offset,
              y: newValue,
            }
          },
          time
        )
        return
      }

      setValuePoint2d(
        component,
        (value) => ({
          x: value.x,
          y: newValue,
        }),
        time
      )
    }
  }

  const updateXByDelta = (delta: number) => {
    components.forEach((component) => {
      if (locked) {
        if ((getAnimatableValue(component, time) as Point2D).x === 0) {
          setValuePoint2d(
            component,
            (value) => ({
              x: value.x + delta,
              y: value.y + delta,
            }),
            time
          )
          return
        }

        setValuePoint2d(
          component,
          (value: Point2D) => {
            const multiplier = value.y / value.x
            const offset = delta * multiplier
            return {
              x: value.x + delta,
              y: value.y + offset,
            }
          },
          time
        )
        return
      }

      setValuePoint2d(
        component,
        (value) => ({
          x: value.x + delta,
          y: value.y,
        }),
        time
      )
    })
  }

  const updateYByDelta = (delta: number) => {
    components.forEach((component) => {
      if (locked) {
        if ((getAnimatableValue(component, time) as Point2D).y === 0) {
          setValuePoint2d(
            component,
            (value) => ({
              x: value.x + delta,
              y: value.y + delta,
            }),
            time
          )
          return
        }

        setValuePoint2d(
          component,
          (value: Point2D) => {
            const multiplier = value.x / value.y
            const offset = delta * multiplier
            return {
              x: value.x + offset,
              y: value.y + delta,
            }
          },
          time
        )
        return
      }

      setValuePoint2d(
        component,
        (value) => ({
          x: value.x,
          y: value.y + delta,
        }),
        time
      )
    })
  }

  const toggleLock = () => {
    lockableComponents.forEach((component) => {
      // @ts-ignore
      component.entity.updateComponent(component.constructor, (v) => !v)
    })
  }

  const value = getAnimatableValue(components[0], time) as Point2D
  return {
    x: xEquals ? value.x : mixed,
    y: yEquals ? value.y : mixed,
    locked,
    updateX,
    updateXByDelta,
    updateY,
    updateYByDelta,
    toggleLock,
  }
}
