import * as React from 'react'

export interface IProps {}
export const MaintenanceModeBanner: React.FC<IProps> = () => (
  <div className="w-full border-b-[1px] border-solid border-b-[#DFE8F3] bg-[#F7F9FC] px-10 py-4 text-center">
    <p className="txt-[18_24_600]">
      Scheduled maintenance on June 28 at 11 p.m. GMT.
      <span className="block text-[#787878] txt-[14_18_400]">
        Should take a couple of hours or less
      </span>
    </p>
  </div>
)
