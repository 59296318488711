// @ts-nocheck
import { Viewport } from '@aninix/core'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import * as PIXI from 'pixi.js'
import { NodeSnapshot } from '../../common/renderers/types'

export function applyBackdropBlur(
  node: NodeSnapshot,
  graphics: PIXI.Graphics,
  kindergarden: PIXI.Container,
  viewport: Viewport,
  app: PIXI.Application,
  prerenderCallbacks: (() => void)[],
  postrenderCallbacks: (() => void)[],
  blurQuality: number
) {
  node.backgroundBlurs.forEach((bgBlur) => {
    if (!bgBlur.visible) return

    const { radius } = bgBlur

    //temp hide text layer
    // @ts-ignore
    // app.stage.children[0].children[0].visible = false

    const resolution = 0.5 //Math.max(1 / (radius / 5), 0.1)

    const backdropTexture = app.renderer.generateTexture(
      //this is frame's container. better to update to argument of this function and pas as ref
      //@ts-ignore
      app.stage.children[0], //.children[1],
      { resolution }
    )

    // PIXI.Cache.remove(`${node.id}_last_backdrop`)
    // PIXI.Cache.set(`${node.id}_last_backdrop`, backdropTexture)

    prerenderCallbacks.push(() => {
      backdropTexture.baseTexture.destroy()
    })
    // setTimeout(() => {
    //   backdropTexture.baseTexture.destroy()
    // }, 0)
    //return text layer
    //@ts-ignore
    // app.stage.children[0].children[0].visible = true

    const backdropSprite = new PIXI.Sprite(backdropTexture)
    backdropSprite.name = `${node.id}_backdropSprite`

    const findMaskBackdrop = kindergarden.getChildByName(
      `${node.id}_maskBackdrop`
    ) as PIXI.Graphics | undefined
    const maskBackdrop = findMaskBackdrop ?? graphics.clone()

    backdropSprite.transform.setFromMatrix(
      kindergarden.transform.worldTransform.clone().invert()
    )

    postrenderCallbacks.push(() => {
      // return
      const blurFilter = new KawaseBlurFilter(
        (radius * viewport.zoom) / 3,
        blurQuality,
        true
      )
      blurFilter.resolution = 1

      // const blurFilter = new PIXI.BlurFilter(
      //   radius * stores.viewport.zoom,
      //   blurQuality,
      //   3
      // )

      // blurFilter.repeatEdgePixels = true

      const backdropContainer = new PIXI.Container()
      backdropContainer.addChild(backdropSprite, maskBackdrop)
      backdropContainer.mask = maskBackdrop

      // const rect = new PIXI.Rectangle(
      //   node.position.x,
      //   node.position.y,
      //   node.size.x,
      //   node.size.y
      // )
      // ;(app.stage.children[0] as PIXI.Container).addChild(
      //   graphics
      //     .clone()
      //     .lineStyle(1, 'red')
      //     .drawRect(node.position.x, node.position.y, node.size.x, node.size.y)
      // )
      const areaToFilter = kindergarden.getBounds()

      backdropSprite.filterArea = areaToFilter

      backdropSprite.filters = [blurFilter]

      kindergarden.addChildAt(backdropContainer, 0)
    })
  })
}
