import {
  CornerRadiusComponent,
  EndAngleComponent,
  Entity,
  FpsComponent,
  InnerRadiusComponent,
  NodeType,
  NodeTypeComponent,
  PathReversedComponent,
  PointCountComponent,
  Root,
  SizeComponent,
  StartAngleComponent,
  TimeComponent,
  TimingCurveComponent,
  getTimingCurveKeyframes,
  round,
} from '@aninix-inc/model'
import { DataDrawer, getDataMap, getStrokePath } from '@aninix-inc/renderer'
import * as R from 'ramda'
import {
  bezierPointsToLottiePoints,
  svgPathToBezierPoints,
} from '../../../../vector-helpers'
import {
  LottieBezier,
  LottieProperty,
  LottieShapeLayerType,
  LottieShapePath,
} from '../types'

const ARC_TIME_STEP_IN_SECONDS = 1 / 60 // 1 frame

/**
 * Find all keyframes.
 * It push keyframes by properties, then keep only where we have animation (eg > 1 keyframe).
 * And then flatten into array of keyframes.
 */
function getKeyframes(node: Entity): Entity[] {
  const nodeType = node.getComponentOrThrow(NodeTypeComponent).value
  const keyframeRows: Entity[][] = []

  if (nodeType === NodeType.Rectangle) {
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(SizeComponent))
    )
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(CornerRadiusComponent))
    )
  } else if (nodeType === NodeType.Ellipse) {
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(SizeComponent))
    )
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(StartAngleComponent))
    )
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(EndAngleComponent))
    )
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(InnerRadiusComponent))
    )
  } else if (nodeType === NodeType.Polygon) {
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(SizeComponent))
    )
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(PointCountComponent))
    )
  } else if (nodeType === NodeType.Star) {
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(SizeComponent))
    )
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(PointCountComponent))
    )
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(InnerRadiusComponent))
    )
  } else {
    keyframeRows.push(
      getTimingCurveKeyframes(node.getComponentOrThrow(SizeComponent))
    )
  }

  return keyframeRows.filter((row) => row.length > 1).flat()
}

/**
 * @description extract path property from node
 */
export function PathProperties(payload: {
  node: Entity
  type?: 'top' | 'right' | 'bottom' | 'left'
}): LottieProperty<LottieBezier>[] {
  const { node, type } = payload
  const project = node.getProjectOrThrow()
  const projectFps = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(FpsComponent).value
  // @ts-ignore
  const getData = getDataMap[
    node.getComponentOrThrow(NodeTypeComponent).value
  ] as DataDrawer

  const keyframes = getKeyframes(node)

  // @NOTE: sort target keyframes
  const sortedKeyframes = R.sort(
    (left, right) =>
      left.getComponentOrThrow(TimeComponent).value -
      right.getComponentOrThrow(TimeComponent).value,
    keyframes
  )

  const path =
    // @ts-ignore
    node.individualStrokesEnabled && type != null
      ? [getStrokePath({ entity: node, time: 0, type })]
      : getData({
          entity: node,
          time: 0,
          isReversed: node.hasComponent(PathReversedComponent),
        })

  // @ts-ignore
  const preparedPaths: LottieProperty<LottieBezier>[] = path
    .flatMap((p) => p.data.split(/(?=[Mm])/))
    .flatMap((data) => {
      const newPath = bezierPointsToLottiePoints({
        regions: [
          {
            points: R.flatten(svgPathToBezierPoints(data)),
            isClosed: data.includes('z') || data.includes('Z'),
          },
        ],
      })

      return newPath
    })
    .map((preparedPath, pathIdx) => {
      if (sortedKeyframes.length > 0) {
        return {
          a: 1,
          k: R.uniqBy(
            (keyframe) => keyframe.getComponentOrThrow(TimeComponent).value,
            sortedKeyframes
          )
            .flatMap((keyframe, keyframeIdx, keyframes) => {
              const paths =
                // @ts-ignore
                node.individualStrokesEnabled && type != null
                  ? // @TODO: FIXME check maybe this would broke because we have separated data getter and trimData.
                    // If so we have to add proper function here from @aninix-inc/renderer package.
                    getStrokePath({
                      entity: node,
                      time: keyframe.getComponentOrThrow(TimeComponent).value,
                      type,
                    }).data
                  : getData({
                      entity: node,
                      time: keyframe.getComponentOrThrow(TimeComponent).value,
                      fixedPointsCount: true,
                    }).flatMap((p) => p.data.split(/(?=[Mm])/))

              const currentPath = paths[pathIdx]

              if (currentPath == null) {
                return null
              }

              const newPath = bezierPointsToLottiePoints({
                regions: [
                  {
                    points: R.flatten(svgPathToBezierPoints(currentPath)),
                    isClosed:
                      currentPath.includes('z') || currentPath.includes('Z'),
                  },
                ],
              })

              const isLast = keyframeIdx === keyframes.length - 1

              if (isLast) {
                return [
                  {
                    t:
                      keyframe.getComponentOrThrow(TimeComponent).value *
                      projectFps,
                    s: [newPath],
                  },
                ]
              }

              // @TODO: add correct type here
              // @ts-ignore
              const nextKeyframe = keyframes[keyframeIdx + 1]

              // @NOTE: if node it an ellipse then add inbetween keyframes.
              // This is required to properly handle cases when paperjs optimzie path generated by ellipse and removes some points
              // but later in animation lottie required those points and cutoff some parts of the shapes.
              // Learn more in tasks ANI-1448 and ANI-1552.
              if (
                node.getComponentOrThrow(NodeTypeComponent).value ===
                NodeType.Ellipse
              ) {
                const times = []

                for (
                  let i = keyframe.getComponentOrThrow(TimeComponent).value;
                  i < nextKeyframe.getComponentOrThrow(TimeComponent).value;
                  i += ARC_TIME_STEP_IN_SECONDS
                ) {
                  times.push(round(i, { fixed: 4 }))
                }

                return times.map((time) => {
                  const paths2 = getData({
                    entity: node,
                    time,
                    fixedPointsCount: true,
                  }).flatMap((p) => p.data.split(/(?=[Mm])/))

                  const currentPath2 = paths2[pathIdx]

                  if (currentPath2 == null) {
                    return null
                  }

                  const newPath2 = bezierPointsToLottiePoints({
                    regions: [
                      {
                        points: R.flatten(svgPathToBezierPoints(currentPath2)),
                        isClosed:
                          currentPath2.includes('z') ||
                          currentPath2.includes('Z'),
                      },
                    ],
                  })

                  return {
                    t: time * projectFps,
                    s: [newPath2],
                    o: {
                      x: [0],
                      y: [0],
                    },
                    i: {
                      x: [1],
                      y: [1],
                    },
                  }
                })
              }

              return [
                {
                  t:
                    keyframe.getComponentOrThrow(TimeComponent).value *
                    projectFps,
                  s: [newPath],
                  o: {
                    x: [
                      keyframe.getComponentOrThrow(TimingCurveComponent).value
                        .out.x,
                    ],
                    y: [
                      keyframe.getComponentOrThrow(TimingCurveComponent).value
                        .out.y,
                    ],
                  },
                  i: {
                    x: [
                      nextKeyframe.getComponentOrThrow(TimingCurveComponent)
                        .value.in.x,
                    ],
                    y: [
                      nextKeyframe.getComponentOrThrow(TimingCurveComponent)
                        .value.in.y,
                    ],
                  },
                },
              ]
            })
            .filter((item) => item != null),
        }
      }

      return {
        a: 0,
        k: preparedPath,
      }
    })

  return preparedPaths
}

/**
 * @description extract lottie paths from node
 */
export function Paths(payload: {
  node: Entity
  type?: 'top' | 'right' | 'bottom' | 'left'
}): LottieShapePath[] {
  const { node, type } = payload
  const pathProperties = PathProperties({ node, type })
  return pathProperties.map((pathProperty) => ({
    ty: LottieShapeLayerType.Path,
    nm: 'Path',
    hd: false,
    ks: pathProperty,
  }))
}
