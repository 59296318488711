import { Entity, SizeComponent } from '@aninix-inc/model'
import { INode } from 'svgson'

export const applySize = (entity: Entity, node: INode) => {
  if (node.name === 'line') {
    const { x1, x2, y1, y2 } = node.attributes
    const x = parseFloat(x2) - parseFloat(x1)
    const y = parseFloat(y2) - parseFloat(y1)

    entity.updateComponent(SizeComponent, { x, y })
  } else if (node.name === 'circle') {
    const { r } = node.attributes

    const x = 2 * parseFloat(r)
    const y = 2 * parseFloat(r)

    entity.updateComponent(SizeComponent, { x, y })
  } else if (node.name === 'ellipse') {
    const { rx, ry } = node.attributes

    const x = 2 * parseFloat(rx)
    const y = 2 * parseFloat(ry)

    entity.updateComponent(SizeComponent, { x, y })
  } else {
    const x = parseFloat(
      node.attributes.width ?? node.attributes.viewBox?.split(' ')[2]
    )
    const y = parseFloat(
      node.attributes.height ?? node.attributes.viewBox?.split(' ')[3]
    )

    if (isNaN(x) || isNaN(y)) return

    entity.updateComponent(SizeComponent, {
      x,
      y,
    })
  }
}
