import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { roleLabels } from '.'

export interface IProps {
  role: ApiUser['aninixRole']
  selectedRole?: ApiUser['aninixRole']
}

export const RoleItem: React.FCC<IProps> = ({ role, selectedRole }) => (
  <div className="flex w-full min-w-[6rem] flex-row items-end gap-2 px-8 py-3 hover:bg-gray-100">
    <p className="font-body text-base font-normal text-gray-500">
      {roleLabels[role]}
    </p>
    {role == selectedRole && <p className="text-gray-400">•</p>}
  </div>
)

RoleItem.displayName = 'RoleItem'
