import { Point2D } from '@aninix-inc/model/legacy'
import { useMouseMove } from '@aninix/app-design-system'
import { Viewport, usePlayback, useViewport } from '@aninix/core'
import {
  IComment,
  useGetComments,
} from '@aninix/core/use-cases/comments/use-get-comments'
import { usePatchComment } from '@aninix/core/use-cases/comments/use-patch-comment'
import { toast } from 'apps/web-app/src/modules/toasts'
import classNames from 'classnames'
import _ from 'lodash'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { BubbleCommentItem } from './bubble-comment-item'
import { CommentsThread } from './comments-thread'
import { useCommentContext } from './use-cases/use-comment-context'
import { CommentGroup } from './utils/map-comments-to-groups'

export interface IProps {}
export const ViewportCommentBubble: React.FCC<{
  commentGroups: CommentGroup[]
  comment: IComment
  data: IComment[]
  index: number
  previousCommentId?: string
  nextCommentId?: string
  rightRatio?: number
}> = observer(
  ({
    data,
    comment,
    index,
    previousCommentId,
    nextCommentId,
    commentGroups,
    rightRatio = 0,
  }) => {
    const viewport = useViewport()

    const uniqueCommentsByAuthors = React.useMemo(
      () =>
        _.uniqBy(
          [comment, ...(comment?.replies ?? [])],
          (reply) => reply.author.id
        ),
      [comment]
    )

    const playback = usePlayback()

    const { selectedCommentId, setSelectedCommentId } = useCommentContext()

    const isSelected = selectedCommentId === comment.id

    const commentRef = React.useRef<HTMLDivElement | null>(null)

    React.useEffect(() => {
      // Add the click event listener when the component mounts
      if (isSelected) document.addEventListener('mousedown', handleClickOutside)
      else document.removeEventListener('mousedown', handleClickOutside)

      // Clean up the event listener when the component unmounts
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [isSelected])

    const handleClickOutside = React.useCallback(
      (event: MouseEvent) => {
        if (
          commentRef.current &&
          !commentRef.current.contains(event.target as Node) &&
          !document
            .getElementById(`comment-list-item-${comment.id}`)
            ?.contains(event.target as Node)
        ) {
          setSelectedCommentId(null) // Clear the selectedCommentId state
        }
      },
      [commentRef]
    )

    function moveViewportToComment(
      comment: IComment,
      viewport: Viewport,
      rightRatio: number = 0
    ) {
      const startX = viewport.position.x
      const startY = viewport.position.y

      const endX =
        -comment.rectangle.x * viewport.zoom +
        viewport.size.x / 2 -
        (viewport.size.x / 2) * rightRatio

      console.log(rightRatio)
      const endY =
        -comment.rectangle.y * viewport.zoom + viewport.size.y / (2 * 1.2)

      const startPlaybackTime = playback.time
      const endPlaybackTime = comment.time

      const playbackDuration = _.clamp(
        (Math.abs(endPlaybackTime - startPlaybackTime) / 2) * 1000,
        400,
        1000
      )
      const viewportDistance = Math.sqrt(
        Math.pow(endX - startX, 2) + Math.pow(endY - startY, 2)
      )
      const viewportDuration = _.clamp(
        (viewportDistance / 400) * 1000,
        400,
        2000
      )

      console.log('viewportDuration', viewportDuration)
      console.log('playbackDuration', playbackDuration)

      const playbackFrameDuration = 1000 / 30 // Approx. 30 FPS
      const viewportFrameDuration = 1000 / 30 // Approx. 30 FPS

      const playbackTotalFrames = Math.round(
        playbackDuration / playbackFrameDuration
      )
      const viewportTotalFrames = Math.round(
        viewportDuration / viewportFrameDuration
      )

      return new Promise((resolve) => {
        let playbackFrame = 0
        let viewportFrame = 0

        function animatePlayback() {
          const linearProgress = playbackFrame / playbackTotalFrames
          const currentPlaybackTime =
            startPlaybackTime +
            (endPlaybackTime - startPlaybackTime) * linearProgress
          playback.updateTime(currentPlaybackTime)
          playbackFrame++

          if (playbackFrame <= playbackTotalFrames) {
            requestAnimationFrame(animatePlayback)
          }
        }

        function animateViewport() {
          const easeProgress = easeInOut(viewportFrame / viewportTotalFrames)
          const currentX = startX + (endX - startX) * easeProgress
          const currentY = startY + (endY - startY) * easeProgress

          viewport.updatePosition({
            x: currentX,
            y: currentY,
          })

          viewportFrame++

          if (viewportFrame <= viewportTotalFrames) {
            requestAnimationFrame(animateViewport)
          } else {
            resolve(true) // Animation completed
          }
        }

        animatePlayback()
        animateViewport()
      })
    }

    // Easing function (Quad in-out)
    function easeInOut(t: number) {
      return 0.5 - 0.5 * Math.cos(Math.PI * t)
    }

    const handleCommentBubbleClick = React.useCallback(() => {
      setSelectedCommentId(comment.id)
    }, [comment.id])

    React.useEffect(() => {
      if (!isSelected) return

      moveViewportToComment(comment, viewport, rightRatio)
      playback.pause()
    }, [isSelected])

    const { query: updateCommentQuery } = usePatchComment()
    const { query: getComments } = useGetComments({ isStartingLoading: false })

    const { isListening, offsetX, offsetY, startListen } = useMouseMove({
      onStart() {
        setIsDragged(true)
        setIsExpanded(true)
      },
      // element: commentRef?.current as HTMLElement,
      onFinish() {
        setTimeout(() => setIsExpanded(false), 0)

        updateCommentQuery
          .mutateAsync({
            commentId: comment.id,
            rectangle: {
              x: comment.rectangle.x + draggedPosition.x,
              y: comment.rectangle.y + draggedPosition.y,
              width: comment.rectangle.width,
              height: comment.rectangle.height,
            },
            time: comment.time,
            comment: comment.comment,
          })
          .then((result) => {
            getComments?.refetch().then((result) => {
              setDraggedPosition({ x: 0, y: 0 })
              setIsDragged(false)
            })
          })
          .catch((error) => {
            toast("Couldn't update comment", { variant: 'error' })
          })
      },
    })

    const [draggedPosition, setDraggedPosition] = React.useState<Point2D>({
      x: 0,
      y: 0,
    })

    React.useEffect(() => {
      if (!isListening) {
        return
      }

      setDraggedPosition({
        x: offsetX / viewport.zoom,
        y: offsetY / viewport.zoom,
      })
    }, [isListening, offsetX, offsetY])

    const [isDragged, setIsDragged] = React.useState<boolean>(false)

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false)

    const bubbleRef = React.useRef<HTMLDivElement>(null)
    const [rect, setRect] = React.useState<DOMRect | null>(null)

    React.useEffect(() => {
      if (bubbleRef.current && bubbleRef.current.firstChild) {
        const rect = (
          bubbleRef.current.firstChild as HTMLElement
        ).getBoundingClientRect()
        setRect(rect)
      }
    }, [])

    const groupId = React.useMemo(() => {
      const group = commentGroups
        .filter((g) => g.comments.length > 1)
        .find((group) => group.comments.map((c) => c.id).includes(comment.id))

      if (group === undefined) return undefined
      else {
        const xValues = group.comments.map((c) => c.rectangle.x)
        const yValues = group.comments.map((c) => c.rectangle.y)
        const posX = (Math.min(...xValues) + Math.max(...xValues)) / 2
        const posY = (Math.min(...yValues) + Math.max(...yValues)) / 2
        group.position = { x: posX, y: posY }
        return group
      }
    }, [commentGroups, viewport.isBusy])

    const hideInGroup = groupId !== undefined && !isSelected

    return (
      <div
        ref={commentRef}
        className={classNames(
          'viewport-comment-bubble absolute h-1 w-1 origin-bottom-left translate-x-[--offset-x-to-group] translate-y-[--offset-y-to-group] scale-[--scale] transition-transform duration-500 hover:z-30',
          {
            ['transition-transform duration-500']: !viewport.isBusy,

            ['z-30']: isSelected,
          }
        )}
        style={
          {
            '--scale': (hideInGroup ? 0 : 1).toString(),
            '--offset-x-to-group':
              (hideInGroup
                ? (groupId!.position.x -
                    (comment.rectangle.x + draggedPosition.x)) *
                  viewport.zoom
                : 0) + 'px',
            '--offset-y-to-group':
              (hideInGroup
                ? (groupId!.position.y -
                    (comment.rectangle.y + draggedPosition.y)) *
                  viewport.zoom
                : 0) + 'px',

            left: (comment.rectangle.x + draggedPosition.x) * viewport.zoom,
            top: (comment.rectangle.y + draggedPosition.y) * viewport.zoom,
          } as React.CSSProperties
        }
      >
        <div className="absolute bottom-0 left-0 flex flex-row gap-2">
          <div
            //@ts-ignore
            onMouseDown={startListen}
            className={classNames(
              'group z-[10] overflow-clip rounded-2xl rounded-bl-none bg-white shadow transition-all duration-200 hover:z-50 hover:opacity-100',
              {
                ['!pointer-events-none']: viewport.isBusy,
                ['pointer-events-auto']: !isSelected,
                ['pointer-events-none ring-2 ring-accent']: isSelected,
                ['opacity-75']: comment.isResolved && !isSelected,
              }
            )}
          >
            <div
              style={
                {
                  '--height': (rect?.height ?? 0) + 8 + 'px',
                  '--width':
                    16 +
                    Math.min(uniqueCommentsByAuthors.length, 4) * 16 +
                    (uniqueCommentsByAuthors.length > 4 ? 24 : 0) +
                    'px',
                } as React.CSSProperties
              }
              className={classNames(
                'relative z-[50] flex h-8 w-[--width] flex-row gap-0 p-1 transition-all duration-300 group-hover:h-[--height] group-hover:w-[240px] group-hover:p-3',
                { ['h-[--height] w-[240px] p-3']: isExpanded }
              )}
            >
              {uniqueCommentsByAuthors.slice(0, 4).map((comment, index) => (
                <div
                  key={'avatar-' + comment.id}
                  style={{ zIndex: uniqueCommentsByAuthors.length - index }}
                  className={classNames(
                    'h-6 w-6 overflow-clip rounded-full border-2 border-white bg-white transition-all duration-300 group-hover:opacity-0',
                    {
                      ['-ml-2 transition-all duration-300 group-hover:ml-1 ']:
                        index > 0,
                    }
                  )}
                >
                  <img
                    draggable={false}
                    key={comment.author.id}
                    className={classNames('h-full w-full object-cover', {
                      ['opacity-50']: !isSelected,
                      ['opacity-25']: comment.isResolved,
                    })}
                    crossOrigin="anonymous"
                    src={comment.author.avatarUrl}
                  />
                </div>
              ))}
              {uniqueCommentsByAuthors.length > 4 && (
                <div
                  className={classNames(
                    '-ml-0 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white bg-gray-700 transition-all duration-300 group-hover:h-5 group-hover:w-5 group-hover:opacity-0',
                    { ['h-5 w-5 opacity-0']: isExpanded }
                  )}
                >
                  <p className="z-[5] -ml-[2px] text-[9px] text-white">
                    {`+${uniqueCommentsByAuthors.length - 4}`}
                  </p>
                </div>
              )}

              <div
                style={
                  {
                    '--height': (rect?.height ?? 0) + 8 + 'px',
                    '--width':
                      32 +
                      Math.min(uniqueCommentsByAuthors.length, 4) * 16 +
                      (uniqueCommentsByAuthors.length > 4 ? 20 : 0) +
                      'px',
                  } as React.CSSProperties
                }
                className={classNames(
                  'absolute bottom-0 left-0 z-[20] flex h-8 w-[--width] flex-col justify-end overflow-clip opacity-0 transition-all duration-300',
                  {
                    ['group-hover:h-[--height] group-hover:w-[240px] group-hover:opacity-100']:
                      !isSelected,
                    ['h-[--height] w-[240px] opacity-100']:
                      isExpanded && !isSelected,
                  }
                )}
              >
                <div
                  ref={bubbleRef}
                  className="min-w-[240px] overflow-clip break-all transition-all duration-300 group-hover:m-0"
                >
                  <BubbleCommentItem
                    isExpanded={isExpanded}
                    isDragged={isDragged}
                    key={comment.id}
                    data={comment}
                    index={data.length - index - 1}
                    isLast={true}
                  />
                </div>
              </div>
            </div>
          </div>

          <div
            className={classNames(
              'relative z-[200] transition-all duration-200',
              {
                ['pointer-events-auto opacity-100 delay-200']: isSelected,
                ['pointer-events-none invisible opacity-0']: !isSelected,
              }
            )}
          >
            {isSelected && (
              <CommentsThread
                data={[...[comment], ...(comment.replies ?? [])]}
                nextCommentId={nextCommentId}
                previousCommentId={previousCommentId}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
)

ViewportCommentBubble.displayName = 'ViewportCommentBubble'
