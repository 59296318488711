import {
  Project,
  getEntryOrThrow,
  getPosition,
  getSize,
} from '@aninix-inc/model'
import { useHotkeys } from 'react-hotkeys-hook'

import { Viewport } from '../../stores'
import { useTrackKeyPress } from './track-key-press'

type Payload = {
  project: Project
  viewport: Viewport
  onKeyPress: (hotkey: string) => void
}
export function useViewportHotkeys({ project, viewport, onKeyPress }: Payload) {
  const trackKeyPress = useTrackKeyPress(onKeyPress)

  // @NOTE: zoom to fit
  useHotkeys('1', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('1')

    const entry = getEntryOrThrow(project)
    const position = getPosition(entry)
    const size = getSize(entry)
    viewport.zoomToRect({
      x: position.x,
      y: position.y,
      width: size.x,
      height: size.y,
    })
  })

  // @NOTE: zoom in viewport
  useHotkeys('=', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('=')
    viewport.zoomToPoint({
      point: viewport.center,
      zoomStep: 1.25,
    })
  })

  // @NOTE: zoom out viewport
  useHotkeys('-', (e) => {
    e.preventDefault()
    e.stopPropagation()
    trackKeyPress('-')
    viewport.zoomToPoint({
      point: viewport.center,
      zoomStep: 0.75,
    })
  })
}
