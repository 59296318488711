import { Entity, VectorPathsComponent } from '@aninix-inc/model'
import { INode } from 'svgson'

enum WindingRule {
  Nonzero = 'NONZERO',
  Evenodd = 'EVENODD',
  None = 'NONE',
}

export const applyPath = (entity: Entity, node: INode) => {
  const { d, fillRule } = node.attributes

  const windingRule =
    fillRule === 'nonzero'
      ? WindingRule.Nonzero
      : fillRule === 'evenodd'
        ? WindingRule.Evenodd
        : WindingRule.None

  entity.updateComponent(VectorPathsComponent, [
    {
      data: d,
      windingRule,
    },
  ])
}
