// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ``, "",{"version":3,"sources":[],"names":[],"mappings":"","sourceRoot":""}]);
// Exports
export var highlight = `var(--figma-color-bg-brand)`;
export var bg = `var(--figma-color-bg)`;
export var rect = `var(--aninix-color-foreground-black-1)`;
export var lines = `var(--aninix-color-foreground-black-1)`;
export var speed = `var(--aninix-color-foreground-black-1)`;
export var value = `var(--figma-color-text)`;
export var valueDisabled = `var(--figma-color-text-disabled)`;
export default ___CSS_LOADER_EXPORT___;
