import {
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  Entity,
  FillsRelationsAspect,
  MaskComponent,
  PaintType,
  PaintTypeComponent,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import * as R from 'ramda'
import { ImageLayers } from './image-layers'
import { NullLayer } from './null-layer'
import { Base } from './properties/base'
import { DropShadow, LayerBlur } from './properties/effects'
import { EmptyGroup } from './properties/empty-group'
import { Fill, Fills } from './properties/fills'
import { shouldRenderPaint } from './properties/paint'
import { Paths } from './properties/paths'
import { ShapeGroup } from './properties/shape-group'
import { StrokeLayers } from './stroke-layers'
import {
  LottieAsset,
  LottieEffect,
  LottieLayer,
  LottieLayerType,
  LottieShapeLayer,
} from './types'

export function ShapeLayer(
  payload: {
    node: Entity
    parent?: number
  },
  assets: LottieAsset[]
): LottieLayer[] {
  const { node, parent } = payload

  const nullLayer = NullLayer({ node, parent })
  const baseProperties = Base({
    node,
    parent: nullLayer.ind,
    skipAnimation: true,
  })
  const paths = Paths({ node })
  const layerBlurs: LottieEffect[] = R.defaultTo(
    [],
    node.getAspectOrThrow(EffectsRelationsAspect)
  )
    .getChildrenList()
    .filter(
      (effect) =>
        effect.getComponentOrThrow(EffectTypeComponent).value ===
          EffectType.LayerBlur &&
        effect.getComponentOrThrow(VisibleInViewportComponent).value
    )
    .map((effect) => LayerBlur(effect))

  // @NOTE: for some reason fills should be reversed.
  // @TODO: Check model.
  const fillLayers: (LottieShapeLayer | LottieLayer)[] = R.reverse(
    R.defaultTo(
      [],
      node.getAspectOrThrow(FillsRelationsAspect)
    ).getChildrenList()
  )
    .filter(shouldRenderPaint)
    .flatMap((paint) => {
      if (
        paint.getComponentOrThrow(PaintTypeComponent).value === PaintType.Image
      ) {
        return ImageLayers({ node, paint, parent: nullLayer.ind }, assets)
      }

      if (layerBlurs.length > 0) {
        return [
          {
            ty: LottieLayerType.Shape,
            ...baseProperties,

            shapes: [ShapeGroup([...paths, Fill({ node, paint })])],
            ef: layerBlurs,
          },
        ]
      }

      return [
        {
          ty: LottieLayerType.Shape,
          ...baseProperties,

          shapes: [ShapeGroup([...paths, Fill({ node, paint })])],
        },
      ]
    })

  const strokeLayers = StrokeLayers({ node, parent: nullLayer.ind })

  const dropShadowLayers = R.defaultTo(
    [],
    node.getAspectOrThrow(EffectsRelationsAspect)
  )
    .getChildrenList()
    .filter(
      (effect) =>
        effect.getComponentOrThrow(EffectTypeComponent).value ===
          EffectType.DropShadow &&
        effect.getComponentOrThrow(VisibleInViewportComponent).value
    )
    .map((effect) => DropShadow(effect))
    .map(
      (dropShadow): LottieShapeLayer => ({
        ty: LottieLayerType.Shape,
        ...baseProperties,
        shapes: [ShapeGroup([...paths, ...Fills({ node })]), EmptyGroup(node)],
        ef: [dropShadow],
      })
    )

  if (
    (fillLayers.length === 0 && strokeLayers.length === 0) ||
    // @NOTE: ignore layers completely if layer is mask.
    // We are handling masks through precomps.
    node.getComponentOrThrow(MaskComponent).value
  ) {
    return []
  }

  return [
    nullLayer,
    ...strokeLayers,
    ...fillLayers,
    ...dropShadowLayers,
  ].filter((l) => l != null)
}
