import * as React from 'react'
import { Link } from 'react-router-dom'
import { AninixIcon, DiscordIcon, TwitterIcon, YoutubeIcon } from './icons'

export interface IProps {}
export const FollowUsFooter: React.FCC<IProps> = () => (
  <div className="flex flex-row gap-1 border-t border-solid border-gray-200 bg-gray-50 items-center justify-center pl-6 pr-3 py-2">
    <p className="font-description text-left font-body text-sm font-normal w-full text-gray-500">
      Follow us for updates:
    </p>
    <div className="flex flex-row gap-2 ">
      <Link className="scale-[0.8]" to={'https://aninix.com/'}>
        <AninixIcon />
      </Link>
      <Link className="scale-[0.8]" to={'https://www.aninix.com/twitter'}>
        <TwitterIcon />
      </Link>
      <Link className="scale-[0.8]" to={'https://www.aninix.com/youtube'}>
        <YoutubeIcon />
      </Link>
      <Link className="scale-[0.8]" to={'https://www.aninix.com/discord'}>
        <DiscordIcon />
      </Link>
    </div>
  </div>
)

FollowUsFooter.displayName = 'FollowUsFooter'
