import * as React from 'react'
import { Loader } from '../loader'
import { Border } from './border'

export interface IProps {
  name: string
  isLoading?: boolean
}
export const SingleItemSelected: React.FCC<IProps> = ({
  name,
  isLoading = false,
}) => (
  <div className="flex relative justify-center">
    <div className="stroke-secondary">
      <Border />
    </div>
    <div className="absolute inset-0 flex items-center justify-center">
      <div className="flex flex-col gap-1 items-center translate-y-2">
        <div className="flex flex-row gap-1 justify-center">
          {isLoading ? <Loader size={16} /> : <FrameIcon />}
          <p
            style={{ lineBreak: 'anywhere' }}
            className="text-xs w-max max-w-[210px] font-semibold text-black/80 line-clamp-1"
          >{`${name}`}</p>
        </div>
        <p className="text-xs font-normal w-fit text-black/80">{`selected`}</p>
      </div>
    </div>
  </div>
)

SingleItemSelected.displayName = 'SingleItemSelected'

const FrameIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 4H14M2 12H14M4 2V14M12 2V14"
      stroke="black"
      strokeOpacity="0.8"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
