import * as React from 'react'
import { Link } from 'react-router-dom'

export const SuccessSvg: React.FCC = () => (
  <svg width="176" height="80" viewBox="0 0 176 80" fill="none">
    <rect
      x="96"
      y="-3.05176e-05"
      width="80"
      height="80"
      rx="16"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M148.85 39.975L131.075 55.8V26.55L124.775 29.8507V61.575L128.75 64.8L158 38.55L128.3 15L122 18.3L148.85 39.975Z"
      fill="white"
    />
    <rect
      y="-3.05176e-05"
      width="80"
      height="80"
      rx="16"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M32.5 62.3333C36.64 62.3333 40 58.9733 40 54.8333V47.3333H32.5C28.36 47.3333 25 50.6933 25 54.8333C25 58.9733 28.36 62.3333 32.5 62.3333Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M25 39.8332C25 35.6933 28.36 32.3333 32.5 32.3333H40V47.3332H32.5C28.36 47.3332 25 43.9732 25 39.8332Z"
      fill="white"
    />
    <path
      d="M25 24.8335C25 20.6935 28.36 17.3335 32.5 17.3335H40V32.3335H32.5C28.36 32.3335 25 28.9735 25 24.8335Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M40 17.3333H47.5C51.64 17.3333 55 20.6933 55 24.8333C55 28.9733 51.64 32.3333 47.5 32.3333H40V17.3333Z"
      fill="white"
    />
    <path
      d="M55 39.8332C55 43.9732 51.64 47.3332 47.5 47.3332C43.36 47.3332 40 43.9732 40 39.8332C40 35.6933 43.36 32.3333 47.5 32.3333C51.64 32.3333 55 35.6933 55 39.8332Z"
      fill="white"
    />
    <path
      d="M88 55C96.2806 55 103 48.2805 103 40C103 31.7194 96.2806 25 88 25C79.7194 25 73 31.7194 73 40C73 48.2805 79.7194 55 88 55Z"
      fill="white"
      stroke="#1BC47D"
      strokeWidth="4"
    />
    <path
      d="M94.1875 36.0628L86.3125 43.9375L82.375 40.0003"
      stroke="#1BC47D"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

interface IProps {}
export const Success: React.FCC<IProps> = ({}) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex w-[360px] flex-col items-center rounded-[10px] bg-green py-[40px] px-[20px] text-center text-white">
        <SuccessSvg />

        <h2 className="mt-[40px] font-header text-[28px] font-semibold leading-[36px]">
          Success!
        </h2>

        <p className="font-description mt-[16px] font-body text-[20px] font-normal leading-[30px]">
          Your Figma account is now connected to Aninix.
          <span className="my-[10px] block" />
          You can close this window <br />
          and get back to Editor.
        </p>
      </div>

      <Link to="/" className="mt-4 text-[#374FD5]">
        Or go directly to home
      </Link>
    </div>
  )
}
