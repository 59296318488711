import { Entity } from '@aninix-inc/model'

import {
  commitUndo,
  EntityType,
  FpsComponent,
  getSelection,
  Root,
  SelectionSystem,
  TimeComponent,
} from '@aninix-inc/model'
import {
  getGroupFromKeyframe,
  getVisibleKeyframes,
  KeyModificator,
  TimeFormat,
  usePlayback,
  useProject,
  useSession,
  useSettings,
} from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'

export const useUpdateTime = () => {
  const project = useProject()
  const playback = usePlayback()
  const session = useSession()
  const selection = project.getSystemOrThrow(SelectionSystem)
  const getSnappedTime = useSnappedTime()

  const update = React.useCallback(
    (time: number) => {
      playback.pause()
      if (session.keyModificators.includes(KeyModificator.Shift)) {
        playback.updateTime(getSnappedTime(time))
      } else {
        playback.updateTime(time)
      }

      const selectedKeyframes = getSelection(project, EntityType.Keyframe)

      if (selectedKeyframes.length > 0) {
        const nodesToSelect: Entity[] = []
        selectedKeyframes.forEach((keyframe) => {
          const group = getGroupFromKeyframe(keyframe)
          if (group.layer != null) nodesToSelect.push(group.layer)
        })
        selection.replace(R.uniq(nodesToSelect.map((n) => n.id)))
      }

      commitUndo(project)
    },
    [playback, session, selection, project, getSnappedTime]
  )

  return update
}

function roundBy(value: number, options?: { by: number }): number {
  const roundBy = options?.by || 5
  const valueToWork = value
  const diff = valueToWork % roundBy
  const full = valueToWork - diff

  if (diff < roundBy / 2) {
    return full
  }

  return full + roundBy
}

const useSnappedTime = () => {
  const project = useProject()
  const { timeFormat } = useSettings()

  const projectFps = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(FpsComponent).value

  const minTimeThresholdToSnap =
    timeFormat === TimeFormat.Frames ? (1 / projectFps) * 10 : 0.1

  const getSnappedTime = (time: number) => {
    const sortedKeyframes = R.sort((left, right) => {
      return (
        Math.abs(time - left.getComponentOrThrow(TimeComponent).value) -
        Math.abs(time - right.getComponentOrThrow(TimeComponent).value)
      )
    }, getVisibleKeyframes(project))

    const closestKeyframe = R.head(sortedKeyframes)

    if (closestKeyframe != null) {
      const closestTime =
        closestKeyframe.getComponentOrThrow(TimeComponent).value
      const timeDifference = Math.abs(closestTime - time)
      if (timeDifference < minTimeThresholdToSnap) {
        return closestTime
      }
    }

    const closestTime = roundBy(time, { by: minTimeThresholdToSnap })
    const timeDifference = Math.abs(closestTime - time)

    if (timeDifference < minTimeThresholdToSnap) {
      return closestTime
    }

    return time
  }

  return getSnappedTime
}
