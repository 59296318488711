// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes F3coVKWX5pTYAHEYZJ3F{0%{transform:translate(-50%, calc(-75% - 12px));opacity:0}100%{transform:translate(-50%, calc(-100% - 12px));opacity:1}}.s5BqewsL4OXejDBOkrwy{position:absolute;top:100%;left:50%;z-index:999;animation-name:F3coVKWX5pTYAHEYZJ3F;animation-duration:150ms;animation-timing-function:cubic-bezier(0, 0, 0.15, 1);animation-fill-mode:both;pointer-events:all}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/viewport/index.scss"],"names":[],"mappings":"AAEA,gCACE,GACE,4CAAA,CACA,SAAA,CAGF,KACE,6CAAA,CACA,SAAA,CAAA,CAIJ,sBACE,iBAAA,CACA,QAAA,CACA,QAAA,CACA,WAAA,CACA,mCAAA,CACA,wBAAA,CACA,qDAAA,CACA,wBAAA,CACA,kBAAA","sourcesContent":["@use '../../theme/index.scss' as *;\n\n@keyframes slide-in {\n  0% {\n    transform: translate(-50%, calc(-75% - 12px));\n    opacity: 0;\n  }\n\n  100% {\n    transform: translate(-50%, calc(-100% - 12px));\n    opacity: 1;\n  }\n}\n\n.property-menu {\n  position: absolute;\n  top: 100%;\n  left: 50%;\n  z-index: 999;\n  animation-name: slide-in;\n  animation-duration: 150ms;\n  animation-timing-function: cubic-bezier(0, 0, 0.15, 1);\n  animation-fill-mode: both;\n  pointer-events: all;\n}\n"],"sourceRoot":""}]);
// Exports
var _1 = `s5BqewsL4OXejDBOkrwy`;
export { _1 as "property-menu" };
var _2 = `F3coVKWX5pTYAHEYZJ3F`;
export { _2 as "slide-in" };
export default ___CSS_LOADER_EXPORT___;
