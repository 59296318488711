import {
  AnchorPointComponent,
  BlurRadiusComponent,
  BottomLeftCornerRadiusComponent,
  BottomRightCornerRadiusComponent,
  ChildrenRelationsAspect,
  commitUndo,
  Component,
  CornerRadiusComponent,
  DashComponent,
  DashOffsetComponent,
  EndAngleComponent,
  EntityType,
  EntityTypeComponent,
  EntryComponent,
  GapComponent,
  getNode,
  getSortedKeyframes,
  InnerRadiusComponent,
  MaskComponent,
  NodeColorComponent,
  NumberKeyframe,
  OpacityComponent,
  ParentRelationAspect,
  Point2dKeyframe,
  PositionComponent,
  ProgressComponent,
  RgbaKeyframe,
  RgbaValueComponent,
  RotationComponent,
  round,
  ScaleComponent,
  ScaleLockedComponent,
  SelectionSystem,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
  ShadowSpreadComponent,
  SizeComponent,
  SizeLockedComponent,
  SkewComponent,
  SpatialPoint2dKeyframe,
  StartAngleComponent,
  StrokeBottomWeightComponent,
  StrokeLeftWeightComponent,
  StrokeRightWeightComponent,
  StrokeTopWeightComponent,
  StrokeWeightComponent,
  TopLeftCornerRadiusComponent,
  TopRightCornerRadiusComponent,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
  UpdatesSystem,
} from '@aninix-inc/model'
import { icons, TimelineTrack } from '@aninix/app-design-system'
import { getSelection, useComponent, useSystem } from '@aninix/core'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'
import { useInfo } from '..'
import { defaults } from '../../../../defaults'
import { formatAngleValue } from '../../../../helpers'
import { useUi } from '../../../../stores'
import { formatPercents } from '../../../properties-panel/components/keyframes/value'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import { KeyframesPropertyControl } from '../keyframes-property-control'
import { LockablePoint2dValue } from '../property-values/lockable-point-2d'
import { NumberValue } from '../property-values/number'
import { Point2dValue } from '../property-values/point-2d'
import { RgbaValue } from '../property-values/rgba'
import { SpatialPoint2dValue } from '../property-values/spatial-point-2d'
import * as styles from './index.scss'
// import { PropertyInput } from '../property-input'

const iconSize = {
  x: 16,
  y: 16,
}

export type IProps = {
  property: Component
  name: string
  indent: number
  additionalIndent?: number
  iconType?: icons.PropertyType
}

export const Property: React.FC<IProps> = ({
  property,
  name,
  indent: providedIndent,
  additionalIndent = 0,
  iconType,
}) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time } = useInfo()
  useComponent(property)
  const layer = getNode(property)

  if (layer === undefined) throw new Error('Invalid state. Node is not found')

  const isAnyParentSelectedValue = isAnyParentSelected(layer)
  const isLayerSelectedValue = isLayerSelected(layer)
  const isComponentSelectedValue = isComponentSelected(property)
  const visibleColor =
    defaults.nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]

  const color = React.useMemo(() => {
    if (isAnyParentSelectedValue || isLayerSelectedValue)
      return `${visibleColor}10`

    if (isComponentSelectedValue) return `${visibleColor}10`

    return 'transparent'
  }, [
    visibleColor,
    isAnyParentSelectedValue,
    isLayerSelectedValue,
    isComponentSelectedValue,
  ])

  const indent = providedIndent - 1
  const maskColor = 'rgba(0, 0, 0, 0.1)'
  const baseIndent = 3

  const maskType = (() => {
    if (layer.hasComponent(EntryComponent)) return 'none'

    // @TODO: FIXME provide proper logic to handle mask icon
    if (
      layer.getComponentOrThrow(EntityTypeComponent).value !== EntityType.Node
    )
      return 'none'

    const children = layer
      .getAspectOrThrow(ParentRelationAspect)
      .getParentEntityOrThrow()
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()
    const maskIdx = children.findIndex((_node) =>
      _node.hasComponent(MaskComponent)
    )
    const hasMask = maskIdx !== -1

    if (!hasMask) return 'none'

    if (layer.hasComponent(MaskComponent)) return 'none'

    const currentIdx = children.findIndex((_node) => _node.id === layer.id)

    if (hasMask && currentIdx < maskIdx) return 'in-scope'

    return 'between'
  })()

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  const propertyProps: PropertyProps = {
    color,
    maskColor,
    indent,
    property,
    maskType,
    baseIndent,
    additionalIndent,
    visibleColor,
    time,
    name,
    iconType,
  }

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <PropertyEditable {...propertyProps} />
      ) : (
        <PropertyDisplay {...propertyProps} />
      )}
    </div>
  )
}

Property.displayName = 'Property'

type PropertyProps = {
  color: string
  maskColor: string
  indent: number
  property: Component
  maskType: PropertyMaskType
  baseIndent: number
  additionalIndent: number
  visibleColor: string
  time: number
  name: string
  iconType?: icons.PropertyType
}

type PropertyMaskType = 'none' | 'in-scope' | 'between'

const PropertyEditable: React.FC<PropertyProps> = ({
  color,
  maskColor,
  indent,
  property,
  maskType,
  baseIndent,
  additionalIndent,
  visibleColor,
  time,
  name,
  iconType,
}) => {
  useComponent(property)
  const project = property.entity.getProjectOrThrow()
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)
  const ui = useUi()
  // @TODO: provide component value getter via functions map
  const keyframes = getSortedKeyframes(property)
  const layer = getNode(property)

  if (layer === undefined) throw new Error('Invalid state. Node is not found')

  const selectPropertyKeyframes = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()

      if (!(e.shiftKey || e.metaKey)) {
        selection.deselectAll()
      }

      const selectedKeyframesIds = getSelection(
        project,
        EntityType.Keyframe
      ).map((k) => k.id)
      const isAllSelected = keyframes
        .map((k) => selectedKeyframesIds.includes(k.id))
        .every((value: boolean | undefined) => value === true)

      if (isAllSelected) {
        selection.deselect(keyframes.map((k) => k.id))
      } else {
        selection.select(keyframes.map((k) => k.id))
      }
      commitUndo(project)
    },
    [selection, project, keyframes]
  )

  const openContextMenu = React.useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      selection.deselectAll()

      ui.openContextMenu(
        [
          {
            title: 'Clear animation',
            onClick: () => {
              project.getSystemOrThrow(UpdatesSystem).batch(() => {
                selection.deselect(keyframes.map((k) => k.id))
                keyframes.forEach((keyframe) => {
                  project.removeEntity(keyframe.id)
                })
                commitUndo(project)
                ui.closeContextMenu()
              })
            },
          },
        ],
        e
      )
    },
    [ui, selection]
  )

  return (
    <TimelineTrack
      className="relative flex flex-row flex-nowrap items-center justify-start"
      variant="property"
      color={color}
      maskColor={maskColor}
      onContextMenu={openContextMenu}
      onClick={selectPropertyKeyframes}
    >
      {R.range(0, indent || 0).map((spacer) => (
        <Spacer key={spacer} />
      ))}

      {maskType !== 'none' ? (
        <div
          className={classNames(
            'relative h-[18px] w-[20px] flex-shrink-0 p-0 px-[2px]',
            {
              [styles.mask__between]: maskType === 'between',
            }
          )}
        />
      ) : (
        <Spacer />
      )}

      {R.range(0, baseIndent + additionalIndent).map((spacer) => (
        <Spacer key={spacer} />
      ))}

      {/* @TODO: recheck this */}
      {Object.values(icons.PropertyType).includes(
        // @ts-ignore
        property.constructor.tag
      ) ? (
        <div className={styles.component}>
          <icons.Property type={iconType!} size={iconSize} />
        </div>
      ) : (
        <>{additionalIndent === 0 && <Spacer />}</>
      )}

      <p
        className={classNames(styles.name, 'min-w-[80px] pl-[4px] m-0 w-full')}
      >
        {name}
      </p>

      <div className="absolute right-0 top-1/2 -translate-y-1/2 pl-4 pr-2">
        <div className="to-[var(--figma-color-bg)]/0 absolute left-0 top-0 z-[1] h-full w-1/4 bg-gradient-to-l from-[var(--figma-color-bg)] via-[var(--figma-color-bg)]" />
        <div className="absolute right-0 top-0 z-[2] h-full w-3/4 bg-[var(--figma-color-bg)]" />

        <div className="relative z-10 flex flex-row">
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              flexShrink: 0,
              width: 96,
            }}
          >
            <div
              onClick={stopPropagation}
              className="w-fit"
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
              }}
            >
              {(property instanceof SkewComponent ||
                property instanceof ShadowOffsetComponent) && (
                <Point2dValue
                  component={property}
                  color={visibleColor}
                  formatValue={(n) => round(n, { fixed: 1 }).toFixed(1)}
                />
              )}

              {property instanceof SizeComponent && (
                <LockablePoint2dValue
                  component={property}
                  lockableComponent={property.entity.getComponentOrThrow(
                    SizeLockedComponent
                  )}
                  color={visibleColor}
                  formatValue={(n) => round(n, { fixed: 1 }).toFixed(1)}
                />
              )}

              {property instanceof ScaleComponent && (
                <LockablePoint2dValue
                  component={property}
                  lockableComponent={property.entity.getComponentOrThrow(
                    ScaleLockedComponent
                  )}
                  color={visibleColor}
                  formatValue={formatPercents}
                  before={(v) => Math.round(v * 100)}
                  after={(v) => v / 100}
                />
              )}

              {property instanceof PositionComponent && (
                <SpatialPoint2dValue
                  component={property}
                  color={visibleColor}
                  formatValue={(n) => round(n, { fixed: 1 }).toFixed(1)}
                />
              )}

              {(property instanceof RotationComponent ||
                property instanceof StartAngleComponent) && (
                <NumberValue
                  component={property}
                  color={visibleColor}
                  format={formatAngleValue}
                />
              )}

              {(property instanceof CornerRadiusComponent ||
                property instanceof TopLeftCornerRadiusComponent ||
                property instanceof TopRightCornerRadiusComponent ||
                property instanceof BottomLeftCornerRadiusComponent ||
                property instanceof BottomRightCornerRadiusComponent ||
                property instanceof ShadowRadiusComponent ||
                property instanceof ShadowSpreadComponent ||
                property instanceof BlurRadiusComponent ||
                property instanceof StrokeWeightComponent ||
                property instanceof StrokeTopWeightComponent ||
                property instanceof StrokeRightWeightComponent ||
                property instanceof StrokeBottomWeightComponent ||
                property instanceof StrokeLeftWeightComponent) && (
                <NumberValue
                  component={property}
                  color={visibleColor}
                  format={(n) => round(n, { fixed: 1 }).toFixed(1)}
                />
              )}

              {(property instanceof RgbaValueComponent ||
                property instanceof ShadowColorComponent) && (
                <RgbaValue component={property} />
              )}

              {(property instanceof OpacityComponent ||
                property instanceof ProgressComponent ||
                property instanceof TrimStartComponent ||
                property instanceof TrimEndComponent ||
                property instanceof TrimOffsetComponent ||
                property instanceof EndAngleComponent ||
                property instanceof InnerRadiusComponent) && (
                <NumberValue
                  component={property}
                  color={visibleColor}
                  min={0}
                  max={100}
                  before={(v) => Math.round(v * 100)}
                  after={(v) => v / 100}
                  format={formatPercents}
                />
              )}
            </div>
          </div>

          <div onClick={stopPropagation} className={styles.component}>
            {(property instanceof AnchorPointComponent ||
              property instanceof SkewComponent ||
              property instanceof SizeComponent ||
              property instanceof ShadowOffsetComponent ||
              property instanceof ScaleComponent) && (
              <KeyframesPropertyControl
                property={property}
                KeyframeConstructor={Point2dKeyframe}
              />
            )}

            {property instanceof PositionComponent && (
              <KeyframesPropertyControl
                property={property}
                KeyframeConstructor={SpatialPoint2dKeyframe}
              />
            )}

            {(property instanceof RotationComponent ||
              property instanceof CornerRadiusComponent ||
              property instanceof TopLeftCornerRadiusComponent ||
              property instanceof TopRightCornerRadiusComponent ||
              property instanceof BottomRightCornerRadiusComponent ||
              property instanceof BottomLeftCornerRadiusComponent ||
              property instanceof DashComponent ||
              property instanceof GapComponent ||
              property instanceof DashOffsetComponent ||
              property instanceof TrimStartComponent ||
              property instanceof TrimEndComponent ||
              property instanceof TrimOffsetComponent ||
              property instanceof OpacityComponent ||
              property instanceof ShadowRadiusComponent ||
              property instanceof ShadowSpreadComponent ||
              property instanceof BlurRadiusComponent ||
              property instanceof StartAngleComponent ||
              property instanceof EndAngleComponent ||
              property instanceof InnerRadiusComponent ||
              property instanceof ProgressComponent ||
              property instanceof StrokeWeightComponent ||
              property instanceof StrokeTopWeightComponent ||
              property instanceof StrokeRightWeightComponent ||
              property instanceof StrokeBottomWeightComponent ||
              property instanceof StrokeLeftWeightComponent) && (
              <KeyframesPropertyControl
                property={property}
                KeyframeConstructor={NumberKeyframe}
              />
            )}

            {(property instanceof RgbaValueComponent ||
              property instanceof ShadowColorComponent) && (
              <KeyframesPropertyControl
                property={property}
                KeyframeConstructor={RgbaKeyframe}
              />
            )}
          </div>
        </div>
      </div>
    </TimelineTrack>
  )
}

PropertyEditable.displayName = 'PropertyEditable'

const PropertyDisplay: React.FC<PropertyProps> = React.memo(
  ({
    color,
    maskColor,
    indent,
    property,
    maskType,
    baseIndent,
    additionalIndent,
    visibleColor,
    time,
    name,
    iconType,
  }) => {
    return (
      <TimelineTrack
        className="relative flex flex-row flex-nowrap items-center justify-start"
        variant="property"
        color={color}
        maskColor={maskColor}
        onContextMenu={() => {}}
        onClick={() => {}}
      >
        {R.range(0, indent || 0).map((spacer) => (
          <Spacer key={spacer} />
        ))}

        {maskType !== 'none' ? (
          <div
            className={classNames(
              'relative h-[18px] w-[20px] flex-shrink-0 p-0 px-[2px]',
              {
                [styles.mask__between]: maskType === 'between',
              }
            )}
          />
        ) : (
          <Spacer />
        )}

        {R.range(0, baseIndent + additionalIndent).map((spacer) => (
          <Spacer key={spacer} />
        ))}

        {/* @TODO: recheck this */}
        {Object.values(icons.PropertyType).includes(
          // @ts-ignore
          property.constructor.tag
        ) ? (
          <div className={styles.component}>
            <icons.Property type={iconType!} size={iconSize} />
          </div>
        ) : (
          <>{additionalIndent === 0 && <Spacer />}</>
        )}

        <p
          className={classNames(
            styles.name,
            'min-w-[80px] pl-[4px] m-0 w-full'
          )}
        >
          {name}
        </p>

        <div className="absolute right-0 top-1/2 -translate-y-1/2 pl-4 pr-2">
          <div className="to-[var(--figma-color-bg)]/0 absolute left-0 top-0 z-[1] h-full w-1/4 bg-gradient-to-l from-[var(--figma-color-bg)] via-[var(--figma-color-bg)]" />
          <div className="absolute right-0 top-0 z-[2] h-full w-3/4 bg-[var(--figma-color-bg)]" />

          <div className="relative z-10 flex flex-row">
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                flexShrink: 0,
                width: 96,
              }}
            >
              <div
                onClick={stopPropagation}
                className="w-fit"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                }}
              >
                {(property instanceof SkewComponent ||
                  property instanceof ShadowOffsetComponent) && (
                  <Point2dValue
                    component={property}
                    color={visibleColor}
                    formatValue={(n) => round(n, { fixed: 1 }).toFixed(1)}
                  />
                )}

                {property instanceof SizeComponent && (
                  <LockablePoint2dValue
                    component={property}
                    lockableComponent={property.entity.getComponentOrThrow(
                      SizeLockedComponent
                    )}
                    color={visibleColor}
                    formatValue={(n) => round(n, { fixed: 1 }).toFixed(1)}
                  />
                )}

                {property instanceof ScaleComponent && (
                  <LockablePoint2dValue
                    component={property}
                    lockableComponent={property.entity.getComponentOrThrow(
                      ScaleLockedComponent
                    )}
                    color={visibleColor}
                    formatValue={formatPercents}
                    before={(v) => Math.round(v * 100)}
                    after={(v) => v / 100}
                  />
                )}

                {property instanceof PositionComponent && (
                  <SpatialPoint2dValue
                    component={property}
                    color={visibleColor}
                    formatValue={(n) => round(n, { fixed: 1 }).toFixed(1)}
                  />
                )}

                {(property instanceof RotationComponent ||
                  property instanceof StartAngleComponent) && (
                  <NumberValue
                    component={property}
                    color={visibleColor}
                    format={formatAngleValue}
                  />
                )}

                {(property instanceof CornerRadiusComponent ||
                  property instanceof TopLeftCornerRadiusComponent ||
                  property instanceof TopRightCornerRadiusComponent ||
                  property instanceof BottomLeftCornerRadiusComponent ||
                  property instanceof BottomRightCornerRadiusComponent ||
                  property instanceof ShadowRadiusComponent ||
                  property instanceof ShadowSpreadComponent ||
                  property instanceof BlurRadiusComponent ||
                  property instanceof StrokeWeightComponent ||
                  property instanceof StrokeTopWeightComponent ||
                  property instanceof StrokeRightWeightComponent ||
                  property instanceof StrokeBottomWeightComponent ||
                  property instanceof StrokeLeftWeightComponent) && (
                  <NumberValue
                    component={property}
                    color={visibleColor}
                    format={(n) => round(n, { fixed: 1 }).toFixed(1)}
                  />
                )}

                {(property instanceof RgbaValueComponent ||
                  property instanceof ShadowColorComponent) && (
                  <RgbaValue component={property} />
                )}

                {(property instanceof OpacityComponent ||
                  property instanceof ProgressComponent ||
                  property instanceof TrimStartComponent ||
                  property instanceof TrimEndComponent ||
                  property instanceof TrimOffsetComponent ||
                  property instanceof EndAngleComponent ||
                  property instanceof InnerRadiusComponent) && (
                  <NumberValue
                    component={property}
                    color={visibleColor}
                    min={0}
                    max={100}
                    before={(v) => Math.round(v * 100)}
                    after={(v) => v / 100}
                    format={formatPercents}
                  />
                )}
              </div>
            </div>

            <div onClick={stopPropagation} className={styles.component}>
              {(property instanceof AnchorPointComponent ||
                property instanceof SkewComponent ||
                property instanceof SizeComponent ||
                property instanceof ShadowOffsetComponent ||
                property instanceof ScaleComponent) && (
                <KeyframesPropertyControl
                  property={property}
                  KeyframeConstructor={Point2dKeyframe}
                />
              )}

              {property instanceof PositionComponent && (
                <KeyframesPropertyControl
                  property={property}
                  KeyframeConstructor={SpatialPoint2dKeyframe}
                />
              )}

              {(property instanceof RotationComponent ||
                property instanceof CornerRadiusComponent ||
                property instanceof TopLeftCornerRadiusComponent ||
                property instanceof TopRightCornerRadiusComponent ||
                property instanceof BottomRightCornerRadiusComponent ||
                property instanceof BottomLeftCornerRadiusComponent ||
                property instanceof DashComponent ||
                property instanceof GapComponent ||
                property instanceof DashOffsetComponent ||
                property instanceof TrimStartComponent ||
                property instanceof TrimEndComponent ||
                property instanceof TrimOffsetComponent ||
                property instanceof OpacityComponent ||
                property instanceof ShadowRadiusComponent ||
                property instanceof ShadowSpreadComponent ||
                property instanceof BlurRadiusComponent ||
                property instanceof StartAngleComponent ||
                property instanceof EndAngleComponent ||
                property instanceof InnerRadiusComponent ||
                property instanceof ProgressComponent ||
                property instanceof StrokeWeightComponent ||
                property instanceof StrokeTopWeightComponent ||
                property instanceof StrokeRightWeightComponent ||
                property instanceof StrokeBottomWeightComponent ||
                property instanceof StrokeLeftWeightComponent) && (
                <KeyframesPropertyControl
                  property={property}
                  KeyframeConstructor={NumberKeyframe}
                />
              )}

              {(property instanceof RgbaValueComponent ||
                property instanceof ShadowColorComponent) && (
                <KeyframesPropertyControl
                  property={property}
                  KeyframeConstructor={RgbaKeyframe}
                />
              )}
            </div>
          </div>
        </div>
      </TimelineTrack>
    )
  },
  (prev, next) => {
    if (prev.additionalIndent !== next.additionalIndent) return false
    if (prev.baseIndent !== next.baseIndent) return false
    if (prev.indent !== next.indent) return false
    if (prev.maskType !== next.maskType) return false
    if (prev.visibleColor !== next.visibleColor) return false
    if (prev.name !== next.name) return false
    if (prev.iconType !== next.iconType) return false
    if (prev.color !== next.color) return false
    if (prev.maskColor !== next.maskColor) return false
    return true
  }
)

PropertyDisplay.displayName = 'PropertyDisplay'

const stopPropagation = (
  e: React.MouseEvent<HTMLDivElement, MouseEvent>
): void => {
  e.preventDefault()
  e.stopPropagation()
}

export const Spacer: React.FC = React.memo(
  () => (
    <div className="h-[1px] p-0 px-[2px]">
      <span className="block w-[16px]" />
    </div>
  ),
  () => true
)

Spacer.displayName = 'Spacer'
