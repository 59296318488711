import { paper } from '@aninix-inc/renderer'
import * as R from 'ramda'
import { BezierPoint } from './bezier-point'

export function bezierPointsToPaperPath(payload: {
  regions: BezierPoint[][]
}): paper.Path {
  const { regions } = payload

  if (regions.length === 0) {
    return new paper.Path()
  }

  const path = new paper.Path()

  for (let i = 0; i < regions.length; i += 1) {
    const region = regions[i]
    const points = region.map((point) => point.toAbsoluteJson())

    for (let j = 0; j < points.length; j += 1) {
      const point = points[j]

      // @NOTE: set start position to correct coordinate
      if (j === 0) {
        path.moveTo(new paper.Point(point.point.x, point.point.y))
        continue
      }

      const prevPoint = points[j - 1]

      const point0 = prevPoint.endTangent.x
      const point1 = prevPoint.endTangent.y
      const point2 = point.startTangent.x
      const point3 = point.startTangent.y
      const point4 = point.point.x
      const point5 = point.point.y

      // @NOTE: draw next point
      path.cubicCurveTo(
        new paper.Point(point0, point1),
        new paper.Point(point2, point3),
        new paper.Point(point4, point5)
      )

      // @NOTE: close the spline if needed
      if (j === points.length - 1 && i === regions.length - 1) {
        const firstPoint = R.head(R.head(regions)!)!.toAbsoluteJson()
        const lastPoint = R.last(R.last(regions)!)!.toAbsoluteJson()
        const isClosed =
          firstPoint.point.x === lastPoint.point.x &&
          firstPoint.point.y === lastPoint.point.y

        if (isClosed) {
          path.closePath()
        }
      }
    }
  }

  return path
}
