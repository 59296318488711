import {
  Component,
  commitUndo,
  getAnimatableValue,
  mixed,
} from '@aninix-inc/model'
import { InputWithIcon } from '@aninix/app-design-system'
import { useComponents } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useFormatTime, useFormattedTime } from '../../formatted-time'
import { useThreshold } from '../../threshold'

// @TODO: provide generic types
export interface IProps {
  components: Component[]
  icon?: React.ReactNode
  min?: number
  max?: number
  /**
   * Run before provide value to UI
   */
  before?: (number: number) => number
  /**
   * Run before provide value to model
   */
  after?: (number: number) => number
  dragDisabled?: boolean
  disabled?: boolean
  iconWide?: boolean
  iconWidth?: number
  width?: number
}
export const TimeValue: React.FC<IProps> = observer(
  ({
    components,
    icon,
    min,
    max,
    before = R.identity,
    after = R.identity,
    dragDisabled,
    disabled,
    iconWide,
    iconWidth,
    width,
  }) => {
    useComponents(components)
    const threshold = useThreshold()
    const { toSeconds, toFormat } = useFormatTime()

    const equals = R.all(
      (component) =>
        getAnimatableValue(component) === getAnimatableValue(components[0]),
      components
    )

    const updateValue = React.useCallback(
      (newValue: number) => {
        components.forEach((component) => {
          component.entity.updateComponent(
            // @ts-ignore
            component.constructor,
            after(toSeconds(newValue))
          )
        })
        commitUndo(components[0].entity.getProjectOrThrow())
      },
      [components]
    )

    const updateByDelta = React.useCallback(
      (providedDelta: number) => {
        const delta = after(providedDelta)

        components.forEach((component) => {
          component.entity.updateComponent(
            // @ts-ignore
            component.constructor,
            // @ts-ignore
            (value) => value + delta
          )
        })
      },
      [components]
    )

    const id = components.map((component) => component.id).join('-')
    const value = equals
      ? before(getAnimatableValue(components[0]) as number)
      : mixed
    const formattedTime = useFormattedTime(value)
    const onEndChange = React.useCallback(() => {
      commitUndo(components[0].entity.getProjectOrThrow())
    }, [components])

    const formatTimeValue = React.useCallback(
      (providedValue: number): string =>
        `${providedValue}${formattedTime.suffix}`,
      [formattedTime]
    )

    return (
      <InputWithIcon
        value={formattedTime.value}
        id={id}
        threshold={threshold}
        icon={icon}
        onChange={updateValue}
        onDeltaChange={updateByDelta}
        onEndChange={onEndChange}
        format={formatTimeValue}
        min={min ? toFormat(min) : undefined}
        max={max ? toFormat(max) : undefined}
        dragDisabled={dragDisabled}
        disabled={disabled}
        iconWide={iconWide}
        iconWidth={iconWidth}
        width={width}
      />
    )
  }
)

TimeValue.displayName = 'TimeValue'
