import classNames from 'classnames'
import * as React from 'react'

import { Close } from '../icons'
import * as styles from './index.scss'

const iconSize = {
  x: 8,
  y: 8,
}

export interface IProps {
  id: string
  title: string
  isSelected: boolean
  forceSelect?: boolean
  onSelect: (id: string) => void
  onClose: (id: string) => void
}
export const ProjectTab: React.FCC<IProps> = ({
  id,
  title,
  isSelected,
  forceSelect = false,
  onSelect,
  onClose,
}) => {
  const handleSelect = React.useCallback(() => {
    if (isSelected && forceSelect === false) {
      return
    }

    onSelect(id)
  }, [onSelect, id, isSelected, forceSelect])

  const handleClose = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()

      onClose(id)
    },
    [onClose, id]
  )

  return (
    <div
      className={classNames(styles.container, {
        [styles['container--selected']]: isSelected,
      })}
      onClick={handleSelect}
    >
      <p className={styles.title}>{title}</p>

      <button onClick={handleClose} className={styles.close}>
        <Close size={iconSize} />
      </button>
    </div>
  )
}
