import * as React from 'react'

const defaultSize = { x: 14, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const VerticalCenter: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 5.5L3.5 0.5H5.5V5.5L8.5 5.5V2.5H10.5V5.5H13.5V6.5H10.5V9.5H8.5V6.5L5.5 6.5V11.5H3.5L3.5 6.5H0.5V5.5H3.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
