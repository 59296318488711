// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nie2N4BlcVeSlun3T1mK{position:fixed;right:20px;bottom:20px;z-index:10001;display:flex;flex-direction:column;align-items:center;justify-content:center;width:36px;height:36px;color:#fff;font-size:14px;background-color:#000;border:none;border-radius:50%}.d1U88rnsTMjLI3Its3jd{overflow:visible}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/helper/index.scss"],"names":[],"mappings":"AAEA,sBACE,cAAA,CACA,UAAA,CACA,WAAA,CACA,aAAA,CACA,YAAA,CACA,qBAAA,CACA,kBAAA,CACA,sBAAA,CACA,UAAA,CACA,WAAA,CACA,UAAA,CACA,cAAA,CACA,qBAAA,CACA,WAAA,CACA,iBAAA,CAEF,sBACE,gBAAA","sourcesContent":["@use '../../../theme/index.scss' as *;\n\n.helperBlock {\n  position: fixed;\n  right: 20px;\n  bottom: 20px;\n  z-index: 10001;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 36px;\n  height: 36px;\n  color: white;\n  font-size: 14px;\n  background-color: black;\n  border: none;\n  border-radius: 50%;\n}\n.popupBlock {\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
export var helperBlock = `nie2N4BlcVeSlun3T1mK`;
export var popupBlock = `d1U88rnsTMjLI3Its3jd`;
export default ___CSS_LOADER_EXPORT___;
