import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const GoToNextKeyframe: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M5.586 8h7m0 0l-2-1.999m2 2l-2 2m-1 1l-1.293 1.292a1 1 0 01-1.414 0L3.293 8.707a1 1 0 010-1.414l3.586-3.586a1 1 0 011.414 0L9.586 5"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
