import * as React from 'react'

import { Row } from './member-row'

const HeadTitle: React.FCC<{ label: string }> = ({ label }) => (
  <p className="font-body text-sm text-gray-400">{label}</p>
)

export const MembersHeader: React.FCC = () => (
  <Row>
    <div />
    <HeadTitle label="Name" />
    <HeadTitle label="Member since" />
    <HeadTitle label="Permission" />
    <HeadTitle label="Admin role" />
    <HeadTitle label="Status" />
    <div />
  </Row>
)

MembersHeader.displayName = 'MembersHeader'
