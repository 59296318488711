import { AnimationItem } from 'lottie-web'
import * as React from 'react'

const extractCurrentProps = (animationItem: AnimationItem) => {
  const {
    isLoaded,
    isPaused,
    currentFrame,
    currentRawFrame,
    playCount,
    autoplay,
    playDirection,
    playSpeed,
  } = animationItem
  return {
    isLoaded,
    isPaused,
    currentFrame,
    currentRawFrame,
    playCount,
    autoplay,
    playDirection,
    playSpeed,
  }
}

export const useLottie = (animationItem: AnimationItem) => {
  const [currentProps, setCurrentProps] = React.useState(
    extractCurrentProps(animationItem)
  )

  const play = React.useCallback(() => {
    animationItem.play()
    updateProps()
  }, [animationItem])
  const pause = React.useCallback(() => {
    animationItem.pause()
    updateProps()
  }, [animationItem])

  const updateProps = React.useCallback(() => {
    setCurrentProps(extractCurrentProps(animationItem))
  }, [animationItem])

  React.useEffect(() => {
    animationItem.addEventListener('enterFrame', updateProps)
    animationItem.addEventListener('complete', updateProps)

    // return () => {
    //   if (animationItem) {
    //     animationItem.removeEventListener('enterFrame', updateProps)
    //     animationItem.removeEventListener('complete', updateProps)
    //   }
    // }
  }, [animationItem])

  return { ...animationItem, ...currentProps, play, pause }
}

useLottie.displayName = 'useLottie'
