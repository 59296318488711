import classNames from 'classnames'
import * as React from 'react'

import * as styles from './styles.scss'

// TODO: move to common
function uppercaseFirstLetter(string: string): string {
  return `${string[0].toUpperCase()}${string.slice(1).toLowerCase()}`
}

type Color = {
  id: string | 'none'
  name: string
  value: string
}
export interface IProps {
  color: string
  onSelect: (color: string) => void
  colors: Color[]
}
export const ColorSelector: React.FCC<IProps> = ({
  color,
  onSelect,
  colors,
}) => (
  <div className={styles.container}>
    {colors.map((currentColor) => (
      <button
        type="button"
        onClick={() => onSelect(currentColor.id)}
        key={currentColor.id}
        className={classNames(styles.item, {
          [styles['item--selected']]: currentColor.value === color,
        })}
      >
        <div className={styles['color-button']}>
          {currentColor.id === 'none' ? (
            <svg
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g stroke={currentColor.value}>
                <rect x="3.5" y="3.5" width="9" height="9" rx="1.5" />
                <path d="M6 10L10 6" strokeLinecap="round" />
              </g>
            </svg>
          ) : (
            <span
              className={styles.color}
              style={{
                backgroundColor: currentColor.value,
              }}
            />
          )}
        </div>

        <p className={styles['color-label']}>
          {uppercaseFirstLetter(currentColor.name)}
        </p>
      </button>
    ))}
  </div>
)
