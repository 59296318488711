import {
  ClipContentComponent,
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  EntityType,
  EntityTypeComponent,
  FillsRelationsAspect,
  NodeType,
  NodeTypeComponent,
  OpacityComponent,
  PaintType,
  PaintTypeComponent,
  Project,
  StrokeAlign,
  StrokeAlignComponent,
  StrokeCap,
  StrokeCapEndComponent,
  StrokeCapStartComponent,
  StrokesRelationsAspect,
  getSortedKeyframes,
} from '@aninix-inc/model'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import * as R from 'ramda'

function capitalizeFirst(str: string): string {
  return startCase(lowerCase(str))
}

export function getListOfNotSupportedTgsFeatures(project: Project): string[] {
  let notSupportedFeaturesArray: string[] = []

  project
    .getEntitiesByPredicate(
      (e) =>
        e.getComponentOrThrow(EntityTypeComponent).value === EntityType.Node
    )
    .forEach((entity) => {
      const nodeType = entity.getComponentOrThrow(NodeTypeComponent)?.value

      if (entity.hasAspect(EffectsRelationsAspect)) {
        for (const effect of entity
          .getAspectOrThrow(EffectsRelationsAspect)
          .getChildrenList()) {
          if (
            [
              EffectType.DropShadow,
              EffectType.LayerBlur,
              EffectType.InnerShadow,
              EffectType.BackgroundBlur,
            ].includes(effect.getComponentOrThrow(EffectTypeComponent).value)
          ) {
            notSupportedFeaturesArray.push(
              capitalizeFirst(
                effect
                  .getComponentOrThrow(EffectTypeComponent)
                  .value.replace('_', ' ')
              )
            )
          }
        }
      }

      if ([NodeType.Polygon, NodeType.Star].includes(nodeType)) {
        notSupportedFeaturesArray.push(
          `${capitalizeFirst(nodeType)} layer type`
        )
      }

      if (nodeType === NodeType.Group) {
        const opacityKeyframes = getSortedKeyframes(
          entity.getComponentOrThrow(OpacityComponent)
        )
        if (opacityKeyframes.length > 0) {
          notSupportedFeaturesArray.push('Groups opacity animation')
        }

        if (entity.getComponentOrThrow(OpacityComponent).value !== 1) {
          notSupportedFeaturesArray.push('Groups opacity not equal to 100%')
        }
      }

      if ([NodeType.Frame, NodeType.Instance].includes(nodeType)) {
        const opacityKeyframes = getSortedKeyframes(
          entity.getComponentOrThrow(OpacityComponent)
        )
        if (entity.getComponentOrThrow(ClipContentComponent).value) {
          notSupportedFeaturesArray.push('Frames with clips content')
        }

        if (opacityKeyframes.length > 0) {
          notSupportedFeaturesArray.push('Frames opacity animation')
        }

        if (entity.getComponentOrThrow(OpacityComponent).value !== 1) {
          notSupportedFeaturesArray.push('Frames opacity not equal to 100%')
        }
      }

      if (entity.hasAspect(FillsRelationsAspect)) {
        for (const fill of entity
          .getAspectOrThrow(FillsRelationsAspect)
          .getChildrenList()) {
          if (
            [PaintType.Image].includes(
              fill.getComponentOrThrow(PaintTypeComponent).value
            )
          ) {
            notSupportedFeaturesArray.push('Images')
          }
        }
      }

      if (entity.hasAspect(StrokesRelationsAspect)) {
        for (const stroke of entity
          .getAspectOrThrow(StrokesRelationsAspect)
          .getChildrenList()) {
          const type = stroke.getComponentOrThrow(PaintTypeComponent).value

          if (
            [PaintType.GradientLinear, PaintType.GradientRadial].includes(type)
          ) {
            notSupportedFeaturesArray.push('Gradient strokes')
          }

          if ([PaintType.Image].includes(type)) {
            notSupportedFeaturesArray.push('Images')
          }
        }

        if (
          [StrokeCap.None, StrokeCap.Round, StrokeCap.Square].includes(
            entity.getComponentOrThrow(StrokeCapStartComponent).value
          ) === false
        ) {
          notSupportedFeaturesArray.push('Arrow heads')
        }

        if (
          [StrokeCap.None, StrokeCap.Round, StrokeCap.Square].includes(
            entity.getComponentOrThrow(StrokeCapEndComponent).value
          ) === false
        ) {
          notSupportedFeaturesArray.push('Arrow heads')
        }

        if (
          entity.getAspectOrThrow(StrokesRelationsAspect).getChildrenList()
            .length > 0 &&
          entity.getComponentOrThrow(StrokeAlignComponent).value !==
            StrokeAlign.Center
        ) {
          notSupportedFeaturesArray.push(
            `${capitalizeFirst(
              entity.getComponentOrThrow(StrokeAlignComponent).value
            )} stroke align`
          )
        }
      }
    })

  return R.uniq(notSupportedFeaturesArray)
}
