import * as styles from './theme/colors.scss'

export function setColorTheme(theme: 'light' | 'dark') {
  document.documentElement.classList.toggle(
    styles['figma-dark'],
    theme === 'dark'
  )
  document.documentElement.classList.toggle(
    styles['figma-light'],
    theme === 'light'
  )

  document.documentElement.classList.toggle(
    styles['aninix-dark'],
    theme === 'dark'
  )
  document.documentElement.classList.toggle(
    styles['aninix-light'],
    theme === 'light'
  )
}

export function handleColorScheme() {
  const isDarkMode =
    window.matchMedia &&
    window.matchMedia('(prefers-color-scheme: dark)').matches
  setColorTheme(isDarkMode ? 'dark' : 'light')

  try {
    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (event) => {
        setColorTheme(event.matches ? 'dark' : 'light')
      })
  } catch (err: unknown) {
    console.warn('matchMedia.addEventListener not supported in your browser')
  }
}
