import { lerp } from '@aninix-inc/model/legacy'
import { useMouseMove } from '@aninix/app-design-system'
import classNames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

export interface IProps {}
export const Scrollable: React.FCC<IProps> = ({ children }) => {
  const containerRef = React.useRef<HTMLDivElement>(null)
  const thumbTopStartRef = React.useRef(0)
  const [scrollOffset, setScrollOffset] = React.useState(0)
  const [isHovered, setIsHovered] = React.useState(false)

  const { thumbTop, thumbHeight } = React.useMemo(() => {
    if (containerRef.current == null) {
      return {
        thumbTop: 0,
        thumbHeight: 0,
      }
    }

    const scrollOffset = containerRef.current!.scrollTop
    const visibleHeight = containerRef.current!.clientHeight
    const totalHeight = containerRef.current!.scrollHeight

    const handlerHeight = visibleHeight / totalHeight
    const handlerHeightPx = handlerHeight * visibleHeight
    const scrollProgress = scrollOffset / (totalHeight - visibleHeight)

    return {
      thumbTop: lerp(scrollProgress, 0, visibleHeight - handlerHeightPx),
      thumbHeight: handlerHeightPx,
    }
  }, [scrollOffset, isHovered])

  const { startListen, isListening, startAtY, offsetY } = useMouseMove({
    element: containerRef.current!,
  })

  const handleScroll = React.useCallback(() => {
    setScrollOffset(containerRef.current!.scrollTop)
  }, [])

  const handleStartListen = React.useCallback(
    (e: any) => {
      thumbTopStartRef.current = thumbTop
      // @ts-ignore
      startListen(e)
    },
    [thumbTop, startListen]
  )

  const handleMouseEnter = React.useCallback(() => {
    const visibleHeight = containerRef.current!.clientHeight
    const totalHeight = containerRef.current!.scrollHeight

    if (visibleHeight >= totalHeight) {
      return
    }

    setIsHovered(true)
    handleScroll()
  }, [])

  const handleMouseLeave = React.useCallback(() => {
    setIsHovered(false)
  }, [])

  React.useEffect(() => {
    containerRef.current?.addEventListener('scroll', handleScroll)

    return () =>
      containerRef.current?.removeEventListener('scroll', handleScroll)
  }, [])

  React.useEffect(() => {
    if (isListening === false) {
      return
    }

    const visibleHeight = containerRef.current!.clientHeight
    const totalHeight = containerRef.current!.scrollHeight
    const offsetToTop = startAtY - thumbTopStartRef.current
    containerRef.current?.scrollTo({
      top: ((offsetY - offsetToTop + startAtY) / visibleHeight) * totalHeight,
    })
  }, [startAtY, offsetY, isListening, thumbHeight])

  return (
    <div
      ref={containerRef}
      className={classNames(styles.container, {
        [styles['container--hovered']]: isHovered,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}

      <div
        className={styles.scrollbar}
        onMouseDown={handleStartListen}
        style={{
          // @ts-ignore
          '--height': `${thumbHeight}px`,
          // @ts-ignore
          '--top': `${thumbTop}px`,
          // @ts-ignore
          '--scroll-offset': `${scrollOffset}px`,
        }}
      />
    </div>
  )
}
