import { buttons } from '@aninix/app-design-system'
import * as React from 'react'

export interface IProps {
  onClick: () => void
  title: string
  tooltip?: string
  disabled?: boolean
}
export const ExportView: React.FCC<IProps> = ({
  onClick,
  title,
  tooltip,
  disabled,
}) => {
  const noop = React.useCallback(() => {}, [])

  return (
    <buttons.Regular
      onClick={tooltip == null ? onClick : noop}
      title={title}
      tooltip={tooltip}
      disabled={disabled}
      variant="contained"
    />
  )
}
