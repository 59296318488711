import { round } from '@aninix-inc/model'
import { mixed } from '@aninix-inc/model/legacy'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { default as classnames } from 'classnames'
import * as R from 'ramda'
import * as React from 'react'

import { LocalStorageIo } from '@aninix/api'
import { OnboardingObject } from '@aninix/app-design-system/hooks/use-onboarding'
import { defaultFigmaLikeFormat } from '@aninix/app-design-system/utils/figma-like-number-format'
import { Unit } from 'mathjs'
import { useMouseMove } from '../../../hooks/use-mouse-move'
import { MathOpsMixedGuide } from './math-ops-mixed-guide'

import { OnboardingPopover } from '../onboarding-popover'
import * as styles from './index.scss'
import { MathOpsNumberGuide } from './math-ops-number-guide'
import { stringToMath } from './string-to-math/string-to-math'

const mathOps = ['+', '-', '*', '/', '^']

export interface IProps {
  id: string
  value: number | typeof mixed
  /**
   * @description offset modifier, less value make scroll more precisable
   */
  threshold?: number
  onStartChange?: () => void
  // @TODO: it's called twice, have to fix
  onChange?: (value: number) => void
  onEndChange?: () => void
  onDeltaChange?: (delta: number) => void
  min?: number
  max?: number
  disabled?: boolean
  dragDisabled?: boolean
  icon?: React.ReactNode
  format?: (value: number) => string
  width?: number
  /**
   * @description if provided it will override .iconWide property
   */
  iconWidth?: number
  iconWide?: boolean
  localFormat?: (value: string) => string
}
const returnValue = (value: any) => value
const noop = () => {}

/**
 * @todo important write tests
 */
export const Input: React.FCC<IProps> = ({
  id,
  value,
  threshold = 1,
  onStartChange = noop,
  onChange = noop,
  onEndChange = noop,
  onDeltaChange,
  min = -Infinity,
  max = Infinity,
  disabled = false,
  dragDisabled = false,
  icon,
  format = defaultFigmaLikeFormat,
  width = 78,
  iconWidth,
  iconWide = false,
  localFormat = returnValue,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const prevId = React.useRef(id)
  const [isFocused, setIsFocused] = React.useState(false)
  const [localValue, setLocalValue] = React.useState(
    value === mixed ? '' : value.toString()
  )

  const valueToApply = React.useRef<number>(0)
  const hasChanged = React.useRef<boolean>(false)

  const { offsetX, deltaX, isListening, startListen } = useMouseMove({
    threshold: 0,
    onStart: onStartChange,
    onFinish: onEndChange,
  })

  const onbMixedRef = React.useRef<OnboardingObject>(null)
  const onbNumberRef = React.useRef<OnboardingObject>(null)

  //Migration FROM
  const legacyMathOpsOnboardingLocalStorage = new LocalStorageIo<{
    passedNumber: boolean
    passedMixed: boolean
  }>('aninix.math-ops-onboarding', {
    passedNumber: false,
    passedMixed: false,
  })

  React.useEffect(() => {
    legacyMathOpsOnboardingLocalStorage.get().then((value) => {
      if (value === null) return

      if (value.passedMixed) onbMixedRef.current?.pass()
      if (value.passedNumber) onbNumberRef.current?.pass()
    })
  }, [])
  //Migration TO

  React.useEffect(() => {
    if (
      !isFocused ||
      onbMixedRef.current == null ||
      onbNumberRef.current == null
    )
      return

    console.log(onbMixedRef, onbNumberRef)

    const { isPassed: isMixedPassed, open: openMixed } = onbMixedRef.current
    const { isPassed: isNumberPassed, open: openNumber } = onbNumberRef.current

    if (initialValue.current === mixed) {
      if (!isMixedPassed) openMixed()
      return
    }

    if (!isNumberPassed) openNumber()
  }, [isFocused, onbMixedRef.current, onbNumberRef.current])

  const initialValue = React.useRef<number | typeof mixed>(value)

  const onChangeWrapper = React.useCallback(
    (providedValue: number) => {
      // @NOTE: we have empty string here when value is mixed or not valid
      if (localValue === '') {
        return
      }

      if (
        value === mixed &&
        mathOps.includes(localValue[0]) &&
        onDeltaChange != null
      ) {
        const finalValue = stringToMath(localValue)
        onDeltaChange(R.clamp(min, max, finalValue))
        return
      }

      // @NOTE: do nothing if values equal
      if (R.equals(value, providedValue)) {
        return
      }

      if (typeof providedValue !== 'number') {
        if (
          !(providedValue as Unit).units.every(
            (value) => value.unit.name === 's' || value.unit.name === 'ms'
          )
        ) {
          return
        }
        // @NOTE: required when user type something like 200ms
        // @ts-ignore
        // @TODO: error here, have to check cases when provided value is undefined
        onChange(R.clamp(min, max, providedValue.toNumber('s')))
        return
      }

      onChange(R.clamp(min, max, providedValue))
    },
    [value, localValue, onDeltaChange, onChange, min, max]
  )

  React.useEffect(() => {
    const withMath = stringToMath(localValue)
    valueToApply.current = R.clamp(min, max, withMath)
  }, [localValue])

  React.useEffect(() => {
    if (isListening) {
      const delta = offsetX * threshold

      if (onDeltaChange != null) {
        const nextValue =
          typeof initialValue.current === 'number'
            ? initialValue.current + deltaX * threshold
            : deltaX * threshold

        setLocalValue(format != null ? format(nextValue) : nextValue.toString())

        if (nextValue < min || nextValue > max) {
          return
        }

        initialValue.current = nextValue
        onDeltaChange(R.clamp(min, max, deltaX * threshold))
        return
      }

      if (typeof initialValue.current === 'number') {
        const newValue = initialValue.current + delta
        const lessThanMin = newValue < min
        const biggerThanMax = newValue > max

        if (lessThanMin && newValue !== min) {
          onChangeWrapper(min)
          return
        }

        if (biggerThanMax && newValue !== max) {
          onChangeWrapper(max)
          return
        }

        onChangeWrapper(newValue)
      }
    }
  }, [isListening, offsetX, isFocused])

  React.useEffect(() => {
    if (isListening === false) {
      initialValue.current = value
      hasChanged.current = false
    }
  }, [isListening, value])

  React.useEffect(() => {
    const input = inputRef.current?.querySelector('input')
    if (isFocused) {
      input?.select()
    }
  }, [isFocused])

  React.useEffect(() => {
    const isIdChanged = id !== prevId.current

    if (isIdChanged) {
      setLocalValue(value === mixed ? '' : value.toString())
      prevId.current = id
      hasChanged.current = false
    }

    if (isFocused || isListening) {
      return
    }

    setLocalValue(value === mixed ? '' : value.toString())
  }, [isFocused, isListening, value, id])

  const handleKeyDown = React.useCallback((e: KeyboardEvent) => {
    const isAltDown = e.altKey

    setIsAltDown(isAltDown)
  }, [])

  const handleKeyUp = React.useCallback((e: KeyboardEvent) => {
    const isAltDown = e.altKey

    setIsAltDown(isAltDown)
  }, [])

  React.useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown)
    document.body.addEventListener('keyup', handleKeyUp)

    return () => {
      document.body.removeEventListener('keydown', handleKeyDown)
      document.body.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const handleKeyPress = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const isKeyDown = e.key.toLowerCase() === 'arrowdown'
      const isKeyUp = e.key.toLowerCase() === 'arrowup'
      const isShiftPressed = e.shiftKey
      const isCtrlPressed = e.ctrlKey || e.metaKey
      const isEsc = e.key.toLowerCase() === 'escape'
      const isEnter = e.key.toLocaleLowerCase() === 'enter'
      const input = inputRef.current?.querySelector('input')

      if (isKeyDown || isKeyUp) {
        e.preventDefault()
        e.stopPropagation()

        const thresholdToApply = (() => {
          if (isShiftPressed) {
            return 10 * threshold
          }

          if (isCtrlPressed) {
            return 0.1 * threshold
          }

          return threshold
        })()

        if (value !== mixed) {
          if (isKeyDown) {
            const newValue = round(valueToApply.current - thresholdToApply, {
              fixed: 4,
            })
            setLocalValue(`${newValue}`)
            onChangeWrapper(newValue)
            return
          }

          const newValue = round(valueToApply.current + thresholdToApply, {
            fixed: 4,
          })
          setLocalValue(`${newValue}`)
          onChangeWrapper(newValue)
        }

        return
      }

      // @TODO: implement cancel when esc pressed
      if (isEnter || isEsc) {
        input?.blur()
        return
      }
    },
    [localValue, onChangeWrapper]
  )

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const valueFromInput = e.target.value
      const formattedValue = localFormat(valueFromInput.replace(/ /g, ''))
      setLocalValue(formattedValue)
    },
    []
  )

  const formattedValue = React.useMemo(() => {
    if (value === mixed) {
      return mixed
    }

    if (format != null && typeof value === 'number') {
      return format(value)
    }

    return value
  }, [value, format])

  const [isAltDown, setIsAltDown] = React.useState(false)

  const handleFocus = React.useCallback(() => {
    onStartChange()

    if (isAltDown) {
      inputRef.current?.blur()
      return
    }

    setIsFocused(true)
  }, [onStartChange, isAltDown])

  const handleBlur = React.useCallback(() => {
    onChangeWrapper(valueToApply.current)
    hasChanged.current = true
    setIsFocused(false)
    onEndChange()

    if (
      !isFocused ||
      onbMixedRef.current == null ||
      onbNumberRef.current == null
    )
      return

    const { isExpanded: isMixedExisExpanded, close: closeMixed } =
      onbMixedRef.current
    const { isExpanded: isNumberExisExpanded, close: closeNumber } =
      onbNumberRef.current

    if (isMixedExisExpanded) closeMixed()
    if (isNumberExisExpanded) closeNumber()
  }, [onChangeWrapper, onbMixedRef.current, onbNumberRef.current])

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      if (e.altKey === true) {
        e.stopPropagation()
        e.preventDefault()
        inputRef?.current?.blur()
        //@ts-ignore
        startListen(e)
      }
    },
    [startListen]
  )

  const handleIconMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      if (dragDisabled) {
        return
      }

      inputRef?.current?.blur()
      // @ts-ignore
      startListen(e)
    },
    [dragDisabled, startListen]
  )

  // @NOTE: required to handle change when user click outside of properties panel
  React.useEffect(() => {
    return () => {
      if (isFocused === false) {
        return
      }

      if (hasChanged.current === true) {
        return
      }

      onChangeWrapper(valueToApply.current)
      onEndChange()
    }
    // @NOTE: onChange here required to update only when property change.
    // We don't need to update every time when onChangeWrapper updates
  }, [onChange, onEndChange, isFocused])

  return (
    <>
      <OnboardingPopover
        anchorEl={inputRef.current}
        name="math-ops-mixed"
        ref={onbMixedRef}
      >
        <MathOpsMixedGuide
          handleClose={() => onbMixedRef.current!.close()}
          handlePass={() => onbMixedRef.current!.pass()}
        />
      </OnboardingPopover>

      <OnboardingPopover
        anchorEl={inputRef.current}
        name="math-ops-number"
        ref={onbNumberRef}
      >
        <MathOpsNumberGuide
          handleClose={() => onbNumberRef.current!.close()}
          handlePass={() => onbNumberRef.current!.pass()}
          exampleValue={
            initialValue.current === mixed ? 0 : initialValue.current
          }
        />
      </OnboardingPopover>

      <OutlinedInput
        ref={inputRef}
        classes={{
          adornedStart: classnames(styles['input-root'], {
            [styles['input--alt-down']]: isAltDown,
          }),
          root: classnames(styles['input-root'], {
            [styles.focused]: isFocused || isListening,
            [styles['input--alt-down']]: isAltDown,
          }),
          notchedOutline: classnames(styles['outline-notched'], {}),
          input: classnames(styles.input, {
            [styles['input--mixed']]:
              isFocused === false &&
              isListening === false &&
              formattedValue === mixed,
            [styles['input--disabled']]: disabled,
            [styles['input--no-icon']]: icon == null,
            [styles['input--alt-down']]: isAltDown,
          }),
          focused: styles.focused,
          disabled: styles.disabled,
        }}
        style={{
          width,
          pointerEvents: isListening ? 'none' : 'all',
        }}
        id={id}
        type="text"
        value={isFocused ? localValue : formattedValue}
        onKeyDown={handleKeyPress}
        onMouseDown={handleMouseDown}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="off"
        startAdornment={
          icon != null && (
            <InputAdornment
              classes={{
                root: classnames(styles['input-adornment'], {
                  [styles['input-adornment--disabled']]:
                    dragDisabled && !isAltDown,
                }),
              }}
              onMouseDown={handleIconMouseDown}
              position="start"
            >
              <span
                className={classnames(styles.icon, {
                  [styles['icon--wide']]: iconWide,
                })}
                style={{ width: iconWidth }}
              >
                {icon}
              </span>
            </InputAdornment>
          )
        }
        disabled={disabled}
      />
    </>
  )
}
