import classNames from 'classnames'
import * as React from 'react'

import { Icons } from '../../code'

export interface IProps {
  title: string
  value: string
}
export const Property: React.FCC<IProps> = ({ title, value }: IProps) => {
  const [showCheckmark, setShowCheckmark] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (showCheckmark)
      setTimeout(() => {
        setShowCheckmark(false)
      }, 1300)
  }, [showCheckmark])

  const handleClick = React.useCallback(() => {
    navigator.clipboard.writeText(value)
    setShowCheckmark(true)
  }, [])
  return (
    <div className="flex flex-col gap-1 " key={`${title}-${value}`}>
      <div className="w-[130px] font-body text-xs font-normal text-gray-400">
        {title}
      </div>

      <div
        onClick={handleClick}
        className="group relative w-max select-text font-mono text-xs font-normal text-secondary hover:cursor-pointer"
      >
        {value}

        <Icons.Copy
          size={{ x: 20, y: 20 }}
          className={classNames(
            'absolute left-[calc(100%+6px)] top-0 h-5 w-5 !stroke-gray-500  opacity-0 transition-all duration-200 group-hover:opacity-100',
            { ['!opacity-0']: showCheckmark }
          )}
        />

        <div
          className={classNames(
            'absolute left-[calc(100%+4px)] -top-2 flex flex-row-reverse items-center gap-1  transition-all duration-200',
            { ['invisible opacity-0']: !showCheckmark }
          )}
        >
          <p
            className={classNames(
              'origin-left select-none rounded bg-[#0B1118] bg-opacity-90 px-3 py-2 font-body text-[11px] text-white transition-all duration-300',
              { ['invisible scale-0 opacity-0']: !showCheckmark }
            )}
          >
            Copied!
          </p>

          <Icons.Check
            size={{ x: 20, y: 20 }}
            className="h-5 w-5 rounded-full bg-[#0B1118]  bg-opacity-90 stroke-gray-100 p-1"
          />
        </div>
      </div>
    </div>
  )
}

Property.displayName = 'Property'
