import { PropertyType } from '@aninix-inc/model/legacy'

export const propertyNames: Record<PropertyType, string> = {
  [PropertyType.Generic]: 'Generic',
  [PropertyType.AnchorPoint]: 'Anchor Point',
  // @NOTE: because using in sweep only
  [PropertyType.Angle]: 'Starting Angle',
  [PropertyType.BlendMode]: 'Blend Mode',
  // @ts-ignore
  [PropertyType.BooleanOperation]: 'Boolean Operation',
  [PropertyType.CornerRadius]: 'Corner Radius',
  [PropertyType.TopLeftRadius]: 'Top Left Corner Radius',
  [PropertyType.TopRightRadius]: 'Top Right Corner Radius',
  [PropertyType.BottomLeftRadius]: 'Bottom Left Corner Radius',
  [PropertyType.BottomRightRadius]: 'Top Right Corner Radius',
  // @ts-ignore
  [PropertyType.FontSize]: 'Font Size',
  // @ts-ignore
  [PropertyType.InnerRadius]: 'Inner Radius',
  [PropertyType.Opacity]: 'Opacity',
  // @ts-ignore
  [PropertyType.PaintType]: 'Paint Type',
  // @ts-ignore
  [PropertyType.PointCount]: 'Point Count',
  [PropertyType.Position]: 'Position',
  // @ts-ignore
  [PropertyType.RGBA]: 'Color',
  [PropertyType.Color]: 'Color',
  [PropertyType.Rotation]: 'Rotation',
  [PropertyType.Scale]: 'Scale',
  [PropertyType.Size]: 'Size',
  [PropertyType.Skew]: 'Skew',
  // @ts-ignore
  [PropertyType.String]: 'Text',
  [PropertyType.StrokeWeight]: 'Stroke Width',
  [PropertyType.StrokeTopWeight]: 'Stroke Top Width',
  [PropertyType.StrokeBottomWeight]: 'Stroke Bottom Width',
  [PropertyType.StrokeLeftWeight]: 'Stroke Left Width',
  [PropertyType.StrokeRightWeight]: 'Stroke Right Width',
  [PropertyType.Visible]: 'Visible',
  // @ts-ignore
  [PropertyType.TextCase]: 'Text Case',
  // @ts-ignore
  // @ts-ignore
  [PropertyType.FontFamily]: 'Font Family',
  // @ts-ignore
  [PropertyType.FontStyle]: 'Font Style',
  // @ts-ignore
  [PropertyType.TextDecoration]: 'Text Decoration',
  // @ts-ignore
  [PropertyType.LetterSpacingUnit]: 'Letter Spacing Unit',
  // @ts-ignore
  [PropertyType.LetterSpacingValue]: 'Letter Spacing Value',
  // @ts-ignore
  [PropertyType.LineHeightUnit]: 'Line Height Unit',
  // @ts-ignore
  [PropertyType.LineHeightValue]: 'Line Height Value',
  // @ts-ignore
  [PropertyType.VectorFillRule]: 'Fill Rule',
  // @ts-ignore
  [PropertyType.VectorPath]: 'Path',
  // @ts-ignore
  [PropertyType.VectorPathMirroring]: 'Path Mirroring',
  // @ts-ignore
  [PropertyType.ImageData]: 'Image Data',
  // @ts-ignore
  [PropertyType.ImageUrl]: 'Image Url',
  // @ts-ignore
  [PropertyType.ScaleMode]: 'Scale Mode',
  // @ts-ignore
  [PropertyType.ClipsContent]: 'Clips Content',
  // @ts-ignore
  [PropertyType.StrokeAlign]: 'Stroke Align',
  // @ts-ignore
  [PropertyType.StrokeCap]: 'Stroke Cap',
  // @ts-ignore
  [PropertyType.StrokeJoin]: 'Stroke Join',
  [PropertyType.Offset]: 'Offset',
  [PropertyType.Radius]: 'Blur',
  [PropertyType.DashOffset]: 'Dash Offset',
  [PropertyType.Spread]: 'Spread',
  [PropertyType.Dash]: 'Dash',
  [PropertyType.Gap]: 'Gap',
  // @ts-ignore
  [PropertyType.EffectType]: 'Effect Type',
  // @ts-ignore
  [PropertyType.IsMask]: 'Is Mask',
  [PropertyType.GradientTransform]: 'Transform',
  // @NOTE: because using in sweep only
  [PropertyType.Percent]: 'Inner Radius',
  // @NOTE: because using in sweep only
  [PropertyType.PercentNegative]: 'Ending Angle',
  [PropertyType.TrimStart]: 'Start',
  [PropertyType.TrimEnd]: 'End',
  [PropertyType.TrimOffset]: 'Offset',
}
