import * as React from 'react'

import { BottomLeftRadius } from '../properties-panel/bottom-left-radius'
import { BottomRightRadius } from '../properties-panel/bottom-right-radius'
import { InnerRadius } from '../properties-panel/inner-radius'
import { PolygonPointCount } from '../properties-panel/polygon-point-count'
import { StarPointCount } from '../properties-panel/star-point-count'
import { Sweep } from '../properties-panel/sweep'
import { TopLeftRadius } from '../properties-panel/top-left-radius'
import { TopRightRadius } from '../properties-panel/top-right-radius'
import { AnchorPoint } from './AnchorPoint'
import { CornerRadius } from './CornerRadius'
import { Opacity } from './Opacity'
import { Position } from './Position'
import { Rotation } from './Rotation'
import { Scale } from './Scale'
import { Size } from './Size'
import { Skew } from './skew'

const defaultSize = {
  x: 16,
  y: 16,
}

export enum PropertyType {
  AnchorPoint = 'ANCHOR_POINT',
  CornerRadius = 'CORNER_RADIUS',
  TopLeftRadius = 'TOP_LEFT_RADIUS',
  TopRightRadius = 'TOP_RIGHT_RADIUS',
  BottomLeftRadius = 'BOTTOM_LEFT_RADIUS',
  BottomRightRadius = 'BOTTOM_RIGHT_RADIUS',
  Opacity = 'OPACITY',
  Position = 'POSITION',
  Rotation = 'ROTATION',
  Scale = 'SCALE',
  Size = 'SIZE',
  Skew = 'SKEW',
  Sweep = 'SWEEP',
  SweepFlipped = 'SWEEP_FLIPPED',
  InnerRadius = 'INNER_RADIUS',
  PolygonPointCount = 'POLYGON_POINT_COUNT',
  StarPointCount = 'STAR_POINT_COUNT',
}

export interface IProps {
  type: PropertyType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Property: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  // @TODO: refactor to use unique separeted icons for properties panel and timeline
  const sizeFromPropertiesPanel = React.useMemo(
    () => ({
      x: size.x * 0.75,
      y: size.y * 0.75,
    }),
    [size]
  )

  const sizeFromPropertiesPanelForCorners = React.useMemo(
    () => ({
      x: size.x * 0.5,
      y: size.y * 0.5,
    }),
    [size]
  )

  if (PropertyType.AnchorPoint === type) {
    return <AnchorPoint size={size} style={style} />
  }

  if (PropertyType.CornerRadius === type) {
    return <CornerRadius size={size} style={style} />
  }

  if (PropertyType.TopLeftRadius === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TopLeftRadius size={sizeFromPropertiesPanelForCorners} style={style} />
      </div>
    )
  }

  if (PropertyType.TopRightRadius === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TopRightRadius
          size={sizeFromPropertiesPanelForCorners}
          style={style}
        />
      </div>
    )
  }

  if (PropertyType.BottomLeftRadius === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BottomLeftRadius
          size={sizeFromPropertiesPanelForCorners}
          style={style}
        />
      </div>
    )
  }

  if (PropertyType.BottomRightRadius === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BottomRightRadius
          size={sizeFromPropertiesPanelForCorners}
          style={style}
        />
      </div>
    )
  }

  if (PropertyType.Opacity === type) {
    return <Opacity size={size} style={style} />
  }

  if (PropertyType.Position === type) {
    return <Position size={size} style={style} />
  }

  if (PropertyType.Rotation === type) {
    return <Rotation size={size} style={style} />
  }

  if (PropertyType.Scale === type) {
    return <Scale size={size} style={style} />
  }

  if (PropertyType.Size === type) {
    return <Size size={size} style={style} />
  }

  if (PropertyType.Skew === type) {
    return <Skew size={size} style={style} />
  }

  if (PropertyType.Sweep === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Sweep size={sizeFromPropertiesPanel} style={style} />
      </div>
    )
  }

  if (PropertyType.SweepFlipped === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Sweep size={sizeFromPropertiesPanel} style={style} flipped />
      </div>
    )
  }

  if (PropertyType.InnerRadius === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <InnerRadius size={sizeFromPropertiesPanel} style={style} />
      </div>
    )
  }

  if (PropertyType.PolygonPointCount === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PolygonPointCount size={sizeFromPropertiesPanel} style={style} />
      </div>
    )
  }

  if (PropertyType.StarPointCount === type) {
    return (
      <div
        style={{
          width: size.x,
          height: size.y,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <StarPointCount size={sizeFromPropertiesPanel} style={style} />
      </div>
    )
  }

  return null
}
