import { Project } from '@aninix-inc/model'
import * as React from 'react'

const Context = React.createContext<Project>(null as any)

export const useProject = (): Project => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'Project context not found. Use ViewportProvider at the root component.'
    )
  }

  return context
}

export const ProjectProvider: React.FCC<{ project: Project }> = ({
  project,
  children,
}) => <Context.Provider value={project}>{children}</Context.Provider>
