import PopupStateProvider, {
  bindMenu,
  bindTrigger,
} from 'material-ui-popup-state'
import * as React from 'react'

import { IViewportZoomInteractor } from './interactor'
import { ViewportZoomView } from './view'
import { ViewportZoomViewModal } from './view-modal'

export interface IProps {
  interactor: IViewportZoomInteractor
}
export const ViewportZoomRouter: React.FCC<IProps> = ({ interactor }) => {
  const zoom = React.useMemo(
    () => `${Math.round(interactor.zoom * 100)}%`,
    [interactor]
  )

  return (
    <>
      {interactor.isViewVisible && (
        // @TODO: replace with Popover
        // @ts-ignore
        <PopupStateProvider variant="popper" popupId="toolbar-zoom">
          {(popupState) => (
            <>
              <ViewportZoomView
                zoom={zoom}
                onClick={(e) => {
                  interactor.openViewportZoomModal()
                  bindTrigger(popupState).onClick(e)
                }}
              />

              <ViewportZoomViewModal
                onZoomInClick={() => {
                  interactor.zoomIn()
                  interactor.closeViewportZoomModal()
                  popupState.close()
                }}
                onZoomOutClick={() => {
                  interactor.zoomOut()
                  interactor.closeViewportZoomModal()
                  popupState.close()
                }}
                onZoomToFitClick={() => {
                  interactor.zoomToFit()
                  interactor.closeViewportZoomModal()
                  popupState.close()
                }}
                onZoomTo50Click={() => {
                  interactor.zoomTo50()
                  interactor.closeViewportZoomModal()
                  popupState.close()
                }}
                onZoomTo100Click={() => {
                  interactor.zoomTo100()
                  interactor.closeViewportZoomModal()
                  popupState.close()
                }}
                onZoomTo200Click={() => {
                  interactor.zoomTo200()
                  interactor.closeViewportZoomModal()
                  popupState.close()
                }}
                width={160}
                {...bindMenu(popupState)}
                open={interactor.isModalVisible}
                onClose={() => {
                  popupState.close()
                  interactor.closeViewportZoomModal()
                }}
              />
            </>
          )}
        </PopupStateProvider>
      )}
    </>
  )
}
