import { Rectangle } from '@aninix-inc/model'
import { applyCommon } from '../maps/apply-common'
import { applyCornerRadius } from '../maps/apply-corner-radius'
import { NodeHandler } from '../types'

export const rect: NodeHandler = (node, project, assets) => {
  const entity = project.createEntity(Rectangle)
  applyCommon(entity, node, assets)
  applyCornerRadius(entity, node)

  return entity
}
