import { Entity, getAbsoluteTransformMatrix, getSize } from '@aninix-inc/model'
import * as paper from 'paper'

export type OutlineBox = {
  topLeft: paper.Point
  topRight: paper.Point
  bottomLeft: paper.Point
  bottomRight: paper.Point
  bottomCenter: paper.Point
  topCenter: paper.Point
  leftCenter: paper.Point
  rightCenter: paper.Point
  center: paper.Point
  width: number
  height: number
  left: number
  right: number
  top: number
  bottom: number
}

export const getOutlineBox = (entity: Entity, time: number) => {
  const size = new paper.Size(getSize(entity, time))
  const matrix = new paper.Matrix(getAbsoluteTransformMatrix({ entity, time }))

  const initTopLeft = new paper.Point(0, 0)
  const initTopRight = new paper.Point(size.width, 0)

  const initBottomLeft = new paper.Point(0, size.height)
  const initBottomRight = new paper.Point(size.width, size.height)

  const initCenter = new paper.Point(size.width / 2, size.height / 2)
  const initBottomCenter = new paper.Point(size.width / 2, size.height)
  const initTopCenter = new paper.Point(size.width / 2, 0)
  const initLeftCenter = new paper.Point(0, size.height / 2)
  const initRightCenter = new paper.Point(size.width, size.height / 2)

  const topLeft = matrix.transform(initTopLeft)
  const topRight = matrix.transform(initTopRight)

  const bottomLeft = matrix.transform(initBottomLeft)
  const bottomRight = matrix.transform(initBottomRight)

  const bottomCenter = matrix.transform(initBottomCenter)
  const topCenter = matrix.transform(initTopCenter)
  const leftCenter = matrix.transform(initLeftCenter)
  const rightCenter = matrix.transform(initRightCenter)
  const center = matrix.transform(initCenter)

  const width = Math.abs(topRight.subtract(topLeft).length)
  const height = Math.abs(bottomLeft.subtract(topLeft).length)

  const left = leftCenter.x
  const right = rightCenter.x
  const top = topCenter.y
  const bottom = bottomLeft.y

  return {
    topLeft,
    topRight,
    bottomLeft,
    bottomRight,
    bottomCenter,
    topCenter,
    leftCenter,
    rightCenter,
    center,
    width,
    height,
    left,
    right,
    top,
    bottom,
  }
}
