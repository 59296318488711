import * as React from 'react'

import { OfflineIcon } from './icon'

export interface IProps {}
export const Offline: React.FCC<IProps> = () => (
  <div className="mx-auto flex h-screen w-screen max-w-sm flex-col items-center justify-center text-center text-black/80">
    <OfflineIcon className="w-[258px]" />

    <p className="mt-6 text-2xl font-semibold">
      Unable to connect to Figma plugin
    </p>

    <p className="mt-2 text-base">
      Seems like you are offline. Please check your internet connection and try
      again.
    </p>

    <p className="mt-1 text-base">
      If the issue keeps happening,{' '}
      <a
        href="mailto:info@aninix.com"
        target="_blank"
        className="text-[#18A0FB]"
        rel="noreferrer"
      >
        contact us.
      </a>
    </p>
  </div>
)

Offline.displayName = 'Offline'
