import { useCurrentFolder, useRestoreProject } from '@aninix/api'
import classNames from 'classnames'
import * as React from 'react'

import * as DateFNS from 'date-fns'

import { ApiProject } from '@aninix/api/helpers'
import { config } from '../../config'
import { toast } from '../../modules/toasts'
import { Icons } from '../icons'
import { Modal, ProjectOption } from './project-item'

interface IProjectOption {
  Icon?: React.ReactNode
  label: string
  openModal?: React.MouseEventHandler<HTMLDivElement>
  options?: IProjectOption[]
}

interface IProjectPrivacyOption {
  projectId: string
  status: ApiProject['accessByLink']
  label: string
  description: string
  isSelected?: boolean
}

export const TrashProjectItem: React.FC<
  Pick<
    ApiProject,
    'id' | 'name' | 'updatedAt' | 'accessByLink' | 'deletedAt' | 'coverUrl'
  > & {
    /**
     * Function which would be called before project restored.
     * If returns `true` then project will be restored properly.
     */
    beforeRestoreProject: () => boolean
  }
> = (
  {
    id,
    name,
    updatedAt,
    accessByLink,
    deletedAt,
    coverUrl,
    beforeRestoreProject,
  } // accessByLink = ProjectAccessByLink,
) => {
  const [showProjectOptions, setShowProjectOptions] =
    React.useState<boolean>(false)
  const [showPrivacyOptions, setShowPrivacyOptions] =
    React.useState<boolean>(false)
  const [showModal, setShowModal] = React.useState<Modal>('none')

  const currentFolder = useCurrentFolder().trash!

  const onProjectUpdate = React.useCallback(() => {
    currentFolder.refetch()
  }, [])

  const restoreProject = useRestoreProject()

  return (
    <>
      <div className="group">
        <div
          onMouseLeave={() => {
            setShowProjectOptions(false)
          }}
          className="z-1 group relative flex w-full flex-col rounded-[8px] border-[1px] border-gray-200 bg-white transition-all duration-300 hover:shadow-lg group-focus:shadow-lg"
        >
          <div className="flex flex-col">
            <div className="flex h-48 w-full items-center overflow-clip rounded-t-[7px] bg-[#f5f5f5]">
              <img
                onLoad={(e) => {
                  e.currentTarget.classList.add('!opacity-100')
                }}
                crossOrigin="anonymous"
                className="pointer-events-none h-full w-full object-contain p-4 opacity-0 transition-opacity duration-1000"
                src={`${config.apiUrl}${coverUrl}`}
                onError={(e) => {
                  // @ts-ignore
                  e.target.src = '/images/default-preview.jpg'
                }}
              />
            </div>
            <div className="flex flex-col gap-4 p-4">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row justify-between">
                  <p className="line-clamp-1 h-6 font-body text-base font-medium text-secondary">
                    {name}
                  </p>
                </div>
                <p
                  className={classNames(
                    'font-body text-xs font-normal text-gray-400',
                    {
                      ['!text-red']: projectUntilForeverDeletionLabel(
                        deletedAt!
                      ).isUrgent,
                    }
                  )}
                >
                  {projectUntilForeverDeletionLabel(deletedAt!).label}
                </p>
              </div>
            </div>
            <div
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setShowProjectOptions(!showProjectOptions)
              }}
              className={classNames(
                'invisible  absolute right-4 top-4 z-10 flex items-center rounded-md border-[0.5px] border-gray-200 bg-white bg-opacity-80 p-1 opacity-0 shadow-lg transition-opacity duration-300 group-hover:visible group-hover:opacity-100',
                {
                  ['!border-gray-800 !bg-black']: showProjectOptions,
                }
              )}
            >
              <Icons.Dots
                className={classNames(
                  ' stroke-gray-900 transition-all duration-300',
                  {
                    ['!stroke-white']: showProjectOptions,
                  }
                )}
              />
              <TrashProjectContextMenu
                projectId={id}
                isShown={showProjectOptions}
                handleClose={() => {
                  setShowProjectOptions(false)
                }}
                handleModal={setShowModal}
                restoreProject={() => {
                  const result = beforeRestoreProject()

                  if (!result) {
                    return
                  }

                  restoreProject
                    .mutateAsync({ projectId: id })
                    .then((value) => {
                      onProjectUpdate()
                      toast('Project restored', {
                        variant: 'info',
                      })
                    })
                    .catch((error) => {
                      console.log(error)
                      toast('Project restoring failed', {
                        variant: 'error',
                      })
                    })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const projectUntilForeverDeletionLabel = (dateString: string) => {
  const date = new Date(dateString)
  const thirtyDaysDeadline = new Date(date.getTime() + 30 * 24 * 60 * 60 * 1000)
  const isPast = DateFNS.isPast(thirtyDaysDeadline)
  const verboseTimeElapsed = isPast
    ? 'now'
    : 'in ' + DateFNS.formatDistanceToNow(thirtyDaysDeadline)

  const daysBetween = DateFNS.differenceInDays(thirtyDaysDeadline, new Date())
  return {
    label: `Will be deleted ${verboseTimeElapsed}`,
    isUrgent: daysBetween <= 7,
  }
}

interface ITrashProjectContextMenu {
  projectId: string
  handleClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isShown: boolean
  handleModal: React.Dispatch<React.SetStateAction<Modal>>
  restoreProject: () => void
}

const TrashProjectContextMenu: React.FC<ITrashProjectContextMenu> = ({
  projectId,
  handleClose,
  isShown,
  handleModal,
  restoreProject,
}) => {
  const projectOptions: IProjectOption[] = React.useMemo(
    () => [
      {
        Icon: <Icons.Projects />,
        label: 'Restore',
        openModal: restoreProject,
      },
    ],
    [handleModal]
  )

  return (
    <div
      onMouseLeave={handleClose}
      className={classNames(
        'absolute -right-0 top-[calc(100%+2px)] flex w-40 flex-col rounded-md border-[0.5px] border-gray-800 bg-secondary transition-all  duration-300',
        {
          ['pointer-events-none invisible opacity-0']: isShown === false,
        }
      )}
    >
      {projectOptions.map((i, index) => (
        <ProjectOption
          {...i}
          projectId={projectId}
          key={`${projectId}-${i.label}-${index}`}
        />
      ))}
    </div>
  )
}

TrashProjectItem.displayName = 'TrashProjectItem'
