import { buttons } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useUi } from '../../stores'
import { Tools } from '../toolbar/components/tools'
import { Export } from '../toolbar/export'
import { toolbarInteractor } from './toolbar-legacy/interactor'
import { ToolbarRouter } from './toolbar-legacy/router'
import { ViewportZoom } from './toolbar-legacy/viewport-zoom'

export interface IProps {
  handleDone: () => void
}
export const Toolbar: React.FCC<IProps> = observer(
  ({ children, handleDone }) => {
    const ui = useUi()

    const interactor = toolbarInteractor({
      ui,
    })

    return (
      <ToolbarRouter
        menu={null}
        tools={
          <div className="flex">
            <Tools />
            <div className="p-2">
              <buttons.Regular
                onClick={handleDone}
                title="Done"
                tooltip="Set selected project version as current"
                variant="contained"
              />
            </div>
          </div>
        }
        exportModule={<Export />}
        viewportZoom={<ViewportZoom />}
        linkToDashboard={null}
        interactor={interactor}
      >
        {children}
      </ToolbarRouter>
    )
  }
)

Toolbar.displayName = 'Toolbar'
