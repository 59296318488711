import { round } from './round'
import type { Vector } from './types'

/**
 * @returns angle of vector in radians
 */
export function getVectorAngle(vector: Vector): number {
  const { start, end } = vector

  const left = Math.abs(end.y - start.y)
  const right = Math.abs(end.x - start.x)

  if (left === right) {
    return 0
  }

  const tan = left / right

  const additionalAngleByQuarter = (() => {
    const isSecond = end.y > start.y && end.x <= start.x
    const isThird = end.y <= start.y && end.x <= start.x
    const isFourth = end.y <= start.y && end.x > start.x

    const isHorizontal = end.y === start.y
    const isVertical = end.x === start.x

    if (isHorizontal) {
      if (end.x < start.x) {
        return Math.PI
      }

      return 0
    }

    if (isVertical) {
      if (end.y < start.y) {
        return Math.PI
      }

      return 0
    }

    if (isSecond) {
      return Math.PI / 2
    }

    if (isThird) {
      return Math.PI
    }

    if (isFourth) {
      return (Math.PI / 2) * 3
    }

    return 0
  })()

  return Math.abs(round(Math.atan(tan) + additionalAngleByQuarter))
}
