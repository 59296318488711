import { DurationComponent, Root } from '@aninix-inc/model'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import * as React from 'react'
import { usePlayback, useProject } from '../../../../../../../stores'

export interface IProps {}

const secondsToMS = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return seconds < 1
    ? format(date, 'SSS') + ' ms'
    : format(date, 'sSSS') + ' ms'
}

const secondsToSS = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return format(date, 's.S') + ' s'
}

export const PlaybackTime: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const playback = usePlayback()
  const duration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value
  const playbackTime = (duration <= 1 ? secondsToMS : secondsToSS)(
    playback.time
  )
  return (
    <p className="font-mono text-xs font-normal text-black text-opacity-80">
      {playbackTime}
    </p>
  )
})

PlaybackTime.displayName = 'PlaybackTime'
