import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Effect: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.833 4H10.833V2H11.833V4ZM10.333 4H9.33301V3L10.333 3V4ZM3.33398 12.5001L3.33409 13H3.83398H12.833H13.333V12.5V8H12.333V12H4.33388L4.33301 8V7.99989L4.33301 4L8.33301 4V3L3.83301 3H3.33301V3.5V8V8.00011L3.33398 12.5001ZM6.18656 10.854L10.1866 6.85403L9.47945 6.14693L5.47945 10.1469L6.18656 10.854ZM10.3332 4.49961V5.49961L8.3332 5.49961V4.49961H10.3332ZM10.833 5.5H11.833V4.5H10.833V5.5ZM12.333 4H13.333V3L12.333 3V4ZM13.333 7H12.333V6H13.333V7ZM14.333 5.5V4.5H12.333V5.5H14.333ZM10.833 8H11.833V6H10.833V8Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
