import classNames from 'classnames'
import * as React from 'react'
import { Layer, LayerType } from '../icons'

const Container: React.FCC = ({ children }) => (
  <div className="flex flex-col items-center gap-2">{children}</div>
)

const ProjectName: React.FC<{ name: string }> = ({ name }) => (
  <p className="text-xs font-semibold text-[#18A0FB]">{name}</p>
)

const SelectedLayer: React.FC<{
  type: LayerType
  name: string
}> = ({ type, name }) => (
  <div className="flex flex-row items-center gap-1">
    <Layer size={{ x: 16, y: 16 }} type={type} />
    <p className="text-xs font-medium text-secondary">{name}</p>
  </div>
)

export interface IProps {
  imageSrc?: string
  backgroundColor?: string
}
export const Preview: React.FCC<IProps> = ({ imageSrc, backgroundColor }) => {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [isError, setIsError] = React.useState(false)
  return (
    <div
      className={classNames(
        'h-[180px] w-full rounded-lg overflow-hidden relative bg-gray-200 border-[1px] border-gray-300 transition-opacity duration-100',
        {
          ['opacity-0']: !isLoaded && !isError,
        }
      )}
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      {imageSrc && (
        <>
          <img
            className={classNames(
              'object-cover w-full h-full transition-opacity duration-200 absolute inset-0 blur-3xl z-10 scale-[1.5] opacity-50',
              { ['opacity-0']: !isLoaded }
            )}
            src={imageSrc}
            crossOrigin="anonymous"
          />
          <img
            onLoad={() => setIsLoaded(true)}
            onError={() => setIsError(true)}
            className={classNames(
              'object-contain w-full h-full transition-opacity duration-200 relative z-20',
              { ['opacity-0']: !isLoaded }
            )}
            src={imageSrc}
            crossOrigin="anonymous"
          />
        </>
      )}
    </div>
  )
}

export const LayerPreview = {
  Container,
  ProjectName,
  SelectedLayer,
  Preview,
}
