import classNames from 'classnames'
import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { useCurrentTeamStore, useCurrentUser } from '../../use-cases'
import { Icons } from '../icons'
import { ExpandedAdminRoles } from './expanded-admin-roles'
import { useCurrentRowContext } from './use-current-row-context'

export const currentAdminRole = (adminRole: ApiUser['adminRole']): string => {
  switch (adminRole) {
    // case TeamMemberAninixRole.Administrator:
    //   return 'Owner'
    case 'owner':
      return 'Owner'
    case 'manager':
      return 'Manager'
    case 'viewer':
      return 'Viewer'
  }
}

export const AdminRole: React.FCC = () => {
  const [isExpandedRoles, setIsExpandedRoles] = React.useState<boolean>(false)

  const handleClose = React.useCallback(() => setIsExpandedRoles(false), [])
  const handleClick = React.useCallback(() => setIsExpandedRoles(true), [])
  const noop = React.useCallback(() => {}, [])

  const { adminRole, status } = useCurrentRowContext()

  const { userId } = useCurrentUser()
  const { id } = useCurrentRowContext()

  const isYourUser = userId == id

  const { currentTeam } = useCurrentTeamStore()

  const isAllowedToManage =
    ['owner', 'manager'].includes(currentTeam!.adminRole) && !isYourUser

  return (
    <div
      className={classNames(
        'relative flex max-w-fit flex-row items-center gap-2'
      )}
      onMouseOver={isAllowedToManage ? handleClick : noop}
    >
      <p className="font-body text-base font-normal ">
        {currentAdminRole(adminRole!)}
      </p>
      {isAllowedToManage && (
        <>
          <Icons.CaretDown
            color={status === 'suspended' ? '#CED3D9' : undefined}
          />
          <ExpandedAdminRoles
            role={adminRole!}
            isExpanded={isExpandedRoles}
            handleClose={handleClose}
          />
        </>
      )}
    </div>
  )
}

AdminRole.displayName = 'AdminRole'
