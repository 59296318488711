import { buttons } from '@aninix/app-design-system'
import * as React from 'react'

import * as styles from './success.scss'

export interface IProps {
  onSuccessClick: () => void
}
export const SuccessView: React.FCC<IProps> = ({ onSuccessClick }) => (
  <div className={styles.container}>
    <div>
      <p className={styles.title}>Export succeed 🎉</p>

      <p className={styles.description}>
        If file is not saved please see our{' '}
        <a
          href="https://www.aninix.com/troubleshooting/file-not-saved-after-export"
          target="_blank"
          rel="noreferrer"
        >
          troubleshoot article
        </a>
      </p>
    </div>

    <div className={styles.buttons}>
      <buttons.Regular
        title="Got it"
        variant="contained"
        success
        onClick={onSuccessClick}
        fullWidth
      />
    </div>
  </div>
)
