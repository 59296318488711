import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Locked: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 5.5V7h-3V5.5a1.5 1.5 0 113 0zM5.5 7V5.5a2.5 2.5 0 015 0V7h.5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5H5a.5.5 0 01-.5-.5v-5A.5.5 0 015 7h.5z"
        fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
