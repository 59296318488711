import { PaintType, RGBA } from '@aninix-inc/model/legacy'
import { Card, Popper } from '@material-ui/core'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

// import { Select } from '../Select'
import { IconButton } from '../buttons/icon-button'
import { GradientColorPicker } from '../gradient-color-picker'
import { ColorStop } from '../gradient-slider/types'
import { Visible } from '../icons'
import { InputGradient } from '../input-gradient'
import { InputHex } from '../input-hex'
import { PopupHeader } from '../popup-header'
import { SolidColorPicker } from '../solid-color-picker'
import * as styles from './styles.scss'

type Fill = SolidFill | GradientFill

type SolidFill = {
  id: string
  visible: boolean
  type: PaintType
  color: RGBA
}

type GradientFill = {
  id: string
  visible: boolean
  type: PaintType
  opacity: number
  colorStops: ColorStop[]
}

const noop = () => {}
const defaultPosition = { x: 0, y: 0 }

export interface IProps {
  color?: RGBA
  colorStops?: ColorStop[]
  opacity?: number
  fill: Fill
  subheader?: React.ReactNode

  visible?: boolean
  position?: {
    x: number
    y: number
  }
  onChange?: (color: { r: number; g: number; b: number; a: number }) => void
  onGradientChange?: (
    colorStops?: (
      | ColorStop
      | { id: undefined; value: undefined; progress: number }
    )[],
    opacity?: number,
    type?: PaintType
  ) => void
  onVisibilityClick?: () => void
  onStartChange?: () => void
  onEndChange?: () => void
  onExpand?: () => void
  onCollapse?: () => void
  selectedColorStopId?: string
  onColorStopSelect?: (id: string) => void
}
export const PaintControl: React.FCC<IProps> = observer(
  ({
    color,
    colorStops,
    opacity,
    visible = false,
    position = defaultPosition,
    onChange = noop,
    onGradientChange = noop,
    onVisibilityClick,
    onStartChange = noop,
    onEndChange = noop,
    onExpand = noop,
    onCollapse = noop,
    fill,
    subheader,
    selectedColorStopId,
    onColorStopSelect = noop,
  }) => {
    const containerRef = React.useRef<HTMLDivElement>(null)

    const [isExpanded, setIsExpanded] = React.useState<boolean>(false)
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

    const expand = React.useCallback(
      (event?: React.MouseEvent<HTMLElement>) => {
        if (event) {
          //Updated since user now can change fill type and need to spawn new element without loose of focus
          setAnchorEl(anchorEl ? null : containerRef.current)
        }
        setIsExpanded(true)
        onExpand?.()
      },
      [onExpand]
    )

    const getFillLabel = React.useCallback((fillType: PaintType) => {
      switch (fillType) {
        case PaintType.Solid:
          return 'Solid'
        case PaintType.GradientLinear:
          return 'Linear'
        case PaintType.GradientRadial:
          return 'Radial'

        default:
          throw new Error('Invalid enum value')
      }
    }, [])

    const mappedFill = React.useMemo(() => {
      if (!fill) return null

      return {
        currentValue: fill.type,
        options: [
          PaintType.Solid,
          PaintType.GradientLinear,
          PaintType.GradientRadial,
        ].map((type) => {
          return { id: type, title: getFillLabel(type) }
        }),
      }
    }, [fill])

    const collapse = React.useCallback(() => {
      setIsExpanded(false)
      onCollapse?.()
    }, [onCollapse])

    const iconBtnSize = React.useMemo(
      () => ({
        width: 32,
        height: 32,
      }),
      []
    )

    const iconSize = React.useMemo(
      () => ({
        x: 16,
        y: 16,
      }),
      []
    )

    return (
      <div
        className={classnames(styles.container, {
          [styles['container--expanded']]: isExpanded,
        })}
        ref={containerRef}
      >
        {colorStops !== undefined && opacity !== undefined && (
          <InputGradient
            onChange={onGradientChange}
            colorStops={colorStops}
            opacity={opacity}
            title={getFillLabel(fill.type)}
            previewEnabled
            onPreviewClick={expand}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
          />
        )}

        {color !== undefined && (
          <InputHex
            onChange={onChange}
            color={color}
            previewEnabled
            onPreviewClick={expand}
            onStartChange={onStartChange}
            onEndChange={onEndChange}
          />
        )}

        <div className={styles.buttons}>
          {onVisibilityClick != null ? (
            <IconButton btnSize={iconBtnSize} onClick={onVisibilityClick}>
              <Visible visible={visible} size={iconSize} />
            </IconButton>
          ) : (
            <div className={styles.spacer} />
          )}
        </div>
        <Popper
          // style={{
          //   transform: `translate(${-5 + position.x}px, ${-8 + position.y}px)`,
          // }}
          style={{ zIndex: 1000 }}
          placement="right-end"
          open={isExpanded}
          disablePortal={false}
          anchorEl={anchorEl}
        >
          <Card className={styles.wrapper}>
            <div>
              <PopupHeader onClose={collapse}>
                {getFillLabel(fill.type)}
                {/* @TODO: enable changing of types of fills ones implemented */}
                {/* {fill.id === 'faux'
                  ? getFillLabel(fill.type)
                  : !!mappedFill && (
                      <Select
                        // @ts-ignore
                        onChange={(newType) => {
                          onGradientChange(
                            undefined,
                            undefined,
                            newType as PaintType
                          )
                        }}
                        activeValueId={mappedFill.currentValue}
                        options={mappedFill.options}
                      />
                    )} */}
              </PopupHeader>
            </div>

            {subheader}

            {color !== undefined && (
              <SolidColorPicker
                color={color}
                onStartChange={onStartChange}
                onEndChange={onEndChange}
                onChange={onChange}
              />
            )}

            {colorStops != undefined && opacity != undefined && (
              <GradientColorPicker
                colorStops={colorStops}
                selectedColorStopId={selectedColorStopId}
                onSelect={onColorStopSelect}
                onStartChange={onStartChange}
                onEndChange={onEndChange}
                onChange={onGradientChange}
              />
            )}
          </Card>
        </Popper>
      </div>
    )
  }
)

PaintControl.displayName = 'PaintControl'
