import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import { AddAvatarPage } from './add-avatar-page'
import { AddInfoPage } from './add-info-page'
import { CurrentStepContext, useCurrentStepContext } from './use-current-step'

export interface IProps {}

const ShowStep = ({ step }: { step: 'addInfo' | 'addAvatar' }) => {
  const analytics = useAnalytics()
  const { setCurrentStep } = useCurrentStepContext()

  const handleInfoNext = React.useCallback(() => {
    setCurrentStep?.('addAvatar')
    analytics.track({
      eventName: AnalyticsEvent.TeamCreated,
    })
  }, [analytics])
  const handleAvatarNext = React.useCallback(
    (teamId: string) => window.location.assign(`../teams/${teamId}/people`),
    []
  )

  switch (step) {
    case 'addInfo':
      return <AddInfoPage handleNavigation={handleInfoNext} />
    case 'addAvatar':
      return <AddAvatarPage handleNavigation={handleAvatarNext} />
  }
}

export const NewTeam: React.FCC<IProps> = () => {
  const analytics = useAnalytics()
  const [urlSearchParams] = useSearchParams()
  const [currentStep, setCurrentStep] = React.useState<'addInfo' | 'addAvatar'>(
    'addInfo'
  )
  const [teamId, setTeamId] = React.useState<string | null>('addInfo')

  React.useEffect(() => {
    analytics.track({
      eventName: AnalyticsEvent.CreateNewTeamPageViewed,
      properties: {
        source: urlSearchParams.get('utm_source'),
      },
    })
  }, [])

  return (
    <div className="flex h-screen min-h-[600px] w-screen flex-row items-center justify-center">
      <div className="flex w-full min-w-min flex-col items-center gap-10 px-4 sm:w-5/12">
        <CurrentStepContext.Provider
          value={{ currentStep, setCurrentStep, teamId, setTeamId }}
        >
          <ShowStep step={currentStep} />
        </CurrentStepContext.Provider>
      </div>
      <div className="relative h-full w-0 overflow-hidden sm:w-7/12 ">
        <img
          crossOrigin="anonymous"
          className="absolute h-full w-full object-cover"
          src="/images/new-team-preview.svg"
        />
        <div className="absolute -right-[1000px] bottom-0 left-[120px] top-[120px] ">
          <img
            crossOrigin="anonymous"
            className="h-full  min-h-[500px]"
            src="/images/teams-preview.png"
          />
        </div>
      </div>
    </div>
  )
}

NewTeam.displayName = 'NewTeam'
