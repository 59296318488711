import * as React from 'react'

import { IIcon } from '.'

export const Logout: React.FCC<IIcon> = ({
  size = { x: 20, y: 20 },
  className = ' stroke-gray-400 h-6 w-6',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 ${size.x} ${size.y}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.25 7.5C15.4926 7.5 16.5 6.49264 16.5 5.25C16.5 4.00736 15.4926 3 14.25 3C13.0074 3 12 4.00736 12 5.25C12 6.49264 13.0074 7.5 14.25 7.5Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.25 9.5204C5.25 9.5204 8.25 6.74999 12.75 10.2704C17.4814 13.9719 20.25 12.5204 20.25 12.5204"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6633 10.2032C12.2562 12.1162 9.5211 19.4021 3 18.75"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.373 15.1088C12.0441 15.4658 16.5001 16.875 16.5001 21.75"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
