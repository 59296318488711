import classNames from 'classnames'
import * as React from 'react'

export interface ITeamItem {
  id: string
  label: string
  thumbnailSrc: string
  /** put all the keys that will be used as source for search */
  meta?: { [key: string]: unknown }
}

export interface IProps {
  item: ITeamItem
  isSelected: boolean
  onSelect: () => void
}
export const TeamItem: React.FCC<IProps> = ({ item, onSelect, isSelected }) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false)
  return (
    <button
      onClick={onSelect}
      className="w-full hover:bg-gray-500/20 px-3 py-2 flex flex-row justify-between items-center"
    >
      <div className="flex flex-row gap-2 items-center">
        <img
          onLoad={() => setIsImageLoaded(true)}
          className={classNames(
            'w-[20px] h-[20px] rounded-full transition-opacity duration-200',
            { ['opacity-0']: !isImageLoaded }
          )}
          crossOrigin="anonymous"
          src={item.thumbnailSrc}
        />
        <p className="font-normal text-white text-[12px]/[14px]">
          {item.label}
        </p>
      </div>
      {isSelected && <CheckIcon />}
    </button>
  )
}

TeamItem.displayName = 'TeamItem'

const CheckIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 7.33333L6.33333 10.6667L13 4"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
