import { Entity, EntryComponent } from '@aninix-inc/model'
import * as React from 'react'
import {
  List,
  OnScrollParams,
  ScrollSync,
  ScrollSyncChildProps,
} from 'react-virtualized'
import { getNodeRows } from '../get-row-data'
import { ScrollSyncContext } from './scroll-sync-context'

const ScrollSyncProvider: React.FCC<ScrollSyncChildProps> = ({
  children,
  onScroll,
  scrollTop,
  ...otherProps
}) => {
  const scrollTo = React.useCallback(
    (params: Partial<OnScrollParams>) => {
      onScroll({ ...otherProps, scrollTop, ...params })
    },
    [otherProps, scrollTop, onScroll]
  )

  const scrollToIndex = React.useCallback(
    (
      listRef: React.RefObject<List>,
      index: number,
      scrollIfVisible?: boolean
    ) => {
      if (!listRef.current || index < 0) return

      listRef.current.measureAllRows()

      const offset = listRef.current.getOffsetForRow({
        alignment: 'center',
        index,
      })

      const height = listRef.current.props.height

      const isInView =
        offset >= scrollTop - height / 2 && offset <= scrollTop + height / 2

      if (isInView && !scrollIfVisible) {
        return
      }

      scrollTo({ scrollTop: offset })
    },
    [scrollTop, scrollTo]
  )

  const scrollToEntity = React.useCallback(
    (
      listRef: React.RefObject<List>,
      entity?: Entity,
      scrollIfVisible?: boolean
    ) => {
      if (!entity) return
      const project = entity.getProjectOrThrow()
      const data = getNodeRows(
        project.entities.find((e) => e.hasComponent(EntryComponent))!,
        { indent: 0 }
      )
      const entityIndex = data.findIndex((v) => v.entity.id === entity.id)
      scrollToIndex(listRef, entityIndex, scrollIfVisible)
    },
    [scrollToIndex]
  )
  return (
    <ScrollSyncContext.Provider
      value={{
        scrollTo,
        scrollToIndex,
        scrollToEntity,
        scrollTop,
        ...otherProps,
      }}
    >
      {children}
    </ScrollSyncContext.Provider>
  )
}

export const ScrollSyncProviderWrapper: React.FCC = ({ children }) => {
  return (
    <ScrollSync>
      {(props) => (
        <ScrollSyncProvider {...props}>{children}</ScrollSyncProvider>
      )}
    </ScrollSync>
  )
}
