import { Point2D } from '@aninix-inc/model/legacy'

export function getSize({
  target,
  actual,
  mode,
}: {
  target: Point2D
  actual: Point2D
  mode: 'fill' | 'fit'
}): Point2D {
  const targetRatio = target.x / target.y
  const actualRatio = actual.x / actual.y

  if (mode === 'fill' ? targetRatio < actualRatio : targetRatio > actualRatio) {
    const coefficient = target.y / actual.y

    return {
      x: actual.x * coefficient,
      y: target.y,
    }
  }

  const coefficient = target.x / actual.x

  return {
    x: target.x,
    y: actual.y * coefficient,
  }
}
