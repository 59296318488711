import { Point2D, RGBA } from '@aninix-inc/model/legacy'
import * as R from 'ramda'
import React from 'react'
import tinycolor from 'tinycolor2'

import { useMouseMove } from '../../../hooks'

const noop = () => {}

function colorToCoords(color: RGBA): Point2D {
  const hsv = tinycolor(color).toHsv()
  return {
    x: hsv.s,
    y: 1 - hsv.v,
  }
}

function hueToHex(hue: number): string {
  return `#${tinycolor({
    h: hue,
    s: 1,
    v: 1,
  }).toHex()}`
}

interface IProps {
  /**
   * @description hue in range 0...360
   */
  hue: number
  color: RGBA
  startChange?: () => void
  endChange?: () => void
  onChange: (color: RGBA) => void
  size?: number
}

export const SaturationLightnessPicker: React.FCC<IProps> = ({
  hue,
  color,
  startChange = noop,
  endChange = noop,
  onChange,
  size = 240,
}) => {
  const containerRef = React.useRef<any>(null)

  const [progressX, setProgressX] = React.useState(colorToCoords(color).x)
  const [progressY, setProgressY] = React.useState(colorToCoords(color).y)
  const [_, setMounted] = React.useState(false)

  const { endAtX, endAtY, startListen, isListening, wasTriggered } =
    useMouseMove({
      threshold: 0,
      element: containerRef.current!,
      delay: 16.67,
      onStart: startChange,
      onFinish: endChange,
    })

  React.useEffect(() => {
    if (isListening === false || wasTriggered === false) {
      return
    }

    const newProgressX = R.clamp(0, 1, endAtX / size)
    const newProgressY = R.clamp(0, 1, endAtY / size)
    setProgressX(newProgressX)
    setProgressY(newProgressY)

    const newColor = tinycolor({
      h: hue,
      s: newProgressX,
      v: 1 - newProgressY,
    }).toRgb()
    onChange({ ...newColor, a: color.a })
  }, [endAtX, endAtY, hue, size, isListening, wasTriggered])

  React.useEffect(() => {
    const { x, y } = colorToCoords(color)

    setProgressY(y)

    if (y > 0.85) {
      return
    }

    setProgressX(x)
  }, [color])

  React.useEffect(() => {
    setMounted(true)
  }, [])

  const currentColor = React.useMemo(
    () =>
      `#${tinycolor({
        h: hue,
        s: progressX,
        v: 1 - progressY,
      }).toHex()}`,
    [hue, progressX, progressY]
  )

  const hueInHex = React.useMemo(() => hueToHex(hue), [hue])

  return (
    <svg
      ref={containerRef}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      // @ts-ignore
      onMouseDown={startListen}
    >
      <defs>
        <linearGradient
          id="saturation_horizontal"
          x1="0%"
          y1="0%"
          x2="100%"
          y2="0%"
        >
          <stop offset="0%" stopColor={hueInHex} stopOpacity={0} />
          <stop offset="100%" stopColor={hueInHex} stopOpacity={1} />
        </linearGradient>

        <linearGradient
          id="saturation_vertical"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stopColor="black" stopOpacity={0} />
          <stop offset="100%" stopColor="black" stopOpacity={1} />
        </linearGradient>
      </defs>

      <g>
        <rect width={size} height={size} fill="white" />
        <rect width={size} height={size} fill="url(#saturation_horizontal)" />
        <rect width={size} height={size} fill="url(#saturation_vertical)" />

        <g transform={`translate(${progressX * size} ${progressY * size})`}>
          <circle cx={0} cy={0} r={8} fill="black" fillOpacity={0.2} />
          <circle
            cx={0}
            cy={0}
            r={6}
            fill={currentColor}
            stroke="white"
            strokeWidth={2}
          />
        </g>
      </g>
    </svg>
  )
}
