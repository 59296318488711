import { getOffsetMutliplier } from './get-offset-mutliplier'

export function getValueAfterDeltaChange({
  isCtrlDown,
  isShiftDown,
  deltaX,
  threshold,
}: {
  isCtrlDown: boolean
  isShiftDown: boolean
  deltaX: number
  threshold: number
}) {
  const multiplier = getOffsetMutliplier({
    isCtrlPressed: isCtrlDown,
    isShiftPressed: isShiftDown,
    isPositive: true,
  })

  const delta = deltaX * threshold * multiplier
  return delta
}
