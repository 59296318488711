import React from 'react'

import * as styles from './index.scss'

export enum AnchorPointPosition {
  TopLeft = 'top-left',
  Top = 'top',
  TopRight = 'top-right',
  Left = 'left',
  Center = 'center',
  Right = 'right',
  BottomLeft = 'bottom-left',
  Bottom = 'bottom',
  BottomRight = 'bottom-right',
  Custom = 'custom',
}

interface IProps {
  value: AnchorPointPosition
  onValueChange: (value: AnchorPointPosition) => void
  className?: string
}

const radioPoints = [
  AnchorPointPosition.TopLeft,
  AnchorPointPosition.Top,
  AnchorPointPosition.TopRight,
  AnchorPointPosition.Left,
  AnchorPointPosition.Center,
  AnchorPointPosition.Right,
  AnchorPointPosition.BottomLeft,
  AnchorPointPosition.Bottom,
  AnchorPointPosition.BottomRight,
]

export const AnchorPoint: React.FC<IProps> = ({
  value,
  onValueChange,
  className,
}) => {
  const handleClick = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onValueChange(e.target.value as AnchorPointPosition)
      e.target.blur()
    },
    [onValueChange]
  )

  return (
    <div className={className}>
      <div className="grid grid-cols-3 w-12 h-12 gap-3">
        {radioPoints.map((point) => (
          <label key={point} className={styles.container} htmlFor={point}>
            <input
              id={point}
              key={point}
              type="radio"
              name="anchorPoint"
              value={point}
              checked={value === point}
              onChange={handleClick}
              tabIndex={-1}
            />
            <span className={styles['check-mark']} />
          </label>
        ))}
      </div>
    </div>
  )
}
