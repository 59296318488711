import {
  NumberKeyframe,
  StartAngleComponent,
  getAnimatableValue,
  getValueNumber,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { formatAngleValue } from '../../../../../helpers'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 16,
  y: 16,
}
export const ArcStartingAngle: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) => n.hasComponent(StartAngleComponent))

  const components = filteredNodes.map((n) =>
    n.getComponentOrThrow(StartAngleComponent)
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  // @TODO replace with snapshot data
  const angles = components.map((c) => getAnimatableValue(c, time))
  const keyframeType = getKeyframesType(components, time)

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <ArcStartingAngleEditable time={time} components={components} />
      ) : (
        <ArcStartingAngleDisplay
          time={time}
          components={components}
          angles={angles}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

ArcStartingAngle.displayName = 'ArcStartingAngle'

const ArcStartingAngleEditable: React.FC<{
  time: number
  components: StartAngleComponent[]
}> = ({ time, components }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={components}
            time={time}
            icon={<icons.propertiesPanel.Sweep size={iconSize} />}
            format={formatAngleValue}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      }
    />
  )
}

ArcStartingAngleEditable.displayName = 'ArcStartingAngleEditable'

interface ArcStartingAngleDisplayProps {
  time: number
  components: StartAngleComponent[]
  angles: number[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (
  prev: ArcStartingAngleDisplayProps,
  next: ArcStartingAngleDisplayProps
) => {
  if (prev.angles.length !== next.angles.length) return false
  if (prev.keyframeType !== next.keyframeType) return false
  for (let i = 0; i < prev.angles.length; i++) {
    if (prev.angles[i] !== next.angles[i]) return false
  }
  return true
}

const ArcStartingAngleDisplay: React.FC<ArcStartingAngleDisplayProps> =
  React.memo(({ time, components }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <NumberValue
              components={components}
              time={time}
              icon={<icons.propertiesPanel.Sweep size={iconSize} />}
              format={formatAngleValue}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        }
      />
    )
  }, propsAreEqual)
