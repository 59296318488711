import * as R from 'ramda'

export type Item = {
  id: string
}

/**
 * @description move items in array by indicies
 * @param fromIdx index to take item
 * @param toIdx index to insert item
 * @param array array to handle
 * @returns array with moved items
 */
export function moveItem<T extends Item>(
  fromIdx: number,
  toIdx: number,
  array: T[]
): T[] {
  const requiredItem = R.clone(array[fromIdx])
  const withoutItem = R.remove(fromIdx, 1, array)
  const withInsertedItem = R.insert(toIdx, requiredItem, withoutItem)
  return withInsertedItem
}
