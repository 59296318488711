import {
  Project,
  RgbaValueComponent,
  Root,
  createComponentsProvider,
  createEntitiesProvider,
  createSystemsProvider,
} from '@aninix-inc/model'

export const createEmptyProject = (id: string) => {
  const project = new Project({
    id: id,
    componentsProvider: createComponentsProvider(),
    entitiesProvider: createEntitiesProvider(),
    systemsProvider: createSystemsProvider([]),
  })

  project.createEntity(Root)

  project.getEntityByType(Root)?.removeComponent(RgbaValueComponent)

  console.log(project.id, project.getSnapshot())
  return project
}
