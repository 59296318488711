import * as React from 'react'
import { ImagePreview } from './image-preview'
import { ISearchItem } from './search-item'

export interface IProps {
  item: ISearchItem
  onDeselect: () => void
  selectAnotherTitle?: string
}
export const SelectedItem: React.FCC<IProps> = ({
  item,
  onDeselect,
  selectAnotherTitle = 'Select another',
}) => (
  <button
    onClick={onDeselect}
    className="w-full text-accent bg-accent/15 px-3 py-2 flex flex-row justify-start items-center gap-2"
  >
    <ImagePreview src={item.thumbnailSrc} />
    <p className="font-normal text-[12px]/[14px] whitespace-nowrap overflow-hidden text-ellipsis flex-shrink">
      {item.label}
    </p>
    <div className="flex-grow" />
    <p className="font-medium text-[12px]/[14px] flex-shrink-0">
      {selectAnotherTitle}
    </p>
  </button>
)

SelectedItem.displayName = 'SelectedItem'
