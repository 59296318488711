import * as React from 'react'

export interface IProps {
  className?: string
}
export const OfflineIcon: React.FCC<IProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 259 207"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_12_7585)">
      <ellipse
        opacity="0.3"
        cx="129.5"
        cy="103.301"
        rx="120"
        ry="50"
        transform="rotate(-30 129.5 103.301)"
        stroke="url(#paint0_linear_12_7585)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeDasharray="0.1 4"
      />
      <g filter="url(#filter0_dddddd_12_7585)">
        <rect x="44.5769" y="165" width="24" height="24" rx="12" fill="white" />
        <g opacity="0.3">
          <path
            d="M56.5769 177C56.5769 176.364 56.8298 175.753 57.2798 175.303C57.7299 174.853 58.3404 174.6 58.9769 174.6C59.6134 174.6 60.2239 174.853 60.674 175.303C61.124 175.753 61.3769 176.364 61.3769 177C61.3769 177.637 61.124 178.247 60.674 178.697C60.2239 179.147 59.6134 179.4 58.9769 179.4C58.3404 179.4 57.7299 179.147 57.2798 178.697C56.8298 178.247 56.5769 177.637 56.5769 177V177Z"
            stroke="black"
            strokeWidth="1.2"
          />
          <path
            d="M51.7769 181.8C51.7769 181.164 52.0297 180.553 52.4798 180.103C52.9299 179.653 53.5403 179.4 54.1769 179.4H56.5769V181.8C56.5769 182.437 56.324 183.047 55.8739 183.497C55.4238 183.947 54.8134 184.2 54.1769 184.2C53.5403 184.2 52.9299 183.947 52.4798 183.497C52.0297 183.047 51.7769 182.437 51.7769 181.8Z"
            stroke="black"
            strokeWidth="1.2"
          />
          <path
            d="M56.5769 169.8V174.6H58.9769C59.6134 174.6 60.2239 174.347 60.674 173.897C61.124 173.447 61.3769 172.837 61.3769 172.2C61.3769 171.564 61.124 170.953 60.674 170.503C60.2239 170.053 59.6134 169.8 58.9769 169.8H56.5769Z"
            stroke="black"
            strokeWidth="1.2"
          />
          <path
            d="M51.7769 172.2C51.7769 172.837 52.0297 173.447 52.4798 173.897C52.9299 174.347 53.5403 174.6 54.1769 174.6H56.5769V169.8H54.1769C53.5403 169.8 52.9299 170.053 52.4798 170.503C52.0297 170.953 51.7769 171.564 51.7769 172.2Z"
            stroke="black"
            strokeWidth="1.2"
          />
          <path
            d="M51.7769 177C51.7769 177.637 52.0297 178.247 52.4798 178.697C52.9299 179.147 53.5403 179.4 54.1769 179.4H56.5769V174.6H54.1769C53.5403 174.6 52.9299 174.853 52.4798 175.303C52.0297 175.753 51.7769 176.364 51.7769 177Z"
            stroke="black"
            strokeWidth="1.2"
          />
        </g>
      </g>
      <g clipPath="url(#clip1_12_7585)">
        <rect
          x="89.5769"
          y="63"
          width="80"
          height="80"
          rx="40"
          fill="url(#paint1_linear_12_7585)"
        />
        <g filter="url(#filter1_dddddd_12_7585)">
          <path
            d="M142.487 102.929L124.294 119.115V89.1976L116.86 93.0662V124.233L122.407 128.714L152.641 101.621L121.803 77.2856L115.005 80.7594L142.487 102.929Z"
            fill="white"
          />
        </g>
      </g>
      <path
        d="M226.197 128.681C226.242 128.864 226.402 129 226.591 129C226.781 129 226.941 128.862 226.987 128.677C227.129 128.094 227.406 127.468 227.827 126.797C228.766 125.315 230.6 123.861 232.257 123.391C232.442 123.339 232.577 123.175 232.577 122.983C232.577 122.792 232.444 122.629 232.26 122.578C231.51 122.37 230.774 122.007 230.061 121.5C228.518 120.408 227.354 118.814 226.988 117.324C226.942 117.139 226.781 117 226.589 117C226.401 117 226.241 117.135 226.194 117.317C226.013 118.033 225.677 118.729 225.186 119.437C224.142 120.952 222.545 122.092 220.89 122.573C220.707 122.626 220.577 122.79 220.577 122.98C220.577 123.174 220.713 123.34 220.9 123.391C221.686 123.609 222.481 124.019 223.264 124.609C224.936 125.863 225.877 127.396 226.197 128.681Z"
        fill="url(#paint2_linear_12_7585)"
      />
      <path
        d="M179.16 184H180.035C180.181 183.104 180.577 182.125 181.244 181.063C182.577 178.958 185.264 176.896 187.577 176.417V175.542C186.431 175.292 185.306 174.771 184.223 174C182.014 172.438 180.389 170.104 180.035 168H179.16C178.952 169.104 178.473 170.167 177.723 171.25C176.244 173.396 173.931 174.979 171.577 175.542V176.417C172.764 176.667 173.973 177.25 175.16 178.146C177.577 179.958 178.848 182.208 179.16 184Z"
        fill="url(#paint3_linear_12_7585)"
      />
      <path
        d="M62.1602 37H63.0352C63.1811 36.1042 63.5769 35.125 64.2436 34.0625C65.5769 31.9583 68.2644 29.8958 70.5769 29.4167V28.5417C69.4311 28.2917 68.3061 27.7708 67.2227 27C65.0144 25.4375 63.3894 23.1042 63.0352 21H62.1602C61.9519 22.1042 61.4727 23.1667 60.7227 24.25C59.2436 26.3958 56.9311 27.9792 54.5769 28.5417V29.4167C55.7644 29.6667 56.9727 30.25 58.1602 31.1458C60.5769 32.9583 61.8477 35.2083 62.1602 37Z"
        fill="url(#paint4_linear_12_7585)"
      />
      <path
        d="M33.1968 92.6812C33.2423 92.8643 33.402 93 33.5906 93C33.7809 93 33.9415 92.8616 33.9866 92.6768C34.1289 92.0944 34.4059 91.4678 34.8269 90.7969C35.7659 89.315 37.6004 87.8607 39.2567 87.3913C39.4415 87.3389 39.5769 87.1747 39.5769 86.9826C39.5769 86.7921 39.4438 86.6287 39.2602 86.5778C38.5102 86.3697 37.7737 86.0069 37.0613 85.5C35.5177 84.4079 34.3542 82.8136 33.988 81.3243C33.9423 81.1385 33.7806 81 33.5892 81C33.4008 81 33.2406 81.1345 33.1943 81.3171C33.0127 82.0327 32.6767 82.7292 32.1863 83.4375C31.1425 84.9517 29.5454 86.0925 27.8896 86.5735C27.7074 86.6264 27.5769 86.7901 27.5769 86.9798C27.5769 87.1737 27.7132 87.3395 27.9 87.3913C28.6861 87.609 29.4813 88.0186 30.2644 88.6094C31.9362 89.8633 32.8769 91.3963 33.1968 92.6812Z"
        fill="url(#paint5_linear_12_7585)"
      />
    </g>
    <defs>
      <filter
        id="filter0_dddddd_12_7585"
        x="38.5769"
        y="164"
        width="36"
        height="52"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_12_7585"
          result="effect2_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_12_7585"
          result="effect3_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_12_7585"
          result="effect4_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_12_7585"
          result="effect5_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="21" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_12_7585"
          result="effect6_dropShadow_12_7585"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_12_7585"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dddddd_12_7585"
        x="109.005"
        y="76.2856"
        width="49.6355"
        height="79.4285"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_12_7585"
          result="effect2_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_12_7585"
          result="effect3_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_12_7585"
          result="effect4_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_12_7585"
          result="effect5_dropShadow_12_7585"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="21" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_12_7585"
          result="effect6_dropShadow_12_7585"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_12_7585"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_12_7585"
        x1="153.29"
        y1="198.095"
        x2="226.04"
        y2="72.0885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#303030" />
        <stop offset="1" stopColor="#6F6F6F" stopOpacity="0.3" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_12_7585"
        x1="129.577"
        y1="63"
        x2="129.577"
        y2="141.689"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E5E5" />
        <stop offset="1" stopColor="#F4F4F4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_12_7585"
        x1="226.577"
        y1="117"
        x2="226.577"
        y2="128.803"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E5E5" />
        <stop offset="1" stopColor="#F4F4F4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_12_7585"
        x1="180"
        y1="189"
        x2="172.715"
        y2="169.177"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C1C1C1" />
        <stop offset="1" stopColor="#E8E8E8" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_12_7585"
        x1="63"
        y1="42"
        x2="55.7151"
        y2="22.1772"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#C1C1C1" />
        <stop offset="1" stopColor="#E8E8E8" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_12_7585"
        x1="33.5769"
        y1="81"
        x2="33.5769"
        y2="92.8033"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E5E5" />
        <stop offset="1" stopColor="#F4F4F4" />
      </linearGradient>
      <clipPath id="clip0_12_7585">
        <rect
          width="257.846"
          height="206.603"
          fill="white"
          transform="translate(0.576904)"
        />
      </clipPath>
      <clipPath id="clip1_12_7585">
        <rect x="89.5769" y="63" width="80" height="80" rx="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

OfflineIcon.displayName = 'Offline'
