// @ts-nocheck
import { RgbaValueComponent, Root } from '@aninix-inc/model'
import * as PIXI from 'pixi.js'
import {
  useImagesStore,
  usePlayback,
  useProject,
  useViewport,
} from '../../stores'
import { NodeSnapshot } from '../common/renderers/types'
import {
  DrawChildNodesConfig,
  drawChildNodes,
} from './pixi-drawers/draw-child-nodes'

export const drawRootNode = ({
  rootNodeSnapshot,
  app,
  childNodesConfig = {},
  prerenderCallbacks,
}: {
  rootNodeSnapshot: NodeSnapshot
  app: PIXI.Application
  childNodesConfig?: Partial<DrawChildNodesConfig>
  prerenderCallbacks: (() => void)[]
}) => {
  const project = useProject()
  const viewport = useViewport()
  const images = useImagesStore()
  const playback = usePlayback()

  while (prerenderCallbacks.length > 0) {
    const callback = prerenderCallbacks.shift()
    callback?.()
  }

  const findRootFrame = app.stage.getChildByName(
    'rootFrame'
  ) as PIXI.Container | null

  const rootFrame = findRootFrame ?? new PIXI.Container()

  rootFrame
    .getChildByName(rootNodeSnapshot.id + '_container')
    ?.destroy({ children: true })

  if (findRootFrame === null) app.stage.addChild(rootFrame)

  rootFrame.interactiveChildren = false

  rootFrame.name = 'rootFrame'
  rootFrame.position = viewport.position
  rootFrame.width = viewport.size.x
  rootFrame.height = viewport.size.y
  rootFrame.scale = { x: viewport.zoom, y: viewport.zoom }

  // const findProjectNameText = app.stage.getChildByName(
  //   'projectNameText'
  // ) as PIXI.Text | null

  // const projectNameText =
  //   findProjectNameText ??
  //   new PIXI.Text(rootNodeSnapshot?.name, {
  //     fontSize: 12,
  //     fill: ['#919191'],
  //   })
  // projectNameText.name = 'projectNameText'
  // projectNameText.position = viewport.position
  // projectNameText.position.y -= 18

  // if (findProjectNameText === null) app.stage.addChild(projectNameText)

  const postrenderCallbacks: (() => void)[] = []

  rootFrame.getChildByName('bg')?.destroy()

  const background = new PIXI.Graphics()
    .beginFill(
      project
        .getEntityByTypeOrThrow(Root)
        .getComponentOrThrow(RgbaValueComponent).value
    )
    .drawRect(0, 0, app.view.width, app.view.height)
    .endFill()
  background.name = 'bg'
  rootFrame.addChild(background)

  drawChildNodes({
    childNodes: [rootNodeSnapshot],
    container: rootFrame,
    images,
    viewport,
    playback,
    app,
    config: childNodesConfig,
    postrenderCallbacks,
    prerenderCallbacks,
  })

  postrenderCallbacks.forEach((callback) => {
    callback()
  })
}
