import { LocalStorageIo, useGetProject } from '@aninix/api'
import { CommentsPanel } from '@aninix/core/modules/comments-panel'
import { Popover } from '@material-ui/core'
import { getCurrentUnixTimestamp } from 'apps/anitype-plugin/src/figma/utils/get-current-unix-timestamp'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Details } from '../details'
import { AutostartExportOption, Export } from '../export'
import { OverridesPanel } from '../overrides-panel'

export type InspectMode =
  | 'preview'
  | 'details'
  | 'export'
  | 'comments'
  | 'overrides'
  | 'quick-export'

const ToggleItem: React.FCC<
  {
    isSelected: boolean
    icon: React.ReactNode
    onClick: () => void
  } & React.RefAttributes<HTMLButtonElement>
> = React.forwardRef(({ isSelected, icon, onClick }, ref) => (
  <button onClick={onClick} ref={ref}>
    <div
      className={classNames(
        ' flex h-10 w-10 items-center justify-center bg-white stroke-[#0B1118] hover:cursor-pointer hover:bg-gray-200',
        {
          ['!bg-accent !stroke-white  shadow-sm']: isSelected,
        }
      )}
    >
      {icon}
    </div>
  </button>
))

export interface IProps {
  projectId: string
  forwardRef: React.RefObject<HTMLDivElement>
  rightRatio: number
  leftRatio: number
  startListen: (e: MouseEvent) => {
    startAtX: number
    startAtY: number
  }
  inspectMode: InspectMode
  onInspectModeChange: (inspectMode: IProps['inspectMode']) => void
  recalculateRatio: () => void
  isListening: boolean
}
export const ToggleMenu: React.FCC<IProps> = observer(
  ({
    forwardRef,
    projectId,
    rightRatio,
    leftRatio,
    startListen,
    inspectMode,
    onInspectModeChange,
    recalculateRatio,
    isListening,
  }) => {
    const getProject = useGetProject({ projectId })

    const params = useSearchParams()

    const autostartExportOption =
      inspectMode === 'export'
        ? (params[0].get('format') as AutostartExportOption)
        : undefined

    const exportSessionId =
      inspectMode === 'export'
        ? (params[0].get('exportSessionId') as string)
        : undefined

    const startTime =
      inspectMode === 'export'
        ? parseFloat(
            params[0].get('startTime') ?? getCurrentUnixTimestamp().toString()
          )
        : undefined

    const getDefaultWidth = React.useCallback(() => {
      switch (inspectMode) {
        case 'preview':
          return '80px'
        case 'details':
          return document.body.getBoundingClientRect().width / 2 + 'px'
        case 'export':
          return '308px'
        case 'comments':
          return '308px'
        case 'overrides':
          return '308px'
        default:
          return '0px'
      }
    }, [inspectMode])

    const handleDoubleClick = React.useCallback(() => {
      setWidth(getDefaultWidth())
    }, [getDefaultWidth])

    const DisplayView = React.useMemo(() => {
      switch (inspectMode) {
        case 'preview':
          return null

        case 'details':
          return (
            <div className="min-w-0 flex-grow">
              <div
                className="absolute -left-1 z-[100] h-full w-2 opacity-0 hover:cursor-ew-resize"
                //@ts-ignore
                onMouseDown={startListen}
                onDoubleClick={handleDoubleClick}
              />
              <Details />
            </div>
          )

        case 'export':
          return (
            <Export
              autostartExportOption={autostartExportOption}
              exportSessionId={exportSessionId}
              startTime={startTime}
            />
          )

        case 'comments':
          return (
            <div className="flex h-full flex-grow flex-col justify-start gap-0 overflow-x-scroll rounded-xl bg-white">
              <CommentsPanel />
            </div>
          )

        case 'overrides':
          return (
            <div className="flex max-h-full flex-grow flex-col justify-start gap-0 overflow-x-scroll">
              <OverridesPanel />
            </div>
          )

        case 'quick-export':
          return null

        default: {
          const never: never = inspectMode
          throw new Error(`Should never reach "${never}"`)
        }
      }
    }, [inspectMode, handleDoubleClick, startListen])

    const [width, setWidth] = React.useState<string>('80px')

    React.useEffect(() => {
      setWidth(getDefaultWidth())
    }, [getDefaultWidth])

    React.useEffect(() => {
      recalculateRatio()
    }, [width])

    React.useEffect(() => {
      if (!isListening) return

      if (inspectMode === 'details')
        setWidth(
          document.body.getBoundingClientRect().width * rightRatio + 'px'
        )
    }, [isListening, rightRatio])

    if (!inspectMode) return null

    return (
      <div
        ref={forwardRef}
        className="absolute right-0 flex h-full flex-row gap-0 overflow-clip"
        style={{
          width,
          background: 'transparent',
          //backgroundColor: tinycolor(project.backgroundColor).toRgbString(),
        }}
      >
        <div
          className={classNames(
            'absolute inset-y-2 left-0 right-2 flex flex-row justify-end gap-2 rounded-xl bg-transparent',
            [
              {
                '!inset-y-5':
                  (getProject.data?.data.totalCommentsCount ?? 0) > 0,
              },
            ]
          )}
        >
          {DisplayView}
        </div>
      </div>
    )
  }
)

interface ToggleViewProps {
  view: InspectMode
  onInspectModeChange: (insepctMode: InspectMode) => void
}

const commentsOnboardingLocalStorage = new LocalStorageIo<{
  passed: boolean
}>('aninix.comments-onboarding', {
  passed: false,
})

const ToggleView: React.FCC<ToggleViewProps> = ({
  view,
  onInspectModeChange,
}) => {
  const commentsIconRef = React.useRef<HTMLButtonElement>(null)

  const [isCommentOnbOpen, setIsCommentOnbOpen] = React.useState<boolean>(false)

  const [isCommentOnbPassed, setIsCommentOnbPassed] =
    React.useState<boolean>(false)

  const openCommentOnb = React.useCallback(() => {
    if (sessionStorage.getItem('comments-onb-seen') === 'true') return
    if (isCommentOnbPassed) return
    if (view === 'comments') return

    setIsCommentOnbOpen(true)
    sessionStorage.setItem('comments-onb-seen', 'true')
  }, [view, isCommentOnbPassed])

  const closeCommentOnb = React.useCallback(() => {
    setIsCommentOnbOpen(false)
  }, [])

  const handleCommentOnbPass = React.useCallback(() => {
    commentsOnboardingLocalStorage.set({ passed: true })
    setIsCommentOnbPassed(true)
    closeCommentOnb()
  }, [closeCommentOnb])

  const [timer, setTimer] = React.useState(0)

  React.useMemo(() => {
    if (['comments'].includes(view)) {
      handleCommentOnbPass()
      return
    }

    if (!['details', 'preview'].includes(view)) return

    commentsOnboardingLocalStorage.get().then((result) => {
      if (result === null) {
        commentsOnboardingLocalStorage.reset()
      }

      setIsCommentOnbPassed(result?.passed ?? false)
      // @ts-ignore
      if (!(result?.passed ?? false)) setTimer(setTimeout(openCommentOnb, 2000))
    })
  }, [view, openCommentOnb, handleCommentOnbPass])

  React.useEffect(() => {
    if (view === 'comments') handleCommentOnbPass()
    clearTimeout(timer)
  }, [view])

  return (
    <div className="flex h-fit flex-shrink-0 flex-col gap-0 overflow-clip rounded-xl">
      <Popover
        elevation={0}
        disableAutoFocus
        PaperProps={{ style: { background: 'transparent' } }}
        disableEnforceFocus
        open={isCommentOnbOpen}
        onClose={closeCommentOnb}
        anchorEl={commentsIconRef.current}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
      >
        <div className="flex flex-col gap-3">
          <div className="relative pr-2">
            <div className="absolute right-1 top-1/2 -translate-y-1/2">
              <PointerIcon />
            </div>
            <div className="flex w-64 flex-col gap-2 rounded-xl bg-[#0B1118] p-3 shadow">
              <div className="flex flex-col gap-1">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center gap-1">
                    {/* <MathOpsIcon /> */}
                    <p className="text-xs font-semibold leading-none text-[#18A0FB]">
                      Give feedback in comments
                    </p>
                  </div>
                  <button onClick={closeCommentOnb}>
                    <CloseIcon />
                  </button>
                </div>

                <p className="text-xs font-normal leading-4 text-white text-opacity-80">
                  Leave notes directly on the animation at the precise time and
                  location. <br />
                  <br />
                  Both the author and you will receive notifications for replies
                  and resolved comments.
                </p>
              </div>
              <button
                className="w-max rounded-md bg-[#18A0FB] px-4 py-2"
                onClick={handleCommentOnbPass}
              >
                <p className="text-whitetext-center text-[11px] font-medium leading-none tracking-tight text-white">
                  Got it
                </p>
              </button>
            </div>
          </div>
        </div>
      </Popover>

      <ToggleItem
        isSelected={view === 'preview'}
        onClick={() => onInspectModeChange('preview')}
        icon={
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 12.5C10 13.0304 10.2107 13.5391 10.5858 13.9142C10.9609 14.2893 11.4696 14.5 12 14.5C12.5304 14.5 13.0391 14.2893 13.4142 13.9142C13.7893 13.5391 14 13.0304 14 12.5C14 11.9696 13.7893 11.4609 13.4142 11.0858C13.0391 10.7107 12.5304 10.5 12 10.5C11.4696 10.5 10.9609 10.7107 10.5858 11.0858C10.2107 11.4609 10 11.9696 10 12.5Z"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M21 12.5C18.5997 16.5003 15.6 18.5 12 18.5C8.4 18.5 5.4003 16.5003 3 12.5C5.4003 8.49971 8.4 6.5 12 6.5C15.6 6.5 18.5997 8.49971 21 12.5Z"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      />
      <ToggleItem
        isSelected={view === 'details'}
        onClick={() => onInspectModeChange('details')}
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path
                d="M17 8L21 12L17 16"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 8L3 12L7 16"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14 4L10 20"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_367_20066"
                x="-2"
                y="-1"
                width="28"
                height="28"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1" />
                <feGaussianBlur stdDeviation="1" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0.0431373 0 0 0 0 0.0666667 0 0 0 0 0.0941176 0 0 0 0.3 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_367_20066"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_367_20066"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
        }
      />
      <ToggleItem
        isSelected={view === 'export'}
        onClick={() => onInspectModeChange('export')}
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 17V19.6C4 20.3732 4.6268 21 5.4 21H18.6C19.3732 21 20 20.3732 20 19.6V17"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 11L12 16L17 11"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 4V16"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
      />

      <ToggleItem
        isSelected={view === 'comments'}
        onClick={() => onInspectModeChange('comments')}
        ref={commentsIconRef}
        icon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="1">
              <path
                d="M3 20.0001L4.3 16.1001C3.17644 14.4384 2.76999 12.4705 3.15622 10.5624C3.54244 8.65427 4.69506 6.93575 6.39977 5.72635C8.10447 4.51696 10.2453 3.89898 12.4241 3.98732C14.6029 4.07566 16.6715 4.86431 18.2453 6.20664C19.819 7.54896 20.7909 9.35362 20.9801 11.2851C21.1693 13.2165 20.563 15.1433 19.2739 16.7072C17.9848 18.2711 16.1007 19.3657 13.9718 19.7874C11.8429 20.2092 9.6142 19.9294 7.7 19.0001L3 20.0001Z"
                strokeWidth="1.4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        }
      />
    </div>
  )
}

ToggleMenu.displayName = 'ToggleMenu'

const PointerIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9497 4.94975L6 0L0.343146 5.65685L6 11.3137L10.9497 6.36396C11.3403 5.97344 11.3403 5.34027 10.9497 4.94975Z"
      fill="#0B1118"
    />
  </svg>
)
const CloseIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00002 7.29287L12.6465 2.64641L13.3536 3.35351L8.70713 7.99998L13.3536 12.6464L12.6465 13.3535L8.00002 8.70709L3.35359 13.3535L2.64648 12.6464L7.29291 7.99998L2.64649 3.35359L3.35359 2.64648L8.00002 7.29287Z"
      fill="white"
      fillOpacity="0.4"
    />
  </svg>
)
