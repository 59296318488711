import { useMutation } from '@tanstack/react-query'

import {
  ApiPatchParams,
  ApiPatchRequestBody,
  ApiPatchResponse,
} from './helpers'
import { httpClient } from './http-client'

export function useLinkFolderToFolder() {
  return useMutation({
    async mutationFn(
      payload: ApiPatchParams<'/folders/{folderId}'>['path'] &
        ApiPatchRequestBody<'/folders/{folderId}'>
    ) {
      return httpClient.patch<ApiPatchResponse<'/folders/{folderId}'>>(
        `/folders/${payload.folderId}`,
        { parentFolderId: payload.parentFolderId ?? null }
      )
    },
  })
}
