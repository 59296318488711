import * as React from 'react'

import { Both } from './Both'
import { None } from './None'
import { Timeline } from './Timeline'
import { Viewport } from './Viewport'

const defaultSize = {
  x: 20,
  y: 20,
}

export enum NodeSoloType {
  None = 'NONE',
  Both = 'BOTH',
  Timeline = 'TIMELINE',
  Viewport = 'VIEWPORT',
}

export interface IProps {
  type: NodeSoloType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Solo: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  if (NodeSoloType.Both === type) {
    return <Both size={size} style={style} />
  }

  if (NodeSoloType.None === type) {
    return <None size={size} style={style} />
  }

  if (NodeSoloType.Timeline === type) {
    return <Timeline size={size} style={style} />
  }

  if (NodeSoloType.Viewport === type) {
    return <Viewport size={size} style={style} />
  }

  return null
}
