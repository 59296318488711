import { NodeSnapshot, PaintSnapshot, paper } from '@aninix-inc/renderer'

export function isRectOnPath({
  nodeSnapshot,
  rect,
  path,
  fills,
  strokes,
  skipGroups,
}: {
  nodeSnapshot: NodeSnapshot
  fills: PaintSnapshot[]
  strokes: PaintSnapshot[]
  rect: {
    x: number
    y: number
    width: number
    height: number
  }
  path: string
  skipGroups: boolean
}) {
  // if (
  //   skipGroups &&
  //  isGroup
  // )
  //   return false
  const isGroup = nodeSnapshot.children !== null

  const paperPath = new paper.CompoundPath(path)
  paperPath.matrix = nodeSnapshot.absoluteTransformMatrix

  if (fills.length > 0) paperPath.fillColor = new paper.Color('black')

  if (isGroup) {
    paperPath.fillColor = new paper.Color('black')
  }

  if (strokes.length > 0)
    paperPath.set({
      strokeWidth: Math.max(
        nodeSnapshot.strokeWeight,
        nodeSnapshot.strokeBottomWeight,
        nodeSnapshot.strokeLeftWeight,
        nodeSnapshot.strokeTopWeight,
        nodeSnapshot.strokeRightWeight
      ),
      strokeColor: 'black',
    })

  const rectPath = new paper.Path.Rectangle(rect)
  rectPath.fillColor = new paper.Color('black')

  const skipGroupWhenNotInside = !(
    skipGroups &&
    isGroup &&
    !paperPath.isInside(rect)
  )

  const doesContain =
    skipGroupWhenNotInside &&
    (paperPath.intersects(rectPath) || paperPath.isInside(rect))

  return { doesContain, skipGroupWhenNotInside }
}
