interface IOptions {
  fixed: number
}
export function round(
  number: number,
  options: IOptions = { fixed: 2 }
): number {
  const zeros = 10 ** options.fixed
  return Math.round(number * zeros) / zeros
}
