import React from 'react'

import { Active } from './active'
import { Empty } from './empty'

export enum PropertyKeyframesType {
  Active = 'ACTIVE',
  HasKeyframes = 'HAS_KEYFRAMES',
  Empty = 'EMPTY',
}

export interface IProps {
  type: PropertyKeyframesType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const KeyIndicator: React.FCC<IProps> = ({ type, size, style }) => {
  if (type === PropertyKeyframesType.Active) {
    return <Active style={style} size={size} />
  }

  return <Empty style={style} size={size} />
}
