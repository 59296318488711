import { CornerRadiusComponent } from '@aninix-inc/model'
import { icons, PropertyRowV2 } from '@aninix/app-design-system'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { NumberValue } from '../../values/number'

const iconSize = {
  x: 12,
  y: 12,
}

export const CornerRadius: React.FC = () => {
  const { nodes } = useNodePropertiesPanel()

  const filteredNodes = nodes.filter((n) =>
    n.hasComponent(CornerRadiusComponent)
  )

  if (!filteredNodes.length) return null

  const components = filteredNodes.map((n) =>
    n.getComponentOrThrow(CornerRadiusComponent)
  )
  const radiuses = components.map((c) => c.value)

  return <CornerRadiusMemo components={components} radiuses={radiuses} />
}

const CornerRadiusMemo: React.FC<{
  components: CornerRadiusComponent[]
  radiuses: number[]
}> = React.memo(
  ({ components }) => (
    <PropertyRowV2
      name="Corner Radius"
      inputs={
        <NumberValue
          components={components}
          icon={<icons.propertiesPanel.CornerRadius size={iconSize} />}
          min={0}
        />
      }
    />
  ),
  (prev, next) => {
    for (let i = 0; i < prev.radiuses.length; i += 1) {
      if (prev.radiuses[i] !== next.radiuses[i]) return false
    }
    return true
  }
)

CornerRadius.displayName = 'CornerRadius'
