import { binaryToJson, jsonToBinary } from '@aninix/core'
import { useGetLottie } from '@aninix/core/use-cases/lottie-preview/use-get-lottie'
import { DotLottie } from '@dotlottie/dotlottie-js'
import classNames from 'classnames'
import { openDB } from 'idb'
import lottie, { AnimationItem } from 'lottie-web'
import pako from 'pako'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from '../toasts'
import { LottieArt } from './components/lottie-art'
import {
  PlayerControls,
  PlayerControlsSkeleton,
} from './components/player-controls'
import { PlayerHeader, PlayerHeaderSkeleton } from './components/player-header'

export interface IProps {}
export const LottiePreview: React.FCC<IProps> = () => {
  const [isDraggedOver, setIsDraggedOver] = React.useState(false)
  const [animationItem, setAnimationItem] =
    React.useState<AnimationItem | null>(null)

  const [bgColor, setBgColor] = React.useState<string>('#ffffff')

  //prepared to send via network
  const [compressedLottie, setCompressedLottie] =
    React.useState<Uint8Array | null>(null)

  const containerRef = React.useRef<HTMLDivElement>(null)

  const handleDragOver = React.useCallback((e: DragEvent) => {
    e.preventDefault()

    setIsDraggedOver(true)
  }, [])
  const handleDragLeave = React.useCallback((e: DragEvent) => {
    e.preventDefault()

    setIsDraggedOver(false)
  }, [])

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files?.length === 0) return

      handleFileSelection(e.target.files?.[0])
    },
    [animationItem]
  )

  const handleDrop = React.useCallback(
    (e: DragEvent) => {
      e.preventDefault()

      setIsDraggedOver(false)

      handleFileSelection(e.dataTransfer?.files[0])
    },
    [animationItem]
  )

  const handleFileSelection = (file: File | undefined) => {
    console.log(file)

    const fileExtension = file?.name.split('.').at(-1) ?? ''

    if (
      !['json', 'lottie', 'tgs'].includes(fileExtension) ||
      file === undefined
    ) {
      toast('Dragged file is not supported. Try .json or .lottie files', {
        variant: 'error',
      })
      return
    }

    if (fileExtension === 'lottie') {
      new DotLottie()
        .fromURL(URL.createObjectURL(file))
        .then((dotlottie) => {
          return dotlottie
            .getAnimations()?.[0][1]
            .toBlob({ inlineAssets: true })
        })
        .then((blob) => {
          if (blob === undefined)
            throw Error('Dotlottie first animation is undefined')

          const fileReader = new FileReader()
          fileReader.readAsText(blob, 'UTF-8')
          fileReader.onload = handleFileLoad(file.name)
        })
        .catch((e) => {
          toast('Dragged object is not a valid .lottie animation', {
            variant: 'error',
          })
        })
    } else if (fileExtension === 'json') {
      const fileReader = new FileReader()
      fileReader.readAsText(file, 'UTF-8')
      fileReader.onload = handleFileLoad(file.name)
    } else if (fileExtension === 'tgs') {
      try {
        file.arrayBuffer().then((arrayBuffer) => {
          const blob = new File(
            [pako.inflate(arrayBuffer, { to: 'string' })],
            'tgs.json'
          )
          const fileReader = new FileReader()
          fileReader.readAsText(blob, 'UTF-8')
          fileReader.onload = handleFileLoad(file.name)
        })
      } catch {
        toast('File is not valid .tgs animation', { variant: 'error' })
      }
    }
  }

  const handleFileLoad = React.useCallback(
    (fileName: string) => (e: ProgressEvent<FileReader>) => {
      console.log(fileName)
      try {
        const json = JSON.parse(e.target?.result as string)
        try {
          animationItem?.destroy()
          containerRef.current!.innerHTML = ''
          setAnimationItem(null)
          setCompressedLottie(null)
          setExpiredAt(null)

          const animation = lottie.loadAnimation({
            container: containerRef.current!,
            animationData: json,
            name: fileName,
            loop: true,
            renderer: 'svg',
          })

          jsonToBinary({ ...json, nm: fileName }).then((file) =>
            setCompressedLottie(file)
          )

          setAnimationItem(animation)

          setSearchParams(new URLSearchParams())
        } catch {
          toast('File is not a valid Lottie animation', { variant: 'error' })
        }
      } catch {
        toast('File is not a valid JSON', { variant: 'error' })
      }
    },
    [animationItem, containerRef]
  )

  React.useEffect(() => {
    document.body.addEventListener('dragover', handleDragOver)
    document.body.addEventListener('dragleave', handleDragLeave)
    document.body.addEventListener('drop', handleDrop)

    return () => {
      document.body.removeEventListener('dragover', handleDragOver)
      document.body.removeEventListener('dragleave', handleDragLeave)
      document.body.removeEventListener('drop', handleDrop)
    }
  }, [handleDrop])

  let [searchParams, setSearchParams] = useSearchParams()
  const { queryAsync: getLottie } = useGetLottie()

  const [isGettingLottie, setIsGettingLottie] = React.useState(false)

  const [expiredAt, setExpiredAt] = React.useState<string | null>(null)

  interface LocationState {
    file: File | null
  }

  const getFileFromDB = React.useCallback(async () => {
    try {
      const db = await openDB('aninix-lottie-preview', 1)
      const fileEntry = await db.get('files', '0')
      db.delete('files', '0')
      return fileEntry?.file
    } catch (e) {
      console.log(e)
      return undefined
    }
  }, [])

  React.useMemo(() => {
    const id = searchParams.get('id')

    const postRenderPreview = searchParams.get('post-render-preview')

    if (postRenderPreview === 'true') {
      getFileFromDB().then((file) => {
        if (file === undefined) return
        handleFileSelection(file)
        return
      })
    }

    if (id === null) return

    setIsGettingLottie(true)

    getLottie?.(id)
      .then((result) => {
        const { data } = result
        setExpiredAt(data.expiredAt)
        const binaryData = new Uint8Array(data.data.data)
        setCompressedLottie(binaryData)
        return binaryToJson(binaryData)
      })
      .then((json) => {
        const animation = lottie.loadAnimation({
          container: containerRef.current!,
          animationData: json,
          name: (json as any).nm,
          loop: true,
          renderer: 'svg',
        })

        setAnimationItem(animation)
      })
      .catch((e) => {
        toast(
          'The file could not be found. It may have expired or been deleted by the owner.',
          {
            variant: 'error',
          }
        )
        setSearchParams(new URLSearchParams())
      })
      .finally(() => {
        setIsGettingLottie(false)
      })
  }, [])

  React.useEffect(() => {
    //add styling when animationItem changes
    const firstChild = containerRef.current?.firstChild as SVGElement
    if (firstChild) {
      firstChild.style.position = 'absolute'
      firstChild.style.left = '0'
      firstChild.style.right = '0'
      firstChild.style.bottom = '0'
      firstChild.style.top = '0'

      const boundingBox = document.createElementNS(
        'http://www.w3.org/2000/svg',
        'rect'
      )
      boundingBox.setAttribute('x', '0')
      boundingBox.setAttribute('y', '0')
      boundingBox.setAttribute('width', '100%')
      boundingBox.setAttribute('height', '100%')
      boundingBox.setAttribute('stroke', '#5C65741A')
      boundingBox.setAttribute('fill', 'none')
      boundingBox.style.height = 'calc(100% - 2px)'
      boundingBox.style.transform = 'translate(1px, 1px)'
      boundingBox.style.width = 'calc(100% - 2px)'

      firstChild.appendChild(boundingBox)
    }
  }, [animationItem])

  React.useEffect(() => {
    if (animationItem === null) document.title = 'Aninix Lottie Preview'
    else document.title = animationItem.name
  }, [animationItem])

  const browseFileInputRef = React.useRef<HTMLInputElement>(null)

  const handleDragAndDropAreaClick = React.useCallback(() => {
    browseFileInputRef.current?.click()
  }, [browseFileInputRef])

  return (
    <div
      className="pointer-events-none flex h-[100svh] w-screen flex-col items-start justify-center gap-5 pb-10 sm:gap-10"
      style={{ '--bgColor': bgColor } as React.CSSProperties}
    >
      <div
        className={classNames(
          'absolute -bottom-2 left-0 right-0 z-30 flex w-full flex-row justify-center px-6 pt-6 transition-all duration-200 sm:px-20',
          {
            ['-translate-y-10']: animationItem || isGettingLottie,
          }
        )}
      >
        {animationItem && !isDraggedOver && (
          <PlayerControls
            animationItem={animationItem}
            bgColor={bgColor}
            setBgColor={setBgColor}
          />
        )}
        {isGettingLottie && <PlayerControlsSkeleton />}
      </div>

      <div className="flex w-full flex-row items-center justify-between border-b border-[#5C65741A] px-6 py-4 sm:px-20">
        <div className="flex flex-row items-center gap-3">
          <AninixIcon />
          <p className="text-sm sm:text-lg">
            <span className=" font-bold leading-normal text-secondary">
              Aninix{' '}
            </span>
            <span className=" leading-normal text-accent">Labs</span>
          </p>
        </div>
        <div className="text-center text-sm font-bold text-secondary sm:text-lg">
          Lottie player
        </div>
        <a
          draggable={false}
          href="https://aninix.com/"
          className="pointer-events-auto text-right text-sm text-secondary sm:text-lg"
        >
          Aninix website
        </a>
      </div>

      <div className="w-full flex-grow px-4 lg:px-[110px]">
        <div
          className={classNames(
            'relative flex h-full w-full items-center justify-center rounded-3xl',
            {
              ['bg-gradient-to-b from-[#374FD51A] to-[#FFFFFF00]']:
                isDraggedOver,
            }
          )}
        >
          <div
            className={classNames(
              'absolute -inset-2 bottom-12 top-0 z-20 flex flex-col gap-6 transition-opacity duration-200 ',
              {
                ['opacity-0']: !isGettingLottie,
              }
            )}
          >
            <PlayerHeaderSkeleton />
            <div className="h-full w-full animate-pulse bg-gray-200" />
          </div>
          <div
            className={classNames(
              'absolute -inset-2 bottom-16 z-20 flex flex-col gap-6 bg-white transition-opacity duration-200',
              {
                ['opacity-0']: !animationItem || isDraggedOver,
              }
            )}
          >
            {animationItem && (
              <PlayerHeader
                fileName={animationItem?.name ?? 'loading'}
                expiredAt={expiredAt}
                setExpiredAt={setExpiredAt}
                handleChange={handleChange}
                compressedLottie={compressedLottie}
              />
            )}

            <div
              style={{ background: 'var(--bgColor)' }}
              className={classNames(
                'relative flex h-full items-center justify-center rounded-lg border-[20px] border-transparent'
              )}
              ref={containerRef}
            />
          </div>

          <label
            className={classNames('group pointer-events-auto', {
              ['invisible']:
                (animationItem && !isDraggedOver) || isGettingLottie,
            })}
          >
            <DashedBorder
              className={classNames(
                'absolute -inset-[1px] z-0 h-full w-full overflow-visible rounded-[24px] stroke-[#E6EAED] transition-transform duration-150',
                {
                  ['stroke-[url(#stroke-gradient)]']: isDraggedOver,
                  ['group-hover:scale-[1.01]']: !isDraggedOver,
                }
              )}
            />

            <div className="drop:bg-white  z-10 flex flex-col items-center gap-6">
              <LottieArt isDraggedOver={isDraggedOver} />

              <div className="flex flex-col gap-2 px-2">
                <div
                  className={classNames('flex w-full flex-row justify-center')}
                >
                  <p
                    className={classNames(
                      'text-center text-[32px] font-bold sm:text-[40px]'
                    )}
                  >
                    Preview and test .lottie animations
                  </p>
                </div>

                <p className=" text-center text-xl font-medium text-secondary">
                  Drag’n’drop Lottie{' '}
                  <span className="rounded-md bg-accent p-1 text-white">
                    .json
                  </span>{' '}
                  ,{' '}
                  <span className="rounded-md bg-accent p-1 text-white">
                    .lottie
                  </span>{' '}
                  or{' '}
                  <span className="rounded-md bg-accent p-1 text-white">
                    .tgs
                  </span>{' '}
                  file
                </p>
              </div>

              <div className="flex flex-col items-center gap-1">
                <p className="text-lg font-normal text-neutral-400 ">or</p>
                <div className="flex flex-row items-center gap-2 rounded-xl border border-[#5C65741A] border-opacity-10 px-3 py-[7px]">
                  <input
                    ref={browseFileInputRef}
                    className="hidden"
                    type={'file'}
                    accept={'.lottie, .json, .tgs'}
                    onChange={handleChange}
                  />
                  <ButtonBrowseIcon />
                  <p>Browse file</p>
                </div>
              </div>
            </div>
          </label>
        </div>
      </div>
    </div>
  )
}
LottiePreview.displayName = 'LottiePreview'

const AninixIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.3125 15.7188L13.9062 22.3125V10.125L11.2812 11.5003V24.7188L12.9375 26.0625L25.125 15.125L12.75 5.3125L10.125 6.6875L21.3125 15.7188Z"
      fill="#374FD5"
    />
    <rect
      x="0.5"
      y="0.5"
      width="31"
      height="31"
      rx="7.5"
      stroke="#374FD5"
      strokeOpacity="0.3"
    />
  </svg>
)

const ButtonBrowseIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3995_14147)">
      <path
        d="M11.3333 13.5L13 15.1667M8.33333 12.1667C8.33333 12.6087 8.50893 13.0326 8.82149 13.3452C9.13405 13.6577 9.55797 13.8333 10 13.8333C10.442 13.8333 10.866 13.6577 11.1785 13.3452C11.4911 13.0326 11.6667 12.6087 11.6667 12.1667C11.6667 11.7246 11.4911 11.3007 11.1785 10.9882C10.866 10.6756 10.442 10.5 10 10.5C9.55797 10.5 9.13405 10.6756 8.82149 10.9882C8.50893 11.3007 8.33333 11.7246 8.33333 12.1667Z"
        stroke="black"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.57143 1.5V4.61111C9.57143 4.81739 9.65421 5.01522 9.80156 5.16108C9.94891 5.30694 10.1488 5.38889 10.3571 5.38889H13.5M9.57143 1.5H4.07143C3.65466 1.5 3.25496 1.66389 2.96026 1.95561C2.66556 2.24733 2.5 2.643 2.5 3.05556V13.9444C2.5 14.357 2.66556 14.7527 2.96026 15.0444C3.25496 15.3361 3.65466 15.5 4.07143 15.5H7M9.57143 1.5L13.5 5.38889M13.5 5.38889V9"
        stroke="black"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3995_14147">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

const DashedBorder = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="100%"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="1"
      width="100%"
      height="100%"
      rx="24"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="8 8"
    />
    <defs>
      <linearGradient
        id="stroke-gradient"
        x1="531"
        y1="1"
        x2="531"
        y2="676"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#374FD5" stopOpacity="0.5" />
        <stop offset="1" stopColor="#E6EAED" />
      </linearGradient>
    </defs>
  </svg>
)
