import classNames from 'classnames'
import * as React from 'react'
import { SelectedItem } from './selected-item'
import { ITeamItem, TeamItem } from './team-item'

export interface IProps {
  teams: ITeamItem[]
  onSelect: (item: ITeamItem) => void
  selectedTeam: ITeamItem
  disabled?: boolean
}

export const TeamPicker: React.FC<IProps> = ({
  teams: teams,
  onSelect,
  selectedTeam,
  disabled,
}) => {
  const [isSelectingOther, setIsSelectingOther] = React.useState(false)

  return (
    <div className="w-full h-min flex flex-col bg-secondary relative z-[1110]">
      <SelectedItem
        onDeselect={() => {
          setIsSelectingOther(true)
        }}
        item={selectedTeam}
        disabled={disabled}
      />

      <div
        className={classNames(
          'absolute top-0 left-0 right-0 bg-secondary transition-opacity duration-100 z-[1111]',
          {
            ['opacity-0 pointer-events-none']: !isSelectingOther,
          }
        )}
      >
        {teams.map((item) => (
          <TeamItem
            key={item.id}
            item={item}
            onSelect={() => {
              setIsSelectingOther(false)
              onSelect?.(item)
            }}
            isSelected={item.id === selectedTeam.id}
          />
        ))}
      </div>
    </div>
  )
}

TeamPicker.displayName = 'TeamPicker'
