import { config } from '../../../apps/web-app/src/config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'

/**
 * @returns created project id
 */
export async function remixProject(payload: {
  projectId: string
  teamId?: string
}): Promise<{ id: string }> {
  const response = await httpClient.post<
    paths['/v2/projects/{projectId}/remix']['post']['responses']['201']['content']['application/json']
  >(`${config.apiUrl}/v2/projects/${payload.projectId}/remix`, {
    teamId: payload.teamId,
  } satisfies paths['/v2/projects/{projectId}/remix']['post']['requestBody']['content']['application/json'])

  return response.data
}
