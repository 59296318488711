// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LwosBA2uboH3VHxrD251{position:relative;width:100%;height:100%;overflow:hidden;background-image:linear-gradient(45deg, #f2f2f2 25%, transparent 25%),linear-gradient(45deg, transparent 75%, #f2f2f2 75%),linear-gradient(45deg, transparent 75%, #f2f2f2 75%),linear-gradient(45deg, #f2f2f2 25%, transparent 25%);background-position:0 0,0 0,-4px -4px,4px 4px;background-size:8px 8px}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/gradient-slider/color-stop/index.scss"],"names":[],"mappings":"AAUA,sBACE,iBAAA,CAEA,UAAA,CACA,WAAA,CACA,eAAA,CACA,oOAAA,CAoBA,6CACE,CAMF,uBAAA","sourcesContent":["@use '../../../../theme/index.scss' as *;\n\n$checkerboard-item-light: transparent;\n$checkerboard-item-dark: #f2f2f2;\n\n:export {\n  color_black: $background-black-3;\n  color_highlighted: $accent-blue;\n}\n\n.container {\n  position: relative;\n\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  background-image: linear-gradient(\n      45deg,\n      $checkerboard-item-dark 25%,\n      $checkerboard-item-light 25%\n    ),\n    linear-gradient(\n      45deg,\n      $checkerboard-item-light 75%,\n      $checkerboard-item-dark 75%\n    ),\n    linear-gradient(\n      45deg,\n      $checkerboard-item-light 75%,\n      $checkerboard-item-dark 75%\n    ),\n    linear-gradient(\n      45deg,\n      $checkerboard-item-dark 25%,\n      $checkerboard-item-light 25%\n    );\n  background-position:\n    0 0,\n    0 0,\n    -4px -4px,\n    4px 4px;\n\n  /* 8px is the size of checkerboard item */\n  background-size: 8px 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export var color_black = `#b3b3b3`;
export var color_highlighted = `var(--figma-color-bg-brand)`;
export var container = `LwosBA2uboH3VHxrD251`;
export default ___CSS_LOADER_EXPORT___;
