import * as React from 'react'

import { Horizontally as HorizontallyIcon } from './horizontally'
import { Vertically as VerticallyIcon } from './vertically'

export enum NodesDistributionType {
  Horizontally = 'horizontally',
  Vertically = 'vertically',
}

export interface IProps {
  type: NodesDistributionType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const NodesDistribution: React.FCC<IProps> = ({ type, size, style }) => {
  if (NodesDistributionType.Horizontally === type) {
    return <HorizontallyIcon style={style} size={size} />
  }

  if (NodesDistributionType.Vertically === type) {
    return <VerticallyIcon style={style} size={size} />
  }

  return null
}
