import * as React from 'react'

const sizeDefault = {
  x: 36,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Square: React.FCC<IProps> = ({ size = sizeDefault, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 36 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M2 5H35V4H1V12H35V11H2V5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
