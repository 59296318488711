import classnames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
  state?: 'normal' | 'loading'
}
export const SyncNodesStructure: React.FCC<IProps> = ({
  size = defaultSize,
  style,
  state = 'normal',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 24L10 22H8V21H10L10 11H8V10H10L10 8H11V10H21L21 8H22L22 10H24V11H22L22 14L21 14L21 11H11L11 21H14V22H11V24H10Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5 20C16.5 18.067 18.067 16.5 20 16.5C21.933 16.5 23.5 18.067 23.5 20V20.7929L21.8536 19.1464L21.1464 19.8536L23.6464 22.3536L24 22.7071L24.3536 22.3536L26.8536 19.8536L26.1464 19.1464L24.5 20.7929V20C24.5 17.5147 22.4853 15.5 20 15.5C17.5147 15.5 15.5 17.5147 15.5 20C15.5 22.4853 17.5147 24.5 20 24.5H21V23.5H20C18.067 23.5 16.5 21.933 16.5 20Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      className={classnames({
        [styles.loading]: state === 'loading',
      })}
    />
  </svg>
)
