/* eslint-disable react/require-default-props */
import MaterialCheckbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import React from 'react'
import { RiCheckboxIndeterminateLine } from 'react-icons/ri'

interface ICheckboxProps {
  onClick: (value: string) => void
  value?: string
  label?: string
  disabled?: boolean
  isChecked?: boolean
  description?: string
  mixed?: boolean
}

const Checkbox = ({
  onClick,
  value,
  label,
  disabled,
  isChecked,
  description,
  mixed,
}: ICheckboxProps) => {
  const handleClick = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onClick(e.target.value)
      e.target.blur()
    },
    [onClick]
  )

  return (
    <>
      <FormControlLabel
        control={
          <MaterialCheckbox
            size="small"
            checked={isChecked}
            disabled={disabled}
            onChange={handleClick}
            tabIndex={-1}
            value={value}
            indeterminate={mixed}
            indeterminateIcon={
              <RiCheckboxIndeterminateLine
                color={disabled ? 'rgba(0, 0, 0, 0.3)' : 'rgba(0, 0, 0, 0.8)'}
              />
            }
            className="text-xs text-[11px]"
            classes={{
              root: '!w-4 !h-4 [&>span>svg]:!w-4 [&>span>svg]:!h-4 [&>span>svg]:!text-[var(--figma-color-bg-brand)] [&>span>svg]:!rounded-[2px]',
              disabled: '[&>span>svg]:!text-[var(--#b3b3b3)]',
            }}
            color="primary"
          />
        }
        label={label}
        classes={{
          label: 'text-xs text-[11px] !ml-1',
          root: '!m-0',
        }}
      />

      {description && (
        <p className="text-xs text-[11px] m-0 mr-2 mb-2 ml-6 text-[var(--figma-color-icon-onselected-secondary)]">
          {description}
        </p>
      )}
    </>
  )
}

export default Checkbox
