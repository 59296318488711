import {
  PopperProps,
  Tooltip as SourceTooltip,
  TooltipProps,
} from '@material-ui/core'
import { Instance } from '@popperjs/core'
import classNames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

interface IProps {
  tooltipClassName?: string
  arrowClassName?: string
  followCursor?: boolean
  offsetX?: number
  offsetY?: number
}
export const Tooltip: React.FCC<TooltipProps & IProps> = ({
  tooltipClassName,
  arrowClassName,
  children,
  followCursor = false,
  offsetX = 0,
  offsetY = 0,
  ...props
}) => {
  const positionRef = React.useRef<{ x: number; y: number }>({
    x: 0,
    y: 0,
  })
  const popperRef = React.useRef<Instance>(null)
  const areaRef = React.useRef<HTMLDivElement>(null)

  const handleMouseMove = React.useCallback(
    (event: React.MouseEvent) => {
      if (!followCursor) {
        return
      }

      positionRef.current = { x: event.clientX, y: event.clientY }

      if (popperRef.current != null) {
        popperRef.current.update()
      }
    },
    [followCursor]
  )

  // @ts-ignore
  const popperProps: PopperProps = followCursor
    ? {
        popperRef,
        anchorEl: {
          getBoundingClientRect: () => {
            return new DOMRect(
              positionRef.current.x + offsetX,
              areaRef.current!.getBoundingClientRect().y + offsetY,
              0,
              0
            )
          },
        },
        style: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      }
    : {
        popperRef,
        style: {
          paddingTop: 4,
          paddingBottom: 4,
        },
      }

  return (
    <SourceTooltip
      {...props}
      classes={{
        // @NOTE: only m-0 remove spacing
        tooltip: classNames(tooltipClassName ?? styles.container, 'm-0'),
        arrow: arrowClassName ?? styles.arrow,
      }}
      PopperProps={popperProps}
    >
      {followCursor ? (
        <div onMouseMove={handleMouseMove} ref={areaRef}>
          {children}
        </div>
      ) : (
        children
      )}
    </SourceTooltip>
  )
}
