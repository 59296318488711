import React from 'react'

interface IProps {
  color?: string
}

export const Mark: React.FCC<IProps> = ({ color }) => (
  <svg width="12" height="9" viewBox="0 0 12 9" fill="none">
    <path
      d="M10.8438 1L4.28125 7.56221L1 4.28125"
      stroke={color ? color : '#1BC47D'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
