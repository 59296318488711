import classNames from 'classnames'
import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { RoleItem } from './role-item'

export interface IProps {
  role: ApiUser['aninixRole']
  isExpanded: boolean
  handleClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  handleSelection: (r: ApiUser['aninixRole']) => void
}
export const ExpandedInviteRoles: React.FCC<IProps> = ({
  role,
  isExpanded,
  handleClose,
  handleSelection,
}: IProps) => (
  <div
    onMouseLeave={handleClose}
    className={classNames(
      'absolute z-[100] flex flex-col items-center overflow-clip rounded-md bg-white shadow-xl transition-all duration-300',
      {
        ['invisible opacity-0']: !isExpanded,
      }
    )}
  >
    {(['editor', 'viewer'] as const).map((r) => (
      <div
        key={r}
        className="w-full"
        onClick={(e) => {
          handleClose(e)
          setTimeout(() => {
            handleSelection(r)
          }, 200) //so it hides first, then setting a state, since view is depended on `currentRole` and triggers width glitches
        }}
      >
        <RoleItem role={r} selectedRole={role} />
      </div>
    ))}
  </div>
)

ExpandedInviteRoles.displayName = 'ExpandedInviteRoles'
