import * as R from 'ramda'

export function uint8ArrayToBase64(bytes: Uint8Array): string {
  let binary = ''
  const len = bytes.byteLength
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i])
  }
  return window.btoa(binary)
}

export function getBase64FromImage(
  img: HTMLImageElement,
  targetBiggerSide?: number
): string {
  const [width, height] = (() => {
    if (targetBiggerSide == null) {
      return [img.width, img.height]
    }

    const biggerSide = R.max(img.width, img.height)
    const targetSizeToResize = R.min(targetBiggerSide, biggerSide)
    const coefficient = targetSizeToResize / biggerSide

    return [img.width * coefficient, img.height * coefficient]
  })()

  // Create an empty canvas element
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  // Copy the image contents to the canvas
  const ctx = canvas.getContext('2d')!
  ctx.drawImage(img, 0, 0, width, height)

  // Get the data-URL formatted image
  // Firefox supports PNG and JPEG. You could check img.src to
  // guess the original format, but be aware the using "image/jpg"
  // will re-encode the image.
  const dataUrl = canvas.toDataURL('image/png')
  canvas.remove()
  return dataUrl
}

export function getBlobFromImage(
  img: HTMLImageElement,
  targetBiggerSide?: number
): Promise<Blob> {
  const [width, height] = (() => {
    if (targetBiggerSide == null) {
      return [img.width, img.height]
    }

    const biggerSide = R.max(img.width, img.height)
    const targetSizeToResize = R.min(targetBiggerSide, biggerSide)
    const coefficient = targetSizeToResize / biggerSide

    return [img.width * coefficient, img.height * coefficient]
  })()

  // Create an empty canvas element
  const canvas = document.createElement('canvas')
  canvas.width = width
  canvas.height = height

  // Copy the image contents to the canvas
  const ctx = canvas.getContext('2d')!
  ctx.drawImage(img, 0, 0, width, height)

  // Get the data-URL formatted image
  // Firefox supports PNG and JPEG. You could check img.src to
  // guess the original format, but be aware the using "image/jpg"
  // will re-encode the image.
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob == null) {
        reject()
        return
      }

      resolve(blob)
    }, 'image/png')
  })
}
