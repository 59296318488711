import { LocalLogger, LogLevel } from '@aninix/logger'
import JsZip from 'jszip'
import { padStart } from 'lodash'
import * as R from 'ramda'
import { yieldToMain } from '../../utils'
import { Encoder } from './types'

const logger = new LocalLogger({
  level: process.env.LOG_LEVEL as LogLevel,
  prefix: '[compressPngSequence]',
})

export const compressPngSequence: Encoder = ({
  iterable,
  options,
  onProgressUpdate,
  onError,
  onDone,
}) => {
  const { framesCount } = options
  let isCancelled = false

  const render = async () => {
    try {
      const zip = new JsZip()

      logger.log('export started')
      let i = 0
      for await (const canvas of iterable) {
        if (isCancelled) {
          return
        }
        if (i % 20 === 0) {
          await yieldToMain()
        }

        let prevTime = Date.now()
        const image = await canvas.convertToBlob()
        logger.log(`received image ${i}/${framesCount}`)
        zip.file(`${padStart(i.toString(), 6, '0')}.png`, image)
        const end = Date.now()
        onProgressUpdate(R.clamp(0, 1, i / framesCount), end - prevTime)
        logger.log(
          `combining frame ${i}/${framesCount} time in ms`,
          end - prevTime
        )
        prevTime = Date.now()
        i++
      }

      const archive = await zip.generateAsync({ type: 'blob' })
      logger.log('export finished')
      onDone(archive)
    } catch (err: any) {
      logger.error('export', err)
      onError('Sequence export failed. Please try again.')
    }
  }

  render()

  return () => {
    try {
      isCancelled = true
    } catch (err: any) {
      logger.warn('cancelation error', err)
    }
  }
}
