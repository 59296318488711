import { useGetLotties } from '@aninix/api/get-lotties'
import * as React from 'react'
import { useMyPageOutletContext } from '../../modules/my-page'
import { Icons } from '../icons'
import { LoadingSpinner } from '../loading-spinner'
import { LottieItem } from './lottie-item'

export interface IProps {}
export const LottieList: React.FCC<IProps> = () => {
  const { data, isLoading, isError } = useGetLotties()

  const pageOutletContext = useMyPageOutletContext()

  const sidePanelWidth = 250,
    maximumItemWidth = 300

  const lotties = data?.data

  const getCurrentBodyWidthFraction = () =>
    Math.max(
      Math.floor(
        (document.body.getBoundingClientRect().width - sidePanelWidth) /
          maximumItemWidth
      ),
      2
    )

  const [repeatCount, setRepeatCount] = React.useState(
    getCurrentBodyWidthFraction()
  )

  const handleResize = React.useCallback(() => {
    setRepeatCount(getCurrentBodyWidthFraction())
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  React.useEffect(() => {
    pageOutletContext.setControls('None')
    pageOutletContext.setTitle('My lotties')
  }, [])

  React.useEffect(() => {
    if (Array.isArray(lotties) && lotties.length > 0) {
      pageOutletContext.setControls('UploadLottie')
    }
  }, [lotties])

  if (isLoading) return <LoadingSpinner />

  if (isError) return <p>Couldn't load lotties, try again later</p>

  if (lotties == null || lotties.length === 0)
    return (
      <div>
        <a
          className="inline-flex items-center flex-row gap-2 self-start rounded-lg bg-secondary px-3 py-2 font-body text-sm font-medium text-white transition-all duration-300 hover:scale-[1.02] hover:shadow-lg cursor-pointer"
          href="/lottie-preview"
          target="_blank"
        >
          <Icons.UploadFile />
          Upload your first animation
        </a>
      </div>
    )

  return (
    <div
      style={
        {
          '--repeat': `repeat(${repeatCount},minmax(0,1fr))`,
        } as React.CSSProperties
      }
      className="grid grid-cols-[--repeat] gap-4 pb-4"
    >
      {lotties?.map((lottie) => {
        const { id, createdAt, expiredAt, userId } = lottie
        return (
          <LottieItem
            key={lottie.id}
            id={id}
            createdAt={createdAt}
            expiredAt={expiredAt}
            userId={userId}
            data={lotties}
          />
        )
      })}
    </div>
  )
}

LottieList.displayName = 'LottieList'
