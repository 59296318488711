import * as React from 'react'

import { Indicators } from './indicators'
import { Playback } from './playback'

export interface IProps {}

export const Controls: React.FCC<IProps> = () => {
  return (
    <div className="flex w-full flex-row justify-between border-b-[1px] border-gray-100 px-4">
      <Playback />
      <Indicators />
    </div>
  )
}

Controls.displayName = 'Controls'
