import {
  AnchorPointComponent,
  BlurRadiusComponent,
  BottomLeftCornerRadiusComponent,
  BottomRightCornerRadiusComponent,
  ChildrenExpandedComponent,
  ChildrenRelationsAspect,
  ColorStopsRelationsAspect,
  Component,
  CornerRadiusComponent,
  DashComponent,
  DashOffsetComponent,
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  EndAngleComponent,
  Entity,
  EntryComponent,
  FillsRelationsAspect,
  GapComponent,
  GradientTransformComponent,
  IndividualCornerRadiusAspect,
  InnerRadiusComponent,
  NameComponent,
  NodeType,
  NodeTypeComponent,
  OpacityComponent,
  PaintType,
  PaintTypeComponent,
  PointCountComponent,
  PositionComponent,
  ProgressComponent,
  PropertiesExpandedComponent,
  RgbaValueComponent,
  RotationComponent,
  ScaleComponent,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
  ShadowSpreadComponent,
  SizeComponent,
  SkewComponent,
  StartAngleComponent,
  StrokeBottomWeightComponent,
  StrokeLeftWeightComponent,
  StrokeRightWeightComponent,
  StrokeTopWeightComponent,
  StrokeWeightComponent,
  StrokesRelationsAspect,
  TopLeftCornerRadiusComponent,
  TopRightCornerRadiusComponent,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
  getSortedKeyframes,
  isIndividualStrokesAvailable,
  isIndividualStrokesEnabled,
} from '@aninix-inc/model'
import { icons } from '@aninix/app-design-system'
import * as R from 'ramda'

function isAnimated(component: Component): boolean {
  return getSortedKeyframes(component).length > 0
}

export type TRowLayer = {
  type: 'layer'
  entity: Entity
  indent: number
  meta: {
    name: string
  }
}
export type TRowPropertyGroup = {
  type: 'property-group'
  entity: Entity
  indent: number
  meta: {
    name: string
  }
}
export type TRowProperty = {
  type: 'property'
  entity: Component
  indent: number
  /**
   * @description in case current row is child of property group.
   * @todo refactor
   */
  additionalIndent?: number
  meta: {
    name: string
    iconType?: icons.PropertyType
  }
}

/**
 * @description convert node to timeline rows
 */
export type TRow = TRowLayer | TRowPropertyGroup | TRowProperty

const getProperties = (
  layer: Entity,
  options: { indent: number }
): TRowProperty[] => {
  const layerType = layer.getComponentOrThrow(NodeTypeComponent).value

  if (layer.hasComponent(EntryComponent)) {
    return [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(OpacityComponent),
        indent: options.indent,
        meta: {
          name: 'Opacity',
          iconType: icons.PropertyType.Opacity,
        },
      },
      // { entity: nodeToHandle.rotation, indent: options.indent },
      // { entity: nodeToHandle.scale, indent: options.indent },
      // { entity: nodeToHandle.size, indent: options.indent },
      // @TODO: add individual corner radius here
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(CornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Corner Radius',
          iconType: icons.PropertyType.CornerRadius,
        },
      },
    ]
  }

  if ([NodeType.Ellipse].includes(layerType)) {
    const firstPart = [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(AnchorPointComponent),
        indent: options.indent,
        meta: {
          name: 'Anchor Point',
          iconType: icons.PropertyType.AnchorPoint,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(OpacityComponent),
        indent: options.indent,
        meta: {
          name: 'Opacity',
          iconType: icons.PropertyType.Opacity,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PositionComponent),
        indent: options.indent,
        meta: {
          name: 'Position',
          iconType: icons.PropertyType.Position,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(RotationComponent),
        indent: options.indent,
        meta: {
          name: 'Rotation',
          iconType: icons.PropertyType.Rotation,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(ScaleComponent),
        indent: options.indent,
        meta: {
          name: 'Scale',
          iconType: icons.PropertyType.Scale,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SizeComponent),
        indent: options.indent,
        meta: {
          name: 'Size',
          iconType: icons.PropertyType.Size,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SkewComponent),
        indent: options.indent,
        meta: {
          name: 'Skew',
          iconType: icons.PropertyType.Skew,
        },
      },
    ].filter((item) => item.entity != null)

    const secondPart = [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(CornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Corner Radius',
          iconType: icons.PropertyType.CornerRadius,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(StartAngleComponent),
        indent: options.indent,
        meta: {
          name: 'Start',
          iconType: icons.PropertyType.Sweep,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(EndAngleComponent),
        indent: options.indent,
        meta: {
          name: 'Sweep',
          iconType: icons.PropertyType.SweepFlipped,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(InnerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Inner Radius',
          iconType: icons.PropertyType.InnerRadius,
        },
      },
    ]

    return [...firstPart, ...secondPart]
  }

  if (
    [NodeType.Rectangle, NodeType.Polygon, NodeType.Star].includes(layerType)
  ) {
    const props = [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(AnchorPointComponent),
        indent: options.indent,
        meta: {
          name: 'Anchor Point',
          iconType: icons.PropertyType.AnchorPoint,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(OpacityComponent),
        indent: options.indent,
        meta: {
          name: 'Opacity',
          iconType: icons.PropertyType.Opacity,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PositionComponent),
        indent: options.indent,
        meta: {
          name: 'Position',
          iconType: icons.PropertyType.Position,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(RotationComponent),
        indent: options.indent,
        meta: {
          name: 'Rotation',
          iconType: icons.PropertyType.Rotation,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(ScaleComponent),
        indent: options.indent,
        meta: {
          name: 'Scale',
          iconType: icons.PropertyType.Scale,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SizeComponent),
        indent: options.indent,
        meta: {
          name: 'Size',
          iconType: icons.PropertyType.Size,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SkewComponent),
        indent: options.indent,
        meta: {
          name: 'Skew',
          iconType: icons.PropertyType.Skew,
        },
      },
    ].filter((item) => item.entity != null)

    if (
      layer.hasAspect(IndividualCornerRadiusAspect) &&
      layer.getAspectOrThrow(IndividualCornerRadiusAspect)
        .isIndividualCornersEnabled === false
    ) {
      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(CornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Corner Radius',
          iconType: icons.PropertyType.CornerRadius,
        },
      })
    }

    if (
      layerType === NodeType.Rectangle &&
      layer.getAspectOrThrow(IndividualCornerRadiusAspect)
        .isIndividualCornersEnabled
    ) {
      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(TopLeftCornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Top Left',
          iconType: icons.PropertyType.TopLeftRadius,
        },
      })

      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(TopRightCornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Top Right',
          iconType: icons.PropertyType.TopRightRadius,
        },
      })

      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(BottomLeftCornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Bottom Left',
          iconType: icons.PropertyType.BottomLeftRadius,
        },
      })

      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(BottomRightCornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Bottom Right',
          iconType: icons.PropertyType.BottomRightRadius,
        },
      })
    }

    if (layerType === NodeType.Polygon) {
      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PointCountComponent),
        indent: options.indent,
        meta: {
          name: 'Points Count',
          iconType: icons.PropertyType.PolygonPointCount,
        },
      })
    }

    if (layerType === NodeType.Star) {
      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PointCountComponent),
        indent: options.indent,
        meta: {
          name: 'Points Count',
          iconType: icons.PropertyType.StarPointCount,
        },
      })

      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(InnerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Ratio',
          iconType: icons.PropertyType.InnerRadius,
        },
      })
    }

    return props
  }

  if ([NodeType.Line].includes(layerType)) {
    return [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(AnchorPointComponent),
        indent: options.indent,
        meta: {
          name: 'Anchor Point',
          iconType: icons.PropertyType.AnchorPoint,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(OpacityComponent),
        indent: options.indent,
        meta: {
          name: 'Opacity',
          iconType: icons.PropertyType.Opacity,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PositionComponent),
        indent: options.indent,
        meta: {
          name: 'Position',
          iconType: icons.PropertyType.Position,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(RotationComponent),
        indent: options.indent,
        meta: {
          name: 'Rotation',
          iconType: icons.PropertyType.Rotation,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(ScaleComponent),
        indent: options.indent,
        meta: {
          name: 'Scale',
          iconType: icons.PropertyType.Scale,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SizeComponent),
        indent: options.indent,
        meta: {
          name: 'Size',
          iconType: icons.PropertyType.Size,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SkewComponent),
        indent: options.indent,
        meta: {
          name: 'Skew',
          iconType: icons.PropertyType.Skew,
        },
      },
    ].filter((item) => item.entity != null)
  }

  if ([NodeType.Frame, NodeType.Instance].includes(layerType)) {
    const props = [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(AnchorPointComponent),
        indent: options.indent,
        meta: {
          name: 'Anchor Point',
          iconType: icons.PropertyType.AnchorPoint,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(OpacityComponent),
        indent: options.indent,
        meta: {
          name: 'Opacity',
          iconType: icons.PropertyType.Opacity,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PositionComponent),
        indent: options.indent,
        meta: {
          name: 'Position',
          iconType: icons.PropertyType.Position,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(RotationComponent),
        indent: options.indent,
        meta: {
          name: 'Rotation',
          iconType: icons.PropertyType.Rotation,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(ScaleComponent),
        indent: options.indent,
        meta: {
          name: 'Scale',
          iconType: icons.PropertyType.Scale,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SizeComponent),
        indent: options.indent,
        meta: {
          name: 'Size',
          iconType: icons.PropertyType.Size,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SkewComponent),
        indent: options.indent,
        meta: {
          name: 'Skew',
          iconType: icons.PropertyType.Skew,
        },
      },
    ].filter((item) => item.entity != null)

    if (
      layer.getAspectOrThrow(IndividualCornerRadiusAspect)
        .isIndividualCornersEnabled === false
    ) {
      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(CornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Corner Radius',
          iconType: icons.PropertyType.CornerRadius,
        },
      })
    } else {
      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(TopLeftCornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Top Left',
          iconType: icons.PropertyType.TopLeftRadius,
        },
      })

      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(TopRightCornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Top Right',
          iconType: icons.PropertyType.TopRightRadius,
        },
      })

      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(BottomLeftCornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Bottom Left',
          iconType: icons.PropertyType.BottomLeftRadius,
        },
      })

      props.push({
        type: 'property' as const,
        entity: layer.getComponentOrThrow(BottomRightCornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Bottom Right',
          iconType: icons.PropertyType.BottomRightRadius,
        },
      })
    }

    return props
  }

  if ([NodeType.Group].includes(layerType)) {
    return [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(AnchorPointComponent),
        indent: options.indent,
        meta: {
          name: 'Anchor Point',
          iconType: icons.PropertyType.AnchorPoint,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(OpacityComponent),
        indent: options.indent,
        meta: {
          name: 'Opacity',
          iconType: icons.PropertyType.Opacity,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PositionComponent),
        indent: options.indent,
        meta: {
          name: 'Position',
          iconType: icons.PropertyType.Position,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(RotationComponent),
        indent: options.indent,
        meta: {
          name: 'Rotation',
          iconType: icons.PropertyType.Rotation,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(ScaleComponent),
        indent: options.indent,
        meta: {
          name: 'Scale',
          iconType: icons.PropertyType.Scale,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SizeComponent),
        indent: options.indent,
        meta: {
          name: 'Size',
          iconType: icons.PropertyType.Size,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SkewComponent),
        indent: options.indent,
        meta: {
          name: 'Skew',
          iconType: icons.PropertyType.Skew,
        },
      },
    ].filter((item) => item.entity != null)
  }

  if ([NodeType.Text].includes(layerType)) {
    return [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(AnchorPointComponent),
        indent: options.indent,
        meta: {
          name: 'Anchor Point',
          iconType: icons.PropertyType.AnchorPoint,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(OpacityComponent),
        indent: options.indent,
        meta: {
          name: 'Opacity',
          iconType: icons.PropertyType.Opacity,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PositionComponent),
        indent: options.indent,
        meta: {
          name: 'Position',
          iconType: icons.PropertyType.Position,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(RotationComponent),
        indent: options.indent,
        meta: {
          name: 'Rotation',
          iconType: icons.PropertyType.Rotation,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(ScaleComponent),
        indent: options.indent,
        meta: {
          name: 'Scale',
          iconType: icons.PropertyType.Scale,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SizeComponent),
        indent: options.indent,
        meta: {
          name: 'Size',
          iconType: icons.PropertyType.Size,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SkewComponent),
        indent: options.indent,
        meta: {
          name: 'Skew',
          iconType: icons.PropertyType.Skew,
        },
      },
    ].filter((item) => item.entity != null)
  }

  if ([NodeType.Vector].includes(layerType)) {
    return [
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(AnchorPointComponent),
        indent: options.indent,
        meta: {
          name: 'Anchor Point',
          iconType: icons.PropertyType.AnchorPoint,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(OpacityComponent),
        indent: options.indent,
        meta: {
          name: 'Opacity',
          iconType: icons.PropertyType.Opacity,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(PositionComponent),
        indent: options.indent,
        meta: {
          name: 'Position',
          iconType: icons.PropertyType.Position,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(RotationComponent),
        indent: options.indent,
        meta: {
          name: 'Rotation',
          iconType: icons.PropertyType.Rotation,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(ScaleComponent),
        indent: options.indent,
        meta: {
          name: 'Scale',
          iconType: icons.PropertyType.Scale,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SizeComponent),
        indent: options.indent,
        meta: {
          name: 'Size',
          iconType: icons.PropertyType.Size,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(SkewComponent),
        indent: options.indent,
        meta: {
          name: 'Skew',
          iconType: icons.PropertyType.Skew,
        },
      },
      {
        type: 'property' as const,
        entity: layer.getComponentOrThrow(CornerRadiusComponent),
        indent: options.indent,
        meta: {
          name: 'Corner Radius',
          iconType: icons.PropertyType.CornerRadius,
        },
      },
    ].filter((item) => item.entity != null)
  }

  return []
}

const getFills = (
  layer: Entity,
  options: { indent: number }
): (TRowPropertyGroup | TRowProperty)[] => {
  if (layer.hasAspect(FillsRelationsAspect) === false) {
    return []
  }

  // @TODO: extract to single place. Duplicated in strokes
  return layer
    .getAspectOrThrow(FillsRelationsAspect)
    .getChildrenList()
    .flatMap((fill, idx, array) => {
      const fillType = fill.getComponentOrThrow(PaintTypeComponent).value
      const isExpanded = fill.getComponentOrThrow(
        PropertiesExpandedComponent
      ).value

      switch (fillType) {
        case PaintType.Solid: {
          const group = {
            type: 'property-group' as const,
            entity: fill,
            indent: options.indent,
            meta: {
              name: array.length > 1 ? `Fill ${idx + 1} Colors` : 'Fill Colors',
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property' as const,
              entity: fill.getComponentOrThrow(RgbaValueComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: `Fill Color`,
              },
            } satisfies TRowProperty,
          ].filter((row) => isAnimated(row.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case PaintType.GradientLinear: {
          const group = {
            type: 'property-group' as const,
            entity: fill,
            indent: options.indent,
            meta: {
              name: `Fill - Linear Gradient ${idx + 1}`,
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property' as const,
              entity: fill.getComponentOrThrow(GradientTransformComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Transform',
              },
            } satisfies TRowProperty,
            ...fill
              .getAspectOrThrow(ColorStopsRelationsAspect)
              .getChildrenList()
              .flatMap((colorStop, idx: number) => [
                {
                  type: 'property' as const,
                  entity: colorStop.getComponentOrThrow(ProgressComponent),
                  indent: options.indent,
                  additionalIndent: 1,
                  meta: {
                    name: `Position ${idx + 1}`,
                  },
                } satisfies TRowProperty,
                {
                  type: 'property' as const,
                  entity: colorStop.getComponentOrThrow(RgbaValueComponent),
                  indent: options.indent,
                  additionalIndent: 1,
                  meta: {
                    name: `Color ${idx + 1}`,
                  },
                } satisfies TRowProperty,
              ]),
            {
              type: 'property' as const,
              entity: fill.getComponentOrThrow(OpacityComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Opacity',
              },
            } satisfies TRowProperty,
          ].filter((row) => isAnimated(row.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case PaintType.GradientRadial: {
          const group = {
            type: 'property-group' as const,
            entity: fill,
            indent: options.indent,
            meta: {
              name: `Fill - Radial Gradient ${idx + 1}`,
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property' as const,
              entity: fill.getComponentOrThrow(GradientTransformComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Transform',
              },
            } satisfies TRowProperty,
            ...fill
              .getAspectOrThrow(ColorStopsRelationsAspect)
              .getChildrenList()
              .flatMap((colorStop, idx: number) => [
                {
                  type: 'property' as const,
                  entity: colorStop.getComponentOrThrow(ProgressComponent),
                  indent: options.indent,
                  additionalIndent: 1,
                  meta: {
                    name: `Position ${idx + 1}`,
                  },
                } satisfies TRowProperty,
                {
                  type: 'property' as const,
                  entity: colorStop.getComponentOrThrow(RgbaValueComponent),
                  indent: options.indent,
                  additionalIndent: 1,
                  meta: {
                    name: `Color ${idx + 1}`,
                  },
                } satisfies TRowProperty,
              ]),
            {
              type: 'property' as const,
              entity: fill.getComponentOrThrow(OpacityComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Opacity',
              },
            } satisfies TRowProperty,
          ].filter((row) => isAnimated(row.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case PaintType.Image:
          return []
      }
    })
}

const getTrimPath = (
  layer: Entity,
  options: { indent: number }
): TRowProperty[] => {
  let properties: TRowProperty[] = []

  const trimStart = layer.getComponent(TrimStartComponent)
  if (trimStart != null && isAnimated(trimStart)) {
    properties.push({
      type: 'property' as const,
      entity: trimStart,
      indent: options.indent,
      meta: {
        name: 'Trim Path Start',
      },
    })
  }

  const trimEnd = layer.getComponent(TrimEndComponent)
  if (trimEnd != null && isAnimated(trimEnd)) {
    properties.push({
      type: 'property' as const,
      entity: trimEnd,
      indent: options.indent,
      meta: {
        name: 'Trim Path End',
      },
    })
  }

  const trimOffset = layer.getComponent(TrimOffsetComponent)
  if (trimOffset != null && isAnimated(trimOffset)) {
    properties.push({
      type: 'property' as const,
      entity: trimOffset,
      indent: options.indent,
      meta: {
        name: 'Trim Path Offset',
      },
    })
  }

  return properties
}

const getDashes = (
  layer: Entity,
  options: { indent: number }
): TRowProperty[] => {
  let properties: TRowProperty[] = []

  const dash = layer.getComponent(DashComponent)
  if (dash != null && isAnimated(dash)) {
    properties.push({
      type: 'property' as const,
      entity: dash,
      indent: options.indent,
      meta: {
        name: 'Dash',
      },
    })
  }

  const gap = layer.getComponent(GapComponent)
  if (gap != null && isAnimated(gap)) {
    properties.push({
      type: 'property' as const,
      entity: gap,
      indent: options.indent,
      meta: {
        name: 'Gap',
      },
    })
  }

  const dashOffset = layer.getComponent(DashOffsetComponent)
  if (dashOffset != null && isAnimated(dashOffset)) {
    properties.push({
      type: 'property' as const,
      entity: dashOffset,
      indent: options.indent,
      meta: {
        name: 'Dash Offset',
      },
    })
  }

  return properties
}

const getStrokes = (
  layer: Entity,
  options: { indent: number }
): (TRowPropertyGroup | TRowProperty)[] => {
  if (layer.hasAspect(StrokesRelationsAspect) === false) {
    return []
  }

  // @TODO: extract to single place. Duplicated in fills
  return layer
    .getAspectOrThrow(StrokesRelationsAspect)
    .getChildrenList()
    .flatMap((stroke, idx, array) => {
      const paintType = stroke.getComponentOrThrow(PaintTypeComponent).value
      const isExpanded = stroke.getComponentOrThrow(
        PropertiesExpandedComponent
      ).value

      switch (paintType) {
        case PaintType.Solid: {
          const group = {
            type: 'property-group' as const,
            entity: stroke,
            indent: options.indent,
            meta: {
              name:
                array.length > 1 ? `Stroke ${idx + 1} Colors` : 'Stroke Colors',
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property' as const,
              entity: stroke.getComponentOrThrow(RgbaValueComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: `Stroke Color`,
              },
            } satisfies TRowProperty,
          ].filter((property) => isAnimated(property.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case PaintType.GradientLinear: {
          const group = {
            type: 'property-group' as const,
            entity: stroke,
            indent: options.indent,
            meta: {
              name: `Stroke - Linear Gradient ${idx + 1}`,
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property' as const,
              entity: stroke.getComponentOrThrow(GradientTransformComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Transform',
              },
            } satisfies TRowProperty,
            ...stroke
              .getAspectOrThrow(ColorStopsRelationsAspect)
              .getChildrenList()
              .flatMap((colorStop, idx: number) => [
                {
                  type: 'property' as const,
                  entity: colorStop.getComponentOrThrow(ProgressComponent),
                  indent: options.indent,
                  additionalIndent: 1,
                  meta: {
                    name: `Position ${idx + 1}`,
                  },
                } satisfies TRowProperty,
                {
                  type: 'property' as const,
                  entity: colorStop.getComponentOrThrow(RgbaValueComponent),
                  indent: options.indent,
                  additionalIndent: 1,
                  meta: {
                    name: `Color ${idx + 1}`,
                  },
                } satisfies TRowProperty,
              ]),
            {
              type: 'property' as const,
              entity: stroke.getComponentOrThrow(OpacityComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Opacity',
              },
            } satisfies TRowProperty,
          ].filter((property) => isAnimated(property.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case PaintType.GradientRadial: {
          const group = {
            type: 'property-group' as const,
            entity: stroke,
            indent: options.indent,
            meta: {
              name: `Stroke - Radial Gradient ${idx + 1}`,
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property' as const,
              entity: stroke.getComponentOrThrow(GradientTransformComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Transform',
              },
            } satisfies TRowProperty,
            ...stroke
              .getAspectOrThrow(ColorStopsRelationsAspect)
              .getChildrenList()
              .flatMap((colorStop, idx: number) => [
                {
                  type: 'property' as const,
                  entity: colorStop.getComponentOrThrow(ProgressComponent),
                  indent: options.indent,
                  additionalIndent: 1,
                  meta: {
                    name: `Position ${idx + 1}`,
                  },
                } satisfies TRowProperty,
                {
                  type: 'property' as const,
                  entity: colorStop.getComponentOrThrow(RgbaValueComponent),
                  indent: options.indent,
                  additionalIndent: 1,
                  meta: {
                    name: `Color ${idx + 1}`,
                  },
                } satisfies TRowProperty,
              ]),
            {
              type: 'property' as const,
              entity: stroke.getComponentOrThrow(OpacityComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Opacity',
              },
            } satisfies TRowProperty,
          ].filter((property) => isAnimated(property.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case PaintType.Image:
          return []
      }
    })
}

const getStrokeWeight = (
  layer: Entity,
  options: { indent: number }
): (TRowPropertyGroup | TRowProperty)[] => {
  const properties: TRowProperty[] = []

  if (
    isIndividualStrokesAvailable(layer) &&
    isIndividualStrokesEnabled(layer)
  ) {
    properties.push({
      type: 'property' as const,
      entity: layer.getComponentOrThrow(StrokeTopWeightComponent),
      indent: options.indent,
      additionalIndent: 1,
      meta: {
        name: 'Stroke Top Weight',
      },
    } satisfies TRowProperty)

    properties.push({
      type: 'property' as const,
      entity: layer.getComponentOrThrow(StrokeRightWeightComponent),
      indent: options.indent,
      additionalIndent: 1,
      meta: {
        name: 'Stroke Right Weight',
      },
    } satisfies TRowProperty)

    properties.push({
      type: 'property' as const,
      entity: layer.getComponentOrThrow(StrokeBottomWeightComponent),
      indent: options.indent,
      additionalIndent: 1,
      meta: {
        name: 'Stroke Bottom Weight',
      },
    } satisfies TRowProperty)

    properties.push({
      type: 'property' as const,
      entity: layer.getComponentOrThrow(StrokeLeftWeightComponent),
      indent: options.indent,
      additionalIndent: 1,
      meta: {
        name: 'Stroke Left Weight',
      },
    } satisfies TRowProperty)
  } else if (layer.hasComponent(StrokeWeightComponent)) {
    properties.push({
      type: 'property' as const,
      entity: layer.getComponentOrThrow(StrokeWeightComponent),
      indent: options.indent,
      additionalIndent: 1,
      meta: {
        name: 'Stroke Weight',
      },
    } satisfies TRowProperty)
  }

  return properties.filter((property) => isAnimated(property.entity))
}

const getEffects = (
  layer: Entity,
  options: { indent: number }
): (TRowPropertyGroup | TRowProperty)[] => {
  if (layer.hasAspect(EffectsRelationsAspect) === false) {
    return []
  }

  return layer
    .getAspectOrThrow(EffectsRelationsAspect)
    .getChildrenList()
    .flatMap((effect, index) => {
      const type = effect.getComponentOrThrow(EffectTypeComponent).value
      const isExpanded = effect.getComponentOrThrow(
        PropertiesExpandedComponent
      ).value

      switch (type) {
        case EffectType.DropShadow: {
          const group = {
            type: 'property-group' as const,
            entity: effect,
            indent: options.indent,
            meta: {
              name: `Drop Shadow ${index + 1}`,
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property' as const,
              entity: effect.getComponentOrThrow(ShadowOffsetComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Offset',
              },
            } satisfies TRowProperty,
            {
              type: 'property',
              entity: effect.getComponentOrThrow(ShadowRadiusComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Blur',
              },
            } satisfies TRowProperty,
            {
              type: 'property',
              entity: effect.getComponentOrThrow(ShadowSpreadComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Spread',
              },
            } satisfies TRowProperty,
            {
              type: 'property',
              entity: effect.getComponentOrThrow(ShadowColorComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Color',
              },
            } satisfies TRowProperty,
          ].filter((property) => isAnimated(property.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case EffectType.InnerShadow: {
          const group = {
            type: 'property-group' as const,
            entity: effect,
            indent: options.indent,
            meta: {
              name: `Inner Shadow ${index + 1}`,
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property' as const,
              entity: effect.getComponentOrThrow(ShadowOffsetComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Offset',
              },
            } satisfies TRowProperty,
            {
              type: 'property',
              entity: effect.getComponentOrThrow(ShadowRadiusComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Blur',
              },
            } satisfies TRowProperty,
            {
              type: 'property',
              entity: effect.getComponentOrThrow(ShadowSpreadComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Spread',
              },
            } satisfies TRowProperty,
            {
              type: 'property',
              entity: effect.getComponentOrThrow(ShadowColorComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Color',
              },
            } satisfies TRowProperty,
          ].filter((property) => isAnimated(property.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case EffectType.LayerBlur: {
          const group = {
            type: 'property-group' as const,
            entity: effect,
            indent: options.indent,
            meta: {
              name: `Layer Blur ${index + 1}`,
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property',
              entity: effect.getComponentOrThrow(BlurRadiusComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Blur',
              },
            } satisfies TRowProperty,
          ].filter((property) => isAnimated(property.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }

        case EffectType.BackgroundBlur: {
          const group = {
            type: 'property-group' as const,
            entity: effect,
            indent: options.indent,
            meta: {
              name: `Background Blur ${index + 1}`,
            },
          } satisfies TRowPropertyGroup
          const properties = [
            {
              type: 'property',
              entity: effect.getComponentOrThrow(BlurRadiusComponent),
              indent: options.indent,
              additionalIndent: 1,
              meta: {
                name: 'Blur',
              },
            } satisfies TRowProperty,
          ].filter((property) => isAnimated(property.entity))

          if (properties.length > 0) {
            return [group, ...(isExpanded ? properties : [])]
          }

          return []
        }
      }
    })
}

const getChildren = (
  layer: Entity,
  options: { indent: number }
): TRowLayer[] => {
  const layerType = layer.getComponentOrThrow(NodeTypeComponent).value

  if ([NodeType.Frame, NodeType.Instance, NodeType.Group].includes(layerType)) {
    return layer
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()
      .map((child) => ({
        type: 'layer' as const,
        entity: child,
        indent: options.indent,
        meta: {
          name: child.getComponentOrThrow(NameComponent).value,
        },
      }))
  }

  return []
}

export const getNodeRows = (
  entity: Entity,
  options: { indent: number }
): TRow[] => {
  const properties = getProperties(entity, options).filter((property) =>
    isAnimated(property.entity)
  )
  const fills = getFills(entity, options)
  const strokes = getStrokes(entity, options)
  const strokeWeight = getStrokeWeight(entity, options)
  const trimPath = getTrimPath(entity, options)
  const dashes = getDashes(entity, options)
  const effects = getEffects(entity, options)
  const children = R.reverse(getChildren(entity, options)).map((row) =>
    getNodeRows(row.entity, { indent: options.indent + 1 })
  )

  const nodeRow: TRowLayer = {
    type: 'layer',
    entity,
    indent: options.indent,
    meta: {
      name: entity.getComponentOrThrow(NameComponent).value,
    },
  }

  const isChildrenVisible = entity.hasComponent(ChildrenExpandedComponent)
    ? entity.getComponentOrThrow(ChildrenExpandedComponent).value
    : false
  const isPropertiesVisible = entity.getComponentOrThrow(
    PropertiesExpandedComponent
  ).value

  let rows: TRow[] = [nodeRow]

  if (isPropertiesVisible) {
    // @TODO: add is animated filter
    rows.push(
      ...R.flatten([
        properties,
        fills,
        strokes,
        strokeWeight,
        dashes,
        trimPath,
        effects,
      ])
    )
  }

  if (isChildrenVisible) {
    rows.push(...R.flatten(children))
  }

  return rows
}
