/* eslint-disable max-len */
import React from 'react'

import { TNormalizedUserPreset } from '.'
import { Folder } from './components/Folder'
import { Preset } from './components/Preset'
import * as styles from './index.scss'

interface IUserGroupedPresets {
  selectedName?: string
  preset: TNormalizedUserPreset
  openGroups: string[]
  onOpen: (id: string) => void
  onEditPreset: (presetId: string) => void
  onApply: (presetId: string) => void
}

const UserGroupedPresets: React.FCC<IUserGroupedPresets> = ({
  selectedName,
  preset,
  openGroups,
  onOpen,
  onEditPreset,
  onApply,
}) => {
  const isOpen = openGroups.includes(preset.name)

  return (
    <React.Fragment key={preset.name}>
      {!preset.children && preset.value && (
        <Preset
          preset={preset.value}
          presetNameToRender={preset.name}
          onEditPreset={onEditPreset}
          onApply={onApply}
          isHighlighted={
            selectedName?.toLowerCase() === preset.name.toLowerCase()
          }
        />
      )}

      {preset.children && (
        <Folder folderName={preset.name} onOpen={onOpen} isOpen={isOpen} />
      )}

      {preset.children &&
        preset.children.length > 0 &&
        openGroups.includes(preset.name) && (
          <div className={styles['margin-left']}>
            {preset.children.map((child: any) => (
              <UserGroupedPresets
                key={child.value?.id || child.name}
                selectedName={selectedName}
                preset={child}
                openGroups={openGroups}
                onOpen={onOpen}
                onEditPreset={onEditPreset}
                onApply={onApply}
              />
            ))}
          </div>
        )}
    </React.Fragment>
  )
}

export default UserGroupedPresets
