import { AnimationCurveAspect, Entity } from '@aninix-inc/model'
import { icons } from '@aninix/app-design-system'
import * as React from 'react'
import { InfoItem } from '../../../../../../../mappers/types'
import { Property } from '../segment/property'

const graphIconSize = {
  x: 28,
  y: 28,
}

export interface IProps {
  segment: [Entity, Entity]
  properties: InfoItem[]
  propertiesName: string
}
export const TimingCurveInfoItems: React.FCC<IProps> = ({
  segment,
  properties,
  propertiesName,
}: IProps) => {
  const leftAspect = segment[0].getAspectOrThrow(AnimationCurveAspect)
  // const rightAspect = segment[1].getAspectOrThrow(AnimationCurveAspect)

  return (
    <>
      <p className="font-body text-xs font-normal text-secondary">
        {propertiesName}
      </p>

      <div className="flex flex-row items-center gap-4">
        <icons.Graph
          previews={
            // @NOTE: set correct timing curve graph here
            leftAspect.getCurveComponent().getCache()
            // leftAspect.getCurveType() === CurveType.Timing
            //   ? timingCurve.create({
            //     out: leftAspect.getCurveComponent().
            //   })
            //   : leftAspect.getCurveComponent().getCache()
          }
          size={graphIconSize}
        />

        {properties.map(({ title, value }: any, index: number) => (
          <Property key={index.toString()} title={title} value={value} />
        ))}
      </div>
    </>
  )
}

TimingCurveInfoItems.displayName = 'TimingCurveInfoItems'
