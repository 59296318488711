import * as React from 'react'

import { toast } from '../../modules/toasts'
import { useCurrentTeamStore } from '../../use-cases'
import { Icons, IIcon } from '../icons'
import { InviteTeamMembers } from '../invite-team-members'

export interface IProps {
  Icon?: React.FCC<IIcon>
  callToAction: string
}
export const InviteMembersButton: React.FCC<IProps> = ({
  Icon = Icons.Plus,
  callToAction = 'Invite members',
}: IProps) => {
  const [showModal, setShowModal] = React.useState<boolean>(false)

  const { currentTeam } = useCurrentTeamStore()

  const isAllowedToManage = ['owner', 'manager'].includes(
    currentTeam!.adminRole
  )

  const openModal = React.useCallback(() => {
    if (isAllowedToManage) setShowModal(true)
    else
      toast(
        'Ask your team owner or team manager to invite others users to the team',
        { variant: 'info' }
      )
  }, [isAllowedToManage])
  const closeModal = React.useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <div>
      <div
        onClick={openModal}
        className="flex flex-row items-center gap-2 rounded-lg bg-secondary px-3 py-2 transition-all duration-500 hover:scale-[1.02] hover:shadow-xl"
      >
        <Icon />
        <p className="font-body text-sm font-medium text-white">
          {callToAction}
        </p>
      </div>
      <InviteTeamMembers isModalOpen={showModal} handleClose={closeModal} />
    </div>
  )
}

InviteMembersButton.displayName = 'InviteMembersButton'
