import { NameComponent, Root } from '@aninix-inc/model'
import { useProject } from '@aninix/core'
import * as React from 'react'
import { Link } from 'react-router-dom'

export const Breadcrumbs: React.FCC<{ dashboardUrl: string }> = ({
  dashboardUrl,
}) => {
  const project = useProject()
  return (
    <div className="bottom-0 top-0 flex items-center justify-center gap-[0.3em] text-sm text-white">
      <Link
        reloadDocument
        target="_blank"
        to={dashboardUrl}
        className="opacity-50 transition-opacity duration-150 hover:!opacity-100"
      >
        Dashboard
      </Link>
      <p className="opacity-30">/</p>
      <p className="opacity-100">
        {
          project
            .getEntityByTypeOrThrow(Root)
            .getComponentOrThrow(NameComponent).value
        }
      </p>
    </div>
  )
}

Breadcrumbs.displayName = 'Breadcrumbs'
