import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import * as React from 'react'

export const tabsKeys = ['inspect', 'code'] as const
export type TTabs = (typeof tabsKeys)[number]

export interface IProps {
  selectedTab: TTabs
  setSelectedTab: React.Dispatch<React.SetStateAction<TTabs>>
}

export const Tabs: React.FCC<IProps> = (props: IProps) => {
  const { selectedTab, setSelectedTab } = props

  const tabs = tabsKeys.map((key) => {
    return {
      key,
      label: capitalize(key),
      select: () => setSelectedTab(key),
      isSelected: selectedTab === key,
    }
  })

  return (
    <div className="flex flex-row gap-2 border-b-[1px] border-gray-100 px-4">
      {tabs.map(({ key, label, select, isSelected }) => (
        <div key={key} onClick={select}>
          <p
            className={classNames(
              //@TODO due to font-weight change — elements are 'jumping' on selection
              'py-[10px] px-2 font-body text-sm font-normal text-gray-400 hover:cursor-pointer',
              {
                ['!font-medium !text-secondary']: isSelected,
              }
            )}
          >
            {label}
          </p>
        </div>
      ))}
    </div>
  )
}

Tabs.displayName = 'Tabs'
