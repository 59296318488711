import * as React from 'react'

import packageJson from '../../../../../../package.json'
import { Icons } from '../../../components/icons'
import { IMenuItem, IProps as IMenuItemsProps, MenuItems } from '../menu-items'
import { SpaceBadge } from '../space-badge'
import { ITeamSideMenuInteractor } from './interactor'

export interface IProps {
  interactor: ITeamSideMenuInteractor
  upgradeToPro: React.ReactNode
}
export const TeamSideMenuRouter: React.FC<IProps> = ({
  interactor,
  upgradeToPro,
}) => {
  const topMenuItems: IMenuItemsProps['items'] = React.useMemo(() => {
    return [
      {
        Icon: Icons.Projects,
        SelectedIcon: Icons.ProjectsSelected,
        label: 'Teams projects',
        path: './projects',
        isExternal: false,
      },

      {
        Icon: Icons.People,
        SelectedIcon: Icons.PeopleSelected,
        label: 'People',
        path: './people',
        isExternal: false,
      },
      ...(interactor.canManage
        ? [
            {
              Icon: Icons.TeamSettings,
              SelectedIcon: Icons.TeamSettingsSelected,
              label: 'Team settings',
              path: './settings',
              isExternal: false,
            },
          ]
        : []),

      {
        Icon: Icons.Trash,
        SelectedIcon: Icons.SelectedTrash,
        label: 'Trash',
        path: './trash',
        isExternal: false,
      },
    ] as IMenuItem[]
  }, [interactor.canManage, interactor.canManage])

  const bottomMenuItems: IMenuItemsProps['items'] = React.useMemo(
    () => [
      ...(interactor.isAdmin && interactor.children.upgradeToPro === false
        ? [
            {
              Icon: Icons.PaymentSettings,
              SelectedIcon: Icons.PaymentSettings,
              label: 'Manage subscription',
              path: './manage-subscription',
              isExternal: true,
            },
          ]
        : []),
      {
        Icon: Icons.Download,
        label: 'Download Figma plugin',
        path: 'https://aninix.com/figma-plugin',
        isExternal: true,
      },
      {
        Icon: Icons.Info,
        label: 'Wiki',
        path: 'https://aninix.com/wiki',
        isExternal: true,
      },
      {
        Icon: Icons.Help,
        label: 'Need help?',
        path: 'mailto:info@aninix.com',
        isExternal: true,
      },
    ],
    [interactor.isAdmin, interactor.children.upgradeToPro]
  )

  return (
    <div className="flex h-full flex-col gap-6">
      <SpaceBadge />
      <MenuItems items={topMenuItems} />
      {interactor.children.upgradeToPro && upgradeToPro}
      <div className="flex-grow" />
      <MenuItems items={bottomMenuItems} />
      <p className="px-3 text-[9px] text-gray-400">
        Version: {packageJson.versions['web-app']}
      </p>
    </div>
  )
}
