import { TimingCurve } from '../types'

function round(number: number): number {
  return Math.round(number * 100) / 100
}

export const timingValueToString = (value: TimingCurve) =>
  `${round(value.out.x)}, ${round(value.out.y)}, ${round(value.in.x)}, ${round(
    value.in.y
  )}`
