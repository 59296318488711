import { useQuery } from '@tanstack/react-query'
import { config } from './config'
import { ApiGetResponse } from './helpers'
import { httpClient } from './http-client'
import { components } from './open-api/schema'

export type ProjectMeta = components['schemas']['ProjectMeta']

export function useGetProjectMeta(id: string) {
  return useQuery({
    queryKey: ['get-project-meta', id],
    async queryFn() {
      return httpClient.get<ApiGetResponse<'/projects/{projectId}/meta'>>(
        `${config.apiUrl}/projects/${id}/meta`
      )
    },
  })
}

export function getProjectMeta(id: string) {
  return httpClient.get<ApiGetResponse<'/projects/{projectId}/meta'>>(
    `${config.apiUrl}/projects/${id}/meta`
  )
}
