import {
  Entity,
  TimeComponent,
  UndoRedoSystem,
  UpdatesSystem,
  cleanupKeyframes,
  commitUndo,
  mixed,
  round,
} from '@aninix-inc/model'
import {
  InputWithIcon,
  PropertyRowV2,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { useComponents } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useFormatTime, useFormattedTime } from '../../formatted-time'
import { useThreshold } from '../../threshold'

function isOffsetExistsFunc(values: Entity[]) {
  const keyframesStart = values.map(
    (keyframe) => keyframe.getComponentOrThrow(TimeComponent).value
  )

  let differencesArray: number[] = []

  for (let i = 1; i < keyframesStart.length; i += 1) {
    const previousStart = keyframesStart[i - 1]
    const currentStart = keyframesStart[i]
    differencesArray.push(round(currentStart - previousStart, { fixed: 4 }))
  }

  const equality = differencesArray.reduce((acc, current) => {
    acc.add(current)
    return acc
  }, new Set<number>([]))

  return equality.size === 1
}

type OffsetType = 'left' | 'right' | 'align-left'

export interface IProps {
  keyframes: Entity[]
}
export const Offset: React.FCC<IProps> = observer(({ keyframes }) => {
  useComponents(keyframes.map((k) => k.getComponentOrThrow(TimeComponent)))
  const prevDirection = React.useRef<OffsetType>('left')
  const project = keyframes[0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)

  const isOffsetExists = isOffsetExistsFunc(keyframes)
  const time = isOffsetExists
    ? Math.abs(
        keyframes[1].getComponentOrThrow(TimeComponent).value -
          keyframes[0].getComponentOrThrow(TimeComponent).value
      )
    : mixed
  const [direction, setDirection] = React.useState<OffsetType>(
    keyframes[1].getComponentOrThrow(TimeComponent).value -
      keyframes[0].getComponentOrThrow(TimeComponent).value >=
      0
      ? 'left'
      : 'right'
  )
  const { toSeconds } = useFormatTime()
  const { value, suffix } = useFormattedTime(time)
  const threshold = useThreshold()

  const initialValue = React.useRef<number | typeof mixed>(0)

  // @TODO: setup proper undo system
  const startChange = React.useCallback(() => {
    initialValue.current = time
  }, [time])
  const endChange = React.useCallback(() => {
    setDirection(
      keyframes[1].getComponentOrThrow(TimeComponent).value -
        keyframes[0].getComponentOrThrow(TimeComponent).value >
        0
        ? 'left'
        : 'right'
    )
    cleanupKeyframes(project)
    commitUndo(project)
  }, [keyframes, undoRedo])

  const applyOffset = React.useCallback(
    (offset: number, providedDirection: OffsetType) => {
      // @NOTE: apply offset to keyframes
      const keyframesToApply =
        providedDirection === 'left' ? keyframes : R.reverse(keyframes)
      const startTimeToApply =
        keyframesToApply[0].getComponentOrThrow(TimeComponent).value
      keyframesToApply.forEach((keyframe, idx) => {
        keyframe.updateComponent(TimeComponent, startTimeToApply + offset * idx)
      })
    },
    [keyframes, undoRedo, updates]
  )

  const updateValue = React.useCallback(
    (duration: number): void => {
      applyOffset(toSeconds(duration), direction)
      endChange()
    },
    [applyOffset, endChange, direction]
  )

  const updateByDelta = React.useCallback(
    (delta: number) => {
      initialValue.current =
        initialValue.current === mixed ? 0 : initialValue.current + delta
      applyOffset(toSeconds(initialValue.current), direction)
    },
    [applyOffset, direction]
  )

  const updateValueAfterDirectionSet = React.useCallback(
    (providedDirection: OffsetType) => {
      if (value === mixed) {
        return
      }

      updates.batch(() => {
        // @NOTE: reset keyframes
        const startTimeToReset = Math.min(
          ...keyframes.map(
            (keyframe) => keyframe.getComponentOrThrow(TimeComponent).value
          )
        )

        keyframes.forEach((keyframe) => {
          keyframe.updateComponent(TimeComponent, startTimeToReset)
        })

        applyOffset(toSeconds(Math.abs(value)), providedDirection)
      })

      prevDirection.current = providedDirection
      setDirection(providedDirection)
    },
    [value, updates, applyOffset, keyframes]
  )

  const onClickLeft = React.useCallback(() => {
    updateValueAfterDirectionSet('left')
  }, [updateValueAfterDirectionSet])

  const onClickRight = React.useCallback(() => {
    updateValueAfterDirectionSet('right')
  }, [updateValueAfterDirectionSet])

  const id = React.useMemo(
    () => `${keyframes.map((k) => k.id).join('-')}-offset`,
    [keyframes]
  )

  const formatValue = React.useCallback(
    (providedValue: number): string => `${providedValue.toFixed(2)}${suffix}`,
    [suffix]
  )

  return (
    <PropertyRowV2
      name="Offset"
      inputs={
        <>
          <InputWithIcon
            id={id}
            value={value}
            icon={<icons.Time />}
            threshold={threshold}
            onStartChange={startChange}
            onChange={updateValue}
            onDeltaChange={updateByDelta}
            onEndChange={endChange}
            format={isOffsetExists ? formatValue : undefined}
          />

          <div className="flex flex-row flex-nowrap items-stretch justify-start">
            <buttons.Icon onClick={onClickLeft} active={direction === 'left'}>
              <icons.propertiesPanel.Offset type="left" />
            </buttons.Icon>

            <buttons.Icon onClick={onClickRight} active={direction === 'right'}>
              <icons.propertiesPanel.Offset type="right" />
            </buttons.Icon>
          </div>
        </>
      }
    />
  )
})
