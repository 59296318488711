import { IUiStore } from '../../../stores'

type Payload = {
  ui: IUiStore
}

export interface IToolbarInteractor {
  children: {
    activeProjectInfo: boolean
    menu: boolean
    tools: boolean
    export: boolean
    viewportZoom: boolean
  }
}

export const toolbarInteractor = ({ ui }: Payload): IToolbarInteractor => {
  const canEdit = ui.allowedAction === 'full'

  return {
    children: {
      activeProjectInfo: true,
      menu: true,
      tools: true,
      export: canEdit,
      viewportZoom: true,
    },
  }
}
