import classNames from 'classnames'
import * as React from 'react'
import { Icon } from './types'

const defaultSize = {
  x: 16,
  y: 16,
}
export const CopyLink: React.FC<Icon> = ({
  size = defaultSize,
  className,
  color = '#FFFFFF',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    className={classNames('flex-shrink-0', className)}
  >
    <g clipPath="url(#clip0_1759_6628)">
      <path
        d="M5.37541 10.6261L10.6258 5.37563M7.12552 2.75041L7.53068 2.28137C8.35131 1.46084 9.46428 0.999918 10.6248 1C11.7852 1.00008 12.8981 1.46116 13.7186 2.28181C14.5392 3.10246 15.0001 4.21545 15 5.37594C14.9999 6.53643 14.5388 7.64935 13.7182 8.46988L13.2509 8.87592M8.87564 13.2513L8.52825 13.7186C7.69803 14.5395 6.57756 15 5.40998 15C4.2424 15 3.12192 14.5395 2.29171 13.7186C1.88249 13.3139 1.55762 12.8321 1.33589 12.3011C1.11417 11.77 1 11.2002 1 10.6247C1 10.0493 1.11417 9.47948 1.33589 8.94842C1.55762 8.41736 1.88249 7.93556 2.29171 7.53093L2.75024 7.12577"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1759_6628">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
