import * as msgpack from '@msgpack/msgpack'
import { decompress } from 'brotli-compress'

export async function binaryToJson(
  compressedData: Uint8Array
): Promise<unknown> {
  // Decompress the data using brotli
  const decompressedData = await decompress(compressedData)

  // Decode the MessagePack data as a JSON object
  const json = msgpack.decode(decompressedData)

  return json
}
