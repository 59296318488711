import {
  Entity,
  Project,
  Root,
  getDuration,
  getEndTime,
  getStartTime,
} from '@aninix-inc/model'
import { makeAutoObservable } from 'mobx'
import * as R from 'ramda'

/**
 * @description preview range
 */
export class PreviewRange {
  private project: Project

  private get root(): Entity {
    return this.project.getEntityByTypeOrThrow(Root)
  }

  start: number = 0

  duration: number = 0

  constructor(payload: { project: Project }) {
    makeAutoObservable(this)
    this.project = payload.project

    this.reset()
  }

  updateStart = (start: number) => {
    this.start = R.clamp(getStartTime(this.root), this.end, start)
    return this
  }

  updateDuration = (duration: number) => {
    this.duration = R.clamp(0, getEndTime(this.root) - this.start, duration)
    return this
  }

  updateEnd = (end: number) => {
    const duration = end - this.start
    this.updateDuration(duration)
    return this
  }

  reset = () => {
    this.start = getStartTime(this.root)
    this.duration = getDuration(this.root)
    return this
  }

  updateProject = (project: Project) => {
    this.project = project
    this.reset()
    return this
  }

  get end() {
    return this.start + this.duration
  }
}
