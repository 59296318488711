import { useCurrentTeamStore } from 'apps/web-app/src/use-cases'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { toast as toastSource } from 'react-toastify'

export interface ITeamSideMenuInteractor {
  currentPath: string

  canManage: boolean
  isAdmin: boolean

  children: {
    upgradeToPro: boolean
  }
}

export function useTeamSideMenuInteractor(): ITeamSideMenuInteractor {
  const { currentTeam } = useCurrentTeamStore()

  const { teamId } = useParams()

  const canManage = ['owner', 'manager'].includes(currentTeam!.adminRole)

  const isAdmin = currentTeam!.adminRole === 'owner'

  const upgradeToProVisible = React.useMemo(() => {
    if (currentTeam?.adminRole !== 'owner' || currentTeam?.plan === 'pro') {
      return false
    }

    if (toastSource.isActive(`team-payment-success-${teamId}`)) {
      return false
    }

    return true
  }, [currentTeam])

  return {
    currentPath: '/my/projects',

    canManage,
    isAdmin,

    children: {
      upgradeToPro: isAdmin && upgradeToProVisible,
    },
  }
}
