import { FullPageLoader } from '@aninix/app-design-system'
import {
  CommentContextProvider,
  IImageProvider,
  IInspectorProps,
  ImagesStore,
  ImagesStoreProvider,
  Playback,
  PlaybackProvider,
  ProjectProvider,
  Session,
  SessionProvider,
  Settings,
  Timeline,
  TimelineProvider,
  ToolsProvider,
  UserRole,
  ViewportProvider,
  getBase64FromImage,
  uint8ArrayToBase64,
} from '@aninix/core'
import { Inspector as InspectorModule } from '@aninix/core/modules/inspector'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import {
  EntityType,
  EntityTypeComponent,
  HashComponent,
  NameComponent,
  Project,
  Root,
  createComponentsProvider,
  createEntitiesProvider,
  createSystemsProvider,
} from '@aninix-inc/model'
import { getAsset, getProjectMeta, syncProjectWithRemote } from '@aninix/api'
import { OverridesProvider } from '@aninix/core/modules/inspector/components/overrides-panel/use-overrides'
import { InspectProvider } from '@aninix/core/modules/inspector/toolbar/use-inspect-mode'
import { useLogger } from '@aninix/logger'
import {
  AccessRestricted,
  FreeLimitExceeded,
} from '../../components/status-pages'
import { config } from '../../config'
import { useCurrentUser } from '../../use-cases'
import { NotFoundView } from '../not-found-view'
import { PaymentRequiredView } from '../payment-required-view'

import * as styles from '../../theme/colors.scss'
import { FontLoadingProvider } from '@aninix/core/stores/font-loading'

// @NOTE: used to resize images before render.
// The bigger number the better quality but slower rendering speed.
// @TODO: this caused issue with CROP type of images.
// If image clamped by this size then we have doubled images count in grid.
const IMAGE_LIMIT_IN_PIXELS_BY_BIGGER_SIDE = 2048

export class ImageProvider implements IImageProvider {
  private async getImagesByHashes(hashes: string[]): Promise<Uint8Array[]> {
    const remoteImageEntries = await Promise.all(
      hashes.map(async (hash) => {
        return [hash, await getAsset({ hash })]
      })
    )
    const imagesMap = Object.fromEntries(remoteImageEntries) as Record<
      string,
      Uint8Array
    >
    return hashes.map((hash) => imagesMap[hash])
  }

  // @NOTE base 64 images but resized by bigger size (v3)
  getImages: IImageProvider['getImages'] = async (hashes) => {
    const data = await this.getImagesByHashes(hashes)

    if (data.length !== hashes.length) {
      return {}
    }

    let images: Record<string, HTMLImageElement> = {}

    const loadedImages = await Promise.all(
      hashes.map(
        (_, idx) =>
          new Promise<HTMLImageElement>((resolve, reject) => {
            const actualData = data || []
            const image = new Image()
            const imageData = actualData[idx]
            image.src = `data:image/jpg;base64, ${uint8ArrayToBase64(
              imageData
            )}`
            image.onload = () => {
              resolve(image)
            }
            image.onerror = (err) => {
              reject(err)
            }
          })
      )
    )

    loadedImages.forEach((sourceImage, idx) => {
      const imageData = getBase64FromImage(
        sourceImage,
        IMAGE_LIMIT_IN_PIXELS_BY_BIGGER_SIDE
      )
      const image = new Image()
      image.src = imageData
      const hash = hashes[idx]
      images[hash] = image
    })

    return images
  }
}

const imagesStore = new ImagesStore(new ImageProvider())

const InternalInspector: React.FC<{
  project: Project
  inspectMode: IInspectorProps['inspectMode']
  onInspectModeChange: IInspectorProps['onInspectModeChange']
}> = ({ project, inspectMode, onInspectModeChange }) => {
  const session = React.useMemo(() => new Session(), [])
  const playback = React.useMemo(
    () => new Playback({ project, user: new Settings() }),
    [project]
  )
  const timeline = React.useMemo(() => new Timeline({ project }), [project])

  return (
    <ProjectProvider project={project!}>
      <SessionProvider store={session}>
        <ImagesStoreProvider store={imagesStore!}>
          <ToolsProvider>
            <PlaybackProvider store={playback!}>
              <TimelineProvider store={timeline!}>
                <ViewportProvider>
                  <CommentContextProvider>
                    <InspectProvider>
                      <FontLoadingProvider project={project}>
                        <OverridesProvider>
                          <div
                            className={`h-screen w-screen ${styles['figma-light']}`}
                          >
                            <InspectorModule
                              inspectMode={inspectMode}
                              onInspectModeChange={onInspectModeChange}
                              webAppUrl={config.webAppUrl}
                            />
                          </div>
                        </OverridesProvider>
                      </FontLoadingProvider>
                    </InspectProvider>
                  </CommentContextProvider>
                </ViewportProvider>
              </TimelineProvider>
            </PlaybackProvider>
          </ToolsProvider>
        </ImagesStoreProvider>
      </SessionProvider>
    </ProjectProvider>
  )
}

export interface IProps {}
export const Inspector: React.FCC<IProps> = observer(() => {
  const { projectId } = useParams()

  const logger = useLogger()
  const [state, setState] = React.useState<
    | 'loading'
    | 'success'
    | 'error'
    | 'login-required'
    | 'payment-required'
    | 'access-not-allowed'
    | 'not-found'
    | 'locked'
  >('loading')
  const [ownerInfo, setOwnerInfo] = React.useState<string | undefined>()
  const { inspectMode } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const { userId, role } = useCurrentUser()

  const project = React.useRef(
    new Project({
      id: projectId,
      componentsProvider: createComponentsProvider(),
      entitiesProvider: createEntitiesProvider(),
      systemsProvider: createSystemsProvider(),
    })
  )

  const loadProject = React.useCallback(async () => {
    try {
      if (projectId == null) {
        setState('error')
        return
      }

      await syncProjectWithRemote(project.current)

      // @NOTE: trigger store to load all images
      const imagePaints = project.current
        .getEntitiesByPredicate(
          (e) =>
            e.getComponentOrThrow(EntityTypeComponent).value ===
              EntityType.Paint && e.hasComponent(HashComponent)
        )
        .map((paint) => paint.getComponentOrThrow(HashComponent).value)
      await imagesStore.clean().loadImagesByHashes(imagePaints)

      // @NOTE: set title of the tab
      document.title = project.current
        .getEntityByTypeOrThrow(Root)
        .getComponentOrThrow(NameComponent).value

      setState('success')
    } catch (err: any) {
      logger.error('Project loading error', err)
      logger.error('Project loading error', err)
      switch (err.statusCode) {
        case 404: {
          setState('not-found')
          return
        }

        case 401: {
          setState('login-required')
          return
        }

        case 402: {
          const projectMeta = await getProjectMeta(projectId!)
          const isOwner = projectMeta.data.userId === userId
          const isAnonymous = role === UserRole.Anonymous

          if (isAnonymous) {
            navigate(`/login?redirectUrlOnSuccess=${location.pathname}`)
            return
          }

          if (isOwner) {
            setState('payment-required')
            return
          }

          setState('access-not-allowed')
          return
        }

        case 417: {
          setState('locked')
          setOwnerInfo(err.ownerInfo)
          return
        }

        default:
          setState('error')
      }
    }
  }, [userId])

  React.useEffect(() => {
    loadProject()
  }, [loadProject])

  const handleInspectModeChange = (
    inspectMode: IInspectorProps['inspectMode']
  ) => {
    navigate(`./../${inspectMode}`)
  }

  if (state === 'loading') {
    return <FullPageLoader />
  }

  if (state === 'not-found') {
    return <NotFoundView />
  }

  if (state === 'payment-required') {
    return <PaymentRequiredView />
  }

  if (state === 'locked') {
    return <FreeLimitExceeded ownerInfo={ownerInfo} />
  }

  if (state === 'access-not-allowed') {
    return <FreeLimitExceeded />
  }

  if (state === 'login-required') {
    const urlToRedirect = encodeURIComponent(`/inspect/${projectId}`)
    return (
      <AccessRestricted redirectUrlOnSuccess={urlToRedirect} autoRedirect />
    )
  }

  if (state === 'error') {
    return <NotFoundView />
  }

  if (project == null) {
    return <NotFoundView />
  }

  return (
    <InternalInspector
      project={project.current}
      inspectMode={(inspectMode ?? 'details') as IInspectorProps['inspectMode']}
      onInspectModeChange={handleInspectModeChange}
    />
  )
})

Inspector.displayName = 'Inspector'
