import {
  ColorStopsRelationsAspect,
  HashComponent,
  PaintType,
  PaintTypeComponent,
  ProgressComponent,
  RgbaValueComponent,
  ScaleTypeComponent,
} from '@aninix-inc/model'

import {
  ConstructorWithTag,
  Entity,
  FillsRelationsAspect,
  StrokesRelationsAspect,
} from '@aninix-inc/model'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { Paint } from '../paint'
import * as styles from './index.scss'

export const isPaintsMixed = (
  layers: Entity[],
  AspectConstructor: ConstructorWithTag<
    FillsRelationsAspect | StrokesRelationsAspect
  >
): boolean => {
  let firstLayer = layers[0]
  const firstLayerPaints = firstLayer
    .getAspectOrThrow(AspectConstructor)
    .getChildrenList()

  for (const layer of layers) {
    const paints = layer.getAspectOrThrow(AspectConstructor).getChildrenList()

    if (firstLayerPaints.length !== paints.length) {
      return true
    }

    for (let i = 0; i < paints.length; i += 1) {
      const firstLayerPaint = firstLayerPaints[i]
      const paint = paints[i]

      const firstLayerPaintType =
        firstLayerPaint.getComponentOrThrow(PaintTypeComponent).value
      const paintType = paint.getComponentOrThrow(PaintTypeComponent).value

      if (paintType !== firstLayerPaintType) {
        return true
      }

      switch (paintType) {
        case PaintType.Solid: {
          if (
            !R.equals(
              firstLayerPaint.getComponentOrThrow(RgbaValueComponent).value,
              paint.getComponentOrThrow(RgbaValueComponent).value
            )
          ) {
            return true
          }

          break
        }

        case PaintType.GradientLinear:
        case PaintType.GradientRadial: {
          const firstLayerColorStops = paint
            .getAspectOrThrow(ColorStopsRelationsAspect)
            .getChildrenList()
          const colorStops = paint
            .getAspectOrThrow(ColorStopsRelationsAspect)
            .getChildrenList()

          if (colorStops.length !== firstLayerColorStops.length) {
            return true
          }

          for (let ii = 0; ii < colorStops.length; ii += 1) {
            const firstLayerColorStop = firstLayerColorStops[ii]
            const colorStop = colorStops[ii]

            if (
              !R.equals(
                firstLayerColorStop.getComponentOrThrow(RgbaValueComponent)
                  .value,
                colorStop.getComponentOrThrow(RgbaValueComponent).value
              )
            ) {
              return true
            }

            if (
              !R.equals(
                firstLayerColorStop.getComponentOrThrow(ProgressComponent)
                  .value,
                colorStop.getComponentOrThrow(ProgressComponent).value
              )
            ) {
              return true
            }
          }

          break
        }

        case PaintType.Image: {
          if (
            !R.equals(
              paint.getComponentOrThrow(HashComponent).value,
              firstLayerPaint.getComponentOrThrow(HashComponent).value
            )
          ) {
            return true
          }

          if (
            !R.equals(
              paint.getComponentOrThrow(ScaleTypeComponent).value,
              firstLayerPaint.getComponentOrThrow(ScaleTypeComponent).value
            )
          ) {
            return true
          }

          break
        }

        default: {
          const never: never = paintType
          throw new Error(`Should be handled "${never}"`)
        }
      }
    }
  }

  return false
}

export const Paints: React.FC<{
  aspect: ConstructorWithTag<FillsRelationsAspect | StrokesRelationsAspect>
}> = ({ aspect }) => {
  const { entitiesWithPaints: nodes } = useNodePropertiesPanel()

  const filteredNodes = nodes.filter((n) => n.hasAspect(aspect))

  useEntities(filteredNodes)

  if (!filteredNodes.length) return null

  const paints = filteredNodes.flatMap((l) =>
    l.getAspectOrThrow(aspect).getChildrenList()
  )

  if (!paints.length) return null

  const isMixed = isPaintsMixed(filteredNodes, aspect)

  if (isMixed)
    return <p className={styles.mixed}>Click + to replace mixed content</p>

  return (
    <div className="flex w-full flex-col gap-[2px]">
      {paints.map((paint, idx) => {
        const selectedPaints = filteredNodes
          .map((l) => l.getAspectOrThrow(aspect).getChildrenList()[idx])
          .filter(
            (e) =>
              e != null &&
              e.getComponentOrThrow(PaintTypeComponent).value ===
                paint.getComponentOrThrow(PaintTypeComponent).value
          )

        if (!selectedPaints.length) return null

        return <Paint key={paint.id} paints={selectedPaints} />
      })}
    </div>
  )
}

Paints.displayName = 'Paints'
