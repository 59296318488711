import * as React from 'react'

import { PlaybackButton } from './button'
import { PlaybackTime } from './time'

export interface IProps {}
export const Playback: React.FCC<IProps> = () => (
  <div className="flex flex-row gap-0 items-center w-1/3">
    <PlaybackButton />
    <PlaybackTime />
  </div>
)

Playback.displayName = 'Playback'
