import {
  Entity,
  getAnchorPoint,
  getSize,
  getTransformMatrix,
  multipliedMatrices,
  ScaleMatrix,
  TranslateMatrix,
  VersionComponent,
} from '@aninix-inc/model'
import {
  convertEntityToSnapshot as convertNodeToSnapshot,
  paper,
  SvgLayer,
} from '@aninix-inc/renderer'
import { safeString } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ImagesStore } from '../../stores'

/**
 * @description calculate actual bounding box of the node related to parent.
 * It includes calculation of size, scale, anchor point and rotation
 */
function getBoundingBoxAtTimeV2(payload: {
  entity: Entity
  time: number
  ignorePosition?: boolean
}) {
  const { entity, time, ignorePosition } = payload
  const anchorPoint = getAnchorPoint(entity, time)
  const transformMatrix = new paper.Matrix(
    getTransformMatrix({
      entity,
      time,
      ignorePosition,
    })
  )
  const baseSize = getSize(entity)
  const size = getSize(entity, time)
  const scaleSize = {
    x: size.x / baseSize.x,
    y: size.y / baseSize.y,
  }
  const sizeMatrix =
    entity.getComponentOrThrow(VersionComponent).value < 3
      ? new paper.Matrix(
          multipliedMatrices(
            TranslateMatrix(-anchorPoint.x, -anchorPoint.y),
            ScaleMatrix(scaleSize.x, scaleSize.y),
            TranslateMatrix(anchorPoint.x, anchorPoint.y)
          )
        )
      : new paper.Matrix(
          multipliedMatrices(
            TranslateMatrix(-anchorPoint.x, -anchorPoint.y),
            ScaleMatrix(scaleSize.x, scaleSize.y)
          )
        )

  const rect = new paper.Path.Rectangle(
    new paper.Point(0, 0),
    new paper.Size(size.x, size.y)
  )
  rect.transform(sizeMatrix)
  rect.transform(transformMatrix)
  const bounds = rect.bounds

  return {
    x: bounds.topLeft.x,
    y: bounds.topLeft.y,
    width: bounds.width,
    height: bounds.height,
  }
}

export interface IProps {
  time: number
  entity: Entity
  images: ImagesStore
  shouldRender?: (entity: Entity) => boolean
  isRoot?: boolean
  className?: string
}
const StaticSvgInner: React.FCC<IProps> = observer(
  ({ time, entity, images, shouldRender, isRoot }) => {
    const snapshot = convertNodeToSnapshot({
      entity,
      time,
      isRoot,
      isStatic: true,
      ignoreTrimPath: true,
      imagesStore: images,
    })

    return (
      <SvgLayer
        rootSnapshot={snapshot}
        snapshot={snapshot}
        // @ts-ignore
        shouldRender={shouldRender}
        ignoreViewportVisibility
      />
    )
  }
)

StaticSvgInner.displayName = 'StaticSvgInner'

export const StaticSvg: React.FCC<IProps> = observer(
  ({ time, entity, shouldRender, images, className, children }) => {
    const boundingBox = getBoundingBoxAtTimeV2({
      entity,
      time,
      ignorePosition: true,
    })

    return (
      <svg
        id={safeString(entity.id)}
        viewBox={`${boundingBox.x} ${boundingBox.y} ${boundingBox.width} ${boundingBox.height}`}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        className={className}
      >
        <StaticSvgInner
          time={time}
          entity={entity}
          shouldRender={shouldRender}
          images={images}
          isRoot
        />

        {children}
      </svg>
    )
  }
)

StaticSvg.displayName = 'StaticSvg'
