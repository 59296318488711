import * as React from 'react'

export const ErrorSvg: React.FCC = () => (
  <svg width="176" height="80" viewBox="0 0 176 80" fill="none">
    <rect
      x="96"
      y="-3.05176e-05"
      width="80"
      height="80"
      rx="16"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M148.85 39.975L131.075 55.8V26.55L124.775 29.8507V61.575L128.75 64.8L158 38.55L128.3 15L122 18.3L148.85 39.975Z"
      fill="white"
    />
    <rect
      y="-3.05176e-05"
      width="80"
      height="80"
      rx="16"
      fill="white"
      fillOpacity="0.2"
    />
    <path
      d="M32.5 62.3333C36.64 62.3333 40 58.9733 40 54.8333V47.3333H32.5C28.36 47.3333 25 50.6933 25 54.8333C25 58.9733 28.36 62.3333 32.5 62.3333Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M25 39.8332C25 35.6933 28.36 32.3333 32.5 32.3333H40V47.3332H32.5C28.36 47.3332 25 43.9732 25 39.8332Z"
      fill="white"
    />
    <path
      d="M25 24.8335C25 20.6935 28.36 17.3335 32.5 17.3335H40V32.3335H32.5C28.36 32.3335 25 28.9735 25 24.8335Z"
      fill="white"
    />
    <path
      opacity="0.5"
      d="M40 17.3333H47.5C51.64 17.3333 55 20.6933 55 24.8333C55 28.9733 51.64 32.3333 47.5 32.3333H40V17.3333Z"
      fill="white"
    />
    <path
      d="M55 39.8332C55 43.9732 51.64 47.3332 47.5 47.3332C43.36 47.3332 40 43.9732 40 39.8332C40 35.6933 43.36 32.3333 47.5 32.3333C51.64 32.3333 55 35.6933 55 39.8332Z"
      fill="white"
    />
    <path
      d="M88 55C96.2806 55 103 48.2805 103 40C103 31.7194 96.2806 25 88 25C79.7194 25 73 31.7194 73 40C73 48.2805 79.7194 55 88 55Z"
      fill="white"
      stroke="#F24822"
      strokeWidth="4"
    />
    <path
      d="M83 39C84.1046 39 85 38.1046 85 37C85 35.8954 84.1046 35 83 35C81.8954 35 81 35.8954 81 37C81 38.1046 81.8954 39 83 39Z"
      fill="#F24822"
    />
    <path
      d="M93 39C94.1046 39 95 38.1046 95 37C95 35.8954 94.1046 35 93 35C91.8954 35 91 35.8954 91 37C91 38.1046 91.8954 39 93 39Z"
      fill="#F24822"
    />
    <path
      d="M93 45.9999C92.3963 45.3752 91.6341 44.8715 90.7691 44.5255C89.9041 44.1795 88.9581 44 88 44C87.0419 44 86.0958 44.1796 85.2308 44.5256C84.3658 44.8715 83.6037 45.3753 83 46"
      stroke="#F24822"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

interface IProps {}
export const Error: React.FCC<IProps> = ({}) => {
  return (
    <div className="flex w-[360px] flex-col items-center rounded-[10px] bg-red py-[40px] px-[20px] text-center text-white">
      <ErrorSvg />

      <h2 className="mt-[40px] font-header text-[28px] font-semibold leading-[36px]">
        Oh no!
      </h2>

      <p className="font-description mt-[16px] font-body text-[20px] font-normal leading-[30px]">
        We could not connect your Figma account to Aninix.
        <span className="my-[10px] block" />
        Please close this tab and try again.
      </p>
    </div>
  )
}
