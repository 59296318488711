import { LocalStorageIo } from '@aninix/api'

// @NOTE: using singleton. Maybe refactor via DI.
export const localStorageIo = new LocalStorageIo<{
  aa: string
  t: Record<string, string>
}>(
  'aninix',
  {
    aa: '',
    t: {},
  },
  {
    debug: true,
  }
)
