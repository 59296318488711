import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Size: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 3.5H3.5V4V7H4.5V4.5H7V3.5H4ZM11.5 9V11.5H9V12.5H12H12.5V12V9H11.5ZM5.20711 11.5L11.5 5.20711V7H12.5V4V3.5H12H9V4.5H10.7929L4.5 10.7929L4.5 9L3.5 9V12V12.5H4H7V11.5H5.20711Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
