export const halfScreenCheck = (ref: React.RefObject<HTMLElement>) => {
  if (!ref.current?.parentElement)
    return { isTopHalf: false, isLeftHalf: false }

  const element = ref.current.parentElement.getBoundingClientRect()

  const window = document.body.getBoundingClientRect()

  return {
    isTopHalf: element.y < window.bottom / 2,
    isLeftHalf: element.x < window.right / 2,
  }
}
