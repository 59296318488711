import {
  DurationComponent,
  FpsComponent,
  NameComponent,
  Project as ProjectModel,
  Root,
  getEntryOrThrow,
  getSize,
  round,
} from '@aninix-inc/model'
import * as R from 'ramda'

import { usedCompositionRefs } from './composition'
import { GroupOrFrame } from './group-or-frame'
import { prepareMasks } from './prepare-masks'
import {
  LottieAsset,
  LottieAssetImage,
  LottieAssetPrecomp,
  LottieLayer,
} from './types'

export async function Project(payload: {
  project: ProjectModel
  imagesMap: Record<string, string>
  /**
   * @description start time in seconds
   */
  startTime?: number
  /**
   * @description end time in seconds
   */
  endTime?: number
}) {
  const { project, imagesMap } = payload
  const entry = getEntryOrThrow(project)
  const root = project.getEntityByTypeOrThrow(Root)
  const fps = root.getComponentOrThrow(FpsComponent).value
  const projectSize = getSize(entry)

  const startTime = payload.startTime ? payload.startTime * fps : 0
  const endTime = payload.endTime
    ? payload.endTime * fps
    : root.getComponentOrThrow(DurationComponent).value *
      root.getComponentOrThrow(FpsComponent).value

  let assets: LottieAsset[] = []

  const hashes = R.keys(imagesMap)
  for (const hash of hashes) {
    const imageUrl = imagesMap[hash]

    const promise = () =>
      new Promise<HTMLImageElement>((resolve, reject) => {
        const image = new Image()

        image.addEventListener('load', () => {
          resolve(image)
        })

        image.addEventListener('error', (err) => {
          reject(err)
          console.error('image loading failed', imageUrl, err)
        })

        image.src = imageUrl

        // @NOTE: set timeout for image loading
        setTimeout(() => {
          resolve(image)
        }, 5000)
      })

    const image = await promise()

    const isImageExternal = imageUrl.includes('http')

    assets.push({
      id: hash,
      w: image.width,
      h: image.height,
      e: isImageExternal ? 0 : 1,
      u: isImageExternal ? imageUrl : '',
      p: isImageExternal ? '' : imageUrl,
    })
  }

  const layers: LottieLayer[] = []

  prepareMasks(payload, assets)

  layers.push(...GroupOrFrame({ node: entry }, assets))

  // @NOTE: required to properly set indicies across all layers
  // @TODO: Optimize after refactor
  let ind = 0
  const layersToProcess = [...layers]
  for (const asset of assets) {
    const typedAsset = asset as LottieAssetPrecomp
    if (typedAsset.layers) {
      layersToProcess.push(...typedAsset.layers)
    }
  }
  layersToProcess.forEach((layer) => {
    ind += 1
    const prevInd = layer.ind
    layer.ind = ind

    layersToProcess
      .filter((child: LottieLayer) => child.parent === prevInd)
      .forEach((child: LottieLayer) => {
        child.parent = layer.ind
      })
  })

  return {
    fr: root.getComponentOrThrow(FpsComponent).value,
    v: '5.9.6',
    ip: startTime,
    // @NOTE: required to make by 1 frame shorter to make all layers visible on the last frame
    op: endTime - 1,
    w: round(projectSize.x, { fixed: 0 }),
    h: round(projectSize.y, { fixed: 0 }),
    nm: root.getComponentOrThrow(NameComponent).value,
    ddd: 0,
    markers: [],
    // @NOTE: ignore unused precomps
    assets: assets.filter((asset) => {
      const typedAsset = asset as LottieAssetImage
      return typedAsset.e != null || usedCompositionRefs.refs.has(typedAsset.id)
    }),
    layers,
    meta: {
      // @TODO: define user name here
      a: '',
      // @TODO: define project description
      d: '',
      // @TODO: define theme color
      tc: '',
      g: 'Aninix',
    },
  }
}
