export const getOffsetMutliplier = ({
  isShiftPressed,
  isCtrlPressed,
  isPositive,
}: {
  isShiftPressed: boolean
  isCtrlPressed: boolean
  isPositive: boolean
}) => {
  return (
    (isShiftPressed ? 10 : 1) *
    (isCtrlPressed ? 0.1 : 1) *
    (isPositive ? 1 : -1)
  )
}
