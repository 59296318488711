import { AnimatableProperty, GenericKeyframe } from '@aninix-inc/model/legacy'

export interface ICommands {
  showNotification: (payload: {
    id: string
    message: string
    options?: { autoClose: number; isError: boolean; button?: string }
  }) => void

  updateProperty: (
    property: AnimatableProperty,
    payload: { time: number; value: unknown }
  ) => void

  toggleKeyframe: (
    property: AnimatableProperty,
    payload: { time: number }
  ) => GenericKeyframe | null

  togglePropertyVisibility: (property: AnimatableProperty) => void
}

/**
 * @file A list of commands to abstract actions inside app.
 * @todo Move all commands to "core" package.
 * @todo Abstract commands pattern.
 * @todo Add to viewport as well.
 */
export function createUpdateValue(payload: {
  beforeUpdate: (property: AnimatableProperty) => void
  afterUpdate: (property: AnimatableProperty) => void
}): ICommands['updateProperty'] {
  const updateValue: ICommands['updateProperty'] = (property, payloadInner) => {
    payload.beforeUpdate(property)
    property.updateValue(payloadInner.time, payloadInner.value)
    payload.afterUpdate(property)
  }

  return updateValue
}

export function createToggleKeyframe() {
  const toggleKeyframe: ICommands['toggleKeyframe'] = (
    property,
    payloadInner
  ) => {
    const keyframes = property.keyframes

    const keyframeAtCurrentTime = keyframes.find(
      (key: GenericKeyframe) => key.time === payloadInner.time
    )

    if (keyframeAtCurrentTime != null) {
      property.removeKeyframe(keyframeAtCurrentTime.id)
      return null
    }

    const keyframe = property.createKeyframe(payloadInner.time)
    property.showOnTimeline()
    return keyframe
  }

  return toggleKeyframe
}

export function createTogglePropertyVisibility() {
  const togglePropertyVisibility: ICommands['togglePropertyVisibility'] = (
    property
  ) => {
    if (property.visibleOnTimeline === false) {
      property.showOnTimeline()
      return
    }

    property.hideOnTimeline()
  }

  return togglePropertyVisibility
}
