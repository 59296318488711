import { round } from '@aninix-inc/model/legacy'

interface IPayload {
  seconds: number
  fps: number
}
interface IOutput {
  frames: number
  suffix: string
  shortSuffix: string
}
export function convertSecondsToFrames({ seconds, fps }: IPayload): IOutput {
  return {
    frames: round(seconds * fps, { fixed: 0 }),
    suffix: 'frames',
    shortSuffix: 'f',
  }
}
