import { Point2D } from '@aninix-inc/model/legacy'
import { Viewport } from '@aninix/core/stores'
import { IComment } from '@aninix/core/use-cases/comments/use-get-comments'
import classNames from 'classnames'
import _ from 'lodash'
import { observer } from 'mobx-react'
import * as React from 'react'
import { CommentGroup } from './utils/map-comments-to-groups'

interface ZoomPosition {
  zoom: number
  position: Point2D
}

function moveViewportToBubble(
  viewport: Viewport,
  currentPosition: ZoomPosition,
  newZoomToRectPosition: ZoomPosition
) {
  const duration = 400

  const ease = (t: number) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t) // ease-in-out cubic

  return new Promise((resolve) => {
    let frame = 0
    const totalFrames = Math.round(duration / (1000 / 40)) // Approx. 30 FPS

    function animateViewport() {
      viewport.borrow()
      const progress = ease(frame / totalFrames)
      const currentZoom =
        currentPosition.zoom +
        (newZoomToRectPosition.zoom - currentPosition.zoom) * progress
      const currentX =
        currentPosition.position.x +
        (newZoomToRectPosition.position.x - currentPosition.position.x) *
          progress
      const currentY =
        currentPosition.position.y +
        (newZoomToRectPosition.position.y - currentPosition.position.y) *
          progress

      viewport.updatePosition({
        x: currentX,
        y: currentY,
      })

      viewport.updateZoom(currentZoom)

      frame++

      if (frame <= totalFrames) {
        requestAnimationFrame(animateViewport)
      } else {
        resolve(true) // Animation completed
        viewport.freeup()
      }
    }

    animateViewport()
  })
}

export const ViewportGroupBubble: React.FCC<{
  group: CommentGroup
  data: IComment[]
  viewport: Viewport
  index: number
  previousCommentId?: string
  nextCommentId?: string
}> = observer(
  ({ data, viewport, group, index, previousCommentId, nextCommentId }) => {
    const xValues = group.comments.map((c) => c.rectangle.x)
    const yValues = group.comments.map((c) => c.rectangle.y)

    const commentBubbleRectangle = {
      x: Math.min(...xValues),
      y: Math.min(...yValues),
      width: Math.max(...xValues) - Math.min(...xValues),
      height: Math.max(...yValues) - Math.min(...yValues),
    }
    const scaleRectangle = 3

    const zoomRectangle = {
      x:
        commentBubbleRectangle.x -
        commentBubbleRectangle.width * ((scaleRectangle - 1) / 2),
      y:
        commentBubbleRectangle.y -
        commentBubbleRectangle.height * ((scaleRectangle - 1) / 2),
      width: commentBubbleRectangle.width * scaleRectangle,
      height: commentBubbleRectangle.height * scaleRectangle,
    }

    const posX = (Math.min(...xValues) + Math.max(...xValues)) / 2
    const posY = (Math.min(...yValues) + Math.max(...yValues)) / 2
    const diameter =
      Math.max(...xValues) -
      Math.min(...xValues) +
      (Math.max(...yValues) - Math.min(...yValues)) / 2

    const [isRendered, setIsRendered] = React.useState<boolean>(false)

    React.useEffect(() => {
      setIsRendered(true)

      return () => {
        setIsRendered(false)
      }
    }, [])

    // const topThreeThreadStarters = React.useMemo(() => {
    //   const users = group.comments.map((e) => {
    //     return { id: e.author.id, avatarUrl: e.author.avatarUrl }
    //   })

    //   const count: Record<string, number> = users.reduce(
    //     (acc: Record<string, number>, { id }) => {
    //       acc[id] = (acc[id] || 0) + 1
    //       return acc
    //     },
    //     {}
    //   )

    //   return Object.entries(count)
    //     .sort((a, b) => b[1] - a[1])
    //     .slice(0, 3)
    //     .map(
    //       ([id]) =>
    //         users.find((user) => user.id === id) as {
    //           id: string
    //           avatarUrl: string
    //         }
    //     )
    // }, [group])

    const handleBubbleClick = React.useCallback(() => {
      // viewport.zoomToRect(rectangle)
      const currentPosition = {
        zoom: viewport.zoom,
        position: viewport.position,
      }

      const newZoomToRectPosition =
        viewport.getZoomToRectPosition(zoomRectangle)

      moveViewportToBubble(viewport, currentPosition, newZoomToRectPosition)
    }, [zoomRectangle, viewport])

    return (
      <div
        id={'group_' + group.id}
        className={classNames('pointer-events-none relative inset-0 z-20')}
      >
        <div
          onClick={handleBubbleClick}
          className={classNames(
            'viewport-group-bubble group absolute z-30 flex h-[--size] w-[--size] origin-center -translate-x-1/2 -translate-y-1/2 transform items-center justify-center overflow-hidden rounded-full bg-white shadow-lg hover:!scale-[1.1] hover:!shadow-xl ',
            {
              ['transition-[left,_to,_transform,_shadow] delay-75 duration-300 ease-out']:
                !viewport.isBusy,
              ['scale-100']: isRendered,
              ['scale-0']: !isRendered,
              ['pointer-events-auto']: !viewport.isBusy,
            }
          )}
          style={
            {
              left: posX * viewport.zoom,
              top: posY * viewport.zoom,
              '--size': _.clamp(diameter / 1.75, 48, 64) + 'px',
            } as React.CSSProperties
          }
        >
          {/* count of comments */}
          <div className="absolute inset-0 flex items-center justify-center">
            <p className="text-center align-middle text-[11px] font-semibold leading-3 text-black text-opacity-80 ">
              {group.comments.length}
            </p>
          </div>

          {/* avatar cloud */}
          {/* <div className="absolute inset-0 rounded-full bg-white transition-opacity delay-75 duration-150 group-hover:opacity-0">
            {topThreeThreadStarters?.[0] && (
              <img
                className={classNames(
                  'absolute  h-[24px] w-[24px] rounded-full transition-all duration-100 group-hover:-left-[24px] group-hover:-top-[24px]',
                  {
                    ['left-[14%] top-[14%]']:
                      topThreeThreadStarters.length === 3,
                    ['left-[33%] top-[33%]']:
                      topThreeThreadStarters.length === 2,
                    ['left-[50%] top-[50%]']:
                      topThreeThreadStarters.length === 1,
                  }
                )}
                crossOrigin="anonymous"
                src={topThreeThreadStarters?.[0].avatarUrl}
              />
            )}
            {topThreeThreadStarters?.[1] && (
              <img
                className="absolute left-[33px] top-[24px] h-[16px] w-[16px] rounded-full transition-all duration-100 group-hover:left-[78px] group-hover:top-[24px]"
                crossOrigin="anonymous"
                src={topThreeThreadStarters?.[1].avatarUrl}
              />
            )}
            {topThreeThreadStarters?.[2] && (
              <img
                className="absolute left-[19px] top-[35px] h-[12px] w-[12px] rounded-full transition-all duration-100 group-hover:left-[24px] group-hover:top-[76px]"
                crossOrigin="anonymous"
                src={topThreeThreadStarters?.[2].avatarUrl}
              />
            )}
          </div> */}
        </div>
      </div>
    )
  }
)

ViewportGroupBubble.displayName = 'ViewportGroupBubble'
