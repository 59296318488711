import * as React from 'react'

import { Normal } from './normal'
import { WithPlus } from './with-plus'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  type: 'normal' | 'with-plus'
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Profile: React.FCC<IProps> = ({
  type,
  style,
  size = defaultSize,
}) => {
  if (type === 'with-plus') {
    return <WithPlus style={style} size={size} />
  }

  if (type === 'normal') {
    return <Normal style={style} size={size} />
  }

  return null
}
