import classnames from 'classnames'
import * as React from 'react'
import * as styles from './styles.scss'

export interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  isHighlighted?: boolean
  isDropTarget?: boolean
  highlightColor?: string
  color?: string
  maskColor?: string
  highlightIndent?: number
  variant?: 'node' | 'property'
  alignment?: 'left' | 'right' | 'center'
}
export const TimelineTrack = React.forwardRef<HTMLDivElement, IProps>(
  (props, ref) => {
    const {
      isHighlighted = false,
      isDropTarget = false,
      highlightColor = 'transparent',
      color = 'transparent',
      maskColor = 'transparent',
      highlightIndent,
      variant = 'node',
      alignment = 'left',
      className,
      children,
      ...restProps
    } = props

    const style = React.useMemo(
      () => ({
        '--color': color,
        '--highlight-color': highlightColor,
        '--highlight-indent': `${Number(highlightIndent) > 0 ? Number(highlightIndent) * 16 : 0}px`,
        '--mask-color': maskColor,
      }),
      [color, maskColor, highlightIndent]
    )

    // TODO: change div with button?
    return (
      <div
        ref={ref}
        className={classnames(styles.container, {
          [styles['container--highlighted']]: isHighlighted,
          [styles['container--highlighted-left']]: alignment === 'left',
          [styles['container--highlighted-right']]: alignment === 'right',
          [styles['container--highlighted-bottom']]: isDropTarget,
        })}
        style={style as React.CSSProperties}
        {...restProps}
      >
        <div className={classnames(styles.content, className)}>{children}</div>
      </div>
    )
  }
)

TimelineTrack.displayName = 'TimelineTrack'
