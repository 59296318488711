import {
  commitUndo,
  Entity,
  expandChildren,
  hideChildren,
  ParentRelationAspect,
} from '@aninix-inc/model'
import { useProject } from '@aninix/core'
import { useUpdates } from '@aninix/editor/hooks/use-updates'
import { useSelection } from '@aninix/editor/modules/selection'
import * as React from 'react'
import {
  DND_ACTIVATION_THRESHOLD_MS,
  EXPAND_CHILDREN_THRESHOLD_MS,
} from './config'
import {
  canEntityContainChildren,
  dropAsChild,
  dropAsSibling,
  dropAsSiblingOfParent,
  DropHandler,
  getDraggableEntities,
  getDropZoneType,
  getIndent,
  hideAllProperties,
  showHiddenProperties,
} from './helpers'
import { DropZoneType, LayerDndContext } from './layer-dnd-context'

type MouseHandler = (e: React.MouseEvent, entity: Entity) => void

interface IProps {
  onDragStart?: (draggableEntities: Entity[]) => void
  onDragEnd?: () => void
  onDrop?: (dropedEntities: Entity[]) => void
}
export const LayerDndProvider: React.FCC<IProps> = ({
  children,
  onDragStart,
  onDragEnd,
  onDrop,
}) => {
  const [isDragging, setIsDragging] = React.useState(false)
  const [draggableEntities, setDraggableEntities] = React.useState<Entity[]>([])
  const [dropTargetEntity, setDropTargetEntity] = React.useState<Entity | null>(
    null
  )
  const [isMouseDown, setIsMouseDown] = React.useState<boolean>(false)
  const [dropZoneType, setDropZoneType] = React.useState<DropZoneType | null>(
    null
  )

  // @TODO: check why we have NodeJS.Timeout type here (in browser context) and remove it
  const expandChildrenTimerRef = React.useRef<number | NodeJS.Timeout | null>(
    null
  )
  // @TODO: check why we have NodeJS.Timeout type here (in browser context) and remove it
  const dndActivationTimerRef = React.useRef<number | NodeJS.Timeout | null>(
    null
  )
  const preparedDndEntityRef = React.useRef<Entity | null>(null)
  const propertiesExpandedEntitiesRef = React.useRef<Set<Entity>>(new Set())

  const updates = useUpdates()
  const project = useProject()
  const { selection } = useSelection()

  React.useEffect(() => {
    const cleanup = () => {
      endDragging()
      preparedDndEntityRef.current = null
      if (dndActivationTimerRef.current) {
        clearTimeout(dndActivationTimerRef.current)
      }
    }

    document.addEventListener('mouseup', cleanup)

    return () => {
      document.removeEventListener('mouseup', cleanup)
      if (dndActivationTimerRef.current) {
        clearTimeout(dndActivationTimerRef.current)
      }
    }
  }, [isDragging])

  const startDragging = React.useCallback(
    (draggable: Entity, dropTarget?: Entity) => {
      const draggableEntities = getDraggableEntities(draggable, selection)
      if (!draggableEntities.length) return

      for (const entity of draggableEntities) {
        if (canEntityContainChildren(entity)) hideChildren(entity)
      }

      propertiesExpandedEntitiesRef.current = hideAllProperties(project)

      setDraggableEntities(draggableEntities)

      if (dropTarget) setDropTargetEntity(dropTarget)
      setIsDragging(true)
      onDragStart?.(draggableEntities)
    },
    [onDragStart, selection, project]
  )

  const endDragging = React.useCallback(() => {
    showHiddenProperties(propertiesExpandedEntitiesRef.current)
    setDraggableEntities([])
    setDropTargetEntity(null)
    setIsDragging(false)
    setIsMouseDown(false)
    onDragEnd?.()
  }, [onDragEnd])

  const dropEntities = React.useCallback(
    (dropTarget: Entity) => {
      if (!draggableEntities.length || !dropZoneType) {
        endDragging()
        return
      }

      const handlers: Record<DropZoneType, DropHandler> = {
        child: dropAsChild,
        siblingOfParent: dropAsSiblingOfParent,
        sibling: dropAsSibling,
      }

      updates.batch(() => handlers[dropZoneType](draggableEntities, dropTarget))
      commitUndo(project)
      onDrop?.(draggableEntities)
      endDragging()
    },
    [draggableEntities, dropZoneType, project, updates, onDrop]
  )

  const isEntityDragging = React.useCallback(
    (entity: Entity) => {
      return isDragging && draggableEntities.some((e) => e.id === entity.id)
    },
    [isDragging, draggableEntities]
  )

  const isEntityDropTarget = React.useCallback(
    (entity: Entity) => {
      return dropTargetEntity ? dropTargetEntity.id === entity.id : false
    },
    [dropTargetEntity]
  )

  const isEntityParentOfDropTarget = React.useCallback(
    (entity: Entity) => {
      if (!dropTargetEntity) return false
      if (canEntityContainChildren(dropTargetEntity)) {
        return entity.id === dropTargetEntity.id
      } else {
        const dropTargetParent = dropTargetEntity
          .getAspect(ParentRelationAspect)
          ?.getParentEntity()
        return dropTargetParent ? entity.id === dropTargetParent.id : false
      }
    },
    [dropTargetEntity]
  )

  const onMouseDown: MouseHandler = React.useCallback(
    (e, entity) => {
      if (e.button !== 0) return
      setIsMouseDown(true)
      preparedDndEntityRef.current = entity
      dndActivationTimerRef.current = setTimeout(() => {
        startDragging(entity, entity)
      }, DND_ACTIVATION_THRESHOLD_MS)
    },
    [startDragging]
  )

  const onMouseOver: MouseHandler = React.useCallback(
    (e, entity) => {
      if (!isDragging || !draggableEntities) return

      if (
        !draggableEntities.some((e) => e.id === entity.id) &&
        canEntityContainChildren(entity)
      ) {
        expandChildrenTimerRef.current = setTimeout(() => {
          expandChildren(entity)
        }, EXPAND_CHILDREN_THRESHOLD_MS)
      }

      setDropTargetEntity(entity)
      setDropZoneType(getDropZoneType(e, entity))
    },
    [isDragging, draggableEntities]
  )

  const onMouseMove: MouseHandler = React.useCallback(
    (e, entity) => {
      if (!isDragging) return
      setDropZoneType(getDropZoneType(e, entity))
    },
    [isDragging]
  )

  const onMouseUp: MouseHandler = React.useCallback(
    (e, entity) => {
      if (expandChildrenTimerRef.current)
        clearTimeout(expandChildrenTimerRef.current)
      if (!isDragging) return
      dropEntities(entity)
    },
    [isDragging, dropEntities]
  )

  const onMouseOut: MouseHandler = React.useCallback(
    (e, entity) => {
      if (isDragging) {
        setDropZoneType(null)
        setDropTargetEntity(null)
      } else {
        if (dndActivationTimerRef.current) {
          clearTimeout(dndActivationTimerRef.current)
        }
        if (isMouseDown && preparedDndEntityRef.current) {
          startDragging(preparedDndEntityRef.current, entity)
        }
      }

      if (expandChildrenTimerRef.current)
        clearTimeout(expandChildrenTimerRef.current)
    },
    [isMouseDown, isDragging, startDragging]
  )

  const getDropZoneIndent = React.useCallback(
    (indent: number) => getIndent(dropZoneType, indent),
    [dropZoneType]
  )

  return (
    <LayerDndContext.Provider
      value={{
        isEntityDragging,
        isEntityDropTarget,
        isEntityParentOfDropTarget,
        getDropZoneIndent,
        onMouseDown,
        onMouseMove,
        onMouseOut,
        onMouseOver,
        onMouseUp,
      }}
    >
      {children}
    </LayerDndContext.Provider>
  )
}
