import {
  nearestColorIndexRGB,
  nearestColorIndexRGBA,
  rgb888_to_rgb444 as rgb888ToRgb444,
  rgb888_to_rgb565 as rgb888ToRgb565,
  rgba8888_to_rgba4444 as rgba8888ToRgba4444,
} from '../vendor/gifenc'

export function ditheringWithoutPalette(
  data: Uint8ClampedArray,
  imageWidth: number
): void {
  const imageLength = data.length
  const skipPixels = 1
  let newPixelR = 0
  let newPixelG = 0
  let newPixelB = 0
  let errR = 0
  let errG = 0
  let errB = 0
  let pixelIndex = 0

  const atkinsonWeights = [
    { offset: 4, weight: 1 / 8 },
    { offset: 8, weight: 1 / 8 },
    { offset: 4 * imageWidth - 4, weight: 1 / 8 },
    { offset: 4 * imageWidth, weight: 1 / 8 },
    { offset: 4 * imageWidth + 4, weight: 1 / 8 },
    { offset: 8 * imageWidth, weight: 1 / 8 },
  ]
  const floydSteinbergWeights = [
    { offset: 4, weight: 7 / 16 },
    { offset: 4 * imageWidth - 4, weight: 3 / 16 },
    { offset: 4 * imageWidth, weight: 5 / 16 },
    { offset: 4 * imageWidth + 4, weight: 1 / 16 },
  ]
  const weights = atkinsonWeights
  // const weights = floydSteinbergWeights

  for (let i = 0; i <= imageLength; i += skipPixels * 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]

    const factor = 16
    newPixelR = Math.round((factor * r) / 255) * Math.floor(255 / factor)
    newPixelG = Math.round((factor * g) / 255) * Math.floor(255 / factor)
    newPixelB = Math.round((factor * b) / 255) * Math.floor(255 / factor)

    errR = r - newPixelR
    errG = g - newPixelG
    errB = b - newPixelB

    data[i] = newPixelR
    data[i + 1] = newPixelG
    data[i + 2] = newPixelB

    for (let j = 0; j < weights.length; j++) {
      pixelIndex = i + weights[j].offset
      if (pixelIndex + 3 >= imageLength) {
        continue
      }
      data[pixelIndex] += Math.floor(errR * weights[j].weight)
      data[pixelIndex + 1] += Math.floor(errG * weights[j].weight)
      data[pixelIndex + 2] += Math.floor(errB * weights[j].weight)
    }
  }
}

/**
 * @url https://en.wikipedia.org/wiki/Atkinson_dithering
 */
export function dithering(
  palette: number[][],
  data: Uint8ClampedArray,
  imageWidth: number,
  format: 'rgb444' | 'rgba4444' | 'rgb565'
): void {
  const imageLength = data.length
  const skipPixels = 1
  let newPixelR = 0
  let newPixelG = 0
  let newPixelB = 0
  let errR = 0
  let errG = 0
  let errB = 0
  let pixelIndex = 0
  const bincount = format === 'rgb444' ? 4096 : 65536
  const cache = new Array(bincount)
  const hasAlpha = format === 'rgba4444'

  const atkinsonWeights = [
    { offset: 4, weight: 1 / 8 },
    { offset: 8, weight: 1 / 8 },
    { offset: 4 * imageWidth - 4, weight: 1 / 8 },
    { offset: 4 * imageWidth, weight: 1 / 8 },
    { offset: 4 * imageWidth + 4, weight: 1 / 8 },
    { offset: 8 * imageWidth, weight: 1 / 8 },
  ]
  const floydSteinbergWeights = [
    { offset: 4, weight: 7 / 16 },
    { offset: 4 * imageWidth - 4, weight: 3 / 16 },
    { offset: 4 * imageWidth, weight: 5 / 16 },
    { offset: 4 * imageWidth + 4, weight: 1 / 16 },
  ]
  const weights = atkinsonWeights
  // const weights = floydSteinbergWeights

  for (let i = 0; i <= imageLength; i += skipPixels * 4) {
    const r = data[i]
    const g = data[i + 1]
    const b = data[i + 2]
    const a = data[i + 3]
    const nearestIndex = (() => {
      if (hasAlpha) {
        const key = rgba8888ToRgba4444(r, g, b, a)
        const idx =
          key in cache
            ? cache[key]
            : (cache[key] = nearestColorIndexRGBA(r, g, b, a, palette))
        return idx as number
      }

      const rgb888ToKey = format === 'rgb444' ? rgb888ToRgb444 : rgb888ToRgb565
      const key = rgb888ToKey(r, g, b)
      const idx =
        key in cache
          ? cache[key]
          : (cache[key] = nearestColorIndexRGB(r, g, b, palette))
      return idx as number
    })()

    newPixelR = palette[nearestIndex]?.[0] ?? 127
    newPixelG = palette[nearestIndex]?.[1] ?? 127
    newPixelB = palette[nearestIndex]?.[2] ?? 127

    errR = r - newPixelR
    errG = g - newPixelG
    errB = b - newPixelB

    data[i] = newPixelR
    data[i + 1] = newPixelG
    data[i + 2] = newPixelB

    for (let j = 0; j < weights.length; j++) {
      pixelIndex = i + weights[j].offset
      if (pixelIndex + 3 >= imageLength) {
        continue
      }
      data[pixelIndex] += Math.floor(errR * weights[j].weight)
      data[pixelIndex + 1] += Math.floor(errG * weights[j].weight)
      data[pixelIndex + 2] += Math.floor(errB * weights[j].weight)
    }
  }
}
