import { LocalStorageIo } from '@aninix/api'
import React from 'react'

interface IProps {
  name: string
  onboardingGroupName?: string
  onClose?: () => void
  onPass?: () => void
}

export type OnboardingObject = ReturnType<typeof useOnboarding>

export const useOnboarding = ({
  name,
  onClose,
  onPass,
  onboardingGroupName = name,
}: IProps) => {
  const localStorageKey = `aninix.${name}-onboarding`

  const onboardingLocalStorage = new LocalStorageIo<{
    passed: boolean
  }>(localStorageKey, {
    passed: false,
  })

  const [isExpanded, setIsExpanded] = React.useState(false)
  const [isPassed, setIsPassed] = React.useState(false)

  React.useEffect(() => {
    onboardingLocalStorage.get().then((result) => {
      if (result === null) {
        onboardingLocalStorage.reset()
        return
      }

      setIsPassed(result.passed)
    })
  }, [])

  const open = React.useCallback(() => {
    if (sessionStorage.getItem(`${onboardingGroupName}`) === 'seen') return
    setIsExpanded(true)
  }, [])

  const close = React.useCallback(() => {
    setIsExpanded(false)
    setIsPassed(true)
    sessionStorage.setItem(`${onboardingGroupName}`, 'seen')

    onClose?.()
  }, [localStorageKey])

  const pass = () => {
    close()
    onboardingLocalStorage.set({ passed: true })

    onPass?.()
  }

  return {
    isExpanded,
    isPassed,
    open,
    close,
    pass,
  }
}
