import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Visible: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 11a6.495 6.495 0 01-5.478-3A6.495 6.495 0 018 5c2.3 0 4.322 1.194 5.479 3A6.495 6.495 0 018 11zm0-7a7.499 7.499 0 016.635 4A7.499 7.499 0 018 12a7.499 7.499 0 01-6.635-4A7.499 7.499 0 018 4zm0 6a2 2 0 100-4 2 2 0 000 4z"
        fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <path fill="white" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
)
