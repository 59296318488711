import { useMutation } from '@tanstack/react-query'

import {
  ApiPatchParams,
  ApiPatchRequestBody,
  ApiPatchResponse,
} from './helpers'
import { httpClient } from './http-client'

export function useTransferProjectToTeam() {
  return useMutation({
    async mutationFn(
      payload: ApiPatchParams<'/projects/{projectId}'>['path'] &
        Pick<ApiPatchRequestBody<'/projects/{projectId}'>, 'teamId'>
    ) {
      return httpClient.patch<ApiPatchResponse<'/projects/{projectId}'>>(
        `/projects/${payload.projectId}`,
        { teamId: payload.teamId }
      )
    },
  })
}
