import { Entity, EntryComponent, ParentRelationAspect } from '@aninix-inc/model'

export function getNodesBranch(node: any, stack: any[] = []): any[] {
  if (node.isRoot && stack.length === 0) {
    return [node]
  }

  if (node.isRoot) {
    return stack
  }

  return getNodesBranch(node.getParent(), [node, ...stack])
}

export function getNodesBranchV2(
  entity: Entity,
  stack: Entity[] = []
): Entity[] {
  if (entity.hasComponent(EntryComponent) && stack.length === 0) {
    return [entity]
  }

  if (entity.hasComponent(EntryComponent)) {
    return stack
  }

  return getNodesBranchV2(
    entity.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow(),
    [entity, ...stack]
  )
}
