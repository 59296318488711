import type { Point2D } from '@aninix-inc/model/legacy'

import { BezierPoint } from './bezier-point'

type Payload = {
  position: Point2D
  size: Point2D
}

export function lineToBezier({ position, size }: Payload): BezierPoint[] {
  const result = [
    new BezierPoint({ point: position }),
    new BezierPoint({
      point: {
        x: position.x + size.x,
        y: position.y + size.y,
      },
    }),
  ]

  return result
}
