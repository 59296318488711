import { FullPageLoader } from '@aninix/app-design-system'
import {
  CommentContextProvider,
  ImagesStore,
  ImagesStoreProvider,
  Playback,
  PlaybackProvider,
  ProjectProvider,
  Session,
  SessionProvider,
  Settings,
  Timeline,
  TimelineProvider,
  Viewport,
  ViewportProvider,
} from '@aninix/core'
import { Player } from '@aninix/core/modules/inspector/components/player'
import { UiProvider } from '../../stores'
import { UseCasesProvider } from '../../use-cases'
// @TODO: refactor imports from web-app.
// Such logic should be handled on top.
import {
  Project,
  getEntryOrThrow,
  getPosition,
  getSize,
} from '@aninix-inc/model'
import { syncProjectSnapshot } from '@aninix/api'
import { LoadingSpinner } from 'apps/web-app/src/components/loading-spinner'
import {
  AccessRestricted,
  FreeLimitExceeded,
} from 'apps/web-app/src/components/status-pages'
import { NotFoundView } from 'apps/web-app/src/modules/not-found-view'
import { PaymentRequiredView } from 'apps/web-app/src/modules/payment-required-view'
import { toast } from 'apps/web-app/src/modules/toasts'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { SelectionProvider } from '../selection'
import { ProjectHistoryPanel } from './project-history-panel'
import { Toolbar } from './toolbar'
import { useGetRemoteProject } from './use-get-remote-project-v2'
import { FontLoadingProvider } from '@aninix/core/stores/font-loading'

export interface IProps {}

const user = new Settings()

export const ProjectHistory: React.FCC<IProps> = observer(() => {
  const [playback, setPlayback] = React.useState<Playback>()
  const [timeline, setTimeline] = React.useState<Timeline>()
  const [imagesStore, setImagesStore] = React.useState<ImagesStore>()
  const [viewport, setViewport] = React.useState<Viewport>()

  const { projectId } = useParams()

  const [selectedVersion, setSelectedVersion] = React.useState<number>(0)
  const isCurrentVersionSelected = React.useMemo(
    () => selectedVersion === 0,
    [selectedVersion]
  )

  const [localProject, setLocalProject] = React.useState<Project>()

  const {
    project: initialProject,
    state,
    imageProvider,
  } = useGetRemoteProject({
    projectId,
    imagesStore,
  })

  const project = React.useMemo(
    () => localProject ?? initialProject,
    [localProject, initialProject]
  )

  const session = React.useMemo(() => new Session(), [])

  const [isProjectLoading, setIsProjectLoading] = React.useState<boolean>(false)

  const handleDone = React.useCallback(() => {
    if (isCurrentVersionSelected && project != null) {
      window.location.assign(`/edit/${projectId}`)
      return
    }

    if (project === undefined) return

    setIsProjectLoading(true)

    const versionSnapshot = project.getSnapshot()
    syncProjectSnapshot(versionSnapshot)
      .then(() => {
        window.location.assign(`/edit/${projectId}`)
      })
      .catch(() => {
        toast("Couldn't update project version", { variant: 'error' })
      })
  }, [project, isCurrentVersionSelected])

  React.useEffect(() => {
    if (state === 'success' && !isProjectLoading) {
      let oldPlaybackState = {
        lastTime: playback?.time,
        isPlaying: playback?.isPlaying,
      }

      const newPlayback = new Playback({ project: project!, user })
      newPlayback.updateTime(oldPlaybackState.lastTime ?? 0)
      if (oldPlaybackState.isPlaying) {
        newPlayback.play()
      }

      setPlayback(newPlayback)
      setTimeline(new Timeline({ project: project! }))
      setImagesStore(new ImagesStore(imageProvider!))
      const localViewport = new Viewport()
      setViewport(localViewport)

      // @NOTE: handle initial zoom
      setTimeout(() => {
        const padding = 100
        const entry = getEntryOrThrow(project!)
        const position = getPosition(entry)
        const size = getSize(entry)
        // @NOTE: zoom to fit
        localViewport.zoomToRect({
          x: position.x - padding / 2,
          y: position.y - padding / 2,
          width: size.x + padding,
          height: size.y + padding,
        })
      }, 1)
    }
  }, [state, isProjectLoading])

  const [rerenderPlayerKey, setRerenderPlayerKey] = React.useState(0)
  React.useEffect(() => {
    setRerenderPlayerKey((v) => v + 1)
  }, [project])

  if (state === 'loading') {
    return <FullPageLoader />
  }

  if (state === 'not-found') {
    return <NotFoundView />
  }

  if (state === 'payment-required') {
    return <PaymentRequiredView />
  }

  if (state === 'locked') {
    return <FreeLimitExceeded />
  }

  if (state === 'login-required') {
    const urlToRedirect = encodeURIComponent(`/inspect/${projectId}`)
    return (
      <AccessRestricted redirectUrlOnSuccess={urlToRedirect} autoRedirect />
    )
  }

  if (state === 'error') {
    return <NotFoundView />
  }

  if (R.any(R.isNil, [playback, timeline, imagesStore])) {
    return null
  }

  return (
    <UiProvider>
      <SessionProvider store={session!}>
        <PlaybackProvider store={playback!}>
          <TimelineProvider store={timeline!}>
            <ViewportProvider store={viewport!}>
              <ProjectProvider project={project!}>
                <ImagesStoreProvider store={imagesStore!}>
                  <SelectionProvider project={project!}>
                    <UseCasesProvider>
                      <CommentContextProvider>
                        <FontLoadingProvider project={project!}>
                          <div className="relative flex h-screen w-screen flex-col">
                            <Toolbar handleDone={handleDone} />
                            <div className="relative flex h-full flex-row gap-0">
                              <div className="relative flex-grow">
                                <div
                                  className={classNames(
                                    'absolute z-10 flex h-full w-full items-center bg-black bg-opacity-10 transition-all duration-150',
                                    {
                                      ['pointer-events-none opacity-0']:
                                        !isProjectLoading,
                                    }
                                  )}
                                >
                                  <LoadingSpinner />
                                </div>
                                <div
                                  className={classNames(
                                    'relative w-full h-full transition-opacity duration-300',
                                    {
                                      ['pointer-events-none opacity-0']:
                                        isProjectLoading,
                                    }
                                  )}
                                >
                                  <Player key={rerenderPlayerKey} />
                                </div>
                              </div>
                              <div className="h-full w-60 flex-grow-0 overflow-x-scroll">
                                <ProjectHistoryPanel
                                  setIsProjectLoading={setIsProjectLoading}
                                  selectedVersion={selectedVersion}
                                  setSelectedVersion={setSelectedVersion}
                                  setProject={setLocalProject}
                                />
                              </div>
                            </div>
                          </div>
                        </FontLoadingProvider>
                      </CommentContextProvider>
                    </UseCasesProvider>
                  </SelectionProvider>
                </ImagesStoreProvider>
              </ProjectProvider>
            </ViewportProvider>
          </TimelineProvider>
        </PlaybackProvider>
      </SessionProvider>
    </UiProvider>
  )
})

ProjectHistory.displayName = 'ProjectHistory'
