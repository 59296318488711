import { Entity, Project } from '@aninix-inc/model'
import { createEmptyProject } from './create-empty-project'

export const getDiffSnapshot = ({
  overridenProject,
  originalProject,
}: {
  overridenProject: Project
  originalProject: Project
}) => {
  const snapshotContainer = createEmptyProject(originalProject.id)

  const entityDiffMap: Record<string, Entity<unknown>> = {}

  overridenProject.entities.forEach((entity) => {
    entity.components.forEach((component, index) => {
      const originalComponent = originalProject.getEntity(entity.id)
        ?.components[index]

      if (
        JSON.stringify(originalComponent?.value) !==
        JSON.stringify(component.value)
      ) {
        entityDiffMap[entity.id] = entity
      }
    })
  })

  Object.values(entityDiffMap).forEach((entity) => {
    return snapshotContainer.createEntityFromSnapshot(entity.getSnapshot(), {
      persistId: true,
    })
  })

  return snapshotContainer.getSnapshot()
}
