import * as React from 'react'

import { useSessionStore } from '../../stores'
import { IAuthenticateUseCase } from '../../use-cases'

type Payload = {
  addAccount: boolean
  searchParams: URLSearchParams
  authenticate: IAuthenticateUseCase
}

export interface ILoginInteractor {
  state: 'idle' | 'loading' | 'error'
  login: () => void
}
export function useLoginInteractor({
  addAccount,
  authenticate,
  searchParams,
}: Payload): ILoginInteractor {
  const sessionStore = useSessionStore()
  const [state, setState] = React.useState<ILoginInteractor['state']>('idle')

  const redirect = React.useCallback(async () => {
    setState('loading')

    try {
      // @TODO: IMPORTANT move to common place
      /**
       * Adding new account to current stack.
       * 1. Receive new token;
       * 2. Add user to current local storage keys;
       * 3. Make request to new auth session.
       */
      if (addAccount) {
        await sessionStore.generateAnonymousToken()
      }

      const result = await authenticate.start({
        redirectUrlOnSuccess: searchParams.get('redirectUrlOnSuccess') ?? '/',
        redirectUrlOnError:
          searchParams.get('redirectUrlOnError') ?? '/auth?status=error',
      })

      if (result == null) {
        return
      }

      window.location.replace(result.url)
    } catch (err: unknown) {
      console.error('login error happen', err)
      setState('error')
    }
  }, [addAccount, authenticate, searchParams])

  React.useEffect(() => {
    const shouldAutoRedirect = searchParams.get('autoRedirect') ?? false

    if (shouldAutoRedirect) {
      redirect()
    }
  }, [])

  return {
    state,
    login: redirect,
  }
}
