import { useQuery } from '@tanstack/react-query'
import * as R from 'ramda'
import { ApiGetParams, ApiProjects } from './helpers'
import { httpClient } from './http-client'

export type GetProjectsPayload = ApiGetParams<'/projects'>['query'] & {
  updatedProjectsAfterLastFetch?: ApiProjects
}

export function useGetProjects(payload: GetProjectsPayload) {
  return useQuery({
    queryKey: [
      'get-dashboard-projects',
      //@ts-ignore
      `${payload.userId} - ${payload?.teamId} - ${payload?.folderId}`,
      //important to notice here's no "skip" and "limit", to provide single data flow between refetches via query cache
      payload.updatedAfter,
    ],
    async queryFn() {
      return httpClient.get<ApiProjects>('/projects', {
        params: payload,
      })
    },
    structuralSharing: createStructuralSharing(payload),
  })
}

type StructuralSharingFunc = (
  prevData: unknown,
  newData: unknown
) => { data: ApiProjects }

export const createStructuralSharing =
  (payload: GetProjectsPayload): StructuralSharingFunc =>
  (prevData, newData) => {
    const prevProjects = isDataContainsProjects(prevData) ? prevData.data : []
    const newProjects = isDataContainsProjects(newData) ? newData.data : []

    // @NOTE: handle case when user deletes the project.
    // Related to ANI-1871
    if (
      payload.updatedProjectsAfterLastFetch == null ||
      payload.updatedProjectsAfterLastFetch.length === 0
    ) {
      return newData as any
    }

    const concatedArray = R.concat(prevProjects, newProjects)
    const concatedUniqueArray = R.uniqBy((item) => item.id, concatedArray)

    //1. each project that has been updated since last request
    payload.updatedProjectsAfterLastFetch?.forEach((updatedProject) => {
      const index = concatedUniqueArray.findIndex(
        (newProject) => newProject.id === updatedProject.id
      )

      if (index !== -1) {
        // 1.1 will replace existing instance in data
        concatedUniqueArray[index] = updatedProject
      } else {
        // 1.2 or if there's no instance, will be pushed to the start of the array
        //helpful for user to see fresh projects at the top of the folder regardless of BE sorting
        concatedUniqueArray.unshift(updatedProject)
      }
    })

    const result = R.assoc('data', concatedUniqueArray, newData)

    return result
  }

const isDataContainsProjects = (
  data: unknown
): data is { data: ApiProjects } => {
  return R.has('data', data) && Array.isArray((data as { data: unknown }).data)
}
