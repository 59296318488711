import { useQuery } from '@tanstack/react-query'

import { ApiGetParams } from './helpers'
import { httpClient } from './http-client'

export function useGetFigmaAuthSession(
  payload: ApiGetParams<'/figma-auth-sessions/{figmaAuthSessionId}'>['path']
) {
  return useQuery({
    queryKey: ['figma-auth-session', payload.figmaAuthSessionId],
    async queryFn() {
      return httpClient.get(
        `/figma-auth-sessions/${payload.figmaAuthSessionId}`
      )
    },
  })
}

export function getFigmaAuthSession(
  payload: ApiGetParams<'/figma-auth-sessions/{figmaAuthSessionId}'>['path']
) {
  return httpClient.get(`/figma-auth-sessions/${payload.figmaAuthSessionId}`)
}
