/**
 * @file group component for properties
 */
import classNames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

const noop = () => {}

export interface IProps {
  title: React.ReactNode
  isOpen: boolean
  onClick?: () => void
  headerButtons?: React.ReactNode
  sliderInHeader?: boolean
}
export const Group: React.FCC<IProps> = ({
  title,
  isOpen,
  onClick = noop,
  headerButtons = null,
  sliderInHeader,
  children,
}) => (
  <div
    className={classNames(styles.container, {
      [styles['container--active']]: isOpen,
    })}
  >
    <div
      className={classNames(styles.header, {
        [styles['header--shrinked']]: sliderInHeader,
        [styles['header--expanded']]: isOpen,
      })}
      onClick={isOpen ? noop : onClick}
    >
      <div className={styles.title}>{title}</div>

      {headerButtons != null && (
        <div className={styles['header-buttons']}>{headerButtons}</div>
      )}
    </div>

    {isOpen && <div className={styles.content}>{children}</div>}
  </div>
)
