import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const TopRightRadius: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.5C8 2.015 5.985 0 3.5 0H0V1H3.5C5.433 1 7 2.567 7 4.5V8H8V4.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
