import * as R from 'ramda'
import * as React from 'react'
import tinycolor from 'tinycolor2'

import { ColorStop } from '../types'

export interface IProps {
  colorStops: ColorStop[]
}
export const Gradient: React.FCC<IProps> = ({ colorStops }) => {
  const preparedColorStops = React.useMemo(
    () => R.sort((left, right) => left.progress - right.progress, colorStops),
    [colorStops]
  )

  return (
    <svg
      width="192"
      height="12"
      viewBox="0 0 192 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <linearGradient id="fill">
          {preparedColorStops.map((colorStop) => (
            <stop
              key={colorStop.id}
              offset={`${colorStop.progress * 100}%`}
              stopColor={`#${tinycolor(colorStop.value).toHex()}`}
              stopOpacity={tinycolor(colorStop.value).getAlpha()}
            />
          ))}
        </linearGradient>
      </defs>

      <rect
        x={1}
        y={1}
        width={190}
        height={10}
        rx={2}
        fill="url(#checkerboard_gradient)"
        stroke="rgba(0, 0, 0, 0.2)"
        strokeWidth={0.5}
      />

      <rect x={1} y={1} width={190} height={10} rx={2} fill="url(#fill)" />
    </svg>
  )
}
