import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Question: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M8 11.625C7.65482 11.625 7.375 11.3452 7.375 11C7.375 10.6548 7.65482 10.375 8 10.375C8.34518 10.375 8.625 10.6548 8.625 11C8.625 11.3452 8.34518 11.625 8 11.625Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M8.00003 9V9C8.00003 8.44772 8.46259 8.01509 8.97727 7.81476C9.11487 7.7612 9.24409 7.69639 9.35721 7.62081C9.72722 7.37357 10.0156 7.02217 10.1859 6.61104C10.3562 6.1999 10.4008 5.7475 10.3139 5.31105C10.2271 4.87459 10.0128 4.47368 9.69817 4.15901C9.3835 3.84434 8.98259 3.63005 8.54613 3.54323C8.10967 3.45642 7.65727 3.50097 7.24614 3.67127C6.83501 3.84157 6.4836 4.12996 6.23637 4.49997C5.98914 4.86998 5.85718 5.30499 5.85718 5.75"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
