import * as React from 'react'

const defaultSize = {
  x: 10,
  y: 10,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const MenuChevron: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9998 7.99976L4.99982 3.99976V11.9998L10.9998 7.99976Z"
      stroke="white"
    />
  </svg>
)
