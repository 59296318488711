import * as React from 'react'

export const HappyIcon = () => (
  <svg
    width="102"
    height="102"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_4786_4168)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M47.8673 61.7947C50.9921 62.2061 54.1673 61.9979 57.2116 61.1822C63.3599 59.5348 68.602 55.5124 71.7845 50C74.9671 44.4876 75.8296 37.9367 74.1822 31.7884C72.5347 25.6401 68.5124 20.398 63 17.2154C57.4875 14.0329 50.9366 13.1704 44.7883 14.8178C41.744 15.6336 38.8901 17.0409 36.3897 18.9596C33.8893 20.8782 31.7912 23.2706 30.2154 26C28.6395 28.7295 27.6167 31.7426 27.2053 34.8674C26.7939 37.9922 27.0021 41.1673 27.8178 44.2117C28.6335 47.256 30.0409 50.1099 31.9595 52.6103C33.8782 55.1107 36.2705 57.2087 39 58.7846C41.7295 60.3605 44.7426 61.3833 47.8673 61.7947ZM45.2163 33.565C43.8688 32.0454 41.6161 31.8869 39.828 32.366C38.0399 32.8451 36.1682 34.1088 35.761 36.0986C35.6503 36.6396 35.9992 37.168 36.5402 37.2787C37.0813 37.3895 37.6097 37.0406 37.7204 36.4995C37.911 35.5684 38.9139 34.6814 40.3456 34.2978C41.7773 33.9142 43.0893 34.1808 43.7199 34.892C44.0863 35.3052 44.7184 35.3431 45.1316 34.9767C45.5448 34.6103 45.5827 33.9782 45.2163 33.565ZM63.2469 28.7337C61.8994 27.2142 59.6466 27.0556 57.8585 27.5347C56.0705 28.0138 54.1988 29.2775 53.7916 31.2673C53.6809 31.8083 54.0298 32.3367 54.5708 32.4474C55.1119 32.5582 55.6403 32.2093 55.751 31.6682C55.9415 30.7371 56.9445 29.8502 58.3762 29.4666C59.8078 29.0829 61.1199 29.3495 61.7505 30.0607C62.1169 30.4739 62.7489 30.5118 63.1622 30.1454C63.5754 29.779 63.6133 29.147 63.2469 28.7337ZM47.1399 46.5917C46.6645 46.3107 46.0513 46.4683 45.7703 46.9438C45.4892 47.4192 45.6469 48.0324 46.1223 48.3134C47.3058 49.0129 48.617 49.4692 49.979 49.6556C51.341 49.842 52.7266 49.7547 54.0545 49.3989C55.3824 49.0431 56.6259 48.4259 57.7123 47.5835C58.7987 46.741 59.706 45.6903 60.3812 44.4928C60.6525 44.0117 60.4823 43.4018 60.0013 43.1306C59.5202 42.8593 58.9103 43.0294 58.639 43.5105C58.0945 44.4762 57.3628 45.3236 56.4867 46.003C55.6106 46.6824 54.6077 47.1801 53.5368 47.4671C52.466 47.754 51.3486 47.8244 50.2502 47.6741C49.1517 47.5238 48.0944 47.1558 47.1399 46.5917Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_4786_4168"
        x="0.333336"
        y="0.666668"
        width="101.333"
        height="101.333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="13.3333" />
        <feGaussianBlur stdDeviation="13.3333" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.184314 0 0 0 0 0.501961 0 0 0 0 0.368627 0 0 0 0.3 0"
        />
        <feBlend
          mode="multiply"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4786_4168"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.33333" />
        <feGaussianBlur stdDeviation="3.33333" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.183313 0 0 0 0 0.500568 0 0 0 0 0.367321 0 0 0 0.3 0"
        />
        <feBlend
          mode="multiply"
          in2="effect1_dropShadow_4786_4168"
          result="effect2_dropShadow_4786_4168"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_4786_4168"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
