// @TODO: IMPORTANT remove the following line once we return to the pixi
// @ts-nocheck
import * as PIXI from 'pixi.js'

import { Point2D } from '@aninix-inc/model/legacy'

import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

import { StatsJSAdapter, addStats } from 'pixi-stats'
import { drawRootNode } from './draw-root-node'
import { DrawChildNodesConfig } from './pixi-drawers/draw-child-nodes'

import { RgbaValueComponent, Root, getEntryOrThrow } from '@aninix-inc/model'
import { convertEntityToSnapshot as convertNodeToSnapshot } from '@aninix-inc/renderer'
import featureFlags from '../../feature-flags'
import {
  useImagesStore,
  usePlayback,
  useProject,
  useViewport,
} from '../../stores'

const CACHE_BITMAP_RESOLUTION = 6

export interface IProps {
  renderConfig: Partial<DrawChildNodesConfig>
  cacheRootOnPause: boolean
  updateId?: number
  showDebug?: boolean
}
export const WebglRender: React.FCC<IProps> = observer(
  ({
    renderConfig,
    updateId = 0,
    cacheRootOnPause = true,
    showDebug = true,
  }) => {
    const images = useImagesStore()
    const project = useProject()
    const playback = usePlayback()
    const viewport = useViewport()
    const entry = getEntryOrThrow(project)
    const root = project.getEntityByTypeOrThrow(Root)
    const wrapperRef = React.useRef<HTMLDivElement>(null)
    const cursorPositionRef = React.useRef<Point2D>({ x: 0, y: 0 })

    const handleWheel = React.useCallback((e: WheelEvent) => {
      e.stopPropagation()
      e.preventDefault()

      viewport.borrow()

      const dx = e.deltaX
      const dy = e.deltaY

      const zoomPoint: Point2D = cursorPositionRef.current
      const clampedDy = R.clamp(-50, 50, dy)
      const zoomStep = 1 - clampedDy * 0.01

      // @NOTE: hack taken from here https://kenneth.io/post/detecting-multi-touch-trackpad-gestures-in-javascript
      if (e.metaKey || e.ctrlKey) {
        viewport.zoomToPoint({
          point: zoomPoint,
          zoomStep,
        })
        return
      }

      viewport.updatePosition({
        x: viewport.position.x - dx,
        y: viewport.position.y - dy,
      })
    }, [])

    const handleTouchMove = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        // @NOTE: required to update cursor position
        const svg = wrapperRef.current!.getBoundingClientRect()
        const defaultOffset = {
          x: svg.left,
          y: svg.top,
        }

        cursorPositionRef.current = {
          x:
            (e.clientX - defaultOffset.x - viewport.position.x) / viewport.zoom,
          y:
            (e.clientY - defaultOffset.y - viewport.position.y) / viewport.zoom,
        }
      },
      []
    )

    React.useEffect(() => {
      wrapperRef.current?.addEventListener('wheel', handleWheel, {
        passive: false,
      })

      return () => {
        wrapperRef.current?.removeEventListener('wheel', handleWheel)
      }
    }, [handleWheel])

    if (entry == null) {
      return <p>There's no frame to display</p>
    }

    const rootNodeSnapshot = React.useMemo(
      () =>
        convertNodeToSnapshot({
          entity: entry,
          time: playback.time,
          isRoot: true,
          imagesStore: images,
        }),
      [playback.time, updateId]
    )

    const [app, setApp] = React.useState<PIXI.Application | null>(null)
    const [stats, setStats] = React.useState<StatsJSAdapter | null>(null)

    const prerenderCallbacks = React.useRef<(() => void)[]>([])

    const updateStats = React.useCallback(() => {
      if (stats) {
        // stats.update()
        stats.stats.fpsPanel.snapshotSize = 5
        const lastFpsElement = document.getElementById('lastFps')
        if (lastFpsElement)
          lastFpsElement.innerText = `lastFps: ${(
            stats.stats.fpsPanel.values.at(-1) ?? 0
          ).toFixed(0)}`

        stats.dcPanel.update(
          stats.hook.deltaDrawCalls,
          Math.max(50, stats.hook.maxDeltaDrawCalls)
        )
        stats.tcPanel.update(
          stats.hook.texturesCount,
          Math.max(20, stats.hook.maxTextureCount)
        )
      }
    }, [stats])

    React.useEffect(() => {
      if (wrapperRef.current !== null && app === null) {
        PIXI.settings.ROUND_PIXELS = true

        const app = new PIXI.Application({
          background: root.getComponentOrThrow(RgbaValueComponent).value,
          powerPreference: 'high-performance',
          resolution: 3,
          autoDensity: true,
          resizeTo: window,
          sharedTicker: false,
          // antialias: true,
          // clearBeforeRender: true,
        })

        app.stop()

        setApp(app)

        //@ts-ignore
        globalThis.__PIXI_APP__ = app

        if (showDebug && featureFlags.pixiDebug) {
          const returnStats = addStats(document, app)

          setStats(returnStats)

          const statsElement = document.getElementById(
            'stats'
          ) as HTMLDivElement
          statsElement.setAttribute(
            'style',
            `
          position: absolute;
          top: 0;
          right: 0;
          zoom: 0.33;
      `
          )
          Array.from(statsElement.children).forEach((el) =>
            el.setAttribute('style', 'display: inline')
          )

          const lastFps = document.createElement('p')
          lastFps.id = 'lastFps'
          lastFps.innerText = `last fps: 0`
          lastFps.setAttribute(
            'style',
            `display: inline-block; width: 200px; padding: 20px; font-size: 24px; background: black; color: white; font-weight: 500`
          )
          statsElement.prepend(lastFps)
          wrapperRef.current.appendChild(statsElement)
        }

        wrapperRef.current.appendChild(app.view as HTMLCanvasElement)
      }
    }, [wrapperRef.current, app])

    React.useEffect(() => {
      if (app !== null && wrapperRef.current) {
        stats?.stats.begin()

        const rootFrame = app.stage.getChildByName(
          'rootFrame'
        ) as PIXI.Container | null

        if (rootFrame) rootFrame.cacheAsBitmap = false

        drawRootNode({
          rootNodeSnapshot,
          app,
          childNodesConfig: renderConfig,
          prerenderCallbacks: prerenderCallbacks.current,
        })

        app.render()

        stats?.stats.end()

        updateStats()
      }
    }, [app, wrapperRef.current, rootNodeSnapshot])

    React.useEffect(() => {
      if (app === null || wrapperRef.current === null) return

      //since playback will update zoom by itself
      if (playback.isPlaying) return

      stats?.stats.begin()

      const rootFrame = app.stage.getChildByName(
        'rootFrame'
      ) as PIXI.Container | null
      if (rootFrame === null) return

      if (cacheRootOnPause) {
        rootFrame.cacheAsBitmap = true
        rootFrame.cacheAsBitmapResolution = CACHE_BITMAP_RESOLUTION
      }

      rootFrame.position = viewport.position
      rootFrame.width = viewport.size.x
      rootFrame.height = viewport.size.y
      rootFrame.scale = { x: viewport.zoom, y: viewport.zoom }

      const projectNameText = app.stage.getChildByName(
        'projectNameText'
      ) as PIXI.Text | null

      if (projectNameText) {
        projectNameText.name = 'projectNameText'
        projectNameText.position = viewport.position
        projectNameText.position.y -= 18
      }

      app.render()

      stats?.stats.end()

      updateStats()
    }, [viewport.position, viewport.zoom, viewport.isBusy])

    React.useEffect(() => {
      if (wrapperRef.current === null || app === null || playback.isPlaying)
        return

      const rootFrame = app.stage.getChildByName(
        'rootFrame'
      ) as PIXI.Container | null

      if (rootFrame === null) return

      if (cacheRootOnPause) {
        rootFrame.cacheAsBitmap = true
        rootFrame.cacheAsBitmapResolution = CACHE_BITMAP_RESOLUTION
      }

      app.render()
    }, [playback.isPlaying])

    React.useEffect(() => {
      if (images.isLoading === true) return

      Object.keys(images.images).forEach((hash) => {
        const image = images.getImageSync(hash)
        if (image === null) return
        PIXI.Cache.set(hash, PIXI.Texture.from(image.src))
      })

      if (wrapperRef.current && app) {
        drawRootNode({
          rootNodeSnapshot,
          app,
          prerenderCallbacks: prerenderCallbacks.current,
        })

        const rootFrame = app.stage.getChildByName(
          'rootFrame'
        ) as PIXI.Container | null
        if (rootFrame === null) return

        if (cacheRootOnPause) {
          rootFrame.cacheAsBitmap = true
          rootFrame.cacheAsBitmapResolution = CACHE_BITMAP_RESOLUTION
        }

        app.render()
      }
    }, [images.isLoading])

    return (
      <div className="relative h-full w-full origin-top-left">
        <div className="absolute inset-0">
          <div
            ref={wrapperRef}
            className="absolute inset-0"
            onMouseMove={handleTouchMove}
          />
        </div>
      </div>
    )
  }
)

WebglRender.displayName = 'WebglRender'
