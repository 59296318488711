import {
  Component,
  getAnimatableValue,
  mixed,
  setValueNumber,
} from '@aninix-inc/model'
import { useComponents } from '@aninix/core'
import * as R from 'ramda'

/**
 * Encapsulate logic to retrieve and update value
 */
export function useValueNumber({
  components,
  time,
}: {
  components: Component[]
  time?: number
}): {
  value: number | typeof mixed
  updateValue: (newValue: number) => void
  updateByDelta: (delta: number) => void
} {
  useComponents(components)

  const equals = R.all(
    (component) =>
      getAnimatableValue(component, time) ===
      getAnimatableValue(components[0], time),
    components
  )

  const updateValue = (value: number) => {
    components.forEach((component) => {
      setValueNumber(component, value, time)
    })
  }

  const updateByDelta = (delta: number) => {
    components.forEach((component) => {
      setValueNumber(component, (value) => value + delta, time)
    })
  }

  return {
    value:
      equals && components.length
        ? (getAnimatableValue(components[0], time) as number)
        : mixed,
    updateValue,
    updateByDelta,
  }
}
