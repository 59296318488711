import classnames from 'classnames'
import MarkdownSource from 'markdown-to-jsx'
import * as React from 'react'

import * as styles from './index.scss'

export interface IProps {
  className?: string
  overrides?: any
}
export const Markdown: React.FCC<IProps> = ({
  overrides,
  className = '',
  children,
}) => {
  if (typeof children !== 'string') {
    return <div>Children can be only type of string</div>
  }

  return (
    <MarkdownSource
      options={{
        forceBlock: true,
        forceWrapper: true,
        wrapper: (props) => (
          <div {...props} className={classnames(styles.container, className)} />
        ),
        overrides,
      }}
    >
      {children}
    </MarkdownSource>
  )
}
