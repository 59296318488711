import { useMutation } from '@tanstack/react-query'

import { ApiPostAcceptedResponse, ApiPostParams } from './helpers'
import { httpClient } from './http-client'

export function useRestoreProject() {
  return useMutation({
    async mutationFn(
      payload: ApiPostParams<'/projects/{projectId}/restore'>['path']
    ) {
      return httpClient.post<
        ApiPostAcceptedResponse<'/projects/{projectId}/restore'>
      >(`/projects/${payload.projectId}/restore`)
    },
  })
}
