import {
  ChildrenRelationsAspect,
  DurationComponent,
  Entity,
  EntityType,
  LayerColor,
  NodeColorComponent,
  Root,
  SelectionSystem,
  getKeyframeValueComponent,
  getSortedKeyframes,
  isSegment,
} from '@aninix-inc/model'
import { GenericKeyframe } from '@aninix-inc/model/legacy'
import { useMouseMove } from '@aninix/app-design-system'
import { useComponent, useEntity, useSystem } from '@aninix/core/updates'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import * as React from 'react'
import {
  usePlayback,
  useProject,
  useSession,
} from '../../../../../../../stores'
import { getSegments } from '../layer-column'
import { Indicators } from './indicators'
import { PlaybackProgress } from './playback-progress'
import { Track } from './track'

const nodeColors = {
  [LayerColor.Blue]: '#18A0FB',
  [LayerColor.Purple]: '#7B61FF',
  [LayerColor.Pink]: '#FF00FF',
  [LayerColor.Green]: '#1BC47D',
  [LayerColor.Turqouise]: '#00B5CE',
  [LayerColor.Red]: '#F24822',
  [LayerColor.Orange]: '#FF9900',
  [LayerColor.Yellow]: '#FFC700',
  [LayerColor.Grey]: '#9FAFBA',
  [LayerColor.None]: '#7A7A7A',
}

export interface IProps {
  layer: Entity
}

export const TimelineColumn: React.FCC<IProps> = observer(({ layer }) => {
  const session = useSession()
  const project = useProject()
  useEntity(layer)
  const playback = usePlayback()
  const durationComponent = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent)
  useComponent(durationComponent)
  const duration = durationComponent.value
  const selection = layer.getProjectOrThrow().getSystemOrThrow(SelectionSystem)
  useSystem(selection)

  const { startListen, isListening, offsetX, endAtX } = useMouseMove()

  const progressRef = React.useRef<HTMLDivElement>(null)

  const [beenPlaying, setBeenPlaying] = React.useState<boolean | null>(null)

  const hasAnimation = (layer: Entity) =>
    getSortedKeyframes(layer, { recursive: true }).length > 0

  const children = layer.getAspect(ChildrenRelationsAspect)?.getChildrenList()

  const isSelected = selection
    .getEntitiesByEntityType(EntityType.Node)
    .map((node) => node.id)
    .includes(layer.id)

  const isHighlighted = session.buffer === layer.id

  const handleMouseEnter = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      session.setBuffer(layer.id)
    },
    []
  )
  const handleMouseLeave = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      session.cleanBuffer()
    },
    []
  )
  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
      // @ts-ignore
      startListen(e)

      selection
        .deselectAll()
        .select([layer.id])
        .select(allSegments.flat().map((key) => key.id))
    },
    []
  )

  const managePlaybackOnTimelineInteraction = () => {
    if (isListening && beenPlaying === null) {
      setBeenPlaying(playback.isPlaying)
      if (playback.isPlaying) playback.pause()
    }

    if (!isListening) {
      setBeenPlaying(null)
      if (beenPlaying) playback.play()
    }
  }

  React.useEffect(() => {
    managePlaybackOnTimelineInteraction()

    if (isListening === false) {
      return
    }

    const { left, width } =
      progressRef.current!.parentElement!.getBoundingClientRect()

    playback.updateTime(((endAtX - left) / width) * duration)
  }, [isListening, offsetX, endAtX])

  const allSegments: [GenericKeyframe, GenericKeyframe][] = R.aperture(
    2,
    getSortedKeyframes(layer, { recursive: true })
  ).filter(([left, right]) =>
    isSegment(left, right, (left, right) =>
      R.equals(
        getKeyframeValueComponent(left).value,
        getKeyframeValueComponent(right).value
      )
    )
  )

  const segments = getSegments(layer)

  const layerColor = layer.getComponentOrThrow(NodeColorComponent).value
  const layerStyle = {
    '--figma-color-text': nodeColors[layerColor],
    '--figma-color-text-01': nodeColors[layerColor] + '1a',
    position: 'relative',
  } as React.CSSProperties

  if (!hasAnimation(layer)) {
    return null
  }

  return (
    <div
      ref={progressRef}
      className={classNames('min-h-full py-1.5', {
        ['bg-[var(--figma-color-text-01)]']: isHighlighted || isSelected,
      })}
      style={layerStyle}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
    >
      <PlaybackProgress />
      <Indicators />
      <div className="h-[22px] pb-1.5" />
      <Track segments={segments} />

      {children?.map((layer) => (
        <div key={layer.id}>
          <TimelineColumn layer={layer} />
        </div>
      ))}
    </div>
  )
})

TimelineColumn.displayName = 'TimelineColumn'
