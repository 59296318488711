import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'
import { useGetTrash } from './get-trash'
import { ApiFolders, ApiProjects } from './helpers'

export interface CurrentFolderContextTypes {
  ProjectId?: string
  FolderId?: string | null
  MousePosition: Point2D | null
}

export interface CurrentFolderContextInterface {
  draggedProjectId?: CurrentFolderContextTypes['ProjectId']
  setDraggedProjectId?: React.Dispatch<
    React.SetStateAction<CurrentFolderContextTypes['ProjectId']>
  >
  draggedFolderOverId?: CurrentFolderContextTypes['FolderId']
  setDraggedFolderOverId?: React.Dispatch<
    React.SetStateAction<CurrentFolderContextTypes['FolderId']>
  >

  selectedProjectsIds?: Set<string> | undefined
  setSelectedProjectsIds?: React.Dispatch<
    React.SetStateAction<Set<string> | undefined>
  >

  selectedFoldersIds?: Set<string> | undefined
  setSelectedFoldersIds?: React.Dispatch<
    React.SetStateAction<Set<string> | undefined>
  >

  currentMousePosition?: CurrentFolderContextTypes['MousePosition']
  setCurrentMousePosition?: React.Dispatch<
    React.SetStateAction<CurrentFolderContextTypes['MousePosition']>
  >

  folder?: {
    refetch: () => void
    data: {
      folders: ApiFolders
      projects: ApiProjects
    }
  }
  trash?: ReturnType<typeof useGetTrash>
  payload?:
    | {
        teamId: string
        folderId: string | undefined
        userId?: undefined
      }
    | {
        userId: string | undefined
        folderId: string | undefined
        teamId?: undefined
      }

  deleteSelected?: () => void
  moveSelected?: () => void
}

export const CurrentFolderContext =
  React.createContext<CurrentFolderContextInterface>({})

export const useCurrentFolder = (): CurrentFolderContextInterface =>
  React.useContext(CurrentFolderContext)

CurrentFolderContext.displayName = 'CurrentFolderContext'
