import { getEntryOrThrow, getPosition, getSize } from '@aninix-inc/model'
import { Dropdown } from '@aninix/app-design-system'
import { usePlayback, useProject, useViewport } from '@aninix/core'
import { PopoverProps } from '@material-ui/core'
import * as React from 'react'

export interface IProps {
  isOpen: boolean
  onClose: () => void
  anchorEl: PopoverProps['anchorEl']
}
export const ZoomDropdown: React.FCC<IProps> = ({
  isOpen,
  onClose,
  anchorEl,
}) => {
  const playback = usePlayback()
  const viewport = useViewport()
  const project = useProject()
  const entry = getEntryOrThrow(project)
  const rootNodeSize = getSize(entry)
  const zoomPoint = React.useMemo(
    () => ({
      x: rootNodeSize.x / 2,
      y: rootNodeSize.y / 2,
    }),
    [rootNodeSize]
  )

  const zoomIn = React.useCallback(() => {
    viewport.zoomToPoint({ point: zoomPoint, zoomStep: 1.25 })
  }, [viewport])

  const zoomOut = React.useCallback(() => {
    viewport.zoomToPoint({ point: zoomPoint, zoomStep: 1 / 1.25 })
  }, [viewport])

  const zoomToFit = React.useCallback(() => {
    const entry = getEntryOrThrow(project)
    const position = getPosition(entry)
    const size = getSize(entry)
    // @NOTE: zoom to fit
    viewport.zoomToRect({
      x: position.x,
      y: position.y,
      width: size.x,
      height: size.y,
    })
  }, [viewport, project, playback])

  const zoomTo50 = React.useCallback(() => {
    viewport.zoomToPoint({ point: zoomPoint, zoomStep: 0.5 / viewport.zoom })
  }, [viewport])

  const zoomTo100 = React.useCallback(() => {
    viewport.zoomToPoint({ point: zoomPoint, zoomStep: 1 / viewport.zoom })
  }, [viewport])

  const zoomTo200 = React.useCallback(() => {
    viewport.zoomToPoint({ point: zoomPoint, zoomStep: 2 / viewport.zoom })
  }, [viewport])

  return (
    <Dropdown
      classes={{ paper: 'rounded-md bg-secondary mt-10 ' }}
      anchorEl={anchorEl}
      transformOrigin={{ horizontal: 'center', vertical: 'top' }}
      transitionDuration={150}
      items={[
        {
          title: 'Zoom in',
          onClick: zoomIn,
          rightPart: '+',
        },
        {
          title: 'Zoom out',
          onClick: zoomOut,
          rightPart: '-',
        },
        {
          title: 'Zoom to fit',
          onClick: zoomToFit,
        },
        {
          title: 'Zoom to 50%',
          onClick: zoomTo50,
        },
        {
          title: 'Zoom to 100%',
          onClick: zoomTo100,
        },
        {
          title: 'Zoom to 200%',
          onClick: zoomTo200,
        },
      ]}
      width={`200px`}
      onClose={onClose}
      open={isOpen}
    />
  )
}

ZoomDropdown.displayName = 'ZoomDropdown'
