import * as React from 'react'

export interface IProps {}
export const Export: React.FCC<IProps> = () => {
  const [isExportMenuOpen, setIsExportMenuOpen] = React.useState<boolean>(false)
  const handleClick = React.useCallback(() => {
    setIsExportMenuOpen((state) => !state)
  }, [])
  const handleClose = React.useCallback(() => {
    setIsExportMenuOpen(false)
  }, [])
  return (
    <>
      <button
        onClick={handleClick}
        className="group flex h-8 cursor-default items-center justify-center rounded-lg bg-accent transition-transform duration-150 hover:scale-[1.02]"
      >
        <p className="px-3 text-xs font-medium text-white">Export</p>
      </button>
    </>
  )
}

Export.displayName = 'Export'
