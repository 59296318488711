import {
  NumberKeyframe,
  RotationComponent,
  getValueNumber,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { formatAngleValue } from '../../../../../helpers'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 16,
  y: 16,
}

export const Rotation: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, snapshots, time } = useNodePropertiesPanel()

  const filteredNodes = nodes.filter((n) => n.hasComponent(RotationComponent))

  const components = filteredNodes.map((l) =>
    l.getComponentOrThrow(RotationComponent)
  )

  const keyframeType = getKeyframesType(components, time)
  const rotations = snapshots.map((s) => s.rotation)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <RotationEditable time={time} components={components} />
      ) : (
        <RotationDisplay
          time={time}
          components={components}
          keyframeType={keyframeType}
          rotations={rotations}
        />
      )}
    </div>
  )
}

Rotation.displayName = 'Rotation'

const RotationEditable: React.FC<{
  time: number
  components: RotationComponent[]
}> = ({ time, components }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={components}
            time={time}
            icon={<icons.propertiesPanel.Angle size={iconSize} />}
            format={formatAngleValue}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      }
    />
  )
}

RotationEditable.displayName = 'RotationEditable'

interface RotationDisplayProps {
  time: number
  components: RotationComponent[]
  keyframeType: PropertyKeyframesType
  rotations: number[]
}

const propsAreEqual = (
  prev: RotationDisplayProps,
  next: RotationDisplayProps
) => {
  if (prev.rotations.length !== next.rotations.length) return false
  if (prev.keyframeType !== next.keyframeType) return false
  for (let i = 0; i < prev.rotations.length; i++) {
    if (prev.rotations[i] !== next.rotations[i]) return false
  }
  return true
}

const RotationDisplay: React.FC<RotationDisplayProps> = React.memo(
  ({ time, components }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <NumberValue
              components={components}
              time={time}
              icon={<icons.propertiesPanel.Angle size={iconSize} />}
              format={formatAngleValue}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        }
      />
    )
  },
  propsAreEqual
)
