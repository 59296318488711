import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'

import { PresetAnimationAppearanceRotationType } from '../../../models/preset'
import * as styles from './index.scss'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  type: PresetAnimationAppearanceRotationType
  size?: Point2D
}
export const Rotate: React.FCC<IProps> = ({ type, size = defaultSize }) => {
  if (type === PresetAnimationAppearanceRotationType.CounterClockwise) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.94765 3.5C10.4669 3.5 12.5003 5.51964 12.5003 8C12.5003 10.4804 10.4669 12.5 7.94765 12.5C6.2606 12.5 4.78938 11.5936 4.00263 10.2477L3.13931 10.7523C4.0998 12.3954 5.89432 13.5 7.94765 13.5C11.0093 13.5 13.5003 11.0425 13.5003 8C13.5003 4.95751 11.0093 2.5 7.94765 2.5C5.20438 2.5 2.9193 4.47293 2.47368 7.07184L1.91829 6.22564L1.08228 6.77436L2.47701 8.89936L2.77321 9.35064L3.20053 9.02081L5.30579 7.39581L4.69477 6.60419L3.41339 7.59326C3.62124 5.30258 5.56758 3.5 7.94765 3.5Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceRotationType.Clockwise) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.05263 3.5C5.53333 3.5 3.5 5.51964 3.5 8C3.5 10.4804 5.53333 12.5 8.05263 12.5C9.73968 12.5 11.2109 11.5936 11.9977 10.2477L12.861 10.7523C11.9005 12.3954 10.106 13.5 8.05263 13.5C4.99095 13.5 2.5 11.0425 2.5 8C2.5 4.95751 4.99095 2.5 8.05263 2.5C10.7959 2.5 13.081 4.47293 13.5266 7.07184L14.082 6.22564L14.918 6.77436L13.5233 8.89936L13.2271 9.35064L12.7998 9.02081L10.6945 7.39581L11.3055 6.60419L12.5869 7.59326C12.379 5.30258 10.4327 3.5 8.05263 3.5Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceRotationType.None) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C9.33875 2.5 10.5658 2.97831 11.5195 3.77337L3.77337 11.5195C2.97831 10.5658 2.5 9.33875 2.5 8ZM4.48047 12.2266C5.43422 13.0217 6.66125 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 6.66125 13.0217 5.43422 12.2266 4.48047L4.48047 12.2266ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  return null
}
