import * as React from 'react'

const sizeDefault = {
  x: 36,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const DiamondArrow: React.FCC<IProps> = ({
  size = sizeDefault,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 36 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.243 3.75699L1 7.99999L5.243 12.243L8.985 8.49999H35V7.49999H8.985L5.243 3.75699Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
