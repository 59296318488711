import { useMutation } from '@tanstack/react-query'

import {
  ApiPatchParams,
  ApiPatchRequestBody,
  ApiPatchResponse,
} from './helpers'
import { httpClient } from './http-client'

export function useSetTeam() {
  return useMutation({
    async mutationFn(
      payload: ApiPatchParams<'/teams/{teamId}'>['path'] &
        Omit<ApiPatchRequestBody<'/teams/{teamId}'>, 'userId'>
    ) {
      return httpClient.patch<ApiPatchResponse<'/teams/{teamId}'>>(
        `/teams/${payload.teamId}`,
        payload
      )
    },
  })
}
