import { lerpClamped } from '@aninix-inc/model/legacy'
import { LocalLogger, LogLevel } from '@aninix/logger'
import * as R from 'ramda'

import { yieldToMain } from '../../utils'
import { Encoder } from './types'
import { dithering } from './utils/dithering'
import { GIFEncoder, applyPalette, quantize } from './vendor/gifenc/index.js'

const logger = new LocalLogger({
  level: process.env.LOG_LEVEL as LogLevel,
  prefix: '[encodeGif]',
})
const transparentPixel = [0, 255, 0, 1]

function fillTransparentPixels(
  transparentPixel: number[],
  imageData: ImageData
): void {
  for (let i = 0; i < imageData.data.length; i += 4) {
    const alpha = imageData.data[i + 3]

    if (alpha === 0) {
      imageData.data[i] = transparentPixel[0]
      imageData.data[i + 1] = transparentPixel[1]
      imageData.data[i + 2] = transparentPixel[2]
      imageData.data[i + 3] = 1
    }
  }
}

/**
 * @description combine images to gif
 */
export const encodeGif: Encoder = ({
  iterable,
  options,
  onProgressUpdate,
  onError,
  onDone,
}) => {
  let encoder: any
  const { fps, framesCount, alpha, loop = true } = options
  let isCancelled = false
  const delay = 1000 / fps

  const render = async () => {
    try {
      encoder = GIFEncoder()

      const format = alpha ? 'rgba4444' : 'rgb565'
      const maxColors = 256

      let imagesData: ImageData[] = []
      let i = 0
      for await (const canvas of iterable) {
        if (isCancelled) {
          return
        }
        if (i % 20 === 0) {
          await yieldToMain()
        }

        const start = Date.now()
        const imageData = canvas
          .getContext('2d')!
          .getImageData(0, 0, canvas.width, canvas.height)
        fillTransparentPixels(transparentPixel, imageData)
        imagesData.push(imageData)
        onProgressUpdate(
          lerpClamped(i / framesCount, 0, 0.25),
          Date.now() - start
        )
        logger.log(
          `preparing data ${i}/${framesCount} time in ms`,
          Date.now() - start
        )

        i++
      }

      let palettes: number[][][] = []
      for (let i = 0; i < imagesData.length; i += 1) {
        const start = Date.now()

        if (isCancelled) {
          return
        }
        if (i % 20 === 0) {
          await yieldToMain()
        }

        const quantized = quantize(imagesData[i].data, maxColors, {
          format,
        }) as number[][]
        onProgressUpdate(
          lerpClamped(i / imagesData.length, 0.25, 0.5),
          Date.now() - start
        )
        palettes.push(quantized)
        logger.log(
          `quantizing ${i}/${imagesData.length} time in ms`,
          Date.now() - start
        )
      }

      const combinedPalette = palettes.reduce((acc, tempPalette) => {
        acc.push(...tempPalette)
        return acc
      }, [])

      // @NOTE: required for transparency
      let preparedPalette = []
      for (let i = 0; i < combinedPalette.length; i += 1) {
        if (isCancelled) {
          return
        }

        const [r, g, b] = combinedPalette[i]
        preparedPalette.push(r, g, b, 1)
        logger.log(`combining palette ${i}/${combinedPalette.length}`)
      }
      const finalPalette = quantize(
        Uint8ClampedArray.from(preparedPalette),
        maxColors,
        {
          format,
        }
      )

      // @NOTE: add transparent pixel for start
      const indexOfTransparentPixel = finalPalette.findIndex((item) =>
        R.equals(item, transparentPixel)
      )
      if (indexOfTransparentPixel !== -1) {
        finalPalette.splice(indexOfTransparentPixel, 1)
      } else if (finalPalette.length === maxColors) {
        finalPalette.shift()
      }
      finalPalette.unshift(transparentPixel)

      for (let i = 0; i < imagesData.length; i += 1) {
        const start = Date.now()

        if (isCancelled) {
          return
        }
        if (i % 20 === 0) {
          await yieldToMain()
        }

        dithering(finalPalette, imagesData[i].data, imagesData[i].width, format)
        onProgressUpdate(
          lerpClamped(i / imagesData.length, 0.5, 0.75),
          Date.now() - start
        )
        logger.log(
          `dithering ${i}/${imagesData.length} time in ms`,
          Date.now() - start
        )
      }

      for (let i = 0; i < imagesData.length; i += 1) {
        const start = Date.now()

        if (isCancelled) {
          return
        }

        const imageData = imagesData[i]
        const data = imageData.data

        encoder.writeFrame(
          applyPalette(data, finalPalette, format),
          imageData.width,
          imageData.height,
          {
            transparentIndex: 0x00,
            palette: i === 0 ? finalPalette : undefined,
            delay,
            transparent: alpha,
            repeat: loop ? 0 : -1,
          }
        )

        onProgressUpdate(
          lerpClamped(i / imagesData.length, 0.75, 1),
          Date.now() - start
        )
        logger.log(
          `encoding frames ${i}/${imagesData.length} time in ms`,
          Date.now() - start
        )
      }

      encoder.finish()
      const output = encoder.bytes()
      const blob = new Blob([output])
      onDone(blob)
    } catch (err: any) {
      onError(
        `Unexpected issue happen. Please send the following info to us: ${err.message}`
      )
    }
  }

  render()

  return () => {
    try {
      isCancelled = true
      encoder?.reset()
    } catch (err: any) {
      logger.warn('cancelation error', err)
    }
  }
}
