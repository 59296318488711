import { UserRole } from '@aninix/core'

import { useSessionStore } from '../stores'

export interface IProps {}

interface ICurrentUser {
  userId?: string
  teamId?: string
  role: UserRole
}

// @TODO: refactor.
// Should be removed completely. It was used before we started to move to stores.
// Now we can use stores directly, or via `const { session } = useStores()`
export const useCurrentUser = (): ICurrentUser => {
  const session = useSessionStore()
  return session
}
