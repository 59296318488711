import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const IndividualStrokes: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0H9V1H3V0ZM9 12H3V11H9V12ZM0 9V3H1V9H0ZM12 3V9H11V3H12Z"
      fill="var(--figma-color-text, rgb(0, 0, 0))"
    />
  </svg>
)
