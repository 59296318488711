import * as React from 'react'

const defaultSize = { x: 14, y: 13 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const VerticalTop: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 13L5.5 3H3.5L3.5 13H5.5ZM13.5 1V9.53674e-07L0.5 0V1L13.5 1ZM10.5 3V9H8.5V3H10.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
