import { Component, commitUndo } from '@aninix-inc/model'
import { useProject } from '@aninix/core'
import * as React from 'react'
import { useInfo } from '../..'
import { useValuePoint2d } from '../../../../../hooks/value-controllers/point-2d'
import { NumericValue } from '../numeric-value'

export interface IProps {
  component: Component
  color?: string
  formatValue?: (value: number) => string
}
export const Point2dValue: React.FC<IProps> = ({
  component,
  color = 'black',
  formatValue,
}) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time } = useInfo()
  const { x, y, updateX, updateY } = useValuePoint2d({
    components: [component],
    time,
  })

  React.useEffect(() => {
    setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)} className="flex">
      {isEditable ? (
        <Point2dValueEditable
          x={x as number}
          y={y as number}
          updateX={updateX}
          updateY={updateY}
          color={color}
          formatValue={formatValue}
        />
      ) : (
        <Point2dValueDisplay
          x={x as number}
          y={y as number}
          color={color}
          formatValue={formatValue}
        />
      )}
    </div>
  )
}

Point2dValue.displayName = 'Point2dValue'

const Point2dValueEditable: React.FC<{
  x: number
  y: number
  updateX: (newValue: number) => void
  updateY: (newValue: number) => void
  color?: string
  formatValue?: (value: number) => string
}> = ({ x, y, updateX, updateY, color, formatValue }) => {
  const project = useProject()

  const onEndChange = React.useCallback(() => {
    commitUndo(project)
  }, [])

  return (
    <>
      <NumericValue
        onEndChange={onEndChange}
        suffix=", "
        value={x as number}
        onChange={updateX}
        textColor={color}
        format={formatValue}
      />
      <NumericValue
        onEndChange={onEndChange}
        value={y as number}
        onChange={updateY}
        textColor={color}
        format={formatValue}
      />
    </>
  )
}

Point2dValueEditable.displayName = 'Point2dValueEditable'

const Point2dValueDisplay: React.FC<{
  x: number
  y: number
  color?: string
  formatValue?: (value: number) => string
}> = React.memo(
  ({ x, y, color, formatValue }) => {
    return (
      <>
        <NumericValue
          onEndChange={() => {}}
          suffix=", "
          value={x as number}
          onChange={() => {}}
          textColor={color}
          format={formatValue}
        />
        <NumericValue
          onEndChange={() => {}}
          value={y as number}
          onChange={() => {}}
          textColor={color}
          format={formatValue}
        />
      </>
    )
  },
  (prev, next) => {
    if (prev.x !== next.x) return false
    if (prev.y !== next.y) return false
    if (prev.color !== next.color) return false
    return true
  }
)

Point2dValueDisplay.displayName = 'Point2dValueDisplay'
