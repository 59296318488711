import { useGetSpaceBadge } from '@aninix/api'
import classNames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { IPaymentInfo } from '.'
import { Typography } from '../../components/design-system/typography'
import { useSessionStore } from '../../stores'
import { useUpgradeToPro } from '../../use-cases'
import { TeamItem } from '../side-menu/space-badge/team-item'
import {
  UserItem,
  UserItemPlaceholder,
} from '../side-menu/space-badge/user-item'
import { KeyHoleIcon } from './key-hole-icon'

interface IProps {
  updatePaymentInfo: (args: Partial<IPaymentInfo>) => void
  urlStatus: IPaymentInfo['status'] | null
}

export const List: React.FCC<IProps> = ({ updatePaymentInfo, urlStatus }) => {
  const sessionStore = useSessionStore()

  const upgradeToProUseCase = useUpgradeToPro({
    sessionStore,
    teamId: undefined,
  })

  const tokens = Object.values(sessionStore.tokens)

  const getSpaceBadge = useGetSpaceBadge({ tokens })

  const users = getSpaceBadge.data?.infoFromTokens.users

  const { isLoading } = getSpaceBadge

  const startPayment = React.useCallback(
    async (userId: string, _teamId: string | undefined) => {
      if (upgradeToProUseCase.state === 'success') {
        return
      }

      const successUrl =
        _teamId != null
          ? `/upgrade?status=success&teamId=${_teamId}`
          : '/upgrade?status=success'
      const errorUrl = `/upgrade?status=error`

      const result = await upgradeToProUseCase.startWithPayload({
        _teamId,
        userId,
        redirectUrlOnError: errorUrl,
        redirectUrlOnSuccess: successUrl,
      })

      if (result === null) return

      if (result == null) {
        return
      }

      window.location.assign(result.url)
      updatePaymentInfo({ link: result.url })
    },
    [upgradeToProUseCase, sessionStore]
  )

  React.useEffect(() => {
    if (
      users?.length === 1 &&
      users[0].teams.length === 0 &&
      upgradeToProUseCase.state === 'not-started' &&
      urlStatus === null &&
      users[0].plan === 'free'
    ) {
      startPayment(users[0].id, undefined)
    }
  }, [users])

  React.useEffect(() => {
    updatePaymentInfo({
      status: upgradeToProUseCase.state,
      onCancel: upgradeToProUseCase.cancel,
    })
  }, [upgradeToProUseCase.state, upgradeToProUseCase.cancel])

  return (
    <div
      className={classNames(
        'flex w-max flex-col gap-1  bg-white py-2 transition-all duration-200',
        {
          ['opacity-50 pointer-events-none']:
            upgradeToProUseCase.state === 'pending',
        }
      )}
    >
      <div className="min-h-[1px] w-full bg-gray-200" />

      {isLoading &&
        [1, 2, 3].map((i) => (
          <React.Fragment key={i}>
            <div
              className={classNames(
                'w-96 rounded-lg px-4 py-2 transition-all duration-150'
              )}
            >
              <UserItemPlaceholder />
            </div>
          </React.Fragment>
        ))}

      {users?.map((user, i) => {
        const { id, name, avatarUrl, plan, teams, email } = user

        return (
          <React.Fragment key={id}>
            <div className="flex flex-col py-1 ">
              <div className="line-clamp-1 px-4 py-2">
                <Typography
                  style="Subscript"
                  text={email ?? ''}
                  className="text-gray-400"
                />
              </div>
              {/* {plan === 'free' && ( */}
              <div
                className={classNames(
                  'rounded-lg px-4 py-2 transition-all duration-150',
                  {
                    ['hover:bg-gray-100']: plan === 'free',
                    ['pointer-events-none']: plan === 'pro',
                  }
                )}
                // @TODO: refactor: move logic to another place and make button instead of div
                /**
                 * Changing current active accout.
                 * 1. Set new id as active-account;
                 * 2. Reload page.
                 *
                 * Also replace localStorageIo with hook for currentUser and setCurrentUser for seamless experience on account change
                 * * Remove reload later
                 */
                onClick={() => {
                  sessionStore.updateUserId(user.id).then(() => {
                    updatePaymentInfo({ userId: user.id, teamId: null })
                    startPayment(user.id, undefined)
                  })
                }}
              >
                <UserItem
                  key={id}
                  id={id}
                  name={name}
                  avatarUrl={avatarUrl}
                  plan={'personal'}
                  isPro={plan === 'pro'}
                />
              </div>
              {/* )} */}
              {teams.map((team) => {
                const {
                  id: teamId,
                  avatarUrl,
                  name,
                  aninixRole,
                  adminRole,
                  plan: teamPlan,
                } = team

                return (
                  <div
                    key={teamId}
                    className={classNames(
                      'rounded-lg px-4 py-2 transition-all duration-150',
                      {
                        [' hover:bg-gray-100']: !(
                          adminRole === 'viewer' || teamPlan === 'pro'
                        ),
                        'pointer-events-none':
                          adminRole === 'viewer' || teamPlan === 'pro',
                      }
                    )}
                    // @TODO: refactor: move logic to another place and make button instead of div
                    /**
                     * Changing current active accout.
                     * 1. Set new id as active-account;
                     * 2. Reload page.
                     *
                     * Also replace localStorageIo with hook for currentUser and setCurrentUser for seamless experience on account change
                     * Remove reload later
                     */
                    onClick={() => {
                      sessionStore.updateUserId(user.id).then(() => {
                        updatePaymentInfo({ userId: user.id, teamId })
                        startPayment(user.id, teamId)
                      })
                    }}
                  >
                    <div className="relative flex flex-col gap-2">
                      <div
                        className={classNames('', {
                          ['opacity-30 saturate-0']: adminRole === 'viewer',
                        })}
                      >
                        <TeamItem
                          name={name}
                          avatarUrl={avatarUrl}
                          aninixRole={aninixRole}
                          isPro={teamPlan === 'pro'}
                        />
                      </div>
                      {adminRole === 'viewer' && (
                        <div className="w-full rounded-[20px] bg-gray-100 bg-opacity-50 p-1 pr-4">
                          <div className="flex flex-row gap-2">
                            <KeyHoleIcon />
                            <Typography
                              className="text-gray-400"
                              style="Subscript"
                              text={`Ask owner for permission to manage this team's plan`}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="min-h-[1px] w-full bg-gray-200" />
          </React.Fragment>
        )
      })}

      <div className="flex flex-col">
        <SpaceBadgeMenuItem
          label="Add another account"
          to="/login/new?redirectUrlOnSuccess=%2Fupgrade"
          Icon={
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C7.65192 17.1453 8.19547 17.3014 8.75 17.3951"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 12V18"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 15L12 15"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 8.3335C7.5 8.99654 7.76339 9.63242 8.23223 10.1013C8.70107 10.5701 9.33696 10.8335 10 10.8335C10.663 10.8335 11.2989 10.5701 11.7678 10.1013C12.2366 9.63242 12.5 8.99654 12.5 8.3335C12.5 7.67045 12.2366 7.03457 11.7678 6.56573C11.2989 6.09689 10.663 5.8335 10 5.8335C9.33696 5.8335 8.70107 6.09689 8.23223 6.56573C7.76339 7.03457 7.5 7.67045 7.5 8.3335Z"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.14001 15.7077C5.34627 15.0212 5.76832 14.4195 6.34355 13.9918C6.91878 13.5641 7.61655 13.3333 8.33335 13.3335L8.75004 13.3335"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
      </div>
    </div>
  )
}

List.displayName = 'List'

export interface ISpaceBadgeMenuItem {
  Icon: React.ReactNode
  to: string
  label: string
}
const SpaceBadgeMenuItem: React.FCC<ISpaceBadgeMenuItem> = ({
  Icon,
  to,
  label,
}) => (
  <Link
    to={to}
    className="flex flex-row gap-3 px-6 py-2 transition-all duration-150 hover:bg-gray-100"
  >
    {Icon}
    <Typography style="Body5Regular" className="text-gray-500" text={label} />
  </Link>
)

SpaceBadgeMenuItem.displayName = 'SpaceBadgeMenuItem'
