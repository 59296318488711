import { useCreateFolder, useCurrentFolder } from '@aninix/api'
import classNames from 'classnames'
import * as React from 'react'

import { Icons } from '../icons'

export interface IProps {
  showNewFolderInput: boolean
  setShowNewFolderInput: React.Dispatch<React.SetStateAction<boolean>>

  onCreateNewFolder: () => void
  locationFolderId: string | null
}
export const NewFolderInput: React.FCC<IProps> = ({
  showNewFolderInput,
  setShowNewFolderInput,
  onCreateNewFolder,
  locationFolderId,
}) => {
  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) =>
    event.target.select()

  const [loadingFolderName, setLoadingFolderName] =
    React.useState<string>('New folder')

  const { payload } = useCurrentFolder()

  const createFolder = useCreateFolder()

  const handleCreateFolder = React.useCallback(
    (
      e: React.FormEvent<HTMLFormElement> | React.FocusEvent<HTMLInputElement>
    ) => {
      e.preventDefault()

      const inputValue =
        e.currentTarget instanceof HTMLFormElement
          ? e.currentTarget.newFolderInput.value
          : e.currentTarget.value
      setLoadingFolderName(inputValue)

      createFolder
        .mutateAsync({
          teamId: payload?.teamId,
          parentFolderId: locationFolderId ?? undefined,
          name: inputValue,
        })
        .then(() => {
          onCreateNewFolder()
          setShowNewFolderInput(false)
        })
    },
    [locationFolderId, payload]
  )
  // const handleBlur = React.useCallback(
  //   (e: React.FocusEvent<HTMLInputElement, Element>) => {
  //     e.preventDefault()
  //     setShowNewFolderInput(false)
  //     onCreateNewFolder()
  //   },
  //   []
  // )

  return showNewFolderInput ? (
    <div
      className={classNames(
        'flex w-full flex-row items-center gap-2 px-4 py-2',
        { ['invisible hidden']: !showNewFolderInput }
      )}
    >
      <Icons.Folder />
      {createFolder.status === 'pending' ? (
        <p className=" font-body text-sm font-normal text-secondary">
          {loadingFolderName}
        </p>
      ) : (
        <form className="flex" onSubmit={handleCreateFolder}>
          <input
            name="newFolderInput"
            className="  font-body text-sm font-normal text-secondary"
            defaultValue={loadingFolderName}
            autoFocus={true}
            onFocus={handleFocus}
            onBlur={handleCreateFolder}
            minLength={1}
            required={true}
          />
        </form>
      )}
    </div>
  ) : null
}

NewFolderInput.displayName = 'NewFolderInput'
