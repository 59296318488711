// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Toastify__toast-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  pointer-events: none;
}

.Toastify__toast {
  width: -moz-fit-content;
  width: fit-content;
  height: 36px;
  min-height: auto;
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
  background: none;
  box-shadow: none;
  filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.2));
  pointer-events: auto;
}

.Toastify__toast-body {
  height: 36px;
  margin: 0;
  padding: 8px 12px;
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  background: rgba(48, 53, 59, 1);
  border-radius: 12px;
}
`, "",{"version":3,"sources":["webpack://./apps/web-app/src/modules/toasts/toast.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,SAAS;EACT,kBAAkB;EAClB,UAAU;EACV,gBAAgB;EAChB,gBAAgB;EAChB,mDAAmD;EACnD,oBAAoB;AACtB;;AAEA;EACE,YAAY;EACZ,SAAS;EACT,iBAAiB;EACjB,6BAA6B;EAC7B,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,+BAA+B;EAC/B,mBAAmB;AACrB","sourcesContent":[".Toastify__toast-container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  width: 100%;\n  pointer-events: none;\n}\n\n.Toastify__toast {\n  width: fit-content;\n  height: 36px;\n  min-height: auto;\n  margin: 0;\n  margin-bottom: 8px;\n  padding: 0;\n  background: none;\n  box-shadow: none;\n  filter: drop-shadow(0px 3px 7px rgba(0, 0, 0, 0.2));\n  pointer-events: auto;\n}\n\n.Toastify__toast-body {\n  height: 36px;\n  margin: 0;\n  padding: 8px 12px;\n  color: rgba(255, 255, 255, 1);\n  font-weight: 400;\n  font-size: 16px;\n  line-height: 23px;\n  background: rgba(48, 53, 59, 1);\n  border-radius: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
