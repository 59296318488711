import * as React from 'react'

import * as styles from './styles.scss'

export const LOGO_WIDTH = 66

export interface IProps {}
export const Logo: React.FCC<IProps> = () => (
  <div className={styles.logo} style={{ width: LOGO_WIDTH }}>
    Aninix
  </div>
)
