import {
  ChildrenRelationsAspect,
  EntityType,
  EntityTypeComponent,
  Project,
  SelectionSystem,
  TargetRelationAspect,
  commitUndo,
} from '@aninix-inc/model'
import { getSelection } from '@aninix/core'
import { IUiStore } from '../stores/ui'

export function clearAnimation({
  project,
  uiStore,
}: {
  project: Project
  uiStore: IUiStore
}): () => void {
  return () => {
    const selectedNodes = getSelection(project, EntityType.Node)
    let nodeIds: string[] = []
    let nodesStack = [...selectedNodes]
    while (nodesStack.length > 0) {
      const node = nodesStack.shift()!

      if (node.hasAspect(ChildrenRelationsAspect)) {
        nodesStack.push(
          ...node.getAspectOrThrow(ChildrenRelationsAspect).getChildrenList()
        )
      }

      nodeIds.push(node.id)
    }
    const keyframes = project.getEntitiesByPredicate((e) => {
      // @NOTE: check for nullability required in cases when there is a keyframe
      // which pointed to removed layer.
      const target = e.getAspect(TargetRelationAspect)?.getTargetEntity()

      if (target == null) {
        return false
      }

      return (
        e.getComponentOrThrow(EntityTypeComponent).value ===
          EntityType.Keyframe && nodeIds.includes(target.id)
      )
    })
    project
      .getSystemOrThrow(SelectionSystem)
      .deselect(keyframes.map((k) => k.id))
    keyframes.forEach((keyframe) => {
      project.removeEntity(keyframe.id)
    })
    commitUndo(project)
    uiStore.closeContextMenu()
  }
}
