import * as React from 'react'

const sizeDefault = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const SkewY: React.FCC<IProps> = ({ size = sizeDefault, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M3 7.67703L3 11.4534C3 12.1791 3.74862 12.6631 4.41037 12.3653L12.4104 8.76534C12.7692 8.60385 13 8.24693 13 7.85341L13 4.47703C13 3.76957 12.2855 3.28581 11.6286 3.54856L3.62861 6.74856C3.24895 6.90042 3 7.26813 3 7.67703Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
