import { round } from '@aninix-inc/model/legacy'
import * as R from 'ramda'

import {
  LottieBezier,
  LottieLayer,
  LottieLayerType,
  LottieShapeLayerType,
  LottieShapePath,
} from '../objects/types'

export function includesHorizontalLine(layer: LottieLayer): boolean {
  if (layer.ty !== LottieLayerType.Shape) {
    return false
  }

  const pathes = layer.shapes.filter(
    (shape) => shape.ty === LottieShapeLayerType.Path
  ) as LottieShapePath[]

  return R.any((path) => {
    const isAnimated = path.ks.a === 1
    const points = path.ks.k

    if (isAnimated) {
      return false
    }

    const pointsToWork = points as LottieBezier

    if (pointsToWork.v.length !== 2) {
      return false
    }

    const leftY = pointsToWork.v[0][1]
    const rightY = pointsToWork.v[1][1]

    return round(leftY, { fixed: 0 }) === round(rightY, { fixed: 0 })
  }, pathes)
}
