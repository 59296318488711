import { IconButton as IconButtonSource } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'

import { Tooltip } from '../../tooltip'
import * as styles from './index.scss'

export interface IProps {
  tooltip?: React.ReactNode
  btnSize?: {
    width: number
    height: number
  }
  active?: boolean
  contrast?: boolean
  disabled?: boolean
  // @NOTE: add bluish color
  selected?: boolean
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

const defaultBtnSize = {
  width: 30,
  height: 30,
}

export const IconButton: React.FCC<IProps> = ({
  btnSize = defaultBtnSize,
  active = false,
  contrast = false,
  tooltip = '',
  selected = false,
  children,
  disabled,
  onClick,
  className,
}) => {
  const inlineStyles = React.useMemo(
    () => ({
      width: `${btnSize.width}px`,
      height: `${btnSize.height}px`,
    }),
    [btnSize.width, btnSize.height]
  )

  if (tooltip === '') {
    return (
      <IconButtonSource
        disabled={disabled}
        onClick={onClick}
        classes={{
          root: classnames(styles.container, {
            [styles['container--active']]: active,
            [styles['container--contrast']]: contrast,
            [styles['container--selected']]: selected,
          }),
        }}
        style={inlineStyles}
        className={className}
      >
        {children}

        <div className={styles['container--with-border']} />
      </IconButtonSource>
    )
  }

  return (
    <Tooltip title={<>{tooltip}</>} arrow>
      <IconButtonSource
        disabled={disabled}
        onClick={onClick}
        classes={{
          root: classnames(styles.container, {
            [styles['container--active']]: active,
            [styles['container--contrast']]: contrast,
            [styles['container--selected']]: selected,
          }),
        }}
        style={inlineStyles}
        className={className}
      >
        {children}

        <div className={styles['container--with-border']} />
      </IconButtonSource>
    </Tooltip>
  )
}
