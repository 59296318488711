import type { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'

import * as styles from './index.scss'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  state?: 'normal' | 'loading' | 'error'
  size?: Point2D
  style?: React.CSSProperties
}
export const Sync: React.FCC<IProps> = ({
  state = 'normal',
  size = defaultSize,
  style,
}) => {
  const helper = React.useCallback(() => {
    if (state === 'loading') {
      return (
        <path
          d="M16.5 24C17.8807 24 19 22.8807 19 21.5C19 20.1193 17.8807 19 16.5 19C15.1193 19 14 20.1193 14 21.5"
          stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
          className={styles.loading}
        />
      )
    }

    if (state === 'error') {
      return (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.5 21.2071L19.1465 23.8536L19.8536 23.1465L17.2071 20.5L19.8536 17.8536L19.1465 17.1465L16.5 19.7929L13.8536 17.1465L13.1465 17.8536L15.7929 20.5L13.1465 23.1465L13.8536 23.8536L16.5 21.2071Z"
          fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
        />
      )
    }

    return (
      <path
        d="M17.0004 24V17M17.0004 17L14.5 19.5M17.0004 17L19.5 19.5"
        stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    )
  }, [state])

  return (
    <svg
      width={size.x}
      height={size.y}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M21.5 20.5C23 20.5 23.8222 19.7934 24.3773 19.0399C24.9323 18.2864 25.1876 17.3536 25.0935 16.4225C24.9995 15.4914 24.5628 14.6284 23.8683 14.0011C23.1738 13.3739 22.271 13.027 21.3351 13.028H20.3841C20.1341 12.0599 19.6475 11.169 18.9681 10.4354C18.2888 9.70179 17.4378 9.14837 16.4917 8.82483C15.5457 8.5013 14.534 8.41777 13.5477 8.58176C12.5614 8.74575 11.6312 9.15212 10.8407 9.76438C10.0502 10.3766 9.42413 11.1756 9.01868 12.0896C8.61324 13.0036 8.44112 14.004 8.51777 15.0009C8.59443 15.9978 8.91746 16.9601 9.45786 17.8014C9.99827 18.6426 10.7391 19.3365 11.6139 19.8207"
        stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />

      {helper()}
    </svg>
  )
}
