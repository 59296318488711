import * as React from 'react'

export function getKeysFromKeyboardEvent(
  e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
) {
  const isKeyDown = e.key.toLowerCase() === 'arrowdown'
  const isKeyUp = e.key.toLowerCase() === 'arrowup'
  const isShiftPressed = e.shiftKey
  const isCtrlPressed = e.ctrlKey || e.metaKey
  const isEsc = e.key.toLowerCase() === 'escape'
  const isEnter = e.key.toLocaleLowerCase() === 'enter'
  return { isKeyDown, isKeyUp, isShiftPressed, isCtrlPressed, isEnter, isEsc }
}
