import {
  Component,
  getAnimatableValue,
  mixed,
  setValuePoint2d,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/legacy'
import { useComponents } from '@aninix/core'
import * as R from 'ramda'

/**
 * Encapsulate logic to retrieve and update value
 */
export function useValuePoint2d({
  components,
  time,
}: {
  components: Component[]
  time?: number
}): {
  x: number | typeof mixed
  y: number | typeof mixed
  updateX: (newValue: number) => void
  updateXByDelta: (delta: number) => void
  updateY: (newValue: number) => void
  updateYByDelta: (delta: number) => void
} {
  useComponents(components)

  const xEquals = R.all(
    (component) =>
      (getAnimatableValue(component, time) as Point2D).x ===
      (getAnimatableValue(components[0], time) as Point2D).x,
    components
  )
  const yEquals = R.all(
    (component) =>
      (getAnimatableValue(component, time) as Point2D).y ===
      (getAnimatableValue(components[0], time) as Point2D).y,
    components
  )

  const updateX = (newValue: number) => {
    components.forEach((component) => {
      setValuePoint2d(
        component,
        (value: Point2D) => ({
          x: newValue,
          y: value.y,
        }),
        time
      )
    })
  }

  const updateY = (newValue: number) => {
    components.forEach((component) => {
      setValuePoint2d(
        component,
        (value: Point2D) => ({
          x: value.x,
          y: newValue,
        }),
        time
      )
    })
  }

  const updateXByDelta = (delta: number) => {
    components.forEach((component) => {
      setValuePoint2d(
        component,
        (value: Point2D) => ({
          x: value.x + delta,
          y: value.y,
        }),
        time
      )
    })
  }

  const updateYByDelta = (delta: number) => {
    components.forEach((component) => {
      setValuePoint2d(
        component,
        (value: Point2D) => ({
          x: value.x,
          y: value.y + delta,
        }),
        time
      )
    })
  }

  return {
    x: xEquals ? (getAnimatableValue(components[0], time) as Point2D).x : mixed,
    y: yEquals ? (getAnimatableValue(components[0], time) as Point2D).y : mixed,
    updateX,
    updateXByDelta,
    updateY,
    updateYByDelta,
  }
}
