import { httpClient } from '@aninix/api'
import { config } from '@aninix/api/config'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'

export interface ILottieUploadResponse {
  id: string
  userId: string
  createdAt: string
  updatedAt: string
  expiredAt: string
}

export interface ILottieUpload {
  query: UseMutationResult<
    AxiosResponse<ILottieUploadResponse, any>,
    unknown,
    File,
    unknown
  >
}

export const useUploadLottie = (): ILottieUpload => {
  const query = useMutation({
    mutationKey: ['uploadLottie'],
    mutationFn: (file: File) => {
      const formData = new FormData()
      formData.append('file', file)

      return httpClient.post(`${config.apiUrl}/lotties`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
    },
  })

  return { query }
}
