/* eslint-disable react/require-default-props */
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import React from 'react'

import * as styles from './index.scss'

type TButton = {
  id: string
  icon: React.ReactNode
  onClick: (id: string) => void
}

interface IGroupButtonProps {
  buttons: TButton[]
  selectedId: string
  disabled?: boolean
}

const GroupButton = ({ buttons, selectedId, disabled }: IGroupButtonProps) => (
  <>
    <ButtonGroup
      disabled={disabled}
      classes={{
        disabled: styles.disabled,
        root: styles.root,
        grouped: styles.grouped,
      }}
    >
      {buttons.map((button) => (
        <Button
          key={button.id}
          onClick={() => button.onClick(button.id)}
          classes={{
            root: selectedId === button.id ? styles.selected : styles.default,
          }}
        >
          {button.icon}
        </Button>
      ))}
    </ButtonGroup>
  </>
)

export default GroupButton
