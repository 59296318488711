import { useThrottle } from '@aninix/app-design-system'
import * as React from 'react'

// @NOTE: send keypress with timeouts
export function useTrackKeyPress(callback: (hotkey: string) => void) {
  const trackKeyPress = useThrottle({
    callback: React.useCallback(callback, [callback]),
    delay: 5000,
  })
  return trackKeyPress
}
