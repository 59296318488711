import Card from '@material-ui/core/Card'
import React, { useState } from 'react'

import { SpringCurveComponent, TimingCurveComponent } from '@aninix-inc/model'
import RegularButton from '../../../buttons/regular-button'
import { Close } from '../../../icons/Close'
import { Graph } from '../../../icons/Graph'
import { Input } from '../../../input'
import Header from '../Header'
import * as styles from './index.scss'

export interface IProps {
  graphValue: TimingCurveComponent | SpringCurveComponent
  onClose: () => void
  onAddUserPreset: (graphName: string) => void
}

export const SaveUserPreset: React.FCC<IProps> = ({
  graphValue,
  onClose,
  onAddUserPreset,
}) => {
  const nameInputRef = React.useRef<HTMLInputElement>(null)
  const [inputName, setInputName] = useState('Graph name')

  const handleAddUserPreset = () => {
    onAddUserPreset(inputName)
  }

  React.useLayoutEffect(() => {
    const input = nameInputRef.current
    // NOTE: to fix issue with auto selection
    setTimeout(() => {
      input?.focus()
      input?.select()
    }, 50)
  }, [])

  return (
    <Card classes={{ root: styles.card }}>
      <Header title="Save custom graph" icon={<Close />} onClick={onClose} />

      <div className={styles.content}>
        <div className={styles.input}>
          <div className={styles.icon}>
            <Graph previews={graphValue.getCache()} variant="dashed" />
          </div>

          <Input
            value={inputName}
            onChange={(value) => setInputName(value)}
            ref={nameInputRef}
            onEscPress={onClose}
            onEnterPress={handleAddUserPreset}
          />
        </div>

        <div className={styles.buttons}>
          <div className={styles['left-button']}>
            <RegularButton
              variant="outlined"
              title="Cancel"
              onClick={onClose}
            />
          </div>

          <RegularButton
            variant="contained"
            title="Create graph style"
            onClick={handleAddUserPreset}
          />
        </div>
      </div>
    </Card>
  )
}
