import { useQuery } from '@tanstack/react-query'
import { ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetTeamContracts(teamId: string) {
  return useQuery({
    queryKey: ['contracts'],
    async queryFn() {
      return httpClient
        .get<
          ApiGetResponse<'/teams/{teamId}/contracts'>
        >(`/teams/${teamId}/contracts`)
        .then((response) => response.data)
    },
  })
}
