import { icons } from '@aninix/app-design-system'
import * as React from 'react'

import * as styles from './view.scss'

export interface IProps {
  zoom: string
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
export const ViewportZoomView: React.FCC<IProps> = ({ zoom, onClick }) => (
  <button type="button" onClick={onClick} className={styles['zoom-button']}>
    {zoom}
    <icons.Expand
      style={{
        // @ts-ignore
        '--figma-color-text': 'rgba(255, 255, 255, 0.9)',
      }}
    />
  </button>
)
