import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Out: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 12L12.9991 4L6.2246 4L2.66908 7.99996L6.22554 12H13ZM13.5001 13H6.00104H5.77655L5.62738 12.8322L1.6264 8.33223L1.33105 8.00004L1.62636 7.66782L5.62636 3.16782L5.77554 3H6.00007L13.4991 3H13.999L13.9991 3.49995L14.0001 12.4999L14.0001 13H13.5001ZM9.35469 5.64551L11.3537 7.64562L11.707 7.99908L11.3537 8.35254L9.35468 10.3525L8.64741 9.64561L9.79295 8.4995H5.50202V7.4995H9.79385L8.6474 6.35242L9.35469 5.64551Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
