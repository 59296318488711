import * as React from 'react'

import { IIcon } from '.'

const defaultSize = { x: 20, y: 20 }

export const MoveTo: React.FCC<IIcon> = ({
  size = defaultSize,
  className = ' stroke-gray-400',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 20 20`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 5H10L7 3H4C2.89543 3 2 3.89543 2 5V15C2 16.1046 2.89543 17 4 17H18M18 11H11M18 11L16 9M18 11L16 13"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
