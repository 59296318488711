import { mixed } from '@aninix-inc/model/legacy'
import { Input, ListSubheader } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import MaterialSelect from '@material-ui/core/Select'
import classnames from 'classnames'
import React from 'react'

import { Expand as ExpandIcon } from '../icons/Expand'
import * as styles from './index.scss'

const iconSize = {
  x: 8,
  y: 8,
}

export type Option = {
  id: string
  title: string
  icon?: React.ReactNode
  disabled?: boolean
  rightPart?: React.ReactNode
  activeElement?: React.ReactNode
}

export type Divider = 'divider'

export type OptionTitle = string

export interface IProps {
  activeValueId: string | typeof mixed
  options: (Option | Divider | OptionTitle)[]
  onChange: (value: string) => void
  disabled?: boolean
  withBorder?: boolean
  className?: string
  rootClassName?: string
  /**
   * @description icon which would be visible in select
   */
  activeIcon?: React.ReactNode
}
export const Select: React.FCC<IProps> = ({
  activeValueId,
  options: providedOptions,
  onChange,
  disabled = false,
  withBorder = false,
  className,
  rootClassName,
  activeIcon = null,
}) => {
  const selectRef = React.useRef()

  const options = React.useMemo(() => {
    if (activeValueId === mixed) {
      return [
        {
          id: mixed,
          title: mixed,
        },
        ...providedOptions,
      ]
    }

    return providedOptions
  }, [activeValueId, providedOptions])

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<{ value: unknown }>) => {
      onChange(e.target.value as string)
    },
    [onChange]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <MaterialSelect
        MenuProps={{
          classes: {
            paper: styles['dropdown-style'],
          },
        }}
        value={activeValueId}
        onChange={handleChange}
        classes={{
          root: classnames(styles['select-root'], {
            [styles.border]: withBorder,
          }),
          disabled: styles['select-root--disabled'],
        }}
        input={
          <Input
            classes={{
              root: classnames(styles['input-root'], rootClassName),
              input: styles.input,
            }}
            startAdornment={activeIcon}
          />
        }
        disabled={disabled}
        IconComponent={() => (
          <div className={styles['icon-component']}>
            <ExpandIcon size={iconSize} />
          </div>
        )}
        renderValue={(selected) => {
          const option = options.find(
            (o) => o !== 'divider' && typeof o !== 'string' && selected === o.id
          ) as Option

          if (option === undefined) {
            throw new Error(`You forgot provide option for "${selected}" value`)
          }

          if (option.activeElement != null) {
            return option.activeElement
          }

          return (
            <div className={styles['option-content']}>
              {option.icon && (
                <div className={styles['option-icon']}>{option.icon}</div>
              )}

              <div className={styles['option-title']}>{option.title}</div>

              {option.rightPart && (
                <div className={styles['option-right-part']}>
                  {option.rightPart}
                </div>
              )}
            </div>
          )
        }}
      >
        {options.map((option, idx) => {
          if (option === 'divider') {
            return (
              <MenuItem
                classes={{
                  root: classnames(
                    styles['menu-item-root'],
                    styles['menu-item-root--no-padding']
                  ),
                  selected: styles['selected-option'],
                }}
                key={`divider-${idx}`}
                value={`divider-${idx}`}
                disabled
              >
                <div className={styles.divider} />
              </MenuItem>
            )
          }

          if (typeof option === 'string') {
            return (
              <ListSubheader
                key={`${option}-${idx}`}
                disableSticky
                className="mb-1 h-5 p-0 pl-3 text-[11px] font-semibold leading-5 text-white"
              >
                {option}
              </ListSubheader>
            )
          }

          return (
            <MenuItem
              classes={{
                root: classnames(styles['menu-item-root']),
                selected: styles['selected-option'],
              }}
              key={option.id}
              value={option.id}
              disabled={option.disabled || option.id === mixed}
            >
              <div className={styles['option-content']}>
                {option.icon && (
                  <div className={styles['option-icon']}>{option.icon}</div>
                )}

                <div className={styles['option-title']}>{option.title}</div>

                {option.rightPart && (
                  <div className={styles['option-right-part']}>
                    {option.rightPart}
                  </div>
                )}
              </div>
            </MenuItem>
          )
        })}
      </MaterialSelect>
    </div>
  )
}
