import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const StrokeBottomWeight: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.5V8H11V1.5C11 1.224 10.776 1 10.5 1H1.5C1.224 1 1 1.224 1 1.5V8H0V1.5C0 0.672 0.672 0 1.5 0H10.5C11.328 0 12 0.672 12 1.5Z"
      fill="var(--figma-color-text, rgb(0, 0, 0))"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12H0V11H12V12Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 1))"
    />
  </svg>
)
