import * as React from 'react'

import { IFpsStatsInteractor } from './interactor'
import * as styles from './router.scss'

export interface IProps {
  interactor: IFpsStatsInteractor
}
export const FpsStatsRouter: React.FCC<IProps> = ({ interactor }) => (
  <div className={styles.container}>
    <p>{interactor.fps} FPS</p>
  </div>
)
