import * as React from 'react'
import { Link } from 'react-router-dom'

import { AccessRestrictedIcon } from './icon'

export interface IProps {
  projectId: string
}
export const EditingDisabled: React.FC<IProps> = ({ projectId }) => (
  <div className="mx-auto flex h-screen w-screen max-w-md flex-col items-center justify-center text-center text-black/80">
    <AccessRestrictedIcon className="w-[258px]" />

    <p className="mt-6 text-2xl font-semibold">File is private</p>

    <p className="mt-2 text-base">
      Only owner of the file can edit. You can view only.
    </p>

    <Link
      to={`/inspect/${projectId}/preview`}
      className="mt-6 rounded-lg bg-[#0B1118] px-4 py-2 text-base text-white"
    >
      Preview
    </Link>
  </div>
)
