import { useCurrentFolder, useGetFolders } from '@aninix/api'
import { Backdrop, Modal } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

import { Typography } from '../design-system/typography'
import { Icons } from '../icons'
import { LoadableChildrenWrapper } from '../loadable-wrapper'
import { CreateNewFolder } from './create-new-folder'
import { MoveToBreadcrumbs } from './move-to-breadcrumbs'
import { NewFolderInput } from './move-to-new-folder-input'

export interface IProps {
  handleClose: () => void
  isOpen: boolean
  handleMoveTo: (folderId?: string) => void
}
export const MoveTo: React.FCC<IProps> = ({
  handleClose,
  isOpen,
  handleMoveTo,
}: IProps) => {
  const { payload } = useCurrentFolder()

  const [locationFolderId, setLocationFolderId] = React.useState(
    payload?.folderId
  )

  const folders = useGetFolders({ ...payload, folderId: locationFolderId })

  const [selectedFolderId, setSelectedFolderId] = React.useState(
    payload?.folderId
  )

  React.useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'

    if (!isOpen) {
      return
    }

    setSelectedFolderId(payload?.folderId)
    setLocationFolderId(payload?.folderId)
  }, [isOpen])

  const foldersMap = folders.data?.data?.map((f) => {
    return {
      ...f,
      selectFolder: () => setSelectedFolderId(f.id),
      selectLocationFolderId: () => setLocationFolderId(f.id),
    }
  })

  const [showNewFolderInput, setShowNewFolderInput] =
    React.useState<boolean>(false)

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      className="flex h-full w-full flex-col items-center justify-center"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <div
        className={classNames('min-w-[400px] rounded-xl bg-white shadow-2xl')}
      >
        <div className="flex w-full flex-col gap-4 p-6 ">
          <div className="flex w-full flex-row items-center justify-between">
            <p className=" font-header text-2xl font-medium text-black text-opacity-80">
              Select folder
            </p>
            <div
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleClose()
              }}
            >
              <Icons.Cross className="h-8 w-8" />
            </div>
          </div>

          <MoveToBreadcrumbs
            folderId={locationFolderId}
            setLocationFolderId={setLocationFolderId}
            setSelectedFolderId={setSelectedFolderId}
          />
          <div className="relative h-[250px] w-full overflow-clip rounded-lg border-[1px] border-gray-200 ">
            <div
              className={classNames(
                ' absolute bottom-0 left-0 right-0 top-[calc(100%-2rem)] z-[50] flex select-none flex-col justify-center  bg-gray-100 bg-opacity-80 transition-all duration-200',
                {
                  ['invisible opacity-0']:
                    selectedFolderId !== locationFolderId || //opened is not selected
                    payload?.folderId === selectedFolderId, //selected is the same where asset is
                }
              )}
            >
              <Typography
                style="Subscript"
                className=" w-full text-center text-gray-400"
              >
                Move to this folder
              </Typography>
            </div>
            <div
              className={classNames(
                'absolute flex h-full w-full flex-row gap-4 overflow-auto transition-all duration-200',
                {
                  ['shadow-xs bg-gray-50 bg-opacity-50']:
                    selectedFolderId === locationFolderId && // opened is the same as selected
                    payload?.folderId !== selectedFolderId, //selected is not the same where asset is
                }
              )}
            >
              <div className="relative flex w-full flex-col items-start py-0.5">
                <LoadableChildrenWrapper
                  isLoading={folders.isLoading}
                  loadingComponent={
                    <>
                      {[1, 2, 3].map((e) => (
                        <div
                          key={e.toString()}
                          className={classNames(
                            'flex w-full flex-row items-center gap-2 px-4 py-2'
                          )}
                        >
                          <Icons.Folder />
                          <div className=" h-[14px] w-12 animate-pulse rounded-sm bg-gray-300" />
                        </div>
                      ))}
                    </>
                  }
                  isError={folders.isError}
                  errorComponent={
                    <div className="flex h-full w-full flex-row items-center justify-center gap-1">
                      <Typography
                        style="Body5Regular"
                        className=" text-gray-400"
                        alignment="center"
                      >
                        Couldn't load folders. Try again later
                      </Typography>
                    </div>
                  }
                >
                  {foldersMap?.map((f, i) => (
                    <button
                      key={f.id}
                      onClick={f.selectFolder}
                      onDoubleClick={f.selectLocationFolderId}
                      className={classNames(
                        'flex w-full flex-row items-center gap-2 px-4 py-2',
                        {
                          ['bg-gray-100']: selectedFolderId === f.id,
                        }
                      )}
                    >
                      <Icons.Folder />
                      <p className=" font-body text-sm font-normal text-secondary">
                        {f.name}
                      </p>
                    </button>
                  ))}

                  {folders.isFetched && foldersMap?.length === 0 && (
                    <div
                      className={classNames(
                        'absolute flex h-full w-full flex-row items-center justify-center gap-1 transition-all delay-100 duration-300'
                      )}
                    >
                      <Typography
                        style="Body5Regular"
                        className=" text-gray-400"
                        alignment="center"
                      >
                        No folders here. You can create one with
                      </Typography>
                      <CreateNewFolder
                        className="h-7 w-7"
                        handleClick={() => setShowNewFolderInput(true)}
                      />
                    </div>
                  )}

                  <NewFolderInput
                    showNewFolderInput={showNewFolderInput}
                    setShowNewFolderInput={setShowNewFolderInput}
                    onCreateNewFolder={folders.refetch}
                    locationFolderId={locationFolderId ?? null}
                  />
                </LoadableChildrenWrapper>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-between">
            <CreateNewFolder handleClick={() => setShowNewFolderInput(true)} />

            <div className="flex w-full flex-row justify-end gap-2">
              <button
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleClose()
                }}
                className="flex h-10 flex-row items-center gap-2 rounded-lg border-[1px] border-gray-200 px-3 py-2"
              >
                <p className="font-body text-sm font-medium text-gray-500">
                  Cancel
                </p>
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  handleMoveTo(selectedFolderId)
                }}
                className={classNames(
                  'flex h-10 w-max flex-row items-center  rounded-lg bg-gray-300 text-sm transition-all duration-500',
                  {
                    [' hover:scale-102 !bg-secondary hover:shadow-md']:
                      payload?.folderId !== selectedFolderId,
                  }
                )}
              >
                <p className="px-4 text-white">Move to</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}
