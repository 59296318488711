import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Code: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.35363 4.35359L1.70718 8.00004L5.35363 11.6465L4.64652 12.3536L0.646522 8.35359L0.292969 8.00004L0.646522 7.64649L4.64652 3.64648L5.35363 4.35359ZM10.6465 11.6465L14.293 8.00004L10.6465 4.35359L11.3536 3.64648L15.3536 7.64648L15.7072 8.00004L15.3536 8.35359L11.3536 12.3536L10.6465 11.6465Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
