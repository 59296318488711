// @ts-nocheck
import dPathParser from 'd-path-parser'

import { StrokeAlign } from '@aninix-inc/model/legacy'
import { paper } from '@aninix-inc/renderer'
import * as PIXI from 'pixi.js'
import * as Tess2 from 'tess2-ts'
import { getArrowHeadsData } from '../../common/renderers/get-arrow-heads-data'
import { NodeSnapshot } from '../../common/renderers/types'
import { Mappers } from '../mappers'

PIXI.Graphics.curves.adaptive = false

const triangulate =
  (windingRule: VectorPath['windingRule']) =>
  (
    graphicsData: PIXI.GraphicsData,
    graphicsGeometry: PIXI.GraphicsGeometry
  ) => {
    // return
    let points = graphicsData.points
    const holes = graphicsData.holes
    const verts = graphicsGeometry.points
    const indices = graphicsGeometry.indices

    if (points.length >= 6) {
      const holeArray = []
      // Comming soon
      for (let i = 0; i < holes.length; i++) {
        const hole = holes[i]

        holeArray.push(points.length / 2)
        points = points.concat(hole.points)
      }

      const findRes = PIXI.Cache.get(points.join('_') + windingRule) as
        | Tess2.IResult
        | undefined

      // Tesselate
      const res =
        findRes ??
        Tess2.tesselate({
          contours: [points],
          windingRule: Mappers.mapWindingRule(windingRule),
          elementType: Tess2.POLYGONS,
          polySize: 3,
          vertexSize: 2,
        })

      if (findRes === undefined)
        PIXI.Cache.set(points.join('_') + windingRule, res)

      if (!res?.elements.length) {
        return
      }

      const vrt = res.vertices
      const elm = res.elements.flat()

      const vertPos = verts.length / 2

      for (var i = 0; i < elm.length; i++) {
        indices.push(elm[i] + vertPos)
      }

      for (let i = 0; i < vrt.length; i++) {
        verts.push(vrt[i])
      }
    }
  }

export class TessGraphics extends PIXI.Graphics {
  private windingRule: VectorPath['windingRule']

  constructor(windingRule: VectorPath['windingRule']) {
    super()
    this.windingRule = windingRule
  }

  render(r: any) {
    PIXI.graphicsUtils.buildPoly.triangulate = triangulate(this.windingRule)
    super.render(r)
  }
}

export function drawVectorPath(
  node: NodeSnapshot,
  graphics: PIXI.Graphics,
  _vectorPath: VectorPath['data'],
  isFill: boolean = true
) {
  const hasMultipleMoves = (_vectorPath.match(/[mM]/g) || []).length >= 2

  let vectorPath: string

  if (hasMultipleMoves) {
    var path = new paper.CompoundPath(_vectorPath)
    path.fillRule = node.data[0].windingRule.toLowerCase()
    let emptyPath = new paper.Path('')
    path = path.unite(emptyPath) as paper.CompoundPath

    vectorPath = path.pathData
  } else {
    vectorPath = _vectorPath
  }

  // Current point
  let x = 0
  let y = 0

  let startX = 0,
    startY = 0

  let finishPoints = []

  const commands = dPathParser(vectorPath)

  for (let i = 0, j = commands.length; i < j; i++) {
    const lastCommand = commands[i - 1]
    const command = commands[i]

    if (isNaN(x) || isNaN(y)) {
      throw new Error('Data corruption')
    }

    // Taken from: https://github.com/bigtimebuddy/pixi-svg/blob/main/src/SVG.js
    // Copyright Matt Karl
    switch (command.code) {
      case 'm': {
        if (i === 0) {
          graphics.moveTo((x += command.end.x), (y += command.end.y))
        } else graphics.lineTo((x += command.end.x), (y += command.end.y))
        startX = x
        startY = y

        break
      }
      case 'M': {
        if (i === 0) graphics.moveTo((x = command.end.x), (y = command.end.y))
        else graphics.lineTo((x = command.end.x), (y = command.end.y))
        startX = x
        startY = y
        break
      }
      case 'H': {
        graphics.lineTo((x = command.value), y)
        break
      }
      case 'h': {
        graphics.lineTo((x += command.value), y)
        break
      }
      case 'V': {
        graphics.lineTo(x, (y = command.value))
        break
      }
      case 'v': {
        graphics.lineTo(x, (y += command.value))
        break
      }
      case 'z':
      case 'Z': {
        if (isFill) {
          graphics.lineTo(startX, startY)
        }
        finishPoints.push({ x: startX, y: startY })

        break
      }
      case 'L': {
        graphics.lineTo((x = command.end.x), (y = command.end.y))
        break
      }
      case 'l': {
        graphics.lineTo((x += command.end.x), (y += command.end.y))
        break
      }
      case 'C': {
        graphics.bezierCurveTo(
          command.cp1.x,
          command.cp1.y,
          command.cp2.x,
          command.cp2.y,
          (x = command.end.x),
          (y = command.end.y)
        )
        break
      }
      case 'c': {
        const currX = x
        const currY = y

        graphics.bezierCurveTo(
          currX + command.cp1.x,
          currY + command.cp1.y,
          currX + command.cp2.x,
          currY + command.cp2.y,
          (x += command.end.x),
          (y += command.end.y)
        )
        break
      }
      case 's':
      case 'S': {
        const cp1 = { x, y }
        const lastCode = commands[i - 1] ? commands[i - 1].code : null

        if (
          i > 0 &&
          (lastCode === 's' ||
            lastCode === 'S' ||
            lastCode === 'c' ||
            lastCode === 'C')
        ) {
          const lastCommand = commands[i - 1]
          const lastCp2 = { ...(lastCommand.cp2 || lastCommand.cp) }

          if (commands[i - 1].relative) {
            lastCp2.x += x - lastCommand.end.x
            lastCp2.y += y - lastCommand.end.y
          }

          cp1.x = 2 * x - lastCp2.x
          cp1.y = 2 * y - lastCp2.y
        }

        const cp2 = { x: command.cp.x, y: command.cp.y }

        if (command.relative) {
          cp2.x += x
          cp2.y += y

          x += command.end.x
          y += command.end.y
        } else {
          x = command.end.x
          y = command.end.y
        }

        graphics.bezierCurveTo(cp1.x, cp1.y, cp2.x, cp2.y, x, y)

        break
      }
      case 'q': {
        const currX = x
        const currY = y

        graphics.quadraticCurveTo(
          currX + command.cp.x,
          currY + command.cp.y,
          (x += command.end.x),
          (y += command.end.y)
        )
        break
      }
      case 'Q': {
        graphics.quadraticCurveTo(
          command.cp.x,
          command.cp.y,
          (x = command.end.x),
          (y = command.end.y)
        )
        break
      }
      case 't':
      case 'T': {
        let cx: number
        let cy: number

        if (lastCommand && lastCommand.cp) {
          let lcx = lastCommand.cp.x
          let lcy = lastCommand.cp.y

          if (lastCommand.relative) {
            const lx = x - lastCommand.end.x
            const ly = y - lastCommand.end.y

            lcx += lx
            lcy += ly
          }

          cx = 2 * x - lcx
          cy = 2 * y - lcy
        } else {
          cx = x
          cy = y
        }

        if (command.code === 't') {
          graphics.quadraticCurveTo(
            cx,
            cy,
            (x += command.end.x),
            (y += command.end.y)
          )
        } else {
          graphics.quadraticCurveTo(
            cx,
            cy,
            (x = command.end.x),
            (y = command.end.y)
          )
        }
        break
      }

      default:
        console.warn(`Command ${command} is not supported`)
    }
  }

  if (isFill) {
    finishPoints.reverse().forEach((p) => {
      graphics.lineTo(p.x, p.y)
    })
    graphics.closePath()
  }
}

function flipStrokeAlign(strokeAlign: StrokeAlign): StrokeAlign {
  switch (strokeAlign) {
    case StrokeAlign.Center: {
      return StrokeAlign.Center
    }
    case StrokeAlign.Inside: {
      return StrokeAlign.Outside
    }
    case StrokeAlign.Outside: {
      return StrokeAlign.Inside
    }
  }
}

export function drawCaps(
  node: NodeSnapshot,
  graphics: PIXI.Graphics,
  container: PIXI.Container,
  path: string,
  isClockwise: boolean,
  isClosed: boolean
) {
  const strokeAlign = isClockwise
    ? node.strokeAlign
    : flipStrokeAlign(node.strokeAlign)

  const heads = getArrowHeadsData({
    strokeAlign: isClosed ? strokeAlign : StrokeAlign.Center,
    strokeCapStart: node.strokeCapStart,
    strokeCapEnd: node.strokeCapEnd,
    path,
    size: node.strokeWeight,
  })

  heads.forEach((head, index) => {
    const headContainer = new PIXI.Container()
    const [a, b, tx, c, d, ty] = head.matrix.flat()
    headContainer.transform.setFromMatrix(new PIXI.Matrix(a, b, c, d, tx, ty))

    // const headGraphics = new PIXI.Graphics().beginFill(graphics.line.color)
    const headGraphics = new PIXI.Graphics().beginFill(
      graphics.line.color,
      graphics.line.alpha
    )
    headContainer.addChild(headGraphics)

    drawVectorPath(node, headGraphics, head.path)
    headGraphics.endFill()

    container.addChild(headContainer)
  })
}
