import { usePlayback, useProject, useViewport } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useViewportZoomInteractor } from './interactor'
import { ViewportZoomRouter } from './router'

export interface IProps {}
export const ViewportZoom: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const playback = usePlayback()
  const viewport = useViewport()

  const interactor = useViewportZoomInteractor({
    project,
    playback,
    viewport,
  })
  return <ViewportZoomRouter interactor={interactor} />
})

ViewportZoom.displayName = 'ViewportZoom'
