import { useGetCurrentFolder } from '@aninix/api'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { Icons } from '../icons'
import { Crumb } from './breadcrumbs-crumb'

export interface IProps {}
export const Breadcrumbs: React.FCC<IProps> = () => {
  const { folderId } = useParams()

  return (
    <div className="mb-4 flex flex-row items-center gap-0 border-y-[1px] border-gray-100 px-2 py-2 duration-300">
      <Crumb
        label="Home"
        icon={
          <Icons.Home className="stroke-gray-400 fill-gray-400 group-hover:stroke-secondary group-hover:fill-secondary transition-all duration-300" />
        }
      />

      {folderId && <PathCrubms folderId={folderId} />}
    </div>
  )
}

Breadcrumbs.displayName = 'Breadcrumbs'

const PathCrubms: React.FCC<{ folderId: string }> = ({ folderId }) => {
  const getCurrentFolder = useGetCurrentFolder({ folderId }).data
  const breadcrumbs = getCurrentFolder?.data?.breadcrumbs
  return (
    <>
      {breadcrumbs?.map(({ id, name }) => (
        <Crumb label={name} folderId={id} key={id} />
      ))}
    </>
  )
}
