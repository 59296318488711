import * as React from 'react'

import * as styles from './styles.scss'

export interface IProps {
  color: string
  name: string | string[]
}
export const PropertyLabel: React.FCC<IProps> = ({ color, name }) => (
  <div className={styles.container}>
    <div className={styles.color} style={{ backgroundColor: color }} />

    <p className={styles.name}>
      {typeof name === 'string'
        ? name
        : name.map((item, idx, array) => {
            const isLast = idx === array.length - 1

            return (
              <React.Fragment key={`${item}-${idx}`}>
                <span>{item}</span>
                {isLast === false && (
                  <span className={styles.separator}>/</span>
                )}
              </React.Fragment>
            )
          })}
    </p>
  </div>
)
