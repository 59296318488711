import { useMutation } from '@tanstack/react-query'
import {
  ApiPostRequestBody,
  ApiPostRequestForm,
  ApiPostResponse,
} from './helpers'
import { httpClient } from './http-client'

export function useSendFeedback() {
  return useMutation({
    async mutationFn(payload: ApiPostRequestForm<'/feedback'>) {
      return httpClient.postForm<
        ApiPostResponse<'/feedback'>,
        ApiPostRequestForm<'/feedback'>
      >('/feedback', payload)
    },
  })
}
