import * as React from 'react'

import { IIcon } from '.'

export const TeamSettingsSelected: React.FCC<IIcon> = ({
  size = { x: 24, y: 24 },
  className = 'w-6 h-6',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 ${size.x} ${size.y}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4002 2.12377C10.9831 1.78724 11.2745 1.61898 11.5844 1.55312C11.8585 1.49485 12.1419 1.49485 12.416 1.55312C12.7259 1.61898 13.0173 1.78724 13.6002 2.12377L19.7533 5.67625C20.3362 6.01278 20.6276 6.18105 20.8396 6.41645C21.0271 6.62474 21.1688 6.87012 21.2554 7.13668C21.3533 7.43794 21.3533 7.77447 21.3533 8.44753V15.5525C21.3533 16.2256 21.3533 16.5621 21.2554 16.8633C21.1688 17.1299 21.0271 17.3753 20.8396 17.5836C20.6276 17.819 20.3362 17.9872 19.7533 18.3238L13.6002 21.8763C13.0173 22.2128 12.7259 22.381 12.416 22.4469C12.1419 22.5052 11.8585 22.5052 11.5844 22.4469C11.2745 22.381 10.9831 22.2128 10.4002 21.8763L4.24712 18.3238C3.66424 17.9872 3.37279 17.819 3.16083 17.5836C2.97329 17.3753 2.83162 17.1299 2.74501 16.8633C2.64712 16.5621 2.64712 16.2256 2.64712 15.5525V8.44754C2.64712 7.77447 2.64712 7.43794 2.74501 7.13668C2.83162 6.87012 2.97329 6.62474 3.16083 6.41645C3.37279 6.18105 3.66423 6.01278 4.24712 5.67625L10.4002 2.12377Z"
      fill="#0B1118"
      stroke="#0B1118"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.40039 12C8.40039 13.9882 10.0122 15.6 12.0004 15.6C13.9886 15.6 15.6004 13.9882 15.6004 12C15.6004 10.0118 13.9886 8.39999 12.0004 8.39999C10.0122 8.39999 8.40039 10.0118 8.40039 12Z"
      fill="white"
    />
  </svg>
)
