// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ClhF1bgmYswoFkeGRPSI{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:flex-start;height:100%;font-variant-numeric:tabular-nums}.MicdPUcDzdTiNNGLqgKe{margin-left:4px}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/timeline/info/time-control-indicator/index.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,0BAAA,CACA,WAAA,CACA,iCAAA,CAGF,sBACE,eAAA","sourcesContent":["@use '../../../../theme/index.scss' as *;\n\n.container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: flex-start;\n  height: 100%;\n  font-variant-numeric: tabular-nums;\n}\n\n.icon {\n  margin-left: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `ClhF1bgmYswoFkeGRPSI`;
export var icon = `MicdPUcDzdTiNNGLqgKe`;
export default ___CSS_LOADER_EXPORT___;
