import { useMutation } from '@tanstack/react-query'

import { ApiDeleteParams } from './helpers'
import { httpClient } from './http-client'

export function useDeleteFolder() {
  return useMutation({
    async mutationFn(payload: ApiDeleteParams<'/folders/{folderId}'>['path']) {
      return httpClient.delete(`/folders/${payload.folderId}`, {})
    },
  })
}
