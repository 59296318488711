type Payload = {
  status: 'success' | 'error' | 'close'
}

export interface IPaymentInteractor {
  children: {
    success: boolean
    error: boolean
    close: boolean
  }
}

export const usePaymentInteractor = ({
  status,
}: Payload): IPaymentInteractor => {
  return {
    children: {
      success: status === 'success',
      error: status === 'error',
      close: status === 'close',
    },
  }
}
