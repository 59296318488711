import { FpsComponent, Root } from '@aninix-inc/model'
import { icons, PropertyRowV2 } from '@aninix/app-design-system'
import { useEntity, useProject } from '@aninix/core'
import { MAX_FPS, MIN_FPS } from '@aninix/editor/defaults'
import * as React from 'react'
import { NumberValue } from '../../values/number'

const iconSize = {
  x: 12,
  y: 12,
}

export const Fps: React.FC = () => {
  const project = useProject()
  const root = project.getEntityByTypeOrThrow(Root)
  const component = root.getComponentOrThrow(FpsComponent)
  useEntity(root)
  return <FpsMemo component={component} />
}

Fps.displayName = 'Fps'

const FpsMemo: React.FC<{ component: FpsComponent }> = React.memo(
  ({ component }) => {
    return (
      <PropertyRowV2
        name="FPS (frames per second)"
        inputs={
          <NumberValue
            components={[component]}
            icon={<icons.Copy size={iconSize} />}
            min={MIN_FPS}
            max={MAX_FPS}
            dragDisabled
          />
        }
      />
    )
  },
  (prev, next) => prev.component.value === next.component.value
)

FpsMemo.displayName = 'FpsMemo'
