import { nodeColors } from '@aninix/core/registries'
import { OutlineBox } from '@aninix/core/utils'
import * as paper from 'paper'
import * as React from 'react'

const isWithinThreshold = (a: number, b: number, threshold: number): boolean =>
  Math.abs(a - b) <= threshold

export type SnappingGuide = {
  start: paper.Point
  end: paper.Point
}
export type SnappingDot = paper.Point

export type SnappingItem = SnappingGuide | SnappingDot

export type SnappingOption = {
  snappedPoint: paper.Point
  items: SnappingItem[]
  distance: number
}

export const snapPoint = (
  point: paper.Point,
  box: OutlineBox,
  zoom: number
) => {
  const edgesThreshold = 10 / zoom
  const pointsThreshold = 15 / zoom

  const pointSnappingOptions: SnappingOption[] = []
  const directionSnappingOptions: SnappingOption[] = []

  const points = [
    {
      point: box.topLeft,
      items: [
        { start: box.topLeft, end: box.topRight },
        { start: box.topLeft, end: box.bottomLeft },
        box.topLeft,
        box.topRight,
        box.bottomLeft,
      ],
    },
    {
      point: box.topCenter,
      items: [
        { start: box.topLeft, end: box.topRight },
        { start: box.topCenter, end: box.bottomCenter },
        box.topLeft,
        box.topRight,
        box.bottomCenter,
      ],
    },
    {
      point: box.topRight,
      items: [
        { start: box.topRight, end: box.topLeft },
        { start: box.topRight, end: box.bottomRight },
        box.topLeft,
        box.topRight,
        box.bottomRight,
      ],
    },
    {
      point: box.leftCenter,
      items: [
        { start: box.leftCenter, end: box.rightCenter },
        { start: box.topLeft, end: box.bottomLeft },
        box.topLeft,
        box.bottomLeft,
        box.leftCenter,
        box.rightCenter,
      ],
    },
    {
      point: box.center,
      items: [
        { start: box.leftCenter, end: box.rightCenter },
        { start: box.topCenter, end: box.bottomCenter },
        box.topCenter,
        box.leftCenter,
        box.rightCenter,
        box.bottomCenter,
        box.center,
      ],
    },
    {
      point: box.rightCenter,
      items: [
        { start: box.rightCenter, end: box.leftCenter },
        { start: box.topRight, end: box.bottomRight },
        box.topRight,
        box.bottomRight,
        box.leftCenter,
        box.rightCenter,
      ],
    },
    {
      point: box.bottomLeft,
      items: [
        { start: box.bottomLeft, end: box.topLeft },
        { start: box.bottomLeft, end: box.bottomRight },
        box.topLeft,
        box.bottomLeft,
        box.bottomRight,
      ],
    },
    {
      point: box.bottomCenter,
      items: [
        { start: box.bottomCenter, end: box.topCenter },
        { start: box.bottomLeft, end: box.bottomRight },
        box.topCenter,
        box.bottomLeft,
        box.bottomRight,
        box.bottomCenter,
      ],
    },
    {
      point: box.bottomRight,
      items: [
        { start: box.bottomRight, end: box.topRight },
        { start: box.bottomRight, end: box.bottomLeft },
        box.topRight,
        box.bottomLeft,
        box.bottomRight,
      ],
    },
  ]

  points.forEach((p) => {
    if (
      isWithinThreshold(point.x, p.point.x, pointsThreshold) &&
      isWithinThreshold(point.y, p.point.y, pointsThreshold)
    ) {
      pointSnappingOptions.push({
        snappedPoint: p.point,
        items: p.items,
        distance: point.getDistance(p.point),
      })
    }
  })

  const directions = [
    { start: box.topLeft, end: box.topRight },
    { start: box.bottomLeft, end: box.bottomRight },
    { start: box.topLeft, end: box.bottomLeft },
    { start: box.topRight, end: box.bottomRight },
    { start: box.topCenter, end: box.bottomCenter },
    { start: box.leftCenter, end: box.rightCenter },
  ]

  directions.forEach((direction) => {
    const start = direction.start
    const end = direction.end
    const lineVec = end.subtract(start)
    const pointVec = point.subtract(start)
    const lineLengthSquared = lineVec.dot(lineVec)
    const t =
      lineLengthSquared !== 0 ? pointVec.dot(lineVec) / lineLengthSquared : 0
    const projectionPoint = start.add(lineVec.multiply(t))
    const distance = point.getDistance(projectionPoint)

    if (distance <= edgesThreshold) {
      const distToStart = projectionPoint.getDistance(start)
      const distToEnd = projectionPoint.getDistance(end)
      const farthestPoint = distToStart > distToEnd ? start : end

      const snapItems = [
        { start, end },
        projectionPoint,
        start,
        end,
        { start: projectionPoint, end: farthestPoint },
      ]

      directionSnappingOptions.push({
        snappedPoint: projectionPoint,
        items: snapItems,
        distance: distance,
      })
    }
  })

  if (pointSnappingOptions.length > 0) {
    const bestOption = pointSnappingOptions.reduce((prev, curr) => {
      return prev.distance < curr.distance ? prev : curr
    })

    return {
      snappedPoint: bestOption.snappedPoint,
      items: bestOption.items,
    }
  } else if (directionSnappingOptions.length > 0) {
    const bestOption = directionSnappingOptions.reduce((prev, curr) => {
      return prev.distance < curr.distance ? prev : curr
    })

    return {
      snappedPoint: bestOption.snappedPoint,
      items: bestOption.items,
    }
  } else {
    return {
      snappedPoint: point,
      items: [],
    }
  }
}

export const Snapping: React.FCC<{
  item: SnappingItem
  zoom: number
}> = (props) => {
  const { item, zoom } = props

  if ('start' in item && 'end' in item) {
    return (
      <line
        x1={item.start.x}
        y1={item.start.y}
        x2={item.end.x}
        y2={item.end.y}
        stroke={nodeColors.RED}
        strokeWidth={1 / zoom}
        pointerEvents="none"
      />
    )
  }

  const size = 3 / zoom
  const offset = (size * Math.sqrt(2)) / 2

  return (
    <>
      <line
        x1={item.x - offset}
        y1={item.y - offset}
        x2={item.x + offset}
        y2={item.y + offset}
        stroke={nodeColors.RED}
        strokeWidth={1.5 / zoom}
        pointerEvents="none"
      />
      <line
        x1={item.x - offset}
        y1={item.y + offset}
        x2={item.x + offset}
        y2={item.y - offset}
        stroke={nodeColors.RED}
        strokeWidth={1.5 / zoom}
        pointerEvents="none"
      />
    </>
  )
}
