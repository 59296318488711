import { useQuery } from '@tanstack/react-query'

import { ApiGetParams, ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetTeam(payload: ApiGetParams<'/teams/{teamId}'>['path']) {
  const queryKey = ['get-team', payload.teamId]

  return {
    ...useQuery({
      queryKey,
      async queryFn() {
        const { data } = await httpClient.get<
          ApiGetResponse<'/teams/{teamId}'>
        >(`/teams/${payload.teamId}`)
        return data
      },
    }),
    queryKey,
  }
}
