import * as React from 'react'

import { IIcon } from '.'

export const Pause: React.FCC<IIcon> = ({
  size = { x: 16, y: 16 },
  className = '',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 ${size.x} ${size.y}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 2.5H10.25C9.97386 2.5 9.75 2.72386 9.75 3V13C9.75 13.2761 9.97386 13.5 10.25 13.5H12.5C12.7761 13.5 13 13.2761 13 13V3C13 2.72386 12.7761 2.5 12.5 2.5Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.75 2.5H3.5C3.22386 2.5 3 2.72386 3 3V13C3 13.2761 3.22386 13.5 3.5 13.5H5.75C6.02614 13.5 6.25 13.2761 6.25 13V3C6.25 2.72386 6.02614 2.5 5.75 2.5Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
