import * as React from 'react'
import { SelectProjectAnimationItem } from './animation-item'
import { FlowSelected } from './flow-selected'
import { MutlipleItemsSelected } from './multiple-items-selected'
import { SingleItemSelected } from './single-item-selected'

interface FrameItem {
  name: string
}
export interface IProps {
  selectedItems: FrameItem[]
  isFlow?: boolean
  isLoading?: boolean
}
export const ProjectPicker: React.FCC<IProps> = ({
  selectedItems,
  isFlow = false,
  isLoading = false,
}) => (
  <>
    {!isFlow && (
      <>
        {selectedItems.length === 0 && (
          <SelectProjectAnimationItem label="Select a frame or a prototype flow starting point to create a project" />
        )}

        {selectedItems.length === 1 && (
          <SingleItemSelected
            name={selectedItems[0].name}
            isLoading={isLoading}
          />
        )}

        {selectedItems.length > 1 && (
          <MutlipleItemsSelected
            length={selectedItems.length}
            isLoading={isLoading}
          />
        )}
      </>
    )}

    {isFlow && (
      <FlowSelected name={selectedItems[0].name} isLoading={isLoading} />
    )}
  </>
)

ProjectPicker.displayName = 'ProjectPicker'
