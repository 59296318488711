import { useLottie } from 'apps/web-app/src/use-cases/use-lottie'
import { AnimationItem } from 'lottie-web'
import * as React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

interface IProps {
  animationItem: AnimationItem
  className?: string
}

export const PlaybackButton: React.FCC<IProps> = ({
  animationItem,
  className,
}: IProps) => {
  const {
    isPaused,
    play: lottiePlay,
    pause: lottiePause,
  } = useLottie(animationItem)

  const pause = React.useCallback(
    (e?: React.MouseEvent) => {
      lottiePause()
      e?.stopPropagation()
    },
    [animationItem]
  )

  const play = React.useCallback(
    (e?: React.MouseEvent) => {
      lottiePlay()
      e?.stopPropagation()
    },
    [animationItem]
  )

  const stopPropagation = React.useCallback(
    (e: React.MouseEvent) => {
      e?.stopPropagation()
    },
    [animationItem]
  )

  const handleSpacePress = React.useCallback(() => {
    if (animationItem.isPaused) {
      play()
      return
    }

    pause()
  }, [play, pause])

  useHotkeys('space', handleSpacePress, [handleSpacePress])

  return (
    <button
      className={className}
      onMouseDown={stopPropagation}
      onClick={isPaused ? play : pause}
    >
      {isPaused ? <PlayIcon /> : <PauseIcon />}
    </button>
  )
}

const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1566_127)">
      <path
        d="M3.10542 15.7718C2.1721 16.3163 1 15.6431 1 14.5625V1.43745C1 0.356945 2.1721 -0.316274 3.10542 0.228162L14.3555 6.79071C15.2816 7.33094 15.2816 8.66906 14.3555 9.20929L3.10542 15.7718Z"
        fill="#0B1118"
      />
    </g>
    <defs>
      <clipPath id="clip0_1566_127">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const PauseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 3V13"
      stroke="#0B1118"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 3V13"
      stroke="#0B1118"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

PlaybackButton.displayName = 'PlaybackButton'
