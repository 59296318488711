import { useQuery } from '@tanstack/react-query'
import { config } from './config'
import { httpClient } from './http-client'

export type LottieItem = {
  data: {
    type: 'Buffer'
    data: ArrayBuffer
  }
}

export function useGetLottie({ id }: { id: string }) {
  return useQuery({
    queryKey: ['get-lottie', id],
    refetchOnWindowFocus: false,
    async queryFn() {
      return httpClient.get<LottieItem>(`${config.apiUrl}/lotties/${id}`)
    },
  })
}
