import { observer } from 'mobx-react'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useSessionStore } from '../../../stores'

import { Icons } from '../../../components/icons'
import { CurrentTeam } from './current-team'
import { CurrentUser } from './current-user'
import { ExpandedSpaceBadge } from './expanded-space-badge'

export interface IProps {}

export interface ITeam {
  avatarUrl: string
  teamName: string
  label: string
}

export const SpaceBadge: React.FCC<IProps> = observer(({}) => {
  const sessionStore = useSessionStore()
  const [isExpanded, setIsExpanded] = React.useState(false)

  const handleClose = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsExpanded(false)
  }

  const { teamId } = useParams()

  return (
    <div className="w-full select-none">
      <div
        className="flex w-full flex-row items-center justify-between rounded-xl px-3 py-2 transition-all duration-150 hover:bg-gray-100"
        onClick={() => setIsExpanded(true)}
      >
        {sessionStore.userId &&
          sessionStore.tokens &&
          (teamId ? (
            <CurrentTeam />
          ) : (
            <CurrentUser
              activeAccount={sessionStore.userId}
              tokens={Object.values(sessionStore.tokens)}
            />
          ))}
        <Icons.ArrowsUpDown />
      </div>

      {sessionStore.userId && sessionStore.tokens && (
        <ExpandedSpaceBadge
          activeAccount={sessionStore.userId}
          tokens={Object.values(sessionStore.tokens)}
          isExpanded={isExpanded}
          onClose={handleClose}
        />
      )}
    </div>
  )
})

SpaceBadge.displayName = 'SpaceBadge'
