import { Entity, PositionComponent } from '@aninix-inc/model'
import { INode } from 'svgson'

export const applyPosition = (entity: Entity, node: INode) => {
  if (node.name === 'line') {
    const { x1, x2, y1, y2 } = node.attributes

    const tx1 = parseFloat(x1)
    const tx2 = parseFloat(x2)
    const ty1 = parseFloat(y1)
    const ty2 = parseFloat(y2)

    entity.updateComponent(PositionComponent, (prev) => ({
      ...prev,
      tx1,
      tx2,
      ty1,
      ty2,
    }))
  } else if (node.name === 'circle') {
    const { cx, cy, r } = node.attributes

    const x = parseFloat(cx) - parseFloat(r)
    const y = parseFloat(cy) - parseFloat(r)

    entity.updateComponent(PositionComponent, (prev) => ({
      ...prev,
      x,
      y,
    }))
  } else if (node.name === 'ellipse') {
    const { rx, ry, cx, cy } = node.attributes

    const x = parseFloat(cx) - parseFloat(rx)
    const y = parseFloat(cy) - parseFloat(ry)

    entity.updateComponent(PositionComponent, (prev) => ({
      ...prev,
      x,
      y,
    }))
  } else {
    const x = parseFloat(node.attributes.x)
    const y = parseFloat(node.attributes.y)

    if (isNaN(x) || isNaN(y)) return

    entity.updateComponent(PositionComponent, (prev) => ({ ...prev, x, y }))
  }
}
