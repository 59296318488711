import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const ShareLink: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2423 3.75714C13.0234 4.53819 13.0234 5.80452 12.2423 6.58557L10.8281 7.99978L11.5352 8.70689L12.9494 7.29267C14.121 6.1211 14.121 4.2216 12.9494 3.05003C11.7779 1.87846 9.87838 1.87846 8.70681 3.05003L7.29259 4.46425L7.9997 5.17135L9.41391 3.75714C10.195 2.97609 11.4613 2.97609 12.2423 3.75714ZM7.9997 10.8282L8.70681 11.5353L7.29259 12.9495C6.12102 14.1211 4.22152 14.1211 3.04995 12.9495C1.87838 11.778 1.87838 9.87846 3.04995 8.70689L4.46416 7.29267L5.17127 7.99978L3.75706 9.41399C2.97601 10.195 2.97601 11.4614 3.75706 12.2424C4.53811 13.0235 5.80444 13.0235 6.58549 12.2424L7.9997 10.8282ZM9.76747 5.52491L5.52483 9.76755L6.23193 10.4747L10.4746 6.23201L9.76747 5.52491Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
