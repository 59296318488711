import { httpClient } from '@aninix/api'
import * as R from 'ramda'

import { LocalLogger, Logger, LogLevel } from '@aninix/logger'
import { Analytics } from './analytics'

export class NetworkAnalytics implements Analytics {
  private projectId: string

  private engine: string

  private os: string

  private logger: Logger

  constructor(
    private readonly config: {
      apiUrl: string
    }
  ) {
    this.logger = new LocalLogger({
      level: process.env.LOG_LEVEL as LogLevel,
      prefix: '[NetworkAnalytics]',
    })
  }

  identify: Analytics['identify'] = async ({ engine, os }) => {
    this.os = os
    this.engine = engine
  }

  setProjectData: Analytics['setProjectData'] = async ({ id }) => {
    this.projectId = id
  }

  track: Analytics['track'] = async ({ eventName, properties = {} }) => {
    let preparedProperties = R.clone(properties)

    if (this.projectId != null) {
      preparedProperties.projectId = this.projectId
    }

    if (this.os != null) {
      preparedProperties.os = this.os
    }

    if (this.engine != null) {
      preparedProperties.engine = this.engine
    }

    await httpClient
      .post(`${this.config.apiUrl}/analytics`, {
        eventName,
        properties: preparedProperties,
      })
      .catch((err) => {
        this.logger.error('error happen', err)
      })
  }
}
