import * as paper from 'paper'
import { map3x2ToPaperMatrix, mapPaperMatrixTo3x2, Matrix3x2 } from '../utils'
import { getAngle } from './utils'

const initStart = new paper.Point(0, 0.5)
const initEnd = new paper.Point(1, 0.5)
const initCenter = initStart.add(initEnd).divide(2)

export const getLinearGradientFromMatrix = (
  transformMatirx: Matrix3x2,
  paintMatrix: paper.Matrix,
  size: paper.Size
) => {
  const matrix = map3x2ToPaperMatrix(transformMatirx)

  const gMatrix = matrix.isInvertible() ? matrix.inverted() : matrix

  const gStart = gMatrix.transform(initStart)
  const gEnd = gMatrix.transform(initEnd)

  const scaledSize = size.divide(paintMatrix.scaling)
  const startPoint = paintMatrix.transform(gStart.multiply(scaledSize))
  const endPoint = paintMatrix.transform(gEnd.multiply(scaledSize))
  const angle = getAngle(startPoint, endPoint)

  return { startPoint, endPoint, angle }
}

export const getMatrixFromLinearGradient = (
  startPoint: paper.Point,
  endPoint: paper.Point,
  paintMatrix: paper.Matrix,
  size: paper.Size
): Matrix3x2 => {
  const scaledSize = size.divide(paintMatrix.scaling)
  const invPaintMatrix = paintMatrix.inverted()

  const gStart = invPaintMatrix.transform(startPoint).divide(scaledSize)
  const gEnd = invPaintMatrix.transform(endPoint).divide(scaledSize)
  const gCenter = gStart.add(gEnd).divide(2)

  const scale = gStart.subtract(gEnd).length

  const angle = getAngle(gStart, gEnd)

  const gMatrix = new paper.Matrix()
  gMatrix.rotate(angle, gCenter)
  gMatrix.scale(scale, gCenter)
  gMatrix.translate(gCenter.subtract(initCenter))

  const resultMatrix = gMatrix.isInvertible() ? gMatrix.inverted() : gMatrix

  const matrix3x2 = mapPaperMatrixTo3x2(resultMatrix)

  return matrix3x2
}
