import classNames from 'classnames'
import React from 'react'
import { Icon } from './types'

const defaultSize = {
  x: 16,
  y: 16,
}
export const NewProject: React.FC<Icon> = ({
  size = defaultSize,
  className,
  color = '#FFFFFF',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('flex-shrink-0', className)}
  >
    <path
      d="M13 3.57143V1M13 3.57143L10.5 2.85714M13 3.57143L15.5 2.85714M13 3.57143L14.6667 6M13 3.57143L11.3333 6"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 9V10.2C15.5 11.8802 15.5 12.7202 15.173 13.362C14.8854 13.9265 14.4265 14.3854 13.862 14.673C13.2202 15 12.3802 15 10.7 15H6.3C4.61984 15 3.77976 15 3.13803 14.673C2.57354 14.3854 2.1146 13.9265 1.82698 13.362C1.5 12.7202 1.5 11.8802 1.5 10.2V5.8C1.5 4.11984 1.5 3.27976 1.82698 2.63803C2.1146 2.07354 2.57354 1.6146 3.13803 1.32698C3.77976 1 4.61984 1 6.3 1H8.5"
      stroke={color}
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
