import {
  DurationComponent,
  Root,
  UpdatesSystem,
  type Project,
} from '@aninix-inc/model'
import { makeAutoObservable } from 'mobx'
import * as React from 'react'

/**
 * @description timeline UI related stuff
 */
export class Timeline {
  private project: Project

  private get root(): Root {
    return this.project.getEntityByTypeOrThrow(Root)
  }

  private get projectDuration(): number {
    return this.root.getComponentOrThrow(DurationComponent).value
  }

  /**
   * @description number of time marks at top of timeline
   */
  public timeMarksAmount: number = 10

  public visibleRangeStartTime: number = 0

  public visibleRangeDuration: number = 2

  public infoWidth: number = 400

  public height: number = 200

  // @NOTE: in seconds
  public threshold: number = 0.1

  constructor(payload: { project: Project }) {
    makeAutoObservable(this)

    this.project = payload.project
    this.visibleRangeDuration = this.projectDuration

    // @NOTE: listen to changes on Root node and reset current store state
    this.project.getSystemOrThrow(UpdatesSystem).onUpdate((updates) => {
      if (updates.includes(this.project.getEntityByTypeOrThrow(Root).id)) {
        this.reset()
      }
    })
  }

  updateVisibleRangeStartTime = (startTime: number) => {
    if (startTime >= this.visibleRangeEndTime) {
      this.visibleRangeStartTime = this.visibleRangeEndTime
      return this
    }

    if (startTime <= 0) {
      this.visibleRangeStartTime = 0
      return this
    }

    this.visibleRangeStartTime = startTime
    return this
  }

  updateVisibleRangeDuration = (duration: number) => {
    const newDuration = Math.max(this.threshold, duration)
    this.visibleRangeDuration = newDuration
    return this
  }

  updateVisibleRangeEndTime = (endTime: number) => {
    if (endTime <= this.visibleRangeStartTime) {
      this.visibleRangeDuration = 0
      return this
    }

    if (endTime >= this.visibleRangeDuration - this.visibleRangeStartTime) {
      this.visibleRangeDuration =
        this.visibleRangeDuration - this.visibleRangeStartTime
      return this
    }

    this.visibleRangeDuration = endTime - this.visibleRangeStartTime
    return this
  }

  reset = () => {
    this.updateVisibleRangeStartTime(0)
    this.updateVisibleRangeDuration(this.projectDuration)
    return this
  }

  updateInfoWidth = (infoWidth: number) => {
    this.infoWidth = infoWidth
    return this
  }

  updateHeight = (height: number) => {
    this.height = height
    return this
  }

  get visibleRangeEndTime(): number {
    return this.visibleRangeStartTime + this.visibleRangeDuration
  }

  zoomBy = (step: number) => {
    const minThreshold = 0.1 // 10% of the timeline

    // @NOTE: because 0.75 mean zoom in and vice versa. Unlike viewport.
    // But to make it conveniece we provide api where
    // 0.75 = zoom out and 1.25 = zoom in
    const newDuration = this.visibleRangeDuration * (1 / step)
    const clampedDuration = Math.max(
      minThreshold * this.projectDuration,
      newDuration
    )

    const delta = (this.visibleRangeDuration - clampedDuration) / 2
    const newStartTime = this.visibleRangeStartTime + delta
    this.updateVisibleRangeStartTime(newStartTime).updateVisibleRangeDuration(
      clampedDuration
    )

    return this
  }
}

const Context = React.createContext<Timeline>(null as any)

export const useTimeline = (): Timeline => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'Timeline context not found. Use TimelineProvider at the root component.'
    )
  }

  return context
}

export const TimelineProvider: React.FCC<{ store: Timeline }> = ({
  store,
  children,
}) => <Context.Provider value={store}>{children}</Context.Provider>
