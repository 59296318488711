import {
  Component,
  PaintType,
  VisibleInViewportComponent,
  commitUndo,
  mixed,
} from '@aninix-inc/model'
import { PaintControl } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useValueRgba } from '../../../../../hooks/value-controllers/rgba'
import * as styles from './index.scss'

export interface IProps {
  components: Component[]
  time?: number
}
export const RgbaValue: React.FCC<IProps> = observer(({ components, time }) => {
  const { value, updateValue } = useValueRgba({ components, time })
  const endChange = () => commitUndo(components[0].entity.getProjectOrThrow())
  const isVisible =
    components[0].entity.getComponent(VisibleInViewportComponent)?.value ??
    undefined
  const toggleVisibilibity = () => {
    components.forEach((component) => {
      component.entity.updateComponent(VisibleInViewportComponent, (v) => !v)
    })
    endChange()
  }

  return (
    <div className="flex flex-col">
      {value === mixed ? (
        <p className={styles.mixed}>Click + to replace mixed content</p>
      ) : (
        <PaintControl
          color={value}
          onChange={updateValue}
          onEndChange={endChange}
          visible={isVisible}
          onVisibilityClick={
            isVisible !== undefined ? toggleVisibilibity : undefined
          }
          fill={
            {
              id: 'faux',
              type: PaintType.Solid,
              visible: false,
              color: value,
            } as any
          }
        />
      )}
    </div>
  )
})

RgbaValue.displayName = 'RgbaValue'
