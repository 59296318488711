import * as React from 'react'

const defaultSize = { x: 13, y: 13 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Left: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 13H0V0H1V13ZM13 4C13 4.55228 12.5523 5 12 5H4C3.44772 5 3 4.55228 3 4C3 3.44772 3.44772 3 4 3H12C12.5523 3 13 3.44772 13 4ZM8 10C8.55229 10 9 9.55229 9 9C9 8.44772 8.55229 8 8 8H4C3.44772 8 3 8.44772 3 9C3 9.55229 3.44772 10 4 10H8Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
