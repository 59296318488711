import React from 'react'

export const ResetIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.04004 8.66654C2.20365 10.1291 2.8991 11.4806 3.99413 12.4638C5.08916 13.4471 6.50738 13.9936 7.97908 13.9995C9.45078 14.0053 10.8733 13.47 11.9761 12.4955C13.0789 11.5209 13.785 10.175 13.9602 8.71376C14.1354 7.25252 13.7674 5.77785 12.9262 4.57022C12.0851 3.36258 10.8294 2.50622 9.39806 2.164C7.9667 1.82177 6.45948 2.01755 5.16305 2.71411C3.86662 3.41067 2.87141 4.55942 2.36671 5.94188M2 2.66722V6.00055H5.33333"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
