import * as React from 'react'

const defaultSize = {
  x: 12,
  y: 14,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Horizontally: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 13.5L1 0.5H5.68249e-07L0 13.5H1ZM12 0.5L12 13.5H11L11 0.5H12ZM7 3.5V10.5H5L5 3.5H7Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
