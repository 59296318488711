import classNames from 'classnames'
import * as React from 'react'

import { LoadingError } from './loading-error'
import { LoadingSpinner } from './loading-spinner'
import { RefetchingSpinner } from './refetching-spinner'

interface ILoadings {
  isLoading?: boolean
  isError?: boolean
  isRefetching?: boolean
  errorComponent?: React.ReactNode
  loadingComponent?: React.ReactNode
  refetchingComponent?: React.ReactNode
}

interface IPropsChildren extends ILoadings {
  children: React.ReactNode
}

interface IPropsComponent extends ILoadings {
  component: React.ReactNode
}

export const LoadableWrapper: React.FCC<IPropsComponent> = ({
  isLoading = false,
  isError = false,
  isRefetching = false,
  errorComponent = <LoadingError />,
  loadingComponent = <LoadingSpinner />,
  refetchingComponent = <RefetchingSpinner />,
  component,
}) => (
  <div className="h-full w-full">
    <div
      className={classNames('relative transition-all duration-300 ', {
        ['visible opacity-100']: isRefetching,
        ['invisible h-0 opacity-0']: !isRefetching,
      })}
    >
      {refetchingComponent}
    </div>
    <div
      className={classNames('transition-all duration-300', {
        ['visible opacity-100']: isLoading,
        ['invisible h-0 opacity-0']: !isLoading,
      })}
    >
      {loadingComponent}
    </div>
    <div
      className={classNames('transition-all duration-300', {
        ['visible opacity-100']: isError,
        ['invisible h-0 opacity-0']: !isError,
      })}
    >
      {errorComponent}
    </div>
    <div
      className={classNames('h-inherit transition-all duration-300', {
        ['visible opacity-100']: !isLoading && !isError,
        ['invisible h-0 opacity-0']: !(!isLoading && !isError),
      })}
    >
      {component}
    </div>
  </div>
)

LoadableWrapper.displayName = 'LoadableWrapper'

export const LoadableChildrenWrapper: React.FCC<IPropsChildren> = ({
  isLoading = false,
  isError = false,
  isRefetching = false,
  errorComponent = <LoadingError />,
  loadingComponent = <LoadingSpinner />,
  refetchingComponent = <RefetchingSpinner />,
  children,
}) => (
  <div className="h-full w-full">
    <div
      className={classNames('relative transition-all duration-300 ', {
        ['visible opacity-100']: isRefetching,
        ['invisible h-0 overflow-hidden opacity-0']: !isRefetching,
      })}
    >
      {refetchingComponent}
    </div>
    <div
      className={classNames('transition-all duration-300', {
        ['visible opacity-100']: isLoading,
        ['invisible h-0 overflow-hidden opacity-0']: !isLoading,
      })}
    >
      {loadingComponent}
    </div>
    <div
      className={classNames('transition-all duration-300', {
        ['visible opacity-100']: isError,
        ['invisible h-0 overflow-hidden opacity-0']: !isError,
      })}
    >
      {errorComponent}
    </div>
    <div
      className={classNames('h-inherit transition-all duration-300', {
        ['visible opacity-100']: !isLoading && !isError,
        ['invisible h-0 overflow-hidden opacity-0']: !(!isLoading && !isError),
      })}
    >
      {children}
    </div>
  </div>
)

LoadableChildrenWrapper.displayName = 'LoadableChildrenWrapper'
