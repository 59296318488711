import { CurveType, round } from '@aninix-inc/model/legacy'

import {
  AnimationCurveAspect,
  Entity,
  SpringCurveComponent,
  TimingCurveComponent,
} from '@aninix-inc/model'
import { InfoBlock } from './mappers/types'

/**
 * The class maps provided segment and returns data for timing curve block.
 * @todo: And move it to entities folder.
 */
export class TimingCurveBlockSegment {
  constructor(private readonly segment: [Entity, Entity]) {}

  asBlock = (): InfoBlock => {
    const [left, right] = this.segment
    const leftAspect = left.getAspectOrThrow(AnimationCurveAspect)
    const rightAspect = right.getAspectOrThrow(AnimationCurveAspect)

    if (
      leftAspect.curveType() === CurveType.Timing &&
      rightAspect.curveType() === CurveType.Timing
    ) {
      return {
        propertiesName: 'Cubic bezier',
        properties: [
          {
            title: 'Values',
            value: [
              round(
                left.getComponentOrThrow(TimingCurveComponent).value.out.x,
                { fixed: 2 }
              ),
              round(
                left.getComponentOrThrow(TimingCurveComponent).value.out.y,
                { fixed: 2 }
              ),
              round(
                right.getComponentOrThrow(TimingCurveComponent).value.in.x,
                { fixed: 2 }
              ),
              round(
                right.getComponentOrThrow(TimingCurveComponent).value.in.y,
                { fixed: 2 }
              ),
            ].join(', '),
          },
        ],
      }
    }

    if (
      leftAspect.curveType() === CurveType.Timing &&
      rightAspect.curveType() === CurveType.Spring
    ) {
      return {
        propertiesName: 'Cubic bezier',
        properties: [
          {
            title: 'Values',
            value: [
              round(
                left.getComponentOrThrow(TimingCurveComponent).value.out.x,
                { fixed: 2 }
              ),
              round(
                left.getComponentOrThrow(TimingCurveComponent).value.out.y,
                { fixed: 2 }
              ),
              0.5,
              1,
            ].join(', '),
          },
        ],
      }
    }

    return {
      propertiesName: 'Spring graph',
      properties: [
        {
          title: 'Stiffness',
          value: round(
            left.getComponentOrThrow(SpringCurveComponent).value.stiffness,
            {
              fixed: 0,
            }
          ).toString(),
        },
        {
          title: 'Damping',
          value: round(
            left.getComponentOrThrow(SpringCurveComponent).value.damping,
            {
              fixed: 0,
            }
          ).toString(),
        },
        {
          title: 'Mass',
          value: round(
            left.getComponentOrThrow(SpringCurveComponent).value.mass,
            { fixed: 0 }
          ).toString(),
        },
      ],
    }
  }
}
