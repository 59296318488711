import * as React from 'react'

import { Applied } from './applied'
import { Normal } from './normal'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
  type: 'normal' | 'applied'
}
export const BlendingMode: React.FCC<IProps> = ({
  size = defaultSize,
  style,
  type,
}) => {
  if (type === 'normal') {
    return <Normal size={size} style={style} />
  }

  if (type === 'applied') {
    return <Applied size={size} style={style} />
  }

  return null
}
