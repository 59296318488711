import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { config } from './config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'

export type IProjectPatchResponse =
  paths['/projects/{projectId}']['patch']['responses']['202']['content']['application/json']

export type IProjectPatchBody =
  paths['/projects/{projectId}']['patch']['requestBody']['content']['application/json']

export interface IProjectPatch {
  query: UseMutationResult<
    AxiosResponse<IProjectPatchResponse, any>,
    unknown,
    Partial<IProjectPatchBody>,
    unknown
  >
}

export const usePatchProject = (id: string): IProjectPatch => {
  const query = useMutation({
    mutationKey: ['patch-project', id],
    mutationFn: (body: Partial<IProjectPatchBody>) => {
      return httpClient.patch(`${config.apiUrl}/projects/${id}`, body)
    },
  })

  return { query }
}
