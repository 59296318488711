import { useGetActiveSystemStatus } from '@aninix/api/get-active-system-status'
import * as React from 'react'

import { Typography } from '../design-system/typography'

export interface IProps {}
export const SystemStatusBanner: React.FCC<IProps> = () => {
  const { message = 'Everything is okay', type = 'info' } =
    useGetActiveSystemStatus().data?.data ?? {}

  // if not error or warning — show nothing
  if (['error', 'warning'].includes(type) === false) return null

  return (
    <a
      target="_blank"
      href="https://aninix.com/status"
      className="b-10 flex flex-row items-center gap-6 rounded-lg bg-[#FDF3D0] px-6 py-[18px]"
      rel="noreferrer"
    >
      <div className="flex h-8 w-8 items-center justify-center rounded-full bg-[#FF9E00] shadow-[0px_2px_6px_rgba(255,158,0,0.35),0px_8px_20px_rgba(255,158,0,0.15)]">
        {icon}
      </div>
      <Typography style="Body5Regular">{message}</Typography>
    </a>
  )
}

const icon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.99998 9.0001H8.99998V6.0001L5.49998 2.5001C6.61961 1.96537 7.87748 1.7909 9.10037 2.00072C10.3233 2.21054 11.451 2.79432 12.3284 3.67167C13.2058 4.54903 13.7895 5.6768 13.9994 6.8997C14.2092 8.1226 14.0347 9.38047 13.5 10.5001L19.5 16.5001C19.8978 16.8979 20.1213 17.4375 20.1213 18.0001C20.1213 18.5627 19.8978 19.1023 19.5 19.5001C19.1022 19.8979 18.5626 20.1214 18 20.1214C17.4374 20.1214 16.8978 19.8979 16.5 19.5001L10.5 13.5001C9.38035 14.0348 8.12248 14.2093 6.89958 13.9995C5.67668 13.7897 4.5489 13.2059 3.67155 12.3285C2.79419 11.4512 2.21041 10.3234 2.0006 9.1005C1.79078 7.8776 1.96525 6.61973 2.49998 5.5001L5.99998 9.0001Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

SystemStatusBanner.displayName = 'SystemStatusBanner'
