import * as React from 'react'
import { InspectMode } from '../components/toggle-menu'

const InspectContext = React.createContext<
  | { inspectMode: InspectMode; setInspectMode: (mode: InspectMode) => void }
  | undefined
>(undefined)

interface InspectProviderProps {
  children: React.ReactNode
}

export const InspectProvider: React.FC<InspectProviderProps> = ({
  children,
}) => {
  const [inspectMode, setInspectMode] = React.useState<InspectMode>('preview')

  return (
    <InspectContext.Provider value={{ inspectMode, setInspectMode }}>
      {children}
    </InspectContext.Provider>
  )
}

export const useInspectMode = () => {
  const context = React.useContext(InspectContext)

  if (!context) {
    throw new Error('useInspectMode must be used within a InspectProvider')
  }

  return context
}
