import * as React from 'react'
import { ShapeType, useTools } from "@aninix/core/stores/tools"

export const ShapeAutoIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  const { activeShape: acitveShape } = useTools()

  const shapes: Record<ShapeType, React.FCC> = {
    [ShapeType.Rectangle]: RectangleIcon,
    [ShapeType.Line]: LineIcon,
    [ShapeType.Ellipse]: EllipseIcon,
    [ShapeType.Polygon]: PolygonIcon,
    [ShapeType.Star]: StarIcon,
  }

  const Icon = shapes[acitveShape]

  return <Icon {...props} />
}

export const RectangleIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M3 3H13V13H3V3Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const LineIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line x1="3" y1="13.2929" x2="13.2929" y2="3" stroke="white" strokeWidth={"1.2"} strokeLinecap="round" />
    </svg>
  )
}

export const EllipseIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="8" cy="8" r="5.5" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export const PolygonIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.5 11.5H3.5L8 3.5L12.5 11.5Z"
        stroke="white"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const StarIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M7.69643 2.41073C7.78891 2.1333 7.83515 1.99459 7.90419 1.9569C7.96391 1.92431 8.03611 1.92431 8.09583 1.9569C8.16487 1.99459 8.21111 2.1333 8.30359 2.41074L9.13532 4.90596C9.26646 5.29937 9.33203 5.49608 9.45364 5.64151C9.56104 5.76993 9.69896 5.86934 9.85475 5.93061C10.0312 6 10.2385 6 10.6532 6H13.135C13.4022 6 13.5358 6 13.5927 6.05095C13.642 6.09509 13.6663 6.16077 13.6576 6.22638C13.6475 6.30212 13.5461 6.38907 13.3432 6.56296L11.3168 8.29985C11.0218 8.55276 10.8743 8.67921 10.7871 8.83611C10.7101 8.97476 10.6672 9.12974 10.6619 9.28825C10.6559 9.46762 10.7173 9.65196 10.8402 10.0206L11.6761 12.5282C11.7739 12.8217 11.8228 12.9685 11.7888 13.0409C11.7594 13.1034 11.6998 13.1464 11.6312 13.1545C11.5518 13.1638 11.428 13.071 11.1805 12.8854L8.96 11.22C8.61574 10.9618 8.44361 10.8327 8.25457 10.7829C8.0877 10.739 7.9123 10.739 7.74543 10.7829C7.55639 10.8327 7.38426 10.9618 7.04 11.22L4.81952 12.8854C4.57199 13.071 4.44822 13.1638 4.36879 13.1545C4.30015 13.1464 4.24055 13.1034 4.21117 13.0409C4.17717 12.9685 4.2261 12.8217 4.32395 12.5282L5.15979 10.0206C5.28268 9.65196 5.34413 9.46762 5.33813 9.28825C5.33283 9.12974 5.2899 8.97476 5.21289 8.83611C5.12575 8.67921 4.97822 8.55276 4.68316 8.29985L2.65679 6.56296C2.45391 6.38907 2.35248 6.30212 2.3424 6.22638C2.33367 6.16077 2.35797 6.09509 2.40729 6.05095C2.46424 6 2.59784 6 2.86504 6H5.34679C5.76148 6 5.96882 6 6.14525 5.93061C6.30104 5.86934 6.43896 5.76993 6.54636 5.64151C6.66797 5.49608 6.73354 5.29938 6.86468 4.90597L7.69643 2.41073Z" stroke="white" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
