import * as React from 'react'

const defaultSize = {
  x: 14,
  y: 12,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Vertically: React.FCC<IProps> = ({
  size = { x: 14, y: 12 },
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 14 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 0H13.5V1H0.5V0ZM3.5 5H10.5V7H3.5V5ZM13.5 11H0.5V12H13.5V11Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
