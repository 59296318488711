import {
  Component,
  Point2D,
  getAnimatableValue,
  mixed,
  setValueSpatialPoint2d,
} from '@aninix-inc/model'
import { SpatialPoint2D } from '@aninix-inc/model/legacy'
import { useComponents } from '@aninix/core'
import * as R from 'ramda'

type TangentMirrorId = 'custom' | 'no-mirroring'

/**
 * Encapsulate logic to retrieve and update value
 */
export function useValueSpatialPoint2d({
  components,
  time,
}: {
  components: Component[]
  time?: number
}): {
  x: number | typeof mixed
  y: number | typeof mixed
  tangentMirrorId: TangentMirrorId
  updateX: (newValue: number) => void
  updateXByDelta: (delta: number) => void
  updateY: (newValue: number) => void
  updateYByDelta: (delta: number) => void
  updateTangetMirror: (tangentMirrorId: TangentMirrorId) => void
} {
  useComponents(components)

  const xEquals = R.all(
    (component) =>
      (getAnimatableValue(component, time) as Point2D).x ===
      (getAnimatableValue(components[0], time) as Point2D).x,
    components
  )
  const yEquals = R.all(
    (component) =>
      (getAnimatableValue(component, time) as Point2D).y ===
      (getAnimatableValue(components[0], time) as Point2D).y,
    components
  )

  const updateX = (newValue: number) => {
    components.forEach((component) => {
      setValueSpatialPoint2d(
        component,
        (value: SpatialPoint2D) => {
          const offsetX = value.x - newValue
          return {
            x: newValue,
            y: value.y,
            tx1: value.tx1 - offsetX,
            tx2: value.tx2 - offsetX,
            ty1: value.ty1,
            ty2: value.ty2,
          }
        },
        time
      )
    })
  }

  const updateY = (newValue: number) => {
    components.forEach((component) => {
      setValueSpatialPoint2d(
        component,
        (value: SpatialPoint2D) => {
          const offsetY = value.y - newValue
          return {
            x: value.x,
            y: newValue,
            tx1: value.tx1,
            tx2: value.tx2,
            ty1: value.ty1 - offsetY,
            ty2: value.ty2 - offsetY,
          }
        },
        time
      )
    })
  }

  const updateXByDelta = (delta: number) => {
    components.forEach((component) => {
      setValueSpatialPoint2d(
        component,
        (value: SpatialPoint2D) => ({
          x: value.x + delta,
          y: value.y,
          tx1: value.tx1 + delta,
          tx2: value.tx2 + delta,
          ty1: value.ty1,
          ty2: value.ty2,
        }),
        time
      )
    })
  }

  const updateYByDelta = (delta: number) => {
    components.forEach((component) => {
      setValueSpatialPoint2d(
        component,
        (value: SpatialPoint2D) => ({
          x: value.x,
          y: value.y + delta,
          tx1: value.tx1,
          tx2: value.tx2,
          ty1: value.ty1 + delta,
          ty2: value.ty2 + delta,
        }),
        time
      )
    })
  }

  const updateTangetMirror = (id: 'custom' | 'no-mirroring') => {
    if (id === 'custom') {
      const defaultOffset = 50
      components.forEach((component) => {
        setValueSpatialPoint2d(
          component,
          (value: SpatialPoint2D) => ({
            x: value.x,
            y: value.y,
            tx1: value.x - defaultOffset,
            ty1: value.y,
            tx2: value.x + defaultOffset,
            ty2: value.y,
          }),
          time
        )
      })
    }

    if (id === 'no-mirroring') {
      components.forEach((component) => {
        setValueSpatialPoint2d(
          component,
          (value: SpatialPoint2D) => ({
            x: value.x,
            y: value.y,
            tx1: value.x,
            ty1: value.y,
            tx2: value.x,
            ty2: value.y,
          }),
          time
        )
      })
    }
  }

  // @TODO: move to separated model component
  const tangentMirrorId = (() => {
    if (xEquals === false && yEquals === false) {
      return 'custom'
    }

    const component = components[0]

    // @TODO: check, it seems broken when applying to properties
    const xTangentsEqual =
      // @ts-ignore
      component.value.x === component.value.tx1 &&
      // @ts-ignore
      component.value.x === component.value.tx2
    const yTangentsEqual =
      // @ts-ignore
      component.value.y === component.value.ty1 &&
      // @ts-ignore
      component.value.y === component.value.ty2

    if (xTangentsEqual && yTangentsEqual) {
      return 'no-mirroring'
    }

    return 'custom'
  })()

  return {
    x: xEquals ? (getAnimatableValue(components[0], time) as Point2D).x : mixed,
    y: yEquals ? (getAnimatableValue(components[0], time) as Point2D).y : mixed,
    tangentMirrorId,
    updateX,
    updateXByDelta,
    updateY,
    updateYByDelta,
    updateTangetMirror,
  }
}
