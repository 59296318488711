import React from 'react'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Active: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M9.58594 11.0002L8.29304 12.293C7.90252 12.6836 7.26935 12.6836 6.87883 12.293L3.29304 8.70726C2.90252 8.31673 2.90252 7.68357 3.29304 7.29304L6.87883 3.70725C7.26936 3.31673 7.90252 3.31673 8.29304 3.70726L9.58594 5.00015M8 8L10.5 8L13 8"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
