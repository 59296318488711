import { ChildrenRelationsAspect } from '@aninix-inc/model'

import { Entity } from '@aninix-inc/model'

export const getFlattenChildren = (providedLayers: Entity[]): Entity[] => {
  let layers: Entity[] = [...providedLayers]

  for (let i = 0; i < layers.length; i += 1) {
    const layer = layers[i]

    if (layer.hasAspect(ChildrenRelationsAspect)) {
      layers.push(
        ...layer.getAspectOrThrow(ChildrenRelationsAspect).getChildrenList()
      )
    }
  }

  return layers
}
