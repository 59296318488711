import { Entity, Project } from '@aninix-inc/model'
import { INode } from 'svgson'
import nodeHandlers from './nodes'
import { Assets, SNodeType } from './types'
import { insertToParent } from './utils/insert-to-parent'

export const convertNode = (
  index: number,
  node: INode,
  assets: Assets,
  parent: Entity,
  project: Project
): Entity | void => {
  if (node.name in nodeHandlers) {
    const entity = nodeHandlers[node.name as SNodeType](node, project, assets)
    insertToParent(entity, parent, index)
    return entity
  } else {
    // console.warn(`Unhandled node type: ${node.name}`)
  }
}
