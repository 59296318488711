import { Point2D } from '@aninix-inc/model/legacy'
import {
  LinearGradientPaintSnapshot,
  RadialGradientPaintSnapshot,
} from '@aninix-inc/renderer'
import * as PIXI from 'pixi.js'
import tinycolor from 'tinycolor2'
import { getPointCoefficient } from '../../common/renderers/utils'

export function gradientTexture(
  fill: LinearGradientPaintSnapshot | RadialGradientPaintSnapshot,
  size: Point2D
) {
  const findTexture = PIXI.Cache.get(JSON.stringify(fill)) as
    | PIXI.Texture
    | undefined

  if (findTexture !== undefined) return findTexture

  const { type, colorStops, opacity, gradientTransform: transform } = fill
  const canvas = document.createElement('canvas')
  canvas.width = size.x
  canvas.height = size.y
  const ctx = canvas.getContext('2d')!

  let grd: CanvasGradient

  if (type === 'linear-gradient') {
    let startPoint: [number, number] = [0, 0.5]
    let endPoint: [number, number] = [1, 0.5]

    const newStartCoef = getPointCoefficient(transform, [...startPoint, 1])
    const newEndCoef = getPointCoefficient(transform, [...endPoint, 1])

    grd = ctx.createLinearGradient(
      newStartCoef.x * size.x,
      newStartCoef.y * size.y,
      newEndCoef.x * size.x,
      newEndCoef.y * size.y
    )
  } else {
    let startPoint: [number, number] = [0.5, 0.5]
    let endPoint: [number, number] = [1, 0.5]

    const newStartCoef = getPointCoefficient(transform, [...startPoint, 1])
    const newEndCoef = getPointCoefficient(transform, [...endPoint, 1])

    const magnitude = Math.sqrt(
      (newEndCoef.x - newStartCoef.x) * (newEndCoef.x - newStartCoef.x) +
        (newEndCoef.y - newStartCoef.y) * (newEndCoef.y - newStartCoef.y)
    )

    grd = ctx.createRadialGradient(
      newStartCoef.x * size.x,
      newStartCoef.y * size.y,
      0,
      newStartCoef.x * size.x,
      newStartCoef.y * size.y,
      magnitude * Math.min(size.x, size.y)
    )
  }

  ctx.globalAlpha = opacity

  colorStops.forEach((cs) => {
    const { color, position } = cs
    grd.addColorStop(position, tinycolor(color).toHex8String())
  })

  ctx.fillStyle = grd
  ctx.fillRect(0, 0, size.x, size.y)

  const texture = PIXI.Texture.from(canvas)

  if (findTexture === undefined) PIXI.Cache.set(JSON.stringify(fill), texture)

  return texture
}
