import classNames from 'classnames'
import * as React from 'react'
import { ITeamItem } from './team-item'

export interface IProps {
  item: ITeamItem
  onDeselect: () => void
  disabled?: boolean
}
export const SelectedItem: React.FCC<IProps> = ({
  item,
  onDeselect,
  disabled,
}) => {
  const [isImageLoaded, setIsImageLoaded] = React.useState(false)
  return (
    <button
      onClick={onDeselect}
      className="w-full text-white px-3 py-2 rounded-lg flex flex-row justify-between items-center disabled:opacity-80"
      disabled={disabled}
    >
      <div className="flex flex-row gap-2 items-center">
        <img
          onLoad={() => setIsImageLoaded(true)}
          className={classNames(
            'w-[20px] h-[20px] rounded-full transition-opacity duration-100',
            { ['opacity-0']: !isImageLoaded }
          )}
          crossOrigin="anonymous"
          src={item.thumbnailSrc}
        />
        <p className="font-normal text-[12px]/[14px]">{item.label}</p>
      </div>
      <SwapIcon />
    </button>
  )
}

SelectedItem.displayName = 'SelectedItem'

const SwapIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="13"
      cy="3"
      r="2"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 8V8.2C14 9.88016 14 10.7202 13.673 11.362C13.3854 11.9265 12.9265 12.3854 12.362 12.673C11.7202 13 10.8802 13 9.2 13H9"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 8L2 7.8C2 6.11984 2 5.27976 2.32698 4.63803C2.6146 4.07354 3.07354 3.6146 3.63803 3.32698C4.27976 3 5.11984 3 6.8 3L7 3"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 11L8 13L10 15"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 5L8 3L6 1"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="3"
      cy="13"
      r="2"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
