import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { UpgradeToProBanner } from '../upgrade-to-pro-banner'
import { useTeamSideMenuInteractor } from './interactor'
import { TeamSideMenuRouter } from './router'

export interface IProps {}
export const TeamSideMenu: React.FC<IProps> = observer(() => {
  const interactor = useTeamSideMenuInteractor()

  return (
    <TeamSideMenuRouter
      interactor={interactor}
      upgradeToPro={
        // @TODO: make upgrate to PRO for team instead of direct user
        <UpgradeToProBanner />
      }
    />
  )
})

TeamSideMenu.displayName = 'TeamSideMenu'
