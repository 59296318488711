import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'
import { renderToStaticMarkup } from 'react-dom/server'

import {
  stringifiedSvgToDataUrl,
  stringifiedSvgToImage,
} from './stringified-svg-to-image'

// @NOTE: convert to date url
export function svgToImage(
  svgNode: React.ReactNode,
  size: Point2D
): Promise<string> {
  const svg = renderToStaticMarkup(<>{svgNode}</>)

  return stringifiedSvgToDataUrl({
    stringifiedSvg: svg,
    size,
  })
}

// @NOTE: convert to date url
export function svgToRawImage(payload: {
  svgNode: React.ReactNode
  size: Point2D
  // @NOTE: if nothing provided then data url generated
  format: 'image/png' | 'image/webp'
  quality: number
}): Promise<Blob> {
  const svg = renderToStaticMarkup(<>{payload.svgNode}</>)

  return stringifiedSvgToImage({
    stringifiedSvg: svg,
    size: payload.size,
    format: payload.format,
    quality: payload.quality,
  }) as Promise<Blob>
}
