import { DurationComponent, Project, Root } from '@aninix-inc/model'
import { observer } from 'mobx-react'
import * as React from 'react'
import {
  PlaybackProvider,
  Playback as PlaybackStore,
  ProjectProvider,
  Settings,
  UndoManager,
  ViewportProvider,
  usePlayback,
} from '../../stores'
import * as styles from './index.scss'
import { Viewport } from './viewport'
import { FontLoadingProvider } from '@aninix/core/stores/font-loading'

export interface IProps {
  project: Project
}

const PreviewChild: React.FCC<IProps> = observer(({ project }) => {
  const playback = usePlayback()
  const duration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value

  React.useEffect(() => {
    playback.updatePreviewRangeDuration(duration)
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.container}>
        <Viewport project={project} />
      </div>
    </div>
  )
})

PreviewChild.displayName = 'PreviewChild'

export const Preview: React.FCC<IProps> = observer(({ project }) => {
  const [playback, setPlayback] = React.useState<PlaybackStore>()
  const [undoManager, setUndoManager] = React.useState<UndoManager>()

  React.useEffect(() => {
    const newPlayback = new PlaybackStore({ project, user: new Settings() })
    setPlayback(newPlayback)
    setUndoManager(new UndoManager({ store: project }))
    newPlayback.play()
  }, [])

  if (playback == null || undoManager == null) {
    return null
  }

  return (
    <PlaybackProvider store={playback}>
      <ViewportProvider>
        <ProjectProvider project={project}>
          <FontLoadingProvider project={project}>
            <PreviewChild project={project} />
          </FontLoadingProvider>
        </ProjectProvider>
      </ViewportProvider>
    </PlaybackProvider>
  )
})

Preview.displayName = 'Preview'
