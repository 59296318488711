import { FeedbackFormWithFiles } from '@aninix/app-design-system/components/feedback-form-with-files'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { FeedbackSuccess } from './feedback-success'

export interface IProps {}
export const SendFeedbackPage: React.FCC<IProps> = () => {
  const [params] = useSearchParams()

  const title = params.get('title')
  const projectLink = params.get('projectLink')

  const [success, setSuccess] = React.useState(false)

  return (
    <div className="w-full min-h-screen bg-accent py-28 px-8 overflow-scroll flex flex-row justify-center">
      <div className="max-w-[700px] w-inherit">
        {success ? (
          <FeedbackSuccess />
        ) : (
          <FeedbackFormWithFiles
            defaultTitle={title ?? ''}
            defaultProjectLink={projectLink ?? ''}
            onSubmit={() => setSuccess(true)}
          />
        )}
      </div>
    </div>
  )
}


SendFeedbackPage.displayName = 'SendFeedbackPage'
