// @ts-nocheck
import { NodeSnapshot, PaintSnapshot } from '@aninix-inc/renderer'
import * as PIXI from 'pixi.js'
import { gradientTexture } from '../helpers/gradient-texture'
import { Mappers } from '../mappers'
import { drawVectorPath } from './draw-vector-path'

export function drawIndividualStrokes(
  node: NodeSnapshot,
  graphics: PIXI.Graphics,
  stroke: PaintSnapshot
) {
  if (!(node.individualStrokesEnabled && node.individualStrokesData)) return

  const individualStrokes = [
    {
      width: node.strokeBottomWeight,
      data: node.individualStrokesData.bottom.data,
    },
    {
      width: node.strokeTopWeight,
      data: node.individualStrokesData.top.data,
    },
    {
      width: node.strokeLeftWeight,
      data: node.individualStrokesData.left.data,
    },
    {
      width: node.strokeRightWeight,
      data: node.individualStrokesData.right.data,
    },
  ]

  individualStrokes.forEach((individualStroke) => {
    if (stroke.type === 'solid') {
      graphics.lineStyle(
        individualStroke.width,
        stroke.color,
        stroke.color.a,
        Mappers.mapStrokeAlign(node.strokeAlign),
        false
      )
    }

    if (
      stroke.type === 'linear-gradient' ||
      stroke.type === 'radial-gradient'
    ) {
      graphics.lineTextureStyle({
        alignment: Mappers.mapStrokeAlign(node.strokeAlign),
        join: Mappers.mapStrokeJoin(node.strokeJoin),
        cap: Mappers.mapCap(node.strokeCapStart),
        alpha: stroke.opacity,
        width: individualStroke.width,
        texture: gradientTexture(stroke, {
          x: node.size.x + node.strokeWeight * 2,
          y: node.size.y + node.strokeWeight * 2,
        }),
      })
    }

    drawVectorPath(node, graphics, individualStroke.data, false)
  })
}
