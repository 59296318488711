import * as React from 'react'

const defaultSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Close: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.00002 5.29295L10.6465 0.646484L11.3536 1.35359L6.70713 6.00006L11.3536 10.6465L10.6465 11.3536L6.00002 6.70716L1.35359 11.3536L0.646484 10.6465L5.29291 6.00006L0.646486 1.35367L1.35359 0.64656L6.00002 5.29295Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
