import * as React from 'react'

import { IIcon } from '.'

const defaultSize = { x: 12, y: 12 }

export const ExternalPage: React.FCC<IIcon> = ({
  size = defaultSize,
  className = '',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 12 12`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 5V1.5L7.4375 1.49963"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6.5L10.2487 2.25134"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 7.5V8.3C11 9.42011 11 9.98016 10.782 10.408C10.5903 10.7843 10.2843 11.0903 9.90798 11.282C9.48016 11.5 8.92011 11.5 7.8 11.5H4.2C3.07989 11.5 2.51984 11.5 2.09202 11.282C1.71569 11.0903 1.40973 10.7843 1.21799 10.408C1 9.98016 1 9.42011 1 8.3V4.7C1 3.57989 1 3.01984 1.21799 2.59202C1.40973 2.21569 1.71569 1.90973 2.09202 1.71799C2.51984 1.5 3.07989 1.5 4.2 1.5H5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
