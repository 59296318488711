import * as React from 'react'
import { BackgroundColor } from './background-color'
import { CurveStyles } from './curve-styles'
import { Fps } from './fps'
import { ProjectDuration } from './project-duration'

export const ProjectProperties: React.FC = () => {
  return (
    <div className="flex w-full flex-col">
      <BackgroundColor />
      <Fps />
      <ProjectDuration />
      <CurveStyles />
    </div>
  )
}

ProjectProperties.displayName = 'ProjectProperties'
