import * as React from 'react'

export interface IProps {
  title: string
  Controls?: React.FCC<any> | React.FCC<any>[]
}

export const NavigationBar: React.FCC<IProps> = ({
  title,
  Controls,
}: IProps) => {
  return (
    <div className="flex flex-row items-center justify-between">
      <p className="font-header text-[40px] font-semibold text-secondary">
        {title}
      </p>
      <div className="flex flex-row items-center gap-3">
        {Array.isArray(Controls) ? (
          Controls.map((C, i) => <C key={i} />)
        ) : Controls ? (
          <Controls />
        ) : null}
      </div>
    </div>
  )
}
