import { Component, Entity } from '@aninix-inc/model'
import { safeString } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import {
  getDuration,
  getKeySplines,
  getKeyTimes,
  getKeyframes,
  getRepeatCount,
  getSegments,
  getStartEnd,
} from './utils'

export interface IProps {
  attributeName: string
  component: Component
  getKeyframeValue: (keyframe: Entity, firstKeyframe: Entity) => string
  applyTo?: string
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
export const TransformProperty: React.FCC<IProps> = observer(
  ({
    attributeName,
    component,
    getKeyframeValue,
    applyTo,
    timeRange,
    startEvent,
    endEvent,
  }) => {
    const entity = component.entity
    const keyframes = getKeyframes(component, timeRange)
    const [start, end] = getStartEnd(keyframes, timeRange)
    const duration = getDuration(keyframes, timeRange)
    const segments = getSegments(keyframes)
    const repeatCount = getRepeatCount()

    const firstKey = R.head(keyframes)!

    if (duration === 0) {
      return null
    }

    // return (
    //   <Portal containerId={applyTo ?? safeString(node.id)}>
    //     <animateTransform
    //       attributeName="transform"
    //       type={attributeName}
    //       values={keyframes
    //         .map((keyframe) => getKeyframeValue(keyframe.value, firstKey.value))
    //         .join(';')}
    //       dur={`${duration}s`}
    //       repeatCount={repeatCount}
    //       calcMode="spline"
    //       keyTimes={keyframes
    //         .map((keyframe) => round(keyframe.time, { fixed: 2 }))
    //         .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    //         .map((time) => round(time, { fixed: 2 }))
    //         .join(';')}
    //       keySplines={segments.map(getSegmentCurve).join(';')}
    //       additive="sum"
    //       begin={startEvent}
    //       end={endEvent}
    //     />
    //   </Portal>
    // )

    return (
      <animateTransform
        href={`#${applyTo ?? safeString(entity.id)}`}
        attributeName="transform"
        type={attributeName}
        values={keyframes
          .map((keyframe) => getKeyframeValue(keyframe, firstKey))
          .join(';')}
        dur={`${duration}s`}
        repeatCount={repeatCount}
        calcMode="spline"
        keyTimes={getKeyTimes({ keyframes, start, end })}
        keySplines={getKeySplines(segments)}
        additive="sum"
        fill="freeze"
        begin={startEvent}
        end={endEvent}
      />
    )
  }
)

TransformProperty.displayName = 'TransformProperty'
