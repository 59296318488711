import { httpClient } from '@aninix/api'
import { config } from '@aninix/api/config'
import React from 'react'

export interface GetProjectHistoryRequest {
  (payload: { projectId: string }): GetProjectHistoryResult
}

export interface GetProjectHistoryResult {
  data: GetProjectHistoryResultItem[] | null
  isError: boolean
  isLoading: boolean
}

interface GetProjectHistoryResultItem {
  id: string
  name: string | null
  projectId: string
  createdAt: string
}

export const useGetProjectHistory: GetProjectHistoryRequest = ({
  projectId,
}) => {
  const [projectHistory, setProjectHistory] =
    React.useState<GetProjectHistoryResult>({
      data: null,
      isLoading: true,
      isError: false,
    })

  React.useMemo(() => {
    httpClient
      .get<GetProjectHistoryResult['data']>(
        `${config.apiUrl}/v2/projects/${projectId}/versions`,
        {
          responseType: 'json',
        }
      )
      .then((value) =>
        setProjectHistory({
          data: value.data,
          isLoading: false,
          isError: false,
        })
      )
      .catch((e) =>
        setProjectHistory({ data: null, isLoading: false, isError: true })
      )
  }, [])

  return projectHistory
}
