import { observer } from 'mobx-react'
import * as React from 'react'
import { useProject } from '../../../../../../stores'

import { getEntryOrThrow } from '@aninix-inc/model'
import { useReloadOnAnyUpdate } from '../../../../../../updates'
import { LayerColumn } from './layer-column'
import { TimelineColumn } from './timeline-column'

export interface IProps {}
export const Layers: React.FCC<IProps> = observer(() => {
  const project = useProject()
  useReloadOnAnyUpdate(project)
  const entry = getEntryOrThrow(project)

  return (
    //@TODO add tailwind scrollbar https://www.npmjs.com/package/tailwind-scrollbar
    <div className="flex h-full flex-row gap-0 overflow-y-scroll px-4">
      <div className="w-1/3 overflow-x-clip">
        <LayerColumn layer={entry} />
      </div>
      <div className="w-2/3 overflow-x-clip">
        <TimelineColumn layer={entry} />
      </div>
    </div>
  )
})

Layers.displayName = 'Layers'
