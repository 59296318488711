import { useMutation } from '@tanstack/react-query'

import { config } from './config'
import { ApiPostParams, ApiPostRequestBody } from './helpers'
import { httpClient } from './http-client'

export function useResendInvite() {
  return useMutation({
    mutationKey: ['resend-invite'],
    mutationFn: async (
      payload: ApiPostParams<'/teams/{teamId}/send-invite'>['path'] &
        ApiPostRequestBody<'/teams/{teamId}/send-invite'>
    ) => {
      await httpClient.post(
        `${config.apiUrl}/teams/${payload.teamId}/send-invite`,
        {
          userId: payload.userId,
        }
      )
    },
  })
}
