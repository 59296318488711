import { Format } from '@aninix/core/modules/inspector/mappers/types'
import * as R from 'ramda'
import * as React from 'react'

const defaultFormats: Format[] = R.values(Format)

type ContextType = {
  formats: Format[]
  format: Format
  setFormat: React.Dispatch<React.SetStateAction<Format>>
}

const Context = React.createContext<ContextType>(null as any)

export const useFormatContext = (): ContextType => React.useContext(Context)

export type PropsType = {}

/**
 * Provides proper format options
 */
export const FormatContext: React.FCC<PropsType> = ({ children }) => {
  const [format, setFormat] = React.useState<Format>(Format.Percents)

  return (
    <Context.Provider
      value={{
        formats: defaultFormats,
        format,
        setFormat,
      }}
    >
      {children}
    </Context.Provider>
  )
}
