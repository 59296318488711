import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  flipped?: boolean
  style?: React.CSSProperties
}
export const Sweep: React.FCC<IProps> = ({
  size = defaultSize,
  flipped = false,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ ...style, transform: flipped ? 'rotate(180deg)' : 'rotate(0deg)' }}
  >
    <path
      d="M10.0005 8C10.0005 9.10457 9.10506 10 8.00049 10C6.89592 10 6.00049 9.10457 6.00049 8C6.00049 6.89543 6.89592 6 8.00049 6"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M13 8C13 10.7614 10.7614 13 8 13C5.23858 13 3 10.7614 3 8C3 5.23858 5.23858 3 8 3"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
