import * as React from 'react'

import { TTabs } from '..'
import { InspectCode as Code } from './code'
import { Inspect } from './inspect'

export interface IProps {
  selectedTab: TTabs
}
export const TabsContent: React.FCC<IProps> = ({ selectedTab }: IProps) => {
  switch (selectedTab) {
    case 'inspect':
      return <Inspect />
    case 'code':
      return <Code />
  }
}

TabsContent.displayName = 'TabsContent'
