import {
  OnboardingObject,
  useOnboarding,
} from '@aninix/app-design-system/hooks/use-onboarding'
import { Popover } from '@material-ui/core'
import * as React from 'react'

type AnchorOrigin = Parameters<typeof Popover>[0]['anchorOrigin']
type TransformOrigin = Parameters<typeof Popover>[0]['transformOrigin']

const defaultAttachToAnchors: AnchorOrigin = {
  vertical: 'center',
  horizontal: 'left',
}

const defaultTransformContentFrom: TransformOrigin = {
  vertical: 'center',
  horizontal: 'right',
}

export interface GuideProps {
  name: string
  anchorEl: Element | ((element: Element) => Element) | null | undefined
  attachToAnchors?: AnchorOrigin
  transformContentFrom?: TransformOrigin
}
export const OnboardingPopover = React.forwardRef<
  OnboardingObject,
  React.PropsWithChildren<GuideProps>
>(
  (
    {
      name,
      anchorEl,
      attachToAnchors = defaultAttachToAnchors,
      transformContentFrom = defaultTransformContentFrom,
      children,
    },
    ref
  ) => {
    const onboarding = useOnboarding({ name })
    const { close, isExpanded, isPassed, open, pass } = onboarding

    React.useImperativeHandle(ref, () => onboarding, [onboarding])

    if (isPassed) return

    return (
      <Popover
        elevation={0}
        disableAutoFocus
        PaperProps={{ style: { background: 'transparent' } }}
        disableEnforceFocus
        open={isExpanded}
        onClose={close}
        anchorEl={anchorEl}
        anchorOrigin={attachToAnchors}
        transformOrigin={transformContentFrom}
      >
        {children}
      </Popover>
    )
  }
)
