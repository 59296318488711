import * as R from 'ramda'

import { arcToBezier } from './arc-to-bezier'
import { BezierPoint } from './bezier-point'
import { bezierPointsToSvgPath } from './bezier-points-to-svg-path'
import { svgPathToBezierPoints } from './svg-path-to-bezier-points'

type Data = string

type Payload = {
  data: Data | BezierPoint[][]
  radius: number
  debug?: boolean
}

/**
 * @todo add docs
 */
function roundPoints(payload: {
  points: [BezierPoint, BezierPoint, BezierPoint]
  radius: number
}): BezierPoint[] {
  const {
    points: [A, B, C],
    radius,
  } = payload

  const ab = B.subtractedBy(A)
  const bc = C.subtractedBy(B)
  const ba = A.subtractedBy(B)

  const shortestEdge = R.min(ab.magnitude(), bc.magnitude())

  const angle = ab.angleBetween(bc)

  // scalars
  const EA = radius
  const tan = Math.tan(angle / 2) || 1
  const AB = EA / tan
  const BC = AB
  const BD = AB * Math.cos(angle / 2)
  const BF = BD * Math.cos(angle / 2)
  const FD = BD * Math.sin(angle / 2)
  const CE = FD / (BF / BC)
  const cornerRadius = CE

  const perpendicular = ab.rotatedBy(Math.PI / 2)
  const isClockwise = bc.dotProduct(perpendicular) >= 0
  const sign = isClockwise ? -1 : 1

  const newAB = cornerRadius * tan
  const maxLength = R.min(newAB, shortestEdge / 2)
  const modifiedCornerRadius = maxLength / tan

  const APoint = ba
    .normalized()
    .multiply(modifiedCornerRadius * tan)
    .addedBy(B)
  const EPoint = ba
    .normalized()
    .rotatedBy((sign * Math.PI) / 2)
    .multiply(modifiedCornerRadius)
    .addedBy(APoint)

  const circleCenter = EPoint

  const abAxisAngle = ab.angleBetween(
    new BezierPoint({ point: { x: 1, y: 0 } })
  )

  return arcToBezier({
    position: {
      x: -modifiedCornerRadius,
      y: -modifiedCornerRadius,
    },
    size: { x: modifiedCornerRadius * 2, y: modifiedCornerRadius * 2 },
    startAngle: 0,
    endAngle: Math.abs(angle),
    clockwise: isClockwise,
  })
    .map((point) => {
      const currentSign = ab.point.y > 0 ? -1 : 1

      const updatedPoint = point.rotatedBy(currentSign * abAxisAngle)

      if (!isClockwise) {
        return updatedPoint
      }

      return updatedPoint.rotatedBy(Math.PI)
    })
    .map((point) => point.translatedBy(circleCenter.point))
}

/**
 * @description round points of any svg path. Most of the logic taken from this article
 * @url https://observablehq.com/@daformat/rounding-polygon-corners
 */
export function roundSvgPath({ data, radius, debug = false }: Payload): Data {
  if (radius < 1) {
    return typeof data === 'string'
      ? data
      : bezierPointsToSvgPath({ regions: data })
  }

  if (debug) {
    console.log('data', data)
  }

  const pointsToRoundArray =
    typeof data === 'string' ? svgPathToBezierPoints(data) : data

  if (debug) {
    console.log('converted points array', pointsToRoundArray)
  }

  const map: BezierPoint[][] = pointsToRoundArray.map((pointsToRound) => {
    if (pointsToRound.length < 3) {
      return pointsToRound
    }

    const roundedPoints: BezierPoint[] = [R.head(pointsToRound)!.clone()]

    const length = pointsToRound.length
    for (let i = 1; i < length - 1; i += 1) {
      const currentPoint = pointsToRound[i].clone()

      if (debug) {
        console.log({
          currentPoint,
          isContinuous: currentPoint.isContinuous(),
          isLinear: currentPoint.isLinear(),
        })
      }

      if (currentPoint.isContinuous() && currentPoint.isLinear() === false) {
        roundedPoints.push(currentPoint)
        continue
      }

      const prevPoint = pointsToRound[i - 1].clone()
      const nextPoint = pointsToRound[i + 1].clone()

      roundedPoints.push(
        ...roundPoints({
          points: [
            new BezierPoint({ point: prevPoint.toAbsoluteJson().endTangent }),
            currentPoint,
            new BezierPoint({ point: nextPoint.toAbsoluteJson().startTangent }),
          ],
          radius,
        })
      )
    }

    const isClosed = R.last(pointsToRound)!.equals(R.head(pointsToRound)!)

    if (isClosed) {
      const predLastIdx = pointsToRound.length - 2
      const firstIdx = 1

      // @NOTE: required to remove not required point in not-circular shapes
      if (
        pointsToRound[predLastIdx].isContinuous() &&
        pointsToRound[predLastIdx].isLinear() &&
        pointsToRound[firstIdx].isContinuous() &&
        pointsToRound[firstIdx].isLinear()
      ) {
        roundedPoints.shift()
      }

      const points = roundPoints({
        points: [
          new BezierPoint({
            point: pointsToRound[predLastIdx].toAbsoluteJson().endTangent,
          }),
          R.last(pointsToRound)!,
          new BezierPoint({
            point: pointsToRound[firstIdx].toAbsoluteJson().startTangent,
          }),
        ],
        radius,
      })

      roundedPoints.push(...points)
      roundedPoints.push(R.head(roundedPoints)!)
    } else {
      roundedPoints.push(R.last(pointsToRound)!)
    }

    const withoutTwoIdenticalPointsInARow = R.uniqWith(
      (left, right) => left.equals(right),
      roundedPoints
    )

    if (isClosed) {
      withoutTwoIdenticalPointsInARow.push(
        R.head(withoutTwoIdenticalPointsInARow)!
      )
    }

    return withoutTwoIdenticalPointsInARow
  })

  if (debug) {
    console.log('map', map)
  }

  const result = bezierPointsToSvgPath({ regions: map })

  if (debug) {
    console.log('result', result)
  }

  return result
}
