import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const SelectOneFrame: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 24V22H8V21H10L10 11H8V10H10V8H11V10H21V8H22V10H24V11H22L22 15H21L21 11H11L11 21H14V22H11V24H10Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      fillOpacity="0.3"
    />

    <path
      d="M18 24L16 16L23.5 19L20 20.5L18 24Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeLinecap="square"
    />
  </svg>
)
