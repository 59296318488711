import { lerp, lerpRange, Point2D } from '@aninix-inc/model/legacy'
import * as R from 'ramda'

import { BezierPoint } from './bezier-point'

const DEFAULT_POINTS = 4

function generatePoints(payload: {
  sign: number
  position: Point2D
  size: Point2D
  startAngle: number
  endAngle: number
  approximationPoints: number
}): BezierPoint[] {
  const { sign, position, size, startAngle, endAngle, approximationPoints } =
    payload

  const lengthCoefficient = (endAngle - startAngle) / (Math.PI * 2)

  // @NOTE: taken from https://stackoverflow.com/a/27863181
  const tangentDistance =
    (4 / 3) * Math.tan(Math.PI / (2 * approximationPoints)) * lengthCoefficient

  let points: BezierPoint[] = []

  for (let i = 0; i < approximationPoints + 1; i += 1) {
    const point = new BezierPoint({
      point: {
        x: 0,
        // @NOTE: move point to right
        y: -1,
      },
      startTangent: {
        x: sign * tangentDistance,
        y: 0,
      },
      endTangent: {
        x: sign * -tangentDistance,
        y: 0,
      },
    })
      .rotateBy(sign * lerp(i / approximationPoints, startAngle, endAngle))
      .scaleBy({ x: size.x / 2, y: size.y / 2 })
      .translateBy({
        x: position.x + size.x / 2,
        y: position.y + size.y / 2,
      })

    points.push(point)
  }

  return points
}

type Payload = {
  position: Point2D
  size: Point2D

  /**
   * @description angle in radians
   */
  startAngle: number

  /**
   * @description angle in radians
   */
  endAngle: number

  clockwise?: boolean

  /**
   * @description inner radius in percents. Required to create arc
   */
  innerRadius?: number

  /**
   * @description should be provided when ellipse arc drew
   */
  ellipse?: boolean

  approximatePoints?: number

  /**
   * @description required to properly handle number of points between keyframes.
   * Was implemented to handle lottie animations.
   */
  fixedPointsCount?: boolean
}

/**
 * @description generate arc by provided parameters and return bezier points
 */
export function arcToBezier({
  position,
  size,
  startAngle,
  endAngle,
  clockwise = true,
  innerRadius = 0,
  ellipse = false,
  approximatePoints = DEFAULT_POINTS,
  fixedPointsCount = false,
}: Payload): BezierPoint[] {
  const sign = clockwise ? 1 : -1

  // @NOTE: the more points we use the longest it take to calculate. Min is 4
  const approximationPoints = Math.round(
    lerpRange(
      Math.abs(endAngle - startAngle),
      0,
      Math.PI * 2,
      4,
      approximatePoints
    )
  )

  const points: BezierPoint[] = generatePoints({
    sign,
    position,
    size,
    startAngle,
    endAngle,
    approximationPoints: fixedPointsCount
      ? DEFAULT_POINTS
      : approximationPoints,
  })

  const isArc =
    Math.abs(endAngle - startAngle) < Math.PI * 2 || innerRadius !== 0
  const strokeOnly = innerRadius === 1

  // @NOTE: render if inner radius provided and arc is not full circle
  if (ellipse && isArc && strokeOnly === false) {
    points[0].resetStartTangent()
    points[points.length - 1].resetEndTangent()

    const newPoints = R.reverse(
      generatePoints({
        sign,
        position,
        size,
        startAngle,
        endAngle,
        approximationPoints: fixedPointsCount
          ? DEFAULT_POINTS
          : Math.round(lerp(innerRadius, 4, approximationPoints)),
      })
    ).map((point) =>
      point
        // @NOTE: shift back
        .translatedBy({
          x: -(position.x + size.x / 2),
          y: -(position.y + size.y / 2),
        })
        .scaledBy({ x: innerRadius, y: innerRadius })
        // @NOTE: shift forward
        .translatedBy({
          x: position.x + size.x / 2,
          y: position.y + size.y / 2,
        })
        .flipTangents()
    )

    newPoints[0].resetStartTangent()
    newPoints[newPoints.length - 1].resetEndTangent()

    points.push(...newPoints)
  }

  if (fixedPointsCount) {
    return points
  }

  // @NOTE: remove points with the same coords
  const cleanedPoints: BezierPoint[] = [R.head(points)!.clone()]

  for (let i = 1; i < points.length; i += 1) {
    const prevPoint = points[i - 1]
    const point = points[i]

    if (prevPoint.equals(point)) {
      continue
    }

    cleanedPoints.push(point.clone())
  }

  if (ellipse && isArc && strokeOnly === false) {
    if (R.head(cleanedPoints)!.equals(R.last(cleanedPoints)!) === false) {
      cleanedPoints.push(R.head(cleanedPoints)!.clone())
    }
    R.head(cleanedPoints)!.resetStartTangent()
    R.last(cleanedPoints)!.resetEndTangent()
  }

  return cleanedPoints
}
