import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Rotation: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.50003 7.99902C4.50003 6.06603 6.06703 4.49902 8.00003 4.49902C9.7529 4.49902 11.2048 5.78758 11.4602 7.46913L10.3 6.59902L9.70003 7.39902L11.7 8.89902L12.1 9.19902L12.4 8.79902L13.9 6.79902L13.1 6.19902L12.4132 7.11481C12.0024 5.05293 10.1827 3.49902 8.00003 3.49902C5.51475 3.49902 3.50003 5.51374 3.50003 7.99902C3.50003 10.4843 5.51475 12.499 8.00003 12.499C9.6663 12.499 11.1204 11.5931 11.8977 10.2494L11.0321 9.74866C10.4262 10.7961 9.2949 11.499 8.00003 11.499C6.06703 11.499 4.50003 9.93202 4.50003 7.99902ZM8.00003 8.99902C7.44775 8.99902 7.00003 8.55131 7.00003 7.99902C7.00003 7.44674 7.44775 6.99902 8.00003 6.99902C8.55232 6.99902 9.00003 7.44674 9.00003 7.99902C9.00003 8.55131 8.55232 8.99902 8.00003 8.99902Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
