import { useMutation } from '@tanstack/react-query'

import { ApiPostCreatedResponse, ApiPostRequestBody } from './helpers'
import { httpClient } from './http-client'

export function useCreateTeam() {
  return useMutation({
    async mutationFn(payload: ApiPostRequestBody<'/teams'>) {
      const { data } = await httpClient.post<ApiPostCreatedResponse<'/teams'>>(
        '/teams',
        payload
      )
      return data
    },
  })
}
