import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { remixProject } from '@aninix/api'
import { Loader } from '@aninix/app-design-system'
import { Popper } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'
import * as styles from './remix.scss'

export const Remix = ({ projectId }: { projectId: string }) => {
  const analytics = useAnalytics()
  const buttonRef = React.useRef<HTMLButtonElement | null>(null)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFocused, setIsFocused] = React.useState(false)

  const remix = React.useCallback(async () => {
    setIsLoading(true)
    analytics.track({
      eventName: AnalyticsEvent.ProjectRemixClicked,
      properties: {
        context: 'toolbar',
        initialProjectId: projectId,
      },
    })
    const { id } = await remixProject({
      projectId,
    })
    // @TODO: move to helper
    window.open(`/edit/${id}`, '_blank')
    setIsLoading(false)
    analytics.track({
      eventName: AnalyticsEvent.ProjectRemixed,
      properties: {
        context: 'toolbar',
        initialProjectId: projectId,
      },
    })
  }, [projectId, analytics])

  const focus = React.useCallback(() => {
    setIsFocused(true)
  }, [])

  const blur = React.useCallback(() => {
    setIsFocused(false)
  }, [])

  return (
    <>
      <Popper open={isFocused} anchorEl={buttonRef.current}>
        <div
          className={classNames(
            'w-[340px] bg-[#0B1118D9] rounded-2xl backdrop-blur',
            styles.tooltip
          )}
        >
          <div className="text-white p-4 flex flex-col justify-start items-start gap-4 font-body">
            <p className="txt-[14_20_500] text-[#0B1118] bg-[#FFC700] px-2 py-[2px] rounded-full flex flex-row items-center gap-1">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 6L10 3.75M6 6V10.5M6 6L2 3.75M6 6L10 8.25M6 6V1.5M6 6L2 8.25"
                  stroke="#0B1118"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              New
            </p>

            <div className="flex flex-col gap-2">
              <p className="txt-[18_26_500]">
                Endless possibilities with remixing!
              </p>

              <p className="txt-[16_23_400] text-[#89909A]">
                Take any Community file and make it your own: tweak project
                animations or learn new techniques from other creatives.
              </p>
            </div>
          </div>
        </div>
      </Popper>

      <button
        onMouseEnter={focus}
        onMouseLeave={blur}
        onClick={remix}
        className="relative flex flex-row justify-center items-center gap-2 rounded-lg border-[1px] border-gray-400 px-3 py-[6px] text-sm text-white disabled:cursor-no-drop"
        disabled={isLoading}
        ref={buttonRef}
      >
        <span
          className={classNames('transition-opacity duration-150 ease-in-out', {
            'opacity-15 !duration-300': isLoading,
          })}
        >
          Remix
        </span>

        <div
          className={classNames(
            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 ease-in-out scale-75',
            {
              '!opacity-100 !scale-100 !duration-150': isLoading,
            }
          )}
        >
          <Loader size={16} color="white" />
        </div>
      </button>
    </>
  )
}
