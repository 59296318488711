import { useGetLottie } from '@aninix/api/get-lottie'
import { LottieItems } from '@aninix/api/get-lotties'
import { binaryToJson } from '@aninix/core'
import classNames from 'classnames'
import { formatDistance } from 'date-fns'
import lottie, { AnimationItem } from 'lottie-web'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from '../design-system/typography'

export const LottieItem: React.FCC<LottieItems[number] & { data: any }> = ({
  id,
  createdAt,
  expiredAt,
  updatedAt,
}) => {
  const { data, isLoading, isError } = useGetLottie({ id })

  const containerRef = React.useRef<HTMLDivElement>(null)

  const [animationItem, setAnimationItem] =
    React.useState<AnimationItem | null>(null)

  React.useMemo(() => {
    if (data?.data.data && animationItem === null) {
      if (containerRef.current !== null) containerRef.current.innerHTML = ''
      const binaryData = new Uint8Array(data?.data.data.data)
      binaryToJson(binaryData)
        .then((json) => {
          const animation = lottie.loadAnimation({
            container: containerRef.current!,
            animationData: json,
            name: (json as any).nm,
            loop: true,
            renderer: 'svg',
            autoplay: false,
          })

          setAnimationItem(animation)
        })
        .catch((e) => {
          setErrorMessage("Couldn't play the file")
        })
    }
  }, [data])

  const [errorMessage, setErrorMessage] = React.useState<string>(
    "Couldn't get lottie file"
  )

  React.useEffect(() => {
    animationItem?.goToAndStop(animationItem.totalFrames / 2, true)
  }, [animationItem])

  const handleMouseEnter = React.useCallback(() => {
    animationItem?.goToAndPlay(0, true)
  }, [animationItem])

  const handleMouseLeave = React.useCallback(() => {
    animationItem?.goToAndStop(animationItem.totalFrames / 2, true)
  }, [animationItem])

  if (isLoading) return <LottieItemPlaceholder />

  if (isError)
    return (
      <div className="flex h-full w-full items-center justify-center rounded-[8px] border-[1px] border-gray-200 bg-white">
        <Typography className="text-gray-500" style="Body4Regular">
          {errorMessage}
        </Typography>
      </div>
    )

  return (
    <Link to={`/lottie-preview?id=${id}`} target="_blank">
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className={classNames(
          'group relative flex w-full flex-col rounded-[8px] border-[1px] border-gray-200 bg-white transition-all duration-75 hover:shadow-lg group-focus:shadow-lg'
        )}
      >
        <div
          className={classNames(
            'absolute inset-0 z-30 transition-all duration-300',
            { ['opacity-0']: animationItem }
          )}
        >
          <LottieItemPlaceholder />
        </div>
        <div className="flex flex-col">
          <div className="flex h-52 w-full items-center overflow-clip rounded-t-[7px] bg-[#f5f5f5]">
            <div className="h-full w-full p-3" ref={containerRef} />
          </div>
          <div className="flex flex-col gap-4 p-4">
            <div className="flex flex-col gap-1">
              <div className="flex flex-row justify-between">
                <p className="line-clamp-1 h-6 font-body text-base font-medium text-secondary">
                  {animationItem?.name ?? 'loading'}
                </p>
              </div>
              <div className="flex h-3 flex-row items-center gap-2">
                {expiredAt && (
                  <p className="line-clamp-2 font-body text-xs font-normal text-gray-400">
                    {lottieUpdateAtLabel(expiredAt)}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

const lottieUpdateAtLabel = (dateString: string) => {
  const date = new Date(dateString)
  const verboseTimeElapsed = formatDistance(date, new Date())
  return `Will expire in ${verboseTimeElapsed}`
}

export const LottieItemPlaceholder: React.FCC = () => (
  <div className="z-1 group relative flex w-full flex-col rounded-[8px] border-[1px] border-gray-200 bg-white transition-all duration-300 hover:shadow-lg">
    <div className="flex h-52 w-full items-center overflow-clip  rounded-t-[7px]">
      <div className="h-full w-full animate-pulse bg-slate-100 object-cover" />
    </div>
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-col gap-1">
        <div className="flex flex-row justify-between">
          <div className="h-6 w-1/2 animate-pulse rounded-md bg-gray-200" />
          <div
            className={classNames(
              'relative z-10 rounded-md p-1 transition-all duration-300'
            )}
          ></div>
        </div>
        <div className="animate-puls h-3 w-1/3 rounded-md bg-gray-200" />
      </div>
    </div>
  </div>
)

LottieItem.displayName = 'LottieItem'
