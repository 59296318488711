import { LocalLogger, LogLevel } from '@aninix/logger'
import * as R from 'ramda'
import { ArrayBufferTarget, Muxer } from 'webm-muxer'
import { yieldToMain } from '../../utils'
import { Encoder } from './types'

const logger = new LocalLogger({
  level: process.env.LOG_LEVEL as LogLevel,
  prefix: '[encodeWebm]',
})

export const encodeWebm: Encoder = ({
  iterable,
  options,
  onProgressUpdate,
  onError,
  onDone,
}) => {
  const { fps, framesCount, size, alpha } = options
  let isCancelled = false
  let videoEncoder: VideoEncoder

  const render = async () => {
    try {
      const muxer = new Muxer({
        target: new ArrayBufferTarget(),
        video: {
          codec: 'V_VP9',
          width: size.x,
          height: size.y,
          alpha,
        },
        firstTimestampBehavior: 'offset',
        type: 'webm',
      })
      let prevTime = Date.now()
      let processedFrame = 0
      videoEncoder = new VideoEncoder({
        output: (chunk, meta) => {
          processedFrame += 1
          const end = Date.now()
          onProgressUpdate(
            R.clamp(0, 1, processedFrame / framesCount),
            end - prevTime
          )
          logger.log(
            `combining frame ${processedFrame}/${framesCount} time in ms`,
            end - prevTime
          )
          prevTime = Date.now()
          return muxer.addVideoChunk(chunk, meta!)
        },
        error: (e) => {
          onError(e.message)
        },
      })
      const config: VideoEncoderConfig = {
        // @NOTE: https://developer.mozilla.org/en-US/docs/Web/Media/Formats/codecs_parameter
        codec: 'vp09.00.62.08',
        framerate: fps,
        width: size.x,
        height: size.y,
        bitrate: 50e6,
        // @TODO: not currently supported but enable once it is
        // alpha: alpha ? 'keep' : undefined,
      }
      videoEncoder.configure(config)

      let i = 0
      for await (const canvas of iterable) {
        if (isCancelled) {
          return
        }
        if (i % 20 === 0) {
          await yieldToMain()
        }
        const videoFrame = new VideoFrame(canvas, {
          timestamp: i * (1 / options.fps) * 1e6,
        })
        videoEncoder.encode(videoFrame, {
          keyFrame: i % 10 === 0,
        })
        videoFrame.close()
        i++
      }

      await videoEncoder.flush()

      muxer.finalize()
      onDone(new Blob([muxer.target.buffer], { type: 'video/webm' }))
    } catch (err: any) {
      onError(
        `Unexpected issue happen. Please send the following info to us: ${err.message}`
      )
      logger.error(err)
    }
  }

  render()

  return () => {
    try {
      isCancelled = true
      videoEncoder.close()
    } catch (err: any) {
      logger.warn('cancelation error', err)
    }
  }
}
