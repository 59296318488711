import classNames from 'classnames'
import * as React from 'react'
import { Icon } from './types'

const defaultSize = {
  x: 16,
  y: 16,
}
export const DeleteProject: React.FC<Icon> = ({
  size = defaultSize,
  className,
  color = '#FFFFFF',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    className={classNames('flex-shrink-0', className)}
  >
    <path
      d="M14.5 4H2.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 1.5H10.5"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.5 4L4.23543 12.0897C4.32882 13.117 4.37552 13.6307 4.60559 14.0192C4.8082 14.3613 5.10819 14.6352 5.46725 14.806C5.87495 15 6.39073 15 7.42229 15H9.57771C10.6093 15 11.1251 15 11.5328 14.806C11.8918 14.6352 12.1918 14.3613 12.3944 14.0192C12.6245 13.6307 12.6712 13.117 12.7646 12.0897L13.5 4"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
