import {
  Entity,
  ParentRelationAspect,
  SelectionSystem,
} from '@aninix-inc/model'

/**
 * Check if provided property's (component) layer, property-group's layer or layer parent selected.
 */
export const isAnyParentSelected = (layer: Entity): boolean => {
  const selection = layer.getProjectOrThrow().getSystemOrThrow(SelectionSystem)

  const parent = layer.getAspect(ParentRelationAspect)?.getParentEntity()
  if (parent !== undefined) {
    const isSelected = selection.isSelected(parent.id)

    if (isSelected) {
      return true
    }

    return isAnyParentSelected(parent)
  }

  return false
}
