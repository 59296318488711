import { Point2D } from '@aninix-inc/model/legacy'
import { makeAutoObservable } from 'mobx'
import * as React from 'react'
import { toast } from 'react-toastify'

// @TODO: move to separated context when refactored.
// Currently we are using useUi because it's time expensive to add more context.
import * as commands from '../commands'

export type PropertiesPanelTab = 'Properties' | 'Presets'

export type ContextMenuItem = {
  title: string
  onClick: () => void
  icon?: React.ReactNode
  rightPart?: React.ReactNode | string
  disabled?: boolean
}

export type ProjectPreview = {
  /**
   * @description frame id where project saved
   */
  id: string

  /**
   * @description current project name
   */
  name: string
}

export interface IUiStore {
  // @NOTE: properties panel tabs
  propertiesPanelTabs: PropertiesPanelTab[]
  propertiesPanelActiveTab: PropertiesPanelTab
  propertiesPanelUpdateActiveTab: (tab: PropertiesPanelTab) => IUiStore

  /**
   * @description context menu items. Usually it's empty array but when context menu open we pass items
   */
  contextMenuItems: Array<ContextMenuItem | 'divider'>
  contextMenuCoords: Point2D
  isContextMenuVisible: boolean
  openContextMenu: (
    items: Array<ContextMenuItem | 'divider'>,
    event: React.MouseEvent<Element, MouseEvent>
  ) => void
  closeContextMenu: () => void

  commands: commands.ICommands

  allowedAction: 'full' | 'view'

  updateAllowedAction: (allowedAction: 'full' | 'view') => IUiStore
}

export class UiStore implements IUiStore {
  constructor() {
    makeAutoObservable(this)
  }

  propertiesPanelTabs: IUiStore['propertiesPanelTabs'] = [
    'Properties',
    'Presets',
  ]

  propertiesPanelActiveTab: IUiStore['propertiesPanelActiveTab'] = 'Properties'

  propertiesPanelUpdateActiveTab: IUiStore['propertiesPanelUpdateActiveTab'] = (
    newValue
  ) => {
    this.propertiesPanelActiveTab = newValue
    return this
  }

  contextMenuItems: IUiStore['contextMenuItems'] = []

  contextMenuCoords: IUiStore['contextMenuCoords'] = {
    x: 0,
    y: 0,
  }

  get isContextMenuVisible() {
    return this.contextMenuItems.length > 0
  }

  openContextMenu: IUiStore['openContextMenu'] = (items, event) => {
    event.stopPropagation()
    event.preventDefault()
    this.contextMenuItems = items
    this.contextMenuCoords = {
      x: event.clientX - 2,
      y: event.clientY - 4,
    }
    return this
  }

  closeContextMenu: IUiStore['closeContextMenu'] = () => {
    this.contextMenuItems = []
    this.contextMenuCoords = {
      x: 0,
      y: 0,
    }
    return this
  }

  commands: IUiStore['commands'] = (() => {
    return {
      // @TODO: implement or delete
      showNotification: console.log,

      updateProperty: commands.createUpdateValue({
        beforeUpdate: () => {},
        afterUpdate: (property) => {
          if (property.hasAnimation === false) {
            // @TODO: IMPORTANT inject via DI don't use it like this
            toast(
              'Properties have no keyframes and managed by Figma. All changes will be lost on next project update',
              {
                toastId: 'updateProperty.warning',
                type: 'warning',
                autoClose: 4000,
              }
            )
          }
        },
      }),

      toggleKeyframe: commands.createToggleKeyframe(),

      togglePropertyVisibility: commands.createTogglePropertyVisibility(),
    }
  })()

  allowedAction: IUiStore['allowedAction'] = 'full'

  updateAllowedAction: IUiStore['updateAllowedAction'] = (allowedAction) => {
    this.allowedAction = allowedAction
    return this
  }
}

export const UiContext = React.createContext<IUiStore>(null as any)

export const useUi = (): IUiStore => {
  const context = React.useContext(UiContext)
  if (context == null) {
    throw new TypeError(
      'UiContext not found. Probably you forgot to wrap your components tree with <UiProvider> component'
    )
  }
  return context
}

const defaultUiStore = new UiStore()
// @TODO: EDITOR set proper value
// @TODO: remove in the next release
defaultUiStore.updateAllowedAction('full')

export interface IProps {
  uiStore?: IUiStore
}
export const UiProvider: React.FCC<IProps> = ({
  uiStore = defaultUiStore,
  children,
}) => {
  return <UiContext.Provider value={uiStore}>{children}</UiContext.Provider>
}
