import * as React from 'react'
import * as styles from './ghost-slider-view.scss'

export interface IProps {
  left: number
  label: string
  onClick: () => void
}
export const GhostSliderView: React.FC<IProps> = ({ left, label, onClick }) => (
  <div className={styles.container} style={{ left }}>
    <div className={styles['ghost-label']} onClick={onClick}>
      <p className={styles['ghost-label-text']}>{label}</p>
    </div>
    <div className={styles['ghost-marker']} />
  </div>
)
