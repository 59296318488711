import { BezierPoint } from './bezier-point'

type Output = {
  // close
  c: boolean
  // vertices
  v: number[][]
  // in tangents
  i: number[][]
  // out tangents
  o: number[][]
}

/**
 * @description convert provided bezier points to svg path
 */
export function bezierPointsToLottiePoints(payload: {
  regions: {
    points: BezierPoint[]
    isClosed: boolean
  }[]
}): Output {
  const { regions } = payload

  let result: Output = {
    c: false,
    v: [],
    i: [],
    o: [],
  }

  if (regions.length === 0) {
    return result
  }

  for (let i = 0; i < regions.length; i += 1) {
    const region = regions[i]
    const points = region.points.map((point) => point.toJson())
    const pointsCount = points.length
    const isPathClosed = region.isClosed

    for (let j = 0; j < pointsCount; j += 1) {
      const point = points[j]
      result.v.push([point.point.x, point.point.y])
      result.i.push([point.startTangent.x, point.startTangent.y])
      result.o.push([point.endTangent.x, point.endTangent.y])
    }

    result.c = isPathClosed

    // @NOTE: required to properly handle in/out tangents for closed shapes.
    // Because lottie renders them a bit differently.
    if (isPathClosed) {
      result.i[0] = [0, 0]
      result.o[result.o.length - 1] = [0, 0]
    }
  }

  return result
}
