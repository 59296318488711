import {
  BlurRadiusComponent,
  DropShadow,
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  Entity,
  NumberKeyframe,
  ParentRelationAspect,
  Point2dKeyframe,
  RgbaKeyframe,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
  UpdatesSystem,
  VisibleInViewportComponent,
  commitUndo,
  getAnimatableValue,
  getValueNumber,
  getValuePoint2d,
  getValueRgba,
  setEffectType,
} from '@aninix-inc/model'
import {
  PropertyRowV2,
  Select,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { useEntities, useEntity, useProject } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { Group } from '../../common/group'
import { NumberValue } from '../../values/number'
import { Point2dValue } from '../../values/point-2d'
import { RgbaValue } from '../../values/rgba'
import { KeyframesPropertyControl } from '../keyframes-property-control'
import { effectSlices } from './effect-slices'
import * as styles from './index.scss'

const EffectContent: React.FC<{
  index: number
}> = ({ index }) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time } = useNodePropertiesPanel()
  const { effectRows } = React.useContext(EffectsContext)
  const effects = effectRows[index]

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <EffectContentEditable effects={effects} time={time} />
      ) : (
        <EffectContentDisplay effects={effects} time={time} />
      )}
    </div>
  )
}

EffectContent.displayName = 'EffectContent'

const EffectContentEditable: React.FC<{
  effects: Entity[]
  time: number
}> = ({ effects, time }) => {
  useEntities(effects)
  useEntity(effects[0])
  const type = effects[0].getComponentOrThrow(EffectTypeComponent).value

  switch (type) {
    case EffectType.DropShadow:
    case EffectType.InnerShadow:
      return (
        <div>
          <div className={styles.row}>
            <Point2dValue
              components={effects.map((e) =>
                e.getComponentOrThrow(ShadowOffsetComponent)
              )}
              time={time}
              iconX={<span>X</span>}
              iconY={<span>Y</span>}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponentOrThrow(ShadowOffsetComponent)
              )}
              time={time}
              KeyframeConstructor={Point2dKeyframe}
              valueGetter={getValuePoint2d}
            />
          </div>

          <div className={styles.row}>
            <NumberValue
              components={effects.map((e) =>
                e.getComponentOrThrow(ShadowRadiusComponent)
              )}
              time={time}
              icon={<span style={{ width: '100%', paddingLeft: 4 }}>Blur</span>}
              min={0}
              iconWidth={96}
              width={192}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponentOrThrow(ShadowRadiusComponent)
              )}
              time={time}
              KeyframeConstructor={NumberKeyframe}
              valueGetter={getValueNumber}
            />
          </div>

          {/* <div className={styles.row}>
            <NumberValue
              components={effects.map((e) =>
                e.getComponent(ShadowSpreadComponent)
              )}
              time={time}
              icon={
                <span style={{ width: '100%', paddingLeft: 4 }}>
                  Spread
                </span>
              }
              iconWide
              width={96}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponent(ShadowSpreadComponent)
              )}
              time={time}
            />
          </div> */}

          <div className="pt-1">
            <div className={styles.row}>
              <RgbaValue
                components={effects.map((e) =>
                  e.getComponentOrThrow(ShadowColorComponent)
                )}
                time={time}
              />

              <KeyframesPropertyControl
                components={effects.map((e) =>
                  e.getComponentOrThrow(ShadowColorComponent)
                )}
                time={time}
                KeyframeConstructor={RgbaKeyframe}
                valueGetter={getValueRgba}
              />
            </div>
          </div>
        </div>
      )

    case EffectType.BackgroundBlur:
    case EffectType.LayerBlur:
      return (
        <div>
          <div className={styles.row}>
            <NumberValue
              components={effects.map((e) =>
                e.getComponentOrThrow(BlurRadiusComponent)
              )}
              time={time}
              icon={<span style={{ width: '100%', paddingLeft: 4 }}>Blur</span>}
              min={0}
              iconWidth={96}
              width={192}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponentOrThrow(BlurRadiusComponent)
              )}
              time={time}
              KeyframeConstructor={NumberKeyframe}
              valueGetter={getValueNumber}
            />
          </div>
        </div>
      )

    default: {
      const never: never = type
      throw new Error(`Should never reach: "${never}"`)
    }
  }
}

EffectContentEditable.displayName = 'EffectContentEditable'

const EffectContentDisplay: React.FC<{
  effects: Entity[]
  time: number
}> = React.memo(
  ({ effects, time }) => {
    useEntities(effects)
    useEntity(effects[0])
    const type = effects[0].getComponentOrThrow(EffectTypeComponent).value

    switch (type) {
      case EffectType.DropShadow:
      case EffectType.InnerShadow:
        return (
          <div>
            <div className={styles.row}>
              <Point2dValue
                components={effects.map((e) =>
                  e.getComponentOrThrow(ShadowOffsetComponent)
                )}
                time={time}
                iconX={<span>X</span>}
                iconY={<span>Y</span>}
              />

              <KeyframesPropertyControl
                components={effects.map((e) =>
                  e.getComponentOrThrow(ShadowOffsetComponent)
                )}
                time={time}
                KeyframeConstructor={Point2dKeyframe}
                valueGetter={getValuePoint2d}
              />
            </div>

            <div className={styles.row}>
              <NumberValue
                components={effects.map((e) =>
                  e.getComponentOrThrow(ShadowRadiusComponent)
                )}
                time={time}
                icon={
                  <span style={{ width: '100%', paddingLeft: 4 }}>Blur</span>
                }
                min={0}
                iconWidth={96}
                width={192}
              />

              <KeyframesPropertyControl
                components={effects.map((e) =>
                  e.getComponentOrThrow(ShadowRadiusComponent)
                )}
                time={time}
                KeyframeConstructor={NumberKeyframe}
                valueGetter={getValueNumber}
              />
            </div>

            {/* <div className={styles.row}>
            <NumberValue
              components={effects.map((e) =>
                e.getComponent(ShadowSpreadComponent)
              )}
              time={time}
              icon={
                <span style={{ width: '100%', paddingLeft: 4 }}>
                  Spread
                </span>
              }
              iconWide
              width={96}
            />

            <KeyframesPropertyControl
              components={effects.map((e) =>
                e.getComponent(ShadowSpreadComponent)
              )}
              time={time}
            />
          </div> */}

            <div className="pt-1">
              <div className={styles.row}>
                <RgbaValue
                  components={effects.map((e) =>
                    e.getComponentOrThrow(ShadowColorComponent)
                  )}
                  time={time}
                />

                <KeyframesPropertyControl
                  components={effects.map((e) =>
                    e.getComponentOrThrow(ShadowColorComponent)
                  )}
                  time={time}
                  KeyframeConstructor={RgbaKeyframe}
                  valueGetter={getValueRgba}
                />
              </div>
            </div>
          </div>
        )

      case EffectType.BackgroundBlur:
      case EffectType.LayerBlur:
        return (
          <div>
            <div className={styles.row}>
              <NumberValue
                components={effects.map((e) =>
                  e.getComponentOrThrow(BlurRadiusComponent)
                )}
                time={time}
                icon={
                  <span style={{ width: '100%', paddingLeft: 4 }}>Blur</span>
                }
                min={0}
                iconWidth={96}
                width={192}
              />

              <KeyframesPropertyControl
                components={effects.map((e) =>
                  e.getComponentOrThrow(BlurRadiusComponent)
                )}
                time={time}
                KeyframeConstructor={NumberKeyframe}
                valueGetter={getValueNumber}
              />
            </div>
          </div>
        )

      default: {
        const never: never = type
        throw new Error(`Should never reach: "${never}"`)
      }
    }
  },
  (prev, next) => {
    if (prev.effects.length !== next.effects.length) return false
    for (let i = 0; i < prev.effects.length; i += 1) {
      if (prev.effects[i].id !== next.effects[i].id) return false
      const prevEffect = prev.effects[i] as Entity<EffectType>
      const nextEffect = next.effects[i] as Entity<EffectType>
      const prevType = prevEffect.getComponentOrThrow(EffectTypeComponent).value
      const nextType = nextEffect.getComponentOrThrow(EffectTypeComponent).value
      if (prevType !== nextType) return false
      switch (prevType) {
        case EffectType.DropShadow:
        case EffectType.InnerShadow:
          const prevRadiusComp = prevEffect.getComponentOrThrow(
            ShadowRadiusComponent
          )
          const nextRadiusComp = nextEffect.getComponentOrThrow(
            ShadowRadiusComponent
          )
          if (
            getAnimatableValue(prevRadiusComp, prev.time) !==
            getAnimatableValue(nextRadiusComp, next.time)
          )
            return false
          const prevOffsetComp = prevEffect.getComponentOrThrow(
            ShadowOffsetComponent
          )
          const nextOffsetComp = nextEffect.getComponentOrThrow(
            ShadowOffsetComponent
          )
          if (
            !R.equals(
              getAnimatableValue(prevOffsetComp, prev.time),
              getAnimatableValue(nextOffsetComp, next.time)
            )
          )
            return false
          const prevColorComp =
            prevEffect.getComponentOrThrow(ShadowColorComponent)
          const nextColorComp =
            nextEffect.getComponentOrThrow(ShadowColorComponent)
          if (
            !R.equals(
              getAnimatableValue(prevColorComp, prev.time),
              getAnimatableValue(nextColorComp, next.time)
            )
          )
            return false

          const prevShadowKeyframesType = getKeyframesType(
            [prevRadiusComp, prevOffsetComp, prevColorComp],
            prev.time
          )
          const nextShadowKeyframesType = getKeyframesType(
            [nextRadiusComp, nextOffsetComp, nextColorComp],
            next.time
          )
          if (prevShadowKeyframesType !== nextShadowKeyframesType) return false
          return true
        case EffectType.BackgroundBlur:
        case EffectType.LayerBlur:
          const prevBlurComp =
            prevEffect.getComponentOrThrow(BlurRadiusComponent)
          const nextBlurComp =
            nextEffect.getComponentOrThrow(BlurRadiusComponent)
          if (
            getAnimatableValue(prevBlurComp, prev.time) !==
            getAnimatableValue(nextBlurComp, next.time)
          )
            return false
          const prevBlurKeyframesType = getKeyframesType(
            [prevBlurComp],
            prev.time
          )
          const nextBlurKeyframesType = getKeyframesType(
            [nextBlurComp],
            next.time
          )
          if (prevBlurKeyframesType !== nextBlurKeyframesType) return false
          return true
        default:
          return true
      }
    }
    return true
  }
)

EffectContentDisplay.displayName = 'EffectContentDisplay'

const Effect: React.FC<{
  type: EffectType
  effects: Entity<unknown>[]
  index: number
}> = React.memo(
  ({ type, effects, index }) => {
    const project = useProject()
    const updates = project.getSystemOrThrow(UpdatesSystem)

    return (
      <div className={styles.row}>
        <Group
          title={
            <Select
              className="pl-[2px]"
              activeValueId={type}
              options={[
                {
                  id: EffectType.InnerShadow,
                  title: 'Inner shadow',
                },
                {
                  id: EffectType.DropShadow,
                  title: 'Drop shadow',
                },
                {
                  id: EffectType.LayerBlur,
                  title: 'Layer blur',
                  disabled: effects.some(
                    (effect) =>
                      effect.getComponentOrThrow(EffectTypeComponent).value ===
                      EffectType.LayerBlur
                  ),
                },
                {
                  id: EffectType.BackgroundBlur,
                  title: 'Background blur',
                  disabled: effects.some(
                    (effect) =>
                      effect.getComponentOrThrow(EffectTypeComponent).value ===
                      EffectType.BackgroundBlur
                  ),
                },
              ]}
              onChange={(id) => {
                updates.batch(() => {
                  effects.forEach((effect) => {
                    setEffectType(effect, id as EffectType)
                  })
                })
                commitUndo(project)
              }}
            />
          }
          isOpen
          headerButtons={
            <>
              <buttons.Icon
                onClick={() => {
                  updates.batch(() => {
                    effects.forEach((effect) => {
                      effect.updateComponent(
                        VisibleInViewportComponent,
                        (value) => !value
                      )
                    })
                  })
                  commitUndo(project)
                }}
              >
                <icons.Visible
                  visible={
                    effects[0].getComponentOrThrow(VisibleInViewportComponent)
                      .value
                  }
                  size={iconSize}
                />
              </buttons.Icon>
              <buttons.Icon
                onClick={() => {
                  updates.batch(() => {
                    effects.forEach((effect) => {
                      effect
                        .getAspectOrThrow(ParentRelationAspect)
                        .getParentEntity()
                        ?.getAspectOrThrow(EffectsRelationsAspect)
                        .removeChild(effect.id)
                    })
                  })
                  commitUndo(project)
                }}
              >
                <icons.Remove />
              </buttons.Icon>
            </>
          }
          sliderInHeader
        >
          <EffectContent index={index} />
        </Group>
      </div>
    )
  },
  (prev, next) => prev.type === next.type && prev.index === next.index
)

Effect.displayName = 'Effect'
const iconSize = {
  x: 16,
  y: 16,
}

type EffectsContextType = {
  effectRows: Entity[][]
}

const EffectsContext = React.createContext<EffectsContextType>(
  null as unknown as EffectsContextType
)

export const Effects: React.FC = () => {
  const { nodes } = useNodePropertiesPanel()
  useEntities(nodes)
  const project = nodes[0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)

  const effectRows = effectSlices(nodes)

  const create = React.useCallback(() => {
    updates.batch(() => {
      nodes.forEach((layer) => {
        layer
          .getAspectOrThrow(EffectsRelationsAspect)
          .addChild(project.createEntity(DropShadow))
      })
    })
    commitUndo(project)
  }, [nodes, project, updates])

  return (
    <EffectsContext.Provider value={{ effectRows }}>
      <PropertyRowV2
        name="Effects"
        headerButtons={
          <buttons.Icon onClick={create}>
            <icons.Add />
          </buttons.Icon>
        }
        wideInputs={
          effectRows.length === 0 ? undefined : (
            <div className={styles.container}>
              {effectRows.map((effects, idx) => (
                <Effect
                  key={idx}
                  type={
                    effects[0].getComponentOrThrow(EffectTypeComponent).value
                  }
                  index={idx}
                  effects={effects}
                />
              ))}
            </div>
          )
        }
        empty={effectRows.length === 0}
      />
    </EffectsContext.Provider>
  )
}
