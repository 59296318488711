import * as React from 'react'

const SHOW_HITBOX = false

export const RotationHandle: React.FCC<{
  point: paper.Point | null
  angle: number | null
  zoom: number
  onMouseDown?: (e: React.MouseEvent<SVGElement>) => void
  onMouseMove?: (e: React.MouseEvent<SVGElement>) => void
  onMouseLeave?: (e: React.MouseEvent<SVGElement>) => void
}> = (props) => {
  const { zoom, point, angle, ...handlers } = props
  if (!point || angle === null) return null
  const hitBoxSize = 25 / zoom

  const points = [
    {
      x: point.x - hitBoxSize,
      y: point.y,
      cursorOffset: -90,
    },
    {
      x: point.x - hitBoxSize,
      y: point.y - hitBoxSize,
      cursorOffset: 0,
    },
    {
      x: point.x,
      y: point.y - hitBoxSize,
      cursorOffset: 90,
    },
    {
      x: point.x,
      y: point.y,
      cursorOffset: 180,
    },
  ]
  return (
    <g transform={`rotate(${angle} ${point.x} ${point.y})`}>
      {points.map((point) => (
        <rect
          key={`${point.x}-${point.y}`}
          x={point.x}
          y={point.y}
          width={hitBoxSize}
          height={hitBoxSize}
          fill={SHOW_HITBOX ? 'red' : 'transparent'}
          opacity={SHOW_HITBOX ? 0.5 : 0}
          data-cursor-offset={point.cursorOffset}
          {...handlers}
        />
      ))}
    </g>
  )
}
