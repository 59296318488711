import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Minimize: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="10"
      y="10"
      width="12"
      height="3"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M10 14.5V22.5H22V14.5"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeDasharray="1 1"
    />
  </svg>
)
