import React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 16,
  y: 16,
}

export const ArrowRight: React.FCC<IIcon> = ({
  size = defaultSize,
  className,
  color = '#CED3D9',
}) => (
  <svg height={size.x} width={size.y}>
    <svg
      className={className}
      height={size.x}
      width={size.y}
      fill="none"
      viewBox="0 0 16 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 12.5L10 8.5L6 4.5"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
    </svg>
  </svg>
)
