import {
  Node,
  NodeType,
  PropertyGroup,
  PropertyGroupType,
} from '@aninix-inc/model/legacy'
import * as R from 'ramda'

import { Entity, MaskComponent, NodeTypeComponent } from '@aninix-inc/model'
import { LayerType } from '../components/common/icons'

export function getLayerType(node: Node): LayerType {
  const type = node.type

  if (node.isMask) {
    return LayerType.Mask
  }

  if (
    R.any(
      (fill: PropertyGroup) => fill.type === PropertyGroupType.Image,
      // @ts-ignore
      node.fills?.children || []
    ) ||
    R.any(
      (stroke: PropertyGroup) => stroke.type === PropertyGroupType.Image,
      // @ts-ignore
      node.strokes?.children || []
    )
  ) {
    return LayerType.VectorWithImageFill
  }

  if (type === NodeType.Ellipse) {
    return LayerType.Ellipse
  }

  if (type === NodeType.Rectangle) {
    return LayerType.Rectangle
  }

  if (type === NodeType.Polygon || type === NodeType.Star) {
    return LayerType.Polygon
  }

  if (type === NodeType.Frame) {
    return LayerType.Frame
  }

  if (type === NodeType.Instance) {
    return LayerType.Instance
  }

  if (type === NodeType.Group) {
    return LayerType.Group
  }

  if (type === NodeType.Text) {
    return LayerType.Text
  }

  return LayerType.Vector
}

export function getLayerTypeV2(node: Entity): LayerType {
  const type = node.getComponentOrThrow(NodeTypeComponent).value
  const isMask = node.getComponent(MaskComponent)?.value

  if (isMask) {
    return LayerType.Mask
  }

  if (
    R.any(
      (fill: PropertyGroup) => fill.type === PropertyGroupType.Image,
      // @ts-ignore
      node.fills?.children || []
    ) ||
    R.any(
      (stroke: PropertyGroup) => stroke.type === PropertyGroupType.Image,
      // @ts-ignore
      node.strokes?.children || []
    )
  ) {
    return LayerType.VectorWithImageFill
  }

  if (type === NodeType.Ellipse) {
    return LayerType.Ellipse
  }

  if (type === NodeType.Rectangle) {
    return LayerType.Rectangle
  }

  if (type === NodeType.Polygon || type === NodeType.Star) {
    return LayerType.Polygon
  }

  if (type === NodeType.Frame) {
    return LayerType.Frame
  }

  if (type === NodeType.Instance) {
    return LayerType.Instance
  }

  if (type === NodeType.Group) {
    return LayerType.Group
  }

  if (type === NodeType.Text) {
    return LayerType.Text
  }

  return LayerType.Vector
}
