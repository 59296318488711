import React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const KeyframeEaseIn: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_37641_14996)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.37121 3.11388C8.1662 2.90886 7.8338 2.90886 7.62879 3.11388L3.228 7.51467L2.48557 6.77224L6.88636 2.37145C7.50141 1.75641 8.49859 1.75641 9.11364 2.37145L13.6293 6.88709C14.2443 7.50213 14.2443 8.49932 13.6293 9.11436L9.11364 13.63C8.49859 14.245 7.50141 14.245 6.88636 13.63L4.62854 11.3722L5.37097 10.6298L7.62879 12.8876C7.8338 13.0926 8.1662 13.0926 8.37121 12.8876L12.8868 8.37194C13.0919 8.16692 13.0919 7.83453 12.8869 7.62951L8.37121 3.11388ZM0.5 15.0002C0.500001 12.9232 1.01961 11.0235 2.39645 9.64668C3.77329 8.26984 5.92293 7.50024 9 7.50024L9 8.50024C6.07707 8.50024 4.22671 9.23063 3.10355 10.3538C1.98039 11.477 1.5 13.0773 1.5 15.0002L0.5 15.0002Z"
        fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    </g>
    <defs>
      <clipPath id="clip0_37641_14996">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
