import {
  NodeType,
  NodeTypeComponent,
  NumberKeyframe,
  PointCountComponent,
  getAnimatableValue,
  getValueNumber,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 16,
  y: 16,
}

export const PointCount: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time, nodes } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) => n.hasComponent(PointCountComponent))

  const components = filteredNodes.map((l) =>
    l.getComponentOrThrow(PointCountComponent)
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const icon =
    filteredNodes[0].getComponentOrThrow(NodeTypeComponent).value ===
    NodeType.Polygon ? (
      <icons.propertiesPanel.PolygonPointCount size={iconSize} />
    ) : (
      <icons.propertiesPanel.StarPointCount size={iconSize} />
    )

  // @TODO replace with snapshot data
  const pointCounts = components.map((c) => getAnimatableValue(c, time))
  const keyframeType = getKeyframesType(components, time)

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <PointCountEditable time={time} components={components} icon={icon} />
      ) : (
        <PointCountDisplay
          time={time}
          components={components}
          icon={icon}
          pointCounts={pointCounts}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

PointCount.displayName = 'PointCount'

const PointCountEditable: React.FC<{
  time: number
  components: PointCountComponent[]
  icon: React.ReactNode
}> = ({ time, components, icon }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={components}
            time={time}
            icon={icon}
            min={3}
            format={(v) => Math.floor(v).toString()}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      }
    />
  )
}

PointCountEditable.displayName = 'PointCountEditable'

interface PointCountDisplayProps {
  time: number
  components: PointCountComponent[]
  icon: React.ReactNode
  pointCounts: number[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (
  prev: PointCountDisplayProps,
  next: PointCountDisplayProps
) => {
  if (prev.pointCounts.length !== next.pointCounts.length) return false
  if (prev.keyframeType !== next.keyframeType) return false
  for (let i = 0; i < prev.pointCounts.length; i++) {
    if (prev.pointCounts[i] !== next.pointCounts[i]) return false
  }
  return true
}

const PointCountDisplay: React.FC<PointCountDisplayProps> = React.memo(
  ({ time, components, icon }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <NumberValue
              components={components}
              time={time}
              icon={icon}
              min={3}
              format={(v) => Math.floor(v).toString()}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        }
      />
    )
  },
  propsAreEqual
)

PointCountDisplay.displayName = 'PointCountDisplay'
