import { useCurrentSpaceStore } from 'apps/web-app/src/use-cases'
import * as React from 'react'

export interface IProps {}
export const Avatar: React.FCC<IProps> = () => {
  const { currentUser } = useCurrentSpaceStore()

  return (
    <div className="h-6 w-6 overflow-hidden rounded-full">
      <img
        crossOrigin="anonymous"
        src={currentUser?.avatarUrl ?? 'images/defaultAvatar.png'}
      />
    </div>
  )
}

Avatar.displayName = 'Avatar'
