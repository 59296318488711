import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Share: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.5 9.5H10H16V10.5H10.5V21.5H21.5V16H22.5V22V22.5H22H10H9.5V22V10V9.5ZM21.5 11.2071L16.3536 16.3536L15.6464 15.6464L20.7929 10.5H18V9.5H22H22.5V10V14H21.5V11.2071Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
