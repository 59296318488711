import * as React from 'react'

import { Tabs, TTabs } from './tabs'
import { TabsContent } from './tabs/content'

export interface IProps {
  // @TODO: implement
  // activeTab?: 'inspect' | 'code'
  // onActiveTabChange: (tab: IProps['activeTab']) => void
}

export const Properties: React.FCC<IProps> = () => {
  const [selectedTab, setSelectedTab] = React.useState<TTabs>('inspect')

  return (
    <div className="flex h-full flex-col gap-0">
      <Tabs selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      <TabsContent selectedTab={selectedTab} />
    </div>
  )
}

Properties.displayName = 'Properties'
