import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { featureFlags } from '@aninix/core'
import { useLogger } from '@aninix/logger'
import * as React from 'react'

import { config } from '../../config'

type Payload = {
  projectId: string
}

export interface IErrorBoundaryInteractor {
  reloadPlugin: () => void
  contactEmail: string
  downloadProject: () => Promise<void>
  isDownloadProjectEnabled: boolean
  handleError: (error: Error) => void
}

export const useErrorBoundaryInteractor = ({
  projectId,
}: Payload): IErrorBoundaryInteractor => {
  const analytics = useAnalytics()
  const logger = useLogger()
  const reloadPlugin = React.useCallback(async () => {
    // @TODO: refactor via DI container so we can use realod in any environment?
    // For example what would happen if we would wrap app in tauri or electron?
    window.location.reload()
  }, [])

  const handleError: IErrorBoundaryInteractor['handleError'] =
    React.useCallback(
      async (error) => {
        logger.error('Unknown error happen and plugin crashed', error)

        if (config.environment === 'browser') {
          return
        }

        analytics.track({
          eventName: AnalyticsEvent.PluginCrashed,
          properties: {
            projectId,
            name: error.name,
            reason: error.message,
            stack: error.stack,
          },
        })
      },
      [analytics]
    )

  const downloadProject = React.useCallback(async () => {}, [])

  return {
    reloadPlugin,
    contactEmail: 'info@aninix.com',
    isDownloadProjectEnabled: featureFlags.downloadProjectInErrorBoundary,
    handleError,
    downloadProject,
  }
}
