import { Tooltip } from '@aninix/app-design-system'
import { Modal } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'
import { EditInFigmaModal } from './edit-in-figma-modal'

export interface IProps {}
export const EditInFigma: React.FCC<IProps> = () => {
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false)

  return (
    <>
      <Tooltip title="Import project to Figma" arrow>
        <button
          className="group relative flex h-8 w-8 cursor-default items-center justify-center"
          onClick={() => setIsModalOpen(true)}
        >
          <div className="absolute inset-0 z-0 rounded-lg bg-white opacity-0 transition-opacity duration-150 group-hover:!opacity-10" />
          <EditInFigmaIcon className={classNames('z-10 h-6 w-6 opacity-50')} />
        </button>
      </Tooltip>

      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        BackdropProps={{ color: '#0000004D' }}
      >
        <EditInFigmaModal onClose={() => setIsModalOpen(false)} />
      </Modal>
    </>
  )
}

EditInFigma.displayName = 'Edit in Figma'

const EditInFigmaIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4286 8.95238C12.0853 8.95238 12.7151 9.21326 13.1795 9.67764C13.6439 10.142 13.9048 10.7718 13.9048 11.4286C13.9048 12.0853 13.6439 12.7151 13.1795 13.1795C12.7151 13.6439 12.0853 13.9048 11.4286 13.9048C10.7718 13.9048 10.142 13.6439 9.67764 13.1795C9.21326 12.7151 8.95238 12.0853 8.95238 11.4286C8.95238 10.7718 9.21326 10.142 9.67764 9.67764C10.142 9.21326 10.7718 8.95238 11.4286 8.95238ZM11.4286 8.95238C12.0853 8.95238 12.7151 8.6915 13.1795 8.22712C13.6439 7.76275 13.9048 7.13292 13.9048 6.47619C13.9048 5.81946 13.6439 5.18964 13.1795 4.72526C12.7151 4.26088 12.0853 4 11.4286 4H6.47619C5.81946 4 5.18964 4.26088 4.72526 4.72526C4.26088 5.18964 4 5.81946 4 6.47619C4 7.13292 4.26088 7.76275 4.72526 8.22712C5.18964 8.6915 5.81946 8.95238 6.47619 8.95238M11.4286 8.95238H6.47619M6.47619 8.95238C5.81946 8.95238 5.18964 9.21326 4.72526 9.67764C4.26088 10.142 4 10.7718 4 11.4286C4 12.0853 4.26088 12.7151 4.72526 13.1795C5.18964 13.6439 5.81946 13.9048 6.47619 13.9048M6.47619 13.9048H8.95238M6.47619 13.9048C5.98645 13.9048 5.5077 14.05 5.10049 14.3221C4.69329 14.5942 4.37591 14.9809 4.18849 15.4334C4.00107 15.8858 3.95204 16.3837 4.04758 16.864C4.14312 17.3444 4.37896 17.7856 4.72526 18.1319C5.07156 18.4782 5.51278 18.714 5.99311 18.8096C6.47344 18.9051 6.97132 18.8561 7.42379 18.6687C7.87625 18.4812 8.26298 18.1639 8.53507 17.7566C8.80716 17.3494 8.95238 16.8707 8.95238 16.381V4M16.965 14.5905C17.1301 14.4254 17.3261 14.2944 17.5418 14.2051C17.7575 14.1157 17.9887 14.0697 18.2221 14.0697C18.4556 14.0697 18.6868 14.1157 18.9025 14.2051C19.1182 14.2944 19.3142 14.4254 19.4793 14.5905C19.6444 14.7556 19.7753 14.9516 19.8647 15.1673C19.954 15.383 20 15.6141 20 15.8476C20 16.0811 19.954 16.3123 19.8647 16.528C19.7753 16.7437 19.6444 16.9397 19.4793 17.1048L16.6094 20H14.0698V17.4603L16.965 14.5905Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
