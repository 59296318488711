import { DurationComponent, Root } from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import { useEntity, usePlayback, useProject } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import * as timeConverters from '../../../../helpers/timeConverters'
import { useFormattedTime } from '../../../properties-panel/components/formatted-time'
import { useUpdateTime } from '../use-update-time'
import { GhostSliderView } from './ghost-slider-view'
import { TimeSliderView } from './time-slider-view'

// TODO: refactor
const handlerWidth = 6

export interface IProps {
  parentTrackWidth: number
}
export const TimeSlider: React.FCC<IProps> = observer(
  ({ parentTrackWidth }) => {
    const project = useProject()
    const root = project.getEntityByTypeOrThrow(Root)
    useEntity(root)
    const playback = usePlayback()
    const projectDuration = root.getComponentOrThrow(DurationComponent).value

    const updateTime = useUpdateTime()

    const releaseGhost = React.useCallback(() => {
      playback.updateTime(playback.ghostTime ?? 0).resetGhostTime()
    }, [])

    const initialTime = React.useRef<number>(0)

    const { offsetX, isListening, startListen } = useMouseMove()

    const time = useFormattedTime(playback.time)
    const ghostTime = useFormattedTime(playback.ghostTime ?? -1)

    React.useEffect(() => {
      if (isListening) {
        const timeOffset = timeConverters.convertPixelsToTime({
          pixels: offsetX,
          projectDuration: projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
        })

        updateTime(timeOffset + initialTime.current)
      }
    }, [isListening, offsetX])

    React.useEffect(() => {
      if (isListening === false) {
        initialTime.current = playback.time as number
      }
    }, [isListening, playback.time])

    const handleMouseDown = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        startListen(e)
      },
      [startListen]
    )

    const leftCoordForTime = React.useMemo(
      () =>
        timeConverters.convertTimeToPixels({
          time: playback.time,
          projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
        }) + handlerWidth,
      [playback.time, projectDuration, parentTrackWidth]
    )

    const leftCoordForGhost = React.useMemo(() => {
      return (
        timeConverters.convertTimeToPixels({
          time: ghostTime.value,
          projectDuration,
          trackWidth: parentTrackWidth - handlerWidth * 2,
        }) + handlerWidth
      )
    }, [ghostTime.value, projectDuration, parentTrackWidth])

    return (
      <>
        {ghostTime.value !== -1 && (
          <GhostSliderView
            left={leftCoordForGhost}
            label={`${ghostTime.value}${ghostTime.suffix}`}
            onClick={releaseGhost}
          />
        )}

        <TimeSliderView
          left={leftCoordForTime}
          label={`${time.value}${time.suffix}`}
          onMouseDown={handleMouseDown}
        />
      </>
    )
  }
)

TimeSlider.displayName = 'TimeSlider'
