import { genericKeyframe } from '@aninix-inc/model/legacy'
import { makeAutoObservable, runInAction, toJS } from 'mobx'
import { IDisposer, IJsonPatch, applyPatch, onPatch } from 'mobx-state-tree'
import * as R from 'ramda'

const DEBUG = false

/**
 * @description manage undo/redo history state.
 * By default it's not tracking any changes and we should explicitly mention it with
 * @example `undoManager.withUndo(() => {})` or
 * `undoManager.startUndoGroup()` and then `undoManager.endUndoGroup()`
 */
export class UndoManager {
  get recordingUndoGroup() {
    return this.recordingUndoGroupStack > 0
  }

  private recordingUndoGroupStack: number = 0

  /**
   * @description target store to listen for changes
   */
  private store: any = undefined

  /**
   * @description array of commits in format [[patch], [patch, patch], ...]
   */
  private commits: IJsonPatch[][] = []

  /**
   * @description array of reverse commits in format [[patch], [patch, patch], ...]
   */
  private reverseCommits: IJsonPatch[][] = []

  /**
   * @description current history pointer
   */
  private pointer: number = 0

  /**
   * @description max number of commits
   */
  private historyLimit: number = 100

  private subscription: IDisposer

  constructor(payload: { store: any; historyLimit?: number }) {
    makeAutoObservable(this, {
      // @ts-ignore
      store: false,
    })

    this.store = payload.store

    if (payload.historyLimit) {
      this.historyLimit = payload.historyLimit
    }

    this.subscription = onPatch(this.store, this.addPatch)
  }

  /**
   * @description add patch to latest commit
   */
  private addPatch = (patch: IJsonPatch, reversePatch: IJsonPatch) => {
    if (this.recordingUndoGroup === false) {
      return this
    }

    // @NOTE: required to add value data when keyframes removed
    let newPatch = R.clone(patch)
    let newReversePatch = R.clone(reversePatch)

    if (patch.op === 'remove') {
      // @ts-ignore
      newPatch.value = R.clone(reversePatch.value)
    }

    if (patch.op === 'add') {
      // @ts-ignore
      newReversePatch.value = R.clone(patch.value)
    }

    this.commits[this.commits.length - 1].push(newPatch)
    this.reverseCommits[this.reverseCommits.length - 1].push(newReversePatch)

    return this
  }

  /**
   * @description apply patch to current store
   */
  private applyPatch = (patch: IJsonPatch) => {
    const model = toJS(patch)

    // @NOTE: handle addition of keyframes
    if (model.value?.modelType === 'keyframe' && model.op === 'add') {
      const oldId = model.value?.id
      // @NOTE: change id of current keyframe
      const newValue = genericKeyframe.create(R.dissoc('id', model.value))
      const newPath = model.path.replace(oldId, newValue.id)
      const withNewPath = R.assocPath(['path'], newPath, {
        ...model,
        value: newValue,
      })

      // @NOTE: replace oldId with newId in entire store
      this.commits.forEach((commit) => {
        commit.forEach((patch) => {
          if (patch.path.includes(oldId)) {
            // @ts-ignore
            patch.path = patch.path.replace(oldId, newValue.id)

            if (patch.value?.modelType === 'keyframe') {
              patch.value.id = newValue.id
            }
          }
        })
      })
      this.reverseCommits.forEach((commit) => {
        commit.forEach((patch) => {
          if (patch.path.includes(oldId)) {
            // @ts-ignore
            patch.path = patch.path.replace(oldId, newValue.id)

            if (patch.value?.modelType === 'keyframe') {
              patch.value.id = newValue.id
            }
          }
        })
      })

      applyPatch(this.store, withNewPath)
      return
    }

    applyPatch(this.store, model)

    return this
  }

  /**
   * @description apply full commit from history
   */
  private applyCommit = (commit: IJsonPatch[]) => {
    runInAction(() => {
      commit.forEach(this.applyPatch)
    })

    return this
  }

  private get undoPointer() {
    return this.pointer - 1
  }

  private get redoPointer() {
    return this.pointer
  }

  get canUndo() {
    return this.reverseCommits.length > 0 && this.undoPointer >= 0
  }

  get canRedo() {
    return this.commits.length > 0 && this.redoPointer < this.commits.length
  }

  undo = () => {
    if (DEBUG) {
      console.log('[undoManager] undo')
    }

    if (this.canUndo === false) {
      throw new Error('[UndoManager] Undo is unavailable')
    }

    // @NOTE: required to end undo group if current undo group accidentely not ended automatically.
    // This allows to prevent data loss.
    if (this.recordingUndoGroup) {
      this.endUndoGroup()
    }

    this.applyCommit(this.nextUndoCommit)
    this.pointer = this.undoPointer

    if (DEBUG) {
      console.log('commits:', this.commits.length)
    }

    return this
  }

  redo = () => {
    if (DEBUG) {
      console.log('[undoManager] redo')
    }

    if (this.canRedo === false) {
      throw new Error('[UndoManager] Redo is unavailable')
    }

    // @NOTE: required to end undo group if current undo group accidentely not ended automatically.
    // This allows to prevent data loss.
    if (this.recordingUndoGroup) {
      this.endUndoGroup()
    }

    this.applyCommit(this.nextRedoCommit)
    this.pointer = this.redoPointer + 1

    if (DEBUG) {
      console.log('commits:', this.commits.length)
    }

    return this
  }

  private removeHistory = () => {
    this.commits.splice(this.pointer, this.commits.length - this.pointer)
    this.reverseCommits.splice(
      this.pointer,
      this.reverseCommits.length - this.pointer
    )

    if (this.pointer + 1 === this.historyLimit) {
      this.commits.shift()
      this.reverseCommits.shift()
      this.pointer -= 1
    }

    return this
  }

  startUndoGroup = () => {
    if (DEBUG) {
      console.log(
        '[undoManager] startUndoGroup, recordingUndoGroupStack count',
        this.recordingUndoGroupStack
      )
    }

    this.recordingUndoGroupStack += 1

    if (this.recordingUndoGroupStack > 1) {
      return
    }

    this.removeHistory()

    this.pointer += 1
    this.commits.push([])
    this.reverseCommits.push([])

    return this
  }

  endUndoGroup = () => {
    if (DEBUG) {
      console.log('[undoManager] endUndoGroup')
    }

    // @NOTE: required to handle issues where we call endUndoGroup multiple times.
    // Which is anti-pattern.
    this.recordingUndoGroupStack = R.max(0, this.recordingUndoGroupStack - 1)

    if (this.recordingUndoGroupStack === 0) {
      return
    }

    if (
      R.isEmpty(R.last(this.commits)) &&
      R.isEmpty(R.last(this.reverseCommits))
    ) {
      this.pointer -= 1
      this.commits.pop()
      this.reverseCommits.pop()
    }

    return this
  }

  withUndo = async (callback: () => void | Promise<void>) => {
    this.startUndoGroup()
    runInAction(() => {
      callback()
    })
    this.endUndoGroup()
  }

  get nextUndoCommit() {
    if (this.reverseCommits[this.undoPointer] == null) {
      return []
    }

    return R.reverse(this.reverseCommits[this.undoPointer])
  }

  get nextRedoCommit() {
    return this.commits[this.redoPointer] ?? []
  }

  updateStore = (payload: { store: any; historyLimit?: number }) => {
    this.subscription()

    this.store = payload.store

    if (payload.historyLimit) {
      this.historyLimit = payload.historyLimit
    }

    this.subscription = onPatch(this.store, this.addPatch)

    return this
  }
}
