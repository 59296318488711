/* eslint-disable jsx-a11y/no-static-element-interactions */
import classNames from 'classnames'
import React from 'react'

import { Loader } from '../loader'
import * as styles from './index.scss'

export type TMenuItem = {
  content: React.ReactNode
  onClick: () => void
  icon?: React.ReactNode
  rightPart?: React.ReactNode
  disabled?: boolean
  children?: TMenuItem[][]
  loading?: boolean
}
export interface IProps {
  menuItems: TMenuItem[][]
  width?: string
}

const NestedMenu: React.FCC<IProps> = ({ menuItems, width = '240px' }) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [hoveredItemId, setHoveredItemId] = React.useState<string | null>(null)

  const handlePopoverOpen = React.useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>, id: React.ReactNode) => {
      setAnchorEl(event.currentTarget)

      if (id != null) {
        setHoveredItemId(id.toString())
      }
    },
    []
  )

  const handlePopoverClose = React.useCallback(() => {
    setHoveredItemId(null)
  }, [])

  return (
    <div
      className={styles.menu}
      style={{ width }}
      onMouseLeave={handlePopoverClose}
    >
      {menuItems.map((group, idx1) => (
        <div key={idx1} className={styles.group}>
          {group.map((item, idx2) => (
            <div key={idx2} className={styles.position}>
              <div
                onMouseEnter={(e) => {
                  if (item.disabled) {
                    return
                  }

                  handlePopoverOpen(e, item.content)
                }}
                className={classNames(styles['menu-item'], {
                  [styles['menu-item-disabled']]: item.disabled,
                  [styles.hover]: hoveredItemId === item.content,
                })}
                onClick={item.onClick}
              >
                <div
                  className="flex items-center justify-start"
                  style={{ maxWidth: item.rightPart ? '70%' : '100%' }}
                >
                  {item.icon && (
                    <div className={styles['list-item-icon']}>{item.icon}</div>
                  )}

                  <span className={styles.content}>
                    {item.loading && (
                      <Loader
                        color="white"
                        size={12}
                        className={styles.loader}
                      />
                    )}

                    {item.content}
                  </span>
                </div>

                {item.rightPart && (
                  <span className={styles['right-part']}>{item.rightPart}</span>
                )}
              </div>

              {item.children &&
                !!anchorEl &&
                hoveredItemId === item.content && (
                  <div className={styles['popover-menu']}>
                    <NestedMenu menuItems={item.children} width={width} />
                  </div>
                )}
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}

export default NestedMenu
