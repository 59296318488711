import { usePlayback, useProject, useTools, useViewport } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useExportInteractor } from './interactor'
import { ExportRouter } from './router'

export interface IProps {}
export const Export: React.FCC<IProps> = observer(() => {
  const playback = usePlayback()
  const viewport = useViewport()
  const tools = useTools()
  const project = useProject()

  const interactor = useExportInteractor({
    project,
    tools,
    playback,
    viewport,
  })

  return <ExportRouter interactor={interactor} />
})

Export.displayName = 'Export'
