import { Entity } from '@aninix-inc/model'
import * as React from 'react'
import { Align } from './align'
import { Curve } from './curve'
import { Duration } from './duration'
import { Offset } from './offset'
import { Start } from './start'

export interface IProps {
  segments: [Entity, Entity][]
}
export const SegmentProperties: React.FCC<IProps> = ({ segments }) => (
  <>
    <Align segments={segments} />
    <Start segments={segments} />
    <Duration segments={segments} />
    <Curve segments={segments} />
    {segments.length > 1 && <Offset segments={segments} />}
  </>
)

SegmentProperties.displayName = 'SegmentProperties'
