import { DurationComponent, Root } from '@aninix-inc/model'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'
import { usePlayback, useProject } from '../../../../../../../stores'

export interface IProps {}
export const PlaybackProgress: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const playback = usePlayback()
  const duration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value

  const progress = playback.time / duration

  return (
    <div
      className={classNames(
        'absolute -bottom-1 -top-1 z-10 w-[1px] bg-accent',
        {
          //so last position sits in timeline
          ['-translate-x-[1px]']: progress === 1,
        }
      )}
      style={{ left: progress * 100 + '%' }}
    />
  )
})

PlaybackProgress.displayName = 'PlaybackProgress'
