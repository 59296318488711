import {
  LocalStorageIo,
  getAnonymousToken,
  getMe,
  setHttpClientToken,
} from '@aninix/api'
import { UserRole } from '@aninix/core'
import { Logger } from '@aninix/logger'
import { makeAutoObservable } from 'mobx'
import * as R from 'ramda'
import * as React from 'react'

import { authBroadcastChannel } from '../shared'

/**
 * Session store with required data from our servers and local storage.
 * It pull required data automatically.
 * To make sure store is fully loaded please refer to `sessionStore.isLoaded` property.
 */
export class SessionStore {
  /**
   * All stored tokens. Required to handle multiple accounts
   */
  tokens: Record<string, string> = {}

  /**
   * Current active user id
   */
  userId: string = ''

  /**
   * Current active team id
   */
  teamId: string = ''

  /**
   * Current user role
   */
  role: UserRole = UserRole.Anonymous

  /**
   * Current user name
   */
  name: string = ''

  /**
   * Current user email
   */
  email: string = ''

  /**
   * Current user avatar url
   */
  avatarUrl: string = '/images/defaultAvatar.png'

  state: 'loading' | 'error' | 'ready' = 'loading'

  get isInitiated() {
    return this.state === 'ready'
  }

  get token(): string {
    return this.tokens[this.userId]
  }

  constructor(
    private readonly dependencies: {
      localStorage: LocalStorageIo<{
        aa: string
        t: Record<string, string>
      }>
      logger: Logger
    }
  ) {
    makeAutoObservable(this)
  }

  public initiate = async (providedUserId?: string) => {
    this.updateState('loading')
    const data = await this.dependencies.localStorage.get()

    if (data == null) {
      await this.generateAnonymousToken()
      this.updateState('ready')
      return this
    }

    this.setUserId(providedUserId ?? data.aa).setTokens(data.t)

    // @NOTE: if active user has no token then retrieve new token
    if (this.token == null) {
      await this.generateAnonymousToken()
    }

    // @NOTE: set correct tokens to network clients
    setHttpClientToken(this.token)
    await this.updateUserId(this.userId)
    await this.refresh()
    this.updateState('ready')
    return this
  }

  /**
   * Get remote user
   */
  public refresh = async () => {
    try {
      // @TODO: provide as dependencies
      const { data: me } = await getMe()
      this.dependencies.logger.log('[identifyUser] me', me)

      // @NOTE: if received user id not equal to active user id then update token id
      if (me.id !== this.userId) {
        const newTokens = {
          ...R.omit([this.userId], this.tokens),
          [me.id]: this.tokens[this.userId],
        }
        this.setTokens(newTokens)
        await this.updateUserId(me.id)
        await this.dependencies.localStorage.set({
          aa: me.id,
          t: newTokens,
        })
      }

      // @NOTE: check required to verify user is anonymous
      if (me.figmaAccountId != null) {
        this.updateRole(
          me.plan === 'pro' ? UserRole.Pro : UserRole.Authenticated
        )
          .updateName(me.name ?? '')
          .updateAvatarUrl(me.avatarUrl ?? '/images/defaultAvatar.png')
          .updateEmail(me.email ?? '')
      } else {
        this.updateRole(UserRole.Anonymous)
      }

      // @TODO: provide as dependencies
      authBroadcastChannel.postMessage('authenticated')
      return this
    } catch (err: any) {
      this.dependencies.logger.error('[sessionStore] load error', err)

      // @NOTE: happen in cases when token was revoked
      if (err.data.message.includes('token_is_not_valid')) {
        this.dependencies.logger.error('[sessionStore] token was revoked', err)
        await this.generateAnonymousToken()
        return this
      }

      throw err
    }
  }

  private updateState = (state: SessionStore['state']) => {
    this.state = state
    return this
  }

  private setUserId = (userId: string) => {
    this.userId = userId
    return this
  }

  public updateUserId = async (userId: string) => {
    await this.dependencies.localStorage.update({
      aa: userId,
    })
    return this.setUserId(userId)
  }

  /**
   * @todo add update of team payment status
   */
  public updateTeamId = (teamId: string) => {
    this.teamId = teamId
    return this
  }

  private setTokens = (tokens: Record<string, string>) => {
    this.tokens = tokens
    return this
  }

  private updateName = (name: string) => {
    this.name = name
    return this
  }

  private updateEmail = (email: string) => {
    this.email = email
    return this
  }

  private updateAvatarUrl = (avatarUrl: string) => {
    this.avatarUrl = avatarUrl
    return this
  }

  private updateRole = (role: UserRole) => {
    this.role = role
    return this
  }

  public generateAnonymousToken = async (): Promise<void> => {
    const getAnonymousTokenResult = await getAnonymousToken()
    const newToken = getAnonymousTokenResult.data.token
    const newUserId = getAnonymousTokenResult.data.userId

    setHttpClientToken(newToken)

    const newTokens = {
      ...this.tokens,
      [newUserId]: newToken,
    }
    this.setUserId(newUserId).setTokens(newTokens)
    await this.dependencies.localStorage.set({
      aa: newUserId,
      t: newTokens,
    })
  }

  logout = async () => {
    await this.dependencies.localStorage.reset()
    authBroadcastChannel.postMessage('logged-out')
  }
}

const Context = React.createContext<SessionStore>(null as any)

export const useSessionStore = (): SessionStore => React.useContext(Context)

export const SessionStoreProvider: React.FCC<{ store: SessionStore }> = ({
  children,
  store,
}) => {
  return <Context.Provider value={store}>{children}</Context.Provider>
}
