import { useMutation, useQueryClient } from '@tanstack/react-query'

import { ApiPostCreatedResponse } from './helpers'
import { httpClient } from './http-client'

export function useCreateUserContract(userId: string) {
  const queryClient = useQueryClient()

  return useMutation({
    async mutationFn() {
      return httpClient.post<
        ApiPostCreatedResponse<'/users/{userId}/contracts'>
      >(`/users/${userId}/contracts`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['contracts'] })
    },
  })
}
