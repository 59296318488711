import {
  StrokeAlign,
  StrokeCap,
  StrokeJoin,
  VectorPath,
} from '@aninix-inc/model/legacy'
import { paper } from '@aninix-inc/renderer'
import { OffsetOptions, PaperOffset } from 'paperjs-offset'

type Payload = {
  data: VectorPath[]
  strokeWeight: number
  strokeAlign?: StrokeAlign
  strokeCap?: StrokeCap
  strokeJoin?: StrokeJoin
}

export function getStrokeShape({
  data,
  strokeWeight,
  strokeAlign = StrokeAlign.Center,
  strokeCap = StrokeCap.None,
  strokeJoin = StrokeJoin.Miter,
}: Payload): VectorPath[] {
  return data.map((_path) => {
    const path = new paper.Path(_path.data)

    const isClosed = _path.data.toLowerCase().includes('z')

    const options: OffsetOptions = {
      cap: strokeCap.toLowerCase() as any,
      join: strokeJoin.toLowerCase() as any,
    }

    if (isClosed) {
      if (strokeAlign === StrokeAlign.Center) {
        const originalPath = PaperOffset.offset(
          path,
          -strokeWeight / 2,
          options
        )
        const offsetPath = PaperOffset.offset(path, strokeWeight / 2, options)
        const outputPath = offsetPath.subtract(originalPath)

        return {
          data: (outputPath.exportSVG() as SVGElement).getAttribute('d')!,
          windingRule: _path.windingRule,
        }
      }

      if (strokeAlign === StrokeAlign.Inside) {
        const offsetPath = PaperOffset.offset(path, -strokeWeight, options)
        const outputPath = path.subtract(offsetPath)

        return {
          data: (outputPath.exportSVG() as SVGElement).getAttribute('d')!,
          windingRule: _path.windingRule,
        }
      }

      if (strokeAlign === StrokeAlign.Outside) {
        const offsetPath = PaperOffset.offset(path, strokeWeight, options)
        const outputPath = offsetPath.subtract(path)

        return {
          data: (outputPath.exportSVG() as SVGElement).getAttribute('d')!,
          windingRule: _path.windingRule,
        }
      }
    }

    // @TODO: add support of different stroke caps
    const outputPath = PaperOffset.offsetStroke(path, strokeWeight / 2, options)

    return {
      data: outputPath.pathData,
      windingRule: _path.windingRule,
    }
  })
}
