import { Point2D } from '@aninix-inc/model/legacy'
import { Tool, useTools, useViewport } from '@aninix/core/stores'
import { useGetComments } from '@aninix/core/use-cases/comments/use-get-comments'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'

import { CreateComment } from './create-comment'
import { filter, useCommentContext } from './use-cases/use-comment-context'
import {
  CommentGroup,
  mapCommentsToGroups,
} from './utils/map-comments-to-groups'
import { ViewportCommentBubble } from './viewport-comment-bubble'
import { ViewportGroupBubble } from './viewport-group-bubble'

const debug = false

export interface IProps {
  isShowingComments: boolean
  rightRatio?: number
}

export const CommentsWrapper: React.FCC<IProps> = observer(
  ({ children, isShowingComments, rightRatio = 0 }) => {
    const viewport = useViewport()
    const tools = useTools()

    const ref = React.useRef<HTMLDivElement>(null)
    const viewportRef = React.useRef<HTMLDivElement>(null)
    const createCommentRef = React.useRef<HTMLDivElement>(null)

    const [createCommentPosition, setCreateCommentPosition] =
      React.useState<Point2D>({ x: 0, y: 0 })

    const { selectedCommentId, setSelectedCommentId } = useCommentContext()

    const [isReadyToCreateComment, setIsReadyToCreateComment] =
      React.useState<boolean>(false)

    const handleMouseUp = React.useCallback(
      (e: MouseEvent) => {
        if (!isReadyToCreateComment) return
        if (selectedCommentId !== null) return
        if (!ref.current) return

        if (
          createCommentRef.current &&
          createCommentRef.current.contains(e.target as Node)
        )
          return

        const rect = ref.current.getBoundingClientRect()
        const x = e.clientX - rect.left
        const y = e.clientY - rect.top

        setCreateCommentPosition({
          x: x / viewport.zoom,
          y: y / viewport.zoom,
        })
      },
      [viewport, selectedCommentId, isReadyToCreateComment]
    )

    const handleMouseDown = React.useCallback(
      (e: MouseEvent) => {
        if (
          createCommentRef.current &&
          createCommentRef.current.contains(e.target as Node)
        )
          return

        if (tools.activeTool !== Tool.Comments) {
          return
        }

        let targetElement: any = e.target

        if (
          !viewportRef.current ||
          !viewportRef.current.contains(e.target as Node)
        ) {
          // console.log('not viewportRef child')
          setIsReadyToCreateComment(false)
          return
        }

        if (selectedCommentId !== null) {
          setIsReadyToCreateComment(false)
          return
        }

        // Traverse up to see if the target is a child of any 'viewport-comment-bubble' class element
        while (targetElement) {
          if (targetElement.classList.contains('viewport-comment-bubble')) {
            // console.log('im bubble child')
            setIsReadyToCreateComment(false)
            return
          }

          targetElement = targetElement.parentElement
        }

        setIsReadyToCreateComment((value) => !value)
      },
      [ref, selectedCommentId, viewportRef, createCommentRef, tools]
    )

    const handleEscDown = React.useCallback((event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsReadyToCreateComment(false)
        setSelectedCommentId(null)
      }
    }, [])

    React.useEffect(() => {
      if (isShowingComments) {
        document.addEventListener('mouseup', handleMouseUp)
        document.addEventListener('mousedown', handleMouseDown)
        document.addEventListener('keydown', handleEscDown)
      } else {
        document.removeEventListener('mouseup', handleMouseUp)
        document.removeEventListener('mousedown', handleMouseDown)
        document.removeEventListener('keydown', handleEscDown)
      }

      return () => {
        document.removeEventListener('mouseup', handleMouseUp)
        document.removeEventListener('mousedown', handleMouseDown)
        document.removeEventListener('keydown', handleEscDown)
      }
    }, [handleMouseUp, handleMouseDown, isShowingComments])

    const { data, error, status } = useGetComments({
      isStartingLoading: isShowingComments,
    })

    const { filterMode, searchText } = useCommentContext()

    const comments = filter(data ?? [], filterMode, searchText)

    const commentGroupingRadius = React.useMemo(
      () => Math.floor(25 / viewport.zoom),
      [viewport.zoom]
    )

    const makeGroups = React.useCallback(
      (previousGroups?: CommentGroup[]) => {
        return mapCommentsToGroups(
          comments,
          commentGroupingRadius,
          previousGroups
        )
      },
      [comments, commentGroupingRadius]
    )

    const [commentGroups, setCommentGroups] = React.useState<CommentGroup[]>([])

    React.useEffect(() => {
      if (viewport.isBusy || !isShowingComments) return
      setCommentGroups((oldGroups) => makeGroups(oldGroups))
    }, [comments, makeGroups, viewport.isBusy])

    const isShowingCreateComment = React.useMemo(() => {
      return isReadyToCreateComment && selectedCommentId === null
    }, [selectedCommentId, isReadyToCreateComment])

    return (
      <>
        <div ref={viewportRef} className="absolute inset-0">
          {children}
        </div>
        {status === 'success' && isShowingComments && (
          <div
            className={classNames(
              'pointer-events-none absolute inset-0 h-full w-full overflow-clip',
              {
                ['bg-white bg-opacity-50']: debug,
              }
            )}
          >
            <div
              ref={ref}
              className={classNames('- absolute inset-0 origin-center', {
                ['bg-red bg-opacity-50']: debug,
              })}
              style={{
                transform: `translate(${viewport.position.x}px, ${viewport.position.y}px)`,
              }}
            >
              <div ref={createCommentRef}>
                <CreateComment
                  position={createCommentPosition}
                  isShowing={isShowingCreateComment}
                  setIsReadyToCreateComment={setIsReadyToCreateComment}
                />
              </div>
              {comments.map((comment, index) => (
                <ViewportCommentBubble
                  key={'comment_' + comment.id}
                  commentGroups={commentGroups}
                  comment={comment}
                  previousCommentId={
                    index !== 0 ? comments[index - 1]?.id : undefined
                  }
                  nextCommentId={
                    index !== comments.length - 1
                      ? comments[index + 1]?.id
                      : undefined
                  }
                  data={comments}
                  index={index}
                  rightRatio={rightRatio}
                />
              ))}
              {commentGroups
                .filter((groups) => groups.comments.length > 1)
                .map((group, index) => (
                  <ViewportGroupBubble
                    key={'group_' + group.id}
                    group={group}
                    previousCommentId={
                      index !== 0 ? comments[index - 1]?.id : undefined
                    }
                    nextCommentId={
                      index !== comments.length - 1
                        ? comments[index + 1]?.id
                        : undefined
                    }
                    data={comments}
                    index={index}
                    viewport={viewport}
                  />
                ))}
            </div>
          </div>
        )}
      </>
    )
  }
)

CommentsWrapper.displayName = 'CommentsWrapper'
