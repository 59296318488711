import classnames from 'classnames'
import * as React from 'react'

import { ProBadge } from '../pro-badge'
import * as styles from './index.scss'

export interface IProps {
  className?: string
}
export const ProFeatureDescription: React.FCC<IProps> = ({
  className,
  children,
}) => (
  <div className={classnames(styles.container, className)}>
    <ProBadge variant="dimmed" className={styles.badge} />

    <div className={styles.content}>
      <p className={styles.title}>This is PRO feature</p>

      {children}
    </div>
  </div>
)
