import { makeAutoObservable } from 'mobx'
import * as R from 'ramda'
import * as React from 'react'

type Point2D = { x: number; y: number }

const ZOOM_TO_FIT_PADDING = 30
let viewportTimeout: number | undefined = undefined

/**
 * @description viewport UI related stuff
 */
export class Viewport {
  public position: Point2D = {
    x: 0,
    y: 0,
  }

  public size: Point2D = {
    x: 500,
    y: 500,
  }

  public zoom: number = 1

  public sizeVersion: number = 0

  /**
   * @description `true` when viewport is moving
   */
  public isBusy: boolean = false

  constructor() {
    makeAutoObservable(this)
  }

  borrow = () => {
    this.isBusy = true
    clearTimeout(viewportTimeout)
    // @ts-ignore
    viewportTimeout = setTimeout(() => {
      this.freeup()
    }, 300)
    return this
  }

  freeup = () => {
    this.isBusy = false
    clearTimeout(viewportTimeout)
    viewportTimeout = undefined
    return this
  }

  updatePosition = (position: Point2D) => {
    this.position = position
    return this
  }

  updateZoom = (zoom: number) => {
    this.zoom = zoom
    return this
  }

  updateSize = (size: Point2D) => {
    this.size = size
    this.sizeVersion += 1
    return this
  }

  get center(): Point2D {
    return {
      x: this.size.x / 2 - this.position.x / 2,
      y: this.size.y / 2 - this.position.y / 2,
    }
  }

  zoomToPoint = (payload: { point: Point2D; zoomStep: number }) => {
    const newZoom = this.zoom * payload.zoomStep

    const minValue = 0.1
    const maxValue = 16

    const updateZoom = (targetZoom: number) => {
      const deltaZoom = targetZoom - this.zoom
      const newPos: Point2D = {
        x: this.position.x - payload.point.x * deltaZoom,
        y: this.position.y - payload.point.y * deltaZoom,
      }

      this.updatePosition(newPos).updateZoom(targetZoom)
    }

    if (payload.zoomStep >= 1 && newZoom >= maxValue) {
      updateZoom(maxValue)
      return this
    }

    if (payload.zoomStep < 1 && newZoom < minValue) {
      updateZoom(minValue)
      return this
    }

    updateZoom(newZoom)
    return this
  }

  zoomToRect = (payload: {
    x: number
    y: number
    width: number
    height: number
  }) => {
    const zoomX = this.size.x / (payload.width + ZOOM_TO_FIT_PADDING)
    const zoomY = this.size.y / (payload.height + ZOOM_TO_FIT_PADDING)
    const zoom = R.min(zoomX, zoomY)

    const zoomPoint = {
      x: payload.width / 2 + payload.x,
      y: payload.height / 2 + payload.y,
    }

    const point = {
      x: this.size.x / 2 - zoomPoint.x * zoom,
      y: this.size.y / 2 - zoomPoint.y * zoom,
    }

    this.updateZoom(zoom).updatePosition(point)

    return this
  }

  getZoomToRectPosition = (payload: {
    x: number
    y: number
    width: number
    height: number
  }) => {
    const zoomX = this.size.x / (payload.width + ZOOM_TO_FIT_PADDING)
    const zoomY = this.size.y / (payload.height + ZOOM_TO_FIT_PADDING)
    const zoom = R.min(zoomX, zoomY)

    const zoomPoint = {
      x: payload.width / 2 + payload.x,
      y: payload.height / 2 + payload.y,
    }

    const point = {
      x: this.size.x / 2 - zoomPoint.x * zoom,
      y: this.size.y / 2 - zoomPoint.y * zoom,
    }

    return { zoom, position: point }
  }
}

const Context = React.createContext<Viewport>(null as any)

export const useViewport = (): Viewport => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'Viewport context not found. Use ViewportProvider at the root component.'
    )
  }

  return context
}

const defaultViewport = new Viewport()
export const ViewportProvider: React.FCC<{ store?: Viewport }> = ({
  store = defaultViewport,
  children,
}) => <Context.Provider value={store}>{children}</Context.Provider>
