import { Entity, TargetRelationAspect, TimeComponent } from '@aninix-inc/model'
import * as R from 'ramda'

/**
 * @description map segments to keyframes unique for selected properties
 * @example
 * [[1, 2], [2, 3]] => [[1, 2, 3]]
 * @todo move to common place
 */
export function mapSegmentsToKeyframes(
  segments: [Entity, Entity][]
): Entity[][] {
  const keyframes = R.flatten(segments)
  const uniqueKeyframes = R.uniqBy((key) => key.id, keyframes)
  const sortedKeyframes = R.sort(
    (left, right) =>
      left.getComponentOrThrow(TimeComponent).value -
      right.getComponentOrThrow(TimeComponent).value,
    uniqueKeyframes
  )
  const keyframesByProperties = R.groupBy((keyframe) => {
    const target = keyframe.getAspectOrThrow(TargetRelationAspect)
    const key = `${
      target.getTargetEntityOrThrow().id
    }/${target.getComponentTag()}`
    return key
  }, sortedKeyframes)
  return R.values(keyframesByProperties) as Entity[][]
}
