import * as React from 'react'

import { IToolbarInteractor } from './interactor'
import * as styles from './router.scss'

export interface IProps {
  menu: React.ReactNode
  tools: React.ReactNode
  exportModule: React.ReactNode
  viewportZoom: React.ReactNode
  linkToDashboard: React.ReactNode
  interactor: IToolbarInteractor
}
export const ToolbarRouter: React.FCC<IProps> = ({
  menu,
  tools,
  exportModule,
  viewportZoom,
  linkToDashboard,
  interactor,
  children,
}) => (
  <div className={styles.container}>
    <div className={styles.left}>
      {interactor.children.menu && menu}

      {interactor.children.tools && <div>{tools}</div>}
    </div>

    <div className={styles.center}>{linkToDashboard}</div>

    <div className={styles.right}>
      {interactor.children.viewportZoom && <div>{viewportZoom}</div>}

      <div className="flex h-12 flex-row items-center">{children}</div>

      {interactor.children.export && (
        <div className={styles.export}>{exportModule}</div>
      )}
    </div>
  </div>
)
