import {
  AnimationCurveAspect,
  CurveType,
  Entity,
  NameComponent,
  SpringCurveComponent,
  TimingCurveComponent,
  UndoRedoSystem,
  UpdatesSystem,
  detachCurveStyle,
  mixed,
  setCurveType,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/legacy'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import * as R from 'ramda'
import * as React from 'react'

const getCurveValue = (
  segment: [Entity, Entity]
):
  | {
      out: Point2D
      in: Point2D
    }
  | {
      stiffness: number
      damping: number
      mass: number
    }
  | typeof mixed => {
  if (segment[0].getAspectOrThrow(AnimationCurveAspect).hasCurveStyle()) {
    return segment[0].getAspectOrThrow(AnimationCurveAspect).curveComponent()
      .value
  }

  if (
    segment[0].getAspectOrThrow(AnimationCurveAspect).curveType() ===
    CurveType.Spring
  ) {
    return segment[0].getComponentOrThrow(SpringCurveComponent).value
  }

  if (
    segment[0].getAspectOrThrow(AnimationCurveAspect).curveType() ===
      CurveType.Timing &&
    segment[1].getAspectOrThrow(AnimationCurveAspect).curveType() ===
      CurveType.Timing
  ) {
    return {
      out: segment[0].getComponentOrThrow(TimingCurveComponent).value.out,
      in: segment[1].getComponentOrThrow(TimingCurveComponent).value.in,
    }
  }

  return mixed
}

function isAllEquals(segments: [Entity, Entity][]): boolean {
  const count = segments.length

  if (count < 2) {
    return true
  }

  for (let i = 1; i < count; i += 1) {
    const prev = segments[i - 1]
    const current = segments[i]

    const prevValue = getCurveValue(prev)
    const currentValue = getCurveValue(current)

    if (prevValue === mixed || currentValue === mixed) {
      return false
    }

    if (R.equals(prevValue, currentValue)) {
      continue
    }

    return false
  }

  return true
}

type Payload = {
  segments: [Entity, Entity][]
}

export interface ICurveInteractor {
  type: CurveType | typeof mixed
  appliedCurveStyle?: TimingCurveComponent | SpringCurveComponent
  appliedCurveStyleName?: string

  showUserPresets: () => void
  hideUserPresets: () => void

  updateCurveType: (curveType: CurveType) => void

  detachCurveStyle: () => void

  applyBasicCurveStyle: () => void

  children: {
    userPresets: boolean
    graph: boolean
  }
}

export const useCurveInteractor = ({ segments }: Payload): ICurveInteractor => {
  const analytics = useAnalytics()
  const project = segments[0][0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const [isUserPresetsVisible, setIsUserPresetsVisible] = React.useState(false)

  const type = (() => {
    // @NOTE: this is required to avoid issue when we have 2 segments with different types:
    // x---timing---x---spring---x
    // in that case selecting the first one caused app crash.
    // @TODO: this can be avoided if we refactor how we treat keyframes and segments.
    // See the ANI-1736 for more details.
    if (segments.length === 1) {
      const value = getCurveValue(segments[0])

      if (value === mixed) {
        return mixed
      }
    }

    if (isAllEquals(segments) === false) {
      return mixed
    }

    if (
      R.all((segment) => {
        // @TODO: move to model
        const aspect = segment[0].getAspectOrThrow(AnimationCurveAspect)
        const curveType = aspect.hasCurveStyle()
          ? aspect
              .getCurveStyle()
              .getAspectOrThrow(AnimationCurveAspect)
              .getCurveType()
          : aspect.getCurveType()
        return curveType === CurveType.Timing
      }, segments)
    ) {
      return CurveType.Timing
    }

    if (
      R.all((segment) => {
        // @TODO: move to model
        const aspect = segment[0].getAspectOrThrow(AnimationCurveAspect)
        const curveType = aspect.hasCurveStyle()
          ? aspect
              .getCurveStyle()
              .getAspectOrThrow(AnimationCurveAspect)
              .getCurveType()
          : aspect.getCurveType()
        return curveType === CurveType.Spring
      }, segments)
    ) {
      return CurveType.Spring
    }

    return mixed
  })()

  const showUserPresets = React.useCallback(() => {
    setIsUserPresetsVisible(true)
  }, [])

  const hideUserPresets = React.useCallback(() => {
    setIsUserPresetsVisible(false)
  }, [])

  const detachCurveStyleInternal: ICurveInteractor['detachCurveStyle'] =
    React.useCallback(() => {
      updates.batch(() => {
        segments.forEach((segment) => {
          detachCurveStyle(segment[0], segment[1])
        })
      })

      analytics.track({
        eventName: AnalyticsEvent.CurveStyleDetached,
      })
    }, [segments, analytics, updates])

  const applyBasicCurveStyle: ICurveInteractor['applyBasicCurveStyle'] =
    React.useCallback(() => {
      updates.batch(() => {
        segments.forEach((segment) => {
          segment.forEach((keyframe) => {
            keyframe
              .getAspectOrThrow(AnimationCurveAspect)
              .updateCurveType(CurveType.Timing)
            keyframe.updateComponent(TimingCurveComponent, {
              out: {
                x: 0.5,
                y: 0.35,
              },
              in: {
                x: 0.15,
                y: 1,
              },
            })
          })
        })
      })
      undoRedo.commitUndo()
    }, [segments, updates, undoRedo])

  const updateCurveType: ICurveInteractor['updateCurveType'] =
    React.useCallback(
      (curveType) => {
        segments.forEach((segment) => {
          segment.forEach((keyframe) => {
            setCurveType(keyframe, curveType)
          })
        })
      },
      [segments]
    )

  const aspect = segments[0][0].getAspectOrThrow(AnimationCurveAspect)
  const hasAppliedStyle = aspect.hasCurveStyle()

  return {
    type,
    appliedCurveStyle: hasAppliedStyle ? aspect.curveComponent() : undefined,
    appliedCurveStyleName: hasAppliedStyle
      ? aspect.curveStyle().getComponentOrThrow(NameComponent).value
      : undefined,

    showUserPresets,
    hideUserPresets,

    updateCurveType,

    detachCurveStyle: detachCurveStyleInternal,
    applyBasicCurveStyle,

    children: {
      userPresets: isUserPresetsVisible,
      graph: type !== mixed,
    },
  }
}
