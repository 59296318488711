import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Reversed: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      opacity="0.4"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.5548 5.13541L11.2967 3L10.6096 3.72658L11.9562 5H3V6H11.9534L10.6096 7.27069L11.2967 7.99729L13.5548 5.862L13.939 5.49871L13.5548 5.13541Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.38462 10.1325L5.64279 7.99707L6.32987 8.72365L4.98324 9.99707H13.9395V10.9971H4.98604L6.32986 12.2678L5.6428 12.9944L3.38463 10.8591L3.00043 10.4958L3.38462 10.1325Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
