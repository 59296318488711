import { Popover } from '@material-ui/core'
import * as React from 'react'

import * as styles from './index.scss'
import { PopupList } from './popup'
import { Link } from './types'

export interface IProps {
  /**
   * @description main array of group links
   * @example
   * [
   *  [
   *    {
   *      url: 'https://some-url.com',
   *      title: 'some title',
   *      secondary: '⌃⇧?'
   *    },
   *  ],
   *  [
   *    {
   *      url: '#',
   *      onClick: console.log,
   *      title: 'some title',
   *      secondary: '⌃⇧?'
   *    },
   *  ],
   * ]
   */
  groups: Link[][]

  /**
   * @description additional shift from top
   * @default 270
   */
  top?: number

  /**
   * @description additional shift from left
   * @default 140
   */
  left?: number
}

export const Helper: React.FCC<IProps> = ({
  groups,
  top = 270,
  left = 140,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const isOpen = React.useMemo(() => Boolean(anchorEl), [anchorEl])
  const id = React.useMemo(
    () => (isOpen ? 'simple-popover' : undefined),
    [isOpen]
  )

  return (
    <div>
      <button
        className={styles.helperBlock}
        aria-describedby={id}
        onClick={handleClick}
      >
        <svg
          width="8"
          height="14"
          viewBox="0 0 8 14"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5 13H4v1h1v-1zM4 1C2.343 1 1 2.343 1 4H0c0-2.21 1.79-4 4-4 2.21 0 4 1.79 4 4 0 1.268-.59 2.398-1.51 3.13l-.083.065C5.553 7.833 5 8.852 5 10H4c0-1.424.662-2.694 1.694-3.518l.083-.064C6.519 5.87 7 4.992 7 4c0-1.657-1.343-3-3-3z"
            fillRule="nonzero"
            fillOpacity="1"
            fill="#fff"
            stroke="none"
          ></path>
        </svg>
      </button>

      <Popover
        classes={{
          paper: styles.popupBlock,
        }}
        id={id}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        disableScrollLock={false}
        anchorPosition={{ top, left }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        style={{
          transform: 'translateY(-13px)',
        }}
      >
        <PopupList groups={groups} />
      </Popover>
    </div>
  )
}
