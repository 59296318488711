import { noop } from 'lodash'
import * as R from 'ramda'
import * as React from 'react'

import { useMouseMove } from '../../../hooks'
import { ColorStop } from './color-stop'
import { Gradient } from './gradient'
import { ColorStop as ColorStopType } from './types'

export interface IProps {
  // @NOTE: айдишник выбранного цвета
  selectedColorStopId?: string

  // @NOTE: отсортированный по прогрессу массив цветов
  colorStops: ColorStopType[]

  // @NOTE: коллбэк вызывается при любом изменении цвета/прогресса
  onChangeProgress: (progress: number) => void

  // @NOTE: коллбэк при клике на любой контрол
  onSelect: (colorStopId: string) => void

  onColorStopCreation?: (progress: number) => void
}

/**
 * @description component to manipulate progress of color steps for gradient
 */
export const GradientSlider: React.FCC<IProps> = ({
  selectedColorStopId,
  colorStops,
  onColorStopCreation = noop,
  onChangeProgress,
  onSelect,
}) => {
  const [_, setIsMounted] = React.useState(false)
  const containerRef = React.useRef<any>(null)
  const startProgressRef = React.useRef<number>(0)
  const gradientWidth = React.useMemo(() => 192, [])
  const width = React.useMemo(() => 216, [])

  const { startAtX, endAtX, offsetX, isListening, wasTriggered, startListen } =
    useMouseMove({
      threshold: 4,
      element: containerRef.current!,
    })

  const handleMouseDown = React.useCallback(
    (e: MouseEvent, id: string) => {
      onSelect(id)
      startListen(e)

      const selectedColorStop = colorStops.find(
        (colorStop) => colorStop.id === id
      )

      if (selectedColorStop == null) {
        return
      }

      startProgressRef.current = selectedColorStop.progress
    },
    [onSelect, startListen, colorStops]
  )

  React.useEffect(() => {
    if (isListening === false || wasTriggered === false) {
      return
    }

    const progressOffset = offsetX / gradientWidth
    const newProgress = startProgressRef.current + progressOffset
    const clampedProgress = R.clamp(0, 1, newProgress)
    onChangeProgress(clampedProgress)
  }, [isListening, wasTriggered, startAtX, endAtX, offsetX])

  React.useEffect(() => {
    setIsMounted(true)
  }, [])

  const handleDoubleClick = React.useCallback(
    (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      const containerRect = containerRef.current.getBoundingClientRect()
      const relativeX = event.clientX - containerRect.left

      const padding = width * 0.06
      const adjustedWidth = width - 2 * padding

      const clampedX = R.clamp(padding, width - padding, relativeX)
      const fraction = (clampedX - padding) / adjustedWidth

      onColorStopCreation(fraction)
    },
    []
  )

  return (
    <svg
      ref={containerRef}
      width={width}
      height={42}
      viewBox={`0 0 ${width} 42`}
      onDoubleClick={handleDoubleClick}
    >
      <defs>
        <pattern
          id="checkerboard_gradient"
          viewBox="0 0 6 6"
          width="4%"
          height="76%"
        >
          <rect width="3" height="3" x="0" y="0" fill="#F0F0F0" />
          <rect width="3" height="3" x="3" y="0" fill="white" />
          <rect width="3" height="3" x="0" y="3" fill="white" />
          <rect width="3" height="3" x="3" y="3" fill="#F0F0F0" />
        </pattern>

        <pattern
          id="checkerboard_color_stop"
          viewBox="0 0 6 6"
          width="50%"
          height="50%"
        >
          <rect width="3" height="3" x="0" y="0" fill="#F0F0F0" />
          <rect width="3" height="3" x="3" y="0" fill="white" />
          <rect width="3" height="3" x="0" y="3" fill="white" />
          <rect width="3" height="3" x="3" y="3" fill="#F0F0F0" />
        </pattern>
      </defs>

      <g transform="translate(12 0)">
        {colorStops.map((colorStop) => (
          <g
            key={colorStop.id}
            transform={`translate(${
              colorStop.progress * gradientWidth - 12
            }, 0)`}
          >
            <ColorStop
              colorStop={colorStop}
              isSelected={colorStop.id === selectedColorStopId}
              onMouseDown={handleMouseDown}
            />
          </g>
        ))}

        <g transform="translate(0 30)">
          <Gradient colorStops={colorStops} />
        </g>
      </g>
    </svg>
  )
}
