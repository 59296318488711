import { Backdrop, Modal } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

import { Button } from '../design-system/button'

export interface IProps {
  header: string
  callToAction: string
  handleDelete: () => void
  Messsage: React.ReactNode
  handleClose: () => void
  isOpen: boolean
}
export const Delete: React.FCC<IProps> = ({
  header,
  callToAction,
  handleDelete,
  Messsage,
  handleClose,
  isOpen,
}) => {
  React.useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'unset'
  }, [isOpen])

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      className="flex h-full w-full flex-col items-center justify-center"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <div
        className={classNames('min-w-[400px] rounded-xl bg-white shadow-2xl')}
      >
        <div className="flex w-full flex-col gap-4 p-6 ">
          <div className="flex w-full flex-row gap-4">
            <div className="flex w-96 flex-col items-start gap-4 py-2">
              {Messsage}
            </div>
          </div>

          <div className="flex w-full flex-row justify-end gap-2">
            <button
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleClose()
              }}
              className="flex h-10 flex-row items-center gap-2 rounded-lg border-[1px] border-gray-200 px-3 py-2"
            >
              <p className="font-body text-sm font-medium text-gray-500">
                Cancel
              </p>
            </button>

            <Button
              className="!bg-red text-sm"
              label={callToAction}
              onClick={(e) => {
                e?.preventDefault()
                e?.stopPropagation()
                handleDelete()
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

Delete.displayName = 'Delete'
