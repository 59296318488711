import { NodeSnapshot } from '../../modules/common/renderers/types'
import { isRectOnPath } from './is-rect-on-path'

export function getItemsIntersectWithRectangle({
  nodes,
  selectionRectBoundingRect,
  highlightedId,
  skipGroupIds,
  isCmdPressed,
}: {
  nodes: NodeSnapshot[]
  selectionRectBoundingRect: {
    x: number
    y: number
    width: number
    height: number
  }
  highlightedId?: string[]
  skipGroupIds: string[]
  isCmdPressed: boolean
}) {
  var returnHighlightedIds = highlightedId ?? []

  nodes.forEach((node) => {
    const skipGroups = isCmdPressed || skipGroupIds.includes(node.id)

    const { doesContain, skipGroupWhenNotInside } = isRectOnPath({
      nodeSnapshot: node,
      rect: selectionRectBoundingRect,
      path: node.fillData.map((e) => e.data).join(''),
      fills: node.fills,
      strokes: node.strokes,
      skipGroups: skipGroups,
    })

    if (doesContain) {
      returnHighlightedIds.push(node.id)
    }

    if (skipGroups && !skipGroupWhenNotInside)
      returnHighlightedIds = getItemsIntersectWithRectangle({
        nodes: node.children,
        selectionRectBoundingRect,
        highlightedId: returnHighlightedIds,
        skipGroupIds,
        isCmdPressed,
      })
  })

  return returnHighlightedIds
}
