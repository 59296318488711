import {
  BlurRadiusComponent,
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  Entity,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/static-types'
import { LottieEffect, LottieEffectType, LottieEffectValueType } from '../types'
import { Property, mapColorAlpha, mapRgb } from './property'

function vectorAngle(vector: Point2D): number {
  // Calculate the angle in radians using Math.atan2
  return Math.atan2(vector.y, vector.x)
}

function vectorMagnitude(vector: Point2D): number {
  // Calculate the length using the Pythagorean theorem
  return Math.sqrt(vector.x * vector.x + vector.y * vector.y)
}

export function DropShadow(effect: Entity): LottieEffect {
  const result: LottieEffect = {
    nm: 'Drop Shadow',
    ty: LottieEffectType.DropShadow,
    en: 1,
    ef: [
      {
        ty: LottieEffectValueType.Color,
        nm: 'Shadow Color',
        v: Property({
          property: effect.getComponentOrThrow(ShadowColorComponent),
          mapper: mapRgb,
        }),
      },
      {
        ty: LottieEffectValueType.Slider,
        nm: 'Opacity',
        v: Property({
          property: effect.getComponentOrThrow(ShadowColorComponent),
          mapper: mapColorAlpha,
        }),
      },
      // @NOTE: map offset to angle + distance
      {
        ty: LottieEffectValueType.Angle,
        nm: 'Direction',
        v: Property({
          property: effect.getComponentOrThrow(ShadowOffsetComponent),
          mapper: ({ value, isAnimated }) => {
            const { x, y } = value
            // @NOTE: check why X here with negative value.
            // For current case it was ok, but don't know if it would be ok for all other cases.
            const angle = (vectorAngle({ x: -x, y }) / Math.PI) * 180
            return isAnimated ? [angle] : angle
          },
        }),
      },
      {
        ty: LottieEffectValueType.Slider,
        nm: 'Distance',
        v: Property({
          property: effect.getComponentOrThrow(ShadowOffsetComponent),
          mapper: ({ value, isAnimated }) => {
            const { x, y } = value
            const magnitude = vectorMagnitude({ x, y })
            return isAnimated ? [magnitude] : magnitude
          },
        }),
      },
      {
        ty: LottieEffectValueType.Slider,
        nm: 'Softness',
        v: Property({
          property: effect.getComponentOrThrow(ShadowRadiusComponent),
        }),
      },
      {
        ty: LottieEffectValueType.Checkbox,
        nm: 'Shadow Only',
        v: {
          a: 0,
          k: 0,
        },
      },
    ],
  }

  return result
}

export function LayerBlur(effect: Entity): LottieEffect {
  const result: LottieEffect = {
    nm: 'Gaussian Blur',
    ty: LottieEffectType.GaussianBlur,
    en: 1,
    ef: [
      {
        ty: LottieEffectValueType.Slider,
        nm: 'Blurriness',
        v: Property({
          property: effect.getComponentOrThrow(BlurRadiusComponent),
        }),
      },
      {
        ty: LottieEffectValueType.Slider,
        nm: 'Blur Dimensions',
        v: {
          a: 0,
          k: 1,
        },
      },
      {
        ty: LottieEffectValueType.Checkbox,
        nm: 'Repeat Edge Pixels',
        v: {
          a: 0,
          k: 0,
        },
      },
    ],
  }

  return result
}

export function Effects(payload: { node: Entity }): LottieEffect[] {
  const { node } = payload

  return node
    .getAspectOrThrow(EffectsRelationsAspect)
    .getChildrenList()
    .filter(
      (effect) => effect.getComponentOrThrow(VisibleInViewportComponent).value
    )
    .flatMap((effect) => {
      const effectType = effect.getComponentOrThrow(EffectTypeComponent).value

      if (effectType === EffectType.DropShadow) {
        return [DropShadow(effect)]
      }

      if (effectType === EffectType.LayerBlur) {
        return [LayerBlur(effect)]
      }

      return []
    })
}
