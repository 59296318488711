import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Applied: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M7.83 2.925L5 0L2.17 2.925C1.39 3.733 1 4.81 1 5.845C1 6.881 1.39 7.974 2.17 8.781C2.95 9.589 3.975 9.998 5 9.998C6.025 9.998 7.05 9.589 7.83 8.781C8.61 7.974 9 6.881 9 5.846C9 4.81 8.61 3.733 7.83 2.926V2.925ZM2 6.032C2.005 4.996 2.31 4.339 2.88 3.754L5 1.512L7.12 3.78C7.69 4.36 7.995 4.996 8 6.032H2Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
