import { ApiUser } from '@aninix/api/helpers'
import * as React from 'react'

type ICurrentRowContext = Partial<
  ApiUser & { rowRef?: React.RefObject<HTMLDivElement> }
>

export const CurrentRowContext = React.createContext<ICurrentRowContext>({})

export const useCurrentRowContext = () => React.useContext(CurrentRowContext)
