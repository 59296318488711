import { Patch, Version } from '@aninix-inc/model'
import { AxiosResponse } from 'axios'
import { config } from './config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'

export const getProjectPatchLimit = async (
  version: Version
): Promise<Patch> => {
  // @NOTE: get remote patch
  const { data: remotePatch } = await httpClient.post<
    paths['/v2/projects/{projectId}/patches/limit']['post']['responses']['200']['content']['application/json'],
    AxiosResponse<
      paths['/v2/projects/{projectId}/patches/limit']['post']['responses']['200']['content']['application/json']
    >,
    paths['/v2/projects/{projectId}/patches/limit']['post']['requestBody']['content']['application/json']
  >(`${config.apiUrl}/v2/projects/${version.id}/patches/limit`, version as any)

  return remotePatch as any
}
