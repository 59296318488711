import { ClipboardDataJson, ClipboardType } from '@aninix-inc/model'

/**
 * Grab copied object, parse it and return prepared to be pasted object.
 * @returns Data to be pasted.
 */
export function createMapFromClipboard() {
  return function mapFromClipboard(rawData?: string) {
    if (rawData == null) {
      return null
    }

    try {
      const parsedData = JSON.parse(rawData) as ClipboardDataJson

      if (parsedData.type === ClipboardType.Keyframes) {
        return parsedData
        // return {
        //   type: parsedData.type,
        //   value: parsedData.value.map((clipboardKeyframe) => ({
        //     ...clipboardKeyframe,
        //     keyframe: genericKeyframe.create(clipboardKeyframe.keyframe),
        //   })),
        // }
      }

      return null
    } catch (err: any) {
      console.error('[mapFromClipboard] error', err)
      return null
    }
  }
}
