import { useCreateFolder } from '@aninix/api'
import * as React from 'react'
import { Link, useParams } from 'react-router-dom'

import { toast } from '../../modules/toasts'
import { useCurrentUser } from '../../use-cases'
import { Icons } from '../icons'
import { CreateNewFolderModal } from '../projects-list/create-new-folder-modal'

export interface IProps {
  refetchCurrentFolder: () => void
}
export const CreateNewFolderButton: React.FCC<IProps> = ({
  refetchCurrentFolder,
}) => {
  const [showModal, setShowModal] = React.useState<boolean>(false)

  const closeModal = React.useCallback(() => {
    setShowModal(false)
  }, [])

  const openModal = React.useCallback(() => {
    setShowModal(true)
  }, [])

  const createFolder = useCreateFolder()

  const { folderId } = useParams()
  const { userId, teamId } = useCurrentUser()

  const onCreateNewFolder = React.useCallback(
    (name: string) =>
      createFolder
        .mutateAsync({
          ...(teamId ? { teamId } : { userId }),
          parentFolderId: folderId,
          name,
        })
        .then((data) => {
          closeModal()
          refetchCurrentFolder()
          toast(
            <Link
              relative="path"
              to={`${data.data.id}`}
              className="flex items-center"
            >
              <p>
                Folder <b>{name}</b> succesfully created
              </p>
              ,
            </Link>,
            { variant: 'success' }
          )
        })
        .catch((e) =>
          toast("Couldn't create new folder", { variant: 'error' })
        ),
    [teamId, folderId, createFolder]
  )

  return (
    <>
      <div>
        <button
          onClick={openModal}
          className="flex flex-row items-center gap-2 rounded-lg bg-secondary px-3 py-2 transition-all duration-500 hover:scale-[1.02] hover:shadow-xl"
        >
          <Icons.CreateNewFolder />
          <p className="font-body text-sm font-medium text-white">
            Create a folder
          </p>
        </button>
        <CreateNewFolderModal
          name="New Folder"
          isLoading={createFolder.status === 'pending'}
          isOpen={showModal}
          handleNewFolderCreation={onCreateNewFolder}
          handleClose={closeModal}
        />
      </div>
    </>
  )
}

CreateNewFolderButton.displayName = 'CreateNewFolderButton'
