import { httpClient } from '@aninix/api'
import { config } from '@aninix/api/config'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'

import { Rectangle } from './use-get-comments'

export interface ICommentPatchComment {
  query: UseMutationResult<
    AxiosResponse<any, any>,
    unknown,
    ICommentPatchPayload,
    unknown
  >
}

export interface ICommentPatchPayload {
  commentId: string
  comment: string
  rectangle: Rectangle
  time: number
  isResolved?: boolean
}

const defaultRectangle: Rectangle = { x: 0, y: 0, width: 0, height: 0 }

export const usePatchComment = (): ICommentPatchComment => {
  const { projectId } = useParams()

  const query = useMutation({
    mutationKey: ['postProjectComment', projectId],
    mutationFn: ({
      comment,
      rectangle = defaultRectangle,
      time = 0,
      commentId,
      isResolved = false,
    }: ICommentPatchPayload) =>
      httpClient.patch(
        `${config.apiUrl}/projects/${projectId}/comments/${commentId}`,
        { comment, rectangle, time, isResolved },
        {
          responseType: 'json',
        }
      ),
  })

  return { query }
}
