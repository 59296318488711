import { lerp } from '@aninix-inc/model/legacy'
import { paper } from '@aninix-inc/renderer'
import { FullPageLoader } from '@aninix/app-design-system'
import classNames from 'classnames'
import { animate, useMotionValue } from 'framer-motion'
import * as R from 'ramda'
import * as React from 'react'
import tinycolor from 'tinycolor2'

import { MaintenanceLightIcon } from '../../components/status-pages/maintenance/icon-light'
import { ILoginInteractor } from './interactor'

function getVector(radians: number, length: number) {
  return new paper.Point({
    // Convert radians to degrees:
    angle: (radians * 180) / Math.PI,
    length: length,
  })
}

/**
 * @url http://paperjs.org/examples/meta-balls/
 */
function metaball(
  ball1: paper.Path,
  ball2: paper.Path,
  strength: number,
  handleLenRate: number,
  maxDistance: number
) {
  var center1 = ball1.position
  var center2 = ball2.position
  var radius1 = ball1.bounds.width / 2
  var radius2 = ball2.bounds.width / 2
  var pi2 = Math.PI / 2
  var d = center1.getDistance(center2)
  var u1, u2

  if (radius1 == 0 || radius2 == 0) {
    return
  }

  if (d > maxDistance || d <= Math.abs(radius1 - radius2)) {
    return
  } else if (d < radius1 + radius2) {
    // case circles are overlapping
    u1 = Math.acos(
      (radius1 * radius1 + d * d - radius2 * radius2) / (2 * radius1 * d)
    )
    u2 = Math.acos(
      (radius2 * radius2 + d * d - radius1 * radius1) / (2 * radius2 * d)
    )
  } else {
    u1 = 0
    u2 = 0
  }

  const angle1 = center2.getAngleInRadians(center1)
  const angle2 = Math.acos((radius1 - radius2) / d)
  const angle1a = angle1 + u1 + (angle2 - u1) * strength
  const angle1b = angle1 - u1 - (angle2 - u1) * strength
  const angle2a = angle1 + Math.PI - u2 - (Math.PI - u2 - angle2) * strength
  const angle2b = angle1 - Math.PI + u2 + (Math.PI - u2 - angle2) * strength
  const p1a = center1.add(getVector(angle1a, radius1))
  const p1b = center1.add(getVector(angle1b, radius1))
  const p2a = center2.add(getVector(angle2a, radius2))
  const p2b = center2.add(getVector(angle2b, radius2))

  // define handle length by the distance between
  // both ends of the curve to draw
  var totalRadius = radius1 + radius2
  var d2 = Math.min(
    strength * handleLenRate,
    p1a.subtract(p2a).length / totalRadius
  )

  // case circles are overlapping:
  d2 *= Math.min(1, (d * 2) / (radius1 + radius2))

  radius1 *= d2
  radius2 *= d2

  var path = new paper.Path({
    segments: [p1a, p2a, p2b, p1b],
    style: ball1.style,
    closed: true,
  })
  var segments = path.segments
  segments[0].handleOut = getVector(angle1a - pi2, radius1)
  segments[1].handleIn = getVector(angle2a + pi2, radius2)
  segments[2].handleOut = getVector(angle2b - pi2, radius2)
  segments[3].handleIn = getVector(angle1b + pi2, radius1)
  return path
}

function lerpColor(t: number, from: string, to: string): string {
  const parsedFromRgb = tinycolor(from).toRgb()
  const parsedToRgb = tinycolor(to).toRgb()
  const newT = R.clamp(0, 1, t)
  return `#${tinycolor({
    r: lerp(newT, parsedFromRgb.r, parsedToRgb.r),
    g: lerp(newT, parsedFromRgb.g, parsedToRgb.g),
    b: lerp(newT, parsedFromRgb.b, parsedToRgb.b),
  }).toHex()}`
}

const circlesRadius = 48

export interface IProps {
  interactor: ILoginInteractor
}
export const LoginRouter: React.FC<IProps> = ({ interactor }) => {
  const [positionProgress, setPositionProgress] = React.useState(0)
  const [colorProgress, setColorProgress] = React.useState(0)
  const [bgProgress, setBgProgress] = React.useState(0)
  const [isHovered, setIsHovered] = React.useState(false)

  const positionProgressMotionValue = useMotionValue(0)
  const colorProgressMotionValue = useMotionValue(0)
  const bgProgressMotionValue = useMotionValue(0)

  const centerLeft = React.useMemo(() => {
    return {
      x: lerp(
        positionProgress,
        140 - circlesRadius * 1.5,
        140 - circlesRadius * 0.85
      ),
      y: circlesRadius,
    }
  }, [positionProgress])
  const centerRight = React.useMemo(() => {
    return {
      x: lerp(
        positionProgress,
        140 + circlesRadius * 1.5,
        140 + circlesRadius * 0.85
      ),
      y: circlesRadius,
    }
  }, [positionProgress])

  const startColorCircles = React.useMemo(() => {
    return lerpColor(colorProgress, '#A8B5FF', '#374FD5')
  }, [colorProgress])
  const endColorCircles = React.useMemo(() => {
    return lerpColor(colorProgress, '#E1E6FF', '#6F83F3')
  }, [colorProgress])

  const bgCircleOpacity = React.useMemo(() => {
    return lerp(bgProgress, 0.2, 0.5)
  }, [bgProgress])

  const path = React.useMemo(() => {
    const strength = 0.6
    const handleLenRate = 2.4
    const maxDistance = 100

    const circlePath1 = new paper.Path.Circle({
      center: centerLeft,
      radius: 48,
    })
    const circlePath2 = new paper.Path.Circle({
      center: centerRight,
      radius: 48,
    })

    const metaballPath =
      metaball(
        circlePath1,
        circlePath2,
        strength,
        handleLenRate,
        maxDistance
      ) ?? new paper.Path()

    const path = new paper.CompoundPath('')
    metaballPath.reverse()
    path.addChildren([circlePath1, circlePath2, metaballPath])
    return path.pathData
  }, [centerLeft, centerRight])

  React.useEffect(() => {
    if (isHovered) {
      animate(positionProgressMotionValue, 1, {
        type: 'spring',
        duration: 0.35,
        stiffness: 400,
        damping: 15,
      })
      animate(colorProgressMotionValue, 1, {
        type: 'tween',
        duration: 0.35,
        ease: [0.65, 0, 0.15, 1],
      })
      animate(bgProgressMotionValue, 1, {
        type: 'tween',
        duration: 0.7,
        ease: [0.35, 0, 0.65, 1],
      })
      return
    }

    animate(positionProgressMotionValue, 0, {
      type: 'spring',
      duration: 0.35,
      stiffness: 200,
      damping: 15,
    })
    animate(colorProgressMotionValue, 0, {
      type: 'tween',
      duration: 0.35,
      ease: [0.65, 0, 0.15, 1],
    })
    animate(bgProgressMotionValue, 0, {
      type: 'tween',
      duration: 0.4,
      ease: [0.35, 0, 0.65, 1],
    })
  }, [isHovered])

  React.useEffect(() => {
    const callback1 = positionProgressMotionValue.onChange((value) => {
      setPositionProgress(value)
    })
    const callback2 = colorProgressMotionValue.onChange((value) => {
      setColorProgress(value)
    })
    const callback3 = bgProgressMotionValue.onChange((value) => {
      setBgProgress(value)
    })
    return () => {
      callback1()
      callback2()
      callback3()
    }
  }, [])

  const enableHover = React.useCallback(() => {
    setIsHovered(true)
  }, [])

  const disableHover = React.useCallback(() => {
    setIsHovered(false)
  }, [])

  if (interactor.state === 'loading') {
    return <FullPageLoader />
  }

  if (interactor.state === 'error') {
    return (
      <div className="mx-auto flex h-screen w-screen max-w-sm flex-col items-center justify-center text-center text-black/80">
        <MaintenanceLightIcon className="w-[258px]" />

        <p className="mt-6 text-2xl font-semibold">Unable to login</p>

        <p className="mt-2 text-base">
          We could not connect your Aninix and Figma accounts due to techinal
          issue on our end.
          <br />
          Please try to connecting again.
        </p>

        <p className="mt-1 text-base">
          If the issue keeps happening,{' '}
          <a
            href="mailto:info@aninix.com"
            target="_blank"
            className="text-[#18A0FB]"
            rel="noreferrer"
          >
            contact us.
          </a>
        </p>

        <button
          onClick={interactor.login}
          className="mt-6 rounded-lg bg-[#0B1118] px-4 py-2 text-base text-white"
        >
          Try again
        </button>
      </div>
    )
  }

  return (
    <div className="mx-auto flex h-screen w-screen max-w-sm flex-col items-center justify-center text-center text-black/80">
      {/* Bg circle */}
      <svg
        className="absolute left-1/2 top-1/2 z-[-1] h-[600px] w-[800px] -translate-x-1/2 -translate-y-[200px]"
        viewBox="0 0 800 600"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <filter
            id="filter0_f_896_3265"
            x="-100%"
            y="-100%"
            width="300%"
            height="300%"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="BackgroundImageFix"
              result="shape"
            />
            <feGaussianBlur
              stdDeviation="80"
              result="effect1_foregroundBlur_896_3265"
            />
          </filter>
          <linearGradient
            id="paint0_linear_896_3265"
            x1="360"
            y1="-264"
            x2="360"
            y2="129.443"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#374FD5" />
            <stop offset="1" stopColor="#6F83F3" />
          </linearGradient>
        </defs>

        <g opacity={bgCircleOpacity} filter="url(#filter0_f_896_3265)">
          <circle
            cx="400"
            cy="0"
            r="200"
            fill="url(#paint0_linear_896_3265)"
            fillRule="nonzero"
          />
        </g>
      </svg>

      {/* Balls */}
      <svg
        className="h-[96px] w-[280px]"
        viewBox="0 0 280 96"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <linearGradient
            id="path_fill"
            x1={0.5 * circlesRadius * 2}
            y1={0 * circlesRadius * 2}
            x2={0.5 * circlesRadius * 2}
            y2={1 * circlesRadius * 2}
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0%" stopOpacity="100" stopColor={startColorCircles} />
            <stop offset="100%" stopOpacity="100" stopColor={endColorCircles} />
          </linearGradient>
        </defs>

        {/* Circles path */}
        <path d={path} fill="url(#path_fill)" fillRule="nonzero" />

        {/* Aninix logo */}
        <svg
          x={centerLeft.x - circlesRadius}
          y={centerLeft.y - circlesRadius}
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_939_3194)">
            <path
              d="M62.993 47.9149L41.161 67.3381V31.4374L32.2408 36.0797V73.4804L38.8964 78.8574L75.1772 46.345L38.1723 17.1431L30.0146 21.3116L62.993 47.9149Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_939_3194"
              x="28.0146"
              y="16.1431"
              width="49.1626"
              height="65.7144"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_939_3194"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_939_3194"
                result="shape"
              />
            </filter>
          </defs>
        </svg>

        {/* Figma logo */}
        <svg
          x={centerRight.x - circlesRadius}
          y={centerRight.y - circlesRadius}
          width="96"
          height="96"
          viewBox="0 0 96 96"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_939_3195)">
            <path
              d="M49.2832 49.3663C49.2832 47.0951 50.1627 44.917 51.7282 43.3111C53.2937 41.7051 55.4171 40.8029 57.6311 40.8029C59.845 40.8029 61.9684 41.7051 63.5339 43.3111C65.0994 44.917 65.9789 47.0951 65.9789 49.3663C65.9789 51.6374 65.0994 53.8156 63.5339 55.4215C61.9684 57.0275 59.845 57.9297 57.6311 57.9297C55.4171 57.9297 53.2937 57.0275 51.7282 55.4215C50.1627 53.8156 49.2832 51.6374 49.2832 49.3663Z"
              fill="white"
            />
            <path
              d="M30.5013 69.4367C30.5013 67.1656 31.3808 64.9874 32.9463 63.3815C34.5118 61.7755 36.6352 60.8733 38.8492 60.8733H47.1971V69.4367C47.1971 71.7079 46.3175 73.886 44.752 75.4919C43.1865 77.0979 41.0632 78.0001 38.8492 78.0001C36.6352 78.0001 34.5118 77.0979 32.9463 75.4919C31.3808 73.886 30.5013 71.7079 30.5013 69.4367Z"
              fill="white"
            />
            <path
              d="M49.8049 21.0001V38.1269H58.1528C60.3668 38.1269 62.4901 37.2247 64.0556 35.6187C65.6212 34.0128 66.5007 31.8346 66.5007 29.5635C66.5007 27.2923 65.6212 25.1142 64.0556 23.5083C62.4901 21.9023 60.3668 21.0001 58.1528 21.0001H49.8049Z"
              fill="white"
            />
            <path
              d="M30.5007 29.5635C30.5007 31.8346 31.3802 34.0127 32.9457 35.6187C34.5112 37.2246 36.6346 38.1268 38.8486 38.1268H47.1964V21.0001H38.8486C36.6346 21.0001 34.5112 21.9023 32.9457 23.5082C31.3802 25.1142 30.5007 27.2923 30.5007 29.5635Z"
              fill="white"
            />
            <path
              d="M30.5009 49.3663C30.5009 51.6374 31.3804 53.8156 32.9459 55.4215C34.5115 57.0275 36.6348 57.9297 38.8488 57.9297H47.1967V40.8029H38.8488C36.6348 40.8029 34.5115 41.7051 32.9459 43.3111C31.3804 44.917 30.5009 47.0951 30.5009 49.3663Z"
              fill="white"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_939_3195"
              x="28.5007"
              y="20"
              width="40"
              height="61"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.2 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_939_3195"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_939_3195"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      </svg>

      <p className="mt-6 txt-[24_28_600]">Hello there!</p>

      <p className="mt-2 txt-[16_24_400]">
        We are happy to see you.
        <br />
        Please connect your Figma account.
      </p>

      <button
        onClick={interactor.login}
        className={classNames(
          'mt-6 rounded-lg bg-[#0B1118] px-4 py-2 text-base text-white transition-all duration-200 ease-in-out',
          {
            '!bg-[#374FD5]': isHovered,
          }
        )}
        onPointerEnter={enableHover}
        onPointerLeave={disableHover}
      >
        Connect
      </button>
    </div>
  )
}
