import { buttons } from '@aninix/app-design-system'
import { usePlayback } from '@aninix/core'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'

const buttonSize = { width: 40, height: 30 }
const playbackSpeeds = [0.25, 0.5, 1, 1.5, 2]
interface IPlaybackSpeedItem {
  factor: number
  handleClick: () => void
}

export interface IProps {}

export const TimeSpeedPicker: React.FCC<IProps> = observer(() => {
  const playback = usePlayback()

  const [mouseInGroup, setMouseInGroup] = React.useState<boolean>(false)
  const [speedOffset, setSpeedOffset] = React.useState<number>(2)

  const onMouseEnter = React.useCallback(() => {
    setMouseInGroup(true)
  }, [])
  const onMouseLeave = React.useCallback(() => {
    setMouseInGroup(false)
  }, [])

  const playbackSpeedItems: IPlaybackSpeedItem[] = React.useMemo(() => {
    return playbackSpeeds.map((factor: number, index: number) => {
      return {
        factor: factor,
        handleClick: () => {
          playback.updateSpeed(factor)
          setSpeedOffset(index)
          setMouseInGroup(false)
        },
      }
    })
  }, [])

  return (
    <div
      className={classNames(
        'abosolute border-r-[1px] border-black  transition-all duration-300',
        {
          [' border-opacity-0']: mouseInGroup,
          [' border-opacity-10']: !mouseInGroup,
        }
      )}
    >
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className="abosolute flex flex-col overflow-hidden rounded hover:overflow-auto hover:bg-white hover:shadow-md"
        style={{
          transition: 'all 100ms ease-in-out',
          maxHeight: mouseInGroup ? `${playbackSpeeds.length * 30}px` : `30px`,
        }}
      >
        {playbackSpeedItems.map((item: IPlaybackSpeedItem, index: number) => {
          return (
            <div
              key={item.factor}
              style={{
                transition: 'all 100ms ease-in-out',
                transform: mouseInGroup
                  ? 'translateY(0px)'
                  : `translateY(-${30 * speedOffset}px)`,
                backgroundColor:
                  speedOffset == index && mouseInGroup
                    ? 'rgba(0, 0, 0, 0.07)'
                    : 'transparent',
              }}
            >
              <buttons.Icon onClick={item.handleClick} btnSize={buttonSize}>
                <p className="w-full text-xs text-black">{`${item.factor}x`}</p>
              </buttons.Icon>
            </div>
          )
        })}
      </div>
    </div>
  )
})

TimeSpeedPicker.displayName = 'TimeSpeedPicker'
