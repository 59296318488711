import * as React from 'react'

import { Locked } from './Locked'
import { Unlocked } from './Unlocked'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  locked: boolean
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Lock: React.FCC<IProps> = ({
  locked,
  size = defaultSize,
  style,
}) =>
  locked ? (
    <Locked size={size} style={style} />
  ) : (
    <Unlocked size={size} style={style} />
  )
