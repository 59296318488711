/**
 * @file
 * If group
 * 1. create composition
 * 2. return layer with linked composition (add opacity to this layer)
 *
 * If frame without clips content
 * 3. See 1-2
 *
 * If frame with clips content
 * 4. group all layers together into composition
 * 5. apply frame mask to this composition
 * 6. return layer with linked composition
 *
 * If node contains layers with mask
 * 7. See 4-6
 */
import {
  BlendModeComponent,
  BlendModeType,
  ChildrenRelationsAspect,
  ClipContentComponent,
  EffectType,
  EffectTypeComponent,
  EffectsRelationsAspect,
  Entity,
  EntryComponent,
  FillsRelationsAspect,
  FpsComponent,
  MaskComponent,
  NameComponent,
  NodeType,
  NodeTypeComponent,
  OpacityComponent,
  PaintType,
  PaintTypeComponent,
  ParentRelationAspect,
  RgbaValueComponent,
  Root,
  VisibleInViewportComponent,
  generateId,
  getSortedKeyframes,
} from '@aninix-inc/model'
import * as R from 'ramda'

import { includesHorizontalLine } from '../utils/includes-horizontal-line'
import { includesVerticalLine } from '../utils/includes-vertical-line'
import { isMaskValid } from '../utils/is-mask-valid'
import { Composition } from './composition'
import { FillMask } from './fill-mask'
import { ImageLayers } from './image-layers'
import { Layer } from './layer'
import { NullLayersChain } from './null-layers-chain'
import { Base } from './properties/base'
import { DropShadow, Effects, LayerBlur } from './properties/effects'
import { EmptyGroup } from './properties/empty-group'
import { Fill } from './properties/fills'
import { Paths } from './properties/paths'
import { ShapeGroup } from './properties/shape-group'
import { StrokeLayers } from './stroke-layers'
import {
  LottieAsset,
  LottieAssetPrecomp,
  LottieEffect,
  LottieLayer,
  LottieLayerType,
  LottieMatteMode,
  LottieShapeLayer,
} from './types'

function Layers(
  payload: {
    nodes: Entity[]
    parent?: number
  },
  assets: LottieAsset[]
): LottieLayer[] {
  const { nodes, parent } = payload

  // @NOTE: reverse required to properly set layers for lottie
  return R.reverse(nodes)
    .filter((n) => n.getComponentOrThrow(VisibleInViewportComponent).value)
    .flatMap((n) => {
      return Layer({ node: n, parent }, assets)
    })
}

function CompositionAsAsset(payload: {
  layers: LottieLayer[]
  name: string
  fps: number
  id: string
}): LottieAssetPrecomp {
  const { layers, name, fps, id } = payload

  return {
    nm: name,
    fr: fps,
    id,
    layers,
  }
}

function LinkedComposition(
  payload: { node: Entity; id: string },
  assets: LottieAsset[]
): LottieLayer {
  const { node, id } = payload
  return Composition(node, assets, {
    id,
    isMask: false,
  })
}

function MaskedLayers(payload: {
  mask: LottieLayer
  layers: LottieLayer[]
}): LottieLayer[] {
  const { mask, layers } = payload

  return layers.flatMap((layer) => {
    if (mask == null || layer.ty === LottieLayerType.Null) {
      return layer
    }

    if (includesVerticalLine(layer) || includesHorizontalLine(layer)) {
      return layer
    }

    return [
      mask,
      {
        ...layer,
        tt: LottieMatteMode.Alpha,
      },
    ]
  })
}

function groupLayersByMasks(nodes: Entity[]): Entity[][] {
  let childrenGroupsByMasks: Entity[][] = [[]]
  let maskIdx = 0
  const childrenCount = nodes.length
  for (let i = 0; i < childrenCount; i += 1) {
    const child = nodes[i]

    if (child.getComponentOrThrow(MaskComponent).value) {
      maskIdx = i
    }

    childrenGroupsByMasks.push([])
    childrenGroupsByMasks[maskIdx].push(child)
  }

  // @NOTE: reverse required to place layers properly
  return R.reverse(childrenGroupsByMasks).filter((group) => group.length > 0)
}

function Group(node: Entity, assets: LottieAsset[]): LottieLayer[] {
  const id = generateId()
  const nullLayers = NullLayersChain(node)
  const layers = Layers(
    {
      nodes: node.getAspectOrThrow(ChildrenRelationsAspect).getChildrenList(),
      parent: R.last(nullLayers)?.ind,
    },
    assets
  )

  const opacityKeyframes = getSortedKeyframes(
    node.getComponentOrThrow(OpacityComponent)
  )
  if (
    node.getComponentOrThrow(BlendModeComponent).value ===
      BlendModeType.Normal &&
    opacityKeyframes.length === 0 &&
    node.getComponentOrThrow(OpacityComponent).value === 1
  ) {
    return [...nullLayers, ...layers]
  }

  assets.push(
    CompositionAsAsset({
      layers: [...nullLayers, ...layers],
      name: `[GROUP] ${layers.map((l) => l.nm).join(' / ')}`,
      id,
      fps: node
        .getProjectOrThrow()
        .getEntityByTypeOrThrow(Root)
        .getComponentOrThrow(FpsComponent).value,
    })
  )
  return [LinkedComposition({ node, id }, assets)]
}

function getFrameLayers(
  payload: {
    node: Entity
    parent?: number
  },
  assets: LottieAsset[]
): {
  strokeLayers: LottieLayer[]
  children: LottieLayer[]
  fillLayers: LottieLayer[]
} {
  const { node, parent } = payload

  const baseProperties = Base({
    node,
    parent,
    skipAnimation: true,
  })

  const paths = Paths({ node })
  const layerBlurs: LottieEffect[] = R.defaultTo(
    [],
    node.getAspect(EffectsRelationsAspect)?.getChildrenList()
  )
    .filter(
      (effect) =>
        effect.getComponentOrThrow(EffectTypeComponent).value ===
          EffectType.LayerBlur &&
        effect.getComponentOrThrow(VisibleInViewportComponent).value
    )
    .map((effect) => LayerBlur(effect))

  // @NOTE: for some reason fills should be reversed.
  const fillLayers: (LottieShapeLayer | LottieLayer)[] = R.reverse(
    R.defaultTo([], node.getAspect(FillsRelationsAspect)?.getChildrenList())
  )
    .filter((paint) => {
      const paintType = paint.getComponentOrThrow(PaintTypeComponent).value

      // @TODO: add support of linear and radial gradients
      if (paintType === PaintType.Solid) {
        const keyframes = getSortedKeyframes(
          paint.getComponentOrThrow(RgbaValueComponent)
        )

        if (
          keyframes.length === 0 &&
          paint.getComponentOrThrow(RgbaValueComponent).value.a === 0
        ) {
          return false
        }
      }

      if (
        paint.getComponentOrThrow(VisibleInViewportComponent).value === false
      ) {
        return false
      }

      return true
    })
    .flatMap((paint) => {
      if (
        paint.getComponentOrThrow(PaintTypeComponent).value === PaintType.Image
      ) {
        return ImageLayers({ node, paint, parent }, assets)
      }

      if (layerBlurs.length > 0) {
        return [
          {
            ty: LottieLayerType.Shape,
            ...baseProperties,
            shapes: [ShapeGroup([...paths, Fill({ node, paint })])],
            ef: layerBlurs,
          },
        ]
      }

      return [
        {
          ty: LottieLayerType.Shape,
          ...baseProperties,
          shapes: [ShapeGroup([...paths, Fill({ node, paint })])],
        },
      ]
    })
  const strokeLayers = StrokeLayers({ node, parent })
  const children = Layers(
    {
      nodes: node.getAspectOrThrow(ChildrenRelationsAspect).getChildrenList(),
      parent,
    },
    assets
  )

  return {
    strokeLayers: strokeLayers.filter(
      (l) => l != (null as unknown as LottieLayer)
    ),
    children: children.filter((l) => l != null) as [LottieLayer],
    fillLayers,
  }
}

function handleFrameWithoutClipsContent(
  node: Entity,
  assets: LottieAsset[]
): LottieLayer[] {
  const id = generateId()
  const nullLayers = NullLayersChain(node)
  const { strokeLayers, children, fillLayers } = getFrameLayers(
    { node, parent: R.last(nullLayers)?.ind },
    assets
  )
  const layers = [...nullLayers, ...strokeLayers, ...children, ...fillLayers]
  assets.push(
    CompositionAsAsset({
      layers: layers,
      name: `[FRAME] ${layers.map((l) => l.nm).join(' / ')}`,
      id,
      fps: node
        .getProjectOrThrow()
        .getEntityByTypeOrThrow(Root)
        .getComponentOrThrow(FpsComponent).value,
    })
  )

  const effects = Effects({ node })

  if (node.hasComponent(EntryComponent) === false && effects.length > 0) {
    return [
      {
        ...LinkedComposition({ node, id }, assets),
        ef: effects,
      },
    ]
  }

  return [LinkedComposition({ node, id }, assets)]
}

function handleFrameWithClipsContent(
  node: Entity,
  assets: LottieAsset[]
): LottieLayer[] {
  const id = generateId()
  const nullLayers = NullLayersChain(node)
  const { children, fillLayers } = getFrameLayers(
    { node, parent: R.last(nullLayers)?.ind },
    assets
  )

  const placeholder = FillMask({
    node,
    applyMaskByDefault: false,
    color: [1, 1, 1],
    // @NOTE: we should take the second parent from the right.
    // The direct parent is current frame, the second parent is the actual we need.
    parent: node
      .getAspect(ParentRelationAspect)
      ?.getParentEntity()
      ?.hasComponent(EntryComponent)
      ? undefined
      : R.last(R.dropLast(1, nullLayers))?.ind,
  })
  const dropShadows: LottieEffect[] = R.defaultTo(
    [],
    node.getAspect(EffectsRelationsAspect)?.getChildrenList()
  )
    .filter(
      (effect) =>
        effect.getComponentOrThrow(EffectTypeComponent).value ===
          EffectType.DropShadow &&
        effect.getComponentOrThrow(VisibleInViewportComponent).value
    )
    .map((effect) => DropShadow(effect))
  // @NOTE: we have separation of drop shadows here to properly handle rendering
  const dropShadowLayers: LottieShapeLayer[] =
    node.hasComponent(EntryComponent) === false && dropShadows.length > 0
      ? [
          {
            ...{
              ...placeholder,
              shapes: [...placeholder.shapes, EmptyGroup(node)],
            },
            ef: dropShadows,
          },
        ]
      : []
  assets.push(
    CompositionAsAsset({
      layers: [...nullLayers, ...children, ...fillLayers],
      name: node.getComponentOrThrow(NameComponent).value,
      id,
      fps: node
        .getProjectOrThrow()
        .getEntityByTypeOrThrow(Root)
        .getComponentOrThrow(FpsComponent).value,
    })
  )

  const maskLayer = isMaskValid(node) ? Composition(node, assets) : null
  // @NOTE: temporary disabled because clips content doesn't work in that case
  // @TODO: find the better way to handle such cases (effects + clips content)
  const maskRequired = true
  // const maskRequired =
  //   node.topLeftRadius.hasAnimation ||
  //   node.topLeftRadius.value > 0 ||
  //   node.topRightRadius.hasAnimation ||
  //   node.topRightRadius.value > 0 ||
  //   node.bottomLeftRadius.hasAnimation ||
  //   node.bottomLeftRadius.value > 0 ||
  //   node.bottomRightRadius.hasAnimation ||
  //   node.bottomRightRadius.value > 0 ||
  //   node.cornerRadius.hasAnimation ||
  //   node.cornerRadius.value > 0

  if (!maskLayer || !maskRequired) {
    const strokeLayers = StrokeLayers({
      node,
      parent: R.last(nullLayers)?.ind,
    })

    if (dropShadows.length > 0) {
      return [
        ...nullLayers,
        ...strokeLayers,
        {
          ...LinkedComposition({ node, id }, assets),
          ef: dropShadows,
        },
      ]
    }

    return [
      ...nullLayers,
      ...strokeLayers,
      LinkedComposition({ node, id }, assets),
    ]
  }

  // @NOTE: required to separate null layers from compositions
  const nullLayers2 = NullLayersChain(node)
  const strokeLayers = StrokeLayers({
    node,
    parent: R.last(nullLayers2)?.ind,
  })
  return [
    ...nullLayers2,
    ...strokeLayers,
    ...MaskedLayers({
      mask: maskLayer,
      layers: [...nullLayers, LinkedComposition({ node, id }, assets)],
    }),
    ...dropShadowLayers,
  ]
}

function Masked(
  groupsByMasks: Entity[][],
  assets: LottieAsset[]
): LottieLayer[] {
  return groupsByMasks.flatMap((group) => {
    const id = generateId()
    const maskNode = R.head(group)!

    const nodes = maskNode.getComponentOrThrow(MaskComponent).value
      ? R.drop(1, group)
      : group

    const nullLayers = NullLayersChain(
      maskNode.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow()
    )
    const layers = Layers({ nodes, parent: R.last(nullLayers)?.ind }, assets)
    assets.push(
      CompositionAsAsset({
        layers: [...nullLayers, ...layers],
        name: `[MASK] ${layers.map((l) => l.nm).join(' / ')}`,
        id,

        fps: maskNode
          .getProjectOrThrow()
          .getEntityByTypeOrThrow(Root)
          .getComponentOrThrow(FpsComponent).value,
      })
    )

    const maskLayer = isMaskValid(maskNode)
      ? Composition(maskNode, assets)
      : null

    if (
      maskLayer == null ||
      maskNode.getComponentOrThrow(MaskComponent).value === false
    ) {
      return [LinkedComposition({ node: maskNode, id }, assets)]
    }

    return MaskedLayers({
      mask: maskLayer,
      layers: [
        ...nullLayers,

        LinkedComposition({ node: maskNode, id }, assets),
      ],
    })
  })
}

export function GroupOrFrame(
  payload: {
    node: Entity
  },
  assets: LottieAsset[]
): LottieLayer[] {
  const { node } = payload
  const nodeType = node.getComponentOrThrow(NodeTypeComponent).value
  const children = node
    .getAspectOrThrow(ChildrenRelationsAspect)
    .getChildrenList()

  if (
    children.find((n: Entity) => n.getComponentOrThrow(MaskComponent).value) !=
    null
  ) {
    return Masked(groupLayersByMasks(children), assets)
  }

  if (nodeType === NodeType.Group) {
    return Group(node, assets)
  }

  if ([NodeType.Frame, NodeType.Instance].includes(nodeType)) {
    if (node.getComponent(ClipContentComponent)?.value) {
      return handleFrameWithClipsContent(node, assets)
    }

    return handleFrameWithoutClipsContent(node, assets)
  }

  return []
}
