import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 20,
  y: 20,
}

export const Comments: React.FCC<IIcon> = ({
  size = defaultSize,
  className = '',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 20 20"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.85067 4.09136C8.099 1.7872 12.7415 1.97553 15.7448 4.53803C18.8007 7.14386 19.2182 11.403 16.7048 14.4522C14.3365 17.3247 10.0673 18.2714 6.51234 16.7897L6.319 16.7055L2.67317 17.4814L2.61067 17.4922L2.519 17.4997L2.42484 17.4964L2.38817 17.4922L2.2965 17.4755L2.209 17.4472L2.12567 17.4105L2.06234 17.3755L1.97234 17.3114L1.90484 17.2497L1.844 17.1805L1.79984 17.118L1.74567 17.0222L1.71067 16.9339L1.68484 16.8397L1.674 16.7772L1.6665 16.6855L1.66984 16.5914L1.674 16.5547L1.69067 16.463L1.709 16.403L2.66734 13.5272L2.649 13.4972C0.807337 10.3747 1.6415 6.50386 4.65817 4.2322L4.84984 4.0922L4.85067 4.09136Z"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
