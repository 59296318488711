import {
  createComponentsProvider,
  createEntitiesProvider,
  createSystemsProvider,
  EntryComponent,
  FillsRelationsAspect,
  Frame,
  NameComponent,
  Project,
  RgbaValueComponent,
  Root,
  SizeComponent,
  StrokesRelationsAspect,
} from '@aninix-inc/model'
import { Vec2 } from '@aninix-inc/model/base/values'

export const createProject = ({ entrySize }: { entrySize: Vec2 }) => {
  const project = new Project({
    componentsProvider: createComponentsProvider(),
    entitiesProvider: createEntitiesProvider(),
    systemsProvider: createSystemsProvider(),
  })

  const root = project.createEntity(Root)
  root.updateComponent(RgbaValueComponent, { r: 30, g: 30, b: 30, a: 1 })

  const entry = project.createEntity(Frame)
  entry.updateComponent(NameComponent, 'Entry')
  entry.createComponent(EntryComponent)
  entry.getAspectOrThrow(FillsRelationsAspect).clear()
  entry.getAspectOrThrow(StrokesRelationsAspect).clear()
  entry.updateComponent(SizeComponent, entrySize)

  return project
}
