import { Patch, Project, Version } from '@aninix-inc/model'
import { config } from '../../../apps/web-app/src/config'
import { httpClient } from './http-client'

const isPatchEmpty = (patch: Patch): boolean =>
  patch.projectOps.length === 0 && patch.entitiesOps.length === 0

/**
 * upload local patch to remote
 */
export async function patchRemoteProject(
  project: Project,
  remoteProjectVersion?: Version
): Promise<void> {
  // @TODO: think about this line, maybe we don't need this
  // because all checks are happen on the server side.
  const patch = project.getPatchDiff(remoteProjectVersion)

  if (isPatchEmpty(patch)) {
    return
  }

  await httpClient.patch(
    `${config.apiUrl}/v2/projects/${project.id}/patch`,
    patch
  )
}
