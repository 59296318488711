// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes VbLcV34bZGycZSmrT08v{0%{transform:rotate(0)}100%{transform:rotate(360deg)}}.p426EiNncNYpVwcbt0bo{transform-origin:21.5px 21.5px;animation-name:VbLcV34bZGycZSmrT08v;animation-duration:.8s;animation-timing-function:cubic-bezier(0.5, 0.25, 0.5, 0.75);animation-iteration-count:infinite;animation-fill-mode:both}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/icons/pull-update-from-figma/index.scss"],"names":[],"mappings":"AAEA,gCACE,GACE,mBAAA,CAGF,KACE,wBAAA,CAAA,CAIJ,sBACE,8BAAA,CACA,mCAAA,CACA,sBAAA,CACA,4DAAA,CACA,kCAAA,CACA,wBAAA","sourcesContent":["@use '../../../../theme/index.scss' as *;\n\n@keyframes infiniteRotation {\n  0% {\n    transform: rotate(0);\n  }\n\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n.loading {\n  transform-origin: 21.5px 21.5px;\n  animation-name: infiniteRotation;\n  animation-duration: 0.8s;\n  animation-timing-function: cubic-bezier(0.5, 0.25, 0.5, 0.75);\n  animation-iteration-count: infinite;\n  animation-fill-mode: both;\n}\n"],"sourceRoot":""}]);
// Exports
export var loading = `p426EiNncNYpVwcbt0bo`;
export var infiniteRotation = `VbLcV34bZGycZSmrT08v`;
export default ___CSS_LOADER_EXPORT___;
