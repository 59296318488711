import * as React from 'react'

import { IIcon } from '.'

const defaultSize = { x: 20, y: 20 }

export const Delete: React.FCC<IIcon> = ({
  size = defaultSize,
  className = '',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 20 20`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.8748 4.375L3.12476 4.37501"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.875 1.875H13.125"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.625 4.375V16.25C15.625 16.4158 15.5592 16.5747 15.4419 16.6919C15.3247 16.8092 15.1658 16.875 15 16.875H5C4.83424 16.875 4.67527 16.8092 4.55806 16.6919C4.44085 16.5747 4.375 16.4158 4.375 16.25V4.375"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
