import React from 'react'

export const DuplicateIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="5"
      y="5"
      width="9"
      height="9"
      rx="2"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.3229 2.5C10.1938 2.38618 10.0498 2.28879 9.89396 2.21091C9.47186 2 8.92124 2 7.82 2H5.18C4.07876 2 3.52814 2 3.10604 2.21091C2.71862 2.4045 2.4045 2.71862 2.21091 3.10604C2 3.52814 2 4.07876 2 5.18V7.82C2 8.92124 2 9.47186 2.21091 9.89396C2.28879 10.0498 2.38618 10.1938 2.5 10.3229"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
