import classNames from 'classnames'
import * as React from 'react'

export const LottieArt = ({ isDraggedOver }: { isDraggedOver: boolean }) => (
  <div className="relative">
    <DefaultArt
      className={classNames('transition-opacity duration-200', {
        ['opacity-0']: isDraggedOver,
      })}
    />
    <HighlightedArt
      className={classNames(
        'absolute inset-0 transition-opacity duration-200',
        { ['opacity-0']: !isDraggedOver }
      )}
    />
  </div>
)

const HighlightedArt = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="201"
    height="141"
    viewBox="0 0 201 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M109.5 20.5V38.2778C109.5 39.4565 109.968 40.587 110.802 41.4205C111.635 42.254 112.766 42.7222 113.944 42.7222H131.722"
      fill="url(#paint0_linear_5_11)"
    />
    <path
      d="M122.833 100.5H78.3889C76.0314 100.5 73.7705 99.5635 72.1035 97.8965C70.4365 96.2295 69.5 93.9686 69.5 91.6111V29.3889C69.5 27.0314 70.4365 24.7705 72.1035 23.1035C73.7705 21.4365 76.0314 20.5 78.3889 20.5H109.5L131.722 42.7222V91.6111C131.722 93.9686 130.786 96.2295 129.119 97.8965C127.452 99.5635 125.191 100.5 122.833 100.5Z"
      fill="url(#paint1_linear_5_11)"
    />
    <g filter="url(#filter0_dddddd_5_11)">
      <path
        d="M94 64.5V82.5L111 66.5L92 50.5"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter1_dddddd_5_11)">
      <path
        d="M115 76.5C109.667 76.5 111.889 84.5 107 84.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M109.5 20.5V38.2778C109.5 39.4565 109.968 40.587 110.802 41.4205C111.635 42.254 112.766 42.7222 113.944 42.7222H131.722"
      fill="url(#paint2_linear_5_11)"
    />
    <ellipse
      opacity="0.3"
      cx="100.5"
      cy="104.5"
      rx="80"
      ry="20"
      stroke="url(#paint3_linear_5_11)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="0.1 4"
    />
    <defs>
      <filter
        id="filter0_dddddd_5_11"
        x="84"
        y="47.5"
        width="35"
        height="64.0001"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5_11"
          result="effect2_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_5_11"
          result="effect3_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_5_11"
          result="effect4_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_5_11"
          result="effect5_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="21" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_5_11"
          result="effect6_dropShadow_5_11"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_5_11"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dddddd_5_11"
        x="99.5"
        y="74"
        width="23"
        height="39"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_5_11"
          result="effect2_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_5_11"
          result="effect3_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_5_11"
          result="effect4_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_5_11"
          result="effect5_dropShadow_5_11"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="21" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.215686 0 0 0 0 0.309804 0 0 0 0 0.835294 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_5_11"
          result="effect6_dropShadow_5_11"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_5_11"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_5_11"
        x1="100.611"
        y1="20.5"
        x2="100.611"
        y2="99.1885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6075ED" />
        <stop offset="1" stopColor="#C8D0FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_5_11"
        x1="100.611"
        y1="20.5"
        x2="100.611"
        y2="99.1885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#6075ED" />
        <stop offset="1" stopColor="#C8D0FF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_5_11"
        x1="120.611"
        y1="20.5"
        x2="120.611"
        y2="42.3579"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BBC5FF" />
        <stop offset="1" stopColor="#EEF1FF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_5_11"
        x1="100.5"
        y1="124.5"
        x2="100.5"
        y2="84.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#374FD5" />
        <stop offset="0.766047" stopColor="#374FD5" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)

const DefaultArt = ({ className }: { className: string }) => (
  <svg
    className={className}
    width="201"
    height="141"
    viewBox="0 0 201 141"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M110 20.5V38.2778C110 39.4565 110.468 40.587 111.302 41.4205C112.135 42.254 113.266 42.7222 114.444 42.7222H132.222"
      fill="url(#paint0_linear_3963_16124)"
    />
    <path
      d="M123.333 100.5H78.8889C76.5314 100.5 74.2705 99.5635 72.6035 97.8965C70.9365 96.2295 70 93.9686 70 91.6111V29.3889C70 27.0314 70.9365 24.7705 72.6035 23.1035C74.2705 21.4365 76.5314 20.5 78.8889 20.5H110L132.222 42.7222V91.6111C132.222 93.9686 131.286 96.2295 129.619 97.8965C127.952 99.5635 125.691 100.5 123.333 100.5Z"
      fill="url(#paint1_linear_3963_16124)"
    />
    <g filter="url(#filter0_dddddd_3963_16124)">
      <path
        d="M94 64.5V82.5L111 66.5L92 50.5"
        stroke="white"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter1_dddddd_3963_16124)">
      <path
        d="M115 76.5C109.667 76.5 111.889 84.5 107 84.5"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M109.5 20.5V38.2778C109.5 39.4565 109.968 40.587 110.802 41.4205C111.635 42.254 112.766 42.7222 113.944 42.7222H131.722"
      fill="url(#paint2_linear_3963_16124)"
    />
    <ellipse
      opacity="0.3"
      cx="100.5"
      cy="104.5"
      rx="80"
      ry="20"
      stroke="url(#paint3_linear_3963_16124)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="0.1 4"
    />
    <defs>
      <filter
        id="filter0_dddddd_3963_16124"
        x="84"
        y="47.5"
        width="35"
        height="64"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3963_16124"
          result="effect2_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_3963_16124"
          result="effect3_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_3963_16124"
          result="effect4_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_3963_16124"
          result="effect5_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="21" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_3963_16124"
          result="effect6_dropShadow_3963_16124"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_3963_16124"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dddddd_3963_16124"
        x="99.5"
        y="74"
        width="23"
        height="39"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_3963_16124"
          result="effect2_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_3963_16124"
          result="effect3_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_3963_16124"
          result="effect4_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_3963_16124"
          result="effect5_dropShadow_3963_16124"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="21" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_3963_16124"
          result="effect6_dropShadow_3963_16124"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_3963_16124"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_3963_16124"
        x1="101.111"
        y1="20.5"
        x2="101.111"
        y2="99.1885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E5E5" />
        <stop offset="1" stopColor="#F4F4F4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_3963_16124"
        x1="101.111"
        y1="20.5"
        x2="101.111"
        y2="99.1885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E5E5" />
        <stop offset="1" stopColor="#F4F4F4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_3963_16124"
        x1="120.611"
        y1="20.5"
        x2="120.611"
        y2="42.3579"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5F5F5" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_3963_16124"
        x1="100.5"
        y1="124.5"
        x2="100.5"
        y2="84.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#303030" />
        <stop offset="0.766047" stopColor="#6F6F6F" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
)
