import { Entity } from '@aninix-inc/model'
import * as R from 'ramda'
import * as React from 'react'

import { Segment } from './segment'

export interface IProps {
  segments: [Entity, Entity][]
}

export const Track: React.FCC<IProps> = ({ segments }: IProps) => {
  return (
    <>
      {segments.length > 0 &&
        segments.map((segment: [Entity, Entity]) => (
          <Segment
            key={`${R.head(segment)!.id}-${R.last(segment)!.id}`}
            segment={segment}
          />
        ))}
    </>
  )
}

Track.displayName = 'Track'
