import classNames from 'classnames'
import React from 'react'

export const FoldIcon: React.FCC<{ isOpen: boolean }> = ({ isOpen }) => (
  <svg
    className={classNames('duration-100 transition-transform', {
      ['rotate-90']: isOpen,
    })}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 8L6 5L6 11L10 8Z"
      fill="#0B1118"
      stroke="#0B1118"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
