import * as React from 'react'

import { Icons } from '../icons'

export interface IProps {
  handleClick: () => void
  className?: string
}
export const CreateNewFolder: React.FCC<IProps> = ({
  handleClick,
  className,
}) => (
  <button className="" onClick={handleClick}>
    <Icons.NewFolderButton className={className} />
  </button>
)

CreateNewFolder.displayName = 'CreateNewFolder'
