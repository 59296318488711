import { LocalLogger, LogLevel } from '@aninix/logger'
import * as R from 'ramda'
import { yieldToMain } from '../../utils'
import { Encoder } from './types'
import WebmWritter from './vendor/webm-writter-js/index.js'

const logger = new LocalLogger({
  level: process.env.LOG_LEVEL as LogLevel,
  prefix: '[encodeWebm]',
})

function canvasFromOffscreenCanvas(canvas: OffscreenCanvas): HTMLCanvasElement {
  const newCanvas = document.createElement('canvas')
  newCanvas.width = canvas.width
  newCanvas.height = canvas.height
  newCanvas.getContext('2d')!.drawImage(canvas, 0, 0)
  return newCanvas
}

/**
 * @description combine images to mp4
 */
export const encodeWebm: Encoder = ({
  iterable,
  options,
  onProgressUpdate,
  onError,
  onDone,
}) => {
  const { fps, framesCount } = options
  let encoder: any
  let isCancelled = false
  const quality = 0.95

  const render = async () => {
    try {
      encoder = new WebmWritter({
        // WebM image quality from 0.0 (worst) to 0.99999 (best), 1.00 (VP8L lossless) is not supported
        quality,
        // FileWriter in order to stream to a file instead of buffering to memory (optional)
        fileWriter: null,
        // Node.js file handle to write to instead of buffering to memory (optional)
        fd: null,

        // You must supply one of:
        // Duration of frames in milliseconds
        frameDuration: null,
        // Number of frames per second
        frameRate: fps,

        // True if an alpha channel should be included in the video
        transparent: options.alpha ?? false,
        // Allows you to set the quality level of the alpha channel separately.
        // If not specified this defaults to the same value as `quality`.
        alphaQuality: undefined,
      })

      let i = 0
      for await (const canvas of iterable) {
        if (isCancelled) {
          return
        }
        if (i % 20 === 0) {
          await yieldToMain()
        }
        const start = Date.now()
        encoder.addFrame(canvasFromOffscreenCanvas(canvas))
        const end = Date.now()
        onProgressUpdate(R.clamp(0, 1, i / framesCount), end - start)
        logger.log(
          `combining frame ${i}/${framesCount} time in ms`,
          end - start
        )
        i++
      }

      const blob = await encoder.complete()
      onDone(blob)
    } catch (err: any) {
      onError(
        `Unexpected issue happen. Please send the following info to us: ${err.message}`
      )
      logger.error('rendering error', err)
    } finally {
      encoder = undefined
    }
  }

  render()

  return () => {
    try {
      isCancelled = true
      encoder = undefined
    } catch (err: any) {
      logger.error('cancelation error', err)
    }
  }
}
