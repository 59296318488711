import { Backdrop, Modal } from '@material-ui/core'
import classNames from 'classnames'
import * as React from 'react'

import { Button } from '../design-system/button'
import { Icons } from '../icons'

export interface IProps {
  handleRename: (name: string) => void
  name: string
  handleClose: () => void
  isOpen: boolean
}
export const Rename: React.FCC<IProps> = ({
  handleRename,
  name,
  handleClose,
  isOpen,
}: IProps) => {
  const ref = React.useRef<HTMLInputElement>(null)

  React.useEffect(() => {
    //@TODO: debug why it's not working without timeout
    setTimeout(() => {
      ref.current?.focus()
      ref.current?.select()
    }, 100)
  }, [isOpen])

  const [inputName, setInputName] = React.useState<string>(name)

  const handleNameInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputName(e.currentTarget.value)
    },
    []
  )

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (inputName === name) return

      handleRename(inputName)
    },
    [inputName]
  )

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      className="flex h-full w-full flex-col items-center justify-center"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <div
        className={classNames(
          'min-w-[400px] rounded-xl bg-white shadow-2xl transition-all duration-300'
          // {
          //   [' scale-95']: !isOpen,
          // }
        )}
      >
        <div className="flex w-full flex-col gap-4 p-6 ">
          <div className="flex w-full flex-row items-center justify-between">
            <p className=" font-header text-2xl font-medium text-black text-opacity-80">
              Rename
            </p>
            <div
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleClose()
              }}
            >
              <Icons.Cross className="h-8 w-8" />
            </div>
          </div>
          <div className="flex w-full flex-row gap-4">
            <div className="flex w-full items-center gap-4 rounded-lg border-[1px] border-gray-200 px-3  py-1">
              <form onSubmit={handleSubmit} className="w-full">
                <input
                  ref={ref}
                  spellCheck={false}
                  className=" rows w-full min-w-[90px] resize-none text-base text-secondary outline-none"
                  defaultValue={inputName}
                  onChange={(e) => {
                    handleNameInput(e)
                  }}
                />
              </form>
            </div>
          </div>
          <div className="flex w-full flex-row justify-end gap-2">
            <div
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                handleClose()
              }}
              className="flex h-10 flex-row items-center gap-2 rounded-lg px-3 py-2 ring-1 ring-gray-200"
            >
              <p className="font-body text-sm font-medium text-gray-500">
                Cancel
              </p>
            </div>
            <Button
              label={'Save'}
              isActive={inputName !== name}
              onClick={(e) => {
                e?.preventDefault()
                e?.stopPropagation()
                handleRename(inputName)
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  )
}

Rename.displayName = 'Rename'
