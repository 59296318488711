// @TODO: make only one instance. Similar class also used as legacy in another file.
import * as R from 'ramda'

export class LocalStorageIo<T extends Object> {
  constructor(
    private readonly key: string,
    private readonly initialState?: T,
    private readonly options?: {
      debug: boolean
    }
  ) {}

  /**
   * Return in memory state if exists
   */
  get = async () => {
    if (this.options?.debug) {
      console.log(`[LocalStorageIo.${this.key}] get`)
    }

    return this.getParsedValue()
  }

  /**
   * Replace state with provided
   */
  set = async (map: T) => {
    if (this.options?.debug) {
      console.log(`[LocalStorageIo.${this.key}] set`, map)
    }

    this.setParsedValue(map)
    return this
  }

  /**
   * Partially update state
   */
  update = async (map: Partial<T>) => {
    if (this.options?.debug) {
      console.log(`[LocalStorageIo.${this.key}] update`, map)
    }

    const value = this.getParsedValue()

    if (value == null && this.initialState == null) {
      throw new Error('Initial state was not provided')
    }

    // @ts-ignore
    this.setParsedValue(R.mergeDeepRight(value ?? this.initialState, map) as T)
  }

  /**
   * Reset to it's initial state
   */
  reset = async () => {
    if (this.options?.debug) {
      console.log(`[LocalStorageIo.${this.key}] reset`)
    }

    if (this.initialState == null) {
      throw new Error('Initial state was not provided')
    }

    this.setParsedValue(this.initialState)
  }

  private getParsedValue = (): T | null => {
    const value = window.localStorage.getItem(this.key)

    if (value == null || value === '') {
      return null
    }

    try {
      return JSON.parse(value) as T
    } catch (err: unknown) {
      console.error(err)
      return null
    }
  }

  private setParsedValue = (map: T) => {
    window.localStorage.setItem(this.key, JSON.stringify(map))
  }
}
