import {
  AnimationCurveAspect,
  Component,
  Entity,
  NameComponent,
  NodeTypeComponent,
  NumberValueComponent,
  Point2dValueComponent,
  PositionComponent,
  RotationComponent,
  ScaleComponent,
  SpatialPoint2dValueComponent,
  TargetRelationAspect,
  TimeComponent,
  TimingCurveComponent,
  TrimEndComponent,
  TrimStartComponent,
  components,
  getAnimatableValue,
  getNode,
  getSize,
  getTimingCurveKeyframesFromSegment,
  round,
} from '@aninix-inc/model'
import { DataDrawer, getDataMap, paper } from '@aninix-inc/renderer'
import snakeCase from 'lodash/snakeCase'
import * as R from 'ramda'
import { Components } from './mappers/types'

type Segment = [Entity, Entity]

function generateHash(str: string): string {
  let hash = 0

  if (str.length === 0) {
    return hash.toString()
  }

  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i)
    hash = (hash << 5) - hash + charCode
    hash = hash & hash // Convert to 32bit integer
  }

  return Math.abs(hash).toString().slice(0, 6)
}

class BaseSegment {
  constructor(private readonly segment: Segment) {}

  asTimingCurve = (): string => {
    const [left, right] = getTimingCurveKeyframesFromSegment(
      this.segment[0],
      this.segment[1]
    )
    const leftCurveComponent = left
      .getAspectOrThrow(AnimationCurveAspect)
      .getCurveComponent() as TimingCurveComponent
    const rightCurveComponent = right
      .getAspectOrThrow(AnimationCurveAspect)
      .getCurveComponent() as TimingCurveComponent

    const curve = [
      leftCurveComponent.value.out.x,
      leftCurveComponent.value.out.y,
      rightCurveComponent.value.in.x,
      rightCurveComponent.value.in.y,
    ].map((item) => round(item, { fixed: 2 }))
    return `cubic-bezier(${curve.join(', ')})`
  }
}

class CssSegment {
  constructor(
    private readonly data: {
      animationName: string
      segment: Segment
      mapper: (keyframe: Entity) => string
    }
  ) {}

  asCode = (): string => {
    const startTime = R.head(this.data.segment)!.getComponentOrThrow(
      TimeComponent
    ).value
    const duration =
      R.last(this.data.segment)!.getComponentOrThrow(TimeComponent).value -
      startTime

    // @TODO: implement getTimingCurveKeyframes
    const keyframes = `@keyframes ${this.data.animationName} {
  ${getTimingCurveKeyframesFromSegment(
    this.data.segment[0],
    this.data.segment[1]
  )
    .map((keyframe) => {
      const progress =
        (keyframe.getComponentOrThrow(TimeComponent).value - startTime) /
        duration
      const percents = Math.round(progress * 100)

      return `${percents}% {
    ${this.data.mapper(keyframe)}
  }`
    })
    .join('\n  ')}
}`

    return keyframes
  }
}

/**
 * The class generates CSS animation code from provided segments.
 * @todo refactor it further to isolate full entity. And move it to entities folder.
 */
export class CssCodeBlock {
  constructor(private readonly segments: Segment[]) {}

  asCode = (): string => {
    const mappedSegments = this.segments.map((segment) =>
      this.mapSegment(segment)
    )

    const supportedSegments = mappedSegments.filter(
      (segment) => segment.animation !== '' && segment.keyframes !== ''
    )
    const notSupportedSegments = mappedSegments.filter(
      (segment) => segment.animation === '' || segment.keyframes === ''
    )

    const groupedByNodeNames = R.groupBy(
      (segment) => segment.nodeName,
      supportedSegments
    )

    const animationData = Object.entries(groupedByNodeNames)
      .map(([nodeName, groupedMappedSegments]) => {
        const animations = groupedMappedSegments!.map(
          ({ animation }) => animation
        )
        const keyframes = groupedMappedSegments!.map(
          ({ keyframes }) => keyframes
        )

        return `${keyframes.join('\n')}
.${nodeName} {
  animation: ${animations.join(',\n    ')};
}`
      })
      .join('\n\n')

    const hasTransformSegmentsData =
      this.segments.filter(
        ([keyframe]) =>
          keyframe.hasComponent(PositionComponent) &&
          keyframe.hasComponent(RotationComponent) &&
          keyframe.hasComponent(ScaleComponent) &&
          keyframe.hasComponent(RotationComponent)
      ).length > 0

    const notSupportedSegmentsData = notSupportedSegments.map(
      ({ animationName }) => {
        return `/* ${animationName} not supported yet */`
      }
    )

    const transformSegmentsComment = `/*
  Aninix is using new syntax for individual transform properties.
  Learn more: https://web.dev/css-individual-transform-properties
*/`

    let finalCode = ''

    if (hasTransformSegmentsData) {
      finalCode += transformSegmentsComment
      finalCode += '\n'
    }

    if (notSupportedSegmentsData.length > 0) {
      finalCode += notSupportedSegmentsData.join('\n')
      finalCode += '\n'
    }

    finalCode += animationData

    return finalCode
  }

  private mapSegment = (
    segment: Segment
  ): {
    nodeName: string
    animationName: string
    keyframes: string
    animation: string
  } => {
    const [left, right] = segment
    const targetAspect = left.getAspectOrThrow(TargetRelationAspect)
    const leftNode = getNode(left)

    if (leftNode == null) {
      throw new Error('Invalid state')
    }

    const nodeName = `layer_${snakeCase(
      leftNode.getComponentOrThrow(NameComponent).value
    )}`
    const propertyMapper = this.valueFunctionGeneration(
      targetAspect.getTargetComponentOrThrow()
    )(segment)

    if (propertyMapper.isSupported === false) {
      const hash = generateHash(`${nodeName}__${propertyMapper.animationName}`)
      const animationName = `${nodeName}__${propertyMapper.animationName}_${hash}`

      return {
        nodeName,
        animationName,
        keyframes: '',
        animation: '',
      }
    }

    const animationTimingFunction = new BaseSegment(segment).asTimingCurve()

    const duration = round(
      right.getComponentOrThrow(TimeComponent).value -
        left.getComponentOrThrow(TimeComponent).value,
      { fixed: 2 }
    )
    const delay = round(left.getComponentOrThrow(TimeComponent).value, {
      fixed: 2,
    })
    const iterationCount = 1
    const direction = 'normal'
    const fillMode = 'forwards'
    const animation = `${duration}s ${animationTimingFunction} ${delay}s ${iterationCount} ${direction} ${fillMode}`
    const hash = generateHash(`${duration}-${delay}-${animationTimingFunction}`)
    const animationName = `${nodeName}__${propertyMapper.animationName}_${hash}`
    const keyframes = new CssSegment({
      animationName,
      segment,
      mapper: propertyMapper.mapper,
    }).asCode()

    return {
      nodeName,
      keyframes,
      animationName,
      animation: `${animationName} ${animation}`,
    }
  }

  private valueFunctionGeneration = (
    providedComponent: Component
  ): ((segment: Segment) =>
    | {
        animationName: string
        mapper: (keyframe: Entity) => string
        isSupported: true
      }
    | {
        animationName: string
        isSupported: false
      }) => {
    const component = providedComponent as Components

    switch (true) {
      case component instanceof components.PositionComponent: {
        return ([left]) => {
          const layer = getNode(left)

          if (layer === undefined) {
            throw new Error('Invalid state. Node not found')
          }

          const size = getSize(layer)
          const startPoint = left.getComponentOrThrow(
            SpatialPoint2dValueComponent
          ).value

          return {
            animationName: 'translate',
            mapper: (keyframe) => {
              const value = keyframe.getComponentOrThrow(
                SpatialPoint2dValueComponent
              ).value
              const x = round(((value.x - startPoint.x) / size.x) * 100, {
                fixed: 0,
              })
              const y = round(((value.y - startPoint.y) / size.y) * 100, {
                fixed: 0,
              })
              return `translate: ${x}px ${y}px;`
            },
            isSupported: true,
          }
        }
      }

      case component instanceof components.SizeComponent: {
        return () => ({
          animationName: 'size',
          mapper: (keyframe) => {
            const value = keyframe.getComponentOrThrow(
              Point2dValueComponent
            ).value
            return `width: ${round(value.x, { fixed: 0 })}px;
    height: ${round(value.y, { fixed: 0 })}px;`
          },
          isSupported: true,
        })
      }

      case component instanceof components.SkewComponent: {
        return () => ({
          animationName: 'skew',
          mapper: (keyframe) => {
            const value = keyframe.getComponentOrThrow(
              Point2dValueComponent
            ).value
            return `transform: skew(${value.x}, ${value.y});`
          },
          isSupported: true,
        })
      }

      case component instanceof components.ScaleComponent: {
        return () => ({
          animationName: 'scale',
          mapper: (keyframe) => {
            const value = keyframe.getComponentOrThrow(
              Point2dValueComponent
            ).value
            return `scale: ${round(value.x * 100, { fixed: 2 })}% ${round(
              value.y * 100,
              { fixed: 2 }
            )}%;`
          },
          isSupported: true,
        })
      }

      case component instanceof components.OpacityComponent: {
        return () => ({
          animationName: 'opacity',
          mapper: (keyframe) => {
            const value =
              keyframe.getComponentOrThrow(NumberValueComponent).value
            return `opacity: ${round(value * 100, { fixed: 2 })}%;`
          },
          isSupported: true,
        })
      }

      case component instanceof components.TrimStartComponent: {
        return () => ({
          animationName: 'trim-start',
          mapper: (keyframe) => {
            const trimPath = keyframe
              .getAspectOrThrow(TargetRelationAspect)
              .getTargetEntityOrThrow()
            const layer = getNode(keyframe)

            if (layer == null) {
              throw new Error('Invalid state')
            }

            const getData = getDataMap[
              trimPath.getComponentOrThrow(NodeTypeComponent).value
            ] as DataDrawer
            const path = getData({ entity: layer, time: 0 })
            const compoundPath = new paper.CompoundPath(
              path.map((p) => p.data).join(',')
            )
            const pathLength = round(compoundPath.length, { fixed: 2 })
            const trimEnd = getAnimatableValue(
              trimPath.getComponentOrThrow(TrimEndComponent),
              keyframe.getComponentOrThrow(TimeComponent).value
            )
            const value =
              keyframe.getComponentOrThrow(NumberValueComponent).value

            return `stroke-dasharray: ${
              (trimEnd - value) * pathLength
            } ${pathLength};
    stroke-dashoffset: ${(trimEnd - value - 1) * pathLength};`
          },
          isSupported: true,
        })
      }

      case component instanceof components.TrimEndComponent: {
        return () => ({
          animationName: 'trim-end',
          mapper: (keyframe) => {
            const trimPath = keyframe
              .getAspectOrThrow(TargetRelationAspect)
              .getTargetEntityOrThrow()
            const layer = getNode(keyframe)

            if (layer == null) {
              throw new Error('Invalid state')
            }

            const getData = getDataMap[
              trimPath.getComponentOrThrow(NodeTypeComponent).value
            ] as DataDrawer
            const path = getData({ entity: layer, time: 0 })
            const compoundPath = new paper.CompoundPath(
              path.map((p) => p.data).join(',')
            )
            const pathLength = round(compoundPath.length, { fixed: 2 })
            const trimStart = getAnimatableValue(
              trimPath.getComponentOrThrow(TrimStartComponent),
              keyframe.getComponentOrThrow(TimeComponent).value
            )
            const value =
              keyframe.getComponentOrThrow(NumberValueComponent).value

            return `stroke-dasharray: ${
              (value - trimStart) * pathLength
            } ${pathLength};
    stroke-dashoffset: ${(1 - value + (value - trimStart - 1)) * pathLength};`
          },
          isSupported: true,
        })
      }

      case component instanceof components.TrimOffsetComponent: {
        return () => ({
          animationName: 'trim-offset',
          mapper: (keyframe) => {
            const trimPath = keyframe
              .getAspectOrThrow(TargetRelationAspect)
              .getTargetEntityOrThrow()
            const layer = getNode(keyframe)

            if (layer == null) {
              throw new Error('Invalid state')
            }

            const getData = getDataMap[
              trimPath.getComponentOrThrow(NodeTypeComponent).value
            ] as DataDrawer
            const path = getData({ entity: layer, time: 0 })
            const compoundPath = new paper.CompoundPath(
              path.map((p) => p.data).join(',')
            )
            const pathLength = round(compoundPath.length, { fixed: 2 })
            const trimStart = getAnimatableValue(
              trimPath.getComponentOrThrow(TrimStartComponent),
              keyframe.getComponentOrThrow(TimeComponent).value
            )
            const trimEnd = getAnimatableValue(
              trimPath.getComponentOrThrow(TrimEndComponent),
              keyframe.getComponentOrThrow(TimeComponent).value
            )
            const value =
              keyframe.getComponentOrThrow(NumberValueComponent).value

            return `stroke-dasharray: ${
              (trimEnd - trimStart) * pathLength
            } ${pathLength};
    stroke-dashoffset: ${
      (1 - trimEnd + (trimEnd - trimStart - 1) - value) * pathLength
    };`
          },
          isSupported: true,
        })
      }

      case component instanceof components.CornerRadiusComponent: {
        return () => ({
          animationName: 'border_radius',
          mapper: (keyframe) => {
            const value =
              keyframe.getComponentOrThrow(NumberValueComponent).value
            return `border-radius: ${round(value, { fixed: 2 })}px;`
          },
          isSupported: true,
        })
      }

      case component instanceof components.StrokeWeightComponent: {
        return () => ({
          animationName: 'border_width',
          mapper: (keyframe) => {
            const value =
              keyframe.getComponentOrThrow(NumberValueComponent).value
            return `border-width: ${round(value, { fixed: 2 })}px;`
          },
          isSupported: true,
        })
      }

      case component instanceof components.RotationComponent: {
        return () => ({
          animationName: 'rotate',
          mapper: (keyframe) => {
            const value =
              keyframe.getComponentOrThrow(NumberValueComponent).value
            return `rotate: ${value}deg;`
          },
          isSupported: true,
        })
      }

      case component instanceof components.ShadowColorComponent:
      case component instanceof components.RgbaValueComponent: {
        return () => {
          return {
            animationName: 'TODO: IMPLEMENT',
            isSupported: false,
          }

          // const property = left.getProperty()
          // const propertyGroup = property.getPropertyGroup()
          // const propertyGroupSet = propertyGroup.getPropertyGroupSet()

          // if (PropertyGroupSetType.Stroke === propertyGroupSet.type) {
          //   return {
          //     animationName: 'border_color',
          //     mapper: (keyframe) => {
          //       const value = keyframe.getComponentOrThrow(RgbaValueComponent).value
          //       return `border-color: rgba(${value.r}, ${value.g}, ${value.b}, ${value.a});`
          //     },
          //     isSupported: true,
          //   }
          // }

          // if (PropertyGroupSetType.Fill === propertyGroupSet.type) {
          //   return {
          //     animationName: 'background_color',
          //     mapper: (keyframe) => {
          //       const value = keyframe.getComponentOrThrow(RgbaValueComponent).value
          //       return `background-color: rgba(${value.r}, ${value.g}, ${value.b}, ${value.a});`
          //     },
          //     isSupported: true,
          //   }
          // }

          // return {
          //   animationName: 'color',
          //   isSupported: false,
          // }
        }
      }

      // @TODO: should be implemented
      case component instanceof components.ProgressComponent:
      case component instanceof components.BlurRadiusComponent:
      case component instanceof components.BottomLeftCornerRadiusComponent:
      case component instanceof components.BottomRightCornerRadiusComponent:
      case component instanceof components.TopLeftCornerRadiusComponent:
      case component instanceof components.TopRightCornerRadiusComponent:
      case component instanceof components.DashComponent:
      case component instanceof components.DashOffsetComponent:
      case component instanceof components.GapComponent:
      case component instanceof components.InnerRadiusComponent:
      case component instanceof components.PointCountComponent:
      case component instanceof components.ShadowRadiusComponent:
      case component instanceof components.ShadowSpreadComponent:
      case component instanceof components.StrokeTopWeightComponent:
      case component instanceof components.StrokeBottomWeightComponent:
      case component instanceof components.StrokeRightWeightComponent:
      case component instanceof components.StrokeLeftWeightComponent:
      case component instanceof components.AnchorPointComponent:
      case component instanceof components.ShadowOffsetComponent:
      case component instanceof components.GradientTransformComponent:
      case component instanceof components.StartAngleComponent:
      case component instanceof components.EndAngleComponent:
      case component instanceof components.ImageTransformComponent: {
        return ([left]) => {
          const propertyName = left
            .getAspectOrThrow(TargetRelationAspect)
            .getComponentTagOrThrow()
          return {
            animationName: snakeCase(propertyName),
            isSupported: false,
            // mapper: (keyframe: GenericKeyframe) =>
            //   `/* ${kebabCase(
            //     propertyName
            //   )} is not supported for now. Please contact us if you need help with it: info@aninix.com */`,
            // mapper: (keyframe: GenericKeyframe) =>
            //   `${kebabCase(propertyName)}: ${round(keyframe.value, { fixed: 2 })};`,
          }
        }
      }

      // @NOTE: handle not targetable components
      case component instanceof components.BlendModeComponent:
      case component instanceof components.ChildrenExpandedComponent:
      case component instanceof components.ClipContentComponent:
      case component instanceof components.DurationComponent:
      case component instanceof components.EffectTypeComponent:
      case component instanceof components.EntityTypeComponent:
      case component instanceof components.EntryComponent:
      case component instanceof components.FpsComponent:
      case component instanceof components.HashComponent:
      case component instanceof components.IndividualCornerRadiusComponent:
      case component instanceof components.IndividualStrokeWeightComponent:
      case component instanceof components.LockedComponent:
      case component instanceof components.MaskComponent:
      case component instanceof components.MatrixTransformValueComponent:
      case component instanceof components.NameComponent:
      case component instanceof components.NumberValueComponent:
      case component instanceof components.NodeColorComponent:
      case component instanceof components.NodeTypeComponent:
      case component instanceof components.PaintTypeComponent:
      case component instanceof components.PathReversedComponent:
      case component instanceof components.Point2dValueComponent:
      case component instanceof components.PropertiesExpandedComponent:
      case component instanceof components.RenderScaleTypeComponent:
      case component instanceof components.RenderScaleComponent:
      case component instanceof components.RenderSuffixComponent:
      case component instanceof components.RenderTypeComponent:
      case component instanceof components.ScaleLockedComponent:
      case component instanceof components.ScaleTypeComponent:
      case component instanceof components.ScalingFactorComponent:
      case component instanceof components.SpatialPoint2dValueComponent:
      case component instanceof components.SizeBehaviourComponent:
      case component instanceof components.SizeLockedComponent:
      case component instanceof components.SmoothCornerRadiusComponent:
      case component instanceof components.SoloComponent:
      case component instanceof components.SpringCurveComponent:
      case component instanceof components.StartTimeComponent:
      case component instanceof components.StrokeAlignComponent:
      case component instanceof components.StrokeCapStartComponent:
      case component instanceof components.StrokeCapEndComponent:
      case component instanceof components.StrokeJoinComponent:
      case component instanceof components.TimeComponent:
      case component instanceof components.TimingCurveComponent:
      case component instanceof components.VectorPathsComponent:
      case component instanceof components.VisibleInViewportComponent:
      case component instanceof components.MainNodeIdComponent:
      case component instanceof components.PresetIdComponent:
      case component instanceof components.CoverTimeComponent:
      case component instanceof components.InitialNodeIdComponent:
      case component instanceof components.MetadataComponent:
      case component instanceof components.TextAlignHorizontalComponent:
      case component instanceof components.TextAlignVerticalComponent:
      case component instanceof components.TextAutoResizeComponent:
      case component instanceof components.TextCaseComponent:
      case component instanceof components.TextDecorationComponent:
      case component instanceof components.TextListOptionsComponent:
      case component instanceof components.TextTruncationComponent:
      case component instanceof components.AutoRenameComponent:
      case component instanceof components.FontStyleComponent:
      case component instanceof components.FontNameComponent:
      case component instanceof components.FontSizeComponent:
      case component instanceof components.FontWeightComponent:
      case component instanceof components.VersionComponent:
      case component instanceof components.CharactersComponent:
      case component instanceof components.StartIndexComponent:
      case component instanceof components.EndIndexComponent:
      case component instanceof components.ParagraphIndentComponent:
      case component instanceof components.ParagraphSpacingComponent:
      case component instanceof components.HangingListComponent:
      case component instanceof components.HangingPunctuationComponent:
      case component instanceof components.MaxLinesComponent:
      case component instanceof components.IndetationComponent:
      case component instanceof components.LineHeightUnitComponent:
      case component instanceof components.LineHeightValueComponent:
      case component instanceof components.LetterSpacingUnitComponent:
      case component instanceof components.LetterSpacingValueComponent:
      case component instanceof components.LeadingTrimComponent:
      case component instanceof components.ListSpacingComponent: {
        throw new Error(`${component} is not supported in such context`)
      }

      default: {
        const never: never = component
        throw new Error(`"${never}" should never be called`)
      }
    }
  }
}
