import { mixed, round } from '@aninix-inc/model/legacy'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import classnames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'

import { defaultFigmaLikeFormat } from '@aninix/app-design-system/utils/figma-like-number-format'
import { Icon as IconButton } from '../buttons'
import { stringToMath } from '../input-with-icon/string-to-math/string-to-math'
import * as styles from './index.scss'

export interface IProps {
  id: string
  value: number | typeof mixed
  /**
   * @description click on icon
   */
  onClick?: () => void
  onChange?: (value?: number) => void
  disabled?: boolean
  icon?: React.ReactNode
  format?: (value: number) => string
  width?: number
  threshold?: number
  /**
   * @description when true then empty input would trigger change with "undefined" in payload
   */
  nullable?: boolean
}

const noop = () => {}

export const Input: React.FCC<IProps> = ({
  id,
  value,
  onClick = noop,
  onChange = noop,
  disabled = false,
  icon,
  format = defaultFigmaLikeFormat,
  width = 72,
  threshold = 1,
  nullable = false,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const prevId = React.useRef(id)
  const [isFocused, setIsFocused] = React.useState(false)
  const [localValue, setLocalValue] = React.useState(
    value === mixed ? '' : value.toString()
  )

  const valueToApply = React.useRef<number>(0)
  const hasChanged = React.useRef<boolean>(false)

  const onChangeWrapper = React.useCallback(
    (providedValue: number) => {
      // @NOTE: we have empty string here when value is mixed or not valid
      if (localValue === '') {
        if (nullable === true) {
          onChange(undefined)
        }

        return
      }

      // @NOTE: do nothing if values equal
      if (R.equals(value, providedValue)) {
        return
      }

      onChange(providedValue)
    },
    [value, localValue, onChange]
  )

  React.useEffect(() => {
    const withMath = stringToMath(localValue)
    valueToApply.current = withMath
  }, [localValue])

  React.useEffect(() => {
    const input = inputRef.current?.querySelector('input')
    if (isFocused) {
      input?.select()
    }
  }, [isFocused])

  React.useEffect(() => {
    const isIdChanged = id !== prevId.current

    if (isIdChanged) {
      setLocalValue(value === mixed ? '' : value.toString())
      prevId.current = id
      hasChanged.current = false
    }

    if (isFocused) {
      return
    }

    setLocalValue(value === mixed ? '' : value.toString())
  }, [isFocused, value, id])

  const handleKeyPress = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const isKeyDown = e.key.toLowerCase() === 'arrowdown'
      const isKeyUp = e.key.toLowerCase() === 'arrowup'
      const isShiftPressed = e.shiftKey
      const isCtrlPressed = e.ctrlKey || e.metaKey
      const isEsc = e.key.toLowerCase() === 'escape'
      const isEnter = e.key.toLocaleLowerCase() === 'enter'
      const input = inputRef.current?.querySelector('input')

      if (isKeyDown || isKeyUp) {
        e.preventDefault()
        e.stopPropagation()

        const thresholdToApply = (() => {
          if (isShiftPressed) {
            return 10 * threshold
          }

          if (isCtrlPressed) {
            return 0.1 * threshold
          }

          return threshold
        })()

        if (value !== mixed) {
          if (isKeyDown) {
            const newValue = round(valueToApply.current - thresholdToApply, {
              fixed: 4,
            })
            setLocalValue(`${newValue}`)
            onChangeWrapper(newValue)
            return
          }

          const newValue = round(valueToApply.current + thresholdToApply, {
            fixed: 4,
          })
          setLocalValue(`${newValue}`)
          onChangeWrapper(newValue)
        }

        return
      }

      // @TODO: implement cancel when esc pressed
      if (isEnter || isEsc) {
        input?.blur()
        return
      }
    },
    [localValue]
  )

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const valueFromInput = e.target.value
      const formattedValue = valueFromInput.replace(/ /g, '')
      setLocalValue(formattedValue)
    },
    []
  )

  const formattedValue = React.useMemo(() => {
    if (value === mixed) {
      return mixed
    }

    if (format != null && typeof value === 'number') {
      return format(value)
    }

    return value
  }, [value, format])

  const handleFocus = React.useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = React.useCallback(() => {
    onChangeWrapper(valueToApply.current)
    hasChanged.current = true
    setIsFocused(false)
  }, [onChangeWrapper])

  return (
    <OutlinedInput
      ref={inputRef}
      classes={{
        root: classnames(styles['input-root'], {
          [styles.focused]: isFocused,
        }),
        notchedOutline: styles['outline-notched'],
        input: classnames(styles.input, {
          [styles['input--mixed']]:
            isFocused === false && formattedValue === mixed,
          [styles['input--no-icon']]: icon == null,
        }),
        focused: styles.focused,
        disabled: styles.disabled,
      }}
      style={{ width }}
      id={id}
      type="text"
      value={isFocused ? localValue : formattedValue}
      onKeyDown={handleKeyPress}
      onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      startAdornment={
        icon != null && (
          <IconButton onClick={onClick} className={styles.button}>
            {icon}
          </IconButton>
        )
      }
      disabled={disabled}
    />
  )
}
