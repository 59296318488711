import {
  DashComponent,
  DashOffsetComponent,
  Entity,
  GapComponent,
  NumberKeyframe,
  disableDashes,
  enableDashes,
  getValueNumber,
  isDashesEnabled,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { Group } from '../../common/group'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const btnSize = {
  width: 32,
  height: 32,
}

export interface IProps {
  nodes: Entity[]
}
export const Dashes: React.FCC<IProps> = ({ nodes }) => {
  useEntities(nodes)

  if (!nodes.length) return null

  const isEnabled = R.any(isDashesEnabled, nodes)
  const enable = () =>
    nodes.forEach((l) => {
      if (isDashesEnabled(l) === false) {
        enableDashes(l)
      }
    })
  const disable = () =>
    nodes.forEach((l) => {
      if (isDashesEnabled(l)) {
        disableDashes(l)
      }
    })

  return (
    <Group
      title="Dashes"
      isOpen={isEnabled}
      onClick={enable}
      headerButtons={
        isEnabled ? (
          <buttons.Icon
            btnSize={btnSize}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              disable()
            }}
            tooltip="Disable dashes"
          >
            <icons.Remove />
          </buttons.Icon>
        ) : (
          <buttons.Icon
            btnSize={btnSize}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              enable()
            }}
            tooltip="Enable dashes"
          >
            <icons.Add />
          </buttons.Icon>
        )
      }
    >
      <Dash />
      <Gap />
      <Offset />
    </Group>
  )
}

Dashes.displayName = 'Dashes'

const Dash: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time, snapshots } = useNodePropertiesPanel()
  const components = nodes.flatMap((n) => n.getComponent(DashComponent) ?? [])
  const dashes = snapshots.map((s) => s.dash)
  const keyframesType = getKeyframesType(components, time)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <DashEditable components={components} time={time} />
      ) : (
        <DashDisplay
          components={components}
          time={time}
          dashes={dashes}
          keyframesType={keyframesType}
        />
      )}
    </div>
  )
}

Dash.displayName = 'Dash'

const DashEditable: React.FC<{
  components: DashComponent[]
  time: number
}> = ({ components, time }) => (
  <div className="flex w-full flex-row justify-between">
    <NumberValue
      components={components}
      icon={<span className="w-full pl-[4px]">Dash</span>}
      time={time}
      min={0}
      iconWide
      iconWidth={96}
      width={192}
    />

    <KeyframesPropertyControl
      components={components}
      time={time}
      KeyframeConstructor={NumberKeyframe}
      valueGetter={getValueNumber}
    />
  </div>
)

DashEditable.displayName = 'DashEditable'

const DashDisplay: React.FC<{
  components: DashComponent[]
  time: number
  dashes: number[]
  keyframesType: PropertyKeyframesType
}> = React.memo(
  ({ components, time }) => (
    <div className="flex w-full flex-row justify-between">
      <NumberValue
        components={components}
        icon={<span className="w-full pl-[4px]">Dash</span>}
        time={time}
        min={0}
        iconWide
        iconWidth={96}
        width={192}
      />

      <KeyframesPropertyControl
        components={components}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  ),
  (prev, next) => {
    if (prev.dashes.length !== next.dashes.length) return false
    for (let i = 0; i < prev.dashes.length; i++) {
      if (prev.dashes[i] !== next.dashes[i]) return false
    }
    if (prev.keyframesType !== next.keyframesType) return false
    return true
  }
)

DashDisplay.displayName = 'DashDisplay'

const Gap: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time, snapshots } = useNodePropertiesPanel()
  const components = nodes.flatMap((n) => n.getComponent(GapComponent) ?? [])
  const keyframesType = getKeyframesType(components, time)
  const gaps = snapshots.map((s) => s.gap)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <GapEditable components={components} time={time} />
      ) : (
        <GapDisplay
          components={components}
          time={time}
          gaps={gaps}
          keyframesType={keyframesType}
        />
      )}
    </div>
  )
}

Gap.displayName = 'Gap'

const GapEditable: React.FC<{
  components: GapComponent[]
  time: number
}> = ({ components, time }) => (
  <div className="flex w-full flex-row justify-between">
    <NumberValue
      components={components}
      icon={<span className="w-full pl-[4px]">Gap</span>}
      time={time}
      min={0}
      iconWide
      iconWidth={96}
      width={192}
    />

    <KeyframesPropertyControl
      components={components}
      time={time}
      KeyframeConstructor={NumberKeyframe}
      valueGetter={getValueNumber}
    />
  </div>
)

GapEditable.displayName = 'GapEditable'

const GapDisplay: React.FC<{
  components: GapComponent[]
  time: number
  gaps: number[]
  keyframesType: PropertyKeyframesType
}> = React.memo(
  ({ components, time }) => (
    <div className="flex w-full flex-row justify-between">
      <NumberValue
        components={components}
        icon={<span className="w-full pl-[4px]">Gap</span>}
        time={time}
        min={0}
        iconWide
        iconWidth={96}
        width={192}
      />

      <KeyframesPropertyControl
        components={components}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  ),
  (prev, next) => {
    if (prev.gaps.length !== next.gaps.length) return false
    for (let i = 0; i < prev.gaps.length; i++) {
      if (prev.gaps[i] !== next.gaps[i]) return false
    }
    if (prev.keyframesType !== next.keyframesType) return false
    return true
  }
)

GapDisplay.displayName = 'GapDisplay'

const Offset: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time, snapshots } = useNodePropertiesPanel()
  const components = nodes.flatMap(
    (n) => n.getComponent(DashOffsetComponent) ?? []
  )
  const offsets = snapshots.map((s) => s.dashOffset)
  const keyframesType = getKeyframesType(components, time)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <OffsetEditable components={components} time={time} />
      ) : (
        <OffsetDisplay
          components={components}
          time={time}
          offsets={offsets}
          keyframesType={keyframesType}
        />
      )}
    </div>
  )
}

Offset.displayName = 'Offset'

const OffsetEditable: React.FC<{
  components: DashOffsetComponent[]
  time: number
}> = ({ components, time }) => (
  <div className="flex w-full flex-row justify-between">
    <NumberValue
      components={components}
      icon={<span className="w-full pl-[4px]">Offset</span>}
      time={time}
      min={0}
      iconWide
      iconWidth={96}
      width={192}
    />

    <KeyframesPropertyControl
      components={components}
      time={time}
      KeyframeConstructor={NumberKeyframe}
      valueGetter={getValueNumber}
    />
  </div>
)

OffsetEditable.displayName = 'OffsetEditable'

const OffsetDisplay: React.FC<{
  components: DashOffsetComponent[]
  time: number
  offsets: number[]
  keyframesType: PropertyKeyframesType
}> = React.memo(
  ({ components, time }) => (
    <div className="flex w-full flex-row justify-between">
      <NumberValue
        components={components}
        icon={<span className="w-full pl-[4px]">Offset</span>}
        time={time}
        min={0}
        iconWide
        iconWidth={96}
        width={192}
      />

      <KeyframesPropertyControl
        components={components}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  ),
  (prev, next) => {
    if (prev.offsets.length !== next.offsets.length) return false
    for (let i = 0; i < prev.offsets.length; i++) {
      if (prev.offsets[i] !== next.offsets[i]) return false
    }
    if (prev.keyframesType !== next.keyframesType) return false
    return true
  }
)

OffsetDisplay.displayName = 'OffsetDisplay'
