import { setTeamAvatar } from '@aninix/api'
import * as React from 'react'

import { useCurrentStepContext } from '../../modules/new-team/use-current-step'
import { toast } from '../../modules/toasts'
import { Button } from '../design-system/button'
import { Icons } from '../icons'

export interface IProps {
  handleNextStep: () => void
}
export const AddAvatar: React.FCC<IProps> = ({ handleNextStep }) => {
  const [inputAvatar, setInputAvatar] = React.useState<File | null>(null)
  const [inputAvatarUrl, setInputAvatarUrl] = React.useState<string | null>(
    null
  )

  const { teamId } = useCurrentStepContext()

  const handleSave = React.useCallback(() => {
    if (inputAvatar)
      setTeamAvatar({
        teamId: teamId!,
        avatar: inputAvatar,
      })
        .then((value) => {
          toast(`New avatar is added!`, { variant: 'success' })
          handleNextStep()
        })
        .catch((error) => {
          toast(`Couldn't set new team avatar`, { variant: 'error' })
        })
  }, [inputAvatar, teamId])

  const handleSkip = handleNextStep

  React.useEffect(() => {
    if (FileReader && inputAvatar) {
      var fr = new FileReader()
      fr.onloadend = function () {
        setInputAvatarUrl(fr.result as string)
      }
      fr.readAsDataURL(inputAvatar)
    }
  }, [inputAvatar])
  return (
    <div className="flex w-96 flex-col items-center gap-6 rounded-3xl border-[1px] border-gray-200 p-6">
      <div className="flex flex-col items-center gap-6">
        <div className="flex h-[120px] w-[120px] items-center  overflow-clip rounded-full">
          <img
            crossOrigin="anonymous"
            className="object-fit"
            src={inputAvatarUrl || '/images/defaultTeamAvatar.png'}
          />
        </div>

        <label className="flex h-10 flex-row items-center gap-2 rounded-lg border-[1px] border-gray-200 px-3 py-2">
          <input
            className="hidden"
            type={'file'}
            accept={'.png,.jpg,.webp'}
            onChange={(e) => {
              setInputAvatar(e.target.files?.[0] || null)
            }}
          />
          <Icons.CloudUpload />
          <p className="font-body text-sm font-medium text-gray-500">
            Upload image
          </p>
        </label>

        <Button
          onClick={inputAvatar ? handleSave : handleSkip}
          label={inputAvatar ? 'Save and proceed' : 'Skip for now'}
        />
      </div>
    </div>
  )
}

AddAvatar.displayName = 'AddAvatar'
