import { useMutation } from '@tanstack/react-query'

import { ApiPostParams, ApiPostRequestBody } from './helpers'
import { httpClient } from './http-client'

export function useInviteUserToTeam() {
  return useMutation({
    async mutationFn(
      payload: ApiPostParams<'/teams/{teamId}/members'>['path'] &
        ApiPostRequestBody<'/teams/{teamId}/members'>
    ) {
      return httpClient.post(`/teams/${payload.teamId}/members`, payload)
    },
  })
}
