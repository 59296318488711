import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Left: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 15L3 3.91465C2.4174 3.70873 2 3.15311 2 2.5C2 1.67157 2.67157 1 3.5 1C4.32843 1 5 1.67157 5 2.5C5 3.15311 4.5826 3.70873 4 3.91465L4 15L3 15ZM8.5 5C7.11929 5 6 6.11929 6 7.5L6 11.5C6 12.8807 7.11929 14 8.5 14L13 14L13 13L8.5 13C7.67157 13 7 12.3284 7 11.5L7 7.5C7 6.67157 7.67157 6 8.5 6L13 6L13 5L8.5 5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
