import { setHttpClientToken, useGetSpaceBadge } from '@aninix/api'
import classNames from 'classnames'
import * as React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Typography } from '../../../components/design-system/typography'
import { useSessionStore } from '../../../stores'

import { TeamItem } from './team-item'
import { UserItem } from './user-item'

interface IProps {
  activeAccount: string
  tokens: string[]
  isExpanded: boolean
  onClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

export const ExpandedSpaceBadge: React.FCC<IProps> = ({
  activeAccount,
  tokens,
  isExpanded,
  onClose,
}) => {
  const getSpaceBadge = useGetSpaceBadge({ tokens })

  const users = getSpaceBadge.data?.infoFromTokens.users
  const sessionStore = useSessionStore()

  const navigate = useNavigate()

  const { teamId } = useParams()

  return (
    <div
      onMouseLeave={onClose}
      className={classNames(
        ' absolute left-3 right-3 top-3 z-20 flex max-h-[95vh] w-72 flex-col gap-1 overflow-auto rounded-xl bg-white py-2 shadow-xl transition-all duration-200',
        {
          ['invisible opacity-0']: !isExpanded,
        }
      )}
    >
      {users?.map((user, i) => {
        const { id, name, avatarUrl, plan, teams, email } = user

        return (
          <React.Fragment key={id}>
            <div className="flex flex-col py-1 ">
              <div className="line-clamp-1 px-4 py-2">
                <Typography
                  style="Subscript"
                  text={email ?? ''}
                  className="text-gray-400"
                />
              </div>
              <div
                className="px-4 py-2 transition-all duration-150 hover:bg-gray-100"
                // @TODO: refactor: move logic to another place and make button instead of div
                /**
                 * Changing current active accout.
                 * 1. Set new id as active-account;
                 * 2. Reload page.
                 *
                 * Also replace localStorageIo with hook for currentUser and setCurrentUser for seamless experience on account change
                 * * Remove reload later
                 */
                onClick={() => {
                  if (user.id === activeAccount && !teamId) {
                    return
                  }

                  setHttpClientToken(sessionStore.tokens[user.id])

                  sessionStore.updateUserId(user.id).then(() => {
                    navigate(`../my/projects`)
                  })

                  if (user.id === activeAccount) {
                    return
                  }
                  // window.location.reload()
                }}
              >
                <UserItem
                  key={id}
                  id={id}
                  name={name}
                  avatarUrl={avatarUrl}
                  plan={plan}
                  selected={user.id === activeAccount && !teamId}
                  isPro={user.plan === 'pro'}
                />
              </div>
              {teams.map((team) => {
                const { id, avatarUrl, name, aninixRole } = team
                return (
                  <div
                    key={id}
                    className="px-4 py-2 transition-all duration-150 hover:bg-gray-100"
                    // @TODO: refactor: move logic to another place and make button instead of div
                    /**
                     * Changing current active accout.
                     * 1. Set new id as active-account;
                     * 2. Reload page.
                     *
                     * Also replace localStorageIo with hook for currentUser and setCurrentUser for seamless experience on account change
                     * Remove reload later
                     */
                    onClick={() => {
                      if (teamId === id && user.id === activeAccount) {
                        return
                      }

                      setHttpClientToken(sessionStore.tokens[user.id])

                      sessionStore.updateUserId(user.id).then(() => {
                        navigate(`../teams/${id}/projects`)
                      })

                      if (user.id === activeAccount) {
                        return
                      }
                      // window.location.reload()
                    }}
                  >
                    <TeamItem
                      name={name}
                      avatarUrl={avatarUrl}
                      aninixRole={aninixRole}
                      selected={teamId === id && user.id === activeAccount}
                      isPro={team.plan === 'pro'}
                    />
                  </div>
                )
              })}
            </div>
            <SpaceBadgeMenuItem
              label="Create a new team"
              to="/teams/new?utm_source=profile-menu"
              Icon={
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 6C7 6.79565 7.31607 7.55871 7.87868 8.12132C8.44129 8.68393 9.20435 9 10 9C10.7956 9 11.5587 8.68393 12.1213 8.12132C12.6839 7.55871 13 6.79565 13 6C13 5.20435 12.6839 4.44129 12.1213 3.87868C11.5587 3.31607 10.7956 3 10 3C9.20435 3 8.44129 3.31607 7.87868 3.87868C7.31607 4.44129 7 5.20435 7 6Z"
                    stroke="#89909A"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M11.5 14C11.5 14.7956 11.8161 15.5587 12.3787 16.1213C12.9413 16.6839 13.7044 17 14.5 17C15.2956 17 16.0587 16.6839 16.6213 16.1213C17.1839 15.5587 17.5 14.7956 17.5 14C17.5 13.2044 17.1839 12.4413 16.6213 11.8787C16.0587 11.3161 15.2956 11 14.5 11C13.7044 11 12.9413 11.3161 12.3787 11.8787C11.8161 12.4413 11.5 13.2044 11.5 14Z"
                    stroke="#89909A"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2.5 14C2.5 14.394 2.5776 14.7841 2.72836 15.1481C2.87913 15.512 3.1001 15.8427 3.37868 16.1213C3.65726 16.3999 3.98797 16.6209 4.35195 16.7716C4.71593 16.9224 5.10603 17 5.5 17C5.89397 17 6.28407 16.9224 6.64805 16.7716C7.01203 16.6209 7.34274 16.3999 7.62132 16.1213C7.8999 15.8427 8.12087 15.512 8.27164 15.1481C8.4224 14.7841 8.5 14.394 8.5 14C8.5 13.606 8.4224 13.2159 8.27164 12.8519C8.12087 12.488 7.8999 12.1573 7.62132 11.8787C7.34274 11.6001 7.01203 11.3791 6.64805 11.2284C6.28407 11.0776 5.89397 11 5.5 11C5.10603 11 4.71593 11.0776 4.35195 11.2284C3.98797 11.3791 3.65726 11.6001 3.37868 11.8787C3.1001 12.1573 2.87913 12.488 2.72836 12.8519C2.5776 13.2159 2.5 13.606 2.5 14Z"
                    stroke="#89909A"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
            />
            <div className="min-h-[1px] w-full bg-gray-200" />
          </React.Fragment>
        )
      })}

      <div className="flex flex-col">
        <SpaceBadgeMenuItem
          label="Add another account"
          to="/login/new"
          Icon={
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C7.65192 17.1453 8.19547 17.3014 8.75 17.3951"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M15 12V18"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 15L12 15"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7.5 8.3335C7.5 8.99654 7.76339 9.63242 8.23223 10.1013C8.70107 10.5701 9.33696 10.8335 10 10.8335C10.663 10.8335 11.2989 10.5701 11.7678 10.1013C12.2366 9.63242 12.5 8.99654 12.5 8.3335C12.5 7.67045 12.2366 7.03457 11.7678 6.56573C11.2989 6.09689 10.663 5.8335 10 5.8335C9.33696 5.8335 8.70107 6.09689 8.23223 6.56573C7.76339 7.03457 7.5 7.67045 7.5 8.3335Z"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.14001 15.7077C5.34627 15.0212 5.76832 14.4195 6.34355 13.9918C6.91878 13.5641 7.61655 13.3333 8.33335 13.3335L8.75004 13.3335"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />

        <SpaceBadgeMenuItem
          label="Log out from all accounts"
          to="/logout"
          Icon={
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.875 6.25C12.9105 6.25 13.75 5.41053 13.75 4.375C13.75 3.33947 12.9105 2.5 11.875 2.5C10.8395 2.5 10 3.33947 10 4.375C10 5.41053 10.8395 6.25 11.875 6.25Z"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.375 7.93356C4.375 7.93356 6.875 5.62489 10.625 8.55856C14.5679 11.6431 16.875 10.4336 16.875 10.4336"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.5528 8.50293C10.2135 10.0971 7.93425 16.1686 2.5 15.6252"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.64404 12.5908C10.0366 12.8884 13.7499 14.0627 13.7499 18.1252"
                stroke="#89909A"
                strokeWidth="1.6"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
        />
      </div>
    </div>
  )
}

ExpandedSpaceBadge.displayName = 'ExpandedSpaceBadge'

export interface ISpaceBadgeMenuItem {
  Icon: React.ReactNode
  to: string
  label: string
}
const SpaceBadgeMenuItem: React.FCC<ISpaceBadgeMenuItem> = ({
  Icon,
  to,
  label,
}) => (
  <Link
    to={to}
    className="flex flex-row gap-3 px-6 py-2 transition-all duration-150 hover:bg-gray-100"
  >
    {Icon}
    <Typography style="Body5Regular" className="text-gray-500" text={label} />
  </Link>
)

SpaceBadgeMenuItem.displayName = 'SpaceBadgeMenuItem'
