import * as R from 'ramda'

import {
  Component,
  DashComponent,
  DashOffsetComponent,
  Entity,
  GapComponent,
  StrokeBottomWeightComponent,
  StrokeCap,
  StrokeCapEndComponent,
  StrokeCapStartComponent,
  StrokeJoin,
  StrokeJoinComponent,
  StrokeLeftWeightComponent,
  StrokeRightWeightComponent,
  StrokeTopWeightComponent,
  StrokeWeightComponent,
  StrokesRelationsAspect,
  isDashesEnabled,
} from '@aninix-inc/model'
import {
  LottieColor,
  LottieDashType,
  LottieGradient,
  LottieLineCap,
  LottieLineJoin,
  LottieShapeGradientStroke,
  LottieShapeLayerType,
  LottieShapeStroke,
  LottieStaticProperty,
} from '../types'
import { Paint, shouldRenderPaint } from './paint'
import { Property } from './property'

function mapLineJoin(lineJoin: StrokeJoin): LottieLineJoin {
  switch (lineJoin) {
    case StrokeJoin.Bevel:
      return LottieLineJoin.Bevel
    case StrokeJoin.Miter:
      return LottieLineJoin.Miter
    case StrokeJoin.Round:
      return LottieLineJoin.Round
  }
}

// @TODO: ARROW HEADS add support of all lines for lottie here
function mapLineCap(
  startStrokeCap: StrokeCap,
  endStrokeCap: StrokeCap
): LottieLineCap {
  if (startStrokeCap !== endStrokeCap) {
    return LottieLineCap.Butt
  }

  switch (startStrokeCap) {
    case StrokeCap.None:
      return LottieLineCap.Butt
    case StrokeCap.Round:
      return LottieLineCap.Round
    case StrokeCap.Square:
      return LottieLineCap.Square
    default:
      return LottieLineCap.Butt
  }
}

function getStrokeWeightProperty(payload: {
  node: Entity
  type?: 'top' | 'right' | 'bottom' | 'left'
}): Component | undefined {
  const { node, type } = payload

  switch (type) {
    case 'top':
      return node.getComponent(StrokeTopWeightComponent)
    case 'right':
      return node.getComponent(StrokeRightWeightComponent)
    case 'bottom':
      return node.getComponent(StrokeBottomWeightComponent)
    case 'left':
      return node.getComponent(StrokeLeftWeightComponent)
    default:
      return node.getComponent(StrokeWeightComponent)
  }
}

function hasDashes(node: Entity): boolean {
  if (!isDashesEnabled(node)) {
    return false
  }

  const dashComponent = node.getComponentOrThrow(DashComponent)
  const gapComponent = node.getComponentOrThrow(GapComponent)

  if (dashComponent.value === 0 && gapComponent.value === 0) {
    return false
  }

  return true
}

/**
 * @description extract lottie shape stroke info from node
 * @param payload.multiplyBy required when we need to create inner/outer stroke which should be multiplied by 2
 */
export function Strokes(payload: {
  node: Entity
  multiplyBy?: number
  type?: 'top' | 'right' | 'bottom' | 'left'
}): Array<LottieShapeStroke | LottieShapeGradientStroke> {
  const { node, multiplyBy = 1, type } = payload

  const strokeWeightProperty = getStrokeWeightProperty({ node, type })
  const strokeWeight = strokeWeightProperty
    ? Property({
        property: strokeWeightProperty,
        mapper: ({ value, isAnimated }) =>
          isAnimated ? [value * multiplyBy] : value * multiplyBy,
      })
    : ({
        a: 0,
        k: 0,
      } as LottieStaticProperty<number>)

  return (
    R.defaultTo([], node.getAspect(StrokesRelationsAspect)?.getChildrenList())
      .filter(shouldRenderPaint)
      .map((paint) => Paint({ node, paint }))
      .filter(
        (lottieColorOrGradient: ReturnType<typeof Paint>) =>
          lottieColorOrGradient != null
      )
      .map((lottieColorOrGradient: ReturnType<typeof Paint>) => {
        const result = (() => {
          // @NOTE: checks if provided value is gradient.
          // @TODO: add proper type checking.
          // @ts-ignore
          if (lottieColorOrGradient.t != null) {
            const gradient = lottieColorOrGradient as LottieGradient
            return {
              ty: LottieShapeLayerType.GradientStroke,
              o: gradient.o,
              s: gradient.s,
              e: gradient.e,
              t: gradient.t,
              g: gradient.g,
              w: strokeWeight,
              ml: 0,
              lc: mapLineCap(
                node.getComponentOrThrow(StrokeCapStartComponent).value,
                node.getComponentOrThrow(StrokeCapEndComponent).value
              ),
              lj: mapLineJoin(
                node.getComponentOrThrow(StrokeJoinComponent).value
              ),
              bm: 0,
              nm: 'Stroke',
              hd: false,
            } satisfies LottieShapeGradientStroke
          }

          const color = lottieColorOrGradient as LottieColor
          return {
            ty: LottieShapeLayerType.Stroke,
            c: color.c,
            o: color.o,
            w: strokeWeight,
            lc: mapLineCap(
              node.getComponentOrThrow(StrokeCapStartComponent).value,
              node.getComponentOrThrow(StrokeCapEndComponent).value
            ),
            lj: mapLineJoin(
              node.getComponentOrThrow(StrokeJoinComponent).value
            ),
            ml: 4,
            bm: 0,
            nm: 'Stroke',
            hd: false,
          } satisfies LottieShapeStroke
        })()

        const finalResult: LottieShapeStroke | LottieShapeGradientStroke =
          hasDashes(node)
            ? {
                ...result,
                d: [
                  {
                    n: LottieDashType.Offset,
                    nm: 'Offset',
                    v: Property({
                      property: node.getComponentOrThrow(DashOffsetComponent),
                      mapper: ({ value, isAnimated }) => {
                        if (isAnimated) {
                          return [value]
                        }

                        return value
                      },
                    }),
                  },
                  {
                    n: LottieDashType.Dash,
                    nm: 'Dash',
                    v: Property({
                      property: node.getComponentOrThrow(DashComponent),
                      mapper: ({ value, isAnimated }) => {
                        if (isAnimated) {
                          return [value]
                        }

                        return value
                      },
                    }),
                  },
                  {
                    n: LottieDashType.Gap,
                    nm: 'Gap',
                    v: Property({
                      property: node.getComponentOrThrow(GapComponent),
                      mapper: ({ value, isAnimated }) => {
                        if (isAnimated) {
                          return [value]
                        }

                        return value
                      },
                    }),
                  },
                ],
              }
            : result

        return finalResult
      })
      // @NOTE: for some reason we have to reverse all paints to meet the same render as we have in Figma
      .reverse()
  )
}
