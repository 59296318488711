import { round } from '@aninix-inc/model'
import { getOffsetMutliplier } from './get-offset-mutliplier'

export function getValueAfterKeyPress({
  threshold,
  isShiftPressed,
  isCtrlPressed,
  value,
  isPositive,
}: {
  threshold: number
  isShiftPressed: boolean
  isCtrlPressed: boolean
  value: number
  isPositive: boolean
}) {
  const multiplier = getOffsetMutliplier({
    isShiftPressed,
    isCtrlPressed,
    isPositive,
  })

  const thresholdToApply = threshold * multiplier

  const newValue = value + thresholdToApply

  const rounded = round(newValue, { fixed: 4 })

  return rounded
}
