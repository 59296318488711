import { useQuery } from '@tanstack/react-query'

import { ApiGetParams, ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetCurrentFolder(
  payload: ApiGetParams<'/folders/{folderId}'>['path']
) {
  return useQuery({
    queryKey: ['get-current-folder', payload.folderId],
    async queryFn() {
      return httpClient.get<ApiGetResponse<'/folders/{folderId}'>>(
        `/folders/${payload.folderId}`
      )
    },
  })
}
