import * as React from 'react'

const defaultSize = { x: 13, y: 14 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const VerticalTopOutside: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00039 0L5.00039 8H3.00039L3.00039 0H5.00039ZM0 13.9998V10.5V10H0.5H12.5H13V10.5V13.9998H12V11H1V13.9998H0ZM10.0005 8V3H8.00049V8H10.0005Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
