// @ts-nocheck
import { Point2D, StrokeAlign, StrokeCap } from '@aninix-inc/model/legacy'
import { paper } from '@aninix-inc/renderer'
import { ImagesStore } from '@aninix/core/stores'
import * as PIXI from 'pixi.js'
import { NodeSnapshot } from '../../common/renderers/types'
import { getSize } from '../helpers/get-size'
import { gradientTexture } from '../helpers/gradient-texture'
import { makeDashedPath } from '../helpers/make-dashed-path'
import { Mappers } from '../mappers'
import { drawIndividualStrokes } from './draw-individual-strokes'
import { drawCaps, drawVectorPath } from './draw-vector-path'

export function drawStrokes(
  node: NodeSnapshot,
  _graphics: PIXI.Graphics,
  images: ImagesStore,
  app: PIXI.Application
) {
  node.strokes.forEach((stroke) => {
    if (!stroke.visible) return

    const { clockwise, closed, length } = paper.Path.create(
      node.data.map((e) => e.data).join('')
    )

    const { length: trimmedLength } = paper.Path.create(
      node.data.map((e) => e.data).join('')
    )

    const isDefault =
      [StrokeCap.None, StrokeCap.Round, StrokeCap.Square].includes(
        node.strokeCapStart
      ) && node.strokeCapStart === node.strokeCapEnd

    const isRoundedAndClosed =
      closed &&
      isDefault &&
      (node.strokeAlign === StrokeAlign.Outside ||
        node.strokeAlign === StrokeAlign.Inside)

    //if stroke is inside, we need to mask it later, so we will draw this one internally and later mask and append to graphics
    const graphics = new PIXI.Graphics()
    const graphicsContainer = new PIXI.Container()

    if (stroke.type === 'solid') {
      graphics.lineStyle(
        isRoundedAndClosed ? node.strokeWeight * 2 : node.strokeWeight,
        stroke.color,
        stroke.color.a,
        isRoundedAndClosed
          ? 0.5
          : Mappers.mapStrokeAlign(node.strokeAlign, clockwise, closed),
        false
      )
      graphics.line.cap = isDefault
        ? Mappers.mapCap(node.strokeCapStart)
        : PIXI.LINE_CAP.BUTT

      graphics.line.join =
        Mappers.mapStrokeJoin(node.strokeJoin) ?? PIXI.LINE_JOIN.MITER
    } else {
      graphics.lineStyle(
        isRoundedAndClosed ? node.strokeWeight * 2 : node.strokeWeight,
        'black',
        1,
        isRoundedAndClosed
          ? 0.5
          : Mappers.mapStrokeAlign(node.strokeAlign, clockwise, closed),
        false
      )
      graphics.line.cap = isDefault
        ? Mappers.mapCap(node.strokeCapStart)
        : PIXI.LINE_CAP.BUTT

      graphics.line.join =
        Mappers.mapStrokeJoin(node.strokeJoin) ?? PIXI.LINE_JOIN.MITER
    }

    graphics.line.miterLimit = 4

    if (node.individualStrokesEnabled && node.individualStrokesData) {
      //@TODO: move individual strokes to model just as separate strokes
      drawIndividualStrokes(node, graphics, stroke)
    } else {
      node.data.forEach((td) => {
        const pathsToDraw = makeDashedPath(
          td.data,
          node.dash,
          node.gap,
          node.dashOffset
        )

        pathsToDraw.forEach((path) =>
          drawVectorPath(node, graphics, path, false)
        )

        if (
          closed &&
          trimmedLength === length &&
          (node.dash === 0 || node.gap === 0)
        )
          graphics.closePath()

        const bigCaps = [
          StrokeCap.ArrowEquilateral,
          StrokeCap.ArrowLines,
          StrokeCap.CircleFilled,
          StrokeCap.DiamondFilled,
          StrokeCap.TriangleFilled,
        ]
        const hasBigArrows =
          bigCaps.includes(node.strokeCapStart) ||
          bigCaps.includes(node.strokeCapEnd)

        if (
          hasBigArrows ||
          (!hasBigArrows && (node.dash === 0 || node.gap === 0))
        )
          drawCaps(node, graphics, graphics, td.data, clockwise, closed)
      })
    }

    // masking inside strokes
    if (isRoundedAndClosed) {
      // if (false) {
      const strokeWeight = Math.max(
        node.strokeWeight,
        node.strokeBottomWeight,
        node.strokeTopWeight,
        node.strokeLeftWeight,
        node.strokeRightWeight
      )

      const maskName = `${node.data.map((e) => e.data).join('')}_${
        node.strokeAlign
      }_${strokeWeight}_${JSON.stringify(node.size)}_${node.strokeLeftWeight}_${
        node.strokeTopWeight
      }_${node.strokeRightWeight}_${node.strokeBottomWeight}_mask`
      const findMaskTexture = PIXI.Cache.get(maskName) as
        | PIXI.Texture
        | undefined

      let maskTexture: PIXI.Texture

      if (findMaskTexture === undefined) {
        const mask = new PIXI.Graphics()
        if (node.strokeAlign === StrokeAlign.Inside) {
          mask.beginFill('black')
          mask.drawRect(
            -strokeWeight,
            -strokeWeight,
            node.size.x + 2 * strokeWeight,
            node.size.y + 2 * strokeWeight
          )
          mask.endFill()

          mask.beginFill('white')
        }
        if (node.strokeAlign === StrokeAlign.Outside) {
          mask.beginFill('white')
          mask.drawRect(
            -strokeWeight,
            -strokeWeight,
            node.size.x + 2 * strokeWeight,
            node.size.y + 2 * strokeWeight
          )
          mask.endFill()

          mask.beginFill('black')
        }
        drawVectorPath(node, mask, node.data.map((e) => e.data).join(''))
        mask.endFill()

        maskTexture = app.renderer.generateTexture(mask, { resolution: 9 })
        PIXI.Cache.set(maskName, maskTexture)
      } else {
        maskTexture = findMaskTexture
      }
      const maskSprite = new PIXI.Sprite(maskTexture)
      graphicsContainer.addChild(maskSprite)
      graphicsContainer.mask = maskSprite
      maskSprite.position = { x: -strokeWeight, y: -strokeWeight }

      graphicsContainer.addChild(graphics)
    }

    if (stroke.type === 'solid') {
      graphicsContainer.addChild(graphics)
    }

    if (
      stroke.type === 'linear-gradient' ||
      stroke.type === 'radial-gradient'
    ) {
      const bigCaps = [
        StrokeCap.ArrowEquilateral,
        StrokeCap.ArrowLines,
        StrokeCap.CircleFilled,
        StrokeCap.DiamondFilled,
        StrokeCap.TriangleFilled,
      ]
      const hasArrowOffset =
        bigCaps.includes(node.strokeCapStart) ||
        bigCaps.includes(node.strokeCapEnd)

      const arrowFactor = hasArrowOffset ? 5.33 : 0.5

      const offset = node.strokeWeight * arrowFactor + 1
      const size = {
        x: node.size.x + offset * 2,
        y: node.size.y + offset * 2,
      }

      const overlayTexture = gradientTexture(stroke, size)

      const overlaySprite = new PIXI.Sprite(overlayTexture)
      overlaySprite.position = { x: -offset, y: -offset }

      graphics.position = { x: offset, y: offset }

      overlaySprite.addChild(graphics)
      overlaySprite.mask = graphics

      graphicsContainer.addChild(overlaySprite)
    }

    if (stroke.type === 'image') {
      const matrix = new PIXI.Matrix()

      const image: HTMLImageElement = images.images[stroke.hash]?.source
      if (image === undefined) return

      let imagePosition: Point2D = { x: 0, y: 0 }
      let imageSize: Point2D = { x: 0, y: 0 }

      const [a, b, tx, c, d, ty] = stroke.imageTransform.flat()

      const originalSize = images.images[stroke.hash].size

      const bigCaps = [
        StrokeCap.ArrowEquilateral,
        StrokeCap.ArrowLines,
        StrokeCap.CircleFilled,
        StrokeCap.DiamondFilled,
        StrokeCap.TriangleFilled,
      ]
      const hasArrowOffset =
        bigCaps.includes(node.strokeCapStart) ||
        bigCaps.includes(node.strokeCapEnd)

      const arrowFactor = hasArrowOffset ? 5.33 : 1
      const offset = node.strokeWeight * arrowFactor

      const findTexture = PIXI.Cache.get(
        `${stroke.hash}_${stroke.id}_${JSON.stringify(node.size)}`
      ) as PIXI.Texture | undefined

      let texture: PIXI.Texture

      if (findTexture === undefined) {
        const canvas = document.createElement('canvas')
        canvas.width = (node.size.x + offset * 2) * 3
        canvas.height = (node.size.y + offset * 2) * 3
        const ctx = canvas.getContext('2d')!

        switch (stroke.scaleMode) {
          case 'FILL': {
            const newSize = getSize({
              target: {
                x: node.size.x + offset * 2,
                y: node.size.y + offset * 2,
              },
              actual: originalSize,
              mode: 'fill',
            })

            imageSize = { x: newSize.x * 3, y: newSize.y * 3 }
            imagePosition = {
              x: ((node.size.x + offset * 2) * 3 - imageSize.x) / 2,
              y: ((node.size.y + offset * 2) * 3 - imageSize.y) / 2,
            }

            break
          }
          case 'FIT': {
            const newSize = getSize({
              target: {
                x: node.size.x + offset * 2,
                y: node.size.y + offset * 2,
              },
              actual: originalSize,
              mode: 'fit',
            })

            imageSize = { x: newSize.x * 3, y: newSize.y * 3 }
            imagePosition = {
              x: ((node.size.x + offset * 2) * 3 - imageSize.x) / 2,
              y: ((node.size.y + offset * 2) * 3 - imageSize.y) / 2,
            }

            break
          }
          case 'CROP': {
            const newSize = getSize({
              target: {
                x: node.size.x + offset * 2,
                y: node.size.y + offset * 2,
              },
              actual: originalSize,
              mode: 'fit',
            })

            imageSize = {
              x: newSize.x * 3,
              y: newSize.y * 3,
            }
            imagePosition = {
              x: ((node.size.x + offset * 2) * 3 - imageSize.x) / 2,
              y: ((node.size.y + offset * 2) * 3 - imageSize.y) / 2,
            }

            ctx.setTransform(a, b, c, d, tx, ty)

            break
          }
          case 'TILE': {
            const newSize = getSize({
              target: {
                x: node.size.x + offset * 2,
                y: node.size.y + offset * 2,
              },
              actual: originalSize,
              mode: 'fit',
            })

            imageSize = { x: newSize.x * 3, y: newSize.y * 3 }
            imagePosition = { x: 0, y: 0 }

            canvas.width = imageSize.x
            canvas.height = imageSize.y

            matrix.scale(stroke.scalingFactor / 2, stroke.scalingFactor / 2)

            break
          }
        }

        ctx.drawImage(
          image,
          imagePosition.x,
          imagePosition.y,
          imageSize.x,
          imageSize.y
        )

        texture = PIXI.Texture.from(canvas)
        PIXI.Cache.set(
          `${stroke.hash}_${stroke.id}_${JSON.stringify(node.size)}`,
          texture
        )
      } else {
        texture = findTexture
      }
      const overlaySprite = new PIXI.Graphics()
        .beginTextureFill({
          texture,
          alpha: stroke.opacity,
          matrix: matrix.scale(1 / 3, 1 / 3),
        })
        .drawRect(0, 0, node.size.x + offset * 2, node.size.y + offset * 2)
        .endFill()

      overlaySprite.position = { x: -offset, y: -offset }

      graphics.position = { x: offset, y: offset }

      overlaySprite.addChild(graphics)
      overlaySprite.mask = graphics

      graphicsContainer.addChild(overlaySprite)
    }

    _graphics.addChild(graphicsContainer)

    graphics.lineTextureStyle(undefined)
    graphics.lineStyle(0)
  })
}
