import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 24,
  y: 24,
}

export const ArrowsUpDown: React.FCC<IIcon> = ({
  size = defaultSize,
  className,
  color = '#CED3D9',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.80005 15L12 19.2L16.2 15"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2 9L12 4.8L7.79995 9"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
