import * as React from 'react'

const defaultSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Edit: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7.05001V0H3V7.05001C4.14112 7.28164 5 8.29052 5 9.5C5 10.7095 4.14112 11.7184 3 11.95V14H2V11.95C0.85888 11.7184 0 10.7095 0 9.5C0 8.29052 0.85888 7.28164 2 7.05001ZM4 9.5C4 10.3284 3.32843 11 2.5 11C1.67157 11 1 10.3284 1 9.5C1 8.67157 1.67157 8 2.5 8C3.32843 8 4 8.67157 4 9.5ZM9 14H10V6.94999C11.1411 6.71836 12 5.70948 12 4.5C12 3.29052 11.1411 2.28164 10 2.05001V0H9V2.05001C7.85888 2.28164 7 3.29052 7 4.5C7 5.70948 7.85888 6.71836 9 6.94999V14ZM11 4.5C11 3.67157 10.3284 3 9.5 3C8.67157 3 8 3.67157 8 4.5C8 5.32843 8.67157 6 9.5 6C10.3284 6 11 5.32843 11 4.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
