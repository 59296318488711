import {
  clone,
  commitUndo,
  Entity,
  moveNodes,
  ParentRelationAspect,
  Project,
} from '@aninix-inc/model'
import { IUiStore } from '../stores'

export const duplicateLayer =
  (node: Entity, project: Project, uiStore: IUiStore) => () => {
    const nodeParent = node.getAspect(ParentRelationAspect)?.getParentEntity()

    if (!nodeParent) {
      uiStore.closeContextMenu()
      return
    }

    const newNode = clone(node, project)

    moveNodes([newNode], nodeParent, {
      after: node,
    })
    commitUndo(project)
    uiStore.closeContextMenu()
  }
