import { RGBA } from '@aninix-inc/model/legacy'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import _ from 'lodash'
import * as styles from './index.scss'

export interface IProps {
  color: RGBA
  onChange: (color: RGBA) => void
}
export const InputRgba: React.FCC<IProps> = ({ color, onChange }) => {
  const [isFocused, setIsFocused] = React.useState(false)
  const [isFocusedAlpha, setIsFocusedAlpha] = React.useState(false)
  const [newColor, setNewColor] = useState({
    ...color,
    a: `${Math.round(color.a * 100)}%`,
  })

  const changeColor = React.useCallback(
    (key: keyof RGBA) => (event: React.ChangeEvent<HTMLInputElement>) => {
      let { value } = event.target
      if (key === 'a') {
        value = !value.includes('%') ? `${value}%` : value
      }
      setNewColor({ ...newColor, [key]: value })
    },
    [color]
  )

  useEffect(() => {
    setNewColor({
      ...color,
      a: `${Math.round(color.a * 100)}%`,
    })
  }, [color])

  const handleFocus = React.useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleBlur = React.useCallback(() => {
    setIsFocused(false)

    const value = (newColor.a.match(/\d+/gim) || []).join('')
    const colorToReturn: RGBA = {
      r: _.clamp(newColor.r, 0, 255),
      g: _.clamp(newColor.g, 0, 255),
      b: _.clamp(newColor.b, 0, 255),
      a: +(_.clamp(parseInt(value, 10), 0, 100) / 100).toFixed(2),
    }

    onChange(colorToReturn)
  }, [color, newColor])

  const handleFocusAlpha = () => {
    handleFocus()
    setIsFocusedAlpha(true)
  }

  const handleBlurAlpha = () => {
    handleBlur()
    setIsFocusedAlpha(false)
  }

  return (
    <div
      className={classNames(styles['inputs-wrapper'], {
        [styles['inputs-wrapper--focused']]: isFocused,
      })}
    >
      <input
        className={styles.input}
        type="text"
        value={newColor.r}
        onChange={changeColor('r')}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <input
        className={styles.input}
        type="text"
        value={newColor.g}
        onChange={changeColor('g')}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <input
        className={styles.input}
        type="text"
        value={newColor.b}
        onChange={changeColor('b')}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <input
        className={classNames(styles.input, styles.percent)}
        type="text"
        value={isFocusedAlpha ? newColor.a.replace('%', '') : newColor.a}
        onChange={changeColor('a')}
        onFocus={handleFocusAlpha}
        onBlur={handleBlurAlpha}
      />
    </div>
  )
}
