import {
  EffectTypeComponent,
  EffectsRelationsAspect,
  Project,
  StrokeCapEndComponent,
  StrokeCapStartComponent,
} from '@aninix-inc/model'
import { EffectType, StrokeCap } from '@aninix-inc/model/legacy'
import lowerCase from 'lodash/lowerCase'
import startCase from 'lodash/startCase'
import * as R from 'ramda'

export function getListOfNotSupportedLottieFeatures(
  project: Project
): string[] {
  let notSupportedFeaturesArray: string[] = []

  project.entities.forEach((entity) => {
    if (entity.hasAspect(EffectsRelationsAspect)) {
      notSupportedFeaturesArray.push(
        ...entity
          .getAspectOrThrow(EffectsRelationsAspect)
          .getChildrenList()
          .filter((c) =>
            [EffectType.InnerShadow, EffectType.BackgroundBlur].includes(
              c.getComponentOrThrow(EffectTypeComponent).value
            )
          )
          .map((c) =>
            startCase(
              lowerCase(
                c
                  .getComponentOrThrow(EffectTypeComponent)
                  .value.replace('_', ' ')
              )
            )
          )
      )
    }

    if (
      entity.hasComponent(StrokeCapStartComponent) &&
      entity.hasComponent(StrokeCapEndComponent)
    ) {
      if (
        [StrokeCap.None, StrokeCap.Round, StrokeCap.Square].includes(
          entity.getComponentOrThrow(StrokeCapStartComponent).value
        ) === false
      ) {
        notSupportedFeaturesArray.push('Arrow heads')
      }

      if (
        [StrokeCap.None, StrokeCap.Round, StrokeCap.Square].includes(
          entity.getComponentOrThrow(StrokeCapEndComponent).value
        ) === false
      ) {
        notSupportedFeaturesArray.push('Arrow heads')
      }
    }
  })

  return R.uniq(notSupportedFeaturesArray)
}
