import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const InnerRadius: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.056 8L2 0.999385L2.72738 0L12.4295 7.50031L13.0769 8L12.4295 8.49969L2.72738 16L2 14.9994L11.056 8Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M7.54695 4.47501C5.62448 6.39747 5.62448 9.51501 7.54695 11.4375L6.67679 12.3076C4.2731 9.90394 4.2731 6.00855 6.67679 3.60486L7.54695 4.47501Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
