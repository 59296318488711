/**
 * @file default timing curve styles
 */
import { curveStyle, CurveStyle, CurveType } from '@aninix-inc/model/legacy'

export const defaultTimingCurvePresets: (CurveStyle | 'divider')[] = [
  curveStyle.create({
    id: 'linear',
    title: 'Linear',
    type: CurveType.Timing,
    value: {
      out: { x: 0, y: 0 },
      in: { x: 1, y: 1 },
    },
  }),

  'divider',

  curveStyle.create({
    id: 'ease',
    title: 'Ease',
    type: CurveType.Timing,
    value: {
      out: { x: 0.25, y: 0.1 },
      in: { x: 0.25, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'ease-in',
    title: 'Ease in',
    type: CurveType.Timing,
    value: {
      out: { x: 0.42, y: 0 },
      in: { x: 1, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'ease-out',
    title: 'Ease out',
    type: CurveType.Timing,
    value: {
      out: { x: 0, y: 0 },
      in: { x: 0.58, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'ease-in-out',
    title: 'Ease in-out',
    type: CurveType.Timing,
    value: {
      out: { x: 0.42, y: 0 },
      in: { x: 0.58, y: 1 },
    },
  }),

  'divider',

  curveStyle.create({
    id: 'material-standard-easing',
    title: 'Material / Standard',
    type: CurveType.Timing,
    value: {
      out: { x: 0.2, y: 0 },
      in: { x: 0, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'material-decelerate-easing',
    title: 'Material / Decelerate',
    type: CurveType.Timing,
    value: {
      out: { x: 0, y: 0 },
      in: { x: 0, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'material-accelerate-easing',
    title: 'Material / Accelerate',
    type: CurveType.Timing,
    value: {
      out: { x: 0.3, y: 0 },
      in: { x: 1, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'material-emphasized-standard-easing',
    title: 'Material / Emphasized / Standard',
    type: CurveType.Timing,
    value: {
      out: { x: 0.2, y: 0 },
      in: { x: 0, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'material-emphasized-decelerate-easing',
    title: 'Material / Emphasized / Decelerate',
    type: CurveType.Timing,
    value: {
      out: { x: 0.05, y: 0.7 },
      in: { x: 0.1, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'material-emphasized-accelerate-easing',
    title: 'Material / Emphasized / Accelerate',
    type: CurveType.Timing,
    value: {
      out: { x: 0.3, y: 0 },
      in: { x: 0.8, y: 0.15 },
    },
  }),

  'divider',

  curveStyle.create({
    id: 'apple-standard-easing',
    title: 'Apple / Standard',
    type: CurveType.Timing,
    value: {
      out: { x: 0.5, y: 0 },
      in: { x: 0.15, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'apple-decelerate-easing',
    title: 'Apple / Decelerate',
    type: CurveType.Timing,
    value: {
      out: { x: 0, y: 0 },
      in: { x: 0.15, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'apple-accelerate-easing',
    title: 'Apple / Accelerate',
    type: CurveType.Timing,
    value: {
      out: { x: 0.5, y: 0 },
      in: { x: 1, y: 1 },
    },
  }),

  'divider',

  curveStyle.create({
    id: 'aninix-standard',
    title: 'Aninix / Standard',
    type: CurveType.Timing,
    value: {
      out: { x: 0.5, y: 0.35 },
      in: { x: 0.15, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'aninix-decelerate',
    title: 'Aninix / Decelerate',
    type: CurveType.Timing,
    value: {
      out: { x: 0, y: 0.35 },
      in: { x: 0.15, y: 1 },
    },
  }),
  curveStyle.create({
    id: 'aninix-accelerate',
    title: 'Aninix / Accelerate',
    type: CurveType.Timing,
    value: {
      out: { x: 0.5, y: 0.35 },
      in: { x: 1, y: 1 },
    },
  }),
  // curveStyle.create({
  //   id: 'aninix-overshoot',
  //   title: 'Aninix / Overshoot',
  //   type: CurveType.Timing,
  //   value: {
  //     out: { x: 0.5, y: 0.35 },
  //     in: { x: 0, y: 1.5 },
  //   },
  // }),
  // curveStyle.create({
  //   id: 'aninix-anticipation-overshoot',
  //   title: 'Aninix / Anticipation overshoot',
  //   type: CurveType.Timing,
  //   value: {
  //     out: { x: 0.5, y: -0.5 },
  //     in: { x: 0, y: 1.5 },
  //   },
  // }),
]

export const defaultSpringCurvePresets: (CurveStyle | 'divider')[] = [
  curveStyle.create({
    id: 'spring-gentle',
    title: 'Gentle',
    type: CurveType.Spring,
    value: {
      stiffness: 120,
      damping: 15,
      mass: 1,
    },
  }),
  curveStyle.create({
    id: 'spring-quick',
    title: 'Quick',
    type: CurveType.Spring,
    value: {
      stiffness: 100,
      damping: 10,
      mass: 1,
    },
  }),
  curveStyle.create({
    id: 'spring-bouncy',
    title: 'Bouncy',
    type: CurveType.Spring,
    value: {
      stiffness: 200,
      damping: 5,
      mass: 1,
    },
  }),
  curveStyle.create({
    id: 'spring-slow',
    title: 'Slow',
    type: CurveType.Spring,
    value: {
      stiffness: 50,
      damping: 25,
      mass: 1,
    },
  }),

  'divider',

  curveStyle.create({
    id: 'ios-standard',
    title: 'iOS / Standard',
    type: CurveType.Spring,
    value: {
      stiffness: 110,
      damping: 35,
      mass: 1,
    },
  }),

  curveStyle.create({
    id: 'ios-dynamic-island',
    title: 'iOS / Dynamic Island',
    type: CurveType.Spring,
    value: {
      stiffness: 105,
      damping: 15,
      mass: 1,
    },
  }),

  'divider',

  curveStyle.create({
    id: 'aninix-bounce',
    title: 'Aninix / Bounce',
    type: CurveType.Spring,
    value: {
      stiffness: 400,
      damping: 15,
      mass: 1,
    },
  }),
]
