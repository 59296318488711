import * as React from 'react'

const defaultSize = {
  x: 8,
  y: 5,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Dropdown: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 8 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.64648 4.35359L0.646484 1.35359L1.35359 0.646484L4.00004 3.29293L6.64648 0.646484L7.35359 1.35359L4.35359 4.35359L4.00004 4.70714L3.64648 4.35359Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
