import Fuse from 'fuse.js'
import * as React from 'react'
import { CellMeasurer, CellMeasurerCache, List } from 'react-virtualized'
import { SearchBar } from './search-bar'
import { ISearchItem, SearchItem } from './search-item'
import { SelectedItem } from './selected-item'

export interface IProps {
  selectedItem?: ISearchItem
  searchItems: ISearchItem[]
  onSelect: (id: string | undefined) => void
  suggestionRowsCount?: number
  defaultSearchValue?: string
  defaultSelectedItem?: ISearchItem
  placeholder?: string
  nothingFoundPlaceholder?: string
  defaultItemHeight?: number
  defaultFuzzyThreshold?: number
  autoFocus?: boolean
}

export const SearchBox: React.FC<IProps> = ({
  selectedItem: providedSelectedItem,
  searchItems,
  onSelect,
  defaultSearchValue = '',
  placeholder = 'Start typing to search',
  nothingFoundPlaceholder = 'Nothing found',
  suggestionRowsCount = 4,
  defaultItemHeight = 36,
  autoFocus = true,
  defaultFuzzyThreshold = 0.5,
  defaultSelectedItem,
}) => {
  const [searchValue, setSearchValue] = React.useState(defaultSearchValue)

  const [selectedItem, setSelectedItem] = React.useState<
    undefined | ISearchItem
  >(providedSelectedItem ?? defaultSelectedItem)

  // @NOTE: dirty hack to sync external and local states
  React.useEffect(() => {
    setSelectedItem(providedSelectedItem)
  }, [providedSelectedItem])

  const options = {
    includeScore: true,
    // Search in every key that appears in included meta
    keys: [
      'label',
      ...(searchItems[0]?.meta
        ? Object.keys(searchItems[0].meta).map((key) => `meta.${key}`)
        : []),
    ],
    threshold: defaultFuzzyThreshold,
  }

  const fuse = new Fuse(searchItems, options)

  const displayItems =
    searchValue.length > 0
      ? fuse.search(searchValue).map((e) => e.item)
      : searchItems

  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 36,
  })

  const rowRenderer: ListRowRenderer = ({ index, key, parent, style }) => {
    const item = displayItems[index]
    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <div style={style}>
            <div onLoad={measure}>
              <SearchItem
                item={item}
                onSelect={() => {
                  setSelectedItem(item)
                  onSelect?.(item.id)
                }}
              />
            </div>
          </div>
        )}
      </CellMeasurer>
    )
  }

  const [rowHeight, setRowHeight] = React.useState(defaultItemHeight)

  const isSearchResultEmpty =
    displayItems.length === 0 && searchItems.length !== 0

  React.useEffect(() => {
    setTimeout(() => {
      setRowHeight(cache.rowHeight({ index: 0 }))
    }, 0)
  }, [])

  return (
    <div className="w-full h-min flex flex-col bg-white overflow-hidden rounded-lg border border-gray-200">
      {selectedItem == null ? (
        <>
          <div className="pt-1 px-1">
            <SearchBar
              autoFocus={autoFocus}
              onChange={setSearchValue}
              placeholder={placeholder}
              defaultSearchValue={searchValue}
            />
          </div>

          <div className="relative">
            <div
              className="absolute z-[1] pointer-events-none flex items-center justify-center top-0 left-0 right-0 bottom-2 transition-opacity duration-150"
              style={{
                opacity: isSearchResultEmpty ? 1 : 0,
                width: 'auto',
                height: rowHeight * suggestionRowsCount,
                lineHeight: rowHeight * suggestionRowsCount + 'px',
              }}
            >
              <p className="text-[12px]/[14px] font-normal text-gray-400 transition-opacity duration-150">
                {nothingFoundPlaceholder}
              </p>
            </div>

            <List
              className="bg-white w-full"
              width={9999}
              autoWidth={true}
              height={rowHeight * suggestionRowsCount}
              rowCount={displayItems.length}
              rowHeight={rowHeight}
              rowRenderer={rowRenderer}
              overscanRowCount={10}
            />
          </div>
        </>
      ) : (
        <SelectedItem
          onDeselect={() => {
            setSelectedItem(undefined)
            onSelect?.(undefined)
          }}
          item={selectedItem}
        />
      )}
    </div>
  )
}

SearchBox.displayName = 'SearchBox'

const handleSearch = ({
  searchItems,
  searchValue,
}: {
  searchItems: ISearchItem[]
  searchValue: string
}) => {
  if (searchValue.length === 0) return searchItems

  return searchItems.filter((e) => e.label.includes(searchValue))
}

type ListRowRenderer = (props: {
  index: number
  key: string
  parent: any
  style: React.CSSProperties
}) => React.ReactNode
