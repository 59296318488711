import { useGetTeam, useInviteUserToTeam } from '@aninix/api'
import { Backdrop, Modal } from '@material-ui/core'
import * as R from 'ramda'
import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { toast } from '../../modules/toasts'
import { useSessionStore } from '../../stores'
import { Button } from '../design-system/button'
import { Typography } from '../design-system/typography'
import { InputEmails } from './input-email'

export const roleLabels: Record<ApiUser['aninixRole'], string> = {
  ['editor']: 'Editor',
  ['viewer']: 'Viewer',
}

export interface IProps {
  handleClose: () => void
  isModalOpen: boolean
}

export const InviteTeamMembers: React.FCC<IProps> = ({
  handleClose,
  isModalOpen,
}: IProps) => {
  const [currentRole, setCurrentRole] =
    React.useState<ApiUser['aninixRole']>('editor')

  const [isValidInvite, setIsValidInvite] = React.useState<boolean>(false)
  const [validEmails, setValidEmails] = React.useState<string[]>([])

  const [validInputEmails, setValidInputEmails] = React.useState<string[]>([])

  const stopPropagation = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation(),
    []
  )

  const invite = useInviteUserToTeam()

  const { teamId } = useSessionStore()

  const team = useGetTeam({ teamId: teamId! })

  const validEmailWithInput = React.useMemo(
    () => R.uniq([...validEmails, ...validInputEmails]),
    [validEmails, validInputEmails]
  )

  const inviteUsers = React.useCallback(() => {
    for (const email of validEmailWithInput) {
      invite
        .mutateAsync({
          teamId: teamId!,
          userEmail: email,
          aninixRole: currentRole,
          adminRole: 'viewer',
        })
        .then(() => {
          toast(
            `User ${email} invited! Look for our email with instructions to get started`,
            { variant: 'success' }
          )
          handleClose()
          team.refetch()
        })
        .catch(() => {
          toast("Couldn't invite user", { variant: 'error' })
        })
    }
  }, [validEmailWithInput, currentRole, handleClose, invite, team, teamId])

  return (
    <Modal
      open={isModalOpen}
      onClose={handleClose}
      className="flex h-full w-full flex-col items-center justify-center"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 250,
      }}
    >
      <div>
        <div
          onClick={stopPropagation}
          className="w-[560px] rounded-xl bg-white shadow-2xl"
        >
          <div className="flex w-full flex-col gap-4 p-6">
            <Typography style="Heading4" text={`Invite team members`} />
            <div className="flex w-full flex-col items-end gap-4">
              <InputEmails
                currentRole={currentRole}
                setCurrentRole={setCurrentRole}
                validEmails={validEmails}
                setValidEmails={setValidEmails}
                setValidInputEmails={setValidInputEmails}
              />
              <Button
                label={'Invite'}
                onClick={inviteUsers}
                isActive={
                  validEmailWithInput.length > 0 && invite.status !== 'pending'
                }
                isLoading={invite.status === 'pending'}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

InviteTeamMembers.displayName = 'InviteTeamMembers'
