import { Component, commitUndo } from '@aninix-inc/model'
import { useProject } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useInfo } from '../..'
import { useValueNumber } from '../../../../../hooks/value-controllers/number'
import { NumericValue } from '../numeric-value'

export interface IProps {
  component: Component
  color?: string
  format?: (value: number) => string
  min?: number
  max?: number
  /**
   * Run before provide value to UI
   */
  before?: (number: number) => number
  /**
   * Run before provide value to model
   */
  after?: (number: number) => number
  disabled?: boolean
  prefix?: string
  suffix?: string
}

export const NumberValue: React.FC<IProps> = ({
  component,
  color = 'black',
  format,
  min,
  max,
  before = R.identity,
  after = R.identity,
  disabled,
  prefix,
  suffix,
}) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time } = useInfo()
  const { value, updateValue } = useValueNumber({
    components: [component],
    time,
  })

  React.useEffect(() => {
    setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)} className="flex">
      {isEditable ? (
        <NumberValueEditable
          value={value as number}
          updateValue={updateValue}
          color={color}
          format={format}
          min={min}
          max={max}
          before={before}
          after={after}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
        />
      ) : (
        <NumberValueDisplay
          value={value as number}
          color={color}
          format={format}
          min={min}
          max={max}
          before={before}
          disabled={disabled}
          prefix={prefix}
          suffix={suffix}
        />
      )}
    </div>
  )
}

NumberValue.displayName = 'NumberValue'

export const NumberValueEditable: React.FC<
  Omit<IProps, 'component'> & {
    updateValue: (value: number) => void
    value: number
  }
> = ({
  color,
  format,
  min,
  max,
  before = R.identity,
  after = R.identity,
  disabled,
  prefix,
  suffix,
  value,
  updateValue,
}) => {
  const project = useProject()
  const endChange = React.useCallback(() => {
    commitUndo(project)
  }, [project])

  return (
    <NumericValue
      value={before(value)}
      onChange={(newValue) => updateValue(after(newValue))}
      onEndChange={endChange}
      format={format}
      min={min}
      max={max}
      disabled={disabled}
      prefix={prefix}
      suffix={suffix}
      textColor={color}
    />
  )
}

NumberValueEditable.displayName = 'NumberValueEditable'

const NumberValueDisplay: React.FC<
  Omit<IProps, 'component' | 'after'> & { value: number }
> = React.memo(
  ({
    color = 'black',
    format,
    min,
    max,
    before = R.identity,
    disabled,
    prefix,
    suffix,
    value,
  }) => {
    return (
      <NumericValue
        value={before(value)}
        onChange={() => {}}
        onEndChange={() => {}}
        format={format}
        min={min}
        max={max}
        disabled={disabled}
        prefix={prefix}
        suffix={suffix}
        textColor={color}
      />
    )
  },
  (prev, next) => {
    if (prev.value !== next.value) return false
    if (prev.min !== next.min) return false
    if (prev.max !== next.max) return false
    if (prev.disabled !== next.disabled) return false
    if (prev.prefix !== next.prefix) return false
    if (prev.suffix !== next.suffix) return false
    return true
  }
)

NumberValueDisplay.displayName = 'NumberValueDisplay'
