import * as React from 'react'

const defaultSize = { x: 13, y: 13 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Right: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 13H13V0H12V13ZM0 4C0 4.55228 0.447715 5 1 5H9C9.55228 5 10 4.55228 10 4C10 3.44772 9.55229 3 9 3H1C0.447715 3 0 3.44772 0 4ZM5 10C4.44772 10 4 9.55229 4 9C4 8.44771 4.44772 8 5 8H9C9.55228 8 10 8.44771 10 9C10 9.55229 9.55228 10 9 10H5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
