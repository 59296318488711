import {
  ChildrenExpandedComponent,
  Entity,
  EntityType,
  EntityTypeComponent,
  NodeColorComponent,
  ParentRelationAspect,
  PropertiesExpandedComponent,
  SelectionSystem,
  TimeComponent,
  UpdatesSystem,
  cleanupKeyframes,
  collapseChildren,
  collapseProperties,
  commitUndo,
  expandChildren,
  expandProperties,
  getNode,
  lerpRange,
  segmentsFromKeyframes,
} from '@aninix-inc/model'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { Tooltip, useMouseMove, useThrottle } from '@aninix/app-design-system'
import { HotkeyCombination } from '@aninix/app-design-system/components/common/hotkey-combination'
import { useClipboard } from '@aninix/clipboard'
import {
  KeyModificator,
  getSelection,
  useEntities,
  useEntity,
  useSession,
  useSystem,
} from '@aninix/core'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'
import { defaults, hotkeysLabels } from '../../../../../defaults'
import * as timeConverters from '../../../../../helpers/timeConverters'
import { useUi } from '../../../../../stores'
import { useSegmentsReverseUseCase } from '../../../../../use-cases'
import { useFormattedTime } from '../../../../properties-panel/components/formatted-time'
import * as styles from './index.scss'

// TODO: refactor
const handlerWidth = 6

export interface IProps {
  layer: Entity
  keyframes: Entity[]
  parentTrackWidth: number
  projectDuration: number
  startTime: number
  endTime: number
}
export const GroupOfSegments: React.FCC<IProps> = ({
  layer,
  keyframes,
  startTime,
  endTime,
  parentTrackWidth,
  projectDuration,
}) => {
  useEntity(layer)
  useEntities(keyframes)
  const analytics = useAnalytics()
  const clipboard = useClipboard()
  const session = useSession()
  const uiStore = useUi()
  const segmentsReverseUseCase = useSegmentsReverseUseCase()
  const project = R.head(keyframes)!.getProjectOrThrow()
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const intersection = R.intersection(
    getSelection(project, EntityType.Keyframe).map((k) => k.id),
    keyframes.map((k) => k.id)
  )
  const isSelected =
    intersection.length !== 0 && intersection.length === keyframes.length
  const duration = useFormattedTime(endTime - startTime)

  const tooltipText = session.keyModificators.includes(KeyModificator.Alt)
    ? `${duration.value}${duration.suffix}`
    : ''

  const initialStartTime = React.useRef<number>(startTime)
  const initialEndTime = React.useRef<number>(endTime)

  // @NOTE: required to handle the same interaction as in figma
  const wasTriggeredRef = React.useRef(false)
  const movingTrackRef = React.useRef<'left' | 'track' | 'right' | 'none'>(
    'none'
  )

  const endUndoGroup = React.useCallback(() => {
    cleanupKeyframes(project)
    commitUndo(project)
    analytics.track({
      eventName: AnalyticsEvent.GroupOfSegmentsMoved,
      properties: {
        context: 'webapp.editor.timeline.tracks',
      },
    })
  }, [project, analytics])

  const { offsetX, isListening, startListen, wasTriggered } = useMouseMove({
    threshold: 4,
    onTrigger: () => {
      wasTriggeredRef.current = true
    },
    onFinish: endUndoGroup,
  })

  const select = React.useCallback(() => {
    analytics.track({
      eventName: AnalyticsEvent.GroupOfSegmentsSelected,
      properties: {
        context: 'webapp.editor.timeline.tracks',
      },
    })
    if (session.keyModificators.includes(KeyModificator.Ctrl)) {
      if (session.keyModificators.includes(KeyModificator.Shift)) {
        if (selection.isSelected(layer.id)) {
          selection.deselect([layer.id])
          return
        }
        selection.select([layer.id])
        commitUndo(project)
        return
      }
      selection.replace([layer.id])
      commitUndo(project)
      return
    }
    if (session.keyModificators.includes(KeyModificator.Shift)) {
      const selectedKeyframeIds = getSelection(
        project,
        EntityType.Keyframe
      ).map((k) => k.id)
      keyframes.forEach((keyframe) => {
        if (selectedKeyframeIds.includes(keyframe.id)) {
          selection.deselect([keyframe.id])
          return
        }
        selection.select([keyframe.id])
      })
      commitUndo(project)
      return
    }
    selection.replace(keyframes.map((k) => k.id))
    commitUndo(project)
  }, [keyframes, selection, isSelected, analytics])

  const shiftOnTimeline = useThrottle({
    callback: React.useCallback((time: number): void => {
      const keyframesToWork = getSelection(project, EntityType.Keyframe)

      if (keyframesToWork.length <= 1) {
        return
      }

      const offset =
        time - R.head(keyframesToWork)!.getComponentOrThrow(TimeComponent).value

      keyframesToWork.forEach((keyframe) => {
        keyframe.updateComponent(TimeComponent, (v) => v + offset)
      })
    }, []),
    delay: 17,
  })

  const updateStartTime = useThrottle({
    callback: React.useCallback(
      (time) => {
        const keyframesToWork = getSelection(project, EntityType.Keyframe)
        const endTimeToUpdate = Math.max(
          ...keyframesToWork.map(
            (k) => k.getComponentOrThrow(TimeComponent).value
          )
        )

        if (Math.abs(time - endTimeToUpdate) <= 0.01) {
          return
        }

        keyframesToWork.forEach((keyframe) => {
          const newValue = lerpRange(
            keyframe.getComponentOrThrow(TimeComponent).value,
            startTime,
            endTimeToUpdate,
            time,
            endTimeToUpdate
          )
          keyframe.updateComponent(TimeComponent, newValue)
        })
      },
      [startTime]
    ),
    delay: 17,
  })

  const updateEndTime = useThrottle({
    callback: React.useCallback(
      (time) => {
        const keyframesToWork = getSelection(project, EntityType.Keyframe)
        const startTimeToUpdate = Math.min(
          ...keyframesToWork.map(
            (k) => k.getComponentOrThrow(TimeComponent).value
          )
        )

        if (Math.abs(time - startTimeToUpdate) <= 0.01) {
          return
        }

        keyframesToWork.forEach((keyframe) => {
          const newValue = lerpRange(
            keyframe.getComponentOrThrow(TimeComponent).value,
            startTimeToUpdate,
            endTime,
            startTimeToUpdate,
            time
          )
          keyframe.updateComponent(TimeComponent, newValue)
        })
      },
      [endTime]
    ),
    delay: 17,
  })

  const reverse = React.useCallback((): void => {
    updates.batch(() => {
      const keyframes = getSelection(project, EntityType.Keyframe)
      const sortedByTime = R.sortBy(
        (key) => key.getComponentOrThrow(TimeComponent).value,
        keyframes
      )
      const segments = segmentsFromKeyframes(sortedByTime)
      segmentsReverseUseCase.execute({ segments })
    })
  }, [selection, segmentsReverseUseCase, updates])

  const openContextMenu = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      select()
      uiStore.openContextMenu(
        [
          {
            title: 'Copy',
            onClick: () => {
              clipboard.copyCurrentSelection()
              uiStore.closeContextMenu()
            },
            rightPart: (
              <p>
                <HotkeyCombination keys={[hotkeysLabels().ctrl, 'C']} />
              </p>
            ),
          },
          'divider',
          {
            title: 'Reverse',
            onClick: () => {
              reverse()
              uiStore.closeContextMenu()
            },
          },
        ],
        e
      )
    },
    [select, uiStore]
  )

  const convertTimeToPixels = React.useCallback(
    (time: number) =>
      timeConverters.convertTimeToPixels({
        projectDuration,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        time,
      }),
    [projectDuration, parentTrackWidth]
  )

  const convertPixelsToTime = React.useCallback(
    (pixels: number) =>
      timeConverters.convertPixelsToTime({
        projectDuration,
        trackWidth: parentTrackWidth - handlerWidth * 2,
        pixels,
      }),
    [parentTrackWidth, projectDuration]
  )

  const color = (() => {
    const internalColor =
      // @NOTE: in case of selected layer
      layer.getComponentOrThrow(EntityTypeComponent).value === EntityType.Node
        ? defaults.nodeColors[
            layer.getComponentOrThrow(NodeColorComponent).value
          ]
        : // @NOTE: in case of property group (effect or paint)
          defaults.nodeColors[
            layer
              .getAspectOrThrow(ParentRelationAspect)
              .getParentEntityOrThrow()
              .getComponentOrThrow(NodeColorComponent).value
          ]

    if (isSelected) {
      return internalColor
    }

    return `${internalColor}75`
  })()

  // @NOTE: 2 pixels here needed for align properly the group of segments.
  // @TODO: think how to improve the layout
  const left = React.useMemo(
    () => convertTimeToPixels(Math.min(startTime, endTime)) - 2,
    [startTime, endTime, convertTimeToPixels]
  )
  const width = React.useMemo(
    () =>
      convertTimeToPixels(Math.abs(endTime - startTime)) +
      (handlerWidth + 2) * 2,
    [startTime, endTime, convertTimeToPixels]
  )

  const handleLeftMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (isSelected === false) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      const isLeftMouseButtonClicked = e.button === 0
      if (isLeftMouseButtonClicked === false) {
        return
      }

      initialStartTime.current = startTime
      initialEndTime.current = endTime
      movingTrackRef.current = 'left'
      // @ts-ignore
      startListen(e)
    },
    [select, startListen, startTime, endTime]
  )

  const handleMiddleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (isSelected === false) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      const isLeftMouseButtonClicked = e.button === 0
      if (isLeftMouseButtonClicked === false) {
        return
      }

      movingTrackRef.current = 'track'
      initialStartTime.current = startTime
      initialEndTime.current = endTime
      // @ts-ignore
      startListen(e)
    },
    [select, startListen, startTime, endTime]
  )

  const handleRightMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
      if (isSelected === false) {
        return
      }

      e.preventDefault()
      e.stopPropagation()

      const isLeftMouseButtonClicked = e.button === 0
      if (isLeftMouseButtonClicked === false) {
        return
      }

      movingTrackRef.current = 'right'
      initialStartTime.current = startTime
      initialEndTime.current = endTime
      // @ts-ignore
      startListen(e)
    },
    [select, startListen, startTime, endTime]
  )

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()

      if (wasTriggeredRef.current === false) {
        select()
      }

      wasTriggeredRef.current = false
    },
    [select]
  )

  const handleDoubleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()

      if (wasTriggeredRef.current === false) {
        updates.batch(() => {
          const layers = keyframes.reduce(
            (acc, keyframe) => {
              const node = getNode(keyframe)

              if (node === undefined) {
                throw new Error('Invalid state. Node not found')
              }

              acc.add(node)
              return acc
            },
            new Set<Entity>([layer])
          )
          const layersList = Array.from(layers)

          const propertiesExpanded = layer.getComponentOrThrow(
            PropertiesExpandedComponent
          ).value
          const childrenExpanded =
            layer.getComponent(ChildrenExpandedComponent)?.value ?? false

          for (const currentLayer of layersList) {
            if (childrenExpanded || propertiesExpanded) {
              collapseChildren(currentLayer, { recursive: true })
              collapseProperties(currentLayer, { recursive: true })
              continue
            }

            expandChildren(currentLayer, { recursive: true })
            expandProperties(currentLayer, { recursive: true })
          }
        })
      }

      wasTriggeredRef.current = false
    },
    [keyframes, layer]
  )

  React.useEffect(() => {
    if (wasTriggered) {
      if (movingTrackRef.current === 'left') {
        const offset = convertPixelsToTime(offsetX)
        updateStartTime(initialStartTime.current + offset)
      }

      if (movingTrackRef.current === 'track') {
        const offset = convertPixelsToTime(offsetX)
        shiftOnTimeline(initialStartTime.current + offset)
      }

      if (movingTrackRef.current === 'right') {
        const offset = convertPixelsToTime(offsetX)
        updateEndTime(initialEndTime.current + offset)
      }
    }
  }, [offsetX, wasTriggered, convertPixelsToTime])

  React.useEffect(() => {
    if (isListening === false) {
      movingTrackRef.current = 'none'
    }
  }, [isListening, startTime, endTime])

  const resizeEnabled = React.useMemo(() => {
    const MINIMAL_WIDTH_TO_PREVENT_RESIZE = 20

    if (width < MINIMAL_WIDTH_TO_PREVENT_RESIZE) {
      return false
    }

    return isSelected
  }, [width, isSelected])

  return (
    <Tooltip
      title={tooltipText ?? ''}
      arrow={false}
      placement="top"
      tooltipClassName="rounded-full bg-[#0B1118]"
      TransitionProps={{
        exit: false,
      }}
      followCursor
      offsetY={-16}
    >
      <div
        className={styles.container}
        style={{
          left: `${left}px`,
          width: `${width}px`,
        }}
        onClick={handleClick}
        onDoubleClick={handleDoubleClick}
        onContextMenu={openContextMenu}
      >
        <button
          type="button"
          // @NOTE: required to calculate selection in tracks
          data-model-type="group-of-segments"
          className={styles.segment}
          style={{
            backgroundColor: color,
          }}
          onMouseDown={handleMiddleMouseDown}
        >
          <span />
        </button>

        {resizeEnabled && (
          <button
            type="button"
            // @NOTE: required to calculate selection in tracks
            data-model-type="group-of-segments"
            className={styles.button}
            onMouseDown={handleLeftMouseDown}
          >
            <span />
          </button>
        )}

        {resizeEnabled && (
          <button
            type="button"
            // @NOTE: required to calculate selection in tracks
            data-model-type="group-of-segments"
            className={classNames(styles.button, styles.button__right)}
            onMouseDown={handleRightMouseDown}
          >
            <span />
          </button>
        )}
      </div>
    </Tooltip>
  )
}
