import classNames from 'classnames'
import * as React from 'react'

export interface IProps {
  keys: string[]
  overrideBackgroundClassName?: React.HTMLAttributes<HTMLSpanElement>['className']
  isOnLightBackground?: boolean
}
export const HotkeyCombination: React.FCC<IProps> = ({
  keys,
  overrideBackgroundClassName,
  isOnLightBackground = false,
}) => {
  return (
    <span className="inline-flex  items-center gap-[2px] break-keep">
      {keys.map((k, index) => (
        <HighlightKey
          keyName={k}
          index={index}
          key={`${index}-${k}`}
          backgroundClassName={overrideBackgroundClassName}
          isOnLightBackground={isOnLightBackground}
        />
      ))}
    </span>
  )
}

const HighlightKey: React.FCC<{
  keyName: string
  index: number
  backgroundClassName?: React.HTMLAttributes<HTMLSpanElement>['className']
  isOnLightBackground: boolean
}> = ({ keyName, index, backgroundClassName, isOnLightBackground }) => {
  return (
    <span className="tracking relative flex items-center justify-center rounded px-[4px] tracking-wide">
      {/* this is element for fake position, user don't see it */}
      <span
        style={{ minWidth: '1em' }}
        className=" origin-top scale-[0.9] opacity-0"
      >
        {keyName}
      </span>

      {/* this is what user see inside the box */}
      <span
        className={classNames(
          'absolute z-20  origin-top -translate-y-[1px] scale-[0.9] bg-blend-exclusion',
          {
            ['!text-black opacity-80']: isOnLightBackground,
            ['!text-white opacity-80']: !isOnLightBackground,
          }
        )}
      >
        {keyName}
      </span>

      {/* this is box/background */}
      {/* <div className="absolute inset-0 z-10 rounded bg-gray-300 opacity-20" /> */}
      <span className="opacity-50">
        <span
          className={classNames(
            'absolute inset-0 -bottom-[2px] -top-[3px] z-10 bg-blend-exclusion',
            {
              ['rounded  bg-gray-300']:
                backgroundClassName === undefined && isOnLightBackground,
              ['rounded  bg-gray-700']:
                backgroundClassName === undefined && !isOnLightBackground,
              [backgroundClassName!]: backgroundClassName !== undefined,
            }
          )}
        />

        <span
          className={classNames(
            'absolute inset-0 -bottom-[0px] -top-[3px] z-10 ',
            {
              ['rounded border border-gray-300 bg-gray-100 ']:
                backgroundClassName === undefined && isOnLightBackground,
              ['rounded border border-gray-700 bg-gray-500 ']:
                backgroundClassName === undefined && !isOnLightBackground,
              [backgroundClassName!]: backgroundClassName !== undefined,
            }
          )}
        />
      </span>
    </span>
  )
}

HotkeyCombination.displayName = 'HotkeyCombination'
