import { Component, getSortedKeyframes, TimeComponent } from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'

export const getKeyframesType = (components: Component[], time: number) => {
  const componentsKeyframes = components.map(
    (component) => [component, getSortedKeyframes(component)] as const
  )

  const types = componentsKeyframes.map(([_, keyframes]) => {
    const hasKeyframeAtTime =
      keyframes.find(
        (keyframe) => keyframe.getComponentOrThrow(TimeComponent).value === time
      ) != null
    const hasKeyframes = keyframes.length > 0

    if (hasKeyframeAtTime) {
      return PropertyKeyframesType.Active
    }

    if (hasKeyframes) {
      return PropertyKeyframesType.HasKeyframes
    }

    return PropertyKeyframesType.Empty
  })

  return types[0]
}
