import { round } from '@aninix-inc/model/legacy'

interface IPayload {
  seconds: number
}
interface IOutput {
  milliseconds: number
  suffix: string
}
export function convertSecondsToMilliseconds({ seconds }: IPayload): IOutput {
  return {
    milliseconds: round(seconds * 1000, { fixed: 0 }),
    suffix: 'ms',
  }
}
