import { Vector } from '@aninix-inc/model'
import { applyCommon } from '../maps/apply-common'
import { applyPath } from '../maps/apply-path'
import { NodeHandler } from '../types'

export const path: NodeHandler = (node, project, assets) => {
  const entity = project.createEntity(Vector)
  applyCommon(entity, node, assets)
  applyPath(entity, node)

  return entity
}
