import { CurrentFolderContext, useGetTrash } from '@aninix/api'
import * as React from 'react'

import { UserRole } from '@aninix/core'
import { useNotifications } from '@aninix/notifications'
import { Paywall } from '../../modules/paywall'
import { usePageOutletContext } from '../../modules/teams'
import { useCurrentTeamStore, useCurrentUser } from '../../use-cases'
import { Typography } from '../design-system/typography'
import { Icons } from '../icons'
import { LoadableChildrenWrapper } from '../loadable-wrapper'
import { ProjectItemPlaceholder } from './project-item'
import { EmptyState } from './project-list-empty-state'
import { TrashProjectItem } from './trash-project-item'

export interface IProps {
  page: 'my' | 'teams'
}

export const TrashProjectsList: React.FCC<IProps> = ({ page }: IProps) => {
  const { userId, role } = useCurrentUser()
  const { currentTeam } = useCurrentTeamStore()
  const notifications = useNotifications()
  const [isUpgradeToProVisible, setIsUpgradeToProVisible] =
    React.useState(false)

  const showUpgradeToPro = React.useCallback(() => {
    if (role === UserRole.Pro) {
      return true
    }

    if (currentTeam != null && currentTeam.adminRole !== 'owner') {
      notifications.showNotification(
        'Please ask administrator to upgrade to PRO from the dashboard',
        { id: 'ask-administrator-to-upgrade-to-pro' }
      )
      return false
    }

    setIsUpgradeToProVisible(true)
    return false
  }, [currentTeam, notifications, role])
  const hideUpgradeToPro = React.useCallback(() => {
    setIsUpgradeToProVisible(false)
  }, [])

  const pageOutletContext = usePageOutletContext()

  React.useEffect(() => {
    pageOutletContext.setControls('None')
    if (page === 'teams') {
      pageOutletContext.setTitle('Team trash')
    }
    if (page === 'my') pageOutletContext.setTitle('My trash')
  }, [])

  const payload = React.useMemo(() => {
    switch (page) {
      case 'teams':
        return { teamId: currentTeam?.id }
      case 'my':
        return { userId }
    }
  }, [currentTeam, page])

  const trash = useGetTrash(payload)
  // const folders = trash.data?.folders ?? []
  const projects =
    trash?.data?.data.sort(
      (a, b) =>
        new Date(a.deletedAt!).getTime() - new Date(b.deletedAt!).getTime()
    ) ?? []

  const sidePanelWidth = 250,
    maximumItemWidth = 300

  const getCurrentBodyWidthFraction = () =>
    Math.max(
      Math.floor(
        (document.body.getBoundingClientRect().width - sidePanelWidth) /
          maximumItemWidth
      ),
      2
    )

  const [repeatCount, setRepeatCount] = React.useState(
    getCurrentBodyWidthFraction()
  )

  const handleResize = React.useCallback(() => {
    setRepeatCount(getCurrentBodyWidthFraction())
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  return (
    <LoadableChildrenWrapper
      isLoading={trash.isLoading}
      isError={trash.isError}
      loadingComponent={
        <div className="flex h-full flex-col gap-4">
          {/* <Breadcrumbs /> */}
          <div className="flex flex-col gap-4">
            {/* <div className="grid grid-cols-3 grid-rows-none gap-4 2xl:grid-cols-4 3xl:grid-cols-5">
              {[1, 2, 3, 4, 5, 6, 7].map((f, i) => (
                <FolderItemPlaceholder key={i} />
              ))}
            </div> */}

            <div
              style={
                {
                  '--repeat': `repeat(${repeatCount},minmax(0,1fr))`,
                } as React.CSSProperties
              }
              className="grid grid-cols-[--repeat] gap-4"
            >
              {[1, 2, 3, 4, 5, 6, 7].map((f, i) => (
                <ProjectItemPlaceholder key={i} />
              ))}
            </div>
          </div>
        </div>
      }
    >
      <CurrentFolderContext.Provider value={{ trash }}>
        <>
          <div className="flex h-full flex-col gap-4">
            {/* <Breadcrumbs /> */}
            <div className="flex w-full flex-row items-center gap-4 rounded-lg bg-gray-100 p-4">
              <Icons.Shred />
              <Typography style="Body5Regular" className="w-80 text-secondary">
                Projects in trash are deleted forever after 30 days
              </Typography>
            </div>

            {/* {folders?.length + projects?.length !== 0 ? ( */}
            {projects?.length !== 0 ? (
              <div className="flex flex-col gap-4">
                <div
                  style={
                    {
                      '--repeat': `repeat(${repeatCount},minmax(0,1fr))`,
                    } as React.CSSProperties
                  }
                  className="grid grid-cols-[--repeat] gap-4"
                >
                  {projects?.map((p) => (
                    <TrashProjectItem
                      {...p}
                      key={p.id}
                      beforeRestoreProject={showUpgradeToPro}
                    />
                  ))}
                </div>
              </div>
            ) : (
              <EmptyState
                header="No deleted projects"
                description="All the deleted projects will be collected here for 30 days"
              />
            )}
          </div>

          <Paywall
            isVisible={isUpgradeToProVisible}
            context="trash"
            onClose={hideUpgradeToPro}
            teamId={currentTeam?.id}
          />
        </>
      </CurrentFolderContext.Provider>
    </LoadableChildrenWrapper>
  )
}

TrashProjectsList.displayName = 'TrashProjectsList'
