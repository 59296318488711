import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const WarningWithQuestion: React.FCC<IProps> = ({
  size = defaultSize,
  // color = '#FFC700',
  // highlightColor = 'rgba(0, 0, 0, 0.8)'
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <rect
      x="5"
      y="8"
      width="22"
      height="16"
      rx="4"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.7774 11.5837L10 11.0654V11.9997V19.9997V20.9339L10.7774 20.4157L16.7773 16.4157L17.4014 15.9997L16.7773 15.5837L10.7774 11.5837ZM15.5986 15.9997L11 19.0654V12.9339L15.5986 15.9997ZM21.0632 17.6989H20.0002C20.0002 16.1735 20.2683 15.6787 21.1494 15.2094C21.6432 14.9465 22.0544 14.5856 22.0544 14.0075C22.0544 13.3161 21.6404 12.8747 20.8513 12.8747C20.0622 12.8747 19.6353 13.1794 19.5884 14.0075H18.4966C18.5474 12.6013 19.3489 11.8903 20.8513 11.8903C22.3537 11.8903 23.126 12.738 23.126 14.0075C23.126 14.863 22.6084 15.5581 21.9074 15.9997C21.178 16.4593 21.0632 16.5601 21.0632 17.6989ZM21.287 19.2576C21.287 19.6912 20.9315 20.0389 20.5057 20.0389C20.076 20.0389 19.7245 19.6912 19.7245 19.2576C19.7245 18.8318 20.076 18.4803 20.5057 18.4803C20.9315 18.4803 21.287 18.8318 21.287 19.2576Z"
      fill="var(--figma-color-text-brand, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
