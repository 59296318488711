import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Playback: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.10355 5.39685L2.70711 4.0004H6C8.76143 4.0004 11 6.23898 11 9.0004C11 11.7618 8.76143 14.0004 6 14.0004C3.31516 14.0004 1.12457 11.8843 1.00513 9.22892H0.00427246C0.124391 12.4367 2.76281 15.0004 6 15.0004C9.31371 15.0004 12 12.3141 12 9.0004C12 5.6867 9.31371 3.0004 6 3.0004H2.70711L4.10355 1.60396L3.39645 0.896851L1.14645 3.14685L0.792894 3.5004L1.14645 3.85396L3.39645 6.10396L4.10355 5.39685Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
