import debounce from 'lodash/debounce'
import { reaction } from 'mobx'
import * as R from 'ramda'
import * as React from 'react'

type GetData<T> = () => T
type ProcessData<T> = (data: T, prevData?: T) => void
type Options = {
  delay?: number
}

const defaultOptions: Required<Options> = {
  // @NOTE: 4 frames
  delay: 68,
}

export function useReaction<T>(
  getData: GetData<T>,
  processData: ProcessData<T>,
  options?: Options
) {
  React.useEffect(() => {
    // @ts-ignore
    const newOptions: Required<Payload<unknown>['options']> = R.mergeDeepLeft(
      // @ts-ignore
      R.defaultTo(defaultOptions, options),
      defaultOptions
    )

    const subscription = reaction(
      getData,
      debounce(
        (value, prevValue) => processData(value, prevValue),
        newOptions!.delay
      )
    )

    processData(getData())

    return subscription
  }, [])
}
