import * as React from 'react'

import { HorizontalCenter as HorizontalCenterIcon } from './horizontal-center'
import { HorizontalLeft as HorizontalLeftIcon } from './horizontal-left'
import { HorizontalLeftOutside as HorizontalLeftOutsideIcon } from './horizontal-left-outside'
import { HorizontalRight as HorizontalRightIcon } from './horizontal-right'
import { HorizontalRightOutside as HorizontalRightOutsideIcon } from './horizontal-right-outside'
import { VerticalBottom as VerticalBottomIcon } from './vertical-bottom'
import { VerticalBottomOutside as VerticalBottomOutsideIcon } from './vertical-bottom-outside'
import { VerticalCenter as VerticalCenterIcon } from './vertical-center'
import { VerticalTop as VerticalTopIcon } from './vertical-top'
import { VerticalTopOutside as VerticalTopOutsideIcon } from './vertical-top-outside'

export enum NodesAlignType {
  HorizontalLeft = 'horizontal-left',
  HorizontalLeftOutside = 'horizontal-left-outside',
  HorizontalMiddle = 'horizontal-middle',
  HorizontalRight = 'horizontal-right',
  HorizontalRightOutside = 'horizontal-right-outside',
  VerticalTop = 'vertical-top',
  VerticalTopOutside = 'vertical-top-outside',
  VerticalCenter = 'vertical-center',
  VerticalBottom = 'vertical-bottom',
  VerticalBottomOutside = 'vertical-bottom-outside',
}

export interface IProps {
  type: NodesAlignType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const NodesAlign: React.FCC<IProps> = ({ type, size, style }) => {
  if (NodesAlignType.HorizontalLeft === type) {
    return <HorizontalLeftIcon style={style} size={size} />
  }

  if (NodesAlignType.HorizontalLeftOutside === type) {
    return <HorizontalLeftOutsideIcon style={style} size={size} />
  }

  if (NodesAlignType.HorizontalMiddle === type) {
    return <HorizontalCenterIcon style={style} size={size} />
  }

  if (NodesAlignType.HorizontalRight === type) {
    return <HorizontalRightIcon style={style} size={size} />
  }

  if (NodesAlignType.HorizontalRightOutside === type) {
    return <HorizontalRightOutsideIcon style={style} size={size} />
  }

  if (NodesAlignType.VerticalTop === type) {
    return <VerticalTopIcon style={style} size={size} />
  }

  if (NodesAlignType.VerticalTopOutside === type) {
    return <VerticalTopOutsideIcon style={style} size={size} />
  }

  if (NodesAlignType.VerticalCenter === type) {
    return <VerticalCenterIcon style={style} size={size} />
  }

  if (NodesAlignType.VerticalBottom === type) {
    return <VerticalBottomIcon style={style} size={size} />
  }

  if (NodesAlignType.VerticalBottomOutside === type) {
    return <VerticalBottomOutsideIcon style={style} size={size} />
  }

  return null
}
