import { Frame } from '@aninix-inc/model'
import { convertNode } from '../convert-node'
import { applyCommon } from '../maps/apply-common'
import { NodeHandler } from '../types'

export const svg: NodeHandler = (node, project, assets) => {
  const entity = project.createEntity(Frame)

  applyCommon(entity, node, assets)

  node.children.forEach((n, i) =>
    convertNode(node.children.length - i, n, assets, entity, project)
  )

  return entity
}
