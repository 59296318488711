import { Project, Text } from '@aninix-inc/model'
import { convertEntityToSnapshot } from '@aninix-inc/renderer'
import { ImagesStore } from '@aninix/core/stores'

export const getTextLayersFromProject = (
  project: Project,
  time: number,
  imagesStore: ImagesStore
) => ({
  nodes: project
    .getEntitiesByType(Text)
    .map((entity) => convertEntityToSnapshot({ entity, time, imagesStore })),
  entities: project.getEntitiesByType(Text),
})
