import * as React from 'react'

import { IIcon } from '.'

export const PeopleSelected: React.FCC<IIcon> = ({
  size = { x: 28, y: 28 },
  className = 'h-6 w-6',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`2 2 ${size.x - 6} ${size.y - 6}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3.6001"
      y="2.39999"
      width="16.8"
      height="19.2"
      rx="2"
      fill="#0B1118"
      stroke="#0B1118"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 15.6C13.6569 15.6 15 14.2569 15 12.6C15 10.9432 13.6569 9.60001 12 9.60001C10.3431 9.60001 9 10.9432 9 12.6C9 14.2569 10.3431 15.6 12 15.6Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.7998 18C8.28888 17.2548 8.92301 16.65 9.65198 16.2335C10.381 15.8169 11.1848 15.6 11.9998 15.6C12.8148 15.6 13.6186 15.8168 14.3476 16.2333C15.0765 16.6499 15.7107 17.2546 16.1998 17.9998"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.6001 6H14.4001"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
