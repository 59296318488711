import {
  ChildrenExpandedComponent,
  ChildrenRelationsAspect,
  Entity,
  EntityType,
  EntityTypeComponent,
  EntryComponent,
  FillsRelationsAspect,
  LayerColor,
  LockedComponent,
  MaskComponent,
  NameComponent,
  NodeColorComponent,
  NodeSoloType,
  NodeType,
  NodeTypeComponent,
  PaintType,
  PaintTypeComponent,
  ParentRelationAspect,
  PropertiesExpandedComponent,
  SoloComponent,
  StrokesRelationsAspect,
  UpdatesSystem,
  VisibleInViewportComponent,
  collapseChildren,
  collapseProperties,
  commitUndo,
  expandChildren,
  expandProperties,
  hasOwnAnimation,
} from '@aninix-inc/model'
import {
  ColorSelector,
  InputTimeline,
  TimelineTrack,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { LayerType } from '@aninix/app-design-system/components/common/icons'
import { useClipboard } from '@aninix/clipboard'
import {
  KeyModificator,
  getSelection,
  nodeColors,
  trimString,
  useEntity,
  useProject,
  useSession,
} from '@aninix/core'
import { useSelection } from '@aninix/editor/modules/selection'
import { clearAnimation } from '@aninix/editor/use-cases/clear-animation'
import { copyPositionKeyframes } from '@aninix/editor/use-cases/copy-position-keyframes'
import { duplicateLayer } from '@aninix/editor/use-cases/duplicate-layer'
import { Popover } from '@material-ui/core'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'
import { useInfo } from '..'
import { defaults } from '../../../../defaults'
import { useUi } from '../../../../stores'
import { ContextMenuItem } from '../../../../stores/ui'
import { NodeExpandType } from '../../../../types'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import { useLayerDnd } from '../drag-n-drop'
import { Spacer } from '../property'
import * as styles from './index.scss'

function getLayerType(layer: Entity): LayerType {
  const type = layer.getComponentOrThrow(NodeTypeComponent).value

  if (
    layer.hasComponent(MaskComponent) &&
    layer.getComponentOrThrow(MaskComponent).value
  ) {
    return LayerType.Mask
  }

  if (
    (layer.hasAspect(FillsRelationsAspect) &&
      R.any(
        (fill) =>
          fill.getComponentOrThrow(PaintTypeComponent).value ===
          PaintType.Image,
        layer.getAspectOrThrow(FillsRelationsAspect).getChildrenList()
      )) ||
    (layer.hasAspect(StrokesRelationsAspect) &&
      R.any(
        (stroke) =>
          stroke.getComponentOrThrow(PaintTypeComponent).value ===
          PaintType.Image,
        layer.getAspectOrThrow(StrokesRelationsAspect).getChildrenList()
      ))
  ) {
    return LayerType.VectorWithImageFill
  }

  if (type === NodeType.Ellipse) {
    return LayerType.Ellipse
  }

  if (type === NodeType.Rectangle) {
    return LayerType.Rectangle
  }

  if (type === NodeType.Polygon || type === NodeType.Star) {
    return LayerType.Polygon
  }

  if (type === NodeType.Frame) {
    return LayerType.Frame
  }

  if (type === NodeType.Instance) {
    return LayerType.Instance
  }

  if (type === NodeType.Group) {
    return LayerType.Group
  }

  if (type === NodeType.Text) {
    return LayerType.Text
  }

  return LayerType.Vector
}

type LayerMaskType = 'none' | 'start' | 'end' | 'in-scope' | 'between'

export interface IProps {
  layer: Entity
  indent: number
}

const iconButtonSize = { width: 16, height: 16 }
const iconSize = { x: 16, y: 16 }

export const Layer: React.FC<IProps> = ({ layer, indent: providedIndent }) => {
  const { time } = useInfo()
  const [isEditable, setIsEditable] = React.useState(false)
  useEntity(layer)

  const { isEntityDragging, isEntityDropTarget, isEntityParentOfDropTarget } =
    useLayerDnd()

  const isDragging = isEntityDragging(layer)
  const isParentOfDropTarget = isEntityParentOfDropTarget(layer)

  const session = useSession()

  const type = getLayerType(layer)
  const name = layer.getComponentOrThrow(NameComponent).value

  const indent = providedIndent - 1
  const maskColor = 'rgba(0, 0, 0, 0.1)'
  const isRoot = layer.hasComponent(EntryComponent)
  const hasAnimatedProperties = isRoot === false && hasOwnAnimation(layer)

  const isAnyParentSelectedValue = isAnyParentSelected(layer)
  const isLayerSelectedValue = isLayerSelected(layer)
  const isComponentSelectedValue = R.any(
    (component) => isComponentSelected(component),
    layer.components
  )

  const color = nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
  const highlightColor = React.useMemo(() => {
    if (isAnyParentSelectedValue || isParentOfDropTarget) return `${color}10`
    if (isComponentSelectedValue) return `${color}35`
    if (isLayerSelectedValue || isDragging) return `${color}35`

    return 'transparent'
  }, [
    isAnyParentSelectedValue,
    isParentOfDropTarget,
    isComponentSelectedValue,
    isLayerSelectedValue,
    isDragging,
    color,
  ])

  const hasChildren = Boolean(
    layer.hasAspect(ChildrenRelationsAspect) &&
      layer.getAspectOrThrow(ChildrenRelationsAspect).getChildrenList().length
  )

  const layerVisible = layer.getComponentOrThrow(
    VisibleInViewportComponent
  ).value

  const layerLocked = layer.getComponentOrThrow(LockedComponent).value

  const childrenExpanded =
    layer.hasComponent(ChildrenExpandedComponent) &&
    layer.getComponentOrThrow(ChildrenExpandedComponent).value

  const propertiesExpanded =
    layer.hasComponent(PropertiesExpandedComponent) &&
    layer.getComponentOrThrow(PropertiesExpandedComponent).value

  const maskType = (() => {
    if (isRoot) return 'none'

    const children = layer
      .getAspectOrThrow(ParentRelationAspect)
      .getParentEntityOrThrow()
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()

    const maskIdx = children.findIndex(
      (node) => node.getComponent(MaskComponent)?.value
    )
    const hasMask = maskIdx !== -1

    if (!hasMask) return 'none'
    if (layer.getComponent(MaskComponent)?.value) return 'start'

    const currentIdx = children.findIndex((_node) => _node.id === layer.id)
    const isLast = currentIdx === children.length - 1

    if (hasMask && isLast) return 'end'
    if (hasMask && currentIdx < maskIdx) return 'in-scope'

    return 'between'
  })()

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  const layerProps: LayerProps = {
    layer,
    color,
    highlightColor,
    maskColor,
    indent,
    maskType,
    childrenExpanded,
    hasChildren,
    propertiesExpanded,
    hasAnimatedProperties,
    layerLocked,
    layerVisible,
    type,
    name,
    isRoot,
  }

  return (
    <div
      onPointerMove={() => setIsEditable(true)}
      onPointerLeave={() => setIsEditable(false)}
    >
      {isEditable ? (
        <LayerEditable {...layerProps} />
      ) : (
        <LayerDisplay {...layerProps} />
      )}
    </div>
  )
}

Layer.displayName = 'Layer'

type LayerProps = {
  layer: Entity
  color: string
  highlightColor: string
  maskColor: string
  indent: number
  maskType: LayerMaskType
  childrenExpanded: boolean
  hasChildren: boolean
  propertiesExpanded: boolean
  hasAnimatedProperties: boolean
  layerLocked: boolean
  layerVisible: boolean
  type: LayerType
  name: string
  isRoot: boolean
}

const LayerEditable: React.FC<LayerProps> = React.memo(
  ({
    layer,
    color,
    highlightColor,
    maskColor,
    indent,
    maskType,
    childrenExpanded,
    hasChildren,
    propertiesExpanded,
    hasAnimatedProperties,
    layerLocked,
    layerVisible,
    type,
    name,
    isRoot,
  }) => {
    useEntity(layer)
    const { selection } = useSelection()
    const project = useProject()
    const updates = project.getSystemOrThrow(UpdatesSystem)
    const [isHovering, setIsHovering] = React.useState(false)

    // @TODO: move to index because using useStores in interactor is anti-pattern
    const clipboard = useClipboard()
    const session = useSession()
    const uiStore = useUi()

    const solo = layer.getComponentOrThrow(SoloComponent).value
    const isLayerSelectedValue = isLayerSelected(layer)

    const isHighlighted = session.buffer === layer.id

    const {
      isEntityDragging,
      isEntityDropTarget,
      isEntityParentOfDropTarget,
      getDropZoneIndent,
      ...dndHandlers
    } = useLayerDnd()

    const onExpandChildren = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        updates.batch(() => {
          const selectedNodes = getSelection(project, EntityType.Node)
          const layersToWork: Entity[] =
            selectedNodes.length === 0 || isLayerSelectedValue === false
              ? [layer]
              : selectedNodes

          // @NOTE: required to make the same state for all selected layers.
          // So layers.forEach((layer) => layer.toggle()) doesn't work for that case.

          if (childrenExpanded) {
            layersToWork.forEach((_layer) => {
              collapseChildren(_layer)
              collapseProperties(_layer)
            })
            return
          }

          layersToWork.forEach((_layer) => {
            expandChildren(_layer)
          })
        })
        commitUndo(project)
      },
      [layer, isLayerSelectedValue, childrenExpanded, project, updates]
    )

    const onExpandPropertiesClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        updates.batch(() => {
          const selectedNodes = getSelection(project, EntityType.Node)
          const layersToWork: Entity[] =
            selectedNodes.length === 0 || isLayerSelectedValue === false
              ? [layer]
              : selectedNodes

          // @NOTE: required to make the same state for all selected layers.
          // So layers.forEach((layer) => layer.toggle()) doesn't work for that case.
          if (propertiesExpanded) {
            layersToWork.forEach((l) => collapseProperties(l))
            return
          }

          layersToWork.forEach((l) => expandProperties(l))
        })
        commitUndo(project)
      },
      [layer, isLayerSelectedValue, propertiesExpanded, project, updates]
    )

    const onNameChange = React.useCallback(
      (name: string): void => {
        layer.updateComponent(NameComponent, name)
      },
      [layer]
    )

    const onColorUpdate = React.useCallback(
      (color: LayerColor): void => {
        updates.batch(() => {
          const nodesToUpdate = getSelection(project, EntityType.Node)

          if (nodesToUpdate.findIndex((node) => node.id === layer.id) === -1) {
            nodesToUpdate.push(layer)
          }

          const updateColor = (node: Entity) => {
            node.updateComponent(NodeColorComponent, color)

            const children = node
              .getAspect(ChildrenRelationsAspect)
              ?.getChildrenList()
              .filter(
                (c) =>
                  c.getComponent(EntityTypeComponent)?.value === EntityType.Node
              )

            children?.forEach(updateColor)
          }

          nodesToUpdate.forEach(updateColor)
        })
        commitUndo(project)
      },
      [layer, updates, project]
    )

    const onTrackClick = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()

        updates.batch(() => {
          // @NOTE: also used in the tracks on timeline
          if (session.keyModificators.includes(KeyModificator.Shift)) {
            const selectedNodes = getSelection(project, EntityType.Node)
            if (selectedNodes.length === 0) {
              selection.select([layer.id])
              return
            }
            const selectedNodeIds = selectedNodes.map((_node) => _node.id)
            const parent = layer
              .getAspectOrThrow(ParentRelationAspect)
              .getParentEntityOrThrow()
            const nodesToSelect = parent
              .getAspectOrThrow(ChildrenRelationsAspect)
              .getChildrenList()
            const minSelectedIndex = R.findIndex(
              (_node: any) => selectedNodeIds.includes(_node.id),
              nodesToSelect
            )
            const currentIndex = R.findIndex(
              (_node: any) => _node.id === layer.id,
              nodesToSelect
            )
            const maxSelectedIndex = R.findLastIndex(
              (_node: any) => selectedNodeIds.includes(_node.id),
              nodesToSelect
            )
            // @NOTE: reversed
            if (currentIndex < minSelectedIndex) {
              R.reverse(
                R.range(
                  R.min(currentIndex, minSelectedIndex),
                  R.max(currentIndex, minSelectedIndex) + 1
                )
              ).forEach((i) => {
                if (nodesToSelect[i] == null) {
                  return
                }
                selection.select([nodesToSelect[i].id])
              })
              return
            }
            R.range(
              R.min(currentIndex, maxSelectedIndex),
              R.max(currentIndex, maxSelectedIndex) + 1
            ).forEach((i) => {
              if (nodesToSelect[i] == null) {
                return
              }
              selection.select([nodesToSelect[i].id])
            })
            return
          }
          if (session.keyModificators.includes(KeyModificator.Ctrl)) {
            if (selection.isSelected(layer.id)) {
              selection.deselect([layer.id])
              return
            }
            selection.select([layer.id])
            return
          }
          selection.replace([layer.id])
        })
        commitUndo(project)
      },
      [layer, project, updates, selection, session]
    )

    const nextSoloType = React.useMemo(() => {
      const isAltPressed = session.keyModificators.includes(KeyModificator.Alt)

      if (isAltPressed) {
        if (solo === NodeSoloType.None) {
          return NodeSoloType.Both
        }

        return NodeSoloType.None
      }

      if (solo === NodeSoloType.None) {
        return NodeSoloType.Viewport
      }

      if (solo === NodeSoloType.Viewport) {
        return NodeSoloType.Timeline
      }

      if (solo === NodeSoloType.Timeline) {
        return NodeSoloType.Both
      }

      return NodeSoloType.None
    }, [solo])

    const onSoloClick = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        layer.updateComponent(SoloComponent, nextSoloType)
      },
      [nextSoloType]
    )

    const onLockToggle = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        updates.batch(() => {
          const selectedNodes = getSelection(project, EntityType.Node)
          const isCurrentNodeSelected = selectedNodes
            .map((node) => node.id)
            .includes(layer.id)

          if (isCurrentNodeSelected === false) {
            if (layer.getComponentOrThrow(LockedComponent).value) {
              layer.updateComponent(LockedComponent, false)
              return
            }
            layer.updateComponent(LockedComponent, true)
            selection.deselect([layer.id])
            return
          }
          selectedNodes.forEach((_node) => {
            if (_node.getComponentOrThrow(LockedComponent).value) {
              _node.updateComponent(LockedComponent, false)
              return
            }
            _node.updateComponent(LockedComponent, true)
          })
          selection.deselectAll()
        })
        commitUndo(project)
      },
      [layer, project, updates, selection]
    )

    const onVisibilityToggle = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()

        updates.batch(() => {
          const selectedNodes = getSelection(project, EntityType.Node)

          const isCurrentNodeSelected = selectedNodes
            .map((node) => node.id)
            .includes(layer.id)

          if (isCurrentNodeSelected === false) {
            if (layer.getComponentOrThrow(VisibleInViewportComponent).value) {
              layer.updateComponent(VisibleInViewportComponent, false)
              return
            }

            layer.updateComponent(VisibleInViewportComponent, true)
            return
          }

          selectedNodes.forEach((node) => {
            node.updateComponent(VisibleInViewportComponent, (v) => !v)
          })
        })

        commitUndo(project)
      },
      [layer, updates, project]
    )

    const enableHighlight = React.useCallback(() => {
      session.setBuffer(layer.id)
    }, [session, layer.id])

    const disableHighlight = React.useCallback(() => {
      session.cleanBuffer()
    }, [session])

    const showContextMenu = React.useCallback(
      (e: React.MouseEvent<Element, MouseEvent>) => {
        if (selection.isSelected(layer.id) === false) {
          selection.select([layer.id])
        }
        const array: Array<ContextMenuItem | 'divider'> = []
        // // @TODO: IMPORTANT refactor. Move to common place or service
        array.push({
          title: 'Copy as position keyframes',
          onClick: copyPositionKeyframes({
            layer,
            project,
            clipboard,
            uiStore,
          }),
        })
        array.push({
          title: 'Duplicate',
          onClick: duplicateLayer(layer, project, uiStore),
        })
        array.push({
          title: 'Clear animation',
          onClick: clearAnimation({ project, uiStore }),
        })
        uiStore.openContextMenu(array, e)
      },
      [uiStore]
    )

    const onTrackMouseOver = React.useCallback(() => {
      setIsHovering(true)
      enableHighlight()
    }, [enableHighlight])

    const onTrackMouseOut = React.useCallback(() => {
      setIsHovering(false)
      disableHighlight()
    }, [disableHighlight])

    return (
      <LayerTrack
        isHighlighted={isHighlighted}
        color={highlightColor}
        highlightColor={color}
        maskColor={maskColor}
        showContextMenu={showContextMenu}
        onClick={onTrackClick}
        onMouseOver={(e) => {
          onTrackMouseOver()
          dndHandlers.onMouseOver(e, layer)
        }}
        onMouseOut={(e) => {
          onTrackMouseOut()
          dndHandlers.onMouseOut(e, layer)
        }}
        onMouseDown={(e) => {
          dndHandlers.onMouseDown(e, layer)
        }}
        onMouseMove={(e) => {
          dndHandlers.onMouseMove(e, layer)
        }}
        onMouseUp={(e) => {
          dndHandlers.onMouseUp(e, layer)
        }}
        isDropTarget={isEntityDropTarget(layer)}
        dropZoneIndent={getDropZoneIndent(indent)}
        memo={[
          childrenExpanded,
          hasChildren,
          propertiesExpanded,
          hasAnimatedProperties,
          layerLocked,
          layerVisible,
          isHovering,
          type,
          name,
          isRoot,
        ]}
      >
        <div className={styles.left}>
          {R.range(0, indent || 0).map((_, idx) => (
            <Spacer key={idx} />
          ))}

          <LayerMask type={maskType} isRoot={isRoot} />
          <ExpandChildren
            expanded={childrenExpanded}
            hasChildren={hasChildren}
            onExpand={onExpandChildren}
          />
          <LayerColorInput color={color} onChange={onColorUpdate} />
          <ExpandProperties
            expanded={propertiesExpanded}
            hasAnimatedProperties={hasAnimatedProperties}
            onExpand={onExpandPropertiesClick}
          />

          <LayerIcon type={type} />
          <LayerName name={name} onChange={onNameChange} />
        </div>

        <div className={styles.right}>
          <LayerLock
            isHovering={isHovering}
            locked={layerLocked}
            onToggle={onLockToggle}
          />

          <LayerVisibility
            isHovering={isHovering}
            visible={layerVisible}
            onToggle={onVisibilityToggle}
          />

          {/* @TODO: implement solo in the next release */}
          {/* <div
          className={classnames(styles.component, {
            [styles['component--hidden']]:
              isHovering === false && soloType === NodeSoloType.None,
          })}
        >
          <buttons.Icon onClick={onSoloClick} size={iconButtonSize}>
            <icons.Solo size={iconSize} type={soloType} />
          </buttons.Icon>
        </div> */}

          <div className={styles.spacer}>
            <span style={{ width: 4 }} />
          </div>
        </div>
      </LayerTrack>
    )
  }
)

LayerEditable.displayName = 'LayerEditable'

const LayerDisplay: React.FC<LayerProps> = React.memo(
  ({
    color,
    highlightColor,
    maskColor,
    indent,
    maskType,
    childrenExpanded,
    hasChildren,
    propertiesExpanded,
    hasAnimatedProperties,
    layerLocked,
    layerVisible,
    type,
    name,
    isRoot,
  }) => {
    return (
      <LayerTrack
        isHighlighted={false}
        color={highlightColor}
        highlightColor={color}
        maskColor={maskColor}
        showContextMenu={() => {}}
        onClick={() => {}}
        onMouseOver={() => {}}
        onMouseOut={() => {}}
        isDropTarget={false}
        dropZoneIndent={0}
        onMouseDown={() => {}}
        onMouseMove={() => {}}
        onMouseUp={() => {}}
        memo={[
          childrenExpanded,
          hasChildren,
          propertiesExpanded,
          hasAnimatedProperties,
          layerLocked,
          layerVisible,
          type,
          name,
          isRoot,
        ]}
      >
        <div className={styles.left}>
          {R.range(0, indent || 0).map((spacer) => (
            <div key={spacer} className={styles.spacer}>
              <span style={{ width: 16 }} />
            </div>
          ))}

          <LayerMask type={maskType} isRoot={isRoot} />
          <ExpandChildren
            expanded={childrenExpanded}
            hasChildren={hasChildren}
            onExpand={() => {}}
          />
          <LayerColorInput color={color} onChange={() => {}} />
          <ExpandProperties
            expanded={propertiesExpanded}
            hasAnimatedProperties={hasAnimatedProperties}
            onExpand={() => {}}
          />

          <LayerIcon type={type} />
          <LayerName name={name} onChange={() => {}} />
        </div>

        <div className={styles.right}>
          <LayerLock
            isHovering={false}
            locked={layerLocked}
            onToggle={() => {}}
          />

          <LayerVisibility
            isHovering={false}
            visible={layerVisible}
            onToggle={() => {}}
          />

          <div className={styles.spacer}>
            <span style={{ width: 4 }} />
          </div>
        </div>
      </LayerTrack>
    )
  },
  (prev, next) => {
    if (prev.childrenExpanded !== next.childrenExpanded) return false
    if (prev.propertiesExpanded !== next.propertiesExpanded) return false
    if (prev.hasChildren !== next.hasChildren) return false
    if (prev.layerLocked !== next.layerLocked) return false
    if (prev.layerVisible !== next.layerVisible) return false
    if (prev.hasAnimatedProperties !== next.hasAnimatedProperties) return false
    if (prev.isRoot !== next.isRoot) return false
    if (prev.indent !== next.indent) return false
    if (prev.color !== next.color) return false
    if (prev.highlightColor !== next.highlightColor) return false
    if (prev.maskColor !== next.maskColor) return false
    if (prev.maskType !== next.maskType) return false
    if (prev.type !== next.type) return false
    if (prev.name !== next.name) return false
    return true
  }
)

LayerDisplay.displayName = 'LayerDisplay'

const LayerTrack: React.FC<{
  isHighlighted: boolean
  color: string
  highlightColor: string
  maskColor: string
  children: React.ReactNode
  isDropTarget: boolean
  dropZoneIndent: number
  showContextMenu: (e: React.MouseEvent<Element, MouseEvent>) => void
  onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseOver: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseOut: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseDown: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseMove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseUp: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  memo: (string | boolean)[]
}> = React.memo(
  ({
    isHighlighted,
    color,
    maskColor,
    children,
    highlightColor,
    isDropTarget,
    dropZoneIndent,
    showContextMenu,
    onClick,
    onMouseOver,
    onMouseOut,
    onMouseDown,
    onMouseMove,
    onMouseUp,
  }) => {
    return (
      <TimelineTrack
        isHighlighted={isHighlighted}
        isDropTarget={isDropTarget}
        onClick={onClick}
        className={styles.container}
        alignment="center"
        highlightColor={highlightColor}
        color={color}
        maskColor={maskColor}
        onContextMenu={showContextMenu}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
        onMouseOver={onMouseOver}
        onMouseOut={onMouseOut}
        highlightIndent={dropZoneIndent}
      >
        {children}
      </TimelineTrack>
    )
  },
  (prev, next) => {
    if (prev.isHighlighted !== next.isHighlighted) return false
    if (prev.isDropTarget !== next.isDropTarget) return false
    if (prev.dropZoneIndent !== next.dropZoneIndent) return false
    if (prev.color !== next.color) return false
    if (prev.highlightColor !== next.highlightColor) return false
    if (prev.maskColor !== next.maskColor) return false
    for (let i = 0; i < prev.memo.length; i++) {
      if (prev.memo[i] !== next.memo[i]) return false
    }
    return true
  }
)

LayerTrack.displayName = 'LayerTrack'

const LayerMask: React.FC<{ type: LayerMaskType; isRoot: boolean }> =
  React.memo(
    ({ type, isRoot }) => {
      if (isRoot) return null

      return (
        <>
          {type !== 'none' ? (
            <div
              className={classNames(styles.mask, {
                [styles[`mask__${type}`]]: type !== 'in-scope',
              })}
            />
          ) : (
            <div className={styles.spacer}>
              <span style={{ width: 16 }} />
            </div>
          )}
        </>
      )
    },
    (prev, next) => {
      if (prev.type !== next.type) return false
      if (prev.isRoot !== next.isRoot) return false
      return true
    }
  )

LayerMask.displayName = 'LayerMask'

const ExpandChildren: React.FC<{
  expanded: boolean
  hasChildren: boolean
  onExpand: React.MouseEventHandler<Element>
}> = React.memo(
  ({ expanded, hasChildren, onExpand }) => {
    if (!hasChildren)
      return (
        <div className={styles.spacer}>
          <span style={{ width: 16 }} />
        </div>
      )

    return (
      <div className={styles.component}>
        <buttons.Icon onClick={onExpand} btnSize={iconButtonSize}>
          <icons.ExpandChildren
            size={iconSize}
            type={
              expanded ? NodeExpandType.ChildrenVisible : NodeExpandType.Normal
            }
          />
        </buttons.Icon>
      </div>
    )
  },
  (prev, next) => {
    if (prev.expanded !== next.expanded) return false
    if (prev.hasChildren !== next.hasChildren) return false
    return true
  }
)

ExpandChildren.displayName = 'ExpandChildren'

const ExpandProperties: React.FC<{
  expanded: boolean
  hasAnimatedProperties: boolean
  onExpand: React.MouseEventHandler<Element>
}> = React.memo(
  ({ expanded, hasAnimatedProperties, onExpand }) => {
    if (!hasAnimatedProperties)
      return (
        <div className={styles.spacer}>
          <span style={{ width: 16 }} />
        </div>
      )

    return (
      <div className={styles.component}>
        <buttons.Icon onClick={onExpand} btnSize={iconButtonSize}>
          <icons.ExpandProperties
            size={iconSize}
            type={
              expanded
                ? NodeExpandType.PropertiesVisible
                : NodeExpandType.Normal
            }
          />
        </buttons.Icon>
      </div>
    )
  },
  (prev, next) => {
    if (prev.expanded !== next.expanded) return false
    if (prev.hasAnimatedProperties !== next.hasAnimatedProperties) return false
    return true
  }
)

ExpandProperties.displayName = 'ExpandProperties'

const LayerIcon: React.FC<{ type: LayerType }> = React.memo(
  ({ type }) => {
    return (
      <div className={styles.component}>
        <icons.Layer type={type} size={iconSize} />
      </div>
    )
  },
  (prev, next) => {
    if (prev.type !== next.type) return false
    return true
  }
)

LayerIcon.displayName = 'LayerIcon'

const LayerColorInput: React.FC<{
  color: string
  onChange: (color: LayerColor) => void
}> = React.memo(
  ({ color, onChange }) => {
    const [anchorEl, setAnchorEl] = React.useState<Element | null>(null)

    const selectorColors = React.useMemo(
      () =>
        R.values(LayerColor).map((id) => ({
          id,
          name: id.toLowerCase(),
          value: defaults.nodeColors[id],
        })),
      []
    )

    const onOpen = React.useCallback(
      (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        event.preventDefault()
        setAnchorEl(event.currentTarget)
      },
      []
    )

    const onClose = React.useCallback(() => {
      setAnchorEl(null)
    }, [])

    return (
      <>
        <div className={styles.component}>
          <button
            type="button"
            className={styles['color-button']}
            onClick={onOpen}
          >
            <span
              className={styles.color}
              style={{
                backgroundColor: color,
              }}
            />
          </button>
        </div>

        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          disableRestoreFocus
        >
          <ColorSelector
            color={color}
            onSelect={(color) => {
              onChange(color.toUpperCase() as LayerColor)
              onClose()
            }}
            colors={selectorColors}
          />
        </Popover>
      </>
    )
  },
  (prev, next) => {
    if (prev.color !== next.color) return false
    return true
  }
)

LayerColorInput.displayName = 'LayerColorInput'

const LayerLock = React.memo(
  ({
    isHovering,
    locked,
    onToggle,
  }: {
    isHovering: boolean
    locked: boolean
    onToggle: React.MouseEventHandler<Element>
  }) => {
    return (
      <div
        className={classNames(styles.component, {
          [styles['component--hidden']]: isHovering === false && !locked,
        })}
      >
        <buttons.Icon onClick={onToggle} btnSize={iconButtonSize}>
          <icons.Lock size={iconSize} locked={locked} />
        </buttons.Icon>
      </div>
    )
  },
  (prev, next) => {
    if (prev.isHovering !== next.isHovering) return false
    if (prev.locked !== next.locked) return false
    return true
  }
)

LayerLock.displayName = 'LayerLock'

const LayerVisibility = React.memo(
  ({
    isHovering,
    visible,
    onToggle,
  }: {
    isHovering: boolean
    visible: boolean
    onToggle: React.MouseEventHandler<Element>
  }) => {
    return (
      <div
        className={classNames(styles.component, {
          [styles['component--hidden']]: isHovering === false && visible,
        })}
      >
        <buttons.Icon onClick={onToggle} btnSize={iconButtonSize}>
          <icons.Visible size={iconSize} visible={visible} />
        </buttons.Icon>
      </div>
    )
  },
  (prev, next) => {
    if (prev.isHovering !== next.isHovering) return false
    if (prev.visible !== next.visible) return false
    return true
  }
)

LayerVisibility.displayName = 'LayerVisibility'

const LayerName = React.memo(
  ({ name, onChange }: { name: string; onChange: (name: string) => void }) => {
    const nameRef = React.useRef<HTMLInputElement | null>(null)
    const [isNameFocused, setIsNameFocused] = React.useState<boolean>(false)
    const [tempName, setTempName] = React.useState<string>(name)

    React.useEffect(() => {
      if (isNameFocused && nameRef.current) {
        nameRef.current.focus()
        nameRef.current.select()
      }
    }, [isNameFocused])

    return (
      <div
        className={styles['name-wrapper']}
        onDoubleClick={() => setIsNameFocused(true)}
      >
        <p
          className={classNames(styles.name, {
            [styles['name--visible']]: !isNameFocused,
          })}
        >
          {trimString(48, name)}
        </p>

        <div
          className={classNames(styles['name-input-wrapper'], {
            [styles['name-input-wrapper--visible']]: isNameFocused,
          })}
        >
          <InputTimeline
            value={tempName}
            onChange={setTempName}
            fullWidth
            ref={nameRef}
            className={styles['name-input']}
            onBlur={() => {
              onChange(tempName)
              setIsNameFocused(false)
            }}
            onEnterPress={() => {
              onChange(tempName)
              setIsNameFocused(false)
            }}
            onEscPress={() => {
              setIsNameFocused(false)
              setTempName(name)
            }}
            fontSize="medium"
          />
        </div>
      </div>
    )
  },
  (prev, next) => {
    if (prev.name !== next.name) return false
    return true
  }
)

LayerName.displayName = 'LayerName'
