import {
  Entity,
  NameComponent,
  NodeTypeComponent,
  StrokeAlign,
  StrokeAlignComponent,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
  isIndividualStrokesAvailable,
  isIndividualStrokesEnabled,
  isTrimPathEnabled,
} from '@aninix-inc/model'
import { DataDrawer, getDataMap } from '@aninix-inc/renderer'
import * as R from 'ramda'
import { ArrowHeads } from './properties/arrow-heads'
import { Base } from './properties/base'
import { EmptyGroup } from './properties/empty-group'
import { PathProperties, Paths } from './properties/paths'
import { Property } from './properties/property'
import { ShapeGroup } from './properties/shape-group'
import { Strokes } from './properties/strokes'
import {
  LottieLayerType,
  LottieMaskMode,
  LottieShapeLayer,
  LottieShapeLayerType,
  LottieTrimMultipleShapesType,
} from './types'

/**
 * @description extract strokes from node and return layers with masks.
 * Required for inner/outer strokes
 */
export function StrokeLayers(payload: {
  node: Entity
  parent?: number
}): LottieShapeLayer[] {
  const { node, parent } = payload

  // @ts-ignore
  const getData = getDataMap[
    node.getComponentOrThrow(NodeTypeComponent).value
  ] as DataDrawer
  const data = getData({ entity: node, time: 0 })
  const isClosed = R.all((path) => path.data.toLowerCase().includes('z'), data)

  const strokeAlign =
    isClosed === false
      ? StrokeAlign.Center
      : node.getComponentOrThrow(StrokeAlignComponent).value

  const baseProperties = Base({ node, skipAnimation: true })
  const pathProperties = PathProperties({ node })

  // @NOTE: required just for render check
  if (Strokes({ node }).length === 0) {
    return []
  }

  let finalLayer: LottieShapeLayer

  if (isIndividualStrokesAvailable(node) && isIndividualStrokesEnabled(node)) {
    finalLayer = {
      ...baseProperties,
      nm: `${node.getComponentOrThrow(NameComponent).value} - Stroke`,
      ty: LottieLayerType.Shape,
      shapes: [
        ShapeGroup([
          ...Paths({ node, type: 'top' }),
          ...Strokes({
            node,
            multiplyBy: strokeAlign === StrokeAlign.Center ? 1 : 2,
            type: 'top',
          }),
        ]),
        ShapeGroup([
          ...Paths({ node, type: 'right' }),
          ...Strokes({
            node,
            multiplyBy: strokeAlign === StrokeAlign.Center ? 1 : 2,
            type: 'right',
          }),
        ]),
        ShapeGroup([
          ...Paths({ node, type: 'bottom' }),
          ...Strokes({
            node,
            multiplyBy: strokeAlign === StrokeAlign.Center ? 1 : 2,
            type: 'bottom',
          }),
        ]),
        ShapeGroup([
          ...Paths({ node, type: 'left' }),
          ...Strokes({
            node,
            multiplyBy: strokeAlign === StrokeAlign.Center ? 1 : 2,
            type: 'left',
          }),
        ]),
      ].filter((layer) => layer != null),
      parent,
    }
  } else {
    const paths = Paths({ node })
    const strokePaints = Strokes({
      node,
      multiplyBy: strokeAlign === StrokeAlign.Center ? 1 : 2,
    })
    finalLayer = {
      ...baseProperties,
      nm: `${node.getComponentOrThrow(NameComponent).value} - Stroke`,
      ty: LottieLayerType.Shape,
      shapes: [ShapeGroup([...paths, ...strokePaints])],
      parent,
    }
  }

  if (isTrimPathEnabled(node)) {
    finalLayer.shapes.push({
      ty: LottieShapeLayerType.Trim,
      s: Property({
        property: node.getComponentOrThrow(TrimStartComponent),
        mapper: ({ value, isAnimated }) => {
          if (isAnimated) {
            return [value * 100]
          }

          return value * 100
        },
      }),
      e: Property({
        property: node.getComponentOrThrow(TrimEndComponent),
        mapper: ({ value, isAnimated }) => {
          if (isAnimated) {
            return [value * 100]
          }

          return value * 100
        },
      }),
      o: Property({
        property: node.getComponentOrThrow(TrimOffsetComponent),
        mapper: ({ value, isAnimated }) => {
          if (isAnimated) {
            return [value * 360]
          }

          return value * 360
        },
      }),
      // m: LottieTrimMultipleShapesType.Simultaneously,
      m: LottieTrimMultipleShapesType.Individually,
    })
  }

  // @TODO: add some rule here
  if ((node as any).strokeCapStart !== (node as any).strokeCapEnd) {
    finalLayer.shapes.push(...ArrowHeads({ node: node as any }))
  }

  const layerWithTrimPathFix = {
    ...finalLayer,
    // @NOTE: empty fill required to properly handle issues with cut shapes when used trim path in web view
    shapes: [...finalLayer.shapes, EmptyGroup(node)],
  }

  if (strokeAlign === StrokeAlign.Center) {
    return [layerWithTrimPathFix]
  }

  return [
    {
      ...layerWithTrimPathFix,
      hasMask: true,
      masksProperties: pathProperties.map((pathProperty) => ({
        nm: 'Mask',
        pt: pathProperty,
        o: {
          a: 0,
          k: 100,
        },
        mode:
          node.getComponentOrThrow(StrokeAlignComponent).value ===
          StrokeAlign.Inside
            ? LottieMaskMode.Add
            : LottieMaskMode.Subtract,
        x: {
          a: 0,
          k: 0,
        },
      })),
    },
  ]
}
