import { useCases, usePlayback } from '@aninix/core'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useProject, useTools, useViewport } from '../../../../stores'
import { CommentsWrapper } from '../../../comments-wrapper'
import { Viewport } from '../../../viewport'
import { Controls } from './controls'
import { Zoom } from './zoom'

export interface IProps {
  rightRatio?: number
  leftRatio?: number
  zoom?: () => void
}

export const Player: React.FCC<IProps> = observer(
  ({ leftRatio = 1, rightRatio = 0, zoom }) => {
    const playback = usePlayback()
    const viewport = useViewport()
    const tools = useTools()
    const project = useProject()

    useCases.usePlaybackHotkeys({
      tools,
      playback,
      project,
      viewport,
      // @TODO: implement analytics
      onKeyPress: console.log,
    })
    useCases.useViewportHotkeys({
      project,
      viewport,
      // @TODO: implement analytics
      onKeyPress: console.log,
    })

    // const handleNodesSelection = React.useCallback(
    //   (nodes: Node[]) => {
    //     session.deselectAll()

    //     nodes.forEach((node) => {
    //       session.selectKeyframes(node.getSortedKeyframes())
    //     })
    //   },
    //   [session]
    // )

    // React.useEffect(() => {
    //   const position = project.rootNode.position.getValue(0)
    //   const size = project.rootNode.size.getValue(0)

    //   const padding = 100

    //   // @NOTE: zoom to fit
    //   viewport.zoomToRect({
    //     x: position.x - padding / 2,
    //     y: position.y - padding / 2,
    //     width: size.x + padding,
    //     height: size.y + padding,
    //   })
    // }, [])

    const { inspectMode } = useParams()

    const isShowingComments = React.useMemo(() => {
      return inspectMode === 'comments' || inspectMode === 'details'
    }, [inspectMode])

    return (
      <div className="relative h-full w-full bg-[var(--background-white-2)]">
        <CommentsWrapper isShowingComments={isShowingComments}>
          <Viewport project={project} editable={false} />
        </CommentsWrapper>

        <div
          className="absolute bottom-4 flex h-[30px] w-full flex-row justify-center px-1 sm:px-4"
          style={{ paddingRight: rightRatio * 100 + '%' }}
        >
          <div className="mx-auto flex w-full min-w-[350px] max-w-[600px] flex-row justify-center gap-2">
            <Controls project={project} />
            <Zoom zoom={zoom} />
          </div>
        </div>
      </div>
    )
  }
)

Player.displayName = 'Player'
