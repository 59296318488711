import React from 'react'

const defaultSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const KeyBack: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M8 5.99954L1 5.99954M1 5.99954L2.9996 7.99914M1 5.99954L3 3.99997M4 2.99997L5.29289 1.70708C5.68342 1.31655 6.31658 1.31655 6.70711 1.70708L10.2929 5.29287C10.6834 5.68339 10.6834 6.31655 10.2929 6.70708L6.70711 10.2929C6.31658 10.6834 5.68342 10.6834 5.29289 10.2929L4 8.99997"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
