import * as React from 'react'

const defaultSize = {
  x: 24,
  y: 24,
}

export interface IProps {
  size?: { x: number; y: number }
}
export const ShareLinkV2: React.FCC<IProps> = ({ size = defaultSize }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <path
        d="M14.4998 9.5L9.49982 14.5"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.3"
        d="M11.0001 6.5L11.7501 5.75C13.545 3.95507 16.4552 3.95508 18.2501 5.75V5.75C20.045 7.54492 20.045 10.4551 18.2501 12.25L17.5001 13"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        opacity="0.3"
        d="M13.0021 17.5022L12.25 18.2535C10.4548 20.0468 7.546 20.046 5.7518 18.2517V18.2517C3.95626 16.4561 3.95707 13.5447 5.75362 11.7501L6.50235 11.0022"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
)
