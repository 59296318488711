import { useGetSpaceBadge } from '@aninix/api'
import * as React from 'react'

import { UserItem, UserItemPlaceholder } from './user-item'

interface IProps {
  activeAccount: string
  tokens: string[]
}

export const CurrentUser: React.FCC<IProps> = ({ activeAccount, tokens }) => {
  const getSpaceBadge = useGetSpaceBadge({ tokens })

  const currentUser = getSpaceBadge.data?.infoFromTokens.users.filter(
    (u) => u.id === activeAccount
  )[0]

  if (getSpaceBadge.isLoading) {
    return <UserItemPlaceholder />
  }

  if (!currentUser) {
    return null
  }

  const { id, avatarUrl, name, plan, teams } = currentUser

  return (
    <UserItem
      key={id}
      id={id}
      name={name}
      avatarUrl={avatarUrl}
      plan={plan}
      isPro={plan === 'pro'}
    />
  )
}

CurrentUser.displayName = 'CurrentUser'
