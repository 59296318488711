import type { Point2D } from '@aninix-inc/model/legacy'

import { getPointOnVector } from './get-point-on-vector'
import { getVectorMagnitude } from './get-vector-magnitude'
import { BezierSegment, Vector } from './types'

class ValidationError extends Error {}

interface IPayload {
  vertices: Point2D[]
  cornerRadius: number
  // in range 0...1
  cornerSmoothing: number
  isClosed?: boolean
}
/**
 * @description convert given array of vertices, cornerRadius and cornerSmoothing
 * to array of bezierSegments which later can be used to build smoothed shapes
 */
export function mapVerticesToBezierSegments({
  vertices,
  cornerRadius,
  cornerSmoothing,
  isClosed = true,
}: IPayload): BezierSegment[] {
  if (cornerSmoothing < 0 || cornerSmoothing > 1) {
    throw new ValidationError('CornerSmoothing should be in range 0...1')
  }

  const segments: BezierSegment[] = []
  const points = isClosed ? [...vertices, vertices[0], vertices[1]] : vertices

  for (let i = 1; i < points.length - 1; i += 1) {
    const p1 = points[i - 1]
    const p2 = points[i]
    const p3 = points[i + 1]

    const left: Vector = {
      start: p1,
      end: p2,
    }
    const right: Vector = {
      start: p2,
      end: p3,
    }

    const leftMagnitude = getVectorMagnitude(left)
    const leftProgress = cornerRadius / leftMagnitude
    const rightMagnitude = getVectorMagnitude(right)
    const rightProgress = cornerRadius / rightMagnitude

    const a1 = getPointOnVector({
      vector: left,
      progress: leftProgress,
    })
    const a2 = getPointOnVector({
      vector: left,
      progress: 1 - leftProgress,
    })
    const a3 = getPointOnVector({
      vector: right,
      progress: rightProgress,
    })

    const a1a2: BezierSegment = {
      start: a1,
      startTangent: a1,
      end: a2,
      endTangent: a2,
    }

    const a2a3: BezierSegment = {
      start: a2,
      startTangent: p2,
      end: a3,
      endTangent: p2,
    }

    segments.push(a1a2)
    segments.push(a2a3)
  }

  return segments
}
