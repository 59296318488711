import { useMutation } from '@tanstack/react-query'

import { ApiPostParams } from './helpers'
import { httpClient } from './http-client'

export function useAcceptUserInviteToTeam() {
  return useMutation({
    async mutationFn(
      payload: ApiPostParams<'/teams/{teamId}/members/{userId}/accept-invite'>['path']
    ) {
      return httpClient.post(
        `/teams/${payload.teamId}/members/${payload.userId}/accept-invite`,
        undefined
      )
    },
  })
}
