import {
  PaintType,
  Point1D,
  Point2D,
  SpatialPoint2D,
  decomposedMatrix,
  lutByEqualLength,
  round,
  tFromProgressByLut,
} from '@aninix-inc/model/legacy'
import * as R from 'ramda'

import {
  DurationComponent,
  Entity,
  NodeTypeComponent,
  Root,
  StrokeAlignComponent,
  StrokeCapEndComponent,
  StrokeCapStartComponent,
  StrokesAspect,
  StrokesRelationsAspect,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import { getDataMap, paper } from '@aninix-inc/renderer'
import {
  ArrowHeadData,
  getArrowHeadsData,
} from '../../../../modules/common/renderers/get-arrow-heads-data'
import {
  bezierPointsToLottiePoints,
  svgPathToBezierPoints,
} from '../../../../vector-helpers'
import {
  LottieKeyframe,
  LottieShapeGroup,
  LottieShapeLayerType,
} from '../types'
import { Fill } from './fills'
import { ShapeGroup } from './shape-group'

type TimingCurve = {
  out: {
    x: number
    y: number
  }
  in: {
    x: number
    y: number
  }
}

type PositionKeyframe = {
  value: SpatialPoint2D
  timingCurve: TimingCurve
  /**
   * progress in range 0...1 along path
   */
  progress: number
}

type RotationKeyframe = {
  value: Point1D
  timingCurve: TimingCurve
  /**
   * progress in range 0...1 along path
   */
  progress: number
}

function positionKeyframesFromSvgPath(
  svgPath: string,
  options?: {
    reversed: true
  }
): PositionKeyframe[] {
  const reversed = options?.reversed ?? false
  const path = new paper.Path(svgPath)

  if (reversed) {
    path.reverse()
  }

  return path.segments.map((segment) => ({
    value: {
      x: round(segment.point.x),
      y: round(segment.point.y),
      tx1: round(segment.handleIn.x),
      tx2: round(segment.handleOut.x),
      ty1: round(segment.handleIn.y),
      ty2: round(segment.handleOut.y),
    },
    timingCurve: {
      out: { x: 0, y: 0 },
      in: { x: 1, y: 1 },
    },
    progress: round(path.getLocationOf(segment.point).offset / path.length),
  }))
}

/**
 *
 * @returns angle in degrees
 */
function autoOrientRotationKeyframesFromSvgPath(
  svgPath: string,
  options?: {
    /**
     * How often keyframe should be created in range 0...1 in each segment
     * @default 0.1
     */
    precision?: number
    reversed?: boolean
  }
): RotationKeyframe[] {
  const precision = options?.precision ?? 0.5
  const reversed = options?.reversed ?? false
  const path = new paper.Path(svgPath)

  if (reversed) {
    path.reverse()
  }

  const rotationKeyframes: RotationKeyframe[] = []
  const totalLength = path.length
  let lengthFromStart = 0

  for (const segment of R.dropLast(1, path.segments)) {
    const segmentLength = segment.curve.length
    const sampleLength = segmentLength * precision

    for (
      let localProgress = 0;
      localProgress < segmentLength;
      localProgress += sampleLength
    ) {
      const tangent = path.getTangentAt(lengthFromStart + localProgress)

      const lastKeyframe = rotationKeyframes[rotationKeyframes.length - 1]

      // @NOTE: check if angle was flipped
      if (
        lastKeyframe != null &&
        Math.sign(lastKeyframe.value.x) !== 0 &&
        Math.sign(tangent.angle) !== 0 &&
        Math.sign(lastKeyframe.value.x) !== Math.sign(tangent.angle)
      ) {
        rotationKeyframes.push({
          value: {
            x: -lastKeyframe.value.x,
          },
          timingCurve: {
            out: { x: 0, y: 0 },
            in: { x: 1, y: 1 },
          },
          progress: lastKeyframe.progress,
        })
      }

      rotationKeyframes.push({
        value: {
          x: round(tangent.angle),
        },
        timingCurve: {
          out: { x: 0, y: 0 },
          in: { x: 1, y: 1 },
        },
        progress: round((lengthFromStart + localProgress) / totalLength),
      })
    }

    lengthFromStart += segment.curve.length
  }

  rotationKeyframes.push({
    value: {
      x: round(path.getTangentAt(path.length).angle),
    },
    timingCurve: {
      out: { x: 0, y: 0 },
      in: { x: 1, y: 1 },
    },
    progress: 1,
  })

  return rotationKeyframes
}

function normalizedTimingCurve(payload: {
  start: Point2D
  outPoint: Point2D
  inPoint: Point2D
  end: Point2D
}): TimingCurve {
  const { start, end, outPoint, inPoint } = payload

  const minX = start.x
  const maxX = end.x
  const minY = start.y
  const maxY = end.y

  return {
    out: {
      x: round((outPoint.x - minX) / (maxX - minX)),
      y: round((outPoint.y - minY) / (maxY - minY)),
    },
    in: {
      x: round((inPoint.x - minX) / (maxX - minX)),
      y: round((inPoint.y - minY) / (maxY - minY)),
    },
  }
}

/**
 * @param keyframes array of numbers in range 0...1
 */
function distributeKeyframesAlongTimeCurve<
  T extends { timingCurve: TimingCurve; progress: number },
>(keyframes: T[], timingCurve: TimingCurve): T[] {
  const lutByLength = lutByEqualLength({
    start: {
      x: timingCurve.out.x,
      y: timingCurve.out.y,
    },
    end: {
      x: timingCurve.in.x,
      y: timingCurve.in.y,
    },
  })
  const path = new paper.Path([
    new paper.Segment(
      new paper.Point(0, 0),
      new paper.Point(0, 0),
      new paper.Point(timingCurve.out.x, timingCurve.out.y)
    ),
    new paper.Segment(
      new paper.Point(1, 1),
      new paper.Point(timingCurve.in.x - 1, timingCurve.in.y - 1),
      new paper.Point(0, 0)
    ),
  ])

  const keyframesWithNewProgress = keyframes.map((keyframe) => ({
    ...keyframe,
    progress: round(tFromProgressByLut(keyframe.progress, lutByLength)),
  }))

  keyframesWithNewProgress.forEach((keyframe) => {
    path.divideAt(keyframe.progress * path.length)
  })

  let keyframesWithNewTimingCurve: T[] = []

  // @TODO: simplify
  for (let i = 0; i < keyframesWithNewProgress.length; i += 1) {
    const isFirst = i === 0
    const isLast = i === keyframesWithNewProgress.length - 1

    const keyframe = keyframesWithNewProgress[i]
    const segment = path.segments.find((segment) => {
      const progressOnPath = round(
        path.getLocationOf(segment.point).offset / path.length
      )
      return progressOnPath === keyframe.progress
    })!

    if (isFirst) {
      const nextKeyframe = keyframesWithNewProgress[i + 1]
      const nextSegment = path.segments.find((segment) => {
        const progressOnPath = round(
          path.getLocationOf(segment.point).offset / path.length
        )
        return progressOnPath === nextKeyframe.progress
      })!

      const newTimingCurve = normalizedTimingCurve({
        start: segment.point,
        outPoint: {
          x: round(segment.handleOut.x + segment.point.x),
          y: round(segment.handleOut.y + segment.point.y),
        },
        inPoint: {
          x: round(nextSegment.handleIn.x + nextSegment.point.x),
          y: round(nextSegment.handleIn.y + nextSegment.point.y),
        },
        end: nextSegment.point,
      })

      keyframesWithNewTimingCurve.push({
        ...keyframe,
        timingCurve: {
          out: newTimingCurve.out,
          in: { x: 1, y: 1 },
        },
      })
      continue
    }

    if (isLast) {
      const prevKeyframe = keyframesWithNewProgress[i - 1]
      const prevSegment = path.segments.find((segment) => {
        const progressOnPath = round(
          path.getLocationOf(segment.point).offset / path.length
        )
        return progressOnPath === prevKeyframe.progress
      })!

      const newTimingCurve = normalizedTimingCurve({
        start: prevSegment.point,
        outPoint: {
          x: round(prevSegment.handleOut.x + prevSegment.point.x),
          y: round(prevSegment.handleOut.y + prevSegment.point.y),
        },
        inPoint: {
          x: round(segment.handleIn.x + segment.point.x),
          y: round(segment.handleIn.y + segment.point.y),
        },
        end: segment.point,
      })

      keyframesWithNewTimingCurve.push({
        ...keyframe,
        timingCurve: {
          out: { x: 0, y: 0 },
          in: newTimingCurve.in,
        },
      })
      continue
    }

    const prevKeyframe = keyframesWithNewProgress[i - 1]
    const nextKeyframe = keyframesWithNewProgress[i + 1]
    const prevSegment = path.segments.find((segment) => {
      const progressOnPath = round(
        path.getLocationOf(segment.point).offset / path.length
      )
      return progressOnPath === prevKeyframe.progress
    })!
    const nextSegment = path.segments.find((segment) => {
      const progressOnPath = round(
        path.getLocationOf(segment.point).offset / path.length
      )
      return progressOnPath === nextKeyframe.progress
    })!

    const prevTimingCurve = normalizedTimingCurve({
      start: prevSegment.point,
      outPoint: {
        x: round(prevSegment.handleOut.x + prevSegment.point.x),
        y: round(prevSegment.handleOut.y + prevSegment.point.y),
      },
      inPoint: {
        x: round(segment.handleIn.x + segment.point.x),
        y: round(segment.handleIn.y + segment.point.y),
      },
      end: segment.point,
    })
    const nextTimingCurve = normalizedTimingCurve({
      start: segment.point,
      outPoint: {
        x: round(segment.handleOut.x + segment.point.x),
        y: round(segment.handleOut.y + segment.point.y),
      },
      inPoint: {
        x: round(segment.handleIn.x + segment.point.x),
        y: round(segment.handleIn.y + segment.point.y),
      },
      end: nextSegment.point,
    })

    keyframesWithNewTimingCurve.push({
      ...keyframe,
      timingCurve: {
        out: nextTimingCurve.out,
        in: prevTimingCurve.in,
      },
    })
  }

  return keyframesWithNewTimingCurve
}

/// ^^^ @NOTE: check if there some usable functions. If not then just remove code.

const SAMPLES_PER_SECOND = 100
const MIN_POSITION_DELTA = 1
const MIN_ROTATION_DELTA = 1

function defaultPredicate<T>(
  left: LottieKeyframe<T>,
  right: LottieKeyframe<T>
): boolean {
  return left.t === right.t && R.equals(left.s, right.s)
}
function optimizedLottieKeyframes<T>(
  keyframes: LottieKeyframe<T>[],
  predicate: (
    left: LottieKeyframe<T>,
    right: LottieKeyframe<T>
  ) => boolean = defaultPredicate
): LottieKeyframe<T>[] {
  const newKeyframes: LottieKeyframe<T>[] = [keyframes[0]]

  for (let i = 1; i < keyframes.length; i += 1) {
    const prevKeyframe = keyframes[i - 1]
    const keyframe = keyframes[i]

    if (i === keyframes.length - 1) {
      newKeyframes.push(keyframe)
    }

    if (predicate(prevKeyframe, keyframe)) {
      continue
    }

    newKeyframes.push(keyframe)
  }

  return newKeyframes
}

export function ArrowHeads(payload: { node: Entity }): LottieShapeGroup[] {
  const project = payload.node.getProjectOrThrow()
  const root = project.getEntityByTypeOrThrow(Root)
  const entity = payload.node
  const getData =
    getDataMap[entity.getComponentOrThrow(NodeTypeComponent).value]
  const projectDuration = root.getComponentOrThrow(DurationComponent).value

  const path = getData({ entity, time: 0 })
    .map((p) => p.data)
    .join(' ')
  const strokesAspect = entity.getAspectOrThrow(StrokesAspect)
  const initialArrowHeads = getArrowHeadsData({
    path,
    strokeAlign: entity.getComponentOrThrow(StrokeAlignComponent).value,
    strokeCapStart: entity.getComponentOrThrow(StrokeCapStartComponent).value,
    strokeCapEnd: entity.getComponentOrThrow(StrokeCapEndComponent).value,
    size: strokesAspect.strokeWeight.getValue(0),
  })

  // keyframes/arrow-heads
  let arrowHeadsMatrices: Array<
    Array<{
      frame: number
      matrix: ArrowHeadData['matrix']
    }>
  > = []

  for (let frame = 0; frame < projectDuration * 60; frame += 1) {
    const path = getData({ entity, time: frame / 60 })
      .map((p) => p.data)
      .join(' ')

    const arrowHeads = getArrowHeadsData({
      path,
      strokeAlign: entity.getComponentOrThrow(StrokeAlignComponent).value,
      strokeCapStart: entity.getComponentOrThrow(StrokeCapStartComponent).value,
      strokeCapEnd: entity.getComponentOrThrow(StrokeCapEndComponent).value,
      size: strokesAspect.strokeWeight.getValue(0),
    })

    arrowHeadsMatrices.push(
      arrowHeads.map((head) => ({
        frame,
        matrix: head.matrix,
      }))
    )
  }

  return initialArrowHeads.map((arrowHead, idx) => {
    const initialDecomposedMatrix = decomposedMatrix(arrowHead.matrix)
    const keyframesWithDecomposedMatrices = arrowHeadsMatrices
      .filter((keyframe) => keyframe[idx] != null)
      .map((keyframe) => ({
        ...keyframe[idx],
        decomposedMatrix: decomposedMatrix(keyframe[idx].matrix),
      }))

    const positionKeyframes = keyframesWithDecomposedMatrices.map(
      (keyframe, idx, array) => {
        if (idx === array.length - 1) {
          return {
            t: keyframe.frame,
            s: [
              keyframe.decomposedMatrix.translation.x,
              keyframe.decomposedMatrix.translation.y,
            ],
          }
        }

        return {
          t: keyframe.frame,
          s: [
            keyframe.decomposedMatrix.translation.x,
            keyframe.decomposedMatrix.translation.y,
          ],
          o: {
            x: [0],
            y: [0],
          },
          i: {
            x: [1],
            y: [1],
          },
        }
      }
    )

    const rotationKeyframes = keyframesWithDecomposedMatrices.map(
      (keyframe, idx, array) => {
        if (idx === array.length - 1) {
          return {
            t: keyframe.frame,
            s: [keyframe.decomposedMatrix.rotation.x],
          }
        }

        return {
          t: keyframe.frame,
          s: [keyframe.decomposedMatrix.rotation.x],
          o: {
            x: [0],
            y: [0],
          },
          i: {
            x: [1],
            y: [1],
          },
        }
      }
    )

    return ShapeGroup([
      {
        nm: 'Path',
        hd: false,
        ty: LottieShapeLayerType.Path,
        ks: {
          a: 0,
          k: bezierPointsToLottiePoints({
            regions: [
              {
                points: R.flatten(svgPathToBezierPoints(arrowHead.path)),
                isClosed: arrowHead.path.toLowerCase().includes('z'),
              },
            ],
          }),
        },
      },
      ...R.defaultTo(
        [],
        payload.node.getAspect(StrokesRelationsAspect)?.getChildrenList()
      )
        .filter((paint) => {
          // @ts-ignore
          if (paint.valueType === PaintType.Image) {
            return false
          }

          if (
            // @ts-ignore
            paint.color?.hasAnimation === false &&
            // @ts-ignore
            paint.color.value.a === 0
          ) {
            return false
          }

          if (
            paint.getComponentOrThrow(VisibleInViewportComponent).value ===
            false
          ) {
            return false
          }

          return true
        })
        .map((paint) => Fill({ node: payload.node, paint }))
        // @NOTE: for some reason we have to reverse all paints to meet the same render as we have in Figma
        .reverse(),
      {
        ty: LottieShapeLayerType.Transform,
        a: {
          a: 0,
          k: [0, 0],
        },
        p: {
          a: 1,
          k: optimizedLottieKeyframes(positionKeyframes, (left, right) => {
            const deltaX = Math.abs(left.s[0] - right.s[0])
            const deltaY = Math.abs(left.s[1] - right.s[1])
            return deltaX < MIN_POSITION_DELTA && deltaY < MIN_POSITION_DELTA
          }),
        },
        s: {
          a: 0,
          k: [
            initialDecomposedMatrix.scaling.x * 100,
            initialDecomposedMatrix.scaling.y * 100,
          ],
        },
        sk: {
          a: 0,
          k: 0,
        },
        sa: {
          a: 0,
          k: 0,
        },
        r: {
          a: 1,
          k: optimizedLottieKeyframes(rotationKeyframes, (left, right) => {
            const delta = Math.abs(left.s[0] - right.s[0])
            return delta < MIN_ROTATION_DELTA
          }),
        },
        o: {
          a: 0,
          k: 100,
        },
      },
    ])
  })
}
