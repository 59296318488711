/**
 * @todo extract useMutation and useQuery to basic requests in api
 */
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import * as React from 'react'
import { httpClient } from './http-client'
import { components, paths } from './open-api/schema'

export type Contract = {
  /**
   * idle – the contract is ready to receive operations
   * error – the contract has an error
   * loading – the contract is initially loading into memory
   * pending – the contract is waiting for an operation applied by the backend
   */
  status: 'idle' | 'loading' | 'pending' | 'error'

  /**
   * Error message exists only when `status === 'error'`
   */
  errorMessage?: string

  /**
   * List of features already included in the contract
   */
  features: string[]

  /**
   * List of features available for purchase or upgrade
   */
  availableFeaturesToPurchase: (
    | components['schemas']['ContractOneTimeFeature']
    | components['schemas']['ContractSubscriptionFeature']
  )[]

  /**
   * Activate contract with provided product
   */
  activate: (priceId: string) => Promise<unknown>

  /**
   * Upgrade contract to include provided product
   */
  upgrade: (priceId: string) => Promise<unknown>
}

const ContractContext = React.createContext<Contract>(null as any)

export const useContract = (): Contract => {
  const context = React.useContext(ContractContext)

  if (context == null) {
    throw new Error(
      'useContractContext should be used within ContractContextProvider'
    )
  }

  return context
}

export const ApiContractProvider: React.FCC<{
  contractId: string
  pollingIntervalInSeconds?: number
  pollingIterationsLimit?: number
}> = ({
  contractId,
  pollingIntervalInSeconds = 5,
  pollingIterationsLimit = 60,
  children,
}) => {
  const queryClient = useQueryClient()
  const [isPending, setIsPending] = React.useState(false)
  const [error, setError] = React.useState<string | undefined>()

  const fetchContract = React.useCallback(
    () =>
      httpClient
        .get<
          paths['/contracts/{contractId}']['get']['responses']['200']['content']['application/json']
        >(`/contracts/${contractId}`)
        .then((response) => response.data),
    [contractId]
  )

  const contract = useQuery({
    queryKey: ['contract'],
    queryFn: fetchContract,
  })

  const poll = React.useCallback(
    async (iteration = 0) => {
      try {
        if (iteration === 0) {
          setIsPending(true)
        }

        // @NOTE: exit polling if limit reached
        if (iteration > pollingIterationsLimit) {
          // @TODO: add error here so we can show timeout to the user
          setIsPending(false)
          setError('Timeout reached')
          return
        }

        const fetchedContract = await fetchContract()

        // @NOTE: in case of changed `updatedAt` date we assume the current operation finished successfully
        if (fetchedContract.updatedAt !== contract.data?.updatedAt) {
          // @TODO: setup proper success state here
          setIsPending(false)
          queryClient.invalidateQueries({ queryKey: ['contract'] })
          return
        }

        setTimeout(() => poll(iteration + 1), pollingIntervalInSeconds * 1000)
      } catch (err) {
        // @TODO: add error here so we can show timeout to the user
        setIsPending(false)
        console.error(err)
        setError('Unknown error happen')
      }
    },
    [
      fetchContract,
      pollingIntervalInSeconds,
      pollingIterationsLimit,
      queryClient,
      contract,
    ]
  )

  const features = useQuery({
    queryKey: ['contract', 'contract-features'],
    queryFn: () =>
      httpClient
        .get<
          paths['/contracts/{contractId}/features']['get']['responses']['200']['content']['application/json']
        >(`/contracts/${contractId}/features`)
        .then((response) => response.data),
  })

  const { mutateAsync: activate } = useMutation({
    mutationFn: async (priceId: string) => {
      setError(undefined)

      const data = await httpClient
        .post<
          paths['/contracts/{contractId}/activate']['post']['responses']['201']['content']['application/json']
        >(`/contracts/${contractId}/activate`, { priceId })
        .then((response) => response.data)

      if (data != null) {
        window.open(data.paymentUrl, '_blank')
      }
    },
    onSuccess: () => {
      poll()
    },
    onError: (err) => {
      console.error(err)
      setError('Activation failed')
    },
  })

  const { mutateAsync: upgrade } = useMutation({
    mutationFn: (priceId: string) => {
      setError(undefined)
      return httpClient
        .post<
          paths['/contracts/{contractId}/upgrade']['post']['responses']['201']['content']['application/json']
        >(`/contracts/${contractId}/upgrade`, { priceId })
        .then((response) => response.data)
    },
    onSuccess: () => {
      poll()
    },
    onError: (err) => {
      console.error(err)
      setError('Upgrade failed')
    },
  })

  return (
    <ContractContext.Provider
      value={{
        status:
          error != null
            ? 'error'
            : isPending
              ? 'pending'
              : contract.isLoading || features.isLoading
                ? 'loading'
                : 'idle',
        errorMessage: error,
        features: contract.data?.features ?? [],
        availableFeaturesToPurchase: features.data ?? [],
        activate,
        upgrade,
      }}
    >
      {children}
    </ContractContext.Provider>
  )
}
