import { Entity, NodeType, NodeTypeComponent } from '@aninix-inc/model'
import { GroupOrFrame } from './group-or-frame'
import { ShapeLayer } from './shape-layer'
import { LottieAsset, LottieLayer } from './types'

export function Layer(
  payload: {
    node: Entity
    parent?: number
  },
  assets: LottieAsset[]
): LottieLayer[] {
  const { node, parent } = payload

  if (
    [NodeType.Frame, NodeType.Group, NodeType.Instance].includes(
      node.getComponentOrThrow(NodeTypeComponent).value
    )
  ) {
    return GroupOrFrame({ node }, assets)
  }

  return ShapeLayer({ node, parent }, assets)
}
