import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 13,
  y: 12,
}

export const Link: React.FC<IIcon> = ({
  size = defaultSize,
  color = 'white',
  className,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.5 4.49988V0.999878L7.9375 0.999512"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 6.00012L10.7487 1.75146"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 7V7.8C11.5 8.92011 11.5 9.48016 11.282 9.90798C11.0903 10.2843 10.7843 10.5903 10.408 10.782C9.98016 11 9.42011 11 8.3 11H4.7C3.57989 11 3.01984 11 2.59202 10.782C2.21569 10.5903 1.90973 10.2843 1.71799 9.90798C1.5 9.48016 1.5 8.92011 1.5 7.8V4.2C1.5 3.07989 1.5 2.51984 1.71799 2.09202C1.90973 1.71569 2.21569 1.40973 2.59202 1.21799C3.01984 1 3.57989 1 4.7 1H5.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
