import * as React from 'react'

export const AccessRestrictedIcon: React.FC<{ className?: string }> = ({
  className,
}) => (
  <svg
    viewBox="0 0 200 140"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M68 56C68 52.2725 68 50.4087 68.609 48.9385C69.4209 46.9783 70.9783 45.4209 72.9385 44.609C74.4087 44 76.2725 44 80 44H120C123.728 44 125.591 44 127.061 44.609C129.022 45.4209 130.579 46.9783 131.391 48.9385C132 50.4087 132 52.2725 132 56V72C132 89.6731 117.673 104 100 104C82.3269 104 68 89.6731 68 72V56Z"
      fill="url(#paint0_linear_127_2398)"
    />
    <ellipse
      opacity="0.3"
      cx="100"
      cy="104"
      rx="80"
      ry="20"
      stroke="url(#paint1_linear_127_2398)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeDasharray="0.1 4"
    />
    <g filter="url(#filter0_dddddd_127_2398)">
      <path
        d="M106 66C106 69.3137 103.314 72 100 72C96.6863 72 94 69.3137 94 66C94 62.6863 96.6863 60 100 60C103.314 60 106 62.6863 106 66Z"
        fill="white"
      />
      <path
        d="M98 71C98 69.8954 98.8954 69 100 69C101.105 69 102 69.8954 102 71V78C102 79.1046 101.105 80 100 80C98.8954 80 98 79.1046 98 78V71Z"
        fill="white"
      />
    </g>
    <path
      d="M118 44V34C118 24.0589 109.941 16 100 16V16C90.0589 16 82 24.0589 82 34V44"
      stroke="url(#paint2_linear_127_2398)"
      strokeWidth="7"
    />
    <defs>
      <filter
        id="filter0_dddddd_127_2398"
        x="88"
        y="59"
        width="24"
        height="48"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_127_2398"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_127_2398"
          result="effect2_dropShadow_127_2398"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_127_2398"
          result="effect3_dropShadow_127_2398"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="8" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_127_2398"
          result="effect4_dropShadow_127_2398"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="14" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_127_2398"
          result="effect5_dropShadow_127_2398"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="21" />
        <feGaussianBlur stdDeviation="3" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_127_2398"
          result="effect6_dropShadow_127_2398"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_127_2398"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_127_2398"
        x1="100"
        y1="44"
        x2="100"
        y2="103.016"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E5E5" />
        <stop offset="1" stopColor="#F4F4F4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_127_2398"
        x1="100"
        y1="124"
        x2="100"
        y2="84"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#303030" />
        <stop offset="0.766047" stopColor="#6F6F6F" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_127_2398"
        x1="100"
        y1="44"
        x2="100"
        y2="14"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#CACACA" />
        <stop offset="1" stopColor="#F1F1F1" />
      </linearGradient>
    </defs>
  </svg>
)
