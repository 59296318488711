import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'

import { PresetAnimationAppearanceScaleType } from '../../../models/preset'
import * as styles from './index.scss'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  type: PresetAnimationAppearanceScaleType
  size?: Point2D
}
export const Scale: React.FCC<IProps> = ({ type, size = defaultSize }) => {
  if (type === PresetAnimationAppearanceScaleType.Vertical) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.99992 2.03613L8.35348 2.38969L10.9818 5.01799L10.2747 5.7251L8.49992 3.95035L8.49992 12.0493L10.2747 10.2746L10.9818 10.9817L8.35348 13.61L7.99992 13.9636L7.64637 13.61L5.01807 10.9817L5.72517 10.2746L7.49992 12.0493L7.49992 3.95035L5.72517 5.7251L5.01807 5.01799L7.64637 2.38969L7.99992 2.03613Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceScaleType.Horizontal) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.95035 8.49992L5.7251 10.2747L5.01799 10.9818L2.38969 8.35348L2.03613 7.99992L2.38969 7.64637L5.01799 5.01807L5.7251 5.72517L3.95035 7.49992H12.0493L10.2746 5.72517L10.9817 5.01807L13.61 7.64637L13.9636 7.99992L13.61 8.35348L10.9817 10.9818L10.2746 10.2747L12.0493 8.49992H3.95035Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceScaleType.Both) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.2054 11.5006H7.99908V12.5006H3.99829H3.49829L3.49829 12.0006V7.99982H4.49829L4.49829 10.7935L10.7928 4.49902L7.99908 4.49902V3.49902L11.9999 3.49902L12.4999 3.49902V3.99902V7.99982H11.4999V5.20613L5.2054 11.5006Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceScaleType.None) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C9.33875 2.5 10.5658 2.97831 11.5195 3.77337L3.77337 11.5195C2.97831 10.5658 2.5 9.33875 2.5 8ZM4.48047 12.2266C5.43422 13.0217 6.66125 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 6.66125 13.0217 5.43422 12.2266 4.48047L4.48047 12.2266ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  return null
}
