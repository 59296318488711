import { styled, Switch, SwitchProps } from '@material-ui/core'
import * as React from 'react'

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 24,
  height: 12,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 12,
      height: 12,
    },

    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .Mui-disabled': {
    opacity: 0.4,
  },
  '& .MuiSwitch-switchBase': {
    padding: 0,

    '&.Mui-disabled': {
      '& + .MuiSwitch-track': {
        background: 'none',
        opacity: 0.4,
      },
    },

    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',

      '& + .MuiSwitch-track': {
        opacity: 1,
        background: '#000000',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    background: '#FFFFFF',
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    border: '1px solid black',
  },
  '& .MuiSwitch-track': {
    background: '#FFFFFF',
    borderRadius: 16 / 2,
    border: '1px solid black',
    opacity: 1,
    boxSizing: 'border-box',
  },
}))

export type IProps = SwitchProps
export const Toggle: React.FCC<IProps> = (props) => (
  // @ts-ignore
  <CustomSwitch disableRipple {...props} />
)
