import * as React from 'react'

import { IIcon } from '.'

export const Mail: React.FCC<IIcon> = ({
  size = { x: 16, y: 16 },
  className = '',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 ${size.x} ${size.y}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 12.5H3C2.44772 12.5 2 12.0523 2 11.5V4C2 3.44772 2.44772 3 3 3H13C13.5523 3 14 3.44772 14 4V11.5C14 12.0523 13.5523 12.5 13 12.5H9.5"
      stroke="#89909A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.5 10.5L9.5 12.5L11.5 14.5"
      stroke="#89909A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 4L8 8L14 4"
      stroke="#89909A"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
