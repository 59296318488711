import { Entity, components } from '@aninix-inc/model'
import { Node } from '@aninix-inc/model/legacy'

export type Components = InstanceType<
  (typeof components)[keyof typeof components]
>

export type InfoItem = {
  title: string
  value: React.ReactNode
}

export type InfoBlock = {
  propertiesName: string
  properties: InfoItem[]
}

export type Info = {
  // @NOTE: node color in hex format
  color: string
  title: string
  items: InfoBlock[]
}

export enum Format {
  Pixels = 'px',
  Percents = '%',
}

export type LayerTrack = {
  value: Node
  segments: [Entity, Entity]
  children: LayerTrack[]
}
