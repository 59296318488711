import classNames from 'classnames'
import * as React from 'react'

const steps = [
  {
    title: 'Switch mode',
    description: 'Switch connect plugin mode to Aninix → Figma',
    range: [0, 1.5],
  },
  {
    title: 'Select the desired project',
    description: 'Select project you want to import from the list',
    range: [1.51, 3.5],
  },
  {
    title: 'Import your project',
    description:
      'Click import to create new frame in Figma and attach Aninix project to it',
    range: [3.51, 7.5],
  },
]

interface IProps {
  onClose: () => void
}
export const EditInFigmaModal: React.FC<IProps> = ({ onClose }) => {
  const [activeStep, setActiveStep] = React.useState<number>(0)
  const [isSyncing, setIsSyncing] = React.useState<boolean>(true)
  const [hasUserInteracted, setHasUserInteracted] =
    React.useState<boolean>(false)
  const videoRef = React.useRef<HTMLVideoElement>(null)

  const handleStepClick = React.useCallback((index: number) => {
    setActiveStep(index)
    setHasUserInteracted(true)
    const video = videoRef.current
    if (video) video.currentTime = steps[index].range[0]
  }, [])

  const handleTimeUpdate = React.useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      const currentTime = e.currentTarget.currentTime
      console.log(currentTime)
      if (hasUserInteracted) {
        const { range } = steps[activeStep]
        if (currentTime < range[0] || currentTime >= range[1]) {
          e.currentTarget.currentTime = range[0]
        }
      } else {
        const stepIndex = steps.findIndex(
          ({ range }) => currentTime >= range[0] && currentTime < range[1]
        )
        console.log(stepIndex)
        if (stepIndex !== -1 && stepIndex !== activeStep)
          setActiveStep(stepIndex)
      }
    },
    [hasUserInteracted, activeStep]
  )

  const handleEnded = React.useCallback(
    (e: React.SyntheticEvent<HTMLVideoElement>) => {
      if (hasUserInteracted) {
        const { range } = steps[activeStep]
        if (range[1] !== -1) {
          e.currentTarget.currentTime = range[0]
          e.currentTarget.play()
        }
      }
    },
    [hasUserInteracted, activeStep]
  )

  return (
    <div className="pointer-events-none flex h-full w-full items-center justify-center">
      <div className="pointer-events-auto flex w-[800px] flex-row rounded-xl bg-white shadow-lg overflow-hidden">
        <div className="flex flex-col flex-1 p-6 gap-3">
          <div className="flex flex-row items-center justify-between">
            <p className="text-base font-medium text-black text-opacity-80">
              Import Aninix project to Figma
            </p>
            <button
              onClick={onClose}
              className="cursor-default rounded-lg bg-white p-1 transition-all duration-150 hover:bg-gray-100"
            >
              <CrossIcon />
            </button>
          </div>
          <div className="flex flex-col gap-1">
            {steps.map(({ title, description }, index) => {
              const isActive = activeStep === index
              return (
                <button
                  key={index}
                  onClick={() => handleStepClick(index)}
                  className={
                    'cursor-default flex flex-row text-left gap-3 p-3 rounded-3xl relative overflow-hidden'
                  }
                >
                  <div
                    className={classNames(
                      'bottom-0',
                      'top-0',
                      'right-0',
                      'left-0',
                      'absolute',
                      'bg-gradient-to-r from-accent/15 to-accent/5',
                      'opacity-0',
                      'transition-opacity',
                      'duration-300',
                      'z-0',
                      {
                        ['opacity-100']: isActive,
                      }
                    )}
                  />
                  <div
                    className={classNames(
                      'font-semibold w-[24px] h-[24px] flex-shrink-0 rounded-full flex items-center justify-center text-sm transition-colors duration-150 z-1 relative',
                      {
                        ['text-secondary bg-accent bg-opacity-10']: !isActive,
                        ['text-accent bg-white']: isActive,
                      }
                    )}
                  >
                    {index + 1}
                  </div>
                  <div className="flex flex-col gap-1">
                    <p className="text-black text-opacity-80 text-base font-medium">
                      {title}
                    </p>
                    <p className={'text-gray-500 text-base'}>{description}</p>
                  </div>
                </button>
              )
            })}
          </div>
          {/* @TODO: enable once we have syncing mechanism */}
          {/* <div className="flex flex-row gap-2 items-center ml-4 text-green font-medium text-sm">
            {isSyncing && (
              <>
                <InfinityIcon />
                <span>Syncing now...</span>
              </>
            )}
          </div> */}
        </div>
        <div className="flex-1 pt-4">
          <video
            ref={videoRef}
            className="object-cover aspect-square w-full h-full rounded-tl-xl"
            autoPlay
            muted
            onTimeUpdate={handleTimeUpdate}
            onEnded={handleEnded}
            loop
          >
            <source src="/videos/edit-in-figma.mp4" type="video/mp4" />
          </video>
        </div>
      </div>
    </div>
  )
}

EditInFigmaModal.displayName = 'EditInFigmaModal'

const CrossIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18M6 6L18 18"
      stroke="#89909A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const InfinityIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 8.11082C7.57325 8.93921 7.0011 9.68417 6.31083 10.3102C5.87581 10.7453 5.32152 11.0417 4.71805 11.1618C4.11459 11.2819 3.48906 11.2203 2.92058 10.9849C2.35209 10.7495 1.86619 10.3508 1.52433 9.83919C1.18247 9.3276 1 8.72612 1 8.11082C1 7.49552 1.18247 6.89403 1.52433 6.38244C1.86619 5.87085 2.35209 5.47213 2.92058 5.23671C3.48906 5.00129 4.11459 4.93974 4.71805 5.05984C5.32152 5.17995 5.87581 5.47632 6.31083 5.91147C7.0011 6.53747 7.57325 7.28242 8 8.11082ZM8 8.11082C8.42675 7.28242 8.9989 6.53747 9.68917 5.91147C10.1242 5.47632 10.6785 5.17995 11.2819 5.05984C11.8854 4.93974 12.5109 5.00129 13.0794 5.23671C13.6479 5.47213 14.1338 5.87085 14.4757 6.38244C14.8175 6.89403 15 7.49552 15 8.11082C15 8.72612 14.8175 9.3276 14.4757 9.83919C14.1338 10.3508 13.6479 10.7495 13.0794 10.9849C12.5109 11.2203 11.8854 11.2819 11.2819 11.1618C10.6785 11.0417 10.1242 10.7453 9.68917 10.3102C8.9989 9.68417 8.42675 8.93921 8 8.11082Z"
      stroke="currentColor"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
