// @ts-nocheck
import { Viewport } from '@aninix/core'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import * as PIXI from 'pixi.js'
import { NodeSnapshot } from '../../common/renderers/types'

export function applyDropShadow(
  node: NodeSnapshot,
  graphics: PIXI.Graphics,
  kindergarden: PIXI.Container<PIXI.DisplayObject>,
  viewport: Viewport,
  app: PIXI.Application,
  prerenderCallbacks: (() => void)[],
  postrenderCallbacks: (() => void)[],
  blurQuality: number
) {
  postrenderCallbacks.push(() => {
    if (node.dropShadows.length > 0) {
      const shadowContainer = new PIXI.Container()

      let shadowTexture = app.renderer.generateTexture(kindergarden, {
        resolution: 0.5,
      })

      prerenderCallbacks.push(() => {
        shadowTexture.baseTexture.destroy()
      })

      node.dropShadows.forEach((shadow) => {
        if (!shadow.visible) return

        const {
          color: colorRGBA,
          spread,
          offset: offsetProp,
          radius,
          blendMode,
        } = shadow
        const offset = {
          x: offsetProp.x,
          y: offsetProp.y,
        }

        const shadowGraphics = new PIXI.Sprite(shadowTexture)
        shadowGraphics.tint = colorRGBA
        shadowGraphics.alpha = colorRGBA.a

        shadowContainer.addChild(shadowGraphics)

        shadowGraphics.position.x += offset.x
        shadowGraphics.position.y += offset.y

        // return

        kindergarden.addChildAt(shadowContainer, 0)

        const blurFilter = new KawaseBlurFilter(
          (radius * viewport.zoom) / 3,
          blurQuality
        )
        blurFilter.resolution = 3

        shadowContainer.filters = [blurFilter]

        const padding = new PIXI.Graphics()
          .beginFill(0)
          .drawRect(
            -radius * 3,
            -radius * 3,
            shadowGraphics.width + 2 * radius * 3,
            shadowGraphics.height + 2 * radius * 3
          )
        padding.alpha = 0
        shadowContainer.addChild(padding)
      })
    }
  })
}
