import { lerp, round } from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import { useTimeline } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import * as timeConverters from '../../../../helpers/timeConverters'
import { useFormatTime } from '../../../properties-panel/components/formatted-time'
import { useUpdateTime } from '../use-update-time'
import * as styles from './index.scss'

// @NOTE: in format [seconds, [width_from, width_to]]
const steps: [number, [number, number]][] = [
  // each 20 seconds
  [1 / 20, [0, 5]],
  // each 5 seconds
  [1 / 5, [5, 10]],
  // each 2 seconds
  [1 / 2, [10, 25]],
  // each 1 second
  [1 / 1, [25, 100]],
  // each 0.5 seconds
  [1 / 0.5, [100, 400]],
  // each 0.1 seconds
  [1 / 0.1, [400, 1000]],
  // each 0.05 seconds
  [1 / 0.05, [1000, 2500]],
  // each 0.01 seconds
  [1 / 0.01, [2500, 1000000]],
]

export interface IProps {
  projectDuration: number
  parentTrackWidth: number
}
export const TimeIndicators: React.FCC<IProps> = observer(
  ({ projectDuration, parentTrackWidth }) => {
    const timeline = useTimeline()

    const { toFormat } = useFormatTime()
    const updateTime = useUpdateTime()

    const duration = projectDuration
    const zoom = timeline.visibleRangeDuration / projectDuration

    const containerRef = React.useRef<any>(null)

    const { startAtX, offsetX, isListening, startListen } = useMouseMove()

    // TODO: refactor
    const handlerWidth = 6

    const roundDetails = React.useMemo(() => {
      const coefficient = lerp(1 - zoom, 2, 4)
      return Math.round(coefficient)
    }, [])

    const levelOfDetails = (() => {
      const width = parentTrackWidth
      const secondWidth = width / duration

      const target = steps.find(
        ([_, [from, to]]) => secondWidth >= from && secondWidth < to
      )

      if (target == null) {
        return 100
      }

      return target[0]
    })()

    const timeItems = R.range(
      0,
      Math.round((duration + 1) * levelOfDetails)
    ).map((item) => {
      return round(item * (1 / levelOfDetails), { fixed: roundDetails })
    })

    React.useEffect(() => {
      if (isListening) {
        const containerOffset =
          containerRef.current.getBoundingClientRect().left
        const offset = startAtX + offsetX - containerOffset - handlerWidth
        const time = timeConverters.convertPixelsToTime({
          pixels: offset,
          trackWidth: parentTrackWidth - handlerWidth * 2,
          projectDuration: duration,
        })

        updateTime(time)
      }
    }, [isListening, startAtX, offsetX])

    const onMouseDown = React.useCallback(
      (e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        startListen(e)
      },
      [startListen]
    )

    const timeItemsPrepared = React.useMemo(
      () =>
        timeItems.map((timeItem) => ({
          left:
            timeConverters.convertTimeToPixels({
              time: timeItem,
              trackWidth: parentTrackWidth - handlerWidth * 2,
              projectDuration: duration,
            }) + handlerWidth,
          value: toFormat(timeItem),
        })),
      [timeItems, toFormat]
    )

    return (
      <button
        ref={containerRef}
        className={styles.container}
        onMouseDown={onMouseDown}
        // @NOTE: required to calculate selection in tracks
        data-model-type="time-indicators"
      >
        {timeItemsPrepared.map((timeItem, idx) => (
          <div
            key={`${timeItems.length}-${timeItem}-${idx}`}
            className={styles.item}
            style={{
              left: timeItem.left,
            }}
          >
            <span className={styles['item-text']}>{timeItem.value}</span>
            <span className={styles['item-separator']} />
          </div>
        ))}
      </button>
    )
  }
)

TimeIndicators.displayName = 'TimeIndicators'
