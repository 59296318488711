import classNames from 'classnames'
import * as React from 'react'
import { Spoiler } from './spoiler'

export interface IProps {
  isOpen: boolean
  toggleOpen: () => void
  label: string
}
export const ComponentGroupPanel: React.FCC<IProps> = ({
  label,
  isOpen,
  toggleOpen,
  children,
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null)

  const contentHeight = (
    contentRef.current?.firstChild as HTMLDivElement | undefined
  )?.getBoundingClientRect().height

  const [transitionIsGoing, setTransitionIsGoing] = React.useState(false)

  const [localOpen, setLocalOpen] = React.useState(isOpen)

  // this is required to avoid problems when text is changing its height
  // we make local open to be dependent of prop value
  // then we make transition available for exactly transition duration
  // therefore transition is working only when isOpen changes,
  // but doesn't work right before and in 150ms after change
  React.useEffect(() => {
    setLocalOpen(isOpen)
    setTransitionIsGoing(true)
    setTimeout(() => setTransitionIsGoing(false), 150)
  }, [isOpen])

  return (
    <div className="py-1.5 border-t border-t-gray/10">
      <div className="px-3">
        <Spoiler isOpen={localOpen} toggleOpen={toggleOpen} label={label} />
      </div>
      <div
        ref={contentRef}
        className={classNames('', {
          ['transition-[max-height] duration-150']: transitionIsGoing,
        })}
        style={{
          overflow: 'hidden',
          maxHeight: localOpen ? `${contentHeight}px` : '0px',
          height: 'fit-content',
        }}
      >
        {children}
      </div>
    </div>
  )
}

ComponentGroupPanel.displayName = 'ComponentGroupPanel'
