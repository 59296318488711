import { useQuery } from '@tanstack/react-query'

import { useLogger } from '@aninix/logger'
import { ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export type UseGetSpaceBadge = {
  infoFromTokens: {
    users: ApiGetResponse<'/users/me'>[]
  }
}

export function useGetSpaceBadge(payload: { tokens: string[] }) {
  const logger = useLogger()

  return useQuery({
    queryKey: ['get-space-badge', payload],
    queryFn: async (): Promise<UseGetSpaceBadge> => {
      const responses = await Promise.all(
        payload.tokens.map(async (token) => {
          try {
            const result = await httpClient.get<ApiGetResponse<'/users/me'>>(
              '/users/me',
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            return result
          } catch (err: unknown) {
            logger.error('useGetSpaceBadge', err)
            return null
          }
        })
      )

      return {
        infoFromTokens: {
          users: responses
            .filter((item) => item != null)
            .filter((item) => item?.data.figmaAccountId != null)
            .map((response) => response!.data),
        },
      }
    },
  })
}
