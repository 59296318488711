import { Project, Root } from '@aninix-inc/model'

export const applyMatchingEntitiesValues = ({
  sourceOfChanges,
  project,
}: {
  sourceOfChanges: Project
  project: Project
}) => {
  sourceOfChanges.entities.forEach((changedEntity) => {
    const entity = project.getEntity(changedEntity.id)

    if (entity == null) return
    if (entity instanceof Root) return

    changedEntity.components.forEach((c) => {
      entity.applySnapshot(c.entity.getSnapshot())
    })
  })
}
