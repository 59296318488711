import { Entity } from '@aninix-inc/model'
import * as React from 'react'
import { Offset } from './offset'
import { Time } from './time'
import { Value } from './value'

export interface IProps {
  keyframes: Entity[]
}
export const KeyframeProperties: React.FCC<IProps> = ({ keyframes }) => {
  return (
    <>
      <Time keyframes={keyframes} />
      <Value keyframes={keyframes} />
      {keyframes.length > 1 && <Offset keyframes={keyframes} />}
    </>
  )
}

KeyframeProperties.displayName = 'KeyframeProperties'
