import * as React from 'react'

import { Collapsed } from './Collapsed'
import { Expanded } from './Expanded'

const defaultSize = {
  x: 20,
  y: 20,
}

export enum NodeExpandType {
  Normal = 'NORMAL',
  ChildrenVisible = 'CHILDREN_VISIBLE',
  PropertiesVisible = 'PROPERTIES_VISIBLE',
  Expanded = 'EXPANDED',
}

export interface IProps {
  type: NodeExpandType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const ExpandChildren: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  if (
    [NodeExpandType.ChildrenVisible, NodeExpandType.Expanded].includes(type)
  ) {
    return <Expanded size={size} style={style} />
  }

  return <Collapsed size={size} style={style} />
}
