import * as React from 'react'

export type ProContext = {
  isPro: boolean
  beforeProFeatureAttepmtToAccess: () => void
}

const Context = React.createContext<ProContext>(null as any)

export const usePro = (): ProContext => {
  const pro = React.useContext(Context)

  if (pro == null) {
    throw new Error('usePro can be used only within <ProContextProvider />')
  }

  return pro
}

export const ProContextProvider: React.FCC<{
  isPro: boolean
  beforeProFeatureAttepmtToAccess: () => void
}> = ({ isPro, beforeProFeatureAttepmtToAccess, children }) => {
  const store = React.useMemo(
    () => ({
      isPro,
      beforeProFeatureAttepmtToAccess,
    }),
    [isPro, beforeProFeatureAttepmtToAccess]
  )

  return <Context.Provider value={store}>{children}</Context.Provider>
}
