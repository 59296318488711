import {
  convertNodeToSnapshot,
  getSelection,
  ImagesStore,
  useImagesStore,
  usePlayback,
  useProject,
  useSystem,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

import {
  Entity,
  EntityType,
  EntryComponent,
  isSegment as isSegmentV2,
  TargetRelationAspect,
  TimeComponent,
  StyledTextSegmentRelationsAspect,
} from '@aninix-inc/model'
import { NodeSnapshot } from '@aninix-inc/renderer'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { PropertiesPanelTab } from '@aninix/editor/stores/ui'
import { PresetsStoreProvider, useUi } from '../../stores'
import { AnimationPresets } from '../animation-presets'
import { useSelection } from '../selection'
import {
  EntryProperties,
  NodeProperties,
  ProjectProperties,
  SegmentProperties,
} from './components'
import { KeyframeProperties } from './components/keyframes'
import { Header } from './header'
import * as styles from './index.scss'
import { ViewTabs } from './view-tabs'
import { featureFlags } from '@aninix/core'

export interface IProps {
  time: number
  isPlaying: boolean
  tabs: PropertiesPanelTab[]
  activeTab: PropertiesPanelTab
  onChangeActiveTab: (tab: PropertiesPanelTab) => void
}

type NodePropertiesPanelContextType = {
  nodes: Entity[]
  snapshots: NodeSnapshot[]
  time: number
  entitiesWithPaints: Entity[]
}

const NodePropertiesPanelContext =
  React.createContext<NodePropertiesPanelContextType>(
    null as unknown as NodePropertiesPanelContextType
  )

export const useNodePropertiesPanel = () => {
  const context = React.useContext(NodePropertiesPanelContext)
  if (!context)
    throw new Error(
      'useNodePropertiesPanel must be used within a NodePropertiesPanelContext'
    )
  return context
}

type PanelView =
  | 'nodes'
  | 'keyframes'
  | 'entry'
  | 'segments'
  | 'project'
  | 'presets'

const NodePropertiesPanelProvider: React.FCC<{
  nodes: Entity[]
  time: number
  imagesStore: ImagesStore
}> = ({ children, time, nodes, imagesStore }) => {
  const snapshots = nodes.map((n) =>
    convertNodeToSnapshot({ entity: n, time, imagesStore })
  )

  const entitiesWithPaints = React.useMemo(() => {
    return !featureFlags.renderText
      ? nodes
      : ([
          ...nodes.filter(
            (n) => !n.hasAspect(StyledTextSegmentRelationsAspect)
          ),
          ...nodes.flatMap((n) =>
            n.hasAspect(StyledTextSegmentRelationsAspect)
              ? n.getAspect(StyledTextSegmentRelationsAspect)?.getChildrenList()
              : []
          ),
        ].filter((n): n is Entity => n !== undefined) as Entity[])
  }, [nodes])

  return (
    <NodePropertiesPanelContext.Provider
      value={{ nodes, snapshots, time, entitiesWithPaints }}
    >
      {children}
    </NodePropertiesPanelContext.Provider>
  )
}

const getSegments = (keyframes: Entity[]) => {
  const sortedKeyframes = R.sortBy(
    (e) => e.getComponentOrThrow(TimeComponent).value,
    keyframes
  )
  const groupedByTarget = R.groupBy(
    (keyframe) =>
      keyframe.getAspectOrThrow(TargetRelationAspect).getRelationOrThrow(),
    sortedKeyframes
  )

  let segments: [Entity, Entity][] = []
  for (const keyframesByProperty of R.values(groupedByTarget)) {
    for (let i = 0; i < keyframesByProperty!.length - 1; i += 1) {
      const keyframe = keyframesByProperty![i]
      const nextKeyframe = keyframesByProperty![i + 1]
      if (isSegmentV2(keyframe, nextKeyframe)) {
        segments.push([keyframe, nextKeyframe])
      }
    }
  }
  return segments
}

type PropertiesPanelOptions = {
  view: PanelView
  showTabs: boolean
  showHeader: boolean
}

const PropertiesPanelActual: React.FCC<IProps> = React.memo(
  ({ time, tabs, activeTab, onChangeActiveTab }) => {
    const project = useProject()
    const analytics = useAnalytics()
    const { selection } = useSelection()
    const imagesStore = useImagesStore()
    useSystem(selection)

    const nodes = getSelection(project, EntityType.Node)
    const entries = nodes.filter((layer) => layer.hasComponent(EntryComponent))
    const keyframes = getSelection(project, EntityType.Keyframe)
    const segments = React.useMemo(() => getSegments(keyframes), [keyframes])

    const { view, showHeader, showTabs } =
      React.useMemo<PropertiesPanelOptions>(() => {
        const result: PropertiesPanelOptions = {
          view: 'project',
          showTabs: false,
          showHeader: true,
        }

        if (nodes.length <= 0 && keyframes.length <= 0)
          result.showHeader = false

        if (
          activeTab === 'Properties' ||
          selection.isEmpty() ||
          nodes.find((node) => node.hasComponent(EntryComponent)) != null ||
          segments.length > 0 ||
          keyframes.length > 0
        ) {
          if (selection.isEmpty()) return result

          if (entries.length > 0) {
            result.view = 'entry'
            return result
          }
          if (nodes.length > 0) {
            result.view = 'nodes'
            result.showTabs = true
            return result
          }
          if (segments.length > 0) {
            result.view = 'segments'
            return result
          }
          if (keyframes.length > 0) {
            result.view = 'keyframes'
            return result
          }
        }

        result.view = 'presets'
        result.showTabs = true
        return result
      }, [nodes, entries, segments, keyframes, selection, activeTab])

    return (
      <div className={styles.container}>
        {showHeader && <Header project={project} />}

        {showTabs && (
          <ViewTabs
            tabs={tabs}
            activeTab={activeTab}
            onUpdateTab={(newTab) => {
              if (newTab === 'Presets') {
                analytics.track({
                  eventName: AnalyticsEvent.AnimationPresetsClicked,
                  properties: {
                    source: 'properties-panel',
                  },
                })
              }

              onChangeActiveTab(newTab as PropertiesPanelTab)
            }}
          />
        )}

        {view === 'presets' ? (
          <PresetsStoreProvider>
            <AnimationPresets project={project} />
          </PresetsStoreProvider>
        ) : (
          <>
            {view === 'project' && <ProjectProperties />}
            {view === 'entry' && (
              <NodePropertiesPanelProvider
                nodes={nodes}
                time={time}
                imagesStore={imagesStore}
              >
                <EntryProperties />
              </NodePropertiesPanelProvider>
            )}
            {view === 'nodes' && (
              <NodePropertiesPanelProvider
                nodes={nodes}
                time={time}
                imagesStore={imagesStore}
              >
                <NodeProperties />
              </NodePropertiesPanelProvider>
            )}
            {view === 'segments' && <SegmentProperties segments={segments} />}
            {view === 'keyframes' && (
              <KeyframeProperties keyframes={keyframes} />
            )}
          </>
        )}
      </div>
    )
  },
  (prev, next) => {
    if (next.isPlaying) return true
    return prev.time === next.time && prev.activeTab === next.activeTab
  }
)

PropertiesPanelActual.displayName = 'PropertiesPanelActual'

export const PropertiesPanel: React.FC = observer(() => {
  const playback = usePlayback()
  const uiStore = useUi()
  return (
    <PropertiesPanelActual
      time={playback.time}
      isPlaying={playback.isPlaying}
      tabs={uiStore.propertiesPanelTabs}
      activeTab={uiStore.propertiesPanelActiveTab}
      onChangeActiveTab={uiStore.propertiesPanelUpdateActiveTab}
    />
  )
})

PropertiesPanel.displayName = 'PropertiesPanel'
