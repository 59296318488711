import {
  CurveStyles as CurveStylesComponent,
  PropertyRowV2,
} from '@aninix/app-design-system'
import { Popover } from '@material-ui/core'
import * as React from 'react'

import { CurveStyle } from '@aninix-inc/model'
import { useEntities, useProject } from '@aninix/core'
import { EditCurveStyle } from '../../common/edit-curve-style'

const noop = () => {}

export const CurveStyles: React.FC = () => {
  const project = useProject()
  const curveStyles = project.getEntitiesByType(CurveStyle)
  useEntities(curveStyles)

  return <CurveStylesMemo curveStyles={curveStyles} />
}

CurveStyles.displayName = 'CurveStyles'

const CurveStylesMemo: React.FC<{ curveStyles: CurveStyle[] }> = React.memo(
  ({ curveStyles }) => {
    const [editingCurveId, setEditingCurveId] = React.useState<string | null>(
      null
    )
    const userPresetsRef = React.useRef<HTMLDivElement>(null)
    const project = useProject()

    const editingCurve = editingCurveId
      ? project.getEntity(editingCurveId)
      : undefined

    if (curveStyles.length === 0) return null

    return (
      <>
        <PropertyRowV2
          name="Curve Styles"
          inputs={
            <div className="w-full" ref={userPresetsRef}>
              <CurveStylesComponent
                curveStyles={curveStyles}
                onApply={noop}
                onEditPreset={setEditingCurveId}
              />
            </div>
          }
        />

        <Popover
          open={editingCurveId != null}
          anchorEl={userPresetsRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          onClose={() => setEditingCurveId(null)}
          disableRestoreFocus
        >
          {editingCurve != null && (
            <EditCurveStyle
              curveStyle={editingCurve}
              onClose={() => setEditingCurveId(null)}
            />
          )}
        </Popover>
      </>
    )
  },
  (prev, next) => {
    if (prev.curveStyles.length !== next.curveStyles.length) return false
    for (let i = 0; i < prev.curveStyles.length; i++) {
      if (prev.curveStyles[i].id !== next.curveStyles[i].id) return false
    }

    return true
  }
)

CurveStylesMemo.displayName = 'CurveStylesMemo'
