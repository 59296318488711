import * as React from 'react'

interface IProps {
  onClick?: () => void
}

export const OptionButton: React.FCC<IProps> = ({ onClick, children }) => {
  return (
    <button
      className="flex flex-row w-full justify-between items-center gap-2 bg-secondary px-3 py-2 font-body text-sm font-medium text-white transition-all duration-300 hover:shadow-lg relative"
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  )
}

OptionButton.displayName = 'OptionButton'
