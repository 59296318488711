import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 12,
  y: 12,
}

export const CaretDown: React.FCC<IIcon> = ({
  size = defaultSize,
  className,
  color = '#89909A',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.75 4.5L6 8.25L2.25 4.5"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
