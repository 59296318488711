import { Popover } from '@material-ui/core'
import * as React from 'react'
import { Export } from '../export'

export interface IProps {}
export const ExportButton: React.FCC<IProps> = () => {
  const [isExportMenuOpen, setIsExportMenuOpen] = React.useState<boolean>(false)
  const handleClick = React.useCallback(() => {
    setIsExportMenuOpen((state) => !state)
  }, [])
  const handleClose = React.useCallback(() => {
    setIsExportMenuOpen(false)
  }, [])

  const buttonRef = React.useRef<HTMLButtonElement>(null)

  return (
    <>
      <button
        ref={buttonRef}
        onClick={handleClick}
        className="group flex h-8 cursor-default items-center justify-center rounded-lg bg-accent transition-transform duration-150 hover:scale-[1.02]"
      >
        <p className="px-3 text-xs font-medium text-white">Export</p>
      </button>
      <Popover
        open={isExportMenuOpen}
        onClose={handleClose}
        anchorEl={buttonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        elevation={5}
        style={{ translate: '0 4px' }}
        PaperProps={{
          style: {
            width: '240px',
          },
        }}
      >
        <Export />
      </Popover>
    </>
  )
}

Export.displayName = 'Export'
