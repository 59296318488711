import * as R from 'ramda'
import * as React from 'react'

import {
  AnimationCurveAspect,
  CurveType,
  Entity,
  TimeComponent,
  TimingCurveComponent,
  copyCurveStyle,
} from '@aninix-inc/model'
import { mapSegmentsToKeyframes } from '../../helpers'

export interface ISegmentsReverseUseCase {
  execute: (payload: { segments: [Entity, Entity][] }) => void
}

/**
 * @description reverse selected segments.
 * 1. Find offset between segments;
 * 2. Reverse each segment;
 * 3. Place segments in time with previous offset.
 */
export function useSegmentsReverseUseCase(): ISegmentsReverseUseCase {
  const execute: ISegmentsReverseUseCase['execute'] = React.useCallback(
    ({ segments }) => {
      const keyframesByProperties = mapSegmentsToKeyframes(segments)

      // @NOTE: required to keep offset between segments
      const absoluteGroupStartTime = Math.min(
        ...keyframesByProperties.flatMap(
          (keyframesByProperty) =>
            R.head(keyframesByProperty)!.getComponentOrThrow(TimeComponent)
              .value
        )
      )
      const absoluteGroupEndTime = Math.max(
        ...keyframesByProperties.flatMap(
          (keyframesByProperty) =>
            R.last(keyframesByProperty)!.getComponentOrThrow(TimeComponent)
              .value
        )
      )

      keyframesByProperties.forEach((keyframesByProperty) => {
        const reversedKeyframes = R.reverse(keyframesByProperty)

        const absoluteTrackStartTime =
          R.head(keyframesByProperty)!.getComponentOrThrow(TimeComponent).value
        const absoluteTrackEndTime =
          R.last(keyframesByProperty)!.getComponentOrThrow(TimeComponent).value
        const relativeTrackStartTime =
          absoluteTrackStartTime - absoluteGroupStartTime
        const relativeTrackEndTime = absoluteGroupEndTime - absoluteTrackEndTime
        const trackDuration = relativeTrackEndTime - relativeTrackStartTime

        const isEven = keyframesByProperty.length % 2 === 0
        const keyframesCount = isEven
          ? Math.floor(keyframesByProperty.length / 2)
          : Math.floor(keyframesByProperty.length / 2) + 1
        for (let idx = 0; idx < keyframesCount; idx += 1) {
          const keyframe = keyframesByProperty[idx]
          const swapWith = reversedKeyframes[idx]

          const absoluteKeyframeTime =
            keyframe.getComponentOrThrow(TimeComponent).value
          const relativeKeyframeTime =
            absoluteKeyframeTime - absoluteTrackStartTime

          const absoluteSwapWithTime =
            swapWith.getComponentOrThrow(TimeComponent).value
          const relativeSwapWithTime =
            absoluteSwapWithTime - absoluteTrackStartTime

          keyframe.updateComponent(
            TimeComponent,
            absoluteTrackEndTime - relativeKeyframeTime + trackDuration
          )
          swapWith.updateComponent(
            TimeComponent,
            absoluteTrackEndTime - relativeSwapWithTime + trackDuration
          )

          // @NOTE: required to reverse timing curve graph
          const keyframeAspect = keyframe.getAspectOrThrow(AnimationCurveAspect)
          const swapWithAspect = swapWith.getAspectOrThrow(AnimationCurveAspect)
          if (
            keyframeAspect.getCurveType() === CurveType.Timing &&
            swapWithAspect.getCurveType() === CurveType.Timing
          ) {
            const keyframeCurve =
              keyframe.getComponentOrThrow(TimingCurveComponent).value
            const swapWithCurve =
              swapWith.getComponentOrThrow(TimingCurveComponent).value
            keyframe.updateComponent(TimingCurveComponent, {
              out: {
                x: swapWithCurve.out.x,
                y: swapWithCurve.out.y,
              },
              in: {
                x: swapWithCurve.in.x,
                y: swapWithCurve.in.y,
              },
            })
            swapWith.updateComponent(TimingCurveComponent, {
              out: {
                x: keyframeCurve.out.x,
                y: keyframeCurve.out.y,
              },
              in: {
                x: keyframeCurve.in.x,
                y: keyframeCurve.in.y,
              },
            })
            continue
          }

          // @NOTE: required to copy spring curve graph
          copyCurveStyle(keyframe, swapWith)
        }
      })
    },
    []
  )

  return {
    execute,
  }
}
