export enum UserRole {
  /**
   * @description user not authenticated
   */
  Anonymous = 'anonymous',

  /**
   * @description user only authenticated but haven't any purchases
   */
  Authenticated = 'authenticated',

  /**
   * @description user purchased pro acc
   */
  Pro = 'pro',
}
