import classnames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

export interface IProps {
  label: string
  variant?: 'normal' | 'dimmed'
  position?: string
  className?: string
}

/**
 * @description badge which automatically sets on the absolute position
 */
export const Badge: React.FCC<IProps> = ({
  label,
  variant = 'normal',
  position = '0 100%',
  children,
  className,
}) => (
  <div className={classnames(styles.container, className)}>
    <p
      className={classnames(styles.label, {
        [styles['label--dimmed']]: variant === 'dimmed',
      })}
      style={{
        top: position.split(' ')[0],
        left: position.split(' ')[1],
      }}
    >
      {label}
    </p>

    {children}
  </div>
)
