import * as React from 'react'
import { Icons } from '../icons'

export const UploadLottieButton: React.FCC = () => {
  return (
    <a
      className="flex flex-row items-center gap-2 rounded-lg bg-secondary px-3 py-2 font-body text-sm font-medium text-white transition-all duration-300 hover:scale-[1.02] hover:shadow-lg cursor-pointer"
      href="/lottie-preview"
      target="_blank"
    >
      <Icons.UploadFile />
      Upload a new file
    </a>
  )
}
