import * as UaParser from 'ua-parser-js'

export enum Os {
  Windows = 'Windows',
  Mac = 'Mac OS',
  Linux = 'Linux',
  Unknown = 'Unknown',
}

const getEngine = (): string => {
  const uaParser = new UaParser.UAParser()
  return uaParser.getBrowser().name || ''
}

const getOs = (): Os => {
  const preparedUserAgent = window.navigator.userAgent.toLowerCase()

  if (preparedUserAgent.includes('windows')) {
    return Os.Windows
  }

  if (preparedUserAgent.includes('mac')) {
    return Os.Mac
  }

  if (preparedUserAgent.includes('linux')) {
    return Os.Linux
  }

  return Os.Unknown
}

export const config = {
  webAppUrl: <string>process.env.WEB_APP_URL,
  // webAppUrl: 'http://localhost:36543',
  sharedArrayBufferSupported: typeof SharedArrayBuffer !== 'undefined',
  videoEncoderSupported: 'VideoEncoder' in window,
  getEngine,
  getOs,
}
