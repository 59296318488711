import * as Sentry from '@sentry/react'
import {
  EditorEvent,
  editorEvents,
} from 'apps/web-app/src/modules/editor/events'
import * as React from 'react'
import { useErrorBoundaryInteractor } from './interactor'
import { ErrorBoundaryRouter } from './router'

interface IErrorBoundaryInternalProps {
  onError: (error: Error) => void
}
interface IState {
  hasError: boolean
}
class ErrorBoundaryInternal extends React.Component<
  IErrorBoundaryInternalProps,
  IState
> {
  constructor(props: IErrorBoundaryInternalProps) {
    super(props)
    this.state = { hasError: false }
  }

  public static getDerivedStateFromError() {
    return { hasError: true }
  }

  public componentDidCatch(error: Error) {
    editorEvents.emit(EditorEvent.EditorCrash)
    this.props.onError(error)
    Sentry.captureException(error)
  }

  public render() {
    const { hasError } = this.state
    // @ts-ignore
    const { children } = this.props

    if (typeof children !== 'function') {
      return (
        <p>Please provide function as children to ErrorBoundary component</p>
      )
    }

    return (
      <>
        {children({
          hasError,
        })}
      </>
    )
  }
}

export interface IProps {
  projectId: string
}
export const ErrorBoundary: React.FCC<IProps> = ({ projectId, children }) => {
  const interactor = useErrorBoundaryInteractor({ projectId })

  return (
    // @ts-ignore
    <ErrorBoundaryInternal onError={interactor.handleError}>
      {({ hasError }: any) => (
        <ErrorBoundaryRouter hasError={hasError} interactor={interactor}>
          {children}
        </ErrorBoundaryRouter>
      )}
    </ErrorBoundaryInternal>
  )
}
