import _ from 'lodash'

export const pointsToPath = (points: string): string => {
  const pointsArray: string[] = points
    .trim()
    .split(' ')
    .reduce<string[]>((arr, point) => {
      return [
        ...arr,
        ...(point.includes(',')
          ? point.split(',')
          : point.trim() !== ''
            ? [point]
            : []),
      ]
    }, [])

  const pairs = _.chunk(pointsArray, 2)
  return pairs
    .map(([x, y], i) => {
      return `${i === 0 ? 'M' : 'L'}${x} ${y}`
    })
    .join(' ')
}
