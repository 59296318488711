import * as React from 'react'

import { Component } from './Component'
import { Frame } from './Frame'
import { Group } from './Group'
import { Instance } from './Instance'
import { Mask } from './Mask'
import { Slice } from './Slice'
import { Type } from './Type'
import { Vector } from './Vector'
import { VectorWithAnimatedFill } from './VectorWithAnimatedFill'
import { VectorWithImageFill } from './VectorWithImageFill'
import { Ellipse } from './ellipse'
import { Gif } from './gif'
import { Lottie } from './lottie'
import { Polygon } from './polygon'
import { Rectangle } from './rectangle'

const defaultSize = {
  x: 32,
  y: 32,
}

export enum LayerType {
  Component = 'COMPONENT',
  Ellipse = 'ELLIPSE',
  Frame = 'FRAME',
  Gif = 'GIF',
  Group = 'GROUP',
  Instance = 'INSTANCE',
  Lottie = 'LOTTIE',
  Mask = 'MASK',
  Polygon = 'POLYGON',
  Rectangle = 'RECTANGLE',
  Slice = 'SLICE',
  Text = 'TEXT',
  Vector = 'VECTOR',
  VectorWithAnimatedFill = 'VECTOR_WITH_ANIMATED_FILL',
  VectorWithImageFill = 'VECTOR_WITH_IMAGE_FILL',
}

export interface IProps {
  type: LayerType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Layer: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  if (LayerType.Component === type) {
    return <Component size={size} style={style} />
  }

  if (LayerType.Ellipse === type) {
    return <Ellipse size={size} style={style} />
  }

  if (LayerType.Frame === type) {
    return <Frame size={size} style={style} />
  }

  if (LayerType.Gif === type) {
    return <Gif size={size} style={style} />
  }

  if (LayerType.Group === type) {
    return <Group size={size} style={style} />
  }

  if (LayerType.Instance === type) {
    return <Instance size={size} style={style} />
  }

  if (LayerType.Lottie === type) {
    return <Lottie size={size} style={style} />
  }

  if (LayerType.Mask === type) {
    return <Mask size={size} style={style} />
  }

  if (LayerType.Polygon === type) {
    return <Polygon size={size} style={style} />
  }

  if (LayerType.Rectangle === type) {
    return <Rectangle size={size} style={style} />
  }

  if (LayerType.Slice === type) {
    return <Slice size={size} style={style} />
  }

  if (LayerType.Text === type) {
    return <Type size={size} style={style} />
  }

  if (LayerType.Vector === type) {
    return <Vector size={size} style={style} />
  }

  if (LayerType.VectorWithAnimatedFill === type) {
    return <VectorWithAnimatedFill size={size} style={style} />
  }

  if (LayerType.VectorWithImageFill === type) {
    return <VectorWithImageFill size={size} style={style} />
  }

  return null
}
