import {
  ChildrenRelationsAspect,
  Entity,
  MaskComponent,
  ParentRelationAspect,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import * as R from 'ramda'

/**
 * @description Go through node neighbors and check if there any mask should be applied.
 * If so then return that layer.
 * If not then return parent layer.
 */
export function getMaskLayerFor(node: Entity): Entity | undefined {
  if (node.hasAspect(ParentRelationAspect) === false) {
    return undefined
  }

  // @NOTE: reverse required to properly set layers for lottie
  const childrenToWork: Entity[] = R.reverse(
    node
      .getAspectOrThrow(ParentRelationAspect)
      .getParentEntityOrThrow()
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()
  ).filter(
    (child) => child.getComponentOrThrow(VisibleInViewportComponent).value
  )
  const parentHasMask =
    childrenToWork.find(
      (child) => child.getComponentOrThrow(MaskComponent).value
    ) != null

  if (parentHasMask) {
    const currentNodeIdx = childrenToWork.findIndex((n) => n.id === node.id)
    const layersCount = childrenToWork.length

    for (let i = 0; i < layersCount; i += 1) {
      const currentLayer = childrenToWork[i]

      if (
        currentLayer.getComponentOrThrow(MaskComponent).value &&
        i > currentNodeIdx
      ) {
        return currentLayer
      }
    }
  }

  return node.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow()
}
