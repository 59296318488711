import classNames from 'classnames'
import * as React from 'react'
import { Icon } from './types'

const defaultSize = {
  x: 16,
  y: 16,
}
export const SyncProject: React.FC<Icon> = ({
  size = defaultSize,
  className,
  color = '#FFFFFF',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames('flex-shrink-0', className)}
    stroke={color}
  >
    <path
      d="M2 7.5C2.1987 5.98839 2.86201 4.58778 3.88773 3.51392C4.91346 2.44006 6.2447 1.75252 7.67639 1.55722C9.10809 1.36191 10.5608 1.66968 11.8108 2.43311C13.0607 3.19654 14.0386 4.37327 14.5938 5.78205M15 2.34614V5.78205H11.75"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15 8.5C14.8013 10.0116 14.138 11.4122 13.1123 12.4861C12.0865 13.5599 10.7553 14.2475 9.32361 14.4428C7.89191 14.6381 6.43919 14.3303 5.18923 13.5669C3.93926 12.8035 2.96139 11.6267 2.40625 10.218M2 13.6539V10.218H5.25"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
