import { Lottie } from '@aninix/app-design-system'
import * as React from 'react'

import { TTabs } from '..'
import placeholder from './placeholder.json'

export interface IProps {
  selectedTab: TTabs
}

export const NoSegmentsToInspect: React.FCC<IProps> = ({ selectedTab }) => {
  const description = React.useMemo(() => {
    switch (selectedTab) {
      case 'inspect':
        return 'Select any segment to see animation parameters.'
      case 'code':
        return 'Select any segment to see code.'
    }
  }, [selectedTab])

  return (
    <div className="flex h-full flex-col items-center justify-center gap-6 overflow-hidden">
      <Lottie
        className="h-1/2 max-h-[150px] min-h-[75px]"
        animationData={placeholder}
        loop={true}
      />
      <p className="font-body text-sm font-normal text-gray-400">
        {description}
      </p>
    </div>
  )
}

NoSegmentsToInspect.displayName = 'NoSegmentsToInspect'
