import { useLottie } from 'apps/web-app/src/use-cases/use-lottie'
import { AnimationDirection, AnimationItem } from 'lottie-web'
import * as React from 'react'

export interface IProps {
  animationItem: AnimationItem
}
export const LoopPicker: React.FCC<IProps> = ({ animationItem }) => {
  const { currentFrame, totalFrames } = useLottie(animationItem)

  const flipDirection = React.useCallback(() => {
    animationItem.setDirection(
      (animationItem.playDirection * -1) as AnimationDirection
    )
  }, [animationItem])

  const [isLoop, setIsLoop] = React.useState<boolean>(true)

  const toggleLoop = React.useCallback(() => {
    setIsLoop((v) => !v)
  }, [setIsLoop])

  React.useEffect(() => {
    if (isLoop) {
      animationItem.setDirection(1)
      return
    }

    if (
      (animationItem.playDirection === 1 && currentFrame > totalFrames - 2) ||
      (animationItem.playDirection === -1 && currentFrame < 2)
    )
      flipDirection()
  }, [currentFrame])
  return (
    <button
      onClick={toggleLoop}
      className="flex h-10 w-10 items-center justify-center hover:bg-gray-100"
    >
      {isLoop ? <LoopIcon /> : <PingPongIcon />}
    </button>
  )
}

const PingPongIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 11L7.5 11C9.433 11 11 9.433 11 7.5V7.5C11 5.567 9.433 4 7.5 4L4 4M2 11L4 13M2 11L4 9"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 2V14"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const LoopIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 3H6.77C5.11814 3 4.29221 3 3.65906 3.31637C3.07792 3.60675 2.60675 4.07792 2.31637 4.65906C2 5.29221 2 6.11814 2 7.77V8M13 3L11 1M13 3L11 5"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3 13L9.23 13C10.8819 13 11.7078 13 12.3409 12.6836C12.9221 12.3933 13.3933 11.9221 13.6836 11.3409C14 10.7078 14 9.88186 14 8.23L14 8M3 13L5 15M3 13L5 11"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

LoopPicker.displayName = 'LoopPicker'
