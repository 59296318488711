import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'

import { PresetAnimationAppearanceAlignmentInBoxType } from '../../../models/preset'
import * as styles from './index.scss'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  type: PresetAnimationAppearanceAlignmentInBoxType
  size?: Point2D
}
export const InInbox: React.FCC<IProps> = ({ type, size = defaultSize }) => {
  if (type === PresetAnimationAppearanceAlignmentInBoxType.Top) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 1H4V2H12V1ZM7.64645 12.3536L8 12.7071L8.35355 12.3536L11.3536 9.35355L10.6464 8.64645L8.5 10.7929L8.5 3L7.5 3L7.5 10.7929L5.35355 8.64645L4.64645 9.35355L7.64645 12.3536Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentInBoxType.Right) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 12L15 4L14 4L14 12L15 12ZM3.64645 7.64645L3.29289 8L3.64645 8.35355L6.64645 11.3536L7.35355 10.6464L5.20711 8.5L13 8.5L13 7.5L5.20711 7.5L7.35355 5.35355L6.64645 4.64645L3.64645 7.64645Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentInBoxType.Bottom) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4 15L12 15L12 14L4 14L4 15ZM8.35355 3.64645L8 3.29289L7.64645 3.64645L4.64645 6.64645L5.35355 7.35355L7.5 5.20711L7.5 13L8.5 13L8.5 5.20711L10.6464 7.35355L11.3536 6.64645L8.35355 3.64645Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentInBoxType.Left) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 4L1 12L2 12L2 4L1 4ZM12.3536 8.35355L12.7071 8L12.3536 7.64645L9.35355 4.64645L8.64645 5.35355L10.7929 7.5L3 7.5L3 8.5L10.7929 8.5L8.64645 10.6464L9.35355 11.3536L12.3536 8.35355Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentInBoxType.Center) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.5 14.5V1.5H1.5V14.5H14.5Z"
          className={styles.icon__stroke}
        />
        <path
          d="M9.5 8C9.5 8.82843 8.82843 9.5 8 9.5C7.17157 9.5 6.5 8.82843 6.5 8C6.5 7.17157 7.17157 6.5 8 6.5C8.82843 6.5 9.5 7.17157 9.5 8Z"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentInBoxType.None) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C9.33875 2.5 10.5658 2.97831 11.5195 3.77337L3.77337 11.5195C2.97831 10.5658 2.5 9.33875 2.5 8ZM4.48047 12.2266C5.43422 13.0217 6.66125 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 6.66125 13.0217 5.43422 12.2266 4.48047L4.48047 12.2266ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  return null
}
