import {
  Component,
  getAnimatableValue,
  mixed,
  setValueRgba,
} from '@aninix-inc/model'
import { RGBA } from '@aninix-inc/model/legacy'
import { useComponents } from '@aninix/core'
import * as R from 'ramda'

/**
 * Encapsulate logic to retrieve and update value
 */
export function useValueRgba({
  components,
  time,
}: {
  components: Component[]
  time?: number
}): {
  value: RGBA | typeof mixed
  updateValue: (newValue: RGBA) => void
} {
  useComponents(components)

  const equals = R.all(
    (component) =>
      // @TODO: optimize comparison algorithm
      R.equals(
        getAnimatableValue(component, time),
        getAnimatableValue(components[0], time)
      ),
    components
  )

  const updateValue = (newValue: RGBA) => {
    components.forEach((component) => {
      setValueRgba(component, newValue, time)
    })
  }

  const value = equals
    ? (components.map((component) =>
        getAnimatableValue(component, time)
      )[0] as RGBA)
    : mixed

  return {
    value,
    updateValue,
  }
}
