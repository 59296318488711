import { useGetProjectMeta } from '@aninix/api/get-project-meta'
import { Modal, Popover } from '@material-ui/core'
import { toast } from 'apps/web-app/src/modules/toasts'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { config } from '../../../config'
import { AddFigmaLinkForm } from './add-figma-link'
import { ShareMenuItem } from './share-menu-item'

export interface IProps {
  handleClose: () => void
  isOpen: boolean
  anchorEl: Element | null
}
export const ShareMenu: React.FCC<IProps> = ({
  handleClose,
  isOpen,
  anchorEl,
}) => {
  const { projectId } = useParams()

  const { data } = useGetProjectMeta(projectId ?? '')

  const projectLink = React.useMemo(() => `/edit/${projectId}`, [projectId])

  const specsLink = React.useMemo(
    () => `/inspect/${projectId}/details`,
    [projectId]
  )

  const handleCopyClick = React.useCallback(() => {
    handleClose()
    if (projectId !== undefined) {
      window.navigator.clipboard.writeText(`${config.webAppUrl}${projectLink}`)
      toast('🔗 Project link is copied')
    } else {
      toast("🔗 Couldn't copy project link", { variant: 'error' })
    }
  }, [projectLink, projectId])

  const [isAddFigmaLinkOpen, setIsAddFigmaLinkOpen] =
    React.useState<boolean>(false)

  const openAddFigmaLink = React.useCallback(() => {
    handleClose()
    setIsAddFigmaLinkOpen(true)
  }, [])
  const closeAddFigmaLink = React.useCallback(() => {
    setIsAddFigmaLinkOpen(false)
  }, [])
  const toggleAddFigmaLinkOpen = React.useCallback(() => {
    setIsAddFigmaLinkOpen((value) => !value)
  }, [])

  const handleAddFigmaLinkClick = React.useCallback(() => {
    handleClose()
    openAddFigmaLink()
  }, [handleClose, openAddFigmaLink])

  const handleCopyFigmaLinkClick = React.useCallback(() => {
    handleClose()
    if (typeof data?.data.figmaUrl === 'string') {
      window.navigator.clipboard.writeText(data?.data.figmaUrl)
      toast('🔗 Figma link is copied')
    } else {
      toast("🔗 Couldn't copy Figma link", { variant: 'error' })
    }
  }, [data?.data.figmaUrl])

  const handleCopySpecsClick = React.useCallback(() => {
    handleClose()
    if (projectId !== undefined) {
      window.navigator.clipboard.writeText(`${config.webAppUrl}${specsLink}`)
      toast('🔗 Specifications link is copied')
    } else {
      toast("🔗 Couldn't copy specifications link", { variant: 'error' })
    }
  }, [specsLink, projectId])

  return (
    <>
      <Modal
        open={isAddFigmaLinkOpen}
        onClose={closeAddFigmaLink}
        BackdropProps={{ color: '#0000004D' }}
      >
        <AddFigmaLinkForm handleClose={closeAddFigmaLink} />
      </Modal>

      <Popover
        PaperProps={{ style: { background: 'transparent' } }}
        // hideBackdrop
        onClose={handleClose}
        elevation={0}
        open={isOpen}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="pt-4">
          <div className="w-[210px] rounded-lg bg-black py-[3px]">
            <ShareMenuItem
              Icon={CopyIcon}
              onClick={handleCopyClick}
              label="Copy project link"
            />

            {typeof data?.data.figmaUrl === 'string' ? (
              <ShareMenuItem
                Icon={CopyFigmaLinkIcon}
                onClick={handleCopyFigmaLinkClick}
                label="Copy Figma link"
              />
            ) : (
              <ShareMenuItem
                Icon={AddFigmaLinkIcon}
                onClick={handleAddFigmaLinkClick}
                label="Add Figma link"
              />
            )}

            <ShareMenuItem
              Icon={CopySpecsIcon}
              onClick={handleCopySpecsClick}
              label="Copy specification link"
            />
          </div>
        </div>
      </Popover>
    </>
  )
}

ShareMenu.displayName = 'ShareMenu'

const CopyFigmaLinkIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.91666 5.00008H15.4167M2.91666 13.3334H6.66666M4.99999 2.91675V15.4167M13.3333 2.91675V6.66675"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.771 14.8963L14.8963 11.771M12.8128 10.2084L13.0539 9.92923C13.5424 9.44081 14.2049 9.16646 14.8957 9.1665C15.5864 9.16655 16.2489 9.44101 16.7373 9.92949C17.2257 10.418 17.5001 11.0805 17.5 11.7712C17.5 12.462 17.2255 13.1245 16.737 13.6129L16.4589 13.8545M13.8545 16.4589L13.6477 16.7371C13.1536 17.2258 12.4866 17.4998 11.7916 17.4998C11.0966 17.4998 10.4296 17.2258 9.93545 16.7371C9.69187 16.4962 9.49849 16.2094 9.3665 15.8933C9.23452 15.5772 9.16656 15.2381 9.16656 14.8955C9.16656 14.553 9.23452 14.2138 9.3665 13.8977C9.49849 13.5816 9.69187 13.2948 9.93545 13.054L10.2084 12.8128"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const CopySpecsIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 6.66675L17.5 10.0001L14.1667 13.3334"
        stroke="#89909A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.83333 6.66675L2.5 10.0001L5.83333 13.3334"
        stroke="#89909A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6666 3.33325L8.33331 16.6666"
        stroke="#89909A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const AddFigmaLinkIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 5.00006L4.99999 5.00008L5 3.00006M2.91666 13.3334H4.99999V15.4167M4.99999 11.0001L5 10.5001M4.99999 7.91675L5 7.50006M13.3333 2.91675V5.00008H15.4167M11.25 5.00008H10.4167M7.91666 5.00008H7.08332"
        stroke="#89909A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8128 10.2084L13.0539 9.92923C13.5424 9.44081 14.2049 9.16646 14.8957 9.1665C15.5864 9.16655 16.2489 9.44101 16.7373 9.92949C17.2257 10.418 17.5001 11.0805 17.5 11.7712C17.5 12.462 17.2255 13.1245 16.737 13.6129L16.4589 13.8545M13.8545 16.4589L13.6477 16.7371C13.1536 17.2258 12.4866 17.4998 11.7916 17.4998C11.0966 17.4998 10.4296 17.2258 9.93545 16.7371C9.69187 16.4962 9.49849 16.2094 9.3665 15.8933C9.23452 15.5772 9.16656 15.2381 9.16656 14.8955C9.16656 14.553 9.23452 14.2138 9.3665 13.8977C9.49849 13.5816 9.69187 13.2948 9.93545 13.054L10.2084 12.8128"
        stroke="#89909A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const CopyIcon = (props: React.HTMLAttributes<SVGSVGElement>) => {
  return (
    <svg
      {...props}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0832 7.91675L7.91652 12.0834"
        stroke="#89909A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16679 5.41659L9.79179 4.79159C11.2876 3.29581 13.7127 3.29581 15.2085 4.79158V4.79158C16.7042 6.28736 16.7042 8.71248 15.2085 10.2083L14.5835 10.8333"
        stroke="#89909A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8351 14.5852L10.2083 15.2112C8.71232 16.7056 6.28833 16.705 4.79316 15.2098V15.2098C3.29687 13.7134 3.29755 11.2873 4.79467 9.79177L5.41861 9.16851"
        stroke="#89909A"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
