import { useCommentContext } from '@aninix/core/modules/comments-wrapper/use-cases/use-comment-context'
import { IComment } from '@aninix/core/use-cases/comments/use-get-comments'
import classNames from 'classnames'
import { intlFormatDistance } from 'date-fns'
import _ from 'lodash'
import * as React from 'react'

export const BubbleCommentItem: React.FCC<{
  data: IComment
  replyTo?: IComment
  index: number
  isLast: boolean
  isDragged: boolean
  isExpanded: boolean
}> = ({ data, index, isLast, replyTo, isDragged, isExpanded }) => {
  const {
    id,
    comment,
    rectangle,
    time,
    isResolved,
    replies,
    author,
    createdAt,
    updatedAt,
    //this means if there's replies - take last one as the source. if no replies - take the original comment
  } = (data.replies?.length ?? 0) > 0 ? data.replies?.at(-1)! : data

  const formattedDistance = React.useMemo(
    () => intlFormatDistance(new Date(createdAt), new Date()),
    [createdAt]
  )

  const uniqueCommentsByAuthors = React.useMemo(
    () =>
      _.uniqBy([data, ...(data?.replies ?? [])], (reply) => reply.author.id),
    [data]
  )

  const { selectedCommentId, setSelectedCommentId } = useCommentContext()
  const isSelected = selectedCommentId === data.id

  const handleBubbleClick = React.useCallback(() => {
    if (isDragged) return
    setSelectedCommentId(data.id)
  }, [isDragged])

  return (
    <>
      <div
        onClick={handleBubbleClick}
        className={classNames(
          'flex flex-col px-1 transition-all duration-300 group-hover:px-3',
          {
            ['bg-[#EDF5FA]']: isSelected,
            ['px-3']: isExpanded,
          }
        )}
      >
        <div className="flex flex-col gap-2 py-3">
          <div className="flex flex-row gap-1">
            {uniqueCommentsByAuthors.map((comment) => (
              <img
                draggable={false}
                key={comment.author.id}
                className={classNames(
                  'h-4 w-4 rounded-full object-cover opacity-0 transition-all duration-300  group-hover:h-6 group-hover:w-6 group-hover:opacity-100',
                  {
                    [' h-6 w-6 opacity-100']: isExpanded,
                  }
                )}
                crossOrigin="anonymous"
                src={comment.author.avatarUrl}
              />
            ))}
          </div>
          <div>
            <p className="font-body text-[12px] font-normal text-black">
              <span className="opacity-80">{author.name}</span>
              <span className="opacity-30">
                {` `}
                {formattedDistance}
              </span>
            </p>
          </div>
          <p className="line-clamp-3 font-body text-[11px] font-normal text-black text-opacity-80">
            {comment}
          </p>

          {data.replies && (
            <p className="font-body text-[11px] font-normal text-black text-opacity-30">{`${
              data.replies?.length
            } ${data.replies.length > 1 ? 'replies' : 'reply'}`}</p>
          )}
        </div>
        {
          //when to show separator
          !isLast && <div className="h-[1px] w-full bg-[#F0F0F0]" />
        }
      </div>
    </>
  )
}
