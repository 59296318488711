import {
  ChildrenRelationsAspect,
  Component,
  EffectsRelationsAspect,
  Entity,
  FillsRelationsAspect,
  Project,
  StrokesRelationsAspect,
  System,
  UpdatesSystem,
  getSortedKeyframes,
} from '@aninix-inc/model'
import * as R from 'ramda'
import * as React from 'react'
export { getSelection } from '@aninix-inc/model'

const getEntityOwnTime = (entity: Entity): string =>
  entity.clock.time.toString()

const getKeyframesTime = (entity: Entity): string => {
  let time = entity.id

  for (const component of entity.components) {
    for (const keyframe of getSortedKeyframes(component)) {
      time += getEntityOwnTime(keyframe)
    }
  }

  return time
}

/**
 * It's a similar to the `@aninix-inc/model` `clone` function.
 */
const getEntityTime = (entity: Entity): string => {
  let time = getEntityOwnTime(entity) + getKeyframesTime(entity)

  // @TODO: add support of color stops duplication
  if (entity.hasAspect(FillsRelationsAspect)) {
    for (const fill of entity
      .getAspectOrThrow(FillsRelationsAspect)
      .getChildrenList()) {
      time += getEntityOwnTime(fill)
      time += getKeyframesTime(fill)
    }
  }

  // @TODO: add support of color stops duplication
  if (entity.hasAspect(StrokesRelationsAspect)) {
    for (const stroke of entity
      .getAspectOrThrow(StrokesRelationsAspect)
      .getChildrenList()) {
      time += getEntityOwnTime(stroke)
      time += getKeyframesTime(stroke)
    }
  }

  if (entity.hasAspect(EffectsRelationsAspect)) {
    for (const effect of entity
      .getAspectOrThrow(EffectsRelationsAspect)
      .getChildrenList()) {
      time += getEntityOwnTime(effect)
      time += getKeyframesTime(effect)
    }
  }

  return time
}

const getComponentRelationsTime = (component: Component): string => {
  const entity = component.entity
  const project = entity.getProjectOrThrow()
  return [
    entity.clock.time,
    // @TODO: optimize relations getter
    ...project
      .getRelations()
      // @ts-ignore
      .getRelationForEntityComponent(entity.id, component.constructor.tag)
      .map((relation) => {
        const [entityId] = relation.split('/')
        // @NOTE: we assume for the moment that we have unrelated entities. So an entity may not be found within the project.
        // @TODO: find and fix the problem and then make the code more strict, e.g. throw when an entity is not found.
        return project.getEntity(entityId)?.clock.time ?? 0
      }),
  ].join('-')
}

const getEntityRelationsTime = (entity: Entity): string => {
  let time = getEntityTime(entity)

  if (entity.hasAspect(ChildrenRelationsAspect)) {
    for (const child of entity
      .getAspectOrThrow(ChildrenRelationsAspect)
      .getChildrenList()) {
      time += getEntityRelationsTime(child)
    }
  }

  return time
}

// @TODO: move to react-model library
export const useComponent = <T extends Component>(component: T): string => {
  const memoed = React.useCallback(
    (callback: () => void) => {
      return component.entity
        .getProjectOrThrow()
        .getSystemOrThrow(UpdatesSystem)
        .onUpdate((updates) => {
          if (updates.includes(component.id)) {
            callback()
          }
        })
    },
    [component]
  )

  const snap = React.useCallback(
    () => getComponentRelationsTime(component),
    [component]
  )

  return React.useSyncExternalStore(memoed, snap, snap)
}

// @TODO: move to react-model library
export const useComponents = <T extends Component>(components: T[]): string => {
  const memoed = React.useCallback(
    (callback: () => void) => {
      if (components[0] === undefined) {
        return () => {}
      }

      return components[0].entity
        .getProjectOrThrow()
        .getSystemOrThrow(UpdatesSystem)
        .onUpdate((updates) => {
          if (
            R.intersection(
              components.map((c) => c.id),
              updates
            ).length > 0
          ) {
            callback()
          }
        })
    },
    [components]
  )

  const snap = React.useCallback(() => {
    if (components.length === 0) {
      return ''
    }

    return components
      .flatMap((component) => getComponentRelationsTime(component))
      .join('-')
  }, [components])

  return React.useSyncExternalStore(memoed, snap, snap)
}

// @TODO: move to react-model library
export const useEntity = <T extends Entity>(entity: T): string => {
  const memoed = React.useCallback(
    (callback: () => void) => {
      return entity
        .getProjectOrThrow()
        .getSystemOrThrow(UpdatesSystem)
        .onUpdate((updates) => {
          if (updates.includes(entity.id)) {
            callback()
          }
        })
    },
    [entity]
  )

  const snap = React.useCallback(() => getEntityRelationsTime(entity), [entity])

  return React.useSyncExternalStore(memoed, snap, snap)
}

export const useFlattenEntities = <T extends Entity>(
  entities: T[][]
): string => {
  const flattenEntities = React.useMemo(() => entities.flat(), [entities])

  return useEntities(flattenEntities)
}

// @TODO: move to react-model library
export const useEntities = <T extends Entity>(entities: T[]): string => {
  const memoed = React.useCallback(
    (callback: () => void) => {
      if (entities.length === 0) {
        return () => {}
      }

      return entities[0]
        .getProjectOrThrow()
        .getSystemOrThrow(UpdatesSystem)
        .onUpdate((updates) => {
          const entityIdsOnly = updates.map((update) => update.split('/')[0])
          if (
            R.intersection(
              entities.map((e) => e.id),
              entityIdsOnly
            ).length > 0
          ) {
            callback()
          }
        })
    },
    [entities]
  )

  const snap = React.useCallback(
    () => entities.map((e) => getEntityRelationsTime(e)).join('-'),
    [entities]
  )

  return React.useSyncExternalStore(memoed, snap, snap)
}

export const useSystem = <T extends System>(system: T): string => {
  const version = React.useRef(0)

  const memoed = React.useCallback(
    (callback: () => void) => {
      return system
        .getProjectOrThrow()
        .getSystemOrThrow(UpdatesSystem)
        .onUpdate((updates) => {
          // @ts-ignore
          if (updates.includes(system.constructor.tag)) {
            version.current += 1
            callback()
          }
        })
    },
    [system]
  )

  const snap = React.useCallback(() => version.current.toString(), [])

  return React.useSyncExternalStore(memoed, snap, snap)
}

export const useReloadOnAnyUpdate = (
  componentOrEntityOrProject: Component | Entity | Project
): string => {
  const project = React.useMemo(() => {
    if (componentOrEntityOrProject instanceof Component) {
      return componentOrEntityOrProject.entity.getProjectOrThrow()
    }

    if (componentOrEntityOrProject instanceof Entity) {
      return componentOrEntityOrProject.getProjectOrThrow()
    }

    return componentOrEntityOrProject
  }, [componentOrEntityOrProject])

  const version = React.useRef(0)

  const memoed = React.useCallback(
    (callback: () => void) => {
      return project.getSystemOrThrow(UpdatesSystem).onUpdate(() => {
        version.current += 1
        callback()
      })
    },
    [project]
  )

  const snap = React.useCallback(() => version.current.toString(), [])

  return React.useSyncExternalStore(memoed, snap, snap)
}
