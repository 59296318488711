import { default as classNames, default as classnames } from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

const noop = () => null

export interface IProps {
  name?: React.ReactNode
  renderHeaderButtons?: () => React.ReactNode
  renderInputs?: (() => React.ReactNode) | (() => React.ReactNode)[]
  renderWideInputs?: (() => React.ReactNode) | (() => React.ReactNode)[]
  empty?: boolean
  onHeaderClick?: () => void
  wide?: boolean
}
/**
 * @description property row used in properties panel
 */
export const PropertyRow: React.FCC<IProps> = ({
  name,
  renderHeaderButtons = noop,
  renderInputs,
  renderWideInputs,
  empty = false,
  onHeaderClick,
  wide = false,
}) => {
  const header = (() => {
    if (name == null) {
      return null
    }

    if (onHeaderClick != null) {
      return (
        <button
          onClick={onHeaderClick}
          className={classNames(styles.title, styles['title--clickable'])}
        >
          {name}
        </button>
      )
    }

    return <p className={styles.title}>{name}</p>
  })()

  return (
    <div className={styles.wrapper}>
      <div
        className={classnames(styles.container, {
          [styles['container--empty']]: empty,
        })}
      >
        <div>
          {(name != null || renderHeaderButtons() != null) && (
            <div
              className={classNames(styles.header, {
                [styles['header--wide']]: wide,
              })}
            >
              {header}

              <div className={styles.buttons}>{renderHeaderButtons()}</div>
            </div>
          )}

          {renderInputs != null && (
            <div className={styles['inputs-wrapper']}>
              {typeof renderInputs === 'function' && (
                <div className={styles.inputs}>{renderInputs()}</div>
              )}

              {typeof renderInputs === 'object' && renderInputs.length > 0 && (
                <>
                  {renderInputs.map((render, idx) => (
                    <div className={styles.inputs} key={idx}>
                      {render()}
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
        </div>

        {renderWideInputs != null && (
          <div className={styles['wide-inputs-wrapper']}>
            {typeof renderWideInputs === 'function' && (
              <div className={styles.inputs}>{renderWideInputs()}</div>
            )}

            {typeof renderWideInputs === 'object' &&
              renderWideInputs.length > 0 && (
                <>
                  {renderWideInputs.map((render, idx) => (
                    <div className={styles.inputs} key={idx}>
                      {render()}
                    </div>
                  ))}
                </>
              )}
          </div>
        )}
      </div>
    </div>
  )
}
