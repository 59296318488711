import {
  RgbaValueComponent,
  Root,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import { PropertyRowV2 } from '@aninix/app-design-system'
import { useEntity, useProject } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { RgbaValue } from '../../values/rgba'

export const BackgroundColor: React.FC = () => {
  const root = useProject().getEntityByTypeOrThrow(Root)
  const component = root.getComponentOrThrow(RgbaValueComponent)

  return <BackgroundColorMemo component={component} />
}

BackgroundColor.displayName = 'BackgroundColor'

const BackgroundColorMemo: React.FC<{ component: RgbaValueComponent }> =
  React.memo(
    ({ component }) => {
      useEntity(component.entity)
      return (
        <PropertyRowV2
          name="Background color"
          inputs={<RgbaValue components={[component]} />}
        />
      )
    },
    (prev, next) =>
      R.equals(prev.component, next.component) &&
      prev.component.entity.getComponentOrThrow(VisibleInViewportComponent)
        .value ===
        next.component.entity.getComponentOrThrow(VisibleInViewportComponent)
          .value
  )

BackgroundColorMemo.displayName = 'BackgroundColorMemo'
