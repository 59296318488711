import * as React from 'react'
import { Typography } from '../../components/design-system/typography'
import { AninixIcon } from './aninix-icon'
import { List } from './list'

export interface IProps {
  onAddAccount: () => void
  tokens?: string[]
  reloadAfterSelection?: boolean
}
export const SelectUser: React.FCC<IProps> = ({
  onAddAccount,
  tokens,
  reloadAfterSelection = false,
}) => (
  <div className="h-screen w-screen overflow-auto">
    <div className="flex min-h-screen w-full flex-col items-center gap-6 pb-16 pt-[20vh]">
      <>
        <div className="flex flex-col items-center gap-4">
          <AninixIcon />
          <div className="flex flex-col items-center gap-0">
            <Typography style="Heading4" text={'Choose an account '} />
            <Typography style="Body3Regular" text={'to access this resource'} />
          </div>
        </div>

        <List
          onAddAccount={onAddAccount}
          _tokens={tokens}
          reloadAfterSelection={reloadAfterSelection}
        />
      </>
    </div>
  </div>
)

SelectUser.displayName = 'SelectUser'
