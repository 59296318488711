import * as React from 'react'

import { Left } from './left'
import { Right } from './right'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
  type: 'left' | 'right'
}
export const TrimLayer: React.FCC<IProps> = ({
  size = defaultSize,
  style,
  type,
}) => {
  if (type === 'left') {
    return <Left size={size} style={style} />
  }

  if (type === 'right') {
    return <Right size={size} style={style} />
  }

  return null
}
