import * as React from 'react'

const defaultSize = { x: 13, y: 14 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const VerticalBottomOutside: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 13 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 14L5 6H3L3 14H5ZM13 0V3.5V4H12.5L0.5 4H0L0 3.5L0 0H1L1 3L12 3V0H13ZM10 6L10 11L8 11V6H10Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
