import { getStripeCustomerPortal } from '@aninix/api'
import { FullPageLoader } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { GeneralAccessRestricted } from '../../components/status-pages'
import { useSessionStore } from '../../stores'
import { useCurrentTeamStore } from '../../use-cases'

export interface IProps {}
// @TODO: refactor because we have a mess with payment modules
export const ManageSubscription: React.FCC<IProps> = observer(() => {
  const sessionStore = useSessionStore()
  const { currentTeam } = useCurrentTeamStore()

  const [url, setUrl] = React.useState<string | null | undefined>(undefined)

  const redirectToCustomerPortal = React.useCallback(async () => {
    try {
      const result = (
        await getStripeCustomerPortal({
          teamId: currentTeam?.id != null ? currentTeam!.id : undefined,
          // @TODO: provide different urls for user / team
          redirectUrlOnSuccess: '/',
        })
      ).data
      setUrl(result.url)
    } catch (err: unknown) {
      // @TODO: add custom erro handling logic
      setUrl(null)
      console.error('Error happen while generating customer portal url', err)
      window.history.back()
    }
  }, [currentTeam])

  React.useEffect(() => {
    redirectToCustomerPortal()

    if (url !== undefined && url !== null) window.location.assign(url)
  }, [url])

  if (url === null) return <GeneralAccessRestricted />

  return <FullPageLoader />
})

ManageSubscription.displayName = 'ManageSubscription'
