import * as React from 'react'
import { Link } from 'react-router-dom'

import { AccessRestrictedIcon } from './icon'

export interface IProps {
  redirectUrlOnSuccess?: string
  autoRedirect?: boolean
}
export const AccessRestricted: React.FC<IProps> = ({
  redirectUrlOnSuccess = '/',
  autoRedirect = false,
}) => {
  return (
    <div className="mx-auto flex h-screen w-screen max-w-md flex-col items-center justify-center text-center text-black/80">
      <AccessRestrictedIcon className="w-[258px]" />

      <p className="mt-6 text-2xl font-semibold">File is private</p>

      <p className="mt-2 text-base">
        Ask the owner of the file to make it available to everyone.
        <br />
        If you are the owner, please log in to access the file.
      </p>

      <Link
        to={`/login?redirectUrlOnSuccess=${redirectUrlOnSuccess}&autoRedirect=${autoRedirect}`}
        className="mt-6 rounded-lg bg-[#0B1118] px-4 py-2 text-base text-white"
      >
        Login
      </Link>
    </div>
  )
}
