import {
  BlendModeComponent,
  Entity,
  FpsComponent,
  NameComponent,
  Root,
  getEndTime,
  getStartTime,
} from '@aninix-inc/model'

import { LottieLayerBase, mapBlendModeToLottie } from '../types'
import {
  TransformProperties,
  defaultTransformProperties,
} from './transform-properties'

/**
 * @description extract base info from node
 */
export function Base(payload: {
  node: Entity
  parent?: number
  skipAnimation?: boolean
}): LottieLayerBase {
  const randomIdx = Math.round(Math.random() * 1000000000)
  const { node, parent, skipAnimation } = payload
  const project = node.getProjectOrThrow()
  const projectFps = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(FpsComponent).value
  const name = node.getComponentOrThrow(NameComponent).value

  return {
    ddd: 0,
    ind: randomIdx,
    // type should be here
    hd: false,
    nm: name,
    // @NOTE: time-stretch required to render in react-native on android device
    sr: 1,
    parent,
    // mask should be here
    ks: skipAnimation
      ? {
          ...defaultTransformProperties,
          o: TransformProperties(node).o,
        }
      : TransformProperties(node),
    ao: 0,
    ip: getStartTime(node) * projectFps,
    op: getEndTime(node) * projectFps,
    st: 0,
    bm: mapBlendModeToLottie(
      node.getComponentOrThrow(BlendModeComponent).value
    ),
    ...(name[0] === '.' && { cl: name.substring(1) }),
    ...(name[0] === '#' && { ln: name.substring(1) }),
  }
}
