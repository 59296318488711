import * as React from 'react'

import { useCurrentRowContext } from '../use-current-row-context'

export interface IProps {}
export const Avatar: React.FCC = ({}) => {
  const { status, avatarUrl } = useCurrentRowContext()

  return (
    <img
      crossOrigin="anonymous"
      className="h-10 w-10 rounded-full object-cover"
      src={
        status === 'invite_pending'
          ? '/images/pendingAvatar.png'
          : (avatarUrl ?? '/images/defaultAvatar.png')
      }
    />
  )
}

Avatar.displayName = 'Avatar'
