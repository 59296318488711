import {
  DurationComponent,
  Entity,
  EntityType,
  Root,
  SelectionSystem,
  TimeComponent,
} from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import * as React from 'react'
import { usePlayback, useProject } from '../../../../../../../stores'

export interface IProps {
  segment: [Entity, Entity]
}
export const Segment: React.FCC<IProps> = observer(({ segment }: IProps) => {
  const project = useProject()
  const playback = usePlayback()
  const selection = project.getSystemOrThrow(SelectionSystem)

  const projectDuration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value

  const startTimeInPercents =
    R.head(segment).getComponentOrThrow(TimeComponent).value / projectDuration

  const durationInPercents =
    (R.last(segment)!.getComponentOrThrow(TimeComponent).value -
      R.head(segment)!.getComponentOrThrow(TimeComponent).value) /
    projectDuration

  const isSelected = R.pipe(
    (keyframes: Entity[]) => R.aperture(2, keyframes),
    R.find(
      ([left, right]: [Entity, Entity]) =>
        left.id === R.head(segment)!.id && right.id === R.last(segment)!.id
    )
  )(selection.getEntitiesByEntityType(EntityType.Keyframe))
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      selection.replace(segment.map((key) => key.id))
      playback.updateTime(segment[0].getComponentOrThrow(TimeComponent).value)
    },
    []
  )

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()

      selection.replace(segment.map((key) => key.id))
      // @ts-ignore
      startListen(e)
    },
    []
  )

  const { startListen, isListening, offsetX, endAtX } = useMouseMove()

  const progressRef = React.useRef<HTMLDivElement>(null)

  const [beenPlaying, setBeenPlaying] = React.useState<boolean | null>(null)

  const managePlaybackOnTimelineInteraction = () => {
    if (isListening && beenPlaying === null) {
      setBeenPlaying(playback.isPlaying)
      if (playback.isPlaying) playback.pause()
    }

    if (!isListening) {
      setBeenPlaying(null)
      if (beenPlaying) playback.play()
    }
  }

  React.useEffect(() => {
    managePlaybackOnTimelineInteraction()

    if (isListening === false) {
      return
    }

    const { left, width } =
      progressRef.current!.parentElement!.getBoundingClientRect()

    playback.updateTime(((endAtX - left) / width) * projectDuration)
  }, [isListening, offsetX, endAtX])

  return (
    <div
      ref={progressRef}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      className={classNames('relative h-5', {
        ['bg-[var(--figma-color-text)] text-white ']: isSelected,
        ['text-[var(--figma-color-text)] ']: !isSelected,
      })}
    >
      <div
        className={classNames('absolute top-[calc(50%-2px)] h-1 rounded-lg ', {
          ['bg-white']: isSelected,
          ['bg-[var(--figma-color-text)]']: !isSelected,
        })}
        style={{
          left: startTimeInPercents * 100 + '%',
          width: durationInPercents * 100 + '%',
        }}
      ></div>
    </div>
  )
})

Segment.displayName = 'Segment'
