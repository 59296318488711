import * as R from 'ramda'
import * as React from 'react'

import {
  Entity,
  TargetRelationAspect,
  TimeComponent,
  UpdatesSystem,
  commitUndo,
} from '@aninix-inc/model'
import { InputWithIcon, PropertyRowV2, icons } from '@aninix/app-design-system'
import { useFlattenEntities } from '@aninix/core'
import { useFormatTime, useFormattedTime } from '../../formatted-time'
import { useThreshold } from '../../threshold'

export interface IProps {
  segments: [Entity, Entity][]
}
export const Start: React.FCC<IProps> = ({ segments }) => {
  useFlattenEntities(segments)
  const minTime = segments.reduce(
    (acc, segment) =>
      acc < segment[0].getComponentOrThrow(TimeComponent).value
        ? acc
        : segment[0].getComponentOrThrow(TimeComponent).value,
    Infinity
  )
  const { value, suffix } = useFormattedTime(minTime)
  const { toSeconds } = useFormatTime()
  const keyframes = segments.flat()
  const project = keyframes[0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const keyframesByProperties = R.values(
    R.groupBy(
      (keyframe) =>
        keyframe.getAspectOrThrow(TargetRelationAspect).getRelationOrThrow(),
      keyframes
    )
  ) as Entity[][]

  const updateValue = React.useCallback(
    (time: number): void => {
      updates.batch(() => {
        const roundedTime = toSeconds(time)

        const startTime = keyframesByProperties
          .map(
            (property) => property[0].getComponentOrThrow(TimeComponent).value
          )
          .reduce((min, time) => (time < min ? time : min), Infinity)

        // NOTE: dirty fix related to the ANI-1609.
        // Required to prevent keyframes to be processed twice in cases when we have 3 keyframes [x, y, z].
        // In that case we process `y` keyframe twice. For the first segment and for the second segment.
        const processedKeys = new WeakSet<Entity>()
        keyframesByProperties.forEach((keyframesByProperty) => {
          const propertyStartTime =
            R.head(keyframesByProperty)!.getComponentOrThrow(
              TimeComponent
            ).value
          const propertyDiff = propertyStartTime - startTime

          keyframesByProperty.forEach((key) => {
            if (processedKeys.has(key)) {
              return
            }

            processedKeys.add(key)
            const keyframeDiff =
              key.getComponentOrThrow(TimeComponent).value - propertyStartTime
            key.updateComponent(
              TimeComponent,
              roundedTime + propertyDiff + keyframeDiff
            )
          })
        })
      })
    },
    [keyframesByProperties, toSeconds, updates]
  )

  const updateByDelta = React.useCallback(
    (delta: number): void => {
      updates.batch(() => {
        const roundedTime = toSeconds(delta)

        // NOTE: dirty fix related to the ANI-1609.
        // Required to prevent keyframes to be processed twice in cases when we have 3 keyframes [x, y, z].
        // In that case we process `y` keyframe twice. For the first segment and for the second segment.
        const processedKeys = new WeakSet<Entity>()
        keyframesByProperties.forEach((keyframesByProperty) => {
          keyframesByProperty.forEach((key) => {
            if (processedKeys.has(key)) {
              return
            }

            processedKeys.add(key)
            key.updateComponent(
              TimeComponent,
              key.getComponentOrThrow(TimeComponent).value + roundedTime
            )
          })
        })
      })
    },
    [keyframesByProperties, toSeconds, updates]
  )

  const threshold = useThreshold()

  const segmentIds = segments.map((segment) => [segment[0].id, segment[1].id])
  const onEndChange = React.useCallback(() => {
    commitUndo(segments[0][0].getProjectOrThrow())
  }, [segments])

  const id = React.useMemo(
    () =>
      segmentIds.length === 1
        ? `${segmentIds[0]}-${segmentIds[1]}-start-time`
        : 'mixed-start-input',
    [segmentIds]
  )

  const formatValue = React.useCallback(
    (providedValue: number): string => `${providedValue}${suffix}`,
    [suffix]
  )

  return (
    <PropertyRowV2
      name="Start"
      inputs={
        <InputWithIcon
          id={id}
          value={value}
          icon={<icons.Time />}
          threshold={threshold}
          onChange={updateValue}
          onDeltaChange={updateByDelta}
          onEndChange={onEndChange}
          format={formatValue}
        />
      }
    />
  )
}
