import {
  NumberKeyframe,
  StrokeBottomWeightComponent,
  StrokeLeftWeightComponent,
  StrokeRightWeightComponent,
  StrokeTopWeightComponent,
  StrokeWeightComponent,
  getValueNumber,
  isIndividualStrokesAvailable,
  isIndividualStrokesEnabled,
  round,
} from '@aninix-inc/model'
import { icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 12,
  y: 12,
}

export enum StrokeSide {
  Top = 'strokeTopWeight',
  Bottom = 'strokeBottomWeight',
  Left = 'strokeLeftWeight',
  Right = 'strokeRightWeight',
}

const iconMap: Record<StrokeSide, React.ReactNode> = {
  [StrokeSide.Top]: <icons.StrokeTopWeight size={iconSize} />,
  [StrokeSide.Bottom]: <icons.StrokeBottomWeight size={iconSize} />,
  [StrokeSide.Left]: <icons.StrokeLeftWeight size={iconSize} />,
  [StrokeSide.Right]: <icons.StrokeRightWeight size={iconSize} />,
}

const componentMap: Record<
  StrokeSide,
  | typeof StrokeTopWeightComponent
  | typeof StrokeBottomWeightComponent
  | typeof StrokeLeftWeightComponent
  | typeof StrokeRightWeightComponent
> = {
  [StrokeSide.Top]: StrokeTopWeightComponent,
  [StrokeSide.Bottom]: StrokeBottomWeightComponent,
  [StrokeSide.Left]: StrokeLeftWeightComponent,
  [StrokeSide.Right]: StrokeRightWeightComponent,
}

export interface IProps {
  side: StrokeSide
}
export const SpecificStrokeWidth: React.FC<IProps> = ({ side }) => {
  const [isEditable, setIsEditable] = React.useState<boolean>(false)
  const { time, nodes, snapshots } = useNodePropertiesPanel()
  useEntities(nodes)

  const filteredNodes = nodes.filter(
    (n) =>
      n.hasComponent(componentMap[side]) &&
      isIndividualStrokesAvailable(n) &&
      isIndividualStrokesEnabled(n)
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const components = nodes.map((l) => l.getComponentOrThrow(componentMap[side]))

  const widths = snapshots.map((s) => s[side])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <SpecificStrokeWidthEditable
          time={time}
          components={components}
          icon={iconMap[side]}
        />
      ) : (
        <SpecificStrokeWidthDisplay
          time={time}
          components={components}
          icon={iconMap[side]}
          widths={widths}
        />
      )}
    </div>
  )
}

SpecificStrokeWidth.displayName = 'SpecificStrokeWidth'

const SpecificStrokeWidthEditable: React.FC<{
  time: number
  components: StrokeWeightComponent[]
  icon: React.ReactNode
}> = ({ time, components, icon }) => {
  return (
    <div className="flex flex-row w-full justify-between">
      <NumberValue
        components={components}
        time={time}
        icon={icon}
        min={0}
        format={(v) => `${round(v, { fixed: 2 })}`}
      />

      <KeyframesPropertyControl
        components={components}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  )
}

interface SpecificStrokeWidthDisplayProps {
  time: number
  components: StrokeWeightComponent[]
  icon: React.ReactNode
  widths: number[]
}

const propsAreEqual = (
  prev: SpecificStrokeWidthDisplayProps,
  next: SpecificStrokeWidthDisplayProps
) => {
  if (prev.widths.length !== next.widths.length) return false
  for (let i = 0; i < prev.widths.length; i++) {
    if (prev.widths[i] !== next.widths[i]) return false
  }
  return true
}

const SpecificStrokeWidthDisplay: React.FC<SpecificStrokeWidthDisplayProps> =
  React.memo(({ time, components, icon }) => {
    return (
      <div className="flex flex-row w-full justify-between">
        <NumberValue
          components={components}
          time={time}
          icon={icon}
          min={0}
          format={(v) => `${round(v, { fixed: 2 })}`}
        />

        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>
    )
  }, propsAreEqual)

SpecificStrokeWidthDisplay.displayName = 'SpecificStrokeWidthDisplay'
