import * as React from 'react'

const sizeDefault = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Angle: React.FCC<IProps> = ({ size = sizeDefault, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2V13.4996V13.9996H2.5H13.9996V12.9996H3V2H2ZM3.26679 7.86609C5.95446 7.86609 8.13325 10.0449 8.13325 12.7325H9.13325C9.13325 9.49259 6.50675 6.86609 3.26679 6.86609V7.86609Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
