import * as React from 'react'

import { IIcon } from '.'

const defaultSize = { x: 24, y: 24 }

export const Cross: React.FCC<IIcon> = ({
  size = defaultSize,
  className = 'stroke-[#89909A]',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 24 24`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.80005 8L12 12.2L16.2 8"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.2 16L12 11.8L7.79995 16"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
