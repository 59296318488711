import { Entity, EntryComponent, ParentRelationAspect } from '@aninix-inc/model'

import { NullLayer } from './null-layer'
import { LottieLayer } from './types'

export function NullLayersChain(node: Entity): LottieLayer[] {
  let currentNode: Entity = node
  let stack: Entity[] = [node]
  let lottieLayers: LottieLayer[] = []

  while (
    currentNode.getAspect(ParentRelationAspect) !== undefined &&
    currentNode.hasComponent(EntryComponent) === false
  ) {
    currentNode = currentNode
      .getAspectOrThrow(ParentRelationAspect)
      .getParentEntityOrThrow()
    stack.unshift(currentNode)
  }

  stack.forEach((nodeFromStack, idx) => {
    const parent = lottieLayers[idx - 1]
    const nullLayer = NullLayer({
      node: nodeFromStack,
      parent: parent != null ? parent.ind : undefined,
    })
    lottieLayers.push(nullLayer)
  })

  return lottieLayers
}
