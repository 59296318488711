import lottie from 'lottie-web'
import * as React from 'react'

export interface IProps {
  animationData: unknown
  loop?: boolean
  className?: string
  renderer?: 'svg' | 'canvas'
}
export const Lottie: React.FCC<IProps> = ({
  animationData,
  loop = true,
  className,
  renderer = 'svg',
}) => {
  const containerRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    lottie.loadAnimation({
      container: containerRef.current!,
      animationData,
      loop,
      renderer,
    })
  }, [])

  return <div ref={containerRef} className={className} />
}

Lottie.displayName = 'Lottie'
