import {
  BlurRadiusComponent,
  BottomLeftCornerRadiusComponent,
  BottomRightCornerRadiusComponent,
  Component,
  ConstructorWithTag,
  CornerRadiusComponent,
  DashComponent,
  DashOffsetComponent,
  EndAngleComponent,
  Entity,
  GapComponent,
  InnerRadiusComponent,
  OpacityComponent,
  PointCountComponent,
  PositionComponent,
  RgbaValueComponent,
  RotationComponent,
  ScaleComponent,
  ShadowColorComponent,
  ShadowOffsetComponent,
  ShadowRadiusComponent,
  ShadowSpreadComponent,
  SizeComponent,
  SkewComponent,
  StartAngleComponent,
  StrokeBottomWeightComponent,
  StrokeLeftWeightComponent,
  StrokeRightWeightComponent,
  StrokeTopWeightComponent,
  StrokeWeightComponent,
  TopLeftCornerRadiusComponent,
  TopRightCornerRadiusComponent,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
} from '@aninix-inc/model'
import { buttons, icons } from '@aninix/app-design-system'
import { HotkeyCombination } from '@aninix/app-design-system/components/common/hotkey-combination'
import { useComponents } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useKeyframeIndicator } from '../../../../../hooks/keyframe-indicator'

const iconSize = {
  x: 16,
  y: 16,
}

const btnSize = {
  width: 32,
  height: 32,
}

const updatedPropertyNames: Record<string, React.ReactNode> = {
  [EndAngleComponent.name]: 'Ending Angle',
  [InnerRadiusComponent.name]: 'Inner Radius',
  [StartAngleComponent.name]: 'Starting Angle',
  [BottomLeftCornerRadiusComponent.name]: 'Bottom Left Corner Radius',
  [BottomRightCornerRadiusComponent.name]: 'Bottom Right Corner Radius',
  [CornerRadiusComponent.name]: 'Corner Radius',
  [PointCountComponent.name]: 'Point Count',
  [SizeComponent.name]: 'Size',
  [SkewComponent.name]: 'Skew',
  [ShadowOffsetComponent.name]: 'Offset',
  [ShadowRadiusComponent.name]: 'Radius',
  [ShadowSpreadComponent.name]: 'Spread',
  [ShadowColorComponent.name]: 'Color',
  [BlurRadiusComponent.name]: 'Radius',
  [RgbaValueComponent.name]: 'Color',
  [TrimStartComponent.name]: 'Trim Start',
  [TrimEndComponent.name]: 'Trim End',
  [TrimOffsetComponent.name]: 'Trim Offset',
  [DashComponent.name]: 'Dash',
  [GapComponent.name]: 'Gap',
  [DashOffsetComponent.name]: 'Dash Offset',
  [StrokeWeightComponent.name]: 'Stroke Weight',
  [StrokeTopWeightComponent.name]: 'Stroke Top Weight',
  [StrokeRightWeightComponent.name]: 'Stroke Right Weight',
  [StrokeBottomWeightComponent.name]: 'Stroke Bottom Weight',
  [StrokeLeftWeightComponent.name]: 'Stroke Left Weight',
  [TopLeftCornerRadiusComponent.name]: 'Top Left Corner Radius',
  [TopRightCornerRadiusComponent.name]: 'Top Right Corner Radius',
  [PositionComponent.name]: (
    <p className="-mx-0.5 py-0.5">
      Position{' '}
      <span className="pt-1">
        <HotkeyCombination keys={['P']} />
      </span>
    </p>
  ),
  [RotationComponent.name]: (
    <p className="-mx-0.5 py-0.5">
      Rotation{' '}
      <span className="pt-1">
        <HotkeyCombination keys={['R']} />
      </span>
    </p>
  ),
  [OpacityComponent.name]: (
    <p className="-mx-0.5 py-0.5">
      Opacity{' '}
      <span className="pt-1">
        <HotkeyCombination keys={['O']} />
      </span>
    </p>
  ),
  [ScaleComponent.name]: (
    <p className="-mx-0.5 py-0.5">
      Scale{' '}
      <span className="pt-1">
        <HotkeyCombination keys={['S']} />
      </span>
    </p>
  ),
}

export interface IProps {
  /**
   * @description receive array of components of the same type
   * from selected nodes.
   * @example
   * nodes.map((node) => node.position)
   */
  components: Component[]
  time: number
  KeyframeConstructor: ConstructorWithTag<Entity>
  valueGetter: (payload: { t: number; segment: [Entity, Entity] }) => unknown
  isDisabled?: boolean

  /**
   * This is a dirty hack required to fix the ANI-1170 task.
   * @todo remove once refactored to support proper types of keyframes.
   */
  onToggle?: () => void
}
export const KeyframesPropertyControl: React.FCC<IProps> = ({
  components,
  time,
  isDisabled,
  KeyframeConstructor,
  onToggle,
}) => {
  useComponents(components)
  const { type, color, toggleKeyframe } = useKeyframeIndicator({
    components,
    time,
    KeyframeConstructor,
  })

  const propertyTitle =
    R.head(components) == null
      ? ''
      : updatedPropertyNames[R.head(components)!.constructor.name]

  const handleToggle = React.useCallback(() => {
    toggleKeyframe()
    onToggle?.()
  }, [onToggle, toggleKeyframe])

  if (propertyTitle == null) {
    throw new Error(
      `PropertyTitle for component ${
        R.head(components)!.constructor.name
      } was not found`
    )
  }

  return (
    <buttons.Icon
      onClick={handleToggle}
      btnSize={btnSize}
      tooltip={isDisabled ? '' : propertyTitle}
      disabled={isDisabled}
    >
      <icons.KeyIndicator
        type={type}
        size={iconSize}
        style={{
          // @ts-ignore
          ['--figma-color-text']: color,
        }}
      />
    </buttons.Icon>
  )
}
