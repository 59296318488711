import { LayerColor } from '@aninix-inc/model/legacy'

export const nodeColors = {
  [LayerColor.Blue]: '#18A0FB',
  [LayerColor.Purple]: '#7B61FF',
  [LayerColor.Pink]: '#FF00FF',
  [LayerColor.Green]: '#1BC47D',
  [LayerColor.Turqouise]: '#00B5CE',
  [LayerColor.Red]: '#F24822',
  [LayerColor.Orange]: '#FF9900',
  [LayerColor.Yellow]: '#FFC700',
  [LayerColor.Grey]: '#9FAFBA',
  [LayerColor.None]: '#7A7A7A',
}
