import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const ResizeToFit: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64648 9.35359L12.2929 13H10V14H13.5H14V13.5V10H13V12.2929L9.35359 8.64648L8.64648 9.35359ZM19.7071 13L23.3536 9.35359L22.6465 8.64648L19 12.2929V10H18V13.5V14H18.5H22V13H19.7071ZM19.7071 19L23.3536 22.6465L22.6465 23.3536L19 19.7071V22H18V18.5V18H18.5H22V19H19.7071ZM12.2929 19L8.64648 22.6465L9.35359 23.3536L13 19.7071V22H14V18.5V18H13.5H10V19H12.2929Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
