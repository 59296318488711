import { Entity, EntryComponent } from '@aninix-inc/model'

/**
 * @description check if provided node is valid for mask
 */
export function isMaskValid(maskNode?: Entity): boolean {
  if (maskNode == null) {
    return false
  }

  if (maskNode.hasComponent(EntryComponent)) {
    return false
  }

  // @NOTE: return true for the rest of cases.
  // @TODO: remove when we start lottie optimization process. Because in that case we return masks for all groups and frames
  // which caused big file sizes.
  return true
}
