import { TimeFormat } from '@aninix/core'

interface IPayload {
  timeFormat: TimeFormat
  fps: number
}
type Output = number
/**
 * @description calculate current time unit
 * @example
 * seconds = 0.1s
 * frames (fps = 60) = 0.01667s
 * milliseconds = 0.0001s
 */
export function getTimeUnit({ timeFormat, fps }: IPayload): Output {
  if (timeFormat === TimeFormat.Frames) {
    return 1 / fps
  }

  if (timeFormat === TimeFormat.Milliseconds) {
    return 0.001
  }

  if (timeFormat === TimeFormat.Seconds) {
    return 0.01
  }

  return 1
}
