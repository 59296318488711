import { Snapshot } from '@aninix-inc/model'
import { PrototypeExportingError } from '@aninix/figma'

import { Logger } from '@aninix/logger'
import type { FigmaConnect } from './figma-connect'
import { Pair } from './pair'
import { PrototypeChain } from './prototype-chain'
import { PrototypeNode } from './prototype-node'
import { smartAnimateTransition } from './smart-animate.transition'

export {
  Pair,
  PrototypeChain,
  PrototypeNode,
  smartAnimateTransition,
  type FigmaConnect,
}

export type GetPrototypeProject = (
  figmaFrameId: string,
  options?: {
    /**
     * When `true` then new project ID generated no matter what.
     * @default false
     */
    shouldGenerateNewProjectId?: boolean
  }
) => Promise<
  | {
      project: Snapshot
      errors: null
      isLooped: boolean
    }
  | {
      project: null
      errors: PrototypeExportingError[]
      isLooped: false
    }
>

export function useGetPrototypeProject(
  figmaConnect: FigmaConnect,
  logger?: Logger
): GetPrototypeProject {
  return async function getPrototypeProject(figmaFrameId, options) {
    logger?.log('[useGetPrototypeProject] starting...')
    const { chain, errors } = await figmaConnect.getPrototypeChain(figmaFrameId)
    logger?.log('[useGetPrototypeProject] chain received', { chain, errors })

    if (errors.length !== 0) {
      return { project: null, errors, isLooped: false }
    }

    try {
      const project = await new PrototypeChain.Figma(figmaConnect, console, {
        type: 'chain',
        chain,
      }).toProject(options)
      return {
        project: project.getSnapshot(),
        errors: null,
        isLooped: chain[0].figmaNodeId === chain[chain.length - 1].figmaNodeId,
      }
    } catch (err: any) {
      return {
        project: null,
        errors: [
          {
            message: err.message,
          },
        ],
        isLooped: false,
      }
    }
  }
}
