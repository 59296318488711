import { FullPageLoader } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { AninixUnavailable } from '../../components/status-pages'
import { useSessionStore } from '../../stores'

export interface IProps {}
export const IdentifyUser: React.FCC<IProps> = observer(({ children }) => {
  const sessionStore = useSessionStore()

  if (sessionStore.state === 'error') {
    return <AninixUnavailable />
  }

  if (sessionStore.state === 'loading') {
    return <FullPageLoader />
  }

  return <>{children}</>
})

IdentifyUser.displayName = 'IdentifyUser'
