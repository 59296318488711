import * as React from 'react'

import { IIcon } from '.'

const defaultSize = { x: 20, y: 20 }

export const MoveToSpaces: React.FCC<IIcon> = ({
  size = defaultSize,
  className = ' stroke-gray-400',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 20 20`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.41943 8.41388C4.70333 8.5 5.05222 8.5 5.75 8.5C6.44778 8.5 6.79667 8.5 7.08057 8.41388C7.71977 8.21998 8.21998 7.71977 8.41388 7.08057C8.5 6.79667 8.5 6.44778 8.5 5.75C8.5 5.05222 8.5 4.70333 8.41388 4.41943C8.21998 3.78023 7.71977 3.28002 7.08057 3.08612C6.79667 3 6.44778 3 5.75 3C5.05222 3 4.70333 3 4.41943 3.08612C3.78023 3.28002 3.28002 3.78023 3.08612 4.41943C3 4.70333 3 5.05222 3 5.75C3 6.44778 3 6.79667 3.08612 7.08057C3.28002 7.71977 3.78023 8.21998 4.41943 8.41388Z"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9194 8.41388C13.2033 8.5 13.5522 8.5 14.25 8.5C14.9478 8.5 15.2967 8.5 15.5806 8.41388C16.2198 8.21998 16.72 7.71977 16.9139 7.08057C17 6.79667 17 6.44778 17 5.75C17 5.05222 17 4.70333 16.9139 4.41943C16.72 3.78023 16.2198 3.28002 15.5806 3.08612C15.2967 3 14.9478 3 14.25 3C13.5522 3 13.2033 3 12.9194 3.08612C12.2802 3.28002 11.78 3.78023 11.5861 4.41943C11.5 4.70333 11.5 5.05222 11.5 5.75C11.5 6.44778 11.5 6.79667 11.5861 7.08057C11.78 7.71977 12.2802 8.21998 12.9194 8.41388Z"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.41943 16.9139C4.70333 17 5.05222 17 5.75 17C6.44778 17 6.79667 17 7.08057 16.9139C7.71977 16.72 8.21998 16.2198 8.41388 15.5806C8.5 15.2967 8.5 14.9478 8.5 14.25C8.5 13.5522 8.5 13.2033 8.41388 12.9194C8.21998 12.2802 7.71977 11.78 7.08057 11.5861C6.79667 11.5 6.44778 11.5 5.75 11.5C5.05222 11.5 4.70333 11.5 4.41943 11.5861C3.78023 11.78 3.28002 12.2802 3.08612 12.9194C3 13.2033 3 13.5522 3 14.25C3 14.9478 3 15.2967 3.08612 15.5806C3.28002 16.2198 3.78023 16.72 4.41943 16.9139Z"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.5 14.5H11M17.5 14.5L15.5 12.5M17.5 14.5L15.5 16.5"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
