import * as R from 'ramda'

import { BezierPoint } from './bezier-point'

/**
 * @description parse svg path and create array of bezier points
 * @param data svg path
 * @url https://developer.mozilla.org/en-US/docs/Web/SVG/Tutorial/Paths
 * @useant this function not handle ARCS
 */
export function svgPathToBezierPoints(providedData: string): BezierPoint[][] {
  const splitted = providedData.split(/(?=[MmVvHhLlCcSQTZz])/)

  const parsedCommands: (string | number)[][] = splitted.map((rawCommand) => {
    const command = R.take(1, rawCommand)
    const points = R.drop(1, rawCommand)
      .replace(/\,/g, ' ')
      .split(' ')
      .filter((point) => point !== '')
      .map((point) => parseFloat(point))

    return [command, ...points]
  })

  const bezierPoints: BezierPoint[][] = []

  for (let i = 0; i < parsedCommands.length; i += 1) {
    const commandWithPoints = parsedCommands[i]
    const command = R.head(commandWithPoints) as string
    const points = R.drop(1, commandWithPoints) as number[]
    const prevPoint = R.last(R.defaultTo([], R.last(bezierPoints)!))!

    if (command === 'M' || command === 'm') {
      bezierPoints.push([])
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: points[1] } })
      )
      continue
    }

    if (command === 'V') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: prevPoint.point.x, y: points[0] } })
      )
      continue
    }

    if (command === 'v') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: { x: prevPoint.point.x, y: prevPoint.point.y + points[0] },
        })
      )
      continue
    }

    if (command === 'H') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: prevPoint.point.y } })
      )
      continue
    }

    if (command === 'h') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: { x: prevPoint.point.x + points[0], y: prevPoint.point.y },
        })
      )
      continue
    }

    if (command === 'L') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({ point: { x: points[0], y: points[1] } })
      )
      continue
    }

    if (command === 'l') {
      bezierPoints[bezierPoints.length - 1].push(
        new BezierPoint({
          point: {
            x: prevPoint.point.x + points[0],
            y: prevPoint.point.y + points[1],
          },
        })
      )
      continue
    }

    if (command === 'C') {
      prevPoint.updateAbsoluteEndTangent({
        x: points[0],
        y: points[1],
      })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          point: { x: points[4], y: points[5] },
          startTangent: { x: points[2], y: points[3] },
        })
      )
      continue
    }

    if (command === 'c') {
      const absolutePoint = prevPoint.toAbsoluteJson()

      prevPoint.updateAbsoluteEndTangent({
        x: absolutePoint.point.x + points[0],
        y: absolutePoint.point.y + points[1],
      })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          point: {
            x: absolutePoint.point.x + points[4],
            y: absolutePoint.point.y + points[5],
          },
          startTangent: {
            x: absolutePoint.point.x + points[2],
            y: absolutePoint.point.y + points[3],
          },
        })
      )

      continue
    }

    if (command === 'S') {
      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: prevPoint.toAbsoluteJson().endTangent,
          point: { x: points[2], y: points[3] },
          endTangent: { x: points[0], y: points[1] },
        })
      )
      continue
    }

    if (command === 's') {
      const absolutePoint = prevPoint.toAbsoluteJson()

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: absolutePoint.endTangent,
          point: {
            x: absolutePoint.point.x + points[2],
            y: absolutePoint.point.y + points[3],
          },
          endTangent: {
            x: absolutePoint.point.x + points[0],
            y: absolutePoint.point.y + points[1],
          },
        })
      )
      continue
    }

    if (command === 'Q') {
      throw new Error('Not supported yet')
      prevPoint.updateAbsoluteEndTangent({ x: points[0], y: points[1] })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: { x: points[0], y: points[1] },
          point: { x: points[2], y: points[3] },
        })
      )
      continue
    }

    if (command === 'T') {
      throw new Error('Not supported yet')
      prevPoint.updateAbsoluteEndTangent({ x: points[0], y: points[1] })

      bezierPoints[bezierPoints.length - 1].push(
        BezierPoint.fromAbsoluteJson({
          startTangent: { x: points[0], y: points[1] },
          point: { x: points[2], y: points[3] },
        })
      )
      continue
    }
  }

  // const withoutTwoIdenticalPointsInARow = R.uniqWith(
  //   (left, right) => left.equals(right),
  //   bezierPoints
  // )

  const preparedPoints = bezierPoints // withoutTwoIdenticalPointsInARow

  return preparedPoints
    .map((points) => {
      const newPoints = R.clone(points)
      const lastCommand = R.last(parsedCommands)!

      if (lastCommand[0] === 'Z' || lastCommand[0] === 'z') {
        newPoints.push(R.head(newPoints)!)
      }

      return newPoints
    })
    .filter((points) => points.length > 0)
}
