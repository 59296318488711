export enum PluginType {
  /**
   * @description plugins for properties-panel when nothing selected
   */
  PropertiesPanelProject = 'properties-panel/project',

  /**
   * @description plugins for properties-panel when root node selected
   */
  PropertiesPanelRootNode = 'properties-panel/root-node',

  /**
   * @description plugins for properties-panel when nodes selected
   */
  PropertiesPanelNodes = 'properties-panel/nodes',

  /**
   * @description plugins for properties-panel when segments selected
   */
  PropertiesPanelSegments = 'properties-panel/segments',

  /**
   * @description plugins for properties-panel when keyframes selected
   */
  PropertiesPanelKeyframes = 'properties-panel/keyframes',

  /**
   * @description plugins for timeline-info for node
   */
  TimelineInfoNode = 'timeline-info/node',

  /**
   * @description plugins for timeline-info for property-group
   */
  TimelineInfoPropertyGroup = 'timeline-info/property-group',

  /**
   * @description plugins for timeline-info for property
   */
  TimelineInfoProperty = 'timeline-info/property',
}
