import { ShapeType, useTools } from '@aninix/core/stores/tools'
import { Popover } from '@material-ui/core'
import * as React from 'react'
import { ShapeMenuItem } from './shape-menu-item'
import { EllipseIcon, LineIcon, PolygonIcon, RectangleIcon, StarIcon } from './shape-icon'

interface IProps {
  anchorEl: Element | null
  open: boolean
  onClose: () => void
}



export const ShapeMenu = ({ open, anchorEl, onClose }: IProps) => {
  const { activeShape: acitveShape, changeShape } = useTools()

  const menuItems: Record<ShapeType, React.FCC> = {
    [ShapeType.Rectangle]: RectangleIcon,
    [ShapeType.Line]: LineIcon,
    [ShapeType.Ellipse]: EllipseIcon,
    [ShapeType.Polygon]: PolygonIcon,
    [ShapeType.Star]: StarIcon,
  }

  return <Popover
    PaperProps={{ style: { background: 'transparent' } }}
    onClose={onClose}
    elevation={0}
    open={open}
    anchorEl={anchorEl}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  >
    <div className="pt-4">
      <div className="w-[210px] rounded-lg bg-black py-[3px] overflow-hidden">
        {Object.entries(menuItems).map(([key, Icon]) => (
          <ShapeMenuItem
            key={key}
            Icon={Icon}
            label={key.slice(0, 1).toUpperCase() + key.slice(1)}
            onClick={() => {
              changeShape(key as ShapeType)
              onClose()
            }}
          />
        ))}
      </div>
    </div>
  </Popover>
}

ShapeMenu.displayName = 'ShapeMenu'
