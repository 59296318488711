import * as React from 'react'

import { IIcon } from '.'

export const Check: React.FCC<IIcon> = ({
  size = { x: 24, y: 24 },
  className = '',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${size.x} ${size.y}`}
  >
    <path
      d="M16.875 5.62549L8.125 14.3751L3.75 10.0005"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

Check.displayName = 'Check'
