import { Point2D } from '@aninix-inc/model/legacy'

export function stringifiedSvgToDataUrl(payload: {
  stringifiedSvg: string
  size: Point2D
}): Promise<string> {
  const { stringifiedSvg, size } = payload

  const canvas = new OffscreenCanvas(size.x, size.y)
  const context = canvas.getContext('2d')!

  return new Promise<string>(async (resolve, reject) => {
    const svgUrl = 'data:image/svg+xml,' + encodeURIComponent(stringifiedSvg)
    const svgImage = new Image(size.x, size.y)

    svgImage.onload = async () => {
      context.drawImage(svgImage, 0, 0, size.x, size.y)

      const blob = await canvas.convertToBlob({
        type: 'image/webp',
        quality: 0.8,
      })

      if (blob == null) {
        reject(new Error('Image cannot be generated'))
        svgImage.remove()
        return
      }

      const img = URL.createObjectURL(blob)
      resolve(img)
      svgImage.remove()
    }

    svgImage.src = svgUrl
  })
}

export function stringifiedSvgToImage(payload: {
  stringifiedSvg: string
  size: Point2D
  format: 'image/png' | 'image/webp'
  quality: number // in range 0...1
}): Promise<Blob> {
  const { stringifiedSvg, size, format, quality } = payload

  const canvas = new OffscreenCanvas(size.x, size.y)
  const context = canvas.getContext('2d')!

  return new Promise<Blob>(async (resolve, reject) => {
    const svgUrl = 'data:image/svg+xml,' + encodeURIComponent(stringifiedSvg)
    const svgImage = new Image(size.x, size.y)

    svgImage.onload = async () => {
      context.drawImage(svgImage, 0, 0, size.x, size.y)
      const blob = await canvas.convertToBlob({
        type: format,
        quality,
      })

      if (blob == null) {
        reject(new Error('Blob cannot be generated'))
        svgImage.remove()
        return
      }

      resolve(blob)
      svgImage.remove()
    }

    svgImage.src = svgUrl
  })
}
