import * as React from 'react'

import { Hidden } from './Hidden'
import { Visible as VisibleIcon } from './Visible'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  visible: boolean
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Visible: React.FCC<IProps> = ({
  visible,
  size = defaultSize,
  style,
}) =>
  visible ? (
    <VisibleIcon size={size} style={style} />
  ) : (
    <Hidden size={size} style={style} />
  )
