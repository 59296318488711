import { config } from './config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'

export const createProjectVersionV2 = async (
  projectId: string
): Promise<void> => {
  await httpClient.post<
    paths['/v2/projects/{projectId}/versions']['post']['responses']['200']['content']['application/json']
  >(`${config.apiUrl}/v2/projects/${projectId}/versions`)
}
