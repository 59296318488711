import * as React from 'react'
import { ImagePreview } from './image-preview'

export interface ISearchItem {
  id: string
  label: string
  thumbnailSrc: string
  /** put all the keys that will be used as source for search */
  meta?: { [key: string]: unknown }
}

export interface IProps {
  item: ISearchItem
  onSelect: () => void
}
export const SearchItem: React.FCC<IProps> = ({ item, onSelect }) => (
  <div className="w-full px-1 py-0.5">
    <button
      onClick={onSelect}
      className="w-full hover:bg-gray-100 px-2 py-1.5 rounded-lg flex flex-row gap-2 items-center"
    >
      <ImagePreview src={item.thumbnailSrc} />
      <p className="font-normal text-[12px]/[14px] whitespace-nowrap overflow-hidden text-ellipsis">
        {item.label}
      </p>
    </button>
  </div>
)

SearchItem.displayName = 'SearchItem'
