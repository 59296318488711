import {
  ChildrenRelationsAspect,
  Entity,
  EntityType,
  LayerColor,
  NameComponent,
  NodeColorComponent,
  SelectionSystem,
  TargetRelationAspect,
  TimeComponent,
  getKeyframeValueComponent,
  getSortedKeyframes,
  isSegment,
} from '@aninix-inc/model'
import { getLayerTypeV2 } from '@aninix/app-design-system'
import { useEntity, useSystem } from '@aninix/core/updates'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import * as React from 'react'
import {
  usePlayback,
  useProject,
  useSession,
} from '../../../../../../../stores'
import { Properties } from './properties'
import { Title } from './title'

export const getSegments = (layer: Entity): [Entity, Entity][] => {
  const keyframesByComponents = Object.values(
    R.groupBy(
      (keyframe: Entity) =>
        keyframe
          .getAspectOrThrow(TargetRelationAspect)
          .getTargetComponentOrThrow().id,
      R.sortBy(
        (keyframe) => keyframe.getComponentOrThrow(TimeComponent).value,
        getSortedKeyframes(layer)
      )
    )
  ).filter((e) => e != null)

  return keyframesByComponents.flatMap((keyframesByComponent) =>
    R.aperture(2, keyframesByComponent).filter(([left, right]) =>
      isSegment(left, right, (left, right) =>
        R.equals(
          getKeyframeValueComponent(left).value,
          getKeyframeValueComponent(right).value
        )
      )
    )
  )
}

const nodeColors = {
  [LayerColor.Blue]: '#18A0FB',
  [LayerColor.Purple]: '#7B61FF',
  [LayerColor.Pink]: '#FF00FF',
  [LayerColor.Green]: '#1BC47D',
  [LayerColor.Turqouise]: '#00B5CE',
  [LayerColor.Red]: '#F24822',
  [LayerColor.Orange]: '#FF9900',
  [LayerColor.Yellow]: '#FFC700',
  [LayerColor.Grey]: '#9FAFBA',
  [LayerColor.None]: '#7A7A7A',
}

export interface IProps {
  layer: Entity
}
export const LayerColumn: React.FCC<IProps> = observer(({ layer }) => {
  const session = useSession()
  const playback = usePlayback()
  const project = useProject()
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)
  useEntity(layer)

  const isSelected = selection
    .getEntitiesByEntityType(EntityType.Node)
    .map((node) => node.id)
    .includes(layer.id)

  const isHighlighted = session.buffer === layer.id

  const allSegments: [Entity, Entity][] = R.aperture(
    2,
    getSortedKeyframes(layer, { recursive: true })
  ).filter(([left, right]) =>
    isSegment(left, right, (left, right) =>
      R.equals(
        getKeyframeValueComponent(left).value,
        getKeyframeValueComponent(right).value
      )
    )
  )

  const segments = getSegments(layer)

  const hasAnimation = (layer: Entity) =>
    getSortedKeyframes(layer, { recursive: true }).length > 0

  const hasOwnAnimation = (layer: Entity) =>
    getSortedKeyframes(layer).length > 0

  const children = layer.getAspect(ChildrenRelationsAspect)?.getChildrenList()

  const handleMouseClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()

      selection
        .deselectAll()
        .select([layer.id])
        .select(allSegments.flat().map((key) => key.id))

      const earliestKeyframe = Math.min(
        ...allSegments
          .flat()
          .map((segment) => segment.getComponentOrThrow(TimeComponent).value)
      )
      playback.updateTime(earliestKeyframe)
    },
    []
  )

  const handleMouseEnter = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      if (e.buttons === 0) session.setBuffer(layer.id)
    },
    []
  )
  const handleMouseLeave = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      session.cleanBuffer()
    },
    []
  )

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation()
    },
    []
  )

  const layerColor = layer.getComponentOrThrow(NodeColorComponent).value
  const layerStyle = {
    '--figma-color-text': nodeColors[layerColor],
    '--figma-color-text-01': nodeColors[layerColor] + '1a',
  } as React.CSSProperties

  if (!hasAnimation(layer)) {
    return null
  }

  return (
    <div
      className={classNames('relative min-h-full py-1.5 pl-1', {
        ['bg-[var(--figma-color-text-01)]']: isHighlighted || isSelected,
      })}
      style={layerStyle}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseDown={handleMouseDown}
      onClick={handleMouseClick}
    >
      {children?.find((entity) => hasAnimation(entity)) && (
        <div className="absolute bottom-0 left-3 top-8 w-[1px] bg-[var(--figma-color-text)] opacity-30" />
      )}

      <Title
        name={layer.getComponentOrThrow(NameComponent).value}
        type={getLayerTypeV2(layer)}
      />
      {hasOwnAnimation(layer) && <Properties segments={segments} />}

      {children?.map((layer) => (
        <div key={layer.id} className="min-h-full pl-5">
          <LayerColumn layer={layer} />
        </div>
      ))}
    </div>
  )
})

LayerColumn.displayName = 'LayerColumn'
