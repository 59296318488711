import {
  Entity,
  NameComponent,
  NodeColorComponent,
  NodeTypeComponent,
  getNode,
} from '@aninix-inc/model'
import { usePlayback } from '@aninix/core'
import { Switcher } from '@aninix/core/modules/inspector/components/export'
import { PropertyNameSegment } from '@aninix/core/modules/inspector/property-name-segment'
import { TimeBlockSegment } from '@aninix/core/modules/inspector/time-block-segment'
import { TimingCurveBlockSegment } from '@aninix/core/modules/inspector/timing-curve-block-segment'
import { ValueBlockSegment } from '@aninix/core/modules/inspector/value-block-segment'
import { nodeColors } from '@aninix/core/registries'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useFormatContext } from '../context'
import {
  SegmentValueItems,
  TimeInfoItems,
  TimingCurveInfoItems,
} from '../items'
import { SegmentTitle } from './title'

const Dash: React.FC = () => (
  <div className="border-solid-[#CED3D9] w-full border-b-[1px] border-dashed" />
)

export interface IProps {
  segment: [Entity, Entity]
}
export const InspectedSegment: React.FCC<IProps> = observer(
  ({ segment }: IProps) => {
    const parentLayer = getNode(segment[0])

    if (parentLayer == null) {
      throw new Error('Invalid state')
    }

    const { formats, format, setFormat } = useFormatContext()
    const playback = usePlayback()

    const propertyNameSegment = new PropertyNameSegment(segment)
    const title = propertyNameSegment.propertyName

    const layerStyle = {
      '--figma-color-text':
        nodeColors[parentLayer.getComponentOrThrow(NodeColorComponent).value],
    } as React.CSSProperties

    const timeBlock = new TimeBlockSegment(segment).asBlock()
    const timingCurveBlock = new TimingCurveBlockSegment(segment).asBlock()
    const infoBlockSegment = new ValueBlockSegment(segment)

    const valueBlock = infoBlockSegment.asBlock({
      time: playback.time,
      format,
    })

    return (
      <div
        className="flex min-w-min flex-col rounded-lg bg-gray-100"
        style={layerStyle}
      >
        <div className="p-4">
          <div className="flex flex-col gap-4">
            <div className="flex flex-row items-center justify-between">
              <div className="flex-[2]">
                <SegmentTitle
                  layerName={
                    parentLayer.getComponentOrThrow(NameComponent).value
                  }
                  // @TODO: provide proper type here
                  iconType={
                    parentLayer.getComponentOrThrow(NodeTypeComponent)
                      .value as any
                  }
                  name={title}
                />
              </div>

              {infoBlockSegment.getSupportedFormats().length > 0 && (
                <div className="flex-shink-0 flex-[1]">
                  <Switcher
                    options={formats}
                    selectedOption={format}
                    setSelectedOption={setFormat}
                  />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-2 px-5">
              <Dash />
              <TimeInfoItems
                properties={timeBlock.properties}
                propertiesName={timeBlock.propertiesName}
              />

              <Dash />
              <TimingCurveInfoItems
                segment={segment}
                properties={timingCurveBlock.properties}
                propertiesName={timingCurveBlock.propertiesName}
              />

              <Dash />
              <SegmentValueItems
                properties={valueBlock.properties}
                propertiesName={valueBlock.propertiesName}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
)

InspectedSegment.displayName = 'InspectedSegment'
