import * as React from 'react'
import {
  MathOpsCloseIcon,
  MathOpsIcon,
  MathOpsPointerIcon,
} from '../icons/math-ops'

interface IProps {
  handleClose: () => void
  handlePass: () => void
  exampleValue: number
}

export const MathOpsNumberGuide: React.FC<IProps> = ({
  handleClose,
  handlePass,
  exampleValue,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <div className="relative pr-1">
        <div className="absolute right-0 top-1/2 -translate-y-1/2">
          <MathOpsPointerIcon />
        </div>
        <div className="flex w-64 flex-col gap-2 rounded-xl bg-[#0B1118] p-3 shadow">
          <div className="flex flex-col gap-1">
            <div className="flex flex-row items-center justify-between">
              <div className="flex flex-row items-center gap-1">
                <MathOpsIcon />
                <p className="text-xs font-semibold leading-none text-[#18A0FB]">
                  Supporting math operations
                </p>
              </div>
              <button onClick={handleClose}>
                <MathOpsCloseIcon />
              </button>
            </div>
            <p className="text-xs font-normal leading-4 text-white text-opacity-80">
              You can use simple math to quickly adjust the values. Available
              operators:
              <br />
              <span className="whitespace-nowrap">
                <b>- + * / ^ ()</b>
              </span>
              <br />
              <br />
              For example: <b>{exampleValue.toString()} * 2</b>
            </p>
          </div>
          <button
            className="w-max rounded-md bg-[#18A0FB] px-4 py-2"
            onClick={handlePass}
          >
            <p className="text-whitetext-center text-[11px] font-medium leading-none tracking-tight text-white">
              Got it
            </p>
          </button>
        </div>
      </div>
    </div>
  )
}
