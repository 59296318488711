import { useGetTeam } from '@aninix/api'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useParams } from 'react-router-dom'

import { ApiUsers } from '@aninix/api/helpers'
import { usePageOutletContext } from '../../modules/teams'
import { LoadableChildrenWrapper } from '../loadable-wrapper'
import { MemberRow, MemberRowPlaceholder } from './member-row'
import { MembersHeader } from './members-header'

const Grid: React.FCC<IProps> = ({ children }) => (
  <div className="grid gap-y-6 pb-8">{children}</div>
)

Grid.displayName = 'Grid'

export interface IProps {}

export const MemberList: React.FCC<IProps> = observer(({}: IProps) => {
  const { teamId } = useParams()
  const getTeam = useGetTeam({ teamId: teamId! })
  const members = getTeam?.data?.users.sort(
    (member1, member2) =>
      Date.parse(member1.createdAt) - Date.parse(member2.createdAt)
  )

  const pageOutletContext = usePageOutletContext()

  React.useEffect(() => {
    pageOutletContext.setControls('InviteMembers')
    pageOutletContext.setTitle('People')
  }, [])

  return (
    <LoadableChildrenWrapper
      isLoading={getTeam?.isLoading}
      isError={getTeam?.isError}
      loadingComponent={
        <Grid>
          <MembersHeader />
          {[1, 2, 3, 4, 5].map((c) => (
            <MemberRowPlaceholder key={c.toString()} />
          ))}
        </Grid>
      }
    >
      <Grid>
        <MembersHeader />
        {(members as ApiUsers)?.map((m) => <MemberRow key={m.id} {...m} />)}
      </Grid>
    </LoadableChildrenWrapper>
  )
})

MemberList.displayName = 'MemberList'
