// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GFQnY9Yjq3edsWlJZbXA{display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:space-between;padding:2px 8px}.SJsLDHe9WFMkvyKpNqcz{display:flex;flex:1;flex-direction:row;flex-wrap:nowrap;align-items:center;justify-content:flex-start}.KvkuV9_EAgvnrHcSDuEm{flex-shrink:0}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/compact-property-row/index.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,6BAAA,CACA,eAAA,CAGF,sBACE,YAAA,CACA,MAAA,CACA,kBAAA,CACA,gBAAA,CACA,kBAAA,CACA,0BAAA,CAGF,sBACE,aAAA","sourcesContent":["@use '../../../theme/index.scss' as *;\n\n.container {\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: space-between;\n  padding: 2px 8px;\n}\n\n.left {\n  display: flex;\n  flex: 1;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  align-items: center;\n  justify-content: flex-start;\n}\n\n.right {\n  flex-shrink: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `GFQnY9Yjq3edsWlJZbXA`;
export var left = `SJsLDHe9WFMkvyKpNqcz`;
export var right = `KvkuV9_EAgvnrHcSDuEm`;
export default ___CSS_LOADER_EXPORT___;
