import classNames from 'classnames'
import * as React from 'react'
import { Icons } from '../../icons'
import { AutoHeightPanel } from './auto-height-panel'
import { CreateProjectFromSvgButton } from './create-project-from-svg-button'
import { OptionButton } from './option-button'
import { Link } from 'react-router-dom'
import { featureFlags } from '@aninix/core'

export interface IProps {}
export const CreateProjectButton: React.FCC<IProps> = () => {
  const [isDropwdownOpen, setIsDropwdownOpen] = React.useState(false)



  return (
    <>
      {isDropwdownOpen && (
        <button
          onClick={() => setIsDropwdownOpen(false)}
          className="absolute inset-0 h-scree w-screen z-[90]"
        />
      )}

      <div className="flex flex-col relative">
        <button
          className={classNames(
            'z-[200] flex flex-row items-center gap-2 rounded-lg bg-secondary px-3 py-2 font-body text-sm font-medium text-white transition-all duration-150 delay-75 hover:shadow-lg relative',
            { ['rounded-b-none']: isDropwdownOpen }
          )}
          type="button"
          onClick={() => setIsDropwdownOpen((v) => !v)}
        >
          {' '}
          <Icons.Plus />
          Create project
          <div
            className={classNames(
              'transition-transform duration-100 ease-in-out',
              {
                ['rotate-180']: isDropwdownOpen,
              }
            )}
          >
            <Icons.CaretDown color="#F5F6F8" />
          </div>
        </button>

        <div
          className={classNames(
            'z-[100] absolute top-full left-0 right-0 rounded-b-lg transition-transform duration-200 overflow-hidden',
            { ['-translate-y-5']: !isDropwdownOpen }
          )}
        >
          <AutoHeightPanel isOpen={isDropwdownOpen}>
            <div>
              {featureFlags.importLottie && (
                <div className="w-full h-[1px] bg-gray-500" />
              )}
              <CreateProjectFromSvgButton />
              {featureFlags.importLottie && (
                <OptionButton>From .lottie</OptionButton>
              )}
              {featureFlags.importLottie && (
                <div className="w-full h-[1px] bg-gray-500" />
              )}
              <Link
                to="https://www.figma.com/community/plugin/988173868842375596"
                target="_blank"
              >
                <OptionButton>
                  <p>From Figma</p>
                  <Icons.ExternalPage className="stroke-gray-400" />
                </OptionButton>
              </Link>
            </div>
          </AutoHeightPanel>
        </div>
      </div>
    </>
  )
}

CreateProjectButton.displayName = 'CreateProjectButton'
