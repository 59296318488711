import React from 'react'

import { IconButton } from '../../../buttons/icon-button'
import * as styles from './index.scss'

interface IProps {
  title: string
  icon: React.ReactNode
  onClick: () => void
  disabled?: boolean
}

const Header: React.FCC<IProps> = ({
  title,
  icon,
  onClick,
  disabled = false,
}) => (
  <div className={styles.header}>
    <div className={styles.title}>{title}</div>

    <IconButton onClick={onClick} disabled={disabled}>
      {icon}
    </IconButton>
  </div>
)

export default Header
