import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'

import { PresetAnimationAppearanceAlignmentOutBoxType } from '../../../models/preset'
import * as styles from './index.scss'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  type: PresetAnimationAppearanceAlignmentOutBoxType
  size?: Point2D
}
export const OutOutbox: React.FCC<IProps> = ({ type, size = defaultSize }) => {
  if (type === PresetAnimationAppearanceAlignmentOutBoxType.Top) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00004 1.29272L8.35359 1.64628L11.3536 4.64628L10.6465 5.35338L8.50004 3.20694L8.50004 10.9998H7.50004L7.50004 3.20694L5.35359 5.35338L4.64648 4.64628L7.64648 1.64628L8.00004 1.29272Z"
          className={styles.icon__fill}
        />
        <path
          d="M11 1.5H14.5V14.5H1.5L1.5 1.5H5"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentOutBoxType.Right) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.7073 7.99979L14.3537 8.35335L11.3537 11.3533L10.6466 10.6462L12.7931 8.49979L5.00017 8.49979L5.00017 7.49979L12.7931 7.49979L10.6466 5.35335L11.3537 4.64624L14.3537 7.64624L14.7073 7.99979Z"
          className={styles.icon__fill}
        />
        <path
          d="M14.5 11L14.5 14.5L1.5 14.5L1.5 1.5L14.5 1.5L14.5 5"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentOutBoxType.Bottom) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.00021 14.7075L7.64665 14.354L4.64665 11.354L5.35376 10.6469L7.50021 12.7933L7.50021 5.00041L8.50021 5.00041L8.50021 12.7933L10.6467 10.6469L11.3538 11.354L8.35376 14.354L8.00021 14.7075Z"
          className={styles.icon__fill}
        />
        <path
          d="M5 14.5L1.5 14.5L1.5 1.5L14.5 1.5L14.5 14.5L11 14.5"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentOutBoxType.Left) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.29272 8.00045L1.64628 7.6469L4.64628 4.6469L5.35338 5.354L3.20694 7.50045L10.9998 7.50045L10.9998 8.50045L3.20694 8.50045L5.35338 10.6469L4.64628 11.354L1.64628 8.354L1.29272 8.00045Z"
          className={styles.icon__fill}
        />
        <path
          d="M1.5 5L1.5 1.5L14.5 1.5L14.5 14.5L1.5 14.5L1.5 11"
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceAlignmentOutBoxType.None) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C9.33875 2.5 10.5658 2.97831 11.5195 3.77337L3.77337 11.5195C2.97831 10.5658 2.5 9.33875 2.5 8ZM4.48047 12.2266C5.43422 13.0217 6.66125 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 6.66125 13.0217 5.43422 12.2266 4.48047L4.48047 12.2266ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  return null
}
