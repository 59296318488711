import * as React from 'react'

const sizeDefault = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const LineArrow: React.FCC<IProps> = ({ size = sizeDefault, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.89118 7.5L5.87518 4.457C6.06918 4.26 6.06518 3.943 5.86918 3.75C5.67118 3.557 5.35518 3.56 5.16218 3.757L1.34318 7.65C1.15318 7.844 1.15318 8.156 1.34318 8.35L5.16218 12.243C5.35518 12.44 5.67218 12.443 5.86918 12.25C6.06618 12.057 6.06918 11.74 5.87518 11.543L2.89118 8.5H15V7.5H2.89118Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
