import { UseGetSpaceBadge, useGetSpaceBadge } from '@aninix/api'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { UseQueryResult } from '@tanstack/react-query'
import { useSessionStore } from '../stores'

export type User = UseGetSpaceBadge['infoFromTokens']['users'][number]
interface IProps {
  badge?: UseQueryResult<UseGetSpaceBadge, unknown>
  currentUser?: User
}

const CurrentSpaceContext = React.createContext<IProps>({})

export const useCurrentSpaceStore = (): IProps => {
  return React.useContext(CurrentSpaceContext)
}

export const CurrentSpaceStore: React.FCC<IProps> = observer(({ children }) => {
  const sessionStore = useSessionStore()

  const tokensArray = sessionStore.tokens
    ? Object.values(sessionStore.tokens)
    : []

  const getSpaceBadge = useGetSpaceBadge({ tokens: tokensArray })

  const currentUser = getSpaceBadge.data?.infoFromTokens.users.find(
    (u) => u.id === sessionStore.userId
  )

  return (
    <CurrentSpaceContext.Provider value={{ badge: getSpaceBadge, currentUser }}>
      {children}
    </CurrentSpaceContext.Provider>
  )
})

CurrentSpaceStore.displayName = 'CurrentSpaceStore'
