import { useMouseMove } from '@aninix/app-design-system'
import * as R from 'ramda'
import * as React from 'react'

interface IProps {
  element: HTMLElement
  ratioTargetElement?: HTMLElement
  initialLeftRatio?: number
  initialTopRatio?: number
  minimumTopRatio?: number
  minimumLeftRatio?: number
  minimumBottomRatio?: number
  minimumRightRatio?: number
}

export const useRatio = ({
  element,
  ratioTargetElement,
  initialLeftRatio = 0.5,
  initialTopRatio = 0.5,
  minimumTopRatio = 0.1,
  minimumLeftRatio = 0.1,
  minimumBottomRatio = 0.1,
  minimumRightRatio = 0.1,
}: IProps) => {
  const { startListen, isListening, offsetX, offsetY, endAtX, endAtY } =
    useMouseMove({ threshold: 0 })

  const [leftRatio, setLeftRatio] = React.useState<number>(initialLeftRatio)
  const [rightRatio, setRightRatio] = React.useState<number>(1 - leftRatio)

  const [topRatio, setTopRatio] = React.useState<number>(initialTopRatio)
  const [bottomRatio, setBottomRatio] = React.useState<number>(1 - topRatio)

  const reset = React.useCallback(() => {
    setLeftRatio(initialLeftRatio)
    setTopRatio(initialTopRatio)
  }, [])

  const recalculateRatio = React.useCallback(() => {
    if (element === null || ratioTargetElement === null) return

    const elWidth = element.getBoundingClientRect().width
    const targetWidth = ratioTargetElement?.getBoundingClientRect?.().width ?? 0

    setLeftRatio((elWidth - targetWidth) / elWidth)
  }, [element, ratioTargetElement])

  React.useEffect(() => {
    recalculateRatio()
  }, [element, ratioTargetElement])

  React.useEffect(() => {
    setRightRatio(1 - leftRatio)
  }, [leftRatio])

  React.useEffect(() => {
    setBottomRatio(1 - topRatio)
  }, [topRatio])

  React.useEffect(() => {
    if (!isListening || offsetX === 0) return

    const { left, width } = element.getBoundingClientRect()

    let newLeftRatio = (endAtX - left) / width
    newLeftRatio = R.max(newLeftRatio, minimumLeftRatio)
    newLeftRatio = R.min(newLeftRatio, 1 - minimumRightRatio)
    setLeftRatio(newLeftRatio)
  }, [isListening, endAtX])

  React.useEffect(() => {
    setRightRatio(1 - leftRatio)
  }, [leftRatio])

  React.useEffect(() => {
    if (!isListening || offsetY === 0) return

    const { top, height } = element.getBoundingClientRect()

    let newTopRatio = (endAtY - top) / height
    newTopRatio = R.max(newTopRatio, minimumTopRatio)
    newTopRatio = R.min(newTopRatio, 1 - minimumBottomRatio)
    setTopRatio(newTopRatio)
  }, [isListening, endAtY])

  React.useEffect(() => {
    setBottomRatio(1 - topRatio)
  }, [topRatio])

  return {
    startListen,
    isListening,
    offsetX,
    offsetY,
    leftRatio,
    rightRatio,
    topRatio,
    bottomRatio,
    reset,
    recalculateRatio,
  }
}
