import {
  EndAngleComponent,
  NumberKeyframe,
  getAnimatableValue,
  getValueNumber,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { formatPercents } from '../../keyframes/value'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 16,
  y: 16,
}

export const ArcEndingAngle: React.FCC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) => n.hasComponent(EndAngleComponent))

  const components = filteredNodes.map((n) =>
    n.getComponentOrThrow(EndAngleComponent)
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const keyframeType = getKeyframesType(components, time)
  // @TODO replace with snapshot data
  const angles = components.map((c) => getAnimatableValue(c, time))

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <ArcEndingAngleEditable time={time} components={components} />
      ) : (
        <ArcEndingAngleDisplay
          time={time}
          components={components}
          angles={angles}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

ArcEndingAngle.displayName = 'ArcEndingAngle'

const ArcEndingAngleEditable: React.FC<{
  time: number
  components: EndAngleComponent[]
}> = ({ time, components }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={components}
            time={time}
            icon={<icons.propertiesPanel.Sweep size={iconSize} flipped />}
            before={(value) => value * 100}
            after={(value) => value / 100}
            format={formatPercents}
            min={0}
            max={100}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      }
    />
  )
}

ArcEndingAngle.displayName = 'ArcEndingAngle'

interface ArcEndingAngleDisplayProps {
  time: number
  components: EndAngleComponent[]
  angles: number[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (
  prev: ArcEndingAngleDisplayProps,
  next: ArcEndingAngleDisplayProps
) => {
  if (prev.angles.length !== next.angles.length) return false
  if (prev.keyframeType !== next.keyframeType) return false
  for (let i = 0; i < prev.angles.length; i++) {
    if (prev.angles[i] !== next.angles[i]) return false
  }
  return true
}

export const ArcEndingAngleDisplay: React.FC<ArcEndingAngleDisplayProps> =
  React.memo(({ time, components }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <NumberValue
              components={components}
              time={time}
              icon={<icons.propertiesPanel.Sweep size={iconSize} flipped />}
              before={(value) => value * 100}
              after={(value) => value / 100}
              format={formatPercents}
              min={0}
              max={100}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        }
      />
    )
  }, propsAreEqual)
