import * as React from 'react'

export interface IProps {}
export const Border: React.FCC<IProps> = () => (
  <svg
    width="258"
    height="168"
    viewBox="0 0 258 168"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 1V5H1M10 5H14M19 5H23M28 5H32M37 5H41M46 5H50M55 5H59M64 5H68M73 5H77M82 5H86M91 5H95M100 5H104M109 5H113M118 5H122M127 5H131M136 5H140M145 5H149M154 5H158M163 5H167M172 5H176M181 5H185M190 5H194M199 5H203M208 5H212M217 5H221M226 5H230M235 5H239M244 5H248M257 5H253V1M5 10V14M5 19V23M5 28V32M5 37V41M5 46V50M5 55V59M5 64V68M5 73V77M5 82V86M5 91V95M5 100V104M5 109V113M5 118V122M5 127V131M5 136V140M5 145V149M5 154V158M5 167V163H1M10 163H14M19 163H23M28 163H32M37 163H41M46 163H50M55 163H59M64 163H68M73 163H77M82 163H86M91 163H95M100 163H104M109 163H113M118 163H122M127 163H131M136 163H140M145 163H149M154 163H158M163 163H167M172 163H176M181 163H185M190 163H194M199 163H203M208 163H212M217 163H221M226 163H230M235 163H239M244 163H248M253 10V14M253 19V23M253 28V32M253 37V41M253 46V50M253 55V59M253 64V68M253 73V77M253 82V86M253 91V95M253 100V104M253 109V113M253 118V122M253 127V131M253 136V140M253 145V149M253 154V158M257 163H253V167"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

Border.displayName = 'Border'
