import classNames from 'classnames'
import * as React from 'react'

import { InputBase } from '@material-ui/core'
import { useCommentContext } from '../comments-wrapper/use-cases/use-comment-context'
import { CommentsList } from './components/comments-list'

export interface IProps {}
export const CommentsPanel: React.FCC<IProps> = () => {
  const { setSearchText } = useCommentContext()

  const [isFilterDropdownOpen, setIsFilterDropdownOpen] =
    React.useState<boolean>(false)

  const closeFilterOptions = React.useCallback(() => {
    setIsFilterDropdownOpen(false)
  }, [])
  const openFilterOptions = React.useCallback(() => {
    setIsFilterDropdownOpen(true)
  }, [])

  const clearInput = React.useCallback(() => {
    setInput('')
  }, [])

  const { filterMode, setFilterMode } = useCommentContext()

  const toggleFilterMode = React.useCallback(() => {
    setFilterMode((value) => {
      if (value === 'all') return 'notResolved'
      else return 'all'
    })
    closeFilterOptions()
  }, [])

  const [input, setInput] = React.useState<string>('')

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setInput(e.currentTarget.value)
    },
    [setInput]
  )

  React.useEffect(() => {
    if (input.length > 0) setSearchText(input)
    else setSearchText(undefined)
  }, [input])

  return (
    <div className="bg-[--figma-color-bg]">
      <div className="border-b-solid border-b-gray-[--figma-color-border] sticky top-0 z-10 flex h-10 w-full flex-row items-center gap-1 border-b-[1px] bg-[--figma-color-bg] bg-opacity-100 px-2 py-3">
        <div className="flex w-full flex-row items-center">
          <InputBase
            value={input}
            onChange={handleInputChange}
            className="flex h-4 grow cursor-default flex-row items-center rounded bg-[--figma-color-bg] py-2 text-xs font-normal text-[--figma-color-text]"
            startAdornment={searchIconSvg}
            endAdornment={
              input.length > 0 && (
                <button onClick={clearInput} className="cursor-default px-2">
                  {crossIcon}
                </button>
              )
            }
            placeholder="Search"
            fullWidth
            type="text"
            // onBlur={clearInput}
            maxRows={1}
          />

          {/* right side button */}
          <div className="relative flex flex-row items-center">
            <button
              onClick={openFilterOptions}
              className="cursor-default hover:bg-[--figma-color-bg-onselected-hover]"
            >
              {filterButton}
            </button>

            {isFilterDropdownOpen && (
              <div
                className="pointer-events-auto fixed inset-0 z-[100]"
                onClick={closeFilterOptions}
              />
            )}

            <div
              className={classNames(
                'absolute right-1 top-[calc(100%+4px)] z-[200] flex w-max flex-col items-start bg-black py-1 transition-all duration-150',
                {
                  ['pointer-events-none -translate-y-2 opacity-0']:
                    !isFilterDropdownOpen,
                  ['pointer-events-auto -translate-y-1']: isFilterDropdownOpen,
                }
              )}
            >
              <button
                className="flex w-full flex-row items-center px-2 hover:bg-accent"
                onClick={toggleFilterMode}
              >
                <div className="flex h-4 w-4 items-center justify-center">
                  {filterMode === 'all' && checkIcon}
                </div>
                <p className="grow px-2 py-1 text-left text-xs text-white ">
                  Show resolved comments
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <CommentsList />
      </div>
    </div>
  )
}

const filterButton = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16ZM24 16C24 20.4183 20.4183 24 16 24C11.5817 24 8 20.4183 8 16C8 11.5817 11.5817 8 16 8C20.4183 8 24 11.5817 24 16ZM12 13V14H20V13H12ZM14 18V19H18V18H14ZM19 16.5H13V15.5H19V16.5Z"
      fill="var(--figma-color-text)"
      fillOpacity="0.8"
    />
  </svg>
)

const searchIconSvg = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 15C20 17.7614 17.7614 20 15 20C12.2386 20 10 17.7614 10 15C10 12.2386 12.2386 10 15 10C17.7614 10 20 12.2386 20 15ZM18.8744 19.5815C17.8291 20.4664 16.4769 21 15 21C11.6863 21 9 18.3137 9 15C9 11.6863 11.6863 9 15 9C18.3137 9 21 11.6863 21 15C21 16.4769 20.4664 17.8291 19.5815 18.8744L23.8536 23.1464L23.1464 23.8536L18.8744 19.5815Z"
      fill="var(--figma-color-text)"
      fillOpacity="0.3"
    />
  </svg>
)

const crossIcon = (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 5.293l4.789-4.79.707.708-4.79 4.79 4.79 4.789-.707.707-4.79-4.79-4.789 4.79-.707-.707L5.293 6 .502 1.211 1.21.504 6 5.294z"
      fillRule="nonzero"
      fillOpacity="1"
      fill="var(--figma-color-text)"
      stroke="none"
    ></path>
  </svg>
)

const checkIcon = (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.176 2.824L3.06 4.706 6.824.941 8 2.118 3.059 7.059 0 4l1.176-1.176z"
      fillRule="evenodd"
      fillOpacity="1"
      fill="white"
      stroke="none"
    ></path>
  </svg>
)

CommentsPanel.displayName = 'CommentsPanel'
