import React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const KeyframeEaseOut: React.FCC<IProps> = ({
  size = { x: 16, y: 16 },
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_37641_14997)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5 1C15.5 3.07707 14.9804 4.97671 13.6036 6.35355C12.2267 7.73039 10.0771 8.5 7 8.5L7 7.5C9.92293 7.5 11.7733 6.76961 12.8964 5.64645C14.0196 4.52329 14.5 2.92293 14.5 1L15.5 1ZM7.6279 12.8865C7.83292 13.0915 8.16531 13.0915 8.37033 12.8865L12.7711 8.48572L13.5135 9.22814L9.11275 13.6289C8.49771 14.244 7.50052 14.244 6.88548 13.6289L2.36984 9.1133C1.75479 8.49825 1.75479 7.50107 2.36984 6.88602L6.88548 2.37038C7.50052 1.75534 8.49771 1.75534 9.11275 2.37038L11.3706 4.6282L10.6281 5.37063L8.37033 3.11281C8.16531 2.90779 7.83292 2.90779 7.6279 3.11281L3.11226 7.62845C2.90725 7.83346 2.90725 8.16586 3.11226 8.37087L7.6279 12.8865Z"
        fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    </g>
    <defs>
      <clipPath id="clip0_37641_14997">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
