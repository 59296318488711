import * as React from 'react'
import { FollowUsFooter } from './follow-us-footer'

export interface IProps {}
export const FeedbackSuccess: React.FCC<IProps> = () => (
  <div className="flex flex-row items-center justify-center w-full">
    <div className="bg-white w-fit rounded-2xl overflow-hidden shadow-lg border-t border-t-gray-200 shadow-[#00034E]/20">
      <div className="flex flex-col items-center justify-center py-4 px-8">
        <SuccessIcon />

        <h2 className="mt-[24px] font-header text-2xl font-medium">
          We got your feedback!
        </h2>

        <p className="font-description mt-[16px] font-body text-lg font-normal text-center w-full">
          Expect reply anytime soon on your email
          <span className="my-[10px] block" />
        </p>
      </div>
      <FollowUsFooter />
    </div>
  </div>
)

const SuccessIcon = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 69C56.0092 69 69 56.009 69 40C69 23.9908 56.0092 11 40 11C23.9908 11 11 23.9908 11 40C11 56.009 23.9908 69 40 69Z"
      fill="white"
      stroke="#1BC47D"
      strokeWidth="7.73333"
    />
    <path
      d="M51.9625 32.3881L36.7375 47.6125L29.125 40.0006"
      stroke="#1BC47D"
      strokeWidth="3.86667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

FeedbackSuccess.displayName = 'FeedbackSuccess'
