import { SelectionSystem } from '@aninix-inc/model'
import * as React from 'react'

type SelectionContextType = {
  selection: SelectionSystem
}

export const SelectionContext = React.createContext<SelectionContextType>(
  null as any
)

export const useSelection = () => {
  const context = React.useContext(SelectionContext)
  if (!context) {
    throw new Error('useSelection must be used within a SelectionProvider')
  }
  return context
}
