import { RGBA, RgbaValueComponent } from '@aninix-inc/model'
import { SolidColorPicker } from '@aninix/app-design-system'
import { useComponent } from '@aninix/core'
import { Popover } from '@material-ui/core'
import { Typography } from 'apps/web-app/src/components/design-system/typography'
import * as React from 'react'
import tinycolor from 'tinycolor2'
import { useOverride } from '../../use-override-item'

export interface IProps {
  id: string
  component: RgbaValueComponent
}
export const ColorInfo: React.FCC<IProps> = ({ id, component }) => {
  useComponent(component)
  const { value: rgba } = component

  const { updateEntity } = useOverride()

  const sixDigitHex = tinycolor(rgba).toHex().toUpperCase()
  const alphaInPercents = `${Math.round(rgba.a * 100)}%`

  const handleColorChange = (color: RGBA) => {
    const newValue = tinycolor(color)

    if (newValue.toHex8() === tinycolor(rgba).toHex8()) return

    updateEntity(component, newValue.toRgb())
  }

  const anchorRef = React.useRef<HTMLLabelElement>(null)

  const [isColorPickerShown, setIsColorPickerShown] = React.useState(false)
  return (
    <label
      ref={anchorRef}
      className="flex flex-row w-full justify-between relative"
    >
      <button
        type="button"
        className="hidden"
        onClick={() => setIsColorPickerShown(true)}
      />
      <Popover
        open={isColorPickerShown}
        onClose={() => setIsColorPickerShown(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transitionDuration={150}
        elevation={5}
        style={{ background: 'transparent', right: '12px', top: '8px' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <SolidColorPicker color={rgba} onChange={handleColorChange} />
      </Popover>
      <div className="flex flex-row gap-2 items-center">
        <div className="w-4 h-4 flex flex-row rounded overflow-hidden border-solid border border-black/10">
          <div
            className="w-1/2 h-full"
            style={{ backgroundColor: `#${tinycolor(rgba).toHex()}` }}
          />
          <div
            className="w-1/2 h-full"
            style={{ backgroundColor: `#${tinycolor(rgba).toHex8()}` }}
          />
        </div>

        <Typography
          className="text-gray-500"
          style="Body5Regular"
          text={sixDigitHex}
        />
      </div>

      <Typography
        className="text-gray-500 w-[42px]"
        style="Body5Regular"
        text={alphaInPercents}
      />
    </label>
  )
}

ColorInfo.displayName = 'ColorInfo'
