import * as React from 'react'

export interface IProps {
  size?: { x: number; y: number }
  color?: string
}
export const Logo: React.FCC<IProps> = ({
  size = { x: 52, y: 52 },
  color = '#374FD5',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 52 52"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="52" height="52" rx="11.5781" fill={color} />
    <path
      d="M34.6328 25.543L22.5977 36.2578V16.4531L18.332 18.688V40.168L21.0234 42.3516L40.8281 24.5781L20.7188 8.63281L16.4531 10.8672L34.6328 25.543Z"
      fill="white"
    />
  </svg>
)
