import { Component, ConstructorWithTag, Entity } from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { buttons, icons } from '@aninix/app-design-system'
import { useComponent } from '@aninix/core'
import * as React from 'react'
import { useInfo } from '..'
import { useKeyframeIndicator } from '../../../../hooks/keyframe-indicator'

const btnSize = {
  width: 16,
  height: 16,
}

const iconSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  property: Component
  KeyframeConstructor: ConstructorWithTag<Entity>
}
export const KeyframesPropertyControl: React.FCC<IProps> = ({
  property,
  KeyframeConstructor,
}) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time } = useInfo()
  useComponent(property)
  const { type, color, toggleKeyframe } = useKeyframeIndicator({
    components: [property],
    time,
    KeyframeConstructor,
  })

  React.useEffect(() => {
    setIsEditable(false)
  }, [time])

  return (
    <div
      onPointerMove={() => setIsEditable(true)}
      onPointerLeave={() => setIsEditable(false)}
    >
      {isEditable ? (
        <KeyframesPropertyControlEditable
          toggle={toggleKeyframe}
          type={type}
          color={color}
        />
      ) : (
        <KeyframesPropertyControlDisplay type={type} color={color} />
      )}
    </div>
  )
}

KeyframesPropertyControl.displayName = 'KeyframesPropertyControl'

export const KeyframesPropertyControlEditable: React.FC<{
  toggle: () => void
  type: PropertyKeyframesType
  color?: string
}> = ({ toggle, type, color }) => {
  return (
    <buttons.Icon onClick={toggle} btnSize={btnSize}>
      <icons.KeyIndicator
        type={type}
        size={iconSize}
        style={{
          // @ts-ignore
          '--figma-color-text': color,
        }}
      />
    </buttons.Icon>
  )
}

KeyframesPropertyControlEditable.displayName =
  'KeyframesPropertyControlEditable'

export const KeyframesPropertyControlDisplay: React.FC<{
  type: PropertyKeyframesType
  color?: string
}> = React.memo(
  ({ type, color }) => {
    return (
      <buttons.Icon btnSize={btnSize} onClick={() => {}}>
        <icons.KeyIndicator
          type={type}
          size={iconSize}
          style={{
            // @ts-ignore
            '--figma-color-text': color,
          }}
        />
      </buttons.Icon>
    )
  },
  (prev, next) => {
    return prev.type === next.type && prev.color === next.color
  }
)

KeyframesPropertyControlDisplay.displayName = 'KeyframesPropertyControlDisplay'
