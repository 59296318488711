/* eslint-disable react/require-default-props */
import MaterialIconButton from '@material-ui/core/IconButton'
import React from 'react'
import * as styles from './index.scss'

interface IOptionButtonProps {
  onClick: () => void
  icon: React.ReactNode
  isOn: boolean
  disabled?: boolean
}

const OptionButton = ({
  onClick,
  icon,
  isOn,
  disabled,
}: IOptionButtonProps) => (
  <>
    <MaterialIconButton
      classes={{
        root: isOn ? styles['root-on'] : styles['root-off'],
        disabled: isOn ? styles['disabled-on'] : styles['disabled-off'],
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {icon}
    </MaterialIconButton>
  </>
)

export default OptionButton
