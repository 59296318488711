import {
  AnimationCurveAspect,
  CurveType,
  Entity,
  SpringCurveComponent,
  TimingCurveComponent,
  commitUndo,
  round,
} from '@aninix-inc/model'
import {
  Point2D,
  TimingCurve as TimingCurveType,
  timingCurve,
} from '@aninix-inc/model/legacy'
import { useAnalytics } from '@aninix/analytics'
import {
  CopyPreset,
  Select,
  TimingGraph,
  icons,
} from '@aninix/app-design-system'
import { useClipboard } from '@aninix/clipboard'
import { nodeColors, useFlattenEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { defaultTimingCurvePresets } from '../../../../../../default-curve-styles'

export type CurveStyle =
  | {
      type: CurveType.Timing
      out: Point2D
      in: Point2D
    }
  | {
      type: CurveType.Spring
      stiffness: number
      damping: number
      mass: number
    }

function timingCurveToText(timingCurve: TimingCurveType): string {
  // @NOTE: can happen when state change
  // @TODO: find and fix issue
  if (timingCurve.type === CurveType.Spring) {
    return '0.5, 0.35, 0.15, 1'
  }

  const xOut = round(timingCurve.out.x, { fixed: 2 })
  const yOut = round(timingCurve.out.y, { fixed: 2 })
  const xIn = round(timingCurve.in.x, { fixed: 2 })
  const yIn = round(timingCurve.in.y, { fixed: 2 })
  return `${xOut}, ${yOut}, ${xIn}, ${yIn}`
}

export const updateCurveStyle = (
  curveStyle: CurveStyle,
  segments: [Entity, Entity][]
): void => {
  if (curveStyle.type === CurveType.Timing) {
    segments.forEach((segment) => {
      segment[0].updateComponent(TimingCurveComponent, (value) => ({
        ...value,
        out: curveStyle.out,
      }))
      segment[1].updateComponent(TimingCurveComponent, (value) => ({
        ...value,
        in: curveStyle.in,
      }))
    })

    return
  }

  segments.forEach((segment) => {
    segment[0].updateComponent(SpringCurveComponent, curveStyle)
  })
}

export interface IProps {
  segments: [Entity, Entity][]
}
export const TimingCurve: React.FCC<IProps> = ({ segments }) => {
  const version = useFlattenEntities(segments)
  const analytics = useAnalytics()
  const clipboard = useClipboard()
  const outAspect = segments[0][0].getAspectOrThrow(AnimationCurveAspect)
  const inAspect = segments[0][1].getAspectOrThrow(AnimationCurveAspect)
  const isCurveStyleApplied = outAspect.hasCurveStyle()
  // @TODO: add support of mixed value
  const value = isCurveStyleApplied
    ? timingCurve.create({
        out: (outAspect.getCurveComponent().value as any).out,
        in: (outAspect.getCurveComponent().value as any).in,
      })
    : timingCurve.create({
        out: (outAspect.getCurveComponent().value as any).out,
        in: (inAspect.getCurveComponent().value as any).in,
      })

  const updateValue = React.useCallback(
    (
      newCurveStyle: CurveStyle,
      updateType?: 'preset-applied' | 'graph-changed'
    ) => {
      updateCurveStyle(newCurveStyle, segments)
      // @TODO: implement
      // if (updateType === 'preset-applied') {
      //   analytics.track({
      //     eventName: AnalyticsEvent.CurveStyleApplied,
      //     properties: {
      //       curveStyleId: curveStyle.id,
      //     },
      //   })
      // }
    },
    [segments, version]
  )

  const copy = React.useCallback(
    (text: string) => {
      // @TODO: replace with correct method
      clipboard.copyCurrentSelection()
    },
    [clipboard, version]
  )

  const onEndChange = React.useCallback(() => {
    commitUndo(segments[0][0].getProjectOrThrow())
  }, [segments, version])

  // @TODO: enable
  // const color =
  //   nodeColors[segments[0][0].getProperty().getNode().color as LayerColor]
  const color = nodeColors.BLUE
  const defaultPresets = defaultTimingCurvePresets
  const hasAppliedStyle = segments[0][0]
    .getAspectOrThrow(AnimationCurveAspect)
    .hasCurveStyle()

  const applyDefaultPreset = React.useCallback(
    (presetId: string) => {
      const preset = defaultPresets
        .filter((_preset) => _preset !== 'divider')
        .map((_preset) => _preset as { id: string; value: CurveStyle })
        .find((_preset) => _preset.id === presetId)

      if (preset == null) {
        return
      }

      updateValue(preset.value, 'preset-applied')
    },
    [version, defaultPresets]
  )

  const selectedPresetId = React.useMemo(() => {
    const preset = defaultPresets
      .filter((_preset) => _preset !== 'divider')
      // @ts-ignore
      .map((_preset) => _preset as CurveStyle)
      .find(
        (_preset) =>
          // @ts-ignore
          _preset.value.type === value.type &&
          // @TODO: check validity of type here
          // @ts-ignore
          _preset.value.isEqual(value)
      )

    if (preset == null) {
      return 'custom'
    }

    // @ts-ignore
    return preset.id
  }, [version, defaultPresets])

  const selectOptions = React.useMemo(() => {
    const defaultOptions = defaultPresets.map((preset) => {
      if (preset === 'divider') {
        return 'divider'
      }

      return {
        id: preset.id,
        title: preset.title,
        icon: (
          <icons.Graph
            previews={preset.value.getCache()}
            style={{
              // @ts-ignore
              '--figma-color-text':
                preset.id === selectedPresetId ? undefined : '#FFFFFF',
            }}
          />
        ),
      }
    })

    if (selectedPresetId === 'custom') {
      return R.pipe(
        // @ts-ignore
        R.append('divider'),
        R.append({
          id: 'custom',
          title: 'Custom',
          icon: (
            <icons.Graph
              previews={value.getCache()}
              style={{
                // @ts-ignore
                '--figma-color-text':
                  selectedPresetId === 'custom' ? undefined : '#FFFFFF',
              }}
            />
          ),
        })
        // @ts-ignore
      )(defaultOptions)
    }

    return defaultOptions
  }, [version, defaultPresets, selectedPresetId])

  const handleChangeTimingCurve = React.useCallback(
    (newCurve: TimingCurveType) => {
      updateValue(newCurve as any)
      onEndChange()
    },
    [version, onEndChange, updateValue]
  )

  return (
    <div className="w-full">
      {hasAppliedStyle === false && (
        <Select
          activeValueId={selectedPresetId}
          onChange={applyDefaultPreset}
          // @ts-ignore
          options={selectOptions}
          rootClassName="w-full"
          className="pl-2 pr-1"
        />
      )}

      <TimingGraph
        width={223}
        height={256}
        value={[value]}
        onDragEnd={onEndChange}
        // @ts-ignore
        onChange={updateValue}
        color={color}
        disableEditing={hasAppliedStyle}
      />

      {hasAppliedStyle === false && (
        <CopyPreset
          id="any"
          value={value}
          onChange={handleChangeTimingCurve}
          onCopy={copy}
        />
      )}
    </div>
  )
}
