import * as React from 'react'

interface IProps {}
export const Error: React.FCC<IProps> = ({}) => {
  return (
    <div className="bg-red rounded-[10px] flex flex-col items-center text-white text-center py-[40px] px-[20px] w-[360px]">
      <h2 className="font-header font-header text-[28px] leading-[36px] font-semibold">
        Oh no!
      </h2>

      <p className="font-body text-[20px] font-description leading-[30px] font-normal mt-[16px]">
        We could not process a payment.
        <span className="block my-[10px]" />
        Close this tab and try again.
      </p>
    </div>
  )
}
