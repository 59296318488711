import {
  Component,
  ConstructorWithTag,
  Entity,
  EntityType,
  EntityTypeComponent,
  NodeColorComponent,
  PropertiesExpandedComponent,
  TimeComponent,
  UpdatesSystem,
  commitUndo,
  expandProperties,
  getAnimatableValue,
  getNode,
  getSortedKeyframes,
} from '@aninix-inc/model'
import { Constructor } from '@aninix-inc/model/base/entity'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { useEntity } from '@aninix/core'
import * as R from 'ramda'
import { defaults } from '../defaults'

/**
 * also used in keyframe-indicator.ts.
 * @returns list of created keyframes or entities (in case when keyframe removed)
 * @todo: refactor to simplify logic of both functions.
 */
export const toggleKeyframe = <C extends Component>(
  KeyframeConstructor: ConstructorWithTag<Entity>,
  components: C[],
  time: number
): Entity[] => {
  return components.flatMap((component) => {
    const keyframes = getSortedKeyframes(component)

    if (keyframes.length === 0) {
      const createdKeyframe = component.entity
        .getProjectOrThrow()
        .createEntity(KeyframeConstructor, {
          target: component,
          time: time,
          value: component.value,
        })
      component.entity.updateComponent(PropertiesExpandedComponent, true)
      return [createdKeyframe]
    }

    const keyframe = keyframes.find(
      (currentKeyframe) =>
        currentKeyframe.getComponentOrThrow(TimeComponent).value === time
    )

    if (keyframe != null) {
      const node = getNode(keyframe)
      keyframe.getProjectOrThrow().removeEntity(keyframe.id)
      return node != null ? [node] : []
    }

    const value = getAnimatableValue(component, time)
    const createdKeyframe = component.entity
      .getProjectOrThrow()
      .createEntity(KeyframeConstructor, {
        target: component,
        time: time,
        value,
      })
    component.entity.updateComponent(PropertiesExpandedComponent, true)
    return [createdKeyframe]
  })
}

function getKeyframesType(
  keyframes: Entity[],
  time: number
): PropertyKeyframesType {
  const hasKeyframeAtTime =
    keyframes.find(
      (keyframe) => keyframe.getComponentOrThrow(TimeComponent).value === time
    ) != null
  const hasKeyframes = keyframes.length > 0

  if (hasKeyframeAtTime) {
    return PropertyKeyframesType.Active
  }

  if (hasKeyframes) {
    return PropertyKeyframesType.HasKeyframes
  }

  return PropertyKeyframesType.Empty
}

export function useKeyframeIndicator(payload: {
  components: Component[]
  time: number
  KeyframeConstructor: Constructor<Entity>
}): {
  type: PropertyKeyframesType
  toggleKeyframe: () => void
  color?: string
} {
  const entity = getNode(payload.components[0])

  if (entity == null) {
    throw new Error('Invalid state')
  }

  const project = entity.getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  useEntity(entity)

  const componentsKeyframes = payload.components.map(
    (component) => [component, getSortedKeyframes(component)] as const
  )

  const type = (() => {
    const types = componentsKeyframes.map(([_, keyframes]) =>
      getKeyframesType(keyframes, payload.time)
    )

    // @NOTE: if all equals
    if (R.all((type) => type === types[0], types)) {
      return types[0]
    }

    return types[0]
    // @TODO: add mixed type
    // return mixed
  })()

  const color = (() => {
    if (type === PropertyKeyframesType.Empty) {
      return undefined
    }

    if (entity == null) {
      return undefined
    }

    return defaults.nodeColors[
      entity.getComponentOrThrow(NodeColorComponent).value
    ]
  })()

  return {
    type,
    color,
    toggleKeyframe: () => {
      updates.batch(() => {
        const keyframesOrNodes = toggleKeyframe(
          payload.KeyframeConstructor,
          componentsKeyframes.map(([component]) => component),
          payload.time
        )

        for (const keyframesOrNode of keyframesOrNodes) {
          const entityType =
            keyframesOrNode.getComponentOrThrow(EntityTypeComponent).value

          if (entityType === EntityType.Keyframe) {
            const node = getNode(keyframesOrNode)
            if (node == null) {
              continue
            }
            expandProperties(node)
            continue
          }
        }
      })
      commitUndo(project)
    },
  }
}
