import { generateId } from '@aninix-inc/model'
import axios, { AxiosError } from 'axios'
import { config } from './config'

const internal: { token?: string; actorId?: string } = {
  // @NOTE: required to properly set unique actor ID
  actorId: generateId(),
}

export const httpClient = axios.create({
  headers: {
    'ani-request-id': internal.actorId,
  },
})

export function getHttpClientToken(): string | undefined {
  return internal.token
}

export function getHttpClientActorId(): string | undefined {
  return internal.actorId
}

export function setHttpClientToken(token: string) {
  httpClient.defaults.headers['Authorization'] = `Bearer ${token}`
  internal.token = token
}

httpClient.defaults.baseURL = config.apiUrl

httpClient.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // @TODO: unwrap .data so we don't use nested objects
    return response
  },
  (error: AxiosError) => {
    // @NOTE: redirect to maintenance page once server is down
    if (error.status === 503) {
      window.location.assign('/503')
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Unwrapped axios response to actual error data
    return Promise.reject({
      statusCode: error.response?.status,
      data: error.response?.data,
    })
  }
)
