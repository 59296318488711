import { Entity, RotationComponent } from '@aninix-inc/model'
import { INode } from 'svgson'
import { parseTransform } from '../../utils/parse-transform'

export const applyTransform = (entity: Entity, node: INode) => {
  const { transform } = node.attributes
  if (!transform) return

  const parsed = parseTransform(transform)

  for (const { type, values } of parsed) {
    switch (type) {
      case 'rotate':
        if (!values?.[0]) break
        entity.updateComponent(RotationComponent, values?.[0])
        break
    }
  }
}
