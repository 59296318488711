import { useGetMe } from '@aninix/api'
import * as React from 'react'

import { Button } from '../design-system/button'
import { Avatar } from '../member-list/member-row/avatar'
import { Info } from '../member-list/member-row/info'
import { CurrentRowContext } from '../member-list/use-current-row-context'
import { TextInputField } from '../team-settings/text-input-field'
import emailList from './free-domains.json'

export type IProps = {
  onTeamCreation: ({ name, domain }: { name: string; domain: string }) => void
  onCreationLoading: boolean
}
export const AddTeamInfo: React.FCC<IProps> = ({
  onTeamCreation,
  onCreationLoading,
}) => {
  const [inputTeamName, setInputTeamName] = React.useState<string | null>(null)
  const [inputDomain, setInputDomain] = React.useState<string | null>('')

  const setTeamName = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputTeamName(e.target.value)
    },
    []
  )

  const [timeoutId, setTimeoutId] = React.useState(0)
  const [delayedIsValid, setDelayedIsValid] = React.useState(true)

  const setDomainName = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const emailDomainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}$/i

      const input = e.target.value
      if (emailDomainRegex.test(input)) {
        setInputDomain(formatDomain(e.target.value))
        setDelayedIsValid(true)
      } else setInputDomain(null)

      clearTimeout(timeoutId)
      const newTimeoutId = setTimeout(() => {
        if (!emailDomainRegex.test(input)) {
          setDelayedIsValid(false)
        }
      }, 500)
      // @ts-ignore
      setTimeoutId(newTimeoutId)
    },
    [timeoutId]
  )

  const handleTeamCreation = React.useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault()

      if (onCreationLoading || !inputDomain || !inputTeamName) return

      onTeamCreation({ domain: inputDomain, name: inputTeamName })
    },
    [inputTeamName, inputDomain, onCreationLoading]
  )

  const user = useGetMe().data?.data

  const formatDomain = React.useCallback((input: string) => {
    const output = input.toLowerCase().replace(/^www\./, '')
    return output
  }, [])

  const guessDomain = React.useMemo(() => {
    let domain = user?.email?.match(/@(.+)$/)?.[1] ?? ''
    if (emailList.includes(domain)) domain = ''

    if (inputDomain !== domain) setInputDomain(domain)

    return domain
  }, [user])

  return (
    <form onSubmit={handleTeamCreation}>
      <div className="flex w-96 flex-col items-center gap-6 rounded-3xl border-[1px] border-gray-200 p-6">
        <CurrentRowContext.Provider
          value={{
            avatarUrl: user?.avatarUrl ?? undefined,
            name: user?.name ?? undefined,
            email: user?.email ?? undefined,
          }}
        >
          <div className="flex w-full flex-row gap-2">
            <Avatar />
            <Info />
          </div>
        </CurrentRowContext.Provider>

        <TextInputField
          defaultValue={''}
          onChange={setTeamName}
          label={'Team name'}
          placeholder={'Dream Team'}
        />

        <TextInputField
          defaultValue={guessDomain}
          isValid={delayedIsValid}
          validationMessage={'Please enter domain in format: domain.com'}
          initialValueMessage={
            guessDomain !== '' && guessDomain === inputDomain
              ? 'Please double-check if this is your company domain'
              : undefined
          }
          label={'Domain'}
          onChange={setDomainName}
          placeholder={'dreamteam.com'}
        />

        <Button
          buttonType="submit"
          isLoading={onCreationLoading}
          isActive={!!inputDomain && !!inputTeamName}
          label="Continue"
        />
      </div>
    </form>
  )
}

AddTeamInfo.displayName = 'AddTeamInfo'
