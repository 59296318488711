import * as React from 'react'
import tinycolor from 'tinycolor2'

import { ColorStop as ColorStopType } from '../types'
import * as styles from './index.scss'

export interface IProps {
  colorStop: Pick<ColorStopType, 'id' | 'value'>
  isSelected: boolean
  onMouseDown: (e: MouseEvent, id: string) => void
}
export const ColorStop: React.FCC<IProps> = ({
  colorStop,
  isSelected,
  onMouseDown,
}) => {
  const borderColor = React.useMemo(() => {
    if (isSelected) {
      return styles.color_highlighted
    }

    return styles.color_black
  }, [isSelected])

  const borderWidth = React.useMemo(() => {
    if (isSelected) {
      return 2
    }

    return 1
  }, [isSelected])

  const handleMouseDown = React.useCallback(
    (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
      onMouseDown(e as unknown as MouseEvent, colorStop.id)
    },
    [colorStop, onMouseDown]
  )

  return (
    <svg
      width="24"
      height="28"
      viewBox="0 0 24 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseDown={handleMouseDown}
    >
      <defs>
        <clipPath id={`clip_${colorStop.id}`}>
          <rect width={16} height={16} rx={1} />
        </clipPath>
      </defs>

      {/* marker */}
      <path
        d="M2.83333 1C1.82081 1 1 1.83147 1 2.85714V21.4286C1 22.4542 1.82081 23.2857 2.83333 23.2857H8.33333L12 27L15.6667 23.2857H21.1667C22.1792 23.2857 23 22.4542 23 21.4286V2.85714C23 1.83147 22.1792 1 21.1667 1H2.83333Z"
        fill="white"
        stroke={borderColor}
        strokeWidth={borderWidth}
      />

      <g transform="translate(4 4)" clipPath={`url(#clip_${colorStop.id})`}>
        <rect
          width={16}
          height={16}
          x={0}
          y={0}
          fill="url(#checkerboard_color_stop)"
        />
        <rect
          width={8}
          height={16}
          fill={`#${tinycolor(colorStop.value).toHex()}`}
        />
        <rect
          x={8}
          width={8}
          height={16}
          fill={tinycolor(colorStop.value).toRgbString()}
        />
      </g>
    </svg>
  )
}
