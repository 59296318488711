import {
  Point2D,
  Point2dKeyframe,
  SizeComponent,
  SizeLockedComponent,
  getValuePoint2d,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow } from '@aninix/app-design-system'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { LockablePoint2dValue } from '../../values/lockable-point-2d'
import { KeyframesPropertyControl } from '../keyframes-property-control'

export const Size: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, snapshots, time } = useNodePropertiesPanel()

  const filteredNodes = nodes.filter(
    (n) => n.hasComponent(SizeComponent) && n.hasComponent(SizeLockedComponent)
  )

  const components = filteredNodes.map((n) =>
    n.getComponentOrThrow(SizeComponent)
  )
  const lockableComponents = filteredNodes.map((n) =>
    n.getComponentOrThrow(SizeLockedComponent)
  )

  const sizes = snapshots.map((s) => s.size)
  const keyframeType = getKeyframesType(components, time)

  React.useEffect(() => {
    setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <SizeEditable
          time={time}
          components={components}
          locableComponents={lockableComponents}
        />
      ) : (
        <SizeDisplay
          time={time}
          components={components}
          locableComponents={lockableComponents}
          sizes={sizes}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

Size.displayName = 'Size'

const SizeEditable: React.FC<{
  time: number
  components: SizeComponent[]
  locableComponents: SizeLockedComponent[]
}> = ({ time, components, locableComponents }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <LockablePoint2dValue
            components={components}
            lockableComponents={locableComponents}
            time={time}
            iconX={<span>W</span>}
            iconY={<span>H</span>}
            minX={0}
            minY={0}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={Point2dKeyframe}
          valueGetter={getValuePoint2d}
        />
      }
    />
  )
}

SizeEditable.displayName = 'SizeEditable'

interface SizeDisplayProps {
  time: number
  components: SizeComponent[]
  locableComponents: SizeLockedComponent[]
  sizes: Point2D[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (prev: SizeDisplayProps, next: SizeDisplayProps) => {
  if (prev.keyframeType !== next.keyframeType) return false
  if (prev.sizes.length !== next.sizes.length) return false
  for (let i = 0; i < prev.sizes.length; i++) {
    if (
      prev.sizes[i].x !== next.sizes[i].x ||
      prev.sizes[i].y !== next.sizes[i].y
    )
      return false
  }
  return true
}

const SizeDisplay: React.FC<SizeDisplayProps> = React.memo(
  ({ time, components, locableComponents }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <LockablePoint2dValue
              components={components}
              lockableComponents={locableComponents}
              time={time}
              iconX={<span>W</span>}
              iconY={<span>H</span>}
              minX={0}
              minY={0}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={Point2dKeyframe}
            valueGetter={getValuePoint2d}
          />
        }
      />
    )
  },
  propsAreEqual
)

SizeDisplay.displayName = 'SizeDisplay'
