import { Entity, TimeComponent, round } from '@aninix-inc/model'
import { InfoBlock } from './mappers/types'

/**
 * The class maps provided segment and returns data for time block.
 * @todo: And move it to entities folder.
 */
export class TimeBlockSegment {
  constructor(private readonly segment: [Entity, Entity]) {}

  asBlock = (): InfoBlock => {
    const [left, right] = this.segment

    return {
      propertiesName: 'Time properties',
      properties: [
        {
          title: 'Delay',
          value: `${round(
            left.getComponentOrThrow(TimeComponent).value * 1000,
            { fixed: 0 }
          )} ms`,
        },
        {
          title: 'Duration',
          value: `${round(
            (right.getComponentOrThrow(TimeComponent).value -
              left.getComponentOrThrow(TimeComponent).value) *
              1000,
            {
              fixed: 0,
            }
          )} ms`,
        },
        {
          title: '',
          value: '',
        },
      ],
    }
  }
}
