import throttle from 'lodash/throttle'
import { useEffect, useMemo } from 'react'

interface IPayload {
  delay: number
  callback: (...args: any[]) => void | Promise<void>
}
/**
 * @description throttle wrapper from lodash to use inside a hook
 */
export function useThrottle({ callback, delay }: IPayload) {
  const throttleHandler = useMemo(
    () => throttle(callback, delay),
    [callback, delay]
  )

  useEffect(() => () => throttleHandler.cancel(), [])

  return throttleHandler
}
