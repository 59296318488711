import * as R from 'ramda'
import * as React from 'react'

const FRAMES_BUFFER = 70

export interface IFpsStatsInteractor {
  fps: number
}

export const useFpsStatsInteractor = (): IFpsStatsInteractor => {
  const currentTime = Date.now()
  const requestId = React.useRef<number | undefined>(undefined)
  const prevTime = React.useRef<number>(currentTime)
  const frames = React.useRef<number>(0)
  const [fps, setFps] = React.useState<number[]>([])

  const calcFps = React.useCallback(() => {
    const currentTime = Date.now()
    frames.current += 1

    if (currentTime > prevTime.current + 1000) {
      const lastFps = Math.round(
        (frames.current * 1000) / (currentTime - prevTime.current)
      )

      setFps((state) => [...state, lastFps])
      setFps((state) => state.slice(-FRAMES_BUFFER))
      frames.current = 0
      prevTime.current = currentTime
    }
  }, [fps])

  React.useEffect(() => {
    const onRequestAnimationFrame = () => {
      calcFps()
      requestId.current = window.requestAnimationFrame(onRequestAnimationFrame)
    }

    requestId.current = window.requestAnimationFrame(onRequestAnimationFrame)

    return () => window.cancelAnimationFrame(requestId.current!)
  }, [])

  return {
    fps: R.last(fps)!,
  }
}
