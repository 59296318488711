/**
 * @description return full cycles
 */
export function toCycles(value: number): number {
  if (value < 360) {
    return 0
  }

  return Math.floor(value / 360)
}

/**
 * @description return part of angles, cut full cycles
 */
export function toDegrees(value: number): number {
  return Math.round(value % 360)
}

/**
 * @description formatter function
 */
export function formatAngleValue(value: number): string {
  return `x${toCycles(value)}, ${toDegrees(value)}°`
}
