import {
  ChildrenRelationsAspect,
  CurveType,
  Entity,
  EntryComponent,
  getAnimatableValue,
  getSize,
  getSortedKeyframes,
  NameComponent,
  NumberValueComponent,
  OpacityComponent,
  setAnchorPoint,
  setEndTime,
  setStartTime,
  TimeComponent,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import { TimingCurve } from '@aninix/figma'
import { animate } from './smart-animate.transition'

export namespace PrototypeNode {
  export type Type = {
    entity: Entity

    /**
     * Add indicies to copied layer names.
     * @example
     * ```
     * - rectangle
     * - rectangle
     * - ellipse
     * ```
     * ->
     * ```
     * - rectangle 1
     * - rectangel 2
     * - ellipse
     * ```
     */
    renameChildrenWithIndicies: () => void

    appear: (options: {
      timeStart: number
      timeEnd: number
      curve?: TimingCurve
    }) => void

    disappear: (options: {
      timeStart: number
      timeEnd: number
      curve?: TimingCurve
    }) => void
  }

  export class Base implements Type {
    constructor(public readonly entity: Entity) {
      // @NOTE: anchor point at center temporarily disabled.
      // @TODO: enable
      return

      if (entity.hasComponent(EntryComponent) === false) {
        const initialSize = getSize(entity)
        setAnchorPoint(entity, {
          x: initialSize.x / 2,
          y: initialSize.y / 2,
        })
      }
    }

    renameChildrenWithIndicies: Type['renameChildrenWithIndicies'] = () => {
      let stack: Entity[] = [this.entity]

      while (stack.length > 0) {
        const entity = stack.pop()

        if (entity == null) {
          throw new Error('Invalid state')
        }

        if (entity.hasAspect(ChildrenRelationsAspect)) {
          const namesMap = new Map<string, number>()
          const children = entity
            .getAspectOrThrow(ChildrenRelationsAspect)
            .getChildrenList()
          for (const child of children) {
            const name = child.getComponentOrThrow(NameComponent).value
            const existingIndex = namesMap.get(name)

            if (existingIndex != null) {
              child.updateComponent(
                NameComponent,
                (name) => `${name} - ${existingIndex}`
              )
              namesMap.set(name, existingIndex + 1)
              continue
            }

            namesMap.set(name, 1)
          }

          stack.push(...children)
        }
      }
    }

    appear: Type['appear'] = ({ timeStart, timeEnd, curve }) => {
      setStartTime(this.entity, timeStart)

      this.entity.updateComponent(VisibleInViewportComponent, true)

      const keyframesInRange = getSortedKeyframes(
        this.entity.getComponentOrThrow(OpacityComponent)
      ).filter((keyframe) => {
        const time = keyframe.getComponentOrThrow(TimeComponent).value
        return time >= timeStart && time <= timeEnd
      })
      if (keyframesInRange.length > 0) {
        return
      }

      const opacity = this.entity.getComponentOrThrow(OpacityComponent)
      const startOpacity = 0
      const endOpacity = getAnimatableValue(opacity, timeEnd)

      const startKeyframe = keyframesInRange[0]

      // @NOTE: stop processing if layer is already visible
      if (
        startKeyframe != null &&
        startKeyframe.getComponentOrThrow(NumberValueComponent).value ===
          endOpacity
      ) {
        return
      }

      animate(opacity, startOpacity, endOpacity, {
        timeStart,
        timeEnd,
        curve: curve ?? {
          type: CurveType.Timing,
          value: {
            out: {
              x: 0.42,
              y: 0,
            },
            in: {
              x: 1 - 0.42,
              y: 1,
            },
          },
        },
      })
    }

    disappear: Type['disappear'] = ({ timeStart, timeEnd, curve }) => {
      setEndTime(this.entity, timeEnd)

      const keyframesInRange = getSortedKeyframes(
        this.entity.getComponentOrThrow(OpacityComponent)
      ).filter((keyframe) => {
        const time = keyframe.getComponentOrThrow(TimeComponent).value
        return time >= timeStart && time <= timeEnd
      })
      if (keyframesInRange.length > 0) {
        return
      }

      const opacity = this.entity.getComponentOrThrow(OpacityComponent)
      const startOpacity = getAnimatableValue(opacity, timeStart)
      const endOpacity = 0

      const startKeyframe = keyframesInRange[0]

      // @NOTE: stop processing if layer is already hidden
      if (
        startKeyframe != null &&
        startKeyframe.getComponentOrThrow(NumberValueComponent).value ===
          endOpacity
      ) {
        return
      }

      animate(opacity, startOpacity, endOpacity, {
        timeStart,
        timeEnd,
        curve: curve ?? {
          type: CurveType.Timing,
          value: {
            out: {
              x: 0.42,
              y: 0,
            },
            in: {
              x: 1 - 0.42,
              y: 1,
            },
          },
        },
      })
    }
  }
}
