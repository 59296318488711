import { Project, SelectionSystem } from '@aninix-inc/model'
import { useSystem } from '@aninix/core'
import * as React from 'react'
import { SelectionContext } from './selection-context'

interface IProps {
  project: Project
}
export const SelectionProvider: React.FCC<IProps> = ({ children, project }) => {
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)

  return (
    <SelectionContext.Provider value={{ selection }}>
      {children}
    </SelectionContext.Provider>
  )
}
