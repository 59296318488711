import { Project } from '@aninix-inc/model'
import { PropertyRow } from '@aninix/app-design-system'
import { HotkeyCombination } from '@aninix/app-design-system/components/common/hotkey-combination'
import { useProject } from '@aninix/core'
import { Typography } from 'apps/web-app/src/components/design-system/typography'
import { LoadableChildrenWrapper } from 'apps/web-app/src/components/loadable-wrapper'
import * as React from 'react'
import { hotkeysLabels } from '../../defaults'
import {
  GetProjectHistoryResult,
  useGetProjectHistory,
} from '../../use-cases/use-get-project-history'
import { VersionEntry } from './version-entry'

export interface IProps {
  defaultState?: GetProjectHistoryResult
  setIsProjectLoading?: React.Dispatch<React.SetStateAction<boolean>>
  selectedVersion: number
  setSelectedVersion: React.Dispatch<React.SetStateAction<number>>
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}

export const ProjectHistoryPanel: React.FCC<IProps> = ({
  defaultState,
  setIsProjectLoading,
  selectedVersion,
  setSelectedVersion,
  setProject,
}) => {
  const project = useProject()
  const projectHistory =
    defaultState ??
    useGetProjectHistory({
      projectId: project.id,
    })

  return (
    <PropertyRow
      name="Version history"
      renderInputs={() => (
        <LoadableChildrenWrapper
          isError={projectHistory.isError}
          isLoading={projectHistory.isLoading}
          errorComponent={
            <Typography className="px-2 text-gray-400" style="Subscript">
              Couldn't load history, try again later
            </Typography>
          }
        >
          <Typography className="px-2 py-2" style="Subscript">
            Press{' '}
            <HotkeyCombination
              isOnLightBackground={true}
              keys={[hotkeysLabels().ctrl, hotkeysLabels().option, 'S']}
            />{' '}
            to add to version history while editing
          </Typography>
          <div className="absolute left-0 top-full flex w-full flex-row">
            <div className="relative flex-row">
              <div className="absolute bottom-7 left-4 top-5 w-[1px] bg-[#D9D9D9]"></div>
            </div>
            <div className="flex w-full flex-col">
              {projectHistory.data?.map(({ id, createdAt, name }, index) => (
                <VersionEntry
                  key={id}
                  id={id}
                  createdAt={createdAt}
                  versionNumber={projectHistory.data!.length - index}
                  name={name}
                  isCurrent={index === 0}
                  isSelected={selectedVersion === index}
                  handleSelection={() => setSelectedVersion(index)}
                  setIsProjectLoading={setIsProjectLoading}
                  setProject={setProject}
                />
              ))}
            </div>
          </div>
        </LoadableChildrenWrapper>
      )}
    ></PropertyRow>
  )
}

ProjectHistoryPanel.displayName = 'ProjectHistoryPanel'
