import * as React from 'react'

const defaultSize = { x: 11, y: 14 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const CenterToSlider: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2.91465C6.5826 2.70873 7 2.15311 7 1.5C7 0.671573 6.32843 0 5.5 0C4.67157 0 4 0.671573 4 1.5C4 2.15311 4.4174 2.70873 5 2.91465V4H1C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6H5V9H3C2.44772 9 2 9.44771 2 10C2 10.5523 2.44772 11 3 11H5V14H6V11H8C8.55228 11 9 10.5523 9 10C9 9.44771 8.55229 9 8 9H6V6H10C10.5523 6 11 5.55228 11 5C11 4.44772 10.5523 4 10 4H6V2.91465Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
