import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 21,
  y: 20,
}

export const Error: React.FCC<IIcon> = ({ size = defaultSize, className }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect x="0.5" width="20" height="20" rx="6" fill="#ED6A5E" />
    <path
      d="M14.875 14.375L6.125 5.625"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.125 14.375L14.875 5.625"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
