import {
  Entity,
  EntityType,
  TargetRelationAspect,
  TimeComponent,
  UndoRedoSystem,
  UpdatesSystem,
  mixed,
  round,
} from '@aninix-inc/model'
import {
  InputWithIcon,
  PropertyRowV2,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { getSelection, useFlattenEntities } from '@aninix/core'
import { setWith } from 'lodash'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useFormatTime, useFormattedTime } from '../../formatted-time'
import { useThreshold } from '../../threshold'

function isOffsetExistsFunc(values: Entity[][][]) {
  const isMultipleLayersSelected = values.length > 1

  const segmentsStart = (() => {
    if (isMultipleLayersSelected) {
      return values.map(
        ([firstSegment]) =>
          firstSegment[0].getComponentOrThrow(TimeComponent).value
      )
    }

    return values[0].map(
      ([firstKeyframe]) =>
        firstKeyframe.getComponentOrThrow(TimeComponent).value
    )
  })()

  let differencesArray: number[] = []

  for (let i = 1; i < segmentsStart.length; i += 1) {
    const previousStart = segmentsStart[i - 1]
    const currentStart = segmentsStart[i]
    differencesArray.push(round(currentStart - previousStart, { fixed: 4 }))
  }

  const equality = differencesArray.reduce((acc, current) => {
    acc.add(current)
    return acc
  }, new Set<number>([]))

  return equality.size === 1
}

export interface IProps {
  segments: [Entity, Entity][]
}
export const Offset: React.FCC<IProps> = observer(({ segments }) => {
  useFlattenEntities(segments)
  const project = segments[0][0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const keyframes = getSelection(project, EntityType.Keyframe)

  /**
   * @description group keyframes by layers by properties for easier updates.
   * Structure is sortedSegments[layer][property][keyframe]
   */
  const keyframesByPropertiesByLayers = (() => {
    let keyframesByLayers: Record<string, Record<string, Entity[]>> = {}

    for (const keyframe of keyframes) {
      const path = keyframe
        .getAspectOrThrow(TargetRelationAspect)
        .getRelationOrThrow()
        .split('/')
      const propertiesAtPath = R.path(path, keyframesByLayers) as Entity[]
      if (propertiesAtPath == null) {
        setWith(keyframesByLayers, [...path, 0], keyframe)
        continue
      }
      propertiesAtPath.push(keyframe)
    }

    return Object.values(keyframesByLayers).map((keyframesByProperties) =>
      Object.values(keyframesByProperties)
    )
  })() as Entity[][][]

  const isMultipleLayersSelected = keyframesByPropertiesByLayers.length > 1
  const isOffsetExists = isOffsetExistsFunc(keyframesByPropertiesByLayers)

  const rawValue = (() => {
    if (isOffsetExists) {
      if (isMultipleLayersSelected) {
        return (
          keyframesByPropertiesByLayers[1][0][0].getComponentOrThrow(
            TimeComponent
          ).value -
          keyframesByPropertiesByLayers[0][0][0].getComponentOrThrow(
            TimeComponent
          ).value
        )
      }

      return (
        keyframesByPropertiesByLayers[0][1][0].getComponentOrThrow(
          TimeComponent
        ).value -
        keyframesByPropertiesByLayers[0][0][0].getComponentOrThrow(
          TimeComponent
        ).value
      )
    }

    return mixed
  })()
  const { value, suffix } = useFormattedTime(
    rawValue === mixed ? mixed : Math.abs(rawValue)
  )
  const { toSeconds } = useFormatTime()
  const threshold = useThreshold()

  const initialValue = React.useRef<number | typeof mixed>(0)
  const [direction, setDirection] = React.useState<'left' | 'right'>(
    rawValue === mixed ? 'left' : rawValue >= 0 ? 'left' : 'right'
  )

  const startChange = React.useCallback(() => {
    initialValue.current = value
  }, [value])

  const endChange = React.useCallback(() => {
    undoRedo.commitUndo()
  }, [])

  const applyOffset = React.useCallback(
    (offset: number, direction: 'left' | 'right') => {
      updates.batch(() => {
        if (isMultipleLayersSelected) {
          // @NOTE: apply offset to segments
          const layersToApply =
            direction === 'left'
              ? keyframesByPropertiesByLayers
              : R.reverse(keyframesByPropertiesByLayers)
          const startTimeToApply =
            layersToApply[0][0][0].getComponentOrThrow(TimeComponent).value
          layersToApply.forEach((layer, layerIdx) => {
            const layerStartTime =
              layer[0][0].getComponentOrThrow(TimeComponent).value
            layer.forEach((property) => {
              const propertyStartTime =
                property[0].getComponentOrThrow(TimeComponent).value
              const propertyDiff = layerStartTime - propertyStartTime
              property.forEach((keyframe) => {
                const keyframeTime =
                  keyframe.getComponentOrThrow(TimeComponent).value
                const keyframeDiff = keyframeTime - propertyStartTime
                keyframe.updateComponent(
                  TimeComponent,
                  startTimeToApply +
                    propertyDiff +
                    keyframeDiff +
                    offset * layerIdx
                )
              })
            })
          })

          return
        }

        // @NOTE: apply offset to segments
        const propertiesToWork =
          direction === 'left'
            ? keyframesByPropertiesByLayers[0]
            : R.reverse(keyframesByPropertiesByLayers[0])
        const startTimeToApply =
          propertiesToWork[0][0].getComponentOrThrow(TimeComponent).value
        propertiesToWork.forEach((property, propertyIdx) => {
          const propertyStartTime =
            property[0].getComponentOrThrow(TimeComponent).value
          property.forEach((keyframe) => {
            const keyframeDiff =
              keyframe.getComponentOrThrow(TimeComponent).value -
              propertyStartTime
            keyframe.updateComponent(
              TimeComponent,
              startTimeToApply + keyframeDiff + offset * propertyIdx
            )
          })
        })
      })
    },
    [isMultipleLayersSelected, keyframesByPropertiesByLayers]
  )

  const updateValue = React.useCallback(
    (duration: number): void => {
      const roundedValue = toSeconds(duration)
      applyOffset(roundedValue, direction)
      undoRedo.commitUndo()
    },
    [project, applyOffset, direction, undoRedo]
  )

  const updateByDelta = React.useCallback(
    (delta: number) => {
      initialValue.current =
        initialValue.current === mixed ? 0 : initialValue.current + delta
      applyOffset(toSeconds(initialValue.current), direction)
      undoRedo.commitUndo()
    },
    [project, applyOffset, undoRedo, direction]
  )

  const updateValueAfterDirectionSet = React.useCallback(
    (providedDirection: 'left' | 'right') => {
      updates.batch(() => {
        try {
          if (isMultipleLayersSelected) {
            // @NOTE: reset segments
            const startTimeToReset = keyframesByPropertiesByLayers
              .flat()
              .map(
                (property) =>
                  property[0].getComponentOrThrow(TimeComponent).value
              )
              .reduce((min, time) => (time < min ? time : min), Infinity)
            keyframesByPropertiesByLayers.forEach((layer) => {
              const layerStartTime =
                layer[0][0].getComponentOrThrow(TimeComponent).value
              layer.forEach((property) => {
                const propertyStartTime =
                  property[0].getComponentOrThrow(TimeComponent).value
                const propertyDiff = propertyStartTime - layerStartTime
                property.forEach((keyframe) => {
                  const keyframeDiff =
                    keyframe.getComponentOrThrow(TimeComponent).value -
                    propertyStartTime
                  keyframe.updateComponent(
                    TimeComponent,
                    startTimeToReset + propertyDiff + keyframeDiff
                  )
                })
              })
            })
            return
          }

          // @NOTE: reset segments
          const startTimeToReset = keyframesByPropertiesByLayers[0]
            .map(
              (property) => property[0].getComponentOrThrow(TimeComponent).value
            )
            .reduce((min, time) => (time < min ? time : min), Infinity)
          keyframesByPropertiesByLayers[0].forEach((property) => {
            const propertyStartTime =
              property[0].getComponentOrThrow(TimeComponent).value
            property.forEach((keyframe) => {
              const diff =
                keyframe.getComponentOrThrow(TimeComponent).value -
                propertyStartTime
              keyframe.updateComponent(TimeComponent, startTimeToReset + diff)
            })
          })
        } finally {
          if (value !== mixed) {
            applyOffset(toSeconds(Math.abs(value)), providedDirection)
          }
          setDirection(providedDirection)
          undoRedo.commitUndo()
        }
      })
    },
    [
      value,
      isMultipleLayersSelected,
      keyframesByPropertiesByLayers,
      updates,
      undoRedo,
      applyOffset,
    ]
  )

  const onClickLeft = React.useCallback(() => {
    updateValueAfterDirectionSet('left')
  }, [updateValueAfterDirectionSet])

  const onClickRight = React.useCallback(() => {
    updateValueAfterDirectionSet('right')
  }, [updateValueAfterDirectionSet])

  const segmentIds = segments.map((segment) => [segment[0].id, segment[1].id])

  const id = React.useMemo(() => `${segmentIds[0]}-${segmentIds[1]}-offset`, [])

  const formatValue = React.useCallback(
    (providedValue: number): string => `${providedValue}${suffix}`,
    [suffix]
  )

  return (
    <PropertyRowV2
      name="Offset"
      inputs={
        <>
          <div className="flex-shrink-0">
            <InputWithIcon
              id={id}
              value={value}
              icon={<icons.Time />}
              threshold={threshold}
              onStartChange={startChange}
              onChange={updateValue}
              onDeltaChange={updateByDelta}
              onEndChange={endChange}
              format={isOffsetExists ? formatValue : undefined}
              width={96}
            />
          </div>

          <div className="flex w-full flex-row flex-nowrap items-stretch justify-end">
            <buttons.Icon onClick={onClickLeft} active={direction === 'left'}>
              <icons.propertiesPanel.Offset type="left" />
            </buttons.Icon>

            <buttons.Icon onClick={onClickRight} active={direction === 'right'}>
              <icons.propertiesPanel.Offset type="right" />
            </buttons.Icon>
          </div>
        </>
      }
    />
  )
})

Offset.displayName = 'Offset'
