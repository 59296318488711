import * as React from 'react'

import { Logger } from './logger'

const Context = React.createContext<Logger>(console)

export const useLogger = (): Logger => React.useContext(Context)

export const LoggerProvider: React.FCC<{ client: Logger }> = ({
  client,
  children,
}) => <Context.Provider value={client}>{children}</Context.Provider>
