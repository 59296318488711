import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { Playback, getSelection } from '@aninix/core'
import * as React from 'react'

import {
  EntityType,
  EntryComponent,
  SelectionSystem,
  UndoRedoSystem,
  UpdatesSystem,
} from '@aninix-inc/model'
import { useNotifications } from '@aninix/notifications'
import { Preset } from '../models/preset'

type Payload = {
  playback: Playback
  selection: SelectionSystem
  undoRedo: UndoRedoSystem
  updates: UpdatesSystem
}

export interface IUseApplyPresetUseCase {
  apply: (payload: { preset: Preset }) => Promise<void>
}

/**
 * @descriptions determine count of active projects in current figma file
 */
export function useApplyPresetUseCase({
  playback,
  selection,
  undoRedo,
  updates,
}: Payload): IUseApplyPresetUseCase {
  const analytics = useAnalytics()
  const notifications = useNotifications()
  const apply: IUseApplyPresetUseCase['apply'] = React.useCallback(
    async ({ preset }) => {
      const nodesToApply = getSelection(
        selection.getProjectOrThrow(),
        EntityType.Node
      ).filter((node) => node.hasComponent(EntryComponent) === false)

      if (nodesToApply.length === 0) {
        notifications.showNotification('⚠️ Please select any node')
        return
      }

      updates.batch(() => {
        preset.apply({
          nodes: nodesToApply,
          time: playback.time,
        })
      })
      undoRedo.commitUndo()

      analytics.track({
        eventName: AnalyticsEvent.AnimationPresetApplied,
        properties: {
          preset: preset.id,
        },
      })
    },
    []
  )

  return {
    apply,
  }
}
