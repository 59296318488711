import { useViewport } from '@aninix/core'
import { IComment } from '@aninix/core/use-cases/comments/use-get-comments'
import * as React from 'react'

import { CommentThreadItem } from './comment-thread-item'
import { ReplyToThreadFooter } from './reply-to-thread-footer'
import { ThreadHeader } from './thread-header'

export interface IProps {
  data: IComment[]
  nextCommentId?: string
  previousCommentId?: string
}
export const CommentsThread: React.FCC<IProps> = ({
  data,
  nextCommentId,
  previousCommentId,
}) => {
  const comments = data.slice().reverse()

  const viewport = useViewport()

  const maxHeight = React.useMemo(
    () => Math.max(200, viewport.size.y - 200),
    [viewport]
  )

  return (
    <div
      className="absolute left-0 top-0"
      style={{
        transform: 'translateY(calc(' + viewport.size.y * 0.1 + 'px - 50%)',
      }}
    >
      <div className=" bg-white shadow-md">
        <ThreadHeader
          parentComment={comments.at(-1)!}
          nextCommentId={nextCommentId}
          previousCommentId={previousCommentId}
        />
        <div className="px-4">
          <div className="h-[1px] w-full bg-[#F0F0F0]" />
        </div>
        {/* pay attention that there's used flex-col-reverse and array is reversed when passed, so parent element is actually last here */}
        <div
          className="flex w-[300px] flex-col-reverse gap-0 overflow-y-scroll"
          style={{ maxHeight: maxHeight + 'px' }}
        >
          {comments.map((comment, index) => (
            <CommentThreadItem
              key={comment.id}
              data={comment}
              index={comments.length - index - 1}
              isLast={index === comments.length - 1}
            />
          ))}
        </div>
        <ReplyToThreadFooter parentCommentId={comments.at(-1)!.id} />
      </div>
    </div>
  )
}

CommentsThread.displayName = 'CommentsThread'
