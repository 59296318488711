import type { Point2D } from '@aninix-inc/model/legacy'

import { round } from './round'
import type { Vector } from './types'

interface IPayload {
  vector: Vector
  // in range 0...1
  progress: number
}
/**
 * @description
 * find point on given vector with given progress
 *
 * @example
 * if we have
 * vector: [0, 0], [100, 100] and progress: 0.2 then return value would be [20, 20]
 */
export function getPointOnVector({ vector, progress }: IPayload): Point2D {
  const currentProgress = (() => {
    if (progress < 0) {
      return 0
    }

    if (progress > 1) {
      return 1
    }

    return progress
  })()

  const result2: Point2D = {
    x: round(
      vector.start.x * (1 - currentProgress) + vector.end.x * currentProgress
    ),
    y: round(
      vector.start.y * (1 - currentProgress) + vector.end.y * currentProgress
    ),
  }

  return result2
}
