import * as R from 'ramda'
import * as React from 'react'
import tinycolor from 'tinycolor2'

import { useMouseMove } from '../../../hooks'

const circleRadius = 6
const circleSize = circleRadius * 2

const noop = () => {}

export interface IProps {
  /**
   * @description hue in range 0...360
   */
  hue: number
  onStartChange?: () => void
  onEndChange?: () => void
  onChange: (hue: number) => void
  size?: number
}
export const HuePicker: React.FCC<IProps> = ({
  hue,
  onStartChange = noop,
  onEndChange = noop,
  onChange,
  size = 240,
}) => {
  const containerRef = React.useRef<any>(null)
  const [_, setMounted] = React.useState(false)
  const wrapperSize = React.useMemo(() => size - circleSize, [size])

  const { endAtX, startListen, isListening, wasTriggered } = useMouseMove({
    threshold: 0,
    element: containerRef!.current,
    onStart: onStartChange,
    onFinish: onEndChange,
  })

  React.useEffect(() => {
    if (isListening === false || wasTriggered === false) {
      return
    }

    const newProgress = R.clamp(0, 1, endAtX / wrapperSize)
    onChange(newProgress * 360)
  }, [endAtX, wrapperSize, isListening, wasTriggered])

  React.useEffect(() => {
    setMounted(true)
  }, [])

  const progress = React.useMemo(() => hue / 360, [hue])

  const currentColor = React.useMemo(
    () =>
      `#${tinycolor({
        h: hue,
        s: 1,
        v: 1,
      }).toHex()}`,
    [hue]
  )

  return (
    <svg width={size} height={14} viewBox={`0 0 ${size} 14`}>
      <defs>
        <linearGradient id="hue_horizontal" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset={`${(0 / 360) * 100}%`} stopColor="#FF0000" />
          <stop offset={`${(30 / 360) * 100}%`} stopColor="#FFA800" />
          <stop offset={`${(60 / 360) * 100}%`} stopColor="#FFFF00" />
          <stop offset={`${(120 / 360) * 100}%`} stopColor="#00FF00" />
          <stop offset={`${(180 / 360) * 100}%`} stopColor="#00FFFF" />
          <stop offset={`${(240 / 360) * 100}%`} stopColor="#0000FF" />
          <stop offset={`${(300 / 360) * 100}%`} stopColor="#FF00FF" />
          <stop offset={`${(360 / 360) * 100}%`} stopColor="#FF0000" />
        </linearGradient>
      </defs>

      <g>
        <rect
          x={1}
          y={1}
          width={size - 2}
          height={12}
          rx={6}
          fill="url(#hue_horizontal)"
          stroke="rgba(0, 0, 0, 0.2)"
          strokeWidth={0.5}
        />

        {/* @ts-ignore */}
        <g onMouseDown={startListen} transform={`translate(${circleRadius} 0)`}>
          <rect
            width={wrapperSize}
            height={14}
            fill="rgba(0, 0, 0, 0.01)"
            ref={containerRef}
          />

          <g transform={`translate(${progress * wrapperSize} 7)`}>
            <circle
              cx={0}
              cy={0}
              r={circleRadius}
              fill="black"
              fillOpacity={0.2}
            />
            <circle
              cx={0}
              cy={0}
              r={circleRadius - 2}
              fill={currentColor}
              stroke="white"
              strokeWidth={2}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
