import React from 'react'

import { IIcon } from '.'

export const Home: React.FCC<IIcon> = ({
  size = { x: 16, y: 16 },
  className = 'stroke-gray-400 fill-gray-400',
}) => (
  <svg
    className={className}
    height={size.x}
    width={size.y}
    fill="none"
    viewBox="0 0 16 17"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 6.96837C2 6.67159 2.13182 6.39015 2.35982 6.20015L7.35982 2.03349C7.73066 1.72445 8.26934 1.72445 8.64018 2.03349L13.6402 6.20015C13.8682 6.39015 14 6.6716 14 6.96837V13.5C14 14.0523 13.5523 14.5 13 14.5H3C2.44772 14.5 2 14.0523 2 13.5V6.96837Z"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.6"
    />
    <path
      d="M7 8.5C7 9.05228 7.44772 9.5 8 9.5C8.55228 9.5 9 9.05228 9 8.5C9 7.94772 8.55228 7.5 8 7.5C7.44772 7.5 7 7.94772 7 8.5Z"
      stroke="none"
    />
  </svg>
)
