import {
  AnchorPointComponent,
  Entity,
  NumberValueComponent,
  OpacityComponent,
  PositionComponent,
  RotationComponent,
  round,
  SpatialPoint2dValueComponent,
} from '@aninix-inc/model'
import { safeString } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { Property } from './common/property'
import { ScaleProperty } from './common/scale-property'
import { SkewProperty } from './common/skew-property'
import { TransformProperty } from './common/transform-property'

function safeSign(int: number): number {
  return int === 0 ? 1 : int
}

function angleInRads(angleInDegrees: number): number {
  return (angleInDegrees / 180) * Math.PI
}

export interface IProps {
  entity: Entity
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
export const LayoutAnimation: React.FCC<IProps> = observer(
  ({ entity, timeRange, startEvent, endEvent }) => {
    const anchorPoint = entity.getComponentOrThrow(AnchorPointComponent).value
    const xSign = safeSign(
      Math.sign(
        Math.cos(
          angleInRads(entity.getComponentOrThrow(RotationComponent).value)
        )
      )
    )
    const ySign = safeSign(
      Math.sign(
        Math.sin(
          angleInRads(entity.getComponentOrThrow(RotationComponent).value)
        )
      )
    )

    return (
      <>
        <TransformProperty
          applyTo={`${safeString(entity.id)}_translate`}
          attributeName="translate"
          component={entity.getComponentOrThrow(PositionComponent)}
          getKeyframeValue={(keyframe, firstKeyframe) =>
            `${round(
              xSign *
                (keyframe.getComponentOrThrow(SpatialPoint2dValueComponent)
                  .value.x -
                  firstKeyframe.getComponentOrThrow(
                    SpatialPoint2dValueComponent
                  ).value.x)
            )} ${round(
              ySign *
                (keyframe.getComponentOrThrow(SpatialPoint2dValueComponent)
                  .value.y -
                  firstKeyframe.getComponentOrThrow(
                    SpatialPoint2dValueComponent
                  ).value.y)
            )}`
          }
          timeRange={timeRange}
          startEvent={startEvent}
          endEvent={endEvent}
        />

        <TransformProperty
          attributeName="rotate"
          component={entity.getComponentOrThrow(RotationComponent)}
          getKeyframeValue={(keyframe, firstKeyframe) =>
            `${round(
              -(
                keyframe.getComponentOrThrow(NumberValueComponent).value -
                firstKeyframe.getComponentOrThrow(NumberValueComponent).value
              )
            )} ${anchorPoint.x} ${anchorPoint.y}`
          }
          timeRange={timeRange}
          startEvent={startEvent}
          endEvent={endEvent}
        />

        <ScaleProperty
          entity={entity}
          timeRange={timeRange}
          startEvent={startEvent}
          endEvent={endEvent}
        />

        <SkewProperty
          entity={entity}
          timeRange={timeRange}
          startEvent={startEvent}
          endEvent={endEvent}
        />

        <Property
          attributeName="opacity"
          component={entity.getComponentOrThrow(OpacityComponent)}
          getKeyframeValue={(keyframe) =>
            keyframe.getComponentOrThrow(NumberValueComponent).value.toString()
          }
          timeRange={timeRange}
          startEvent={startEvent}
          endEvent={endEvent}
          additive="replace"
        />
      </>
    )
  }
)

LayoutAnimation.displayName = 'Transform'
