import {
  ApiContractProvider,
  useContract,
  useCreateUserContract,
  useGetMe,
  useGetUserContracts,
} from '@aninix/api'
import { useNotifications } from '@aninix/notifications'
import * as React from 'react'

const PaymentsComponent: React.FC = () => {
  const contract = useContract()
  const notifications = useNotifications()

  React.useEffect(() => {
    if (contract.status !== 'error') {
      return
    }

    notifications.showNotification(contract.errorMessage, { variant: 'error' })
  }, [contract.status])

  if (contract.status === 'loading') {
    return <div>Loading...</div>
  }

  return (
    <div className="p-4 flex flex-col gap-4 max-w-4xl mx-auto">
      {contract.features.length > 0 ? (
        <p>Active features: {contract.features.join(', ')}</p>
      ) : (
        <p>No active features yet 👿</p>
      )}

      <div className="grid grid-cols-4 gap-4">
        {contract.availableFeaturesToPurchase.map((feature) => (
          <div key={feature.id} className="flex flex-col gap-2">
            <div>
              <p>{feature.metadata.slug}</p>
              <p>Price: {(feature.price / 100).toFixed(2)}$</p>
              <p>
                Interval:{' '}
                {feature.type === 'subscription'
                  ? feature.interval
                  : 'one time payment'}
              </p>
            </div>

            {contract.features.length === 0 ? (
              <button
                onClick={() => contract.activate(feature.id)}
                className="bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors duration-150 ease-in-out disabled:animate-pulse"
                disabled={contract.status === 'pending'}
              >
                Activate
              </button>
            ) : (
              <button
                onClick={() => contract.upgrade(feature.id)}
                className="bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors duration-150 ease-in-out disabled:animate-pulse"
                disabled={contract.status === 'pending'}
              >
                Upgrade
              </button>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

const ContractProvider: React.FC<{ userId: string }> = ({ userId }) => {
  const contracts = useGetUserContracts(userId)
  const createContract = useCreateUserContract(userId)

  if (contracts.isLoading) {
    return <p>Loading...</p>
  }

  const contract = contracts.data?.[0]

  if (contract == null) {
    return (
      <div className="p-4 flex flex-col gap-4 max-w-4xl mx-auto">
        <p>Contracts are not found for provided user</p>
        <button
          onClick={() => createContract.mutateAsync()}
          className="bg-gray-200 px-4 py-2 rounded-md hover:bg-gray-300 transition-colors duration-150 ease-in-out disabled:animate-pulse"
          disabled={createContract.isPending}
        >
          Create contract
        </button>
      </div>
    )
  }

  return (
    <ApiContractProvider contractId={contract.id}>
      <PaymentsComponent />
    </ApiContractProvider>
  )
}

export const TestPayments: React.FC = () => {
  const me = useGetMe()

  if (me.isLoading) {
    return <p>Loading...</p>
  }

  return <ContractProvider userId={me.data!.data.id} />
}
