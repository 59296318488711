import { StrokeAlign, StrokeCap } from '@aninix-inc/model/legacy'
import * as PIXI from 'pixi.js'
// @ts-ignore
import * as Tess2 from 'tess2'

export const mapStrokeAlign = (
  align: StrokeAlign,
  isClockwise: boolean = true,
  closed: boolean = true
) => {
  if (!closed) return 0.5

  switch (align) {
    case StrokeAlign.Inside:
      return isClockwise ? 0 : 1
    case StrokeAlign.Center:
      return 0.5
    case StrokeAlign.Outside:
      return isClockwise ? 1 : 0
  }
}

export const mapWindingRule = (
  windingRule: VectorPath['windingRule']
): Tess2.WINDING => {
  switch (windingRule) {
    case 'EVENODD':
      return Tess2.WINDING_ODD
    case 'NONE':
      return Tess2.NONE
    case 'NONZERO':
      return Tess2.WINDING_NONZERO
  }
}

export function mapStrokeJoin(
  strokeJoin: StrokeJoin
): PIXI.LINE_JOIN | undefined {
  switch (strokeJoin) {
    case 'BEVEL':
      return PIXI.LINE_JOIN.BEVEL
    case 'MITER':
      return PIXI.LINE_JOIN.MITER
    case 'ROUND':
      return PIXI.LINE_JOIN.ROUND
  }
}

export function mapCap(strokeCapStart: StrokeCap): PIXI.LINE_CAP {
  switch (strokeCapStart) {
    case 'NONE':
      return PIXI.LINE_CAP.BUTT
    case 'SQUARE':
      return PIXI.LINE_CAP.SQUARE
    case 'ROUND':
      return PIXI.LINE_CAP.ROUND
    default: {
      console.warn(strokeCapStart, 'is not supported')
      return PIXI.LINE_CAP.BUTT
    }
  }
}

export function mapBlendMode(blendMode: string): PIXI.BLEND_MODES {
  switch (blendMode) {
    case 'darken':
      return PIXI.BLEND_MODES.DARKEN
    case 'multiply':
      return PIXI.BLEND_MODES.MULTIPLY
    case 'color_burn':
      return PIXI.BLEND_MODES.COLOR_BURN
    case 'lighten':
      return PIXI.BLEND_MODES.LIGHTEN
    case 'screen':
      return PIXI.BLEND_MODES.SCREEN
    case 'color_dodge':
      return PIXI.BLEND_MODES.COLOR_DODGE
    case 'overlay':
      return PIXI.BLEND_MODES.OVERLAY
    case 'soft_light':
      return PIXI.BLEND_MODES.SOFT_LIGHT
    case 'hard_light':
      return PIXI.BLEND_MODES.HARD_LIGHT
    // case 'difference':
    //   return PIXI.BLEND_MODES.DIFFERENCE
    // case 'exclusion':
    //   return PIXI.BLEND_MODES.EXCLUSION
    // case 'hue':
    //   return PIXI.BLEND_MODES.HUE
    // case 'saturation':
    //   return PIXI.BLEND_MODES.SATURATION
    // case 'color':
    // return PIXI.BLEND_MODES.COLOR
    // case 'luminosity':
    //   return PIXI.BLEND_MODES.LUMINOSITY
    default:
      return PIXI.BLEND_MODES.NORMAL
  }
}

export * as Mappers from '.'
