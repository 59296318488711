import * as msgpack from '@msgpack/msgpack'
import { compress } from 'brotli-compress'

export async function jsonToBinary(json: unknown): Promise<Uint8Array> {
  // Encode the JSON object as MessagePack
  const msgpackData = msgpack.encode(json)

  // Compress the MessagePack data using brotli
  const compressedData = compress(msgpackData, { quality: 5 })

  return compressedData
}
