import classNames from 'classnames'
import * as React from 'react'

import { Typography } from '../design-system/typography'

export interface IProps {
  label: string
  defaultValue: string
  isValid?: boolean
  validationMessage?: string
  initialValueMessage?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
}

export const TextInputField: React.FCC<IProps> = ({
  label,
  defaultValue,
  isValid = true,
  validationMessage,
  initialValueMessage,
  onChange,
  placeholder,
}) => (
  <div className="flex flex-col gap-1">
    <p className="font-body text-sm text-gray-500">{label}</p>
    <div className="flex flex-col gap-0">
      <div className="flex w-80 items-center gap-4 rounded-lg border-[1px] border-gray-200 bg-white px-3 py-1 focus-within:ring-1">
        <input
          placeholder={placeholder}
          autoFocus={false}
          type={'text'}
          spellCheck={false}
          className="rows w-full min-w-[90px] select-none resize-none font-body text-base text-secondary outline-none"
          defaultValue={defaultValue}
          onChange={onChange}
        />
      </div>
      <Typography
        style="Subscript"
        text={validationMessage}
        className={classNames('text-red transition-all duration-200', {
          ['invisible opacity-0']: isValid,
          ['h-0']: initialValueMessage,
        })}
      />
      <Typography
        style="Subscript"
        text={initialValueMessage}
        className={classNames('text-gray-400 transition-all duration-200', {
          ['invisible opacity-0']: !initialValueMessage,
        })}
      />
    </div>
  </div>
)

TextInputField.displayName = 'TextInputField'
