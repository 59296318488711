import { Popover } from '@material-ui/core'
import * as React from 'react'

import { ArchivingView } from './archiving'
import { IRenderInteractor } from './interactor'
import * as styles from './router.scss'
import { SuccessView } from './success'
import { RenderView } from './view'

export interface IProps {
  interactor: IRenderInteractor
}
export const RenderRouter: React.FCC<IProps> = ({ interactor }) => {
  if (interactor.isViewVisible === false) {
    return null
  }

  return (
    <Popover
      open
      onClose={interactor.onClose}
      anchorEl={interactor.anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      disableRestoreFocus
      className={styles['popover-container']}
      classes={{
        paper: styles['popover-content'],
      }}
    >
      <div className={styles.container}>
        <svg
          width="14"
          height="7"
          viewBox="0 0 14 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.arrow}
        >
          <path d="M4.73726 2.26274L0 7L14 7L9.26274 2.26274C8.47071 1.47071 8.07469 1.07469 7.61803 0.926314C7.21635 0.795798 6.78365 0.795798 6.38197 0.926314C5.92531 1.07469 5.52929 1.47071 4.73726 2.26274Z" />
        </svg>

        <div className={styles.content}>
          {interactor.isRenderFinished && (
            <SuccessView onSuccessClick={interactor.startOver} />
          )}

          {interactor.isArchiving && <ArchivingView />}

          {interactor.isRenderFinished === false &&
            interactor.isArchiving === false && (
              <RenderView
                exportPresets={interactor.exportPresets}
                activePresetIdx={interactor.activePresetIdx}
                onUpdatePreset={interactor.updatePreset}
                onClose={interactor.onClose}
                onExportStart={interactor.startRender}
                onCancel={interactor.cancelRender}
                onAddPreset={interactor.createPreset}
                onRemovePreset={interactor.deletePreset}
                progress={interactor.renderProgress}
                isRendering={interactor.isRendering}
                warningMessages={interactor.warningMessages}
                errorMessage={interactor.errorMessage}
                statusMessage={interactor.statusMessage}
                includeOnlySelectedRangeAllowed={
                  interactor.includeOnlySelectedRangeAllowed
                }
                includeOnlySelectedRange={interactor.includeOnlySelectedRange}
                toggleIncludeOnlySelectedRange={
                  interactor.toggleIncludeOnlySelectedRange
                }
              />
            )}
        </div>
      </div>
    </Popover>
  )
}
