import { StrokeAlign, StrokeCap } from '@aninix-inc/model/legacy'
import { paper } from '@aninix-inc/renderer'

type Matrix = [[number, number, number], [number, number, number]]

export type ArrowHeadData = {
  /**
   * Svg path
   */
  path: string

  /**
   * Transform matrix
   */
  matrix: Matrix
}

type GetHead = (payload: {
  strokeAlign: StrokeAlign
  size: number
  matrix: paper.Matrix
}) => ArrowHeadData

function mapPaperMatrixToModel(matrix: paper.Matrix): Matrix {
  const v = matrix.values

  return [
    [v[0], v[1], v[4]],
    [v[2], v[3], v[5]],
  ]
}

const getRoundHead: GetHead = (payload) => {
  return {
    path: new paper.Path.Circle(new paper.Point(0, 0), payload.size / 2)
      .pathData,
    matrix: mapPaperMatrixToModel(payload.matrix),
  }
}

const getSquareHead: GetHead = (payload) => {
  const initialMatrix = (() => {
    const m = new paper.Matrix()
    m.translate(-1, -payload.size / 2)
    return m
  })()

  return {
    path: new paper.Path.Rectangle(
      new paper.Point(0, 0),
      new paper.Size(payload.size, payload.size)
    ).pathData,
    matrix: mapPaperMatrixToModel(payload.matrix.append(initialMatrix)),
  }
}

const getLineArrowHead: GetHead = (payload) => {
  const initialMatrix = (() => {
    const m = new paper.Matrix()
    m.translate(-3.5, -5)
    m.scale(payload.size, new paper.Point(3.5, 5))
    return m
  })()

  return {
    path: 'M3.3095 4.5L0.3255 1.457C0.1315 1.26 0.1355 0.942998 0.3315 0.749998C0.5295 0.556998 0.8455 0.559998 1.0385 0.756998L4.8575 4.65C5.0475 4.844 5.0475 5.156 4.8575 5.35L1.0385 9.243C0.8455 9.44 0.5285 9.443 0.3315 9.25C0.1345 9.057 0.1315 8.74 0.3255 8.543L3.3095 5.5V4.5Z',
    matrix: mapPaperMatrixToModel(payload.matrix.appended(initialMatrix)),
  }
}

const getTriangleArrowHead: GetHead = (payload) => {
  const initialMatrix = (() => {
    const m = new paper.Matrix()
    m.translate(-3.5, -5)
    m.scale(payload.size / 2, new paper.Point(3.5, 5))
    return m
  })()

  return {
    path: 'M8 5L0.5 9.33013L0.5 0.669873L8 5Z',
    matrix: mapPaperMatrixToModel(payload.matrix.appended(initialMatrix)),
  }
}

const getTriangleFilledHead: GetHead = (payload) => {
  const initialMatrix = (() => {
    const m = new paper.Matrix()
    m.translate(-3.5, -5)
    m.scale(payload.size / 2, new paper.Point(3.5, 5))
    return m
  })()

  return {
    path: 'M-5.96244e-08 5L7.5 9.33013L7.5 0.669873L-5.96244e-08 5Z',
    matrix: mapPaperMatrixToModel(payload.matrix.appended(initialMatrix)),
  }
}

const getCircleFilledHead: GetHead = (payload) => {
  const strokeWeight = payload.size * 3.5
  const radius =
    payload.strokeAlign === StrokeAlign.Center ? strokeWeight / 2 : strokeWeight

  return {
    path: new paper.Path.Circle(new paper.Point(0, 0), radius).pathData,
    matrix: mapPaperMatrixToModel(payload.matrix),
  }
}

const getDiamondFilledHead: GetHead = (payload) => {
  const initialMatrix = (() => {
    const m = new paper.Matrix()
    m.translate(-payload.size / 2, -payload.size / 2)
    m.rotate(45, new paper.Point(payload.size / 2, payload.size / 2))
    m.scale(3.5, new paper.Point(payload.size / 2, payload.size / 2))
    return m
  })()

  return {
    path: new paper.Path.Rectangle(
      new paper.Point(0, 0),
      new paper.Size(payload.size, payload.size)
    ).pathData,
    matrix: mapPaperMatrixToModel(payload.matrix.appended(initialMatrix)),
  }
}

function mapStrokeCapToGetHead(strokeCap: StrokeCap): GetHead | null {
  switch (strokeCap) {
    case StrokeCap.Round:
      return getRoundHead
    case StrokeCap.Square:
      return getSquareHead
    case StrokeCap.ArrowLines:
      return getLineArrowHead
    case StrokeCap.ArrowEquilateral:
      return getTriangleArrowHead
    case StrokeCap.TriangleFilled:
      return getTriangleFilledHead
    case StrokeCap.CircleFilled:
      return getCircleFilledHead
    case StrokeCap.DiamondFilled:
      return getDiamondFilledHead
    default:
      return null
  }
}

/**
 * Return abstract arrow heads data for provided path with settings.
 * @param payload.path is svg path. Can be received from current shape by a given time.
 * @param payload.size is related to strokeWeight now but can be adjustable later by custom property.
 */
export function getArrowHeadsData(
  payload: {
    path: string
    strokeAlign: StrokeAlign
    strokeCapStart: StrokeCap
    strokeCapEnd: StrokeCap
    size: number
  },
  options?: {
    /**
     * Enable when you need only heads without any additional transformations along the path
     */
    ignorePathTransform?: boolean
  }
): ArrowHeadData[] {
  const ignorePathTransform = options?.ignorePathTransform ?? false
  const parsedPath = new paper.CompoundPath(payload.path)
  return parsedPath.children.flatMap((providedPath): any => {
    let heads: ArrowHeadData[] = []

    const p = providedPath as paper.Path
    const isClosed = p.closed

    if (isClosed) {
      return heads
    }

    if (p.curves.length === 0) {
      return heads
    }

    const startHeadGetter = mapStrokeCapToGetHead(payload.strokeCapStart)
    if (startHeadGetter != null) {
      const startMatrix = (() => {
        const curve = p.firstCurve
        const matrix = new paper.Matrix()

        if (curve == null) {
          if (!ignorePathTransform) {
            const position = p.firstSegment.point
            matrix.translate(position.x, position.y)
          }

          return matrix
        }

        if (!ignorePathTransform) {
          const position = curve.point1
          const tangent = curve.getTangentAt(0)
          matrix.translate(position.x, position.y)
          matrix.rotate(tangent.angle + 180, new paper.Point(0, 0))
        }

        return matrix
      })()

      heads.push(
        startHeadGetter({
          strokeAlign: payload.strokeAlign,
          size: payload.size,
          matrix: startMatrix,
        })
      )
    }

    const endHeadGetter = mapStrokeCapToGetHead(payload.strokeCapEnd)
    if (endHeadGetter != null) {
      const endMatrix = (() => {
        const curve = p.lastCurve
        const matrix = new paper.Matrix()

        if (curve == null) {
          if (!ignorePathTransform) {
            const position = p.lastSegment.point
            matrix.translate(position.x, position.y)
          }

          return matrix
        }

        if (!ignorePathTransform) {
          const tangent = curve.getTangentAt(curve.length)
          const position = curve.point2
          matrix.translate(position.x, position.y)
          matrix.rotate(tangent.angle, new paper.Point(0, 0))
        }

        return matrix
      })()

      heads.push(
        endHeadGetter({
          strokeAlign: payload.strokeAlign,
          size: payload.size,
          matrix: endMatrix,
        })
      )
    }

    return heads
  })
}
