import {
  ChildrenExpandedComponent,
  ChildrenRelationsAspect,
  Entity,
  EntityType,
  EntryComponent,
  getOrdered,
  hideProperties,
  moveNodes,
  ParentRelationAspect,
  Project,
  PropertiesExpandedComponent,
  SelectionSystem,
  showProperties,
} from '@aninix-inc/model'
import { NESTING_RAISING_THRESHOLD_PRECENT } from './config'
import { DropZoneType } from './layer-dnd-context'

export const isEntityDraggable = (entity?: Entity) =>
  !entity?.hasComponent(EntryComponent)

export const shouldRiseNesting = (e: React.MouseEvent) => {
  const rect = e.currentTarget.getBoundingClientRect()
  const shouldAppendChildThreshold =
    rect.width * (NESTING_RAISING_THRESHOLD_PRECENT / 100)
  const localX = e.clientX - rect.left
  return localX <= shouldAppendChildThreshold
}

export const canEntityContainChildren = (entity: Entity) => {
  return entity.hasAspect(ChildrenRelationsAspect)
}

export const isEntityLastChild = (entity: Entity) => {
  const parent = entity.getAspect(ParentRelationAspect)?.getParentEntity()
  const lastChild = parent
    ?.getAspectOrThrow(ChildrenRelationsAspect)
    .getChildAt(0)
  return lastChild ? lastChild.id === entity.id : false
}

export const isEntityChildrenExpanded = (entity: Entity) => {
  const childrenExpanded =
    entity.hasComponent(ChildrenExpandedComponent) &&
    entity.getComponentOrThrow(ChildrenExpandedComponent).value
  return childrenExpanded
}

export const getDropZoneType = (
  e: React.MouseEvent,
  entity: Entity
): DropZoneType => {
  if (canEntityContainChildren(entity)) {
    if (isEntityChildrenExpanded(entity)) return 'child'

    return shouldRiseNesting(e) ? 'sibling' : 'child'
  }

  const isParentOfEntityRoot = entity
    .getAspect(ParentRelationAspect)
    ?.getParentEntity()
    ?.hasComponent(EntryComponent)

  if (isEntityLastChild(entity) && !isParentOfEntityRoot)
    return shouldRiseNesting(e) ? 'siblingOfParent' : 'sibling'

  return 'sibling'
}

export const getDropZoneParent = (
  dropTargetEntity: Entity,
  dropZoneType: DropZoneType
) => {
  if (dropZoneType === 'child' && canEntityContainChildren(dropTargetEntity)) {
    return dropTargetEntity
  }

  const parent = dropTargetEntity
    .getAspect(ParentRelationAspect)
    ?.getParentEntity()
  return parent ?? null
}

export const getIndent = (
  dropZoneType: DropZoneType | null,
  indent: number
) => {
  let newIndent = indent

  if (dropZoneType === 'child') newIndent += 1
  if (dropZoneType === 'siblingOfParent') newIndent -= 1

  return newIndent
}

export type DropHandler = (entities: Entity[], dropTarget: Entity) => void

export const dropAsChild: DropHandler = (entities, dropTarget) => {
  const filteredEntities = entities.filter(
    (entity) => entity.id !== dropTarget.id
  )
  moveNodes(filteredEntities, dropTarget)
}

export const dropAsSibling: DropHandler = (entities, dropTarget) => {
  const dropTargetParent = dropTarget
    .getAspectOrThrow(ParentRelationAspect)
    .getParentEntityOrThrow()

  moveNodes(entities, dropTargetParent, { before: dropTarget })
}

export const dropAsSiblingOfParent: DropHandler = (entities, dropTarget) => {
  const dropTargetParent = dropTarget
    .getAspectOrThrow(ParentRelationAspect)
    .getParentEntityOrThrow()
  const parentOfDropTargetParent = dropTargetParent
    .getAspect(ParentRelationAspect)
    ?.getParentEntity()

  if (!parentOfDropTargetParent) return

  moveNodes(entities, parentOfDropTargetParent, { before: dropTargetParent })
}

export const hideAllProperties = (project: Project) => {
  const hidden = new Set<Entity>()

  for (const entity of project.entities) {
    if (!entity.getComponent(PropertiesExpandedComponent)?.value) continue

    hideProperties(entity)
    hidden.add(entity)
  }

  return hidden
}

export const showHiddenProperties = (hidden: Set<Entity>) => {
  hidden.forEach(showProperties)
  hidden.clear()
}

export const getDraggableEntities = (
  draggable: Entity,
  selection: SelectionSystem
) => {
  const selected = selection.getEntitiesByEntityType(EntityType.Node)
  if (selected.length && selection.selection().includes(draggable.id)) {
    const entities = selected.filter(isEntityDraggable) as Entity[]
    const orderedEntites = getOrdered(entities)
    return orderedEntites
  }

  if (!isEntityDraggable(draggable)) return []

  selection.deselectAll()
  selection.select([draggable.id])
  return [draggable]
}
