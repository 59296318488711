import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const StrokeLeftWeight: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5 12H4V11H10.5C10.776 11 11 10.776 11 10.5V1.5C11 1.224 10.776 1 10.5 1H4V0H10.5C11.328 0 12 0.672 12 1.5V10.5C12 11.328 11.328 12 10.5 12Z"
      fill="var(--figma-color-text, rgb(0, 0, 0))"
      fillOpacity="0.3"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 12V0H1V12H0Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 1))"
    />
  </svg>
)
