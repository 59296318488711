import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const PolygonPointCount: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M13.3333 6.66667H16V9.33333H13.3333V6.66667ZM0 6.66667H2.66667V9.33333H0V6.66667ZM9.33333 13.3333V16H6.66667V13.3333H9.33333ZM9.33333 0V2.66667H6.66667V0H9.33333Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
