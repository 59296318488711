export enum EditorEvent {
  EditorCrash = 'editor_crash',
}

type EditorEventListener = (type: EditorEvent, data?: unknown) => void

class EditorEvents {
  private listeners = new Set<EditorEventListener>()

  emit(type: EditorEvent, data?: unknown): void {
    this.listeners.forEach((listener) => listener(type, data))
  }

  subscribe(listener: EditorEventListener): () => void {
    this.listeners.add(listener)
    return () => {
      this.listeners.delete(listener)
    }
  }
}

export const editorEvents = new EditorEvents()
