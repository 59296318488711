import * as React from 'react'

export interface IProps {
  placeholder: string
  defaultSearchValue: string
  onChange: (value: string) => void
  autoFocus: boolean
}
export const SearchBar: React.FCC<IProps> = ({
  placeholder,
  onChange,
  defaultSearchValue,
  autoFocus,
}) => (
  <input
    autoFocus={autoFocus}
    defaultValue={defaultSearchValue}
    placeholder={placeholder}
    onChange={(e) => onChange(e.currentTarget.value)}
    className="w-full p-2 font-normal text-[12px]/[14px] border-b border-gray-200 placeholder:text-gray-400"
  />
)

SearchBar.displayName = 'SearchBar'
