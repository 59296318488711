import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Comment: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.927 19.5266L10.6897 19.0341C10.248 18.1174 10 17.0891 10 16C10 12.134 13.134 9 17 9C20.866 9 24 12.134 24 16C24 19.866 20.866 23 17 23C15.8953 23 14.853 22.7448 13.9263 22.2911L13.6584 22.1599L13.3625 22.1969L8.94388 22.7492L10.6405 19.9923L10.927 19.5266ZM7.67186 22.9082L7 24L8.27203 23.841L13.4865 23.1892C14.5471 23.7085 15.7395 24 17 24C21.4183 24 25 20.4183 25 16C25 11.5817 21.4183 8 17 8C12.5817 8 9 11.5817 9 16C9 17.2426 9.28329 18.419 9.78881 19.4682L7.67186 22.9082Z"
      fill="white"
    />
  </svg>
)
