import classNames from 'classnames'
import * as React from 'react'
import { Menu as MenuV1 } from './old-menu'

export interface IProps {}
export const Menu: React.FCC<IProps> = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false)

  const handleMenuClick = React.useCallback(() => {
    setIsMenuOpen((state) => !state)
  }, [])
  const handleMenuClose = React.useCallback(() => {
    setIsMenuOpen(false)
  }, [])

  const menuButtonRef = React.useRef(null)

  return (
    <>
      <button
        ref={menuButtonRef}
        onClick={handleMenuClick}
        className="group relative flex h-8 w-8 cursor-default items-center justify-center"
      >
        <div
          className={classNames(
            'absolute inset-0 z-0 rounded-lg bg-white opacity-0 transition-opacity duration-150 group-hover:!opacity-10',
            {}
          )}
        />
        <div
          className={classNames(
            'absolute inset-0 z-0 rounded-lg bg-accent transition-opacity duration-100 ',
            {
              ['opacity-100']: isMenuOpen,
              ['opacity-0']: !isMenuOpen,
            }
          )}
        />
        <MenuIcon
          className={classNames('pointer-events-auto z-10 h-6 w-6', {
            ['opacity-50']: !isMenuOpen,
          })}
        />
      </button>

      {/* this is popover */}
      <MenuV1
        handleMenuClose={handleMenuClose}
        isMenuOpen={isMenuOpen}
        anchorEl={menuButtonRef.current}
        dashboardUrl="/"
      />
    </>
  )
}

Menu.displayName = 'Menu'

const MenuIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 6H19"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 12H19"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5 18H19"
      stroke="white"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
