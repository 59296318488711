import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Normal: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0L2.17 2.925C1.39 3.733 1 4.81 1 5.845C1 6.881 1.39 7.974 2.17 8.781C2.95 9.589 3.975 9.998 5 9.998C6.025 9.998 7.05 9.589 7.83 8.781C8.61 7.974 9 6.881 9 5.846C9 4.81 8.61 3.733 7.83 2.926L5 0ZM5 1.438L7.11 3.62C7.7 4.23 8 5.052 8 5.846C8 6.643 7.696 7.48 7.11 8.086C6.521 8.696 5.757 8.998 5 8.998C4.243 8.998 3.479 8.697 2.89 8.087C2.303 7.48 2 6.643 2 5.846C2 5.052 2.301 4.229 2.89 3.62C2.889 3.62 2.89 3.62 2.89 3.62L5 1.438Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
