import * as React from 'react'

import { useSearchParams } from 'react-router-dom'
import { Typography } from '../../components/design-system/typography'
import { useSessionStore } from '../../stores'
import { IUpgradeToProUseCase } from '../../use-cases'
import { AninixProIcon } from './aninix-pro-icon'
import { List } from './list'
import { PaymentStatus } from './payment-status'

export interface IProps {}

export interface IPaymentInfo {
  status: IUpgradeToProUseCase['state']
  onCancel: () => void
  link: string | undefined
  userId: string
  teamId?: string | null
}

export const Upgrade: React.FCC<IProps> = () => {
  const sessionStore = useSessionStore()

  const [searchParams] = useSearchParams()

  const status = searchParams.get('status') as IPaymentInfo['status'] | null

  const teamId = searchParams.get('teamId') as string | null

  const [paymentInfo, setPaymentInfo] = React.useState<IPaymentInfo>({
    status: status ?? 'not-started',
    onCancel: () => {},
    link: undefined,
    userId: sessionStore.userId,
    teamId: teamId ?? undefined,
  })

  const updatePaymentInfo = React.useCallback((args: Partial<IPaymentInfo>) => {
    setPaymentInfo((oldInfo) => {
      return { ...oldInfo, ...args }
    })
  }, [])

  return (
    <>
      <PaymentStatus paymentInfo={paymentInfo} />
      <div className="h-screen w-screen overflow-auto">
        <div className="flex min-h-screen w-full flex-col items-center gap-6 pb-16 pt-[20vh]">
          <>
            <div className="flex flex-col items-center gap-4">
              <AninixProIcon />
              <div className="flex flex-col items-center gap-0">
                <Typography
                  style="Heading4"
                  text={'Choose an account or team'}
                />
                <Typography
                  style="Body3Regular"
                  text={'to upgrade to Aninix Pro'}
                />
              </div>
            </div>

            <List urlStatus={status} updatePaymentInfo={updatePaymentInfo} />
          </>
        </div>
      </div>
    </>
  )
}

Upgrade.displayName = 'Upgrade'
