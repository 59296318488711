import classNames from 'classnames'
import * as React from 'react'

import { toast } from '../../modules/toasts'
import { Icons, IIcon } from '../icons'
import { ContextOption } from './folder-item-context-option'

interface IProps {
  handleClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isShown: boolean
  handleModal: React.Dispatch<React.SetStateAction<Modal>>
  handleDelete: () => void
  folderLink: string
  canEditFolder?: boolean
}

type Modal = 'none' | 'rename' | 'moveto' | 'delete'

export interface IFolderOption {
  Icon: React.FCC<IIcon>
  label: string
  openModal?: React.MouseEventHandler<HTMLDivElement>
}

export const FolderContextMenu: React.FCC<IProps> = ({
  folderLink,
  handleClose,
  isShown,
  handleModal,
  handleDelete,
  canEditFolder,
}) => {
  const folderOptions: IFolderOption[] = React.useMemo(
    () => [
      {
        Icon: Icons.GetLink,
        label: 'Get link',
        openModal: (e) => {
          navigator.clipboard.writeText(folderLink)
          toast('🔗 Link copied, paste it to any browser', {
            variant: 'info',
          })
        },
      },
      ...(canEditFolder
        ? [
            {
              Icon: Icons.Rename,
              label: 'Rename',
              openModal: () => handleModal('rename'),
            },
            {
              Icon: Icons.MoveTo,
              label: 'Move to...',
              openModal: () => handleModal('moveto'),
            },
            {
              Icon: Icons.Delete,
              label: 'Delete',
              openModal: () => handleModal('delete'),
            },
          ]
        : []),
    ],
    [handleModal, canEditFolder]
  )

  return (
    <div
      onMouseLeave={handleClose}
      className={classNames(
        'absolute -right-0 top-[calc(100%+2px)] flex w-40 flex-col overflow-clip rounded-md bg-secondary transition-all  duration-300',
        {
          ['invisible opacity-0']: isShown === false,
        }
      )}
    >
      {folderOptions.map((i) => (
        <ContextOption {...i} key={i.label} />
      ))}
    </div>
  )
}

FolderContextMenu.displayName = 'FolderContextMenu'
