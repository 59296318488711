import classnames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

export interface IProps {
  variant?: 'normal' | 'dimmed'
  className?: string
}
export const ProBadge: React.FCC<IProps> = ({
  variant = 'normal',
  children = 'PRO',
  className,
}) => (
  <p
    className={classnames(styles.container, className, {
      [styles['container--dimmed']]: variant === 'dimmed',
    })}
  >
    {children}
  </p>
)
