import {
  useGetTeam,
  useRemoveUserFromTeam,
  useResendInvite,
  useRestoreUserInTeam,
} from '@aninix/api'
import { toast } from 'apps/web-app/src/modules/toasts'
import classNames from 'classnames'
import * as React from 'react'

import { halfScreenCheck, useCurrentUser } from '../../../use-cases'
import { IIcon, Icons } from '../../icons'
import { useCurrentRowContext } from '../use-current-row-context'
import { ContextOption } from './context-option'

interface IProps {
  handleClose: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  isShown: boolean
}

export interface IRowOptions {
  Icon: React.FCC<IIcon>
  label: string
  onClick?: () => void
}

export const MemberRowContextMenu: React.FCC<IProps> = ({ isShown }) => {
  const { teamId } = useCurrentUser()

  const removeUserFromTeam = useRemoveUserFromTeam()
  const restoreUserInTeam = useRestoreUserInTeam()
  const resendInviteSource = useResendInvite()
  const getTeam = useGetTeam({ teamId: teamId! })

  const { id, email, name } = useCurrentRowContext()

  const { status } = useCurrentRowContext()

  const suspendUser = React.useCallback(() => {
    if (id == null || teamId == null) {
      return
    }

    removeUserFromTeam
      .mutateAsync({ userId: id, teamId })
      .then(() => {
        toast(`User ${name ?? email} is suspended`, { variant: 'success' })
        getTeam.refetch()
      })
      .catch(() => {
        toast("Couldn't suspend user", { variant: 'error' })
        getTeam.refetch()
      })
  }, [])
  const restoreUser = React.useCallback(() => {
    if (id == null || teamId == null) {
      return
    }

    restoreUserInTeam
      .mutateAsync({ userId: id, teamId })
      .then(() => {
        toast(`User ${name ?? email} is restored`, { variant: 'success' })
        getTeam.refetch()
      })
      .catch(() => {
        toast("Couldn't restore user", { variant: 'error' })
        getTeam.refetch()
      })
  }, [])
  const resendInvite = React.useCallback(() => {
    if (id == null || teamId == null) {
      return
    }

    resendInviteSource
      .mutateAsync({ userId: id, teamId })
      .then(() => {
        toast('Invite successfully sent', { variant: 'success' })
      })
      .catch(() => {
        toast('Sending failed. Please try again', { variant: 'error' })
      })
  }, [id, teamId])

  const options: IRowOptions[] = [
    ...(status === 'invite_pending'
      ? [
          {
            Icon: Icons.Mail,
            label: 'Re-send invite',
            onClick: resendInvite,
          },
        ]
      : []), //that is conditional element https://stackoverflow.com/questions/44908159/how-to-define-an-array-with-conditional-elements

    status !== 'suspended'
      ? {
          Icon: Icons.Pause,
          label: 'Suspend',
          onClick: suspendUser,
        }
      : {
          Icon: Icons.Plus,
          label: 'Restore',
          onClick: restoreUser,
        },
  ]

  const ref = React.useRef<HTMLDivElement>(null)

  const { isTopHalf } = halfScreenCheck(ref)

  return (
    <div
      ref={ref}
      // onMouseLeave={handleClose}
      className={classNames(
        'absolute -right-0 flex w-40 flex-col overflow-clip rounded-md bg-secondary transition-all  duration-300',
        {
          ['invisible opacity-0']: isShown === false,
          ['top-full mt-[2px]']: isTopHalf,
          ['bottom-full mb-[2px]']: !isTopHalf,
        }
      )}
    >
      {options.map((i) => (
        <ContextOption {...i} key={i.label} />
      ))}
    </div>
  )
}

MemberRowContextMenu.displayName = 'MemberRowContextMenu'
