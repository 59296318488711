import {
  Entity,
  NumberKeyframe,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
  disableTrimPath,
  enableTrimPath,
  getAnimatableValue,
  getValueNumber,
  isPathReversed,
  isTrimPathEnabled,
  reversePath,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { Group } from '../../common/group'
import { formatPercents } from '../../keyframes/value'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 16,
  y: 16,
}

const btnSize = {
  width: 32,
  height: 32,
}

export interface IProps {
  nodes: Entity[]
}
export const TrimPath: React.FCC<IProps> = ({ nodes }) => {
  useEntities(nodes)

  const enable = React.useCallback(() => nodes.forEach(enableTrimPath), [nodes])
  const disable = React.useCallback(
    () => nodes.forEach(disableTrimPath),
    [nodes]
  )

  const toggleReversePath = React.useCallback(
    () => nodes.forEach(reversePath),
    [nodes]
  )

  if (!nodes.length) return null

  const isReversed = R.any(isPathReversed, nodes)
  const enabled = R.any(isTrimPathEnabled, nodes)

  return (
    <Group
      title="Trim Path"
      isOpen={enabled}
      onClick={() => nodes.forEach(enableTrimPath)}
      headerButtons={
        <>
          {enabled && (
            <buttons.Icon
              btnSize={btnSize}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                toggleReversePath()
              }}
              tooltip="Reverse path"
            >
              {isReversed ? (
                <icons.propertiesPanel.ReversePath
                  type="reversed"
                  size={iconSize}
                />
              ) : (
                <icons.propertiesPanel.ReversePath
                  type="normal"
                  size={iconSize}
                />
              )}
            </buttons.Icon>
          )}

          {enabled ? (
            <buttons.Icon
              btnSize={btnSize}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                disable()
              }}
              tooltip="Disable trim path"
            >
              <icons.Remove />
            </buttons.Icon>
          ) : (
            <buttons.Icon
              btnSize={btnSize}
              onClick={(e) => {
                e.stopPropagation()
                e.preventDefault()
                enable()
              }}
              tooltip="Enable trim path"
            >
              <icons.Add />
            </buttons.Icon>
          )}
        </>
      }
    >
      <Start />
      <End />
      <Offset />
    </Group>
  )
}

TrimPath.displayName = 'TrimPath'

const Start: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()
  const components = nodes.flatMap((l) =>
    l.hasComponent(TrimStartComponent)
      ? l.getComponentOrThrow(TrimStartComponent)
      : []
  )
  const keyframesType = getKeyframesType(components, time)
  // @TODO replace with snapshot data
  const starts = components.map((c) => getAnimatableValue(c, time))

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <StartEditable components={components} time={time} />
      ) : (
        <StartDisplay
          components={components}
          time={time}
          starts={starts}
          keyframesType={keyframesType}
        />
      )}
    </div>
  )
}

Start.displayName = 'Start'

const StartEditable: React.FC<{
  time: number
  components: TrimStartComponent[]
}> = ({ time, components }) => (
  <div className="flex w-full flex-row justify-between">
    <NumberValue
      components={components}
      time={time}
      icon={<span className="w-full pl-[4px]">Start</span>}
      min={0}
      max={100}
      before={(value) => value * 100}
      after={(value) => value / 100}
      format={formatPercents}
      iconWide
      iconWidth={96}
      width={192}
    />

    <KeyframesPropertyControl
      components={components}
      time={time}
      KeyframeConstructor={NumberKeyframe}
      valueGetter={getValueNumber}
    />
  </div>
)

StartEditable.displayName = 'StartEditable'

const StartDisplay: React.FC<{
  time: number
  components: TrimStartComponent[]
  starts: number[]
  keyframesType: PropertyKeyframesType
}> = React.memo(
  ({ time, components }) => {
    return (
      <div className="flex w-full flex-row justify-between">
        <NumberValue
          components={components}
          time={time}
          icon={<span className="w-full pl-[4px]">Start</span>}
          min={0}
          max={100}
          before={(value) => value * 100}
          after={(value) => value / 100}
          format={formatPercents}
          iconWide
          iconWidth={96}
          width={192}
        />

        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      </div>
    )
  },
  (prev, next) => {
    if (prev.starts.length !== next.starts.length) return false
    for (let i = 0; i < prev.starts.length; i++) {
      if (prev.starts[i] !== next.starts[i]) return false
    }
    if (prev.keyframesType !== next.keyframesType) return false
    return true
  }
)

StartDisplay.displayName = 'StartDisplay'

const End: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()

  const components = nodes.flatMap((n) =>
    n.hasComponent(TrimEndComponent)
      ? n.getComponentOrThrow(TrimEndComponent)
      : []
  )

  const ends = components.map((c) => getAnimatableValue(c, time))
  const keyframesType = getKeyframesType(components, time)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <EndEditable components={components} time={time} />
      ) : (
        <EndDisplay
          components={components}
          time={time}
          ends={ends}
          keyframesType={keyframesType}
        />
      )}
    </div>
  )
}

End.displayName = 'End'

const EndEditable: React.FC<{
  time: number
  components: TrimEndComponent[]
}> = ({ time, components }) => (
  <div className="flex w-full flex-row justify-between">
    <NumberValue
      components={components}
      time={time}
      icon={<span className="w-full pl-[4px]">End</span>}
      min={0}
      max={100}
      before={(value) => value * 100}
      after={(value) => value / 100}
      format={formatPercents}
      iconWide
      iconWidth={96}
      width={192}
    />

    <KeyframesPropertyControl
      components={components}
      time={time}
      KeyframeConstructor={NumberKeyframe}
      valueGetter={getValueNumber}
    />
  </div>
)

EndEditable.displayName = 'EndEditable'

const EndDisplay: React.FC<{
  time: number
  components: TrimEndComponent[]
  ends: number[]
  keyframesType: PropertyKeyframesType
}> = React.memo(
  ({ time, components }) => (
    <div className="flex w-full flex-row justify-between">
      <NumberValue
        components={components}
        time={time}
        icon={<span className="w-full pl-[4px]">End</span>}
        min={0}
        max={100}
        before={(value) => value * 100}
        after={(value) => value / 100}
        format={formatPercents}
        iconWide
        iconWidth={96}
        width={192}
      />

      <KeyframesPropertyControl
        components={components}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  ),
  (prev, next) => {
    if (prev.ends.length !== next.ends.length) return false
    for (let i = 0; i < prev.ends.length; i++) {
      if (prev.ends[i] !== next.ends[i]) return false
    }
    if (prev.keyframesType !== next.keyframesType) return false
    return true
  }
)

EndDisplay.displayName = 'EndDisplay'

const Offset: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()

  const components = nodes.flatMap((n) =>
    n.hasComponent(TrimOffsetComponent)
      ? n.getComponentOrThrow(TrimOffsetComponent)
      : []
  )

  const offsets = components.map((c) => getAnimatableValue(c, time))
  const keyframesType = getKeyframesType(components, time)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <OffsetEditable components={components} time={time} />
      ) : (
        <OffsetDisplay
          components={components}
          time={time}
          offsets={offsets}
          keyframesType={keyframesType}
        />
      )}
    </div>
  )
}

Offset.displayName = 'Offset'

const OffsetEditable: React.FC<{
  time: number
  components: TrimOffsetComponent[]
}> = ({ time, components }) => (
  <div className="flex w-full flex-row justify-between">
    <NumberValue
      components={components}
      time={time}
      icon={<span className="w-full pl-[4px]">Offset</span>}
      min={-100}
      max={100}
      before={(value) => value * 100}
      after={(value) => value / 100}
      format={formatPercents}
      iconWide
      iconWidth={96}
      width={192}
    />

    <KeyframesPropertyControl
      components={components}
      time={time}
      KeyframeConstructor={NumberKeyframe}
      valueGetter={getValueNumber}
    />
  </div>
)

OffsetEditable.displayName = 'OffsetEditable'

const OffsetDisplay: React.FC<{
  time: number
  components: TrimOffsetComponent[]
  offsets: number[]
  keyframesType: PropertyKeyframesType
}> = React.memo(
  ({ time, components }) => (
    <div className="flex w-full flex-row justify-between">
      <NumberValue
        components={components}
        time={time}
        icon={<span className="w-full pl-[4px]">Offset</span>}
        min={-100}
        max={100}
        before={(value) => value * 100}
        after={(value) => value / 100}
        format={formatPercents}
        iconWide
        iconWidth={96}
        width={192}
      />

      <KeyframesPropertyControl
        components={components}
        time={time}
        KeyframeConstructor={NumberKeyframe}
        valueGetter={getValueNumber}
      />
    </div>
  ),
  (prev, next) => {
    if (prev.offsets.length !== next.offsets.length) return false
    for (let i = 0; i < prev.offsets.length; i++) {
      if (prev.offsets[i] !== next.offsets[i]) return false
    }
    if (prev.keyframesType !== next.keyframesType) return false
    return true
  }
)

OffsetDisplay.displayName = 'OffsetDisplay'
