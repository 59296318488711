import type { Entity } from '@aninix-inc/model'
import * as React from 'react'

export type DropZoneType = 'child' | 'sibling' | 'siblingOfParent'
export type MouseHandler = (e: React.MouseEvent, entity: Entity) => void

type LayerDndContextType = {
  isEntityDragging: (entity: Entity) => boolean
  isEntityDropTarget: (entity: Entity) => boolean
  isEntityParentOfDropTarget: (entity: Entity) => boolean
  getDropZoneIndent: (indent: number) => number
  onMouseMove: MouseHandler
  onMouseDown: MouseHandler
  onMouseUp: MouseHandler
  onMouseOver: MouseHandler
  onMouseOut: MouseHandler
}

export const LayerDndContext = React.createContext<LayerDndContextType>(
  null as any
)

export const useLayerDnd = () => {
  const context = React.useContext(LayerDndContext)
  if (!context) {
    throw new Error('useLayerDnd must be used within a LayerDndProvider')
  }
  return context
}
