import {
  useCurrentFolder,
  useDeleteFolder,
  useLinkFolderToFolder,
  useSetFolderName,
} from '@aninix/api'
import classNames from 'classnames'
import * as React from 'react'
import { Link, useHref, useNavigate } from 'react-router-dom'
import { useAnalytics } from '@aninix/analytics'
import { AnalyticsEvent } from '@aninix/analytics'

import { config } from '../../config'
import { toast } from '../../modules/toasts'
import { useCurrentSpaceStore } from '../../use-cases'
import { Typography } from '../design-system/typography'
import { Icons } from '../icons'
import { Delete } from './delete'
import { FolderContextMenu } from './folder-item-context'
import { MoveTo } from './move-to'
import { Rename } from './rename'

type Modal = 'none' | 'rename' | 'moveto' | 'delete'

const projectCountLabel = (count: number) => {
  if (count > 1) return count + ' projects'
  else if (count == 1) return count + ' project'
  else return ''
}

const folderCountLabel = (count: number) => {
  if (count > 1) return count + ' folders'
  else if (count == 1) return count + ' folder'
  else return ''
}

const folderInfoLabel = (projectsCount: number) => {
  return projectsCount ? projectCountLabel(projectsCount) : 'Empty folder'
}

export const FolderItemPlaceholder: React.FCC = () => (
  <div className="group relative flex max-h-32 w-full flex-col gap-4 rounded-lg border-[1px] border-gray-200 bg-white p-4 transition-all duration-300 hover:shadow-lg ">
    <div className="h-8 w-9">
      <img
        crossOrigin="anonymous"
        className="object-fill"
        src="/images/defaultFolderIcon.png"
      />
    </div>
    <div className="flex flex-col gap-1">
      <div className=" h-6 w-1/2 animate-pulse rounded-md bg-gray-200" />
      <div className=" h-4 w-1/3 animate-pulse rounded-md bg-gray-200" />
    </div>
  </div>
)

export const FolderItem: React.FCC<{
  id: string
  name: string
  teamId?: string
  userId?: string
  projects: { id: string; deletedAt?: string }[]
}> = ({ id, name, projects, userId, teamId }) => {
  const [showModal, setShowModal] = React.useState<Modal>('none')

  const iconUrl = null //@TODO: replace with actual asset

  const projectsCount =
    projects.filter((p) => p.deletedAt === undefined).length ?? 0

  const [displayOptions, setDisplayOptions] = React.useState<boolean>(false)

  const analytics = useAnalytics()

  const trackFolderAction = React.useCallback(
    (eventName: AnalyticsEvent) => {
      analytics.track({
        eventName,
        properties: {
          space: teamId ? 'team' : 'personal',
        },
      })
    },
    [analytics, teamId]
  )

  const options = {
    show: React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        setDisplayOptions(true)
      },
      []
    ),
    hide: React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setDisplayOptions(false)
      },
      []
    ),
    toggleDisplay: React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        setDisplayOptions(!displayOptions)
      },
      []
    ),
  }

  const hideModals = React.useCallback(() => {
    setShowModal('none')
  }, [])

  const currentFolder = useCurrentFolder().folder!

  const linkFolderToFolder = useLinkFolderToFolder()

  const { selectedFoldersIds, setSelectedFoldersIds, setSelectedProjectsIds } =
    useCurrentFolder()

  const isSelected = React.useMemo(
    () => selectedFoldersIds?.has(id) ?? false,
    [selectedFoldersIds]
  )

  const handleClick = React.useCallback(() => {
    setSelectedProjectsIds?.(new Set([]))

    if (isSelected) setSelectedFoldersIds?.(new Set([]))
    else setSelectedFoldersIds?.(new Set([id]))
  }, [isSelected, setSelectedFoldersIds])

  const navigate = useNavigate()

  const moveFolderTo = React.useCallback(
    (folderId?: string | null) => {
      linkFolderToFolder
        .mutateAsync({
          folderId: id,
          parentFolderId: folderId,
        })
        .then((value) => {
          hideModals()
          currentFolder.refetch()
          toast('Folder moved successfully. Click to open the folder', {
            variant: 'info',
            toastProps: {
              icon: <Icons.Folder />,
              onClick: () =>
                navigate('../projects' + (folderId ? `/${folderId}` : '')),
            },
          })
          trackFolderAction(AnalyticsEvent.FolderMove)
        })
    },
    [trackFolderAction]
  )

  const setFolderName = useSetFolderName()

  const renameFolder = React.useCallback(
    (name: string) => {
      setFolderName
        .mutateAsync({ folderId: id, name })
        .then((value) => {
          setShowModal('none')
          currentFolder.refetch()
          toast(`Folder renamed to ${name}`)
          trackFolderAction(AnalyticsEvent.FolderRename)
        })
        .catch((error) => toast("Couldn't rename folder", { variant: 'error' }))
    },
    [trackFolderAction]
  )

  const handleModalClose = React.useCallback(() => {
    setShowModal('none')
  }, [])

  const deleteFolder = useDeleteFolder()

  const handleDeleteFolder = React.useCallback(() => {
    deleteFolder
      .mutateAsync({ folderId: id })
      .then((value) => {
        currentFolder.refetch()
        toast(`Folder "${name}" has been deleted`, { variant: 'success' })
        trackFolderAction(AnalyticsEvent.FolderDelete)
      })
      .catch((error) => toast("Couldn't delete folder", { variant: 'error' }))
  }, [trackFolderAction])

  const relativeFolderLink = teamId
    ? `/teams/${teamId}/projects/${id}`
    : `/my/projects/${id}`
  const absoluteFolderLink = config.webAppUrl + useHref(relativeFolderLink)

  const [isDraggedOver, setIsDraggedOver] = React.useState(false)

  const { setDraggedFolderOverId } = useCurrentFolder()

  const [isDragged, setIsDragged] = React.useState(false)

  const [translate, setTranslate] = React.useState({ x: 0, y: 0 })

  const handleDrag = React.useCallback(
    (e: React.DragEvent<HTMLButtonElement>) => {
      const target = e.currentTarget.getBoundingClientRect()

      if (e.clientX === 0) {
        setTranslate({ x: 0, y: 0 })
        return
      }

      const toTranslate = {
        x: e.clientX - target.x - target.width / 2,
        y: e.clientY - target.y - target.height / 2,
      }
      setTranslate(toTranslate)
    },
    []
  )

  const currentFolderId = useCurrentFolder().payload?.folderId
  const { draggedFolderOverId } = useCurrentFolder()

  const dragImage = React.useMemo(() => {
    let img = new Image()
    img.src = '/images/transparentPixel.png'
    return img
  }, [])

  const { currentUser } = useCurrentSpaceStore()

  const teamUser = React.useMemo(
    () => currentUser?.teams.find((e) => e.id === teamId),
    [currentUser, teamId]
  )

  const canEditFolder = React.useMemo(() => {
    return (
      ['editor'].includes(teamUser?.aninixRole ?? 'viewer') ||
      userId === currentUser?.id
    )
  }, [teamUser, userId, currentUser?.id])

  return (
    <>
      <Rename
        handleRename={renameFolder}
        name={name}
        handleClose={handleModalClose}
        isOpen={showModal == 'rename'}
      />
      <MoveTo
        handleClose={hideModals}
        isOpen={showModal == 'moveto'}
        handleMoveTo={moveFolderTo}
      />
      <Delete
        header="Delete folder"
        callToAction="Delete"
        Messsage={
          <>
            <Typography style="Body4Regular">
              Are you sure you want to permanently delete the "{name}" folder?
              You can’t undo this action.
            </Typography>

            <Typography style="Body5Regular" className="text-gray-500">
              The subfolders will be deleted, but nested projects will be moved
              to the root of your space.
            </Typography>
          </>
        }
        handleClose={hideModals}
        isOpen={showModal == 'delete'}
        handleDelete={handleDeleteFolder}
      />
      <button
        draggable={canEditFolder}
        onClick={handleClick}
        onDoubleClick={() => {
          trackFolderAction(AnalyticsEvent.FolderOpen)
          navigate(relativeFolderLink)
        }}
        onMouseLeave={(e) => {
          e.preventDefault()
          e.stopPropagation()
          e.currentTarget.blur()
        }}
        className={classNames('group')}
        onDrag={handleDrag}
        onDragStart={(e) => {
          e.dataTransfer.setDragImage(dragImage, 0, 0)
          e.dataTransfer.effectAllowed = 'move'
          e.dataTransfer.dropEffect = 'move'
          setIsDragged(true)
          setDraggedFolderOverId?.(undefined)
        }}
        onDragEnd={(e) => {
          setIsDragged(false)
          setTranslate({ x: 0, y: 0 })

          if (
            currentFolderId !== draggedFolderOverId &&
            draggedFolderOverId !== undefined &&
            draggedFolderOverId !== id
          ) {
            moveFolderTo(draggedFolderOverId)
          } else setTranslate({ x: 0, y: 0 })

          setDraggedFolderOverId?.(undefined)
        }}
        onDrop={(e) => {
          setIsDragged(false)
          setTranslate({ x: 0, y: 0 })
        }}
      >
        <div
          onMouseLeave={options.hide}
          className={classNames(
            'group relative flex max-h-32 w-full flex-col gap-4 rounded-lg border-[1px] border-gray-200 bg-white p-4 transition-all duration-75 hover:shadow-lg group-focus:shadow-lg ',
            {
              ['!border-accent transition-all duration-75']: isDraggedOver,
              ['pointer-events-none !border-accent opacity-50 !transition-transform !duration-300']:
                isDragged,
              [' !border-[#374FD580] !bg-[#374FD50D]']:
                isSelected && !isDragged,
            }
          )}
          style={{
            ...(isDragged && {
              zIndex: 999,
              translate: `${translate.x}px ${translate.y}px`,
            }),
            ...(isDragged &&
              draggedFolderOverId !== undefined && {
                transform: `scale(0)`,
              }),
          }}
          onDragOver={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setIsDraggedOver(true)
            setDraggedFolderOverId?.(id)
          }}
          onDragLeave={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setIsDraggedOver(false)
            setDraggedFolderOverId?.(undefined)
          }}
          onDrop={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setIsDraggedOver(false)
          }}
        >
          <div className="h-8 w-9">
            <img
              crossOrigin="anonymous"
              className="object-fill"
              src={iconUrl || '/images/defaultFolderIcon.png'}
            />
          </div>
          <div className="flex flex-col gap-1 items-start">
            <p className="line-clamp-1 h-6 font-body text-base  font-medium text-secondary">
              {name}
            </p>
            <p className="font-body text-xs font-normal text-gray-400">
              {folderInfoLabel(projectsCount)}
            </p>
          </div>
          <div
            onClick={options.toggleDisplay}
            className={classNames(
              'invisible absolute right-4 top-4 z-10 flex items-center rounded-md bg-white bg-opacity-40 p-1 opacity-0 transition-all duration-300 group-hover:visible group-hover:opacity-100',
              {
                [' bg-black bg-opacity-100']: displayOptions,
              }
            )}
          >
            <Icons.Dots
              className={classNames(
                'stroke-gray-500 transition-all duration-300',
                {
                  ['!stroke-white']: displayOptions,
                }
              )}
            />
            <FolderContextMenu
              handleDelete={handleDeleteFolder}
              canEditFolder={canEditFolder}
              handleClose={options.hide}
              isShown={displayOptions}
              handleModal={setShowModal}
              folderLink={absoluteFolderLink}
            />
          </div>
        </div>
      </button>
    </>
  )
}
