import { timingCurve } from '@aninix-inc/model/legacy'

import { TimingCurve } from '../types'

export const stringToTimingValue = (string: string): TimingCurve => {
  const splitted = string.replace(/ /g, '').split(',')

  if (splitted.length !== 4) {
    return timingCurve.create({
      out: {
        x: 0.5,
        y: 0.35,
      },
      in: {
        x: 0.15,
        y: 1,
      },
    })
  }

  return timingCurve.create({
    out: {
      x: parseFloat(splitted[0]),
      y: parseFloat(splitted[1]),
    },
    in: {
      x: parseFloat(splitted[2]),
      y: parseFloat(splitted[3]),
    },
  })
}
