import * as React from 'react'
import { Loader } from '../loader'
import { FlowLeft } from './flow'

export interface IProps {
  name: string
  isLoading?: boolean
}
export const FlowSelected: React.FCC<IProps> = ({
  name,
  isLoading = false,
}) => (
  <div className="w-full overflow-hidden">
    <div className="flex relative items-center justify-center">
      <div className="flex flex-row">
        <div className="stroke-secondary">
          <FlowLeft />
        </div>

        <div className="relative flex flex-col gap-1 items-center max-w-[240px] h-auto">
          <div className="absolute inset-0 top-1/2 -translate-y-1/2 w-full h-[2px] bg-secondary" />
          <div className="translate-y-full flex flex-col items-center">
            <div className="flex flex-row gap-1 pr-1 max-w-[240px] justify-center">
              {isLoading ? <Loader size={16} /> : <FlowIcon />}
              <p
                style={{ lineBreak: 'anywhere' }}
                className="text-xs w-max line-clamp-1 font-semibold text-black/80"
              >{`${name}`}</p>
            </div>
            <p className="text-xs font-normal text-black/80">{`selected`}</p>
          </div>
        </div>

        <div className="-scale-x-100 stroke-secondary">
          <FlowLeft />
        </div>
      </div>
    </div>
  </div>
)

FlowSelected.displayName = 'FlowSelected'

const FlowIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 13V3.00003L13.5 8.00003L5.5 13Z"
      stroke="black"
      strokeOpacity="0.8"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
