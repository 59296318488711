import { Tool, useTools } from '@aninix/core/stores'
import {
  ICommentsGetResponse,
  useGetComments,
} from '@aninix/core/use-cases/comments/use-get-comments'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'

import {
  filter,
  useCommentContext,
} from '../comments-wrapper/use-cases/use-comment-context'
import { CommentTimelineItem } from './comment-timeline-item'

export interface IProps {
  commentsGetResponse?: ICommentsGetResponse
  isShowingComments?: boolean
}

export const CommentsTimeline: React.FCC<IProps> = observer(
  ({ commentsGetResponse, isShowingComments }) => {
    const { filterMode, searchText } = useCommentContext()

    const tools = useTools()

    const _isShowingComments =
      isShowingComments ?? tools.activeTool === Tool.Comments

    const response =
      commentsGetResponse ??
      useGetComments({ isStartingLoading: isShowingComments })
    const { data, status } = response

    //slice for not modifying old array
    const comments = filter(
      data?.slice().reverse() ?? [],
      filterMode,
      searchText
    )

    return (
      <div
        className={classNames(
          'pointer-events-none absolute inset-0 left-[6px] right-[6px] z-[200] transition-all duration-150',
          {
            ['pointer-events-none translate-y-2 opacity-0']:
              !_isShowingComments || status === 'pending',
          }
        )}
      >
        {comments.map((comment, index) => (
          <CommentTimelineItem
            key={comment.id}
            data={comment}
            // index={data.length - index - 1}
            // isLast={index === data.length - 1}
          />
        ))}
      </div>
    )
  }
)

CommentsTimeline.displayName = 'CommentsTimeline'
