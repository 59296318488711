import * as React from 'react'

export interface IProps {
  className?: string
}
export const MaintenanceLightIcon: React.FC<IProps> = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 180 180"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_219_2761)">
      <path
        opacity="0.5"
        d="M30 0V180M60 0V180M90.25 0V180M120.25 0V180M150.25 0V180M180 30L0 30M180 60L0 60M180 90L0 90M180 120L0 120M180 150L0 150"
        stroke="url(#paint0_radial_219_2761)"
        strokeWidth="0.5"
      />
      <path
        d="M63.6779 70.5721C69.9936 64.2564 78.2621 61.0834 86.5398 61.0533C88.2064 61.0472 88.91 63.0176 87.7316 64.1961L71.3404 80.5873C70.0215 81.9062 69.362 82.5656 68.9635 83.3478C68.6109 84.0399 68.4077 84.7984 68.367 85.574C68.3211 86.4507 68.5624 87.3515 69.0452 89.1532L72.3721 101.569C72.8548 103.371 73.0962 104.272 73.5743 105.008C73.9974 105.659 74.5526 106.215 75.204 106.638C75.9402 107.116 76.8411 107.357 78.6428 107.84L91.0588 111.167C92.8604 111.65 93.7613 111.891 94.6379 111.845C95.4136 111.804 96.1721 111.601 96.8641 111.248C97.6463 110.85 98.3058 110.19 99.6247 108.872L116.016 92.4804C117.194 91.3019 119.165 92.0056 119.159 93.6721C119.129 101.95 115.956 110.218 109.64 116.534C102.296 123.878 92.3115 126.973 82.7424 125.818C80.7796 125.581 78.767 126.095 77.3232 127.446L27.2321 169.466C24.8691 171.677 21.1787 171.616 18.8906 169.327L10.8845 161.321C8.59639 159.033 8.5349 155.343 10.7455 152.98L52.7662 102.889C54.1168 101.445 54.6312 99.4323 54.3943 97.4695C53.2393 87.9004 56.3339 77.9161 63.6779 70.5721Z"
        fill="url(#paint1_linear_219_2761)"
      />
      <g filter="url(#filter0_ddddd_219_2761)">
        <path
          d="M101.134 72.5815C102.936 73.0643 103.837 73.3056 104.573 73.7838C105.225 74.2068 105.78 74.762 106.203 75.4134C106.681 76.1497 106.922 77.0505 107.405 78.8522L110.732 91.2682C111.215 93.0699 111.456 93.9707 111.41 94.8474C111.37 95.6231 111.166 96.3815 110.814 97.0736C110.415 97.8558 109.756 98.5152 108.437 99.8341L99.3476 108.923C98.0287 110.242 97.3692 110.902 96.587 111.3C95.895 111.653 95.1365 111.856 94.3609 111.897C93.4842 111.943 92.5834 111.701 90.7817 111.219L78.3657 107.892C76.564 107.409 75.6631 107.168 74.9269 106.689C74.2755 106.266 73.7203 105.711 73.2972 105.06C72.8191 104.323 72.5777 103.423 72.095 101.621L68.7681 89.205C68.2853 87.4033 68.044 86.5024 68.0899 85.6258C68.1306 84.8501 68.3338 84.0917 68.6864 83.3996C69.085 82.6174 69.7444 81.958 71.0633 80.639L80.1525 71.5499C81.4714 70.2309 82.1309 69.5715 82.913 69.173C83.6051 68.8203 84.3636 68.6171 85.1392 68.5764C86.0159 68.5305 86.9167 68.7719 88.7184 69.2546L101.134 72.5815Z"
          fill="url(#paint2_linear_219_2761)"
        />
        <path
          d="M111.698 91.0094L108.371 78.5934C108.356 78.5358 108.34 78.479 108.325 78.4229C107.887 76.785 107.607 75.7396 107.042 74.8688C106.542 74.0999 105.887 73.4444 105.118 72.9451C104.247 72.3796 103.202 72.0997 101.564 71.6612C101.508 71.6462 101.451 71.631 101.393 71.6156L88.9772 68.2887C88.9197 68.2733 88.8629 68.2581 88.8068 68.243C87.1691 67.8038 86.1238 67.5235 85.0869 67.5778C84.1713 67.6258 83.276 67.8657 82.4591 68.2819C81.534 68.7533 80.7688 69.5188 79.5701 70.718C79.5291 70.7591 79.4875 70.8007 79.4454 70.8428L70.3562 79.9319C70.3141 79.974 70.2725 80.0156 70.2314 80.0567C69.0322 81.2554 68.2668 82.0205 67.7954 82.9456C67.3792 83.7625 67.1393 84.6578 67.0913 85.5734C67.0369 86.6103 67.3173 87.6556 67.7565 89.2933C67.7715 89.3494 67.7868 89.4063 67.8022 89.4638L71.129 101.88C71.1444 101.937 71.1597 101.994 71.1747 102.05C71.6132 103.688 71.8931 104.734 72.4585 105.604C72.9579 106.373 73.6133 107.029 74.3823 107.528C75.253 108.094 76.2985 108.373 77.9364 108.812C77.9925 108.827 78.0493 108.842 78.1068 108.858L90.5229 112.184C90.5804 112.2 90.6372 112.215 90.6933 112.23C92.331 112.669 93.3763 112.95 94.4132 112.895C95.3288 112.847 96.2241 112.607 97.041 112.191C97.9661 111.72 98.7313 110.954 99.93 109.755C99.971 109.714 100.013 109.673 100.055 109.63L109.144 100.541C109.186 100.499 109.228 100.458 109.269 100.416C110.468 99.2178 111.233 98.4527 111.705 97.5276C112.121 96.7106 112.361 95.8153 112.409 94.8997C112.463 93.8629 112.183 92.8176 111.744 91.1799C111.729 91.1237 111.713 91.0669 111.698 91.0094Z"
          stroke="white"
          strokeWidth="2"
        />
      </g>
      <g filter="url(#filter1_dddddd_219_2761)">
        <path
          d="M94.507 94.6087L82.3521 93.899L92.9294 83.3216L88.9335 82.0613L77.9143 93.0805L78.291 96.6257L98.5594 97.736L96.2604 78.2296L92.6288 77.0543L94.507 94.6087Z"
          fill="white"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_ddddd_219_2761"
        x="61.0847"
        y="65.5713"
        width="57.3306"
        height="66.3306"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_219_2761"
          result="effect2_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_219_2761"
          result="effect3_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="7" />
        <feGaussianBlur stdDeviation="2" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_219_2761"
          result="effect4_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="13" />
        <feGaussianBlur stdDeviation="2.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_219_2761"
          result="effect5_dropShadow_219_2761"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect5_dropShadow_219_2761"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_dddddd_219_2761"
        x="74.9143"
        y="76.5543"
        width="26.645"
        height="34.6817"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="0.5" />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.29 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_219_2761"
          result="effect2_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1.5" />
        <feGaussianBlur stdDeviation="0.75" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="effect2_dropShadow_219_2761"
          result="effect3_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="1.25" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="effect3_dropShadow_219_2761"
          result="effect4_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="7" />
        <feGaussianBlur stdDeviation="1.25" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.04 0"
        />
        <feBlend
          mode="normal"
          in2="effect4_dropShadow_219_2761"
          result="effect5_dropShadow_219_2761"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="10.5" />
        <feGaussianBlur stdDeviation="1.5" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0 0.788235 0 0 0 0.01 0"
        />
        <feBlend
          mode="normal"
          in2="effect5_dropShadow_219_2761"
          result="effect6_dropShadow_219_2761"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect6_dropShadow_219_2761"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_219_2761"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(90.25 90) scale(90)"
      >
        <stop stopColor="#CED3D9" />
        <stop offset="1" stopColor="white" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_219_2761"
        x1="103.231"
        y1="76.981"
        x2="14.8875"
        y2="165.324"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.47724" stopColor="#E5E5E5" />
        <stop offset="0.990807" stopColor="white" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_219_2761"
        x1="105.474"
        y1="74.513"
        x2="74.5419"
        y2="105.445"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#E5E5E5" />
        <stop offset="1" stopColor="#F4F4F4" />
      </linearGradient>
      <clipPath id="clip0_219_2761">
        <rect width="180" height="180" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
