import * as R from 'ramda'
import * as React from 'react'

import {
  Entity,
  TargetRelationAspect,
  TimeComponent,
  UpdatesSystem,
  cleanupKeyframes,
  commitUndo,
} from '@aninix-inc/model'
import { SegmentsAlignType, buttons, icons } from '@aninix/app-design-system'
import {
  KeyModificator,
  useFlattenEntities,
  usePlayback,
  useSession,
} from '@aninix/core'
import * as styles from './index.scss'

export interface IProps {
  segments: [Entity, Entity][]
}
export const Align: React.FCC<IProps> = ({ segments }) => {
  useFlattenEntities(segments)
  const project = segments[0][0].getProjectOrThrow()
  const session = useSession()
  const playback = usePlayback()

  const updateSegmentTime = React.useCallback(
    (payload: { segment: Entity[]; time: number }) => {
      const { segment, time } = payload
      const startTime =
        R.head(segment)!.getComponentOrThrow(TimeComponent).value

      segment.forEach((keyframe) => {
        const duration =
          keyframe.getComponentOrThrow(TimeComponent).value - startTime
        keyframe.updateComponent(TimeComponent, time + duration)
      })
    },
    []
  )

  const preparedSegments = React.useMemo(() => {
    const keyframesByProperties = R.values(
      R.groupBy(
        (keyframe) =>
          keyframe.getAspectOrThrow(TargetRelationAspect).getRelationOrThrow(),
        R.flatten(segments)
      )
    )

    const uniqKeyframes = keyframesByProperties.map((propertyKeyframes) =>
      R.uniqBy((keyframe) => keyframe.id, propertyKeyframes!)
    )

    return uniqKeyframes
  }, [segments])

  const align = React.useCallback(
    // @TODO: provide type here
    ({ type }: any) => {
      project.getSystemOrThrow(UpdatesSystem).batch(() => {
        const min = preparedSegments.reduce(
          (acc, segment) =>
            Math.min(
              acc,
              R.head(segment)!.getComponentOrThrow(TimeComponent).value
            ),
          Infinity
        )

        const max = preparedSegments.reduce(
          (acc, segment) =>
            Math.max(
              acc,
              R.last(segment)!.getComponentOrThrow(TimeComponent).value
            ),
          -Infinity
        )

        if (type === SegmentsAlignType.Left) {
          preparedSegments.forEach((segment) => {
            updateSegmentTime({
              segment,
              time: min,
            })
          })
          return
        }

        if (type === SegmentsAlignType.LeftToSlider) {
          preparedSegments.forEach((segment) => {
            updateSegmentTime({
              segment,
              time: playback.time,
            })
          })
          return
        }

        if (type === SegmentsAlignType.Center) {
          preparedSegments.forEach((segment) => {
            updateSegmentTime({
              segment,
              time:
                (max - min) / 2 +
                min -
                (R.last(segment)!.getComponentOrThrow(TimeComponent).value -
                  R.head(segment)!.getComponentOrThrow(TimeComponent).value) /
                  2,
            })
          })
          return
        }

        if (type === SegmentsAlignType.CenterToSlider) {
          preparedSegments.forEach((segment) => {
            updateSegmentTime({
              segment,
              time:
                playback.time -
                (R.last(segment)!.getComponentOrThrow(TimeComponent).value -
                  R.head(segment)!.getComponentOrThrow(TimeComponent).value) /
                  2,
            })
          })
          return
        }

        if (type === SegmentsAlignType.Right) {
          preparedSegments.forEach((segment) => {
            updateSegmentTime({
              segment,
              time:
                max -
                (R.last(segment)!.getComponentOrThrow(TimeComponent).value -
                  R.head(segment)!.getComponentOrThrow(TimeComponent).value),
            })
          })
          return
        }

        if (type === SegmentsAlignType.RightToSlider) {
          preparedSegments.forEach((segment) => {
            updateSegmentTime({
              segment,
              time:
                playback.time -
                (R.last(segment)!.getComponentOrThrow(TimeComponent).value -
                  R.head(segment)!.getComponentOrThrow(TimeComponent).value),
            })
          })
          return
        }

        if (type === SegmentsAlignType.SpaceBetween) {
          preparedSegments.forEach((segment, idx) => {
            const progress = idx / (preparedSegments.length - 1)
            const duration = max - min
            const segmentDuration =
              R.last(segment)!.getComponentOrThrow(TimeComponent).value -
              R.head(segment)!.getComponentOrThrow(TimeComponent).value

            updateSegmentTime({
              segment,
              time: min + progress * (duration - segmentDuration),
            })
          })
          return
        }
      })
      cleanupKeyframes(project)
      commitUndo(project)
    },
    [preparedSegments, playback, updateSegmentTime]
  )

  const isDistributionVisible = preparedSegments.length > 2
  const toSlider = session.keyModificators.includes(KeyModificator.Alt)

  if (
    (preparedSegments.length > 1 ||
      session.keyModificators.includes(KeyModificator.Alt)) === false
  ) {
    return null
  }

  return (
    <div className={styles.container}>
      {toSlider ? (
        <buttons.Icon
          onClick={() =>
            align({
              type: icons.propertiesPanel.SegmentsAlignType.LeftToSlider,
            })
          }
        >
          <icons.propertiesPanel.SegmentsAlign
            type={icons.propertiesPanel.SegmentsAlignType.LeftToSlider}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon
          onClick={() =>
            align({
              type: icons.propertiesPanel.SegmentsAlignType.Left,
            })
          }
        >
          <icons.propertiesPanel.SegmentsAlign
            type={icons.propertiesPanel.SegmentsAlignType.Left}
          />
        </buttons.Icon>
      )}

      {toSlider ? (
        <buttons.Icon
          onClick={() =>
            align({
              type: icons.propertiesPanel.SegmentsAlignType.CenterToSlider,
            })
          }
        >
          <icons.propertiesPanel.SegmentsAlign
            type={icons.propertiesPanel.SegmentsAlignType.CenterToSlider}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon
          onClick={() =>
            align({
              type: icons.propertiesPanel.SegmentsAlignType.Center,
            })
          }
        >
          <icons.propertiesPanel.SegmentsAlign
            type={icons.propertiesPanel.SegmentsAlignType.Center}
          />
        </buttons.Icon>
      )}

      {toSlider ? (
        <buttons.Icon
          onClick={() =>
            align({
              type: icons.propertiesPanel.SegmentsAlignType.RightToSlider,
            })
          }
        >
          <icons.propertiesPanel.SegmentsAlign
            type={icons.propertiesPanel.SegmentsAlignType.RightToSlider}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon
          onClick={() =>
            align({
              type: icons.propertiesPanel.SegmentsAlignType.Right,
            })
          }
        >
          <icons.propertiesPanel.SegmentsAlign
            type={icons.propertiesPanel.SegmentsAlignType.Right}
          />
        </buttons.Icon>
      )}

      {isDistributionVisible && (
        <buttons.Icon
          onClick={() =>
            align({
              type: icons.propertiesPanel.SegmentsAlignType.SpaceBetween,
            })
          }
        >
          <icons.propertiesPanel.SegmentsAlign
            type={icons.propertiesPanel.SegmentsAlignType.SpaceBetween}
          />
        </buttons.Icon>
      )}
    </div>
  )
}
