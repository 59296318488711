import * as React from 'react'

import { Logo } from '../../components'
import { Error } from './components/error'
import { Success } from './components/success'
import { IAuthInteractor } from './interactor'

const size = {
  x: 32,
  y: 32,
}

export interface IProps {
  interactor: IAuthInteractor
}

export const AuthRouter: React.FCC<IProps> = ({ interactor }) => (
  <div className="flex h-screen w-screen flex-col items-center bg-grey p-[20px]">
    <div className="mb-[72px] flex items-center">
      <Logo size={size} />{' '}
      <p className="ml-[8px] font-header text-[20px] font-bold">Aninix</p>
    </div>

    {interactor.children.error && <Error />}
    {interactor.children.success && <Success />}
  </div>
)
