import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import { toast as toastSource } from 'react-toastify'
import { IPaymentInfo } from '.'
import { Typography } from '../../components/design-system/typography'
import { toast } from '../toasts'

export interface IProps {
  paymentInfo: IPaymentInfo
}
export const PaymentStatus: React.FCC<IProps> = ({ paymentInfo }) => {
  const { link, onCancel, status, userId, teamId } = paymentInfo

  const navigate = useNavigate()

  React.useEffect(() => {
    if (status !== 'not-started') toastSource.dismiss()

    const toastId = [
      teamId != null ? 'team' : 'user',
      'payment',
      status,
      teamId != null ? teamId : userId,
    ].join('-')

    switch (status) {
      case 'not-started': {
        return
      }
      case 'pending': {
        toast(<Pending link={link} onCancel={onCancel} />, {
          variant: 'info',
          toastProps: {
            toastId: toastId,
            autoClose: false,
            bodyStyle: { background: 'none' },
          },
        })
        return
      }
      case 'success': {
        toast(<Success />, {
          variant: 'info',
          toastProps: {
            toastId: toastId,
            autoClose: false,
            bodyStyle: { background: 'none' },
          },
        })

        setTimeout(() => {
          if (teamId != null) {
            navigate(`/teams/${teamId}`)
          } else {
            navigate('/my')
          }
        }, 500)

        return
      }
      case 'error': {
        toast(<Error />, {
          variant: 'info',
          toastProps: {
            toastId: toastId,
            autoClose: false,
            bodyStyle: { background: 'none' },
          },
        })
        return
      }
    }
  }, [status])

  return null
}

PaymentStatus.displayName = 'PaymentStatus'

export const Pending = ({
  link,
  onCancel,
}: {
  link?: string
  onCancel: () => void
}) => (
  <div className="flex flex-row gap-0 overflow-hidden rounded-xl bg-[#0B1118D9]">
    {/* Status */}
    <div className="flex flex-row items-center gap-2 px-3 py-[6.5px]">
      <ClockIcon />
      <Typography
        style="Body4Regular"
        text={`Payment is pending`}
        color="light"
      />
    </div>

    {/* Link */}
    <a
      href={link}
      target="_blank"
      rel="noreferrer"
      className="pointer-events-auto flex flex-row items-center gap-2 border-x-[1px] border-gray-400 bg-[#0B11184D] px-3 py-[6.5px] hover:!bg-[#6767674d]"
    >
      <Typography style="Body4Medium" text="Payment page ↗" color="light" />
    </a>

    {/* Cancel */}
    <button
      onClick={onCancel}
      className="pointer-events-auto flex flex-row flex-grow items-center gap-2 bg-[#0B11184D] px-3 py-[6.5px] hover:!bg-[#6767674d]"
    >
      <Typography style="Body4Medium" text="Cancel" color="light" />
    </button>
  </div>
)

export const Success = () => (
  <div className="flex flex-row gap-0 overflow-hidden rounded-xl bg-[#0B1118D9] bg-gradient-to-b from-[#1DB95C00] to-[#1DB95C1A]">
    {/* Status */}
    <div className="flex flex-row items-center gap-2 px-3 py-[6.5px]">
      <SuccessIcon />
      <Typography
        style="Body4Regular"
        text={`Payment successful!`}
        className="stroke-[#1DB95C] text-[#1DB95C]"
      />
    </div>
  </div>
)

export const Error = () => (
  <div className="flex flex-row gap-0 overflow-hidden rounded-xl bg-[#0B1118D9] bg-gradient-to-b from-[#F2482200] to-[#F248221A]">
    {/* Status */}
    <div className="flex flex-row items-center gap-2 px-3 py-[6.5px]">
      <ErrorIcon />
      <Typography
        style="Body4Regular"
        text={`Payment failed`}
        className="stroke-[#F24822] text-[#F24822]"
      />
    </div>
  </div>
)

const ClockIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5 11.6667L10 10V5.83333M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const SuccessIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.33329 4.16665H4.99996M4.16663 3.33331V4.99998M9.58329 3.33331L9.16663 4.99998M15 4.16665H16.6666M15.8333 3.33331V4.99998M12.5 7.49998L11.6666 8.33331M15 10.8333L16.6666 10.4166M15 15.8333H16.6666M15.8333 15V16.6666M11.6667 13.765L6.235 8.33331L2.57667 16.3166C2.50436 16.4716 2.4815 16.6451 2.51118 16.8136C2.54087 16.982 2.62167 17.1373 2.74261 17.2582C2.86356 17.3791 3.01878 17.4599 3.18723 17.4896C3.35567 17.5193 3.52916 17.4965 3.68417 17.4241L11.6667 13.765Z"
      stroke="#1DB95C"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const ErrorIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.0833 13.375C11.8118 13.0979 11.4876 12.8777 11.1299 12.7273C10.7722 12.577 10.388 12.4996 10 12.4996C9.61197 12.4996 9.22784 12.577 8.87011 12.7273C8.51237 12.8777 8.18823 13.0979 7.91667 13.375M6.66667 7.5L8.33333 9.16667M8.33333 7.5L6.66667 9.16667M11.6667 7.5L13.3333 9.16667M13.3333 7.5L11.6667 9.16667M2.5 10C2.5 10.9849 2.69399 11.9602 3.0709 12.8701C3.44781 13.7801 4.00026 14.6069 4.6967 15.3033C5.39314 15.9997 6.21993 16.5522 7.12987 16.9291C8.03982 17.306 9.01509 17.5 10 17.5C10.9849 17.5 11.9602 17.306 12.8701 16.9291C13.7801 16.5522 14.6069 15.9997 15.3033 15.3033C15.9997 14.6069 16.5522 13.7801 16.9291 12.8701C17.306 11.9602 17.5 10.9849 17.5 10C17.5 9.01509 17.306 8.03982 16.9291 7.12987C16.5522 6.21993 15.9997 5.39314 15.3033 4.6967C14.6069 4.00026 13.7801 3.44781 12.8701 3.0709C11.9602 2.69399 10.9849 2.5 10 2.5C9.01509 2.5 8.03982 2.69399 7.12987 3.0709C6.21993 3.44781 5.39314 4.00026 4.6967 4.6967C4.00026 5.39314 3.44781 6.21993 3.0709 7.12987C2.69399 8.03982 2.5 9.01509 2.5 10Z"
      stroke="#F24822"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
