import { format } from 'date-fns'

import { LogLevel, Logger } from './logger'

function getTimestamp(): string {
  return format(new Date(), 'HH:mm:ss')
}

export class LocalLogger implements Logger {
  constructor(
    private readonly config: {
      level: LogLevel
      prefix?: string
    }
  ) {}

  log: Logger['log'] = (...args) => {
    if (this.config.level === LogLevel.Error) {
      return
    }

    const preparedArgs = [
      `[info]${this.config.prefix ?? ''} ${getTimestamp()}:`,
      ...args,
    ]

    console.log(...preparedArgs)
  }

  warn: Logger['warn'] = (...args) => {
    if (this.config.level === LogLevel.Error) {
      return
    }

    const preparedArgs = [
      `[warn]${this.config.prefix ?? ''} ${getTimestamp()}:`,
      ...args,
    ]

    console.warn(...preparedArgs)
  }

  error: Logger['error'] = (...args) => {
    const preparedArgs = [
      `[error]${this.config.prefix ?? ''} ${getTimestamp()}:`,
      ...args,
    ]
    console.error(...preparedArgs)
  }
}
