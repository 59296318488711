import {
  Point2D,
  TimingCurve,
  bezierInterpolation,
  bezierSpeedInterpolation,
  timingCurve,
} from '@aninix-inc/model/legacy'
import * as R from 'ramda'
import * as React from 'react'

import { useMouseMove } from '../../../../../hooks'
import * as styles from './styles.scss'

const PADDING_X = 6
const PADDING_Y = 24
const GRAPH_DETAILS = 40

function lerpRange(
  value: number,
  inMin: number,
  inMax: number,
  outMin: number,
  outMax: number
): number {
  const t = (value - inMin) / (inMax - inMin)
  return (outMax - outMin) * t + outMin
}

function getValueGraphPreview(
  timingCurve: TimingCurve,
  bounds: { lower: Point2D; upper: Point2D }
): Point2D[] {
  const range = R.range(0, Math.round(GRAPH_DETAILS) + 1).map(
    (idx) => idx / GRAPH_DETAILS
  )

  const previewPoints = range.map((progress) => {
    const result = bezierInterpolation({
      t: progress,
      left: bounds.lower,
      leftTangent: timingCurve.out,
      rightTangent: timingCurve.in,
      right: bounds.upper,
    })

    return result
  })

  return previewPoints
}

function getSpeedGraphPreview(
  timingCurve: TimingCurve,
  bounds: { lower: Point2D; upper: Point2D }
): Point2D[] {
  const range = R.range(0, Math.round(GRAPH_DETAILS) + 1).map(
    (idx) => idx / GRAPH_DETAILS
  )

  const previewPoints = range.map((progress) => {
    const result = bezierSpeedInterpolation({
      t: progress,
      left: bounds.lower,
      leftTangent: timingCurve.out,
      rightTangent: timingCurve.in,
      right: bounds.upper,
    })

    return {
      x: progress,
      y: result.y,
    }
  })

  // const xMin = previewPoints
  //   .map((point) => point.x)
  //   .reduce((acc, x) => Math.min(acc, x), Infinity)
  // const xMax = previewPoints
  //   .map((point) => point.x)
  //   .reduce((acc, x) => Math.max(acc, x), -Infinity)
  const yMin = previewPoints
    .map((point) => point.y)
    .reduce((acc, y) => Math.min(acc, y), Infinity)
  const yMax = previewPoints
    .map((point) => point.y)
    .reduce((acc, y) => Math.max(acc, y), -Infinity)

  const preparedPoints = previewPoints.map((point) => ({
    x: point.x,
    y: lerpRange(point.y, yMin, yMax, 0, 1),
  }))

  return preparedPoints
}

const noop = () => {}

export interface IProps {
  width: number
  height: number
  value: TimingCurve[]
  disableEditing?: boolean
  onChange: (value: TimingCurve) => void
  onDragStart?: () => void
  onDragEnd?: () => void
  showSpeed?: boolean
  color?: string
}

export const TimingGraph: React.FCC<IProps> = ({
  width,
  height,
  value,
  disableEditing = false,
  onChange,
  onDragStart = noop,
  onDragEnd = noop,
  showSpeed = false,
  color = styles.highlight,
}) => {
  const containerRef = React.useRef<any>()
  const minY = React.useMemo(
    () => value.reduce((acc, point) => R.min(acc, point.out.y), 0),
    [value]
  )
  const maxY = React.useMemo(
    () => value.reduce((acc, point) => R.max(acc, point.in.y), 1),
    [value]
  )

  const draggingHandlerTypeRef = React.useRef<'in' | 'out'>('in')
  const activeLineBuffer = React.useRef<{
    valueOut: Point2D
    valueIn: Point2D
  }>({
    valueOut: { x: 0, y: 0 },
    valueIn: { x: 0, y: 0 },
  })
  const activeLineBufferInPercents = React.useRef<{
    valueOut: Point2D
    valueIn: Point2D
  }>({
    valueOut: { x: 0, y: 0 },
    valueIn: { x: 0, y: 0 },
  })
  const {
    endAtX,
    endAtY,
    isListening,
    wasTriggered,
    shiftPressed,
    cmdPressed,
    startListen,
  } = useMouseMove({
    threshold: 2,
    element: containerRef.current,
    delay: 8.3,
    onStart: onDragStart,
    onFinish: onDragEnd,
  })

  const lower: Point2D = React.useMemo(
    () => ({
      x: 0,
      y: 0,
    }),
    [minY]
  )

  const upper: Point2D = React.useMemo(
    () => ({
      x: 1,
      y: 1,
    }),
    [maxY]
  )

  const coefficient = React.useMemo(() => 1 / (maxY - minY), [maxY, minY])

  const anchor = React.useMemo(() => 0.5, [minY, maxY])

  const scalePoint = React.useCallback(
    (number: number) => {
      const fromCenter = number - anchor
      const scaledFromCenter = fromCenter * coefficient
      const normal = anchor - scaledFromCenter
      return normal
    },
    [coefficient, anchor]
  )

  const downscalePoint = React.useCallback(
    (number: number) => {
      const fromCenter = number - anchor
      const scaledFromCenter = fromCenter * (1 / coefficient)
      const normal = anchor - scaledFromCenter
      return normal
    },
    [coefficient, anchor]
  )

  const getPositionFromPoint = React.useCallback(
    (point: Point2D) => {
      const progression = scalePoint(point.y)

      return {
        x: PADDING_X + point.x * (width - PADDING_X * 2),
        y: PADDING_Y + progression * (height - PADDING_Y * 2),
      }
    },
    [width, height, scalePoint, PADDING_Y]
  )

  const getPointFromPosition = React.useCallback(
    (position: Point2D) => ({
      x: (position.x - PADDING_X) / (width - PADDING_X * 2),
      y: downscalePoint((position.y - PADDING_Y) / (height - PADDING_Y * 2)),
    }),
    [width, height, downscalePoint, PADDING_Y]
  )

  const horizontalLines = React.useMemo(() => [0.25, 0.5, 0.75], [])
  const verticalLines = React.useMemo(() => [0.25, 0.5, 0.75], [])

  const start = React.useMemo(
    () => getPositionFromPoint(lower),
    [getPositionFromPoint]
  )

  const end = React.useMemo(
    () => getPositionFromPoint(upper),
    [getPositionFromPoint]
  )

  const linesToRender = React.useMemo(
    () =>
      value.map((line) => {
        const valueOut = getPositionFromPoint(line.out)
        const valueIn = getPositionFromPoint(line.in)

        return {
          valueOut,
          valueIn,
        }
      }),
    [value, getPositionFromPoint]
  )

  const valuePreviews = React.useMemo(
    () => value.map((item) => getValueGraphPreview(item, { lower, upper })),
    [value, lower, upper]
  )
  const speedPreviews = React.useMemo(() => {
    if (showSpeed === false) {
      return []
    }

    return value.map((item) => getSpeedGraphPreview(item, { lower, upper }))
  }, [value, lower, upper, showSpeed])

  const pointsOut = React.useMemo(
    () => [
      start.x,
      start.y,
      linesToRender[0].valueOut.x,
      linesToRender[0].valueOut.y,
    ],
    [start, linesToRender]
  )

  const pointsIn = React.useMemo(
    () => [
      end.x,
      end.y,
      linesToRender[0].valueIn.x,
      linesToRender[0].valueIn.y,
    ],
    [end, linesToRender]
  )

  const updateLines = React.useCallback(
    ({ outPoint, inPoint }: { outPoint: Point2D; inPoint: Point2D }) => {
      onChange(
        timingCurve.create({
          out: outPoint,
          in: inPoint,
        })
      )
    },
    [onChange, linesToRender]
  )

  const handleStartListen = React.useCallback(
    (e: any, type: 'in' | 'out') => {
      draggingHandlerTypeRef.current = type
      // @ts-ignore
      startListen(e)

      activeLineBuffer.current = linesToRender[0]
      activeLineBufferInPercents.current = {
        valueOut: getPointFromPosition(linesToRender[0].valueOut),
        valueIn: getPointFromPosition(linesToRender[0].valueIn),
      }
    },
    [linesToRender]
  )

  React.useEffect(() => {
    if (isListening === false || wasTriggered === false) {
      return
    }

    const boundingBox = containerRef.current.getBoundingClientRect()
    const x = R.clamp(PADDING_X, boundingBox.width - PADDING_X, endAtX)
    const y = R.clamp(
      -boundingBox.height * 0.1,
      boundingBox.height * 1.1,
      endAtY
    )

    // @NOTE: needed to handle vertical and horizontal stickness when shift pressed
    const finalValue = (() => {
      const pointToCompare =
        draggingHandlerTypeRef.current === 'out'
          ? activeLineBuffer.current.valueOut
          : activeLineBuffer.current.valueIn

      if (shiftPressed) {
        const xDelta = Math.abs(x - pointToCompare.x)
        const yDelta = Math.abs(y - pointToCompare.y)

        if (xDelta > yDelta) {
          return {
            x,
            y: pointToCompare.y,
          }
        }

        return {
          x: pointToCompare.x,
          y,
        }
      }

      return { x, y }
    })()

    // @NOTE: required to handle mirrored changes
    // @TODO: enable once it stable
    // if (cmdPressed) {
    //   const point = getPointFromPosition(finalValue)

    //   if (draggingHandlerTypeRef.current === 'out') {
    //     updateLines({
    //       outPoint: point,
    //       inPoint: {
    //         x: 1 - point.x,
    //         y: 1 - point.y,
    //       },
    //     })
    //     return
    //   }

    //   updateLines({
    //     outPoint: {
    //       x: 1 - point.x,
    //       y: 1 - point.y,
    //     },
    //     inPoint: point,
    //   })

    //   return
    // }

    if (draggingHandlerTypeRef.current === 'out') {
      updateLines({
        outPoint: getPointFromPosition(finalValue),
        inPoint: activeLineBufferInPercents.current.valueIn,
      })
      return
    }

    updateLines({
      outPoint: activeLineBufferInPercents.current.valueOut,
      inPoint: getPointFromPosition(finalValue),
    })

    return
  }, [
    isListening,
    wasTriggered,
    endAtX,
    endAtY,
    shiftPressed,
    cmdPressed,
    getPointFromPosition,
  ])

  const tangent = React.useCallback(
    (type: 'in' | 'out') => {
      if (type === 'out') {
        return (
          <polyline
            strokeWidth={1}
            stroke={color}
            opacity={0.4}
            points={pointsOut.join(',')}
          />
        )
      }

      return (
        <polyline
          strokeWidth={1}
          stroke={color}
          opacity={0.4}
          points={pointsIn.join(',')}
        />
      )
    },
    [pointsIn, pointsOut]
  )

  const handler = React.useCallback(
    (type: 'in' | 'out') => (
      <circle
        cx={
          type === 'out'
            ? linesToRender[0].valueOut.x
            : linesToRender[0].valueIn.x
        }
        cy={
          type === 'out'
            ? linesToRender[0].valueOut.y
            : linesToRender[0].valueIn.y
        }
        r={4}
        fill={color}
        onMouseDown={(e) => handleStartListen(e, type)}
      />
    ),
    [start, end, linesToRender, handleStartListen]
  )

  const keyframe = React.useCallback(
    (type: 'in' | 'out') => {
      const position =
        type === 'out'
          ? getPositionFromPoint({ x: 1, y: 1 })
          : getPositionFromPoint({ x: 0, y: 0 })

      return (
        <rect
          width={8}
          height={8}
          style={{
            transform: `translate(${position.x - 4}px, ${
              position.y - 4
            }px) rotate(45deg)`,
            transformOrigin: '4px 4px',
          }}
          stroke={styles.value}
          strokeWidth={2}
          fill={styles.bg}
          rx={2}
        />
      )
    },
    [getPositionFromPoint, disableEditing]
  )

  return (
    <svg
      ref={containerRef}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      xmlns="http://www.w3.org/2000/svg"
      style={{
        userSelect: 'none',
      }}
    >
      <rect
        x={PADDING_X}
        y={PADDING_Y + ((height - PADDING_Y * 2) * (1 - coefficient)) / 2}
        width={width - PADDING_X * 2}
        height={(height - PADDING_Y * 2) * coefficient}
        fill={styles.rect}
        fillOpacity={0.5}
      />

      {horizontalLines.map((line, idx) => (
        <line
          key={idx}
          strokeWidth={1}
          stroke={styles.lines}
          strokeOpacity={0.7}
          x1={getPositionFromPoint({ x: 0, y: line }).x}
          y1={getPositionFromPoint({ x: 0, y: line }).y}
          x2={getPositionFromPoint({ x: 1, y: line }).x}
          y2={getPositionFromPoint({ x: 1, y: line }).y}
        />
      ))}

      {verticalLines.map((line, idx) => (
        <line
          key={idx}
          strokeWidth={1}
          stroke={styles.lines}
          strokeOpacity={0.7}
          x1={getPositionFromPoint({ x: line, y: minY - 1 }).x}
          y1={getPositionFromPoint({ x: line, y: minY - 1 }).y}
          x2={getPositionFromPoint({ x: line, y: maxY + 1 }).x}
          y2={getPositionFromPoint({ x: line, y: maxY + 1 }).y}
        />
      ))}

      {speedPreviews.map((preview, idx) => (
        <polyline
          key={idx}
          stroke={styles.speed}
          strokeWidth={2}
          fill="none"
          points={R.flatten(
            preview.map(getPositionFromPoint).map((point) => [point.x, point.y])
          ).join(',')}
        />
      ))}

      {valuePreviews.map((preview, idx) => (
        <polyline
          key={idx}
          stroke={disableEditing ? styles.valueDisabled : styles.value}
          strokeWidth={disableEditing ? 2 : 3}
          fill="none"
          points={R.flatten(
            preview.map(getPositionFromPoint).map((point) => [point.x, point.y])
          ).join(',')}
        />
      ))}

      {!disableEditing && (
        <>
          {tangent('out')}
          {tangent('in')}
        </>
      )}

      {!disableEditing && (
        <>
          {keyframe('out')}
          {keyframe('in')}
        </>
      )}

      {!disableEditing && (
        <>
          {handler('out')}
          {handler('in')}
        </>
      )}
    </svg>
  )
}
