import React from 'react'

const defaultSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const KeyForward: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M3.58582 6.00034L10.5858 6.00034M10.5858 6.00034L8.58621 4.00074M10.5858 6.00034L8.58582 7.99991M7.58582 8.99991L6.29292 10.2928C5.9024 10.6833 5.26923 10.6833 4.87871 10.2928L1.29292 6.70701C0.902398 6.31649 0.902398 5.68332 1.29292 5.2928L4.87871 1.70701C5.26924 1.31649 5.9024 1.31649 6.29292 1.70701L7.58582 2.99991"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
