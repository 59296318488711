import classNames from 'classnames'
import * as React from 'react'

export type Props = {
  src: string
}
export const ImagePreview: React.FC<Props> = ({ src }) => {
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [isError, setIsError] = React.useState(false)

  return (
    <div
      className={classNames(
        'w-5 h-5 relative rounded overflow-hidden bg-gray-200 border-[1px] border-gray-300 transition-opacity duration-100 flex-shrink-0',
        {
          ['opacity-0']: !isLoaded && !isError,
        }
      )}
    >
      <img
        onLoad={() => setIsLoaded(true)}
        onError={() => setIsError(true)}
        className={classNames(
          'absolute inset-0 transition-opacity duration-100',
          {
            ['opacity-0']: !isLoaded,
          }
        )}
        crossOrigin="anonymous"
        src={src}
      />
    </div>
  )
}
