import { mixed } from '@aninix-inc/model/legacy'

export function getNumberInputValue({
  value,
  format,
  isFocused,
}: {
  value: typeof mixed | number
  format: (value: number) => string
  isFocused: boolean
}) {
  const previewValue = value === mixed ? mixed : format(value)
  const editingValue = value === mixed ? '' : `${value}`

  return isFocused ? editingValue : previewValue
}
