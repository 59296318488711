import { buttons, icons } from '@aninix/app-design-system'
import { config } from '@aninix/editor/config'
import classNames from 'classnames'
import * as React from 'react'

const size = {
  width: 48,
  height: 48,
}

export const CopyLink: React.FCC<{ projectId: string }> = ({ projectId }) => {
  const [isLinkCopied, setIsLinkCopied] = React.useState(false)

  const copyLink = React.useCallback(async () => {
    await navigator.clipboard.writeText(
      `${config.webAppUrl}/inspect/${projectId}/details`
    )
    setIsLinkCopied(true)
    setTimeout(() => {
      setIsLinkCopied(false)
    }, 2000)
  }, [])

  return (
    <buttons.Icon
      tooltip={isLinkCopied ? 'Copied' : 'Copy link'}
      onClick={copyLink}
      btnSize={size}
      contrast
    >
      <div className="w-[48px] h-[48px] relative ">
        <div
          className={classNames(
            'absolute w-4 h-4 z-10 origin-center inset-0 left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-[opacity,transform] duration-150',
            {
              ['opacity-100']: isLinkCopied,
              ['opacity-0 -rotate-90']: !isLinkCopied,
            }
          )}
        >
          <icons.Check
            style={{
              // @ts-ignore
              '--figma-color-text': '#FFFFFF',
            }}
          />
        </div>
        <div
          className={classNames(
            'absolute z-0 origin-center left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transition-[opacity,transform] duration-150',
            {
              ['opacity-100']: !isLinkCopied,
              ['opacity-0 -rotate-90']: isLinkCopied,
            }
          )}
        >
          <ShareLink />
        </div>
      </div>
    </buttons.Icon>
  )
}

const ShareLink = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4999 9.49999L9.49989 14.5M11.0002 6.5L11.7502 5.75C13.5451 3.95508 16.4553 3.95508 18.2502 5.75C20.0451 7.54493 20.0451 10.4551 18.2502 12.25L17.5002 13M13.0022 17.5022L12.2501 18.2535C10.4549 20.0468 7.54609 20.046 5.75188 18.2517C3.95634 16.4561 3.95716 13.5447 5.7537 11.7501L6.50243 11.0022"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
