import classnames from 'classnames'
import * as React from 'react'

import * as styles from './view-tabs.scss'

export interface IProps {
  tabs: string[]
  activeTab: string
  onUpdateTab: (tab: string) => void
}
export const ViewTabs: React.FCC<IProps> = ({
  tabs,
  activeTab,
  onUpdateTab,
}) => (
  <div className={styles.container}>
    {tabs.map((tab) => (
      <button
        key={tab}
        onClick={() => onUpdateTab(tab)}
        className={classnames(styles.tab, {
          [styles['tab--highlighted']]: tab === activeTab,
        })}
      >
        {tab}
      </button>
    ))}
  </div>
)
