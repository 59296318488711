import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const CornerRadius: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21 13H17C14.7909 13 13 14.7909 13 17V21H12V17C12 14.2386 14.2386 12 17 12H21V13Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
