import { FpsComponent, Root } from '@aninix-inc/model'
import { mixed } from '@aninix-inc/model/legacy'
import { useProject, useSettings } from '@aninix/core'
import { roundTime, toFormat, toSeconds } from '../../../helpers/round-time'

export function useFormatTime(): {
  toFormat: (value: number) => number
  roundTime: (value: number) => number
  toSeconds: (formattedValue: number) => number
} {
  const user = useSettings()
  const project = useProject()
  const root = project.getEntityByTypeOrThrow(Root)
  const fps = root.getComponentOrThrow(FpsComponent).value
  const timeFormat = user.timeFormat

  return {
    toFormat: (value) => toFormat({ time: value, timeFormat, fps }).value,
    roundTime: (value) =>
      roundTime({
        time: value,
        fps,
        timeFormat,
      }),
    toSeconds: (value) =>
      toSeconds({
        time: value,
        fps,
        timeFormat,
      }),
  }
}

export function useFormattedTime<T extends number | typeof mixed>(
  time: T
): {
  value: T
  suffix: string
  formatted: string
} {
  const user = useSettings()
  const project = useProject()
  const root = project.getEntityByTypeOrThrow(Root)
  const fps = root.getComponentOrThrow(FpsComponent).value
  const timeFormat = user.timeFormat

  if (time === mixed) {
    return {
      value: mixed,
      suffix: '',
      formatted: mixed,
    } as any
  }

  return toFormat({ time, timeFormat, fps }) as any
}
