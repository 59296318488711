import { useMutation } from '@tanstack/react-query'

import {
  ApiPatchParams,
  ApiPatchRequestBody,
  ApiPatchResponse,
} from './helpers'
import { httpClient } from './http-client'

export function useSetFolderName() {
  return useMutation({
    async mutationFn(
      payload: (ApiPatchParams<'/folders/{folderId}'>['path'] &
        Omit<ApiPatchRequestBody<'/folders/{folderId}'>, 'parentFolderId'>) & {
        parentFolderId?: string | undefined | null
      }
    ) {
      return httpClient.patch<ApiPatchResponse<'/folders/{folderId}'>>(
        `/folders/${payload.folderId}`,
        { name: payload.name }
      )
    },
  })
}
