import { round } from '@aninix-inc/model/legacy'

interface IPayload {
  frames: number
  fps: number
}
interface IOutput {
  seconds: number
}
export function convertFramesToSeconds({ frames, fps }: IPayload): IOutput {
  return {
    seconds: round(frames / fps, { fixed: 4 }),
  }
}
