import {
  Entity,
  EntityType,
  EntityTypeComponent,
  getEntryOrThrow,
  NodeType,
  Project,
} from '@aninix-inc/model'
import { convertEntityToSnapshot, paper } from '@aninix-inc/renderer'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  ImagesStore,
  useImagesStore,
  usePlayback,
  useSession,
  useViewport,
} from '../../../stores'
import { getSelection } from '../../../updates'
import featureFlags from '@aninix/core/feature-flags'

export interface IProps {
  project: Project
}

const getLengthsFromLine = (line: [paper.Point, paper.Point]) => {
  return {
    position: line[0].add(line[1]).divide(2),
    value: line[0].getDistance(line[1]),
    orientation:
      line[0].x === line[1].x ? ('vertical' as const) : ('horizontal' as const),
  }
}

export const getBoundsFromNodes = (
  nodes: Entity[],
  time: number,
  imagesStore: ImagesStore,
  ignoreRotation: boolean = false
) => {
  if (nodes.length === 0) {
    return undefined
  }

  const nodesSnapshots = nodes.map((entity) =>
    convertEntityToSnapshot({
      entity,
      time,
      imagesStore,
    })
  )

  const group = new paper.Group()

  nodesSnapshots.forEach((snapshot) => {
    const path = new paper.CompoundPath(
      featureFlags.renderText && snapshot.type === NodeType.Text
        ? `M0,0 h${snapshot.size.x} v${snapshot.size.y} h-${snapshot.size.x} Z`
        : snapshot.fillData.map((i) => i.data).join('')
    )
    path.matrix = snapshot.absoluteTransformMatrix

    if (nodesSnapshots.length === 1 && ignoreRotation) {
      path.rotation = -snapshot.rotation
    }

    group.addChild(path)
  })

  return group.bounds
}

export const SvgDistances: React.FCC<IProps> = observer(({ project }) => {
  const session = useSession()
  const images = useImagesStore()
  const playback = usePlayback()
  const viewport = useViewport()
  const root = getEntryOrThrow(project)

  const selectionBounds = React.useMemo(() => {
    return getBoundsFromNodes(
      getSelection(project, EntityType.Node),
      playback.time,
      images
    )
  }, [project, images, playback])

  const bufferBounds = React.useMemo(() => {
    const node =
      project.getFirstWhere(
        (e) =>
          session.buffer === e.id &&
          e.getComponentOrThrow(EntityTypeComponent).value === EntityType.Node
      ) ?? root

    return getBoundsFromNodes([node], playback.time, images)
  }, [images, playback, project, root, session])

  if (selectionBounds === undefined || bufferBounds === undefined) {
    return null
  }

  const topLine: [paper.Point, paper.Point] = [
    selectionBounds.topCenter,
    new paper.Point(
      selectionBounds.center.x,
      selectionBounds.top > bufferBounds.bottom
        ? Math.min(bufferBounds.bottom, selectionBounds.top)
        : Math.min(bufferBounds.top, selectionBounds.top)
    ),
  ]

  const bottomLine: [paper.Point, paper.Point] = [
    selectionBounds.bottomCenter,
    new paper.Point(
      selectionBounds.center.x,
      selectionBounds.bottom < bufferBounds.top
        ? Math.max(bufferBounds.top, selectionBounds.bottom)
        : Math.max(bufferBounds.bottom, selectionBounds.bottom)
    ),
  ]

  const leftLine: [paper.Point, paper.Point] = [
    selectionBounds.leftCenter,
    new paper.Point(
      selectionBounds.left > bufferBounds.right
        ? Math.min(bufferBounds.right, selectionBounds.left)
        : Math.min(bufferBounds.left, selectionBounds.left),
      selectionBounds.center.y
    ),
  ]

  const rightLine: [paper.Point, paper.Point] = [
    selectionBounds.rightCenter,
    new paper.Point(
      selectionBounds.right < bufferBounds.left
        ? Math.max(bufferBounds.left, selectionBounds.right)
        : Math.max(bufferBounds.right, selectionBounds.right),
      selectionBounds.center.y
    ),
  ]

  const topGuideEndX = (() => {
    if (topLine[0].getDistance(topLine[1]) <= 0) return topLine[1].x

    if (selectionBounds.center.x < bufferBounds.left) return bufferBounds.left
    if (selectionBounds.center.x > bufferBounds.right) return bufferBounds.right

    return topLine[1].x
  })()

  const topGuide: [paper.Point, paper.Point] = [
    topLine[1],
    new paper.Point(topGuideEndX, topLine[1].y),
  ]

  const bottomGuideEndX = (() => {
    if (bottomLine[0].getDistance(bottomLine[1]) <= 0) return bottomLine[1].x

    if (selectionBounds.center.x < bufferBounds.left) return bufferBounds.left
    if (selectionBounds.center.x > bufferBounds.right) return bufferBounds.right

    return bottomLine[1].x
  })()

  const bottomGuide: [paper.Point, paper.Point] = [
    bottomLine[1],
    new paper.Point(bottomGuideEndX, bottomLine[1].y),
  ]

  const leftGuideEndY = (() => {
    if (leftLine[0].getDistance(leftLine[1]) <= 0) return leftLine[1].y

    if (selectionBounds.center.y < bufferBounds.top) return bufferBounds.top
    if (selectionBounds.center.y > bufferBounds.bottom)
      return bufferBounds.bottom

    return leftLine[1].y
  })()

  const leftGuide: [paper.Point, paper.Point] = [
    leftLine[1],
    new paper.Point(leftLine[1].x, leftGuideEndY),
  ]

  const rightGuideEndY = (() => {
    if (rightLine[0].getDistance(rightLine[1]) <= 0) return rightLine[1].y

    if (selectionBounds.center.y < bufferBounds.top) return bufferBounds.top
    if (selectionBounds.center.y > bufferBounds.bottom)
      return bufferBounds.bottom

    return rightLine[1].y
  })()

  const rightGuide: [paper.Point, paper.Point] = [
    rightLine[1],
    new paper.Point(rightLine[1].x, rightGuideEndY),
  ]

  const linesToDraw: [paper.Point, paper.Point][] = [
    topLine,
    bottomLine,
    leftLine,
    rightLine,
  ]

  const lengthsToDraw = linesToDraw
    .map(getLengthsFromLine)
    .filter((e) => e.value > 0)

  const guidesToDraw: [paper.Point, paper.Point][] = [
    topGuide,
    bottomGuide,
    leftGuide,
    rightGuide,
  ]

  const boundsToDraw = [bufferBounds, selectionBounds]

  return (
    <g className="pointer-events-none">
      {boundsToDraw.map((rect) => (
        <rect
          key={JSON.stringify(rect)}
          x={rect.x}
          y={rect.y}
          width={rect.width}
          height={rect.height}
          stroke="red"
          strokeWidth={1 / viewport.zoom}
          fillOpacity={0}
        />
      ))}

      {linesToDraw.map((line) => (
        <line
          key={JSON.stringify(line)}
          x1={line[0].x}
          y1={line[0].y}
          x2={line[1].x}
          y2={line[1].y}
          stroke="red"
          strokeWidth={1 / viewport.zoom}
        />
      ))}

      {guidesToDraw.map((line) => (
        <line
          key={JSON.stringify(line)}
          x1={line[0].x}
          y1={line[0].y}
          x2={line[1].x}
          y2={line[1].y}
          stroke="red"
          strokeDasharray={[5, 2].map((v) => v / viewport.zoom).join(' ')}
          strokeDashoffset={2 / viewport.zoom}
          strokeWidth={1 / viewport.zoom}
        />
      ))}

      {lengthsToDraw.map((length) => (
        <foreignObject
          className="overflow-visible"
          textAnchor={'middle'}
          dominantBaseline={'middle'}
          key={JSON.stringify(length)}
          width={1}
          height={1}
          x={length.position.x}
          y={length.position.y}
        >
          <div
            style={{
              transform: `scale(${1 / viewport.zoom}) translate(${
                length.orientation === 'horizontal' ? '-50%' : '4px'
              }, ${length.orientation === 'vertical' ? '-50%' : '4px'})`,
              transformOrigin: 'top left',
              padding: '2px 4px',
            }}
            className="w-fit rounded-sm bg-red text-[10px] text-white"
          >
            <p>{parseFloat(length.value.toFixed(2))}</p>
          </div>
        </foreignObject>
      ))}
    </g>
  )
})

SvgDistances.displayName = 'SvgDistances'
