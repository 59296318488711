import classNames from 'classnames'
import { AnimationItem } from 'lottie-web'
import * as React from 'react'

const buttonSize = { width: 40, height: 40 }
const playbackSpeeds = [0.5, 1, 2]
interface IPlaybackSpeedItem {
  factor: number
  handleClick: (e: React.PointerEvent<HTMLButtonElement>) => void
}

export interface IProps {
  animationItem: AnimationItem
}

export const TimeSpeedPicker: React.FCC<IProps> = ({ animationItem }) => {
  const [mouseInGroup, setMouseInGroup] = React.useState<boolean>(false)
  const [speedOffset, setSpeedOffset] = React.useState<number>(1)

  const onMouseEnter = React.useCallback(() => {
    setMouseInGroup(true)
  }, [])
  const onMouseLeave = React.useCallback(() => {
    setMouseInGroup(false)
  }, [])

  React.useEffect(() => {
    //to reset the speed to default when animationItem changes
    setSpeedOffset(1)
  }, [animationItem])

  const playbackSpeedItems: IPlaybackSpeedItem[] = React.useMemo(() => {
    return playbackSpeeds.map((factor: number, index: number) => {
      return {
        factor: factor,
        handleClick: (e) => {
          if (!mouseInGroup) {
            setMouseInGroup(true)
            return
          }
          e.stopPropagation()
          animationItem.setSpeed(factor)
          setSpeedOffset(index)
          setMouseInGroup(false)
        },
      }
    })
  }, [animationItem, mouseInGroup])

  const icon = React.useCallback(
    (speed: number) => {
      switch (speed) {
        case 0.5:
          return pointFiveSpeedIcon
        case 1:
          return oneSpeedIcon
        case 2:
          return twoSpeedIcon
        default:
          throw Error('speed offset has not expected value')
      }
    },
    [speedOffset]
  )

  return (
    <div
      className={classNames(
        'abosolute select-none  transition-all duration-300',
        {
          [' border-opacity-0']: mouseInGroup,
          [' border-opacity-100']: !mouseInGroup,
        }
      )}
    >
      <div
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        className={classNames(
          'abosolute flex flex-col overflow-hidden rounded',
          {
            ['overflow-auto bg-white shadow-md']: mouseInGroup,
          }
        )}
        style={{
          transition: 'all 100ms ease-in-out',
          maxHeight: mouseInGroup ? `${playbackSpeeds.length * 40}px` : `40px`,
        }}
      >
        {playbackSpeedItems.map((item: IPlaybackSpeedItem, index: number) => {
          return (
            <div
              key={item.factor}
              style={{
                transition: 'all 100ms ease-in-out',
                transform: mouseInGroup
                  ? 'translateY(0px)'
                  : `translateY(-${40 * speedOffset}px)`,
                backgroundColor:
                  speedOffset == index && mouseInGroup
                    ? 'rgba(0, 0, 0, 0.07)'
                    : 'transparent',
              }}
            >
              <button
                className={classNames('flex items-center justify-center', {
                  ['hover:bg-gray-100']: speedOffset !== index,
                })}
                onPointerDown={item.handleClick}
                style={{ width: buttonSize.width, height: buttonSize.height }}
              >
                {/* <p className="w-full text-xs text-black">{`${item.factor}x`}</p> */}
                {icon(item.factor)()}
              </button>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const pointFiveSpeedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 12.01C4 12.01 4.5 12.01 6 12C6.70583 11.9953 7.03914 11.7893 7.41421 11.4142C7.78929 11.0391 8 10.5304 8 10C8 9.46957 7.78929 8.96086 7.41421 8.58579C7.03914 8.21071 6.53043 8 6 8H4V4H7.5M1 12V12.01M11 10L15 6M15 10L11 6"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const oneSpeedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 12V4L3 6M9 10L13 6M13 10L9 6"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const twoSpeedIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 10L14 6M14 10L10 6M2 6C2 5.46957 2.21071 4.96086 2.58579 4.58579C2.96086 4.21071 3.46957 4 4 4C4.53043 4 5.03914 4.21071 5.41421 4.58579C5.78929 4.96086 6 5.46957 6 6C6 6.591 5.583 7.318 5.184 7.858L2 12.001H6"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

TimeSpeedPicker.displayName = 'TimeSpeedPicker'
