import React from 'react'

import { Link } from '../types'
import * as styles from './index.scss'

const LinkComponent: React.FCC<{ link: Link }> = ({ link }) => {
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      if (link.onClick != null) {
        e.preventDefault()
        link.onClick()
      }
    },
    [link]
  )

  return (
    <a href={link.url} onClick={handleClick}>
      {link.title}
      <span>{link.secondary}</span>
    </a>
  )
}

interface IProps {
  groups: Link[][]
}

export const PopupList: React.FCC<IProps> = ({ groups }) => (
  <span>
    <ul className={styles.ul}>
      {groups.map((links, idx) => (
        <React.Fragment key={idx}>
          {links.map((link) => (
            <li key={link.title}>
              <LinkComponent link={link} />
            </li>
          ))}

          {idx !== groups.length - 1 && <hr />}
        </React.Fragment>
      ))}
    </ul>

    <div className={styles.romb} />
  </span>
)
