import { Entity } from '@aninix-inc/model'
import { INode } from 'svgson'
import { Assets } from '../../types'
import { applyFill } from './apply-fill'
import { applyFilters } from './apply-filters'
import { applyName } from './apply-name'
import { applyOpacity } from './apply-opacity'
import { applyPosition } from './apply-position'
import { applySize } from './apply-size'
import { applyStroke } from './apply-stroke'
import { applyStyle } from './apply-style'
import { applyTransform } from './apply-transform'

export const applyCommon = (entity: Entity, node: INode, assets: Assets) => {
  applyFill(entity, node, assets.gradients)
  applyStroke(entity, node, assets.gradients)
  applyOpacity(entity, node)
  applyStyle(entity, node)
  applyTransform(entity, node)
  applyFilters(entity, node, assets.filters)
  applySize(entity, node)
  applyPosition(entity, node)
  applyName(entity)
}
