import { mixed } from '@aninix-inc/model/legacy'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import classnames from 'classnames'
import * as React from 'react'

import { defaultFigmaLikeFormat } from '@aninix/app-design-system/utils/figma-like-number-format'
import { Icon as IconButton } from '../buttons'
import { getKeysFromKeyboardEvent } from '../input-with-icon-v2/utils/get-keys-from-keyboard-event'
import { getNumberInputValue } from '../input-with-icon-v2/utils/get-number-input-value'
import { getValueAfterKeyPress } from '../input-with-icon-v2/utils/get-value-after-key-press'
import { getValueFromInput } from '../input-with-icon-v2/utils/get-value-from-input'
import * as styles from './index.scss'

export interface IProps {
  id: string
  value: number | typeof mixed
  /**
   * @description click on icon
   */
  onClick?: () => void
  onChange?: (value?: number) => void
  disabled?: boolean
  icon?: React.ReactNode
  format?: (value: number) => string
  width?: number
  threshold?: number
  /**
   * @description when true then current value is undefined and input is empty.
   * This is dirty hack. We should find better approach.
   */
  isNull?: boolean
  placeholder?: string
}

const noop = () => {}

export const Input: React.FCC<IProps> = ({
  id,
  value,
  onClick = noop,
  onChange = noop,
  disabled = false,
  icon,
  format = defaultFigmaLikeFormat,
  width = 72,
  threshold = 1,
  isNull = false,
  placeholder,
}) => {
  const inputWrapperRef = React.createRef<HTMLInputElement>()
  const inputRef = React.createRef<HTMLInputElement>()

  const [isFocused, setIsFocused] = React.useState(false)

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      isKeyDown,
      isKeyUp,
      isShiftPressed,
      isCtrlPressed,
      isEnter,
      isEsc,
    } = getKeysFromKeyboardEvent(e)

    if (value !== mixed && (isKeyDown || isKeyUp)) {
      e.preventDefault()
      e.stopPropagation()

      const valueToApply = getValueAfterKeyPress({
        threshold,
        isShiftPressed,
        isCtrlPressed,
        value,
        isPositive: isKeyUp,
      })

      onChange(valueToApply)
    }

    if (isEnter || isEsc) {
      inputRef.current?.blur()
    }
  }

  // const handleChange: React.ChangeEventHandler<
  //   HTMLInputElement | HTMLTextAreaElement
  // > = (e) => {}

  const handleFocus = () => {
    setIsFocused(true)
  }
  const handleBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setIsFocused(false)

    if (e.currentTarget.value === inputValue) {
      return
    }

    if (e.currentTarget.value === '') {
      onChange(undefined)
      return
    }

    const newValue = getValueFromInput({
      formatBeforeApply: (value: any) => value,
      inputValue: e.currentTarget.value,
      initialValue: value,
      min: -Infinity,
      max: +Infinity,
    })

    if (typeof newValue !== 'number' || isNaN(newValue)) {
      return
    }

    onChange(newValue)
  }

  const inputValue = getNumberInputValue({ value, format, isFocused })

  React.useEffect(() => {
    if (inputRef.current == null) {
      return
    }

    if (isNull) {
      inputRef.current.value = ''
      return
    }

    inputRef.current.value = inputValue

    if (isFocused) {
      inputRef.current?.select()
    }
  }, [value, isFocused, isNull])

  return (
    <OutlinedInput
      ref={inputWrapperRef}
      inputRef={inputRef}
      classes={{
        root: classnames(styles['input-root'], {
          [styles.focused]: isFocused,
        }),
        notchedOutline: styles['outline-notched'],
        input: classnames(styles.input, {
          [styles['input--mixed']]: isFocused === false && value === mixed,
          [styles['input--no-icon']]: icon == null,
        }),
        focused: styles.focused,
        disabled: styles.disabled,
      }}
      style={{ width }}
      id={id}
      type="text"
      autoComplete="off"
      defaultValue={isNull ? undefined : inputValue}
      onKeyDown={handleKeyPress}
      // onChange={handleChange}
      onFocus={handleFocus}
      onBlur={handleBlur}
      startAdornment={
        icon != null && (
          <IconButton onClick={onClick} className={styles.button}>
            {icon}
          </IconButton>
        )
      }
      disabled={disabled}
      placeholder={placeholder}
    />
  )
}

Input.displayName = 'InputWithButton'
