import { LocalStorageIo } from '@aninix/api'
import { TimeFormat } from '@aninix/core'

type UserSettings = {
  timeFormat: TimeFormat
  timelineHeight: number
  timelineInfoWidth: number
  legacyExport: boolean
  gifInfiniteLoop: boolean
}
export const userSettingsLocalStorage = new LocalStorageIo<UserSettings>(
  'aninix.user-settings',
  {
    timeFormat: TimeFormat.Seconds,
    timelineHeight: 200,
    timelineInfoWidth: 400,
    legacyExport: false,
    gifInfiniteLoop: true,
  }
)
