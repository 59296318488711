import { useMutation } from '@tanstack/react-query'

import { ApiDeleteParams, ApiDeleteResponse } from './helpers'
import { httpClient } from './http-client'

export function useRemoveUserFromTeam() {
  return useMutation({
    async mutationFn(
      payload: ApiDeleteParams<'/teams/{teamId}/members/{userId}'>['path']
    ) {
      return httpClient.delete<
        ApiDeleteResponse<'/teams/{teamId}/members/{userId}'>
      >(`/teams/${payload.teamId}/members/${payload.userId}`)
    },
  })
}
