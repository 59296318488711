import * as React from 'react'

import { useCurrentUser } from '../../../use-cases'
import { useCurrentRowContext } from '../use-current-row-context'

export interface IProps {}
export const Info: React.FCC = ({}) => {
  const { email, name, id } = useCurrentRowContext()

  const { userId } = useCurrentUser()

  const isYourUser = userId == id

  return (
    <div className="flex flex-col gap-0">
      {name ? (
        <>
          <div className="flex flex-row items-center gap-2">
            <p className="h-6 font-body text-base font-medium ">{name}</p>
            {isYourUser && (
              <p className="h-6 font-body text-base font-normal ">{'(You)'}</p>
            )}
          </div>
          <p className="h-5 font-body text-sm font-normal ">{email}</p>
        </>
      ) : (
        <div className="flex flex-row items-center gap-2">
          <p className="h-6 font-body text-base font-medium ">{email}</p>
          {isYourUser && (
            <p className="h-6 font-body text-base font-normal text-gray-500">
              {'(You)'}
            </p>
          )}
        </div>
      )}
    </div>
  )
}

Info.displayName = 'Info'
