import { Project } from '@aninix-inc/model'
import * as React from 'react'
import { PlaybackButton } from '../../../details/timeline/controls/playback/button'
import { CurrentTime } from './current-time'
import { ProgressBar } from './progress-bar'
import { TotalTime } from './total-time'

export interface IProps {
  project: Project
}
export const Playback: React.FCC<IProps> = ({ project }) => {
  return (
    <div
      className={`playback flex flex-1 flex-row items-center justify-start gap-1 !fill-black px-[5px]`}
    >
      <PlaybackButton className="h-8 w-8 fill-black hover:cursor-pointer" />
      <CurrentTime project={project} />
      <ProgressBar />
      <TotalTime />
    </div>
  )
}

Playback.displayName = 'Playback'
