import * as React from 'react'

import { MaintenanceLightIcon } from './icon-light'

export interface IProps {
  message?: string
}
export const Maintenance: React.FCC<IProps> = ({ message }) => (
  <div className="mx-auto flex h-screen w-screen max-w-xl flex-col items-center justify-center text-center text-black/80">
    <MaintenanceLightIcon className="w-[258px]" />

    <p className="mt-6 text-2xl font-semibold">Aninix will be back soon</p>

    <p className="mt-2 text-base">
      {message ?? 'We’re updating the database. Thank you for staying with us.'}
    </p>

    <a href="/" className="mt-1 block text-base text-[#18A0FB]">
      I’m feeling lucky
    </a>
  </div>
)

Maintenance.displayName = 'Maintenance'
