import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

import { Loader } from '@aninix/app-design-system/components/common/loader'
import { useSessionStore } from '../../stores'
import { useAuthenticateUseCase, useCurrentSpaceStore } from '../../use-cases'
import { SelectUser } from '../select-user'
import { useLoginInteractor } from './interactor'
import { LoginRouter } from './router'

// @TODO: refactor auth states. Currently we have very unintuitive components set.
// @TODO: also improve design and flow of this module. This is prove of concept.
export interface IProps {
  addAccount?: boolean
}
export const Login: React.FCC<IProps> = observer(({ addAccount = false }) => {
  const [searchParams] = useSearchParams()
  const sessionStore = useSessionStore()

  const users = useCurrentSpaceStore().badge?.data?.infoFromTokens.users

  const isLoading = useCurrentSpaceStore().badge?.isLoading

  const authenticate = useAuthenticateUseCase({ sessionStore })

  const interactor = useLoginInteractor({
    addAccount,
    authenticate,
    searchParams,
  })

  React.useEffect(() => {
    if (addAccount) interactor.login()
  }, [addAccount])

  if (isLoading)
    return (
      <div className="flex h-screen w-screen flex-row items-center justify-center">
        <Loader />
      </div>
    )

  if (!addAccount && (users?.length ?? 0 > 0))
    return <SelectUser onAddAccount={interactor.login} />

  return <LoginRouter interactor={interactor} />
})

Login.displayName = 'Login'
