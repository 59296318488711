import { Point2D } from '@aninix-inc/model/legacy'
import { NodeSnapshot } from '@aninix/core/modules/common/renderers/types'
import { isPointOnPath } from './is-point-on-path'

export function getItemsUnderCursor({
  nodes,
  cursorPositionRef,
  highlightedId,
  skipGroupIds,
  isCmdPressed,
}: {
  nodes: NodeSnapshot[]
  cursorPositionRef: React.MutableRefObject<Point2D>
  highlightedId?: string
  skipGroupIds: string[]
  isCmdPressed: boolean
}) {
  var returnHighlightedId = highlightedId ?? ''

  //@NOTE: possible place to optimization, use reversed for iteration
  nodes.forEach((node) => {
    const skipGroups = isCmdPressed || skipGroupIds.includes(node.id)

    if (
      isPointOnPath({
        nodeSnapshot: node,
        point: cursorPositionRef.current,
        path: node.fillData.map((e) => e.data).join(''),
        fills: node.fills,
        strokes: node.strokes,
        skipGroups: skipGroups,
      })
    ) {
      returnHighlightedId = node.id
    }

    if (skipGroups)
      returnHighlightedId = getItemsUnderCursor({
        nodes: node.children,
        cursorPositionRef,
        highlightedId: returnHighlightedId,
        skipGroupIds,
        isCmdPressed,
      })
  })

  return returnHighlightedId
}
