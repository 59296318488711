import * as React from 'react'

const defaultSize = { x: 14, y: 14 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const LeftToSlider: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 2.91465C2.5826 2.70873 3 2.15311 3 1.5C3 0.671573 2.32843 0 1.5 0C0.671573 0 0 0.671573 0 1.5C0 2.15311 0.417404 2.70873 1 2.91465V14H2V2.91465ZM13 6C13.5523 6 14 5.55228 14 5C14 4.44772 13.5523 4 13 4H5C4.44772 4 4 4.44772 4 5C4 5.55228 4.44772 6 5 6H13ZM9 11C9.55229 11 10 10.5523 10 10C10 9.44772 9.55229 9 9 9H5C4.44772 9 4 9.44772 4 10C4 10.5523 4.44772 11 5 11H9Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
