import { useCurrentSpaceStore } from 'apps/web-app/src/use-cases'
import * as React from 'react'

export interface IUserSideMenuInteractor {
  currentPath: string

  children: {
    upgradeToPro: boolean
  }
}

export function useUserSideMenuInteractor(): IUserSideMenuInteractor {
  const { currentUser } = useCurrentSpaceStore()

  const upgradeToProVisible = React.useMemo(() => {
    if (currentUser?.plan === 'pro') return false

    return true
  }, [currentUser])

  return {
    currentPath: '/my/projects',

    children: {
      upgradeToPro: upgradeToProVisible,
    },
  }
}
