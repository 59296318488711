import * as React from 'react'

import { Analytics } from './analytics'
import { LocalAnalytics } from './local.analytics'

// @NOTE: default value required to prevent any errors in runtime with undefined values
const Context = React.createContext<Analytics>(new LocalAnalytics())

export const useAnalytics = (): Analytics => React.useContext(Context)

export const AnalyticsProvider: React.FCC<{ client: Analytics }> = ({
  client,
  children,
}) => <Context.Provider value={client}>{children}</Context.Provider>
