import * as React from 'react'

import { RgbaValueComponent, Root } from '@aninix-inc/model'
import { useComponents } from '@aninix/core/updates'
import { ComponentGroupPanel } from '../../components/component-group-panel'
import { useOverride } from '../use-override-item'
import { Fill } from './colors/fill'

export interface IProps {}
export const Colors: React.FCC<IProps> = () => {
  const {
    openedPaneles,
    updateOpenedPanels,
    data: { project },
  } = useOverride()

  const data = project.getEntitiesByPredicate(
    (e) => e.hasComponent(RgbaValueComponent) && !(e instanceof Root)
  )

  useComponents(data.map((e) => e.getComponentOrThrow(RgbaValueComponent)))

  if (data === undefined || data.length === 0) return null

  return (
    <div>
      <ComponentGroupPanel
        isOpen={openedPaneles.colors}
        toggleOpen={() => updateOpenedPanels({ colors: !openedPaneles.colors })}
        label="Colors"
      >
        <div className="flex flex-col gap-1">
          {data.map((fill) => (
            <Fill
              key={fill.id}
              component={fill.getComponentOrThrow(RgbaValueComponent)}
            />
          ))}
        </div>
      </ComponentGroupPanel>
    </div>
  )
}

Colors.displayName = 'Colors'
