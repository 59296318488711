import classNames from 'classnames'
import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { AdminRole } from '../admin-role'
import { Options } from '../options'
import { AninixRole } from '../role'
import { Status } from '../status'
import { CurrentRowContext } from '../use-current-row-context'
import { Avatar } from './avatar'
import { Info } from './info'
import { MemberSince } from './member-since'

export const Row: React.FCC<Partial<ApiUser>> = ({
  id,
  avatarUrl,
  name,
  email,
  status,
  aninixRole,
  adminRole,
  children,
  createdAt,
}) => {
  const ref = React.useRef<HTMLDivElement>(null)
  return (
    <div
      ref={ref}
      className={classNames(
        'grid grid-cols-[40px_minmax(280px,2fr)_minmax(140px,1fr)_minmax(140px,1fr)_minmax(140px,1fr)_minmax(120px,1fr)_32px] items-center gap-x-6',
        {
          ['text-gray-300']: status === 'suspended',
          ['text-gray-500']: status !== 'suspended',
        }
      )}
    >
      <CurrentRowContext.Provider
        value={{
          rowRef: ref,
          id,
          avatarUrl,
          name,
          email,
          status,
          aninixRole,
          adminRole,
          createdAt,
        }}
      >
        {children}
      </CurrentRowContext.Provider>
    </div>
  )
}

export const MemberRow: React.FCC<ApiUser> = ({
  id,
  avatarUrl,
  name,
  email,
  status,
  aninixRole,
  adminRole,
  createdAt,
}) => {
  return (
    <Row
      id={id}
      avatarUrl={avatarUrl}
      name={name}
      email={email}
      status={status}
      aninixRole={aninixRole}
      adminRole={adminRole}
      createdAt={createdAt}
    >
      <Avatar />
      <Info />
      <MemberSince />
      <AninixRole />
      <AdminRole />
      <Status />
      <Options />
    </Row>
  )
}

export const MemberRowPlaceholder: React.FCC = () => (
  <Row>
    <div className="h-10 w-10 animate-pulse rounded-full bg-slate-300" />
    <div className="flex flex-col gap-[1px]">
      <div className="h-6 w-20 rounded-lg bg-gray-200 " />
      <div className="h-5 w-40 rounded-lg bg-gray-200" />
    </div>
    <div className="h-6 w-14 rounded-lg bg-gray-200" />
    <div className="h-6 w-14 rounded-lg bg-gray-200" />
    <div className="h-6 w-14 rounded-lg bg-gray-200" />
    <div />
  </Row>
)

MemberRow.displayName = 'MemberRow'
