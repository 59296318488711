import * as React from 'react'

import * as styles from './index.scss'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
  state?: 'normal' | 'loading'
}
export const PullUpdateFromFigma: React.FCC<IProps> = ({
  size = defaultSize,
  style,
  state = 'normal',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M10 14.9993C10 13.7106 11.0446 12.666 12.3333 12.666H14.7499V17.3325H12.3333C11.0446 17.3325 10 16.2879 10 14.9993V14.9993Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M10 19.6663C10 18.3776 11.0446 17.333 12.3333 17.333H14.7499V19.6246C14.7499 20.9362 13.6656 21.9995 12.3539 21.9995V21.9995C11.0653 21.9995 10 20.9549 10 19.6663V19.6663Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M10 10.3323C10 9.04366 11.0446 7.99902 12.3333 7.99902H14.7499V12.6655H12.3333C11.0446 12.6655 10 11.6209 10 10.3323V10.3323Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M19.5 10.3328C19.5 11.6214 18.4554 12.666 17.1667 12.666L14.7501 12.666L14.7501 7.99951L17.1667 7.99951C18.4554 7.99951 19.5 9.04414 19.5 10.3328V10.3328Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M14.75 14.9993C14.75 13.7107 15.8133 12.666 17.125 12.666C18.4366 12.666 19.4999 13.7107 19.4999 14.9993C19.4999 16.2879 18.4366 17.3325 17.125 17.3325C15.8133 17.3325 14.75 16.2879 14.75 14.9993Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />

    {state === 'normal' && (
      <path
        d="M21.4995 17L21.4996 24M21.4996 24L24 21.5M21.4996 24L19 21.5"
        stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    )}

    {state === 'loading' && (
      <path
        d="M21.5 24C22.8807 24 24 22.8807 24 21.5C24 20.1193 22.8807 19 21.5 19C20.1193 19 19 20.1193 19 21.5"
        stroke="var(--figma-color-text-brand, rgba(0, 0, 0, 0.8))"
        className={styles.loading}
      />
    )}
  </svg>
)
