import { logout } from '@aninix/api'
import * as React from 'react'

import { SessionStore } from '../stores/session'

export interface ILogoutUseCase {
  execute: () => Promise<void>
}

type Payload = {
  sessionStore: SessionStore
}

export function useLogout({ sessionStore }: Payload): ILogoutUseCase {
  const execute = React.useCallback(async () => {
    await logout()
    await sessionStore.logout()
    window.location.reload()
  }, [])

  return {
    execute,
  }
}
