import * as React from 'react'

export interface IProps {}
export const KeyHoleIcon: React.FCC<IProps> = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4517_6635)">
      <circle cx="8" cy="8" r="8" fill="#89909A" />
      <path
        d="M8 5C7.49542 4.99984 7.00943 5.19041 6.63945 5.5335C6.26947 5.87659 6.04284 6.34685 6.005 6.85L6 7L6.005 7.15C6.02966 7.47461 6.13318 7.78829 6.30658 8.06381C6.47997 8.33932 6.718 8.56835 7 8.731V10.5L7.007 10.617C7.0371 10.8701 7.16271 11.1021 7.35817 11.2657C7.55362 11.4293 7.80416 11.5121 8.05861 11.4972C8.31305 11.4822 8.55218 11.3707 8.72715 11.1854C8.90212 11 8.99972 10.7549 9 10.5L9.001 8.732C9.38233 8.51181 9.68034 8.17193 9.84879 7.76509C10.0172 7.35824 10.0467 6.90718 9.93263 6.48187C9.81856 6.05657 9.56731 5.6808 9.21787 5.41286C8.86843 5.14492 8.44034 4.9998 8 5Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_4517_6635">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

KeyHoleIcon.displayName = 'KeyHoleIcon'
