import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Group: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 3H7v1h2V3zm2.5 9h.5v-.5h1V13h-1.5v-1zM4 7v2H3V7h1zm8-2.5V4h-.5V3H13v1.5h-1zM12 7v2h1V7h-1zM4 4.5V4h.5V3H3v1.5h1zM3 12v-.5h1v.5h.5v1H3v-1zm6 0H7v1h2v-1z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
