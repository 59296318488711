import { NodeHandler, SNodeType } from '../types'
import { circle } from './circle'
import { ellipse } from './ellipse'
import { g } from './g'
import { line } from './line'
import { path } from './path'
import { polygon } from './polygon'
import { polyline } from './polyline'
import { rect } from './rect'
import { svg } from './svg'

const handlers: Record<SNodeType, NodeHandler> = {
  svg,
  rect,
  path,
  circle,
  ellipse,
  line,
  polygon,
  g,
  polyline,
}

export default handlers
