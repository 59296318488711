import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Angle: React.FCC<IProps> = ({
  size = defaultSize,
  style: styles,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={styles}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 12V19.5V20H12.5H20V19H17C17 16.7909 15.2091 15 13 15V12H12ZM13 16V19H16C16 17.3431 14.6569 16 13 16Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
