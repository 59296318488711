import { Analytics } from './analytics'

export class LocalAnalytics implements Analytics {
  private identityData: any

  private projectData: any

  identify: Analytics['identify'] = async (payload) => {
    console.log('[analytics] identify', payload)
    this.identityData = payload
  }

  setProjectData: Analytics['setProjectData'] = async (projectData) => {
    console.log('[analytics] setProjectData', projectData)
    this.projectData = projectData
  }

  track: Analytics['track'] = async ({ eventName, properties = {} }) => {
    console.log('[analytics] track', {
      eventName,
      properties: { ...this.projectData, ...this.identityData, ...properties },
    })
  }
}
