import {
  Entity,
  EntryComponent,
  getBoundingBox as getBoundingBoxSource,
  getPosition,
  ParentRelationAspect,
  setPosition,
  UndoRedoSystem,
  UpdatesSystem,
} from '@aninix-inc/model'
import {
  buttons,
  Dropdown,
  icons,
  NodesAlignType,
  NodesDistributionType,
} from '@aninix/app-design-system'
import { KeyModificator, useSession } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'

export const Align: React.FC = observer(() => {
  const { time } = useNodePropertiesPanel()
  const [isEditable, setIsEditable] = React.useState(false)
  const isOutside = useSession().keyModificators.includes(KeyModificator.Alt)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  return (
    <div onPointerEnter={() => setIsEditable(true)}>
      {isEditable ? (
        <AlignEditable isOutside={isOutside} />
      ) : (
        <AlignDisplay isOutside={isOutside} />
      )}
    </div>
  )
})

Align.displayName = 'Align'

const AlignEditable: React.FC<{
  isOutside: boolean
}> = ({ isOutside }) => {
  const { nodes, time } = useNodePropertiesPanel()

  const updateNodePosition = React.useCallback(
    (payload: { entity: Entity; point: { x?: number; y?: number } }) => {
      if (payload.point.x == null && payload.point.y == null) {
        throw new TypeError('payload.x or payload.y are required')
      }

      const position = getPosition(payload.entity, time)

      const point = {
        x: payload.point.x == null ? position.x : payload.point.x,
        y: payload.point.y == null ? position.y : payload.point.y,
      }
      // @TODO: move logic into model
      const deltaX = position.x - point.x
      const deltaY = position.y - point.y

      setPosition(
        payload.entity,
        {
          x: point.x,
          y: point.y,
          tx1: position.tx1 + deltaX,
          ty1: position.ty1 + deltaY,
          tx2: position.tx2 + deltaX,
          ty2: position.ty2 + deltaY,
        },
        time
      )
    },
    [time]
  )

  const getBoundingBox = React.useCallback(
    (nodes: Entity[]) => {
      const project = nodes[0].getProjectOrThrow()

      if (nodes.length > 1) {
        return getBoundingBoxSource(nodes, time)
      }

      const layer = nodes[0]
      const parent = layer
        .getAspectOrThrow(ParentRelationAspect)
        .getParentEntityOrThrow()
      const entry = project.getFirstWhere((e) => e.hasComponent(EntryComponent))

      if (entry == null) {
        throw new Error('Invalid state')
      }

      if (parent.id !== entry.id) {
        return getBoundingBoxSource([parent], time)
      }

      return getBoundingBoxSource([entry], time)
    },
    [time]
  )

  // @NOTE: bounding box calculates correctly, but final coordinate not.
  // @TODO: fix final coordinate calculation.
  const align = React.useCallback(
    // @TODO: provide type
    ({ type }: any) => {
      const applyToNode = (
        entity: Entity,
        type: NodesAlignType,
        boundingBox: ReturnType<typeof getBoundingBox>
      ) => {
        const entityBoundinbBox = getBoundingBoxSource([entity], time)
        const topLeftX = boundingBox.x
        const topLeftY = boundingBox.y
        const centerX = boundingBox.x + boundingBox.width / 2
        const centerY = boundingBox.y + boundingBox.height / 2
        const bottomRightX = boundingBox.x + boundingBox.width
        const bottomRightY = boundingBox.y + boundingBox.height

        if (type === NodesAlignType.HorizontalLeft) {
          updateNodePosition({
            entity,
            point: { x: topLeftX },
          })
          return
        }
        if (type === NodesAlignType.HorizontalLeftOutside) {
          updateNodePosition({
            entity,
            point: { x: -entityBoundinbBox.width },
          })
          return
        }
        if (type === NodesAlignType.HorizontalMiddle) {
          updateNodePosition({
            entity,
            point: { x: centerX - entityBoundinbBox.width / 2 },
          })
          return
        }
        if (type === NodesAlignType.HorizontalRight) {
          updateNodePosition({
            entity,
            point: { x: bottomRightX - entityBoundinbBox.width },
          })
          return
        }
        if (type === NodesAlignType.HorizontalRightOutside) {
          updateNodePosition({
            entity,
            point: { x: bottomRightX },
          })
          return
        }
        if (type === NodesAlignType.VerticalTop) {
          updateNodePosition({
            entity,
            point: { y: topLeftY },
          })
          return
        }
        if (type === NodesAlignType.VerticalTopOutside) {
          updateNodePosition({
            entity,
            point: { y: -entityBoundinbBox.height },
          })
          return
        }
        if (type === NodesAlignType.VerticalCenter) {
          updateNodePosition({
            entity,
            point: { y: centerY - entityBoundinbBox.height / 2 },
          })
          return
        }
        if (type === NodesAlignType.VerticalBottom) {
          updateNodePosition({
            entity,
            point: { y: bottomRightY - entityBoundinbBox.height },
          })
          return
        }
        if (type === NodesAlignType.VerticalBottomOutside) {
          updateNodePosition({
            entity,
            point: { y: bottomRightY },
          })
          return
        }
      }

      const project = nodes[0].getProjectOrThrow()
      const boundingBox = getBoundingBox(nodes)
      const updates = project.getSystemOrThrow(UpdatesSystem)
      const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
      updates.batch(() => {
        nodes.forEach((entity) => {
          applyToNode(entity, type, boundingBox)
        })
      })
      undoRedo.commitUndo()
    },
    [nodes, time, updateNodePosition, getBoundingBox]
  )

  // @TODO: improve how distribute work. Now it's not looking for coordinates coorectly
  const distribute = React.useCallback(
    // @TODO: provide type
    ({ type }: any) => {
      if (nodes.length <= 1) {
        return
      }
      const project = nodes[0].getProjectOrThrow()
      const updates = project.getSystemOrThrow(UpdatesSystem)
      const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
      const boundingBox = getBoundingBox(nodes)

      updates.batch(() => {
        if (type === NodesDistributionType.Horizontally) {
          const sortedEntities = R.sort(
            (left, right) =>
              getPosition(left, time).x - getPosition(right, time).x,
            nodes
          )
          sortedEntities.forEach((entity, idx, array) => {
            if (idx === 0) {
              return
            }

            if (array.length === idx + 1) {
              return
            }

            const topLeftX = boundingBox.x
            const bottomRightX = boundingBox.x + boundingBox.width
            const x =
              ((bottomRightX - topLeftX) / (sortedEntities.length - 1)) * idx +
              topLeftX
            updateNodePosition({
              entity,
              point: {
                x,
              },
            })
          })
          return
        }
        if (type === NodesDistributionType.Vertically) {
          const sortedEntities = R.sort(
            (left, right) =>
              getPosition(left, time).y - getPosition(right, time).y,
            nodes
          )
          sortedEntities.forEach((entity, idx, array) => {
            if (idx === 0) {
              return
            }

            if (array.length === idx + 1) {
              return
            }

            const topLeftY = boundingBox.y
            const bottomRightY = boundingBox.y + boundingBox.height
            const y =
              ((bottomRightY - topLeftY) / (sortedEntities.length - 1)) * idx +
              topLeftY
            updateNodePosition({
              entity,
              point: {
                y,
              },
            })
          })
          return
        }
      })
      undoRedo.commitUndo()
    },
    [time, nodes, updateNodePosition, getBoundingBox]
  )

  const [isDistributionVisible, setIsDistributionVisible] =
    React.useState(false)

  const openDistributionPopover = React.useCallback(() => {
    setIsDistributionVisible(true)
  }, [])

  const closeDistributionPopover = React.useCallback(() => {
    setIsDistributionVisible(false)
  }, [])

  const onClickAlignHorizontalLeft = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalLeft,
    })
  }, [align])

  const onClickAlignHorizontalLeftOutside = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalLeftOutside,
    })
  }, [align])

  const onClickAlignHorizontalMiddle = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalMiddle,
    })
  }, [align])

  const onClickAlignHorizontalRight = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalRight,
    })
  }, [align])

  const onClickAlignHorizontalRightOutside = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.HorizontalRightOutside,
    })
  }, [align])

  const onClickAlignVerticalTop = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalTop,
    })
  }, [align])

  const onClickAlignVerticalTopOutside = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalTopOutside,
    })
  }, [align])

  const onClickAlignVerticalCenter = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalCenter,
    })
  }, [align])

  const onClickAlignVerticalBottom = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalBottom,
    })
  }, [align])

  const onClickAlignVerticalBottomOutside = React.useCallback(() => {
    align({
      type: icons.propertiesPanel.NodesAlignType.VerticalBottomOutside,
    })
  }, [align])

  const onClickDistributeHorizontally = React.useCallback(() => {
    distribute({
      type: icons.propertiesPanel.NodesDistributionType.Horizontally,
    })
    closeDistributionPopover()
  }, [closeDistributionPopover, distribute])

  const onClickDistributeVertically = React.useCallback(() => {
    distribute({
      type: icons.propertiesPanel.NodesDistributionType.Vertically,
    })
    closeDistributionPopover()
  }, [closeDistributionPopover, distribute])

  const distributionContainerRef = React.useRef<HTMLDivElement | null>(null)

  return (
    <div className="border-b-solid flex flex-row flex-nowrap items-stretch justify-between border-b-[1px] border-b-[var(--aninix-color-foreground-black-05)] px-2 py-1">
      {isOutside ? (
        <buttons.Icon onClick={onClickAlignHorizontalRightOutside}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalRightOutside}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon onClick={onClickAlignHorizontalLeft}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalLeft}
          />
        </buttons.Icon>
      )}

      <buttons.Icon onClick={onClickAlignHorizontalMiddle}>
        <icons.propertiesPanel.NodesAlign
          type={icons.propertiesPanel.NodesAlignType.HorizontalMiddle}
        />
      </buttons.Icon>

      {isOutside ? (
        <buttons.Icon onClick={onClickAlignHorizontalLeftOutside}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalLeftOutside}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon onClick={onClickAlignHorizontalRight}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalRight}
          />
        </buttons.Icon>
      )}

      {isOutside ? (
        <buttons.Icon onClick={onClickAlignVerticalBottomOutside}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalBottomOutside}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon onClick={onClickAlignVerticalTop}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalTop}
          />
        </buttons.Icon>
      )}

      <buttons.Icon onClick={onClickAlignVerticalCenter}>
        <icons.propertiesPanel.NodesAlign
          type={icons.propertiesPanel.NodesAlignType.VerticalCenter}
        />
      </buttons.Icon>

      {isOutside ? (
        <buttons.Icon onClick={onClickAlignVerticalTopOutside}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalTopOutside}
          />
        </buttons.Icon>
      ) : (
        <buttons.Icon onClick={onClickAlignVerticalBottom}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalBottom}
          />
        </buttons.Icon>
      )}

      <div ref={distributionContainerRef}>
        <buttons.Icon onClick={openDistributionPopover}>
          <div className="flex flex-row flex-nowrap items-center justify-center">
            <icons.propertiesPanel.NodesDistribution
              type={icons.propertiesPanel.NodesDistributionType.Horizontally}
            />

            <div className="ml-1 flex flex-col items-center justify-center">
              <icons.Dropdown />
            </div>
          </div>
        </buttons.Icon>

        <Dropdown
          anchorEl={distributionContainerRef.current}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          open={isDistributionVisible}
          onClose={closeDistributionPopover}
          items={[
            {
              title: 'Distribute Horizontally',
              onClick: onClickDistributeHorizontally,
              icon: (
                <icons.propertiesPanel.NodesDistribution
                  type={
                    icons.propertiesPanel.NodesDistributionType.Horizontally
                  }
                  style={{
                    // @ts-ignore
                    '--figma-color-text': 'rgba(255, 255, 255, 0.9)',
                  }}
                />
              ),
              // @TODO: uncomment when hotkeys implemented
              // rightPart: '⌃⌥H',
            },
            {
              title: 'Distribute Vertically',
              onClick: onClickDistributeVertically,
              icon: (
                <icons.propertiesPanel.NodesDistribution
                  type={icons.propertiesPanel.NodesDistributionType.Vertically}
                  style={{
                    // @ts-ignore
                    '--figma-color-text': 'rgba(255, 255, 255, 0.9)',
                  }}
                />
              ),
              // @TODO: uncomment when hotkeys implemented
              // rightPart: '⌃⌥V',
            },
          ]}
        />
      </div>
    </div>
  )
}

AlignEditable.displayName = 'AlignEditable'

const AlignDisplay: React.FC<{ isOutside: boolean }> = React.memo(
  ({ isOutside }) => {
    return (
      <div className="border-b-solid flex flex-row flex-nowrap items-stretch justify-between border-b-[1px] border-b-[var(--aninix-color-foreground-black-05)] px-2 py-1">
        {isOutside ? (
          <buttons.Icon onClick={() => {}}>
            <icons.propertiesPanel.NodesAlign
              type={icons.propertiesPanel.NodesAlignType.HorizontalRightOutside}
            />
          </buttons.Icon>
        ) : (
          <buttons.Icon onClick={() => {}}>
            <icons.propertiesPanel.NodesAlign
              type={icons.propertiesPanel.NodesAlignType.HorizontalLeft}
            />
          </buttons.Icon>
        )}

        <buttons.Icon onClick={() => {}}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.HorizontalMiddle}
          />
        </buttons.Icon>

        {isOutside ? (
          <buttons.Icon onClick={() => {}}>
            <icons.propertiesPanel.NodesAlign
              type={icons.propertiesPanel.NodesAlignType.HorizontalLeftOutside}
            />
          </buttons.Icon>
        ) : (
          <buttons.Icon onClick={() => {}}>
            <icons.propertiesPanel.NodesAlign
              type={icons.propertiesPanel.NodesAlignType.HorizontalRight}
            />
          </buttons.Icon>
        )}

        {isOutside ? (
          <buttons.Icon onClick={() => {}}>
            <icons.propertiesPanel.NodesAlign
              type={icons.propertiesPanel.NodesAlignType.VerticalBottomOutside}
            />
          </buttons.Icon>
        ) : (
          <buttons.Icon onClick={() => {}}>
            <icons.propertiesPanel.NodesAlign
              type={icons.propertiesPanel.NodesAlignType.VerticalTop}
            />
          </buttons.Icon>
        )}

        <buttons.Icon onClick={() => {}}>
          <icons.propertiesPanel.NodesAlign
            type={icons.propertiesPanel.NodesAlignType.VerticalCenter}
          />
        </buttons.Icon>

        {isOutside ? (
          <buttons.Icon onClick={() => {}}>
            <icons.propertiesPanel.NodesAlign
              type={icons.propertiesPanel.NodesAlignType.VerticalTopOutside}
            />
          </buttons.Icon>
        ) : (
          <buttons.Icon onClick={() => {}}>
            <icons.propertiesPanel.NodesAlign
              type={icons.propertiesPanel.NodesAlignType.VerticalBottom}
            />
          </buttons.Icon>
        )}

        <div>
          <buttons.Icon onClick={() => {}}>
            <div className="flex flex-row flex-nowrap items-center justify-center">
              <icons.propertiesPanel.NodesDistribution
                type={icons.propertiesPanel.NodesDistributionType.Horizontally}
              />

              <div className="ml-1 flex flex-col items-center justify-center">
                <icons.Dropdown />
              </div>
            </div>
          </buttons.Icon>
        </div>
      </div>
    )
  }
)

AlignDisplay.displayName = 'AlignDisplay'

type BoundingBox = {
  x: number
  y: number
  width: number
  height: number
}
