import {
  Entity,
  EntityType,
  SelectionSystem,
  TimeComponent,
} from '@aninix-inc/model'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import * as React from 'react'
import { PropertyNameSegment } from '../../../../../../../modules/inspector/property-name-segment'
import { usePlayback } from '../../../../../../../stores'

export interface IProps {
  segment: [Entity, Entity]
}

export const Property: React.FCC<IProps> = observer(({ segment }) => {
  const name = new PropertyNameSegment(segment).propertyName

  const playback = usePlayback()
  const selection = segment[0]
    .getProjectOrThrow()
    .getSystemOrThrow(SelectionSystem)

  const isSelected = R.pipe(
    (keyframes: Entity[]) => R.aperture(2, keyframes),
    R.find(
      ([left, right]: [Entity, Entity]) =>
        left.id === R.head(segment)!.id && right.id === R.last(segment)!.id
    )
  )(selection.getEntitiesByEntityType(EntityType.Keyframe))
  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.preventDefault()
      e.stopPropagation()
      selection.replace(segment.map((key) => key.id))
      playback.updateTime(segment[0].getComponentOrThrow(TimeComponent).value)
    },
    []
  )

  return (
    <div
      onClick={handleClick}
      className={classNames(
        '-ml-1 line-clamp-1 py-0.5 pl-6 font-mono text-xs font-medium',
        {
          ['] bg-[var(--figma-color-text)] text-white ']: isSelected,
          ['text-[var(--figma-color-text)] ']: !isSelected,
        }
      )}
    >
      {name}
    </div>
  )
})

Property.displayName = 'Property'
