import { useAcceptUserInviteToTeam, useGetSpaceBadge } from '@aninix/api'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { toast } from '../../modules/toasts'
import { useSessionStore } from '../../stores'

export interface IProps {}
export const JoinTeam: React.FCC<IProps> = () => {
  const { teamId = '' } = useParams()
  const sessionStore = useSessionStore()
  const { userId } = sessionStore
  const acceptInvite = useAcceptUserInviteToTeam()

  const navigate = useNavigate()

  const tokensArray = Object.values(sessionStore.tokens ?? '')
  const spaceBadge = useGetSpaceBadge({
    tokens: tokensArray,
  })

  React.useEffect(() => {
    acceptInvite
      .mutateAsync({
        teamId,
        userId,
      })
      .then((value) => {
        spaceBadge.refetch().then(() => {
          navigate(`/teams/${teamId}`)
          toast(`You successfully joined the team!`, { variant: 'success' })
        })
      })
      .catch((error) => {
        navigate(`/my`)
        toast(
          `Couldn't accept invite. Check if you logged in right account or copied right invitation URL`,
          { variant: 'error' }
        )
      })
  }, [])

  return null
}

JoinTeam.displayName = 'JoinTeam'
