import { Angle } from './angle'
import { BlendingMode } from './blending-mode'
import { BottomLeftRadius } from './bottom-left-radius'
import { BottomRightRadius } from './bottom-right-radius'
import { Copy } from './copy'
import { CornerRadius } from './corner-radius'
import { Detach } from './detach'
import { IndividualCorners } from './individual-corners'
import { IndividualStrokes } from './individual-strokes'
import { InnerRadius } from './inner-radius'
import { NodesAlign, NodesAlignType } from './nodes-align'
import { NodesDistribution, NodesDistributionType } from './nodes-distribution'
import { Offset } from './offset'
import { Opacity } from './opacity'
import { PolygonPointCount } from './polygon-point-count'
import { ReversePath } from './reverse-path'
import { ScaleX, ScaleY } from './scale'
import { SegmentsAlign, SegmentsAlignType } from './segments-align'
import { SkewX, SkewY } from './skew'
import { StarPointCount } from './star-point-count'
import * as StrokeCap from './stroke-cap'
import { Sweep } from './sweep'
import { TopLeftRadius } from './top-left-radius'
import { TopRightRadius } from './top-right-radius'
import { TrimLayer } from './trim-layer'

export const propertiesPanel = {
  Angle,
  BlendingMode,
  BottomLeftRadius,
  BottomRightRadius,
  Copy,
  Detach,
  IndividualCorners,
  IndividualStrokes,
  InnerRadius,
  CornerRadius,
  NodesAlign,
  NodesAlignType,
  NodesDistribution,
  NodesDistributionType,
  Offset,
  Opacity,
  PolygonPointCount,
  ReversePath,
  ScaleX,
  ScaleY,
  SkewX,
  SkewY,
  SegmentsAlign,
  SegmentsAlignType,
  StarPointCount,
  StrokeCap,
  Sweep,
  TopLeftRadius,
  TopRightRadius,
  TrimLayer,
}
