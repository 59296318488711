import * as React from 'react'

const defaultSize = { x: 14, y: 13 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const HorizontalRightOutside: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 0H3.5H4V0.5V12.5V13H3.5H0V12H3V1H0V0ZM14 4.99969H6V2.99969H14V4.99969ZM6 9.99969H11V7.99969H6V9.99969Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
