import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import * as R from 'ramda'
import * as React from 'react'

import {
  Entity,
  EntityType,
  FillsRelationsAspect,
  NodeTypeComponent,
  SelectionSystem,
  StrokesRelationsAspect,
} from '@aninix-inc/model'
import { NodeType } from '@aninix-inc/model/legacy'
import { getSelection } from '@aninix/core'
import { Preset, PresetAnimation } from '../../models/preset'
import { IUiStore } from '../../stores'
import { IUseApplyPresetUseCase } from '../../use-cases'

type Payload = {
  uiStore: IUiStore
  selection: SelectionSystem
  applyPresetUseCase: IUseApplyPresetUseCase
  quickPresetsViewed: boolean
}

export interface IAnimationQuickPresetsInteractor {
  presets: Preset[]
  learnMore: () => void
  applyPreset: (preset: Preset) => void
  visibleFirstTime: boolean
}

export const useAnimationQuickPresetsInteractor = ({
  uiStore,
  selection,
  applyPresetUseCase,
  quickPresetsViewed,
}: Payload): IAnimationQuickPresetsInteractor => {
  const analytics = useAnalytics()
  const project = selection.project
  const applyPreset: IAnimationQuickPresetsInteractor['applyPreset'] =
    React.useCallback(
      async (preset) => {
        await applyPresetUseCase.apply({ preset })
      },
      [applyPresetUseCase]
    )

  const learnMore: IAnimationQuickPresetsInteractor['learnMore'] =
    React.useCallback(() => {
      analytics.track({
        eventName: AnalyticsEvent.AnimationPresetsClicked,
        properties: {
          source: 'quick-presets',
        },
      })
      uiStore.propertiesPanelUpdateActiveTab('Presets')
    }, [analytics, uiStore])

  const presets = React.useMemo(() => {
    const basePresets: Preset[] = [
      new Preset({
        id: 'grow',
        title: 'Grow',
        type: Preset.Type.In,
        speed: Preset.Speed.Medium,
        timingCurve: Preset.TimingCurve.Eager,
        baseAnimations: [
          {
            id: PresetAnimation.Id.AppearanceScale,
            type: PresetAnimation.Types.AppearanceScale.Both,
            values: {
              0: -1,
              1: 0,
            },
          },
        ],
        requiredAnimations: [],
        optionalAnimations: [],
        isCustomizable: false,
      }),

      new Preset({
        id: 'scale-horizontal',
        title: 'Scale horizontal',
        type: Preset.Type.In,
        speed: Preset.Speed.Medium,
        timingCurve: Preset.TimingCurve.Eager,
        baseAnimations: [
          {
            id: PresetAnimation.Id.AppearanceScale,
            type: PresetAnimation.Types.AppearanceScale.Horizontal,
            values: {
              0: -1,
              1: 0,
            },
          },
        ],
        requiredAnimations: [],
        optionalAnimations: [],
        isCustomizable: false,
      }),

      new Preset({
        id: 'fade',
        title: 'Fade',
        type: Preset.Type.In,
        speed: Preset.Speed.Medium,
        timingCurve: Preset.TimingCurve.Eager,
        baseAnimations: [
          {
            id: PresetAnimation.Id.AppearanceFade,
            type: PresetAnimation.Types.AppearanceFade.Fade,
            values: {
              0: -1,
              1: 0,
            },
          },
        ],
        requiredAnimations: [],
        optionalAnimations: [],
        isCustomizable: false,
      }),

      new Preset({
        id: 'to-right',
        title: 'To right',
        type: Preset.Type.In,
        speed: Preset.Speed.Medium,
        timingCurve: Preset.TimingCurve.Eager,
        baseAnimations: [
          {
            id: PresetAnimation.Id.AppearanceFade,
            type: PresetAnimation.Types.AppearanceFade.Fade,
            values: {
              0: -1,
              1: 0,
            },
          },
          {
            id: PresetAnimation.Id.AppearanceDirection,
            type: PresetAnimation.Types.AppearanceDirection.Right,
            values: {
              0: 1,
              1: 0,
            },
          },
        ],
        requiredAnimations: [],
        optionalAnimations: [],
        isCustomizable: false,
      }),

      new Preset({
        id: 'to-top',
        title: 'To top',
        type: Preset.Type.In,
        speed: Preset.Speed.Medium,
        timingCurve: Preset.TimingCurve.Eager,
        baseAnimations: [
          {
            id: PresetAnimation.Id.AppearanceFade,
            type: PresetAnimation.Types.AppearanceFade.Fade,
            values: {
              0: -1,
              1: 0,
            },
          },
          {
            id: PresetAnimation.Id.AppearanceDirection,
            type: PresetAnimation.Types.AppearanceDirection.Up,
            values: {
              0: 1,
              1: 0,
            },
          },
        ],
        requiredAnimations: [],
        optionalAnimations: [],
        isCustomizable: false,
      }),
    ]

    const trimPath = new Preset({
      id: 'trim-path',
      title: 'Trim path',
      type: Preset.Type.In,
      speed: Preset.Speed.Medium,
      timingCurve: Preset.TimingCurve.Eager,
      baseAnimations: [
        {
          id: PresetAnimation.Id.AppearanceTrimPath,
          type: PresetAnimation.Types.AppearanceTrimPath.Clockwise,
          values: {
            0: 0,
            1: 1,
          },
        },
      ],
      requiredAnimations: [],
      optionalAnimations: [],
      isCustomizable: false,
    })

    const selectedNodes = getSelection(project, EntityType.Node)
    const hasStrokesOnly = R.all(
      (node: Entity) =>
        node.getComponentOrThrow(NodeTypeComponent).value !== NodeType.Group &&
        node.getAspectOrThrow(FillsRelationsAspect).getChildrenList().length ===
          0 &&
        node.getAspectOrThrow(StrokesRelationsAspect).getChildrenList().length >
          0,
      selectedNodes
    )
    if (hasStrokesOnly) {
      return [...basePresets, trimPath]
    }

    return basePresets
  }, [selection])

  return {
    presets,
    applyPreset,
    learnMore,
    visibleFirstTime: quickPresetsViewed === false,
  }
}
