import {
  IComment,
  useGetComments,
} from '@aninix/core/use-cases/comments/use-get-comments'
import { usePatchComment } from '@aninix/core/use-cases/comments/use-patch-comment'
import { CircularProgress, InputBase } from '@material-ui/core'
import { toast } from 'apps/web-app/src/modules/toasts'
import classNames from 'classnames'
import * as React from 'react'

export interface IProps {
  data: IComment
  isShowing: boolean
  onFinish?: () => void
}
export const EditCommentThreadItem: React.FCC<IProps> = ({
  data,
  isShowing,
  onFinish,
}) => {
  const [input, setInput] = React.useState(data.comment)
  const [isLoading, setIsLoading] = React.useState(false)

  const formRef = React.useRef<HTMLFormElement>(null)

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if (input === data.comment) {
        onFinish?.()
        return
      }

      setIsLoading(true)

      patchCommentQuery
        .mutateAsync({
          commentId: data.id,
          comment: input,
          rectangle: data.rectangle,
          time: data.time,
        })
        .then((v) => {
          getCommentsQuery?.refetch().then((result) => {
            setIsLoading(false)
            onFinish?.()
          })
        })
        .catch((e) => {
          toast(<p>Couldn't update comment</p>, { variant: 'error' })
          setIsLoading(false)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [setInput, input, data]
  )

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.currentTarget.value !== '\n') setInput(e.currentTarget.value)
      else setInput('')
    },
    [setInput]
  )

  const handleFormKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.metaKey && e.key === 'Enter' && input.length > 0)
        formRef?.current?.requestSubmit()
    },
    [formRef, input]
  )

  const { query: getCommentsQuery } = useGetComments({
    isStartingLoading: false,
  })
  const { query: patchCommentQuery } = usePatchComment()

  React.useEffect(() => {
    setTimeout(() => {
      if (!formRef.current) return

      if (isShowing) {
        formRef.current.querySelector('textarea')?.focus()
        formRef.current.querySelector('textarea')!.selectionStart = 100000
        formRef.current.querySelector('textarea')!.selectionEnd = 100000
      } else formRef.current.querySelector('textarea')?.blur()
    }, 300)
  }, [isShowing, formRef])

  React.useEffect(() => {
    if (!isShowing) setInput(data.comment)
  }, [isShowing])

  return (
    <form
      ref={formRef}
      onKeyDown={handleFormKeyDown}
      className="w-full pl-8"
      onSubmit={handleSubmit}
    >
      <InputBase
        disabled={isLoading}
        value={input}
        onChange={handleInputChange}
        className="min-h-10 flex flex-row items-end whitespace-pre-wrap rounded bg-neutral-100 pb-3 pl-2 font-body text-[11px] text-xs font-normal text-black text-opacity-80"
        endAdornment={
          <button
            disabled={input.length === 0 || isLoading}
            className={classNames(
              'relative -mb-1 flex cursor-default items-center justify-center rounded-full bg-[#F5F5F5] pr-2'
            )}
          >
            <div
              className={classNames(
                'absolute inset-0 flex items-center justify-center pr-2 transition-opacity duration-200',
                {
                  ['opacity-0']: !isLoading,
                }
              )}
            >
              <CircularProgress size={24} />
            </div>
            <div
              className={classNames(
                'absolute inset-0 fill-[#18A0FB] transition-opacity duration-200',
                {
                  ['opacity-0']: input.length === 0 || isLoading,
                }
              )}
            >
              {saveIcon}
            </div>
            <div
              className={classNames(
                'fill-[#D9D9D9] transition-opacity duration-200',
                {
                  ['opacity-0']: isLoading,
                }
              )}
            >
              {saveIcon}
            </div>
          </button>
        }
        placeholder="Reply"
        fullWidth
        type="text"
        multiline
      />
    </form>
  )
}

const saveIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.6611 16.1152L17.6611 9.36541L16.8389 8.63459L11.2264 14.9485L7.1389 10.8611L6.3611 11.6389L10.8611 16.1387L11.2736 16.5512L11.6611 16.1152Z"
    />
  </svg>
)

EditCommentThreadItem.displayName = 'EditCommentThreadItem'
