import { Ellipse } from '@aninix-inc/model'
import { applyCommon } from '../maps/apply-common'
import { NodeHandler } from '../types'

export const circle: NodeHandler = (node, project, assets) => {
  const entity = project.createEntity(Ellipse)
  applyCommon(entity, node, assets)

  return entity
}
