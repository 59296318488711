import { usePlayback } from '@aninix/core'
import { observer } from 'mobx-react'
import * as React from 'react'

export interface IProps {
  className?: string
}

const PlayIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11 23V9L23 16L11 23Z" />
  </svg>
)

const PauseIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="32"
    height="32"
    viewBox="0 0 32 32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 9H11V23H13V9ZM21 9H19V23H21V9Z"
    />
  </svg>
)

export const PlaybackButton: React.FCC<IProps> = observer(
  ({ className = 'w-8 h-8 fill-accent hover:cursor-pointer' }: IProps) => {
    const playback = usePlayback()

    const pause = React.useCallback(
      (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        playback.pause()
        e.stopPropagation()
      },
      [playback]
    )

    const play = React.useCallback(
      (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
        playback.play()
        e.stopPropagation()
      },
      [playback]
    )

    const stopPropagation = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
      },
      [playback]
    )

    return (
      <div className={className} onMouseDown={stopPropagation}>
        {playback.isPlaying ? (
          <PauseIcon onClick={pause} />
        ) : (
          <PlayIcon onClick={play} />
        )}
      </div>
    )
  }
)

PlaybackButton.displayName = 'PlaybackButton'
