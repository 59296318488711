import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Right: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 15L12 3.91465C11.4174 3.70873 11 3.15311 11 2.5C11 1.67157 11.6716 1 12.5 1C13.3284 1 14 1.67157 14 2.5C14 3.15311 13.5826 3.70873 13 3.91465L13 15L12 15ZM7.5 14C8.88071 14 10 12.8807 10 11.5L10 7.5C10 6.11929 8.88071 5 7.5 5L3 5L3 6L7.5 6C8.32843 6 9 6.67157 9 7.5L9 11.5C9 12.3284 8.32843 13 7.5 13L3 13L3 14L7.5 14Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
