import { NameComponent, Project, Root } from '@aninix-inc/model'
import { Frame, IStores, PlaybackButton, Player } from '@aninix-inc/player'
import { config } from '@aninix/api'

import { getCurrentUnixTimestamp } from 'apps/anitype-plugin/src/figma/utils/get-current-unix-timestamp'
import classNames from 'classnames'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { ErrorHappend, ExportFinished, ExportStarted, Progress } from '.'
import { LoadImagesHandler } from './load-images-handler'
import {
  AutostartExportOption,
  RenderHandler,
  VideoGifScaleOption,
} from './render-handler'

export interface IProps {
  images?: Record<string, Uint8Array>
  project: Project
  autostartExportOption?: AutostartExportOption
  exportSessionId?: string
  defaultScale?: VideoGifScaleOption
}
export const ProjectPreview: React.FCC<IProps> = ({
  images,
  project,
  autostartExportOption,
  defaultScale,
}) => {
  const [stores, setStores] = React.useState<Pick<IStores, 'playback'>>()

  const handleExportStarted = () => {
    window.parent.postMessage(
      { type: 'export-started' } satisfies ExportStarted,
      '*'
    )
  }

  const handleProgressUpdate = (progress: number) => {
    window.parent.postMessage(
      { type: 'progress', value: progress } satisfies Progress,
      '*'
    )
  }

  const handleExportFinish = () => {
    window.parent.postMessage(
      { type: 'export-finished' } satisfies ExportFinished,
      '*'
    )
  }

  const handleError = (message: string) => {
    window.parent.postMessage(
      { type: 'error-happend', value: message } satisfies ErrorHappend,
      '*'
    )
  }

  const [renderId, setRenderId] = React.useState(0)

  React.useEffect(() => {
    stores?.playback.updateTime(0)
    stores?.playback.play()
    setRenderId((v) => v + 1)
  }, [project, images])

  return (
    <div className="group">
      <Player
        key={renderId}
        baseUrl={config.apiUrl}
        projectData={project.getSnapshot()}
        setStores={setStores}
        images={images}
      >
        <LoadImagesHandler />
        <RenderHandler
          defaultScale={defaultScale}
          onExportFinish={handleExportFinish}
          onProgressUpdate={handleProgressUpdate}
          onExportStart={handleExportStarted}
          onError={handleError}
          autostartExportOption={autostartExportOption}
          projectName={
            project
              .getEntityByTypeOrThrow(Root)
              .getComponentOrThrow(NameComponent).value
          }
        />
        <div
          className="absolute inset-0"
          style={{
            backgroundColor: 'transparent',
          }}
        />
        <div className="absolute inset-0">
          <Frame />
        </div>
        <div className="absolute inset-0 flex justify-center items-center">
          <div
            className={classNames(
              'bg-white rounded-full scale-[1.25] p-1.5 shadow-lg transition-opacity group-hover:opacity-100 group-hover:delay-0',
              {
                ['opacity-0 delay-1000']: stores?.playback.isPlaying,
              }
            )}
          >
            <div className="tranlate-x-[0.5px]">
              <PlaybackButton />
            </div>
          </div>
        </div>
      </Player>
    </div>
  )
}

ProjectPreview.displayName = 'ProjectPreview'
