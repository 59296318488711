import { mixed, round } from '@aninix-inc/model'
import _ from 'lodash'
import { stringToMath } from '../string-to-math/string-to-math'

export interface IProps {
  formatBeforeApply: (value: string) => string
  inputValue: string
  initialValue: typeof mixed | number
  min: number
  max: number
}
export function getValueFromInput({
  formatBeforeApply,
  inputValue,
  initialValue,
  min,
  max,
}: IProps) {
  const formatted = formatBeforeApply(inputValue)

  const newValue = _.clamp(stringToMath(formatted), min, max)

  return round(newValue)
}
