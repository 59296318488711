import { Loader } from '@aninix/app-design-system'
import classNames from 'classnames'
import * as React from 'react'
import { Icons } from '../../components/icons'
import { AutoHeightPanel } from '../../components/navigation-bar/create-project/auto-height-panel'
import { HappyIcon } from './icons/happy-icon'
import { QuestionIcon } from './icons/question-icon'
import { useFeelings } from './use-feelings'

type FeelingType =
  | 'not-disappointed'
  | 'somewhat-disappointed'
  | 'very-disappointed'
  | 'skip'

export interface IProps {}
export const FeelingsBanner: React.FCC<IProps> = ({}) => {
  const { isDone, isReadyToShow, onSubmit, errorMessage } = useFeelings()

  const [autoOpen, setAutoOpen] = React.useState(false)

  const [hide, setHide] = React.useState(false)

  const [isLoading, setIsLoading] = React.useState(false)

  const [feeling, setFeeling] = React.useState<FeelingType>()

  const handleCheckboxChange: (
    newFeeling: FeelingType
  ) => React.MouseEventHandler<HTMLInputElement> = (newFeeling) => {
    return (e: React.MouseEvent<HTMLInputElement>) => {
      if (feeling === newFeeling) {
        setFeeling(undefined)
      } else {
        setFeeling(newFeeling)
      }
    }
  }

  const textAreaRef = React.createRef<HTMLTextAreaElement>()

  const handleFinishedClose = () => {
    setAutoOpen(false)
  }

  const handleEarlyClose = () => {
    try {
      onSubmit({ type: feeling ?? 'skip' })
    } catch (e) {}
    setAutoOpen(false)
  }

  const handleSubmit = () => {
    if (feeling == null) return

    setIsLoading(true)

    onSubmit({ type: feeling, comment: textAreaRef?.current?.value })
      .then(() => {
        setTimeout(() => {
          setAutoOpen(false)
        }, 5_000)
      })
      .catch((e) => {
        console.log('caught it')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const handleOpen = () => {
    setTimeout(() => {
      setAutoOpen(true)
    }, 1000)
  }

  React.useEffect(() => {
    if (isReadyToShow) {
      window.addEventListener('render-finished', handleOpen, { once: true })
    }
  }, [isReadyToShow])

  React.useEffect(() => {
    if (isDone)
      setTimeout(() => {
        setAutoOpen(false)

        setTimeout(() => {
          setHide(true)
        }, 500)
      }, 5_000)
  }, [isDone])

  if (hide) return null

  if (!isReadyToShow) return null

  return (
    <div className="rounded-xl w-[240px] bg-white">
      <AutoHeightPanel isOpen={autoOpen} durationMs={500}>
        {isDone && (
          <div className="flex gap-2 items-center flex-col p-4 relative">
            <button
              type="button"
              onClick={handleFinishedClose}
              className="absolute right-3 top-3"
            >
              <Icons.Cross className="stroke-gray-300" />
            </button>

            <div className="relative w-[100px] h-[90px]">
              <div className="absolute inset-0 scale-[0.5] rounded-full bg-[#1DB95C88] blur-2xl" />
              <div className="absolute z-0">
                <HappyIcon />
              </div>
            </div>
            <p className="whitespace-break-spaces text-center font-medium text-sm">
              Feedback is a gift!
            </p>
            <p className="whitespace-break-spaces text-center text-gray-500 text-sm">
              We appreciate the time you took to share it with us!
            </p>
          </div>
        )}
        {!isDone && (
          <div className="flex gap-2 items-center flex-col p-4 relative">
            <button
              type="button"
              onClick={handleEarlyClose}
              className="absolute right-3 top-3"
            >
              <Icons.Cross className="stroke-gray-300" />
            </button>

            <div className="relative w-[100px] h-[90px]">
              <div className="absolute inset-0 scale-[0.5] rounded-full bg-[#FFAA2B88] blur-2xl" />
              <div className="absolute z-0">
                <QuestionIcon />
              </div>
            </div>
            <p className="whitespace-break-spaces text-center font-medium text-sm">
              Imagine you could no longer use Aninix. How would that make you
              feel?
            </p>

            <div className="peer group flex flex-col gap-2 w-full">
              <AutoHeightPanel
                durationMs={500}
                isOpen={feeling == null || feeling === 'not-disappointed'}
              >
                <label className="has-[:checked]:bg-gray-200 has-[:disabled]:opacity-0 transition-opacity duration-700 has-[:disabled]:duration-50 has-[:checked]:ring-2 has-[:checked]:ring-secondary w-full ring-1 text-center line-clamp-1 ring-solid ring-gray-200 rounded-lg p-2 text-sm/[14px] text-secondary px-3 py-2">
                  <input
                    readOnly
                    disabled={feeling != null && feeling !== 'not-disappointed'}
                    checked={feeling === 'not-disappointed'}
                    type="checkbox"
                    hidden
                    onClick={handleCheckboxChange('not-disappointed')}
                  />
                  I wouldn't miss it at all
                </label>
              </AutoHeightPanel>
              <AutoHeightPanel
                durationMs={500}
                isOpen={feeling == null || feeling === 'somewhat-disappointed'}
              >
                <label className="has-[:checked]:bg-gray-200 has-[:disabled]:opacity-0 transition-opacity duration-700 has-[:disabled]:duration-50 has-[:checked]:ring-2 has-[:checked]:ring-secondary w-full ring-1 text-center line-clamp-1 ring-solid ring-gray-200 rounded-lg p-2 text-sm/[14px] text-secondary px-3 py-2">
                  <input
                    readOnly
                    checked={feeling === 'somewhat-disappointed'}
                    type="checkbox"
                    hidden
                    onClick={handleCheckboxChange('somewhat-disappointed')}
                  />
                  I would miss it somewhat
                </label>
              </AutoHeightPanel>
              <AutoHeightPanel
                durationMs={500}
                isOpen={feeling == null || feeling === 'very-disappointed'}
              >
                <label className="has-[:checked]:bg-gray-200 has-[:disabled]:opacity-0 transition-opacity duration-700 has-[:disabled]:duration-50 has-[:checked]:ring-2 has-[:checked]:ring-secondary w-full ring-1 text-center line-clamp-1 ring-solid ring-gray-200 rounded-lg p-2 text-sm/[14px] text-secondary px-3 py-2">
                  <input
                    readOnly
                    checked={feeling === 'very-disappointed'}
                    type="checkbox"
                    hidden
                    onClick={handleCheckboxChange('very-disappointed')}
                  />
                  I would miss it a lot
                </label>
              </AutoHeightPanel>
            </div>

            <div className="w-full">
              <AutoHeightPanel isOpen={feeling != undefined} durationMs={500}>
                <div>
                  <textarea
                    ref={textAreaRef}
                    className="peer h-fit py-2 px-3 text-sm text-secondary w-full border-gray-200 rounded-lg border resize-none"
                    placeholder="Let us know why"
                    rows={5}
                  />
                  <p className="text-xs px-2 py-1 text-balance w-full text-red font-medium text-center">
                    {errorMessage}
                  </p>
                  <button
                    onClick={handleSubmit}
                    type="button"
                    className={classNames(
                      'peer-invalid:bg-gray-300 w-full rounded-lg bg-secondary py-2 px-3 flex justify-center items-center text-sm font-medium text-white',
                      { ['animate-pulse']: isLoading }
                    )}
                  >
                    {!isLoading ? (
                      'Submit feedback'
                    ) : (
                      <Loader size={16} color="#fff" />
                    )}
                  </button>
                </div>
              </AutoHeightPanel>
            </div>
          </div>
        )}
      </AutoHeightPanel>
    </div>
  )
}

FeelingsBanner.displayName = 'FeelingsBanner'
