import * as React from 'react'

const defaultSize = { x: 14, y: 14 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const RightToSlider: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 2.91465C13.5826 2.70873 14 2.15311 14 1.5C14 0.671573 13.3284 0 12.5 0C11.6716 0 11 0.671573 11 1.5C11 2.15311 11.4174 2.70873 12 2.91465V14H13V2.91465ZM1 6C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4H9C9.55229 4 10 4.44772 10 5C10 5.55228 9.55228 6 9 6H1ZM5 11C4.44772 11 4 10.5523 4 10C4 9.44771 4.44772 9 5 9H9C9.55228 9 10 9.44771 10 10C10 10.5523 9.55228 11 9 11H5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
