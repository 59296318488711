import { Entity, getEntryOrThrow, Project } from '@aninix-inc/model'
import { parseSync } from 'svgson'
import { convertNode } from './convert-node'
import { createProject } from './utils/create-project'
import { parseAssets } from './utils/parse-assets'

export function svgToAni(svg: string, externalProject?: undefined): Project
export function svgToAni(svg: string, externalProject: Project): Entity
export function svgToAni(
  svg: string,
  externalProject?: Project
): Project | Entity {
  const root = parseSync(svg, { camelcase: true })

  if (!root || root.name !== 'svg') throw new Error('Invalid SVG')

  const project =
    externalProject ??
    createProject({
      entrySize: {
        x:
          parseInt(root.attributes.width) ||
          parseInt(root.attributes.viewBox.split(' ')[2]),
        y:
          parseInt(root.attributes.height) ||
          parseInt(root.attributes.viewBox.split(' ')[3]),
      },
    })

  const assets = parseAssets(root)

  const entity = convertNode(
    Infinity,
    root,
    assets,
    getEntryOrThrow(project),
    project
  )

  if (!externalProject) return project
  return entity as Entity
}
