/**
 * @param projectDuration current project duration
 * @param trackWidth current track width which contains time changers
 * @param zoom current zoom of timeline
 */
interface ISharedPayload {
  projectDuration: number
  trackWidth: number
}

/**
 * @param time time in seconds to convert
 * @returns pixels
 */
export const convertTimeToPixels = (
  payload: ISharedPayload & { time: number }
) => (payload.time / payload.projectDuration) * payload.trackWidth

/**
 * @param pixels pixels to convert
 * @returns time in seconds
 */
export const convertPixelsToTime = (
  payload: ISharedPayload & { pixels: number }
) => (payload.pixels / payload.trackWidth) * payload.projectDuration
