import * as HME from 'h264-mp4-encoder'
import * as R from 'ramda'

import { LocalLogger, LogLevel } from '@aninix/logger'
import { yieldToMain } from '../../utils'
import { Encoder } from './types'

const logger = new LocalLogger({
  level: process.env.LOG_LEVEL as LogLevel,
  prefix: '[encodeMp4]',
})

export const encodeMp4: Encoder = ({
  iterable,
  options,
  onProgressUpdate,
  onError,
  onDone,
}) => {
  const { fps, size, framesCount } = options
  let encoder: Awaited<ReturnType<typeof HME.createH264MP4Encoder>>
  let isCancelled = false

  const render = async () => {
    try {
      encoder = await HME.createH264MP4Encoder()

      encoder.width = size.x
      encoder.height = size.y
      encoder.frameRate = fps
      encoder.speed = 0
      encoder.quantizationParameter = 10
      encoder.groupOfPictures = 30
      encoder.initialize()

      let i = 0
      for await (const canvas of iterable) {
        if (isCancelled) {
          return
        }
        if (i % 20 === 0) {
          await yieldToMain()
        }
        const start = Date.now()
        const image = canvas
          .getContext('2d')!
          .getImageData(0, 0, canvas.width, canvas.height)
        encoder.addFrameRgba(image.data)
        const end = Date.now()
        onProgressUpdate(R.clamp(0, 1, i / framesCount), end - start)
        logger.log(
          `combining frame ${i}/${framesCount} time in ms`,
          end - start
        )
        i++
      }

      encoder.finalize()

      const uint8Array = encoder.FS.readFile(encoder.outputFilename)
      const blob = new Blob([uint8Array])

      onDone(blob)
    } catch (err: any) {
      onError(
        `Unexpected issue happen. Please send the following info to us: ${err.message}`
      )
      logger.error(err)
    }
  }

  render()

  return () => {
    try {
      isCancelled = true
      encoder?.delete()
    } catch (err: any) {
      logger.warn('[encode mp4] cancelation error', err)
    }
  }
}
