import { NameComponent, Root } from '@aninix-inc/model'
import { useProject } from '@aninix/core'
import * as React from 'react'

export const Breadcrumbs: React.FCC = () => {
  const project = useProject()
  return (
    <div className="bottom-0 top-0 flex items-center justify-center gap-[0.3em] text-sm text-white">
      <p className="opacity-100">
        {
          project
            .getEntityByTypeOrThrow(Root)
            .getComponentOrThrow(NameComponent).value
        }
      </p>
    </div>
  )
}

Breadcrumbs.displayName = 'Breadcrumbs'
