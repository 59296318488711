import type { RGBA } from '@aninix-inc/model/legacy'
import classnames from 'classnames'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import tinycolor from 'tinycolor2'

import * as styles from './index.scss'

export interface IProps {
  color: RGBA
  onStartChange?: () => void
  onChange: (color: RGBA) => void
  onEndChange?: () => void
  onPreviewClick: () => void
}
export const InputHex: React.FCC<IProps> = ({
  color,
  onStartChange,
  onChange,
  onEndChange,
  onPreviewClick,
}) => {
  const [isFocused, setIsFocused] = useState(false)
  const [isPercentFocused, setIsPercentFocused] = useState(false)
  const [localHex, setLocalHex] = useState(tinycolor(color).toHex())
  const [localOpacity, setLocalOpacity] = useState(color.a * 100)

  const normalizedColor = tinycolor(localHex)
  const normalizedOpacity = localOpacity / 100

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      // NOTE: required to correctly handle selection because we change value
      setTimeout(() => {
        e.target.select()
      }, 50)

      onStartChange?.()
      setIsFocused(true)
    },
    [onStartChange]
  )

  const handleBlur = React.useCallback(() => {
    onEndChange?.()
    setIsFocused(false)
  }, [onEndChange])

  const handlePercentFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      handleFocus(e)
      setIsPercentFocused(true)
    },
    [handleFocus]
  )

  const handlePercentBlur = React.useCallback(() => {
    handleBlur()
    setIsPercentFocused(false)
  }, [handleBlur])

  const updateLocalOpacity = React.useCallback((value: number) => {
    setLocalOpacity(Math.round(value * 100) / 100)
  }, [])

  const handleChangeHex = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valueWithOpacity = {
      ...tinycolor(e.target.value).toRgb(),
      a: normalizedOpacity,
    }

    setLocalHex(e.target.value)
    onChange(valueWithOpacity)
  }

  const handleChangeOpacity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRoughOpacity = e.target.value
    const newOpacity = newRoughOpacity === '' ? '0' : newRoughOpacity
    const parsedOpacity = R.clamp(0, 100, parseFloat(newOpacity))

    const valueWithOpacity = {
      ...normalizedColor.toRgb(),
      a: parsedOpacity / 100,
    }

    updateLocalOpacity(parsedOpacity)
    onChange(valueWithOpacity)
  }

  useEffect(() => {
    if (isFocused) {
      return
    }

    setLocalHex(tinycolor(color).toHex())
    updateLocalOpacity(color.a * 100)
  }, [color, isFocused, updateLocalOpacity])

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      const isEsc = e.key.toLowerCase() === 'escape'
      const isEnter = e.key.toLocaleLowerCase() === 'enter'

      // @TODO: implement cancel when esc pressed
      if (isEnter || isEsc) {
        // @ts-ignore
        e.target.blur()
        return
      }
    },
    []
  )

  return (
    <div
      className={classnames(styles.container, {
        [styles['container--focused']]: isFocused,
      })}
    >
      <div
        className={classnames(styles.left, {
          [styles['left--focused']]: isFocused,
        })}
      >
        <button
          type="button"
          className={styles.preview}
          onClick={onPreviewClick}
        >
          <span
            className={styles['preview-left']}
            style={{ backgroundColor: normalizedColor.toString() }}
          />
          <span
            className={styles['preview-right']}
            style={{
              backgroundColor: normalizedColor.toString(),
              opacity: normalizedOpacity,
            }}
          />
        </button>

        <input
          className={styles.input}
          type="text"
          value={localHex.toUpperCase()}
          onChange={handleChangeHex}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
        />
      </div>

      <div className={styles.right}>
        <input
          className={styles.input}
          type="text"
          value={isPercentFocused ? localOpacity : `${localOpacity}%`}
          onChange={handleChangeOpacity}
          onFocus={handlePercentFocus}
          onBlur={handlePercentBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  )
}
