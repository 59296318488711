import { Entity, NameComponent } from '@aninix-inc/model'
import { Base } from './properties/base'
import { Paths } from './properties/paths'
import { ShapeGroup } from './properties/shape-group'
import {
  LottieFillRule,
  LottieLayerType,
  LottieShapeLayer,
  LottieShapeLayerType,
} from './types'

/**
 * @description generates mask for provided node for fills only
 */
export function FillMask(payload: {
  node: Entity
  parent?: number
  skipAnimation?: boolean
  debug?: boolean
  applyMaskByDefault?: boolean
  opacity?: number
  // @NOTE: in range 0...1
  color?: [number, number, number]
}): LottieShapeLayer {
  const {
    node,
    parent,
    skipAnimation,
    debug = false,
    applyMaskByDefault = true,
    opacity = 100,
    color = [0, 1, 0],
  } = payload

  const baseProperties = Base({ node, parent, skipAnimation })
  const paths = Paths({ node })

  const finalLayer: LottieShapeLayer = {
    ty: LottieLayerType.Shape,
    ...baseProperties,
    nm: `${node.getComponentOrThrow(NameComponent).value} - Shape Mask`,
    shapes: [
      ShapeGroup([
        ...paths,
        {
          ty: LottieShapeLayerType.Fill,
          o: {
            a: 0,
            k: debug ? 10 : opacity,
          },
          c: {
            a: 0,
            // @NOTE: alpha channel required to render in react-native on android device
            k: [...color, 1],
          },
          nm: 'Fill',
          hd: false,
          r: LottieFillRule.Nonzero,
        },
      ]),
    ],
  }

  if (debug === false && applyMaskByDefault) {
    finalLayer.td = 1
  }

  return finalLayer
}
