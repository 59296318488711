import {
  Entity,
  ProgressComponent,
  RgbaValueComponent,
} from '@aninix-inc/model'
import * as paper from 'paper'

export const interpolateColor = (
  leftColor: RGBA,
  leftProgress: number,
  rightColor: RGBA,
  rightProgress: number,
  progress: number
): RGBA => {
  if (progress < leftProgress || progress > rightProgress) {
    throw new Error('Progress should be between leftProgress and rightProgress')
  }

  const normalizedProgress =
    (progress - leftProgress) / (rightProgress - leftProgress)

  const lerp = (start: number, end: number, t: number): number => {
    return start + (end - start) * t
  }

  const r = Math.round(lerp(leftColor.r, rightColor.r, normalizedProgress))
  const g = Math.round(lerp(leftColor.g, rightColor.g, normalizedProgress))
  const b = Math.round(lerp(leftColor.b, rightColor.b, normalizedProgress))
  const a = lerp(leftColor.a, rightColor.a, normalizedProgress)
  return { r, g, b, a }
}

export const getColorAtProgress = (
  progress: number,
  colorStops: Entity[]
): RGBA => {
  if (colorStops.length === 0) {
    throw new Error('No color stops')
  }

  if (colorStops.length === 1) {
    return colorStops[0].getComponentOrThrow(RgbaValueComponent).value
  }

  const sortedStops = [...colorStops].sort(
    (a, b) =>
      a.getComponentOrThrow(ProgressComponent).value -
      b.getComponentOrThrow(ProgressComponent).value
  )

  let leftStop = sortedStops[0]
  let rightStop = sortedStops[sortedStops.length - 1]

  for (let i = 0; i < sortedStops.length - 1; i++) {
    const leftProgress =
      sortedStops[i].getComponentOrThrow(ProgressComponent).value
    const rightProgress =
      sortedStops[i + 1].getComponentOrThrow(ProgressComponent).value
    if (progress >= leftProgress && progress <= rightProgress) {
      leftStop = sortedStops[i]
      rightStop = sortedStops[i + 1]
      break
    }
  }

  const leftColor = leftStop.getComponentOrThrow(RgbaValueComponent).value
  const rightColor = rightStop.getComponentOrThrow(RgbaValueComponent).value
  const leftProgress = leftStop.getComponentOrThrow(ProgressComponent).value
  const rightProgress = rightStop.getComponentOrThrow(ProgressComponent).value

  if (progress === leftProgress) return leftColor
  if (progress === rightProgress) return rightColor

  return interpolateColor(
    leftColor,
    leftProgress,
    rightColor,
    rightProgress,
    progress
  )
}

export const normalizeAngle = (angle: number) =>
  ((((angle + 180) % 360) + 360) % 360) - 180

export const getAngle = (p1: paper.Point, p2: paper.Point) => {
  const angle = Math.atan2(p2.y - p1.y, p2.x - p1.x) * (180 / Math.PI)

  return normalizeAngle(angle)
}

export const getClientPoint = (e: React.MouseEvent<SVGElement>) => {
  const svg = e.currentTarget.ownerSVGElement
  if (!svg) return new paper.Point(0, 0)

  const domPoint = svg.createSVGPoint()
  domPoint.x = e.clientX
  domPoint.y = e.clientY
  return new paper.Point(
    domPoint.matrixTransform(svg.getScreenCTM()?.inverse())
  )
}

export const getProgressByClientCoords = (
  e: React.MouseEvent<SVGElement>,
  startPoint: paper.Point,
  endPoint: paper.Point
) => {
  const cursorPoint = getClientPoint(e)

  const vector = endPoint.subtract(startPoint)
  const pointVector = cursorPoint.subtract(startPoint)

  const dotProduct = vector.dot(pointVector)
  const vectorLengthSquared = vector.length ** 2

  let progress = dotProduct / vectorLengthSquared
  progress = Math.max(0, Math.min(1, progress))

  let normalizedProgress = Math.round(progress * 100) / 100
  return normalizedProgress
}
