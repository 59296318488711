import { setTeamAvatar, useSetTeam } from '@aninix/api'
import * as React from 'react'

import { usePageOutletContext } from '../../modules/teams'
import { toast } from '../../modules/toasts'
import { useCurrentSpaceStore, useCurrentTeamStore } from '../../use-cases'
import { Button } from '../design-system/button'
import { AvatarInput } from './avatar-input'
import { TextInputField } from './text-input-field'

export interface IProps {}

export const TeamSettings: React.FCC<IProps> = ({}: IProps) => {
  const [isValidTeamSettings, setIsValidTeamSettings] =
    React.useState<boolean>(false)

  const { badge } = useCurrentSpaceStore()

  const { currentTeam } = useCurrentTeamStore()

  const teamId = currentTeam!.id

  const { name, domain, avatarUrl } = currentTeam!

  const [inputTeamName, setInputTeamName] = React.useState<string | null>(name)
  const [inputDomain, setInputDomain] = React.useState<string | null>(domain)
  const [inputAvatar, setInputAvatar] = React.useState<File | null>(null)
  const [inputAvatarUrl, setInputAvatarUrl] = React.useState<string | null>(
    avatarUrl ?? '/images/defaultTeamAvatar.png'
  )

  const isValidName = !!inputTeamName
  const isValidDomain = !!inputDomain

  const isInfoDifferent = inputTeamName !== name || inputDomain !== domain

  const isInputAvatarNotEmpty = inputAvatar !== null

  const isFormDifferent = isInfoDifferent || isInputAvatarNotEmpty

  React.useEffect(() => {
    setInputTeamName(name)
    setInputDomain(domain)
    setInputAvatarUrl(avatarUrl ?? '/images/defaultTeamAvatar.png')
    setInputAvatar(null)
  }, [name, domain, avatarUrl])

  React.useEffect(() => {
    if (FileReader && inputAvatar) {
      var fr = new FileReader()
      fr.onloadend = function () {
        setInputAvatarUrl(fr.result as string)
      }
      fr.readAsDataURL(inputAvatar)
    }

    //additional validation add here
    const isValid = isValidName && isValidDomain && isFormDifferent // || !!inputAvatar

    setIsValidTeamSettings(isValid)
  }, [inputTeamName, inputDomain, inputAvatar])

  const onAvatarInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputAvatar(e.target.files?.[0] || null)
    },
    []
  )

  const onTeamNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setInputTeamName(e.target.value)
    },
    []
  )

  const onTeamDomainChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const emailDomainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}$/i
      const input = e.target.value
      if (emailDomainRegex.test(input)) setInputDomain(e.target.value)
      else setInputDomain(null)
    },
    []
  )

  const { setTitle, setControls } = usePageOutletContext()

  React.useEffect(() => {
    setTitle('Team settings')
    setControls('None')
  }, [])

  const setTeam = useSetTeam()

  const [isUpdatingInfo, setIsUpdatingInfo] = React.useState(false)

  //@TODO: add avatarUrl later
  const handleSaveTeamSettings = React.useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault()

      if (!isValidTeamSettings) return

      setIsUpdatingInfo(true)

      let infoPromise = isInfoDifferent
        ? setTeam
            .mutateAsync({
              teamId,
              domain:
                domain === inputDomain ? undefined : (inputDomain ?? undefined),
              name:
                name === inputTeamName
                  ? undefined
                  : (inputTeamName ?? undefined),
            })
            .then((value) => {
              toast(`Team information is saved!`, { variant: 'success' })
            })
            .catch((error) => {
              toast(`Couldn't save new team information`, { variant: 'error' })
            })
        : Promise.resolve()

      let avatarPromise = isInputAvatarNotEmpty
        ? setTeamAvatar({
            teamId: teamId,
            avatar: inputAvatar,
          })
            .then((value) => {
              toast(`New avatar is added!`, { variant: 'success' })
            })
            .catch((error) => {
              toast(`Couldn't set new team avatar`, { variant: 'error' })
            })
        : Promise.resolve()

      Promise.allSettled([infoPromise, avatarPromise]).then(() => {
        setIsUpdatingInfo(false)
        badge?.refetch().then(() => {
          setInputAvatar(null)
          setInputAvatarUrl(null)
        })
        setIsValidTeamSettings(false)
      })
    },
    [inputTeamName, inputDomain, inputAvatar, isValidTeamSettings]
  )

  return (
    <form onSubmit={handleSaveTeamSettings}>
      <div className="flex flex-col gap-5">
        <AvatarInput
          inputAvatarUrl={inputAvatarUrl}
          avatarUrl={avatarUrl ?? '/images/defaultTeamAvatar.png'}
          onChange={onAvatarInputChange}
        />

        <TextInputField
          defaultValue={name}
          label={'Team name'}
          onChange={onTeamNameChange}
        />

        <TextInputField
          defaultValue={domain}
          isValid={!!name && !!domain ? isValidDomain : true}
          validationMessage={'Please enter domain in format: domain.com'}
          label={'Domain'}
          onChange={onTeamDomainChange}
        />

        <Button
          buttonType="submit"
          isLoading={isUpdatingInfo}
          isActive={isValidTeamSettings}
          label="Save settings"
        />
      </div>
    </form>
  )
}
