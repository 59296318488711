import {
  Project,
  createComponentsProvider,
  createEntitiesProvider,
  createEntryIfNotExists,
  createSystemsProvider,
} from '@aninix-inc/model'
import { getProjectPatchLimit } from '@aninix/api'
import { useLogger } from '@aninix/logger'
import { Typography } from 'apps/web-app/src/components/design-system/typography'
import classNames from 'classnames'
import * as DateFNS from 'date-fns'
import _ from 'lodash'
import * as React from 'react'
import { useParams } from 'react-router-dom'
import { useGetProjectHistoryVersion } from '../../use-cases/use-get-project-history-version'

export interface IProps {
  id: string
  createdAt: string
  versionNumber: number
  name: string | null
  isCurrent: boolean
  isSelected: boolean
  handleSelection: () => void
  setIsProjectLoading?: React.Dispatch<React.SetStateAction<boolean>>
  setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}
export const VersionEntry: React.FCC<IProps> = ({
  id,
  createdAt,
  versionNumber,
  name,
  isCurrent,
  isSelected,
  handleSelection,
  setIsProjectLoading,
  setProject,
}) => {
  const logger = useLogger()
  const format = React.useMemo(
    () =>
      `MMM d${
        Date.now() - Date.parse(createdAt) > 1000 * 60 * 60 * 24 * 365
          ? ' y'
          : ''
      }, p`,
    []
  )

  const { projectId } = useParams()

  const { result: version, handleFetch: fetchVersion } =
    useGetProjectHistoryVersion({
      projectId: projectId!,
      versionId: id,
    })

  const handleVersionClick = React.useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
      if (isSelected) {
        return
      }

      fetchVersion()
      setIsProjectLoading?.(true)
      handleSelection()
    },
    [isSelected]
  )

  React.useEffect(() => {
    if (version.data === null) {
      return
    }

    getProjectPatchLimit(version.data)
      .then((patch) => {
        const project = new Project({
          componentsProvider: createComponentsProvider(),
          entitiesProvider: createEntitiesProvider(),
          systemsProvider: createSystemsProvider(),
        }).recoverFromPatch(patch)
        createEntryIfNotExists(project)
        setProject(project)
      })
      .catch((err) => {
        logger.error(err)
      })
      .finally(() => {
        setIsProjectLoading?.(false)
      })
  }, [version])

  return (
    <div
      onClick={handleVersionClick}
      className={classNames(
        'relative left-0 right-0 transition-all duration-150',
        {
          ['hover:cursor-pointer hover:bg-accent hover:bg-opacity-5']:
            !isSelected,
        }
      )}
    >
      <div
        className={classNames('relative flex flex-col py-2 pl-7', {
          ['bg-[#F2F2F2] bg-opacity-50']: isSelected,
        })}
      >
        <Typography style="Subscript" className=" text-black text-opacity-30">
          {name ?? DateFNS.format(Date.parse(createdAt), format)}
        </Typography>
        <Typography style="Subscript" className=" text-black text-opacity-80">
          {`Version ${_.padStart(versionNumber.toString(), 3, '0')}`}
        </Typography>
      </div>
      <div className="absolute left-[0.9rem] top-4 h-1 w-1 rounded-full border-[1px] border-[#D9D9D9] bg-white" />
      {isCurrent && (
        <div className="absolute right-2 top-[calc(50%-8px)] rounded-full bg-[#18A0FB]">
          <p className="px-[6px] py-[1px] font-body text-[8px] font-semibold text-white">
            CURRENT
          </p>
        </div>
      )}
    </div>
  )
}

VersionEntry.displayName = 'VersionEntry'
