import {
  InnerRadiusComponent,
  NumberKeyframe,
  getAnimatableValue,
  getValueNumber,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { formatPercents } from '../../keyframes/value'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 16,
  y: 16,
}

export const InnerRadius: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) =>
    n.hasComponent(InnerRadiusComponent)
  )

  const components = filteredNodes.map((l) =>
    l.getComponentOrThrow(InnerRadiusComponent)
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const keyframeType = getKeyframesType(components, time)
  // @TODO replace with snapshot data
  const innerRadiuses = components.map((c) => getAnimatableValue(c, time))

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <InnerRadiusEditable components={components} time={time} />
      ) : (
        <InnerRadiusDisplay
          components={components}
          time={time}
          innerRadiuses={innerRadiuses}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

InnerRadius.displayName = 'InnerRadius'

const InnerRadiusEditable: React.FC<{
  time: number
  components: InnerRadiusComponent[]
}> = ({ components, time }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={components}
            time={time}
            icon={<icons.propertiesPanel.InnerRadius size={iconSize} />}
            before={(value) => value * 100}
            after={(value) => value / 100}
            min={0}
            max={100}
            format={formatPercents}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      }
    />
  )
}

interface InnerRadiusDisplayProps {
  time: number
  components: InnerRadiusComponent[]
  innerRadiuses: number[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (
  prev: InnerRadiusDisplayProps,
  next: InnerRadiusDisplayProps
) => {
  if (prev.innerRadiuses.length !== next.innerRadiuses.length) return false
  if (prev.keyframeType !== next.keyframeType) return false
  for (let i = 0; i < prev.innerRadiuses.length; i++) {
    if (prev.innerRadiuses[i] !== next.innerRadiuses[i]) return false
  }
  return true
}

const InnerRadiusDisplay: React.FC<InnerRadiusDisplayProps> = React.memo(
  ({ components, time }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <NumberValue
              components={components}
              time={time}
              icon={<icons.propertiesPanel.InnerRadius size={iconSize} />}
              before={(value) => value * 100}
              after={(value) => value / 100}
              min={0}
              max={100}
              format={formatPercents}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        }
      />
    )
  },
  propsAreEqual
)

InnerRadiusDisplay.displayName = 'InnerRadiusDisplay'
