import { Snapshot } from '@aninix-inc/model'
import { config } from '../../../apps/web-app/src/config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'

export async function getProjectSnapshot(projectId: string): Promise<Snapshot> {
  const result = await httpClient.get<
    paths['/v2/projects/{projectId}/snapshot']['get']['responses']['200']['content']['application/json']
  >(`${config.apiUrl}/v2/projects/${projectId}/snapshot`)
  return result.data as any
}
