import { useQuery } from '@tanstack/react-query'
import { ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetUserContracts(userId: string) {
  return useQuery({
    queryKey: ['contracts'],
    async queryFn() {
      return httpClient
        .get<
          ApiGetResponse<'/users/{userId}/contracts'>
        >(`/users/${userId}/contracts`)
        .then((response) => response.data)
    },
  })
}
