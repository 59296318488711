import { ImagesStore, getBlobFromImage } from '@aninix/core'
import JsZip from 'jszip'
import * as React from 'react'

import {
  EntityType,
  EntityTypeComponent,
  HashComponent,
  NameComponent,
  Project,
  Root,
} from '@aninix-inc/model'
import { downloadUriAsBlob } from '../helpers/downloadUri'

type Payload = {
  images: ImagesStore
}
export interface IUseDownloadProjectUseCase {
  execute: (payload: {
    project: Project
    type: 'snapshot' | 'patch'
  }) => Promise<void>
}
export const useDownloadProjectUseCase = ({
  images,
}: Payload): IUseDownloadProjectUseCase => {
  const execute: IUseDownloadProjectUseCase['execute'] = React.useCallback(
    async ({ project, type }) => {
      try {
        // @NOTE: compress all files into one *.zip
        const zip = new JsZip()

        const json = type === 'patch' ? project.toJSON() : project.getSnapshot()
        zip.file('index.json', JSON.stringify(json))

        const hashes = project
          .getEntitiesByPredicate(
            (e) =>
              e.getComponentOrThrow(EntityTypeComponent).value ===
                EntityType.Paint && e.hasComponent(HashComponent)
          )
          .map((paint) => paint.getComponentOrThrow(HashComponent).value)
        await images.loadImagesByHashes(hashes)
        for (const hash of hashes) {
          const image = await images.getImage(hash)
          const blob = await getBlobFromImage(image!.source)
          zip.file(`${hash}.png`, blob)
        }

        const archive = await zip.generateAsync({ type: 'blob' })

        downloadUriAsBlob(
          archive,
          `${
            project
              .getEntityByTypeOrThrow(Root)
              .getComponentOrThrow(NameComponent).value
          }.zip`
        )
      } catch (err) {
        console.error('Project dowload failed', err)
      }
    },
    []
  )

  return {
    execute,
  }
}
