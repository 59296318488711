import {
  BlurRadiusComponent,
  Component,
  CornerRadiusComponent,
  DashComponent,
  DashOffsetComponent,
  EndAngleComponent,
  Entity,
  GapComponent,
  InnerRadiusComponent,
  NodeType,
  NodeTypeComponent,
  NumberValueComponent,
  OpacityComponent,
  Point2dValueComponent,
  PointCountComponent,
  PositionComponent,
  ProgressComponent,
  RgbaValueComponent,
  RotationComponent,
  ScaleComponent,
  ShadowOffsetComponent,
  ShadowSpreadComponent,
  SizeComponent,
  SizeLockedComponent,
  SkewComponent,
  SpatialPoint2dValueComponent,
  StartAngleComponent,
  StrokeWeightComponent,
  TargetRelationAspect,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
  getNode,
  round,
  type Constructor,
} from '@aninix-inc/model'
import { PropertyRowV2, icons } from '@aninix/app-design-system'
import * as React from 'react'

import { formatAngleValue } from '../../../../../helpers'
import { LockablePoint2dValue } from '../../values/lockable-point-2d'
import { NumberValue } from '../../values/number'
import { Point2dValue } from '../../values/point-2d'
import { RgbaValue } from '../../values/rgba'
import { SpatialPoint2dValue } from '../../values/spatial-point-2d'

const iconSize = {
  x: 32,
  y: 32,
}

export function fromPercents(number: number): number {
  return number * 100
}

export function toPercents(number: number): number {
  return number / 100
}

// @TODO: extract to helpers
export function formatPercents(number: number): string {
  return `${round(number, { fixed: 0 })}%`
}

export interface IProps {
  keyframes: Entity[]
}
export const Value: React.FCC<IProps> = ({ keyframes }) => {
  const entitiesByTargetComponentConstructor: Map<
    Constructor<Component>,
    Entity[]
  > = keyframes.reduce((acc, keyframe) => {
    const componentTag = keyframe
      .getAspectOrThrow(TargetRelationAspect)
      .getRelationOrThrow()
      .split('/')[1]
    const Constructor = keyframe
      .getProjectOrThrow()
      .componentsProvider.getComponentConstructorByTagName(componentTag)

    if (acc.has(Constructor) === false) {
      acc.set(Constructor, [])
    }

    acc.get(Constructor)?.push(keyframe)
    return acc
  }, new Map<Constructor<Component>, Entity[]>())

  return (
    <>
      {entitiesByTargetComponentConstructor.has(StartAngleComponent) && (
        <PropertyRowV2
          name="Start"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(StartAngleComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              icon={
                <icons.propertiesPanel.Sweep
                  size={{
                    x: 16,
                    y: 16,
                  }}
                />
              }
              format={formatAngleValue}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(EndAngleComponent) && (
        <PropertyRowV2
          name="Sweep"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(EndAngleComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              icon={
                <icons.propertiesPanel.Sweep
                  size={{
                    x: 16,
                    y: 16,
                  }}
                  flipped
                />
              }
              before={fromPercents}
              after={toPercents}
              format={formatPercents}
              // @TODO: move those restrictions directly to model
              min={-100}
              max={100}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(BlurRadiusComponent) && (
        <PropertyRowV2
          name="Blur"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(BlurRadiusComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(RgbaValueComponent) && (
        <PropertyRowV2
          name="Color"
          inputs={
            <div className="flex flex-col">
              {entitiesByTargetComponentConstructor
                .get(RgbaValueComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(RgbaValueComponent)
                )
                .map((component) => (
                  <RgbaValue key={component.id} components={[component]} />
                ))}
            </div>
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(CornerRadiusComponent) && (
        <PropertyRowV2
          name="Corner Radius"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(CornerRadiusComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              icon={<icons.Property type={icons.PropertyType.CornerRadius} />}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(DashComponent) && (
        <PropertyRowV2
          name="Dash"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(DashComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(DashOffsetComponent) && (
        <PropertyRowV2
          name="Dash Offset"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(DashOffsetComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(GapComponent) && (
        <PropertyRowV2
          name="Gap"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(GapComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(OpacityComponent) && (
        <PropertyRowV2
          name="Opacity"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(OpacityComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              icon={<icons.propertiesPanel.Opacity />}
              min={0}
              max={100}
              before={fromPercents}
              after={toPercents}
              format={formatPercents}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(ProgressComponent) && (
        <PropertyRowV2
          name="Progress"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(ProgressComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              // icon={<icons.propertiesPanel.Opacity />}
              min={0}
              max={100}
              before={fromPercents}
              after={toPercents}
              format={formatPercents}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(RotationComponent) && (
        <PropertyRowV2
          name="Rotation"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(RotationComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              icon={<icons.Property type={icons.PropertyType.Rotation} />}
              format={formatAngleValue}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(ScaleComponent) && (
        <PropertyRowV2
          name="Scale"
          inputs={
            <LockablePoint2dValue
              components={entitiesByTargetComponentConstructor
                .get(ScaleComponent)!
                .map((k) => k.getComponentOrThrow(Point2dValueComponent))}
              lockableComponents={entitiesByTargetComponentConstructor
                .get(ScaleComponent)!
                .map((k) =>
                  k
                    .getAspectOrThrow(TargetRelationAspect)
                    .getTargetEntityOrThrow()
                    .getComponentOrThrow(SizeLockedComponent)
                )}
              iconX={<icons.propertiesPanel.ScaleX />}
              iconY={<icons.propertiesPanel.ScaleY />}
              before={fromPercents}
              after={toPercents}
              formatValue={formatPercents}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(SizeComponent) && (
        <PropertyRowV2
          name="Size"
          inputs={
            <LockablePoint2dValue
              components={entitiesByTargetComponentConstructor
                .get(SizeComponent)!
                .map((k) => k.getComponentOrThrow(Point2dValueComponent))}
              lockableComponents={entitiesByTargetComponentConstructor
                .get(SizeComponent)!
                .map((k) =>
                  k
                    .getAspectOrThrow(TargetRelationAspect)
                    .getTargetEntityOrThrow()
                    .getComponentOrThrow(SizeLockedComponent)
                )}
              iconX={<span>W</span>}
              iconY={<span>H</span>}
              minX={0}
              minY={0}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(SkewComponent) && (
        <PropertyRowV2
          name="Skew"
          inputs={
            <Point2dValue
              components={entitiesByTargetComponentConstructor
                .get(SkewComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(Point2dValueComponent)
                )}
              iconX={<icons.propertiesPanel.SkewX />}
              iconY={<icons.propertiesPanel.SkewY />}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(PositionComponent) && (
        <PropertyRowV2
          name="Position"
          inputs={
            <SpatialPoint2dValue
              components={entitiesByTargetComponentConstructor
                .get(PositionComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(SpatialPoint2dValueComponent)
                )}
              iconX={<span>X</span>}
              iconY={<span>Y</span>}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(StrokeWeightComponent) && (
        <PropertyRowV2
          name="Stroke Width"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(StrokeWeightComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              icon={<icons.StrokeWeight size={iconSize} />}
              min={0}
              max={100}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(ShadowOffsetComponent) && (
        <PropertyRowV2
          name="Offset"
          inputs={
            <Point2dValue
              components={entitiesByTargetComponentConstructor
                .get(ShadowOffsetComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(Point2dValueComponent)
                )}
              iconX={<span>X</span>}
              iconY={<span>Y</span>}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(ShadowSpreadComponent) && (
        <PropertyRowV2
          name="Spread"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(ShadowSpreadComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(TrimStartComponent) && (
        <PropertyRowV2
          name="Trim Start"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(TrimStartComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              min={0}
              max={100}
              before={fromPercents}
              after={toPercents}
              format={formatPercents}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(TrimEndComponent) && (
        <PropertyRowV2
          name="Trim End"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(TrimEndComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              min={0}
              max={100}
              before={fromPercents}
              after={toPercents}
              format={formatPercents}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(TrimOffsetComponent) && (
        <PropertyRowV2
          name="Trim Offset"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(TrimOffsetComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              min={-100}
              max={100}
              before={fromPercents}
              after={toPercents}
              format={formatPercents}
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(PointCountComponent) && (
        <PropertyRowV2
          name="Point Count"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(PointCountComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              min={3}
              icon={
                <icons.Property
                  type={
                    getNode(
                      entitiesByTargetComponentConstructor.get(
                        PointCountComponent
                      )![0]
                    )?.getComponentOrThrow(NodeTypeComponent).value ===
                    NodeType.Polygon
                      ? icons.PropertyType.PolygonPointCount
                      : icons.PropertyType.StarPointCount
                  }
                />
              }
            />
          }
        />
      )}

      {entitiesByTargetComponentConstructor.has(InnerRadiusComponent) && (
        <PropertyRowV2
          name="Ratio"
          inputs={
            <NumberValue
              components={entitiesByTargetComponentConstructor
                .get(InnerRadiusComponent)!
                .map((keyframe) =>
                  keyframe.getComponentOrThrow(NumberValueComponent)
                )}
              icon={<icons.Property type={icons.PropertyType.InnerRadius} />}
              min={0}
              max={100}
              before={fromPercents}
              after={toPercents}
              format={formatPercents}
            />
          }
        />
      )}
    </>
  )
}
