import { LottieShape, LottieShapeGroup, LottieShapeLayerType } from '../types'

/**
 * @description Create new shape layer group from provided shapes.
 * Add default transform properties to it.
 */
export function ShapeGroup(shapes: LottieShape[]): LottieShapeGroup {
  const includesTransform = shapes.some(
    (shape) => shape.ty === LottieShapeLayerType.Transform
  )

  const shapeGroup = {
    ty: LottieShapeLayerType.Group as const,
    nm: 'Group',
    hd: false,
    np: shapes.length + 1,
    it: shapes,
  }

  if (!includesTransform) {
    shapeGroup.it.push({
      ty: LottieShapeLayerType.Transform,
      a: {
        a: 0,
        k: [0, 0],
      },
      p: {
        a: 0,
        k: [0, 0],
      },
      s: {
        a: 0,
        k: [100, 100],
      },
      sk: {
        a: 0,
        k: 0,
      },
      sa: {
        a: 0,
        k: 0,
      },
      r: {
        a: 0,
        k: 0,
      },
      o: {
        a: 0,
        k: 100,
      },
    })
  }

  return shapeGroup
}
