import * as React from 'react'

import * as styles from './index.scss'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const FrameLoading: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 24L10 22H8V21H10L10 11H8V10H10L10 8H11V10H21L21 8H22L22 10H24V11H22L22 14L21 14L21 11H11L11 21H14V22H11V24H10Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />

    <path
      d="M20 24C22.2091 24 24 22.2091 24 20C24 17.7909 22.2091 16 20 16C17.7909 16 16 17.7909 16 20"
      stroke="var(--figma-color-text-brand, rgba(0, 0, 0, 0.8))"
      className={styles.loading}
    />
  </svg>
)
