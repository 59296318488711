import { evaluate } from 'mathjs'

// @TODO: move to correct place in file system
/**
 * @description evaluate string with math expressions to number
 */
export function stringToMath(string: string): number {
  // @NOTE: case when user type `10f` to put frames
  if (string.includes('f') || string.includes('frames')) {
    return parseInt(string, 10)
  }

  // @NOTE: case when user type '45deg' to put degrees
  if (string.includes('deg') || string.includes('degrees')) {
    return parseFloat(string)
  }

  try {
    const result = evaluate(string.replaceAll(',', '.'))
    return result
  } catch (err) {
    console.warn('[stringToMath] error:', err)
    return 0
  }
}
