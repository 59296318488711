import { AxiosInstance } from 'axios'
import { config } from './config'
import { httpClient as defaultHttpClient } from './http-client'
import { paths } from './open-api/schema'

/**
 * Abstraction allows to work with assets
 */
export type Asset = {
  /**
   * Check if asset is already exists
   */
  isExists: () => Promise<boolean>

  /**
   * Write data to the asset
   */
  write: (buffer: ArrayBuffer | Uint8Array) => Promise<void>
}

export class ApiAsset implements Asset {
  constructor(
    private readonly hash: string,
    private readonly httpClient: AxiosInstance = defaultHttpClient
  ) {}

  isExists: Asset['isExists'] = async () =>
    this.httpClient
      .get(`/assets/${this.hash}`)
      .then(() => true)
      .catch(() => false)

  write: Asset['write'] = async (asset) => {
    const { data } = await this.httpClient.post<
      paths['/assets/{hash}/manual-upload']['post']['responses']['201']['content']['application/json']
    >(`/assets/${this.hash}/manual-upload`)

    if (config.env === 'local') {
      const formData = new FormData()
      formData.append('file', new Blob([asset]))
      await fetch(data.url, {
        method: 'PUT',
        body: formData,
      })
      return
    }

    await fetch(data.url, {
      method: 'PUT',
      body: asset,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
  }
}
