import { Point2D } from '@aninix-inc/model/legacy'
import { usePlayback, useViewport } from '@aninix/core'
import { useGetComments } from '@aninix/core/use-cases/comments/use-get-comments'
import { usePostNewComment } from '@aninix/core/use-cases/comments/use-post-new-comment'
import { CircularProgress, InputBase } from '@material-ui/core'
import { toast } from 'apps/web-app/src/modules/toasts'
import classNames from 'classnames'
import * as React from 'react'

import { useCommentContext } from './use-cases/use-comment-context'

export interface IProps {
  defaultValue?: string
  position: Point2D
  isShowing: boolean
  setIsReadyToCreateComment: React.Dispatch<React.SetStateAction<boolean>>
}
export const CreateComment: React.FCC<IProps> = ({
  defaultValue,
  position,
  isShowing,
  setIsReadyToCreateComment,
}) => {
  const playback = usePlayback()
  const viewport = useViewport()

  const [input, setInput] = React.useState(defaultValue ?? '')
  const [isLoading, setIsLoading] = React.useState(false)

  const formRef = React.useRef<HTMLFormElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)

  const { setSelectedCommentId } = useCommentContext()

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setInput('')

      setIsLoading(true)

      postCommentQuery
        .mutateAsync({
          comment: input,
          rectangle: { ...position, width: 0, height: 0 },
          time: Math.floor(playback.time * 100) / 100,
        })
        .then((value) => {
          getCommentsQuery?.refetch()
          setIsReadyToCreateComment(false)
        })
        .catch((e) => {
          toast(
            <p>
              Couldn't post comment. Try again later or{' '}
              <a
                className="text-blue-500 underline"
                href="mailto:info@aninix.com"
              >
                contact us
              </a>
            </p>,
            { variant: 'error' }
          )
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [setInput, input, playback]
  )

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.currentTarget.value !== '\n') setInput(e.currentTarget.value)
      else setInput('')
    },
    [setInput]
  )

  const handleFormKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.metaKey && e.key === 'Enter' && input.length > 0)
        formRef?.current?.requestSubmit()
    },
    [formRef, input]
  )

  React.useEffect(() => {
    setTimeout(() => {
      if (!formRef.current) return

      if (isShowing) formRef.current.querySelector('textarea')?.focus()
      else formRef.current.querySelector('textarea')?.blur()
    }, 0)
  }, [isShowing, formRef])

  // const { query: postCommentQuery } = usePostComment()
  const { query: postCommentQuery } = usePostNewComment()
  const { query: getCommentsQuery } = useGetComments({
    isStartingLoading: false,
  })

  return (
    <div
      className={classNames(
        'relative z-[200] h-1 w-1 transition-opacity  duration-200',
        { ['opacity-0']: !isShowing, ['delay-75']: isShowing }
      )}
      style={{
        left: position.x * viewport.zoom,
        top: position.y * viewport.zoom,
      }}
    >
      <div
        className={classNames(' absolute bottom-0 left-0', {
          ['pointer-events-auto']: isShowing,
        })}
      >
        <div
          className={classNames(
            'w-[240px] origin-bottom-left shadow-md transition-all duration-150',
            {
              [' -translate-x-1 -translate-y-1 scale-50']: !isShowing,
              ['delay-75']: isShowing,
            }
          )}
        >
          <form
            ref={formRef}
            onKeyDown={handleFormKeyDown}
            className="w-full"
            onSubmit={handleSubmit}
          >
            <InputBase
              value={input}
              onChange={handleInputChange}
              className="min-h-10 flex cursor-default flex-row items-end rounded bg-white pb-3 pl-4 text-xs font-normal"
              endAdornment={
                <button
                  disabled={input.length === 0}
                  className={classNames(
                    'relative -mb-1 flex items-center justify-center rounded-full bg-white pr-2'
                  )}
                >
                  <div
                    className={classNames(
                      'absolute inset-0 flex items-center justify-center pr-2 transition-opacity duration-200',
                      {
                        ['opacity-0']: !isLoading,
                      }
                    )}
                  >
                    <CircularProgress size={24} />
                  </div>
                  <div
                    className={classNames(
                      'absolute inset-0 fill-accent transition-opacity duration-200',
                      {
                        ['opacity-0']: input.length === 0,
                      }
                    )}
                  >
                    {arrowUpIcon}
                  </div>
                  <div
                    className={classNames(
                      'fill-[#D9D9D9] transition-opacity duration-200',
                      {
                        ['opacity-0']: isLoading,
                      }
                    )}
                  >
                    {arrowUpIcon}
                  </div>
                </button>
              }
              placeholder="Add a comment"
              fullWidth
              type="text"
              multiline
              maxRows={4}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

const arrowUpIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.3536 5.64645L12 5.29289L11.6464 5.64645L6.64645 10.6464L7.35355 11.3536L11.5 7.20711V18H12.5V7.20711L16.6464 11.3536L17.3536 10.6464L12.3536 5.64645Z"
    />
  </svg>
)

CreateComment.displayName = 'CreateComment'
