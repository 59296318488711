import { icons } from '@aninix/app-design-system'
import * as R from 'ramda'
import * as React from 'react'

import {
  Entity,
  EntityType,
  TargetRelationAspect,
  TimeComponent,
  UpdatesSystem,
  cleanupKeyframes,
  commitUndo,
  lerp,
} from '@aninix-inc/model'
import { InputWithIcon, PropertyRowV2 } from '@aninix/app-design-system'
import { getSelection, useFlattenEntities, useSettings } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import { useFormatTime, useFormattedTime } from '../../formatted-time'
import { useThreshold } from '../../threshold'

export interface IProps {
  segments: [Entity, Entity][]
}
export const Duration: React.FCC<IProps> = observer(({ segments }) => {
  useFlattenEntities(segments)
  const user = useSettings()
  const project = segments[0][0].getProjectOrThrow()
  const updates = project.getSystemOrThrow(UpdatesSystem)
  const times = segments
    .flat()
    .map((keyframe) => keyframe.getComponentOrThrow(TimeComponent).value)
  const startTime = Math.min(...times)
  const endTime = Math.max(...times)
  const { value, suffix } = useFormattedTime(endTime - startTime)
  const { toSeconds } = useFormatTime()
  const keyframes = getSelection(project, EntityType.Keyframe)
  const keyframesByProperties = R.values(
    R.groupBy(
      (keyframe) =>
        keyframe.getAspectOrThrow(TargetRelationAspect).getRelationOrThrow(),
      keyframes
    )
  )
  const threshold = useThreshold()

  const updateValue = React.useCallback(
    (duration: number): void => {
      updates.batch(() => {
        const durationInSeconds = toSeconds(duration)
        keyframes.forEach((keyframe) => {
          const newValue = lerp(
            (keyframe.getComponentOrThrow(TimeComponent).value - startTime) /
              (endTime - startTime),
            startTime,
            startTime + durationInSeconds
          )
          keyframe.updateComponent(TimeComponent, newValue)
        })
      })
    },
    [startTime, endTime, keyframes, updates, toSeconds]
  )

  const updateByDelta = React.useCallback(
    (delta: number) => {
      updates.batch(() => {
        const roundedDelta = toSeconds(delta)

        // NOTE: dirty fix related to the ANI-1609.
        // Required to prevent keyframes to be processed twice in cases when we have 3 keyframes [x, y, z].
        // In that case we process `y` keyframe twice. For the first segment and for the second segment.
        const processedKeys = new WeakSet<Entity>()
        keyframesByProperties.forEach((keyframesByProperty) => {
          const startTime = R.head(keyframesByProperty!)!.getComponentOrThrow(
            TimeComponent
          ).value
          const duration =
            R.last(keyframesByProperty!)!.getComponentOrThrow(TimeComponent)
              .value - startTime
          keyframesByProperty!.forEach((key) => {
            if (processedKeys.has(key)) {
              return
            }

            processedKeys.add(key)
            const progress =
              (key.getComponentOrThrow(TimeComponent).value - startTime) /
              duration
            const minDelta = 0.01 * progress
            const newTime =
              key.getComponentOrThrow(TimeComponent).value +
              roundedDelta * progress
            const clampedTime = R.max(startTime + minDelta, newTime)
            key.updateComponent(TimeComponent, clampedTime)
          })
        })
      })
    },
    [keyframesByProperties, updates, toSeconds]
  )

  const segmentIds = segments.map((segment) => [segment[0].id, segment[1].id])
  const onEndChange = React.useCallback(() => {
    cleanupKeyframes(project)
    commitUndo(project)
  }, [project])

  const id = React.useMemo(
    () => `${segmentIds[0]}-${segmentIds[1]}-duration`,
    [segmentIds]
  )

  const formatValue = React.useCallback(
    (providedValue: number): string => `${providedValue}${suffix}`,
    [suffix]
  )

  const formatBeforeApply = React.useCallback(
    (value: string): string =>
      value.replace(/[^0-9,.fms\+\-\/*\(\)\^]{1}/g, () => ''),
    []
  )

  return (
    <PropertyRowV2
      name="Duration"
      inputs={
        <InputWithIcon
          id={id}
          value={value}
          icon={<icons.Time />}
          threshold={threshold}
          onChange={updateValue}
          onDeltaChange={updateByDelta}
          min={0.01}
          onEndChange={onEndChange}
          format={formatValue}
          formatBeforeApply={formatBeforeApply}
          localFormat={formatBeforeApply}
        />
      }
    />
  )
})

Duration.displayName = 'Duration'
