import { type INode } from 'svgson'
import { Assets, Filter, Gradient } from '../types'
import { applyColorMatrix } from './apply-color-matrix'
import { parseColor } from './parse-color'

export const parseAssets = (node: INode): Assets => {
  const filters: Record<string, Filter[]> = {}
  const gradients: Record<string, Gradient> = {}

  const parseFilter = (node: INode) => {
    const filterId = node.attributes.id
    if (!filterId) return

    let radius: number | null = null
    let offsetX: number | null = null
    let offsetY: number | null = null
    let colorMatrix: string | null = null
    let direction: 'in' | 'out' | null = null
    let spread: number | null = null

    for (const fe of node.children) {
      switch (fe.name) {
        case 'feBlend':
          if (
            (fe.attributes.result?.includes('dropShadow') ||
              fe.attributes.result?.includes('innerShadow')) &&
            colorMatrix &&
            typeof radius === 'number'
          ) {
            filters[filterId] = [
              ...(filters[filterId] ?? []),
              {
                type: 'shadow',
                direction: direction ?? 'out',
                x: offsetX ?? 0,
                y: offsetY ?? 0,
                radius: radius * 2,
                color: applyColorMatrix(
                  { r: 0, g: 0, b: 0, a: 1 },
                  colorMatrix
                ),
                ...(spread ? { spread } : {}),
              },
            ]
            radius = null
            offsetX = null
            offsetY = null
            colorMatrix = null
            direction = null
            spread = null
          }
          break

        case 'feGaussianBlur':
          if (
            fe.attributes.in === 'BackgroundImageFix' ||
            fe.attributes.result?.includes('foregroundBlur')
          ) {
            filters[filterId] = [
              ...(filters[filterId] ?? []),
              {
                type: 'blur',
                blurType: fe.attributes.result?.includes('foregroundBlur')
                  ? 'layer'
                  : 'background',
                radius: parseFloat(fe.attributes.stdDeviation) * 2,
              },
            ]
          } else {
            radius = parseFloat(fe.attributes.stdDeviation)
          }
          break

        case 'feOffset':
          offsetX = parseFloat(fe.attributes.dx) || 0
          offsetY = parseFloat(fe.attributes.dy) || 0
          break

        case 'feColorMatrix':
          colorMatrix = fe.attributes.values
          break

        case 'feComposite':
          direction = fe.attributes.operator === 'arithmetic' ? 'in' : 'out'
          break
      }
    }
  }

  const parseGradient = (node: INode) => {
    const gradientId = node.attributes.id
    if (!gradientId) return

    if (node.name !== 'linearGradient' && node.name !== 'radialGradient') return

    const stops: Gradient['stops'] = []

    for (const stop of node.children) {
      if (stop.name === 'stop') {
        const color = stop.attributes.style
          ? stop.attributes.style
          : stop.attributes.stopColor
        stops.push({
          progress: parseFloat(stop.attributes.offset),
          color: parseColor(color),
        })
      }
    }

    gradients[gradientId] = {
      type: node.name === 'linearGradient' ? 'linear' : 'radial',
      stops,
    }
  }

  const parse = (node: INode) => {
    if (node.name === 'filter') {
      parseFilter(node)
      return
    } else if (
      node.name === 'linearGradient' ||
      node.name === 'radialGradient'
    ) {
      parseGradient(node)
      return
    }

    node.children.forEach(parse)
  }

  parse(node)

  return { filters, gradients }
}
