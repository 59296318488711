import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'

import { PresetAnimationAppearanceShadowType } from '../../../models/preset'
import * as styles from './index.scss'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  type: PresetAnimationAppearanceShadowType
  size?: Point2D
}
export const Shadow: React.FCC<IProps> = ({ type, size = defaultSize }) => {
  if (type === PresetAnimationAppearanceShadowType.Drop) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 8C4.5 6.067 6.06701 4.5 8 4.5C9.933 4.5 11.5 6.067 11.5 8C11.5 9.933 9.933 11.5 8 11.5C6.06701 11.5 4.5 9.933 4.5 8ZM8 3.5C5.51472 3.5 3.5 5.51472 3.5 8C3.5 10.4853 5.51472 12.5 8 12.5C10.4853 12.5 12.5 10.4853 12.5 8C12.5 5.51472 10.4853 3.5 8 3.5ZM4.33346 12.0996C4.1277 11.9155 3.8116 11.933 3.62744 12.1388C3.44327 12.3445 3.46078 12.6606 3.66655 12.8448C4.81619 13.8737 6.33556 14.5 8 14.5C9.66445 14.5 11.1838 13.8737 12.3335 12.8448C12.5392 12.6606 12.5567 12.3445 12.3726 12.1388C12.1884 11.933 11.8723 11.9155 11.6665 12.0996C10.6932 12.9708 9.40897 13.5 8 13.5C6.59104 13.5 5.30685 12.9708 4.33346 12.0996Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceShadowType.Inner) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C11.0376 2.5 13.5 4.96243 13.5 8C13.5 11.0376 11.0376 13.5 8 13.5C4.96243 13.5 2.5 11.0376 2.5 8ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5ZM5.37487 5.68504C6.01693 4.9576 6.95471 4.5 8 4.5C9.04529 4.5 9.98307 4.9576 10.6251 5.68504C10.8079 5.89208 11.1238 5.91178 11.3309 5.72904C11.5379 5.54631 11.5576 5.23034 11.3749 5.02331C10.551 4.08991 9.3441 3.5 8 3.5C6.6559 3.5 5.44896 4.08991 4.62513 5.02331C4.4424 5.23034 4.4621 5.54631 4.66913 5.72904C4.87617 5.91178 5.19214 5.89208 5.37487 5.68504Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  if (type === PresetAnimationAppearanceShadowType.None) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.5 8C2.5 4.96243 4.96243 2.5 8 2.5C9.33875 2.5 10.5658 2.97831 11.5195 3.77337L3.77337 11.5195C2.97831 10.5658 2.5 9.33875 2.5 8ZM4.48047 12.2266C5.43422 13.0217 6.66125 13.5 8 13.5C11.0376 13.5 13.5 11.0376 13.5 8C13.5 6.66125 13.0217 5.43422 12.2266 4.48047L4.48047 12.2266ZM8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5Z"
          className={styles.icon__fill}
        />
      </svg>
    )
  }

  return null
}
