import { useMutation } from '@tanstack/react-query'

import { ApiPostCreatedResponse, ApiPostRequestBody } from './helpers'
import { httpClient } from './http-client'

export function useCreateFolder() {
  return useMutation({
    async mutationFn(payload: ApiPostRequestBody<'/folders'>) {
      return httpClient.post<ApiPostCreatedResponse<'/folders'>>(
        '/folders',
        payload
      )
    },
  })
}
