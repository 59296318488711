import { Loader } from '@aninix/app-design-system'
import * as React from 'react'

import * as styles from './archiving.scss'

export interface IProps {}
export const ArchivingView: React.FCC<IProps> = () => (
  <div className={styles.container}>
    <p className={styles.title}>Archiving...</p>

    <p className={styles.description}>
      <Loader size={16} />
      <span>It can take a few minutes.</span>
    </p>
  </div>
)
