import { useGetComments } from '@aninix/core/use-cases/comments/use-get-comments'
import { usePostCommentResponse } from '@aninix/core/use-cases/comments/use-post-comment-response'
import { CircularProgress, InputBase } from '@material-ui/core'
import { toast } from 'apps/web-app/src/modules/toasts'
import { useCurrentSpaceStore } from 'apps/web-app/src/use-cases'
import classNames from 'classnames'
import * as React from 'react'

import { useCommentContext } from './use-cases/use-comment-context'

export interface IProps {
  parentCommentId: string
  defaultValue?: string
}
export const ReplyToThreadFooter: React.FCC<IProps> = ({
  parentCommentId,
  defaultValue,
}) => {
  const { currentUser } = useCurrentSpaceStore()

  const [input, setInput] = React.useState(defaultValue ?? '')
  const [isLoading, setIsLoading] = React.useState(false)

  const formRef = React.useRef<HTMLFormElement>(null)

  const { selectedCommentId } = useCommentContext()

  const isShowing = selectedCommentId === parentCommentId

  const handleSubmit = React.useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      setInput('')

      setIsLoading(true)

      postCommentQuery
        .mutateAsync({ comment: input, parentCommentId })
        .then((v) => {
          getCommentsQuery?.refetch()
        })
        .catch((e) => {
          toast(
            <p>
              Couldn't post comment. Try again later or{' '}
              <a
                className="text-blue-500 underline"
                href="mailto:info@aninix.com"
              >
                contact us
              </a>
            </p>,
            { variant: 'error' }
          )
        })
        .finally(() => {
          setIsLoading(false)
        })

      // console.log('submit')
    },
    [setInput, input]
  )

  const handleInputChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      if (e.currentTarget.value !== '\n') setInput(e.currentTarget.value)
      else setInput('')
    },
    [setInput]
  )

  const handleFormKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLFormElement>) => {
      if (e.metaKey && e.key === 'Enter' && input.length > 0)
        formRef?.current?.requestSubmit()
    },
    [formRef, input]
  )

  const { query: postCommentQuery } = usePostCommentResponse()
  const { query: getCommentsQuery } = useGetComments({
    isStartingLoading: false,
  })

  React.useEffect(() => {
    setTimeout(() => {
      if (!formRef.current) return

      if (isShowing) formRef.current.querySelector('textarea')?.focus()
      else formRef.current.querySelector('textarea')?.blur()
    }, 300)
  }, [isShowing, formRef])

  return (
    <div className="relative flex w-full flex-row items-center gap-2 px-4 pb-4 pt-2">
      <div className="absolute left-0 right-0 top-0"></div>
      <img
        draggable={false}
        src={currentUser?.avatarUrl ?? '/images/defaultAvatar.png'}
        className="h-6 w-6 rounded-full"
        crossOrigin="anonymous"
      />

      <form
        ref={formRef}
        onKeyDown={handleFormKeyDown}
        className="w-full"
        onSubmit={handleSubmit}
      >
        <InputBase
          disabled={isLoading}
          value={input}
          onChange={handleInputChange}
          className="min-h-10 flex flex-row items-end rounded bg-neutral-100 pb-3 pl-4 text-xs font-normal"
          endAdornment={
            <button
              disabled={input.length === 0}
              className={classNames(
                'relative -mb-1 flex cursor-default items-center justify-center rounded-full bg-[#F5F5F5] pr-2'
              )}
            >
              <div
                className={classNames(
                  'absolute inset-0 flex items-center justify-center pr-2 transition-opacity duration-200',
                  {
                    ['opacity-0']: !isLoading,
                  }
                )}
              >
                <CircularProgress size={24} />
              </div>
              <div
                className={classNames(
                  'absolute inset-0 fill-[#18A0FB] transition-opacity duration-200',
                  {
                    ['opacity-0']: input.length === 0 || isLoading,
                  }
                )}
              >
                {arrowUpIcon}
              </div>
              <div
                className={classNames(
                  'fill-[#D9D9D9] transition-opacity duration-200',
                  {
                    ['opacity-0']: isLoading,
                  }
                )}
              >
                {arrowUpIcon}
              </div>
            </button>
          }
          placeholder="Reply"
          fullWidth
          type="text"
          multiline
          maxRows={4}
        />
      </form>
    </div>
  )
}

const arrowUpIcon = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM12.3536 5.64645L12 5.29289L11.6464 5.64645L6.64645 10.6464L7.35355 11.3536L11.5 7.20711V18H12.5V7.20711L16.6464 11.3536L17.3536 10.6464L12.3536 5.64645Z"
    />
  </svg>
)

ReplyToThreadFooter.displayName = 'ReplyToThreadFooter'
