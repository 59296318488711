export async function loadInlineFonts(url: string): Promise<string> {
  if (!checkBrowserRequirements()) {
    throw new Error(
      'Sorry, current browser does not support required features.'
    )
  }

  if (!verifyURL(url)) {
    throw new Error('Invalid Google Fonts CSS URL')
  }

  try {
    const cssText = await fetchCSS(url)
    return await embedFonts(cssText)
  } catch (error) {
    console.error('Error loading inline fonts:', error)
    throw error
  }
}

function checkBrowserRequirements(): boolean {
  return (
    typeof window.fetch !== 'undefined' &&
    typeof window.FileReader !== 'undefined' &&
    typeof window.URL !== 'undefined'
  )
}

function verifyURL(url: string): boolean {
  return url.startsWith('https://fonts.googleapis.com/')
}

async function fetchCSS(url: string): Promise<string> {
  const response = await fetch(url)
  return response.text()
}

async function embedFonts(cssText: string): Promise<string> {
  const fontLocations = cssText.match(/https:\/\/[^)]+/g) || []
  let modifiedCssText = cssText
  const fontLoadedPromises = fontLocations.map(async (location) => {
    const response = await fetch(location)
    const blob = await response.blob()
    const dataUrl = await blobToDataURL(blob)
    modifiedCssText = modifiedCssText.replace(
      new RegExp(location, 'g'),
      dataUrl
    )
    return [location, dataUrl]
  })

  await Promise.all(fontLoadedPromises)
  return modifiedCssText
}

function blobToDataURL(blob: Blob): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = reject
    reader.readAsDataURL(blob)
  })
}
