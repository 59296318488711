import * as React from 'react'

import { useCurrentRowContext } from './use-current-row-context'

export const Status: React.FCC = () => {
  const { status } = useCurrentRowContext()

  if (status == null) {
    return null
  }

  switch (status) {
    case 'active':
      return (
        <div className="flex min-w-[6rem] flex-row items-center gap-2">
          <div className="h-2 w-2 rounded-full bg-green"></div>
          <p className="font-body text-base font-normal text-green">
            {'Active'}
          </p>
        </div>
      )
    case 'invite_pending':
      return (
        <div className="flex min-w-[6rem] flex-row items-center gap-2">
          <div className="h-2 w-2 rounded-full bg-yellow"></div>
          <p className="font-body text-base font-normal text-yellow">
            {'Invited'}
          </p>
        </div>
      )
    case 'suspended':
      return (
        <div className="flex min-w-[6rem] flex-row items-center gap-2">
          <div className="h-2 w-2 rounded-full bg-gray-300"></div>
          <p className="font-body text-base font-normal text-gray-300">
            {'Suspended'}
          </p>
        </div>
      )
    default:
      const never: never = status
      throw new Error(never)
  }
}

Status.displayName = 'Status'
