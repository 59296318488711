import React from 'react'

import { Collapsed } from '../../../../../icons/expand-children/Collapsed'
import { Expanded } from '../../../../../icons/expand-children/Expanded'
import * as styles from './index.scss'

export interface IProps {
  folderName: string
  onOpen: (folderName: string) => void
  isOpen: boolean
}
export const Folder: React.FCC<IProps> = ({ folderName, onOpen, isOpen }) => (
  <div className={styles.folder}>
    <button
      type="button"
      className={styles['folder-wrapper']}
      onClick={() => onOpen(folderName)}
    >
      <div className={styles.icon}>{isOpen ? <Expanded /> : <Collapsed />}</div>

      <div className={styles['folder-name']}>{folderName}</div>
    </button>
  </div>
)
