import { Entity, StrokeWeightComponent, getSize } from '@aninix-inc/model'
import {
  LottieFillRule,
  LottieShapeGroup,
  LottieShapeLayerType,
} from '../types'
import { ShapeGroup } from './shape-group'

// @NOTE: this fix required to properly render shapes with trim paths or effects
export function EmptyGroup(node: Entity): LottieShapeGroup {
  const size = getSize(node)
  const strokeWeight = node.getComponentOrThrow(StrokeWeightComponent).value
  return ShapeGroup([
    {
      ty: LottieShapeLayerType.Rectangle,
      nm: 'Rectangle',
      hd: false,
      p: {
        a: 0,
        k: [(size.x + strokeWeight) / 2, (size.y + strokeWeight) / 2],
      },
      s: {
        a: 0,
        k: [(size.x + strokeWeight) * 2, (size.y + strokeWeight) * 2],
      },
      r: {
        a: 0,
        k: 0,
      },
    },
    {
      ty: LottieShapeLayerType.Fill,
      o: {
        a: 0,
        k: 0,
      },
      c: {
        a: 0,
        // @NOTE: alpha channel required to render in react-native on android device
        k: [0, 1, 0, 1],
      },
      nm: 'Fill',
      hd: false,
      r: LottieFillRule.Nonzero,
    },
  ])
}
