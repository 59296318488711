import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Maximize: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3334 9.5H9.83337V10V22V22.5H10.3334H22.3334H22.8334V22V10V9.5H22.3334H10.3334ZM10.8334 21.5V10.5H21.8334V21.5H10.8334ZM12.3334 13.5H20.3334V12.5H12.3334V13.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
