import { Tool, useProject, useTools, useViewport } from '../../stores'
// @TODO: refactor, core shouldn't depend on the web-app
import {
  SelectionSystem,
  getEntryOrThrow,
  getPosition,
  getSize,
} from '@aninix-inc/model'
import { Typography } from 'apps/web-app/src/components/design-system/typography'
import { Icons } from 'apps/web-app/src/components/icons'
import { observer } from 'mobx-react'
import * as React from 'react'
import { MobilePlayer } from './components/mobile-player'
import { Player } from './components/player'
import {
  IProps as IToggleMenuProps,
  ToggleMenu,
} from './components/toggle-menu'
import { useRatio } from './components/use-ratio'
import { Toolbar } from './toolbar'
import { useInspectMode } from './toolbar/use-inspect-mode'
import { QuickExport } from '../quick-export'
import { useAnalytics } from '@aninix/analytics'
import { AnalyticsEvent } from '@aninix/analytics'

export interface IProps {
  inspectMode: IToggleMenuProps['inspectMode']
  onInspectModeChange: IToggleMenuProps['onInspectModeChange']
  webAppUrl: string
}
export const Inspector: React.FCC<IProps> = observer(
  ({ inspectMode, onInspectModeChange, webAppUrl }) => {
    const [isMobile, setIsMobile] = React.useState(false)
    const project = useProject()
    const selection = project.getSystemOrThrow(SelectionSystem)
    const ratioRef = React.useRef<HTMLDivElement>(null)
    const ratioTargetRef = React.useRef<HTMLDivElement>(null)

    const analytics = useAnalytics()

    const {
      startListen,
      isListening,
      offsetX,
      leftRatio,
      rightRatio,
      reset,
      recalculateRatio,
    } = useRatio({
      element: ratioRef.current as HTMLElement,
      ratioTargetElement: ratioTargetRef.current as HTMLElement,
      minimumLeftRatio: 0.2,
      minimumRightRatio: 0.25,
    })

    const viewport = useViewport()
    const tools = useTools()

    const zoom = React.useCallback(() => {
      const entry = getEntryOrThrow(project)
      const position = getPosition(entry)
      const size = getSize(entry)

      const padding = 100

      // @NOTE: zoom to fit
      viewport.zoomToRect({
        x: position.x - padding / 2,
        y: position.y - padding / 2,
        width: (size.x + padding) * (1 / leftRatio),
        height: size.y + padding,
      })
    }, [leftRatio])

    React.useEffect(() => {
      zoom()
    }, [leftRatio])

    const handleResize = React.useCallback(() => {
      const width = window.document.body.clientWidth
      setIsMobile(width < 800)

      zoom()
    }, [zoom])

    React.useEffect(() => {
      window.addEventListener('resize', handleResize)
      handleResize()

      return () => {
        window.removeEventListener('resize', handleResize)
      }
    }, [handleResize])

    React.useEffect(() => {
      if (inspectMode === 'comments') {
        tools.changeTool(Tool.Comments)
        selection.deselectAll()
        return
      }

      tools.changeTool(Tool.Selection)

      if (inspectMode !== 'quick-export') {
        analytics.track({
          eventName: AnalyticsEvent.InspectView,
          properties: { page: inspectMode },
        })
      }
    }, [inspectMode])

    const { inspectMode: contextInspectMode } = useInspectMode()

    React.useEffect(() => {
      onInspectModeChange(contextInspectMode)
      console.log('changed', contextInspectMode)
    }, [contextInspectMode])

    if (inspectMode === 'quick-export')
      return (
        <div className="h-[100dvh] w-screen flex flex-col overflow-hidden">
          <Toolbar project={project} />
          <div className="h-full w-full relative">
            <div className="absolute inset-0">
              <QuickExport />
            </div>
          </div>
        </div>
      )

    return (
      <div ref={ratioRef} className="h-[100dvh] w-screen">
        {/* <div className="h-[48px] w-full bg-[#30353B] px-2"> */}
        {/* <Toolbar webAppUrl={webAppUrl} isMobile={isMobile} /> */}
        <Toolbar project={project} />
        {/* </div> */}

        <div className="h-[calc(100%-48px)]">
          {isMobile === false ? (
            <div className="relative flex h-full w-full flex-row">
              <div className="absolute inset-0 overflow-clip">
                <Player
                  rightRatio={rightRatio}
                  leftRatio={leftRatio}
                  zoom={zoom}
                />
              </div>

              <ToggleMenu
                projectId={project.id}
                isListening={isListening}
                forwardRef={ratioTargetRef}
                rightRatio={rightRatio}
                leftRatio={leftRatio}
                startListen={startListen}
                inspectMode={inspectMode}
                onInspectModeChange={onInspectModeChange}
                recalculateRatio={recalculateRatio}
              />
            </div>
          ) : (
            <div className="relative h-full w-full">
              <div className=" absolute left-2 right-2 top-2 z-[999] flex flex-col rounded-lg border-[0.5px] border-black border-opacity-20 bg-white p-3">
                <div className="flex flex-row items-center gap-2">
                  <Icons.DeviceDesktop />
                  <Typography
                    style="Body4Medium"
                    text="Full specifications on desktop"
                  />
                </div>
                <Typography
                  style="Body5Regular"
                  text="Open this link in your desktop browser to access  layer controls, animation specs, and code."
                />
              </div>
              <MobilePlayer />
            </div>
          )}
        </div>
      </div>
    )
  }
)

Inspector.displayName = 'Inspector'
