import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const In: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 3H2.5H10H10.2445L10.3947 3.19303L13.8947 7.69303L14.1334 8L13.8947 8.30697L10.3947 12.807L10.2445 13H10H2.5H2V12.5V3.5V3ZM3 4V12H9.75546L12.8666 8L9.75546 4H3ZM8.35355 5.64648L10.3536 7.64648L10.7071 8.00004L10.3536 8.35359L8.35355 10.3536L7.64645 9.64648L8.79289 8.50004H4.5V7.50004H8.79289L7.64645 6.35359L8.35355 5.64648Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
