import { Project } from '@aninix-inc/model'
import { useImagesStore, usePlayback } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useRenderInteractor } from './interactor'
import { RenderRouter } from './router'
import { useFontsLoader } from '@aninix/editor/modules/fonts-loader/useFontsLoader'
import { useFontLoading } from '@aninix/core/stores/font-loading'

export interface IProps {
  anchorEl: HTMLDivElement | null
  onClose: () => void
  project: Project
}
export const Render: React.FCC<IProps> = observer(
  ({ anchorEl, onClose, project }) => {
    const imagesStore = useImagesStore()
    const playback = usePlayback()

    const { inlineFonts } = useFontLoading()

    const interactor = useRenderInteractor({
      anchorEl,
      onClose,
      project,
      imagesStore,
      previewRangeStart: playback.previewRange.start,
      previewRangeEnd: playback.previewRange.end,
      inlineCss: inlineFonts,
    })

    return <RenderRouter interactor={interactor} />
  }
)

Render.displayName = 'Render'
