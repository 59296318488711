import {
  GenericPropertyGroup,
  PropertyGroupType,
  RGBA,
} from '@aninix-inc/model/legacy'
import * as R from 'ramda'

export function areAllObjectsEqual<T>(arr: T[]): boolean {
  // an empty array is considered to have all elements equal
  if (arr.length === 0) {
    return true
  }

  // get the first element of the array
  const firstElement = R.head(arr)!

  // create a curried function to check if two T objects are equal
  const isEqual = R.curry((first: T, second: T) => R.equals(first, second))

  // check if all objects are equal to the first object
  // @ts-ignore
  return R.all(isEqual(firstElement), arr)
}

export function arePaintsEqual(
  paints: GenericPropertyGroup[],
  time: number
): boolean {
  if (paints.length === 0) {
    return false
  }

  const isLengthEqual = R.all(
    (item) => item === true,
    paints.map((fills, idx, array) => {
      if (idx === 0) {
        return true
      }

      // @ts-ignore
      return fills.length === array[idx - 1].length
    })
  )

  if (isLengthEqual === false) {
    return false
  }

  // @NOTE: normalized paints by nodes.
  // Each element contains paints from DIFFERENT nodes
  // example: [[paint1, paint1], [paint2, paint2]]
  let normalizedPaints: GenericPropertyGroup[][] = []
  // @ts-ignore
  for (let i = 0; i < paints[0].length; i += 1) {
    // @ts-ignore
    const items = paints.map((combinedPaints) => combinedPaints[i])

    if (normalizedPaints[i] == null) {
      normalizedPaints[i] = []
    }

    // @ts-ignore
    normalizedPaints[i].push(items)
  }

  const isEqual = normalizedPaints.reduce((acc, combinedPaints) => {
    const objectsToCompare: RGBA[] = combinedPaints
      .flatMap((paint: GenericPropertyGroup) => paint)
      .map((paint: GenericPropertyGroup) => {
        if ([PropertyGroupType.Solid].includes(paint.type)) {
          // @ts-ignore
          return paint.color.getValue(time)
        }

        if ([PropertyGroupType.Gradient].includes(paint.type)) {
          return {
            // @ts-ignore
            opacity: paint.opacity.getValue(time),
            // @ts-ignore
            colorStops: paint.colorStops.children.map((cs: any) => {
              return {
                color: cs.color.getValue(time),
                position: cs.position.getValue(time),
              }
            }),
          }
        }
      })

    return acc && areAllObjectsEqual(objectsToCompare)
  }, true)

  return isEqual
}
