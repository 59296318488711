import * as R from 'ramda'
import * as React from 'react'

export { Fixed } from './Fixed'

const defaultSize = { x: 12, y: 12 }

const baseSize = 16
const padding = 2

const clamp = (number: number): number => {
  const middle = baseSize / 2
  const influence = (number - middle) / middle
  return number - padding * influence
}
const flip = (number: number): number => baseSize - number

type Item = {
  // x
  progress: number
  // y
  value: number
}

export interface IProps {
  previews?: Item[]
  variant?: 'solid' | 'dashed'
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Graph: React.FCC<IProps> = ({
  previews,
  variant = 'solid',
  size = defaultSize,
  style,
}) => {
  const points = React.useMemo(() => {
    const currentPoints = (previews || []).map((preview) => ({
      x: clamp(preview.progress * baseSize),
      y: clamp(flip(preview.value * baseSize)),
    }))

    const firstPoint = R.head(currentPoints)

    if (firstPoint == null) {
      return 'M0,0'
    }

    return R.drop(1, currentPoints).reduce(
      (acc, point) => `${acc} L${point.x},${point.y}`,
      `M${firstPoint.x},${firstPoint.y}`
    )
  }, [previews])

  return (
    <svg
      width={size.x}
      height={size.y}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d={points}
        stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
        strokeDasharray={variant === 'dashed' ? '2 1' : '0'}
      />
    </svg>
  )
}
