import * as React from 'react'

const sizeDefault = {
  x: 36,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const CircleArrow: React.FCC<IProps> = ({
  size = sizeDefault,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 36 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 12C3.79 12 2 10.21 2 8C2 5.79 3.79 4 6 4C8.04 4 9.723 5.527 9.97 7.5H35V8.5H9.97C9.723 10.473 8.04 12 6 12Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
