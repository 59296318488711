import * as math from 'mathjs'
import * as R from 'ramda'

import {
  Entity,
  HashComponent,
  ImageTransformComponent,
  ScaleType,
  ScaleTypeComponent,
  ScalingFactorComponent,
  getSize,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/static-types'
import { getPointCoefficient } from '../../../modules/common/renderers/utils'
import { FillMask } from './fill-mask'
import { Base } from './properties/base'
import { defaultTransformProperties } from './properties/transform-properties'
import {
  LottieAsset,
  LottieAssetImage,
  LottieLayer,
  LottieLayerType,
  LottieMatteMode,
} from './types'

type GetSizePayload = {
  target: Point2D
  actual: Point2D
}
function getSizeToFill({ target, actual }: GetSizePayload): Point2D {
  const targetRatio = target.x / target.y
  const actualRatio = actual.x / actual.y

  if (targetRatio < actualRatio) {
    const coefficient = target.y / actual.y

    return {
      x: actual.x * coefficient,
      y: target.y,
    }
  }

  const coefficient = target.x / actual.x

  return {
    x: target.x,
    y: actual.y * coefficient,
  }
}

function getSizeToFit({ target, actual }: GetSizePayload): Point2D {
  const targetRatio = target.x / target.y
  const actualRatio = actual.x / actual.y

  if (targetRatio < actualRatio) {
    const coefficient = target.x / actual.x

    return {
      x: target.x,
      y: actual.y * coefficient,
    }
  }

  const coefficient = target.y / actual.y

  return {
    x: actual.x * coefficient,
    y: target.y,
  }
}

export function ImageLayers(
  payload: {
    node: Entity
    paint: Entity
    parent?: number
  },
  assets: LottieAsset[]
): LottieLayer[] {
  const { node, paint, parent } = payload

  const imageData = assets.find(
    (asset) => asset.id === paint.getComponentOrThrow(HashComponent).value
  ) as LottieAssetImage

  const baseProperties = Base({ node, skipAnimation: true })

  if (imageData == null) {
    return []
  }

  const size = getSize(node, 0)

  if (paint.getComponentOrThrow(ScaleTypeComponent).value === ScaleType.Fill) {
    const targetRatio = size.x / size.y

    const isVertical = targetRatio <= 1

    const newSize = getSizeToFill({
      target: size,
      actual: { x: imageData.w, y: imageData.h },
    })

    const newPosition = (() => {
      if (isVertical) {
        return {
          x: -(newSize.x - size.x) / 2,
          y: 0,
        }
      }

      return {
        x: 0,
        y: -(newSize.y - size.y) / 2,
      }
    })()

    const mask = FillMask({ node, parent, skipAnimation: true })

    return [
      mask,
      {
        ty: LottieLayerType.Image,
        ...baseProperties,
        ks: {
          ...defaultTransformProperties,
          o: baseProperties.ks.o,
          s: {
            a: 0,
            k: [
              (newSize.x / imageData.w) * 100,
              (newSize.y / imageData.h) * 100,
            ],
          },
          p: {
            a: 0,
            k: [newPosition.x, newPosition.y],
          },
        },
        refId: paint.getComponentOrThrow(HashComponent).value,
        parent,
        tt: LottieMatteMode.Alpha,
      },
    ]
  }

  if (paint.getComponentOrThrow(ScaleTypeComponent).value === ScaleType.Fit) {
    const targetRatio = size.x / size.y

    const isVertical = targetRatio <= 1

    const newSize = getSizeToFit({
      target: size,
      actual: { x: imageData.w, y: imageData.h },
    })

    const newPosition = (() => {
      if (isVertical) {
        return {
          x: 0,
          y: -(newSize.y - size.y) / 2,
        }
      }

      return {
        x: -(newSize.x - size.x) / 2,
        y: 0,
      }
    })()

    const newScale = {
      x: newSize.x / imageData.w,
      y: newSize.y / imageData.h,
    }

    const mask = FillMask({ node, parent, skipAnimation: true })

    return [
      mask,
      {
        ty: LottieLayerType.Image,
        ...baseProperties,
        ks: {
          ...defaultTransformProperties,
          o: baseProperties.ks.o,
          s: {
            a: 0,
            k: [newScale.x * 100, newScale.y * 100],
          },
          p: {
            a: 0,
            k: [newPosition.x, newPosition.y],
          },
        },
        refId: paint.getComponentOrThrow(HashComponent).value,
        parent,
        tt: LottieMatteMode.Alpha,
      },
    ]
  }

  if (paint.getComponentOrThrow(ScaleTypeComponent).value === ScaleType.Tile) {
    const newSize = {
      x: imageData.w * paint.getComponentOrThrow(ScalingFactorComponent).value,
      y: imageData.h * paint.getComponentOrThrow(ScalingFactorComponent).value,
    }

    const rows = Math.floor(size.x / newSize.x) + 1
    const columns = Math.floor(size.y / newSize.y) + 1

    const table = R.range(0, rows).map(() => R.range(0, columns))

    const mask = FillMask({ node, parent, skipAnimation: true })

    // @ts-ignore
    return table.flatMap((item, row) =>
      item.flatMap((column) => [
        mask,
        {
          ty: LottieLayerType.Image,
          ...baseProperties,
          ks: {
            ...defaultTransformProperties,
            o: baseProperties.ks.o,
            s: {
              a: 0,
              k: [
                (newSize.x / imageData.w) * 100,
                (newSize.y / imageData.h) * 100,
              ],
            },
            p: {
              a: 0,
              k: [newSize.x * row, newSize.y * column],
            },
          },
          refId: paint.getComponentOrThrow(HashComponent).value,
          parent,
          tt: LottieMatteMode.Alpha,
        },
      ])
    )
  }

  if (paint.getComponentOrThrow(ScaleTypeComponent).value === ScaleType.Crop) {
    const newCoef = getPointCoefficient(
      paint.getComponentOrThrow(ImageTransformComponent).value,
      [0, 0, 1]
    )

    const invertedTransformMatrix = math
      .inv(
        math.matrix([
          ...paint.getComponentOrThrow(ImageTransformComponent).value,
          [0, 0, 1],
        ])
      )
      .toArray() as number[][]

    const newSize = {
      x: size.x * invertedTransformMatrix[0][0],
      y: size.y * invertedTransformMatrix[1][1],
    }

    const newPos = {
      x: size.x * newCoef.x,
      y: size.y * newCoef.y,
    }

    const scaleFactorFromCrop = Math.min(
      newSize.x / imageData.w,
      newSize.y / imageData.h
    )

    const mask = FillMask({ node, parent, skipAnimation: true })

    return [
      mask,
      {
        ty: LottieLayerType.Image,
        ...baseProperties,
        ks: {
          ...defaultTransformProperties,
          o: baseProperties.ks.o,
          s: {
            a: 0,
            k: [scaleFactorFromCrop * 100, scaleFactorFromCrop * 100],
          },
          p: {
            a: 0,
            k: [newPos.x, newPos.y],
          },
        },
        refId: paint.getComponentOrThrow(HashComponent).value,
        parent,
        tt: LottieMatteMode.Alpha,
      },
    ]
  }

  return []
}
