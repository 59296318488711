import classNames from 'classnames'
import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { useCurrentTeamStore } from '../../use-cases'
import { Icons } from '../icons'
import { ExpandedRoles } from './expanded-roles'
import { useCurrentRowContext } from './use-current-row-context'

export const currentAdminRole = (adminRole: ApiUser['adminRole']): string => {
  switch (adminRole) {
    // case TeamMemberAninixRole.Administrator:
    //   return 'Owner'
    case 'owner':
      return 'Owner'
    case 'manager':
      return 'Manager'
    case 'viewer':
      return 'Viewer'
  }
}

export const currentRole = (role: ApiUser['aninixRole']): string => {
  switch (role) {
    // case TeamMemberAninixRole.Administrator:
    //   return 'Owner'
    case 'editor':
      return 'Editor'
    case 'viewer':
      return 'Viewer'
  }
}

export const AninixRole: React.FCC = () => {
  const [isExpandedRoles, setIsExpandedRoles] = React.useState<boolean>(false)

  const handleClose = React.useCallback(() => setIsExpandedRoles(false), [])
  const handleClick = React.useCallback(() => setIsExpandedRoles(true), [])
  const noop = React.useCallback(() => {}, [])

  const { currentTeam } = useCurrentTeamStore()

  const isAllowedToManage = ['owner', 'manager'].includes(
    currentTeam!.adminRole
  )

  const { aninixRole, status } = useCurrentRowContext()

  return (
    <div
      className={classNames(
        'relative flex max-w-fit flex-row items-center gap-2'
      )}
      onMouseOver={isAllowedToManage ? handleClick : noop}
    >
      <p className="font-body text-base font-normal">
        {currentRole(aninixRole!)}
      </p>
      {isAllowedToManage && (
        <>
          <Icons.CaretDown
            color={status === 'suspended' ? '#CED3D9' : undefined}
          />
          <ExpandedRoles
            role={aninixRole!}
            isExpanded={isExpandedRoles}
            handleClose={handleClose}
          />
        </>
      )}
    </div>
  )
}

AninixRole.displayName = 'Role'
