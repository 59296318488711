import { useQuery } from '@tanstack/react-query'

import { ApiGetParams, ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetProject(
  payload: ApiGetParams<'/projects/{projectId}'>['path']
) {
  return useQuery({
    queryKey: ['get-project', payload.projectId],
    async queryFn() {
      return httpClient.get<ApiGetResponse<'/projects/{projectId}'>>(
        `/projects/${payload.projectId}`
      )
    },
  })
}
