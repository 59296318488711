import {
  commitUndo,
  EntityType,
  moveNodes,
  ParentRelationAspect,
  UndoRedoSystem,
} from '@aninix-inc/model'
import { svgToAni } from '@aninix/core/use-cases'
import { useSelection } from '@aninix/editor/modules/selection'
import { useNotifications } from '@aninix/notifications'
import * as React from 'react'
import { useUpdates } from '../../../editor/src/hooks/use-updates'
import { useProject } from '../stores'

export const useAddSvgFileToSelectedLayer = () => {
  const updates = useUpdates()
  const { selection } = useSelection()
  const project = useProject()
  const notifications = useNotifications()

  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)

  const addFile = React.useCallback(
    (svgFile: File) => {
      let isSuccess = false
      try {
        updates.batch(async () => {
          const svgRoot = svgToAni(await svgFile.text(), project)
          const selectedEntity = selection
            .getEntitiesByEntityType(EntityType.Node)
            .at(-1)
          const selectedParent = selectedEntity
            ?.getAspect(ParentRelationAspect)
            ?.getParentEntity()
          if (selectedEntity && selectedParent) {
            moveNodes([svgRoot], selectedParent, { before: selectedEntity })
          }
          selection.deselectAll()
          selection.select([svgRoot.id])
        })
        isSuccess = true
      } catch (e) {
        notifications.showNotification(
          'An error occurred while pasting the SVG element.',
          { autoClose: 1000 }
        )
      } finally {
        commitUndo(project)
        if (!isSuccess) updates.batch(() => undoRedo.undo())
      }
    },
    [selection, project]
  )

  return addFile
}
