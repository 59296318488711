class AninixBroadcastChannel {
  private readonly broadcastChannel: BroadcastChannel

  constructor(name: string) {
    if (window.BroadcastChannel != null) {
      this.broadcastChannel = new window.BroadcastChannel(name)
    }
  }

  addEventListener(
    type: any,
    listener: (event: any) => void,
    options?: any
  ): void {
    return
    this.broadcastChannel?.addEventListener(type, listener, options)
  }

  postMessage(message: unknown): void {
    return
    this.broadcastChannel?.postMessage(message)
  }

  close(): void {
    return
    this.broadcastChannel?.close()
  }
}

export const authBroadcastChannel = new AninixBroadcastChannel(
  'aninix_auth_channel'
)
