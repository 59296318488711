import { config } from './config'
import { httpClient } from './http-client'

export async function setTeamAvatar(payload: {
  teamId: string
  avatar: File
}): Promise<void> {
  const formData = new FormData()

  formData.append('teamId', payload.teamId)
  formData.append('avatar', payload.avatar)

  await httpClient.put(`${config.apiUrl}/${payload.teamId}/avatar`, formData)
}
