import {
  Component,
  SelectionSystem,
  getNode,
  getSortedKeyframes,
} from '@aninix-inc/model'
import * as R from 'ramda'

export const isComponentSelected = (component: Component): boolean => {
  const layer = getNode(component)

  if (layer === undefined) {
    throw new Error('Invalid state. Node is not found')
  }

  const selection = layer.getProjectOrThrow().getSystemOrThrow(SelectionSystem)
  const keyframes = getSortedKeyframes(component)
  const isKeyframesSelected = R.any(
    (keyframe) => selection.isSelected(keyframe.id),
    keyframes
  )
  return isKeyframesSelected
}
