import {
  IComment,
  useGetComments,
} from '@aninix/core/use-cases/comments/use-get-comments'
import { usePatchComment } from '@aninix/core/use-cases/comments/use-patch-comment'
import { CircularProgress } from '@material-ui/core'
import { toast } from 'apps/web-app/src/modules/toasts'
import classNames from 'classnames'
import * as React from 'react'

import { useCommentContext } from './use-cases/use-comment-context'

export interface IProps {
  parentComment: IComment
  nextCommentId?: string
  previousCommentId?: string
}
export const ThreadHeader: React.FCC<IProps> = ({
  parentComment,
  nextCommentId,
  previousCommentId,
}) => {
  const { setSelectedCommentId } = useCommentContext()

  const selectNextComment = React.useCallback(
    () => setSelectedCommentId(nextCommentId ?? null),
    [nextCommentId, setSelectedCommentId]
  )
  const selectPreviousComment = React.useCallback(
    () => setSelectedCommentId(previousCommentId ?? null),
    [previousCommentId, setSelectedCommentId]
  )

  const deselectComment = React.useCallback(() => {
    setSelectedCommentId(null)
  }, [setSelectedCommentId])

  const [isResolving, setIsResolving] = React.useState<boolean>(false)

  const { query: patchCommentQuery } = usePatchComment()
  const { query: getCommentsQuery } = useGetComments({
    isStartingLoading: false,
  })

  const handleResolve = React.useCallback(() => {
    setIsResolving(true)

    patchCommentQuery
      .mutateAsync({
        commentId: parentComment.id,
        ...parentComment,
        isResolved: true,
      })
      .catch((e) => {
        toast("Couldn't resolve comment", { variant: 'error' })
      })
      .then(() => {
        getCommentsQuery?.refetch()
      })
      .then(() => {
        setIsResolving(false)
        setSelectedCommentId(null)
      })
  }, [])

  const handleUnresolve = React.useCallback(() => {
    setIsResolving(true)

    patchCommentQuery
      .mutateAsync({
        commentId: parentComment.id,
        ...parentComment,
        isResolved: false,
      })
      .catch((e) => {
        toast("Couldn't resolve comment", { variant: 'error' })
      })
      .then(() => {
        getCommentsQuery?.refetch()
      })
      .then(() => {
        setIsResolving(false)
      })
  }, [])

  return (
    <div className="pointer-events-auto flex flex-row justify-between bg-white px-4 py-2">
      {/* left controls */}
      <div className="flex flex-row gap-0">
        <button
          disabled={previousCommentId === undefined}
          onClick={selectPreviousComment}
          className={classNames('cursor-default', {
            ['hover:bg-gray-100']: previousCommentId !== undefined,
            ['opacity-50']: previousCommentId === undefined,
          })}
        >
          {chevronLeftIcon}
        </button>
        <button
          disabled={nextCommentId === undefined}
          onClick={selectNextComment}
          className={classNames('cursor-default', {
            ['hover:bg-gray-100']: nextCommentId !== undefined,
            ['opacity-50']: nextCommentId === undefined,
          })}
        >
          {chevronRightIcon}
        </button>
      </div>

      {/* right controls */}
      <div className="flex flex-row gap-0">
        {/* <button className="hover:bg-gray-100">{threeDotsIcon}</button> */}
        <button
          onClick={parentComment.isResolved ? handleUnresolve : handleResolve}
          className="flex h-8 w-8 cursor-default items-center justify-center hover:bg-gray-100"
        >
          {isResolving ? (
            <CircularProgress size={18} />
          ) : parentComment.isResolved ? (
            unresolveIcon
          ) : (
            resolveIcon
          )}
        </button>
        <button
          className="cursor-default hover:bg-gray-100"
          onClick={deselectComment}
        >
          {closeIcon}
        </button>
      </div>
    </div>
  )
}

ThreadHeader.displayName = 'ThreadHeader'

const threeDotsIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5 16C11.5 16.8284 10.8284 17.5 10 17.5C9.17157 17.5 8.5 16.8284 8.5 16C8.5 15.1716 9.17157 14.5 10 14.5C10.8284 14.5 11.5 15.1716 11.5 16ZM17.5 16C17.5 16.8284 16.8284 17.5 16 17.5C15.1716 17.5 14.5 16.8284 14.5 16C14.5 15.1716 15.1716 14.5 16 14.5C16.8284 14.5 17.5 15.1716 17.5 16ZM22 17.5C22.8284 17.5 23.5 16.8284 23.5 16C23.5 15.1716 22.8284 14.5 22 14.5C21.1716 14.5 20.5 15.1716 20.5 16C20.5 16.8284 21.1716 17.5 22 17.5Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
)

const resolveIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16ZM24 16C24 20.4183 20.4183 24 16 24C11.5817 24 8 20.4183 8 16C8 11.5817 11.5817 8 16 8C20.4183 8 24 11.5817 24 16ZM15.9111 18.8654L19.9111 14.3655L19.0889 13.6347L15.4764 17.6986L12.8889 15.1112L12.1111 15.889L15.1111 18.8889L15.5236 19.3014L15.9111 18.8654Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
)

const closeIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 15.293L20.6465 10.6465L21.3536 11.3536L16.7071 16.0001L21.3536 20.6465L20.6465 21.3536L16 16.7072L11.3536 21.3536L10.6465 20.6465L15.2929 16.0001L10.6465 11.3537L11.3536 10.6466L16 15.293Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
)

const chevronLeftIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3536 16.3535L18 20.9999L17.2929 21.707L12.2929 16.7071L11.9393 16.3535L12.2929 16L17.2929 10.9999L18 11.707L13.3536 16.3535Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
)

const chevronRightIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6464 16.3535L13 11.7071L13.7071 11L18.7071 16L19.0607 16.3535L18.7071 16.7071L13.7071 21.7071L13 21L17.6464 16.3535Z"
      fill="black"
      fillOpacity="0.8"
    />
  </svg>
)
const unresolveIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="15.9999" r="8" fill="#219653" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 15.9999C23 19.8659 19.866 22.9999 16 22.9999C12.134 22.9999 9 19.8659 9 15.9999C9 12.1339 12.134 8.99988 16 8.99988C19.866 8.99988 23 12.1339 23 15.9999ZM24 15.9999C24 20.4182 20.4183 23.9999 16 23.9999C11.5817 23.9999 8 20.4182 8 15.9999C8 11.5816 11.5817 7.99988 16 7.99988C20.4183 7.99988 24 11.5816 24 15.9999ZM15.9111 18.8653L19.9111 14.3654L19.0889 13.6346L15.4764 17.6985L12.8889 15.1111L12.1111 15.8889L15.1111 18.8888L15.5236 19.3012L15.9111 18.8653Z"
      fill="white"
    />
  </svg>
)
