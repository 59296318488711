import {
  Entity,
  EntityType,
  EntityTypeComponent,
  FpsComponent,
  Project,
  PropertiesExpandedComponent,
  Root,
  TargetRelationAspect,
  TimeComponent,
  UpdatesSystem,
  getDuration,
  getEndTime,
  getNode,
  getStartTime,
  round,
} from '@aninix-inc/model'
import { makeAutoObservable, reaction } from 'mobx'
import * as R from 'ramda'
import * as React from 'react'

import { TimeFormat } from '../constants'
import { PreviewRange } from './preview-range'
import { Settings } from './settings'
import { Ticker } from './ticker'

/**
 * @returns pair of layer/group (paint or effect) for provided keyframe
 */
export function getGroupFromKeyframe(keyframe: Entity): {
  layer?: Entity
  group?: Entity
} {
  // @NOTE: check for nullability required in cases when there is a keyframe
  // which pointed to removed layer.
  const parent = keyframe.getAspect(TargetRelationAspect)?.getTargetEntity()

  if (parent == null) {
    return {
      layer: undefined,
      group: undefined,
    }
  }

  const entityType = parent.getComponentOrThrow(EntityTypeComponent).value

  if (entityType === EntityType.Node) {
    return {
      layer: parent,
      group: undefined,
    }
  }

  if (
    entityType === EntityType.Effect ||
    entityType === EntityType.Paint ||
    entityType === EntityType.ColorStop
  ) {
    return {
      layer: getNode(parent),
      group: parent,
    }
  }

  throw new Error(
    `Entity type "${entityType}" does not supported, please check current use case`
  )
}

export function getAllKeyframes(project: Project): Entity[] {
  return R.sortBy(
    (k) => k.getComponentOrThrow(TimeComponent).value,
    project
      .getEntitiesByPredicate(
        (e) =>
          e.getComponentOrThrow(EntityTypeComponent).value ===
          EntityType.Keyframe
      )
      // @NOTE: this fix is related to ANI-2115 issue.
      // @TODO: move this logic to the `@aninix-inc/model` package.
      // It should treat keyframes connected to removed layer as removed.
      // Related to ANI-2242.
      .filter((k) => {
        const node = getNode(k)
        if (node != null) {
          return !project.isEntityRemoved(node.id)
        }
        return false
      })
  )
}

/**
 * @returns all visible but not sorted keyframes
 */
export function getVisibleKeyframes(project: Project): Entity[] {
  return getAllKeyframes(project).filter((keyframe) => {
    const { layer, group } = getGroupFromKeyframe(keyframe)

    if (layer == null) {
      return false
    }

    if (group != null) {
      return (
        layer.getComponentOrThrow(PropertiesExpandedComponent).value &&
        group.getComponentOrThrow(PropertiesExpandedComponent).value
      )
    }

    return layer.getComponentOrThrow(PropertiesExpandedComponent).value
  })
}

/**
 * @description playback
 * and preview range stick together
 */
export class Playback {
  private project: Project

  private get root(): Root {
    return this.project.getEntityByTypeOrThrow(Root)
  }

  private get fps(): number {
    return this.root.getComponentOrThrow(FpsComponent).value
  }

  private user: Settings

  private ticker: Ticker = new Ticker()

  previewRange: PreviewRange

  time: number = 0

  /**
   * @description cached previous time. Required for ghost slider feature
   */
  private _ghostTime: number = -1

  isPlaying: boolean = false

  playTime: number = 0

  /**
   * @description playback speed
   */
  speed: number = 1

  constructor(payload: { project: Project; user: Settings }) {
    makeAutoObservable(this)
    this.previewRange = new PreviewRange({ project: payload.project })
    this.project = payload.project
    this.user = payload.user
    this.ticker.onTick((time) => {
      this.setTime(this.getProtectedTime(time))
    })

    // @NOTE: listen to changes on Root node and reset current store state
    this.project.getSystemOrThrow(UpdatesSystem).onUpdate((updates) => {
      if (updates.includes(this.project.getEntityByTypeOrThrow(Root).id)) {
        this.reset()
      }
    })

    reaction(
      () => ({
        isPlaying: this.isPlaying,
        startTime: this.previewRange.start,
        endTime: this.previewRange.end,
      }),
      ({ isPlaying, startTime, endTime }) => {
        if (isPlaying) {
          this.ticker.play({
            fps: this.fps,
            time: this.time,
            speed: this.speed,
            min: startTime,
            max: endTime,
          })
          return
        }

        this.ticker.pause()
      }
    )
  }

  dispose = () => {
    this.ticker.dispose()
  }

  toStart = () => {
    this.updateTime(getStartTime(this.root))
    return this
  }

  toPreviousKeyframe = () => {
    const keyframes = getVisibleKeyframes(this.project)
    const targetKeyframe = R.findLast(
      (keyframe) =>
        keyframe.getComponentOrThrow(TimeComponent).value < this.time,
      keyframes
    )

    if (targetKeyframe == null) {
      return this
    }

    this.updateTime(targetKeyframe.getComponentOrThrow(TimeComponent).value)
    return this
  }

  toPreviousTimeUnit = (multiply: number = 1) => {
    // @NOTE: required to handle errors when we provide not valid input
    let multiplyBy = multiply
    if (typeof multiply !== 'number') {
      multiplyBy = 1
    }

    const prevTime = round(this.time - this.timeUnit * multiplyBy, { fixed: 4 })

    const start = 0
    const end = getDuration(this.root)
    const isBeforeStartTime = prevTime < start
    const isAfterEndTime = prevTime > end

    if (isBeforeStartTime || isAfterEndTime) {
      this.updateTime(end)
      return
    }

    this.updateTime(prevTime)
    return this
  }

  play = () => {
    this.playTime = performance.now()
    this.isPlaying = true
    return this
  }

  pause = () => {
    this.isPlaying = false
    return this
  }

  togglePlaying = () => {
    if (this.isPlaying) {
      this.pause()
      return
    }

    this.play()
    return this
  }

  toNextTimeUnit = (multiply: number = 1) => {
    // @NOTE: required to handle errors when we provide not valid input
    let multiplyBy = multiply
    if (typeof multiply !== 'number') {
      multiplyBy = 1
    }

    const nextTime = round(this.time + this.timeUnit * multiplyBy, { fixed: 4 })

    const start = 0
    const end = getDuration(this.root)
    const isBeforeStartTime = nextTime < start
    const isAfterEndTime = nextTime > end

    if (isBeforeStartTime || isAfterEndTime) {
      this.updateTime(start)
      return
    }

    this.updateTime(nextTime)
    return this
  }

  toNextKeyframe = () => {
    const keyframes = getVisibleKeyframes(this.project)
    const targetKeyframe = R.find(
      (keyframe) =>
        keyframe.getComponentOrThrow(TimeComponent).value > this.time,
      keyframes
    )

    if (targetKeyframe == null) {
      return this
    }

    this.updateTime(targetKeyframe.getComponentOrThrow(TimeComponent).value)
    return this
  }

  toEnd = () => {
    this.updateTime(getEndTime(this.root))
    return this
  }

  updateTime = (newTime: number) => {
    this.setTime(this.getProtectedTime(newTime))
    this.ticker.updateTime(this.getProtectedTime(newTime))
    return this
  }

  setGhostTime = () => {
    this._ghostTime = this.time
    return this
  }

  resetGhostTime = () => {
    this._ghostTime = -1
    return this
  }

  get ghostTime() {
    return this._ghostTime === -1 ? undefined : this._ghostTime
  }

  updateProject = (project: Project) => {
    this.project = project
    this.previewRange.updateProject(project)
    this.reset()
    return this
  }

  updatePreviewRangeStart = (time: number) => {
    this.previewRange.updateStart(time)
    return this
  }

  updatePreviewRangeDuration = (duration: number) => {
    this.previewRange.updateDuration(duration)
    return this
  }

  updatePreviewRangeEnd = (time: number) => {
    this.previewRange.updateEnd(time)
    return this
  }

  reset = () => {
    this.previewRange.reset()
    return this
  }

  updateSpeed = (newSpeed: number) => {
    this.speed = newSpeed
    return this
  }

  private getProtectedTime = (time: number): number =>
    R.clamp(getStartTime(this.root), getEndTime(this.root), time)

  private setTime = (time: number): this => {
    this.time = round(time, { fixed: 4 })
    return this
  }

  /**
   * @description calculate current time unit
   * @example
   * seconds = 0.1s
   * frames (fps = 60) = 0.01667s
   * milliseconds = 0.0001s
   */
  private get timeUnit() {
    if (this.user.timeFormat === TimeFormat.Frames) {
      return 1 / this.fps
    }

    if (this.user.timeFormat === TimeFormat.Milliseconds) {
      return 0.001
    }

    if (this.user.timeFormat === TimeFormat.Seconds) {
      return 0.01
    }

    return 1
  }
}

const Context = React.createContext<Playback>(null as any)

export const usePlayback = (): Playback => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'Playback context not found. Use PlaybackProvider at the root component.'
    )
  }

  return context
}

export const PlaybackProvider: React.FCC<{ store: Playback }> = ({
  store,
  children,
}) => <Context.Provider value={store}>{children}</Context.Provider>
