// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.curve {
  transition: d 0.2s ease-in-out;
  d: path('M20 160C120 160 55 20 160 20');
}

.group:hover .curve {
  d: path('M20 160C80 160 55 20 160 20');
}
`, "",{"version":3,"sources":["webpack://./apps/web-app/src/components/folder-empty-state.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,uCAAuC;AACzC;;AAEA;EACE,sCAAsC;AACxC","sourcesContent":[".curve {\n  transition: d 0.2s ease-in-out;\n  d: path('M20 160C120 160 55 20 160 20');\n}\n\n.group:hover .curve {\n  d: path('M20 160C80 160 55 20 160 20');\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
