import classNames from 'classnames'
import * as React from 'react'
import { NavLink } from 'react-router-dom'

import { IIcon } from '../../../components/icons'

export interface IMenuItem {
  Icon?: React.FCC<IIcon>
  avatarUrl?: string
  label: string
  path: string
  isExternal: boolean
  SelectedIcon?: React.FCC<IIcon>
}

const MenuItem: React.FC<IMenuItem> = ({
  Icon,
  avatarUrl,
  label,
  path,
  isExternal,
  SelectedIcon,
}) => {
  return (
    <NavLink
      target={isExternal ? '_blank' : undefined}
      to={path}
      className={({ isActive }) =>
        classNames(
          'flex select-none items-center gap-2 rounded-xl stroke-gray-500 p-3 font-body text-gray-500 transition-all  duration-500 hover:bg-gray-100 ',
          {
            ['bg-gray-100']: isActive,
          }
        )
      }
    >
      {({ isActive }) => (
        <>
          {SelectedIcon && isActive && <SelectedIcon />}
          {Icon && !isActive && <Icon />}
          {avatarUrl && (
            <img
              crossOrigin="anonymous"
              className="h-6 w-6 rounded-full"
              src={avatarUrl}
            />
          )}
          <p
            className={classNames('font-body text-base', {
              ['font-medium text-secondary']: isActive,
            })}
          >
            {label}
          </p>
          {/* @NOTE: temporary disabled */}
          {/* {isExternal && <Icons.ExternalPage />} */}
        </>
      )}
    </NavLink>
  )
}

export const MenuItemsPlaceHolder = () => {
  return (
    <div className="flex w-full flex-col gap-1">
      {[1, 2].map((i) => (
        <div
          key={i.toString()}
          className=" m-3 h-6 w-1/2 animate-pulse rounded bg-gray-200"
        />
      ))}
    </div>
  )
}

export interface IProps {
  items: IMenuItem[]
}

export const MenuItems: React.FC<IProps> = ({ items }) => {
  return (
    <div className="flex w-full flex-col gap-1">
      {items.map((e, i) => (
        <MenuItem key={i.toString()} {...e} />
      ))}
    </div>
  )
}

MenuItems.displayName = 'MenuItems'
