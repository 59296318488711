import * as React from 'react'

import { PopupHeader } from '../popup-header'
import * as styles from './index.scss'

export interface IProps {
  onClose: () => void
  title: React.ReactNode
}
export const PropertiesPanelPopper: React.FCC<IProps> = ({
  onClose,
  title,
  children,
}) => (
  <div className={styles.container}>
    <PopupHeader onClose={onClose} className={styles.header}>
      {title}
    </PopupHeader>

    {children}
  </div>
)
