import classnames from 'classnames'
import React from 'react'

import * as styles from './index.scss'

export interface IProps {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  renderAsPercents?: boolean
  fullWidth?: boolean
  withBorder?: boolean
  ref?: any
  onFocus?: () => void
  onBlur?: () => void
  /**
   * When pressed then `onBlur` event is not triggered
   */
  onEnterPress?: () => void
  /**
   * When pressed then `onBlur` event is not triggered
   */
  onEscPress?: () => void
  onKeyPress?: (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void
  className?: string
  style?: any
  fontSize?: 'small' | 'medium'
  // @TODO: refactor
  fullHeight?: boolean
}

export const InputTimeline: React.FCC<IProps> = React.forwardRef<any, IProps>(
  (
    {
      value,
      disabled,
      renderAsPercents,
      fullWidth,
      onChange,
      withBorder,
      onFocus,
      onBlur,
      onEnterPress,
      onEscPress,
      onKeyPress,
      className = '',
      style,
      fontSize = 'small',
      fullHeight = false,
    },
    ref
  ) => {
    const latestPressedKeyRef = React.useRef<string>()

    const valueToRender = React.useMemo(
      () => (renderAsPercents ? `${+value * 100}%` : value),
      [renderAsPercents, value]
    )

    const handleChange = React.useCallback(
      (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        onChange(e.target.value)
      },
      [onChange]
    )

    const handleFocus = React.useCallback(() => {
      onFocus?.()
    }, [onFocus])

    const handleBlur = React.useCallback(() => {
      if (
        latestPressedKeyRef.current?.toLowerCase() === 'escape' ||
        latestPressedKeyRef.current?.toLowerCase() === 'enter'
      ) {
        return
      }

      onBlur?.()
    }, [onBlur])

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        latestPressedKeyRef.current = e.key
        const isEnter = e.key.toLowerCase() === 'enter'
        const isEsc = e.key.toLowerCase() === 'escape'

        if (isEnter) {
          // @ts-ignore
          e.target.blur()

          if (onEnterPress != null) {
            onEnterPress()
          }
        }

        if (isEsc) {
          // @ts-ignore
          e.target.blur()

          if (onEscPress != null) {
            onEscPress()
          }
        }

        if (onKeyPress != null) {
          onKeyPress(e)
        }
      },
      [onEnterPress, onEscPress, onKeyPress]
    )

    // @TODO: replace with basic html input
    return (
      <input
        style={style}
        ref={ref}
        value={valueToRender}
        disabled={disabled}
        className={classnames(styles.input, className, {
          [styles.width]: fullWidth,
          [styles.border]: withBorder,
          [styles.small]: fontSize === 'small',
          [styles.medium]: fontSize === 'medium',
          [styles['input--full-height']]: fullHeight,
        })}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onKeyDown={handleKeyDown}
      />
    )
  }
)
