import { CurveType, Entity } from '@aninix-inc/model'
import { useFlattenEntities } from '@aninix/core'
import * as React from 'react'
import { useCurveInteractor } from './interactor'
import { CurveRouter } from './router'
import * as styles from './router.scss'
import { SpringCurve } from './spring-curve'
import { TimingCurve } from './timing-curve'
import { UserPresets } from './user-presets'

export interface IProps {
  segments: [Entity, Entity][]
}
export const Curve: React.FCC<IProps> = ({ segments }) => {
  useFlattenEntities(segments)

  const interactor = useCurveInteractor({
    segments,
  })

  const graph = React.useMemo(() => {
    if (interactor.type === CurveType.Timing) {
      return <TimingCurve segments={segments} />
    }

    if (interactor.type === CurveType.Spring) {
      return <SpringCurve segments={segments} />
    }

    return <p className={styles.mixed}>Click + to replace mixed content</p>
  }, [interactor])

  return (
    <CurveRouter
      graph={graph}
      userPresets={
        <UserPresets onClose={interactor.hideUserPresets} segments={segments} />
      }
      interactor={interactor}
    />
  )
}
