import { FullPageLoader } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useSessionStore } from '../../stores'
import { useUpgradeToPro } from '../../use-cases'
import { usePaymentRequiredViewInteractor } from './interactor'
import { PaymentRequiredViewRouter } from './router'

export interface IProps {
  teamId?: string
  onDone?: () => void
}
export const PaymentRequiredView: React.FC<IProps> = observer(
  ({ teamId, onDone }) => {
    const sessionStore = useSessionStore()
    const upgradeToProUseCase = useUpgradeToPro({
      sessionStore,
      teamId,
    })
    const interactor = usePaymentRequiredViewInteractor({
      upgradeToProUseCase,
      teamId,
      onDone,
    })
    if (interactor.isLoading) {
      return <FullPageLoader />
    }
    return <PaymentRequiredViewRouter interactor={interactor} />
  }
)

PaymentRequiredView.displayName = 'PaymentRequiredView'
