import { Project } from '@aninix-inc/model'
import * as React from 'react'

import { NodeSnapshot, SmartSvgLayer } from '@aninix-inc/renderer'
import { ImagesStore } from '@aninix/core/stores'
import { useReloadOnAnyUpdate } from '@aninix/core/updates'
import { observe } from 'mobx'
import { loadFonts } from './font-load'
import featureFlags from '@aninix/core/feature-flags'
import { FullPageLoader } from '@aninix/app-design-system'

export interface IProps {
  time: number
  project: Project
  imagesStore: ImagesStore
  /**
   * @description required for background blur to reject zome layers by zIndex.
   * @todo refactor
   */
  shouldRender?: (node: NodeSnapshot) => boolean
  ignoreViewportVisibility?: boolean
  fontsAreLoading?: boolean

  areFontsMissing?: boolean
}

export const SmartSvgWrapper: React.FC<IProps> = ({
  time,
  project,
  imagesStore,
  shouldRender,
  ignoreViewportVisibility,
  fontsAreLoading = false,
  areFontsMissing = true,
}) => {
  const key = useReloadOnAnyUpdate(project)
  const [forceRenderId, setForceRenderId] = React.useState(0)

  React.useEffect(() => {
    observe(imagesStore, () => {
      setForceRenderId((v) => v + 1)
    })
  }, [])

  if (fontsAreLoading) return <FullPageLoader />

  return (
    <SmartSvgLayer
      key={`${key}_${forceRenderId}_${fontsAreLoading}`}
      time={time}
      project={project}
      imagesStore={imagesStore}
      shouldRender={shouldRender}
      ignoreViewportVisibility={ignoreViewportVisibility}
      renderText={featureFlags.renderText && !areFontsMissing}
    />
  )
}

SmartSvgWrapper.displayName = 'SmartSvgWrapper'
