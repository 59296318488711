import { Typography } from 'apps/web-app/src/components/design-system/typography'
import classNames from 'classnames'
import * as React from 'react'
import { ResetIcon } from '../../../../icons/ResetIcon'
import { TextInput } from '../../../components/text-input'
import { TextType } from '../../../use-overrides'
import { useOverride } from '../../use-override-item'

export interface IProps {
  data: TextType
}
export const TextItem: React.FCC<IProps> = ({ data }) => {
  const { isEntityChanged, resetText, updateText } = useOverride()

  const isChanged = isEntityChanged(data.id)

  const [defaultData, setDefaultData] = React.useState(data)

  const handleChange = (value: string) => {
    updateText(data.id, value)
  } //updateEntity(data.id, value)

  const handleReset = () => {
    resetText(data.id)
  }

  //resets color if entity is removed from change map in Override context
  React.useEffect(() => {
    if (isChanged) return

    //in case of object it could be cloned, but string require more unique solution
    setDefaultData({ ...data, value: '' })
    setTimeout(() => setDefaultData({ ...data }), 0)
  }, [isChanged])

  return (
    <div className="flex flex-row w-full justify-between px-3 items-start">
      {/* <div className="min-w-[64px] py-1.5">
        <Typography
          className="text-gray-500"
          style="Body5Regular"
          text={data.name}
        />
      </div> */}

      <TextInput
        key={defaultData.value}
        isMultiline={true}
        value={defaultData.value}
        onChange={handleChange}
        isHtmlFormattedText
      />

      <button
        onClick={handleReset}
        className={classNames('pt-2 pl-2', {
          ['opacity-30 pointer-events-none']: !isChanged,
        })}
      >
        <ResetIcon />
      </button>
    </div>
  )
}

TextItem.displayName = 'TextItem'
