import React from 'react'
import { Align } from './align'
import { AnchorPoint } from './anchor-point'
import { ArcEndingAngle } from './arc-ending-angle'
import { ArcStartingAngle } from './arc-starting-angle'
import { ClipsContent } from './clips-content'
import { ColorSelection } from './color-selection'
import { CornerRadius } from './corner-radius'
import { Effects } from './effects'
import { Fills } from './fills'
import { InnerRadius } from './inner-radius'
import { Opacity } from './opacity'
import { PointCount } from './point-count'
import { Position } from './position'
import { Rotation } from './rotation'
import { Scale } from './scale'
import { Size } from './size'
import { Skew } from './skew'
import { SmoothCorners } from './smooth-corners'
import { SpecificCorner, SpecificCornerRadius } from './specific-corner-radius'
import { Strokes } from './strokes'
import { TrimLayer } from './trim-layer'

export const NodeProperties: React.FC = () => {
  return (
    <>
      <Align />
      <AnchorPoint />
      <Position />
      <Size />
      <Scale />
      <Skew />
      <Rotation />

      <CornerRadius />
      <SpecificCornerRadius corner={SpecificCorner.TopLeft} />
      <SpecificCornerRadius corner={SpecificCorner.TopRight} />
      <SpecificCornerRadius corner={SpecificCorner.BottomLeft} />
      <SpecificCornerRadius corner={SpecificCorner.BottomRight} />

      <SmoothCorners />

      <ArcStartingAngle />
      <ArcEndingAngle />

      <InnerRadius />

      <PointCount />

      <ClipsContent />

      <Opacity />

      <TrimLayer />

      <Fills />

      <Strokes />

      <ColorSelection />

      <Effects />
    </>
  )
}

NodeProperties.displayName = 'NodeProperties'
