import React from 'react'

const defaultSize = {
  x: 14,
  y: 14,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
  accentColor?: string
}
export const BgColorPicker: React.FCC<IProps> = ({
  size = defaultSize,
  style,
  accentColor = 'var(--figma-color-text-brand, rgba(0, 0, 0, 0.8))',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 0.5H13.5V5H12.5V1.5H1.5V12.5H6V13.5H0.5V0.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M9.16129 8.93851C9.57767 8.33635 10.1818 7.65046 11 6.74498C11.8183 7.65046 12.4224 8.33635 12.8387 8.93851C13.3065 9.61504 13.5 10.1347 13.5 10.6815L13.5 10.6826C13.5016 11.4197 13.2474 12.1468 12.7508 12.6947C11.7777 13.7684 10.2223 13.7684 9.24924 12.6947C8.75269 12.1468 8.49837 11.4197 8.50001 10.6826V10.6815C8.50001 10.1347 8.6935 9.61503 9.16129 8.93851Z"
      fill={accentColor}
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)

BgColorPicker.displayName = 'BgColorPicker'
