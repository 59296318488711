import {
  Component,
  Point2D,
  PositionComponent,
  SpatialPoint2dKeyframe,
  getValueSpatialPoint2d,
  round,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow } from '@aninix/app-design-system'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { SpatialPoint2dValue } from '../../values/spatial-point-2d'
import { KeyframesPropertyControl } from '../keyframes-property-control'

export const Position: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, snapshots, time } = useNodePropertiesPanel()

  const filteredNodes = nodes.filter((n) => n.hasComponent(PositionComponent))

  const components = filteredNodes.map((l) =>
    l.getComponentOrThrow(PositionComponent)
  )
  const keyframeType = React.useMemo(
    () => getKeyframesType(components, time),
    [components, time]
  )
  const positions = snapshots.map((s) => s.position)
  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <PositionEditable time={time} components={components} />
      ) : (
        <PositionDisplay
          positions={positions}
          components={components}
          time={time}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

const PositionEditable: React.FC<{
  time: number
  components: Component[]
}> = ({ time, components }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <SpatialPoint2dValue
            components={components}
            time={time}
            iconX={<span>X</span>}
            iconY={<span>Y</span>}
            formatValue={(n) => round(n, { fixed: 2 }).toFixed(2)}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={SpatialPoint2dKeyframe}
          valueGetter={getValueSpatialPoint2d}
        />
      }
    />
  )
}

PositionEditable.displayName = 'PositionEditable'

interface PositionDisplayProps {
  positions: Point2D[]
  time: number
  components: Component[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (
  prev: PositionDisplayProps,
  next: PositionDisplayProps
) => {
  if (prev.positions.length !== next.positions.length) return false
  if (prev.keyframeType !== next.keyframeType) return false

  for (let i = 0; i < prev.positions.length; i++) {
    if (
      prev.positions[i].x !== next.positions[i].x ||
      prev.positions[i].y !== next.positions[i].y
    )
      return false
  }

  return true
}

const PositionDisplay: React.FC<PositionDisplayProps> = React.memo(
  ({ time, components }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <SpatialPoint2dValue
              components={components}
              time={time}
              iconX={<span>X</span>}
              iconY={<span>Y</span>}
              formatValue={(n) => round(n, { fixed: 2 }).toFixed(2)}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={SpatialPoint2dKeyframe}
            valueGetter={getValueSpatialPoint2d}
          />
        }
      />
    )
  },
  propsAreEqual
)

PositionDisplay.displayName = 'PositionDisplay'
