import { useGetProjectMeta } from '@aninix/api/get-project-meta'
import { usePatchProject } from '@aninix/api/patch-project'
import { CircularProgress, InputBase } from '@material-ui/core'
import { toast } from 'apps/web-app/src/modules/toasts'
import classNames from 'classnames'
import * as React from 'react'
import { useParams } from 'react-router-dom'

export interface IProps {
  handleClose: () => void
  defaultFigmaUrl?: string
}
export const AddFigmaLinkForm: React.FCC<IProps> = ({
  handleClose,
  defaultFigmaUrl = '',
}) => {
  const [inputValue, setInputValue] = React.useState<string>(defaultFigmaUrl)
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  )
  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setInputValue(e.target.value)

      if (e.target.value.length === 0) {
        setErrorMessage(undefined)
        return
      }

      if (
        !(
          e.currentTarget.value.startsWith('https://figma.com/') ||
          e.currentTarget.value.startsWith('https://www.figma.com/')
        )
      ) {
        setErrorMessage('Please, use valid figma.com link')
        return
      }
      setErrorMessage(undefined)
    },
    []
  )

  const [isLoading, setIsLoading] = React.useState<boolean>(false)

  const { projectId } = useParams()
  const { query: patchProjectQuery } = usePatchProject(projectId ?? '')
  const { refetch } = useGetProjectMeta(projectId ?? '')

  const handleSave = React.useCallback(() => {
    if (inputValue === defaultFigmaUrl || inputValue.length === 0) {
      handleClose()
      return
    }

    if (errorMessage !== undefined) return

    setIsLoading(true)

    patchProjectQuery
      .mutateAsync({ figmaUrl: inputValue })
      .then(() => {
        setIsLoading(false)
        handleClose()
        refetch()
        toast('Link is successfully updated')
      })
      .catch(() => {
        toast("Couldn't add link to the project", { variant: 'error' })
      })
  }, [inputValue, defaultFigmaUrl])

  return (
    <div className="pointer-events-none flex h-full w-full items-center justify-center">
      <div className="pointer-events-auto flex w-[400px] flex-col gap-4 rounded-xl bg-white p-4 shadow-lg">
        {/* Header */}
        <div className="flex flex-row items-center justify-between">
          <p className="text-base font-medium text-black opacity-80">
            Add Figma link
          </p>
          <button
            onClick={handleClose}
            className="cursor-default rounded-lg bg-white p-1 transition-all duration-150 hover:bg-gray-100"
          >
            <CrossIcon />
          </button>
        </div>

        {/* Body */}
        <p className="text-base text-gray-500">
          Add a link to your project frame in Figma to be able to share it right
          from Aninix editor
        </p>

        {/* Input */}
        <div className="flex flex-col gap-1">
          <InputBase
            type="url"
            autoFocus
            placeholder="https://www.figma.com/file/..."
            value={inputValue}
            onChange={handleChange}
            className="rounded-lg border border-gray-300 pl-2 text-base text-secondary"
          />
          {/* Error element */}
          <p
            className={classNames(
              'text-xs text-red transition-opacity duration-150',
              {
                ['opacity-0']: errorMessage === undefined,
              }
            )}
          >
            {errorMessage}
          </p>
        </div>

        {/* Controls */}
        <div className="flex w-full flex-row items-center justify-end gap-2">
          <button
            onClick={handleClose}
            className="rounded-lg border border-gray-200 bg-white px-3 py-2 transition-transform duration-150 hover:scale-[1.01]"
          >
            <p className="text-sm font-medium ">Cancel</p>
          </button>
          <button
            disabled={isLoading}
            onClick={handleSave}
            className="relative overflow-hidden rounded-lg bg-accent px-3 py-2 transition-transform duration-150 hover:scale-[1.01]"
          >
            <p className="text-sm font-medium text-white">Save</p>
            <div
              className={classNames(
                'absolute inset-0 flex items-center justify-center bg-gray-200 transition-opacity duration-150',
                { ['opacity-0']: !isLoading }
              )}
            >
              <CircularProgress size={24} />
            </div>
          </button>
        </div>
      </div>
    </div>
  )
}

AddFigmaLinkForm.displayName = 'AddFigmaLinkForm'

const CrossIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18M6 6L18 18"
      stroke="#89909A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
