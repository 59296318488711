import classNames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

export interface IProps {
  timelineHeight: number
}
export const ProjectEditorSkeleton: React.FCC<IProps> = ({
  timelineHeight,
}) => (
  <div className={styles.container}>
    <div className={styles.toolbar} />

    <div className={classNames(styles.layout, 'animate-pulse')}>
      <div className={styles['working-area']}>
        <div className={styles.viewport} />
        <div className={styles.timeline} style={{ height: timelineHeight }} />
      </div>

      <div className={styles['properties-panel']} />
    </div>
  </div>
)
