import React from 'react'

const defaultSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const FreeAnchorPoint: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.85353 4.35366L8.00009 2.2071L10.1464 4.35364L10.8535 3.64657L8.35368 1.14644L8.00012 0.792847L7.64655 1.14642L5.14642 3.64655L5.85353 4.35366ZM2.2071 7.99986L4.35366 10.1464L3.64655 10.8535L1.14642 8.35339L0.792847 7.99982L1.14644 7.64627L3.64657 5.1464L4.35364 5.85354L2.2071 7.99986ZM9.99997 7.99997C9.99997 9.10454 9.10454 9.99997 7.99997 9.99997C6.8954 9.99997 5.99997 9.10454 5.99997 7.99997C5.99997 6.8954 6.8954 5.99997 7.99997 5.99997C9.10454 5.99997 9.99997 6.8954 9.99997 7.99997ZM13.7928 8.00009L11.6463 5.85353L12.3534 5.14642L14.8535 7.64655L15.2071 8.00012L14.8535 8.35368L12.3534 10.8535L11.6463 10.1464L13.7928 8.00009ZM10.1464 11.6463L7.99986 13.7928L5.85354 11.6463L5.1464 12.3534L7.64627 14.8535L7.99982 15.2071L8.35339 14.8535L10.8535 12.3534L10.1464 11.6463Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
