import * as React from 'react'

import { Icons } from '../icons'

export interface IProps {
  label: string
  folderId?: string
  icon?: React.ReactNode
  setLocationFolderId: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedFolderId: React.Dispatch<React.SetStateAction<string | undefined>>
}
export const Crumb: React.FCC<IProps> = ({
  label,
  folderId,
  icon = null,
  setLocationFolderId,
  setSelectedFolderId,
}) => {
  const handleClick = React.useCallback(() => {
    setLocationFolderId(folderId)
    setSelectedFolderId(folderId)
  }, [folderId])
  return (
    <>
      {folderId && <Icons.ArrowRight />}
      <button
        onClick={handleClick}
        className="flex flex-row items-center gap-1"
      >
        {icon}
        <p className="font-body text-sm text-gray-400">{label}</p>
      </button>
    </>
  )
}

Crumb.displayName = 'Crumb'
