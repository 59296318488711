import { useMutation } from '@tanstack/react-query'

import { ApiPatchParams, ApiPatchRequestBody } from './helpers'
import { httpClient } from './http-client'

export function useSetMemberAdminRole() {
  return useMutation({
    async mutationFn(
      payload: ApiPatchParams<'/teams/{teamId}/members/{userId}'>['path'] &
        ApiPatchRequestBody<'/teams/{teamId}/members/{userId}'>
    ) {
      const { adminRole } = payload
      return httpClient.patch(
        `/teams/${payload.teamId}/members/${payload.userId}`,
        { adminRole }
      )
    },
  })
}
