import { Entity, NameComponent } from '@aninix-inc/model'

import { Base } from './properties/base'
import { LottieLayerType, LottieNullLayer } from './types'

export function NullLayer(payload: {
  node: Entity
  parent?: number
}): LottieNullLayer {
  const { node, parent } = payload
  const base = Base({ node })

  return {
    ty: LottieLayerType.Null,
    ...base,
    nm: `${node.getComponentOrThrow(NameComponent).value} - Null`,
    parent,
  }
}
