/**
 * @file Toasts container and hook used to show notifications to user.
 */
import * as React from 'react'
import {
  ToastContainer,
  ToastContentProps,
  cssTransition,
  toast as toastSource,
} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.min.css'

import { Icons } from '../../components/icons'
import * as styles from './index.scss'
import './toast.css'

const Aninix = cssTransition({
  enter: styles.enter,
  exit: styles.exit,
  appendPosition: false,
})

export function toast(
  content: React.ReactNode,
  options?: {
    variant: 'success' | 'info' | 'warning' | 'error'
    toastProps?: Partial<ToastContentProps['toastProps']>
  }
): {
  dismiss: () => void
} {
  const { variant, toastProps } = options ?? {}

  if (variant === 'success') {
    const id = toastSource(content, {
      ...toastProps,
      icon: <Icons.Check color="#FFFFFF" />,
    })
    return {
      dismiss: () => toastSource.dismiss(id),
    }
  }

  if (variant === 'warning') {
    const id = toastSource(content, {
      ...toastProps,
      icon: <Icons.Warning />,
    })
    return {
      dismiss: () => toastSource.dismiss(id),
    }
  }

  if (variant === 'error') {
    const id = toastSource(content, {
      ...toastProps,
      icon: <Icons.Error />,
    })
    return {
      dismiss: () => toastSource.dismiss(id),
    }
  }

  const id = toastSource(content, toastProps)
  return {
    dismiss: () => toastSource.dismiss(id),
  }
}

export interface IProps {}
export const Toasts: React.FCC<IProps> = () => (
  <ToastContainer
    position="bottom-center"
    autoClose={5000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    transition={Aninix}
    draggable
    pauseOnHover
    closeButton={false}
    limit={5}
  />
)

Toasts.displayName = 'Toasts'
