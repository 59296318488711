import * as React from 'react'

const defaultSize = { x: 12, y: 14 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const HorizontalCenter: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.5 0.5H5.5V3.5H0.5V5.5H5.5V8.5H2.5V10.5H5.5V13.5H6.5V10.5H9.5V8.5H6.5V5.5H11.5V3.5H6.5V0.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
