import * as React from 'react'

export interface IProps {
  Icon: (props: React.HTMLAttributes<SVGSVGElement>) => React.JSX.Element
  label: string
  onClick: () => void
}
export const ShareMenuItem: React.FCC<IProps> = ({ Icon, label, onClick }) => (
  <button
    onClick={onClick}
    className="flex w-full cursor-default flex-row items-center gap-2 bg-opacity-0 px-3 py-2 transition-colors duration-150 hover:bg-white hover:bg-opacity-20"
  >
    <Icon />
    <p className="text-sm font-normal leading-5 text-white">{label}</p>
  </button>
)

ShareMenuItem.displayName = 'ShareMenuItem'
