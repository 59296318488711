/**
 * @file We used such structure index.tsx -> app.tsx to make sure our styles working properly.
 * The main issue in conflict of material-ui and tailwind. So we have to import tailwind styles first
 * and then make import of all other styles.
 */
import '@aninix/core/styles/tailwind.css'
import * as React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import './_input.css'
import { App } from './app'
import { Maintenance } from './components/status-pages'
import { ErrorBoundary } from './modules/error-boundary'
import './theme/global.scss'

const root = createRoot(document.getElementById('main-app')!)
root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <Routes>
        <Route path="503" element={<Maintenance />} />
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </ErrorBoundary>
)
