import classNames from 'classnames'
import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { AdminRoleItem } from './admin-role-item'
import { useCurrentRowContext } from './use-current-row-context'

interface IExpededRoles {
  role: ApiUser['adminRole']
  isExpanded: boolean
  handleClose: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent
  ) => void
}

export const ExpandedAdminRoles: React.FCC<IExpededRoles> = ({
  role,
  isExpanded,
  handleClose,
}: IExpededRoles) => {
  const currentRowContext = useCurrentRowContext()

  React.useEffect(() => {
    currentRowContext?.rowRef?.current?.addEventListener(
      'mouseleave',
      handleClose
    )

    return () => {
      currentRowContext?.rowRef?.current?.removeEventListener(
        'mouseleave',
        handleClose
      )
    }
  })

  return (
    <div
      // onMouseLeave={handleClose}
      className={classNames(
        ' absolute z-[100] flex -translate-x-8 flex-col items-center overflow-clip rounded-md bg-white shadow-xl transition-all duration-300',
        {
          ['translate-y-12']: role == 'owner',
          ['translate-y-0']: role == 'manager',
          ['-translate-y-12']: role == 'viewer',
          ['invisible opacity-0']: !isExpanded,
        }
      )}
    >
      {(['owner', 'manager', 'viewer'] as const).map((r) => (
        <AdminRoleItem
          key={r.toString()}
          role={r}
          handleClose={handleClose}
          selectedRole={role}
        />
      ))}
    </div>
  )
}

ExpandedAdminRoles.displayName = 'ExpandedAdminRoles'
