import { KeyModificator, useSession } from '@aninix/core'

export function useThreshold(): number {
  const session = useSession()

  if (session.keyModificators.includes(KeyModificator.Shift)) {
    return 0.1
  }

  if (session.keyModificators.includes(KeyModificator.Ctrl)) {
    return 0.001
  }

  return 0.01
}
