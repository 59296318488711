import * as React from 'react'

const sizeDefault = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const ScaleX: React.FCC<IProps> = ({ size = sizeDefault, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.7072 8.00004L13.3536 7.64648L10.3536 4.64648L9.64652 5.35359L11.793 7.50004L4.20718 7.50004L6.35363 5.35359L5.64652 4.64648L2.64652 7.64648L2.29297 8.00004L2.64652 8.35359L5.64652 11.3536L6.35363 10.6465L4.20718 8.50004L11.793 8.50004L9.64652 10.6465L10.3536 11.3536L13.3536 8.35359L13.7072 8.00004Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
