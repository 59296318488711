type Payload = {
  status: 'success' | 'error'
}

export interface IAuthInteractor {
  children: {
    success: boolean
    error: boolean
  }
}

export const useAuthInteractor = ({ status }: Payload): IAuthInteractor => {
  return {
    children: {
      success: status === 'success',
      error: status === 'error',
    },
  }
}
