import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
  className?: string
}
export const NotificationError: React.FCC<IProps> = ({
  size = defaultSize,
  // color = '#FFC700',
  // highlightColor = 'rgba(0, 0, 0, 0.8)',
  style,
  className,
}) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className={className}
  >
    <rect
      width="16"
      height="16"
      rx="4"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M11.5 11.5L4.5 4.5"
      stroke="var(--figma-color-text-brand, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M4.5 11.5L11.5 4.5"
      stroke="var(--figma-color-text-brand, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
