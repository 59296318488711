import { useQuery } from '@tanstack/react-query'
import { config } from './config'
import { httpClient } from './http-client'

export function useGetVersion({
  entity,
}: {
  entity: 'plugin' | 'widget' | 'editor'
}) {
  return useQuery({
    queryKey: ['get-version', entity],
    refetchOnWindowFocus: false,
    async queryFn() {
      return httpClient.get<string>(`${config.apiUrl}/versions/${entity}`, {
        responseType: 'text',
      })
    },
  })
}
