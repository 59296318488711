import { Component, commitUndo } from '@aninix-inc/model'
import { RGBA } from '@aninix-inc/model/legacy'
import { PopupHeader, SolidColorPicker } from '@aninix/app-design-system'
import { useProject } from '@aninix/core'
import { Card, Popover } from '@material-ui/core'
import * as R from 'ramda'
import * as React from 'react'
import { useInfo } from '../..'
import { useValueRgba } from '../../../../../hooks/value-controllers/rgba'
import { InputHex } from '../../input-hex'
import * as styles from './index.scss'

export interface IProps {
  component: Component
}

export const RgbaValue: React.FC<IProps> = ({ component }) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time } = useInfo()
  const { value, updateValue } = useValueRgba({ components: [component], time })

  return (
    <div onPointerDown={() => setIsEditable(true)} className="flex">
      {isEditable ? (
        <RgbaValueEditable value={value as RGBA} updateValue={updateValue} />
      ) : (
        <RgbaValueDisplay value={value as RGBA} />
      )}
    </div>
  )
}

RgbaValue.displayName = 'RgbaValue'

const RgbaValueEditable: React.FC<{
  value: RGBA
  updateValue: (value: RGBA) => void
}> = ({ value, updateValue }) => {
  const rgbaPopupAnchorRef = React.useRef<HTMLDivElement>(null)
  const [isRgbaModalVisible, setIsRgbaPopupVisible] = React.useState(false)

  const project = useProject()
  const onEndChange = React.useCallback(() => {
    commitUndo(project)
  }, [project])

  // @TODO: add support of mixed values
  return (
    <div ref={rgbaPopupAnchorRef}>
      <InputHex
        color={value as RGBA}
        onChange={updateValue}
        onEndChange={onEndChange}
        onPreviewClick={() => {
          setIsRgbaPopupVisible(true)
        }}
      />

      <Popover
        open={isRgbaModalVisible}
        anchorEl={rgbaPopupAnchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          onEndChange()
          setIsRgbaPopupVisible(false)
        }}
        disableRestoreFocus
      >
        <Card className={styles.wrapper}>
          <PopupHeader
            onClose={() => {
              onEndChange()
              setIsRgbaPopupVisible(false)
            }}
          >
            Solid
          </PopupHeader>

          <SolidColorPicker
            color={value as RGBA}
            onEndChange={onEndChange}
            onChange={updateValue}
          />
        </Card>
      </Popover>
    </div>
  )
}

RgbaValueEditable.displayName = 'RgbaValueEditable'

const RgbaValueDisplay: React.FC<{
  value: RGBA
}> = React.memo(
  ({ value }) => {
    return (
      <div>
        <InputHex
          color={value as RGBA}
          onChange={() => {}}
          onEndChange={() => {}}
          onPreviewClick={() => {}}
        />
      </div>
    )
  },
  (prev, next) => R.equals(prev.value, next.value)
)

RgbaValueDisplay.displayName = 'RgbaValueDisplay'
