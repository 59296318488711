import { RGBA, round } from '@aninix-inc/model/legacy'
import classnames from 'classnames'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import tinycolor from 'tinycolor2'

import * as styles from './index.scss'

const noop = () => {}

export interface IProps {
  color: RGBA
  onChange: (color: RGBA) => void
  previewEnabled?: boolean
  onPreviewClick?: () => void
  onStartChange?: () => void
  onEndChange?: () => void
  disabled?: boolean
}
export const InputHex: React.FCC<IProps> = ({
  color,
  onChange,
  previewEnabled = false,
  onPreviewClick = noop,
  onStartChange = noop,
  onEndChange = noop,
  disabled,
}) => {
  const isDirtyRef = React.useRef(false)
  const [isFocused, setIsFocused] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const [isPercentFocused, setIsPercentFocused] = useState(false)
  const [localHex, setLocalHex] = useState(tinycolor(color).toHex())
  const [localOpacity, setLocalOpacity] = useState(Math.round(color.a * 100))

  const normalizedColor = tinycolor(localHex)
  const normalizedOpacity = localOpacity / 100

  const startChange = React.useCallback(() => {
    isDirtyRef.current = true
    onStartChange()
  }, [onStartChange])

  const endChange = React.useCallback(() => {
    isDirtyRef.current = true
    onEndChange()
  }, [onEndChange])

  const handleChangeHex = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newHex = e.target.value

    const valueWithOpacity = {
      ...tinycolor(newHex).toRgb(),
      a: normalizedOpacity,
    }

    setLocalHex(newHex)
    onChange(valueWithOpacity)
  }

  const handleChangeOpacity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newRoughOpacity = e.target.value
    const newOpacity = newRoughOpacity === '' ? '0' : newRoughOpacity
    const parsedOpacity = R.clamp(0, 100, parseFloat(newOpacity))

    const valueWithOpacity = {
      ...normalizedColor.toRgb(),
      a: round(parsedOpacity / 100, { fixed: 2 }),
    }

    setLocalOpacity(parsedOpacity)
    onChange(valueWithOpacity)
  }

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      // NOTE: required to correctly handle selection because we change value
      setTimeout(() => {
        e.target.select()
      }, 50)
      startChange()
      setIsFocused(true)
    },
    [startChange]
  )

  const handleBlur = React.useCallback(() => {
    endChange()
    setIsFocused(false)
  }, [endChange])

  const handlePercentFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      handleFocus(e)
      setIsPercentFocused(true)
    },
    [handleFocus]
  )

  const handlePercentBlur = React.useCallback(() => {
    setIsFocused(false)
    setIsPercentFocused(false)
    endChange()
  }, [endChange])

  const handleKeyDown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter' || e.key === 'Escape') {
        // @ts-ignore
        e.target.blur()
      }
    },
    []
  )

  useEffect(() => {
    if (isFocused) {
      return
    }

    setLocalHex(tinycolor(color).toHex())
    setLocalOpacity(Math.round(color.a * 100))
  }, [color, isFocused])

  useEffect(() => {
    return () => {
      if (isDirtyRef.current) {
        onEndChange()
      }
    }
  }, [])

  return (
    <div
      className={classnames(styles.container, {
        [styles['container--hovered']]: isHovered,
        [styles['container--focused']]: isFocused,
      })}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div
        className={classnames(styles.left, {
          [styles['left--hovered']]: isHovered,
          [styles['left--focused']]: isFocused,
        })}
      >
        {previewEnabled && (
          <button
            type="button"
            className={styles.preview}
            onClick={onPreviewClick}
          >
            <span
              className={styles['preview-left']}
              style={{ backgroundColor: normalizedColor.toString() }}
            />
            <span
              className={styles['preview-right']}
              style={{
                backgroundColor: normalizedColor.toString(),
                opacity: normalizedOpacity,
              }}
            />
          </button>
        )}

        <input
          className={styles.input}
          type="text"
          value={localHex.toUpperCase()}
          onChange={handleChangeHex}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onKeyDown={handleKeyDown}
          disabled={disabled}
        />
      </div>

      <div className={styles.right}>
        <input
          className={styles.input}
          type="text"
          value={isPercentFocused ? localOpacity : `${localOpacity}%`}
          onChange={handleChangeOpacity}
          onFocus={handlePercentFocus}
          onBlur={handlePercentBlur}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  )
}

InputHex.displayName = 'InputHex'
