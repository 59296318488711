import {
  ColorStopsRelationsAspect,
  Entity,
  GradientTransformComponent,
  OpacityComponent,
  PaintType,
  PaintTypeComponent,
  ProgressComponent,
  RgbaValueComponent,
  SizeComponent,
  TimeComponent,
  VisibleInViewportComponent,
  getAnimatableValue,
  getSortedKeyframes,
  getTimingCurveKeyframes,
} from '@aninix-inc/model'
import { toJS } from 'mobx'
import * as R from 'ramda'
import { getPointCoefficient } from '../../../../modules/common/renderers/utils'
import { LottieColor, LottieGradient, LottieGradientType } from '../types'
import { Property, mapColorAlpha, mapRgb, prepareKeyframes } from './property'

export function shouldRenderPaint(paint: Entity): boolean {
  const keyframes = paint.hasComponent(RgbaValueComponent)
    ? getSortedKeyframes(paint.getComponentOrThrow(RgbaValueComponent))
    : []

  if (
    keyframes.length === 0 &&
    paint.getComponent(RgbaValueComponent)?.value.a === 0
  ) {
    return false
  }

  if (paint.getComponentOrThrow(VisibleInViewportComponent).value === false) {
    return false
  }

  return true
}

function getGradientValuesForTime(
  time: number,
  colorStops: Entity[]
): number[] {
  const preparedColorStops = R.pipe(
    R.map((colorStop: Entity) => {
      const position = getAnimatableValue(
        colorStop.getComponentOrThrow(ProgressComponent),
        time
      )
      const color = getAnimatableValue(
        colorStop.getComponentOrThrow(RgbaValueComponent),
        time
      )
      return {
        position,
        color,
      }
    }),
    R.sort((left, right) => left.position - right.position)
  )(colorStops)

  const colors = preparedColorStops.flatMap(({ color, position }) => {
    return [position, color.r / 255, color.g / 255, color.b / 255]
  })

  const alphas = R.all(
    (colorStop) =>
      colorStop.getComponentOrThrow(RgbaValueComponent).value.a === 1,
    colorStops
  )
    ? []
    : preparedColorStops.flatMap(({ color, position }) => [position, color.a])

  return [...colors, ...alphas]
}

function mapColorStopsToGradientColors(
  node: Entity,
  colorStops: Entity[]
): any {
  const project = node.getProjectOrThrow()

  const keyframes = R.pipe(
    R.map((colorStop: Entity) => [
      ...getTimingCurveKeyframes(
        colorStop.getComponentOrThrow(ProgressComponent)
      ),
      ...getTimingCurveKeyframes(
        colorStop.getComponentOrThrow(RgbaValueComponent)
      ),
    ]),
    R.flatten,
    R.uniqBy((keyframe) => keyframe.getComponentOrThrow(TimeComponent).value),
    R.sort(
      (left, right) =>
        left.getComponentOrThrow(TimeComponent).value -
        right.getComponentOrThrow(TimeComponent).value
    )
  )(colorStops)

  if (keyframes.length > 1) {
    return {
      a: 1,
      k: prepareKeyframes({
        project,
        keyframes,
        mapper: ({ time }) => getGradientValuesForTime(time, colorStops),
      }),
    }
  }

  return {
    a: 0,
    k: getGradientValuesForTime(0, colorStops),
  }
}

export function Paint(payload: {
  node: Entity
  paint: Entity
}): LottieColor | LottieGradient | null {
  const { node, paint } = payload
  const size = node.getComponentOrThrow(SizeComponent)
  const paintType = paint.getComponentOrThrow(PaintTypeComponent).value

  if (paintType === PaintType.Solid) {
    return {
      o: Property<number | number[]>({
        property: paint.getComponentOrThrow(RgbaValueComponent),
        mapper: mapColorAlpha,
      }),
      c: Property<number[]>({
        property: paint.getComponentOrThrow(RgbaValueComponent),
        mapper: mapRgb,
      }),
    }
  }

  if (paintType === PaintType.GradientLinear) {
    // @NOTE: taken from figma
    const startPoint: [number, number] = [0, 0.5]
    const endPoint: [number, number] = [1, 0.5]

    const start = Property({
      property: paint.getComponentOrThrow(GradientTransformComponent),
      depenencyProperties: [size],
      mapper: ({ value, time }) => {
        const newStartCoef = getPointCoefficient(toJS(value), [
          ...startPoint,
          1,
        ])
        const currentSize = getAnimatableValue(size, time)
        return [newStartCoef.x * currentSize.x, newStartCoef.y * currentSize.y]
      },
    })

    const end = Property({
      property: paint.getComponentOrThrow(GradientTransformComponent),
      depenencyProperties: [size],
      mapper: ({ value, time }) => {
        const newEndCoef = getPointCoefficient(toJS(value), [...endPoint, 1])
        const currentSize = getAnimatableValue(size, time)
        return [newEndCoef.x * currentSize.x, newEndCoef.y * currentSize.y]
      },
    })

    return {
      o: Property<number | number[]>({
        property: paint.getComponentOrThrow(OpacityComponent),
        mapper: ({ value, isAnimated }) => {
          if (isAnimated) {
            return [value * 100]
          }

          return value * 100
        },
      }),
      g: {
        p: paint.getAspectOrThrow(ColorStopsRelationsAspect).getChildrenList()
          .length,
        k: mapColorStopsToGradientColors(
          node,
          paint.getAspectOrThrow(ColorStopsRelationsAspect).getChildrenList()
        ),
      },
      s: start,
      e: end,
      t: LottieGradientType.Linear,
    }
  }

  if (paintType === PaintType.GradientRadial) {
    // @NOTE: taken from figma
    const startPoint: [number, number] = [0.5, 0.5]
    const endPoint: [number, number] = [1, 0.5]

    const start = Property({
      property: paint.getComponentOrThrow(GradientTransformComponent),
      depenencyProperties: [size],
      mapper: ({ value, time }) => {
        const newStartCoef = getPointCoefficient(toJS(value), [
          ...startPoint,
          1,
        ])
        // @NOTE: for some reason we shouldn't get animated size value for radial gradients in lottie
        const currentSize = size.value
        // @TODO: check why radial gradient working in not expected way. You can check stage-1-simple.json file to see the problem
        // const currentSize = node.size.getValue(time)
        return [newStartCoef.x * currentSize.x, newStartCoef.y * currentSize.y]
      },
    })

    const end = Property({
      property: paint.getComponentOrThrow(GradientTransformComponent),
      depenencyProperties: [size],
      mapper: ({ value, time }) => {
        const newEndCoef = getPointCoefficient(toJS(value), [...endPoint, 1])
        const currentSize = getAnimatableValue(size, time)
        return [newEndCoef.x * currentSize.x, newEndCoef.y * currentSize.y]
      },
    })

    return {
      o: Property<number | number[]>({
        property: paint.getComponentOrThrow(OpacityComponent),
        mapper: ({ value, isAnimated }) => {
          if (isAnimated) {
            return [value * 100]
          }

          return value * 100
        },
      }),
      g: {
        p: paint.getAspectOrThrow(ColorStopsRelationsAspect).getChildrenList()
          .length,
        k: mapColorStopsToGradientColors(
          node,
          paint.getAspectOrThrow(ColorStopsRelationsAspect).getChildrenList()
        ),
      },
      s: start,
      e: end,
      t: LottieGradientType.Radial,
    }
  }

  return null
}
