import { FullPageLoader } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useSessionStore } from '../../stores'
import { useLogout } from '../../use-cases'

export interface IProps {}
export const Logout: React.FC<IProps> = observer(() => {
  const sessionStore = useSessionStore()
  const logout = useLogout({ sessionStore })

  React.useEffect(() => {
    logout.execute().then(() => window.location.assign('https://aninix.com'))
  }, [])

  return <FullPageLoader />
})

Logout.displayName = 'Logout'
