import * as React from 'react'

import { IIcon } from '.'

const defaultSize = { x: 24, y: 26 }

export const Shred: React.FCC<IIcon> = ({
  size = defaultSize,
  className = '',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 24 26`}
    fill="#0B1118"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.45508 24.845C2.45508 24.977 2.56061 25.0826 2.69266 25.0826H3.88074C4.01279 25.0826 4.11832 24.977 4.11832 24.845L4.1185 15.2344H2.48158L2.4814 24.845H2.45508Z"
      fill="#CED3D9"
    />
    <path
      d="M19.8809 24.845C19.8809 24.977 19.9864 25.0826 20.1184 25.0826H21.3065C21.4386 25.0826 21.5441 24.977 21.5441 24.845V15.2344H19.9072V24.845H19.8809Z"
      fill="#CED3D9"
    />
    <path
      d="M16.3955 24.845C16.3955 24.977 16.501 25.0826 16.6331 25.0826H17.8212C17.9532 25.0826 18.0588 24.977 18.0588 24.845L18.0589 15.2344H16.422L16.4218 24.845H16.3955Z"
      fill="#CED3D9"
    />
    <path
      d="M12.9111 24.845C12.9111 24.977 13.0167 25.0826 13.1487 25.0826H14.3368C14.4688 25.0826 14.5744 24.977 14.5744 24.845V15.2344H12.9375V24.845H12.9111Z"
      fill="#CED3D9"
    />
    <path
      d="M9.42578 24.845C9.42578 24.977 9.53131 25.0826 9.66336 25.0826H10.8514C10.9835 25.0826 11.089 24.977 11.089 24.845L11.0892 15.2344H9.45228L9.4521 24.845H9.42578Z"
      fill="#CED3D9"
    />
    <path
      d="M5.94043 24.845C5.94043 24.977 6.04596 25.0826 6.17801 25.0826H7.36609C7.49814 25.0826 7.60367 24.977 7.60367 24.845V15.2344H5.96675V24.845H5.94043Z"
      fill="#CED3D9"
    />
    <path
      d="M23.6566 12.5148H21.518V1.79529C21.518 0.818439 20.7259 0 19.7227 0H9.92729V6.6535C9.92729 7.10232 9.55766 7.47194 9.10885 7.47194H2.45535V12.5149H0.316777C0.131868 12.5149 0 12.6469 0 12.8316V13.835C0 14.0199 0.132052 14.1518 0.316777 14.1518H23.6832C23.8681 14.1518 24 14.0197 24 13.835V12.8316C23.9735 12.6467 23.8416 12.5149 23.6567 12.5149L23.6566 12.5148Z"
      fill="#CED3D9"
    />
    <path
      d="M8.29053 0.475342L2.85156 5.8351H8.29053V0.475342Z"
      fill="#CED3D9"
    />
  </svg>
)
