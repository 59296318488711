import { Project } from '@aninix-inc/model'
import { useLogger } from '@aninix/logger'
import * as React from 'react'
import featureFlags from '../feature-flags'
import { FontMeta, loadFonts } from '../modules/common/renderers/font-load'

interface FontLoadingContextType {
  inlineFonts: string | undefined
  fontsAreLoading: boolean
  missingFonts: FontMeta[]
}

const FontLoadingContext = React.createContext<
  FontLoadingContextType | undefined
>(undefined)

export const FontLoadingProvider: React.FC<{
  project: Project
  children: React.ReactNode
}> = ({ project, children }) => {
  const [inlineFonts, setInlineFonts] = React.useState<string>()
  const [missingFonts, setMissingFonts] = React.useState<FontMeta[]>([])
  const [fontsAreLoading, setFontsAreLoading] = React.useState(true)
  const logger = useLogger()

  React.useEffect(() => {
    if (!featureFlags.renderText) {
      setFontsAreLoading(false)
      return
    }

    setFontsAreLoading(true)
    loadFonts(project).then((value) => {
      if (value == null) {
        setFontsAreLoading(false)
        return
      }

      if (value.missingFonts.length > 0) {
        logger.log(['Missing Fonts'], value.missingFonts)
      }

      if (value.inlineFonts) {
        setInlineFonts(value.inlineFonts)
        setMissingFonts(value.missingFonts)

        const styleId = 'aninix-inline-fonts'
        let styleElement = document.getElementById(styleId) as HTMLStyleElement

        if (!styleElement) {
          styleElement = document.createElement('style')
          styleElement.id = styleId
          document.head.appendChild(styleElement)
        }

        styleElement.textContent = value.inlineFonts

        // required to repaint fonts for measuring
        setTimeout(() => {
          setFontsAreLoading(false)
        }, 100)
      }
    })
  }, [project])

  return (
    <FontLoadingContext.Provider
      value={{ inlineFonts, missingFonts, fontsAreLoading }}
    >
      {children}
    </FontLoadingContext.Provider>
  )
}

export const useFontLoading = (): FontLoadingContextType => {
  const context = React.useContext(FontLoadingContext)
  if (context === undefined) {
    throw new Error('useFontLoading must be used within a FontLoadingProvider')
  }
  return context
}
