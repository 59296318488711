import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Skew: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M8.32297 13H4.54659C3.82093 13 3.33688 12.2514 3.63466 11.5896L7.23466 3.58964C7.39615 3.23078 7.75307 3 8.14659 3H11.523C12.2304 3 12.7142 3.71453 12.4514 4.37139L9.25144 12.3714C9.09958 12.751 8.73187 13 8.32297 13Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
