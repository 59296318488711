import { useCreateTeam } from '@aninix/api'
import * as React from 'react'

import { AddTeamInfo } from '../../components/add-team-info'
import { Typography } from '../../components/design-system/typography'
import { toast } from '../toasts'
import { useCurrentStepContext } from './use-current-step'

// import { useCurrentStepContext } from './use-current-step'

export interface IProps {
  handleNavigation: () => void
}
export const AddInfoPage: React.FCC<IProps> = ({ handleNavigation }) => {
  const createTeam = useCreateTeam()

  const { setTeamId } = useCurrentStepContext()

  //@TODO: add avatarUrl later
  const onTeamCreation = React.useCallback(
    ({ name, domain }: { name: string; domain: string }) => {
      createTeam
        .mutateAsync({ name, domain })
        .then((value) => {
          toast(`Team "${name}" successfully created!`, { variant: 'success' })
          setTeamId?.(value.id)
          handleNavigation()
        })
        .catch((error) => {
          toast("Couldn't create team", { variant: 'error' })
        })
    },
    []
  )

  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <img
        crossOrigin="anonymous"
        className="h-20"
        src="/images/aninix-and-new-team.svg"
      />
      <Typography
        style="Heading3"
        alignment="center"
        text="Start a team with Aninix"
      />
      <AddTeamInfo
        onTeamCreation={onTeamCreation}
        onCreationLoading={createTeam.status === 'pending'}
      />
    </div>
  )
}

AddInfoPage.displayName = 'AddInfoPage'
