import { getEntryOrThrow, getPosition, getSize } from '@aninix-inc/model'
import { icons } from '@aninix/app-design-system'
import Button from '@material-ui/core/Button'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useProject, useViewport } from '../../../../stores'

export interface IProps {
  zoom?: () => void
}

export const Zoom: React.FCC<IProps> = observer(({ zoom }) => {
  const project = useProject()
  const viewport = useViewport()
  const entry = getEntryOrThrow(project)
  const rootNodeSize = getSize(entry)
  const zoomPoint = React.useMemo(
    () => ({
      x: rootNodeSize.x / 2,
      y: rootNodeSize.y / 2,
    }),
    [rootNodeSize]
  )

  const handleZoomIn = React.useCallback(() => {
    viewport.zoomToPoint({
      point: zoomPoint,
      zoomStep: 1.25,
    })
  }, [viewport, zoomPoint])

  const handleZoomOut = React.useCallback(() => {
    viewport.zoomToPoint({
      point: zoomPoint,
      zoomStep: 0.75,
    })
  }, [viewport, zoomPoint])

  const handleZoomToFit = React.useCallback(() => {
    if (zoom !== undefined) {
      zoom()
      return
    }

    const padding = 100

    const position = getPosition(entry)
    const size = getSize(entry)
    // @NOTE: zoom to fit
    viewport.zoomToRect({
      x: position.x - padding / 2,
      y: position.y - padding / 2,
      width: size.x + padding,
      height: size.y + padding,
    })
  }, [viewport, project, zoom])

  return (
    <ButtonGroup
      variant="contained"
      className="bg-white bg-opacity-80 shadow-lg backdrop-blur"
      disableElevation
    >
      <Button onClick={handleZoomOut} className="p-0 bg-white">
        <icons.Remove />
      </Button>

      <Button onClick={handleZoomToFit} className="p-0 bg-white">
        <icons.ResizeToFit />
      </Button>

      <Button onClick={handleZoomIn} className="p-0 bg-white">
        <icons.Add />
      </Button>
    </ButtonGroup>
  )
})

Zoom.displayName = 'Zoom'
