import { Line } from '@aninix-inc/model'
import { applyCommon } from '../maps/apply-common'
import { NodeHandler } from '../types'

export const line: NodeHandler = (node, project, assets) => {
  const entity = project.createEntity(Line)

  applyCommon(entity, node, assets)

  return entity
}
