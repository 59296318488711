import * as React from 'react'

const defaultSize = { x: 12, y: 13 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const SpaceBetween: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.00002 13L1.00002 0H2.25306e-05L2.19623e-05 13H1.00002ZM12 0L12 13H11L11 0H12ZM6.00002 3C6.55231 3 7.00002 3.44772 7.00002 4V9C7.00002 9.55229 6.55231 10 6.00002 10C5.44774 10 5.00002 9.55229 5.00002 9L5.00002 4C5.00002 3.44772 5.44774 3 6.00002 3Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
