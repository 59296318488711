import {
  ClipboardKeyframesJson,
  ClipboardType,
  Entity,
  NodeTypeComponent,
  PositionComponent,
  Project,
  SpatialPoint2dKeyframe,
  SpatialPoint2dValueComponent,
  TimingCurveComponent,
  bezierInterpolation,
} from '@aninix-inc/model'
import { getDataMap, paper } from '@aninix-inc/renderer'
import { Clipboard } from '@aninix/clipboard'
import { svgPathToBezierPoints } from '@aninix/core'
import * as R from 'ramda'
import { IUiStore } from '../stores/ui'

// @TODO: move to model
export function copyPositionKeyframes({
  layer,
  project,
  clipboard,
  uiStore,
}: {
  layer: Entity<unknown>
  project: Project
  clipboard: Clipboard
  uiStore: IUiStore
}): () => void {
  return async () => {
    const curve = {
      out: {
        x: 0,
        y: 0,
      },
      in: {
        x: 1,
        y: 1,
      },
    }
    const preparedData: ClipboardKeyframesJson = {
      type: ClipboardType.Keyframes,
      value: (() => {
        // @TODO: check why getDataMap working incorrectly
        const rawPath: VectorPath[] = getDataMap[
          layer.getComponentOrThrow(NodeTypeComponent).value
        ]({
          entity: layer,
          time: 0,
        })
        const value = rawPath
          .map((vectorPath) => vectorPath.data)
          .map((vectorPath) => new paper.CompoundPath(vectorPath).pathData)
          .join('')
        const bezierPoints = R.flatten(svgPathToBezierPoints(value))
        // @TODO: change base duration here
        const duration = 1
        const segments = bezierPoints.map((point) => {
          const p = point.toJson()
          return new paper.Segment(
            new paper.Point(p.point.x, p.point.y),
            new paper.Point(p.startTangent.x, p.startTangent.y),
            new paper.Point(p.endTangent.x, p.endTangent.y)
          )
        })
        const path = new paper.Path(segments)
        const keyframes = bezierPoints.map((point, idx) => {
          const preparedPoint = point.toAbsoluteJson()
          const progress =
            path.getOffsetOf(
              new paper.Point(preparedPoint.point.x, preparedPoint.point.y)
            ) / path.length
          // const progress = idx / (bezierPoints.length - 1)
          const isFirst = idx === 0
          const isLast = bezierPoints.length - 1 === idx
          // @NOTE: required to zerify position
          const firstPoint = R.head(bezierPoints)!
          // @TODO: add correct timing curves depends on curve length etc
          const timingCurve = (() => {
            if (isFirst) {
              return {
                out: curve.out,
                in: {
                  x: 1,
                  y: 1,
                },
              }
            }
            if (isLast) {
              return {
                out: {
                  x: 0,
                  y: 0,
                },
                in: curve.in,
              }
            }
            return {
              out: {
                x: 0,
                y: 0,
              },
              in: {
                x: 1,
                y: 1,
              },
            }
          })()
          const timeInBezier = bezierInterpolation({
            t: progress,
            left: {
              x: 0,
              y: 0,
            },
            leftTangent: curve.out,
            right: {
              x: 1,
              y: 1,
            },
            rightTangent: curve.in,
          })
          return project
            .createIndependentEntity(SpatialPoint2dKeyframe, {
              target: layer.getComponentOrThrow(PositionComponent),
              time: timeInBezier.x * duration,
            })
            .updateComponent(SpatialPoint2dValueComponent, {
              x: preparedPoint.point.x - firstPoint.point.x,
              y: preparedPoint.point.y - firstPoint.point.y,
              tx1: preparedPoint.startTangent.x - firstPoint.point.x,
              ty1: preparedPoint.startTangent.y - firstPoint.point.y,
              tx2: preparedPoint.endTangent.x - firstPoint.point.x,
              ty2: preparedPoint.endTangent.y - firstPoint.point.y,
            })
            .updateComponent(TimingCurveComponent, timingCurve)
        })

        return keyframes.map((keyframe) => ({
          entity: keyframe.getSnapshot(),
          targetComponentTag: 'position',
          targetEntitiesChain: layer.id,
        })) satisfies ClipboardKeyframesJson['value']
      })(),
    }
    await clipboard.copy(JSON.stringify(preparedData))
    uiStore.closeContextMenu()
  }
}
