import * as React from 'react'
import { Icons } from '../icons'

export interface IProps {
  header?: string
  description?: string
}

export const EmptyState: React.FC<IProps> = ({
  header = 'No published projects',
  description = 'Publish your first project using Figma plugin for Aninix and it will appear here.',
}) => (
  <div className="flex h-full w-full flex-col items-center justify-center gap-6">
    <Icons.EmptyProjectList />

    <div className="flex flex-col items-center gap-1">
      <p className=" font-body text-lg  text-secondary">{header}</p>

      <p className=" font-body text-base text-gray-400">{description}</p>

      <a
        // @TODO: insert dynamic url
        href="https://aninix.com/figma-plugin"
        target="_blank"
        className="mt-4 flex flex-row items-center gap-2 rounded-lg bg-[#0B1118] px-4 py-2 text-base text-white"
        rel="noreferrer"
      >
        <span>Download Figma plugin</span>

        {/* @TODO: replace svg with icons from design system */}
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <path
              d="M11 4.5V1L7.4375 0.999634"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M6 6L10.2487 1.75134"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11 7V7.8C11 8.92011 11 9.48016 10.782 9.90798C10.5903 10.2843 10.2843 10.5903 9.90798 10.782C9.48016 11 8.92011 11 7.8 11H4.2C3.07989 11 2.51984 11 2.09202 10.782C1.71569 10.5903 1.40973 10.2843 1.21799 9.90798C1 9.48016 1 8.92011 1 7.8V4.2C1 3.07989 1 2.51984 1.21799 2.09202C1.40973 1.71569 1.71569 1.40973 2.09202 1.21799C2.51984 1 3.07989 1 4.2 1H5"
              stroke="white"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </svg>
      </a>
    </div>
  </div>
)

EmptyState.displayName = 'EmptyState'
