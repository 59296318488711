import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'
import CardMedia from '@material-ui/core/CardMedia'
import React from 'react'

import { IconButton } from '../buttons/icon-button'
import RegularButton from '../buttons/regular-button'
import { Close } from '../icons/Close'
import * as styles from './index.scss'

export interface IBasicCardProps {
  title: string
  imgSrc: string
  description: string
  onClose?: () => void
  leftButton?: {
    title: string
    onClick: () => void
  }
  rightButton: {
    title: string
    onClick: () => void
  }
}

const BasicCard: React.FCC<IBasicCardProps> = ({
  title,
  imgSrc,
  description,
  onClose,
  leftButton,
  rightButton,
}) => (
  <Card
    classes={{
      root: styles.card,
    }}
  >
    <CardHeader
      classes={{
        title: styles.title,
      }}
      action={
        onClose != null && (
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        )
      }
      title={title}
    />
    <CardMedia component="img" image={imgSrc} />
    <CardContent
      classes={{
        root: styles.content,
      }}
    >
      <p className={styles.description}>{description}</p>
    </CardContent>
    <CardActions>
      <div className={styles['card-actions']}>
        {leftButton != null && (
          <RegularButton
            variant="text"
            onClick={leftButton.onClick}
            title={leftButton.title}
          />
        )}
        <RegularButton
          variant="contained"
          onClick={rightButton.onClick}
          title={rightButton.title}
        />
      </div>
    </CardActions>
  </Card>
)

export default BasicCard
