import * as React from 'react'

import { Project } from '@aninix-inc/model'
import { ColorPicker } from './color-picker'
import { Playback } from './playback'
import { TimeSpeedPicker } from './time-speed-picker'

export interface IProps {
  project: Project
}
export const Controls: React.FCC<IProps> = ({ project }) => {
  return (
    <div className="flex w-3/4 min-w-[250px] flex-row items-end justify-start rounded bg-white bg-opacity-80 shadow-lg backdrop-blur">
      <TimeSpeedPicker />
      <Playback project={project} />
      <ColorPicker project={project} />
    </div>
  )
}

Controls.displayName = 'Controls'
