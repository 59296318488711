import { Entity, SmoothCornerRadiusComponent, mixed } from '@aninix-inc/model'
import { Checkbox, CompactPropertyRow } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'

export const SmoothCorners: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) =>
    n.hasComponent(SmoothCornerRadiusComponent)
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  const components = filteredNodes.map((n) =>
    n.getComponentOrThrow(SmoothCornerRadiusComponent)
  )

  if (!filteredNodes.length) return null

  const equals = R.all((p) => p.value === components[0].value, components)
  const value = equals ? components[0].value : mixed

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <SmoothCornersEditable nodes={filteredNodes} value={value} />
      ) : (
        <SmoothCornersDisplay value={value} />
      )}
    </div>
  )
}

SmoothCorners.displayName = 'SmoothCorners'

const SmoothCornersEditable: React.FC<{
  nodes: Entity[]
  value: boolean | typeof mixed
}> = ({ nodes, value }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="py-[6px]">
          <Checkbox
            // @TODO: add support of mixed component
            isChecked={value === mixed ? false : value}
            onClick={() => {
              nodes.forEach((n) =>
                n.updateComponent(SmoothCornerRadiusComponent, (v) => !v)
              )
            }}
            label="Smooth corners"
          />
        </div>
      }
      rightColumn={null}
    />
  )
}

SmoothCornersEditable.displayName = 'SmoothCornersEditable'

interface SmoothCornersDisplayProps {
  value: boolean | typeof mixed
}

const propsAreEqual = (
  prev: SmoothCornersDisplayProps,
  next: SmoothCornersDisplayProps
) => {
  return prev.value === next.value
}

const SmoothCornersDisplay: React.FC<SmoothCornersDisplayProps> = React.memo(
  ({ value }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="py-[6px]">
            <Checkbox
              isChecked={value === mixed ? false : value}
              onClick={() => {}}
              label="Smooth corners"
            />
          </div>
        }
        rightColumn={null}
      />
    )
  },
  propsAreEqual
)

SmoothCornersDisplay.displayName = 'SmoothCornersDisplay'
