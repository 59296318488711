/**
 * @file generate from all frames and masks lottie precomps
 * 1. For each layer
 * 2. if layer is `frame` OR layer is `mask`
 * 2.1. create composition as project size
 * 2.2. generate null objects for current frame and parents chain
 * 2.3. create mask and parent it to the last object in nulls chain
 * 2.4. if composition has parent then add parent composition as layer and apply mask to generated mask layer
 */
import {
  ChildrenRelationsAspect,
  ClipContentComponent,
  Entity,
  EntryComponent,
  FpsComponent,
  MaskComponent,
  NameComponent,
  NodeType,
  NodeTypeComponent,
  ParentRelationAspect,
  Project,
  Root,
  getEntryOrThrow,
} from '@aninix-inc/model'
import * as R from 'ramda'

import { getMaskLayerFor } from '../utils/get-mask-layer'
import { Composition } from './composition'
import { FillMask } from './fill-mask'
import { MaskLayers } from './mask-layers'
import { NullLayersChain } from './null-layers-chain'
import { TransformProperties } from './properties/transform-properties'
import { LottieAsset, LottieLayer, LottieMatteMode } from './types'

/**
 * @todo add ignorance of groups when they do nothing so we can safe some space
 */
export function prepareMasks(
  payload: {
    project: Project
  },
  assets: LottieAsset[]
) {
  const { project } = payload
  const entry = getEntryOrThrow(project)
  const root = project.getEntityByTypeOrThrow(Root)
  const projectFps = root.getComponentOrThrow(FpsComponent).value

  let stack: Entity[] = [entry]

  while (stack.length > 0) {
    const node = stack.pop()!
    const nodeType = node.getComponentOrThrow(NodeTypeComponent).value
    const isMask = node.getComponentOrThrow(MaskComponent).value
    const parent = node.getAspect(ParentRelationAspect)?.getParentEntity()

    if (
      nodeType === NodeType.Frame ||
      nodeType === NodeType.Instance ||
      nodeType === NodeType.Group ||
      isMask
    ) {
      let composition = {
        nm: node.getComponentOrThrow(NameComponent).value,
        fr: projectFps,
        id: node.id,
        layers: [] as LottieLayer[],
      }
      const nullLayers = NullLayersChain(node)
      composition.layers.push(...nullLayers)

      if (parent != null && parent.hasComponent(EntryComponent) === false) {
        const maskLayer = getMaskLayerFor(node)
        // @ts-ignore
        const parentLayer = Composition(maskLayer, assets, {
          markAsUsed: false,
        })
        composition.layers.push(parentLayer)
      }

      // @TODO: separate those calls
      const maskLayers: LottieLayer[] = (() => {
        if (isMask) {
          return MaskLayers(
            {
              node,
              parent: R.last(nullLayers)?.ind,
              skipAnimation: true,
              applyMaskByDefault: false,
            },
            assets
          )
        }

        // @NOTE: required to create proper group opacity mask.
        // Here we create full frame mask but apply opacity from group.
        // Also we use the same technique for frames without clip content.
        if (
          nodeType === NodeType.Group ||
          (nodeType === NodeType.Frame &&
            node.getComponentOrThrow(ClipContentComponent).value === false)
        ) {
          const layer = FillMask({
            node: entry,
            applyMaskByDefault: false,
          })

          return [
            {
              ...layer,
              ks: {
                ...layer.ks,
                o: TransformProperties(node).o,
              },
            },
          ]
        }

        return [
          FillMask({
            node,
            parent: R.last(nullLayers)?.ind,
            applyMaskByDefault: false,
            skipAnimation: true,
          }),
        ]
      })()

      if (!parent?.hasComponent(EntryComponent)) {
        composition.layers.push(
          ...maskLayers.map((mask) => ({
            ...mask,
            tt: LottieMatteMode.Alpha,
          }))
        )
      } else {
        composition.layers.push(...maskLayers)
      }

      assets.push(composition)
    }

    const childrenComponent = node.getAspect(ChildrenRelationsAspect)
    if (childrenComponent != null) {
      stack.push(...childrenComponent.getChildrenList())
    }
  }
}
