import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const WithPlus: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 6.5V7V9H24.5H25V10H24.5H22.5V12V12.5H21.5V12V10H19.5H19V9H19.5H21.5V7V6.5H22.5ZM16 8.5C11.8579 8.5 8.5 11.8579 8.5 16C8.5 18.256 9.49612 20.2794 11.0725 21.6544C12.3074 20.3706 14.0869 19.5 16 19.5C17.9322 19.5 19.576 20.1783 20.9337 21.6489C22.5065 20.2741 23.5 18.2531 23.5 16C23.5 15.3518 23.4179 14.7235 23.2637 14.1246L24.2322 13.8754C24.4071 14.555 24.5 15.267 24.5 16C24.5 20.6944 20.6944 24.5 16 24.5C11.3056 24.5 7.5 20.6944 7.5 16C7.5 11.3056 11.3056 7.5 16 7.5C16.733 7.5 17.445 7.5929 18.1246 7.76782L17.8754 8.73625C17.2765 8.58212 16.6482 8.5 16 8.5ZM20.1346 22.2584C18.9782 21.0389 17.6185 20.5 16 20.5C14.4256 20.5 12.9338 21.2045 11.8746 22.2645C13.0581 23.0454 14.476 23.5 16 23.5C17.528 23.5 18.9492 23.0431 20.1346 22.2584ZM16 12.5C14.6193 12.5 13.5 13.6193 13.5 15C13.5 16.3807 14.6193 17.5 16 17.5C17.3807 17.5 18.5 16.3807 18.5 15C18.5 13.6193 17.3807 12.5 16 12.5ZM12.5 15C12.5 13.067 14.067 11.5 16 11.5C17.933 11.5 19.5 13.067 19.5 15C19.5 16.933 17.933 18.5 16 18.5C14.067 18.5 12.5 16.933 12.5 15Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
