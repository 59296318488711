import {
  EffectsRelationsAspect,
  EffectTypeComponent,
  Entity,
} from '@aninix-inc/model'

/**
 * 2 dimension array of slices by effects by layers. For example we have 2 layers with the following effects:
 * ```
 * layer1: [dropShadow, layerBlur]
 * layer2: [backgroundBlur]
 * ```
 * then slices would be:
 * ```
 * [
 *   [dropShadow, backgroundBlur]
 *   [layerBlur]
 * ]
 * ```
 */
export const effectSlices = (layers: Entity[]): Entity[][] => {
  const effectsByLayers = layers.map((layer) =>
    layer.getAspectOrThrow(EffectsRelationsAspect).getChildrenList()
  )
  const maxEffectsLength = Math.max(
    ...effectsByLayers.map((effects) => effects.length)
  )

  // @NOTE: creating an empty 2 dimension array filling with nulls
  const slices: Entity[][] = new Array(maxEffectsLength)
    .fill(null)
    .map(() => new Array(layers.length).fill(null))

  // @NOTE: filling that array
  for (let i = 0; i < layers.length; i += 1) {
    const layer = layers[i]
    const effects = layer
      .getAspectOrThrow(EffectsRelationsAspect)
      .getChildrenList()

    for (let ii = 0; ii < maxEffectsLength; ii += 1) {
      const effect = effects[ii]

      if (effect == null) {
        continue
      }

      slices[ii][i] = effect
    }
  }

  const isValid = slices.every((slice) =>
    slice.every(
      (effect) =>
        effect != null &&
        effect.getComponentOrThrow(EffectTypeComponent).value ===
          slice[0].getComponentOrThrow(EffectTypeComponent).value
    )
  )

  if (isValid) {
    return slices
  }

  return []
}
