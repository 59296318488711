// @ts-nocheck
import { ImagesStore, Playback, Viewport } from '@aninix/core'

import * as PIXI from 'pixi.js'
import { NodeSnapshot } from '../../common/renderers/types'
import { simpleHash } from '../helpers/simple-string-hash'
import { mapBlendMode } from '../mappers'
import { applyBackdropBlur } from './apply-backdrop-blur'
import { applyDropShadow } from './apply-drop-shadow-3'
import { applyInnerShadow } from './apply-inner-shadow-2'
import { applyLayerBlur } from './apply-layer-blur'
import { drawFills } from './draw-fills'
import { drawStrokes } from './draw-strokes'
import { TessGraphics, drawVectorPath } from './draw-vector-path'

export interface IProps {
  childNodes: NodeSnapshot[]
}

export interface DrawChildNodesConfig {
  renderBlur: boolean
  renderInnerShadow: boolean
  renderShadow: boolean
  renderBackdropBlur: boolean
  blurQuality: number
  containerCaching: boolean
  shapeCaching: boolean
}

export const drawChildNodes = ({
  childNodes,
  container,
  images,
  viewport,
  playback,
  app,
  config = {},
  postrenderCallbacks,
  prerenderCallbacks,
}: {
  childNodes: NodeSnapshot[]
  container: PIXI.Container
  images: ImagesStore
  viewport: Viewport
  playback: Playback
  app: PIXI.Application
  config?: Partial<DrawChildNodesConfig>
  postrenderCallbacks: (() => void)[]
  prerenderCallbacks: (() => void)[]
}) => {
  let _config = {
    containerCaching: config.containerCaching ?? true,
    shapeCaching: config.shapeCaching ?? true,
    renderBlur: config.renderBlur ?? true,
    renderInnerShadow: config.renderInnerShadow ?? true,
    renderShadow: config.renderShadow ?? true,
    renderBackdropBlur: config.renderBackdropBlur ?? true,
    blurQuality: config.blurQuality ?? 12,
  }

  childNodes.forEach(async (cn, idx) => {
    // console.log('drawing:', cn.id)
    if (!cn.visible) return

    const findKindergarten = container.getChildByName(
      cn.id + '_container'
    ) as PIXI.Container | null
    const kindergarden = findKindergarten ?? new PIXI.Container()

    if (findKindergarten === null) {
      kindergarden.name = cn.id + '_container'
      container.addChild(kindergarden)
      if (cn.isMask) container.mask = kindergarden
    }

    kindergarden.visible = cn.opacity !== 0

    kindergarden.transform.setFromMatrix(
      new PIXI.Matrix(...cn.transformMatrix.values)
    )

    kindergarden.interactiveChildren = false

    if (cn.clipContent) {
      const findMask = kindergarden.getChildByName(
        cn.id + '_mask'
      ) as PIXI.Graphics | null

      const mask = findMask ?? new PIXI.Graphics()

      mask.clear()
      mask.beginFill(0)
      drawVectorPath(cn, mask, cn.data.map((e) => e.data).join(''))
      mask.endFill()

      if (findMask === null) {
        mask.name = cn.id + '_mask'
        kindergarden.addChild(mask)
        kindergarden.mask = mask
      }
    }
    // console.log('step 1:', cn.id)
    if (cn.data.length > 0) {
      const findGraphics = kindergarden.getChildByName(
        cn.id + '_graphics'
      ) as TessGraphics | null
      var graphics = findGraphics ?? new TessGraphics(cn.data[0].windingRule)

      graphics.clear()
      graphics.removeChildren()

      kindergarden.filters = [] //release memory

      //this is required since we actually don't want to apply alpha to container,
      //due to it's affect on each child seperately, rather then entire composition

      const blendMode = mapBlendMode(cn.blendMode)

      if (findGraphics === null) {
        graphics.name = cn.id + '_graphics'
        kindergarden.addChild(graphics)
      }

      if (_config.renderBackdropBlur)
        applyBackdropBlur(
          cn,
          graphics,
          kindergarden,
          viewport,
          app,
          prerenderCallbacks,
          postrenderCallbacks,
          _config.blurQuality
        )
      // console.log('step 2:', cn.id)
      const useCache =
        playback.time !== 0 &&
        cn.data[0].data.length > 1000 &&
        cn.hasAnimation === true &&
        _config.shapeCaching

      if (!useCache) {
        drawFills(cn, graphics, images, app, prerenderCallbacks)
        drawStrokes(cn, graphics, images, app)
      } else {
        const cacheData = {
          fills: cn.fills,
          strokes: cn.strokes,
          path: cn.data,
          trimmedPath: cn.data,
        }
        const cacheName = simpleHash(JSON.stringify(cacheData))
        const findCachedTexture = PIXI.Cache.get(cacheName) as
          | PIXI.Texture
          | undefined

        const findBounds = PIXI.Cache.get(cacheName + '_bounds') as
          | PIXI.Rectangle
          | undefined

        if (findCachedTexture === undefined) {
          drawFills(cn, graphics, images, app, prerenderCallbacks)
          drawStrokes(cn, graphics, images, app)

          PIXI.Cache.set(
            cacheName,
            app.renderer.generateTexture(graphics, { resolution: 3 })
          )
          PIXI.Cache.set(cacheName + '_bounds', graphics.getLocalBounds())
        } else {
          if (findBounds) {
            graphics.beginTextureFill({
              texture: findCachedTexture,
              matrix: new PIXI.Matrix().translate(findBounds.x, findBounds.y),
            })

            graphics.drawRect(
              findBounds.x,
              findBounds.y,
              findBounds.width,
              findBounds.height
            )

            graphics.endFill()
          }
        }
      }
      // console.log('step 3:', cn.id)
      if (cn.opacity < 1 || blendMode !== PIXI.BLEND_MODES.NORMAL) {
        postrenderCallbacks.push(() => {
          const alphaFilter = new PIXI.AlphaFilter(cn.opacity)
          alphaFilter.resolution = 3
          alphaFilter.padding =
            Math.max(
              ...cn.dropShadows.map((e) => Math.max(e.offset.x, e.offset.y)),
              cn.strokeWeight,
              -0.33
            ) * viewport.zoom

          alphaFilter.blendMode = mapBlendMode(cn.blendMode)

          // console.log(cn.blendMode)
          kindergarden.filters?.push(
            alphaFilter
            // getBlendFilter(mapBlendMode(cn.blendMode))
          )
        })
      }
      // console.log('step 4:', cn.id)
      if (_config.renderShadow)
        applyDropShadow(
          cn,
          graphics,
          kindergarden,
          viewport,
          app,
          prerenderCallbacks,
          postrenderCallbacks,
          _config.blurQuality
        )
      if (_config.renderInnerShadow)
        applyInnerShadow(
          cn,
          graphics,
          kindergarden,
          viewport,
          app,
          postrenderCallbacks,
          _config.blurQuality
        )

      if (_config.renderBlur)
        applyLayerBlur(
          cn,
          graphics,
          kindergarden,
          app,
          postrenderCallbacks,
          _config.blurQuality
        )
    }
    // console.log('step 5:', cn.id)
    if (cn.hasAnimation || playback.time === 0 || !_config.containerCaching) {
      drawChildNodes({
        childNodes: cn.children,
        container: kindergarden,
        images,
        viewport,
        playback,
        app,
        config,

        prerenderCallbacks,
        postrenderCallbacks,
      })
    } else {
      const findCachedContainer = PIXI.Cache.get(cn.id + '_static') as
        | PIXI.Texture
        | undefined

      const findBounds = PIXI.Cache.get(cn.id + '_static_bounds') as
        | PIXI.Rectangle
        | undefined

      if (findCachedContainer === undefined || findBounds === undefined) {
        drawChildNodes({
          childNodes: cn.children,
          container: kindergarden,
          images,
          viewport,
          playback,
          app,
          config,

          prerenderCallbacks,
          postrenderCallbacks,
        })
      } else {
        const sprite = new PIXI.Sprite(findCachedContainer)
        sprite.position = { x: findBounds.x, y: findBounds.y }
        kindergarden.addChild(sprite)
      }
      // console.log('step 6:', cn.id)
      if (cn.hasAnimation === false && findCachedContainer === undefined) {
        postrenderCallbacks.push(() => {
          PIXI.Cache.set(
            cn.id + '_static',
            app.renderer.generateTexture(kindergarden, { resolution: 3 })
          )
          PIXI.Cache.set(
            cn.id + '_static_bounds',
            kindergarden.getLocalBounds()
          )
        })
      }
    }
  })
}
