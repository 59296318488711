import React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const KeyframeSpeedIn: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_37641_14998)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8869 7.62854C13.0919 7.83355 13.0919 8.16595 12.8869 8.37096L8.48606 12.7718L9.22848 13.5142L13.6293 9.11339C14.2443 8.49834 14.2443 7.50116 13.6293 6.88611L9.11364 2.37047C8.49859 1.75543 7.50141 1.75543 6.88636 2.37047L2.37072 6.88611C1.75568 7.50116 1.75568 8.49834 2.37072 9.11339L4.62854 11.3712L5.37097 10.6288L3.11315 8.37096C2.90813 8.16595 2.90813 7.83355 3.11315 7.62854L7.62879 3.1129C7.8338 2.90788 8.1662 2.90788 8.37121 3.1129L12.8869 7.62854ZM1.00049 15.4997C3.07756 15.4997 4.9772 14.9801 6.35404 13.6033C7.73088 12.2265 8.50049 10.0768 8.50049 6.99975L7.50049 6.99975C7.50049 9.92268 6.77009 11.773 5.64694 12.8962C4.52378 14.0194 2.92342 14.4997 1.00049 14.4997L1.00049 15.4997Z"
        fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    </g>
    <defs>
      <clipPath id="clip0_37641_14998">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
