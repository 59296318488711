import { Project as AninixProject } from '@aninix-inc/model'

import { Project } from './objects'
export { getListOfNotSupportedLottieFeatures } from './get-list-of-not-supported-lottie-features'
export { getListOfNotSupportedTgsFeatures } from './get-list-of-not-supported-tgs-features'

type Hash = string
type Url = string

type Payload = {
  project: AninixProject
  imagesMap: Record<Hash, Url>
  /**
   * @description start time in seconds
   */
  startTime?: number
  /**
   * @description end time in seconds
   */
  endTime?: number
}

export async function generateLottieFile({
  project,
  imagesMap,
  startTime,
  endTime,
}: Payload) {
  return Project({
    project,
    imagesMap,
    startTime,
    endTime,
  })
}
