import { AnimationItem } from 'lottie-web'
import * as React from 'react'
import { BackgroundFillPicker } from './background-fill-picker'
import { LoopPicker } from './loop-picker'
import { PlaybackButton } from './playback-button'
import { PlaybackProgress } from './playback-progress'
import { TimeSpeedPicker } from './time-speed-picker'

export interface IProps {
  animationItem: AnimationItem
  bgColor: string
  setBgColor: React.Dispatch<React.SetStateAction<string>>
}
export const PlayerControls: React.FCC<IProps> = ({
  animationItem,
  bgColor,
  setBgColor,
}) => {
  return (
    <div className="flex flex-col items-center gap-3 px-1 sm:flex-row sm:px-0">
      <div className="pointer-events-auto flex max-h-[42px] flex-row items-end justify-center gap-0 rounded-xl border border-gray-200 bg-white">
        <TimeSpeedPicker animationItem={animationItem} />
        <LoopPicker animationItem={animationItem} />
        <div className="border-l-[1px] border-gray-200">
          <PlaybackButton
            className="flex h-10 w-12 items-center justify-center fill-secondary hover:bg-gray-100"
            animationItem={animationItem}
          />
        </div>
        <div className="flex flex-row border-r-[1px] border-gray-200 pr-[10px]">
          <PlaybackProgress animationItem={animationItem} />
        </div>
        <BackgroundFillPicker bgColor={bgColor} setBgColor={setBgColor} />
      </div>
    </div>
  )
}

export const PlayerControlsSkeleton = () => (
  <div className="flex h-10 w-[327px] flex-col items-center gap-3 rounded-xl border border-[#5C65741A] bg-gray-100 sm:flex-row" />
)

PlayerControls.displayName = 'PlayerControls'
