import * as R from 'ramda'

import {
  ChildrenRelationsAspect,
  Entity,
  FillsRelationsAspect,
  NameComponent,
  PaintType,
  PaintTypeComponent,
  StrokeAlign,
  StrokeAlignComponent,
  TrimEndComponent,
  TrimOffsetComponent,
  TrimStartComponent,
  isTrimPathEnabled,
} from '@aninix-inc/model'
import { ImageLayers } from './image-layers'
import { Base } from './properties/base'
import { Fills } from './properties/fills'
import { Paths } from './properties/paths'
import { Property } from './properties/property'
import { ShapeGroup } from './properties/shape-group'
import { Strokes } from './properties/strokes'
import {
  LottieAsset,
  LottieLayer,
  LottieLayerType,
  LottieShapeLayer,
  LottieShapeLayerType,
  LottieTrimMultipleShapesType,
} from './types'

/**
 * @description required to prevent issues when mask includes additional stroke with inside param
 */
function getStrokeMaskMultiplicator(strokeAlign: StrokeAlign): number {
  if (strokeAlign === StrokeAlign.Outside) {
    return 2
  }

  if (strokeAlign === StrokeAlign.Inside) {
    return 2
  }

  return 1
}

function mapMask(
  payload: {
    node: Entity
    parent?: number
    skipAnimation?: boolean
    debug?: boolean
    applyMaskByDefault?: boolean
  },
  assets: LottieAsset[]
): LottieLayer {
  const {
    node,
    parent,
    skipAnimation,
    debug,
    applyMaskByDefault = true,
  } = payload

  const baseProperties = Base({ node, parent, skipAnimation })
  const fillPaints = Fills({ node })
  const strokePaints = Strokes({
    node,
    // @NOTE: check required because in `group` node we don't have Strokes and Fills
    multiplyBy: node.hasComponent(StrokeAlignComponent)
      ? getStrokeMaskMultiplicator(
          node.getComponentOrThrow(StrokeAlignComponent).value
        )
      : undefined,
  })
  const paths = Paths({ node })
  const rawImagePaints = R.defaultTo(
    [],
    node.getAspect(FillsRelationsAspect)?.getChildrenList()
  ).filter(
    (paint) =>
      paint.getComponentOrThrow(PaintTypeComponent).value === PaintType.Image
  )

  const images = rawImagePaints.flatMap((imagePaint) =>
    ImageLayers({ node, paint: imagePaint, parent }, assets)
  )

  if (images.length > 0) {
    return {
      ...images[0],
      td: debug || applyMaskByDefault === false ? 0 : 1,
    }
  }

  const finalLayer: LottieShapeLayer = {
    ty: LottieLayerType.Shape,
    ...baseProperties,
    nm: `${node.getComponentOrThrow(NameComponent).value} - Layer Mask`,
    shapes: [ShapeGroup([...paths, ...fillPaints, ...strokePaints])],
    td: debug || applyMaskByDefault === false ? 0 : 1,
  }

  if (isTrimPathEnabled(node)) {
    finalLayer.shapes.push({
      ty: LottieShapeLayerType.Trim,
      s: Property({
        property: node.getComponentOrThrow(TrimStartComponent),
        mapper: ({ value, isAnimated }) =>
          isAnimated ? [value * 100] : value * 100,
      }),
      e: Property({
        property: node.getComponentOrThrow(TrimEndComponent),
        mapper: ({ value, isAnimated }) =>
          isAnimated ? [value * 100] : value * 100,
      }),
      o: Property({
        property: node.getComponentOrThrow(TrimOffsetComponent),
        mapper: ({ value, isAnimated }) =>
          isAnimated ? [value * 360] : value * 360,
      }),
      m: LottieTrimMultipleShapesType.Simultaneously,
    })
  }

  return finalLayer
}

/**
 * @description generates mask for provided node
 */
export function MaskLayers(
  payload: {
    node: Entity
    parent?: number
    skipAnimation?: boolean
    debug?: boolean
    applyMaskByDefault?: boolean
  },
  assets: LottieAsset[]
): LottieLayer[] {
  // @TODO: handle cases for Frame when it's visible. For example has visible fill or stroke.
  if (payload.node.hasAspect(ChildrenRelationsAspect)) {
    const stack = [
      ...payload.node
        .getAspectOrThrow(ChildrenRelationsAspect)
        .getChildrenList(),
    ]
    const maskLayers: LottieLayer[] = []
    while (stack.length > 0) {
      const node = stack.pop()!
      maskLayers.push(
        mapMask(
          {
            node,
            parent: payload.parent,
            skipAnimation: payload.skipAnimation,
            debug: payload.debug,
            applyMaskByDefault: false,
          },
          assets
        )
      )
    }
    return maskLayers
  }

  return [mapMask(payload, assets)]
}
