import classNames from 'classnames'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { LoadingSpinner } from '../loading-spinner'

type ButtonType = 'submit' | 'reset' | 'button'

export interface IProps {
  onClick?: (e?: React.MouseEvent<HTMLElement, MouseEvent>) => void
  isActive?: boolean
  isLoading?: boolean
  label: string
  className?: string
  loaderClassName?: string
  buttonType?: ButtonType
  rightIcon?: React.ReactNode
}

export const Button: React.FCC<IProps> = ({
  className = '',
  loaderClassName = '',
  buttonType = 'button',
  onClick,
  isActive = true,
  isLoading = false,
  label,
  rightIcon,
}) => (
  <button
    type={buttonType}
    onClick={onClick}
    className={classNames(
      'relative flex h-10 w-fit flex-row items-center justify-center gap-2 rounded-lg bg-secondary px-4 text-white transition-all duration-500',
      className,
      {
        ['!opacity-30']: !isActive,
        ['ring-accent hover:scale-[1.02] hover:shadow-md focus:scale-[1.02] focus:shadow-md']:
          isActive && !isLoading,
        ['pointer-events-none']: !isActive || isLoading,
        ['animate-pulse']: isLoading,
      }
    )}
  >
    {isLoading && (
      <div className="absolute h-[24px] w-[24px]">
        <LoadingSpinner
          className="flex h-full w-full flex-row items-center justify-center"
          loaderClassName={loaderClassName}
        />
      </div>
    )}
    <p
      className={classNames('select-none text-sm', {
        ['invisible']: isLoading,
      })}
    >
      {label}
    </p>
    {rightIcon}
  </button>
)

interface ILink {
  to: string
  target?: React.HTMLAttributeAnchorTarget
}

export const LinkButton: React.FCC<IProps & ILink> = ({
  className = '',
  isActive = true,
  isLoading = false,
  label,
  to,
  rightIcon,
  target,
}) => (
  <Link
    target={target}
    to={to}
    className={classNames(
      'relative flex h-10 w-fit flex-row items-center justify-center gap-2 rounded-lg px-4 transition-all duration-500 ',
      {
        [' bg-gray-300']: !isActive,
        [' bg-secondary ring-accent hover:scale-[1.02] hover:shadow-md focus:scale-[1.02] focus:shadow-md ']:
          isActive && !isLoading,
        ['pointer-events-none']: !isActive || isLoading,
        [' animate-pulse']: isLoading,
      }
    )}
  >
    <p className={classNames('select-none text-sm text-white')}>{label}</p>
    {rightIcon}
  </Link>
)

export const ExternalLinkButton: React.FCC<IProps & ILink> = ({
  className = '',
  isActive = true,
  isLoading = false,
  label,
  to,
  rightIcon,
  target,
}) => (
  <a
    target={target}
    href={to}
    className={classNames(
      'relative flex h-10 w-fit flex-row items-center justify-center gap-2 rounded-lg px-4 transition-all duration-500 ',
      {
        [' bg-gray-300']: !isActive,
        [' bg-secondary ring-accent hover:scale-[1.02] hover:shadow-md focus:scale-[1.02] focus:shadow-md ']:
          isActive && !isLoading,
        ['pointer-events-none']: !isActive || isLoading,
        [' animate-pulse']: isLoading,
      }
    )}
  >
    <p className={classNames('select-none text-white')}>{label}</p>
    {rightIcon}
  </a>
)

Button.displayName = 'Button'
