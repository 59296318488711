import * as React from 'react'
import { useOverrides } from '../use-overrides'
import { Override } from './override'

export interface IProps {}
export const Overrides: React.FCC<IProps> = () => {
  const { data, setSelectedOverride } = useOverrides()

  return (
    <div className="flex flex-col gap-2">
      {data?.map((override, index) => (
        <Override key={override.id} data={override} index={`${index}`} />
      ))}
    </div>
  )
}

Overrides.displayName = 'Overrides'
