import { nodeColors } from '@aninix/core/registries'
import * as paper from 'paper'
import * as React from 'react'

const SHOW_HITBOX = false

export const ColorStopHandle: React.FCC<{
  startPoint: paper.Point | null
  endPoint: paper.Point | null
  progress: number
  color: RGBA
  zoom: number
  stopId?: string
  isSelected?: boolean
  onMouseDown?: (e: React.MouseEvent<SVGElement>) => void
  onMouseMove?: (e: React.MouseEvent<SVGElement>) => void
  onMouseLeave?: (e: React.MouseEvent<SVGElement>) => void
}> = (props) => {
  const {
    stopId,
    startPoint,
    endPoint,
    progress,
    color,
    isSelected,
    zoom,
    ...handlers
  } = props
  if (!startPoint || !endPoint) return null

  const vectorDirection = endPoint.subtract(startPoint)
  const stopPosition = startPoint.add(vectorDirection.multiply(progress))
  const colorStopSize = 24 / zoom
  const strokeWidth = 1.5 / zoom

  let normal = vectorDirection
    .rotate(90, new paper.Point(0, 0))
    .normalize()
    .multiply(-1)

  const offset = normal.multiply(colorStopSize * 1.5)
  const finalPosition = stopPosition.add(offset)
  const angle = normal.angle

  const patternSize = 8 / zoom

  return (
    <>
      <defs>
        <pattern
          id="color-stop-checkerboard"
          patternUnits="userSpaceOnUse"
          height={patternSize}
          width={patternSize}
        >
          <rect
            width={patternSize / 2}
            height={patternSize / 2}
            fill="#cccccc"
          />
          <rect
            x={patternSize / 2}
            y={patternSize / 2}
            width={patternSize / 2}
            height={patternSize / 2}
            fill="#cccccc"
          />
          <rect
            x={patternSize / 2}
            width={patternSize / 2}
            height={patternSize / 2}
            fill="white"
          />
          <rect
            y={patternSize / 2}
            width={patternSize / 2}
            height={patternSize / 2}
            fill="white"
          />
        </pattern>
      </defs>
      <g
        transform={`translate(${finalPosition.x}, ${finalPosition.y}) rotate(${angle})`}
        filter={`drop-shadow(0 0 ${1 / zoom}px rgba(0, 0, 0, 0.5))`}
      >
        <rect
          x={-colorStopSize}
          y={-colorStopSize / 2}
          width={colorStopSize}
          height={colorStopSize}
          fill="url(#color-stop-checkerboard)"
        />
        <rect
          x={-colorStopSize}
          y={-colorStopSize / 2}
          width={colorStopSize}
          height={colorStopSize}
          fill={`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`}
        />
        <rect
          x={-colorStopSize}
          y={-colorStopSize / 2}
          width={colorStopSize / 2 + strokeWidth / 2}
          height={colorStopSize}
          fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
        />
        <rect
          x={-colorStopSize}
          y={-colorStopSize / 2}
          width={colorStopSize}
          height={colorStopSize}
          stroke={isSelected ? nodeColors.BLUE : 'white'}
          strokeWidth={strokeWidth}
          fill={'transparent'}
        />
        {isSelected && (
          <rect
            x={-colorStopSize + strokeWidth}
            y={-colorStopSize / 2 + strokeWidth}
            width={colorStopSize - strokeWidth * 2}
            height={colorStopSize - strokeWidth * 2}
            fill={'transparent'}
            stroke={'white'}
            strokeWidth={1 / zoom}
          />
        )}
        <polygon
          points={`0,${colorStopSize / 4} ${colorStopSize / 4},${colorStopSize / 2} ${-colorStopSize / 4},${colorStopSize / 2}`}
          fill={isSelected ? nodeColors.BLUE : 'white'}
          stroke={isSelected ? nodeColors.BLUE : 'white'}
          strokeWidth={1 / zoom}
          transform={`translate(${colorStopSize * -1.5}, 0) rotate(-90)`}
        />
        <rect
          x={-colorStopSize - strokeWidth - colorStopSize / 2}
          y={-colorStopSize / 2 - strokeWidth}
          width={colorStopSize + strokeWidth * 2 + colorStopSize / 2}
          height={colorStopSize + strokeWidth * 2}
          fill={SHOW_HITBOX ? '#ffff0090' : 'transparent'}
          data-stop-id={stopId}
          {...handlers}
        />
      </g>
    </>
  )
}
