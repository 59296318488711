import {
  Project,
  getEntryOrThrow,
  getPosition,
  getSize,
} from '@aninix-inc/model'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useViewport } from '../../../stores'
import { Viewport as ViewportModule } from '../../viewport'
import * as styles from './index.scss'

export interface IProps {
  project: Project
}
export const Viewport: React.FCC<IProps> = observer(({ project }) => {
  const viewport = useViewport()

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  })

  const handleResize = React.useCallback(() => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize, false)
  }, [])

  React.useEffect(() => {
    const entry = getEntryOrThrow(project)
    const position = getPosition(entry)
    const size = getSize(entry)
    viewport.zoomToRect({
      x: position.x,
      y: position.y,
      width: size.x,
      height: size.y,
    })
  }, [dimensions, project])

  return (
    <div className={styles['viewport-wrapper']}>
      <ViewportModule project={project} editable={false} />
    </div>
  )
})

Viewport.displayName = 'Viewport'
