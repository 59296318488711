import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { UpgradeToProBannerRouter } from './router'

export interface IProps {}
export const UpgradeToProBanner: React.FC<IProps> = observer(({}) => {
  return <UpgradeToProBannerRouter />
})

UpgradeToProBanner.displayName = 'UpgradeToProBanner'
