import { Dropdown } from '@aninix/app-design-system'
import * as React from 'react'

export interface IProps {
  onZoomInClick: () => void
  onZoomOutClick: () => void
  onZoomToFitClick: () => void
  onZoomTo50Click: () => void
  onZoomTo100Click: () => void
  onZoomTo200Click: () => void
  width: number
  open: boolean
  onClose: () => void
}
export const ViewportZoomViewModal: React.FCC<IProps> = ({
  onZoomInClick,
  onZoomOutClick,
  onZoomToFitClick,
  onZoomTo50Click,
  onZoomTo100Click,
  onZoomTo200Click,
  width,
  onClose,
  ...rest
}) => (
  <Dropdown
    items={[
      {
        title: 'Zoom in',
        onClick: onZoomInClick,
        rightPart: '+',
      },
      {
        title: 'Zoom out',
        onClick: onZoomOutClick,
        rightPart: '-',
      },
      {
        title: 'Zoom to fit',
        onClick: onZoomToFitClick,
        // rightPart: '1',
      },
      {
        title: 'Zoom to 50%',
        onClick: onZoomTo50Click,
      },
      {
        title: 'Zoom to 100%',
        onClick: onZoomTo100Click,
      },
      {
        title: 'Zoom to 200%',
        onClick: onZoomTo200Click,
      },
    ]}
    width={`${width}px`}
    {...rest}
    onClose={onClose}
  />
)
