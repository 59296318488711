import classNames from 'classnames'
import * as React from 'react'

export interface IProps {
  bgColor: string
  setBgColor: React.Dispatch<React.SetStateAction<string>>
}
export const BackgroundFillPicker: React.FCC<IProps> = ({
  bgColor,
  setBgColor,
}) => {
  const bgColors = [
    '#ffffff',
    '#f8e399',
    '#a3e4c9',
    '#a3d2fc',
    '#c9bffd',
    '#0e0f0f',
    '#e0e0e0',
    `url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_8_5)'%3E%3Crect width='20' height='20' fill='white'/%3E%3Crect width='10' height='10' fill='%23F5F6F8'/%3E%3Crect x='10' y='10' width='10' height='10' fill='%23F5F6F8'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_8_5'%3E%3Crect width='20' height='20' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E")`,
  ]
  interface IBgColorItem {
    color: string
    handleClick: (e: React.PointerEvent<HTMLButtonElement>) => void
  }

  const [mouseInGroup, setMouseInGroup] = React.useState<boolean>(false)
  const [colorOffset, setColorOffset] = React.useState<number>(0)

  const bgColorItems: IBgColorItem[] = React.useMemo(() => {
    return bgColors.map((color: string, index: number) => {
      return {
        color: color,
        handleClick: (e) => {
          if (!mouseInGroup) {
            setMouseInGroup(true)
            return
          }
          setBgColor(color)
          e.stopPropagation()
          setColorOffset(index)
          setMouseInGroup(false)
        },
      }
    })
  }, [mouseInGroup])

  const handleMouseEnter = React.useCallback(() => setMouseInGroup(true), [])
  const handleMouseLeave = React.useCallback(() => setMouseInGroup(false), [])

  React.useMemo(() => {
    setBgColor(bgColors[7])
    setColorOffset(7)
  }, [])

  const size = 40

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames(
        'flex h-10 w-10 flex-col items-center overflow-hidden rounded',
        { 'h-inherit overflow-auto bg-white shadow-md': mouseInGroup }
        // { ['']: true }
      )}
      style={{
        transition: 'max-height 150ms ease-in-out',
        maxHeight: mouseInGroup ? `${bgColors.length * size}px` : `${size}px`,
      }}
    >
      {bgColorItems.map((item: IBgColorItem, index: number) => {
        return (
          <div
            key={item.color}
            style={{
              transition: 'transform 150ms ease-in-out',
              transform: mouseInGroup
                ? 'translateY(0px)'
                : `translateY(-${size * colorOffset}px)`,
              backgroundColor:
                colorOffset == index && mouseInGroup
                  ? 'rgba(0, 0, 0, 0.07)'
                  : 'transparent',
            }}
          >
            <button
              onPointerDown={item.handleClick}
              className={classNames(
                'flex h-10 w-10 items-center justify-center',
                { ['hover:bg-gray-100']: colorOffset !== index }
              )}
            >
              {/* this is for transparency grid background, alternative icon is easier to implement */}
              {index !== 7 ? (
                <BackgroundFillIcon
                  style={
                    { '--selectedColor': item.color } as React.CSSProperties
                  }
                />
              ) : (
                <BackgroundFillTransparentIcon />
              )}
            </button>
          </div>
        )
      })}
    </div>
  )
}

BackgroundFillPicker.displayName = 'BackgroundFillPicker'

const BackgroundFillIcon = ({ style }: { style: React.CSSProperties }) => (
  <svg
    style={style}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1502_42)">
      <path
        style={{ fill: 'var(--selectedColor)' }}
        d="M12 7C14 9.20688 15 10.3103 15 11.6815C15.0019 12.5314 14.709 13.382 14.1213 14.0304C12.9498 15.3232 11.0503 15.3232 9.87875 14.0304C9.29109 13.382 8.99812 12.5314 9.00001 11.6815C9.00001 10.3103 10.0001 9.20688 12 7ZM12 7L12 7.00126"
        stroke="#0B1118"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 15H5.77C4.11814 15 3.29221 15 2.65906 14.6836C2.07792 14.3933 1.60675 13.9221 1.31637 13.3409C1 12.7078 1 11.8819 1 10.23V5.77C1 4.11814 1 3.29221 1.31637 2.65906C1.60675 2.07792 2.07792 1.60675 2.65906 1.31637C3.29221 1 4.11814 1 5.77 1H10.23C11.8819 1 12.7078 1 13.3409 1.31637C13.9221 1.60675 14.3933 2.07792 14.6836 2.65906C15 3.29221 15 4.11814 15 5.77V6"
        stroke="#0B1118"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1502_42">
        <rect width="16" height="16" />
      </clipPath>
    </defs>
  </svg>
)

const BackgroundFillTransparentIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_8_15)">
      <g clipPath="url(#clip1_8_15)">
        <path d="M15 1H1V15H15V1Z" fill="white" />
        <path d="M4.5 1H1V4.5H4.5V1Z" fill="#CCCCCC" />
        <path d="M4.5 8H1V11.5H4.5V8Z" fill="#CCCCCC" />
        <path d="M11.5 1H8V4.5H11.5V1Z" fill="#CCCCCC" />
        <path d="M11.5 8H8V11.5H11.5V8Z" fill="#CCCCCC" />
        <path d="M8 4.5H4.5V8H8V4.5Z" fill="#CCCCCC" />
        <path d="M8 11.5H4.5V15H8V11.5Z" fill="#CCCCCC" />
        <path d="M15 4.5H11.5V8H15V4.5Z" fill="#CCCCCC" />
        <path d="M15 11.5H11.5V15H15V11.5Z" fill="#CCCCCC" />
      </g>
      <rect
        x="1"
        y="1"
        width="14"
        height="14"
        rx="3"
        stroke="black"
        strokeWidth="1.6"
      />
    </g>
    <defs>
      <clipPath id="clip0_8_15">
        <rect width="16" height="16" fill="white" />
      </clipPath>
      <clipPath id="clip1_8_15">
        <rect x="1" y="1" width="14" height="14" rx="1" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
