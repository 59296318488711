import debounce from 'lodash/debounce'
import { useEffect, useMemo } from 'react'

interface IPayload {
  delay: number
  callback: (...args: any[]) => void | Promise<void>
}
/**
 * @description debounce wrapper from lodash to use inside a hook
 */
export function useDebounce({ callback, delay }: IPayload) {
  const debounceHandler = useMemo(
    () => debounce(callback, delay),
    [callback, delay]
  )

  useEffect(() => () => debounceHandler.cancel(), [])

  return debounceHandler
}
