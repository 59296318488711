import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Opacity: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <circle
      cx="6.8"
      cy="9.2"
      r="2.8"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M10.714 8.8A2.571 2.571 0 009.428 4c-.952 0-1.783.517-2.228 1.286"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeDasharray="2 1"
    />
  </svg>
)
