import { Snapshot } from '@aninix-inc/model'
import { AxiosResponse } from 'axios'
import { config } from '../../../apps/web-app/src/config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'

export async function syncProjectSnapshot(snapshot: Snapshot): Promise<void> {
  await httpClient.patch<
    paths['/v2/projects/{projectId}/sync-snapshot']['patch']['responses']['200']['content'],
    AxiosResponse<
      paths['/v2/projects/{projectId}/sync-snapshot']['patch']['responses']['200']['content']
    >,
    paths['/v2/projects/{projectId}/sync-snapshot']['patch']['requestBody']['content']['application/json']
  >(
    `${config.apiUrl}/v2/projects/${snapshot.id}/sync-snapshot`,
    snapshot as any
  )
}
