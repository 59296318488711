import * as React from 'react'

const sizeDefault = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const CornerRadius: React.FCC<IProps> = ({
  size = sizeDefault,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 3H7C4.79086 3 3 4.79087 3 7.00001V14H2V7.00001C2 4.23858 4.23858 2 7 2H14V3Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
