import * as React from 'react'

export interface IProps {}
export const LoadingError: React.FCC<IProps> = () => (
  <div className="flex flex-row justify-center items-center h-20 w-full">
    <p className="font-body text-lg text-gray-400">
      Couldn't load content. Try again later
    </p>
  </div>
)

LoadingError.displayName = 'LoadingError'
