import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Vector: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.315 6.336l-.479-.143-.15.503.479.143.15-.503zm.3-1.006l-.479-.143-.15.503.479.143.15-.503zm-.328-.646l.479.143.15-.503-.479-.143-.15.503zm.93-1.365l-.479-.143-.15.503.479.143.15-.503zm5.566 9.393l.479.143.15-.503-.479-.143-.15.503zm.93-1.365l-.479-.143-.15.503.479.143.15-.503zm-.328-.646l.479.143.15-.503-.479-.143-.15.503zm.3-1.006l.479.143.15-.503-.479-.143-.15.503zM5.5 3.25a1.25 1.25 0 100-2.5 1.25 1.25 0 000 2.5zm0-2a.75.75 0 110 1.5.75.75 0 010-1.5zm5 11.5a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm0 2a.75.75 0 110-1.5.75.75 0 010 1.5zM13.5 3A1.5 1.5 0 0012 4.5c0 .455.206.857.526 1.132-.011-.009-.025-.013-.036-.023a29.22 29.22 0 01-.93 1.904C10.068 10.285 9.223 10.5 9 10.5c-.093 0-.266 0-.387-.546C8.5 9.445 8.5 8.711 8.5 8c0-.764 0-1.555-.137-2.171C8.119 4.731 7.487 4.5 7 4.5c-.934 0-1.935.93-3.049 2.823l-.416-.125-.15.503.301.09c-.042.076-.083.143-.126.222-.046.085-.083.165-.127.25l-.198-.059-.15.503.106.032c-.081.163-.162.321-.236.477l-.02-.006-.041.139c-.106.225-.216.456-.305.655.016.001.03.009.045.011C2.588 10.009 2.546 10 2.5 10A1.5 1.5 0 104 11.5a1.49 1.49 0 00-.526-1.132c.011.009.026.014.036.023a29.22 29.22 0 01.93-1.904C5.932 5.715 6.777 5.5 7 5.5c.093 0 .266 0 .387.546C7.5 6.555 7.5 7.29 7.5 8c0 .764 0 1.555.137 2.171C7.881 11.269 8.513 11.5 9 11.5c.93 0 1.926-.921 3.034-2.796l.432.129.15-.503-.316-.095c.047-.085.093-.16.141-.249.041-.076.074-.147.114-.223l.212.063.15-.503-.12-.036c.081-.162.163-.321.237-.477l.033.01.068-.226c.096-.205.197-.417.279-.599-.016-.001-.029-.009-.045-.011.043.007.086.016.131.016a1.5 1.5 0 000-3zm-10 8.5c0 .551-.449 1-1 1-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1zm10-6c-.551 0-1-.449-1-1 0-.551.449-1 1-1 .551 0 1 .449 1 1 0 .551-.449 1-1 1z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
