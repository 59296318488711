import * as paper from 'paper'
import * as React from 'react'

const SHOW_HITBOX = false

export const ProgressHandle: React.FCC<{
  startPoint: paper.Point | null
  endPoint: paper.Point | null
  zoom: number
  onMouseMove?: (e: React.MouseEvent<SVGLineElement>) => void
  onMouseLeave?: (e: React.MouseEvent<SVGLineElement>) => void
  onMouseDown?: (e: React.MouseEvent<SVGLineElement>) => void
}> = (props) => {
  const { startPoint, endPoint, zoom, ...handlers } = props
  if (!startPoint || !endPoint) return null

  const hitBox = 36 / zoom
  const direction = endPoint.subtract(startPoint)
  const normal = direction
    .rotate(90, new paper.Point(0, 0))
    .normalize()
    .multiply(-1)
  const offset = normal.multiply(hitBox / 2)

  const start = startPoint.add(offset)
  const end = endPoint.add(offset)

  return (
    <g>
      <line
        x1={startPoint.x}
        y1={startPoint.y}
        x2={endPoint.x}
        y2={endPoint.y}
        stroke="white"
        strokeWidth={2 / zoom}
        filter={`drop-shadow(0 0 ${1 / zoom}px rgba(0, 0, 0, 0.5))`}
        pointerEvents={'none'}
      />
      <line
        x1={start.x}
        y1={start.y}
        x2={end.x}
        y2={end.y}
        stroke={SHOW_HITBOX ? '#ff00ff' : 'transparent'}
        strokeOpacity={SHOW_HITBOX ? 0.5 : 0}
        strokeWidth={hitBox}
        {...handlers}
      />
    </g>
  )
}
