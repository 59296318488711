import {
  DurationComponent,
  Entity,
  StartTimeComponent,
  UndoRedoSystem,
  UpdatesSystem,
  getEndTime,
  getStartTime,
  mixed,
  setEndTime,
  setStartTime,
} from '@aninix-inc/model'
import {
  InputWithButton,
  PropertyRowV2,
  icons,
} from '@aninix/app-design-system'
import { useEntities, useProject } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'

const iconSize = {
  x: 16,
  y: 16,
}

export const TrimLayer: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time } = useNodePropertiesPanel()
  useEntities(nodes)

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!nodes.length) return null

  const valuesStart = nodes.map((l) => getStartTime(l))
  const valuesEnd = nodes.map((l) => getEndTime(l))

  const equalsStart = R.all((value) => value === valuesStart[0], valuesStart)
  const equalsEnd = R.all((value) => value === valuesEnd[0], valuesEnd)

  const isStartNull = equalsStart
    ? R.all((l) => !l.hasComponent(StartTimeComponent), nodes)
    : false
  const isEndNull = equalsEnd
    ? R.all((l) => !l.hasComponent(DurationComponent), nodes)
    : false

  const uniqueKey = nodes.map((l) => l.id).join('-')

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <TrimLayerEditable
          uniqueKey={uniqueKey}
          nodes={nodes}
          time={time}
          valuesStart={valuesStart}
          valuesEnd={valuesEnd}
          equalsStart={equalsStart}
          equalsEnd={equalsEnd}
          isStartNull={isStartNull}
          isEndNull={isEndNull}
        />
      ) : (
        <TrimLayerDisplay
          uniqueKey={uniqueKey}
          valuesStart={valuesStart}
          valuesEnd={valuesEnd}
          equalsStart={equalsStart}
          equalsEnd={equalsEnd}
          isStartNull={isStartNull}
          isEndNull={isEndNull}
        />
      )}
    </div>
  )
}

TrimLayer.displayName = 'TrimLayer'

export const TrimLayerEditable: React.FC<{
  uniqueKey: string
  nodes: Entity[]
  time: number
  valuesStart: number[]
  valuesEnd: number[]
  equalsStart: boolean
  equalsEnd: boolean
  isStartNull: boolean
  isEndNull: boolean
}> = ({
  uniqueKey,
  nodes,
  time,
  valuesStart,
  valuesEnd,
  equalsStart,
  equalsEnd,
  isStartNull,
  isEndNull,
}) => {
  const project = useProject()
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const updates = project.getSystemOrThrow(UpdatesSystem)

  return (
    <PropertyRowV2
      key={uniqueKey}
      name="Trim Layer"
      inputs={
        <>
          <InputWithButton
            id="trim-start"
            value={equalsStart ? valuesStart[0] : mixed}
            onClick={() => {
              updates.batch(() => {
                nodes.forEach((layer) => {
                  setStartTime(layer, time)
                })
              })
              undoRedo.commitUndo()
            }}
            onChange={(value) => {
              updates.batch(() => {
                nodes.forEach((layer) => {
                  setStartTime(layer, value)
                })
              })
              undoRedo.commitUndo()
            }}
            icon={
              <icons.propertiesPanel.TrimLayer type="left" size={iconSize} />
            }
            width={96}
            placeholder="Auto"
            isNull={isStartNull}
          />

          <InputWithButton
            id="trim-end"
            value={equalsEnd ? valuesEnd[0] : mixed}
            onClick={() => {
              updates.batch(() => {
                nodes.forEach((layer) => {
                  setEndTime(layer, time)
                })
              })
              undoRedo.commitUndo()
            }}
            onChange={(value) => {
              updates.batch(() => {
                nodes.forEach((layer) => {
                  setEndTime(layer, value)
                })
              })
              undoRedo.commitUndo()
            }}
            icon={
              <icons.propertiesPanel.TrimLayer type="right" size={iconSize} />
            }
            width={96}
            placeholder="Auto"
            isNull={isEndNull}
          />
        </>
      }
    />
  )
}

TrimLayerEditable.displayName = 'TrimLayerEditable'

interface TrimLayerDisplayProps {
  uniqueKey: string
  valuesStart: number[]
  valuesEnd: number[]
  equalsStart: boolean
  equalsEnd: boolean
  isStartNull: boolean
  isEndNull: boolean
}

const propsAreEqual = (a: TrimLayerDisplayProps, b: TrimLayerDisplayProps) => {
  if (a.isStartNull !== b.isStartNull) return false
  if (a.isEndNull !== b.isEndNull) return false
  if (a.equalsStart !== b.equalsStart) return false
  if (a.equalsEnd !== b.equalsEnd) return false
  for (let i = 0; i < a.valuesStart.length; i++) {
    if (a.valuesStart[i] !== b.valuesStart[i]) return false
  }
  for (let i = 0; i < a.valuesEnd.length; i++) {
    if (a.valuesEnd[i] !== b.valuesEnd[i]) return false
  }
  if (a.uniqueKey !== b.uniqueKey) return false
  return true
}

const TrimLayerDisplay: React.FC<TrimLayerDisplayProps> = React.memo(
  ({
    valuesStart,
    valuesEnd,
    equalsStart,
    equalsEnd,
    isStartNull,
    isEndNull,
    uniqueKey,
  }) => {
    return (
      <PropertyRowV2
        key={uniqueKey}
        name="Trim Layer"
        inputs={
          <>
            <InputWithButton
              id="trim-start"
              value={equalsStart ? valuesStart[0] : mixed}
              onClick={() => {}}
              onChange={() => {}}
              icon={
                <icons.propertiesPanel.TrimLayer type="left" size={iconSize} />
              }
              width={96}
              placeholder="Auto"
              isNull={isStartNull}
            />

            <InputWithButton
              id="trim-end"
              value={equalsEnd ? valuesEnd[0] : mixed}
              onClick={() => {}}
              onChange={() => {}}
              icon={
                <icons.propertiesPanel.TrimLayer type="right" size={iconSize} />
              }
              width={96}
              placeholder="Auto"
              isNull={isEndNull}
            />
          </>
        }
      />
    )
  },
  propsAreEqual
)

TrimLayerDisplay.displayName = 'TrimLayerDisplay'
