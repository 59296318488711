import {
  AnimationCurveAspect,
  Component,
  CurveType,
  DurationComponent,
  Entity,
  Root,
  TargetRelationAspect,
  TimeComponent,
  TimingCurveComponent,
  getAnimatableValue,
  getTimingCurveKeyframes,
  setAnimatableValue,
} from '@aninix-inc/model'
import { lerpRangeClamped, round } from '@aninix-inc/model/legacy'
import * as R from 'ramda'

function getSegmentCurve([left, right]: [Entity, Entity]): string {
  const curveType = left.getAspectOrThrow(AnimationCurveAspect).curveType()

  switch (curveType) {
    case CurveType.Timing: {
      return [
        left.getComponentOrThrow(TimingCurveComponent).value.out.x,
        left.getComponentOrThrow(TimingCurveComponent).value.out.y,
        right.getComponentOrThrow(TimingCurveComponent).value.in.x,
        right.getComponentOrThrow(TimingCurveComponent).value.in.y,
      ]
        .map((value) => R.clamp(0, 1, value))
        .join(' ')
    }
    case CurveType.Spring: {
      return '0 0 1 1'
    }
    default: {
      const never: never = curveType
      throw new Error(`Should never reach "${never}"`)
    }
  }
}

export const getKeyframes = (
  component: Component,
  timeRange?: [number, number]
): Entity[] => {
  let preparedKeyframes: Entity[] = []

  const keyframes = R.sortBy(
    (keyframe) => keyframe.getComponentOrThrow(TimeComponent).value,
    getTimingCurveKeyframes(component)
  )
  const project = component.entity.getProjectOrThrow()
  const end =
    timeRange?.[1] ??
    project.getEntityByTypeOrThrow(Root).getComponentOrThrow(DurationComponent)
      .value

  const firstKeyframe = R.head(keyframes)
  const lastKeyframe = R.last(keyframes)

  // @NOTE: handle case when we have to extend animation to the start
  if (
    firstKeyframe != null &&
    firstKeyframe.getComponentOrThrow(TimeComponent).value > 0
  ) {
    const cloned = project.createIndependentEntityFromSnapshot(
      firstKeyframe.getSnapshot()
    )
    cloned.updateComponent(TimeComponent, 0)
    preparedKeyframes = R.prepend(cloned, preparedKeyframes)
  }

  preparedKeyframes = R.concat(preparedKeyframes, keyframes)

  // @NOTE: handle case when we have to extend animation to the end
  if (
    lastKeyframe != null &&
    lastKeyframe.getComponentOrThrow(TimeComponent).value < end
  ) {
    const cloned = project.createIndependentEntityFromSnapshot(
      lastKeyframe.getSnapshot()
    )
    cloned.updateComponent(TimeComponent, end)
    preparedKeyframes = R.append(cloned, preparedKeyframes)
  }

  // @NOTE: handle case when we have to shorten the animation
  if (
    lastKeyframe != null &&
    lastKeyframe.getComponentOrThrow(TimeComponent).value > end
  ) {
    const component = lastKeyframe
      .getAspectOrThrow(TargetRelationAspect)
      .getTargetComponentOrThrow()
    const keyframe = setAnimatableValue(
      component,
      getAnimatableValue(component, end),
      end,
      true
    )

    if (keyframe != null) {
      const lastKeyframe = preparedKeyframes.pop()!
      const timingCurve =
        lastKeyframe.getComponentOrThrow(TimingCurveComponent).value

      keyframe.updateComponent(TimingCurveComponent, {
        out: {
          x: timingCurve.out.x,
          y: timingCurve.out.y,
        },
        in: {
          x: 1,
          y: 1,
        },
      })

      preparedKeyframes = R.append(keyframe, preparedKeyframes)
    }
  }

  return preparedKeyframes
}

export function getKeySplines(segments: [Entity, Entity][]): string {
  return segments.map(getSegmentCurve).join(';')
}

export const getDuration = (
  keyframes: Entity[],
  timeRange?: [number, number]
): number => {
  const [start, end] = getStartEnd(keyframes, timeRange)
  return end - start
}

export const getSegments = (keyframes: Entity[]): [Entity, Entity][] =>
  R.aperture(2, keyframes)

export const getStartEnd = (
  keyframes: Entity[],
  timeRange?: [number, number]
): [number, number] => {
  if (keyframes.length === 0) {
    return [0, 0]
  }

  if (timeRange != null) {
    return timeRange
  }

  const projectDuration = R.head(keyframes)!
    .getProjectOrThrow()
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value

  return [
    round(R.head(keyframes)!.getComponentOrThrow(TimeComponent).value, {
      fixed: 4,
    }),
    Math.min(
      projectDuration,
      round(R.last(keyframes)!.getComponentOrThrow(TimeComponent).value, {
        fixed: 4,
      })
    ),
  ]
}

export function getKeyTimes(payload: {
  keyframes: Entity[]
  start: number
  end: number
}): string {
  const { keyframes, start, end } = payload

  return keyframes
    .map((keyframe, idx, array) => {
      if (idx === 0) {
        return 0
      }

      if (idx === array.length - 1) {
        return keyframe
          .getProjectOrThrow()
          .getEntityByTypeOrThrow(Root)
          .getComponentOrThrow(DurationComponent).value
      }

      return round(keyframe.getComponentOrThrow(TimeComponent).value, {
        fixed: 2,
      })
    })
    .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    .map((time) => round(time, { fixed: 2 }))
    .join(';')
}

/**
 * @description return samples for provided project duration.
 * Used as function to simplify future modifications.
 */
export function getSamplesForDuration(duration: number): number {
  return Math.round(duration * 50)
}

/**
 * @description return repeat count in svg-format.
 * Used as function to simplify future modifications.
 */
export function getRepeatCount(): string {
  // return '1'
  return 'indefinite'
}
