import { useMutation } from '@tanstack/react-query'

import { ApiPostCreatedResponse, ApiPostParams } from './helpers'
import { httpClient } from './http-client'

export function useRestoreUserInTeam() {
  return useMutation({
    async mutationFn(
      payload: ApiPostParams<'/teams/{teamId}/members/{userId}/restore'>['path']
    ) {
      return httpClient.post<
        ApiPostCreatedResponse<'/teams/{teamId}/members/{userId}/restore'>
      >(`/teams/${payload.teamId}/members/${payload.userId}/restore`)
    },
  })
}
