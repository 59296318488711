import * as React from 'react'

import { Clipboard } from './clipboard'
import { LocalClipboard } from './local.clipboard'

const Context = React.createContext<Clipboard>(
  new LocalClipboard({ logger: console })
)

export const useClipboard = (): Clipboard => React.useContext(Context)

export const ClipboardProvider: React.FCC<{ client: Clipboard }> = ({
  client,
  children,
}) => <Context.Provider value={client}>{children}</Context.Provider>
