import { useQuery } from '@tanstack/react-query'
import { config } from './config'
import { httpClient } from './http-client'
import { paths } from './open-api/schema'

export type LottieItems =
  (paths['/lotties']['get']['responses']['200']['content']['application/json'][number] & {
    // data: any
  })[]

export function useGetLotties() {
  return useQuery({
    queryKey: ['get-lotties'],
    async queryFn() {
      return httpClient.get<LottieItems>(`${config.apiUrl}/lotties`)
    },
  })
}
