import { Playback, Viewport } from '@aninix/core'
import * as React from 'react'

import {
  Project,
  getEntryOrThrow,
  getPosition,
  getSize,
} from '@aninix-inc/model'
import { useReaction } from '../../../../hooks/use-reaction'

type Payload = {
  viewport: Viewport
  project: Project
  playback: Playback
}

export interface IViewportZoomInteractor {
  zoomIn: () => void
  zoomOut: () => void
  zoomToFit: () => void
  zoomTo50: () => void
  zoomTo100: () => void
  zoomTo200: () => void
  openViewportZoomModal: () => void
  closeViewportZoomModal: () => void
  zoom: number
  isViewVisible: boolean
  isModalVisible: boolean
  children: {}
}

export const useViewportZoomInteractor = ({
  project,
  playback,
  viewport,
}: Payload): IViewportZoomInteractor => {
  const [isViewportZoomModalVisible, setIsViewportZoomModalVisible] =
    React.useState(false)

  const [currentZoom, setCurrentZoom] = React.useState(viewport.zoom)

  const zoomIn = React.useCallback(() => {
    viewport.zoomToPoint({
      point: viewport.center,
      zoomStep: 1.25,
    })
  }, [viewport])

  const zoomOut = React.useCallback(() => {
    viewport.zoomToPoint({
      point: viewport.center,
      zoomStep: 0.75,
    })
  }, [viewport])

  const zoomToFit = React.useCallback(() => {
    const entry = getEntryOrThrow(project)
    const position = getPosition(entry, playback.time)
    const size = getSize(entry, playback.time)
    // @NOTE: zoom to fit
    viewport.zoomToRect({
      x: position.x,
      y: position.y,
      width: size.x,
      height: size.y,
    })
  }, [viewport, project, playback])

  const zoomTo50 = React.useCallback(() => {
    viewport.updateZoom(0.5)
  }, [viewport])

  const zoomTo100 = React.useCallback(() => {
    viewport.updateZoom(1)
  }, [viewport])

  const zoomTo200 = React.useCallback(() => {
    viewport.updateZoom(2)
  }, [viewport])

  const openViewportZoomModal = React.useCallback(() => {
    setIsViewportZoomModalVisible(true)
  }, [setIsViewportZoomModalVisible])

  const closeViewportZoomModal = React.useCallback(() => {
    setIsViewportZoomModalVisible(false)
  }, [setIsViewportZoomModalVisible])

  useReaction(
    () => viewport.zoom,
    (zoom) => {
      setCurrentZoom(zoom)
    }
  )

  return {
    zoomIn,
    zoomOut,
    zoomToFit,
    zoomTo50,
    zoomTo100,
    zoomTo200,
    zoom: currentZoom,
    openViewportZoomModal,
    closeViewportZoomModal,
    isViewVisible: true,
    isModalVisible: isViewportZoomModalVisible,
    children: {},
  }
}
