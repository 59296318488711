import { LocalStorageIo } from '@aninix/api'
import { getLatestFeeling } from '@aninix/api/get-latest-feeling'
import { postFeeling } from '@aninix/api/post-feeling'
import { getCurrentUnixTimestamp } from 'apps/anitype-plugin/src/figma/utils/get-current-unix-timestamp'
import * as React from 'react'

interface FeelingsProps {
  isReadyToShow: boolean
  isDone: boolean
  errorMessage?: string
  onSubmit: (payload: Parameters<typeof postFeeling>[0]) => Promise<any>
}

const isMoreThan3Months = (milliseconds: number) =>
  //              ms    s    m    h     d   mounths count
  milliseconds > 1000 * 60 * 60 * 24 * 30 * 3

export const useFeelings = (): FeelingsProps => {
  const [isReadyToShow, setIsReadyToShow] = React.useState<boolean>(false)

  const [isDone, setIsDone] = React.useState<boolean>(false)

  const [errorMessage, setErrorMessage] = React.useState<string>()

  const latestFeelingLocalStorage = new LocalStorageIo<{
    unixTimestamp: number | undefined
  }>('aninix.latest-feeling', {
    unixTimestamp: undefined,
  })

  React.useEffect(() => {
    latestFeelingLocalStorage.get().then(async (value) => {
      let lastFeelingTimestamp = value?.unixTimestamp

      try {
        if (lastFeelingTimestamp == null) {
          const { createdAt } = (await getLatestFeeling()).data

          if (createdAt != null) {
            lastFeelingTimestamp = new Date(createdAt).getTime()
            latestFeelingLocalStorage.set({
              unixTimestamp: lastFeelingTimestamp,
            })
          }

          console.log(
            'createdAt, lastFeelingTimestamp',
            createdAt,
            lastFeelingTimestamp
          )
        }
      } catch (e) {}

      if (
        lastFeelingTimestamp == undefined ||
        isMoreThan3Months(getCurrentUnixTimestamp() - lastFeelingTimestamp)
      )
        setIsReadyToShow(true)
    })
  }, [])

  const onSubmit: FeelingsProps['onSubmit'] = (payload) => {
    return postFeeling(payload)
      .then(() => {
        latestFeelingLocalStorage.set({
          unixTimestamp: getCurrentUnixTimestamp(),
        })

        setIsDone(true)
      })
      .catch((e) => {
        setErrorMessage("Couldn't submit feedback. Try again later")
        throw Error(e)
      })
  }

  return { isReadyToShow, onSubmit, isDone, errorMessage }
}
