import { useQuery } from '@tanstack/react-query'

import { ApiGetParams, ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetTrash(payload: ApiGetParams<'/projects'>['query']) {
  return useQuery({
    queryKey: [
      'get-trash-projects',
      `${payload?.teamId} - ${payload?.folderId}`,
    ],
    async queryFn() {
      return httpClient.get<ApiGetResponse<'/projects'>>('/projects', {
        params: { ...payload, includeActive: false, includeDeleted: true },
      })
    },
  })
}
