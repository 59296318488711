import {
  Entity,
  TimeComponent,
  cleanupKeyframes,
  commitUndo,
} from '@aninix-inc/model'
import { mixed } from '@aninix-inc/model/legacy'
import { InputWithIcon, PropertyRowV2, icons } from '@aninix/app-design-system'
import { useComponent } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useFormatTime, useFormattedTime } from '../../formatted-time'
import { useThreshold } from '../../threshold'

export interface IProps {
  keyframes: Entity[]
}
export const Time: React.FCC<IProps> = observer(({ keyframes }) => {
  const firstKey = keyframes[0]
  useComponent(firstKey.getComponentOrThrow(TimeComponent))

  const threshold = useThreshold()

  const isEquals =
    keyframes.reduce((acc, keyframe) => {
      acc.add(keyframe.getComponentOrThrow(TimeComponent).value)
      return acc
    }, new Set()).size === 1
  const time = isEquals
    ? firstKey.getComponentOrThrow(TimeComponent).value
    : mixed
  const formattedTime = useFormattedTime(time)
  const { toSeconds } = useFormatTime()

  const updateValue = React.useCallback(
    (newValue: number) => {
      keyframes.forEach((keyframe) => {
        keyframe.updateComponent(TimeComponent, toSeconds(newValue))
      })
    },
    [keyframes, formattedTime]
  )

  const updateByDelta = React.useCallback(
    (delta: number) => {
      keyframes.forEach((keyframe) => {
        keyframe.updateComponent(TimeComponent, (currentTime) => {
          return currentTime + delta
        })
      })
    },
    [keyframes]
  )

  const formatValue = React.useCallback(
    (providedValue: number): string =>
      `${providedValue}${formattedTime.suffix}`,
    [formattedTime]
  )

  const onEndChange = React.useCallback(() => {
    const project = keyframes[0].getProjectOrThrow()
    cleanupKeyframes(project)
    commitUndo(project)
  }, [keyframes])

  return (
    <PropertyRowV2
      name="Time"
      inputs={
        <InputWithIcon
          value={formattedTime.value}
          id="time"
          icon={<icons.Time />}
          threshold={threshold}
          onChange={updateValue}
          onEndChange={onEndChange}
          onDeltaChange={updateByDelta}
          format={formatValue}
        />
      }
    />
  )
})
