import { InfoItem } from '@aninix/core/modules/inspector/mappers/types'
import { observer } from 'mobx-react'
import * as React from 'react'

import { Property } from '../segment/property'

export interface IProps {
  properties: InfoItem[]
  propertiesName: string
}
export const SegmentValueItems: React.FCC<IProps> = observer(
  ({ properties, propertiesName }: IProps) => {
    return (
      <>
        <p className="font-body text-xs font-normal text-secondary">
          {propertiesName}
        </p>
        <div className="grid grid-cols-3 gap-4">
          {properties.map(({ title, value }: any, index: number) => (
            <Property key={index.toString()} title={title} value={value} />
          ))}
        </div>
      </>
    )
  }
)

SegmentValueItems.displayName = 'SegmentValueItems'
