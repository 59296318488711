import * as React from 'react'

import { IUseApplyPresetUseCase, useApplyPresetUseCase } from './apply-preset'
import {
  IUseDownloadProjectUseCase,
  useDownloadProjectUseCase,
} from './download-project'
import * as segments from './segments'
export { generateAndDownloadSvgSequence } from './generate-and-download-svg-sequence'

export * from './segments'
export { useApplyPresetUseCase, useDownloadProjectUseCase }
export type { IUseApplyPresetUseCase, IUseDownloadProjectUseCase }

export interface IUseCases {
  useApplyPresetUseCase: typeof useApplyPresetUseCase
  useDownloadProjectUseCase: typeof useDownloadProjectUseCase

  useSegmentsPasteUseCase: typeof segments.useSegmentsPasteUseCase
  useSegmentsReverseUseCase: typeof segments.useSegmentsReverseUseCase
}

const Context = React.createContext<IUseCases>(null as any)

export const useUseCases = (): IUseCases => React.useContext(Context)

export interface IProps {}
export const UseCasesProvider: React.FCC<IProps> = ({ children }) => (
  <Context.Provider
    value={{
      useApplyPresetUseCase,
      useDownloadProjectUseCase,
      useSegmentsPasteUseCase: segments.useSegmentsPasteUseCase,
      useSegmentsReverseUseCase: segments.useSegmentsReverseUseCase,
    }}
  >
    {children}
  </Context.Provider>
)
