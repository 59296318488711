import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 16,
  y: 16,
}

export const Copy: React.FCC<IIcon> = ({
  size = defaultSize,
  className,
  color = 'black',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 2H10V10H2V2Z" stroke={color} strokeLinecap="square" />
    <path
      d="M12 6H14V14H6V12"
      stroke={color}
      strokeLinecap="square"
      strokeDasharray="2 2"
    />
  </svg>
)
