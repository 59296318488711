import { CurveType, mixed, timingCurve } from '@aninix-inc/model/legacy'
import { PropertyRow, buttons, icons } from '@aninix/app-design-system'
import { Popover } from '@material-ui/core'
import * as React from 'react'
import { ICurveInteractor } from './interactor'

const timingCurveIconData = timingCurve
  .create({
    out: {
      x: 0.5,
      y: 0,
    },
    in: {
      x: 0.15,
      y: 1,
    },
  })
  .getCache()

export interface IProps {
  graph: React.ReactNode
  userPresets: React.ReactNode
  interactor: ICurveInteractor
}
export const CurveRouter: React.FCC<IProps> = ({
  graph,
  userPresets,
  interactor,
}) => {
  const headerRef = React.useRef<any>()

  const iconSize = React.useMemo(
    () => ({
      x: 14,
      y: 14,
    }),
    []
  )

  const renderHeaderButtons = React.useCallback(() => {
    if (interactor.appliedCurveStyle != null) {
      return (
        <buttons.Icon onClick={interactor.detachCurveStyle}>
          <icons.propertiesPanel.Detach size={iconSize} />
        </buttons.Icon>
      )
    }

    if (interactor.type === mixed) {
      return (
        <div className="flex flex-row flex-nowrap items-center justify-start">
          <buttons.Icon onClick={interactor.showUserPresets}>
            <icons.Menu />
          </buttons.Icon>

          <buttons.Icon onClick={interactor.applyBasicCurveStyle}>
            <icons.Add />
          </buttons.Icon>
        </div>
      )
    }

    return (
      <div className="flex flex-row flex-nowrap items-center justify-start">
        <buttons.Group
          buttons={[
            {
              id: CurveType.Timing,
              icon: (
                <icons.Graph previews={timingCurveIconData} size={iconSize} />
              ),
              onClick: () => interactor.updateCurveType(CurveType.Timing),
            },
            {
              id: CurveType.Spring,
              icon: <icons.CurveSpring size={iconSize} />,
              onClick: () => interactor.updateCurveType(CurveType.Spring),
            },
          ]}
          selectedId={interactor.type}
        />

        <buttons.Icon onClick={interactor.showUserPresets}>
          <icons.Menu />
        </buttons.Icon>
      </div>
    )
  }, [interactor])

  const renderInputs = React.useCallback(() => <>{graph}</>, [graph])

  const title = (() => {
    if (interactor.type === mixed) {
      return 'Value curve (mixed)'
    }

    if (interactor.appliedCurveStyle != null) {
      return (
        <div className="flex flex-row flex-nowrap items-center justify-start text-[var(--figma-color-text)]">
          <icons.Graph previews={interactor.appliedCurveStyle.getCache()} />
          <span className="ml-2">{interactor.appliedCurveStyleName}</span>
        </div>
      )
    }

    return 'Value curve'
  })()

  return (
    <>
      <div ref={headerRef}>
        <PropertyRow
          name={title}
          renderHeaderButtons={renderHeaderButtons}
          renderInputs={renderInputs}
          onHeaderClick={
            interactor.appliedCurveStyle != null
              ? interactor.showUserPresets
              : undefined
          }
          wide={interactor.appliedCurveStyle != null}
        />
      </div>

      <Popover
        open={interactor.children.userPresets}
        anchorEl={headerRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        style={{
          transform: 'translate(8px, 40px)',
        }}
        onClose={interactor.hideUserPresets}
        disableRestoreFocus
      >
        {userPresets}
      </Popover>
    </>
  )
}
