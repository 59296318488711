import { useLottie } from 'apps/web-app/src/use-cases/use-lottie'
import { format } from 'date-fns'
import { AnimationItem } from 'lottie-web'
import * as React from 'react'
import { ProgressBar } from './progress-bar'

export interface IProps {
  animationItem: AnimationItem
}
export const PlaybackProgress: React.FCC<IProps> = ({ animationItem }) => {
  const { currentFrame, frameRate } = useLottie(animationItem)

  const currentTime = React.useMemo(
    () => (currentFrame / frameRate) * 1000,
    [currentFrame]
  )

  const totalTime = React.useMemo(
    () => animationItem.getDuration() * 1000,
    [animationItem]
  )

  const formattedTime = React.useCallback(
    (time: number, formatString: string = 'mm:ss') => {
      if (totalTime < 10_000) {
        return (time / 1000).toFixed(1) + ' s'
      }

      const formattedString = format(time, formatString)
      return formattedString
    },
    [totalTime]
  )

  return (
    <div className="flex flex-row items-center gap-[10px]">
      <p className="w-10 pl-1 text-right text-xs tabular-nums text-secondary">
        {formattedTime(currentTime)}
      </p>
      <ProgressBar animationItem={animationItem} />
      <p className="w-max pr-2 text-right text-xs tabular-nums text-secondary">
        {formattedTime(totalTime)}
      </p>
    </div>
  )
}

PlaybackProgress.displayName = 'PlaybackProgress'
