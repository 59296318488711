import * as React from 'react'

const initialSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Effect: React.FCC<IProps> = ({ size = initialSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M13.5 2.5L11 5"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <circle
      cx="8"
      cy="8"
      r="2"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path d="M8 0.5V3.5" stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))" />
    <path
      d="M15.5 8H12.5"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M13.5 13.5L11 11"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M8 15.5V12.5"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M2.5 13.5L5 11"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path d="M0.5 8H3.5" stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))" />
    <path
      d="M2.5 2.5L5 5"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
