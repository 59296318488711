import * as React from 'react'
import { Link } from 'react-router-dom'

import { AccessRestrictedIcon } from '../access-restricted/icon'

export interface IProps {
  url?: string
  ownerInfo?: string
}
export const FreeLimitExceeded: React.FC<IProps> = ({
  url = '/',
  ownerInfo,
}) => (
  <div className="mx-auto flex h-screen w-screen max-w-lg flex-col items-center justify-center px-6 text-center text-black/80">
    <AccessRestrictedIcon className="w-[258px]" />

    <p className="mt-6 text-2xl font-semibold">Free limit exceeded</p>

    <p className="mt-2 text-base">
      To remove the limit, please ask owner{ownerInfo && ` (${ownerInfo})`} to
      upgrade. This can be done on the{' '}
      <Link to={url} className="text-accent hover:opacity-80">
        profile page.
      </Link>
    </p>
  </div>
)
