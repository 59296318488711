import { useViewport } from '@aninix/core'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import * as React from 'react'
import { ZoomDropdown } from './zoom-dropdown'

export interface IProps {}
export const Zoom: React.FCC<IProps> = observer(() => {
  const viewport = useViewport()

  const [isZoomMenuOpen, setIsZoomMenuOpen] = React.useState<boolean>(false)

  const handleZoomMenuClick = React.useCallback(() => {
    setIsZoomMenuOpen((state) => !state)
  }, [])
  const handleZoomMenuClose = React.useCallback(() => {
    setIsZoomMenuOpen(false)
  }, [])

  const zoomMenuButtonRef = React.useRef(null)

  const zoomLabel = React.useMemo(() => {
    return (viewport.zoom * 100).toFixed(0) + '%'
  }, [viewport.zoom])
  return (
    <>
      <button
        onClick={handleZoomMenuClick}
        ref={zoomMenuButtonRef}
        className="group relative flex h-8 cursor-default flex-row items-center justify-center gap-1 pl-2 pr-1"
      >
        <div
          className={classNames(
            'absolute inset-0 z-0 rounded-lg bg-white opacity-0 transition-opacity duration-150',
            {
              ['group-hover:!opacity-10']: !isZoomMenuOpen,
              ['opacity-10']: isZoomMenuOpen,
            }
          )}
        />

        <p className="z-10 w-9 text-center text-xs text-gray-100">
          {zoomLabel}
        </p>
        <ShevronDownIcon className="z-10" />
      </button>

      {/* this is popover */}
      <ZoomDropdown
        anchorEl={zoomMenuButtonRef.current}
        onClose={handleZoomMenuClose}
        isOpen={isZoomMenuOpen}
      />
    </>
  )
})

Zoom.displayName = 'Zoom'

const ShevronDownIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6.5L8 9.5L5 6.5"
      stroke="#5C6574"
      strokeWidth="1.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
