import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 24,
  y: 24,
}

export const Download: React.FC<IIcon> = ({
  size = defaultSize,
  className,
  color = '#89909A',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M4 17V19C4 19.5304 4.21071 20.0391 4.58579 20.4142C4.96086 20.7893 5.46957 21 6 21H18C18.5304 21 19.0391 20.7893 19.4142 20.4142C19.7893 20.0391 20 19.5304 20 19V17"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 11L9.73726 13.7373C10.5293 14.5293 10.9253 14.9253 11.382 15.0737C11.7837 15.2042 12.2163 15.2042 12.618 15.0737C13.0747 14.9253 13.4707 14.5293 14.2627 13.7373L17 11"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 4V16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
