import { LayerColor } from '@aninix-inc/model/legacy'

import { config, Os } from './config'

export const MIN_FPS = 6
export const MAX_FPS = 120
export const MIN_DURATION = 0.1
export const MAX_DURATION = 600 // 10 mins
export const MINIMIZED_WINDOW_WIDTH = 240
export const MINIMIZED_WINDOW_HEIGHT = 56

export const defaults = {
  nodeColors: {
    [LayerColor.Blue]: '#18A0FB',
    [LayerColor.Purple]: '#7B61FF',
    [LayerColor.Pink]: '#FF00FF',
    [LayerColor.Green]: '#1BC47D',
    [LayerColor.Turqouise]: '#00B5CE',
    [LayerColor.Red]: '#F24822',
    [LayerColor.Orange]: '#FF9900',
    [LayerColor.Yellow]: '#FFC700',
    [LayerColor.Grey]: '#9FAFBA',
    [LayerColor.None]: '#7A7A7A',
  },
}

export const hotkeysLabels = () => {
  const os = config.getOs()
  const isMac = os === Os.Mac

  return {
    ctrl: isMac ? '⌘' : 'Ctrl ',
    shift: isMac ? '⇧' : 'Shift ',
    option: isMac ? '⌥' : 'Alt ',
    // space: isMac ? '⎵' : 'Space ',
    space: 'Space',
    remove: '⌫',
    delete: isMac ? '⌫' : 'Del ',
    enter: isMac ? '↵' : 'Enter ',
  }
}
