// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iONh9nbKhU_J04ovzqQj{display:flex;align-items:center;justify-content:space-between}.t_JHxtMex0u9X2Z_Pqa0{width:100%}`, "",{"version":3,"sources":["webpack://./packages/app-design-system/src/components/common/graph-editor/components/copy-preset/index.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,kBAAA,CACA,6BAAA,CAGF,sBACE,UAAA","sourcesContent":["@use '../../../../../theme/index.scss' as *;\n\n.wrapper {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.input-wrapper {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export var wrapper = `iONh9nbKhU_J04ovzqQj`;
var _1 = `t_JHxtMex0u9X2Z_Pqa0`;
export { _1 as "input-wrapper" };
export default ___CSS_LOADER_EXPORT___;
