import * as React from 'react'

import { IIcon } from '.'

const defaultSize = { x: 16, y: 16 }

export const UploadFile: React.FCC<IIcon> = ({
  size = defaultSize,
  className = 'stroke-white',
}) => (
  <svg
    width={defaultSize.x}
    height={defaultSize.y}
    viewBox="0 0 17 16"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0714 1V4.11111C10.0714 4.31739 10.1542 4.51522 10.3016 4.66108C10.4489 4.80694 10.6488 4.88889 10.8571 4.88889H14M10.0714 1H4.57143C4.15466 1 3.75496 1.16389 3.46026 1.45561C3.16556 1.74733 3 2.143 3 2.55556V13.4444C3 13.857 3.16556 14.2527 3.46026 14.5444C3.75496 14.8361 4.15466 15 4.57143 15H12.4286C12.8453 15 13.245 14.8361 13.5397 14.5444C13.8344 14.2527 14 13.857 14 13.4444V4.88889M10.0714 1L14 4.88889M8.5 7.22222V11.8889M8.5 7.22222L6.53571 9.16667M8.5 7.22222L10.4643 9.16667"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
