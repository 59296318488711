import { Version } from '@aninix-inc/model'
import { httpClient } from '@aninix/api'
import { config } from '@aninix/api/config'
import React from 'react'

export interface GetProjectHistoryVersionRequest {
  (payload: { projectId: string; versionId: string }): {
    result: GetProjectHistoryVersionResult
    handleFetch: () => void
  }
}

export interface GetProjectHistoryVersionResult {
  data: Version | null
  isError: boolean
  isLoading: boolean
}

export const useGetProjectHistoryVersion: GetProjectHistoryVersionRequest = ({
  projectId,
  versionId,
}) => {
  const [result, setResult] = React.useState<GetProjectHistoryVersionResult>({
    data: null,
    isLoading: true,
    isError: false,
  })

  const handleFetch = React.useCallback(() => {
    setResult({
      data: result.data,
      isLoading: true,
      isError: false,
    })

    httpClient
      .get<GetProjectHistoryVersionResult['data']>(
        `${config.apiUrl}/v2/projects/${projectId}/versions/${versionId}`,
        {
          responseType: 'json',
        }
      )
      .then((value) =>
        setResult({
          data: value.data,
          isLoading: false,
          isError: false,
        })
      )
      .catch((e) => setResult({ data: null, isLoading: false, isError: true }))
  }, [])

  return { result, handleFetch }
}
