import { useQuery } from '@tanstack/react-query'
import { ApiGetParams, ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetFolders(payload: ApiGetParams<'/folders'>['query']) {
  return useQuery({
    queryKey: [
      'get-dashboard-folders',
      //@ts-ignore
      `${payload.userId} - ${payload?.teamId} - ${payload?.folderId}`,
    ],
    async queryFn() {
      return httpClient.get<ApiGetResponse<'/folders'>>('/folders', {
        params: payload,
      })
    },
  })
}
