import classnames from 'classnames'
import * as React from 'react'

import { lerpClamped } from '@aninix-inc/model'

export interface IProps {
  className?: string
  size?: number
  color?: string
}
export const Loader: React.FCC<IProps> = ({
  className,
  size = 32,
  color = '#18a0fb',
}) => (
  <span
    className={classnames(
      'block flex-shrink-0 border-solid rounded-full',
      'animate-spin',
      className
    )}
    style={{
      width: size,
      height: size,
      color,
      borderColor: color,
      borderTopColor: 'transparent',
      // @NOTE: default border is 2px
      borderWidth: `${lerpClamped(size / 24, 1, 3)}px`,
    }}
  />
)
