import * as R from 'ramda'

import {
  Entity,
  FillsRelationsAspect,
  NodeTypeComponent,
  PaintType,
  PaintTypeComponent,
} from '@aninix-inc/model'
import { DataDrawer, getDataMap } from '@aninix-inc/renderer'
import {
  LottieColor,
  LottieFillRule,
  LottieGradient,
  LottieShapeFill,
  LottieShapeGradientFill,
  LottieShapeLayerType,
} from '../types'
import { Paint, shouldRenderPaint } from './paint'

function mapWindingRuleToLottie(
  windingRule: VectorPath['windingRule']
): LottieFillRule {
  switch (windingRule) {
    case 'NONE':
      return LottieFillRule.Nonzero
    case 'NONZERO':
      return LottieFillRule.Nonzero
    case 'EVENODD':
      return LottieFillRule.Evenodd
  }
}

function WindingRule(payload: { node: Entity }): LottieFillRule {
  const { node } = payload
  // @ts-ignore
  const getData = getDataMap[
    node.getComponentOrThrow(NodeTypeComponent).value
  ] as DataDrawer
  const paths = getData({ entity: node, time: 0 })
  return paths.length > 0
    ? mapWindingRuleToLottie(R.head(paths)!.windingRule)
    : LottieFillRule.Nonzero
}

export function Fill(payload: {
  node: Entity
  paint: Entity
}): LottieShapeFill | LottieShapeGradientFill {
  const { node, paint } = payload
  const windingRule = WindingRule({ node })
  const mappedPaint = Paint({ node, paint })

  // @ts-ignore
  if (mappedPaint.t != null) {
    const lottieGradient = mappedPaint as LottieGradient
    return {
      ty: LottieShapeLayerType.GradientFill,
      ...lottieGradient,
      nm: 'Fill',
      hd: false,
      r: windingRule,
    }
  }

  const lottieColor = mappedPaint as LottieColor
  return {
    ty: LottieShapeLayerType.Fill,
    ...lottieColor,
    nm: 'Fill',
    hd: false,
    r: windingRule,
  }
}

/**
 * @description extract lottie shape fill info from node
 */
export function Fills(payload: {
  node: Entity
}): (LottieShapeFill | LottieShapeGradientFill)[] {
  const { node } = payload

  const paints: Array<LottieShapeFill | LottieShapeGradientFill> = R.defaultTo(
    [],
    node.getAspect(FillsRelationsAspect)?.getChildrenList()
  )
    .filter((paint) => {
      // @NOTE: don't remember why this is required.
      // @TODO: add note once figured out the use case.
      if (
        paint.getComponentOrThrow(PaintTypeComponent).value === PaintType.Image
      ) {
        return false
      }

      return shouldRenderPaint(paint)
    })
    .map((paint) => Fill({ node, paint }))
    // @NOTE: for some reason we have to reverse all paints to meet the same render as we have in Figma
    .reverse()

  return paints
}
