import { getAbsoluteTransformMatrix, Point2D } from '@aninix-inc/model'
import { paper } from '@aninix-inc/renderer'
import { useMouseMove } from '@aninix/app-design-system'
import {
  KeyModificator,
  Tool,
  usePlayback,
  useSession,
  useTools,
  useViewport,
} from '@aninix/core/stores'
import { BezierPoint } from '@aninix/core/vector-helpers'
import { observer } from 'mobx-react'

import * as React from 'react'

export interface IProps {
  node: any
  color: string
  onStartChange: () => void
  onEndChange: () => void
  bezier: BezierPoint
  editable?: boolean
  pathIndex: number
  bezierIndex: number
  onPathUpdate: (
    pathIndex: number,
    bezierIndex: number,
    newBezier: BezierPoint
  ) => void
  selection: Set<string>
  setSelection: React.Dispatch<React.SetStateAction<Set<string>>>
  onTangentsUpdate: (
    updatedPathIndex: number,
    updatedBezierIndex: number,
    newRelativeStartPoint: Point2D,
    newRelativeEndPoint: Point2D
  ) => void
  prevAndNextPoints: Point2D[]
}

export const RegionPoint: React.FCC<IProps> = observer(
  ({
    bezier,
    node,
    onStartChange,
    onEndChange,
    color,
    editable = true,
    onPathUpdate,
    bezierIndex,
    pathIndex,
    selection,
    setSelection,
    onTangentsUpdate,
    prevAndNextPoints,
  }) => {
    const targetRef = React.useRef<'vertex' | 'tangent-left' | 'tangent-right'>(
      'vertex'
    )
    const tools = useTools()
    const viewport = useViewport()
    const playback = usePlayback()
    const session = useSession()

    const prevTool = React.useRef(tools.activeTool)

    const [startDragPoint, setStartDragPoint] = React.useState<Point2D>({
      x: 0,
      y: 0,
    })

    const { offsetX, offsetY, startListen, isListening, wasTriggered } =
      useMouseMove({
        threshold: 10,
        element: document.getElementById('stage')!,
        listenElementForMouseUpEvent: true,
        // @NOTE: 120 fps
        delay: 8.3,
        onStart: onStartChange,
        onFinish: onEndChange,
      })

    const id = `${pathIndex}-${bezierIndex}`

    const isSelected = React.useMemo(() => selection.has(id), [selection])

    const setIsSelected = React.useCallback(
      (isSelected: boolean) => {
        const newSelection = new Set(selection)

        if (isSelected) newSelection.add(id)
        else newSelection.delete(id)

        setSelection(newSelection)
      },
      [selection]
    )

    const parentAbsoluteTransformMatrix = new paper.Matrix(
      getAbsoluteTransformMatrix({
        entity: node,
        time: playback.time,
      })
    )
    const parentAbsoluteTransformMatrixAtKeyframeTime = new paper.Matrix(
      getAbsoluteTransformMatrix({
        entity: node,
        time: playback.time,
      })
    )

    const vertexPosition = parentAbsoluteTransformMatrix.transform(
      new paper.Point(bezier.point)
    )
    const leftTangent = parentAbsoluteTransformMatrix.transform(
      new paper.Point(
        bezier.point.x + bezier.startTangent.x,
        bezier.point.y + bezier.startTangent.y
      )
    )
    const rightTangent = parentAbsoluteTransformMatrix.transform(
      new paper.Point(
        bezier.point.x + bezier.endTangent.x,
        bezier.point.y + bezier.endTangent.y
      )
    )

    const zerifyPosition = React.useCallback(
      (moved: Point2D) => {
        const movedPoint =
          parentAbsoluteTransformMatrixAtKeyframeTime.inverseTransform(
            new paper.Point(moved.x, moved.y)
          )
        const startPoint =
          parentAbsoluteTransformMatrixAtKeyframeTime.inverseTransform(
            new paper.Point({ x: -startDragPoint.x, y: -startDragPoint.y })
          )

        return {
          x: movedPoint.x - startPoint.x,
          y: movedPoint.y - startPoint.y,
        }
      },
      [
        parentAbsoluteTransformMatrixAtKeyframeTime,
        vertexPosition,
        viewport,
        startDragPoint,
      ]
    )

    const handleTangentMove = React.useCallback(
      ({
        position: newPosition,
        isLeft,
      }: {
        position: Point2D
        isLeft: boolean
      }): void => {
        if (editable === false) {
          return
        }

        const newRelativePosition = {
          x: bezier.point.x + newPosition.x,
          y: bezier.point.y + newPosition.y,
        }

        onTangentsUpdate(
          pathIndex,
          bezierIndex,
          isLeft ? newRelativePosition : bezier.startTangent,
          isLeft ? bezier.endTangent : newRelativePosition
        )
      },
      [bezier, editable]
    )

    const handleVertexMove = React.useCallback(
      ({ position: newOffset }: { position: Point2D }): void => {
        if (editable === false) {
          return
        }

        onPathUpdate(pathIndex, bezierIndex, {
          point: newOffset,
        } as BezierPoint)
      },
      [editable, onPathUpdate]
    )

    const toggleVertexTangents = React.useCallback(() => {
      const id = (() => {
        const xEquals = bezier.startTangent.x === 0 && bezier.endTangent.x === 0
        const yEquals = bezier.startTangent.y === 0 && bezier.endTangent.y === 0

        if (xEquals && yEquals) {
          return 'no-mirroring'
        }

        return 'custom'
      })()

      const nextId = (() => {
        if (id === 'custom') {
          return 'no-mirroring'
        }

        return 'custom'
      })()

      if (nextId === 'custom') {
        const [newStartRelative, newEndRelative] = getCubicControlPoints(
          prevAndNextPoints[0],
          bezier.point,
          prevAndNextPoints[1]
        )

        console.log(prevAndNextPoints[0], bezier.point, prevAndNextPoints[1])
        console.log(newStartRelative, newEndRelative)

        onTangentsUpdate(
          pathIndex,
          bezierIndex,
          newStartRelative,
          newEndRelative
        )
      }

      if (nextId === 'no-mirroring') {
        const newBothRelative = {
          x: 0,
          y: 0,
        }

        onTangentsUpdate(
          pathIndex,
          bezierIndex,
          newBothRelative,
          newBothRelative
        )
      }
    }, [bezier, onTangentsUpdate])

    const handleVertexSelect = (): void => {
      if (isSelected) {
        if (session.keyModificators.includes(KeyModificator.Shift)) {
          setIsSelected(false)
          return
        }

        return
      }

      if (session.keyModificators.includes(KeyModificator.Shift)) {
        setIsSelected(true)
        return
      }

      setSelection(new Set([id]))
    }

    const handleVertexClick = React.useCallback(() => {
      handleVertexSelect()
    }, [handleVertexSelect, session])

    const handleMouseOver = React.useCallback(() => {
      const nextActiveTool = isCmdDown ? Tool.ToggleTangents : Tool.Selection

      if (prevTool.current === nextActiveTool) {
        return
      }

      prevTool.current = tools.activeTool
      tools.changeTool(nextActiveTool)
    }, [tools])

    const handleMouseLeave = React.useCallback(() => {
      tools.changeTool(prevTool.current || Tool.Selection)
    }, [tools, session])

    const handleTangentLeftDragStart = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()

        setStartDragPoint(bezier.clone().startTangent)

        if (tools.activeTool !== Tool.Selection) {
          return
        }

        // @ts-ignore
        startListen(e)
        targetRef.current = 'tangent-left'
      },
      [startListen, tools]
    )

    const handleTangentRightDragStart = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()

        setStartDragPoint(bezier.clone().endTangent)
        // @ts-ignore
        startListen(e)
        targetRef.current = 'tangent-right'
      },
      [startListen]
    )

    const handleVertexDragStart = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        // @ts-ignore
        startListen(e)
        targetRef.current = 'vertex'
        setStartDragPoint(bezier.point)
      },
      [startListen, bezier]
    )

    const handleDragEnd = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        // onPathUpdate(pathIndex, bezierIndex, localPosition)
      },
      []
    )

    const handleClick = React.useCallback(
      (e: React.MouseEvent<SVGElement, MouseEvent>) => {
        e.preventDefault()
        e.stopPropagation()
        handleVertexClick()

        if (tools.activeTool === Tool.ToggleTangents) {
          toggleVertexTangents()
        }
      },
      [handleVertexClick, toggleVertexTangents, tools]
    )

    React.useEffect(() => {
      if (isListening === false || wasTriggered === false) {
        return
      }

      if (
        isListening &&
        isSelected === false &&
        targetRef.current === 'vertex'
      ) {
        handleVertexClick()
      }

      const moved = (() => {
        const actualOffsetX = Math.round(offsetX / viewport.zoom)
        const actualOffsetY = Math.round(offsetY / viewport.zoom)

        if (session.keyModificators.includes(KeyModificator.Shift)) {
          const isXBigger = Math.abs(actualOffsetX) > Math.abs(actualOffsetY)
          const isYBigger = Math.abs(actualOffsetX) < Math.abs(actualOffsetY)

          if (isXBigger) {
            return {
              x: actualOffsetX,
              y: 0,
            }
          }

          if (isYBigger) {
            return {
              x: 0,
              y: actualOffsetY,
            }
          }
        }

        return {
          x: actualOffsetX,
          y: actualOffsetY,
        }
      })()

      const offset = zerifyPosition(moved)

      if (targetRef.current === 'vertex') {
        handleVertexMove({
          position: {
            x: offset.x - bezier.point.x,
            y: offset.y - bezier.point.y,
          },
        })
        return
      }

      if (targetRef.current === 'tangent-left') {
        handleTangentMove({
          position: {
            x: offset.x - bezier.point.x,
            y: offset.y - bezier.point.y,
          },
          isLeft: true,
        })
        return
      }

      if (targetRef.current === 'tangent-right') {
        handleTangentMove({
          position: {
            x: offset.x - bezier.point.x,
            y: offset.y - bezier.point.y,
          },
          isLeft: false,
        })
      }
    }, [isListening, wasTriggered, offsetX, offsetY, isSelected])

    const tangentVertexSize = 6 / viewport.zoom

    const [isCmdDown, setIsCmdDown] = React.useState(false)
    const [isHovered, setIsHovered] = React.useState(false)

    React.useEffect(() => {
      if (isHovered && isCmdDown) {
        prevTool.current = tools.activeTool
        tools.changeTool(Tool.ToggleTangents)
      }

      if (!isCmdDown && tools.activeTool === Tool.ToggleTangents) {
        tools.changeTool(prevTool.current)
      }
    }, [isHovered, isCmdDown])

    const vertexRef = React.createRef<SVGCircleElement>()

    const handleDocumentMouseMove = (e: MouseEvent) => {
      setIsHovered(vertexRef?.current?.matches(':hover') ?? false)
    }

    const handleDocumentKeyDown = (e: KeyboardEvent) => {
      setIsCmdDown(e.metaKey)
      setIsHovered(vertexRef?.current?.matches(':hover') ?? false)

      console.log(e.metaKey, vertexRef?.current?.matches(':hover') ?? false)
    }

    const handleDocumentKeyUp = (e: KeyboardEvent) => {
      setIsCmdDown(e.metaKey)
      setIsHovered(vertexRef?.current?.matches(':hover') ?? false)
    }

    React.useEffect(() => {
      window.addEventListener('mousemove', handleDocumentMouseMove)
      window.addEventListener('keydown', handleDocumentKeyDown)
      window.addEventListener('keyup', handleDocumentKeyUp)

      return () => {
        window.removeEventListener('mousemove', handleDocumentMouseMove)
        window.removeEventListener('keydown', handleDocumentKeyDown)
        window.removeEventListener('keyup', handleDocumentKeyUp)
      }
    }, [
      handleDocumentMouseMove,
      handleDocumentKeyDown,
      handleDocumentKeyUp,
      vertexRef.current,
    ])

    const tangents = () => {
      if (
        bezier.startTangent.x === 0 &&
        bezier.startTangent.y === 0 &&
        bezier.endTangent.x === 0 &&
        bezier.endTangent.y === 0
      ) {
        return null
      }

      return [leftTangent, rightTangent].map((point, idx) => (
        <React.Fragment key={idx}>
          <line
            x1={point.x}
            y1={point.y}
            x2={vertexPosition.x}
            y2={vertexPosition.y}
            strokeWidth={1 / viewport.zoom}
            stroke={color}
          />

          <g className="group">
            <rect
              className="p-1"
              name="spatial-control"
              x={point.x}
              y={point.y}
              width={tangentVertexSize * 2}
              height={tangentVertexSize * 2}
              transform={`rotate(45 ${point.x} ${
                point.y
              }) translate(${-tangentVertexSize} ${-tangentVertexSize})`}
              fill="transparent"
              strokeWidth={1 / viewport.zoom}
              onMouseDown={
                idx === 0
                  ? handleTangentLeftDragStart
                  : handleTangentRightDragStart
              }
              onMouseUp={handleDragEnd}
              onClick={handleClick}
            />

            <rect
              style={{ '--nodeColor': color } as React.CSSProperties}
              className="group-hover:fill-[var(--nodeColor)] fill-white pointer-events-none"
              name="spatial-visible-handle"
              x={point.x}
              y={point.y}
              width={tangentVertexSize}
              height={tangentVertexSize}
              transform={`rotate(45 ${point.x} ${point.y}) translate(${
                -tangentVertexSize / 2
              } ${-tangentVertexSize / 2})`}
              stroke={color}
              strokeWidth={1 / viewport.zoom}
            />
          </g>
        </React.Fragment>
      ))
    }

    return (
      <>
        {/* @NOTE: tangents */}
        {tangents()}

        {/* @NOTE: vertex */}
        <circle
          ref={vertexRef}
          id={JSON.stringify(bezier)}
          cx={vertexPosition.x}
          cy={vertexPosition.y}
          r={4 / viewport.zoom}
          fill="#FFFFFF"
          stroke={color}
          strokeWidth={isSelected ? 3 / viewport.zoom : 1 / viewport.zoom}
          onMouseOver={handleMouseOver}
          onMouseMove={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onMouseDown={handleVertexDragStart}
          onMouseUp={handleDragEnd}
          onClick={handleClick}
        />
      </>
    )
  }
)

RegionPoint.displayName = 'RegionPoint'

function getCubicControlPoints(
  prevPoint: Point2D,
  currPoint: Point2D,
  nextPoint: Point2D
): [Point2D, Point2D] {
  const niceApproxCurviness = 0.55342686 //https://spencermortensen.com/articles/bezier-circle/

  const a = prevPoint.x - currPoint.x
  const b = prevPoint.y - currPoint.y
  const c = nextPoint.x - currPoint.x
  const d = nextPoint.y - currPoint.y
  const e = a * (prevPoint.x + currPoint.x) + b * (prevPoint.y + currPoint.y)
  const f = c * (nextPoint.x + currPoint.x) + d * (nextPoint.y + currPoint.y)
  const g =
    2 * (a * (nextPoint.y - currPoint.y) - b * (nextPoint.x - currPoint.x))
  let centerX = (d * e - b * f) / g
  let centerY = (a * f - c * e) / g

  const orientation =
    (currPoint.y - prevPoint.y) * (nextPoint.x - currPoint.x) -
    (currPoint.x - prevPoint.x) * (nextPoint.y - currPoint.y)

  let currLeftTangent: Point2D
  let currRightTangent: Point2D

  if (orientation > 0) {
    // Clockwise
    currLeftTangent = {
      x: -(currPoint.y - centerY),
      y: currPoint.x - centerX,
    }
    currRightTangent = {
      x: currPoint.y - centerY,
      y: -(currPoint.x - centerX),
    }
  } else {
    // Counterclockwise
    currRightTangent = {
      x: -(currPoint.y - centerY),
      y: currPoint.x - centerX,
    }
    currLeftTangent = {
      x: currPoint.y - centerY,
      y: -(currPoint.x - centerX),
    }
  }

  const distanceToPrevPoint = Math.hypot(
    prevPoint.x - currPoint.x,
    prevPoint.y - currPoint.y
  )
  const distanceToNextPoint = Math.hypot(
    nextPoint.x - currPoint.x,
    nextPoint.y - currPoint.y
  )

  // Diameter of the circumcircle formed by the three points
  const diameter = Math.hypot(centerX - currPoint.x, centerY - currPoint.y) * 2

  const totalDistanceToNeighbourPoints =
    distanceToPrevPoint + distanceToNextPoint

  const commonCompensation =
    Math.sqrt(distanceToPrevPoint ** 2 + distanceToNextPoint ** 2) / diameter

  const leftCompensation =
    distanceToNextPoint /
    totalDistanceToNeighbourPoints /
    0.5 /
    commonCompensation

  const rightCompensation =
    distanceToPrevPoint /
    totalDistanceToNeighbourPoints /
    0.5 /
    commonCompensation

  const currLeftTangentLength = Math.sqrt(
    currLeftTangent.x ** 2 + currLeftTangent.y ** 2
  )
  const currRightTangentLength = Math.sqrt(
    currRightTangent.x ** 2 + currRightTangent.y ** 2
  )

  const normalizedLeftTangent: Point2D = {
    x: currLeftTangent.x / (currLeftTangentLength * leftCompensation),
    y: currLeftTangent.y / (currLeftTangentLength * leftCompensation),
  }
  const normalizedRightTangent: Point2D = {
    x: currRightTangent.x / (currRightTangentLength * rightCompensation),
    y: currRightTangent.y / (currRightTangentLength * rightCompensation),
  }

  const controlPoint1: Point2D = {
    x: normalizedLeftTangent.x * currLeftTangentLength * niceApproxCurviness,

    y: normalizedLeftTangent.y * currLeftTangentLength * niceApproxCurviness,
  }
  const controlPoint2: Point2D = {
    x: normalizedRightTangent.x * currRightTangentLength * niceApproxCurviness,
    y: normalizedRightTangent.y * currRightTangentLength * niceApproxCurviness,
  }

  return [controlPoint1, controlPoint2]
}
