import { Project, getEntryOrThrow, getSize } from '@aninix-inc/model'
import { convertEntityToSnapshot as convertNodeToSnapshot } from '@aninix-inc/renderer'
import * as React from 'react'
import { SvgLayer } from '../../modules/common/renderers/svg-layer'
import { ImagesStore } from '../../stores'
import { AnimatedSvg } from './animated'
import { StaticSvg } from './static'
import { svgToString } from './svg-to-string'

export { AnimatedSvg, StaticSvg, svgToString }

type Payload = {
  project: Project
  images: ImagesStore
  /**
   * @description start time in seconds
   */
  startTime?: number
  /**
   * @description end time in seconds
   */
  endTime?: number
}

export function generateStaticSvgAsString({
  project,
  images,
  time,
}: {
  project: Project
  images: ImagesStore
  time: number
}): string {
  const entry = getEntryOrThrow(project)
  const Component = <StaticSvg entity={entry} images={images} time={time} />
  return svgToString(Component)
}

export function generateStaticSvg({
  project,
  images,
  time,
}: {
  project: Project
  images: ImagesStore
  time: number
}): React.ReactNode {
  const entry = getEntryOrThrow(project)
  return <StaticSvg entity={entry} images={images} time={time} />
}

export function generateDefaultSvg({
  project,
  images,
  time,
}: {
  project: Project
  images: ImagesStore
  time: number
}): React.ReactNode {
  const entry = getEntryOrThrow(project)
  const snapshot = convertNodeToSnapshot({
    entity: entry,
    time,
    imagesStore: images,
  })
  const size = getSize(entry)
  return (
    <svg
      viewBox={`${0} ${0} ${size.x} ${size.y}`}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <SvgLayer
        snapshot={snapshot}
        rootSnapshot={snapshot}
        version={snapshot.version}
      />
    </svg>
  )
}

export function generateAnimatedSvg({
  project,
  images,
  startTime,
  endTime,
}: Payload) {
  const entry = getEntryOrThrow(project)
  const timeRange: [number, number] | undefined =
    startTime == null || endTime == null ? undefined : [startTime, endTime]

  return svgToString(
    <AnimatedSvg
      entity={entry}
      images={images}
      timeForPreview={0}
      timeRange={timeRange}
    />
  )
}
