import classNames from 'classnames'
import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { roleLabels } from '.'
import { Icons } from '../icons'
import { ExpandedInviteRoles } from './expanded-invite-roles'

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// source: https://emailregex.com/, JS section
// should cover 99.9% of cases, but never 100% unless you try to send an email :)

export interface IProps {
  currentRole: ApiUser['aninixRole']
  setCurrentRole: React.Dispatch<React.SetStateAction<ApiUser['aninixRole']>>
  setValidEmails: React.Dispatch<React.SetStateAction<string[]>>
  validEmails: string[]
  setValidInputEmails: React.Dispatch<React.SetStateAction<string[]>>
}
export const InputEmails: React.FCC<IProps> = ({
  currentRole,
  setCurrentRole,
  validEmails,
  setValidEmails,
  setValidInputEmails,
}) => {
  const [isExpandedRoles, setIsExpandedRoles] = React.useState<boolean>(false)
  const [inputEmailList, setInputEmailList] = React.useState<string[]>([])

  const handleOpen = React.useCallback(() => {
    setIsExpandedRoles(true)
  }, [])

  const handleClose = React.useCallback(() => {
    setIsExpandedRoles(false)
  }, [])

  const updateEmailsList = React.useCallback((inputValue: string) => {
    const emailList = inputValue
      .replace(/(\r\n|\n|\r)/gm, '') //remove new lines
      .split(/ {0,}, {0,}/) //split all possible comma separations
      .filter((e) => e.length > 0) //filter empty resulte e.g trailing comma

    setInputEmailList((state) => [...state, ...emailList])
  }, [])

  const handleEmailInput = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedElement(null)

      if (emailRegex.test(e.target.value)) setValidInputEmails([e.target.value])
      else setValidInputEmails([])

      if (!e.target.value.includes(',')) {
        return
      }

      updateEmailsList(e.target.value)
      e.target.value = ''
      setValidInputEmails([])
    },
    [validEmails, updateEmailsList]
  )

  const removeEmailAtIndex = React.useCallback(
    (index: number) => {
      setInputEmailList(inputEmailList.filter((_, i) => i !== index))
    },
    [inputEmailList]
  )

  const [selectedElement, setSelectedElement] = React.useState<number | null>(
    null
  )
  const clearSelectedElement = React.useCallback(() => {
    setSelectedElement(null)
  }, [])

  const selectLastElement = React.useCallback(() => {
    setSelectedElement(inputEmailList.length - 1)
  }, [inputEmailList])

  const handleBackspacePress = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        updateEmailsList(e.currentTarget.value)
        e.currentTarget.value = ''
      }

      if (!(e.key === 'Backspace' || e.key === 'Delete')) {
        return
      }

      if (e.currentTarget.value.length > 0) {
        return
      }

      if (selectedElement !== null) {
        removeEmailAtIndex(selectedElement)
        setSelectedElement(
          selectedElement - 1 >= 0 ? selectedElement - 1 : null
        )
      } else {
        selectLastElement()
      }
    },
    [selectedElement, inputEmailList, updateEmailsList]
  )

  React.useEffect(() => {
    if (inputEmailList.map((e) => emailRegex.test(e)).includes(false))
      setValidEmails([])
    else setValidEmails(inputEmailList)
  }, [inputEmailList])

  const removeElement = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      // debugger
      removeEmailAtIndex(Number(e.currentTarget.dataset.index))
    },
    [inputEmailList]
  )

  return (
    <div className="flex w-full flex-row items-start justify-center gap-4 rounded-lg border-[1px] border-gray-200 px-3 py-1">
      <div className="min-h-8 flex max-h-96 w-full min-w-[90px] select-none resize-none flex-wrap gap-2 overflow-y-scroll py-1 pr-3 ">
        {inputEmailList.map((email, index) => {
          const isValid = emailRegex.test(email)
          return (
            <div
              key={index + email}
              className={classNames(
                'flex max-w-full flex-row items-center gap-1.5 rounded-full px-3 py-1 ring-inset ring-opacity-80',
                {
                  ['bg-gray-100 bg-opacity-100']: isValid,
                  ['bg-red bg-opacity-10']: !isValid,
                  ['ring-2 ring-accent ']: index === selectedElement && isValid,
                  ['ring-2 ring-red']: index === selectedElement && !isValid,
                }
              )}
            >
              <p className="line-clamp-1 break-all text-[16px]">{email}</p>
              <div
                className="h-4 w-4"
                data-index={index}
                onClick={removeElement}
              >
                <Icons.Cross
                  className={classNames('h-4 w-4', {
                    ['stroke-red']: !isValid,
                    ['stroke-gray-400']: isValid,
                  })}
                />
              </div>
            </div>
          )
        })}
        <input
          autoFocus={true}
          spellCheck={false}
          className="h-8 w-full flex-grow select-none resize-none overflow-x-scroll py-1 pr-3 text-base text-secondary outline-none"
          placeholder="Add emails separated with comma"
          onChange={handleEmailInput}
          onFocus={clearSelectedElement}
          onKeyDown={handleBackspacePress}
        />
      </div>
      <div className="flex h-10 items-center justify-center">
        <div
          className="flex select-none flex-row items-center gap-2 py-1"
          onClick={handleOpen}
        >
          <p className="w-[3rem] font-body text-base font-normal text-gray-500">
            {roleLabels[currentRole]}
          </p>
          <Icons.CaretDown />
        </div>
        <ExpandedInviteRoles
          role={currentRole}
          isExpanded={isExpandedRoles}
          handleClose={handleClose}
          handleSelection={setCurrentRole}
        />
      </div>
    </div>
  )
}

InputEmails.displayName = 'InputEmails'
