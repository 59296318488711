import {
  Entity,
  Point2D,
  PositionComponent,
  RotationComponent,
  SizeComponent,
  mixed,
} from '@aninix-inc/model'
import React from 'react'
import { useValueNumber } from '../hooks/value-controllers/number'
import { useValueSpatialPoint2d } from '../hooks/value-controllers/spatial-point-2d'

export let scopes = {}

/** patching current scope via spreading */
export const updateScopes = (newScopes: any) => {
  scopes = {
    ...scopes,
    ...newScopes,
  }
}

/** replaces scope with new object*/
export const replaceScopes = (newScopes: any) => {
  scopes = {
    ...newScopes,
  }
}

export const clearScopes = () => {
  scopes = {}
}

export const useMathScopes = (entities: Entity<unknown>[], time: number) => {
  const size = useValueSpatialPoint2d({
    components: entities.map((l) => l.getComponentOrThrow(SizeComponent)),
    time,
  })
  const position = useValueSpatialPoint2d({
    components: entities.map((l) => l.getComponentOrThrow(PositionComponent)),
    time,
  })
  const rotation = useValueNumber({
    components: entities.map((l) => l.getComponentOrThrow(RotationComponent)),
    time,
  })

  React.useEffect(() => {
    clearScopes()

    const unformattedScopes = {
      size: {
        x: mixedFallback(size.x),
        y: mixedFallback(size.y),
      } as Point2D,
      position: {
        x: mixedFallback(position.x),
        y: mixedFallback(position.x),
      } as Point2D,
      rotation: mixedFallback(rotation.value),
    }

    const newScopes = makePropAlias(unformattedScopes)

    replaceScopes(newScopes)
  }, [size, position, rotation])

  return null
}

const mixedFallback = (value: number | typeof mixed) => {
  if (value === mixed) return 0

  return value
}

const makePropAlias = ({
  size,
  position,
  rotation,
}: {
  size: Point2D
  position: Point2D
  rotation: number
}) => ({
  sizex: size.x,
  width: size.x,
  w: size.x,
  sizey: size.y,
  height: size.y,
  h: size.y,

  positionx: position.x,
  posx: position.x,
  x: position.x,
  positiony: position.y,
  posy: position.y,
  y: position.y,

  rotation: rotation,
  r: rotation,
})
