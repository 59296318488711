import {
  BlendModeComponent,
  DurationComponent,
  Entity,
  FpsComponent,
  NameComponent,
  OpacityComponent,
  Root,
  getEntryOrThrow,
  getSize,
  round,
} from '@aninix-inc/model'
import { Property } from './properties/property'
import { defaultTransformProperties } from './properties/transform-properties'
import {
  LottieAsset,
  LottieLayer,
  LottieLayerType,
  mapBlendModeToLottie,
} from './types'

export let usedCompositionRefs = {
  refs: new Set<string>(),
}

// @TODO: optimize algorithm to prevent stack limits
function addTreeOfAssets(id: string, assets: LottieAsset[]): void {
  const asset = assets.find((a) => a.id === id)
  // @ts-ignore
  asset?.layers?.forEach((l) => {
    addTreeOfAssets(l.refId, assets)
  })
  usedCompositionRefs.refs.add(id)
}

export function Composition(
  node: Entity,
  assets: LottieAsset[],
  options?: {
    id?: string
    isMask?: boolean
    parent?: number
    globalSize?: boolean
    markAsUsed?: boolean
  }
): LottieLayer {
  const randomIdx = Math.round(Math.random() * 1000000000)
  const id = options?.id ?? node.id
  const isMask = options?.isMask ?? true
  const isGlobalSize = options?.globalSize ?? true
  const markAsUsed = options?.markAsUsed ?? true

  const project = node.getProjectOrThrow()
  const root = project.getEntityByTypeOrThrow(Root)
  const entry = getEntryOrThrow(project)
  const startTime = 0
  const endTime =
    root.getComponentOrThrow(DurationComponent).value *
    root.getComponentOrThrow(FpsComponent).value
  const size = isGlobalSize ? getSize(entry) : getSize(node)

  if (markAsUsed) {
    addTreeOfAssets(id, assets)
  }

  return {
    ddd: 0,
    ind: randomIdx,
    ty: LottieLayerType.Precomp,
    nm: node.getComponentOrThrow(NameComponent).value,
    parent: options?.parent,
    td: isMask ? 1 : undefined,
    refId: id,
    sr: 1,
    ks: {
      ...defaultTransformProperties,
      o: Property<number | number[]>({
        property: node.getComponentOrThrow(OpacityComponent),
        mapper: ({ value, isAnimated }) =>
          isAnimated ? [value * 100] : value * 100,
      }),
    },
    ao: 0,
    w: round(size.x, { fixed: 0 }),
    h: round(size.y, { fixed: 0 }),
    ip: startTime,
    op: endTime,
    st: 0,
    hd: false,
    bm: mapBlendModeToLottie(
      node.getComponentOrThrow(BlendModeComponent).value
    ),
  }
}
