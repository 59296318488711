import {
  AnimationCurveAspect,
  CurveStyle,
  CurveType,
  NameComponent,
  ReferenceRelationAspect,
  SpringCurveComponent,
  TimingCurveComponent,
  UndoRedoSystem,
  UpdatesSystem,
  round,
} from '@aninix-inc/model'
import {
  CurveStyle as CurveStyleLegacy,
  SpringCurve,
  TimingCurve,
  springCurve,
  timingCurve,
} from '@aninix-inc/model/legacy'
import {
  CopyPreset,
  Input,
  InputWithIcon,
  Select,
  SpringGraph,
  TimingGraph,
  buttons,
  icons,
} from '@aninix/app-design-system'
import { useEntity, useProject } from '@aninix/core'
import {
  defaultSpringCurvePresets,
  defaultTimingCurvePresets,
} from '@aninix/editor/default-curve-styles'
import * as R from 'ramda'
import * as React from 'react'
import * as styles from './index.scss'

const timingCurveIconData = timingCurve
  .create({
    out: {
      x: 0.5,
      y: 0,
    },
    in: {
      x: 0.15,
      y: 1,
    },
  })
  .getCache()

const iconSize = {
  x: 12,
  y: 12,
}

export interface IProps {
  curveStyle: CurveStyle
  onClose: () => void
}
export const EditCurveStyle: React.FCC<IProps> = ({ curveStyle, onClose }) => {
  useEntity(curveStyle)
  const project = useProject()
  const undoRedo = project.getSystemOrThrow(UndoRedoSystem)
  const updates = project.getSystemOrThrow(UpdatesSystem)

  const curveType = curveStyle
    .getAspectOrThrow(AnimationCurveAspect)
    .curveType()
  const curveComponent = curveStyle
    .getAspectOrThrow(AnimationCurveAspect)
    .curveComponent()
  const presets =
    curveType === CurveType.Timing
      ? defaultTimingCurvePresets
      : defaultSpringCurvePresets

  const updateValue = React.useCallback(
    (value: TimingCurve | SpringCurve): void => {
      if (curveStyle == null) {
        return
      }

      if (value.type === CurveType.Timing) {
        const typedValue = value as TimingCurve
        curveStyle.updateComponent(TimingCurveComponent, {
          out: {
            x: typedValue.out.x,
            y: typedValue.out.y,
          },
          in: {
            x: typedValue.in.x,
            y: typedValue.in.y,
          },
        })
        return
      }

      const typedValue = value as SpringCurve
      curveStyle.updateComponent(SpringCurveComponent, {
        stiffness: typedValue.stiffness,
        damping: typedValue.damping,
        mass: typedValue.mass,
      })
    },
    [curveStyle, project, curveType]
  )

  const updateTitle = React.useCallback(
    (name: string): void => {
      curveStyle.updateComponent(NameComponent, name)
      undoRedo.commitUndo()
    },
    [curveStyle, undoRedo]
  )

  const remove = React.useCallback((): void => {
    onClose()
    updates.batch(() => {
      const connectedKeyframes = project.getEntitiesByPredicate(
        (e) =>
          e.getAspect(ReferenceRelationAspect)?.getRelation() === curveStyle.id
      )
      connectedKeyframes.forEach((keyframe) => {
        keyframe.getAspectOrThrow(AnimationCurveAspect).detachCurveStyle()
      })
      project.removeEntity(curveStyle.id)
    })
    undoRedo.commitUndo()
  }, [curveStyle, project, undoRedo])

  const updateCurveType = React.useCallback(
    (curveType: CurveType): void => {
      curveStyle.getAspectOrThrow(AnimationCurveAspect).setCurveType(curveType)
    },
    [curveStyle]
  )

  const titleInputRef = React.useRef<HTMLInputElement>()

  const applyDefaultPreset = React.useCallback(
    (presetId: string) => {
      const preset = presets
        .filter((_preset) => _preset !== 'divider')
        .map((_preset) => _preset as CurveStyleLegacy)
        .find((_preset) => _preset.id === presetId)

      if (preset == null) {
        return
      }

      updateCurveType(preset.type)
      updateValue(preset.value)
      undoRedo.commitUndo()
    },
    [undoRedo, presets]
  )

  const selectedPresetId = React.useMemo(() => {
    if (curveStyle == null) {
      return 'custom'
    }

    const preset = presets
      .filter((_preset) => _preset !== 'divider')
      .map((_preset) => _preset as CurveStyleLegacy)
      .find(
        (_preset) =>
          _preset.value.type === curveType &&
          R.equals(_preset.value, curveComponent.value)
      )

    if (preset == null) {
      return 'custom'
    }

    return preset.id
  }, [curveComponent, presets])

  const selectOptions = (() => {
    const defaultOptions = presets.map((preset) => {
      if (preset === 'divider') {
        return 'divider'
      }

      return {
        id: preset.id,
        title: preset.title,
        icon: (
          <icons.Graph
            previews={preset.value.getCache()}
            style={{
              // @ts-ignore
              '--figma-color-text':
                preset.id === selectedPresetId ? undefined : '#FFFFFF',
            }}
          />
        ),
      }
    })

    if (selectedPresetId === 'custom' && curveStyle != null) {
      return R.append(
        {
          id: 'custom',
          title: 'Custom',
          icon: (
            <icons.Graph
              previews={curveComponent.getCache()}
              style={{
                // @ts-ignore
                '--figma-color-text':
                  selectedPresetId === 'custom' ? undefined : '#FFFFFF',
              }}
            />
          ),
        },
        defaultOptions
      )
    }

    return defaultOptions
  })()

  const handleChangeSpringCurve = React.useCallback(
    (payload: { stiffness?: number; damping?: number; mass?: number }) => {
      if (curveStyle == null) {
        return
      }

      const value = (curveComponent as SpringCurveComponent).value

      const newCurve = springCurve.create({
        stiffness: payload.stiffness ?? value.stiffness,
        damping: payload.damping ?? value.damping,
        mass: payload.mass ?? value.mass,
      })

      updateValue(newCurve)
      undoRedo.commitUndo()
    },
    [undoRedo]
  )

  const handleStiffnessUpdate = React.useCallback(
    (stiffness: number) => {
      handleChangeSpringCurve({ stiffness })
    },
    [handleChangeSpringCurve]
  )

  const handleDampingUpdate = React.useCallback(
    (damping: number) => {
      handleChangeSpringCurve({ damping })
    },
    [handleChangeSpringCurve]
  )

  const handleMassUpdate = React.useCallback(
    (mass: number) => {
      handleChangeSpringCurve({ mass })
    },
    [handleChangeSpringCurve]
  )

  React.useEffect(() => {
    titleInputRef.current?.focus()
    titleInputRef.current?.select()
  }, [])

  const handleTimingCurveType = React.useCallback(() => {
    updateCurveType(CurveType.Timing)
  }, [])

  const handleSpringCurveType = React.useCallback(() => {
    updateCurveType(CurveType.Spring)
  }, [])

  if (curveStyle == null) {
    return null
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.title}>Edit Graph</p>

        <buttons.Icon onClick={onClose}>
          <icons.Close size={iconSize} />
        </buttons.Icon>
      </div>

      <div className={styles['label-input']}>
        <Input
          ref={titleInputRef}
          value={curveStyle.getComponentOrThrow(NameComponent).value}
          onChange={updateTitle}
        />
      </div>

      {curveType === CurveType.Timing && (
        <>
          <div className={styles['default-presets-wrapper']}>
            <Select
              activeValueId={selectedPresetId}
              onChange={applyDefaultPreset}
              options={selectOptions}
              className={styles['default-presets-wrapper__select']}
            />

            <div className={styles['default-presets-wrapper__toggle']}>
              <buttons.Group
                buttons={[
                  {
                    id: CurveType.Timing,
                    icon: (
                      <icons.Graph
                        previews={timingCurveIconData}
                        size={iconSize}
                      />
                    ),
                    onClick: handleTimingCurveType,
                  },
                  {
                    id: CurveType.Spring,
                    icon: <icons.CurveSpring size={iconSize} />,
                    onClick: handleSpringCurveType,
                  },
                ]}
                selectedId={curveType}
              />
            </div>
          </div>

          <TimingGraph
            width={224}
            height={256}
            value={[
              timingCurve.create(
                (curveComponent as TimingCurveComponent).value
              ),
            ]}
            onChange={updateValue}
            onDragEnd={undoRedo.commitUndo}
          />

          <CopyPreset
            id="any"
            value={timingCurve.create(
              (curveComponent as TimingCurveComponent).value
            )}
            onChange={updateValue}
          />
        </>
      )}

      {curveType === CurveType.Spring && (
        <>
          <div className={styles['default-presets-wrapper']}>
            <Select
              activeValueId={selectedPresetId}
              onChange={applyDefaultPreset}
              options={selectOptions}
              className={styles['default-presets-wrapper__select']}
            />

            <div className={styles['default-presets-wrapper__toggle']}>
              <buttons.Group
                buttons={[
                  {
                    id: CurveType.Timing,
                    icon: (
                      <icons.Graph
                        previews={timingCurveIconData}
                        size={iconSize}
                      />
                    ),
                    onClick: handleTimingCurveType,
                  },
                  {
                    id: CurveType.Spring,
                    icon: <icons.CurveSpring size={iconSize} />,
                    onClick: handleSpringCurveType,
                  },
                ]}
                selectedId={curveType}
              />
            </div>
          </div>

          <SpringGraph
            width={224}
            height={256}
            value={springCurve.create(
              (curveComponent as SpringCurveComponent).value
            )}
            onChange={({ stiffness, damping }) =>
              updateValue(
                springCurve.create({
                  stiffness,
                  damping,
                  mass:
                    (curveComponent as SpringCurveComponent).value.mass || 1,
                })
              )
            }
            onDragEnd={undoRedo.commitUndo}
          />

          <div className={styles.rows}>
            <InputWithIcon
              id="stiffness"
              icon={
                <span style={{ width: '100%', paddingLeft: 4 }}>Stiffness</span>
              }
              value={round(
                (curveComponent as SpringCurveComponent).value.stiffness,
                {
                  fixed: 2,
                }
              )}
              onChange={handleStiffnessUpdate}
              iconWidth={96}
              width={224}
              min={1}
              max={500}
            />

            <InputWithIcon
              id="Damping"
              icon={
                <span style={{ width: '100%', paddingLeft: 4 }}>Damping</span>
              }
              value={round(
                (curveComponent as SpringCurveComponent).value.damping,
                { fixed: 2 }
              )}
              onChange={handleDampingUpdate}
              iconWidth={96}
              width={224}
              min={2}
              max={50}
            />

            <InputWithIcon
              id="Mass"
              icon={<span style={{ width: '100%', paddingLeft: 4 }}>Mass</span>}
              value={round(
                (curveComponent as SpringCurveComponent).value.mass,
                { fixed: 2 }
              )}
              onChange={handleMassUpdate}
              iconWidth={96}
              width={224}
              min={0.5}
              max={10}
            />
          </div>
        </>
      )}

      <div className={styles['delete-button-wrapper']}>
        <buttons.Regular
          onClick={remove}
          title="Delete curve style"
          variant="contained"
          destructive
          className={styles['delete-button']}
        />
      </div>
    </div>
  )
}
