import {
  ChildrenRelationsAspect,
  Entity,
  EntryComponent,
  MaskComponent,
  NodeColorComponent,
  ParentRelationAspect,
  PropertiesExpandedComponent,
  SelectionSystem,
  getNode,
} from '@aninix-inc/model'
import { TimelineTrack, buttons, icons } from '@aninix/app-design-system'
import { nodeColors } from '@aninix/core'
import { useEntity, useSystem } from '@aninix/core/updates'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'
import { isAnyParentSelected } from '../../common/is-any-parent-selected'
import { isComponentSelected } from '../../common/is-component-selected'
import { isLayerSelected } from '../../common/is-layer-selected'
import { Spacer } from '../property'
import * as styles from './index.scss'

export interface IProps {
  propertyGroup: Entity
  name: string
  indent: number
}
export const PropertyGroup: React.FCC<IProps> = ({
  propertyGroup,
  name,
  indent: providedIndent,
}) => {
  const [isEditable, setIsEditable] = React.useState<boolean>(false)

  const layer = getNode(propertyGroup)

  if (layer === undefined) throw new Error('Invalid state. Node is not found')

  useEntity(propertyGroup)
  const project = propertyGroup.getProjectOrThrow()
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)

  const isExpanded = propertyGroup.getComponentOrThrow(
    PropertiesExpandedComponent
  ).value

  const isAnyParentSelectedValue = isAnyParentSelected(layer)
  const isLayerSelectedValue = isLayerSelected(layer)
  const isComponentSelectedValue = R.any(
    (component) => isComponentSelected(component),
    propertyGroup.components
  )

  const maskType = (() => {
    const node = propertyGroup
      .getAspectOrThrow(ParentRelationAspect)
      .getParentEntityOrThrow()

    if (node.hasComponent(EntryComponent)) {
      return 'none'
    }

    const children = layer.getAspect(ChildrenRelationsAspect)?.getChildrenList()

    if (children === undefined) return 'none'

    const maskIdx = children.findIndex((_node) =>
      _node.hasComponent(MaskComponent)
    )
    const hasMask = maskIdx !== -1

    if (!hasMask) return 'none'

    if (node.hasComponent(MaskComponent)) return 'none'

    const currentIdx = children.findIndex((_node) => _node.id === node.id)

    if (hasMask && currentIdx < maskIdx) return 'in-scope'

    return 'between'
  })()

  const indent = providedIndent - 1
  const color = React.useMemo(() => {
    const color =
      nodeColors[layer.getComponentOrThrow(NodeColorComponent).value]
    if (isAnyParentSelectedValue || isLayerSelectedValue) return `${color}10`
    if (isComponentSelectedValue) return `${color}10`

    return 'transparent'
  }, [
    layer,
    isAnyParentSelectedValue,
    isLayerSelectedValue,
    isComponentSelectedValue,
  ])
  const maskColor = 'rgba(0, 0, 0, 0.1)'
  const baseIndent = 3

  const propertyGroupProps: PropertyGroupProps = {
    name,
    indent,
    color,
    maskColor,
    maskType,
    isExpanded,
    baseIndent,
  }

  return (
    <div
      onPointerMove={() => setIsEditable(true)}
      onPointerLeave={() => setIsEditable(false)}
    >
      {isEditable ? (
        <PropertyGroupEditable
          propertyGroup={propertyGroup}
          {...propertyGroupProps}
        />
      ) : (
        <PropertyGroupDisplay {...propertyGroupProps} />
      )}
    </div>
  )
}

PropertyGroup.displayName = 'PropertyGroup'

type PropertyGroupMaskType = 'none' | 'between' | 'in-scope'

type PropertyGroupProps = {
  name: string
  indent: number
  color: string
  maskColor: string
  maskType: PropertyGroupMaskType
  isExpanded: boolean
  baseIndent: number
}

const PropertyGroupEditable: React.FC<
  PropertyGroupProps & { propertyGroup: Entity }
> = ({
  propertyGroup,
  name,
  indent,
  color,
  maskColor,
  maskType,
  isExpanded,
  baseIndent,
}) => {
  const layer = getNode(propertyGroup)

  if (layer === undefined) throw new Error('Invalid state. Node is not found')

  useEntity(propertyGroup)
  const project = propertyGroup.getProjectOrThrow()
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)

  const handleExpandToggle = () => {
    propertyGroup.updateComponent(PropertiesExpandedComponent, (v) => !v)
  }

  return (
    <TimelineTrack
      className="flex flex-row flex-nowrap items-center justify-start"
      color={color}
      variant="property"
      maskColor={maskColor}
    >
      {R.range(0, indent || 0).map((spacer) => (
        <Spacer key={spacer} />
      ))}

      {maskType !== 'none' ? (
        <div
          className={classNames(
            'relative h-[18px] w-[20px] flex-shrink-0 p-0 px-[2px]',
            {
              [styles.mask__between]: maskType === 'between',
            }
          )}
        />
      ) : (
        <Spacer />
      )}

      {R.range(0, baseIndent).map((spacer) => (
        <Spacer key={spacer} />
      ))}

      <div className={styles.component}>
        <buttons.Icon
          onClick={handleExpandToggle}
          btnSize={{
            width: 16,
            height: 16,
          }}
        >
          <icons.ExpandProperties
            type={
              isExpanded
                ? icons.NodeExpandType.Expanded
                : icons.NodeExpandType.Normal
            }
            size={{ x: 16, y: 16 }}
          />
        </buttons.Icon>
      </div>

      <p className={styles.name}>{name}</p>
    </TimelineTrack>
  )
}

PropertyGroupEditable.displayName = 'PropertyGroupEditable'

const PropertyGroupDisplay: React.FC<PropertyGroupProps> = React.memo(
  ({ name, indent, color, maskColor, maskType, isExpanded, baseIndent }) => {
    return (
      <TimelineTrack
        className="flex flex-row flex-nowrap items-center justify-start"
        color={color}
        variant="property"
        maskColor={maskColor}
      >
        {R.range(0, indent || 0).map((spacer) => (
          <Spacer key={spacer} />
        ))}

        {maskType !== 'none' ? (
          <div
            className={classNames(
              'relative h-[18px] w-[20px] flex-shrink-0 p-0 px-[2px]',
              {
                [styles.mask__between]: maskType === 'between',
              }
            )}
          />
        ) : (
          <Spacer />
        )}

        {R.range(0, baseIndent).map((spacer) => (
          <Spacer key={spacer} />
        ))}

        <div className={styles.component}>
          <buttons.Icon
            onClick={() => {}}
            btnSize={{
              width: 16,
              height: 16,
            }}
          >
            <icons.ExpandProperties
              type={
                isExpanded
                  ? icons.NodeExpandType.Expanded
                  : icons.NodeExpandType.Normal
              }
              size={{ x: 16, y: 16 }}
            />
          </buttons.Icon>
        </div>

        <p className={styles.name}>{name}</p>
      </TimelineTrack>
    )
  },
  (prev, next) => {
    if (prev.isExpanded !== next.isExpanded) return false
    if (prev.name !== next.name) return false
    if (prev.indent !== next.indent) return false
    if (prev.color !== next.color) return false
    if (prev.maskColor !== next.maskColor) return false
    if (prev.maskType !== next.maskType) return false
    if (prev.baseIndent !== next.baseIndent) return false

    return true
  }
)

PropertyGroupDisplay.displayName = 'PropertyGroupDisplay'
