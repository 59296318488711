import { default as classnames, default as classNames } from 'classnames'
import React from 'react'
import { DeleteIcon } from '../../icons/DeleteIcon'
import { DuplicateIcon } from '../../icons/DuplicateIcon'
import { ResetIcon } from '../../icons/ResetIcon'
import { Spoiler } from '../components/spoiler'
import { useOverrides } from '../use-overrides'
import { useOverride } from './use-override-item'

export interface IProps {
  label: string
  isOpen: boolean
  toggleOpen: () => void
}

const Header: React.FC<IProps> = ({ label, isOpen, toggleOpen }) => {
  const {
    resetAllChanges,
    deleteOverride,
    duplicateOverride,
    data: { id },
  } = useOverride()

  const { selectedOverride } = useOverrides()

  return (
    <div className={classNames('flex flex-row justify-between items-center')}>
      <Spoiler isOpen={isOpen} toggleOpen={toggleOpen} label={label} />
      <div
        className={classNames(
          'flex flex-row items-center gap-2 duration-150 transition-opacity',
          {
            ['opacity-0']: selectedOverride !== id,
          }
        )}
      >
        <button type="button" onClick={resetAllChanges}>
          <ResetIcon />
        </button>
        <button onClick={() => duplicateOverride()}>
          <DuplicateIcon />
        </button>
        <button
          className={classnames('', {
            ['pointer-events-none opacity-20']: id === 'original',
          })}
          onClick={() => deleteOverride()}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}

Header.displayName = 'Header'

export default Header
