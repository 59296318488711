import { IComment } from '@aninix/core/use-cases/comments/use-get-comments'
import { isEqual } from 'lodash'

export interface CommentGroup {
  id: string
  position: { x: number; y: number }
  comments: IComment[]
}

export function mapCommentsToGroups(
  comments: IComment[],
  radius: number,
  previousGroups?: CommentGroup[]
): CommentGroup[] {
  const groups: CommentGroup[] = []

  comments.forEach((comment) => {
    const nearbyGroups = groups.filter((group) =>
      group.comments.some((existingComment) => {
        const dx = comment.rectangle.x - existingComment.rectangle.x
        const dy = comment.rectangle.y - existingComment.rectangle.y
        return Math.sqrt(dx * dx + dy * dy) < 2 * radius
      })
    )
    if (nearbyGroups.length > 0) {
      nearbyGroups[0].comments.push(comment)
      for (let i = 1; i < nearbyGroups.length; i++) {
        nearbyGroups[0].comments = nearbyGroups[0].comments.concat(
          nearbyGroups[i].comments
        )
        groups.splice(groups.indexOf(nearbyGroups[i]), 1)
      }
      nearbyGroups[0].id =
        nearbyGroups[0].comments[0].id + '_' + nearbyGroups[0].comments[1].id
    } else {
      groups.push({
        id: comment.id,
        position: { x: comment.rectangle.x, y: comment.rectangle.y },
        comments: [comment],
      })
    }
  })

  if (previousGroups && isEqual(previousGroups, groups)) return previousGroups

  return groups
}
