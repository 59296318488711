import { makeAutoObservable } from 'mobx'
import * as React from 'react'

import { TimeFormat } from '../constants'

/**
 * @description user settings
 */
export class Settings {
  isInitiated: boolean = false

  timeFormat: TimeFormat

  constructor(payload?: { timeFormat: TimeFormat }) {
    this.timeFormat = payload?.timeFormat ?? TimeFormat.Seconds
    makeAutoObservable(this)
  }

  updateTimeFormat = (newTimeFormat: TimeFormat) => {
    this.timeFormat = newTimeFormat
    return this
  }

  initiate = () => {
    this.isInitiated = true
    return this
  }
}

const Context = React.createContext<Settings>(null as any)

export const useSettings = (): Settings => {
  const context = React.useContext(Context)

  if (context == null) {
    throw new Error(
      'Settings context not found. Use UserProvider at the root component.'
    )
  }

  return context
}

const defaultSettings = new Settings()
export const SettingsProvider: React.FCC<{ store?: Settings }> = ({
  store = defaultSettings,
  children,
}) => <Context.Provider value={store}>{children}</Context.Provider>
