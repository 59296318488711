import {
  Entity,
  EntityType,
  getAbsoluteTransformMatrix,
  getNode,
  getSortedKeyframes,
  getTransformMatrix,
  lerp,
  ParentRelationAspect,
  SelectionSystem,
  SpatialPoint2dKeyframe,
  TimeComponent,
} from '@aninix-inc/model'
import { paper } from '@aninix-inc/renderer'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

import {
  LayoutAspect,
  NodeColorComponent,
  PositionComponent,
  Project,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/legacy'
import { nodeColors } from '../../../registries'
import { usePlayback, useViewport } from '../../../stores'
import { useEntities, useSystem } from '../../../updates'

export interface IProps {
  project: Project
}
export const SvgMotionPath: React.FCC<IProps> = observer(({ project }) => {
  const playback = usePlayback()
  const viewport = useViewport()
  const selection = project.getSystemOrThrow(SelectionSystem)
  const nodes = selection.getEntitiesByEntityType(EntityType.Node)
  const keyframes = selection.getEntitiesByEntityType(EntityType.Keyframe)
  useEntities(nodes)
  useEntities(keyframes)
  useSystem(selection)

  const nodesToRender = Array.from(
    new Set([
      ...keyframes
        .map((keyframe) => getNode(keyframe))
        .filter((keyframe) => keyframe !== undefined),
      ...nodes,
    ])
  ) as Entity[]

  const points: Point2D[][] = React.useMemo(() => {
    return nodesToRender.map((node) => {
      const keyframes: SpatialPoint2dKeyframe[] = getSortedKeyframes(
        node.getComponentOrThrow(PositionComponent)
      )

      const details = 3
      const basePointsCount = 10
      const SEGMENTS =
        details * basePointsCount * Math.round(keyframes.length / 2)
      const firstKey = keyframes.length > 0 ? R.head(keyframes) : undefined
      const lastKey = keyframes.length > 0 ? R.last(keyframes) : undefined

      const spatialPathPoints = (() => {
        if (firstKey == null || lastKey == null) {
          return []
        }

        const parent = node
          .getAspectOrThrow(ParentRelationAspect)
          .getParentEntity()

        if (parent == null) {
          return []
        }

        const parentAbsoluteTransformMatrix = new paper.Matrix(
          getAbsoluteTransformMatrix({
            entity: parent,
            time: playback.time,
          })
        )
        const layout = node.getAspectOrThrow(LayoutAspect)

        return R.range(0, Math.round(SEGMENTS)).map((idx) => {
          const time = lerp(
            idx / SEGMENTS,
            firstKey.getComponentOrThrow(TimeComponent).value,
            lastKey.getComponentOrThrow(TimeComponent).value
          )

          const anchorPoint = layout.anchorPoint.getValue(time)
          const transformMatrix = new paper.Matrix(
            getTransformMatrix({ entity: node, time })
          )
          const point = transformMatrix.transform(
            new paper.Point(anchorPoint.x, anchorPoint.y)
          )
          const finalPoint = parentAbsoluteTransformMatrix.transform(
            new paper.Point(point.x, point.y)
          )

          return {
            x: finalPoint.x,
            y: finalPoint.y,
          }
        })
      })()

      return spatialPathPoints
    })
  }, [nodesToRender])

  const strokeWidth = React.useMemo(() => 2 / viewport.zoom, [viewport.zoom])

  return (
    <>
      {points.map((nodePoints, i) => (
        <g key={i}>
          {nodePoints.map((position, j) => (
            <circle
              key={j}
              cx={position.x}
              cy={position.y}
              r={strokeWidth}
              fill={
                nodeColors[
                  nodesToRender[i].getComponentOrThrow(NodeColorComponent).value
                ]
              }
            />
          ))}
        </g>
      ))}
    </>
  )
})

SvgMotionPath.displayName = 'SvgMotionPath'
