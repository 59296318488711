import { BlendModeType } from '@aninix-inc/model/legacy'

/**
 * @url https://lottiefiles.github.io/lottie-docs/assets/
 */
export type LottieAssetImage = {
  // @NOTE: Unique identifier used by layers when referencing this asset
  id: string

  // @NOTE: Human readable name
  nm?: string

  // @NOTE: Width of the image
  w: number

  // @NOTE: Height of the image
  h: number

  // @NOTE: Path to the directory containing a file
  u: string

  // @NOTE: Data url
  p: string

  // @NOTE: Whether the file is embedded. Example: 0/1
  e: number

  // @NOTE: Marks as part of an image sequence if present. Example: 'seq'
  t?: string
}

export type LottieAssetPrecomp = {
  // @NOTE: array of Precomposition Layer or Solid Color Layer or Image Layer or Null Layer or Shape Layer or Text Layer or Audio Layer or Camera Layer or Data Layer
  // An array of layers (See: Lists of layers and shapes)
  layers: LottieLayer[]

  // @NOTE: Unique identifier used by layers when referencing this asset
  id: string

  // @NOTE: Human readable name
  nm: string

  // @NOTE: Framerate in frames per second
  fr: number

  // @NOTE: Extra composition. Example: 0/1
  xt?: number
}

export type LottieAsset = LottieAssetImage | LottieAssetPrecomp

export enum LottieLayerType {
  Precomp = 0,
  Solid = 1,
  Image = 2,
  Null = 3,
  Shape = 4,
}

export enum LottieMatteMode {
  Normal = 0,
  Alpha = 1,
  InvertedAlpha = 2,
  Luma = 3,
  InvertedLuma = 4,
}

export enum LottieMaskMode {
  No = 'n',
  Add = 'a',
  Subtract = 's',
  Intersect = 'i',
  Lighten = 'l',
  Darken = 'd',
  Difference = 'f',
}

export enum LottieShapeLayerType {
  Rectangle = 'rc',
  Ellipse = 'el',
  PolyStar = 'sr',
  Path = 'sh',
  Fill = 'fl',
  Stroke = 'st',
  GradientFill = 'gf',
  GradientStroke = 'gs',
  Group = 'gr',
  Transform = 'tr',
  Repeater = 'rp',
  Trim = 'tm',
  RoundedCorners = 'rd',
  PuckerAndBloat = 'pb',
  Merge = 'mm',
  Twist = 'tw',
  OffsetPath = 'op',
  ZigZag = 'zz',
}

export enum LottieFillRule {
  Nonzero = 1,
  Evenodd = 2,
}

export enum LottieLineCap {
  Butt = 1,
  Round = 2,
  Square = 3,
}

export enum LottieLineJoin {
  Miter = 1,
  Round = 2,
  Bevel = 3,
}

export enum LottieDashType {
  Dash = 'd',
  Gap = 'g',
  Offset = 'o',
}

export enum LottieTrimMultipleShapesType {
  Individually = 1,
  Simultaneously = 2,
}

export enum LottieGradientType {
  Linear = 1,
  Radial = 2,
}

export enum LottieBlendMode {
  Normal = 0,
  Multiply = 1,
  Screen = 2,
  Overlay = 3,
  Darken = 4,
  Lighten = 5,
  ColorDodge = 6,
  ColorBurn = 7,
  HardLight = 8,
  SoftLight = 9,
  Difference = 10,
  Exclusion = 11,
  Hue = 12,
  Saturation = 13,
  Color = 14,
  Luminosity = 15,
}

export enum LottieEffectValueType {
  Slider = 0,
  Angle = 1,
  Color = 2,
  Point = 3,
  Checkbox = 4,
  Ignored = 6,
  Dropdown = 7,
  Layer = 10,
}

export enum LottieEffectType {
  MiscEffect = 5,
  PaintOverTransparent = 7,
  Tint = 20,
  Fill = 21,
  Stroke = 22,
  Tritone = 23,
  ProLevels = 24,
  DropShadow = 25,
  RadialWipe = 26,
  DisplacementMap = 27,
  Matte3 = 28,
  GaussianBlur = 29,
  MeshWarp = 31,
  Wavy = 32,
  Spherize = 33,
  Puppet = 34,
}

export type LottieBezier = {
  /**
   * Is closed
   */
  c: boolean

  /**
   * Array of verticies
   */
  v: number[][]

  /**
   * Array of in tangents
   */
  i: number[][]

  /**
   * Array of out tangents
   */
  o: number[][]
}

export type LottieStaticProperty<Value extends any> = {
  /**
   * Animation off
   */
  a: 0

  /**
   * Property value
   */
  k: Value
}

export type LottieKeyframe<Value extends any> = {
  /**
   * Time
   */
  t: number

  /**
   * Value in keyframe
   */
  s: Value

  /**
   * Out handle. Optional when keyframe is last.
   * @example { x: [0], y: [0] }
   */
  o?: {
    x: number[]
    y: number[]
  }

  /**
   * In handle. Optional when keyframe is last.
   * @example { x: [1], y: [1] }
   */
  i?: {
    x: number[]
    y: number[]
  }

  /**
   * Tangent in. Used only for spatial/position keyframes.
   */
  ti?: [number, number]

  /**
   * Tangent in. Used only for spatial/position keyframes.
   */
  to?: [number, number]

  /**
   * Is hold keyframe
   */
  h?: 0 | 1
}

export type LottieAnimatedProperty<Value extends any> = {
  /**
   * Animation on
   */
  a: 1

  /**
   * Keyframes
   */
  k: LottieKeyframe<Value>[]
}

export type LottieProperty<Value extends any> =
  | LottieStaticProperty<Value>
  | LottieAnimatedProperty<Value>

export type LottieTransform = {
  /**
   * Anchor point, require 2 numbers for x, y
   */
  a: LottieProperty<number[]>

  /**
   * Position, require 2 numbers for x, y
   */
  p: LottieProperty<number[]>

  /**
   * Scale, require 2 numbers for x, y
   */
  s: LottieProperty<number[]>

  /**
   * Skew
   */
  sk: LottieProperty<number | number[]>

  /**
   * Skew angle
   */
  sa: LottieProperty<number | number[]>

  /**
   * Rotation
   */
  r: LottieProperty<number | number[]>

  /**
   * Opacity
   */
  o: LottieProperty<number | number[]>
}

export type LottieMask = {
  /**
   * Mask name
   */
  nm: string

  /**
   * Mask path
   */
  pt: LottieProperty<LottieBezier>

  /**
   * Mask opacity
   */
  o: LottieProperty<number>

  /**
   * Dilate
   */
  x: LottieProperty<number>
}

export type LottieEffectValueBase = {
  /**
   * Effect value name
   */
  nm?: string
}

export type LottieEffectValueSlider = LottieEffectValueBase & {
  ty: LottieEffectValueType.Slider
  v: LottieProperty<number | number[]>
}

export type LottieEffectValueAngle = LottieEffectValueBase & {
  ty: LottieEffectValueType.Angle
  v: LottieProperty<number | number[]>
}

export type LottieEffectValueColor = LottieEffectValueBase & {
  ty: LottieEffectValueType.Color
  v: LottieProperty<number[]>
}

export type LottieEffectValuePoint = LottieEffectValueBase & {
  ty: LottieEffectValueType.Point
  v: LottieProperty<number[]>
}

export type LottieEffectValueCheckbox = LottieEffectValueBase & {
  ty: LottieEffectValueType.Checkbox
  v: LottieProperty<number | number[]>
}

export type LottieEffectValueIgnored = LottieEffectValueBase & {
  ty: LottieEffectValueType.Ignored
  v: number
}

export type LottieEffectValueDropdown = LottieEffectValueBase & {
  ty: LottieEffectValueType.Dropdown
  v: LottieProperty<number | number[]>
}

export type LottieEffectValueLayer = LottieEffectValueBase & {
  ty: LottieEffectValueType.Layer
  v: LottieProperty<number | number[]>
}

export type LottieEffectValue =
  | LottieEffectValueSlider
  | LottieEffectValueAngle
  | LottieEffectValueColor
  | LottieEffectValuePoint
  | LottieEffectValueCheckbox
  | LottieEffectValueIgnored
  | LottieEffectValueDropdown
  | LottieEffectValueLayer

export type LottieEffect = {
  /**
   * Effect name
   */
  nm: string

  /**
   * Effect type
   */
  ty: LottieEffectType

  /**
   * Enabled or not
   */
  en: 0 | 1

  ef: LottieEffectValue[]
}

export type LottieLayerBase = {
  /**
   * Is 3d enabled
   */
  ddd: 0 | 1

  /**
   * Is layer hidden
   */
  hd: boolean

  /**
   * Index of layer
   */
  ind?: number

  /**
   * Layer name
   */
  nm: string

  /**
   * Index of parent
   */
  parent?: number

  /**
   * Matte target
   * @url https://lottiefiles.github.io/lottie-docs/layers/#mattes
   */
  td?: 0 | 1

  /**
   * Time stretch
   */
  sr?: number

  /**
   * Layer transform
   */
  ks: LottieTransform

  /**
   * Start time
   */
  st: number

  /**
   * In point. Frame
   */
  ip: number

  /**
   * Out point. Frame
   */
  op: number

  /**
   * Auto orient
   */
  ao?: 0 | 1

  /**
   * Matte mode (alpha or luma)
   * @url https://lottiefiles.github.io/lottie-docs/layers/#mattes
   */
  tt?: LottieMatteMode

  /**
   * Whenever layer has applied masks
   */
  hasMask?: boolean

  /**
   * Array of mask properties
   */
  masksProperties?: LottieMask[]

  /**
   * Array of effects
   */
  ef?: LottieEffect[]

  /**
   * blend mode
   */
  bm?: LottieBlendMode

  /**
   * cSS class used by the SVG renderer
   */
  cl?: string

  /**
   * Id attribute used by the SVG renderer
   */
  ln?: string
}

/**
 * Shape layer
 * @url https://lottiefiles.github.io/lottie-docs/shapes/
 */
export type LottieShapeBase = {
  /**
   * Name
   */
  nm: string

  /**
   * Is hidden
   */
  hd: boolean

  /**
   * blend mode
   */
  bm?: LottieBlendMode
}

export type LottieShapeTransform = LottieTransform & {
  ty: LottieShapeLayerType.Transform
}

export type LottieShapeGroup = LottieShapeBase & {
  /**
   * Type of the shape
   */
  ty: LottieShapeLayerType.Group

  /**
   * Array of lottie shapes
   */
  it: LottieShape[]

  /**
   * Number of properties
   */
  np: number
}

export type LottieShapeRectangle = LottieShapeBase & {
  /**
   * Type of the shape
   */
  ty: LottieShapeLayerType.Rectangle

  /**
   * Position, require 2 numbers for x, y
   */
  p: LottieProperty<number[]>

  /**
   * Size, require 2 numbers for x, y
   */
  s: LottieProperty<number[]>

  /**
   * Rounded corners
   */
  r: LottieProperty<number>
}

export type LottieShapePath = LottieShapeBase & {
  /**
   * Type of the shape
   */
  ty: LottieShapeLayerType.Path

  /**
   * Path
   */
  ks: LottieProperty<LottieBezier>
}

export type LottieColor = {
  /**
   * Opacity
   */
  o: LottieProperty<number | number[]>

  /**
   * Color in range 0...1. Require 3 numbers for r, g, b
   */
  c: LottieProperty<number[]>
}

export type LottieShapeFill = LottieShapeBase &
  LottieColor & {
    /**
     * Type of the shape
     */
    ty: LottieShapeLayerType.Fill

    /**
     * Fill rule
     */
    r: LottieFillRule
  }

export type LottieGradientColor = {
  /**
   * Animated gradient colors
   * @url https://lottiefiles.github.io/lottie-docs/concepts/#gradients
   */
  k: LottieProperty<number[]>

  /**
   * Number of colors
   */
  p: number
}

export type LottieGradient = {
  /**
   * Opacity
   */
  o: LottieProperty<number | number[]>

  /**
   * Start of gradient, require 2 numbers for x, y
   */
  s: LottieProperty<number[]>

  /**
   * End of gradient, require 2 numbers for x, y
   */
  e: LottieProperty<number[]>

  /**
   * Gradient type
   */
  t: LottieGradientType

  /**
   * Gradient colors
   */
  g: LottieGradientColor
}

export type LottieShapeGradientFill = LottieShapeBase &
  LottieGradient & {
    /**
     * Type of the shape
     */
    ty: LottieShapeLayerType.GradientFill

    /**
     * Fill rule
     */
    r: LottieFillRule
  }

export type LottieDash = {
  /**
   * Dash type
   */
  n: LottieDashType

  /**
   * Optional name
   */
  nm?: string

  /**
   * Length of dash
   */
  v: LottieProperty<number | number[]>
}

export type LottieShapeStroke = LottieShapeBase &
  LottieColor & {
    /**
     * Type of the shape
     */
    ty: LottieShapeLayerType.Stroke

    /**
     * Line cap
     */
    lc: LottieLineCap

    /**
     * Line join
     */
    lj: LottieLineJoin

    /**
     * Miter limit
     */
    ml: number

    /**
     * Width
     */
    w: LottieProperty<number | number[]>

    /**
     * Dashes
     */
    d?: LottieDash[]
  }

export type LottieShapeGradientStroke = LottieShapeBase &
  LottieGradient & {
    /**
     * Type of the shape
     */
    ty: LottieShapeLayerType.GradientStroke

    /**
     * Line cap
     */
    lc: LottieLineCap

    /**
     * Line join
     */
    lj: LottieLineJoin

    /**
     * Miter limit
     */
    ml: number

    /**
     * Width
     */
    w: LottieProperty<number | number[]>

    /**
     * Dashes
     */
    d?: LottieDash[]
  }

export type LottieTrimPath = {
  /**
   * Type of the shape
   */
  ty: LottieShapeLayerType.Trim

  /**
   * Start in range 0...100
   */
  s: LottieProperty<number | number[]>

  /**
   * End in range 0...100
   */
  e: LottieProperty<number | number[]>

  /**
   * Offset in range 0...360
   */
  o: LottieProperty<number | number[]>

  /**
   * How to trim multiple shapes
   */
  m: LottieTrimMultipleShapesType
}

export type LottieShape =
  | LottieShapeGroup
  | LottieShapeRectangle
  | LottieShapePath
  | LottieShapeFill
  | LottieShapeGradientFill
  | LottieShapeStroke
  | LottieShapeGradientStroke
  | LottieTrimPath
  | LottieShapeTransform

export type LottieShapeLayer = LottieLayerBase & {
  /**
   * Lottie layer type
   */
  ty: LottieLayerType.Shape
  shapes: LottieShape[]
}

export type LottiePrecomposeLayer = LottieLayerBase & {
  /**
   * Lottie layer type
   */
  ty: LottieLayerType.Precomp

  /**
   * Reference asset id
   */
  refId: string

  /**
   * Width
   */
  w: number

  /**
   * Height
   */
  h: number
}

export type LottieImageLayer = LottieLayerBase & {
  /**
   * Lottie layer type
   */
  ty: LottieLayerType.Image

  /**
   * Reference asset id
   */
  refId: string
}

export type LottieNullLayer = LottieLayerBase & {
  /**
   * Lottie layer type
   */
  ty: LottieLayerType.Null
}

export type LottieLayer =
  | LottieShapeLayer
  | LottiePrecomposeLayer
  | LottieImageLayer
  | LottieNullLayer

export function mapBlendModeToLottie(
  blendMode: BlendModeType
): LottieBlendMode {
  switch (blendMode) {
    case BlendModeType.Darken:
      return LottieBlendMode.Darken
    case BlendModeType.Multiply:
      return LottieBlendMode.Multiply
    case BlendModeType.ColorBurn:
      return LottieBlendMode.ColorBurn
    case BlendModeType.Lighten:
      return LottieBlendMode.Lighten
    case BlendModeType.Screen:
      return LottieBlendMode.Screen
    case BlendModeType.ColorDodge:
      return LottieBlendMode.ColorDodge
    case BlendModeType.Overlay:
      return LottieBlendMode.Overlay
    case BlendModeType.SoftLight:
      return LottieBlendMode.SoftLight
    case BlendModeType.HardLight:
      return LottieBlendMode.HardLight
    case BlendModeType.Difference:
      return LottieBlendMode.Difference
    case BlendModeType.Exclusion:
      return LottieBlendMode.Exclusion
    case BlendModeType.Hue:
      return LottieBlendMode.Hue
    case BlendModeType.Saturation:
      return LottieBlendMode.Saturation
    case BlendModeType.Color:
      return LottieBlendMode.Color
    case BlendModeType.Luminosity:
      return LottieBlendMode.Luminosity
    default:
      return LottieBlendMode.Normal
  }
}
