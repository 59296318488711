import * as React from 'react'

const defaultSize = { x: 11, y: 13 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Center: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 11 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0H5V3H1C0.447715 3 0 3.44772 0 4C0 4.55228 0.447715 5 1 5H5V8H3C2.44772 8 2 8.44771 2 9C2 9.55229 2.44772 10 3 10H5V13H6V10H8C8.55228 10 9 9.55229 9 9C9 8.44771 8.55229 8 8 8H6V5H10C10.5523 5 11 4.55228 11 4C11 3.44772 10.5523 3 10 3H6V0Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
