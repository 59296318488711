import * as React from 'react'

export const TeamItem: React.FCC<{
  avatarUrl?: string
  name?: string
  aninixRole: string
  selected?: boolean
  isPro?: boolean
}> = ({ avatarUrl, name, aninixRole, selected = false, isPro = false }) => (
  <div className="flex flex-row gap-3 ">
    <div className="relative">
      <img
        crossOrigin="anonymous"
        src={avatarUrl ?? '/images/defaultTeamAvatar.png'}
        className="h-10 min-h-[2.5rem] w-10 min-w-[2.5rem] rounded-full object-cover"
      />
      {selected && (
        <div className="absolute bottom-0 right-0">
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect x="1" y="1" width="14" height="14" rx="7" fill="#374FD5" />
            <path
              d="M5.75 8L7.25 9.5L10.25 6.5"
              stroke="white"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <rect
              x="1"
              y="1"
              width="14"
              height="14"
              rx="7"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </div>
    <div className="flex flex-col">
      <div className="flex h-6 flex-row text-base text-secondary">
        <p className="line-clamp-1 max-w-[15rem] break-all">{name}</p>
        {isPro && (
          <p className="ml-2 rounded-full bg-[#1BC47D40] px-1.5 text-[10px] text-green">
            PRO
          </p>
        )}
      </div>
      <p className="text-xs capitalize text-gray-400">{aninixRole}</p>
    </div>
  </div>
)

TeamItem.displayName = 'TeamItem'

export const TeamItemPlaceholder: React.FCC = () => (
  <div className="flex flex-row gap-3 ">
    <img
      crossOrigin="anonymous"
      src={'/images/defaultTeamAvatar.png'}
      className="h-10 w-10 rounded-full object-cover"
    />
    <div className="flex flex-col gap-2">
      <div className="h-4 w-24 animate-pulse rounded bg-gray-300" />
      <div className="h-2 w-12 animate-pulse rounded-sm bg-gray-100" />
    </div>
  </div>
)

TeamItemPlaceholder.displayName = 'UserItemPlaceholder'
