export enum Transform {
  ROTATE_TOP_LEFT = 'rotate_top_left',
  ROTATE_TOP_RIGHT = 'rotate_top_right',
  ROTATE_BOTTOM_LEFT = 'rotate_bottom_left',
  ROTATE_BOTTOM_RIGHT = 'rotate_bottom_right',
  SCALE_TOP_LEFT = 'scale_top_left',
  SCALE_TOP_RIGHT = 'scale_top_right',
  SCALE_BOTTOM_LEFT = 'scale_bottom_left',
  SCALE_BOTTOM_RIGHT = 'scale_bottom_right',
  SCALE_TOP = 'scale_top',
  SCALE_BOTTOM = 'scale_bottom',
  SCALE_LEFT = 'scale_left',
  SCALE_RIGHT = 'scale_right',
  POSITION = 'position',
}
