import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const CurveSpring: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => {
  return (
    <svg
      width={size.x}
      height={size.y}
      viewBox="8 8 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.5 10.628V9h-1v2.372l3.216.965-7.79 1.168-.024.985 7.197 1.44-7.173 1.076-.024.984 7.197 1.44-7.173 1.076-.047.98 6 1.5.242-.971-3.569-.892 7.522-1.129.024-.984-7.197-1.44 7.173-1.076.024-.984-7.197-1.44 7.173-1.075.07-.974-4.644-1.393z"
        fillRule="evenodd"
        fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    </svg>
  )
}
