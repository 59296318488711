import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 20,
  y: 20,
}

export const Warning: React.FCC<IIcon> = ({
  size = defaultSize,
  className,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <rect width="20" height="20" rx="6" fill="#F4BD50" />
    <path
      d="M14.3748 13.1253H5.6248L9.9998 5.62409L14.3748 13.1253Z"
      stroke="white"
      strokeWidth="1.4"
      strokeLinejoin="round"
    />
  </svg>
)
