import React from 'react'

import RegularButton from '../buttons/regular-button'
import * as styles from './index.scss'
import { Mark } from './vector'

export type Advantage = {
  title: string
  flags?: Array<'beta' | 'soon'>
}

export type PaymentPlan = {
  name: string
  price: string
  period: string
  description: string
  advantagesTitle: string
  advantages: Advantage[]
  buttonStyle: 'contained' | 'outlined'
  buttonText: string
  onButtonClick: () => void
  buttonDisabled?: boolean
  buttonLoading?: boolean
  color: string
}

export interface IProps {
  paymentPlans: PaymentPlan[]
}

/**
 * @todo refactor with independent small composable components
 */
export const PaymentPlansComparison: React.FCC<IProps> = ({ paymentPlans }) => (
  <div className={styles.container}>
    <div className={styles.tariff}>
      {paymentPlans.map((paymentPlan, idx) => (
        <div className={styles.plan} key={paymentPlan.name}>
          <p className={styles.plan_name}>{paymentPlan.name}</p>

          <p className={styles.plan_price}>
            {paymentPlan.price}
            <span className={styles.plan_payment_period}>
              {paymentPlan.period}
            </span>
          </p>

          {paymentPlan.description ? (
            <p className={styles.plan_description}>{paymentPlan.description}</p>
          ) : (
            <br />
          )}

          <p className={styles.plan_advantages_title}>
            {paymentPlan.advantagesTitle}
          </p>

          <ul className={styles.plan_advantage}>
            {paymentPlan.advantages.map((advantage) => (
              <li key={advantage.title}>
                <Mark color={paymentPlan.color} />

                <div>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: advantage.title.replace(/\n/g, '<br />'),
                    }}
                  />

                  {advantage.flags?.map((flag) => (
                    <span
                      key={flag}
                      // @ts-ignore
                      style={{ ['--color']: paymentPlan.color }}
                      className={styles['plan_advantage__soon-label']}
                    >
                      {flag}
                    </span>
                  ))}
                </div>
              </li>
            ))}
          </ul>

          <RegularButton
            classes={{
              container: styles.button,
              label: styles.button__text,
            }}
            variant={paymentPlan.buttonStyle}
            title={paymentPlan.buttonText}
            onClick={paymentPlan.onButtonClick}
            fullWidth={true}
            color={paymentPlan.color}
            loading={paymentPlan.buttonLoading}
            disabled={paymentPlan.buttonDisabled}
          />
        </div>
      ))}
    </div>
  </div>
)
