import { buttons } from '@aninix/app-design-system'
import classNames from 'classnames'
import * as React from 'react'
import tinycolor from 'tinycolor2'

import { Project, RgbaValueComponent, Root } from '@aninix-inc/model'
import { BgColorPicker } from './bg-color-picker-icon'

export interface IProps {
  project: Project
}

const bgColors = [
  'ffffff',
  'f8e399',
  'a3e4c9',
  'a3d2fc',
  'c9bffd',
  '0e0f0f',
  'e0e0e0',
]
interface IBgColorItem {
  color: string
  handleClick: () => void
}
export const ColorPicker: React.FCC<IProps> = ({ project }) => {
  const updateColor = (color: string) =>
    project
      .getEntityByTypeOrThrow(Root)
      .updateComponent(RgbaValueComponent, tinycolor(`#${color}`).toRgb())

  const [mouseInGroup, setMouseInGroup] = React.useState<boolean>(false)
  const [colorOffset, setColorOffset] = React.useState<number>(0)

  const bgColorItems: IBgColorItem[] = React.useMemo(() => {
    return bgColors.map((color: string, index: number) => {
      return {
        color: color,
        handleClick: () => {
          updateColor(color)
          setColorOffset(index)
          setMouseInGroup(false)
        },
      }
    })
  }, [])

  const handleMouseEnter = React.useCallback(() => setMouseInGroup(true), [])
  const handleMouseLeave = React.useCallback(() => setMouseInGroup(false), [])

  return (
    <div
      className={classNames(
        'abosolute border-l-[1px] border-black  transition-all duration-300',
        {
          [' border-opacity-0']: mouseInGroup,
          [' border-opacity-10']: !mouseInGroup,
        }
      )}
    >
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="flex flex-col items-center overflow-hidden rounded hover:overflow-auto hover:bg-white hover:shadow-md"
        style={{
          transition: 'all 150ms ease-in-out',
          maxHeight: mouseInGroup ? `${bgColors.length * 30}px` : `30px`,
        }}
      >
        {bgColorItems.map((item: IBgColorItem, index: number) => {
          return (
            <div
              key={item.color}
              style={{
                transition: 'all 150ms ease-in-out',
                transform: mouseInGroup
                  ? 'translateY(0px)'
                  : `translateY(-${30 * colorOffset}px)`,
                backgroundColor:
                  colorOffset == index && mouseInGroup
                    ? 'rgba(0, 0, 0, 0.07)'
                    : 'transparent',
              }}
            >
              <buttons.Icon onClick={item.handleClick} className={''}>
                <BgColorPicker accentColor={`#${item.color}`} />
              </buttons.Icon>
            </div>
          )
        })}
      </div>
    </div>
  )
}

ColorPicker.displayName = 'ColorPicker'
