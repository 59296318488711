import { IComment } from '@aninix/core/use-cases/comments/use-get-comments'
import { useCurrentUser } from 'apps/web-app/src/use-cases'
import classNames from 'classnames'
import { intlFormatDistance } from 'date-fns'
import * as React from 'react'

import { EditCommentThreadItem } from './edit-comment-thread-item'
import { useCommentContext } from './use-cases/use-comment-context'

export const CommentThreadItem: React.FCC<{
  data: IComment
  replyTo?: IComment
  index: number
  isLast: boolean
}> = ({ data, index, isLast, replyTo }) => {
  const {
    id,
    comment,
    rectangle,
    time,
    isResolved,
    replies,
    author,
    createdAt,
    updatedAt,
  } = data

  const formattedDistance = React.useMemo(
    () => intlFormatDistance(new Date(createdAt), new Date()),
    [createdAt]
  )

  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false)

  const [isEditingComment, setIsEditingComment] = React.useState<boolean>(false)

  const startEditing = React.useCallback(() => {
    setIsDropdownOpen(false)
    setIsEditingComment(true)
  }, [])

  const finishEditing = React.useCallback(() => {
    setIsEditingComment(false)
  }, [])

  const openCommentOptions = React.useCallback(() => {
    setIsDropdownOpen(true)
  }, [])
  const closeCommentOptions = React.useCallback(() => {
    setIsDropdownOpen(false)
  }, [])
  const toggleCommentOptions = React.useCallback(() => {
    setIsDropdownOpen((value) => !value)
  }, [])

  const commentOptionsButtonRef = React.useRef<HTMLButtonElement>(null)

  const { selectedCommentId } = useCommentContext()

  React.useEffect(() => {
    if (selectedCommentId !== id) {
      closeCommentOptions()
      setIsEditingComment(false)
    }
  }, [selectedCommentId])

  const { userId } = useCurrentUser()

  const canEdit = author.id === userId

  return (
    <>
      <div className={classNames('flex flex-col px-4', {})}>
        <div className="flex flex-col gap-2 py-2">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center gap-2">
              <img
                draggable={false}
                className="h-6 w-6 rounded-full object-cover"
                crossOrigin="anonymous"
                src={data.author.avatarUrl}
              />
              <p className="font-body text-[12px] font-normal text-black">
                <span className="opacity-80">{author.name}</span>
                <span className="opacity-30">
                  {` `}
                  {formattedDistance}
                </span>
              </p>
            </div>
            {canEdit && (
              <div className="relative">
                <button
                  className="pointer-events-auto cursor-default"
                  ref={commentOptionsButtonRef}
                  onClick={toggleCommentOptions}
                >
                  {threeDotsIcon}
                </button>

                {isDropdownOpen && (
                  <div
                    className="pointer-events-auto fixed inset-0 z-[100]"
                    onClick={closeCommentOptions}
                  />
                )}

                <div
                  className={classNames(
                    'absolute right-0 z-[200] bg-secondary py-1 transition-all duration-150',
                    {
                      ['pointer-events-none -translate-y-2 opacity-0']:
                        !isDropdownOpen,
                      ['pointer-events-auto -translate-y-1']: isDropdownOpen,
                    }
                  )}
                >
                  <button onClick={startEditing}>
                    <p className="px-3 py-1 text-xs text-white hover:bg-accent">
                      Edit...
                    </p>
                  </button>
                </div>
              </div>
            )}
          </div>
          <div className="relative">
            <div
              className={classNames(' overflow-hidden ', {
                ['hidden']: !isEditingComment,
              })}
            >
              <EditCommentThreadItem
                data={data}
                isShowing={isEditingComment}
                onFinish={finishEditing}
              />
            </div>
            <div
              className={classNames(' overflow-hidden', {
                ['hidden']: isEditingComment,
              })}
            >
              <p className="select-text whitespace-pre-wrap pl-8 font-body text-[11px] font-normal text-black text-opacity-80">
                {comment}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

CommentThreadItem.displayName = 'CommentThreadItem'

const threeDotsIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="1" fill="black" />
    <circle cx="12" cy="8" r="1" fill="black" />
    <circle cx="4" cy="8" r="1" fill="black" />
  </svg>
)
