import {
  commitUndo,
  DurationComponent,
  Entity,
  EntityType,
  EntityTypeComponent,
  FpsComponent,
  getEntryOrThrow,
  getSortedKeyframes,
  LayerColor,
  Project,
  Root,
  round,
  SelectionSystem,
  TargetRelationAspect,
  TimeComponent,
  UpdatesSystem,
} from '@aninix-inc/model'
import { useMouseMove, VirtualizedList } from '@aninix/app-design-system'
import {
  getSelection,
  KeyModificator,
  useComponent,
  useEntity,
  usePlayback,
  useReloadOnAnyUpdate,
  useSession,
  useSystem,
  useTimeline,
} from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import type { OnScrollParams } from 'react-virtualized/dist/es/ScrollSync'
import { defaults } from '../../../defaults'
import * as timeConverters from '../../../helpers/timeConverters'
import { getNodeRows, TRow } from '../get-row-data'
import { useAutoScrollSync, useScrollSync } from '../scroll-sync'
import * as styles from './index.scss'
import { Layer } from './layer'
import { PreviewRange } from './preview-range'
import { Property } from './property'
import { PropertyGroup } from './property-group'
import { TimeIndicators } from './time-indicators'
import { TimeSlider } from './time-slider'
import { VisibleRange } from './visible-range'

const ROW_HEIGHT = 18
const HANDLER_WIDTH = 6

const createRenderRow =
  (actualContainerWidth: number) =>
  (props: {
    value: unknown
    measure: () => void
    isVisible: boolean
  }): JSX.Element => {
    const value = props.value as TRow

    if (value.type === 'layer') {
      const project = value.entity.getProjectOrThrow()

      let cacheMap = project.cache.get<Map<string, unknown>>(value.entity.id)

      if (cacheMap == null) {
        cacheMap = new Map<string, unknown>()
        project.cache.set(value.entity.id, cacheMap)
      }

      let cachedValue = cacheMap.get('keyframes') as Entity[] | undefined

      const keyframes = (() => {
        if (cachedValue != null) {
          return cachedValue
        }

        cachedValue = R.sortBy(
          (e) => e.getComponentOrThrow(TimeComponent).value,
          project.getEntitiesByPredicate((e) => {
            // @NOTE: check for nullability required in cases when there is a keyframe
            // which pointed to removed layer.
            const target = e.getAspect(TargetRelationAspect)?.getTargetEntity()

            if (target == null) {
              return false
            }

            return (
              e.getComponentOrThrow(EntityTypeComponent).value ===
                EntityType.Keyframe &&
              // @TODO: FIXME IMPORTANT. Related to ANI-1348
              // The dirty fix to ignore DISCONNECTED keyframes.
              // Which can happen when user copy-pasted keyframes for linked entities (fills/strokes/trim-path/effect etc)
              Object.keys(project.getRelations().getRelationForEntity(e.id))
                .length !== 0 &&
              target.id === value.entity.id
            )
          })
        )
        cacheMap.set('keyframes', cachedValue)
        return cachedValue
      })()

      return (
        <Layer
          key={value.entity.id}
          layer={value.entity}
          // @TODO: add support of nested groups (eg from children)
          keyframes={keyframes}
          parentTrackWidth={actualContainerWidth}
        />
      )
    }

    if (value.type === 'property') {
      return (
        <Property
          key={value.entity.id}
          component={value.entity}
          parentTrackWidth={actualContainerWidth}
        />
      )
    }

    if (value.type === 'property-group') {
      return (
        <PropertyGroup
          key={value.entity.id}
          entity={value.entity}
          measure={props.measure}
          parentTrackWidth={actualContainerWidth}
        />
      )
    }

    return <div />
  }

export interface IProps {
  project: Project
}
// @TODO: refactor selection
export const Tracks: React.FCC<IProps> = observer(({ project }) => {
  useReloadOnAnyUpdate(project)
  const timeline = useTimeline()
  const playback = usePlayback()
  const session = useSession()
  const selection = project.getSystemOrThrow(SelectionSystem)
  useSystem(selection)

  const updates = project.getSystemOrThrow(UpdatesSystem)

  const entry = getEntryOrThrow(project)
  useEntity(entry)
  const root = project.getEntityByTypeOrThrow(Root)
  useEntity(root)
  const projectDurationComponent = root.getComponentOrThrow(DurationComponent)
  const projectFpsComponent = root.getComponentOrThrow(FpsComponent)
  const projectDuration = projectDurationComponent.value
  const projectFps = projectFpsComponent.value
  useComponent(projectDurationComponent)
  useComponent(projectFpsComponent)
  const zoom = projectDuration / timeline.visibleRangeDuration

  const data = getNodeRows(entry, { indent: 0 })

  const containerRef = React.useRef<HTMLDivElement>(null)
  const listContainerRef = React.useRef<HTMLDivElement>(null)
  const listRef = React.useRef<any>(null)
  const [listScrollOffset, setListScrollOffset] = React.useState(0)
  const [initialListScrollOffset, setInitialListScrollOffset] =
    React.useState(0)
  const listScrollOffsetRef = React.useRef(0)
  // @NOTE: current selected keyframes, required to properly handle shift key modificator
  const selectedKeyframesRef = React.useRef<Entity[]>([])
  const [baseContainerWidth, setBaseContainerWidth] = React.useState(500)
  const actualContainerWidth = Math.round(
    baseContainerWidth * round(zoom, { fixed: 4 })
  )

  const wasTriggeredRef = React.useRef(false)
  const {
    isListening,
    wasTriggered,
    startListen,
    startAtX,
    startAtY,
    endAtX,
    endAtY,
  } = useMouseMove({
    threshold: 4,
    element: listContainerRef.current!,
    onTrigger: () => {
      wasTriggeredRef.current = true
    },
  })

  const { scrollTo, scrollTop } = useScrollSync()

  const convertPixelsToTime = React.useCallback(
    (pixels: number) =>
      timeConverters.convertPixelsToTime({
        projectDuration: projectDuration,
        trackWidth: actualContainerWidth - HANDLER_WIDTH * 2,
        pixels: pixels - HANDLER_WIDTH,
      }),
    [projectDuration, actualContainerWidth, timeline.visibleRangeStartTime]
  )

  const convertPixelsToRowIndex = React.useCallback(
    (pixels: number) => Math.floor((pixels + listScrollOffset) / ROW_HEIGHT),
    [listScrollOffset]
  )

  const selectionRectBoundingRect = React.useMemo(() => {
    const zerofy = initialListScrollOffset - listScrollOffset
    const delta = listScrollOffset - initialListScrollOffset

    if (session.keyModificators.includes(KeyModificator.Ctrl)) {
      const startY = 0
      const endY = containerRef.current?.clientHeight ?? 100
      const top = Math.min(startY, endY)
      const height = Math.abs(endY - startY)

      return {
        x: Math.min(startAtX, endAtX),
        y: top,
        width: Math.abs(endAtX - startAtX),
        height: height,
      }
    }

    const startY = startAtY + zerofy
    const endY = endAtY + zerofy + delta
    const top = Math.min(startY, endY)
    const height = Math.abs(endY - startY)

    return {
      x: Math.min(startAtX, endAtX),
      y: top,
      width: Math.abs(endAtX - startAtX),
      height: height,
    }
  }, [
    startAtX,
    startAtY,
    endAtX,
    endAtY,
    listScrollOffset,
    initialListScrollOffset,
    session.keyModificators.includes(KeyModificator.Ctrl),
  ])

  const isSelectionRectVisible = React.useMemo(
    () => isListening && wasTriggered,
    [isListening, wasTriggered]
  )

  const handleScroll = React.useCallback(
    (params: OnScrollParams) => {
      scrollTo(params)
      setListScrollOffset(params.scrollTop)
      listScrollOffsetRef.current = params.scrollTop
    },
    [scrollTo]
  )

  React.useEffect(() => {
    const containerRefResizeObserver = new ResizeObserver((entries) => {
      const parent = entries[0].target as HTMLDivElement
      setBaseContainerWidth(parent.clientWidth)
    })

    containerRefResizeObserver.observe(containerRef.current!)
    setBaseContainerWidth(containerRef.current!.clientWidth)

    return () => {
      containerRefResizeObserver.disconnect()
    }
  }, [])

  React.useEffect(
    function updateTimelineAndPlaybackOnProjectDurationChange() {
      return updates.onUpdate((updates) => {
        if (updates.includes(root.getComponentOrThrow(DurationComponent).id)) {
          // @NOTE: this dirty hack required to schedule update when state would be updated.
          // @TODO: FIXME but it would be better to have `beforeUpdate` and `afterUpdate` callbacks.
          // So we would have more control over updating system.
          setTimeout(() => {
            const duration = root.getComponentOrThrow(DurationComponent).value

            timeline
              .updateVisibleRangeStartTime(0)
              .updateVisibleRangeDuration(duration)

            playback.previewRange.updateStart(0).updateDuration(duration)
          })
        }
      })
    },
    [updates, root, timeline, playback]
  )

  const selectKeyframes = React.useCallback(
    (payload: {
      startRowIdx: number
      endRowIdx: number
      startSelectionTime: number
      endSelectionTime: number
    }) => {
      const { startRowIdx, endRowIdx, startSelectionTime, endSelectionTime } =
        payload
      const startTime = Math.min(startSelectionTime, endSelectionTime)
      const endTime = Math.max(startSelectionTime, endSelectionTime)
      const startIdx = Math.min(startRowIdx, endRowIdx)
      const endIdx = Math.max(startRowIdx, endRowIdx)

      let keyframes: Entity[] = []
      for (let i = 0; i < data.length; i += 1) {
        // @NOTE: skip out of range rows
        if (i < startIdx || endIdx < i) {
          continue
        }

        const layerOrPropertyGroupOrProperty = data[i]
        if (layerOrPropertyGroupOrProperty.type === 'property') {
          const component = layerOrPropertyGroupOrProperty.entity
          const keyframesToSelect = getSortedKeyframes(component).filter(
            (keyframe) => {
              const time = keyframe.getComponentOrThrow(TimeComponent).value
              return time >= startTime && time <= endTime
            }
          )
          keyframes.push(...keyframesToSelect)
        }
      }

      // @NOTE: required to remove duplicated items in selection.
      // Related to ANI-1583.
      selection.replace(
        R.uniq([
          ...keyframes.map((k) => k.id),
          ...selectedKeyframesRef.current.map((k) => k.id),
        ])
      )
    },
    [data, project, selection]
  )

  useAutoScrollSync({
    containerRef,
    listContainerRef,
    rowsCount: data.length,
    enabled: isSelectionRectVisible,
  })

  React.useEffect(() => {
    if (isListening === false || wasTriggered === false) {
      return
    }

    const zerofy = initialListScrollOffset - listScrollOffset
    const delta = listScrollOffset - initialListScrollOffset
    const startY = startAtY + zerofy
    const endY = endAtY + zerofy + delta

    if (session.keyModificators.includes(KeyModificator.Ctrl)) {
      selectKeyframes({
        startRowIdx: convertPixelsToRowIndex(-Infinity),
        endRowIdx: convertPixelsToRowIndex(Infinity),
        startSelectionTime: convertPixelsToTime(startAtX),
        endSelectionTime: convertPixelsToTime(endAtX),
      })
      return
    }

    selectKeyframes({
      startRowIdx: convertPixelsToRowIndex(startY),
      endRowIdx: convertPixelsToRowIndex(endY),
      startSelectionTime: convertPixelsToTime(startAtX),
      endSelectionTime: convertPixelsToTime(endAtX),
    })
  }, [
    isListening,
    wasTriggered,
    startAtX,
    startAtY,
    endAtX,
    endAtY,
    selectKeyframes,
    convertPixelsToRowIndex,
    convertPixelsToTime,
  ])

  const renderRow = React.useMemo(
    () => createRenderRow(actualContainerWidth),
    [actualContainerWidth]
  )
  const visibleStartTime = timeline.visibleRangeStartTime

  const convertTimeToPixels = React.useCallback(
    (time: number) =>
      timeConverters.convertTimeToPixels({
        projectDuration,
        trackWidth: actualContainerWidth,
        time,
      }),
    [projectDuration, actualContainerWidth]
  )

  const left = convertTimeToPixels(visibleStartTime) * -1
  const width = actualContainerWidth
  const height = timeline.height

  return (
    <div ref={containerRef}>
      <div
        className={styles.container}
        style={{
          width: `calc(100vw - ${actualContainerWidth} - ${styles.properties_panel_width})`,
        }}
      >
        <div className={styles['tracks-container']}>
          <div className={styles['layers-container']} style={{ left, width }}>
            <div className={styles['header-container']}>
              <TimeIndicators
                projectDuration={projectDuration}
                parentTrackWidth={actualContainerWidth}
              />

              <PreviewRange
                projectDuration={projectDuration}
                parentTrackWidth={actualContainerWidth}
              />
            </div>

            <TimeSlider parentTrackWidth={actualContainerWidth} />

            <div
              className={styles['nodes-list']}
              style={{
                height: height,
              }}
            >
              <div
                ref={listContainerRef}
                style={{ position: 'relative' }}
                onMouseDown={(e) => {
                  // @ts-ignore
                  const closestButton = e.target.closest('button')
                  const buttonType =
                    closestButton?.getAttribute('data-model-type')
                  // @ts-ignore
                  const type = e.target?.getAttribute('data-model-type')
                  const types = [
                    'time-indicators',
                    'time-slider',
                    'preview-range',
                    // @NOTE: commented entities to test new interactions on timeline
                    // 'node',
                    // 'group-of-segments',
                    // 'segment',
                    // 'keyframe',
                    'visible-range',
                  ]
                  const shouldPreventSelection =
                    types.includes(buttonType) || types.includes(type)
                  if (shouldPreventSelection) {
                    return
                  }
                  if (session.keyModificators.includes(KeyModificator.Shift)) {
                    selectedKeyframesRef.current = getSelection(
                      project,
                      EntityType.Keyframe
                    )
                  } else {
                    selectedKeyframesRef.current = []
                  }
                  // @ts-ignore
                  startListen(e)
                  setInitialListScrollOffset(listScrollOffset)
                }}
                onMouseUp={(e) => {
                  // @ts-ignore
                  const closestButton = e.target.closest('button')
                  const buttonType =
                    closestButton?.getAttribute('data-model-type')
                  // @ts-ignore
                  const type = e.target?.getAttribute('data-model-type')
                  // @NOTE: deselect everything when clicking on empty space
                  if (
                    buttonType == null &&
                    (type == null || type === 'property')
                  ) {
                    selection.deselectAll()
                  }

                  if (wasTriggeredRef.current) {
                    wasTriggeredRef.current = false
                    commitUndo(project)
                    return
                  }
                }}
              >
                {/* @NOTE: selection rectangle */}
                <div
                  style={{
                    left: selectionRectBoundingRect.x,
                    top: selectionRectBoundingRect.y,
                    width: selectionRectBoundingRect.width,
                    height: selectionRectBoundingRect.height,
                    position: 'absolute',
                    border: `1px solid ${defaults.nodeColors[LayerColor.Blue]}`,
                    backgroundColor: `${
                      defaults.nodeColors[LayerColor.Blue]
                    }35`,
                    display: isSelectionRectVisible ? 'block' : 'none',
                    zIndex: 3,
                  }}
                />

                <VirtualizedList
                  // @ts-ignore
                  ref={listRef}
                  onScroll={handleScroll}
                  data={data}
                  renderRow={renderRow}
                  offsetY={scrollTop}
                  height={height}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles['visible-range-container']}>
          <VisibleRange
            parentTrackWidth={baseContainerWidth}
            projectDuration={projectDuration}
          />
        </div>
      </div>
    </div>
  )
})

Tracks.displayName = 'Tracks'
