import { Typography } from 'apps/web-app/src/components/design-system/typography'
import * as React from 'react'
import { FoldIcon } from '../../icons/FoldIcon'

export interface IProps {
  label: string
  isOpen: boolean
  toggleOpen: () => void
}
export const Spoiler: React.FCC<IProps> = ({ label, isOpen, toggleOpen }) => (
  <label className="flex py-1 flex-row gap-0.5 items-center">
    <button className="hidden" type="button" onClick={toggleOpen} />

    <FoldIcon isOpen={isOpen} />
    <Typography className="w-full" style="Body5Medium" text={label} />
  </label>
)

Spoiler.displayName = 'Spoiler'
