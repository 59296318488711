import classNames from 'classnames'
import * as React from 'react'
import { Icon } from './types'

const defaultSize = {
  x: 16,
  y: 16,
}
export const OpenEditor: React.FC<Icon> = ({
  size = defaultSize,
  className,
  color = '#FFFFFF',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    stroke={color}
    className={classNames('flex-shrink-0', className)}
  >
    <g clipPath="url(#clip0_1759_6625)">
      <path
        d="M12.3444 10.2667C12.4888 10.1222 12.6603 10.0076 12.849 9.92945C13.0378 9.85127 13.2401 9.81103 13.4444 9.81103C13.6487 9.81103 13.8509 9.85127 14.0397 9.92945C14.2284 10.0076 14.3999 10.1222 14.5444 10.2667C14.6888 10.4111 14.8034 10.5826 14.8816 10.7714C14.9598 10.9601 15 11.1624 15 11.3667C15 11.571 14.9598 11.7732 14.8816 11.962C14.8034 12.1507 14.6888 12.3222 14.5444 12.4667L12.0333 15H9.81103V12.7778L12.3444 10.2667Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 15H5.8C4.11984 15 3.27976 15 2.63803 14.673C2.07354 14.3854 1.6146 13.9265 1.32698 13.362C1 12.7202 1 11.8802 1 10.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H10.2C11.8802 1 12.7202 1 13.362 1.32698C13.9265 1.6146 14.3854 2.07354 14.673 2.63803C15 3.27976 15 4.11984 15 5.8V7.5"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 5.5L10.5 8L6.5 10.5V5.5Z"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1759_6625">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
