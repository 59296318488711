import { Button } from '@material-ui/core'
import classnames from 'classnames'
import React from 'react'

import { Loader } from '../../loader'
import { Tooltip } from '../../tooltip'
import * as styles from './index.scss'

type ButtonVariant = 'contained' | 'outlined' | 'text'

export interface IProps {
  variant: ButtonVariant
  title: React.ReactNode
  onClick: () => void
  className?: string
  classes?: {
    container?: string
    label?: string
  }
  disabled?: boolean
  success?: boolean
  destructive?: boolean
  loading?: boolean
  fullWidth?: boolean
  tooltip?: string
  color?: string
}

const RegularButton: React.FCC<IProps> = ({
  variant,
  title,
  onClick,
  className,
  classes,
  disabled = false,
  success = false,
  destructive = false,
  loading = false,
  fullWidth,
  tooltip = '',
  color,
}) => (
  <Tooltip title={tooltip} arrow>
    <Button
      variant={variant}
      // @ts-ignore
      style={{ ['--color']: color }}
      classes={{
        root: styles.container,
        label: classnames(styles.label, classes?.label),
        contained: classnames(
          styles['contained-default'],
          className,
          classes?.container,
          {
            [styles['contained-success']]: success,
            [styles['contained-destructive']]: destructive,
            [styles['full-width']]: fullWidth,
          }
        ),
        outlined: classnames(
          styles['outlined-default'],
          className,
          classes?.container,
          {
            [styles['outlined-success']]: success,
            [styles['outlined-destructive']]: destructive,
            [styles['full-width']]: fullWidth,
          }
        ),
        text: classnames(styles['text-button'], className, classes?.container),
      }}
      disabled={disabled || loading}
      onClick={onClick}
      disableRipple
    >
      {loading && (
        <Loader
          size={16}
          color={
            variant === 'outlined'
              ? styles.loader_color_inverted
              : styles.loader_color
          }
          className={styles.loader}
        />
      )}
      {title}
    </Button>
  </Tooltip>
)

export default RegularButton
