export { AnchorPoint, AnchorPointPosition } from './anchor-point'
export { Badge } from './badge'
export { default as BasicCard } from './BasicCard'
export * as buttons from './buttons'
export { default as Checkbox } from './Checkbox'
export { Lottie } from './lottie'

// Color pickers
export { AlphaPicker } from './alpha-picker'
export { EyeDropper } from './eye-dropper'
export { GradientSlider } from './gradient-slider'
export { HuePicker } from './hue-picker'
export { InputHex } from './input-hex'
export { InputRgba } from './input-rgba'
export { SaturationLightnessPicker } from './saturation-lightness-picker'
export { SolidColorPicker } from './solid-color-picker'

// Controls
export { PaintControl } from './paint-control'

export { ColorSelector } from './ColorSelector'
export { CompactPropertyRow } from './compact-property-row'
export { Dropdown } from './Dropdown'
export { FullPageLoader } from './FullPageLoader'

export { CopyPreset } from './graph-editor/components/copy-preset'
export { SaveUserPreset } from './graph-editor/components/save-user-graph'
export { SpringGraph } from './graph-editor/components/spring-graph'
export { TimingGraph } from './graph-editor/components/timing-graph'
export { UserPresets } from './graph-editor/components/user-graphs'
export { CurveStyles } from './graph-editor/components/user-graphs/curve-styles'

export { Helper, type IProps as HelperProps } from './helper'
export * as icons from './icons'
export { Input } from './input'
export { InputTimeline } from './input-timeline'
export { Logo, LOGO_WIDTH } from './Logo'
export { PropertyLabel } from './PropertyLabel'
export { Select } from './Select'
export type { Option as OptionType, IProps as SelectProps } from './Select'

import { featureFlags } from '@aninix/core'

import { Input as InputWithButtonV1 } from './input-with-button'
import { Input as InputWithButtonV2 } from './input-with-button-v2'

export const InputWithButton = featureFlags.refactoredInputWithIcon
  ? InputWithButtonV2
  : InputWithButtonV1

import { Input as InputWithIconV1 } from './input-with-icon'
import { Input as InputWithIconV2 } from './input-with-icon-v2'
import { Input as InputWithIconV3 } from './input-with-icon-v3'

export const InputWithIcon = featureFlags.optimization
  ? InputWithIconV3
  : featureFlags.refactoredInputWithIcon
    ? InputWithIconV2
    : InputWithIconV1

export { Loader } from './loader'
export { Markdown } from './markdown'
export { default as NestedMenu } from './nested-menu'
export type { TMenuItem as NestedMenuItemType } from './nested-menu'
export { PaymentPlansComparison } from './payment-plans-comparison'
export type { Advantage, PaymentPlan } from './payment-plans-comparison'
export { PopupHeader } from './popup-header'
export { ProBadge } from './pro-badge'
export { ProFeatureDescription } from './pro-feature-description'
export { ProjectTab } from './project-tab'
export { PropertiesPanelPopper } from './properties-panel-popper'
export { PropertyRow } from './property-row'
export { PropertyRow as PropertyRowV2 } from './property-row-v2'
export { TimelineTrack } from './timeline-track'
export { Toggle } from './toggle'
export { Tooltip } from './tooltip'
export { default as VirtualizedList } from './virtualized-list'

export { NodesAlignType } from './icons/properties-panel/nodes-align'
export { NodesDistributionType } from './icons/properties-panel/nodes-distribution'
export { SegmentsAlignType } from './icons/properties-panel/segments-align'

// Aninix Connect 2.1
export { LayerPreview, type IProps as LayerPreviewProps } from './layer-preview'
export {
  ProjectPicker,
  type IProps as ProjectPickerProps,
} from './project-picker'
export { SearchBox, type IProps as SearchBoxProps } from './search-box'
export { TeamPicker, type IProps as TeamPickerProps } from './team-picker'
export { ToggleImport, type IProps as ToogleImportProps } from './toggle-import'

export { HotkeyCombination } from './hotkey-combination'
