import { useMutation } from '@tanstack/react-query'

import { ApiPostCreatedResponse, ApiPostRequestBody } from './helpers'
import { httpClient } from './http-client'

export function useStartFigmaAuthSession() {
  return useMutation({
    async mutationFn(payload: ApiPostRequestBody<'/figma-auth-sessions'>) {
      return httpClient.post<ApiPostCreatedResponse<'/figma-auth-sessions'>>(
        '/figma-auth-sessions',
        payload
      )
    },
  })
}

export function startFigmaAuthSession(
  payload: ApiPostRequestBody<'/figma-auth-sessions'>
) {
  return httpClient.post<ApiPostCreatedResponse<'/figma-auth-sessions'>>(
    '/figma-auth-sessions',
    payload
  )
}
