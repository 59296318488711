import { DurationComponent, Root } from '@aninix-inc/model'
import classNames from 'classnames'
import * as React from 'react'
import { useProject } from '../../stores'
import { IComment } from '../../use-cases/comments/use-get-comments'
import { useCommentContext } from '../comments-wrapper/use-cases/use-comment-context'

export interface IProps {
  data: IComment
}
export const CommentTimelineItem: React.FCC<IProps> = ({ data }) => {
  const {
    id,
    comment,
    rectangle,
    time,
    isResolved,
    replies,
    author,
    createdAt,
    updatedAt,
    //this means if there's replies - take last one as the source. if no replies - take the original comment
  } = data.replies !== undefined ? data.replies.at(-1)! : data

  const project = useProject()

  const leftPosition = React.useMemo(
    () =>
      (data.time /
        project
          .getEntityByTypeOrThrow(Root)
          .getComponentOrThrow(DurationComponent).value) *
        100 +
      '%',
    [project, data.time]
  )

  const { selectedCommentId, setSelectedCommentId } = useCommentContext()

  const selectComment = React.useCallback(() => {
    setSelectedCommentId(data.id)
  }, [setSelectedCommentId, data.id])

  const isSelected = selectedCommentId === data.id

  return (
    <div
      onClick={selectComment}
      className={classNames(
        'group pointer-events-auto absolute w-6 cursor-pointer',
        {
          ['z-[200]']: !isSelected,
          ['z-[250]']: isSelected,
        }
      )}
      style={{
        left: leftPosition,
        transform: 'translate(-12px, -28px)',
      }}
    >
      <div className={classNames('relative transition-all duration-150')}>
        <div className="absolute -bottom-[5px] left-[11px] h-[2px] w-[2px] rounded-full bg-red" />

        <div
          className={classNames('rounded-full bg-white', {
            ['origin-bottom scale-[0.66]']: !isSelected,
          })}
        >
          <img
            draggable={false}
            className={classNames(
              'group-hover:opacity-100! h-6 w-6 rounded-full object-cover transition-all duration-150 ',
              {
                ['opacity-50']: !isSelected,
              }
            )}
            crossOrigin="anonymous"
            src={author.avatarUrl}
          />
        </div>
      </div>
    </div>
  )
}

CommentTimelineItem.displayName = 'CommentTimelineItem'
