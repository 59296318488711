import {
  FontNameComponent,
  FontStyleComponent,
  FontWeightComponent,
  Project,
  StyledTextSegment,
} from '@aninix-inc/model'
import { loadInlineFonts } from './load-inline-fonts'

export type FontMeta = {
  fontFamilyName: string
  fontStyle: string
  weight: number
}

const findMissingFonts = (
  meta: FontMeta[],
  inlineFonts: string
): FontMeta[] => {
  const missingFonts = meta.filter(({ fontFamilyName, fontStyle, weight }) => {
    const fontFaceRegex = new RegExp(
      `@font-face\\s*{[^}]*font-family:\\s*['"]?${fontFamilyName}['"]?;[^}]*font-style:\\s*${fontStyle};[^}]*font-weight:\\s*${weight};[^}]*}`
    )
    return !fontFaceRegex.test(inlineFonts)
  })

  // Remove duplicates
  return missingFonts.filter(
    (font, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t.fontFamilyName === font.fontFamilyName &&
          t.fontStyle === font.fontStyle &&
          t.weight === font.weight
      )
  )
}

export const loadFonts = async (project: Project) => {
  const styledSegments = project.getEntitiesByType(StyledTextSegment)

  if (styledSegments.length === 0) return undefined

  const meta = styledSegments.map((e) => ({
    fontFamilyName: e.getComponentOrThrow(FontNameComponent).value,
    fontStyle: e
      .getComponentOrThrow(FontStyleComponent)
      .value.includes('Italic')
      ? 'italic'
      : 'normal',
    weight: e.getComponentOrThrow(FontWeightComponent).value,
  }))

  const params = meta
    .reduce((acc, { fontFamilyName, fontStyle, weight }) => {
      const fontParam = `family=${encodeURIComponent(fontFamilyName)}:ital,wght@${
        fontStyle === 'italic' ? '1' : '0'
      },${weight}`
      return acc.includes(fontParam) ? acc : [...acc, fontParam]
    }, [] as string[])
    .join('&')

  const googleFontsLink = `https://fonts.googleapis.com/css2?${params}`

  const inlineFonts = await loadInlineFonts(googleFontsLink)

  const missingFonts = findMissingFonts(meta, inlineFonts)

  return {
    inlineFonts,
    missingFonts,
  }
}
