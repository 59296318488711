import { httpClient } from '@aninix/api'
import { ImagesStore } from '@aninix/core'
import { useLogger } from '@aninix/logger'
import { autorun } from 'mobx'
import * as React from 'react'

import { ApiGetResponse } from '@aninix/api/helpers'
import { config } from '../config'

export enum ActiveSystemStatus {
  Default = 'DEFAULT',
  Warning = 'WARNING',
  Loading = 'LOADING',
  Error = 'ERROR',
}

export interface IActiveSystemStatus {
  status: ActiveSystemStatus
  comment: string
  onClick?: () => void
}

type Payload = {
  imagesStore: ImagesStore
}

export function useActiveSystemStatus({
  imagesStore,
}: Payload): IActiveSystemStatus {
  const logger = useLogger()
  const [defaultStatus, setDefaultStatus] = React.useState<ActiveSystemStatus>(
    ActiveSystemStatus.Default
  )
  const [defaultComment, setDefaultComment] = React.useState<string>(
    'All operations up and running'
  )
  const [status, setStatus] = React.useState<ActiveSystemStatus | undefined>()
  const [comment, setComment] = React.useState<string | undefined>()

  const setLocalStatus = React.useCallback(
    (payload: { status: ActiveSystemStatus; comment?: string }) => {
      setStatus(payload.status)
      setComment(payload.comment)
    },
    []
  )

  const clearLocalStatus = React.useCallback(() => {
    setStatus(undefined)
    setComment(undefined)
  }, [])

  const getRemoteStatus = React.useCallback(async () => {
    try {
      const { data: systemStatus } = await httpClient.get<
        ApiGetResponse<'/system-statuses/editor'>
      >('/system-statuses/editor')

      if (systemStatus == null) {
        return
      }

      if (systemStatus.type === 'error') {
        setDefaultStatus(ActiveSystemStatus.Error)
        setDefaultComment(systemStatus.message)
        return
      }

      if (systemStatus.type === 'warning') {
        setDefaultStatus(ActiveSystemStatus.Warning)
        setDefaultComment(systemStatus.message)
        return
      }
    } catch (err: any) {
      logger.error('Status notification get data error', err)
    }
  }, [])

  React.useEffect(() => {
    const engine = config.getEngine()
    const notChromium =
      ['electron', 'chrome'].includes(engine.toLowerCase()) === false

    if (notChromium) {
      setDefaultStatus(ActiveSystemStatus.Warning)
      setDefaultComment(
        'Some operations are not available in your browser. For better experience please use Google Chrome.'
      )
      return
    }
  }, [])

  React.useEffect(() => {
    getRemoteStatus()
  }, [])

  React.useEffect(() => {
    const subscription = autorun(() => {
      const isImagesLoading = imagesStore.isLoading

      if (isImagesLoading) {
        setLocalStatus({
          status: ActiveSystemStatus.Loading,
          comment: 'Loading images...',
        })
        return
      }

      clearLocalStatus()
    })

    return subscription
  }, [])

  const onClick: IActiveSystemStatus['onClick'] = React.useCallback(() => {
    window.open('https://aninix.com/status', '_blank')
  }, [])

  const actualStatus = React.useMemo(
    () => status ?? defaultStatus,
    [status, defaultStatus]
  )

  const actualComment = React.useMemo(
    () => comment ?? defaultComment,
    [comment, defaultComment]
  )

  return {
    status: actualStatus,
    comment: actualComment,
    onClick: defaultStatus === ActiveSystemStatus.Error ? onClick : undefined,
  }
}
