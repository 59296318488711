import * as React from 'react'

import { Loader } from '../loader'
import * as styles from './styles.scss'

export interface IProps {}
export const FullPageLoader: React.FCC<IProps> = ({ children }) => (
  <div className={styles.container}>
    <Loader />
    {children}
  </div>
)
