import { useQuery } from '@tanstack/react-query'

import { ApiGetResponse } from './helpers'
import { httpClient } from './http-client'

export function useGetMe() {
  return useQuery({
    queryKey: ['get-me'],
    async queryFn() {
      return httpClient.get<ApiGetResponse<'/users/me'>>('/users/me')
    },
  })
}

export function getMe() {
  return httpClient.get<ApiGetResponse<'/users/me'>>('/users/me')
}
