import * as React from 'react'

import { Left as LeftIcon } from './left'
import { Right as RightIcon } from './right'

export interface IProps {
  type: 'left' | 'right'
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Offset: React.FCC<IProps> = ({ type, size, style }) => {
  if (type === 'left') {
    return <LeftIcon style={style} size={size} />
  }

  if (type === 'right') {
    return <RightIcon style={style} size={size} />
  }

  return null
}
