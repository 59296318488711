import * as React from 'react'
import { OfflineIcon } from '../offline/icon'

export interface IProps {
  message?: string
}
export const GeneralAccessRestricted: React.FCC<IProps> = ({
  message = 'Feature is not available for you account.',
}) => (
  <div className="mx-auto flex h-screen w-screen max-w-sm flex-col items-center justify-center text-center text-black/80">
    <OfflineIcon className="w-[258px]" />

    <p className="mt-2 text-base">{message}</p>

    <p className="mt-1 text-base">
      Please,{' '}
      <a
        href="mailto:info@aninix.com"
        target="_blank"
        className="text-[#18A0FB]"
        rel="noreferrer"
      >
        contact us.
      </a>
    </p>
  </div>
)

GeneralAccessRestricted.displayName = 'GeneralAccessRestricted'
