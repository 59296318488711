import classnames from 'classnames'
import React from 'react'

import { IconButton } from '../buttons/icon-button'
import { Close } from '../icons/Close'
import * as styles from './index.scss'

export interface IProps {
  onClose: () => void
  disabled?: boolean
  className?: string
}
export const PopupHeader: React.FCC<IProps> = ({
  children,
  onClose,
  disabled = false,
  className,
}) => (
  <div className={classnames(styles.header, className)}>
    <div className={styles.content}>{children}</div>

    <IconButton onClick={onClose} disabled={disabled}>
      <Close />
    </IconButton>
  </div>
)
