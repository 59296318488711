/**
 * @description allow download blob as a file
 */
export function downloadUriAsBlob(
  blob: Blob,
  name: string,
  focusCallback?: () => void
) {
  const handleFocus = () => {
    focusCallback?.()
    window.removeEventListener('focus', handleFocus)
  }

  if (focusCallback !== undefined) window.addEventListener('focus', handleFocus)

  const uri = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

/**
 * @description allow download base64 url as a file
 */
export function downloadUri(url: string, name: string) {
  const link = document.createElement('a')
  link.download = name
  link.href = url
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
