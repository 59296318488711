import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Timeline: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M8 12a4 4 0 004-4H4a4 4 0 004 4z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 11a3 3 0 100-6 3 3 0 000 6zm0 1a4 4 0 100-8 4 4 0 000 8z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
