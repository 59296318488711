import {
  Component,
  ScaleLockedComponent,
  SizeLockedComponent,
  commitUndo,
} from '@aninix-inc/model'
import { buttons, icons } from '@aninix/app-design-system'
import { useProject } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useInfo } from '../..'
import { useValueLockablePoint2d } from '../../../../../hooks/value-controllers/lockable-point-2d'
import { NumericValue } from '../numeric-value'

const btnSize = {
  width: 16,
  height: 16,
}
const iconSize = {
  x: 16,
  y: 16,
}

// @TODO: provide generic types
export interface IProps {
  component: Component
  lockableComponent: ScaleLockedComponent | SizeLockedComponent
  color?: string
  formatValue?: (value: number) => string
  /**
   * Run before provide value to UI
   */
  before?: (number: number) => number
  /**
   * Run before provide value to model
   */
  after?: (number: number) => number
  minX?: number
  maxX?: number
  minY?: number
  maxY?: number
}
export const LockablePoint2dValue: React.FCC<IProps> = ({
  component,
  lockableComponent,
  color = 'black',
  formatValue,
  before = R.identity,
  after = R.identity,
  minX,
  maxX,
  minY,
  maxY,
}) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { time } = useInfo()
  const { x, y, locked, toggleLock, updateX, updateY } =
    useValueLockablePoint2d({
      components: [component],
      lockableComponents: [lockableComponent],
      time,
    })

  React.useEffect(() => {
    setIsEditable(false)
  }, [time])

  return (
    <div onPointerMove={() => setIsEditable(true)} className="flex">
      {isEditable ? (
        <LockablePoint2dValueEditable
          x={x as number}
          y={y as number}
          locked={locked}
          toggleLock={toggleLock}
          updateX={updateX}
          updateY={updateY}
          color={color}
          formatValue={formatValue}
          before={before}
          after={after}
          minX={minX}
          maxX={maxX}
          minY={minY}
          maxY={maxY}
        />
      ) : (
        <LockablePoint2dValueDisplay
          x={x as number}
          y={y as number}
          locked={locked}
          color={color}
          formatValue={formatValue}
          before={before}
          minX={minX}
          maxX={maxX}
          minY={minY}
          maxY={maxY}
        />
      )}
    </div>
  )
}

LockablePoint2dValue.displayName = 'LockablePoint2dValue'

const LockablePoint2dValueEditable: React.FC<
  Omit<IProps, 'component' | 'lockableComponent'> & {
    x: number
    y: number
    locked: boolean
    toggleLock: () => void
    updateX: (newValue: number) => void
    updateY: (newValue: number) => void
  }
> = ({
  color,
  formatValue,
  before = R.identity,
  after = R.identity,
  minX,
  maxX,
  minY,
  maxY,
  locked,
  toggleLock,
  x,
  y,
  updateX,
  updateY,
}) => {
  const project = useProject()
  const onEndChange = React.useCallback(() => {
    commitUndo(project)
  }, [project])

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <buttons.Icon btnSize={btnSize} onClick={toggleLock}>
        <icons.Link locked={locked} size={iconSize} />
      </buttons.Icon>
      <NumericValue
        onEndChange={onEndChange}
        suffix=", "
        value={before(x as number)}
        onChange={(newValue) => updateX(after(newValue))}
        textColor={color}
        min={minX}
        max={maxX}
        format={formatValue}
      />
      <NumericValue
        onEndChange={onEndChange}
        value={before(y as number)}
        onChange={(newValue) => updateY(after(newValue))}
        textColor={color}
        min={minY}
        max={maxY}
        format={formatValue}
      />
    </div>
  )
}

LockablePoint2dValueEditable.displayName = 'LockablePoint2dValueEditable'

const LockablePoint2dValueDisplay: React.FC<{
  color: string
  formatValue?: (value: number) => string
  before: (number: number) => number
  minX?: number
  maxX?: number
  minY?: number
  maxY?: number
  locked: boolean
  x: number
  y: number
}> = React.memo(
  ({ color, formatValue, before, minX, maxX, minY, maxY, locked, x, y }) => {
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <buttons.Icon btnSize={btnSize} onClick={() => {}}>
          <icons.Link locked={locked} size={iconSize} />
        </buttons.Icon>
        <NumericValue
          onEndChange={() => {}}
          suffix=", "
          value={before(x as number)}
          onChange={() => {}}
          textColor={color}
          min={minX}
          max={maxX}
          format={formatValue}
        />
        <NumericValue
          onEndChange={() => {}}
          value={before(y as number)}
          onChange={() => {}}
          textColor={color}
          min={minY}
          max={maxY}
          format={formatValue}
        />
      </div>
    )
  },
  (prev, next) => {
    if (prev.locked !== next.locked) return false
    if (prev.x !== next.x) return false
    if (prev.y !== next.y) return false
    if (prev.minX !== next.minX) return false
    if (prev.maxX !== next.maxX) return false
    if (prev.minY !== next.minY) return false
    if (prev.maxY !== next.maxY) return false
    return true
  }
)

LockablePoint2dValueDisplay.displayName = 'LockablePoint2dValueDisplay'
