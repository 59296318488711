import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const StarPointCount: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M7.33333 0H8.66667V16H7.33333V0ZM16 7.33333V8.66667H0V7.33333H16ZM13.1867 1.872L14.128 2.81467L2.81467 14.128L1.872 13.1853L13.1853 1.872H13.1867ZM14.128 13.1853L13.1853 14.128L1.872 2.81467L2.81467 1.872L14.128 13.1853Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
