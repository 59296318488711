import { icons } from '@aninix/app-design-system'
import classnames from 'classnames'
import * as React from 'react'

import { PresetType } from '../../models/preset'
import * as styles from './view-preset-type.scss'

export interface IProps {
  type: PresetType
  updateType: (type: PresetType) => void
}

export const ViewPresetType: React.FCC<IProps> = ({ type, updateType }) => {
  const createApplyType = React.useCallback(
    (type: PresetType) => {
      return () => {
        updateType(type)
      }
    },
    [updateType]
  )

  const typeItems = React.useMemo(() => {
    return [
      {
        type: PresetType.In,
        icon: <icons.PresetType type="in" />,
        title: 'In',
        onClick: createApplyType(PresetType.In),
      },
      {
        type: PresetType.Out,
        icon: <icons.PresetType type="out" />,
        title: 'Out',
        onClick: createApplyType(PresetType.Out),
      },
      {
        type: PresetType.Effect,
        icon: <icons.PresetType type="effect" />,
        title: 'Effect',
        onClick: createApplyType(PresetType.Effect),
      },
    ]
  }, [createApplyType])

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {typeItems.map((typeItem) => (
          <button
            key={typeItem.type}
            className={classnames(styles.button, {
              [styles['button--highlighted']]: type === typeItem.type,
            })}
            onClick={typeItem.onClick}
          >
            {typeItem.icon}
            <span>{typeItem.title}</span>
          </button>
        ))}
      </div>
    </div>
  )
}
