import * as React from 'react'

import { useCurrentRowContext } from '../use-current-row-context'

export interface IProps {}
export const MemberSince: React.FCC = ({}) => {
  const { createdAt } = useCurrentRowContext()
  const date = new Date(createdAt!)

  return (
    <div className="flex flex-col gap-0">
      <p className="h-6 font-body text-base font-normal">
        {date.toLocaleDateString()}
      </p>
    </div>
  )
}

MemberSince.displayName = 'MemberSince'
