import { AnimationCurveAspect, CurveStyle } from '@aninix-inc/model'
import classNames from 'classnames'
import React from 'react'

import { IconButton } from '../../../../../buttons/icon-button'
import { Edit } from '../../../../../icons/Edit'
import { Graph } from '../../../../../icons/Graph'
import * as styles from './index.scss'

export interface IProps {
  preset: CurveStyle
  presetNameToRender: string
  onEditPreset: (presetId: string) => void
  onApply: (presetId: string) => void
  isHighlighted?: boolean
}
export const Preset: React.FCC<IProps> = ({
  preset,
  presetNameToRender,
  onEditPreset,
  onApply,
  isHighlighted = false,
}) => {
  const handleApply = React.useCallback(() => {
    onApply(preset.id)
  }, [preset.id, onApply])

  const handleEdit = React.useCallback(() => {
    onEditPreset(preset.id)
  }, [preset.id, onEditPreset])

  const iconSize = React.useMemo(() => ({ x: 16, y: 16 }), [])

  return (
    <div
      className={classNames(styles.preset, {
        [styles['preset--highlighted']]: isHighlighted,
      })}
    >
      <button
        type="button"
        className={styles['preset-value-wrapper']}
        onClick={handleApply}
      >
        <div className={styles['preset-icon']}>
          <Graph
            previews={preset
              .getAspectOrThrow(AnimationCurveAspect)
              .curveComponent()
              .getCache()}
            size={iconSize}
          />
        </div>

        <div className={styles['preset-value']}>{presetNameToRender}</div>
      </button>

      <div className={styles.hide}>
        <IconButton onClick={handleEdit} selected={isHighlighted}>
          <Edit />
        </IconButton>
      </div>
    </div>
  )
}

Preset.displayName = 'Preset'
