export enum LogLevel {
  /**
   * @description includes all logs levels
   */
  Debug = 'debug',

  /**
   * @description includes only user logs level
   */
  Info = 'info',

  /**
   * @description includes only errors
   */
  Error = 'error',
}

export interface Logger {
  log: (...args: any[]) => void
  warn: (...args: any[]) => void
  error: (...args: any[]) => void
}
