import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { useAuthInteractor } from './interactor'
import { AuthRouter } from './router'

export interface IProps {}
export const Auth: React.FCC<IProps> = observer(() => {
  const location = useLocation()
  const status = new URLSearchParams(location.search).get('status') as any
  const interactor = useAuthInteractor({ status })

  return <AuthRouter interactor={interactor} />
})

Auth.displayName = 'Auth'
