import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Normal: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M10.5 11H10h.5zm-5 0H6h-.5zM6 7V5H5v2h1zm4-2v2h1V5h-1zM8 3a2 2 0 012 2h1a3 3 0 00-3-3v1zM6 5a2 2 0 012-2V2a3 3 0 00-3 3h1zm4 4v2h1V9h-1zm-4 2V9H5v2h1zm2 2a2 2 0 01-2-2H5a3 3 0 003 3v-1zm2-2a2 2 0 01-2 2v1a3 3 0 003-3h-1z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
