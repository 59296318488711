import { round } from '@aninix-inc/model/legacy'
import classnames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import React, { useEffect, useState } from 'react'
import tinycolor from 'tinycolor2'

import { ColorStop } from '../gradient-slider/types'
import * as styles from './index.scss'

const noop = () => {}

const generateGradientStyle = (colorStops: ColorStop[]) => {
  const gradientStops = colorStops.map(
    (colorStop) =>
      `${tinycolor(colorStop.value).toHexString()} ${colorStop.progress * 100}%`
  )
  return {
    backgroundImage: `linear-gradient(90deg, ${gradientStops.join(', ')})`,
  }
}

export interface IProps {
  colorStops: ColorStop[]
  opacity: number
  onChange: (
    color: (
      | ColorStop
      | { id: undefined; value: undefined; progress: number }
    )[],
    opacity: number
  ) => void
  title: string
  previewEnabled?: boolean
  onPreviewClick?: () => void
  onStartChange?: () => void
  onEndChange?: () => void
  disabled?: boolean
}
export const InputGradient: React.FCC<IProps> = observer(
  ({
    colorStops,
    opacity,
    onChange,
    title,
    previewEnabled = false,
    onPreviewClick = noop,
    onStartChange = noop,
    onEndChange = noop,
    disabled,
  }) => {
    const isDirtyRef = React.useRef(false)
    const [isFocused, setIsFocused] = useState(false)
    const [isHovered, setIsHovered] = useState(false)
    const [isPercentFocused, setIsPercentFocused] = useState(false)

    const [localOpacity, setLocalOpacity] = useState(Math.round(opacity * 100))

    const startChange = React.useCallback(() => {
      isDirtyRef.current = true
      onStartChange()
    }, [onStartChange])

    const endChange = React.useCallback(() => {
      isDirtyRef.current = true
      onEndChange()
    }, [onEndChange])

    const handleChangeOpacity = (e: React.ChangeEvent<HTMLInputElement>) => {
      const newRoughOpacity = e.target.value
      const newOpacity = newRoughOpacity === '' ? '0' : newRoughOpacity
      const parsedOpacity = R.clamp(0, 100, parseFloat(newOpacity))
      setLocalOpacity(parsedOpacity)
      onChange(colorStops, round(parsedOpacity / 100, { fixed: 2 }))
    }

    const handleFocus = React.useCallback(
      (e: React.FocusEvent<HTMLInputElement, Element>) => {
        setLocalOpacity(Math.round(opacity * 100))
        // NOTE: required to correctly handle selection because we change value
        setTimeout(() => {
          e.target.select()
        }, 50)
        startChange()
        setIsFocused(true)
      },
      [startChange, opacity]
    )

    const handleBlur = React.useCallback(() => {
      endChange()
      setIsFocused(false)
    }, [endChange])

    const handlePercentFocus = React.useCallback(
      (e: React.FocusEvent<HTMLInputElement, Element>) => {
        handleFocus(e)
        setIsPercentFocused(true)
      },
      [handleFocus]
    )

    const handlePercentBlur = React.useCallback(() => {
      setIsPercentFocused(false)
      handleBlur()
    }, [handleBlur])

    const handleKeyDown = React.useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === 'Escape') {
          // @ts-ignore
          e.target.blur()
        }
      },
      []
    )

    useEffect(() => {
      return () => {
        if (isDirtyRef.current) {
          onEndChange()
        }
      }
    }, [])

    return (
      <div
        className={classnames(styles.container, {
          [styles['container--hovered']]: isHovered,
          [styles['container--focused']]: isFocused,
        })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div
          className={classnames(styles.left, {
            [styles['left--hovered']]: isHovered,
            [styles['left--focused']]: isFocused,
          })}
        >
          {previewEnabled && (
            <div className={styles.preview}>
              <button
                type="button"
                onClick={onPreviewClick}
                className="cursor-default"
                style={{
                  ...generateGradientStyle(colorStops),
                  width: '100%',
                  height: '100%',
                }}
              />
            </div>
          )}

          <input
            className={styles.input}
            type="text"
            value={title}
            onFocus={handleFocus}
            disabled={true}
          />
        </div>

        <div className={styles.right}>
          <input
            className={styles.input}
            type="text"
            value={
              isPercentFocused ? localOpacity : `${Math.round(opacity * 100)}%`
            }
            onChange={handleChangeOpacity}
            onFocus={handlePercentFocus}
            onBlur={handlePercentBlur}
            onKeyDown={handleKeyDown}
          />
        </div>
      </div>
    )
  }
)

InputGradient.displayName = 'InputGradient'
