import { binaryToJson } from '@aninix/core'
import { downloadUriAsBlob } from '@aninix/editor'
import { AnimationOptions, DotLottie } from '@dotlottie/dotlottie-js'
import pako from 'pako'

function removeFileExtension(filename: string) {
  return filename.replace(/\.(tgs|json|lottie)$/, '')
}

export const exportBinary = async (
  binary: Uint8Array,
  format: 'tgs' | 'jsonLottie' | 'dotLottie'
) => {
  const json = await binaryToJson(binary)

  switch (format) {
    case 'dotLottie':
      return exportDotLottie(json)
    case 'jsonLottie':
      return exportJsonLottie(json)
    case 'tgs':
      return exportTgs(json)
  }
}

function exportDotLottie(json: unknown) {
  const dotLottie = new DotLottie()
  dotLottie.addAnimation({ id: '1', data: json } as AnimationOptions)

  const fileName = removeFileExtension((json as any).nm) + '.lottie'

  dotLottie.download(fileName)
}

function exportJsonLottie(json: unknown) {
  var blob = new Blob([JSON.stringify(json)], { type: 'application/json' })

  const fileName = removeFileExtension((json as any).nm) + '.json'

  downloadUriAsBlob(blob, fileName)
}

function exportTgs(json: unknown) {
  const deflated = pako.deflate(JSON.stringify(json))

  var blob = new Blob([deflated])

  const fileName = removeFileExtension((json as any).nm) + '.tgs'

  downloadUriAsBlob(blob, fileName)
}
