import { httpClient } from '@aninix/api'
import { config } from '@aninix/api/config'
import { UseMutationResult, useMutation } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'

import { IComment, Rectangle } from './use-get-comments'

export interface ICommentPostNewComment {
  query: UseMutationResult<
    AxiosResponse<IComment, any>,
    unknown,
    ICommentPostPayload,
    unknown
  >
}

export interface ICommentPostPayload {
  comment: string
  rectangle?: Rectangle
  time?: number
}

const defaultRectangle: Rectangle = { x: 0, y: 0, width: 0, height: 0 }

export const usePostNewComment = (): ICommentPostNewComment => {
  const { projectId } = useParams()

  const query = useMutation({
    mutationKey: ['postProjectComment', projectId],
    mutationFn: ({
      comment,
      rectangle = defaultRectangle,
      time = 0,
    }: ICommentPostPayload) =>
      httpClient.post(
        `${config.apiUrl}/projects/${projectId}/comments`,
        { comment, rectangle, time },
        {
          responseType: 'json',
        }
      ),
  })

  return { query }
}
