import {
  DurationComponent,
  Root,
  setEndTime,
  TimeComponent,
} from '@aninix-inc/model'
import { buttons, icons, PropertyRowV2 } from '@aninix/app-design-system'
import { getAllKeyframes, useEntity, useProject } from '@aninix/core'
import { MAX_DURATION, MIN_DURATION } from '@aninix/editor/defaults'
import * as React from 'react'
import { TimeValue } from '../../values/time'

export const ProjectDuration: React.FC = () => {
  const root = useProject().getEntityByTypeOrThrow(Root)
  const component = root.getComponentOrThrow(DurationComponent)
  useEntity(root)
  return <ProjectDurationMemo component={component} />
}

ProjectDuration.displayName = 'ProjectDuration'

const ProjectDurationMemo: React.FC<{ component: DurationComponent }> =
  React.memo(
    ({ component }) => {
      const project = useProject()
      const root = project.getEntityByTypeOrThrow(Root)

      const fitDuration = React.useCallback(() => {
        const allKeyframes = getAllKeyframes(project)

        if (allKeyframes.length < 2) {
          return
        }

        const maxTime = allKeyframes.reduce(
          (max, keyframe) =>
            Math.max(max, keyframe.getComponentOrThrow(TimeComponent).value),
          -Infinity
        )
        setEndTime(root, maxTime)
      }, [project, root])

      return (
        <PropertyRowV2
          name="Project duration"
          inputs={
            <div className="flex w-full flex-col gap-[8px]">
              <TimeValue
                components={[component]}
                icon={<icons.Time />}
                min={MIN_DURATION}
                max={MAX_DURATION}
                dragDisabled
              />
              <buttons.Regular
                title="Fit to animation"
                onClick={fitDuration}
                variant="outlined"
                className="w-full"
              />
            </div>
          }
        />
      )
    },
    (prev, next) => prev.component.value === next.component.value
  )

ProjectDurationMemo.displayName = 'ProjectDurationMemo'
