import { Point2D } from '@aninix-inc/model'
import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export type Props = {
  color?: string
  size?: Point2D
}
export const ImportProjectFromAninix: React.FC<Props> = ({
  color = '#FFFFFF',
  size = defaultSize,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1762_6948)">
      <path
        d="M7.5 15H5.8C4.11984 15 3.27976 15 2.63803 14.673C2.07354 14.3854 1.6146 13.9265 1.32698 13.362C1 12.7202 1 11.8802 1 10.2V5.8C1 4.11984 1 3.27976 1.32698 2.63803C1.6146 2.07354 2.07354 1.6146 2.63803 1.32698C3.27976 1 4.11984 1 5.8 1H10.2C11.8802 1 12.7202 1 13.362 1.32698C13.9265 1.6146 14.3854 2.07354 14.673 2.63803C15 3.27976 15 4.11984 15 5.8V7.5"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 15L12.5 9M12.5 15L14.5 13M12.5 15L10.5 13"
        stroke={color}
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1762_6948">
        <rect width="16" height="16" fill={color} />
      </clipPath>
    </defs>
  </svg>
)
