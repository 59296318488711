import { RGBA } from '@aninix-inc/model/legacy'
import React, { useState } from 'react'
import tinycolor from 'tinycolor2'

import { Option, Select } from '../Select'
import { AlphaPicker } from '../alpha-picker'
import { EyeDropper } from '../eye-dropper'
import { GradientSlider } from '../gradient-slider'
import { ColorStop } from '../gradient-slider/types'
import { HuePicker } from '../hue-picker'
import { InputHex } from '../input-hex'
import { InputRgba } from '../input-rgba'
import { SaturationLightnessPicker } from '../saturation-lightness-picker'
import * as styles from './index.scss'

type TColorModel = 'rgba' | 'hex'

type TColorModelOptions = {
  id: string
  title: string
}

// @TODO: enable multiple text fields to change colors
const colorModelOptions: TColorModelOptions[] = [
  {
    id: 'rgba',
    title: 'RGB',
  },
  // {
  //   id: 'hex',
  //   title: 'Hex',
  // },
]

export interface IProps {
  selectedColorStopId?: string
  onSelect: (id: string) => void
  colorStops: ColorStop[]
  onStartChange: () => void
  onEndChange: () => void
  onChange: (
    colorStops?: (
      | ColorStop
      | { id: undefined; value: undefined; progress: number }
    )[],
    opacity?: number
  ) => void
}
export const GradientColorPicker: React.FCC<IProps> = ({
  onStartChange,
  onEndChange,
  onChange,
  colorStops,
  selectedColorStopId,
  onSelect,
}) => {
  const [selectedColorModel, setSelectedColorModel] =
    useState<TColorModel>('rgba')
  const activeSelectOption = colorModelOptions.find(
    (option) => option.id == selectedColorModel
  ) as Option

  const color = React.useMemo(
    () =>
      colorStops.find((e) => e.id === selectedColorStopId)?.value ?? {
        r: 0,
        g: 0,
        b: 0,
        a: 0,
      },
    [colorStops, selectedColorStopId]
  )

  const [hue, setHue] = React.useState(tinycolor(color).toHsv().h)

  const handleChange = React.useCallback(
    (newColor: RGBA) => {
      const newColorStops = [...colorStops]
      newColorStops.find((cs) => cs.id === selectedColorStopId)!.value =
        newColor
      onChange(newColorStops)
    },
    [colorStops, onChange, selectedColorStopId]
  )

  const handleChangeProgress = React.useCallback(
    (progress: number) => {
      const newColorStops = [...colorStops]
      newColorStops.find((cs) => cs.id === selectedColorStopId)!.progress =
        progress
      onChange(newColorStops)
    },
    [colorStops, onChange, selectedColorStopId]
  )

  const handleChangeHue = React.useCallback(
    (newHue: number) => {
      const hsv = tinycolor(color).toHsv()
      const newColor = tinycolor({
        h: newHue,
        s: hsv.s,
        v: hsv.v,
      }).toRgb()
      handleChange({ ...newColor, a: color.a })
      setHue(newHue)
    },
    [colorStops, color, handleChange]
  )

  const handleChangeAlpha = React.useCallback(
    (newAlpha: number) => {
      handleChange({ ...color, a: newAlpha })
    },
    [colorStops, color, handleChange]
  )

  React.useEffect(() => {
    const hsv = tinycolor(color).toHsv()

    if (hsv.s === 0 || hsv.v === 0) {
      return
    }

    setHue(hsv.h)
  }, [colorStops, color])

  return (
    <div className={styles.wrapper}>
      <div className="flex content-center justify-center py-2">
        <GradientSlider
          selectedColorStopId={selectedColorStopId}
          colorStops={colorStops}
          onColorStopCreation={(progress: number) =>
            onChange([
              ...colorStops,
              { progress, id: undefined, value: undefined },
            ])
          }
          onChangeProgress={handleChangeProgress}
          onSelect={onSelect}
        />
      </div>
      <SaturationLightnessPicker
        hue={hue}
        color={color}
        startChange={onStartChange}
        endChange={onEndChange}
        onChange={handleChange}
      />

      <div className={styles['bottom-wrapper']}>
        <div className={styles['controls-wrapper']}>
          <div className={styles['button-wrapper']}>
            <EyeDropper onChange={handleChange} />
          </div>

          <div className={styles['slider-wrapper']}>
            <HuePicker
              hue={hue}
              onStartChange={onStartChange}
              onEndChange={onEndChange}
              onChange={handleChangeHue}
              size={168}
            />
            <AlphaPicker
              hue={hue}
              alpha={color.a}
              onStartChange={onStartChange}
              onEndChange={onEndChange}
              onChange={handleChangeAlpha}
              size={168}
            />
          </div>
        </div>

        <div className={styles['color-model-wrapper']}>
          <Select
            options={colorModelOptions}
            activeValueId={activeSelectOption.id}
            onChange={(value) => setSelectedColorModel(value as TColorModel)}
            disabled={colorModelOptions.length === 1}
          />

          {selectedColorModel === 'rgba' && (
            <InputRgba color={color} onChange={handleChange} />
          )}

          {selectedColorModel === 'hex' && (
            <InputHex color={color} onChange={handleChange} />
          )}
        </div>
      </div>
    </div>
  )
}

GradientColorPicker.displayName = 'GradientColorPicker'
