import { Point2D } from '@aninix-inc/model/legacy'
import { buttons, Tooltip } from '@aninix/app-design-system'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'

import {
  none,
  Preset,
  PresetAnimation,
  PresetAnimationId,
  PresetSnapshot,
  PresetSpeedType,
  PresetTimingCurveType,
  PresetType,
} from '../../models/preset'
import {
  Align,
  Blur,
  Direction,
  EffectTranslate,
  Fade,
  InInbox,
  InOutbox,
  OutInbox,
  OutOutbox,
  Rotate,
  Scale,
  Shadow,
  Speed,
  TimingCurve,
} from './icons'
import * as styles from './index.scss'

const defaultSize = {
  x: 16,
  y: 16,
}

const speedTypes = R.values(Preset.Speed)
const timingCurveTypes = R.values(Preset.TimingCurve)

const mapTitles: Record<PresetAnimationId, string> = {
  [PresetAnimationId.AppearanceAlignmentInBox]: 'Align in the box',
  [PresetAnimationId.AppearanceAlignmentOutBox]: 'Align out of the box',
  [PresetAnimationId.AppearanceLayerBlur]: 'Blur',
  [PresetAnimationId.AppearanceDirection]: 'Direction',
  [PresetAnimationId.AppearanceFade]: 'Fade',
  [PresetAnimationId.AppearanceRotation]: 'Rotate',
  [PresetAnimationId.AppearanceScale]: 'Scale',
  [PresetAnimationId.AppearanceShadow]: 'Shadow',
  [PresetAnimationId.AppearanceTrimPath]: 'Trim Path',
  [PresetAnimationId.EffectTranslate]: 'Translate',
  [PresetAnimationId.EffectRotation]: 'Rotate',
  [PresetAnimationId.EffectScale]: 'Scale',
}

const speedTooltips = {
  [Preset.Speed.Slow]: 'Slow',
  [Preset.Speed.Medium]: 'Medium',
  [Preset.Speed.Fast]: 'Fast',
}

const timingCurveTooltips = {
  [Preset.TimingCurve.Eager]: 'Eager',
  [Preset.TimingCurve.Gentle]: 'Gentle',
  [Preset.TimingCurve.Bounce]: 'Bounce',
}

const mapTooltips: Record<PresetAnimationId, Record<string, string>> = {
  [PresetAnimation.Id.AppearanceAlignmentInBox]: {
    [PresetAnimation.Types.AppearanceAlignmentInBox.Top]: 'Top',
    [PresetAnimation.Types.AppearanceAlignmentInBox.Right]: 'Right',
    [PresetAnimation.Types.AppearanceAlignmentInBox.Bottom]: 'Bottom',
    [PresetAnimation.Types.AppearanceAlignmentInBox.Left]: 'Left',
    [PresetAnimation.Types.AppearanceAlignmentInBox.Center]: 'Center',
    [PresetAnimation.Types.AppearanceAlignmentInBox.None]: 'None',
  },
  [PresetAnimation.Id.AppearanceAlignmentOutBox]: {
    [PresetAnimation.Types.AppearanceAlignmentOutBox.Top]: 'Top',
    [PresetAnimation.Types.AppearanceAlignmentOutBox.Right]: 'Right',
    [PresetAnimation.Types.AppearanceAlignmentOutBox.Bottom]: 'Bottom',
    [PresetAnimation.Types.AppearanceAlignmentOutBox.Left]: 'Left',
    [PresetAnimation.Types.AppearanceAlignmentOutBox.None]: 'None',
  },
  [PresetAnimation.Id.AppearanceLayerBlur]: {
    [PresetAnimation.Types.AppearanceLayerBlur.Layer]: 'Layer Blur',
    // [PresetAnimation.Types.AppearanceLayerBlur.Background]: 'Background Blur',
    [PresetAnimation.Types.AppearanceLayerBlur.None]: 'No Blur',
  },
  [PresetAnimation.Id.AppearanceDirection]: {
    [PresetAnimation.Types.AppearanceDirection.Up]: 'Up',
    [PresetAnimation.Types.AppearanceDirection.Right]: 'Right',
    [PresetAnimation.Types.AppearanceDirection.Down]: 'Down',
    [PresetAnimation.Types.AppearanceDirection.Left]: 'Left',
    [PresetAnimation.Types.AppearanceDirection.None]: 'None',
  },
  [PresetAnimation.Id.AppearanceRotation]: {
    [PresetAnimation.Types.AppearanceRotation.Clockwise]: 'Clockwise',
    [PresetAnimation.Types.AppearanceRotation.CounterClockwise]:
      'Counter Clockwise',
    [PresetAnimation.Types.AppearanceRotation.None]: 'None',
  },
  [PresetAnimation.Id.AppearanceFade]: {
    [PresetAnimation.Types.AppearanceFade.Fade]: 'Fade',
    [PresetAnimation.Types.AppearanceFade.None]: 'None',
  },
  [PresetAnimation.Id.AppearanceScale]: {
    [PresetAnimation.Types.AppearanceScale.Vertical]: 'Vertical',
    [PresetAnimation.Types.AppearanceScale.Horizontal]: 'Horizontal',
    [PresetAnimation.Types.AppearanceScale.Both]: 'Both',
    [PresetAnimation.Types.AppearanceScale.None]: 'None',
  },
  [PresetAnimation.Id.AppearanceShadow]: {
    [PresetAnimation.Types.AppearanceShadow.Drop]: 'Drop Shadow',
    [PresetAnimation.Types.AppearanceShadow.Inner]: 'Inner Shadow',
    [PresetAnimation.Types.AppearanceShadow.None]: 'No Shadow',
  },
  [PresetAnimation.Id.AppearanceTrimPath]: {
    [PresetAnimation.Types.AppearanceTrimPath.Clockwise]: 'Clockwise',
    [PresetAnimation.Types.AppearanceTrimPath.CounterClockwise]:
      'Counter Clockwise',
    [PresetAnimation.Types.AppearanceTrimPath.None]: 'None',
  },
  [PresetAnimation.Id.EffectTranslate]: {
    [PresetAnimation.Types.EffectTranslate.Vertical]: 'Vertical',
    [PresetAnimation.Types.EffectTranslate.Horizontal]: 'Horizontal',
    [PresetAnimation.Types.EffectTranslate.None]: 'None',
  },
  [PresetAnimation.Id.EffectRotation]: {
    [PresetAnimation.Types.EffectRotation.Clockwise]: 'Clockwise',
    [PresetAnimation.Types.EffectRotation.CounterClockwise]:
      'Counter Clockwise',
    [PresetAnimation.Types.EffectRotation.None]: 'None',
  },
  [PresetAnimation.Id.EffectScale]: {
    [PresetAnimation.Types.EffectScale.Both]: 'Both',
    [PresetAnimation.Types.EffectScale.None]: 'None',
  },
}

const mapTypes: Record<PresetAnimationId, string[]> = {
  [PresetAnimation.Id.AppearanceAlignmentInBox]: R.values(
    PresetAnimation.Types.AppearanceAlignmentInBox
  ),
  [PresetAnimation.Id.AppearanceAlignmentOutBox]: R.values(
    PresetAnimation.Types.AppearanceAlignmentOutBox
  ),
  [PresetAnimation.Id.AppearanceLayerBlur]: R.values(
    PresetAnimation.Types.AppearanceLayerBlur
  ),
  [PresetAnimation.Id.AppearanceDirection]: R.values(
    PresetAnimation.Types.AppearanceDirection
  ),
  [PresetAnimation.Id.AppearanceFade]: R.values(
    PresetAnimation.Types.AppearanceFade
  ),
  [PresetAnimation.Id.AppearanceRotation]: R.values(
    PresetAnimation.Types.AppearanceRotation
  ),
  [PresetAnimation.Id.AppearanceScale]: R.values(
    PresetAnimation.Types.AppearanceScale
  ),
  [PresetAnimation.Id.AppearanceShadow]: R.values(
    PresetAnimation.Types.AppearanceShadow
  ),
  [PresetAnimation.Id.AppearanceTrimPath]: R.values(
    PresetAnimation.Types.AppearanceTrimPath
  ),
  [PresetAnimation.Id.EffectTranslate]: R.values(
    PresetAnimation.Types.EffectTranslate
  ),
  [PresetAnimation.Id.EffectRotation]: R.values(
    PresetAnimation.Types.EffectRotation
  ),
  [PresetAnimation.Id.EffectScale]: R.values(PresetAnimation.Types.EffectScale),
}

const mapIcons: Record<
  PresetAnimationId,
  Record<PresetType, React.FCC<{ type: any; size?: Point2D }>>
> = {
  [PresetAnimation.Id.AppearanceAlignmentInBox]: {
    [PresetType.In]: InInbox,
    [PresetType.Out]: OutInbox,
    [PresetType.Effect]: InInbox,
  },
  [PresetAnimation.Id.AppearanceAlignmentOutBox]: {
    [PresetType.In]: InOutbox,
    [PresetType.Out]: OutOutbox,
    [PresetType.Effect]: Align,
  },
  [PresetAnimation.Id.AppearanceLayerBlur]: {
    [PresetType.In]: Blur,
    [PresetType.Out]: Blur,
    [PresetType.Effect]: Blur,
  },
  [PresetAnimation.Id.AppearanceDirection]: {
    [PresetType.In]: Direction,
    [PresetType.Out]: Direction,
    [PresetType.Effect]: Direction,
  },
  [PresetAnimation.Id.AppearanceFade]: {
    [PresetType.In]: Fade,
    [PresetType.Out]: Fade,
    [PresetType.Effect]: Fade,
  },
  [PresetAnimation.Id.AppearanceRotation]: {
    [PresetType.In]: Rotate,
    [PresetType.Out]: Rotate,
    [PresetType.Effect]: Rotate,
  },
  [PresetAnimation.Id.AppearanceScale]: {
    [PresetType.In]: Scale,
    [PresetType.Out]: Scale,
    [PresetType.Effect]: Scale,
  },
  [PresetAnimation.Id.AppearanceShadow]: {
    [PresetType.In]: Shadow,
    [PresetType.Out]: Shadow,
    [PresetType.Effect]: Shadow,
  },
  [PresetAnimation.Id.AppearanceTrimPath]: {
    [PresetType.In]: Rotate,
    [PresetType.Out]: Rotate,
    [PresetType.Effect]: Rotate,
  },
  [PresetAnimation.Id.EffectTranslate]: {
    [PresetType.In]: EffectTranslate,
    [PresetType.Out]: EffectTranslate,
    [PresetType.Effect]: EffectTranslate,
  },
  [PresetAnimation.Id.EffectRotation]: {
    [PresetType.In]: Rotate,
    [PresetType.Out]: Rotate,
    [PresetType.Effect]: Rotate,
  },
  [PresetAnimation.Id.EffectScale]: {
    [PresetType.In]: Scale,
    [PresetType.Out]: Scale,
    [PresetType.Effect]: Scale,
  },
}

interface IPresetItemProps {
  id: string
  title: string
  activeType: string
  types: string[]
  onClick: (id: string, type: string) => void
  Icon: React.FCC<{ type: any; size?: Point2D }>
  tooltip: Record<string, string>
}

const PresetItemComponent: React.FCC<IPresetItemProps> = ({
  id,
  title,
  types,
  activeType,
  onClick,
  Icon,
  tooltip,
}) => {
  const activeTypeRef = React.useRef(activeType)

  const handleClick = React.useCallback(
    (type: string) => {
      onClick(id, type)
      activeTypeRef.current = type
    },
    [id, onClick]
  )

  return (
    <div className={styles.row}>
      <p className={styles.row__title}>{title}</p>

      <div className={styles.row__buttons}>
        {types.map((type) => (
          <Tooltip
            key={type}
            title={tooltip[type]}
            arrow
            color="white"
            tooltipClassName={styles.tooltip__container}
            arrowClassName={styles.tooltip__arrow}
          >
            <button
              onClick={() => handleClick(type)}
              className={classNames(styles.row__button, {
                [styles['row__button--selected']]: type === activeType,
              })}
            >
              <Icon type={type} size={defaultSize} />
            </button>
          </Tooltip>
        ))}
      </div>
    </div>
  )
}

export interface IProps {
  preset: PresetSnapshot
  onSpeedChange: (type: PresetSpeedType) => void
  onTimingCurveChange: (type: PresetTimingCurveType) => void
  onItemChange: (id: PresetAnimationId, type: string) => void
  onApply: () => void
  onReset: () => void
  resetAllowed: boolean
  applyButtonTitle?: string
  applyButtonStyle?: 'blue' | 'green'
  isPro?: boolean
}
export const PresetCustomizer: React.FCC<IProps> = ({
  preset,
  onSpeedChange,
  onTimingCurveChange,
  onItemChange,
  onApply,
  onReset,
  resetAllowed,
  applyButtonTitle = 'Apply',
  applyButtonStyle = 'blue',
}) => {
  const handleChange = React.useCallback(
    (id: string, type: any) => {
      if (id === 'speed') {
        onSpeedChange(type)
        return
      }

      if (id === 'timingCurve') {
        onTimingCurveChange(type)
        return
      }

      onItemChange(id as any, type)
    },
    [onSpeedChange, onTimingCurveChange, onItemChange]
  )

  const handleApply = React.useCallback(() => {
    onApply()
  }, [onApply])

  return (
    <div className={styles.container}>
      <svg
        width="17"
        height="8"
        viewBox="0 0 17 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={styles.container__arrow}
      >
        <path
          d="M6.43722 2.26274L0.699951 8L16.7 8L10.9627 2.26274L10.9627 2.26274C10.1707 1.47071 9.77465 1.07469 9.31799 0.926314C8.9163 0.795798 8.48361 0.795797 8.08192 0.926313C7.62527 1.07469 7.22925 1.4707 6.43722 2.26273L6.43722 2.26274Z"
          className={styles['container__arrow-path']}
        />
      </svg>

      <div className={styles.row}>
        <p className={styles.title}>{preset.title}</p>
      </div>

      {preset.speed != null && (
        <PresetItemComponent
          id="speed"
          title="Speed"
          types={speedTypes}
          activeType={preset.speed}
          onClick={handleChange}
          Icon={Speed}
          tooltip={speedTooltips}
        />
      )}

      {preset.timingCurve != null && (
        <PresetItemComponent
          id="timingCurve"
          title="Timnig Curve"
          types={timingCurveTypes}
          activeType={preset.timingCurve}
          onClick={handleChange}
          Icon={TimingCurve}
          tooltip={timingCurveTooltips}
        />
      )}

      {preset.requiredAnimations.length > 0 && (
        <>
          {(preset.speed != null || preset.timingCurve != null) && (
            <div className={styles.separator} />
          )}

          {preset.requiredAnimations.map((item) => (
            <PresetItemComponent
              key={item.id}
              id={item.id}
              title={mapTitles[item.id]}
              types={R.without([none], mapTypes[item.id])}
              activeType={item.type}
              onClick={handleChange}
              Icon={mapIcons[item.id][preset.type]}
              tooltip={mapTooltips[item.id]}
            />
          ))}
        </>
      )}

      {preset.optionalAnimations.length > 0 && (
        <>
          <div className={styles.separator} />

          {preset.optionalAnimations.map((item) => (
            <PresetItemComponent
              key={item.id}
              id={item.id}
              title={mapTitles[item.id]}
              types={mapTypes[item.id]}
              activeType={item.type}
              onClick={handleChange}
              Icon={mapIcons[item.id][preset.type]}
              tooltip={mapTooltips[item.id]}
            />
          ))}
        </>
      )}

      <div className={styles.cta__container}>
        <buttons.Regular
          variant="contained"
          title={applyButtonTitle}
          onClick={handleApply}
          className={styles.cta__button}
          success={applyButtonStyle === 'green'}
        />

        {resetAllowed && (
          <buttons.Regular
            variant="text"
            title="Reset settings to default"
            onClick={onReset}
            className={classNames(styles.cta__button, styles.cta__reset)}
          />
        )}
      </div>
    </div>
  )
}
