import * as React from 'react'

import { Notifications } from './notifications'

const Context = React.createContext<Notifications>({} as any)

export const useNotifications = (): Notifications => React.useContext(Context)

export const NotificationsProvider: React.FCC<{ client: Notifications }> = ({
  client,
  children,
}) => <Context.Provider value={client}>{children}</Context.Provider>
