import {
  CurrentFolderContext,
  CurrentFolderContextTypes,
  deleteProject,
  useGetFolders,
  useGetProjects,
  useLinkProjectToFolder,
} from '@aninix/api'
import { UserRole } from '@aninix/core'
import classNames from 'classnames'
import _ from 'lodash'
import * as React from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useNavigate, useParams } from 'react-router-dom'
import { usePageOutletContext } from '../../modules/teams'
import { toast } from '../../modules/toasts'
import {
  useCurrentSpaceStore,
  useCurrentTeamStore,
  useCurrentUser,
} from '../../use-cases'
import { Typography } from '../design-system/typography'
import { FolderEmptyState } from '../folder-empty-state'
import { Icons } from '../icons'
import { LoadableChildrenWrapper } from '../loadable-wrapper'
import { SystemStatusBanner } from '../system-status'
import { Breadcrumbs } from './breadcrumbs'
import { CreateNewFolderListItem } from './create-new-folder-list-item'
import { Delete } from './delete'
import { FolderItem, FolderItemPlaceholder } from './folder-item'
import { MoveTo } from './move-to'
import { ProjectItem, ProjectItemPlaceholder } from './project-item'

function isInteractiveElement(element: HTMLElement): boolean {
  return (
    element instanceof HTMLInputElement ||
    element instanceof HTMLButtonElement ||
    element instanceof HTMLSelectElement ||
    element instanceof HTMLTextAreaElement ||
    element instanceof HTMLAnchorElement
    // Add more if needed
  )
}

const isInteractiveElementSelector = [
  'input',
  'button',
  'select',
  'textarea',
  'a',
].join(',')

export interface IProps {
  page: 'my' | 'teams'
}

export const ProjectsList: React.FCC<IProps> = ({ page }: IProps) => {
  const { folderId } = useParams()
  const { userId } = useCurrentUser()
  const { teamId } = useParams()
  const { currentUser } = useCurrentSpaceStore()

  const pageOutletContext = usePageOutletContext()

  const teamUser = React.useMemo(
    () => currentUser?.teams.find((e) => e.id === teamId),
    [currentUser, teamId]
  )

  const canCreateFolder = React.useMemo(() => {
    return ['editor'].includes(teamUser?.aninixRole ?? 'viewer')
  }, [teamUser, userId, currentUser?.id])

  const [updatedProjectsAfterLastFetch, setUpdatedProjectsAfterLastFetch] =
    React.useState<any[]>([])

  const [skip, setSkip] = React.useState(0)
  const limit = 50

  const payload = (() => {
    switch (page) {
      case 'teams':
        return { teamId, folderId, skip, limit }
      case 'my':
        return { userId, folderId, skip, limit }
    }
  })()

  const getFoldersRequest = useGetFolders(payload)

  /* PAGINATED PROJECTS REQUESTING START */

  //  paginated request
  //
  //    query key knows nothing about payload "skip" and "limit", so all requests with differenent such values
  //    will not trigger full reload, but only update inside paginated request
  //
  //  this is required to make comparisons between structures and handle state inside query cache

  //please look in detail how useGetProjects works, important for understanding what's going on here
  const getProjectsRequest = useGetProjects({
    ...payload,
    updatedProjectsAfterLastFetch,
  })

  //request to check updated projects since last request
  const getUpdatedProjects = useGetProjects({
    ...payload,
    skip: 0,
    limit: 100,
    updatedAfter: new Date(getProjectsRequest.dataUpdatedAt).toISOString(),
  })

  //refetch callback that been used after operations like move, delete, rename
  const refetchFolder = React.useCallback(() => {
    if (skip === 0) {
      // @NOTE: it was deprecated in the new version of react-query.
      // @TODO: remove the following line once we tested everything.
      // getProjectsRequest.remove()
      getProjectsRequest.refetch()
    }
    getUpdatedProjects.refetch().then((result) => {
      //will trigger useEffect to update folders, to ensure the folder will have all the fresh data
      //and we don't refetch projects directly, since they are paginated and it's hard to predict where project will be in pages
      setUpdatedProjectsAfterLastFetch(result.data?.data ?? [])
    })
    getFoldersRequest.refetch()
  }, [skip])

  React.useEffect(() => {
    //required for focus change. also triggers useEffect with refetching paginated request
    if (getUpdatedProjects.isFetched && getUpdatedProjects.data?.data)
      setUpdatedProjectsAfterLastFetch(getUpdatedProjects.data.data)
  }, [getUpdatedProjects.isRefetching])

  React.useEffect(() => {
    //if there's something to include from updated data, forse refetch paginated projects
    //if not, tanstack will refetch it anyway
    if (updatedProjectsAfterLastFetch.length > 0) getProjectsRequest.refetch()
  }, [updatedProjectsAfterLastFetch])

  /* PAGINATED PROJECTS REQUESTING END */

  const folders = getFoldersRequest?.data?.data ?? []
  //front sorting is not an option anymore due to pagination
  const projects = getProjectsRequest?.data?.data ?? []
  // .sort(
  //   (a, b) =>
  //     parseISO(b?.updatedAt ?? '').getTime() -
  //     parseISO(a?.updatedAt ?? '').getTime()
  // )

  const [draggedProjectId, setDraggedProjectId] =
    React.useState<CurrentFolderContextTypes['ProjectId']>(undefined)
  const [draggedFolderOverId, setDraggedFolderOverId] =
    React.useState<CurrentFolderContextTypes['FolderId']>(undefined)

  const [selectedProjectsIds, setSelectedProjectsIds] = React.useState<
    Set<string> | undefined
  >(new Set([]))

  const [selectedFoldersIds, setSelectedFoldersIds] = React.useState<
    Set<string> | undefined
  >(new Set([]))

  useHotkeys(
    'esc',
    () => {
      setSelectedProjectsIds(new Set([]))
      setSelectedFoldersIds(new Set([]))
    },
    [setSelectedProjectsIds]
  )

  useHotkeys(
    'del,backspace',
    () => {
      setShowModal('delete')
    },
    [setSelectedProjectsIds]
  )

  const [currentMousePosition, setCurrentMousePosition] =
    React.useState<CurrentFolderContextTypes['MousePosition']>(null)

  React.useEffect(() => {
    if (page === 'teams') {
      pageOutletContext.setTitle('Team projects')
      pageOutletContext.setControls('CreateProject')
    }
    if (page === 'my') {
      pageOutletContext.setTitle('My projects')
      pageOutletContext.setControls('CreateProject')
    }

    pageOutletContext.setRefetchCurrentFolder(() => refetchFolder)
  }, [canCreateFolder, page, folders, projects])

  const sidePanelWidth = 250,
    maximumItemWidth = 300

  const getCurrentBodyWidthFraction = () =>
    Math.max(
      Math.floor(
        (document.body.getBoundingClientRect().width - sidePanelWidth) /
          maximumItemWidth
      ),
      2
    )

  const [repeatCount, setRepeatCount] = React.useState(
    getCurrentBodyWidthFraction()
  )

  const handleResize = React.useCallback(() => {
    setRepeatCount(getCurrentBodyWidthFraction())
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])

  const linkProjectToFolder = useLinkProjectToFolder()

  const navigate = useNavigate()

  const moveSelectedProjectsTo = React.useCallback(
    (folderId?: string) => {
      if (!selectedProjectsIds) return

      const promises = Promise.all(
        [...selectedProjectsIds]
          .filter((e) => e !== undefined)
          .map((projectId) => {
            return linkProjectToFolder.mutateAsync({ projectId, folderId })
          })
      )
      promises.then((value) => {
        setShowModal('none')
        refetchFolder()

        setSelectedProjectsIds?.(new Set([]))

        toast('Projects moved successfully. Click to open the folder', {
          variant: 'info',
          toastProps: {
            icon: <Icons.Folder />,
            onClick: () =>
              navigate('../projects' + (folderId ? `/${folderId}` : '')),
          },
        })
      })
    },
    [selectedProjectsIds]
  )

  const [showModal, setShowModal] = React.useState<
    'delete' | 'moveto' | 'none'
  >('none')
  const handleModalClose = React.useCallback(() => {
    setShowModal('none')
  }, [])

  const { role } = useCurrentUser()
  const { currentTeam } = useCurrentTeamStore()

  const isPro = React.useMemo(
    () =>
      currentTeam != null ? currentTeam.plan === 'pro' : role === UserRole.Pro,
    [currentTeam, role]
  )

  const deleteSelectedProjects = React.useCallback(async () => {
    const promises = Promise.all(
      [...(selectedProjectsIds ?? [])].map((id) =>
        deleteProject({ projectId: id })
      )
    )

    promises
      .then(() => {
        setShowModal('none')
        setSelectedProjectsIds(new Set([]))
        refetchFolder()
        toast('Project moved to trash', {
          variant: 'info',
        })
      })
      .catch(() => {
        toast('Project deleting failed', {
          variant: 'error',
        })
      })
  }, [deleteProject, selectedProjectsIds])

  const handleDeselect = React.useCallback(
    (e: MouseEvent): any => {
      // @NOTE: required to prevent deselection when user clicks on buttons
      const target = e.target as HTMLElement

      // Check if the clicked element or any of its ancestors are interactive elements
      const isInteractable =
        isInteractiveElement(target) ||
        !!target.closest(isInteractiveElementSelector)

      if (isInteractable) {
        return
      }

      //there's no need to check anything, since project items has disabled propagation onclick
      e.preventDefault()

      setSelectedProjectsIds?.(new Set([]))
    },
    [setSelectedProjectsIds]
  )

  React.useEffect(() => {
    document.body.addEventListener('click', handleDeselect)

    return () => {
      document.body.removeEventListener('click', handleDeselect)
    }
  }, [handleDeselect])

  /* PAGINATED PROJECTS INTERACTIONS START */

  //used for understanding what amount of projects has been fetched
  const [lastFetchedSkip, setLastFetchedSkip] = React.useState(0)

  //used to avoid refetching when all the projects has been loaded
  const [maxSkip, setMaxSkip] = React.useState(limit)

  //will trigger refetch when bottom is 2 screen heights away
  //will not trigger refetch if maxSkip is reached or if there's ongoing refetch
  //only updates state, bussiness logic in useEffects
  const handleScroll = React.useCallback(
    (e: Event) => {
      if (pageOutletContext.scrollRef?.current) {
        const element = pageOutletContext.scrollRef.current
        const windowHeight = window.innerHeight
        const bottomPosition = element.scrollHeight - element.scrollTop

        if (
          bottomPosition - windowHeight * 2 <= windowHeight &&
          !getProjectsRequest.isRefetching
        ) {
          setSkip(Math.min(lastFetchedSkip + limit, maxSkip))
        }
      }
    },
    [pageOutletContext.scrollRef, getProjectsRequest, lastFetchedSkip, maxSkip]
  )

  //required to better folder navigation
  React.useEffect(() => {
    if (getProjectsRequest.data?.config.params.skip !== 0) {
      // @NOTE: it was deprecated in the new version of react-query.
      // @TODO: remove the following line once we tested everything.
      // getProjectsRequest.remove()
    }
    setSkip(0)
    setMaxSkip(limit)
    setSelectedProjectsIds(new Set([]))
    setSelectedFoldersIds(new Set([]))
  }, [folderId])

  //stop skipping if there's less projects are then page size
  React.useEffect(() => {
    if (projects.length < limit) setMaxSkip(0)
  }, [projects.length])

  React.useEffect(() => {
    getProjectsRequest.refetch().then((request) => {
      //definition of next skip amount. it is previously requested amount
      setLastFetchedSkip(payload.skip)
      //max skip is retrieved amount of projects (eventually we will stop requesting since amount of projects will stop increasing)
      setMaxSkip((value) => request.data?.data.length ?? value)
    })
  }, [skip])

  //handling scroll
  React.useEffect(() => {
    if (pageOutletContext.scrollRef?.current)
      pageOutletContext.scrollRef.current.addEventListener(
        'scroll',
        handleScroll
      )

    return () => {
      if (pageOutletContext.scrollRef?.current)
        pageOutletContext.scrollRef.current.removeEventListener(
          'scroll',
          handleScroll
        )
    }
  }, [pageOutletContext.scrollRef, handleScroll, getProjectsRequest])

  /* PAGINATED PROJECTS INTERACTIONS END */

  const deleteSelected = React.useCallback(() => {
    setShowModal('delete')
  }, [])
  const moveSelected = React.useCallback(() => {
    setShowModal('moveto')
  }, [])

  return (
    <CurrentFolderContext.Provider
      value={{
        folder: {
          refetch: refetchFolder,
          data: {
            folders,
            projects,
          },
        },
        payload,
        draggedProjectId,
        setDraggedProjectId,
        draggedFolderOverId,
        setDraggedFolderOverId,

        selectedProjectsIds,
        setSelectedProjectsIds,

        selectedFoldersIds,
        setSelectedFoldersIds,

        currentMousePosition,
        setCurrentMousePosition,

        deleteSelected,
        moveSelected,
      }}
    >
      <div className="flex flex-col gap-6">
        <SystemStatusBanner />
        {!(
          folders?.length + projects?.length === 0 && folderId === undefined
        ) && (
          <div className="flex flex-col gap-6">
            <Breadcrumbs />
          </div>
        )}
        <LoadableChildrenWrapper
          isLoading={
            getFoldersRequest.isLoading || getProjectsRequest.isLoading
          }
          isError={getFoldersRequest.isError || getProjectsRequest.isError}
          loadingComponent={
            <>
              <div className="flex h-full flex-col gap-4">
                <div className="flex flex-col gap-4">
                  <div
                    style={
                      {
                        '--repeat': `repeat(${repeatCount},minmax(0,1fr))`,
                      } as React.CSSProperties
                    }
                    className="grid grid-cols-[--repeat] gap-4"
                  >
                    {_.range(repeatCount * 2).map((f, i) => (
                      <FolderItemPlaceholder key={i} />
                    ))}
                  </div>

                  <div className="grid grid-cols-3 grid-rows-none gap-4 2xl:grid-cols-4 3xl:grid-cols-5">
                    {_.range(repeatCount * 2).map((f, i) => (
                      <ProjectItemPlaceholder key={i} />
                    ))}
                  </div>
                </div>
              </div>
            </>
          }
        >
          <MoveTo
            handleClose={handleModalClose}
            isOpen={showModal == 'moveto'}
            handleMoveTo={moveSelectedProjectsTo}
          />
          <Delete
            header={'Delete project'}
            callToAction={'Delete'}
            Messsage={
              <>
                <Typography style="Body4Regular">
                  Are you sure you want to delete {selectedProjectsIds?.size}{' '}
                  projects?
                </Typography>

                <Typography style="Body5Regular" className="text-gray-500">
                  {isPro
                    ? 'Deleted projects are available in the "Trash" folder for 30 days, before they are permanently deleted.'
                    : 'You can’t undo this action'}
                </Typography>
              </>
            }
            handleClose={handleModalClose}
            isOpen={showModal == 'delete'}
            handleDelete={deleteSelectedProjects}
          />

          {/* One project or one folder selected */}
          <div
            className={classNames(
              'absolute bottom-0 left-0 right-0 z-50 flex flex-row justify-center overflow-hidden pb-6',
              {
                ['pointer-events-none']:
                  selectedProjectsIds?.size !== 1 ||
                  selectedFoldersIds?.size !== 1,
              }
            )}
          >
            <div
              className={classNames(
                'flex flex-row items-center gap-4 rounded-xl bg-secondary px-4 py-3 transition-all duration-200',
                {
                  ['translate-y-[100px] opacity-0']:
                    selectedProjectsIds?.size !== 1 &&
                    selectedFoldersIds?.size !== 1,
                }
              )}
            >
              <p className="font-body text-base text-white">
                Hint: double-click to open the{' '}
                {selectedProjectsIds?.size === 1 ? 'project' : 'folder'}
              </p>
              <div className="flex flex-row gap-2">
                {selectedProjectsIds !== undefined && (
                  <button
                    onClick={() => {
                      window.open(
                        `/edit/${[...selectedProjectsIds][0]}`,
                        '_blank'
                      )
                    }}
                    className="flex flex-row items-center gap-2 rounded-lg border border-[#5C6574] bg-secondary px-3 py-2 transition-all duration-500 hover:scale-[1.02] hover:shadow-xl"
                  >
                    <p className="font-body text-sm font-medium text-white">
                      Open
                    </p>
                  </button>
                )}
              </div>
            </div>
          </div>

          {/* Multiple projects selected */}
          <div
            className={classNames(
              'absolute bottom-0 left-0 right-0 z-50 flex flex-row justify-center overflow-hidden pb-6',
              {
                ['pointer-events-none']: (selectedProjectsIds?.size ?? 0) < 2,
              }
            )}
          >
            <div
              className={classNames(
                'flex flex-row items-center gap-4 rounded-xl bg-secondary px-4 py-3 transition-all duration-200',
                {
                  ['translate-y-[100px] opacity-0']:
                    (selectedProjectsIds?.size ?? 0) < 2,
                }
              )}
            >
              <p className="font-body text-base text-white">
                {selectedProjectsIds?.size} projects selected
              </p>
              {(currentTeam === undefined ||
                currentTeam?.aninixRole === 'editor') && (
                <div className="flex flex-row gap-2">
                  <>
                    <button
                      onClick={() => {
                        setShowModal('moveto')
                      }}
                      className="flex flex-row items-center gap-2 rounded-lg border border-[#5C6574] bg-secondary px-3 py-2 transition-all duration-500 hover:scale-[1.02] hover:shadow-xl"
                    >
                      <Icons.MoveTo />
                      <p className="font-body text-sm font-medium text-white">
                        Move to...
                      </p>
                    </button>

                    <button
                      onClick={() => {
                        setShowModal('delete')
                      }}
                      className="flex flex-row items-center gap-2 rounded-lg border border-[#5C6574] bg-secondary px-3 py-2 transition-all duration-500 hover:scale-[1.02] hover:shadow-xl"
                    >
                      <Icons.Delete />
                      <p className="font-body text-sm font-medium text-white">
                        Delete
                      </p>
                    </button>
                  </>
                </div>
              )}
            </div>
          </div>

          {folders?.length + projects?.length !== 0 ? (
            <div className="flex h-full flex-col gap-4">
              <div className="flex flex-col gap-4">
                <div
                  style={
                    {
                      '--repeat': `repeat(${repeatCount},minmax(0,1fr))`,
                    } as React.CSSProperties
                  }
                  className="grid grid-cols-[--repeat] gap-4"
                >
                  <CreateNewFolderListItem
                    refetchCurrentFolder={refetchFolder}
                  />
                  {folders?.map((f) => <FolderItem {...f} key={f.id} />)}
                </div>

                <div
                  style={
                    {
                      '--repeat': `repeat(${repeatCount},minmax(0,1fr))`,
                    } as React.CSSProperties
                  }
                  className="grid grid-cols-[--repeat] gap-4"
                >
                  {projects?.map((p) => <ProjectItem {...p} key={p.id} />)}
                  {skip > lastFetchedSkip && (
                    <>
                      {_.range(
                        (projects.length % repeatCount) + repeatCount
                      ).map((f, i) => (
                        <ProjectItemPlaceholder key={i} />
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <FolderEmptyState />
          )}
        </LoadableChildrenWrapper>
      </div>
    </CurrentFolderContext.Provider>
  )
}

ProjectsList.displayName = 'ProjectsList'
