import { icons, icons as Icons } from '@aninix/app-design-system'
import * as React from 'react'

export interface IProps {
  layerName: string
  iconType: icons.LayerType
  name: string
}
export const SegmentTitle: React.FCC<IProps> = ({
  layerName,
  iconType,
  name,
}: IProps) => (
  <div className="flex flex-row items-center gap-1">
    <Icons.Layer type={iconType} size={{ x: 16, y: 16 }} />

    <p className="font-body text-xs font-medium text-secondary">{layerName}</p>

    <p className="ml-1 font-mono text-xs font-medium text-[var(--figma-color-text)]">
      / {name}
    </p>
  </div>
)

SegmentTitle.displayName = 'SegmentTitle'
