import { SelectionSystem } from '@aninix-inc/model'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useProject } from '../../../../../stores'
import { Controls } from './controls'
import { Layers } from './layers'

export interface IProps {}
export const Timeline: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const selection = project.getSystemOrThrow(SelectionSystem)

  const [hadDown, setHadDown] = React.useState<boolean>(false)

  const handleMouseClick = React.useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (hadDown) {
        setHadDown(false)
        e.preventDefault()
        e.stopPropagation()
        selection.deselectAll()
      }
    },
    [hadDown, selection]
  )
  const handleMouseDown = React.useCallback(() => {
    setHadDown(true)
  }, [])

  return (
    <div
      onClick={handleMouseClick}
      onMouseDown={handleMouseDown}
      className="flex w-full flex-col gap-0 border-b-[1px] border-gray-100"
    >
      <Controls />
      <Layers />
    </div>
  )
})

Timeline.displayName = 'Timeline'
