import { round } from '@aninix-inc/model/legacy'
import { TimeFormat } from '@aninix/core'

import {
  convertFramesToSeconds,
  convertMillisecondsToSeconds,
  convertSecondsToFrames,
  convertSecondsToMilliseconds,
} from '.'

interface IPayload {
  time: number
  fps: number
  timeFormat: TimeFormat
}

export function toFormat({ time, fps, timeFormat }: IPayload) {
  if (timeFormat === TimeFormat.Frames) {
    const { frames, shortSuffix } = convertSecondsToFrames({
      seconds: time,
      fps,
    })
    return {
      value: frames,
      suffix: shortSuffix,
      get formatted() {
        return `${this.value}${this.suffix}`
      },
    }
  }

  if (timeFormat === TimeFormat.Milliseconds) {
    const { milliseconds, suffix } = convertSecondsToMilliseconds({
      seconds: time,
    })
    return {
      value: milliseconds,
      suffix,
      get formatted() {
        return `${this.value}${this.suffix}`
      },
    }
  }

  return {
    value: Math.round(time * 100) / 100,
    suffix: 's',
    get formatted() {
      return `${this.value.toFixed(2)}${this.suffix}`
    },
  }
}

/**
 * @description round provided time according to fps and timeFormat.
 * Mostly used when change time on timeline
 */
export function roundTime({ time, fps, timeFormat }: IPayload) {
  if (timeFormat === TimeFormat.Frames) {
    const { frames } = convertSecondsToFrames({ seconds: time, fps })
    const roundedFrames = round(frames, { fixed: 0 })
    const { seconds } = convertFramesToSeconds({ frames: roundedFrames, fps })
    return seconds
  }

  if (timeFormat === TimeFormat.Milliseconds) {
    const { milliseconds } = convertSecondsToMilliseconds({ seconds: time })
    const roundedMilliseconds = round(milliseconds, { fixed: 0 })
    const { seconds } = convertMillisecondsToSeconds({
      milliseconds: roundedMilliseconds,
    })
    return seconds
  }

  return round(time, { fixed: 2 })
}

/**
 * @description convert provided time in any format to seconds
 */
export function toSeconds(payload: {
  time: number
  fps: number
  timeFormat: TimeFormat
}): number {
  const { time, fps, timeFormat } = payload

  const roundedTime = roundTime({ time, fps, timeFormat })

  if (timeFormat === TimeFormat.Frames) {
    return round(roundedTime / fps)
  }

  if (timeFormat === TimeFormat.Milliseconds) {
    return roundedTime / 1000
  }

  return time
}
