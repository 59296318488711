import { round } from '@aninix-inc/model/legacy'
import * as R from 'ramda'
import * as React from 'react'
import tinycolor from 'tinycolor2'

import { useMouseMove } from '../../../hooks'

const circleRadius = 6
const circleSize = circleRadius * 2

const noop = () => {}

export interface IProps {
  /**
   * @description hue in range 0...360
   */
  hue: number
  /**
   * @description alpha in range 0...1
   */
  alpha: number
  onStartChange?: () => void
  onEndChange?: () => void
  onChange: (alpha: number) => void
  size?: number
}
export const AlphaPicker: React.FCC<IProps> = ({
  hue,
  alpha,
  onStartChange = noop,
  onEndChange = noop,
  onChange,
  size = 240,
}) => {
  const containerRef = React.useRef<any>(null)
  const [_, setMounted] = React.useState(false)
  const wrapperSize = React.useMemo(() => size - circleSize, [size])

  const { endAtX, startListen, isListening, wasTriggered } = useMouseMove({
    threshold: 0,
    element: containerRef!.current,
    delay: 16.67,
    onStart: onStartChange,
    onFinish: onEndChange,
  })

  React.useEffect(() => {
    if (isListening === false || wasTriggered === false) {
      return
    }

    const newValue = round(endAtX / wrapperSize, { fixed: 2 })
    const newProgress = R.clamp(0, 1, newValue)
    onChange(newProgress)
  }, [endAtX, wrapperSize, isListening, wasTriggered])

  React.useEffect(() => {
    setMounted(true)
  }, [])

  const progress = React.useMemo(() => alpha, [alpha])

  const currentColor = React.useMemo(
    () =>
      `#${tinycolor({
        h: hue,
        s: 1,
        v: 1,
      }).toHex()}`,
    [hue]
  )

  return (
    <svg width={size} height={14} viewBox={`0 0 ${size} 14`}>
      <defs>
        <pattern
          id="alpha_checkerboard"
          viewBox="0 0 6 6"
          width="4%"
          height="76%"
        >
          <rect width="3" height="3" x="0" y="0" fill="#F0F0F0" />
          <rect width="3" height="3" x="3" y="0" fill="white" />
          <rect width="3" height="3" x="0" y="3" fill="white" />
          <rect width="3" height="3" x="3" y="3" fill="#F0F0F0" />
        </pattern>

        <linearGradient id="alpha_horizontal" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={currentColor} stopOpacity={0} />
          <stop offset="100%" stopColor={currentColor} stopOpacity={1} />
        </linearGradient>
      </defs>

      <g>
        <rect
          x={1}
          y={1}
          width={size - 2}
          height={12}
          rx={6}
          fill="url(#alpha_checkerboard)"
        />
        <rect
          x={1}
          y={1}
          width={size - 2}
          height={12}
          rx={6}
          fill="url(#alpha_horizontal)"
          stroke="rgba(0, 0, 0, 0.2)"
          strokeWidth={0.5}
        />

        {/* @ts-ignore */}
        <g onMouseDown={startListen} transform={`translate(${circleRadius} 0)`}>
          <rect
            width={wrapperSize}
            height={14}
            fill="rgba(0, 0, 0, 0.01)"
            ref={containerRef}
          />

          <g transform={`translate(${progress * wrapperSize} 7)`}>
            <circle
              cx={0}
              cy={0}
              r={circleRadius}
              fill="black"
              fillOpacity={0.2}
            />
            <circle
              cx={0}
              cy={0}
              r={circleRadius - 2}
              fill={currentColor}
              fillOpacity={progress}
              stroke="white"
              strokeWidth={2}
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
