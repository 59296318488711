import { Entity, FillsRelationsAspect, SolidPaint } from '@aninix-inc/model'
import { INode } from 'svgson'
import { Gradient } from '../../types'
import { parseColor } from '../../utils/parse-color'
import { applyGradient } from './apply-gradient'

export const applyFill = (
  entity: Entity,
  node: INode,
  gradients: Record<string, Gradient>
) => {
  const { fill, fillOpacity } = node.attributes

  const fillAspect = entity.createAspectIfNotExists(FillsRelationsAspect)
  fillAspect.clear()

  if (!fill || fill === 'none') return

  if (fill.startsWith('url')) {
    const gradientId = fill.substring(5, fill.length - 1)
    const gradient = gradients[gradientId]
    applyGradient(entity, gradient, fillAspect)
  } else {
    const color = parseColor(fill)

    const paint = entity.getProjectOrThrow().createEntity(SolidPaint, {
      ...color,
      a: fillOpacity ? parseFloat(fillOpacity) : color.a,
    })

    fillAspect.addChild(paint)
  }
}
