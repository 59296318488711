import * as React from 'react'

export const QuestionIcon = () => (
  <svg
    width="102"
    height="102"
    viewBox="0 0 102 102"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_dd_4786_4121)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51 62C64.2548 62 75 51.2548 75 38C75 24.7452 64.2548 14 51 14C37.7452 14 27 24.7452 27 38C27 51.2548 37.7452 62 51 62ZM46.4322 38.8359C45.831 40.5039 46.4898 42.0442 48.1468 42.6621C49.4758 43.1577 50.5212 42.8106 51.3611 41.7297C52.1619 40.5943 52.8385 40.1296 55.703 39.6244C58.7781 39.0783 60.6145 37.7316 61.5447 35.1506C63.0824 30.8842 60.6474 26.8293 55.4176 24.8789C51.4996 23.4178 48.0729 23.8527 46.1192 25.6337C45.5163 26.1657 45.0969 26.7861 44.8248 27.541C44.238 29.1147 44.9066 30.5193 46.5291 31.1243C47.7373 31.5749 48.9299 31.3624 49.8418 30.4078C50.8388 29.3257 52.0049 29.0237 53.3857 29.5386C54.9392 30.1179 55.7004 31.5371 55.1878 32.9592C54.6436 34.4691 53.7674 34.9988 50.9138 35.528C48.6401 35.9149 47.1599 36.8168 46.4701 38.7306L46.4322 38.8359ZM43.1991 46.3937C42.56 48.167 43.51 50.0947 45.3223 50.7706C47.1173 51.44 49.0843 50.6001 49.7235 48.8269C50.3562 47.0711 49.389 45.137 47.594 44.4676C45.7817 43.7917 43.8319 44.638 43.1991 46.3937Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_dd_4786_4121"
        x="0.333336"
        y="0.666668"
        width="101.333"
        height="101.333"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="13.3333" />
        <feGaussianBlur stdDeviation="13.3333" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.666667 0 0 0 0 0.168627 0 0 0 0.3 0"
        />
        <feBlend
          mode="multiply"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4786_4121"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3.33333" />
        <feGaussianBlur stdDeviation="3.33333" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.666667 0 0 0 0 0.168627 0 0 0 0.3 0"
        />
        <feBlend
          mode="multiply"
          in2="effect1_dropShadow_4786_4121"
          result="effect2_dropShadow_4786_4121"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_4786_4121"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
)
