import * as R from 'ramda'
import * as React from 'react'

import { useThrottle } from './use-throttle'

const noop = () => {}

type BoundingBox = {
  top: number
  left: number
  width: number
  height: number
}

interface IState {
  /**
   * @description started X coordinate
   */
  startAtX: number

  /**
   * @description delta of movement related to started point.
   * Calculated by endX - startX
   */
  endAtX: number

  /**
   * @description the same as moveStartFromX but for Y
   */
  startAtY: number

  /**
   * @description the same as movedByX but for Y
   */
  endAtY: number

  /**
   * @description x coordinate for current time.
   * If user doesn't move cursor than change coordinate would be 0
   */
  deltaX: number

  /**
   * @description the same as deltaX but for Y
   */
  deltaY: number

  /**
   * @description true when user hold shift while dragging
   */
  shiftPressed: boolean

  /**
   * @description true when user hold cmd while dragging
   */
  cmdPressed: boolean
}

interface IProps {
  /**
   * @description threshold in pixels to trigger event
   * @default 10
   */
  threshold?: number

  /**
   * @description any element to listen for events
   * @default document
   */
  element?: Document | HTMLElement

  /**
   * @description should listen events on provided element or document by default
   */
  listenElementForMouseUpEvent?: boolean

  /**
   * @description delay between functions calls
   */
  delay?: number

  onTrigger?: () => void

  onStart?: () => void

  /**
   * @description triggered when onMouseUp event happen
   */
  onFinish?: () => void
}
/**
 * @description create mouse move event listener and return distance moved by cursor
 * rest of the logic should be handled outside of this hook
 * @example
 * const { isEditing, distance, startListen } = useMouseMove()
 * // ...later in the callback
 * <input onMouseDown={startListen} />
 */
export const usePointerMove = ({
  threshold = 10,
  element: providedElement,
  listenElementForMouseUpEvent = false,
  // @NOTE: 60 fps
  delay = 17,
  onTrigger = noop,
  onStart = noop,
  onFinish = noop,
}: IProps = {}) => {
  const onStartTriggeredRef = React.useRef(false)
  const onFinishTriggeredRef = React.useRef(false)
  const [isListening, setIsListening] = React.useState(false)
  const [state, setState] = React.useState<IState>({
    startAtX: 0,
    endAtX: 0,
    startAtY: 0,
    endAtY: 0,
    deltaX: 0,
    deltaY: 0,
    shiftPressed: false,
    cmdPressed: false,
  })
  const [wasInitiallyTriggered, setWasInitiallyTriggered] =
    React.useState(false)

  const element = React.useMemo(
    () => providedElement || document,
    [providedElement]
  )

  const offsetX = React.useMemo(() => state.endAtX - state.startAtX, [state])
  const offsetY = React.useMemo(() => state.endAtY - state.startAtY, [state])

  const wasTriggeredPrevValueRef = React.useRef(false)
  const wasTriggered = React.useMemo(
    () =>
      wasInitiallyTriggered ||
      R.max(Math.abs(offsetX), Math.abs(offsetY)) > threshold,
    [wasInitiallyTriggered, offsetX, offsetY, threshold]
  )

  const mouseMoveListener = React.useCallback(
    (e: PointerEvent) => {
      if (onFinishTriggeredRef.current) {
        return
      }

      const boundingBox: BoundingBox =
        providedElement == null
          ? {
              left: 0,
              top: 0,
              width: 0,
              height: 0,
            }
          : (element as HTMLDivElement).getBoundingClientRect()

      setState((s) => ({
        ...s,
        endAtX: e.clientX - boundingBox.left,
        endAtY: e.clientY - boundingBox.top,
        deltaX: e.movementX,
        deltaY: e.movementY,
        shiftPressed: e.shiftKey,
        cmdPressed: e.ctrlKey || e.metaKey,
      }))
    },
    [providedElement]
  )

  const mouseUpListener = React.useCallback(
    (e: PointerEvent) => {
      onStartTriggeredRef.current = false
      onFinishTriggeredRef.current = true

      if (wasTriggered) {
        onFinish()
      }

      setIsListening(false)
      setWasInitiallyTriggered(false)

      setState({
        startAtX: 0,
        endAtX: 0,
        startAtY: 0,
        endAtY: 0,
        deltaX: 0,
        deltaY: 0,
        shiftPressed: false,
        cmdPressed: false,
      })
    },
    [onFinish, wasTriggered]
  )

  const throttledMouseMoveListener = useThrottle({
    callback: mouseMoveListener,
    delay,
  })

  React.useEffect(() => {
    if (wasTriggeredPrevValueRef.current !== wasTriggered && wasTriggered) {
      onTrigger()
    }

    wasTriggeredPrevValueRef.current = wasTriggered
  }, [wasTriggered])

  React.useEffect(() => {
    function subscribe() {
      if (listenElementForMouseUpEvent) {
        element?.addEventListener('pointermove', throttledMouseMoveListener)
        // @ts-ignore
        element?.addEventListener('pointerup', mouseUpListener)
        return
      }

      document.addEventListener('pointermove', throttledMouseMoveListener)
      // @ts-ignore
      document.addEventListener('pointerup', mouseUpListener)
    }

    function unsubscribe() {
      if (listenElementForMouseUpEvent) {
        element?.removeEventListener('pointermove', throttledMouseMoveListener)
        // @ts-ignore
        element?.removeEventListener('pointerup', mouseUpListener)
        return
      }

      document.removeEventListener('pointermove', throttledMouseMoveListener)
      // @ts-ignore
      document.removeEventListener('pointerup', mouseUpListener)
    }

    if (isListening) {
      subscribe()
    } else {
      unsubscribe()
    }

    return unsubscribe
  }, [
    isListening,
    throttledMouseMoveListener,
    mouseUpListener,
    listenElementForMouseUpEvent,
  ])

  React.useEffect(() => {
    if (wasTriggered === false) {
      return
    }

    if (onStartTriggeredRef.current) {
      return
    }

    onStartTriggeredRef.current = true
    onStart()
  }, [onStart, wasTriggered])

  const startListen = React.useCallback(
    (e: PointerEvent) => {
      setIsListening(true)
      onFinishTriggeredRef.current = false

      if (threshold === 0) {
        setWasInitiallyTriggered(true)
      }

      const boundingBox: BoundingBox =
        providedElement == null
          ? {
              left: 0,
              top: 0,
              width: 0,
              height: 0,
            }
          : (element as HTMLDivElement).getBoundingClientRect()

      const moveStartFromX = e.clientX - boundingBox.left
      const moveStartFromY = e.clientY - boundingBox.top

      setState({
        startAtX: moveStartFromX,
        endAtX: e.clientX - boundingBox.left,
        startAtY: moveStartFromY,
        endAtY: e.clientY - boundingBox.top,
        deltaX: e.movementX,
        deltaY: e.movementY,
        shiftPressed: e.shiftKey,
        cmdPressed: e.ctrlKey || e.metaKey,
      })

      return {
        startAtX: moveStartFromX,
        startAtY: moveStartFromY,
      }
    },
    [providedElement, threshold]
  )

  return {
    isListening,
    offsetX,
    startAtX: state.startAtX,
    endAtX: state.endAtX,
    offsetY,
    startAtY: state.startAtY,
    endAtY: state.endAtY,
    deltaX: state.deltaX,
    deltaY: state.deltaY,
    startListen,
    wasTriggered,
    shiftPressed: state.shiftPressed,
    cmdPressed: state.cmdPressed,
  }
}
