export const parseStyle = (styleString: string): Record<string, string> => {
  const styles: Record<string, string> = {}

  styleString.split(';').forEach((style) => {
    const [property, value] = style.split(':')
    if (property && value) {
      styles[property.trim()] = value.trim()
    }
  })

  return styles
}
