import { ClipContentComponent, Entity, mixed } from '@aninix-inc/model'
import { Checkbox, CompactPropertyRow } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'

export const ClipsContent: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState<boolean>(false)
  const { nodes, time } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) =>
    n.hasComponent(ClipContentComponent)
  )

  const components = filteredNodes.map((l) =>
    l.getComponentOrThrow(ClipContentComponent)
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const equals = R.all((p) => p.value === components[0].value, components)
  const value = equals ? components[0].value : mixed

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <ClipsContentEditable nodes={filteredNodes} value={value} />
      ) : (
        <ClipsContentDisplay value={value} />
      )}
    </div>
  )
}

export const ClipsContentEditable: React.FC<{
  value: boolean | typeof mixed
  nodes: Entity[]
}> = ({ value, nodes }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="py-[6px]">
          <Checkbox
            // @TODO: add support of mixed component
            isChecked={value === mixed ? false : value}
            onClick={() => {
              nodes.forEach((n) =>
                n.updateComponent(ClipContentComponent, (v) => !v)
              )
            }}
            label="Clip content"
          />
        </div>
      }
      rightColumn={null}
    />
  )
}

ClipsContentEditable.displayName = 'ClipsContentEditable'

interface ClipsContentDisplay {
  value: boolean | typeof mixed
}

const propsAreEqual = (prev: ClipsContentDisplay, next: ClipsContentDisplay) =>
  prev.value === next.value

const ClipsContentDisplay: React.FC<ClipsContentDisplay> = React.memo(
  ({ value }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="py-[6px]">
            <Checkbox
              // @TODO: add support of mixed component
              isChecked={value === mixed ? false : value}
              onClick={() => {}}
              label="Clip content"
            />
          </div>
        }
        rightColumn={null}
      />
    )
  },
  propsAreEqual
)

ClipsContentDisplay.displayName = 'ClipsContentDisplay'
