import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const TangentsMirroring: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5858 15.5L10.5 14.4142L9.41421 15.5L10.5 16.5858L11.5858 15.5ZM11.2071 13.7071L10.5 13L9.79289 13.7071L8.70711 14.7929L8 15.5L8.70711 16.2071L9.79289 17.2929L10.5 18L11.2071 17.2929L12.2929 16.2071L12.5 16H14.5854C14.7913 16.5826 15.3469 17 16 17C16.6531 17 17.2087 16.5826 17.4146 16H19.5L19.7071 16.2071L20.7929 17.2929L21.5 18L22.2071 17.2929L23.2929 16.2071L24 15.5L23.2929 14.7929L22.2071 13.7071L21.5 13L20.7929 13.7071L19.7071 14.7929L19.5 15H17.4146C17.2087 14.4174 16.6531 14 16 14C15.3469 14 14.7913 14.4174 14.5854 15H12.5L12.2929 14.7929L11.2071 13.7071ZM22.5858 15.5L21.5 14.4142L20.4142 15.5L21.5 16.5858L22.5858 15.5Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
