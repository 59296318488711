import { httpClient } from '@aninix/api'
import { config } from '@aninix/api/config'
import { AxiosResponse } from 'axios'

export interface ILottie {
  id: string
  userId: string
  createdAt: string
  updatedAt: string
  expiredAt: string
  data: {
    type: 'Buffer'
    data: ArrayBuffer
  }
}

export interface ILottieGetResponse {
  queryAsync: (id: string) => Promise<AxiosResponse<ILottie, any>>
}

interface IProps {
  isStartingLoading?: boolean
}

export const useGetLottie = (): ILottieGetResponse => {
  const queryAsync = async (id: string) => {
    const data = await httpClient.get<ILottie>(
      `${config.apiUrl}/lotties/${id}`,
      {
        responseType: 'json',
      }
    )
    return data
  }

  return { queryAsync }
}
