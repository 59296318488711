/**
 * @todo refactor further to remove class declaration here
 */
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { getFigmaAuthSession, logout, startFigmaAuthSession } from '@aninix/api'
import { Logger, useLogger } from '@aninix/logger'
import * as React from 'react'
import * as Rx from 'rxjs'

import { SessionStore } from '../stores/session'

type Payload = {
  sessionStore: SessionStore
}

export interface IAuthenticateUseCase {
  state: 'not-started' | 'pending' | 'success' | 'error'
  errorMessage?: string
  start: (payload?: {
    redirectUrlOnSuccess?: string
    redirectUrlOnError?: string
  }) => Promise<{ url: string } | null>
  cancel: () => void
}

/**
 * @description user received new token from remote service
 */
type SuccessEvent = {
  data: {
    status: 'success'
  }
}

/**
 * @description authentication still in progress
 */
type PendingEvent = {
  data: {
    status: 'pending'
  }
}

/**
 * @description received error from remote service
 */
type ErrorEvent = {
  data: {
    status: 'error'
    message: string
  }
}

export type AuthenticationEvent = SuccessEvent | ErrorEvent | PendingEvent

export interface IAuthenticationService {
  /**
   * @description starts authentication session.
   * Should redirect user to received url to start process.
   */
  startAuthSession: (payload?: {
    rediretUrlOnSuccess?: string
    redirectUrlOnError?: string
  }) => Promise<{ url: string }>

  /**
   * @description cancel authentication process if user wants to
   */
  cancelAuthSession: () => IAuthenticationService

  logout: () => Promise<void>

  /**
   * @description stream of events
   */
  events$: Rx.Observable<AuthenticationEvent>
}

export class AuthenticationService implements IAuthenticationService {
  private readonly _events$: Rx.ReplaySubject<AuthenticationEvent> =
    new Rx.ReplaySubject<AuthenticationEvent>(20)

  private eventsSubscription?: Rx.Subscription

  constructor(private readonly logger: Logger) {}

  startAuthSession: IAuthenticationService['startAuthSession'] = async (
    payload
  ) => {
    this.logger.log('[Authentication Service]', 'request session')

    const result = await startFigmaAuthSession(payload ?? {})

    this.logger.log(
      '[Authentication Service]',
      'auth session started',
      result.data
    )

    this.listenEvents(result.data.id)

    return {
      url: result.data.authUrl,
    }
  }

  cancelAuthSession: IAuthenticationService['cancelAuthSession'] = () => {
    this.eventsSubscription?.unsubscribe()
    this.eventsSubscription = undefined
    return this
  }

  get events$() {
    return this._events$
  }

  logout: IAuthenticationService['logout'] = async () => {
    await logout()
  }

  private listenEvents = (sessionId: string) => {
    this.logger.log(
      '[Authentication Service]',
      'event source created for sessionId',
      sessionId
    )

    this.events$.next({
      data: {
        status: 'pending',
      },
    })

    this.eventsSubscription = Rx.interval(2000)
      .pipe(
        Rx.tap((ind) =>
          console.log('[Authentication Service] Interval fire', ind)
        ),
        Rx.switchMap(() =>
          Rx.from(getFigmaAuthSession({ figmaAuthSessionId: sessionId }))
        ),
        Rx.map((result) => result.data.figmaAuthSession),
        Rx.tap((session) =>
          console.log('[Authentication Service] responded', session)
        ),
        Rx.takeWhile((session) => session.status === 'pending', true)
      )
      .subscribe((result) => {
        if (result.status === 'failure') {
          this.logger.log('[Authentication Service] failure')

          this._events$.next({
            data: {
              status: 'error',
              message: 'Authenitcation failed',
            },
          })

          return
        }

        if (result.status === 'success') {
          this.logger.log('[Authentication Service] success')

          this._events$.next({
            data: {
              status: 'success',
            },
          })

          return
        }
      })
  }
}

/**
 * @description Authentication Flow from start to finish.
 * It creates new authentication session and then polling session status.
 * Once session status changed use case returns received value.
 */
export const useAuthenticateUseCase = ({
  sessionStore,
}: Payload): IAuthenticateUseCase => {
  const analytics = useAnalytics()
  const logger = useLogger()
  const authenticationService = React.useRef(new AuthenticationService(logger))
  const [state, setState] =
    React.useState<IAuthenticateUseCase['state']>('not-started')
  const [errorMessage, setErrorMessage] =
    React.useState<IAuthenticateUseCase['errorMessage']>(undefined)

  const start: IAuthenticateUseCase['start'] = React.useCallback(
    async (payload) => {
      setState('pending')

      analytics.track({
        eventName: AnalyticsEvent.AuthenticationSessionRequested,
      })

      try {
        const { url } =
          await authenticationService.current.startAuthSession(payload)

        authenticationService.current.events$.subscribe(async (event) => {
          switch (event.data.status) {
            case 'success':
              analytics.track({
                eventName: AnalyticsEvent.AuthenticationSessionCompleted,
              })
              await sessionStore.refresh()
              setState('success')
              break
            case 'error':
              setState('error')
              setErrorMessage(event.data.message)
              break
            case 'pending':
              break
          }
        })

        return { url }
      } catch (err: any) {
        console.error('Figma starting auth sessions failed', err)

        // @NOTE: do nothing if user is offline
        if (err.data.message.includes('Network request failed')) {
          throw err
        }

        await sessionStore.generateAnonymousToken()

        throw err
      }
    },
    [analytics, authenticationService]
  )

  const cancel: IAuthenticateUseCase['cancel'] = React.useCallback(() => {
    authenticationService.current.cancelAuthSession()
    analytics.track({
      eventName: AnalyticsEvent.AuthenticationSessionCancelled,
    })
    setState('not-started')
  }, [authenticationService])

  return {
    state,
    errorMessage,
    start,
    cancel,
  }
}
