import { BlendModeComponent, BlendModeType, Entity } from '@aninix-inc/model'
import { INode } from 'svgson'
import { parseStyle } from '../../utils/parse-style'

const blendModes = [
  'pass-through',
  'normal',
  'darken',
  'multiply',
  'linear-burn',
  'color-burn',
  'lighten',
  'screen',
  'linear-dodge',
  'color-dodge',
  'overlay',
  'soft-light',
  'hard-light',
  'difference',
  'exclusion',
  'hue',
  'saturation',
  'color',
  'luminosity',
]

export const applyStyle = (entity: Entity, node: INode) => {
  const { style } = node.attributes
  if (!style) return

  const parsed = parseStyle(style)

  for (const [key, value] of Object.entries(parsed)) {
    if (key === 'mix-blend-mode' && blendModes.includes(value)) {
      const blendMode = value
        .replaceAll('-', '_')
        .toUpperCase() as BlendModeType

      entity.updateComponent(BlendModeComponent, blendMode)
    }
  }
}
