import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useNotFoundViewInteractor } from './interactor'
import { NotFoundViewRouter } from './router'

export interface IProps {}
export const NotFoundView: React.FCC<IProps> = observer(() => {
  const interactor = useNotFoundViewInteractor()

  return <NotFoundViewRouter interactor={interactor} />
})

NotFoundView.displayName = 'NotFoundView'
