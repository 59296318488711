import {
  EntityType,
  EntityTypeComponent,
  HashComponent,
  PaintType,
  PaintTypeComponent,
} from '@aninix-inc/model'
import { usePlayerStores } from '@aninix-inc/player'
import { observer } from 'mobx-react'
import * as React from 'react'

export interface IProps {}
export const LoadImagesHandler: React.FCC<IProps> = observer(() => {
  const stores = usePlayerStores()
  const { project, imagesStore } = stores

  React.useEffect(() => {
    const hashes = project
      .getEntitiesByPredicate(
        (e) =>
          e.getComponentOrThrow(EntityTypeComponent).value ===
            EntityType.Paint &&
          e.getComponentOrThrow(PaintTypeComponent).value === PaintType.Image
      )
      .map((paint) => paint.getComponentOrThrow(HashComponent).value)

    imagesStore
      .loadImagesByHashes(hashes)
      .then(() => {
        window.parent.postMessage({ type: 'ready' }, '*')
      })
      .catch((err) => {
        console.error('images loading failed', err)
      })
  }, [imagesStore, project])

  return null
})

LoadImagesHandler.displayName = 'LoadImagesHandler'
