export const parseTransform = (transform: string) => {
  const transforms = transform.match(/(\w+\([^\)]+\))/g)
  if (!transforms) return []

  return transforms.map((t) => {
    const type = t.split('(')[0]
    const values = t
      .match(/\(([^)]+)\)/)?.[1]
      .split(/[\s,]+/)
      .map(Number)
    return { type, values }
  })
}
