import * as React from 'react'
import { ColorSelection } from '../nodes/color-selection'
import { Fills } from '../nodes/fills'
import { Strokes } from '../nodes/strokes'
import { TrimLayer } from '../nodes/trim-layer'
import { ClipContent } from './clip-content'
import { CornerRadius } from './corner-radius'
import { Size } from './size'

export const EntryProperties: React.FC = () => {
  return (
    <>
      <Size />
      <CornerRadius />
      <ClipContent />
      <TrimLayer />
      <Fills />
      <Strokes />
      <ColorSelection />
    </>
  )
}

EntryProperties.displayName = 'EntryProperties'
