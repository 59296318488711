import {
  ChildrenExpandedComponent,
  Entity,
  EntityType,
  EntryComponent,
  ParentRelationAspect,
  SelectionSystem,
  getEntryOrThrow,
} from '@aninix-inc/model'
import { Point2D } from '@aninix-inc/model/legacy'
import { convertEntityToSnapshot as convertNodeToSnapshot } from '@aninix-inc/renderer'
import { getNodesBranchV2 } from '@aninix/core/utils/get-nodes-branch'
import * as React from 'react'
import { ImagesStore, KeyModificator, Playback, Session } from '../../stores'
import { getSelection } from '../../updates'
import { getItemsIntersectWithRectangle } from './get-items-intersects-with-rectangle'
import { getItemsUnderCursor } from './get-items-under-cursor'

interface IUseLayerSelection {
  node: Entity
  cursorPositionRef: React.MutableRefObject<Point2D>
  images: ImagesStore
  session: Session
  playback: Playback
  selectionRectBoundingRect: {
    x: number
    y: number
    width: number
    height: number
  }
  isSelectionRectVisible: boolean
}

export const getAllParentGroups = (
  node: Entity,
  parents: Entity[] = []
): Entity[] => {
  if (
    !node.hasAspect(ParentRelationAspect) ||
    node.hasComponent(EntryComponent)
  ) {
    return parents
  } else {
    return getAllParentGroups(
      node.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow(),
      [
        ...parents,
        node.getAspectOrThrow(ParentRelationAspect).getParentEntityOrThrow(),
      ]
    )
  }
}

export const expandBranch = (entity: Entity): void => {
  const entry = getEntryOrThrow(entity.getProjectOrThrow())
  const branch = getNodesBranchV2(entity)
  entry.updateComponent(ChildrenExpandedComponent, true)
  branch.forEach((e) => {
    if (e.hasComponent(ChildrenExpandedComponent)) {
      e.updateComponent(ChildrenExpandedComponent, true)
    }
  })
}

export function selectLayers({
  node: entity,
  cursorPositionRef,
  images,
  session,
  playback,
  selectionRectBoundingRect,
  isSelectionRectVisible,
}: IUseLayerSelection) {
  const project = entity.getProjectOrThrow()
  const selection = project.getSystemOrThrow(SelectionSystem)

  const isCmdPressed = session.keyModificators.includes(KeyModificator.Ctrl)

  const skipGroupIds = getSelection(project, EntityType.Node)
    .flatMap((e) => getAllParentGroups(e))
    .map((e) => e.id)

  const snapshot = convertNodeToSnapshot({
    entity,
    time: playback.time,
    isRoot: entity.hasComponent(EntryComponent),
    imagesStore: images,
  })

  if (snapshot.visible === false) {
    return
  }

  const visibleChildren = snapshot.children.filter((n) => n.visible)

  if (isSelectionRectVisible) {
    const selectedItems = getItemsIntersectWithRectangle({
      nodes: visibleChildren,
      selectionRectBoundingRect,
      skipGroupIds,
      isCmdPressed,
    })

    selection.replace(selectedItems)
    expandBranch(entity)
  } else {
    const highlightedItems = getItemsUnderCursor({
      nodes: visibleChildren,
      cursorPositionRef,
      skipGroupIds,
      isCmdPressed,
    })

    session.setBuffer(highlightedItems)
  }
}
