import type { Point2D } from '@aninix-inc/model/legacy'
import { lerp } from '@aninix-inc/model/legacy'
import * as R from 'ramda'

import { BezierPoint } from './bezier-point'

type Payload = {
  position: Point2D
  size: Point2D
  pointCount: number
}

export function polygonToBezier({
  position,
  size,
  pointCount,
}: Payload): BezierPoint[] {
  const outerRadius = 1

  const points = R.range(0, Math.round(pointCount)).map((idx) => {
    const angle = lerp(idx / pointCount, Math.PI, Math.PI * 3)
    const magnitude = outerRadius

    return new BezierPoint({
      point: {
        x: 0,
        y: magnitude,
      },
    })
      .rotateBy(angle)
      .scaleBy({
        x: size.x / 2,
        y: size.y / 2,
      })
      .translateBy({
        x: position.x + size.x / 2,
        y: position.y + size.y / 2,
      })
  })

  return R.append(R.head(points)!, points)
}
