import { config } from './config'
import { ApiPostCreatedResponse, ApiPostRequestBody } from './helpers'
import { httpClient } from './http-client'

export function postFeeling(payload: ApiPostRequestBody<'/feelings'>) {
  return httpClient.post<
    ApiPostRequestBody<'/feelings'>,
    ApiPostCreatedResponse<'/feelings'>
  >(`${config.apiUrl}/feelings`, payload)
}
