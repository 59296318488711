import { buttons, icons } from '@aninix/app-design-system'
import * as React from 'react'

import { IErrorBoundaryInteractor } from './interactor'
import * as styles from './router.scss'

export interface IProps {
  interactor: IErrorBoundaryInteractor
  hasError: boolean
}
export const ErrorBoundaryRouter: React.FCC<IProps> = ({
  interactor,
  hasError,
  children,
}) => (
  <>
    {hasError && (
      <div className={styles.container}>
        <icons.Error
          style={{
            // @ts-ignore
            '--figma-color-text': styles.color,
          }}
        />

        <div className={styles.description}>
          <p className={styles.bold}>Unexpected issue happen</p>

          <p className={styles['main-message']}>
            Your changes were saved, but we experienced unexpected issue.
            <br />
            Please try to reload the window. If the issue keeps happening,{' '}
            <a
              href="mailto:info@aninix.com"
              target="_blank"
              className="text-[#18A0FB]"
              rel="noreferrer"
            >
              contact us.
            </a>
          </p>
        </div>

        <div className={styles.buttons}>
          <buttons.Regular
            variant="contained"
            title="Reload window"
            onClick={interactor.reloadPlugin}
          />
        </div>

        {interactor.isDownloadProjectEnabled && (
          <buttons.Regular
            variant="text"
            title="Download Project"
            onClick={interactor.downloadProject}
            className="mt-5"
          />
        )}
      </div>
    )}

    {hasError === false && children}
  </>
)
