import {
  EntityType,
  EntityTypeComponent,
  ExportPreset,
  HashComponent,
  PaintType,
  PaintTypeComponent,
  RenderScaleComponent,
  RenderType,
  RenderTypeComponent,
  getEntryOrThrow,
  getSize,
} from '@aninix-inc/model'
import { useCases } from '@aninix/core'
import { Button } from 'apps/web-app/src/components/design-system/button'
import { Typography } from 'apps/web-app/src/components/design-system/typography'
import classNames from 'classnames'
import * as React from 'react'
import { useImagesStore, usePlayback, useProject } from '../../../../stores'
import { useRenderProjectToFile } from '../../../../use-cases/render-project-to-file'

const exportOptions = ['.lottie', 'Video/GIF', 'SVG'] as const
const videoGifFormatOptions = ['MP4', 'WEBM', 'GIF'] as const
const videoGifScaleOptions = ['1×', '2×', '4×'] as const

type ExportOption = (typeof exportOptions)[number]
type VideoGifFormatOption = (typeof videoGifFormatOptions)[number]
type VideoGifScaleOption = (typeof videoGifScaleOptions)[number]

export interface IProps {}
export const Export: React.FCC<IProps> = () => {
  const images = useImagesStore()
  const project = useProject()
  const playback = usePlayback()

  const [isRendering, setIsRendering] = React.useState<boolean>(false)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [exportAs, setExportAs] = React.useState<ExportOption>('Video/GIF')
  const [format, setFormat] = React.useState<VideoGifFormatOption>('MP4')
  const [scale, setScale] = React.useState<VideoGifScaleOption>('1×')

  const exportPreset: ExportPreset = React.useMemo(() => {
    if (exportAs === '.lottie') {
      return project
        .createIndependentEntity(ExportPreset)
        .updateComponent(RenderTypeComponent, RenderType.lottie)
        .updateComponent(RenderScaleComponent, 1)
    }

    if (exportAs === 'SVG') {
      return project
        .createIndependentEntity(ExportPreset)
        .updateComponent(RenderTypeComponent, RenderType.svg)
        .updateComponent(RenderScaleComponent, 1)
    }

    const entity = project.createIndependentEntity(ExportPreset)

    switch (format) {
      case 'GIF': {
        entity.updateComponent(RenderTypeComponent, RenderType.gif)
        break
      }

      case 'MP4': {
        entity.updateComponent(RenderTypeComponent, RenderType.mp4)
        break
      }

      case 'WEBM': {
        entity.updateComponent(RenderTypeComponent, RenderType.webm)
        break
      }

      default: {
        const never: never = format
        throw new Error(`Shoul never reach "${never}"`)
      }
    }

    switch (scale) {
      case '1×': {
        entity.updateComponent(RenderScaleComponent, 1)
        break
      }

      case '2×': {
        entity.updateComponent(RenderScaleComponent, 2)
        break
      }

      case '4×': {
        entity.updateComponent(RenderScaleComponent, 4)
        break
      }

      default: {
        const never: never = scale
        throw new Error(`Shoul never reach "${never}"`)
      }
    }

    return entity
  }, [exportAs, format, scale])

  const render = useRenderProjectToFile({
    project,
    exportPreset,
    imagesStore: images,
    onDone: ({ data, fileName }) => {
      downloadUriAsBlob(data, fileName)
      // @NOTE: required when we have fast updates (e.g., rendering svg).
      // In this case the react dispatch updates in a batch and this setter is not called.
      setTimeout(() => {
        setIsRendering(false)
      }, 1)
    },
    onError: (message) => {
      setErrorMessage(message)
      // @NOTE: required when we have fast updates (e.g., rendering svg).
      // In this case the react dispatch updates in a batch and this setter is not called.
      setTimeout(() => {
        setIsRendering(false)
      }, 1)
    },
    logger: {
      log: console.log,
      error: console.error,
    },
  })

  const startRender = React.useCallback(
    async (e?: any) => {
      setErrorMessage(null)
      render.start()

      const imagePaints = project.getEntitiesByPredicate(
        (entity) =>
          entity.getComponentOrThrow(EntityTypeComponent).value ===
            EntityType.Paint &&
          entity.getComponentOrThrow(PaintTypeComponent).value ===
            PaintType.Image
      )
      await images.loadImagesByHashes(
        imagePaints.map(
          (paint) => paint.getComponentOrThrow(HashComponent).value
        )
      )

      setIsRendering(true)
    },
    [render]
  )

  const cancelRender = React.useCallback(() => {
    render.cancel()
    setIsRendering(false)
  }, [render])

  const features = React.useMemo(() => {
    return useCases.getListOfNotSupportedLottieFeatures(project)
  }, [project])

  const entry = getEntryOrThrow(project)

  return (
    <div className="flex h-fit flex-grow flex-col justify-start gap-0 overflow-clip rounded-xl bg-white bg-opacity-[0.85] backdrop-blur">
      <Typography style="Body5Medium" className="bg-white p-3">
        Export animation
      </Typography>
      <div className="h-[1px] bg-gray-200 bg-opacity-50" />
      <div className="bg flex flex-col gap-3 p-3">
        <Switcher
          options={exportOptions}
          selectedOption={exportAs}
          setSelectedOption={setExportAs}
          label={'Export as'}
        />
        {exportAs === 'Video/GIF' && (
          <>
            <Switcher
              options={videoGifFormatOptions}
              selectedOption={format}
              setSelectedOption={setFormat}
              label={'Format'}
            />
            <Switcher
              options={videoGifScaleOptions}
              selectedOption={scale}
              setSelectedOption={setScale}
              label={'Scale'}
            />
          </>
        )}
      </div>
      {exportAs === 'Video/GIF' && isRendering && (
        <ProgressBar fraction={render.progress} />
      )}
      <div className="flex flex-col gap-3 p-3">
        {exportAs === 'Video/GIF' && isRendering && (
          <div className="flex flex-row gap-1">
            <Spinner />
            <Typography style="Subscript">
              {formatRemainingTime(render.remainingTimeInSeconds)}
            </Typography>
          </div>
        )}

        {errorMessage && (
          <div className="flex flex-row gap-1">
            <Typography style="Subscript" className="text-red">
              {errorMessage}
            </Typography>
          </div>
        )}

        {features.length > 0 && exportAs === '.lottie' && (
          <Warning>
            <div className="flex flex-col gap-2">
              <Typography style="Body5Medium">
                Some of the features in your designs may not be supported by
                .lottie format:
              </Typography>
              <div className="flex flex-col gap-1 pl-1">
                {features.map((effectName) => (
                  <Typography key={effectName} style="Body5Regular">
                    <b>•</b> {effectName}
                  </Typography>
                ))}
              </div>
            </div>
          </Warning>
        )}

        {/* @NOTE: project info */}
        {isRendering === false && (
          <div className="flex flex-col gap-2 text-xs">
            <div>
              <p>Frame size:</p>

              <div className="flex flex-row items-center gap-2">
                <p>
                  <span className="mr-1 text-black/40">W</span>
                  {getSize(entry).x}
                </p>

                <p>
                  <span className="mr-1 text-black/40">H</span>
                  {getSize(entry).y}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="flex flex-row items-center justify-end gap-2">
          {isRendering && (
            <button
              type="button"
              onClick={cancelRender}
              className="h-8 px-3 py-1.5 text-[14px] font-medium text-accent"
            >
              Cancel
            </button>
          )}

          <Button
            isActive={!isRendering}
            onClick={startRender}
            label="Export"
            className="!h-8 !rounded-lg !bg-accent !px-3 !py-1.5 !text-[14px] !font-medium"
          />
        </div>
      </div>
    </div>
  )
}

const Warning: React.FCC<{
  warningElement?: React.ReactNode
}> = ({ warningElement, children }) => {
  return (
    <div className="flex flex-col gap-3 rounded-xl bg-[#FFF3C8D9] bg-opacity-[0.85] p-3">
      <div className="max-w-min rounded-xl bg-[#FF9E00]">
        <p className=" px-2 py-1 font-body text-xs font-medium text-white">
          Warning!
        </p>
      </div>
      {warningElement ?? children}
    </div>
  )
}

export function Switcher<Options extends readonly string[]>(props: {
  label?: string
  options: Options
  readonly selectedOption: Options[number]
  setSelectedOption: React.Dispatch<React.SetStateAction<Options[number]>>
}) {
  const { label, options, selectedOption, setSelectedOption } = props

  const optionsMap = React.useMemo(
    () =>
      options.map((o) => {
        return { value: o, handleClick: () => setSelectedOption(o) }
      }),
    [options, setSelectedOption]
  )
  return (
    <div className="flex flex-col gap-1">
      {label && <Typography style="Subscript">{label}</Typography>}

      <div className="relative flex w-full flex-row rounded-lg bg-gray-500 bg-opacity-10">
        {optionsMap.map((option) => (
          <div
            key={option.value}
            style={{ width: (100 / options.length) * 100 + '%' }}
          >
            <p
              className={classNames(
                'py-[6px] text-center font-body text-[11px] font-normal text-gray-500 opacity-0',
                { ['!opacity-100']: selectedOption !== option.value }
              )}
            >
              {option.value}
            </p>
          </div>
        ))}

        <div className="absolute flex w-full flex-row px-[2px] ">
          {optionsMap.map((option) => (
            <div
              key={option.value}
              style={{ width: (100 / options.length) * 100 + '%' }}
              onClick={option.handleClick}
              className={classNames(
                'cursor-pointer transition-transform duration-500 ease-out',
                {
                  ['my-[2px] rounded-md bg-white shadow-md']:
                    selectedOption === option.value,
                  ['!opacity-100']: selectedOption === option.value,
                  [' scale-[0.8] opacity-0']: selectedOption !== option.value,
                }
              )}
            >
              <p
                className={classNames(
                  ' text-center font-body text-[11px] ',
                  {
                    ['py-[4px] font-semibold text-black ']:
                      selectedOption === option.value,
                  },
                  {
                    ['py-[6px] font-normal text-gray-500']:
                      selectedOption !== option.value,
                  }
                )}
              >
                {option.value}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const ProgressBar: React.FCC<{ fraction: number }> = ({ fraction }) => (
  <div className="relative h-[1px] w-full bg-accent bg-opacity-30">
    <div
      className="absolute bottom-0 left-0 top-0 bg-accent"
      style={{ width: fraction * 100 + '%' }}
    />
  </div>
)

const Spinner: React.FCC = () => (
  <div className="h-4 w-4">
    <svg
      aria-hidden="true"
      className="mr-2 h-full w-full animate-spin fill-accent text-gray-200 dark:text-gray-600"
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>
  </div>
)

Export.displayName = 'Export'

function formatRemainingTime(seconds?: number): string {
  if (seconds == null) {
    return 'Estimating time remaining...'
  }

  const m = Math.floor(seconds / 60)
  const s = seconds % 60

  if (m < 1) {
    return 'Less than a minute remaining'
  }

  let finalString = 'About'

  if (m === 1) {
    finalString += ` ${m} minute`
  } else {
    finalString += ` ${m} minutes`
  }

  finalString += ' remaining'

  return finalString
}

function downloadUriAsBlob(blob: Blob, name: string) {
  const uri = window.URL.createObjectURL(blob)
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
