import React from 'react'

enum FontStyle {
  Heading1 = 'font-header text-[4.5rem] leading-[5rem] font-bold',
  Heading2 = 'font-header text-[3.5rem] leading-[4rem] font-bold',
  Heading3 = 'font-header text-[2.5rem] leading-[3.25rem] font-bold',
  Heading4 = 'font-header text-[1.5rem] leading-[2rem] font-bold',
  Heading5 = 'font-header text-[1.25rem] leading-[1.75rem] font-bold',

  Body1Regular = 'font-body text-2xl font-normal',
  Body2Regular = 'font-body text-xl font-normal',
  Body3Regular = 'font-body text-lg font-normal',
  Body4Regular = 'font-body text-md font-normal',
  Body5Regular = 'font-body text-sm font-normal',

  Body1Medium = 'font-body text-2xl font-medium',
  Body2Medium = 'font-body text-xl font-medium',
  Body3Medium = 'font-body text-lg font-medium',
  Body4Medium = 'font-body text-md font-medium',
  Body5Medium = 'font-body text-sm font-medium',

  Subscript = 'font-body text-xs font-regular',
}

enum Color {
  light = 'text-white',
  dark = 'text-secondary',
  none = '',
}

enum Alignment {
  left = 'text-left',
  center = 'text-center',
  right = 'text-right',
}

interface TypographyProps {
  style: keyof typeof FontStyle
  color?: keyof typeof Color
  alignment?: keyof typeof Alignment
  className?: string
  text?: string
}

export const Typography: React.FCC<TypographyProps> = ({
  text,
  children,
  style,
  color = 'dark',
  alignment = 'left',
  className,
}) => {
  return (
    <p
      className={[
        FontStyle[style],
        Alignment[alignment],
        className ?? Color[color],
      ].join(' ')}
    >
      {text ?? children}
    </p>
  )
}
