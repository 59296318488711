import { icons } from '@aninix/app-design-system'
import classNames from 'classnames'
import * as React from 'react'

import { IAnimationQuickPresetsInteractor } from './interactor'
import * as styles from './router.scss'
import { ViewPreset } from './view-preset'

export interface IProps {
  interactor: IAnimationQuickPresetsInteractor
}
export const AnimationQuickPresetsRouter: React.FCC<IProps> = ({
  interactor,
}) => {
  const iconSize = React.useMemo(
    () => ({
      x: 16,
      y: 16,
    }),
    []
  )

  return (
    <div
      className={classNames(styles.container, {
        [styles['container--first-time']]: interactor.visibleFirstTime,
      })}
    >
      {interactor.visibleFirstTime && (
        <div className={styles.header}>
          <p className={styles.header__title}>
            <icons.PresetType
              type="effect"
              size={iconSize}
              style={{
                // @ts-ignore
                '--figma-color-text': styles.header_color,
              }}
            />

            <span>Quick presets</span>
          </p>

          <p className={styles.header__text}>
            Try quick presets to save your time
          </p>
        </div>
      )}

      <div className={styles.presets}>
        {interactor.presets.map((preset) => (
          <ViewPreset
            key={preset.id}
            preset={preset}
            onClick={() => interactor.applyPreset(preset)}
          />
        ))}

        <button
          onClick={interactor.learnMore}
          className={classNames(styles['learn-more'], {
            // [styles['learn-more--first-time']]: interactor.visibleFirstTime,
          })}
        >
          Show all →
        </button>
      </div>
    </div>
  )
}
