import { Tooltip } from '@aninix/app-design-system'
import classNames from 'classnames'
import * as React from 'react'
import { ShareMenu } from './share-menu'

export interface IProps {}
export const Share: React.FCC<IProps> = () => {
  const [isShareMenuOpen, setIsShareMenuOpen] = React.useState<boolean>(false)
  const openShareMenu = React.useCallback(() => {
    setIsShareMenuOpen(true)
  }, [])
  const closeShareMenu = React.useCallback(() => {
    setIsShareMenuOpen(false)
  }, [])
  const toggleShareMenuOpen = React.useCallback(() => {
    setIsShareMenuOpen((value) => !value)
  }, [])

  const shareMenuButtonRef = React.useRef(null)

  return (
    <>
      <Tooltip title="Share project" arrow>
        <button
          ref={shareMenuButtonRef}
          onClick={toggleShareMenuOpen}
          className="group relative flex h-8 w-8 cursor-default items-center justify-center"
        >
          <div className="absolute inset-0 z-0 rounded-lg bg-white opacity-0 transition-opacity duration-150 group-hover:!opacity-10" />
          <div
            className={classNames(
              'absolute inset-0 z-0 rounded-lg bg-accent transition-opacity duration-100 ',
              {
                ['opacity-100']: isShareMenuOpen,
                ['opacity-0']: !isShareMenuOpen,
              }
            )}
          />
          <ShareIcon
            className={classNames('z-10 h-6 w-6', {
              ['opacity-50']: !isShareMenuOpen,
            })}
          />
        </button>
      </Tooltip>

      <ShareMenu
        isOpen={isShareMenuOpen}
        handleClose={closeShareMenu}
        anchorEl={shareMenuButtonRef.current}
      />
    </>
  )
}

Share.displayName = 'Share'

const ShareIcon = (props: React.HTMLAttributes<SVGSVGElement>) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 4H10.4C8.15979 4 7.03968 4 6.18404 4.43597C5.43139 4.81947 4.81947 5.43139 4.43597 6.18404C4 7.03968 4 8.15979 4 10.4V13.6C4 15.8402 4 16.9603 4.43597 17.816C4.81947 18.5686 5.43139 19.1805 6.18404 19.564C7.03968 20 8.15979 20 10.4 20H13.6C15.8402 20 16.9603 20 17.816 19.564C18.5686 19.1805 19.1805 18.5686 19.564 17.816C20 16.9603 20 15.8402 20 13.6V12"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13V13C11.6805 10.7638 11.5208 9.64566 11.8085 8.76193C12.1429 7.73452 12.8787 6.8861 13.8485 6.40974C14.6827 6 15.8121 6 18.0711 6L20 6M20 6L17 3M20 6L17 9"
      stroke="white"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
