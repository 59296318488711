import classNames from 'classnames'
import * as React from 'react'
import Header from '../overrides/header'
import { useOverride } from '../overrides/use-override-item'

export interface IProps {
  isOpen: boolean

  label: string
}
export const OverridePanel: React.FCC<IProps> = ({
  label,
  isOpen,

  children,
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null)

  const { openOverride, closeOverride, isOverrideOpened } = useOverride()

  const [forceUpdateIterator, setForceUpdateIterator] = React.useState(0)

  const recalculateHeight = () => setForceUpdateIterator((v) => v + 1)

  const contentHeight = React.useMemo(
    () =>
      (
        contentRef.current?.firstChild as HTMLDivElement | undefined
      )?.getBoundingClientRect().height,
    [forceUpdateIterator]
  )

  const [transitionIsGoing, setTransitionIsGoing] = React.useState(false)

  const toggleOpen = isOverrideOpened ? closeOverride : openOverride

  const [localOpen, setLocalOpen] = React.useState(isOpen)

  const resizeObserver = new ResizeObserver(() => recalculateHeight())

  // this is required to avoid problems when text is changing its height
  // we make local open to be dependent of prop value
  // then we make transition available for exactly transition duration
  // therefore transition is working only when isOpen changes,
  // but doesn't work right before and in 150ms after change
  React.useEffect(() => {
    recalculateHeight()

    if (isOpen) {
      resizeObserver.observe(contentRef.current?.firstChild as Element)
    }

    if (!isOpen) {
      resizeObserver.unobserve(contentRef.current?.firstChild as Element)
    }

    setLocalOpen(isOpen)
    setTransitionIsGoing(true)
    setTimeout(() => setTransitionIsGoing(false), 150)
  }, [isOpen])

  React.useEffect(() => {
    if (!isOpen) openOverride()
  }, [isOpen])

  return (
    <div className="py-1.5 bg-white/85 rounded">
      <div
        className={classNames('px-3 pb-1', {
          ['pointer-events-none']: !isOpen,
        })}
      >
        <Header isOpen={localOpen} toggleOpen={toggleOpen} label={label} />
      </div>
      <div
        ref={contentRef}
        className={classNames('', {
          ['transition-[max-height] duration-150']: transitionIsGoing,
        })}
        style={{
          overflow: 'hidden',
          maxHeight: localOpen ? `${contentHeight}px` : '0px',
          height: 'fit-content',
        }}
      >
        {children}
      </div>
    </div>
  )
}

OverridePanel.displayName = 'OverridePanel'
