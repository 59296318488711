import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Polygon: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.64397 6.0975L8.52536 2.629L8 1L7.47464 2.629L6.35603 6.0975L2.71161 6.08954L1 6.0858L2.38692 7.08883L5.34 9.22452L4.20624 12.6881L3.67376 14.3148L5.05629 13.3057L8 11.1571L10.9437 13.3057L12.3262 14.3148L11.7938 12.6881L10.66 9.22452L13.6131 7.08883L15 6.0858L13.2884 6.08954L9.64397 6.0975ZM11.9015 7.09257L9.64616 7.0975L8.91627 7.0991L8.69224 6.40444L8 4.25799L7.30776 6.40444L7.08373 7.0991L6.35384 7.0975L4.09853 7.09257L5.92602 8.41422L6.51744 8.84195L6.29038 9.53562L5.58876 11.679L7.41045 10.3494L8 9.91909L8.58955 10.3494L10.4112 11.679L9.70962 9.53562L9.48256 8.84195L10.074 8.41422L11.9015 7.09257Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
