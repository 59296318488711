import { Entity } from '@aninix-inc/model'
import * as React from 'react'
import { List, OnScrollParams, ScrollSyncChildProps } from 'react-virtualized'

type ScrollSyncContextType = Omit<ScrollSyncChildProps, 'onScroll'> & {
  scrollTo: (params: Partial<OnScrollParams>) => void
  scrollToIndex: (
    listRef: React.RefObject<List>,
    index: number,
    scrollIfVisible?: boolean
  ) => void
  scrollToEntity: (
    listRef: React.RefObject<List>,
    entity?: Entity,
    scrollIfVisible?: boolean
  ) => void
}

export const ScrollSyncContext = React.createContext<ScrollSyncContextType>(
  null as any
)

export const useScrollSync = () => React.useContext(ScrollSyncContext)
