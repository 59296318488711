import { useEffect, useState } from 'react'

const useKeyModifiers = () => {
  const [isCtrlDown, setIsCtrlDown] = useState(false)
  const [isShiftDown, setIsShiftDown] = useState(false)
  const [isAltDown, setIsAltDown] = useState(false)

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Control') setIsCtrlDown(true)
    if (event.key === 'Shift') setIsShiftDown(true)
    if (event.key === 'Alt') setIsAltDown(true)
  }

  const handleKeyUp = (event: KeyboardEvent) => {
    if (event.key === 'Control') setIsCtrlDown(false)
    if (event.key === 'Shift') setIsShiftDown(false)
    if (event.key === 'Alt') setIsAltDown(false)
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return { isCtrlDown, isShiftDown, isAltDown }
}

export default useKeyModifiers
