import * as React from 'react'

import { IIcon } from '.'

export const PaymentSettings: React.FCC<IIcon> = ({
  size = { x: 24, y: 24 },
  className = ' stroke-gray-400 w-6 h-6',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`0 0 ${size.x} ${size.y}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1999 4.79999C18.32 4.79999 18.8801 4.79999 19.3079 5.01797C19.6842 5.20972 19.9902 5.51568 20.1819 5.89201C20.3999 6.31983 20.3999 6.87988 20.3999 7.99999L20.3999 16C20.3999 17.1201 20.3999 17.6801 20.1819 18.108C19.9902 18.4843 19.6842 18.7903 19.3079 18.982C18.8801 19.2 18.32 19.2 17.1999 19.2L5.5999 19.2C4.4798 19.2 3.91974 19.2 3.49192 18.982C3.1156 18.7903 2.80963 18.4843 2.61789 18.108C2.3999 17.6801 2.3999 17.1201 2.3999 16L2.3999 7.99999C2.3999 6.87988 2.3999 6.31983 2.61789 5.89201C2.80964 5.51568 3.1156 5.20972 3.49192 5.01797C3.91974 4.79999 4.4798 4.79999 5.5999 4.79999L17.1999 4.79999Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.3999 9H20.3999"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 15H8.4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
