import * as React from 'react'

import * as styles from './time-slider-view.scss'

export interface IProps {
  left: number
  onMouseDown: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  label: string
}
export const TimeSliderView: React.FCC<IProps> = ({
  left,
  onMouseDown,
  label,
}) => (
  <div
    className={styles.container}
    style={{
      // @NOTE: 7.5px – half width of slider
      transform: `translateX(${left - 7.5}px)`,
    }}
  >
    <button
      type="button"
      className={styles.handler}
      onMouseDown={onMouseDown}
      // @NOTE: required to calculate selection in tracks
      data-model-type="time-slider"
    >
      <div className={styles.label}>
        {/* TODO: handle with different time formatters */}
        <p className={styles['label-text']}>{label}</p>
      </div>
    </button>

    <div className={styles.marker} />
  </div>
)
