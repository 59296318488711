/* eslint-disable react/require-default-props */
import Box from '@material-ui/core/Box'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Menu, { MenuProps } from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import classNames from 'classnames'
import React from 'react'

import * as styles from './index.scss'

type TDropdownItems = {
  title: string
  onClick: () => void
  icon?: React.ReactNode
  rightPart?: React.ReactNode | string
  disabled?: boolean
}

export interface IProps {
  items: Array<TDropdownItems | 'divider'>
  open: boolean
  width?: string
}
export const Dropdown: React.FCC<IProps & MenuProps> = ({
  items,
  width,
  open,
  ...rest
}) => (
  <Menu
    classes={{
      paper: styles.menu,
    }}
    open={open}
    {...rest}
  >
    {items.map((item, idx) => {
      if (item === 'divider') {
        return (
          <MenuItem
            classes={{
              root: classNames(
                styles['menu-item'],
                styles['menu-item--no-padding']
              ),
            }}
            key={`divider-${idx}`}
            value={`divider-${idx}`}
            disabled
            // @ts-ignore
            style={{ '--width': width }}
          >
            <div className={styles.divider} />
          </MenuItem>
        )
      }

      return (
        <MenuItem
          key={idx}
          classes={{
            root: item.disabled
              ? styles['menu-item-disabled']
              : styles['menu-item'],
          }}
          onClick={item.onClick}
          // @ts-ignore
          style={{ '--width': width }}
        >
          {/* @ts-ignore */}
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            maxWidth={item.rightPart ? '70%' : '100%'}
          >
            {item.icon && (
              <ListItemIcon classes={{ root: styles['list-item-icon'] }}>
                {item.icon}
              </ListItemIcon>
            )}

            <Typography noWrap classes={{ root: styles.title }}>
              {item.title}
            </Typography>
          </Box>

          {item.rightPart && (
            <span className={styles['right-part']}>{item.rightPart}</span>
          )}
        </MenuItem>
      )
    })}
  </Menu>
)
