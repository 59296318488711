import { useUploadLottie } from '@aninix/core/use-cases/lottie-preview/use-upload-lottie'
import { Popover } from '@material-ui/core'
import { config } from 'apps/web-app/src/config'
import classNames from 'classnames'
import { formatDistanceToNow } from 'date-fns'
import * as React from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'
import { toast } from '../../toasts'
import { exportBinary } from '../use-export-binary'

interface IProps {
  fileName: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  compressedLottie: Uint8Array | null
  expiredAt: string | null
  setExpiredAt: React.Dispatch<React.SetStateAction<string | null>>
}

export const PlayerHeader: React.FC<IProps> = ({
  fileName,
  handleChange,
  compressedLottie,
  expiredAt,
  setExpiredAt,
}) => {
  const [isUploading, setIsUploading] = React.useState(false)

  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const handleModalClose = React.useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleModalOpen = React.useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const [searchParams, setSearchParams] = useSearchParams()

  const copyLottieShareId = React.useCallback(
    (id: string, expiredAt: string | null) => {
      const urlToShare = `${config.webAppUrl}/lottie-preview?id=` + id
      navigator.clipboard.writeText(urlToShare)
      setSearchParams(createSearchParams({ id }))

      toast(
        `Link is copied.` +
          (expiredAt
            ? ` It will expire in ${formatDistanceToNow(new Date(expiredAt))}`
            : ''),
        {
          variant: 'success',
          toastProps: { className: 'bottom-16' },
        }
      )
    },
    [searchParams]
  )

  const initialExpiredAt = React.useRef<string | null>(expiredAt)

  const startFileShare = React.useCallback(() => {
    const id = searchParams.get('id')

    if (compressedLottie === null && id === null) {
      toast("There's no file to share", { variant: 'error' })
      return
    }

    if (id !== null) {
      copyLottieShareId(id, expiredAt)
      return
    }

    setIsUploading(true)

    const file = new File([compressedLottie!], fileName)

    uploadLottie
      .mutateAsync(file)
      .then((result) => {
        setIsUploading(false)
        copyLottieShareId(result.data.id, result.data.expiredAt)
        setExpiredAt(result.data.expiredAt)
      })
      .catch((e) => {
        toast("Couldn't upload file to share. Try again later", {
          variant: 'error',
        })
      })
  }, [compressedLottie, searchParams])

  const openShareModal = (url: string) => console.log(url)

  const { query: uploadLottie } = useUploadLottie()

  const exportAsButtonRef = React.useRef(null)
  const [isExportMenuOpen, setIsExportMenuOpen] = React.useState(false)

  const openExportMenu = React.useCallback(() => {
    setIsExportMenuOpen(true)
  }, [])
  const closeExportMenu = React.useCallback(() => {
    setIsExportMenuOpen(false)
  }, [])

  return (
    <>
      {/* <Modal
        onClose={handleModalClose}
        open={isModalOpen}
        className="flex items-center justify-center"
      >
        <div className="w-[450px] flex-col items-center justify-start rounded-xl bg-white py-4 shadow">
          <div className="flex flex-row items-center justify-between px-4">
            <p className="text-base leading-[23px] text-black text-opacity-80">
              Share animation
            </p>

            <button onClick={handleModalClose}>
              <CloseIcon />
            </button>
          </div>
          <div className="flex flex-col items-start justify-start gap-4 px-4 pt-4">
            <div className="flex flex-col items-start justify-start gap-1">
              <p className="text-base leading-[23px] text-black text-opacity-80">
                Share link
              </p>
              <p className="text-left text-base font-normal leading-[23px] text-gray-500">
                You can create a view-only link to this file.
              </p>
            </div>
            <div className="items-center justify-start gap-2">
              <div className="flex items-center justify-center gap-2 rounded-lg bg-blue-700 px-3 py-2">
                <LinkIcon />
                <p className="text-center text-sm leading-tight text-white">
                  Create and copy link
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal> */}

      {/* Export menu */}
      <Popover
        hideBackdrop
        PaperProps={{ style: { background: 'transparent' } }}
        onClick={closeExportMenu}
        elevation={0}
        open={isExportMenuOpen}
        anchorEl={exportAsButtonRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {compressedLottie && (
          <div className="pt-2">
            <div
              style={
                {
                  '--width':
                    ((
                      exportAsButtonRef?.current as HTMLElement | null
                    )?.getBoundingClientRect().width ?? 100) + 'px',
                } as React.CSSProperties
              }
              className="flex w-[--width] flex-col gap-0 overflow-hidden rounded-xl border border-[#5C65741A] bg-white"
            >
              <button
                onClick={() => exportBinary(compressedLottie, 'jsonLottie')}
                className="pointer-events-auto relative flex w-full flex-row items-center gap-2 border-opacity-10 px-3 py-[6px] hover:bg-gray-100"
              >
                <p className="w-full text-base font-normal text-black">.json</p>
              </button>
              <div className="h-[1px] w-full bg-[#5C65741A]" />
              <button
                onClick={() => exportBinary(compressedLottie, 'dotLottie')}
                className="pointer-events-auto relative flex w-full flex-row items-center gap-2 border-opacity-10 px-3 py-[6px] hover:bg-gray-100"
              >
                <p className="w-full text-base font-normal text-black">
                  .lottie
                </p>
              </button>
              <div className="h-[1px] w-full bg-[#5C65741A]" />
              <button
                onClick={() => exportBinary(compressedLottie, 'tgs')}
                className="pointer-events-auto relative flex w-full flex-row items-center gap-2 border-opacity-10 px-3 py-[6px] hover:bg-gray-100"
              >
                <p className="w-full text-base font-normal text-black">.tgs</p>
              </button>
            </div>
          </div>
        )}
      </Popover>
      <div className="flex w-full flex-col items-center justify-between gap-3 sm:flex-row sm:gap-0">
        <div className="flex flex-col items-center gap-0 sm:items-start">
          <p
            className={classNames(
              'text-2xl font-medium tracking-wide text-secondary transition-all duration-200',
              {
                ['translate-y-[12px]']: initialExpiredAt.current === null,
              }
            )}
          >
            {fileName}
          </p>

          <p
            className={classNames(
              'text-xs text-gray-400 transition-all duration-200',
              {
                ['opacity-0']: initialExpiredAt.current === null,
              }
            )}
          >
            Link expires in{' '}
            {expiredAt !== null ? formatDistanceToNow(new Date(expiredAt)) : ''}
          </p>
        </div>
        <div className="flex flex-row gap-2 px-1">
          {compressedLottie && (
            <button
              ref={exportAsButtonRef}
              onClick={openExportMenu}
              className={classNames(
                'pointer-events-auto relative flex w-max flex-row items-center gap-2 rounded-xl border border-[#5C65741A] border-opacity-10 px-3 py-[6px] transition-colors duration-200',
                { ['bg-gray-50']: isExportMenuOpen }
              )}
            >
              <p className="text-sm font-normal text-black sm:text-base">
                Export as
              </p>
              <ExportIcon />
            </button>
          )}
          <button
            onClick={startFileShare}
            className="pointer-events-auto relative flex w-max flex-row items-center gap-2 rounded-xl border border-[#5C65741A] border-opacity-10 px-3 py-[6px]"
          >
            <ShareIcon />
            <p className="text-sm font-normal text-black sm:text-base">Share</p>

            <div
              className={classNames(
                'absolute inset-0 z-20 flex items-center justify-center rounded-xl bg-[#FFFFFF]',
                {
                  ['opacity-0']: !isUploading,
                }
              )}
            >
              <div className="flex h-4 w-4 animate-spin items-center justify-center">
                <LoadingIcon />
              </div>
            </div>
          </button>
          <label className="pointer-events-auto flex w-max flex-row items-center gap-2 rounded-xl border border-[#5C65741A] border-opacity-10 px-3 py-[6px]">
            <input
              className="hidden"
              type={'file'}
              accept={'.lottie, .json, .tgs'}
              onChange={handleChange}
            />
            <UploadIcon />
            <p className="text-sm font-normal text-black sm:text-base">
              Upload a new file
            </p>
          </label>
        </div>
      </div>
    </>
  )
}

export const PlayerHeaderSkeleton = () => (
  <div className="flex w-full items-center justify-between gap-1">
    <div>
      <div className="pointer-events-auto relative flex w-max animate-pulse flex-row items-center gap-2 overflow-hidden rounded-xl border border-[#5C65741A] border-opacity-10 bg-gray-200 px-3 py-[6px] duration-200">
        <p className="text-base font-medium tracking-wide text-secondary opacity-0">
          Export as
        </p>
        <ExportIcon />
      </div>
    </div>

    <div className="flex flex-row gap-2">
      <div className="pointer-events-auto flex w-max animate-pulse flex-row items-center gap-2 rounded-xl border border-[#5C65741A] border-opacity-10 bg-gray-200 px-3 py-[6px]">
        <div className="opacity-0">
          <ShareIcon />
        </div>
        <p className="text-base font-normal text-black opacity-0">Share</p>
      </div>
      <div className="pointer-events-auto flex w-max animate-pulse flex-row items-center gap-2 rounded-xl border border-[#5C65741A] border-opacity-10 bg-gray-200 px-3 py-[6px]">
        <div className="opacity-0">
          <UploadIcon />
        </div>
        <p className="text-base font-normal text-black opacity-0">
          Upload a new file
        </p>
      </div>
    </div>
  </div>
)

const ExportIcon = () => (
  <svg
    width="8"
    height="8"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.646484 3.35359L1.35359 2.64648L4.00004 5.29293L6.64648 2.64648L7.35359 3.35359L4.00004 6.70714L0.646484 3.35359Z"
      fill="black"
    />
  </svg>
)

const LoadingIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 1.95146C5.98058 1.34634 7.11904 1 8.33333 1C12.0152 1 15 4.18416 15 8.11203C15 11.426 12.8754 14.2105 10 15"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.35735 4V4.0086M1 7.30258V7.31118M1.4928 10.8288V10.8374M3.68009 13.6411V13.6497M7 14.9914V15"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const CloseIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 2L2 14M2 2L14 14"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const LinkIcon = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4106_14499)">
      <path
        d="M5.37541 11.1261L10.6258 5.87563M7.12552 3.25041L7.53068 2.78137C8.35131 1.96084 9.46428 1.49992 10.6248 1.5C11.7852 1.50008 12.8981 1.96116 13.7186 2.78181C14.5392 3.60246 15.0001 4.71545 15 5.87594C14.9999 7.03643 14.5388 8.14935 13.7182 8.96988L13.2509 9.37592M8.87564 13.7513L8.52825 14.2186C7.69803 15.0395 6.57756 15.5 5.40998 15.5C4.2424 15.5 3.12192 15.0395 2.29171 14.2186C1.88249 13.8139 1.55762 13.3321 1.33589 12.8011C1.11417 12.27 1 11.7002 1 11.1247C1 10.5493 1.11417 9.97948 1.33589 9.44842C1.55762 8.91736 1.88249 8.43556 2.29171 8.03093L2.75024 7.62577"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4106_14499">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
)

const ShareIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.125 1H5.8C4.11984 1 3.27976 1 2.63803 1.32698C2.07354 1.6146 1.6146 2.07354 1.32698 2.63803C1 3.27976 1 4.11984 1 5.8V10.2C1 11.8802 1 12.7202 1.32698 13.362C1.6146 13.9265 2.07354 14.3854 2.63803 14.673C3.27976 15 4.11984 15 5.8 15H10.2C11.8802 15 12.7202 15 13.362 14.673C13.9265 14.3854 14.3854 13.9265 14.673 13.362C15 12.7202 15 11.8802 15 10.2V8"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 9V9C7.79572 7.77432 7.69358 7.16148 7.73629 6.66034C7.88081 4.96452 9.0826 3.54586 10.7316 3.12451C11.2189 3 11.8402 3 13.0828 3L15 3M15 3L13 1M15 3L13 5"
      stroke="#0B1118"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

const UploadIcon = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0714 1V4.11111C10.0714 4.31739 10.1542 4.51522 10.3016 4.66108C10.4489 4.80694 10.6488 4.88889 10.8571 4.88889H14M10.0714 1H4.57143C4.15466 1 3.75496 1.16389 3.46026 1.45561C3.16556 1.74733 3 2.143 3 2.55556V13.4444C3 13.857 3.16556 14.2527 3.46026 14.5444C3.75496 14.8361 4.15466 15 4.57143 15H12.4286C12.8453 15 13.245 14.8361 13.5397 14.5444C13.8344 14.2527 14 13.857 14 13.4444V4.88889M10.0714 1L14 4.88889M8.5 7.22222V11.8889M8.5 7.22222L6.53571 9.16667M8.5 7.22222L10.4643 9.16667"
      stroke="black"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

PlayerHeader.displayName = 'PlayerHeader'
