// @ts-nocheck
import { useViewport } from '@aninix/core'
import { KawaseBlurFilter } from '@pixi/filter-kawase-blur'
import * as PIXI from 'pixi.js'
import { NodeSnapshot } from '../../common/renderers/types'
import { TessGraphics } from './draw-vector-path'

export function applyLayerBlur(
  node: NodeSnapshot,
  graphics: TessGraphics,
  kindergarden: PIXI.Container<PIXI.DisplayObject>,
  app: PIXI.Application<PIXI.ICanvas>,
  postrenderCallbacks: (() => void)[],
  blurQuality: number
) {
  const viewport = useViewport()

  node.layerBlurs.forEach((blur) => {
    if (!blur.visible) return

    const { radius } = blur

    const blurFilter = new KawaseBlurFilter(
      (radius * viewport.zoom) / 3,
      blurQuality
    )
    blurFilter.resolution = 3

    // const blurFilter = new PIXI.BlurFilter(
    //   radius * stores.viewport.zoom,
    //   blurQuality,
    //   3
    // )

    kindergarden.filters?.push(blurFilter)
  })
}
