// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NbVrTSOXDtpgxYRteopd{display:grid;grid-template-columns:auto auto}.RT1hlYihGp5E0Z3JbriS{padding:8px}`, "",{"version":3,"sources":["webpack://./packages/editor/src/modules/animation-presets/router.scss"],"names":[],"mappings":"AAEA,sBACE,YAAA,CACA,+BAAA,CAGF,sBACE,WAAA","sourcesContent":["@use '../../theme/index.scss' as *;\n\n.presets {\n  display: grid;\n  grid-template-columns: auto auto;\n}\n\n.preset {\n  padding: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
export var presets = `NbVrTSOXDtpgxYRteopd`;
export var preset = `RT1hlYihGp5E0Z3JbriS`;
export default ___CSS_LOADER_EXPORT___;
