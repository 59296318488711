import * as React from 'react'

import { IRowOptions } from './context-menu'

export const ContextOption: React.FCC<IRowOptions> = ({
  Icon,
  label,
  onClick,
}) => (
  <div
    onClick={onClick}
    className=" flex flex-row items-center gap-2 px-4 py-2 hover:bg-white hover:bg-opacity-[15%]"
  >
    <Icon />
    <p className="font-body text-sm font-normal text-white">{label}</p>
  </div>
)

ContextOption.displayName = 'ContextOption'
