import {
  EntityType,
  EntityTypeComponent,
  ExportPreset,
  Project,
} from '@aninix-inc/model'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { Playback, Tools, Viewport } from '@aninix/core'
import * as React from 'react'

type Payload = {
  project: Project
  tools: Tools
  playback: Playback
  viewport: Viewport
}

export interface IExportInteractor {
  openModal: () => void
  closeModal: () => void

  project: Project
  tools: Tools
  playback: Playback
  viewport: Viewport

  children: {
    render: boolean
  }
}

/**
 * @todo move from application to regular storagescd
 */
export const useExportInteractor = ({
  project,
  tools,
  playback,
  viewport,
}: Payload): IExportInteractor => {
  const analytics = useAnalytics()
  const [isExporting, setIsExporting] = React.useState(false)

  const openModal: IExportInteractor['openModal'] = React.useCallback(() => {
    playback.pause()

    if (
      project.getEntitiesByPredicate(
        (e) =>
          e.getComponentOrThrow(EntityTypeComponent).value ===
          EntityType.ExportPreset
      )
    ) {
      project.createEntity(ExportPreset)
    }

    analytics.track({
      eventName: AnalyticsEvent.ExportPopupViewed,
    })
    setIsExporting(true)
  }, [analytics])

  const closeModal: IExportInteractor['closeModal'] = React.useCallback(() => {
    setIsExporting(false)
  }, [])

  return {
    openModal,
    closeModal,

    project,
    tools,
    playback,
    viewport,

    children: {
      render: isExporting,
    },
  }
}
