import type { Point2D } from '@aninix-inc/model/legacy'
import * as R from 'ramda'

const round = (number: number) => Math.round(number * 10_000) / 10_000

type Matrix3x1 = [number, number, number]
type Matrix3x3 = [
  [number, number, number],
  [number, number, number],
  [number, number, number],
]

function multiply(left: Matrix3x1, right: Matrix3x3): Matrix3x1 {
  return [
    left[0] * right[0][0] + left[1] * right[1][0] + left[2] * right[2][0],
    left[0] * right[0][1] + left[1] * right[1][1] + left[2] * right[2][1],
    left[0] * right[0][2] + left[1] * right[1][2] + left[2] * right[2][2],
  ]
}

type BezierPointType = {
  point: Point2D
  startTangent?: Point2D
  endTangent?: Point2D
}

export class BezierPoint {
  /**
   * @description base point
   */
  public point: Point2D

  /**
   * @description startTangent of the point
   * relative to base point
   */
  public startTangent: Point2D = {
    x: 0,
    y: 0,
  }

  /**
   * @description endTangent of the point
   * relative to base point
   */
  public endTangent: Point2D = {
    x: 0,
    y: 0,
  }

  constructor(payload: BezierPointType) {
    this.point = {
      x: round(payload.point.x),
      y: round(payload.point.y),
    }

    if (payload.startTangent != null) {
      this.startTangent = {
        x: round(payload.startTangent.x),
        y: round(payload.startTangent.y),
      }
    }

    if (payload.endTangent != null) {
      this.endTangent = {
        x: round(payload.endTangent.x),
        y: round(payload.endTangent.y),
      }
    }
  }

  public static fromAbsoluteJson(point: BezierPointType): BezierPoint {
    const startTangent = ((): BezierPointType['startTangent'] => {
      if (point.startTangent != null) {
        return {
          x: point.startTangent.x - point.point.x,
          y: point.startTangent.y - point.point.y,
        }
      }

      return { x: 0, y: 0 }
    })()

    const endTangent = ((): BezierPointType['endTangent'] => {
      if (point.endTangent != null) {
        return {
          x: point.endTangent.x - point.point.x,
          y: point.endTangent.y - point.point.y,
        }
      }

      return { x: 0, y: 0 }
    })()

    return new BezierPoint({
      startTangent,
      point: point.point,
      endTangent,
    })
  }

  public isLinear = () => {
    return (
      this.startTangent.x === 0 &&
      this.startTangent.y === 0 &&
      this.endTangent.x === 0 &&
      this.endTangent.y === 0
    )
  }

  // @NOTE: check if 2 tangents lies on the same line. We do this by check of square of triangle startTangent, point, endTangent
  public isContinuous = () => {
    const ax = this.point.x + this.startTangent.x
    const ay = this.point.y + this.startTangent.y
    const bx = this.point.x
    const by = this.point.y
    const cx = this.point.x + this.endTangent.x
    const cy = this.point.y + this.endTangent.y

    return round((by - ay) * (cx - ax)) == round((bx - ax) * (cy - ay))
  }

  /**
   * @description rotate point related to origin: { x: 0, y: 0 }
   * @param payload.angle – angle in radians
   */
  public rotateBy(angle: number) {
    const rotationMatrix: Matrix3x3 = [
      [Math.cos(angle), -Math.sin(angle), 0],
      [Math.sin(angle), Math.cos(angle), 0],
      [0, 0, 1],
    ]

    this.transformByMatrix(rotationMatrix)

    return this
  }

  public rotatedBy(angle: number) {
    const newPoint = this.clone()
    newPoint.rotateBy(angle)
    return newPoint
  }

  /**
   * @description shift point by provided offset
   * @param payload.offset – 2d point to offset
   */
  public translateBy(offset: Point2D) {
    this.point = {
      x: this.point.x + offset.x,
      y: this.point.y + offset.y,
    }

    return this
  }

  public translatedBy(offset: Point2D) {
    const newPoint = this.clone()
    return newPoint.translateBy(offset)
  }

  public scaleBy(scale: Point2D) {
    const scaleMatrix: Matrix3x3 = [
      [scale.x, 0, 0],
      [0, scale.y, 0],
      [0, 0, 1],
    ]

    this.transformByMatrix(scaleMatrix)

    return this
  }

  public scaledBy(scale: Point2D) {
    const newPoint = this.clone()
    return newPoint.scaleBy(scale)
  }

  public equals = (point: BezierPoint) => {
    return (
      this.point.x === point.point.x &&
      this.point.y === point.point.y &&
      this.startTangent.x === point.startTangent.x &&
      this.startTangent.y === point.startTangent.y &&
      this.endTangent.x === point.endTangent.x &&
      this.endTangent.y === point.endTangent.y
    )
  }

  public dotProduct = (point: BezierPoint) => {
    const a = this.normalized().point
    const b = point.normalized().point

    return a.x * b.x + a.y * b.y
  }

  public angleBetween = (point: BezierPoint) => {
    const magnitude = this.magnitude()
    const pointMagnitude = point.magnitude()

    if (magnitude === 0 || pointMagnitude === 0) {
      return 0
    }

    const angleCos = R.clamp(
      -1,
      1,
      (this.point.x * point.point.x + this.point.y * point.point.y) /
        (magnitude * pointMagnitude)
    )

    return Math.acos(angleCos)
  }

  /**
   * @description convert current class to json representation
   */
  public toJson = () => ({
    point: this.point,
    startTangent: this.startTangent,
    endTangent: this.endTangent,
  })

  /**
   * @description convert current class to json representation
   * with absolute coordinates
   */
  public toAbsoluteJson = () => ({
    point: this.point,
    startTangent: {
      x: this.point.x + this.startTangent.x,
      y: this.point.y + this.startTangent.y,
    },
    endTangent: {
      x: this.point.x + this.endTangent.x,
      y: this.point.y + this.endTangent.y,
    },
  })

  public clone = () => BezierPoint.fromAbsoluteJson(this.toAbsoluteJson())

  public updateRelativeStartTangent = (
    point: BezierPointType['startTangent']
  ) => {
    this.startTangent = {
      x: point!.x,
      y: point!.y,
    }

    return this
  }

  public updateAbsoluteStartTangent = (
    point: BezierPointType['startTangent']
  ) => {
    this.startTangent = {
      x: point!.x - this.point.x,
      y: point!.y - this.point.y,
    }

    return this
  }

  public updateRelativeEndTangent = (point: BezierPointType['endTangent']) => {
    this.endTangent = {
      x: point!.x,
      y: point!.y,
    }

    return this
  }

  public updateAbsoluteEndTangent = (point: BezierPointType['endTangent']) => {
    this.endTangent = {
      x: point!.x - this.point.x,
      y: point!.y - this.point.y,
    }

    return this
  }

  public resetStartTangent = () => {
    this.startTangent = {
      x: 0,
      y: 0,
    }
    return this
  }

  public resetEndTangent = () => {
    this.endTangent = {
      x: 0,
      y: 0,
    }
    return this
  }

  /**
   * @description flip tangents by assigning start = end, end = start
   */
  public flipTangents = () => {
    const bufferTangent = R.clone(this.startTangent)
    this.startTangent = R.clone(this.endTangent)
    this.endTangent = bufferTangent

    return this
  }

  public distanceTo = (point: BezierPoint) => {
    const difference = new BezierPoint({
      point: {
        x: point.point.x - this.point.x,
        y: point.point.y - this.point.y,
      },
    })

    const distance = Math.sqrt(
      difference.point.x * difference.point.x +
        difference.point.y * difference.point.y
    )

    return distance
  }

  public magnitude = () => {
    return this.distanceTo(new BezierPoint({ point: { x: 0, y: 0 } }))
  }

  public subtract = (point: BezierPoint) => {
    this.point = {
      x: this.point.x - point.point.x,
      y: this.point.y - point.point.y,
    }
    return this
  }

  public subtractedBy = (point: BezierPoint) => {
    const newPoint = this.clone()
    return newPoint.subtract(point)
  }

  public add = (point: BezierPoint) => {
    this.point = {
      x: this.point.x + point.point.x,
      y: this.point.y + point.point.y,
    }

    return this
  }

  public addedBy = (point: BezierPoint) => {
    const newPoint = this.clone()
    return newPoint.add(point)
  }

  public multiply = (number: number) => {
    this.point = {
      x: this.point.x * number,
      y: this.point.y * number,
    }

    return this
  }

  public multipliedBy = (number: number) => {
    const newPoint = this.clone()
    return newPoint.multiply(number)
  }

  public normalized = (): BezierPoint => {
    const length = this.magnitude()

    if (length === 0) {
      return new BezierPoint({
        point: {
          x: 1,
          y: 0,
        },
      })
    }

    return new BezierPoint({
      point: {
        x: this.point.x / length,
        y: this.point.y / length,
      },
    })
  }

  /**
   * @description helper for matrix transformation
   */
  private transformByMatrix(transformMatrix: Matrix3x3) {
    const [x, y] = multiply([this.point.x, this.point.y, 1], transformMatrix)

    this.point = { x: round(x), y: round(y) }

    const [startTangentX, startTangentY] = multiply(
      [this.startTangent.x, this.startTangent.y, 1],
      transformMatrix
    )

    this.startTangent = { x: round(startTangentX), y: round(startTangentY) }

    const [endTangentX, endTangentY] = multiply(
      [this.endTangent.x, this.endTangent.y, 1],
      transformMatrix
    )

    this.endTangent = { x: round(endTangentX), y: round(endTangentY) }

    return this
  }
}
