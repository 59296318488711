import { DurationComponent, Project, Root } from '@aninix-inc/model'
import { usePlayback } from '@aninix/core'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import * as React from 'react'

const secondsToMS = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return seconds < 1
    ? format(date, 'SSS') + ' ms'
    : format(date, 'sSSS') + ' ms'
}

const secondsToSS = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return format(date, 's.S') + ' s'
}

export interface IProps {
  project: Project
}
export const CurrentTime: React.FCC<IProps> = observer(({ project }) => {
  const playback = usePlayback()
  const totalTime = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value
  const playbackTime = (totalTime <= 1 ? secondsToMS : secondsToSS)(
    playback.time
  )
  return (
    <p className="m-0 min-w-[54px] shrink-[1] select-none text-center font-mono text-[11px] text-black text-opacity-30">
      {playbackTime}
    </p>
  )
})

CurrentTime.displayName = 'CurrentTime'
