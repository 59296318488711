import { Tool, useTools } from '@aninix/core/stores'
import { IComment } from '@aninix/core/use-cases/comments/use-get-comments'
import _ from 'lodash'
import * as React from 'react'
import { useSearchParams } from 'react-router-dom'

interface CommentContextType {
  selectedCommentId: string | null
  setSelectedCommentId: React.Dispatch<React.SetStateAction<string | null>>
  filterMode: FilterMode
  setFilterMode: React.Dispatch<React.SetStateAction<FilterMode>>
  searchText: string | undefined
  setSearchText: React.Dispatch<React.SetStateAction<string | undefined>>
}

const CommentContext = React.createContext<CommentContextType | undefined>(
  undefined
)

export type FilterMode = 'all' | 'notResolved'

export const filter = (
  comments: IComment[],
  filterMode: FilterMode,
  searchText?: string
) => {
  let filteredComments = (() => {
    switch (filterMode) {
      case 'all':
        return comments
      case 'notResolved':
        return comments.filter((comment) => !comment.isResolved)
      default:
        return comments
    }
  })()

  const filterSearchText =
    searchText === undefined
      ? filteredComments
      : filteredComments.filter(
          (comment) =>
            comment.comment
              .replace('\n', ' ')
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            _.some(
              comment.replies?.filter((reply) =>
                reply.comment
                  .replace('\n', ' ')
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              )
            )
        )

  return filterSearchText
}

export const CommentContextProvider: React.FCC = ({ children }) => {
  const tools = useTools()

  let [searchParams, setSearchParams] = useSearchParams()

  const [selectedCommentId, setSelectedCommentId] = React.useState<
    string | null
  >(null)

  const [filterMode, setFilterMode] = React.useState<FilterMode>('notResolved')

  const [searchText, setSearchText] = React.useState<string | undefined>(
    undefined
  )

  //Valid example:
  //?tool=comments&commentFilterMode=all&commentId=bf17032b-f222-47a6-a7c0-11b085f83d02
  React.useMemo(() => {
    const paramsCommentId = searchParams.get('commentId')

    if (paramsCommentId !== null) {
      tools.changeTool(Tool.Comments)
      setFilterMode('all')
      setSelectedCommentId(paramsCommentId)
    }

    setTimeout(() => {
      setSearchParams(new URLSearchParams(''))
    }, 0)
  }, [])

  return (
    <CommentContext.Provider
      value={{
        selectedCommentId,
        setSelectedCommentId,
        filterMode,
        setFilterMode,
        searchText,
        setSearchText,
      }}
    >
      {children}
    </CommentContext.Provider>
  )
}

export const useCommentContext = (): CommentContextType => {
  const context = React.useContext(CommentContext)
  if (context === undefined) {
    throw new Error(
      'useCommentContext must be used within a CommentContextProvider'
    )
  }
  return context
}
