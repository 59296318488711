import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 24,
  y: 24,
}

export const Pro: React.FC<IIcon> = ({
  size = defaultSize,
  color = '#0B1118',
  className,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3C10.8181 3 9.64778 3.23279 8.55585 3.68508C7.46392 4.13738 6.47177 4.80031 5.63604 5.63604C4.80031 6.47177 4.13738 7.46392 3.68508 8.55585C3.23279 9.64778 3 10.8181 3 12C3 13.1819 3.23279 14.3522 3.68508 15.4442C4.13738 16.5361 4.80031 17.5282 5.63604 18.364C6.47177 19.1997 7.46392 19.8626 8.55585 20.3149C9.1823 20.5744 9.83456 20.7616 10.5 20.8741"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9 10C9 10.7956 9.31607 11.5587 9.87868 12.1213C10.4413 12.6839 11.2044 13 12 13C12.7956 13 13.5587 12.6839 14.1213 12.1213C14.6839 11.5587 15 10.7956 15 10C15 9.20435 14.6839 8.44129 14.1213 7.87868C13.5587 7.31607 12.7956 7 12 7C11.2044 7 10.4413 7.31607 9.87868 7.87868C9.31607 8.44129 9 9.20435 9 10Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.16797 18.849C6.41548 18.0252 6.92194 17.3032 7.61222 16.79C8.30249 16.2768 9.13982 15.9997 9.99997 16L10.5 16"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.2851 21.5951C18.7416 21.3103 18.4699 21.168 18.1837 21.112C17.9303 21.0625 17.6697 21.0625 17.4163 21.112C17.1301 21.168 16.8584 21.3103 16.3149 21.5951L15.628 21.955C15.5635 21.9885 15.491 22.0035 15.4186 21.9982C15.3461 21.9929 15.2765 21.9676 15.2176 21.9251C15.1587 21.8826 15.1127 21.8245 15.0849 21.7574C15.0571 21.6903 15.0485 21.6167 15.06 21.545L15.1888 20.7965C15.2935 20.1882 15.3459 19.8841 15.3106 19.5931C15.2794 19.3354 15.1983 19.0863 15.0718 18.8596C14.9291 18.6036 14.7077 18.3885 14.2651 17.9585L13.718 17.427C13.6656 17.3763 13.6284 17.3119 13.6108 17.2411C13.5933 17.1703 13.5959 17.096 13.6186 17.0266C13.6412 16.9573 13.6829 16.8957 13.7388 16.8489C13.7948 16.8021 13.8627 16.772 13.935 16.762L14.7001 16.651C15.3086 16.5628 15.6128 16.5187 15.8779 16.3959C16.1126 16.2871 16.3239 16.1338 16.5003 15.9445C16.6994 15.7307 16.8358 15.4552 17.1087 14.9042L17.449 14.217C17.4815 14.1517 17.5315 14.0967 17.5935 14.0583C17.6556 14.0199 17.727 13.9995 17.8 13.9995C17.8729 13.9995 17.9444 14.0199 18.0064 14.0583C18.0685 14.0967 18.1185 14.1517 18.151 14.217L18.4913 14.9042C18.7642 15.4552 18.9006 15.7307 19.0997 15.9445C19.276 16.1338 19.4874 16.2871 19.7221 16.3959C19.9871 16.5187 20.2914 16.5628 20.8999 16.651L21.665 16.762C21.737 16.7723 21.8047 16.8027 21.8604 16.8495C21.9162 16.8963 21.9576 16.9578 21.9802 17.027C22.0028 17.0962 22.0056 17.1703 21.9882 17.241C21.9708 17.3117 21.9341 17.3761 21.882 17.427L21.3344 17.9589C20.8919 18.3888 20.6707 18.6038 20.5279 18.8597C20.4015 19.0863 20.3204 19.3353 20.2891 19.5929C20.2538 19.8838 20.306 20.1879 20.4105 20.7959L20.539 21.544C20.5514 21.6158 20.5434 21.6898 20.516 21.7573C20.4885 21.8249 20.4426 21.8834 20.3836 21.9262C20.3245 21.969 20.2547 21.9944 20.1819 21.9995C20.1092 22.0046 20.0364 21.9891 19.972 21.955L19.2851 21.5951Z"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
