import classNames from 'classnames'
import * as React from 'react'

export interface IProps {
  isOpen: boolean
  durationMs?: number
}
export const AutoHeightPanel: React.FCC<IProps> = ({
  isOpen,
  durationMs = 150,

  children,
}) => {
  const contentRef = React.useRef<HTMLDivElement>(null)

  const [forceUpdateIterator, setForceUpdateIterator] = React.useState(0)

  const recalculateHeight = () => setForceUpdateIterator((v) => v + 1)

  const contentHeight = React.useMemo(
    () =>
      (
        contentRef.current?.firstChild as HTMLDivElement | undefined
      )?.getBoundingClientRect().height,
    [forceUpdateIterator]
  )

  const [transitionIsGoing, setTransitionIsGoing] = React.useState(false)

  const [localOpen, setLocalOpen] = React.useState(isOpen)

  const resizeObserver = new ResizeObserver(() => recalculateHeight())

  // this is required to avoid problems when text is changing its height
  // we make local open to be dependent of prop value
  // then we make transition available for exactly transition duration
  // therefore transition is working only when isOpen changes,
  // but doesn't work right before and in 150ms after change
  React.useEffect(() => {
    recalculateHeight()

    if (isOpen) {
      resizeObserver.observe(contentRef.current?.firstChild as Element)
    }

    if (!isOpen) {
      resizeObserver.unobserve(contentRef.current?.firstChild as Element)
    }

    setLocalOpen(isOpen)
    setTransitionIsGoing(true)
    setTimeout(() => setTransitionIsGoing(false), durationMs)
  }, [isOpen])

  return (
    <div
      style={
        {
          overflow: localOpen && !transitionIsGoing ? 'visible' : 'hidden',
          maxHeight: localOpen ? `${contentHeight}px` : '0px',
          height: 'fit-content',
          '--durationMs': `${durationMs}ms`,
        } as React.CSSProperties
      }
      ref={contentRef}
      className={classNames('', {
        ['transition-[max-height] duration-[var(--durationMs)]']:
          transitionIsGoing,
      })}
    >
      {children}
    </div>
  )
}

AutoHeightPanel.displayName = 'AutoHeightPanel'
