import * as React from 'react'

export const useWindowResize = (callback: (e?: UIEvent) => void) => {
  React.useEffect(() => {
    window.addEventListener('resize', callback)
    callback()

    return () => window.removeEventListener('resize', callback)
  }, [])
}
