import { useThrottle } from '@aninix/app-design-system'
import * as React from 'react'
import {
  BASE_OFFSET_STEP_SPEED,
  BOTTOM_DELTA_THRESHOLD,
  LIST_BOTTOM_OFFSET,
  ROW_HEIGHT,
  TOP_DELTA_THRESHOLD,
} from './config'
import { useScrollSync } from './scroll-sync-context'

type UseAutoScrollSyncArgs = {
  containerRef: React.RefObject<HTMLElement>
  listContainerRef: React.RefObject<HTMLElement>
  rowsCount: number
  enabled: boolean
}

const isAbleToScroll = (
  direction: 'top' | 'bottom',
  step: number,
  scrollTop: number,
  rowsHeight: number,
  containerHeight: number
) => {
  if (direction === 'top' && scrollTop <= step) return false

  if (
    direction === 'bottom' &&
    rowsHeight - scrollTop + LIST_BOTTOM_OFFSET <= containerHeight
  ) {
    return false
  }

  return true
}

export const useAutoScrollSync = (args: UseAutoScrollSyncArgs) => {
  const { enabled, rowsCount, containerRef, listContainerRef } = args
  const rowsHeight = rowsCount * ROW_HEIGHT
  const animationFrameRef = React.useRef<number | null>(null)
  const [containerMouseY, setContainerMouseY] = React.useState<number>(0)

  const { scrollTop, scrollTo } = useScrollSync()

  const stop = React.useCallback(() => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current)
      animationFrameRef.current = null
    }
  }, [])

  const scroll = useThrottle({
    callback: React.useCallback(
      (direction: 'top' | 'bottom', stepOffset: number) => {
        if (!containerRef.current) return
        const containerRect = containerRef.current.getBoundingClientRect()
        const containerHeight = containerRect.height
        const containerWidth = containerRect.width
        const step = direction === 'top' ? -stepOffset : stepOffset

        if (
          !isAbleToScroll(
            direction,
            step,
            scrollTop,
            rowsHeight,
            containerHeight
          )
        ) {
          return
        }

        animationFrameRef.current = requestAnimationFrame(() => {
          scrollTo({
            scrollTop: scrollTop + step,
            clientHeight: containerHeight,
            clientWidth: containerWidth,
            scrollWidth: containerWidth,
          })
        })
      },
      [scrollTop, scrollTo]
    ),
    delay: 16.67,
  })

  React.useEffect(() => {
    const onMouseMove = (e: MouseEvent) => {
      setContainerMouseY(
        e.clientY - (containerRef.current?.getBoundingClientRect()?.top ?? 0)
      )
    }

    if (enabled && containerRef.current) {
      document.addEventListener('mousemove', onMouseMove)
    }

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
    }
  }, [enabled])

  React.useEffect(() => {
    stop()

    if (!enabled || !listContainerRef.current) return

    const listHeight = listContainerRef.current.getBoundingClientRect().height

    const delta = containerMouseY / listHeight

    const isTopDirection = delta < TOP_DELTA_THRESHOLD
    const isBottomDirection = delta > BOTTOM_DELTA_THRESHOLD

    const offsetStep =
      Math.abs(isTopDirection ? delta - 1 : delta) * BASE_OFFSET_STEP_SPEED

    if (isTopDirection) scroll('top', offsetStep)
    if (isBottomDirection) scroll('bottom', offsetStep)

    // console.group('use-auto-scroll-sync')
    // console.log('listHeight', listHeight)
    // console.log('containerMouseY', containerMouseY)
    // console.log('delta', delta)
    // console.log('isTopDirection', isTopDirection)
    // console.log('isBottomDirection', isBottomDirection)
    // console.log('offsetStep', offsetStep)
    // console.groupEnd()
  }, [enabled, scroll, containerMouseY])
}
