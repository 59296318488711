import * as React from 'react'

const defaultSize = { x: 12, y: 12 }

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const Copy: React.FCC<IProps> = ({ size = defaultSize, style }) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M2 2H10V10H2V2Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeLinecap="square"
    />
    <path
      d="M12 6H14V14H6V12"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeLinecap="square"
      strokeDasharray="2 2"
    />
  </svg>
)
