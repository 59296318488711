import * as React from 'react'
import { renderToString } from 'react-dom/server'
import animationJson from './select-project-animation.json'

export interface IProps {
  label: string
}
export const SelectProjectAnimationItem: React.FCC<IProps> = ({ label }) => (
  <div
    className="scale-[1.3]"
    dangerouslySetInnerHTML={{
      __html: animationJson.animation.replace(
        '$foreignPlaceholder',
        renderToString(
          <div className="w-full h-full flex items-center justify-center text-center">
            <p className="text-[12px]/[16px] font-normal">{label}</p>
          </div>
        )
      ),
    }}
  />
)

SelectProjectAnimationItem.displayName = 'SelectProjectAnimationItem'
