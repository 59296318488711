import {
  ColorStop,
  ColorStopsRelationsAspect,
  Entity,
  FillsRelationsAspect,
  LinearGradientPaint,
  RadialGradientPaint,
  StrokesRelationsAspect,
} from '@aninix-inc/model'
import { Gradient } from '../../types'

export const applyGradient = (
  entity: Entity,
  gradient: Gradient,
  aspect: FillsRelationsAspect | StrokesRelationsAspect
) => {
  if (!gradient) return

  const paint = entity
    .getProjectOrThrow()
    .createEntity(
      gradient.type === 'linear' ? LinearGradientPaint : RadialGradientPaint
    )

  const csAspect = paint.getAspectOrThrow(ColorStopsRelationsAspect)

  gradient.stops.forEach((stop) => {
    const colorStop = paint.getProjectOrThrow().createEntity(ColorStop, {
      color: stop.color,
      progress: stop.progress,
    })

    csAspect.addChild(colorStop)
  })

  aspect.addChild(paint)
}
