import { getEntryOrThrow, getPosition, getSize } from '@aninix-inc/model'
import { observer } from 'mobx-react'
import * as React from 'react'
import { Viewport } from '../../../modules/viewport'
import { useProject, useViewport } from '../../../stores'
import { Indicators } from './details/timeline/controls/mobile-indicators'
import { Playback } from './player/controls/playback'

export interface IProps {}
export const MobilePlayer: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const viewport = useViewport()

  React.useEffect(() => {
    const entry = getEntryOrThrow(project)
    const position = getPosition(entry)
    const size = getSize(entry)

    const padding = 100

    // @NOTE: zoom to fit
    viewport.zoomToRect({
      x: position.x - padding / 2,
      y: position.y - padding / 2,
      width: size.x + padding,
      height: size.y + padding,
    })
  }, [viewport.size])

  return (
    <div className="h-full w-full bg-[var(--background-white-2)]">
      <Viewport project={project} editable={false} />
      <div className="absolute bottom-0 flex h-[48px] w-full flex-row bg-white">
        <div className="flex w-full flex-row justify-between pt-1">
          <Playback project={project} />
          <Indicators />
        </div>
      </div>
    </div>
  )
})

MobilePlayer.displayName = 'MobilePlayer'
