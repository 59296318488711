import { round } from './round'
import { Vector } from './types'

export function getVectorMagnitude(vector: Vector): number {
  const magnitude = Math.sqrt(
    (vector.end.x - vector.start.x) ** 2 + (vector.end.y - vector.start.y) ** 2
  )

  return round(magnitude)
}
