import classNames from 'classnames'
import * as React from 'react'

import { useCurrentTeamStore, useCurrentUser } from '../../use-cases'
import { Icons } from '../icons'
import { MemberRowContextMenu } from './member-row/context-menu'
import { useCurrentRowContext } from './use-current-row-context'

export interface IProps {}

export const Options: React.FCC<IProps> = () => {
  const [displayOptions, setDisplayOptions] = React.useState<boolean>(false)

  const options = React.useMemo(() => {
    return {
      show: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent | null
      ) => {
        e?.preventDefault()
        e?.stopPropagation()
        setDisplayOptions(true)
      },
      hide: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent | null
      ) => {
        e?.preventDefault()
        e?.stopPropagation()
        setDisplayOptions(false)
      },
      toggleDisplay: (
        e: React.MouseEvent<HTMLDivElement, MouseEvent> | MouseEvent | null
      ) => {
        e?.preventDefault()
        e?.stopPropagation()
        setDisplayOptions(!displayOptions)
      },
    }
  }, [displayOptions])

  const currentRowContext = useCurrentRowContext()

  React.useEffect(() => {
    currentRowContext?.rowRef?.current?.addEventListener(
      'mouseleave',
      options.hide
    )

    return () => {
      currentRowContext?.rowRef?.current?.removeEventListener(
        'mouseleave',
        options.hide
      )
    }
  })

  const { userId } = useCurrentUser()

  const isYourUser = currentRowContext.id == userId

  const { currentTeam } = useCurrentTeamStore()

  const isAllowedToManage =
    ['owner', 'manager'].includes(currentTeam!.adminRole) && !isYourUser

  if (!isAllowedToManage) return null

  return (
    <div
      onClick={options.toggleDisplay}
      className={classNames(
        'relative z-10 flex items-center rounded-md bg-white bg-opacity-40 p-1 transition-all duration-300 hover:bg-gray-100',
        {
          [' z-50 !bg-black !bg-opacity-100']: displayOptions,
        }
      )}
    >
      <Icons.Dots
        className={classNames('stroke-gray-500 transition-all duration-300', {
          ['!stroke-white']: displayOptions,
        })}
      />
      <MemberRowContextMenu
        handleClose={options.hide}
        isShown={displayOptions}
      />
    </div>
  )
}

Options.displayName = 'Options'
