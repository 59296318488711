import * as React from 'react'

import { IIcon } from '.'

export const People: React.FCC<IIcon> = ({
  size = { x: 24, y: 24 },
  className = 'stroke-gray-400 h-6 w-6',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox={`2 2 ${size.x - 6} ${size.y - 6}`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="3"
      y="2.5"
      width="14"
      height="16"
      rx="3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10 13.5C11.3807 13.5 12.5 12.3807 12.5 11C12.5 9.61929 11.3807 8.5 10 8.5C8.61929 8.5 7.5 9.61929 7.5 11C7.5 12.3807 8.61929 13.5 10 13.5Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 15.5C6.90756 14.879 7.436 14.375 8.04348 14.0279C8.65096 13.6807 9.32079 13.5 9.99996 13.5C10.6791 13.5 11.349 13.6807 11.9565 14.0278C12.564 14.3749 13.0924 14.8789 13.5 15.4998"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 5.5H12"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
