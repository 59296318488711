import classNames from 'classnames'
import * as React from 'react'

import * as styles from './index.scss'

export interface IProps {
  name?: React.ReactNode
  headerButtons?: React.ReactNode
  inputs?: React.ReactNode
  wideInputs?: React.ReactNode
  empty?: boolean
  onHeaderClick?: () => void
  wide?: boolean
}
/**
 * @description property row used in properties panel
 */
export const PropertyRow: React.FCC<IProps> = ({
  name,
  headerButtons,
  inputs,
  wideInputs,
  empty = false,
  onHeaderClick,
  wide = false,
}) => {
  const header = (() => {
    if (name == null) {
      return null
    }

    if (onHeaderClick != null) {
      return (
        <button
          onClick={onHeaderClick}
          className={classNames(styles.title, styles['title--clickable'])}
        >
          {name}
        </button>
      )
    }

    return <p className={styles.title}>{name}</p>
  })()

  return (
    <div className={styles.wrapper}>
      <div
        className={classNames(styles.container, {
          [styles['container--empty']]: empty,
        })}
      >
        <div>
          {(name != null || headerButtons != null) && (
            <div
              className={classNames(styles.header, {
                [styles['header--wide']]: wide,
              })}
            >
              {header}

              <div className={styles.buttons}>{headerButtons}</div>
            </div>
          )}

          {inputs != null && (
            <div className={styles['inputs-wrapper']}>
              {React.Children.map(inputs, (child, idx) => (
                <div className={styles.inputs} key={idx}>
                  {child}
                </div>
              ))}
            </div>
          )}
        </div>

        {wideInputs != null && (
          <div className={styles['wide-inputs-wrapper']}>
            {React.Children.map(wideInputs, (child, idx) => (
              <div className={styles.inputs} key={idx}>
                {child}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}
