import * as React from 'react'

import { LinkButton } from '../../components/design-system/button'
import { Typography } from '../../components/design-system/typography'
import { Icons } from '../../components/icons'

export interface IProps {}
export const CreateNewTeamBanner: React.FCC<IProps> = () => {
  return (
    <div className="flex flex-col items-start gap-2 rounded-2xl bg-accent bg-opacity-10 p-3">
      <div className="flex flex-row items-center gap-2">
        <Icons.Users />
        <Typography style="Body4Medium" className="text-secondary">
          Start a team on Aninix
        </Typography>
      </div>
      <Typography style="Body5Regular">
        Onboard your team and start working on the animations together.
      </Typography>

      <LinkButton
        to="../teams/new?utm_source=side-menu-banner"
        label="Create a new team"
        className="w-full !bg-accent"
      />
    </div>
  )
}

CreateNewTeamBanner.displayName = 'CreateNewTeamBanner'
