import * as React from 'react'

export interface IProps {
  currentStep?: 'addInfo' | 'addAvatar'
  setCurrentStep?: React.Dispatch<React.SetStateAction<'addInfo' | 'addAvatar'>>
  teamId?: string | null
  setTeamId?: React.Dispatch<React.SetStateAction<string | null>>
}

export const CurrentStepContext = React.createContext<IProps>({})

export const useCurrentStepContext = () => React.useContext(CurrentStepContext)

useCurrentStepContext.displayName = 'useCurrentStepContext'
