import * as React from 'react'

const defaultSize = {
  x: 32,
  y: 32,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const SelectMultipleFrames: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M18 24L16 16L23.5 19L20 20.5L18 24Z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeLinecap="square"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.9422 13.0004H20.4522V12.3342L22.2196 10.6016C22.7217 10.092 22.9802 9.78871 22.9802 9.36364C22.9802 8.88388 22.6198 8.58807 22.1351 8.58807C21.628 8.58807 21.2998 8.91371 21.2998 9.43324H20.4224C20.4224 8.46626 21.1383 7.83984 22.1475 7.83984C23.1717 7.83984 23.8652 8.46378 23.8652 9.31641C23.8652 9.89062 23.5918 10.353 22.6024 11.3026L21.7249 12.195V12.2298H23.9422V13.0004ZM10 24L10 22H8V21H10L10 11H8V10H10L10 8L11 8L11 10H19V11H11L11 21H14V22H11L11 24H10Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      fillOpacity={0.3}
    />
  </svg>
)
