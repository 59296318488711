import React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const KeyframeSpeedOut: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g clipPath="url(#clip0_37641_14999)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9996 0.499631C12.9226 0.499631 11.0229 1.01924 9.64608 2.39608C8.26924 3.77292 7.49963 5.92256 7.49963 8.99963L8.49963 8.99963C8.49963 6.0767 9.23002 4.22634 10.3532 3.10318C11.4763 1.98002 13.0767 1.49963 14.9996 1.49963L14.9996 0.499631ZM3.11327 8.37182C2.90825 8.1668 2.90825 7.83441 3.11327 7.62939L7.5149 3.22777L6.77247 2.48534L2.37084 6.88697C1.7558 7.50201 1.7558 8.4992 2.37084 9.11424L6.88746 13.6309C7.5025 14.2459 8.49969 14.2459 9.11473 13.6309L13.6313 9.11424C14.2464 8.4992 14.2464 7.50201 13.6313 6.88697L11.373 4.62866L10.6306 5.37109L12.8889 7.62939C13.0939 7.83441 13.0939 8.1668 12.8889 8.37182L8.37231 12.8884C8.16729 13.0934 7.8349 13.0934 7.62988 12.8884L3.11327 8.37182Z"
        fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      />
    </g>
    <defs>
      <clipPath id="clip0_37641_14999">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
