import React from 'react'

interface Props {
  className?: string
}

export const NewFolderButton = (props: Props) => {
  return (
    <svg
      className={props.className ?? ''}
      height="40"
      width="42"
      fill="none"
      viewBox="0 0 42 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M29 15H21L18 13H15C13.8954 13 13 13.8954 13 15V25C13 26.1046 13.8954 27 15 27H29M26 18V24M23 21H29"
        stroke="#89909A"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.6"
      />
      <rect height="39" width="41" rx="7.5" stroke="#E6EAED" x="0.5" y="0.5" />
    </svg>
  )
}
