import {
  Component,
  Entity,
  FpsComponent,
  Project,
  Root,
  TimeComponent,
  TimingCurveComponent,
  getAnimatableValue,
  getTimingCurveKeyframes,
} from '@aninix-inc/model'
import * as R from 'ramda'

import { Point2D } from '@aninix-inc/model/static-types'
import { LottieKeyframe, LottieProperty } from '../types'

const noop = () => []

const mapNumber = (payload: {
  value: number
  isPercent?: boolean
  isAnimated?: boolean
}) => {
  const { value, isPercent, isAnimated } = payload
  const coefficient = isPercent ? 100 : 1

  if (isAnimated) {
    return [value * coefficient]
  }

  return value * coefficient
}

const mapPoint2D = (payload: { value: Point2D; isPercent?: boolean }) => {
  const { value, isPercent } = payload
  const coefficient = isPercent ? 100 : 1
  return [value.x * coefficient, value.y * coefficient]
}

export const mapColorAlpha = ({
  value,
  isAnimated,
}: {
  value: { a: number }
  isAnimated?: boolean
}) => (isAnimated ? [value.a * 100] : value.a * 100)

// @NOTE: in gradients we have to use 3 digits for colors
export const mapRgbForGradient = ({
  value,
}: {
  value: RGBA
  isAnimated?: boolean
}) => R.take(3, R.values(value)).map((v) => v / 255)

// @NOTE: but in other colors we have to use 4 digits
export const mapRgb = ({ value }: { value: any; isAnimated?: boolean }) => {
  // @NOTE: alpha channel required to render in react-native on android device
  // @TODO: add correct type here
  // @ts-ignore
  return [...R.take(3, R.values(value)).map((v) => v / 255), 1]
}

export function prepareKeyframes<Value extends any>(payload: {
  project: Project
  keyframes: Entity[]
  mapper: (payload: { time: number; isAnimated?: boolean }) => unknown
  /**
   * Required by previous API.
   * @todo refactor and optimize
   */
  additionalMappers?: (keyframe: Entity, nextKeyframe: Entity) => any
}): LottieKeyframe<Value>[] {
  const { project, keyframes, mapper, additionalMappers } = payload
  const projectFps = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(FpsComponent).value

  return keyframes.map((keyframe, idx, array) => {
    const value = mapper({
      time: keyframe.getComponentOrThrow(TimeComponent).value,
    })
    const isLast = idx === array.length - 1

    if (isLast) {
      return {
        t: keyframe.getComponentOrThrow(TimeComponent).value * projectFps,
        s: value,
      }
    }

    const nextKeyframe = keyframes[idx + 1]
    return {
      t: keyframe.getComponentOrThrow(TimeComponent).value * projectFps,
      s: value,
      o: {
        x: [keyframe.getComponentOrThrow(TimingCurveComponent).value.out.x],
        y: [keyframe.getComponentOrThrow(TimingCurveComponent).value.out.y],
      },
      i: {
        x: [nextKeyframe.getComponentOrThrow(TimingCurveComponent).value.in.x],
        y: [nextKeyframe.getComponentOrThrow(TimingCurveComponent).value.in.y],
      },
      ...additionalMappers?.(keyframe, nextKeyframe),
    }
  })
}

export function Property<Value extends any>(payload: {
  property: Component

  /**
   * @description related animated properties.
   * All keyframes will be given to affect animation.
   */
  depenencyProperties?: Array<Component>

  /**
   * @description how to map values
   */
  mapper?: (payload: { value: any; isAnimated: boolean; time: number }) => Value

  /**
   * @description used only for spatial interpolation of position in layers
   * @todo refactor
   */
  additionalMappers?: (keyframe: Entity, nextKeyframe: Entity) => any
}): LottieProperty<Value> {
  const {
    property,
    depenencyProperties = [],
    mapper,
    additionalMappers = noop,
  } = payload

  const project = property.entity.getProjectOrThrow()

  const rawKeyframes = [property, ...depenencyProperties].flatMap((prop) =>
    getTimingCurveKeyframes(prop)
  )
  const keyframes = R.sort(
    (left, right) =>
      left.getComponentOrThrow(TimeComponent).value -
      right.getComponentOrThrow(TimeComponent).value,
    rawKeyframes
  )

  const getValue = (payload: { time: number; isAnimated?: boolean }): any => {
    const { time, isAnimated = true } = payload

    const valueToProcess = getAnimatableValue(property, time) as
      | number
      | Point2D

    if (mapper != null) {
      return mapper({
        value: valueToProcess,
        isAnimated,
        time,
      }) as any
    }

    if (typeof valueToProcess === 'number') {
      return mapNumber({ value: valueToProcess, isAnimated })
    }

    return mapPoint2D({ value: valueToProcess })
  }

  if (keyframes.length === 1) {
    const keyframe = keyframes[0]
    const value = getValue({
      time: keyframe.getComponentOrThrow(TimeComponent).value,
      isAnimated: false,
    })
    return {
      a: 0,
      k: value,
    }
  }

  if (keyframes.length > 1) {
    return {
      a: 1,
      k: prepareKeyframes({
        project,
        keyframes,
        mapper: getValue,
        additionalMappers,
      }),
    }
  }

  return {
    a: 0,
    k: getValue({ time: 0, isAnimated: false }),
  }
}
