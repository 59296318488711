import * as React from 'react'

const defaultSize = {
  x: 16,
  y: 16,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
  className?: string
}
export const NotificationWarning: React.FCC<IProps> = ({
  size = defaultSize,
  // color = '#FFC700',
  // highlightColor = 'rgba(0, 0, 0, 0.8)',
  style,
  className,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
    className={className}
  >
    <path
      d="M0 6.44C0 4.16204 0 3.02306 0.450346 2.1561C0.829848 1.42553 1.42553 0.829848 2.1561 0.450346C3.02306 0 4.16204 0 6.44 0H9.56C11.838 0 12.9769 0 13.8439 0.450346C14.5745 0.829848 15.1702 1.42553 15.5497 2.1561C16 3.02306 16 4.16204 16 6.44V9.56C16 11.838 16 12.9769 15.5497 13.8439C15.1702 14.5745 14.5745 15.1702 13.8439 15.5497C12.9769 16 11.838 16 9.56 16H6.44C4.16204 16 3.02306 16 2.1561 15.5497C1.42553 15.1702 0.829848 14.5745 0.450346 13.8439C0 12.9769 0 11.838 0 9.56V6.44Z"
      fill="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.99984 3.50684L8.43175 4.24737L11.9317 10.2483L12.3703 11.0003H11.4998H4.49984H3.62939L4.06793 10.2483L7.56793 4.24737L7.99984 3.50684ZM5.37029 10.0003H10.6294L7.99984 5.49171L5.37029 10.0003Z"
      fill="var(--figma-color-text-brand, rgba(0, 0, 0, 0.8))"
    />
  </svg>
)
