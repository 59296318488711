import { useCommentContext } from '@aninix/core/modules/comments-wrapper/use-cases/use-comment-context'
import {
  IComment,
  useGetComments,
} from '@aninix/core/use-cases/comments/use-get-comments'
import { usePatchComment } from '@aninix/core/use-cases/comments/use-patch-comment'
import { CircularProgress } from '@material-ui/core'
import { toast } from 'apps/web-app/src/modules/toasts'
import classNames from 'classnames'
import { intlFormatDistance } from 'date-fns'
import _ from 'lodash'
import * as React from 'react'

export const CommentListItem: React.FCC<{
  data: IComment
  replyTo?: IComment
  index: number
  isLast: boolean
}> = ({ data, index, isLast, replyTo }) => {
  const {
    id,
    comment,
    rectangle,
    time,
    isResolved,
    replies,
    author,
    createdAt,
    updatedAt,
    //this means if there's replies - take last one as the source. if no replies - take the original comment
  } = data.replies !== undefined ? data.replies.at(-1)! : data

  const formattedDistance = React.useMemo(
    () => intlFormatDistance(new Date(createdAt), new Date()),
    [createdAt]
  )

  const uniqueCommentsByAuthors = React.useMemo(
    () =>
      _.uniqBy([data, ...(data?.replies ?? [])], (reply) => reply.author.id),
    [data]
  )

  const { selectedCommentId, setSelectedCommentId } = useCommentContext()
  const isSelected = selectedCommentId === data.id

  const [isResolving, setIsResolving] = React.useState<boolean>(false)

  const { query: patchCommentQuery } = usePatchComment()
  const { query: getCommentsQuery } = useGetComments({
    isStartingLoading: false,
  })

  const parentComment = data

  const handleResolve = React.useCallback(() => {
    setIsResolving(true)

    patchCommentQuery
      .mutateAsync({
        commentId: parentComment.id,
        ...parentComment,
        isResolved: true,
      })
      .catch((e) => {
        toast("Couldn't resolve comment", { variant: 'error' })
      })
      .then(() => {
        getCommentsQuery?.refetch()
      })
      .then(() => {
        setIsResolving(false)
        setSelectedCommentId(null)
      })
  }, [])

  const handleUnresolve = React.useCallback(() => {
    setIsResolving(true)

    patchCommentQuery
      .mutateAsync({
        commentId: parentComment.id,
        ...parentComment,
        isResolved: false,
      })
      .catch((e) => {
        toast("Couldn't resolve comment", { variant: 'error' })
      })
      .then(() => {
        getCommentsQuery?.refetch()
      })
      .then(() => {
        setIsResolving(false)
      })
  }, [])

  const [hasAppeared, setHasAppeared] = React.useState<boolean>(false)

  const commentRef = React.useRef<React.ElementRef<'div'>>(null)

  React.useEffect(() => {
    setHasAppeared(true)
  }, [getCommentsQuery?.isRefetching])

  React.useEffect(() => {
    if (isSelected) {
      commentRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [isSelected])

  return (
    <>
      <div
        ref={commentRef}
        id={`comment-list-item-${data.id}`}
        onClick={() => setSelectedCommentId(data.id)}
        className={classNames(
          'flex cursor-pointer flex-col px-3 transition-colors duration-700',
          {
            ['bg-[--figma-color-bg-onselected-hover]']: !hasAppeared,
            ['bg-[--figma-color-bg-onselected]']: isSelected,
            ['opacity-50']: parentComment.isResolved && !isSelected,
          }
        )}
      >
        <div className="flex flex-col gap-2 py-3">
          <div className="justify-betwee flex h-6 flex-row items-center justify-between">
            <div className="flex flex-row gap-1">
              {uniqueCommentsByAuthors.map((comment) => (
                <img
                  draggable={false}
                  key={comment.author.id}
                  className="h-6 w-6  rounded-full object-cover"
                  crossOrigin="anonymous"
                  src={comment.author.avatarUrl}
                />
              ))}
            </div>
            <button
              onClick={data.isResolved ? handleUnresolve : handleResolve}
              className={classNames(
                'pointer-events-auto flex h-8 w-8 items-center justify-center hover:bg-white hover:bg-opacity-50',
                { ['hidden']: !isSelected }
              )}
            >
              {isResolving ? (
                <CircularProgress size={18} />
              ) : data.isResolved ? (
                unresolveIcon
              ) : (
                resolveIcon
              )}
            </button>
          </div>
          <div>
            <p className="text[--figma-color-text] font-body text-[11px] font-normal">
              <span className="opacity-80">#{index + 1}</span>
              <span className="opacity-80">{` ∙ `}</span>
              <span className="opacity-30">{data.time} s</span>
            </p>
          </div>
          <div>
            <p className="text[--figma-color-text] font-body text-[12px] font-normal">
              <span className="opacity-80">{author.name}</span>
              <span className="opacity-30">
                {` `}
                {formattedDistance}
              </span>
            </p>
          </div>
          <p className="text[--figma-color-text] line-clamp-3 select-text font-body text-[11px] font-normal text-opacity-80">
            {comment}
          </p>

          {data.replies && (
            <p className="text[--figma-color-text] font-body text-[11px] font-normal text-opacity-30">{`${
              data.replies?.length
            } ${data.replies.length > 1 ? 'replies' : 'reply'}`}</p>
          )}
        </div>
        {
          //when to show separator
          !isLast && (
            <div className="h-[1px] w-full bg-[--figma-color-border]" />
          )
        }
      </div>
    </>
  )
}

const resolveIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    // fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 16C23 19.866 19.866 23 16 23C12.134 23 9 19.866 9 16C9 12.134 12.134 9 16 9C19.866 9 23 12.134 23 16ZM24 16C24 20.4183 20.4183 24 16 24C11.5817 24 8 20.4183 8 16C8 11.5817 11.5817 8 16 8C20.4183 8 24 11.5817 24 16ZM15.9111 18.8654L19.9111 14.3655L19.0889 13.6347L15.4764 17.6986L12.8889 15.1112L12.1111 15.889L15.1111 18.8889L15.5236 19.3014L15.9111 18.8654Z"
      fill="var(--figma-color-text)"
      fillOpacity="0.8"
    />
  </svg>
)

const unresolveIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="15.9999" r="8" fill="#219653" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23 15.9999C23 19.8659 19.866 22.9999 16 22.9999C12.134 22.9999 9 19.8659 9 15.9999C9 12.1339 12.134 8.99988 16 8.99988C19.866 8.99988 23 12.1339 23 15.9999ZM24 15.9999C24 20.4182 20.4183 23.9999 16 23.9999C11.5817 23.9999 8 20.4182 8 15.9999C8 11.5816 11.5817 7.99988 16 7.99988C20.4183 7.99988 24 11.5816 24 15.9999ZM15.9111 18.8653L19.9111 14.3654L19.0889 13.6346L15.4764 17.6985L12.8889 15.1111L12.1111 15.8889L15.1111 18.8888L15.5236 19.3012L15.9111 18.8653Z"
      fill="var(--figma-color-bg)"
    />
  </svg>
)
