import * as React from 'react'

import { NodeExpandType } from '../expand-children'
import { Collapsed } from './Collapsed'
import { Expanded } from './Expanded'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  type: NodeExpandType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const ExpandProperties: React.FCC<IProps> = ({
  type,
  size = defaultSize,
  style,
}) => {
  if (
    [NodeExpandType.PropertiesVisible, NodeExpandType.Expanded].includes(type)
  ) {
    return <Expanded size={size} style={style} />
  }

  return <Collapsed size={size} style={style} />
}
