import {
  BottomLeftCornerRadiusComponent,
  BottomRightCornerRadiusComponent,
  Component,
  NumberKeyframe,
  TopLeftCornerRadiusComponent,
  TopRightCornerRadiusComponent,
  getValueNumber,
  isIndividualCornerRadiusAvailable,
  isIndividualCornerRadiusEnabled,
  round,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 8,
  y: 8,
}

export enum SpecificCorner {
  TopLeft = 'topLeftRadius',
  TopRight = 'topRightRadius',
  BottomLeft = 'bottomLeftRadius',
  BottomRight = 'bottomRightRadius',
}

const componentMap: Record<SpecificCorner, any> = {
  [SpecificCorner.TopLeft]: TopLeftCornerRadiusComponent,
  [SpecificCorner.TopRight]: TopRightCornerRadiusComponent,
  [SpecificCorner.BottomLeft]: BottomLeftCornerRadiusComponent,
  [SpecificCorner.BottomRight]: BottomRightCornerRadiusComponent,
}

const iconMap: Record<SpecificCorner, React.ReactNode> = {
  [SpecificCorner.TopLeft]: (
    <icons.propertiesPanel.TopLeftRadius size={iconSize} />
  ),
  [SpecificCorner.TopRight]: (
    <icons.propertiesPanel.TopRightRadius size={iconSize} />
  ),
  [SpecificCorner.BottomLeft]: (
    <icons.propertiesPanel.BottomLeftRadius size={iconSize} />
  ),
  [SpecificCorner.BottomRight]: (
    <icons.propertiesPanel.BottomRightRadius size={iconSize} />
  ),
}

export interface IProps {
  corner: SpecificCorner
}
export const SpecificCornerRadius: React.FC<IProps> = ({ corner }) => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time, snapshots } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter(
    (n) =>
      n.hasComponent(componentMap[corner]) &&
      isIndividualCornerRadiusAvailable(n) &&
      isIndividualCornerRadiusEnabled(n)
  )

  const components = filteredNodes.map((l) =>
    l.getComponentOrThrow(componentMap[corner])
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const cornerRadiuses = snapshots.map((s) => s[corner])
  const keyframeType = getKeyframesType(components, time)
  const icon = iconMap[corner]

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <SpecificCornerRadiusEditable
          time={time}
          components={components}
          icon={icon}
        />
      ) : (
        <SpecificCornerRadiusDisplay
          time={time}
          components={components}
          icon={icon}
          cornerRadiuses={cornerRadiuses}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

SpecificCornerRadius.displayName = 'SpecificCornerRadius'

const SpecificCornerRadiusEditable: React.FC<{
  time: number
  components: Component[]
  icon: React.ReactNode
}> = ({ time, components, icon }) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={components}
            time={time}
            icon={icon}
            min={0}
            format={(v) => `${round(v, { fixed: 2 })}`}
          />
        </div>
      }
      rightColumn={
        <KeyframesPropertyControl
          components={components}
          time={time}
          KeyframeConstructor={NumberKeyframe}
          valueGetter={getValueNumber}
        />
      }
    />
  )
}

SpecificCornerRadiusEditable.displayName = 'SpecificCornerRadiusEditable'

interface SpecificCornerRadiusDisplayProps {
  time: number
  components: Component[]
  icon: React.ReactNode
  cornerRadiuses: number[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (
  prev: SpecificCornerRadiusDisplayProps,
  next: SpecificCornerRadiusDisplayProps
) => {
  if (prev.cornerRadiuses.length !== next.cornerRadiuses.length) return false
  if (prev.keyframeType !== next.keyframeType) return false
  for (let i = 0; i < prev.cornerRadiuses.length; i += 1) {
    if (prev.cornerRadiuses[i] !== next.cornerRadiuses[i]) return false
  }
  return true
}

const SpecificCornerRadiusDisplay: React.FC<SpecificCornerRadiusDisplayProps> =
  React.memo(({ components, icon, time }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <NumberValue
              components={components}
              time={time}
              icon={icon}
              min={0}
              format={(v) => `${round(v, { fixed: 2 })}`}
            />
          </div>
        }
        rightColumn={
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        }
      />
    )
  }, propsAreEqual)
