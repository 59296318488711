import { useGetTeam, useSetMemberAninixRole } from '@aninix/api'
import { useQueryClient } from '@tanstack/react-query'
import lowerCase from 'lodash/lowerCase'
import * as React from 'react'

import { ApiUser } from '@aninix/api/helpers'
import { toast } from '../../modules/toasts'
import { useCurrentTeamStore, useCurrentUser } from '../../use-cases'
import { currentRole } from './role'
import { useCurrentRowContext } from './use-current-row-context'

interface IProps {
  role: ApiUser['aninixRole']
  selectedRole: ApiUser['aninixRole']
  handleClose: (e?: any) => void
}

export const AninixRoleItem: React.FCC<IProps> = ({
  role,
  selectedRole,
  handleClose,
}: IProps) => {
  const setRole = useSetMemberAninixRole()

  const { teamId } = useCurrentUser()

  const { id, name, email } = useCurrentRowContext()
  const { currentTeam } = useCurrentTeamStore()

  const queryClient = useQueryClient()

  const getTeam = useGetTeam({ teamId: teamId! })

  const handleRoleClick = React.useCallback(() => {
    if (role === selectedRole) return

    queryClient.setQueryData(
      getTeam.queryKey,
      (oldData: typeof getTeam.data) => {
        const newData = { ...oldData }
        if (newData !== undefined) {
          newData.users!.find((user) => user.id === id)!.aninixRole = role
        }
        return newData as typeof getTeam.data
      }
    )

    setTimeout(handleClose, 200)

    setRole
      .mutateAsync({ userId: id!, teamId: teamId!, aninixRole: role })
      .then((value) => {
        getTeam.refetch()
        if (currentTeam?.plan === 'pro') {
          toast(
            <p>
              {`${name ?? email} is now `}
              <b>{lowerCase(currentRole(role))}</b>. Billing settings will be
              updated soon.
            </p>,
            { variant: 'success' }
          )
          return
        }

        toast(
          <p>
            {`${name ?? email} is now `}
            <b>{lowerCase(currentRole(role))}</b>.
          </p>,
          { variant: 'success' }
        )
      })
      .catch(() => {
        toast("Couldn't change user role", { variant: 'error' })
        getTeam.refetch()
      })
  }, [role, selectedRole])

  return (
    <div
      onClick={handleRoleClick}
      className="flex w-full min-w-[6rem] flex-row items-end gap-2 px-8 py-3 hover:bg-gray-100"
    >
      <p className="font-body text-base font-normal text-gray-500">
        {currentRole(role)}
      </p>
      {role == selectedRole && <p className="text-gray-400">•</p>}
    </div>
  )
}

AninixRoleItem.displayName = 'AninixRoleItem'
