import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { KeyModificator, Session } from '@aninix/core'
import * as React from 'react'

import { Preset, PresetType } from '../../models/preset'
import { usePro } from '../../models/pro'
import { IPresetsStore } from '../../stores'
import { IUseApplyPresetUseCase } from '../../use-cases'

type Payload = {
  presetsStore: IPresetsStore
  session: Session
  applyPresetUseCase: IUseApplyPresetUseCase
}

type PresetGroup = {
  title: string
  presets: Preset[]
  isLocked: boolean
}
export interface IAnimationPresetsInteractor {
  presetGroups: PresetGroup[]
  type: PresetType
  updateType: (type: PresetType) => void
  /**
   * Returns `true` when preset properly applied
   */
  applyPreset: (preset: Preset) => boolean
  forceApply: boolean
  isUserPro: boolean
}

export const useAnimationPresetsInteractor = ({
  presetsStore,
  session,
  applyPresetUseCase,
}: Payload): IAnimationPresetsInteractor => {
  const analytics = useAnalytics()
  const { isPro, beforeProFeatureAttepmtToAccess } = usePro()

  const presetGroups = (() => {
    const free: Preset[] = []
    const pro: Preset[] = []

    for (const preset of presetsStore.presetsForActiveType) {
      if (preset.isPro) {
        pro.push(preset)
        continue
      }

      free.push(preset)
    }

    return [
      {
        title: 'Basic',
        isLocked: false,
        presets: free,
      },
      {
        title: 'Advanced',
        isLocked: !isPro,
        presets: pro,
      },
    ] satisfies PresetGroup[]
  })()

  const applyPreset: IAnimationPresetsInteractor['applyPreset'] =
    React.useCallback(
      (preset) => {
        analytics.track({
          eventName: AnalyticsEvent.AnimationPresetClicked,
          properties: {
            preset: preset.id,
            isPresetLocked: !isPro && preset.isPro,
          },
        })

        if (!isPro && preset.isPro) {
          beforeProFeatureAttepmtToAccess()
          return false
        }

        applyPresetUseCase.apply({ preset })
        return true
      },
      [applyPresetUseCase, isPro, beforeProFeatureAttepmtToAccess, analytics]
    )

  React.useEffect(() => {
    analytics.track({
      eventName: AnalyticsEvent.AnimationPresetsViewed,
    })
  }, [])

  return {
    presetGroups,
    type: presetsStore.activeType,
    updateType: presetsStore.updateActiveType,
    applyPreset,
    forceApply: session.keyModificators.includes(KeyModificator.Ctrl),
    isUserPro: isPro,
  }
}
