import {
  EntityType,
  SelectionSystem,
  TimeComponent,
  segmentsFromKeyframes,
} from '@aninix-inc/model'
import { observer } from 'mobx-react'
import * as R from 'ramda'
import * as React from 'react'
import { useProject } from '../../../../../../../../stores'
import {
  useEntities,
  useReloadOnAnyUpdate,
  useSystem,
} from '../../../../../../../../updates'
import { NoSegmentsToInspect } from '../no-segments-to-inspect'
import { FormatContext } from './context'
import { InspectedSegment } from './segment'

export interface IProps {}
export const Inspect: React.FCC<IProps> = observer(() => {
  const project = useProject()
  useReloadOnAnyUpdate(project)
  const selection = project.getSystemOrThrow(SelectionSystem)
  const keyframes = R.sortBy(
    (e) => e.getComponentOrThrow(TimeComponent).value,
    selection.getEntitiesByEntityType(EntityType.Keyframe)
  )
  const segments = segmentsFromKeyframes(keyframes)
  useSystem(selection)
  useEntities(keyframes)

  if (segments.length === 0) {
    return <NoSegmentsToInspect selectedTab="inspect" />
  }

  return (
    <FormatContext>
      <div className="flex h-full flex-col gap-3 overflow-scroll p-3">
        {segments.map((segment) => (
          <InspectedSegment
            key={`${R.head(segment)!.id}-${R.last(segment)!.id}`}
            segment={segment}
          />
        ))}
      </div>
    </FormatContext>
  )
})

Inspect.displayName = 'Inspect'
