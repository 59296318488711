import {
  applyTimingCurve,
  getOpacity,
  lerp,
  NumberValueComponent,
  OpacityComponent,
  round,
  setAnimatableValue,
  setStartTime,
  TimingCurveComponent,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import { TimingCurve } from '@aninix/figma'
import { Pair } from './pair'

const STEPS = 20

/**
 * Opacity change is not a linear function, so we must use the correct formula.
 */
function getComplementaryOpacity(
  leftOpacity: number,
  targetOpacity: number
): number {
  // Using formula: targetOpacity = 1 - (1 - layer1Opacity) * (1 - layer2Opacity)

  if (leftOpacity === 1) {
    return 0
  }

  if (targetOpacity === 1) {
    return 1 - Math.pow(leftOpacity, 20)
  }

  return 1 - (1 - targetOpacity) / (1 - leftOpacity)
}

/**
 * Creates appear/disappear transition
 * @mutates pair
 */
export function blend(
  pair: Pair.Type,
  options: { timeStart: number; timeEnd: number; curve?: TimingCurve }
): void {
  const { timeStart, timeEnd, curve } = options

  if (pair.left == null || pair.right == null) {
    throw new Error(
      'Only fulfiled pair (eg both left and right are defined) can be blended'
    )
  }

  pair.left.disappear({ timeStart, timeEnd, curve })

  setStartTime(pair.right.entity, timeStart)
  const startOpacity = getOpacity(pair.left.entity, timeStart)
  const endOpacity = getOpacity(pair.right.entity, timeEnd)

  if (
    startOpacity === 0 ||
    pair.left.entity.getComponentOrThrow(VisibleInViewportComponent).value ===
      false
  ) {
    return
  }

  for (let i = 0; i <= STEPS; i += 1) {
    const progress = i / STEPS
    const t = applyTimingCurve([0.42, 0, 0.58, 1], progress)
    const time = lerp(t, timeStart, timeEnd)
    const leftOpacity = getOpacity(pair.left.entity, time)
    const rightOpacity = round(
      Math.min(1, Math.max(0, getComplementaryOpacity(leftOpacity, endOpacity)))
    )
    if (getOpacity(pair.right.entity, time) !== rightOpacity) {
      const keyframe = setAnimatableValue(
        pair.right.entity.getComponentOrThrow(OpacityComponent),
        leftOpacity,
        time,
        true
      )
      keyframe.updateComponent(NumberValueComponent, rightOpacity)
      keyframe.updateComponent(TimingCurveComponent, {
        out: { x: 0, y: 0 },
        in: { x: 1, y: 1 },
      })
    }
  }
}
