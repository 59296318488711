import * as React from 'react'

import packageJson from '../../../../../../package.json'
import { Icons } from '../../../components/icons'
import { useCurrentSpaceStore } from '../../../use-cases'
import { CreateNewTeamBanner } from '../create-new-team-banner'
import { IProps as IMenuItemsProps, MenuItems } from '../menu-items'
import { SpaceBadge } from '../space-badge'
import { IUserSideMenuInteractor } from './interactor'

export interface IProps {
  interactor: IUserSideMenuInteractor
  upgradeToPro: React.ReactNode
}
export const UserSideMenuRouter: React.FC<IProps> = ({
  interactor,
  upgradeToPro,
}) => {
  const topMenuItems: IMenuItemsProps['items'] = React.useMemo(() => {
    return [
      {
        Icon: Icons.Projects,
        label: 'Projects',
        path: '/my/projects',
        isExternal: false,
        SelectedIcon: Icons.ProjectsSelected,
      },
      {
        Icon: Icons.Projects,
        label: 'Lotties',
        path: '/my/lotties',
        isExternal: false,
        SelectedIcon: Icons.ProjectsSelected,
      },
      {
        Icon: Icons.Trash,
        label: 'Trash',
        path: '/my/trash',
        isExternal: false,
        SelectedIcon: Icons.SelectedTrash,
      },
    ]
  }, [interactor.currentPath])

  const bottomMenuItems: IMenuItemsProps['items'] = React.useMemo(
    () =>
      [
        ...(!interactor.children.upgradeToPro
          ? [
              {
                Icon: Icons.PaymentSettings,
                SelectedIcon: Icons.PaymentSettings,
                label: 'Manage subscription',
                path: '/manage-subscription',
                isExternal: true,
              },
            ]
          : []),
        {
          Icon: Icons.Download,
          label: 'Download Figma plugin',
          path: 'https://aninix.com/figma-plugin',
          isExternal: true,
        },
        {
          Icon: Icons.Info,
          label: 'Wiki',
          path: 'https://aninix.com/wiki',
          isExternal: true,
        },
        {
          Icon: Icons.Help,
          label: 'Need help?',
          path: 'mailto:info@aninix.com',
          isExternal: true,
        },
      ] as IMenuItemsProps['items'],
    [interactor.children.upgradeToPro]
  )

  const { currentUser } = useCurrentSpaceStore()

  const hasNoTeams = currentUser!.teams.length === 0

  return (
    <div className="flex h-full flex-col gap-6">
      <SpaceBadge />
      <MenuItems items={topMenuItems} />
      {interactor.children.upgradeToPro && upgradeToPro}
      {!interactor.children.upgradeToPro && hasNoTeams && (
        <CreateNewTeamBanner />
      )}
      <div className="flex-grow" />
      <MenuItems items={bottomMenuItems} />
      <p className="px-3 text-[9px] text-gray-400">
        Version: {packageJson.versions['web-app']}
      </p>
    </div>
  )
}
