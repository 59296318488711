import {
  AnimationCurveAspect,
  CurveType,
  Entity,
  commitUndo,
  round,
} from '@aninix-inc/model'
import { springCurve } from '@aninix-inc/model/legacy'
import { useAnalytics } from '@aninix/analytics'
import {
  InputWithIcon,
  Select,
  SpringGraph,
  icons,
} from '@aninix/app-design-system'
import { nodeColors, useFlattenEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { defaultSpringCurvePresets } from '../../../../../../default-curve-styles'
import { CurveStyle, updateCurveStyle } from '../timing-curve'

export interface IProps {
  segments: [Entity, Entity][]
}
export const SpringCurve: React.FCC<IProps> = ({ segments }) => {
  const version = useFlattenEntities(segments)
  const analytics = useAnalytics()
  const updateValue = React.useCallback(
    (
      newCurveStyle: CurveStyle,
      updateType?: 'preset-applied' | 'graph-changed'
    ) => {
      updateCurveStyle(newCurveStyle, segments)
      // @TODO: implement
      // if (updateType === 'preset-applied') {
      //   analytics.track({
      //     eventName: AnalyticsEvent.CurveStyleApplied,
      //     properties: {
      //       curveStyleId: curveStyle.id,
      //     },
      //   })
      // }
    },
    [segments, version]
  )

  // @TODO: replace with new model
  const value = springCurve.create(
    segments[0][0].getAspectOrThrow(AnimationCurveAspect).curveComponent()
      .value as any
  )

  const hasAppliedStyle = segments[0][0]
    .getAspectOrThrow(AnimationCurveAspect)
    .hasCurveStyle()
  const onEndChange = React.useCallback(() => {
    commitUndo(segments[0][0].getProjectOrThrow())
  }, [segments])
  const defaultPresets = defaultSpringCurvePresets
  // const color =
  //   nodeColors[segments[0][0].getProperty().getNode().color as LayerColor]
  const color = nodeColors.BLUE

  const stiffness = round(value.stiffness, { fixed: 2 })
  const damping = round(value.damping, { fixed: 2 })
  const mass = round(value.mass, { fixed: 2 })

  const handleChangeSpringCurveFromGraph = React.useCallback(
    (payload: { stiffness?: number; damping?: number; mass?: number }) => {
      updateValue({
        type: CurveType.Spring,
        stiffness: payload.stiffness ?? value.stiffness,
        damping: payload.damping ?? value.damping,
        mass: payload.mass ?? value.mass,
      })
    },
    [value, version]
  )

  const handleChangeSpringCurve = React.useCallback(
    (payload: { stiffness?: number; damping?: number; mass?: number }) => {
      updateValue({
        type: CurveType.Spring,
        stiffness: payload.stiffness ?? value.stiffness,
        damping: payload.damping ?? value.damping,
        mass: payload.mass ?? value.mass,
      })
      onEndChange()
    },
    [onEndChange, version]
  )

  const handleStiffnessUpdate = React.useCallback(
    (stiffness: number) => {
      handleChangeSpringCurve({ stiffness })
    },
    [handleChangeSpringCurve, version]
  )

  const handleDampingUpdate = React.useCallback(
    (damping: number) => {
      handleChangeSpringCurve({ damping })
    },
    [handleChangeSpringCurve, version]
  )

  const handleMassUpdate = React.useCallback(
    (mass: number) => {
      handleChangeSpringCurve({ mass })
    },
    [handleChangeSpringCurve, version]
  )

  // @TODO: implement
  const applyDefaultPreset = React.useCallback(
    (presetId: string) => {
      const preset = defaultPresets
        .filter((_preset) => _preset !== 'divider')
        .map((_preset) => _preset as { id: string; value: CurveStyle })
        .find((_preset) => _preset.id === presetId)

      if (preset == null) {
        return
      }

      updateValue(preset.value, 'preset-applied')
    },
    [version]
  )

  // @TODO: implement
  const selectedPresetId = React.useMemo(() => {
    return 'custom'
    // const preset = defaultPresets
    //   .filter((_preset) => _preset !== 'divider')
    //   .map((_preset) => _preset as CurveStyle)
    //   .find(
    //     (_preset) =>
    //       _preset.value.type === value.type &&
    //       // @TODO: check validity of type here
    //       // @ts-ignore
    //       _preset.value.isEqual(value)
    //   )

    // if (preset == null) {
    //   return 'custom'
    // }

    // return preset.id
  }, [version])

  const selectOptions = React.useMemo(() => {
    const defaultOptions = defaultPresets.map((preset) => {
      if (preset === 'divider') {
        return 'divider'
      }

      return {
        id: preset.id,
        title: preset.title,
        icon: (
          <icons.Graph
            previews={preset.value.getCache()}
            style={{
              // @ts-ignore
              '--figma-color-text':
                preset.id === selectedPresetId ? undefined : '#FFFFFF',
            }}
          />
        ),
      }
    })

    if (selectedPresetId === 'custom') {
      return R.pipe(
        // @ts-ignore
        R.append('divider'),
        R.append({
          id: 'custom',
          title: 'Custom',
          icon: (
            <icons.Graph
              previews={value.getCache()}
              style={{
                // @ts-ignore
                '--figma-color-text':
                  selectedPresetId === 'custom' ? undefined : '#FFFFFF',
              }}
            />
          ),
        })
        // @ts-ignore
      )(defaultOptions)
    }

    return defaultOptions
  }, [selectedPresetId, version])

  return (
    <div className="w-full">
      {hasAppliedStyle === false && (
        <Select
          activeValueId={selectedPresetId}
          onChange={applyDefaultPreset}
          // @ts-ignore
          options={selectOptions}
          rootClassName="w-full"
          className="pl-2 pr-1"
        />
      )}

      <SpringGraph
        width={223}
        height={256}
        value={value}
        onChange={handleChangeSpringCurveFromGraph}
        onDragEnd={onEndChange}
        color={color}
        disableEditing={hasAppliedStyle}
      />

      {hasAppliedStyle === false && (
        <>
          <InputWithIcon
            id="stiffness"
            icon={
              <span style={{ width: '100%', paddingLeft: 4 }}>Stiffness</span>
            }
            value={stiffness}
            onChange={handleStiffnessUpdate}
            iconWidth={96}
            width={224}
            min={1}
            max={500}
          />

          <InputWithIcon
            id="Damping"
            icon={
              <span style={{ width: '100%', paddingLeft: 4 }}>Damping</span>
            }
            value={damping}
            onChange={handleDampingUpdate}
            iconWidth={96}
            width={224}
            min={2}
            max={50}
          />

          <InputWithIcon
            id="Mass"
            icon={<span style={{ width: '100%', paddingLeft: 4 }}>Mass</span>}
            value={mass}
            onChange={handleMassUpdate}
            iconWidth={96}
            width={224}
            min={0.5}
            max={10}
          />
        </>
      )}
    </div>
  )
}
