import { InputTimeline, useMouseMove } from '@aninix/app-design-system'
import classNames from 'classnames'
import * as R from 'ramda'
import * as React from 'react'

import { round } from '@aninix-inc/model'
import { stringToMath } from './string-to-math'
import * as styles from './styles.scss'

const defaultFormat = (_value: number) => _value.toString()

export interface IProps {
  value: number
  onChange: (value: number) => void
  onEndChange?: () => void
  /**
   * @description offset modifier, less value make scroll more precisable
   */
  threshold?: number
  format?: (value: number) => string
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  disabled?: boolean
  min?: number
  max?: number
  // @TODO: refactor
  fullHeight?: boolean
  textColor?: string
}
export const NumericValue: React.FCC<IProps> = ({
  value,
  onChange,
  onEndChange,
  threshold = 1,
  format = defaultFormat,
  prefix,
  suffix,
  disabled = false,
  min = -Infinity,
  max = Infinity,
  fullHeight = false,
  textColor,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [isFocused, setIsFocused] = React.useState(false)
  const [localValue, setLocalValue] = React.useState(value.toString())
  const valueToChangeRef = React.useRef<number>(0)

  const { offsetX, isListening, startListen, wasTriggered } = useMouseMove({
    threshold: 4,
    onFinish: () => {
      inputRef.current?.blur()
      onEndChange?.()
      setLocalValue(valueToChangeRef.current.toString())
    },
  })
  const initialValue = React.useRef(value)

  const resetLocalValues = React.useCallback(() => {
    const roundedValue = round(value, { fixed: 2 })
    setLocalValue(roundedValue.toString())
    valueToChangeRef.current = roundedValue
    initialValue.current = roundedValue
  }, [value])

  const handleStartListen = React.useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      startListen(e)
      initialValue.current = value
    },
    [startListen, value]
  )

  const handleClick = React.useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      setIsFocused(true)
      resetLocalValues()
    },
    [resetLocalValues]
  )

  const handleBlur = React.useCallback(() => {
    setIsFocused(false)

    if (valueToChangeRef.current === value) {
      onEndChange?.()
      return
    }

    onChange(valueToChangeRef.current)
    onEndChange?.()
  }, [onChange, onEndChange, value])

  const handleChange = React.useCallback(
    (newValue: string) => {
      const formattedValue = newValue.replace(/ /g, '')

      const numberedValue = stringToMath(formattedValue)

      setLocalValue(formattedValue.toString())

      if (isNaN(parseFloat(formattedValue))) {
        return
      }

      const modifiedValue = R.clamp(min, max, numberedValue)
      valueToChangeRef.current = modifiedValue
      onChange(modifiedValue)
    },
    [min, max, onChange]
  )

  const handleKeyPress = React.useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const isKeyDown = e.key.toLowerCase() === 'arrowdown'
      const isKeyUp = e.key.toLowerCase() === 'arrowup'
      const isShiftPressed = e.shiftKey
      const isCtrlPressed = e.ctrlKey || e.metaKey

      if (isKeyDown || isKeyUp) {
        e.preventDefault()
        e.stopPropagation()

        const thresholdToApply = (() => {
          if (isShiftPressed) {
            return 10 * threshold
          }

          if (isCtrlPressed) {
            return 0.1 * threshold
          }

          return threshold
        })()

        if (isKeyDown) {
          const newValue = valueToChangeRef.current - thresholdToApply
          handleChange(`${newValue}`)
          return
        }

        const newValue = valueToChangeRef.current + thresholdToApply
        handleChange(`${newValue}`)

        return
      }
    },
    [handleChange]
  )

  React.useEffect(() => {
    if (isListening && wasTriggered === false) {
      return
    }

    if (isListening && wasTriggered) {
      handleChange((initialValue.current + offsetX * threshold).toString())
      return
    }
  }, [offsetX, isListening, wasTriggered])

  React.useEffect(() => {
    if (isFocused) {
      inputRef.current?.focus()
      inputRef.current?.select()
      return
    }

    setLocalValue(valueToChangeRef.current.toString())
  }, [isFocused])

  React.useEffect(() => {
    resetLocalValues()
  }, [value])

  return (
    <>
      <div
        className={classNames(styles['input-container'], {
          [styles['full-height']]: fullHeight,
        })}
        style={{
          width: isFocused ? 'auto' : 0,
          opacity: isFocused ? 1 : 0,
          pointerEvents: isFocused ? 'all' : 'none',
        }}
      >
        <InputTimeline
          ref={inputRef}
          className={classNames(styles.input, {
            [styles['full-height']]: fullHeight,
          })}
          value={localValue}
          onChange={handleChange}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          disabled={disabled}
          fullHeight={fullHeight}
        />
      </div>

      {/* TODO: maybe replace with interactive element */}
      {/* eslint-disable-next-line */}
      <div
        className={classNames(styles.container, {
          [styles['full-height']]: fullHeight,
        })}
        style={{
          display: isFocused ? 'none' : 'flex',
          // @ts-ignore
          '--text-color': textColor,
        }}
        // @ts-ignore
        onMouseDown={handleStartListen}
        // @ts-ignore
        onClick={handleClick}
      >
        <span className={styles.comment}>{prefix}</span>

        <p className={styles.text}>{format(parseFloat(localValue))}</p>

        <span className={styles.comment}>{suffix}</span>
      </div>
    </>
  )
}
