import * as React from 'react'

import { Center } from './center'
import { CenterToSlider } from './center-to-slider'
import { Left } from './left'
import { LeftToSlider } from './left-to-slider'
import { Right } from './right'
import { RightToSlider } from './right-to-slider'
import { SpaceBetween } from './space-between'

export enum SegmentsAlignType {
  LeftToSlider = 'left-to-slider',
  Left = 'left',
  CenterToSlider = 'center-to-slider',
  Center = 'center',
  RightToSlider = 'right-to-slider',
  Right = 'right',
  SpaceBetween = 'space-between',
}

export interface IProps {
  type: SegmentsAlignType
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const SegmentsAlign: React.FCC<IProps> = ({ type, size, style }) => {
  if (SegmentsAlignType.LeftToSlider === type) {
    return <LeftToSlider style={style} size={size} />
  }

  if (SegmentsAlignType.Left === type) {
    return <Left style={style} size={size} />
  }

  if (SegmentsAlignType.CenterToSlider === type) {
    return <CenterToSlider style={style} size={size} />
  }

  if (SegmentsAlignType.Center === type) {
    return <Center style={style} size={size} />
  }

  if (SegmentsAlignType.RightToSlider === type) {
    return <RightToSlider style={style} size={size} />
  }

  if (SegmentsAlignType.Right === type) {
    return <Right style={style} size={size} />
  }

  if (SegmentsAlignType.SpaceBetween === type) {
    return <SpaceBetween style={style} size={size} />
  }

  return null
}
