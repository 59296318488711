/**
 * @description used to determine state of the node on timeline
 * Normal – node minimized, children and properties are not visible
 * ChildrenVisible – children are visible
 * PropertiesVisible – properties are visible
 * Expanded – node maximazed, children and properties are visible
 */
export enum NodeExpandType {
  Normal = 'NORMAL',
  ChildrenVisible = 'CHILDREN_VISIBLE',
  PropertiesVisible = 'PROPERTIES_VISIBLE',
  Expanded = 'EXPANDED',
}
