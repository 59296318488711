import classNames from 'classnames'
import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 24,
  y: 24,
}

export const Check: React.FCC<IIcon> = ({
  size = defaultSize,
  className,
  color = 'white',
}) => (
  <svg
    className={classNames('h-6 w-6', className)}
    width={size.x}
    height={size.y}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${size.x} ${size.y}`}
  >
    <path
      d="M16.875 5.62549L8.125 14.3751L3.75 10.0005"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
