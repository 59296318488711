import { httpClient } from '@aninix/api'
import { config } from '@aninix/api/config'
import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { useParams } from 'react-router-dom'

export interface ICommentsGetResponse {
  status: 'pending' | 'error' | 'success'
  error?: unknown
  data?: IComment[]
  query?: UseQueryResult<AxiosResponse<IComment[], any>, unknown>
}

export interface Author {
  id: string
  name: string | null
  avatarUrl?: string
  isAnonymous: boolean
}

export interface Rectangle {
  x: number
  y: number
  width: number
  height: number
}

export interface IComment {
  id: string
  comment: string
  rectangle: Rectangle
  time: number
  isResolved: boolean
  replies?: IComment[]
  author: Author
  createdAt: string
  updatedAt?: string
}

const createdAtSort = (a: IComment, b: IComment) =>
  new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()

interface IProps {
  isStartingLoading?: boolean
}

export const useGetComments = ({
  isStartingLoading = true,
}: IProps): ICommentsGetResponse => {
  const { projectId } = useParams()

  const query = useQuery({
    queryKey: ['getProjectComments', projectId],
    queryFn: () =>
      httpClient.get<IComment[]>(
        `${config.apiUrl}/projects/${projectId}/comments`,
        {
          responseType: 'json',
        }
      ),
    enabled: isStartingLoading,
  })

  const { data, status, error } = query

  const sortedData = data?.data?.sort(createdAtSort).map((comment) => {
    return { ...comment, replies: comment.replies?.sort(createdAtSort) }
  })

  const fallbackedData = sortedData?.map((comment) => {
    return {
      ...comment,
      author: {
        ...comment.author,
        avatarUrl:
          comment.author.avatarUrl ??
          (comment.author.isAnonymous
            ? '/images/anonymousAvatar.png'
            : '/images/defaultAvatar.png'),
        name: comment.author.name ?? 'Anonymous',
      },
      replies: comment.replies?.map((reply) => {
        return {
          ...reply,
          author: {
            ...reply.author,
            avatarUrl:
              reply.author.avatarUrl ??
              (reply.author.isAnonymous
                ? '/images/anonymousAvatar.png'
                : '/images/defaultAvatar.png'),
            name: reply.author.name ?? 'Anonymous',
          },
        }
      }),
    }
  })

  return { data: fallbackedData, status, error, query }
}
