import { makeAutoObservable } from 'mobx'
import * as React from 'react'

import {
  Preset,
  PresetAnimation,
  PresetAnimationAppearanceLayerBlurType,
  PresetType,
} from '../models/preset'

export interface IPresetsStore {
  /**
   * @description array of all presets
   */
  presets: Preset[]

  presetsForActiveType: Preset[]

  /**
   * @description currently active preset type
   */
  activeType: PresetType

  updateActiveType: (type: PresetType) => IPresetsStore
}

class PresetsStore implements IPresetsStore {
  presets: Preset[] = []

  activeType: IPresetsStore['activeType'] = PresetType.In

  constructor(presets: Preset[]) {
    this.presets = presets
    makeAutoObservable(this)
  }

  get presetsForActiveType() {
    return this.presets.filter((preset) => preset.type === this.activeType)
  }

  updateActiveType: IPresetsStore['updateActiveType'] = (type) => {
    this.activeType = type
    return this
  }
}

export const PresetsStoreContext = React.createContext<IPresetsStore>(
  null as any
)

export const usePresetsStore = (): IPresetsStore => {
  const context = React.useContext(PresetsStoreContext)
  if (context == null) {
    throw new TypeError(
      'PresetsStoreContext not found. Probably you forgot to wrap your components tree with <PresetsStoreProvider> component'
    )
  }
  return context
}

const inPresets: Preset[] = [
  new Preset({
    id: 'appear-from-left',
    title: 'From left →',
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    coverAt: 0,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: -1,
          1: 0,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceDirection,
        type: PresetAnimation.Types.AppearanceDirection.Right,
        values: {
          0: 1,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
  new Preset({
    id: 'appear-from-top',
    title: 'From top ↓',
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    coverAt: 0,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: -1,
          1: 0,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceDirection,
        type: PresetAnimation.Types.AppearanceDirection.Down,
        values: {
          0: 1,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
  new Preset({
    id: 'appear-from-right',
    title: 'From right ←',
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    coverAt: 0,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: -1,
          1: 0,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceDirection,
        type: PresetAnimation.Types.AppearanceDirection.Left,
        values: {
          0: 1,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
  new Preset({
    id: 'appear-from-bottom',
    title: 'From bottom ↑',
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    coverAt: 0,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: -1,
          1: 0,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceDirection,
        type: PresetAnimation.Types.AppearanceDirection.Up,
        values: {
          0: 1,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'appear-fade',
    title: 'Fade',
    isPro: true,
    type: Preset.Type.In,
    speed: Preset.Speed.Slow,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: -1,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [
      {
        id: PresetAnimation.Id.AppearanceLayerBlur,
        type: PresetAnimation.Types.AppearanceLayerBlur.None,
        values: {
          0: 24,
          1: 0,
        },
      },
    ],
  }),

  new Preset({
    id: 'appear-grow',
    title: 'Grow',
    isPro: true,
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.AppearanceScale,
        type: PresetAnimation.Types.AppearanceScale.Both,
        values: {
          0: -1,
          1: 0,
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'appear-pull-out',
    title: 'Pull out',
    isPro: true,
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: -1,
          1: 0,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceScale,
        type: PresetAnimation.Types.AppearanceScale.Both,
        values: {
          0: 0.75,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [
      {
        id: PresetAnimation.Id.AppearanceRotation,
        type: PresetAnimation.Types.AppearanceRotation.Clockwise,
        values: {
          0: 45,
          1: 0,
        },
      },
    ],
  }),

  new Preset({
    id: 'appear-in-the-box',
    title: 'In the box',
    isPro: true,
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    coverAt: 0,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.AppearanceAlignmentInBox,
        type: PresetAnimation.Types.AppearanceAlignmentInBox.Left,
        values: {
          0: true,
          1: false,
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'appear-out-of-the-box',
    title: 'Out of the box',
    isPro: true,
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    coverAt: 0,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.AppearanceAlignmentOutBox,
        type: PresetAnimation.Types.AppearanceAlignmentOutBox.Left,
        values: {
          0: true,
          1: false,
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'appear-trim-path',
    title: 'Trim path',
    isPro: true,
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.AppearanceTrimPath,
        type: PresetAnimation.Types.AppearanceTrimPath.Clockwise,
        values: {
          0: 0,
          1: 1,
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'appear-layer-blur',
    title: 'Layer Blur',
    isPro: true,
    type: Preset.Type.In,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: -1,
          1: 0,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceLayerBlur,
        type: PresetAnimationAppearanceLayerBlurType.Layer,
        values: {
          0: 1,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
]

const outPresets: Preset[] = [
  new Preset({
    id: 'disappear-to-right',
    title: 'To right →',
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: 0,
          1: -1,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceDirection,
        type: PresetAnimation.Types.AppearanceDirection.Left,
        values: {
          0: 0,
          1: 1,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
  new Preset({
    id: 'disappear-to-bottom',
    title: 'To bottom ↓',
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: 0,
          1: -1,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceDirection,
        type: PresetAnimation.Types.AppearanceDirection.Up,
        values: {
          0: 0,
          1: 1,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
  new Preset({
    id: 'disappear-to-left',
    title: 'To left ←',
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: 0,
          1: -1,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceDirection,
        type: PresetAnimation.Types.AppearanceDirection.Right,
        values: {
          0: 0,
          1: 1,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
  new Preset({
    id: 'disappear-to-top',
    title: 'To top ↑',
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: 0,
          1: -1,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceDirection,
        type: PresetAnimation.Types.AppearanceDirection.Down,
        values: {
          0: 0,
          1: 1,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'disappear-fade',
    title: 'Fade',
    isPro: true,
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: 0,
          1: -1,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [
      {
        id: PresetAnimation.Id.AppearanceLayerBlur,
        type: PresetAnimation.Types.AppearanceLayerBlur.None,
        values: {
          0: 0,
          1: 24,
        },
      },
    ],
  }),

  new Preset({
    id: 'disappear-shrink',
    title: 'Shrink',
    isPro: true,
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.AppearanceScale,
        type: PresetAnimation.Types.AppearanceScale.Both,
        values: {
          0: 0,
          1: -1,
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'disappear-push-in',
    title: 'Push in',
    isPro: true,
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: 0,
          1: -1,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceScale,
        type: PresetAnimation.Types.AppearanceScale.Both,
        values: {
          0: 0,
          1: 0.75,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [
      {
        id: PresetAnimation.Id.AppearanceRotation,
        type: PresetAnimation.Types.AppearanceRotation.CounterClockwise,
        values: {
          0: 0,
          1: 45,
        },
      },
    ],
  }),

  new Preset({
    id: 'disappear-in-the-box',
    title: 'In the box',
    isPro: true,
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.AppearanceAlignmentInBox,
        type: PresetAnimation.Types.AppearanceAlignmentInBox.Left,
        values: {
          0: false,
          1: true,
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'disappear-out-of-the-box',
    title: 'Out of the box',
    isPro: true,
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.AppearanceAlignmentOutBox,
        type: PresetAnimation.Types.AppearanceAlignmentOutBox.Left,
        values: {
          0: false,
          1: true,
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'disappear-trim-path',
    title: 'Trim path',
    isPro: true,
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.AppearanceTrimPath,
        type: PresetAnimation.Types.AppearanceTrimPath.Clockwise,
        values: {
          0: 1,
          1: 0,
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'disappear-layer-blur',
    title: 'Layer Blur',
    isPro: true,
    type: Preset.Type.Out,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: 0,
          1: -1,
        },
      },
      {
        id: PresetAnimation.Id.AppearanceLayerBlur,
        type: PresetAnimationAppearanceLayerBlurType.Layer,
        values: {
          0: 0,
          1: 1,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
]

const effectPresets: Preset[] = [
  new Preset({
    id: 'effect-click',
    title: 'Click',
    type: Preset.Type.Effect,
    speed: Preset.Speed.Fast,
    timingCurve: Preset.TimingCurve.Gentle,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectScale,
        type: PresetAnimation.Types.EffectScale.Both,
        values: {
          0: [0, 0],
          0.5: [-0.2, -0.2],
          1: [0, 0],
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'effect-pop',
    title: 'Pop',
    isPro: true,
    type: Preset.Type.Effect,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Gentle,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectScale,
        type: PresetAnimation.Types.EffectScale.Both,
        values: {
          0: [0, 0],
          0.5: [-0.3, -0.3],
          1: [0, 0],
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'effect-pulse',
    title: 'Pulse',
    isPro: true,
    type: Preset.Type.Effect,
    speed: Preset.Speed.Slow,
    timingCurve: Preset.TimingCurve.Gentle,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectScale,
        type: PresetAnimation.Types.EffectScale.Both,
        values: {
          0: [0, 0],
          0.4: [0.4, 0.4],
          1: [0, 0],
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'effect-ping',
    title: 'Ping',
    isPro: true,
    type: Preset.Type.Effect,
    timingCurve: Preset.TimingCurve.Gentle,
    duration: 0.75,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectScale,
        type: PresetAnimation.Types.EffectScale.Both,
        values: {
          0: [0, 0],
          1: [0.4, 0.4],
        },
        timingCurves: {
          0: [0.5, 0, 0.4, 1],
          1: [0.5, 0, 0.4, 1],
        },
      },
      {
        id: PresetAnimation.Id.AppearanceFade,
        type: PresetAnimation.Types.AppearanceFade.Fade,
        values: {
          0: 0,
          1: -1,
        },
        timingCurves: {
          0: [0.5, 0, 0.4, 1],
          1: [0.5, 0, 0.4, 1],
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],

    coverAt: 0,
    isCustomizable: false,
  }),

  new Preset({
    id: 'effect-rubber-band-horizontal',
    title: 'Rubber-band horizontal',
    isPro: true,
    type: Preset.Type.Effect,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Gentle,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectScale,
        type: PresetAnimation.Types.EffectScale.Both,
        values: {
          0: [0, 0],
          0.7: [0.4, -0.4],
          0.9: [-0.1, 0.1],
          1: [0, 0],
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],

    isCustomizable: false,
  }),

  new Preset({
    id: 'effect-rubber-band-vertical',
    title: 'Rubber-band vertical',
    isPro: true,
    type: Preset.Type.Effect,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Gentle,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectScale,
        type: PresetAnimation.Types.EffectScale.Both,
        values: {
          0: [0, 0],
          0.7: [-0.4, 0.4],
          0.9: [0.1, -0.1],
          1: [0, 0],
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],

    isCustomizable: false,
  }),

  new Preset({
    id: 'effect-jiggle',
    title: 'Jiggle',
    isPro: true,
    type: Preset.Type.Effect,
    speed: Preset.Speed.Medium,
    duration: 1.5,
    timingCurve: Preset.TimingCurve.Gentle,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectRotation,
        type: PresetAnimation.Types.EffectRotation.Clockwise,
        values: {
          0: 0,
          0.2: 30,
          0.4: -20,
          0.6: 10,
          0.8: -5,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],

    isCustomizable: false,
  }),

  new Preset({
    id: 'effect-levitate',
    title: 'Levitate',
    isPro: true,
    type: Preset.Type.Effect,
    duration: 1.4,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.EffectTranslate,
        type: PresetAnimation.Types.EffectTranslate.Vertical,
        values: {
          0: 0,
          0.2: -1,
          0.6: 0.5,
          1: 0,
        },
        timingCurves: {
          0: [0.35, 0, 0.65, 1],
          0.2: [0.35, 0, 0.65, 1],
          0.6: [0.35, 0, 0.65, 1],
          1: [0.35, 0, 0.65, 1],
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'effect-jump',
    title: 'Jump',
    isPro: true,
    type: Preset.Type.Effect,
    speed: Preset.Speed.Medium,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectTranslate,
        type: PresetAnimation.Types.EffectTranslate.Vertical,
        values: {
          0: 0,
          0.3: -1,
          0.6: 0,
          0.8: -0.33,
          1: 0,
        },
        timingCurves: {
          0: [0.65, 0, 1, 1],
          0.3: [0.65, 0, 0.35, 1],
          0.6: [0, 0, 1, 1],
          0.8: [0.65, 0, 0.35, 1],
          1: [0, 0, 0.35, 1],
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],

    isCustomizable: false,
  }),

  new Preset({
    id: 'effect-bounce',
    title: 'Bounce',
    isPro: true,
    type: Preset.Type.Effect,
    speed: Preset.Speed.Fast,
    baseAnimations: [
      {
        id: PresetAnimation.Id.EffectTranslate,
        type: PresetAnimation.Types.EffectTranslate.Vertical,
        values: {
          0: 0,
          0.5: -0.33,
          1: 0,
        },
        timingCurves: {
          0: [0, 0, 1, 1],
          0.5: [0.65, 0, 0.35, 1],
          1: [0, 0, 1, 1],
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],

    isCustomizable: false,
  }),

  new Preset({
    id: 'effect-spin',
    title: 'Spin',
    type: Preset.Type.Effect,
    speed: Preset.Speed.Medium,
    baseAnimations: [],
    requiredAnimations: [
      {
        id: PresetAnimation.Id.EffectRotation,
        type: PresetAnimation.Types.EffectRotation.Clockwise,
        values: {
          0: 0,
          1: 360,
        },
        timingCurves: {
          0: [0.5, 0.25, 1, 1],
          1: [0, 0, 0.5, 0.75],
        },
      },
    ],
    optionalAnimations: [],
  }),

  new Preset({
    id: 'glitchy-layer-blur',
    title: 'Glitchy Layer Blur',
    isPro: true,
    type: Preset.Type.Effect,
    speed: Preset.Speed.Medium,
    timingCurve: Preset.TimingCurve.Eager,
    baseAnimations: [
      {
        id: PresetAnimation.Id.AppearanceLayerBlur,
        type: PresetAnimationAppearanceLayerBlurType.Layer,
        values: {
          0: 0,
          0.3: 0.3,
          0.6: 0,
          0.8: 0.1,
          1: 0,
        },
      },
    ],
    requiredAnimations: [],
    optionalAnimations: [],
  }),
]

export const defaultPresetsStore = new PresetsStore([
  ...inPresets,
  ...outPresets,
  ...effectPresets,
])

export interface IProps {
  presetsStore?: IPresetsStore
}
export const PresetsStoreProvider: React.FCC<IProps> = ({
  presetsStore = defaultPresetsStore,
  children,
}) => {
  return (
    <PresetsStoreContext.Provider value={presetsStore}>
      {children}
    </PresetsStoreContext.Provider>
  )
}
