import { DurationComponent, Root } from '@aninix-inc/model'
import { CommentsTimeline } from '@aninix/core/modules/comments-timeline'
import { format } from 'date-fns'
import { observer } from 'mobx-react'
import * as React from 'react'
import { useProject } from '../../../../../../../stores'
import { PlaybackProgress } from './playback-progress'

const secondsToMS = (seconds: number) => {
  seconds = Math.round(seconds * 1000) / 1000 // rounding to 3rd decimal, to avoid JS likeliness of 899.9999999 to be rounded to 899
  const date = new Date(seconds * 1000)
  return seconds < 1 ? format(date, 'SSS') : format(date, 'sSSS')
}

const secondsToSS = (seconds: number) => {
  const date = new Date(seconds * 1000)
  return format(date, 's.S')
}

export interface IProps {}
export const Indicators: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const duration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value

  const millisecondsAtPoint = React.useCallback(
    (point: number) => secondsToMS(duration * point),
    []
  )
  const secondsAtPoint = React.useCallback(
    (point: number) => secondsToSS(duration * point),
    []
  )
  const indicators =
    duration <= 1
      ? [
          'MS',
          millisecondsAtPoint(0.25),
          millisecondsAtPoint(0.5),
          millisecondsAtPoint(0.75),
          millisecondsAtPoint(1),
        ]
      : [
          'S',
          secondsAtPoint(0.25),
          secondsAtPoint(0.5),
          secondsAtPoint(0.75),
          secondsAtPoint(1),
        ]

  return (
    <div className="relative flex w-2/3 flex-row items-center justify-between">
      <div className="absolute left-[-8px] right-[8px] top-[8px]">
        <CommentsTimeline isShowingComments={true} />
      </div>

      <PlaybackProgress />

      {indicators.map((indicator) => (
        <p
          key={indicator.toString()}
          className="font-body text-xs text-gray-400"
        >
          {indicator}
        </p>
      ))}
    </div>
  )
})

Indicators.displayName = 'Indicators'
