import classNames from 'classnames'
import * as React from 'react'

export interface IProps {
  defaultIsImport?: boolean
  onChange: (isImport: boolean) => void
  disabled?: boolean
}
export const ToggleImport: React.FCC<IProps> = ({
  onChange,
  defaultIsImport = true,
  disabled,
}) => {
  const [isImport, setIsImport] = React.useState(defaultIsImport)

  React.useEffect(() => {
    onChange(isImport)
  }, [isImport])

  return (
    <button
      className="text-[12px]/[14px] font-medium w-[126px] h-[26px]"
      onClick={() => setIsImport((v) => !v)}
      style={
        {
          '--currentColor': isImport ? '#18A0FB' : '#374FD5',
          '--transition-duration': '200ms',
          '--arrow-head-transition-delay': '80ms',
          '--transition-graph': 'cubic-bezier(0.5,0.35,0.15,1)',
        } as React.CSSProperties
      }
      disabled={disabled}
    >
      <div className="text-black rounded-full py-1.5 px-2.5 relative items-center flex flex-row gap-9 bg-[var(--currentColor)] transition-color duration-[var(--transition-duration)] ease-[var(--transition-graph)]">
        <div
          className={classNames(
            'z-0 absolute bg-white rounded-full inset-0.5 transition-transform duration-[var(--transition-duration)] ease-[var(--transition-graph)] w-[70px]',
            {
              ['translate-x-0']: !isImport,
              ['translate-x-[calc(100%-18px)]']: isImport,
            }
          )}
        />

        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={classNames(
            'absolute z-10 left-[calc(50%)] transition-all duration-[var(--transition-duration)] ease-[cubic-bezier(0.85,0,0.15,1)] stroke-[var(--currentColor)]',
            {
              ['-translate-x-[2px]']: isImport,
              ['-translate-x-[13px]']: !isImport,
            }
          )}
        >
          <g transform="translate(0 8)">
            <path d="M2 0H13" strokeWidth="1.6" strokeLinecap="round" />

            <path
              d="M0 0H6"
              strokeWidth="1.6"
              strokeLinecap="round"
              className={classNames(
                'rotate-45 translate-x-[2px] transition-transform duration-[var(--transition-duration)] ease-[0.5,0.35,1,1] delay-[var(--arrow-head-transition-delay)]',
                {
                  '!rotate-0 !duration-[var(--transition-duration)] !delay-0 !ease-[0,0,0.15,1]':
                    isImport,
                }
              )}
            />
            <path
              d="M0 0H6"
              strokeWidth="1.6"
              strokeLinecap="round"
              className={classNames(
                '-rotate-45 translate-x-[2px] transition-transform duration-[var(--transition-duration)] ease-[0.5,0.35,1,1] delay-[var(--arrow-head-transition-delay)]',
                {
                  '!rotate-0 !duration-[var(--transition-duration)] !delay-0 !ease-[0,0,0.15,1]':
                    isImport,
                }
              )}
            />

            <path
              d="M0 0H6"
              strokeWidth="1.6"
              strokeLinecap="round"
              className={classNames(
                'rotate-[135deg] translate-x-[13px] transition-transform duration-[var(--transition-duration)] ease-[0.5,0.35,1,1] delay-[var(--arrow-head-transition-delay)]',
                {
                  '!rotate-[180deg] !duration-[var(--transition-duration)] !delay-0 !ease-[0,0,0.15,1]':
                    !isImport,
                }
              )}
            />
            <path
              d="M0 0H6"
              strokeWidth="1.6"
              strokeLinecap="round"
              className={classNames(
                '-rotate-[135deg] translate-x-[13px] transition-transform duration-[var(--transition-duration)] ease-[0.5,0.35,1,1] delay-[var(--arrow-head-transition-delay)]',
                {
                  '!-rotate-[180deg] !duration-[var(--transition-duration)] !delay-0 !ease-[0,0,0.15,1]':
                    !isImport,
                }
              )}
            />
          </g>
        </svg>

        <p
          className={classNames(
            'z-10 text-secondary transition-color duration-75 ease-[var(--transition-graph)] delay-[25ms]',
            {
              ['text-white']: isImport,
            }
          )}
        >
          Figma
        </p>

        <p
          className={classNames(
            'z-10 text-secondary transition-color duration-75 ease-[var(--transition-graph)] delay-[25ms]',
            {
              ['text-white']: !isImport,
            }
          )}
        >
          Aninix
        </p>
      </div>
    </button>
  )
}

ToggleImport.displayName = 'ToggleImport'
