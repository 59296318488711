import {
  SaveUserPreset,
  UserPresets as UserPresetsComponent,
} from '@aninix/app-design-system'
import { Modal, Popover } from '@material-ui/core'
import * as React from 'react'

import {
  AnimationCurveAspect,
  CurveStyle,
  CurveType,
  Entity,
  EntityType,
  EntityTypeComponent,
  NameComponent,
  TimingCurveComponent,
} from '@aninix-inc/model'
import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { useFlattenEntities } from '@aninix/core'
import { EditCurveStyle } from '../../../common/edit-curve-style'

export interface IProps {
  segments: [Entity, Entity][]
  onClose: () => void
}
export const UserPresets: React.FCC<IProps> = ({ segments, onClose }) => {
  useFlattenEntities(segments)
  const analytics = useAnalytics()
  const [isCreateNewCurveVisible, setIsCreateNewCurveVisible] =
    React.useState(false)
  const [editingCurveId, setEditingCurveId] = React.useState<
    string | undefined
  >()

  const curve = (() => {
    const leftKey = segments[0][0]
    const aspect = leftKey.getAspectOrThrow(AnimationCurveAspect)
    return aspect.curveComponent()
  })()

  const showCreateNewCurve = React.useCallback(() => {
    setIsCreateNewCurveVisible(true)
  }, [])

  const hideCreateNewCurve = React.useCallback(() => {
    setIsCreateNewCurveVisible(false)
  }, [])

  const showEditCurve = React.useCallback((curveId: string) => {
    setEditingCurveId(curveId)
  }, [])

  const hideEditCurve = React.useCallback(() => {
    setEditingCurveId(undefined)
  }, [])

  const applyCurve = React.useCallback(
    (curveStyleId: string) => {
      segments.forEach((segment) => {
        segment[0]
          .getAspectOrThrow(AnimationCurveAspect)
          .updateOrAttachCurveStyle(curveStyleId)
      })
      analytics.track({
        eventName: AnalyticsEvent.CurveStyleAttached,
      })
      onClose()
    },
    [onClose, analytics]
  )

  const createNewCurve = React.useCallback(
    (curveName: string) => {
      const generatedCurveStyle = (() => {
        if (curve instanceof TimingCurveComponent) {
          const curveStyle = project.createEntity(CurveStyle)
          curveStyle.updateComponent(NameComponent, curveName)
          const aspect = curveStyle
            .getAspectOrThrow(AnimationCurveAspect)
            .setCurveType(CurveType.Timing)
          curveStyle.updateComponent(
            // @ts-ignore
            aspect.curveComponent().constructor,
            curve.value
          )
          return curveStyle
        }

        const curveStyle = project.createEntity(CurveStyle)
        curveStyle.updateComponent(NameComponent, curveName)
        const aspect = curveStyle
          .getAspectOrThrow(AnimationCurveAspect)
          .setCurveType(CurveType.Spring)
        curveStyle.updateComponent(
          // @ts-ignore
          aspect.curveComponent().constructor,
          curve.value
        )
        return curveStyle
      })()

      applyCurve(generatedCurveStyle.id)
      hideCreateNewCurve()
    },
    [hideCreateNewCurve, curve, applyCurve]
  )

  const curveValue = curve
  const appliedCurve = segments[0][0]
    .getAspectOrThrow(AnimationCurveAspect)
    .getCurveComponent()
  const project = segments[0][0].getProjectOrThrow()
  const curveStyles = project.getEntitiesByPredicate(
    (entity) =>
      entity.getComponentOrThrow(EntityTypeComponent).value ===
      EntityType.CurveStyle
  )
  const editingCurve =
    editingCurveId != null ? project.getEntity(editingCurveId) : undefined

  const userPresetsRef = React.useRef<any>()

  return (
    <>
      <div ref={userPresetsRef}>
        <UserPresetsComponent
          curveStyles={curveStyles}
          onAddPreset={showCreateNewCurve}
          onEditPreset={showEditCurve}
          onApply={applyCurve}
          selectedCurveStyleId={appliedCurve?.id}
          openAllGroupsByDefault
        />
      </div>

      {/* @ts-ignore */}
      <Modal
        open={isCreateNewCurveVisible}
        onClose={hideCreateNewCurve}
        className="flex flex-col items-center justify-center text-[var(--figma-color-text)]"
      >
        <SaveUserPreset
          graphValue={curveValue}
          onClose={hideCreateNewCurve}
          onAddUserPreset={createNewCurve}
        />
      </Modal>

      <Popover
        open={editingCurveId != null}
        anchorEl={userPresetsRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        onClose={hideEditCurve}
        disableRestoreFocus
      >
        {editingCurve != null ? (
          <EditCurveStyle curveStyle={editingCurve} onClose={hideEditCurve} />
        ) : null}
      </Popover>
    </>
  )
}
