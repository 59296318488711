import { useComponents } from '@aninix/core'
import { useProject } from '@aninix/core/stores'
import classNames from 'classnames'
import * as React from 'react'
import { applyMatchingEntitiesValues } from '../../overrides-export/utils/apply-matching-entities-values'
import { OverrideWithProject, useOverrides } from '../use-overrides'
import { Colors } from './panels/colors'
import { Medias } from './panels/medias'
import { Texts } from './panels/texts'
import { OverrideProvider } from './use-override-item'

import { OverridePanel } from '../components/override-panel'

export interface IProps {
  data: OverrideWithProject
  index: string
}
export const Override: React.FCC<IProps> = ({ data, index }) => {
  const { selectedOverride, setSelectedOverride } = useOverrides()

  const isSelected = selectedOverride === data.id

  const project = useProject()

  const version = useComponents(
    data.project.entities.flatMap((e) => e.components)
  )

  React.useEffect(() => {
    if (isSelected)
      applyMatchingEntitiesValues({ sourceOfChanges: data.project, project })
  }, [data.project, selectedOverride, version])

  const name = isSelected
    ? `${data.name ?? 'Variation'} (current)`
    : `${data.name ?? 'Variation'}`

  return (
    <OverrideProvider data={data}>
      <div
        onClick={() => {
          setSelectedOverride(data.id)
        }}
        className={classNames('flex flex-col gap-2 px-2', {})}
      >
        <OverridePanel isOpen={isSelected} label={name}>
          <div>
            {/* <IndexLabel value={index} onChange={noop} /> */}

            <div className="flex flex-col gap-2">
              <Colors />
              <Texts />
              <Medias />
            </div>
          </div>
        </OverridePanel>
      </div>
    </OverrideProvider>
  )
}

Override.displayName = 'Override'
