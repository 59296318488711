import { Point2D } from '@aninix-inc/model/legacy'
import * as React from 'react'

import { PresetTimingCurveType } from '../../../models/preset'
import * as styles from './index.scss'

const defaultSize = { x: 16, y: 16 }

export interface IProps {
  type: PresetTimingCurveType
  size?: Point2D
}
export const TimingCurve: React.FCC<IProps> = ({
  type,
  size = defaultSize,
}) => {
  if (type === PresetTimingCurveType.Eager) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 2C5 2 5 12.5 2 14"
          strokeWidth={1}
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetTimingCurveType.Gentle) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 2C5 2 10 14 2 14"
          strokeWidth={1}
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  if (type === PresetTimingCurveType.Bounce) {
    return (
      <svg
        width={size.x}
        height={size.y}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 14C2 8 4 2 7 2C10 2 10 6.5 14 6.5"
          strokeWidth={1}
          className={styles.icon__stroke}
        />
      </svg>
    )
  }

  return null
}
