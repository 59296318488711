import { observer } from 'mobx-react'
import * as React from 'react'

export interface IProps {}
export const Indicators: React.FCC<IProps> = observer(() => {
  const indicators = [0, 0.25, 0.5, 0.75, 1]

  return (
    <div className="absolute flex flex-row justify-between h-full w-full">
      {indicators.map((indicator) => (
        <div
          key={indicator.toString()}
          className="h-full -top-1 w-[1px] bg-gray-200"
          style={{ left: indicator * 100 + '%' }}
        />
      ))}
    </div>
  )
})

Indicators.displayName = 'Indicators'
