import { usePointerMove } from '@aninix/app-design-system'
import { useLottie } from 'apps/web-app/src/use-cases/use-lottie'
import _ from 'lodash'
import { AnimationItem } from 'lottie-web'
import * as React from 'react'

export interface IProps {
  animationItem: AnimationItem
}
export const ProgressBar: React.FCC<IProps> = ({ animationItem }) => {
  const { currentFrame, totalFrames, isPaused, pause, play } =
    useLottie(animationItem)

  const { startListen, isListening, offsetX, endAtX } = usePointerMove()

  const progressRef = React.useRef<HTMLDivElement>(null)

  const progress = (currentFrame / totalFrames) * 100

  const [beenPlaying, setBeenPlaying] = React.useState<boolean | null>(null)

  const managePlaybackOnTimelineInteraction = React.useCallback(() => {
    if (isListening && beenPlaying === null) {
      setBeenPlaying(!animationItem.isPaused)
      if (!isPaused) pause()
    }

    if (!isListening) {
      setBeenPlaying(null)
      if (beenPlaying) play()
    }
  }, [animationItem, isPaused, isListening, beenPlaying])

  const goToTargetTime = React.useCallback(
    (targetTime: number) => {
      animationItem.goToAndStop(targetTime, false)
    },
    [isPaused, animationItem]
  )

  React.useEffect(() => {
    managePlaybackOnTimelineInteraction()

    if (isListening === false) {
      return
    }

    const { left, width } = progressRef.current!.getBoundingClientRect()

    goToTargetTime(
      _.clamp(
        (((endAtX - left) / width) * animationItem.getDuration() * 1000) /
          animationItem.playSpeed,
        0,
        (animationItem.getDuration() * 1000) / animationItem.playSpeed
      )
    )
  }, [isListening, offsetX, endAtX, animationItem])

  return (
    <div
      className="relative h-[40px] w-[90px] flex-1 sm:w-[120px]"
      ref={progressRef}
    >
      <div className="absolute left-0 top-1/2 z-0 h-1 w-full -translate-y-1/2 overflow-hidden rounded-full bg-gray-300">
        <div
          className="pointer-events-none h-full bg-gray-500"
          style={{
            width: `${progress}%`,
          }}
        />
      </div>
      <div
        className="absolute -left-1 -right-1 bottom-0 top-0 z-30"
        // @ts-ignore
        onPointerDown={startListen}
      />
    </div>
  )
}

ProgressBar.displayName = 'ProgressBar'
