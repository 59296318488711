export type Range = [number, number]

export function isRangesOverlapped(left: Range, right: Range): boolean {
  const [inLeft, outLeft] = left
  const [inRight, outRight] = right
  const isOverlappedLeft =
    inLeft <= inRight && outLeft >= inRight && outLeft <= outRight
  const isOverlappedRight =
    inLeft >= inRight && inLeft <= outRight && outLeft >= outRight
  const isIncludeLeft = inLeft <= inRight && outLeft >= outRight
  const isIncludeRight = inLeft >= inRight && outLeft <= outRight
  return (
    isOverlappedLeft || isOverlappedRight || isIncludeLeft || isIncludeRight
  )
}

/**
 * @example
 * ```
 * --O=====X------O=======X---- |
 * --O===X----O====X----------- | ranges by properties
 * --------OX---------O====X--- |
 *   |      | |            |
 * --O======X-O============X--- | ranges
 * ```
 */
export function rangify(ranges: Range[]): Range[] {
  if (!ranges.length) {
    return []
  }

  // Sort ranges based on the starting point, and if they are equal based on the end point
  ranges.sort((a, b) => a[0] - b[0] || a[1] - b[1])

  const mergedRanges: [number, number][] = []
  let currentRange = ranges[0]

  for (let i = 1; i < ranges.length; i++) {
    const [currentStart, currentEnd] = currentRange
    const [nextStart, nextEnd] = ranges[i]

    if (nextStart > currentEnd) {
      // No overlap
      mergedRanges.push(currentRange)
      currentRange = ranges[i]
    } else {
      // Merge overlapping ranges
      currentRange = [currentStart, Math.max(currentEnd, nextEnd)]
    }
  }

  // Add the last range
  mergedRanges.push(currentRange)

  return mergedRanges
}
