import { useGetCurrentFolder } from '@aninix/api'
import * as React from 'react'

import { Icons } from '../icons'
import { Crumb } from './move-to-breadcrumbs-crumb'

export interface IProps {
  folderId?: string
  setLocationFolderId: React.Dispatch<React.SetStateAction<string | undefined>>
  setSelectedFolderId: React.Dispatch<React.SetStateAction<string | undefined>>
}
export const MoveToBreadcrumbs: React.FCC<IProps> = ({
  folderId,
  setLocationFolderId,
  setSelectedFolderId,
}) => {
  const currentFolder = folderId ? useGetCurrentFolder({ folderId }).data : null
  const breadcrumbs = currentFolder?.data.breadcrumbs
  return (
    <div className="flex flex-row items-center gap-1">
      <Crumb
        label="Home"
        icon={<Icons.Home />}
        setLocationFolderId={setLocationFolderId}
        setSelectedFolderId={setSelectedFolderId}
      />
      {breadcrumbs?.map(({ id, name }) => (
        <Crumb
          key={id}
          label={name}
          folderId={id}
          setLocationFolderId={setLocationFolderId}
          setSelectedFolderId={setSelectedFolderId}
        />
      ))}
    </div>
  )
}

MoveToBreadcrumbs.displayName = 'MoveToBreadcrumbs'
