import {
  Entity,
  EntityType,
  EntityTypeComponent,
  getEntryOrThrow,
} from '@aninix-inc/model'
import {
  useMouseMove,
  useThrottle,
  VirtualizedList,
} from '@aninix/app-design-system'
import {
  usePlayback,
  useProject,
  useReloadOnAnyUpdate,
  useTimeline,
} from '@aninix/core'
import classNames from 'classnames'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { List } from 'react-virtualized'
import { useSelection } from '../../selection'
import { getNodeRows, TRow } from '../get-row-data'
import { useAutoScrollSync, useScrollSync } from '../scroll-sync'
import { LayerDndProvider } from './drag-n-drop'
import * as styles from './index.scss'
import { Layer } from './layer'
import { Property } from './property'
import { PropertyGroup } from './property-group'
import { TimeControlIndicator } from './time-control-indicator'
import { TimeControlPlayback } from './time-control-playback'

type InfoContextType = {
  time: number
}

const InfoContext = React.createContext<InfoContextType>(
  null as unknown as InfoContextType
)

const InfoProvider: React.FCC<InfoContextType> = ({ time, children }) => {
  return (
    <InfoContext.Provider value={{ time }}>{children}</InfoContext.Provider>
  )
}

export const useInfo = () => {
  const context = React.useContext(InfoContext)
  if (context === null) {
    throw new Error('useInfo must be used within a InfoProvider')
  }
  return context
}

const InfoActual: React.FCC<{
  isPlaying: boolean
  width: number
  height: number
  onWidthUpdate: (width: number) => void
}> = React.memo(
  ({ width, height, onWidthUpdate }) => {
    const project = useProject()
    useReloadOnAnyUpdate(project)
    const { selection } = useSelection()

    const listRef = React.useRef<List>(null)
    const containerRef = React.useRef<HTMLDivElement>(null)
    const listContainerRef = React.useRef<HTMLDivElement>(null)
    const data = getNodeRows(getEntryOrThrow(project), { indent: 0 })

    const [autoScroll, setAutoScroll] = React.useState<boolean>(false)
    const { scrollTop, scrollTo, scrollToEntity } = useScrollSync()

    useAutoScrollSync({
      containerRef,
      listContainerRef,
      rowsCount: data.length,
      enabled: autoScroll,
    })

    const handleClick = React.useCallback(() => {
      selection.deselectAll()
    }, [selection])

    const bufferTimelineInfoWidthRef = React.useRef(0)
    const minTimelineInfoWidthRef = React.useRef(240)
    const maxTimelineInfoWidthRef = React.useRef(640)

    const resizeStart = React.useCallback(() => {
      bufferTimelineInfoWidthRef.current = width
    }, [width])

    const handleResize = useThrottle({
      callback: React.useCallback(
        (deltaX) => {
          const nextValue = R.clamp(
            minTimelineInfoWidthRef.current,
            maxTimelineInfoWidthRef.current,
            bufferTimelineInfoWidthRef.current + deltaX
          )

          onWidthUpdate(nextValue)
        },
        [onWidthUpdate]
      ),
      delay: 32,
    })

    const resizeEnd = React.useCallback(() => {
      bufferTimelineInfoWidthRef.current = 0
    }, [])

    React.useEffect(
      function autoscrollToSelectedRow() {
        const subscription = selection.onSelectionUpdate((prev, current) => {
          const newEntities = R.difference(current, prev)
          const selectedNodes = newEntities
            .map((id) => {
              const entity = project.getEntity(id)
              if (
                entity?.getComponentOrThrow(EntityTypeComponent).value ===
                EntityType.Node
              ) {
                return entity
              }
              return undefined
            })
            .filter((n) => n !== undefined) as Entity[]

          if (selectedNodes.length === 0) {
            return
          }

          const lastItem = R.last(selectedNodes)
          scrollToEntity(listRef, lastItem)
        })

        return subscription
      },
      [selection, scrollToEntity]
    )

    const { startListen, offsetX, isListening } = useMouseMove()

    const startListenFn = React.useCallback((e: MouseEvent) => {
      resizeStart()
      startListen(e)
    }, [])

    React.useEffect(() => {
      if (isListening === false) {
        resizeEnd()
        return
      }

      handleResize(offsetX)
    }, [isListening, offsetX])

    const handleDrop = React.useCallback(
      (entities: Entity[]) => {
        queueMicrotask(() =>
          scrollToEntity(listRef, entities[entities.length - 1])
        )
      },
      [scrollToEntity]
    )

    return (
      <LayerDndProvider
        onDragStart={() => setAutoScroll(true)}
        onDragEnd={() => setAutoScroll(false)}
        onDrop={handleDrop}
      >
        <div className={styles.container} ref={containerRef}>
          <div
            className={classNames(styles.resizer, {
              [styles['resizer--active']]: isListening,
            })}
            // @ts-ignore
            onMouseDown={startListenFn}
          />

          <div className={styles.time_control_container}>
            <TimeControlPlayback />
            <TimeControlIndicator />
          </div>

          {/* TODO: replace div with another interactive component? */}
          <div
            onClick={handleClick}
            style={{
              overflow: 'hidden',
              height,
            }}
          >
            <div ref={listContainerRef} style={{ position: 'relative' }}>
              <VirtualizedList
                ref={listRef}
                onScroll={scrollTo}
                data={data}
                renderRow={renderRow}
                offsetY={scrollTop}
                height={height}
              />
            </div>
          </div>
        </div>
      </LayerDndProvider>
    )
  },
  (_, current) => current.isPlaying
)

InfoActual.displayName = 'InfoActual'

export const Info: React.FC = observer(() => {
  const playback = usePlayback()
  const timeline = useTimeline()
  return (
    <InfoProvider time={playback.time}>
      <InfoActual
        isPlaying={playback.isPlaying}
        width={timeline.infoWidth}
        height={timeline.height}
        onWidthUpdate={timeline.updateInfoWidth}
      />
    </InfoProvider>
  )
})

Info.displayName = 'Info'

const renderRow = ({ value }: { value: TRow }) => {
  switch (value.type) {
    case 'layer':
      return (
        <Layer
          key={value.entity.id}
          layer={value.entity}
          indent={value.indent}
        />
      )
    case 'property-group':
      return (
        <PropertyGroup
          key={value.entity.id}
          propertyGroup={value.entity}
          name={value.meta.name}
          indent={value.indent}
        />
      )
    case 'property':
      return (
        <Property
          key={value.entity.id}
          property={value.entity}
          name={value.meta.name}
          iconType={value.meta.iconType}
          indent={value.indent}
          additionalIndent={value.additionalIndent}
        />
      )
    default:
      return <div />
  }
}
