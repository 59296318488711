import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 24,
  y: 24,
}

export const Dots: React.FCC<IIcon> = ({
  size = defaultSize,
  color = 'black',
  className = '',
}) => (
  <svg
    className={className}
    width={size.x}
    height={size.y}
    viewBox="0 0 24 24"
    fill="none"
    stroke={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="5"
      cy="12"
      r="1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="12"
      cy="12"
      r="1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="19"
      cy="12"
      r="1"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
