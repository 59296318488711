type RGBA = {
  r: number
  g: number
  b: number
  a: number
}

export const applyColorMatrix = (color: RGBA, matrixValues: string): RGBA => {
  const values = matrixValues.split(' ').map(parseFloat)
  if (values.length !== 20) {
    throw new Error('Matrix must be 5x4')
  }

  const [m00, m01, m02, m03, m04, m10, m11, m12, m13, m14, m20, m21, m22, m23, m24, m30, m31, m32, m33, m34] = values

  const r = color.r / 255
  const g = color.g / 255
  const b = color.b / 255
  const a = color.a

  const newR = m00 * r + m01 * g + m02 * b + m03 * a + m04
  const newG = m10 * r + m11 * g + m12 * b + m13 * a + m14
  const newB = m20 * r + m21 * g + m22 * b + m23 * a + m24
  const newA = m30 * r + m31 * g + m32 * b + m33 * a + m34

  return {
    r: Math.min(255, Math.max(0, Math.round(newR * 255))),
    g: Math.min(255, Math.max(0, Math.round(newG * 255))),
    b: Math.min(255, Math.max(0, Math.round(newB * 255))),
    a: Math.min(1, Math.max(0, newA)),
  }
}
