import { ClipContentComponent, Entity } from '@aninix-inc/model'
import { Checkbox, PropertyRowV2 } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'

export const ClipContent: React.FC = () => {
  const { nodes } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) =>
    n.hasComponent(ClipContentComponent)
  )

  if (!filteredNodes.length) return null

  const isClipped = filteredNodes.map((n) =>
    n.getComponentOrThrow(ClipContentComponent)
  )[0].value

  return <ClipContentMemo isClipped={isClipped} nodes={filteredNodes} />
}

const ClipContentMemo: React.FC<{
  isClipped: boolean
  nodes: Entity[]
}> = React.memo(
  ({ isClipped, nodes }) => {
    return (
      <PropertyRowV2
        inputs={
          <div className="py-[6px]">
            <Checkbox
              isChecked={isClipped}
              onClick={() => {
                nodes.forEach((l) =>
                  l.updateComponent(ClipContentComponent, (v) => !v)
                )
              }}
              label="Clip content"
            />
          </div>
        }
      />
    )
  },
  (prev, next) => prev.isClipped === next.isClipped
)
