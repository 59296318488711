import * as React from 'react'

const defaultSize = {
  x: 20,
  y: 20,
}

export interface IProps {
  size?: { x: number; y: number }
  style?: React.CSSProperties
}
export const AnchorPoint: React.FCC<IProps> = ({
  size = defaultSize,
  style,
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <path
      d="M5.5 11a.5.5 0 10-1 0 .5.5 0 001 0z"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
    />
    <path
      d="M12 11.5A7.5 7.5 0 004.5 4m5.5 6l2 2 2-2"
      stroke="var(--figma-color-text, rgba(0, 0, 0, 0.8))"
      strokeLinecap="square"
    />
  </svg>
)
