import { observer } from 'mobx-react-lite'
import * as React from 'react'

import { useFpsStatsInteractor } from './interactor'
import { FpsStatsRouter } from './router'

export interface IProps {}
export const FpsStats: React.FCC<IProps> = observer(() => {
  const interactor = useFpsStatsInteractor()

  return <FpsStatsRouter interactor={interactor} />
})
