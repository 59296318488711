import {
  DashComponent,
  Entity,
  GapComponent,
  SolidPaint,
  StrokeCap,
  StrokeCapEndComponent,
  StrokeCapStartComponent,
  StrokeJoin,
  StrokeJoinComponent,
  StrokesRelationsAspect,
  StrokeWeightComponent,
} from '@aninix-inc/model'
import { INode } from 'svgson'
import { Gradient } from '../../types'
import { parseColor } from '../../utils/parse-color'
import { applyGradient } from './apply-gradient'

const strokeLinecaps = [
  'none',
  'round',
  'square',
  'arrow-lines',
  'arrow-equilateral',
  'triangle-filled',
  'diamond-filled',
  'circle-filled',
]

const strokeJoins = ['miter', 'bevel', 'round']

export const applyStroke = (
  entity: Entity,
  node: INode,
  gradients: Record<string, Gradient>
) => {
  const {
    stroke,
    strokeOpacity,
    strokeWidth,
    strokeDasharray,
    strokeLinecap,
    strokeLinejoin,
  } = node.attributes

  const strokeAspect = entity.createAspectIfNotExists(StrokesRelationsAspect)
  strokeAspect.clear()

  if (!stroke || stroke === 'none') return

  entity.updateComponent(StrokeWeightComponent, parseFloat(strokeWidth) || 1)

  if (strokeDasharray) {
    const [dash, gap] = strokeDasharray.split(' ').map(parseFloat)
    if (dash && gap) {
      entity.updateComponent(DashComponent, dash)
      entity.updateComponent(GapComponent, gap)
    }
  }

  if (strokeLinecap && strokeLinecaps.includes(strokeLinecap)) {
    const cap = strokeLinecap.replaceAll('-', '_').toUpperCase() as StrokeCap
    entity.updateComponent(StrokeCapStartComponent, cap)
    entity.updateComponent(StrokeCapEndComponent, cap)
  }

  if (strokeLinejoin && strokeJoins.includes(strokeLinejoin)) {
    entity.updateComponent(
      StrokeJoinComponent,
      strokeLinejoin.toUpperCase() as StrokeJoin
    )
  }

  if (stroke.startsWith('url')) {
    const gradientId = stroke.substring(5, stroke.length - 1)
    const gradient = gradients[gradientId]
    applyGradient(entity, gradient, strokeAspect)
  } else {
    const color = parseColor(stroke)

    const paint = entity.getProjectOrThrow().createEntity(SolidPaint, {
      ...color,
      a: strokeOpacity ? parseFloat(strokeOpacity) : color.a,
    })

    strokeAspect.addChild(paint)
  }
}
