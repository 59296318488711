import { timingCurve, TimingCurve } from '@aninix-inc/model/legacy'
import * as R from 'ramda'
import React from 'react'

import { IconButton } from '../../../buttons/icon-button'
import * as icons from '../../../icons'
import { Input } from '../../../input'
import { stringToTimingValue } from '../../helpers/stringToTimingValue'
import { timingValueToString } from '../../helpers/timingValueToString'
import * as styles from './index.scss'

function protectedNumber(number: number): number {
  if (Number.isNaN(number)) {
    return 0
  }

  return number
}

export interface IProps {
  id: string
  value: TimingCurve
  onChange: (value: TimingCurve) => void
  onCopy?: (value: string) => void
}
export const CopyPreset: React.FCC<IProps> = ({
  id,
  value,
  onChange,
  onCopy,
}) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [localValue, setLocalValue] = React.useState<string>(
    timingValueToString(value)
  )
  const valueRef = React.useRef<TimingCurve>(value)
  const localValueRef = React.useRef<string>(timingValueToString(value))
  const [copied, setCopied] = React.useState(false)

  const updateLocalValue = React.useCallback((newValue: string) => {
    setLocalValue(newValue)
    localValueRef.current = newValue
  }, [])

  const handleCopyClick = React.useCallback(
    (e: React.MouseEvent): void => {
      const TIME = 1500
      if (onCopy != null) {
        const input = inputRef.current as HTMLInputElement
        input.focus()
        input.select()
        input.setSelectionRange(0, 99999)
        onCopy(timingValueToString(value))
        setCopied(true)

        setTimeout(() => {
          setCopied(false)
        }, TIME)
      }
    },
    [value, onCopy]
  )

  const handleChange = React.useCallback(() => {
    const currentValue = localValueRef.current
    const parsedValue = stringToTimingValue(currentValue)

    const newValue = timingCurve.create({
      out: {
        x: R.clamp(0, 1, protectedNumber(parsedValue.out.x)),
        y: protectedNumber(parsedValue.out.y),
      },
      in: {
        x: R.clamp(0, 1, protectedNumber(parsedValue.in.x)),
        y: protectedNumber(parsedValue.in.y),
      },
    })

    // @NOTE: not call onChange if nothing changed
    if (R.equals(valueRef.current, newValue)) {
      return
    }

    onChange(newValue)
    inputRef.current?.blur()
  }, [onChange])

  const handleEscPress = React.useCallback(() => {
    updateLocalValue(timingValueToString(value))
    inputRef.current?.blur()
  }, [value])

  React.useEffect(() => {
    valueRef.current = value
    updateLocalValue(timingValueToString(value))

    if (inputRef.current !== document.activeElement) {
      return
    }

    setTimeout(() => {
      inputRef.current!.setSelectionRange(0, 0)
      inputRef.current!.setSelectionRange(0, inputRef.current!.value.length)
    })
  }, [value])

  const iconSize = React.useMemo(
    () => ({
      x: 16,
      y: 16,
    }),
    []
  )

  const handleFocus = React.useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      e.target.setSelectionRange(0, 0)
      e.target.setSelectionRange(0, e.target.value.length)
    },
    []
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles['input-wrapper']}>
        <Input
          ref={inputRef}
          value={localValue}
          icon={<icons.Graph previews={value.getCache()} variant="dashed" />}
          onChange={updateLocalValue}
          onFocus={handleFocus}
          onBlur={handleChange}
          onEnterPress={handleChange}
          onEscPress={handleEscPress}
        />
      </div>

      {onCopy != null && (
        <IconButton onClick={handleCopyClick}>
          {copied ? (
            <icons.Check />
          ) : (
            <icons.propertiesPanel.Copy size={iconSize} />
          )}
        </IconButton>
      )}
    </div>
  )
}
