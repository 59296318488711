import { Vector } from '@aninix-inc/model'
import { applyCommon } from '../maps/apply-common'
import { applyPath } from '../maps/apply-path'
import { NodeHandler } from '../types'
import { pointsToPath } from '../utils/points-to-path'

export const polygon: NodeHandler = (sourceNode, project, assets) => {
  const entity = project.createEntity(Vector)

  const { points, ...otherAttributes } = sourceNode.attributes

  // @TODO remove this when we have a proper way to handle points
  const node = {
    ...sourceNode,
    attributes: { ...otherAttributes, d: pointsToPath(points) },
  }

  applyCommon(entity, node, assets)
  applyPath(entity, node)

  return entity
}
