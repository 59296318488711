import { AnalyticsEvent, useAnalytics } from '@aninix/analytics'
import { useCreateFolder } from '@aninix/api'
import * as React from 'react'
import { Link, useParams } from 'react-router-dom'

import { toast } from '../../modules/toasts'
import { useCurrentUser } from '../../use-cases'
import { CreateNewFolderModal } from '../projects-list/create-new-folder-modal'

export interface IProps {
  refetchCurrentFolder: () => void
}

export const CreateNewFolderListItem: React.FCC<IProps> = ({
  refetchCurrentFolder,
}) => {
  const [showModal, setShowModal] = React.useState<boolean>(false)
  const analytics = useAnalytics()

  const closeModal = React.useCallback(() => {
    setShowModal(false)
  }, [])

  const openModal = React.useCallback(() => {
    setShowModal(true)
  }, [])

  const createFolder = useCreateFolder()

  const { folderId } = useParams()
  const { userId, teamId } = useCurrentUser()

  const trackFolderCreation = React.useCallback(() => {
    analytics.track({
      eventName: AnalyticsEvent.FolderCreate,
      properties: {
        space: teamId ? 'team' : 'personal',
      },
    })
  }, [analytics, teamId])

  const onCreateNewFolder = React.useCallback(
    (name: string) =>
      createFolder
        .mutateAsync({
          ...(teamId ? { teamId } : { userId }),
          parentFolderId: folderId,
          name,
        })
        .then((data) => {
          closeModal()
          refetchCurrentFolder()
          trackFolderCreation()
          toast(
            <Link
              relative="path"
              to={`${data.data.id}`}
              className="flex items-center"
            >
              <p>
                Folder <b>{name}</b> successfully created
              </p>
              ,
            </Link>,
            { variant: 'success' }
          )
        })
        .catch((e) =>
          toast("Couldn't create new folder", { variant: 'error' })
        ),
    [teamId, userId, folderId, createFolder, trackFolderCreation]
  )

  return (
    <>
      <button
        onClick={openModal}
        className="group relative flex max-h-32 w-full flex-col gap-4 border-dashed rounded-lg border-[1px] border-gray-200 bg-white p-4 transition-all duration-75 hover:shadow-lg group-focus:shadow-lg"
      >
        <CreateFolderIcon />
        <p className="text-base text-gray-500 font-medium">Create folder</p>
      </button>
      <CreateNewFolderModal
        name="New Folder"
        isLoading={createFolder.status === 'pending'}
        isOpen={showModal}
        handleNewFolderCreation={onCreateNewFolder}
        handleClose={closeModal}
      />
    </>
  )
}

CreateNewFolderListItem.displayName = 'CreateNewFolderListItem'

const CreateFolderIcon = () => (
  <svg
    width="39"
    height="34"
    viewBox="0 0 39 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M32.5033 32.9998H7.77325C5.49529 32.9998 4.35631 32.9998 3.48935 32.5494C2.75878 32.1699 2.1631 31.5742 1.7836 30.8437C1.33325 29.9767 1.33325 28.8377 1.33325 26.5598V4.22C1.33325 3.08102 1.33325 2.51153 1.55843 2.07805C1.74818 1.71276 2.04602 1.41492 2.4113 1.22517C2.84478 1 3.41427 1 4.55325 1H9.99992L18.294 6H33.3334C34.2632 6 34.7282 6 35.1096 6.1022C36.145 6.37958 36.9537 7.18828 37.2311 8.22364C37.3333 8.60509 37.3333 9.07002 37.3333 9.99988V27.9999V28.1698C37.3333 29.8782 37.3333 30.7325 36.9955 31.3827C36.7109 31.9306 36.2641 32.3774 35.7162 32.662C35.066 32.9998 34.2117 32.9998 32.5033 32.9998Z"
      stroke="#CED3D9"
      strokeWidth="1.6"
      strokeLinecap="round"
    />
    <path
      d="M19.3333 13V25M13.3333 19H25.3333"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
