import {
  CornerRadiusComponent,
  Entity,
  NumberKeyframe,
  getValueNumber,
  isIndividualCornerRadiusAvailable,
  isIndividualCornerRadiusEnabled,
  round,
  toggleIndividualCornerRadius,
} from '@aninix-inc/model'
import { PropertyKeyframesType } from '@aninix-inc/model/legacy'
import { CompactPropertyRow, buttons, icons } from '@aninix/app-design-system'
import { useEntities } from '@aninix/core'
import * as R from 'ramda'
import * as React from 'react'
import { useNodePropertiesPanel } from '../../..'
import { getKeyframesType } from '../../../utils/getKeyframeType'
import { NumberValue } from '../../values/number'
import { KeyframesPropertyControl } from '../keyframes-property-control'

const iconSize = {
  x: 12,
  y: 12,
}

const btnSize = {
  width: 32,
  height: 32,
}

export const CornerRadius: React.FC = () => {
  const [isEditable, setIsEditable] = React.useState(false)
  const { nodes, time, snapshots } = useNodePropertiesPanel()

  useEntities(nodes)

  const filteredNodes = nodes.filter((n) =>
    n.hasComponent(CornerRadiusComponent)
  )

  const components = filteredNodes.map((l) =>
    l.getComponentOrThrow(CornerRadiusComponent)
  )

  React.useEffect(() => {
    if (isEditable) setIsEditable(false)
  }, [time])

  if (!filteredNodes.length) return null

  const nodesWithAvailableIndividualCorners = nodes.filter((n) =>
    isIndividualCornerRadiusAvailable(n)
  )

  const isIndividualCornersAllowed =
    nodesWithAvailableIndividualCorners.length > 0

  const isIndividualCornersEnabled =
    isIndividualCornersAllowed &&
    R.all(
      (n) => isIndividualCornerRadiusEnabled(n),
      nodesWithAvailableIndividualCorners
    )

  const cornerRadiuses = snapshots.map((s) => s.cornerRadius)

  const keyframeType = getKeyframesType(components, time)

  return (
    <div onPointerMove={() => setIsEditable(true)}>
      {isEditable ? (
        <CornerRadiusEditable
          time={time}
          components={components}
          isIndividualCornersAllowed={isIndividualCornersAllowed}
          isIndividualCornersEnabled={isIndividualCornersEnabled}
          nodes={nodesWithAvailableIndividualCorners}
        />
      ) : (
        <CornerRadiusDisplay
          time={time}
          components={components}
          isIndividualCornersAllowed={isIndividualCornersAllowed}
          isIndividualCornersEnabled={isIndividualCornersEnabled}
          cornerRadiuses={cornerRadiuses}
          keyframeType={keyframeType}
        />
      )}
    </div>
  )
}

CornerRadius.displayName = 'CornerRadius'

const CornerRadiusEditable: React.FC<{
  time: number
  components: CornerRadiusComponent[]
  isIndividualCornersEnabled: boolean
  isIndividualCornersAllowed: boolean
  nodes: Entity[]
}> = ({
  time,
  components,
  isIndividualCornersEnabled,
  isIndividualCornersAllowed,
  nodes,
}) => {
  return (
    <CompactPropertyRow
      leftColumn={
        <div className="flex">
          <NumberValue
            components={components}
            time={time}
            icon={<icons.propertiesPanel.CornerRadius size={iconSize} />}
            min={0}
            dragDisabled={isIndividualCornersEnabled}
            disabled={isIndividualCornersEnabled}
            format={(v) => `${round(v, { fixed: 2 })}`}
          />
        </div>
      }
      rightColumn={
        <div className="flex flex-row">
          {isIndividualCornersAllowed && (
            <buttons.Icon
              onClick={() => {
                nodes.forEach(toggleIndividualCornerRadius)
              }}
              btnSize={btnSize}
              active={isIndividualCornersEnabled}
              tooltip="Independent corners"
            >
              <icons.propertiesPanel.IndividualCorners size={iconSize} />
            </buttons.Icon>
          )}
          <KeyframesPropertyControl
            components={components}
            time={time}
            KeyframeConstructor={NumberKeyframe}
            valueGetter={getValueNumber}
          />
        </div>
      }
    />
  )
}

CornerRadiusEditable.displayName = 'CornerRadiusEditable'

interface CornerRadiusDisplayProps {
  time: number
  components: CornerRadiusComponent[]
  isIndividualCornersEnabled: boolean
  isIndividualCornersAllowed: boolean
  cornerRadiuses: number[]
  keyframeType: PropertyKeyframesType
}

const propsAreEqual = (
  prev: CornerRadiusDisplayProps,
  next: CornerRadiusDisplayProps
) => {
  if (prev.isIndividualCornersAllowed !== next.isIndividualCornersAllowed)
    return false
  if (prev.isIndividualCornersEnabled !== next.isIndividualCornersEnabled)
    return false
  if (prev.cornerRadiuses.length !== next.cornerRadiuses.length) return false
  if (prev.keyframeType !== next.keyframeType) return false

  for (let i = 0; i < prev.cornerRadiuses.length; i++) {
    if (prev.cornerRadiuses[i] !== next.cornerRadiuses[i]) return false
  }

  return true
}

const CornerRadiusDisplay: React.FC<CornerRadiusDisplayProps> = React.memo(
  ({
    time,
    components,
    isIndividualCornersEnabled,
    isIndividualCornersAllowed,
  }) => {
    return (
      <CompactPropertyRow
        leftColumn={
          <div className="flex">
            <NumberValue
              components={components}
              time={time}
              icon={<icons.propertiesPanel.CornerRadius size={iconSize} />}
              min={0}
              dragDisabled={isIndividualCornersEnabled}
              disabled={isIndividualCornersEnabled}
              format={(v) => `${round(v, { fixed: 2 })}`}
            />
          </div>
        }
        rightColumn={
          <div className="flex flex-row">
            {isIndividualCornersAllowed && (
              <buttons.Icon
                onClick={() => {}}
                btnSize={btnSize}
                active={isIndividualCornersEnabled}
                tooltip="Independent corners"
              >
                <icons.propertiesPanel.IndividualCorners size={iconSize} />
              </buttons.Icon>
            )}
            <KeyframesPropertyControl
              components={components}
              time={time}
              KeyframeConstructor={NumberKeyframe}
              valueGetter={getValueNumber}
            />
          </div>
        }
      />
    )
  },
  propsAreEqual
)

CornerRadiusDisplay.displayName = 'CornerRadiusDisplay'
