import { useCases, useImagesStore } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'

import {
  Entity,
  EntityType,
  EntityTypeComponent,
  TimeComponent,
} from '@aninix-inc/model'
import { Preset } from '../../models/preset'

const customCovers: Record<string, React.ReactNode> = {
  'to-right': (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.3" cx="15" cy="16" r="6" fill="white" />
      <circle opacity="0.5" cx="24" cy="16" r="6" fill="white" />
      <circle opacity="0.75" cx="33" cy="16" r="6" fill="white" />
    </svg>
  ),
  'to-top': (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.3" cx="24" cy="23" r="5" fill="white" />
      <circle opacity="0.5" cx="24" cy="16" r="5" fill="white" />
      <circle opacity="0.75" cx="24" cy="9" r="5" fill="white" />
    </svg>
  ),
  fade: (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.5"
        cx="24"
        cy="16"
        r="8"
        fill="url(#paint0_linear_5163_56888)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5163_56888"
          x1="32"
          y1="16"
          x2="16"
          y2="16"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  ),
  'scale-horizontal': (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.3"
        x="16"
        y="8"
        width="16"
        height="16"
        rx="2"
        fill="white"
      />
      <rect
        opacity="0.5"
        x="19"
        y="8"
        width="10"
        height="16"
        rx="2"
        fill="white"
      />
      <rect
        opacity="0.75"
        x="22"
        y="8"
        width="4"
        height="16"
        rx="2"
        fill="white"
      />
    </svg>
  ),
  grow: (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.3" cx="24" cy="16" r="9" fill="white" />
      <circle opacity="0.5" cx="24" cy="16" r="6" fill="white" />
      <circle opacity="0.75" cx="24" cy="16" r="3" fill="white" />
    </svg>
  ),
  'trim-path': (
    <svg
      width="48"
      height="32"
      viewBox="0 0 48 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      strokeWidth="2"
      stroke="white"
    >
      <path opacity="0.1" d="M31 15C31 17.1304 30.1672 19.0663 28.8095 20.5" />
      <path opacity="0.3" d="M23 23C25.2879 23 27.3514 22.0396 28.8095 20.5" />
      <path
        opacity="0.5"
        d="M23 23.0001C20.8696 23.0001 18.9337 22.1673 17.5 20.8096"
      />
      <path opacity="0.6" d="M15 15C15 17.2879 15.9604 19.3514 17.5 20.8095" />
      <path opacity="0.7" d="M15 15C15 12.8696 15.8328 10.9337 17.1905 9.5" />
      <path opacity="0.8" d="M23 7C20.7121 7 18.6486 7.96038 17.1905 9.5" />
      <path opacity="0.9" d="M23 7C25.1304 7 27.0663 7.83275 28.5 9.1905" />
      <path
        opacity="1"
        d="M31 14.9999C31 12.7121 30.0396 10.6485 28.5 9.19043"
      />
    </svg>
  ),
}

export interface IProps {
  node: Entity
  preset: Preset
  isActive: boolean
}
export const PresetPreview: React.FCC<IProps> = observer(
  ({ node, preset, isActive }) => {
    const images = useImagesStore()

    const duration = (() => {
      const keyframeTimes = R.sortBy(
        (v) => v,
        node
          .getProjectOrThrow()
          .getEntitiesByPredicate(
            (entity) =>
              entity.getComponentOrThrow(EntityTypeComponent).value ===
              EntityType.Keyframe
          )
          .map((entity) => entity.getComponentOrThrow(TimeComponent).value)
      )

      if (keyframeTimes.length === 0) {
        return 0
      }

      return R.last(keyframeTimes)! - R.head(keyframeTimes)!
    })()

    const previews = React.useMemo(
      () => [
        {
          time: duration * 0,
          opacity: 1,
        },
        {
          time: duration * 0.33,
          opacity: 0.4,
        },
        {
          time: duration * 0.67,
          opacity: 0.4,
        },
        {
          time: duration * 1,
          opacity: 1,
        },
      ],
      [duration]
    )

    const Preview = customCovers[preset.id] ?? (
      <div
        style={{
          width: '100%',
          height: '100%',
          position: 'relative',
        }}
      >
        {previews.map((preview, idx) => (
          <div
            key={idx}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          >
            <useCases.StaticSvg
              time={preview.time}
              entity={node}
              images={images}
            >
              {/* @ts-ignore */}
              <set attributeName="opacity" to={preview.opacity} />
            </useCases.StaticSvg>
          </div>
        ))}
      </div>
    )

    // @NOTE: animate all presets at once. Used for debugging purposes
    // return (
    //   <useCases.AnimatedSvg
    //     entity={node}
    //     images={images}
    //     // @NOTE: disabled because we get wrong first state for animation.
    //     // Because position animated relatively.
    //     timeForPreview={preset.finalStateAt}
    //   />
    // )

    return (
      <>
        {isActive ? (
          <useCases.AnimatedSvg
            entity={node}
            images={images}
            // @NOTE: disabled because we get wrong first state for animation.
            // Because position animated relatively.
            timeForPreview={preset.finalStateAt}
          />
        ) : (
          Preview
        )}
      </>
    )
  }
)

PresetPreview.displayName = 'PresetPreview'
