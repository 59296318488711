import { Component, commitUndo, mixed } from '@aninix-inc/model'
import { InputWithIcon } from '@aninix/app-design-system'
import { observer } from 'mobx-react-lite'
import * as R from 'ramda'
import * as React from 'react'
import { useValueNumber } from '../../../../../hooks/value-controllers/number'

// @TODO: provide generic types
export interface IProps {
  components: Component[]
  time?: number
  icon?: React.ReactNode
  format?: (value: number) => string
  min?: number
  max?: number
  /**
   * Run before provide value to UI
   */
  before?: (number: number) => number
  /**
   * Run before provide value to model
   */
  after?: (number: number) => number
  dragDisabled?: boolean
  disabled?: boolean
  iconWide?: boolean
  iconWidth?: number
  width?: number
}
export const NumberValue: React.FC<IProps> = observer(
  ({
    components,
    time,
    icon,
    format,
    min,
    max,
    before = R.identity,
    after = R.identity,
    dragDisabled,
    disabled,
    iconWide,
    iconWidth,
    width,
  }) => {
    const { value, updateValue, updateByDelta } = useValueNumber({
      components,
      time,
    })
    const id = components.map((component) => component.id).join('-')
    const endChange = () => commitUndo(components[0].entity.getProjectOrThrow())

    return (
      <InputWithIcon
        value={value === mixed ? mixed : before(value)}
        id={id}
        icon={icon}
        onChange={(newValue) => updateValue(after(newValue))}
        onDeltaChange={(newDelta) => updateByDelta(after(newDelta))}
        onEndChange={endChange}
        format={format}
        min={min}
        max={max}
        dragDisabled={dragDisabled}
        disabled={disabled}
        iconWide={iconWide}
        iconWidth={iconWidth}
        width={width}
      />
    )
  }
)

NumberValue.displayName = 'NumberValue'
