import {
  ChildrenRelationsAspect,
  Entity,
  FillsRelationsAspect,
  getOpacity,
  StrokesRelationsAspect,
  VisibleInViewportComponent,
} from '@aninix-inc/model'
import * as R from 'ramda'

export const isVisible = (entity: Entity, time?: number): boolean => {
  if (!entity.getComponentOrThrow(VisibleInViewportComponent).value) {
    return false
  }

  if (getOpacity(entity, time) === 0) {
    return false
  }

  if (entity.hasAspect(ChildrenRelationsAspect)) {
    return true
  }

  if (
    entity.hasAspect(FillsRelationsAspect) &&
    entity.hasAspect(StrokesRelationsAspect)
  ) {
    const fills = entity
      .getAspectOrThrow(FillsRelationsAspect)
      .getChildrenList()
    const strokes = entity
      .getAspectOrThrow(StrokesRelationsAspect)
      .getChildrenList()

    return (
      R.any((fill) => {
        if (
          fill.getComponentOrThrow(VisibleInViewportComponent).value === false
        ) {
          return false
        }

        return true
      }, fills) ||
      R.any((stroke) => {
        if (
          stroke.getComponentOrThrow(VisibleInViewportComponent).value === false
        ) {
          return false
        }

        return true
      }, strokes)
    )
  }

  return true
}
