import { DurationComponent, Root } from '@aninix-inc/model'
import { useMouseMove } from '@aninix/app-design-system'
import { CommentsTimeline } from '@aninix/core/modules/comments-timeline'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { usePlayback, useProject } from '../../../../../../stores'

export interface IProps {}
export const ProgressBar: React.FCC<IProps> = observer(() => {
  const project = useProject()
  const playback = usePlayback()
  const duration = project
    .getEntityByTypeOrThrow(Root)
    .getComponentOrThrow(DurationComponent).value

  const { startListen, isListening, offsetX, endAtX } = useMouseMove()

  const progressRef = React.useRef<HTMLDivElement>(null)

  const progress = (playback.time / duration) * 100

  const [beenPlaying, setBeenPlaying] = React.useState<boolean | null>(null)

  const managePlaybackOnTimelineInteraction = () => {
    if (isListening && beenPlaying === null) {
      setBeenPlaying(playback.isPlaying)
      if (playback.isPlaying) playback.pause()
    }

    if (!isListening) {
      setBeenPlaying(null)
      if (beenPlaying) playback.play()
    }
  }

  React.useEffect(() => {
    managePlaybackOnTimelineInteraction()

    if (isListening === false) {
      return
    }

    const { left, width } = progressRef.current!.getBoundingClientRect()

    playback.updateTime(((endAtX - left) / width) * duration)
  }, [isListening, offsetX, endAtX])

  return (
    <div className="relative h-[30px] w-full flex-1" ref={progressRef}>
      <div className="absolute left-[-6px] right-[-6px] top-[16px]">
        <CommentsTimeline />
      </div>
      <div className="absolute left-0 top-1/2 z-0 h-0.5 w-full bg-black bg-opacity-10" />
      <div
        className="pointer-events-none absolute left-0 top-1/2 z-10 h-0.5 bg-black bg-opacity-80"
        style={{
          width: `${progress}%`,
        }}
      />

      {/* <div
        className="absolute -translate-x-1 h-2 w-2 rounded-full bg-black top-[calc(50%-0.2rem)] origin-bottom z-20  pointer-events-none"
        style={{ left: `${progress}%` }}
      /> */}
      <div
        className="absolute -left-1 -right-1 bottom-0 top-0 z-30 hover:cursor-pointer"
        // @ts-ignore
        onMouseDown={startListen}
      />
    </div>
  )
})

ProgressBar.displayName = 'ProgressBar'
