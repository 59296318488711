import * as React from 'react'

import { Logo } from '../../components'
import { Close } from './components/close'
import { Error } from './components/error'
import { Success } from './components/success'
import { IPaymentInteractor } from './interactor'

export interface IProps {
  interactor: IPaymentInteractor
}

export const PaymentRouter: React.FCC<IProps> = ({ interactor }) => (
  <div className="bg-grey w-screen h-screen flex flex-col items-center p-[20px]">
    <div className="flex items-center mb-[72px]">
      <Logo size={{ x: 32, y: 32 }} />{' '}
      <p className="font-header text-[20px] font-bold ml-[8px]">Aninix</p>
    </div>

    {interactor.children.success && <Success />}
    {interactor.children.error && <Error />}
    {interactor.children.close && <Close />}
  </div>
)
