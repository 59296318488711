import { Point2D } from '@aninix-inc/model/legacy'
import { NodeSnapshot, PaintSnapshot, paper } from '@aninix-inc/renderer'

export function isPointOnPath({
  nodeSnapshot,
  point,
  path,
  fills,
  strokes,
  skipGroups,
}: {
  nodeSnapshot: NodeSnapshot
  fills: PaintSnapshot[]
  strokes: PaintSnapshot[]
  point: Point2D
  path: string
  skipGroups: boolean
}) {
  const paperPath = new paper.CompoundPath(path)
  paperPath.matrix = nodeSnapshot.absoluteTransformMatrix

  if (fills.length > 0) paperPath.fillColor = new paper.Color('black')

  if (!skipGroups && nodeSnapshot.children !== null) {
    paperPath.fillColor = new paper.Color('black')
  }

  if (strokes.length > 0)
    paperPath.set({
      strokeWidth: Math.max(
        nodeSnapshot.strokeWeight,
        nodeSnapshot.strokeBottomWeight,
        nodeSnapshot.strokeLeftWeight,
        nodeSnapshot.strokeTopWeight,
        nodeSnapshot.strokeRightWeight
      ),
      strokeColor: 'black',
    })

  const hitOptions = {
    segments: true,
    stroke: true,
    fill: true,
    tolerance: 5,
  }

  const doesContain = paperPath.hitTest(point, hitOptions) !== null

  if (nodeSnapshot.name === 'star Fill')
    console.log(
      'hittesting',
      nodeSnapshot.name,
      'result:',
      doesContain,
      paperPath.bounds,
      paperPath.fillColor,
      skipGroups,
      nodeSnapshot.type,
      point
    )

  return doesContain
}
