import { mixed } from '@aninix-inc/model/legacy'
import InputAdornment from '@material-ui/core/InputAdornment'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import { default as classnames } from 'classnames'
import * as R from 'ramda'
import * as React from 'react'

import { LocalStorageIo } from '@aninix/api'
import { OnboardingObject } from '@aninix/app-design-system/hooks/use-onboarding'
import { defaultFigmaLikeFormat } from '@aninix/app-design-system/utils/figma-like-number-format'
import { MathOpsMixedGuide } from './math-ops-mixed-guide'

import { useMouseMove } from '@aninix/app-design-system'
import useKeyModifiers from '@aninix/app-design-system/hooks/use-key-modifiers'
import { OnboardingPopover } from '../onboarding-popover'
import * as styles from './index.scss'
import { MathOpsNumberGuide } from './math-ops-number-guide'
import { getKeysFromKeyboardEvent } from './utils/get-keys-from-keyboard-event'
import { getNumberInputValue } from './utils/get-number-input-value'
import { getValueAfterDeltaChange } from './utils/get-value-after-delta-change'
import { getValueAfterKeyPress } from './utils/get-value-after-key-press'
import { getValueFromInput } from './utils/get-value-from-input'
import { isRelativeMathOps } from './utils/is-relative-math-ops'

export interface IProps {
  id: string
  value: number | typeof mixed
  /**
   * @description offset modifier, less value make scroll more precisable
   */
  threshold?: number
  onStartChange?: () => void
  // @TODO: it's called twice, have to fix
  onChange?: (value: number) => void
  onEndChange?: () => void
  onDeltaChange?: (delta: number) => void
  min?: number
  max?: number
  disabled?: boolean
  dragDisabled?: boolean
  icon?: React.ReactNode
  format?: (value: number) => string
  width?: number
  /**
   * @description if provided it will override .iconWide property
   */
  iconWidth?: number
  iconWide?: boolean
  formatBeforeApply?: (value: string) => string
}
const returnValue = (value: string) => value
const noop = () => {}

/**
 * @todo important write tests
 */
export const Input: React.FCC<IProps> = ({
  id,
  value,
  threshold = 1,
  onStartChange = noop,
  onChange = noop,
  onEndChange = noop,
  onDeltaChange,
  min = -Infinity,
  max = Infinity,
  disabled = false,
  dragDisabled = false,
  icon,
  format = defaultFigmaLikeFormat,
  width = 78,
  iconWidth,
  iconWide = false,
  formatBeforeApply = returnValue,
}) => {
  const inputWrapperRef = React.createRef<HTMLInputElement>()
  const inputRef = React.createRef<HTMLInputElement>()

  const onbMixedRef = React.useRef<OnboardingObject>(null)
  const onbNumberRef = React.useRef<OnboardingObject>(null)

  //Migration FROM
  const legacyMathOpsOnboardingLocalStorage = new LocalStorageIo<{
    passedNumber: boolean
    passedMixed: boolean
  }>('aninix.math-ops-onboarding', {
    passedNumber: false,
    passedMixed: false,
  })

  React.useEffect(() => {
    legacyMathOpsOnboardingLocalStorage.get().then((value) => {
      if (value === null) return

      if (value.passedMixed) onbMixedRef.current?.pass()
      if (value.passedNumber) onbNumberRef.current?.pass()
    })
  }, [])
  //Migration TO

  const { isCtrlDown, isShiftDown, isAltDown } = useKeyModifiers()

  const { deltaX, isListening, startListen } = useMouseMove({
    threshold: 0,
    onStart: onStartChange,
    onFinish: onEndChange,
  })

  const [isFocused, setIsFocused] = React.useState(false)

  const handleKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const {
      isKeyDown,
      isKeyUp,
      isShiftPressed,
      isCtrlPressed,
      isEnter,
      isEsc,
    } = getKeysFromKeyboardEvent(e)

    if (value !== mixed && (isKeyDown || isKeyUp)) {
      e.preventDefault()
      e.stopPropagation()

      const valueToApply = getValueAfterKeyPress({
        threshold,
        isShiftPressed,
        isCtrlPressed,
        value,
        isPositive: isKeyUp,
      })

      onChange(R.clamp(min, max, valueToApply))
    }

    if (isEnter || isEsc) {
      inputRef.current?.blur()
    }
  }
  const handleMouseDown: React.MouseEventHandler<HTMLDivElement> = (e) => {
    startListen(e as unknown as MouseEvent)
  }

  // const handleChange: React.ChangeEventHandler<
  //   HTMLInputElement | HTMLTextAreaElement
  // > = (e) => {}

  const handleFocus = () => {
    setIsFocused(true)

    onStartChange()
  }
  const handleBlur: React.FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setIsFocused(false)

    const newValue = getValueFromInput({
      formatBeforeApply,
      inputValue: e.currentTarget.value,
      initialValue: value,
      min,
      max,
    })

    if (typeof newValue !== 'number' || isNaN(newValue)) return

    if (value === mixed && isRelativeMathOps(e.currentTarget.value)) {
      onDeltaChange?.(R.clamp(min, max, newValue))
    } else {
      onChange(R.clamp(min, max, newValue))
    }

    onEndChange()
  }

  const inputValue = getNumberInputValue({ value, format, isFocused })

  React.useEffect(() => {
    if (isListening) {
      const delta = getValueAfterDeltaChange({
        isCtrlDown,
        isShiftDown,
        deltaX,
        threshold,
      })

      if (value !== mixed) {
        const nextValue = value + delta
        const newDelta = nextValue >= min && nextValue <= max ? delta : 0
        onDeltaChange?.(newDelta)
      } else {
        onDeltaChange?.(delta)
      }
    }
  }, [isListening, deltaX, isFocused])

  React.useEffect(() => {
    if (inputRef.current == null) return

    inputRef.current.value = inputValue

    if (isFocused) inputRef.current?.select()
  }, [value, isFocused])

  return (
    <>
      <OnboardingPopover
        anchorEl={inputWrapperRef.current}
        name="math-ops-mixed"
        ref={onbMixedRef}
      >
        <MathOpsMixedGuide
          handleClose={() => onbMixedRef.current!.close()}
          handlePass={() => onbMixedRef.current!.pass()}
        />
      </OnboardingPopover>

      <OnboardingPopover
        anchorEl={inputWrapperRef.current}
        name="math-ops-number"
        ref={onbNumberRef}
      >
        <MathOpsNumberGuide
          handleClose={() => onbNumberRef.current!.close()}
          handlePass={() => onbNumberRef.current!.pass()}
          exampleValue={value === mixed ? 0 : value}
        />
      </OnboardingPopover>

      <OutlinedInput
        ref={inputWrapperRef}
        inputRef={inputRef}
        inputProps={{ style: { height: '100%' } }}
        classes={{
          adornedStart: classnames(styles['input-root'], {
            [styles['input--alt-down']]: isAltDown,
          }),
          root: classnames(styles['input-root'], {
            [styles.focused]: isFocused || isListening,
            [styles['input--alt-down']]: isAltDown,
          }),
          notchedOutline: classnames(styles['outline-notched'], {}),
          input: classnames(styles.input, {
            [styles['input--mixed']]:
              isFocused === false && isListening === false && value === mixed,
            [styles['input--disabled']]: disabled,
            [styles['input--no-icon']]: icon == null,
            [styles['input--alt-down']]: isAltDown,
          }),
          focused: styles.focused,
          disabled: styles.disabled,
        }}
        style={{
          width,
          pointerEvents: isListening ? 'none' : 'all',
        }}
        id={id}
        type="text"
        defaultValue={inputValue}
        onKeyDown={handleKeyPress}
        onMouseDown={handleMouseDown}
        // onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        autoComplete="off"
        startAdornment={
          icon != null && (
            <InputAdornment
              classes={{
                root: classnames(styles['input-adornment'], {
                  [styles['input-adornment--disabled']]:
                    dragDisabled && !isAltDown,
                }),
              }}
              // onMouseDown={handleIconMouseDown}
              position="start"
            >
              <span
                className={classnames(styles.icon, {
                  [styles['icon--wide']]: iconWide,
                })}
                style={{ width: iconWidth }}
              >
                {icon}
              </span>
            </InputAdornment>
          )
        }
        disabled={disabled}
      />
    </>
  )
}
