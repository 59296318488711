import { Project } from '@aninix-inc/model'
import { featureFlags } from '@aninix/core'
import * as React from 'react'
import {
  Avatar,
  Breadcrumbs,
  EditInFigma,
  Menu,
  Share,
  Tools,
  Zoom,
} from './components'
import { Export as OldExport } from './components/old-export'

type DivProps = React.HTMLAttributes<HTMLDivElement>

const LeftControls: React.FCC<DivProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
)
const CenterControls: React.FCC<DivProps> = ({ className, children }) => (
  <div className={className}>{children}</div>
)
const RightControls: React.FCC<DivProps> = ({ children, className }) => (
  <div className={className}>{children}</div>
)

export interface IProps {
  project: Project
  displayShareProject?: boolean
  dashboardUrl: string
  centerComponents?: React.ReactNode
  rightComponents?: React.ReactNode
}

export const Toolbar: React.FCC<IProps> = ({
  project,
  dashboardUrl,
  displayShareProject = false,
  centerComponents,
  rightComponents,
}) => {
  /*use this to declare any global variables for toolbar*/
  const toolbarCssVariablesStyle = {
    '--toolbar-height': '48px',
    '--toolbar-bg': '#0B1118D9',
  } as React.CSSProperties

  return (
    <div
      style={toolbarCssVariablesStyle}
      className="relative flex w-full flex-col items-center bg-[--toolbar-bg] px-2 sm:flex-row sm:justify-between sm:px-4"
    >
      <div className="flex h-[--toolbar-height] w-full flex-row justify-between">
        <LeftControls className="flex items-center gap-2">
          <Avatar />
          <Menu />
          <Tools />
        </LeftControls>

        <RightControls className="flex items-center gap-2">
          <Zoom />

          {rightComponents}
          {featureFlags.aninixToFigmaInstruction && <EditInFigma />}
          {displayShareProject && <Share />}
          {/* @TODO: replace export with new one */}
          <OldExport project={project} />
          {/* <Export /> */}
        </RightControls>
      </div>

      <CenterControls className="pointer-events-none inline w-full items-center justify-center pb-4 pt-1 sm:absolute sm:inset-0 sm:flex sm:p-0">
        <div className="pointer-events-auto flex items-center gap-2">
          <Breadcrumbs dashboardUrl={dashboardUrl} />
          {centerComponents}
        </div>
      </CenterControls>
    </div>
  )
}

Toolbar.displayName = 'Toolbar'
