import * as React from 'react'

import { IIcon } from '.'

const defaultSize = {
  x: 20,
  y: 20,
}

export const InspectCode: React.FCC<IIcon> = ({
  size = defaultSize,
  className = '',
}) => (
  <svg
    width={size.x}
    height={size.y}
    viewBox="0 0 20 20"
    className={className}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1665 6.6665L17.4998 9.99984L14.1665 13.3332"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.83333 6.6665L2.5 9.99984L5.83333 13.3332"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6668 3.3335L8.3335 16.6668"
      stroke="#89909A"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
