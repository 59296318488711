import {
  Entity,
  LayoutAspect,
  Point2dValueComponent,
  SkewComponent,
  TimeComponent,
} from '@aninix-inc/model'
import { safeString } from '@aninix/core'
import { observer } from 'mobx-react-lite'
import * as React from 'react'
import {
  getDuration,
  getKeySplines,
  getKeyTimes,
  getKeyframes,
  getRepeatCount,
  getSegments,
  getStartEnd,
} from './utils'

interface IProps {
  entity: Entity
  applyTo?: string
  timeRange?: [number, number]
  startEvent?: string
  endEvent?: string
}
export const SkewProperty: React.FCC<IProps> = observer(
  ({ entity, applyTo, timeRange, startEvent, endEvent }) => {
    const layout = entity.getAspectOrThrow(LayoutAspect)
    const initialValue = layout.skew.getValue(0)
    const keyframes = getKeyframes(
      entity.getComponentOrThrow(SkewComponent),
      timeRange
    )
    const [start, end] = getStartEnd(keyframes, timeRange)
    const duration = getDuration(keyframes, timeRange)
    const segments = getSegments(keyframes)
    const repeatCount = getRepeatCount()

    if (duration === 0) {
      return null
    }

    // return (
    //   <Portal containerId={applyTo ?? safeString(node.id)}>
    //     <animateTransform
    //       attributeName="transform"
    //       type="translate"
    //       values={keyframes
    //         .map((keyframe) => {
    //           const _anchorPoint = node.anchorPoint.getValue(keyframe)
    //           return `${_anchorPoint.x} ${_anchorPoint.y}`
    //         })
    //         .join(';')}
    //       dur={`${duration}s`}
    //       repeatCount={repeatCount}
    //       calcMode="spline"
    //       keyTimes={keyframes
    //         .map((keyframe) => round(keyframe.time, { fixed: 2 }))
    //         .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    //         .map((time) => round(time, { fixed: 2 }))
    //         .join(';')}
    //       keySplines={segments.map(getSegmentCurve).join(';')}
    //       additive="sum"
    //       begin={startEvent}
    //       end={endEvent}
    //     />

    //     <animateTransform
    //       attributeName="transform"
    //       type="skewX"
    //       values={keyframes
    //         .map((keyframe) => keyframe.value.x - initialValue.x)
    //         .join(';')}
    //       dur={`${duration}s`}
    //       repeatCount={repeatCount}
    //       calcMode="spline"
    //       keyTimes={keyframes
    //         .map((keyframe) => round(keyframe.time, { fixed: 2 }))
    //         .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    //         .map((time) => round(time, { fixed: 2 }))
    //         .join(';')}
    //       keySplines={segments.map(getSegmentCurve).join(';')}
    //       additive="sum"
    //       begin={startEvent}
    //       end={endEvent}
    //     />

    //     <animateTransform
    //       attributeName="transform"
    //       type="skewY"
    //       values={keyframes
    //         .map((keyframe) => keyframe.value.y - initialValue.y)
    //         .join(';')}
    //       dur={`${duration}s`}
    //       repeatCount={repeatCount}
    //       calcMode="spline"
    //       keyTimes={keyframes
    //         .map((keyframe) => round(keyframe.time, { fixed: 2 }))
    //         .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    //         .map((time) => round(time, { fixed: 2 }))
    //         .join(';')}
    //       keySplines={segments.map(getSegmentCurve).join(';')}
    //       additive="sum"
    //       begin={startEvent}
    //       end={endEvent}
    //     />

    //     <animateTransform
    //       attributeName="transform"
    //       type="translate"
    //       values={keyframes
    //         .map((keyframe) => {
    //           const _anchorPoint = node.anchorPoint.getValue(keyframe)
    //           return `${-_anchorPoint.x} ${-_anchorPoint.y}`
    //         })
    //         .join(';')}
    //       dur={`${duration}s`}
    //       repeatCount={repeatCount}
    //       calcMode="spline"
    //       keyTimes={keyframes
    //         .map((keyframe) => round(keyframe.time, { fixed: 2 }))
    //         .map((time) => lerpRangeClamped(time, start, end, 0, 1))
    //         .map((time) => round(time, { fixed: 2 }))
    //         .join(';')}
    //       keySplines={segments.map(getSegmentCurve).join(';')}
    //       additive="sum"
    //       begin={startEvent}
    //       end={endEvent}
    //     />
    //   </Portal>
    // )

    return (
      <>
        <animateTransform
          href={`#${applyTo ?? safeString(entity.id)}`}
          attributeName="transform"
          type="translate"
          values={keyframes
            .map((keyframe) => {
              const localAnchorPoint = layout.anchorPoint.getValue(
                keyframe.getComponentOrThrow(TimeComponent).value
              )
              return `${localAnchorPoint.x} ${localAnchorPoint.y}`
            })
            .join(';')}
          dur={`${duration}s`}
          repeatCount={repeatCount}
          calcMode="spline"
          keyTimes={getKeyTimes({ keyframes, start, end })}
          keySplines={getKeySplines(segments)}
          additive="sum"
          fill="freeze"
          begin={startEvent}
          end={endEvent}
        />

        <animateTransform
          xlinkHref={`#${applyTo ?? safeString(entity.id)}`}
          href={`#${applyTo ?? safeString(entity.id)}`}
          attributeName="transform"
          type="skewX"
          values={keyframes
            .map(
              (keyframe) =>
                keyframe.getComponentOrThrow(Point2dValueComponent).value.x -
                initialValue.x
            )
            .join(';')}
          dur={`${duration}s`}
          repeatCount={repeatCount}
          calcMode="spline"
          keyTimes={getKeyTimes({ keyframes, start, end })}
          keySplines={getKeySplines(segments)}
          additive="sum"
          fill="freeze"
          begin={startEvent}
          end={endEvent}
        />

        <animateTransform
          xlinkHref={`#${applyTo ?? safeString(entity.id)}`}
          href={`#${applyTo ?? safeString(entity.id)}`}
          attributeName="transform"
          type="skewY"
          values={keyframes
            .map(
              (keyframe) =>
                keyframe.getComponentOrThrow(Point2dValueComponent).value.y -
                initialValue.y
            )
            .join(';')}
          dur={`${duration}s`}
          repeatCount={repeatCount}
          calcMode="spline"
          keyTimes={getKeyTimes({ keyframes, start, end })}
          keySplines={getKeySplines(segments)}
          additive="sum"
          fill="freeze"
          begin={startEvent}
          end={endEvent}
        />

        <animateTransform
          xlinkHref={`#${applyTo ?? safeString(entity.id)}`}
          href={`#${applyTo ?? safeString(entity.id)}`}
          attributeName="transform"
          type="translate"
          values={keyframes
            .map((keyframe) => {
              const localAnchorPoint = layout.anchorPoint.getValue(
                keyframe.getComponentOrThrow(TimeComponent).value
              )
              return `${-localAnchorPoint.x} ${-localAnchorPoint.y}`
            })
            .join(';')}
          dur={`${duration}s`}
          repeatCount={repeatCount}
          calcMode="spline"
          keyTimes={getKeyTimes({ keyframes, start, end })}
          keySplines={getKeySplines(segments)}
          additive="sum"
          fill="freeze"
          begin={startEvent}
          end={endEvent}
        />
      </>
    )
  }
)

SkewProperty.displayName = 'SkewProperty'
